import React, { useEffect, useState } from "react";
import PopupBackground from "@component/PopupBackground";
import {
  copyReturnOrder,
  fetchCreateReturnOrder,
  fetchUpdateReturnOrder,
  returnOrderState,
  fetchCarrierList,
  copyReturnOrderWithProducts,
  fetchExternalWarehouseList,
  fetchInternalWarehouseList,
} from "@redux/returnOrderSlice";
import { Button, Checkbox, DatePicker, Form, Input, Select, Spin } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment, { Moment } from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { ReturnOrderDetail } from "./interfaces";

const FormWrapper = styled(Form)`
  width: 500px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colorNeutral100};
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
`;
const GrayText = styled.span`
  color: ${({ theme }) => theme.colorNeutral500};
`;
const NoticeText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 40px;
`;

type Props = {
  onClose: () => void;
  returnOrderDetail?: ReturnOrderDetail | null;
  mode?: string;
};

export default function EditOrderForm(props: Props) {
  const { onClose, returnOrderDetail, mode } = props;
  const dispatch = useDispatch();
  const { isFetching, carrierList, externalWarehouseList, internalWarehouseList } = useSelector(returnOrderState);
  const { returnOrderId } = useParams();
  const [form] = Form.useForm();
  const [isCopyProduct, setIsCopyProduct] = useState<boolean>(false);
  const sourceWarehouseForm = Form.useWatch("sourceWarehouseCode", form);
  const destWarehouseForm = Form.useWatch("destWarehouseCode", form);

  const destWarehouseOption = internalWarehouseList.filter((item) => {
    return item.code !== sourceWarehouseForm;
  });

  const onSubmit = (values: any) => {
    const params = {
      returnOrderId,
      estArrivedDate: values.estArrivedDate ? values.estArrivedDate.format("YYYY-MM-DD") : "",
      estDeployedDate: values.estDeployedDate ? values.estDeployedDate.format("YYYY-MM-DD") : "",
      sourceWarehouseCode: values.sourceWarehouseCode,
      destWarehouseCode: values.destWarehouseCode,
      desc: values.desc,
      carrierId: values.carrierId,
    };
    const copyParams = {
      returnOrderId,
      estArrivedDate: values.estArrivedDate ? values.estArrivedDate.format("YYYY-MM-DD") : "",
      estDeployedDate: values.estDeployedDate ? values.estDeployedDate.format("YYYY-MM-DD") : "",
      desc: values.desc,
    };

    if (mode === "copy") {
      if (isCopyProduct) {
        dispatch(copyReturnOrderWithProducts(copyParams));
      } else {
        dispatch(copyReturnOrder(params));
      }
      onClose();
      return;
    }
    if (returnOrderDetail) {
      // 編輯
      dispatch(fetchUpdateReturnOrder(params));
    } else {
      // 新增
      dispatch(fetchCreateReturnOrder(params));
    }
    onClose();
  };

  const handleOnSelectDestWarehouse = (value: string) => {
    const selectedValue = internalWarehouseList.find((internalWarehouse) => {
      return internalWarehouse.code === value;
    });
    if (selectedValue) {
      form.setFieldsValue({
        receiverName: selectedValue.contact,
        receiverPhone: selectedValue.phone,
        receiverAddress: selectedValue.address,
      });
    } else {
      form.setFieldsValue({
        receiverName: undefined,
        receiverPhone: undefined,
        receiverAddress: undefined,
      });
    }
  };

  useEffect(() => {
    if (sourceWarehouseForm) {
      dispatch(fetchCarrierList(sourceWarehouseForm));
    }
    dispatch(fetchExternalWarehouseList());
    dispatch(fetchInternalWarehouseList());
  }, [dispatch, sourceWarehouseForm]);

  const disabledDate = (currentDate: Moment) => {
    return currentDate && currentDate < moment().startOf("day");
  };

  return (
    <PopupBackground close={onClose}>
      <FormWrapper
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        labelAlign="left"
        onFinish={onSubmit}
        colon={false}
        initialValues={{
          ...returnOrderDetail,
          estDeployedDate: returnOrderDetail?.estDeployedDate ? moment(returnOrderDetail.estDeployedDate) : "",
          estArrivedDate: returnOrderDetail?.estArrivedDate ? moment(returnOrderDetail.estArrivedDate) : "",
        }}
      >
        <Spin spinning={isFetching}>
          {returnOrderId && mode === "copy" && (
            <Form.Item>
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => {
                  const { checked } = e.target;
                  setIsCopyProduct(checked);
                }}
                checked={isCopyProduct}
              >
                要一併複製調撥商品
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item name="sourceWarehouseCode" label="出庫倉庫" rules={[{ required: true, message: "" }]}>
            <Select
              placeholder="請選擇"
              style={{ width: 200 }}
              onChange={() =>
                form.setFieldsValue({
                  carrierRelationName: undefined,
                  carrierId: undefined,
                  destWarehouseCode: undefined,
                })
              }
            >
              {externalWarehouseList.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const estArrivedDateForm = getFieldValue("estArrivedDate");
              return (
                <Form.Item
                  shouldUpdate
                  name="estDeployedDate"
                  label="預計拋單日期"
                  extra={<NoticeText>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</NoticeText>}
                  rules={[
                    { required: true, message: "" },
                    {
                      validator: (rule, value) => {
                        if (estArrivedDateForm) {
                          return value < moment(estArrivedDateForm) ? Promise.resolve() : Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: "預計到貨日必須大於或等於預計拋單日",
                    },
                  ]}
                >
                  <DatePicker placeholder="請選擇日期" style={{ width: 200 }} disabledDate={disabledDate} />
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const estDeployedDateForm = getFieldValue("estDeployedDate");
              return (
                <Form.Item
                  shouldUpdate
                  name="estArrivedDate"
                  label="預計到貨日"
                  extra={<NoticeText>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</NoticeText>}
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (estDeployedDateForm && value) {
                          return value > moment(estDeployedDateForm) ? Promise.resolve() : Promise.reject();
                        }
                        return Promise.resolve();
                      },
                      message: "預計到貨日必須大於或等於預計拋單日",
                    },
                  ]}
                >
                  <DatePicker placeholder="請選擇日期" style={{ width: 200 }} disabledDate={disabledDate} />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item label="運送方式" name="carrierId" rules={[{ required: true, message: "" }]}>
            <Select placeholder="請選擇" style={{ width: 200 }} disabled={!sourceWarehouseForm}>
              {carrierList.map((carrier) => (
                <Select.Option key={carrier.id} value={carrier.carrierId}>
                  {carrier.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="入庫倉庫" name="destWarehouseCode" rules={[{ required: true, message: "" }]}>
            <Select
              placeholder="請選擇"
              style={{ width: 200 }}
              onChange={handleOnSelectDestWarehouse}
              disabled={!sourceWarehouseForm}
            >
              {destWarehouseOption.map((item) => (
                <Select.Option key={item.code} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="收件人" name="receiverName">
            {destWarehouseForm ? (
              <Input bordered={false} disabled />
            ) : (
              <GrayText>選擇入庫倉庫後，系統會自動帶入</GrayText>
            )}
          </Form.Item>
          <Form.Item label="收件電話" name="receiverPhone">
            {destWarehouseForm ? (
              <Input bordered={false} disabled />
            ) : (
              <GrayText>選擇入庫倉庫後，系統會自動帶入</GrayText>
            )}
          </Form.Item>
          <Form.Item label="收件地址" name="receiverAddress">
            {destWarehouseForm ? (
              <Input bordered={false} disabled />
            ) : (
              <GrayText>選擇入庫倉庫後，系統會自動帶入</GrayText>
            )}
          </Form.Item>
          <Form.Item label="備註" name="desc">
            <Input placeholder="最多30字" maxLength={50} />
          </Form.Item>
          <Footer>
            <Button style={{ marginRight: "14px" }} onClick={() => onClose()}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              儲存
            </Button>
          </Footer>
        </Spin>
      </FormWrapper>
    </PopupBackground>
  );
}
