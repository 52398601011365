import { topicState } from "@redux/topicSlice";
import React, { createContext, FC, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import generateCategoryTree, { SalesCategory } from "./utils/generateCategoryTree";

interface ContextType {
  tree: Map<number, SalesCategory>;
  level1: Map<number, SalesCategory>;
  level2: Map<number, SalesCategory>;
  level3: Map<number, SalesCategory>;
  searchText: string;
  checkCategories: number[];
  openModal: boolean;
  tagList: Map<number, { name: string; id: number }>;
  setTagList: React.Dispatch<React.SetStateAction<Map<number, { name: string; id: number }>>>;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  setCheckCategories: React.Dispatch<React.SetStateAction<number[]>>;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
}

const initialState = {
  tree: new Map(),
  level1: new Map(),
  level2: new Map(),
  level3: new Map(),
  searchText: "",
  checkCategories: [],
  openModal: false,
  tagList: new Map(),
  setTagList: () => {},
  setOpenModal: () => {},
  setCheckCategories: () => {},
  setSearchText: () => {},
};

const ManageTagProvider: FC = (props) => {
  const { children } = props;

  const { salesCategories } = useSelector(topicState);

  const [tree, setTree] = useState<Map<number, SalesCategory>>(new Map());
  const [level1, setLevel1] = useState<Map<number, SalesCategory>>(new Map());
  const [level2, setLevel2] = useState<Map<number, SalesCategory>>(new Map());
  const [level3, setLevel3] = useState<Map<number, SalesCategory>>(new Map());
  const [searchText, setSearchText] = useState<string>("");
  const [checkCategories, setCheckCategories] = useState<number[]>([]);
  const [tagList, setTagList] = useState<Map<number, { name: string; id: number }>>(new Map());

  // layout
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (salesCategories) {
      const { treeMap, level1Map, level2Map, level3Map } = generateCategoryTree(salesCategories);
      setTree(treeMap);
      setLevel1(level1Map);
      setLevel2(level2Map);
      setLevel3(level3Map);
    }
  }, [salesCategories]);

  return (
    <ManageTagContext.Provider
      value={{
        tree,
        level1,
        level2,
        level3,
        searchText,
        checkCategories,
        openModal,
        tagList,
        setTagList,
        setOpenModal,
        setCheckCategories,
        setSearchText,
      }}
    >
      {children}
    </ManageTagContext.Provider>
  );
};

const ManageTagContext = createContext<ContextType>(initialState);
export const useManageTagContext = () => useContext(ManageTagContext);
export default ManageTagProvider;
