/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import useDebounce from "@hooks/useDebounce";
import { ColumnsType } from "antd/lib/table";
import { PlusCircleTwoTone, MenuOutlined, MinusCircleTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { message, Button, Form, Input, Modal, Checkbox, Table, InputNumber, AutoComplete } from "antd";
import { SortableContainer, SortableElement, SortableHandle, SortEnd } from "react-sortable-hoc";
import {
  fetchLabelList,
  nutritionState,
  setShowAddPopup,
  fetchAddNutritionSummary,
  fetchUpdateNutritionRelation,
  fetchUpdateNutritionSummary,
  fetchNutritionSummary,
  fetchNutritionRelation,
} from "@redux/nutritionSlice";
import { arrayMoveImmutable } from "array-move";
import nutritionApi from "@api/nutritionApi";

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#1890FF" }} />);

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
  .ant-input[disabled] {
    color: #000;
  }
`;
const AddLabelForm = styled(Wrapper)``;

const Text = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 5px 5px 0 0px;
  color: rgba(0, 0, 0, 0.25); ;
`;
const Title = styled.div`
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
`;
const CustomButton = styled(Button)`
  width: 80px;
  border-radius: 2px;
`;
const Div = styled.div`
  margin: 16px;
`;
const TableWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;
const PopupFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const Row = styled.div`
  display: flex;
  margin: 0 0 10px 0;
`;
const InputRow = styled.div`
  position: relative;
  margin-left: 15px;
  margin-top: 10px;
  border-radius: 2px;
`;
const PerSizeRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  margin-top: 10px;
`;
const PerSizeDiv = styled.div`
  display: flex;
`;
const ErrDiv = styled.div`
  color: #ff4d4e;
`;
const RequiredIcon = styled.span`
  color: #ff4d4e;
`;
const RowTitle = styled.div`
  width: 80px;
  margin-top: 10px;
  line-height: 32px;
`;
const CuntomInput = styled(Input)``;

const OptionButton = styled(Button)`
  margin: 5px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;
const AutoCompleteWrapper = styled.div`
  width: 150px;
`;
const UnitAutoCompleteWrapper = styled.div`
  width: 75px;
`;
const CustomInput = styled(Input)<{ borderColor?: string }>`
  position: relative;
  border: ${({ borderColor }) => (borderColor ? `${borderColor} solid 1px` : "")};
`;

const Nutrition = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [labelAddForm] = Form.useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [count, setCount] = useState(dataSource.length);
  const [addLabelId, setAddLabelId] = useState<number | string>(-1);
  const [errMessage, setErrMessage] = useState(false);
  const [noOneError, setNoOneError] = useState(false);
  const [status, setStatus] = useState("");

  const { id: productId } = useParams();

  const {
    isFetching,
    isSummaryFetching,
    showAddPopup,
    labelList,
    nutritionSummary,
    nutritionRelations,
    summaryUnitList,
  } = useSelector(nutritionState);

  const formattedNutritionRelations = nutritionRelations.results.map((item, index) => {
    return {
      ...item,
      index,
    };
  });

  const SortableItem = SortableElement((props: any) => {
    return <tr {...props} />;
  });
  const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

  const DraggableContainer = (props: any) => (
    <SortableContainers useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = (props: any) => {
    const index = dataSource.findIndex((x: any) => {
      return x.index === props["data-row-key"];
    });
    return <SortableItem index={index} {...props} />;
  };

  const defaultColumns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "",
      render: (_, value) => {
        return (
          <CustomButton onClick={() => handleDelete(value.id)} icon={<MinusCircleTwoTone />}>
            刪除
          </CustomButton>
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      render: (_, value) => {
        return <Form.Item name={`labelId-${value.id}`} />;
      },
    },
    {
      title: "成分",
      dataIndex: "label",
      render: (_, value) => {
        return (
          <>
            {value.key !== "new" && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldsValue }) => {
                  const labelsFormValues = getFieldsValue();
                  const labelKeys = Object.keys(labelsFormValues).filter((key) => key.startsWith("label-"));
                  const labelValues = labelKeys.map((key) => labelsFormValues[key]);

                  const filteredLabels = labelList.results.filter((item) => !labelValues.includes(item.label));
                  const filteredLabelsOption = filteredLabels.map((item) => {
                    return {
                      value: item.label,
                      id: item.id,
                      text: item.label,
                    };
                  });
                  const handleOpenPopup = (id: number | string) => {
                    labelAddForm.resetFields();
                    dispatch(setShowAddPopup(true));
                    setAddLabelId(id);
                  };
                  return (
                    <AutoCompleteWrapper>
                      <Form.Item name={`label-${value.id}`} rules={[{ required: true, message: "請填寫成分" }]}>
                        <AutoComplete
                          allowClear
                          onSelect={(e: any) => onSelectLabel(e, value.id)}
                          options={filteredLabelsOption}
                          placeholder="請選擇"
                          filterOption={(inputValue, option) =>
                            option!.text.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                          }
                          dropdownRender={(menu) => (
                            <>
                              {menu}
                              <OptionButton type="primary" onClick={() => handleOpenPopup(value.id)}>
                                新增
                              </OptionButton>
                            </>
                          )}
                        />
                      </Form.Item>
                    </AutoCompleteWrapper>
                  );
                }}
              </Form.Item>
            )}
          </>
        );
      },
    },
    {
      title: "含量",
      dataIndex: "amountPerServing",
      width: "150px",
      render: (_, value, index) => {
        return (
          <>
            {value.key !== "new" && (
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const label = getFieldValue([`label-${value.id}`]);

                  return (
                    <Form.Item
                      name={`amountPerServing-${value.id}`}
                      rules={[{ required: true, message: "請填寫含量" }]}
                    >
                      <Input disabled={!label} onChange={() => onChangeAmountPerServing(value.id, index)} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            )}
          </>
        );
      },
    },
    {
      title: "單位",
      dataIndex: "unit",
      render: (_, value) => {
        return (
          <Form.Item label="" name={`unit-${value.id}`}>
            <CuntomInput
              defaultValue={value.nutritionLabel?.unit ? `(${value.nutritionLabel?.unit})` : ""}
              disabled
              bordered={false}
            />
          </Form.Item>
        );
      },
    },
    {
      title: "每日攝量百分比",
      dataIndex: "dailyIntake",
      render: (_, value) => {
        return (
          <>
            {value.key !== "new" && (
              <Form.Item label="" name={`dailyIntake-${value.id}`}>
                <CuntomInput
                  defaultValue={
                    value.amountPerServing > 0 && value.nutritionLabel?.dailyIntake > 0
                      ? `${((value.amountPerServing / value.nutritionLabel?.dailyIntake) * 100).toFixed(2)}%`
                      : "*"
                  }
                  disabled
                  bordered={false}
                />
              </Form.Item>
            )}
          </>
        );
      },
    },

    {
      title: "順序(拖曳)",
      dataIndex: "sort",
      width: 100,
      className: "drag-visible",
      render: (_, value) => {
        return <DragHandle />;
      },
    },
  ];

  const onSelectLabel = (e: any, id: number | string) => {
    const selectedLabel = labelList.results.find((label) => {
      return label.label === e;
    });

    const labelsFormValues = form.getFieldsValue();
    const labelAmountPerServing = labelsFormValues[`amountPerServing-${id}`];

    if (selectedLabel) {
      form.setFieldsValue({
        [`unit-${id}`]: `(${selectedLabel.unit})`,
        [`label-${id}`]: `${selectedLabel.label}`,
        [`labelId-${id}`]: `${selectedLabel.id}`,
      });
    }
    form.setFieldsValue({
      [`dailyIntake-${id}`]:
        selectedLabel?.dailyIntake && labelAmountPerServing
          ? `${((labelAmountPerServing / selectedLabel?.dailyIntake) * 100).toFixed(1) || 0}%`
          : "*",
    });
  };

  const onChangeAmountPerServing = useDebounce((id: number | string, index: number) => {
    const labelsFormValues = form.getFieldsValue();
    const labelAmountPerServing = labelsFormValues[`amountPerServing-${id}`];
    const labelValue = labelsFormValues[`label-${id}`];

    const selectedLabel = labelList.results.find((label) => {
      return label.label === labelValue;
    });

    form.setFieldsValue({
      [`dailyIntake-${id}`]: selectedLabel?.dailyIntake
        ? `${((labelAmountPerServing / selectedLabel?.dailyIntake) * 100).toFixed(1)}%`
        : "*",
    });
  }, 300);
  const handleAdd = () => {
    scrollToLeft();
    const newData = {
      id: `new-${count}`,
      index: count,
      rank: count,
    };

    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };

  function scrollToLeft() {
    const table = document.getElementsByClassName("ant-table-content")[0];
    if (table) {
      table.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }
  }

  const handleDelete = (id: number) => {
    const newData = dataSource.filter((item: { id: number }) => item.id !== id);
    setDataSource(newData);
  };

  useEffect(() => {
    dispatch(fetchLabelList());
    dispatch(fetchNutritionSummary(productId));
    dispatch(fetchNutritionRelation(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    setCount(dataSource.length);
  }, [dataSource.length]);

  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource, oldIndex, newIndex).map((item, index) => {
        return {
          ...item,
          rank: index,
          key: index,
        };
      });
      setDataSource(newData);
    }
  };

  const onSubmit = async (id: number | string) => {
    const labelFormValues = labelAddForm.getFieldsValue();
    const payload = {
      ...labelFormValues,
      dailyIntake: labelFormValues.dailyIntake ? labelFormValues.dailyIntake : undefined,
    };
    let response;
    try {
      response = await nutritionApi.fetchAddLabel(payload);
      dispatch(fetchLabelList());
      form.setFieldsValue({
        [`label-${id}`]: labelFormValues.label,
        [`labelId-${id}`]: response?.id,
        [`unit-${id}`]: `(${labelFormValues.unit})`,
        [`dailyIntake-${id}`]: "*",
        [`amountPerServing-${id}`]: labelFormValues.dailyIntake && labelFormValues.amountPerServing,
      });
      message.success("已新增");
      return "success";
    } catch (error: any) {
      return "error";
    }
  };

  const handleOnSubmit = () => {
    const labelsFormValues = form.getFieldsValue();

    const formatedData = dataSource.map((item) => {
      return {
        id: typeof item.id === "number" ? item.id : null,
        labelId: parseInt(labelsFormValues[`labelId-${item.id}`], 10),
        amountPerServing: labelsFormValues[`amountPerServing-${item.id}`],
        rank: item.rank,
      };
    });

    const payload = {
      productId,
      data: formatedData,
    };

    if (nutritionSummary.count > 0) {
      // 編輯
      const params = {
        productId,
        servingPerContainer: labelsFormValues.servingPerContainer,
        servingSize: labelsFormValues.servingSize ? Number(labelsFormValues.servingSize) : undefined,
        summaryId: nutritionSummary.results[0].id,
        perServing: labelsFormValues.perServing ? Number(labelsFormValues.perServing) : undefined,
        perServingUnit: labelsFormValues.perServingUnit?.trim(),
      };
      dispatch(fetchUpdateNutritionSummary(params));
    } else {
      // 新增
      const params = {
        productId,
        servingPerContainer: labelsFormValues.servingPerContainer,
        servingSize: labelsFormValues.servingSize ? Number(labelsFormValues.servingSize) : undefined,
        perServing: labelsFormValues.perServing ? Number(labelsFormValues.perServing) : undefined,
        perServingUnit: labelsFormValues.perServingUnit?.trim(),
      };
      dispatch(fetchAddNutritionSummary(params));
    }
    dispatch(fetchUpdateNutritionRelation(payload));
  };

  useEffect(() => {
    if (nutritionSummary.count !== 0) {
      // 編輯
      nutritionRelations.results.forEach((item) => {
        form.setFieldsValue({
          [`amountPerServing-${item.id}`]: item.amountPerServing,
          [`labelId-${item.id}`]: item.nutritionLabel.id,
          [`label-${item.id}`]: item.nutritionLabel.label,
        });
      });
      setDataSource([...formattedNutritionRelations]);
    }
    form.setFieldsValue({
      servingSize: nutritionSummary.results[0]?.servingSize,
      servingPerContainer: nutritionSummary.results[0]?.servingPerContainer,
      perServing: nutritionSummary.results[0]?.perServing,
      perServingUnit: nutritionSummary.results[0]?.perServingUnit,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nutritionRelations.results, nutritionSummary.count]);

  return (
    <>
      <Modal visible={showAddPopup} closable={false} footer={false} maskClosable={false}>
        <AddLabelForm
          labelAlign="left"
          colon={false}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 10 }}
          form={labelAddForm}
          onFinish={() => onSubmit(addLabelId)}
        >
          <Form.Item label="成份名稱" name="label" rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="單位" name="unit" rules={[{ required: true, message: "" }]}>
            <Input placeholder="僅可輸入中文" />
          </Form.Item>
          <Form.Item label="每日建議攝取量" name="dailyIntake">
            <StyledInputNumber min={0} placeholder="僅可輸入數字" />
          </Form.Item>
          <Form.Item label="前台是否可篩選" name="filterEnabled" valuePropName="checked">
            <Checkbox />
          </Form.Item>
          <PopupFooter>
            <Form.Item>
              <Button style={{ marginRight: "10px" }} onClick={() => dispatch(setShowAddPopup(false))}>
                取消
              </Button>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                確認
              </Button>
            </Form.Item>
          </PopupFooter>
        </AddLabelForm>
      </Modal>
      <Wrapper labelAlign="left" colon={false} onFinish={handleOnSubmit} form={form}>
        <Title>營養成分用量（每次用量/每一份量至少須擇一填寫）</Title>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => {
            // 若填寫每一份量，則數字和單位兩者都要填
            const unitsOption = summaryUnitList.map((item, index) => {
              return {
                value: item,
                id: index,
                text: item,
              };
            });

            if (
              (getFieldValue("perServing") && !getFieldValue("perServingUnit")?.toString().trim()) ||
              (getFieldValue("perServingUnit") && !getFieldValue("perServing")?.toString()?.trim())
            ) {
              setErrMessage(true);
            } else {
              setErrMessage(false);
            }
            if (
              !isSummaryFetching &&
              !getFieldValue("perServing")?.toString()?.trim() &&
              !getFieldValue("servingSize")?.toString()?.trim()
            ) {
              setNoOneError(true);
              setStatus("#FF4D4E");
            } else {
              setNoOneError(false);
              setStatus("");
            }
            return (
              <>
                <Row>
                  <RowTitle>每一份量</RowTitle>
                  <PerSizeRow>
                    <PerSizeDiv>
                      <Form.Item shouldUpdate name="perServing" style={{ margin: "0px" }}>
                        <CustomInput style={{ width: "170px", marginRight: "15px" }} borderColor={status} />
                      </Form.Item>
                      <UnitAutoCompleteWrapper>
                        <Form.Item
                          shouldUpdate
                          name="perServingUnit"
                          labelCol={{ span: 8, offset: 20 }}
                          style={{ margin: "0px" }}
                        >
                          <AutoComplete
                            allowClear
                            options={unitsOption}
                            placeholder="請選擇"
                            filterOption={(inputValue, option) =>
                              option!.text.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                            }
                          />
                        </Form.Item>
                      </UnitAutoCompleteWrapper>
                    </PerSizeDiv>

                    {errMessage && <ErrDiv>每一份量或單位請填寫完整</ErrDiv>}
                    {noOneError && <ErrDiv>每次用量/每一份量至少須擇一填寫</ErrDiv>}
                  </PerSizeRow>
                </Row>
                <Row>
                  <RowTitle>每次用量</RowTitle>
                  <InputRow>
                    <>
                      <Form.Item style={{ margin: "0px" }} shouldUpdate name="servingSize">
                        <CustomInput borderColor={status} />
                      </Form.Item>
                      <Text>份</Text>
                    </>
                    {noOneError && <ErrDiv>每次用量/每一份量至少須擇一填寫</ErrDiv>}
                  </InputRow>
                </Row>
              </>
            );
          }}
        </Form.Item>
        <Row>
          <RowTitle>
            每包裝份數
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <InputRow>
            <Form.Item name="servingPerContainer" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
            <Text>份</Text>
          </InputRow>
        </Row>
        <Title>每份營養價值成分</Title>

        <TableWrapper>
          <Form form={form} onFinish={handleOnSubmit}>
            <Table
              loading={isFetching}
              rowKey="index"
              scroll={{ x: "max-content" }}
              columns={defaultColumns}
              dataSource={dataSource}
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
              pagination={false}
            />
          </Form>
          <Div>
            <CustomButton onClick={() => handleAdd()} icon={<PlusCircleTwoTone />}>
              新增
            </CustomButton>
          </Div>
        </TableWrapper>
        <Footer>
          <Form.Item>
            <Button type="primary" htmlType="submit" disabled={noOneError || errMessage}>
              儲存此分頁
            </Button>
          </Form.Item>
        </Footer>
      </Wrapper>
    </>
  );
};

export default Nutrition;
