import { CloseCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import { fetchCategoryRemoveTag, topicState } from "@redux/topicSlice";
import { Button, Modal, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useManageTagContext } from "./ManageTagContext";
import getCategoryBreadcrumb from "./utils/getCategoryBreadcrumb";

const Wrapper = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled(Row)`
  padding: 10px 17px;
  background: #f4f5fa;
  position: relative;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 50px;
`;

const SearchInput = styled.input`
  width: 300px;
  height: 30px;
  padding: 8px 0 8px 10px;
  border-radius: 2px;
  border: solid 1px ${({ theme }) => theme.colorNeutral400};
`;

const { confirm } = Modal;

const SearchCategory = () => {
  const dispatch = useDispatch();
  const { categoryInfo, categoriesTag, isCategoryFetching, isFetching } = useSelector(topicState);

  const {
    level1,
    level2,
    level3,
    tagList,
    openModal,
    checkCategories,
    setSearchText,
    setOpenModal,
    setCheckCategories,
    setTagList,
  } = useManageTagContext();

  const [canEdit, setCanEdit] = useState<boolean>(false);

  /*
    1 先取得 categoryInfo 的 category、tag
    2 用 salesCategories 去比對 category
    3 用 categoriesTag 去比對 tag
    4 組出字串
  */
  const currentCategoryTag = useMemo(() => {
    const resultList: string[] = [];
    const tagMap = new Map<number, string>();

    categoriesTag.forEach((tag) => {
      tagMap.set(tag.id, tag.name);
    });

    if (categoryInfo) {
      categoryInfo.tags.forEach((tag) => {
        const tagInfo = tagMap.get(tag);

        if (tagInfo) {
          resultList.push(tagInfo);
        }
      });

      const breadcrumb = getCategoryBreadcrumb(level1, level2, level3, categoryInfo.categories);
      const categoriesText = checkCategories.length === level1.size ? "全選" : breadcrumb;

      return `${categoriesText} 且標籤包含 ${resultList.toString()}`;
    }

    return "";
  }, [categoriesTag, categoryInfo, level1, level2, level3, checkCategories.length]);

  const haveCategoryTag = useMemo(
    () => categoryInfo && (categoryInfo.categories.length > 0 || categoryInfo.tags.length > 0),
    [categoryInfo],
  );

  const removeCategoryTags = () => {
    if (categoryInfo) {
      dispatch(fetchCategoryRemoveTag(categoryInfo.id));

      // clear local
      const cloneMap = new Map(tagList);
      cloneMap.clear();
      setTagList(cloneMap);
      setCheckCategories([]);
    }
  };

  const showModalConfirm = () => {
    confirm({
      title: "你確定要刪除這筆資料？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        removeCategoryTags();
      },
      onCancel() {},
    });
  };

  const editModalHandler = () => {
    setOpenModal(!openModal);
  };

  useEffect(() => {
    if (categoryInfo) {
      setCanEdit(true);
    }
  }, [categoryInfo]);

  return (
    <Spin spinning={isCategoryFetching || isFetching}>
      <Wrapper>
        <SearchWrapper>
          {canEdit && !openModal && haveCategoryTag ? (
            currentCategoryTag
          ) : (
            <SearchInput
              placeholder="選擇絕對分類與標籤"
              onFocus={() => setOpenModal(true)}
              onChange={(e) => setSearchText(e.target.value)}
            />
          )}
          {haveCategoryTag && (
            <Row>
              <Button type="link" onClick={editModalHandler}>
                編輯
              </Button>
              <CloseCircleTwoTone twoToneColor="#1890FF" onClick={showModalConfirm} />
            </Row>
          )}
        </SearchWrapper>
      </Wrapper>
    </Spin>
  );
};

export default SearchCategory;
