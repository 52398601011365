import productApi, { ProductCategory } from "@api/productApi";
import staffApi, { FetchStaffListParam, StaffListResult } from "@api/staffApi";
import stockApi, {
  ProductDailyAggregationsConfig,
  ProductDailyAggregationsListResult,
  ShelfLifeListParam,
  ShelfLifeListResult,
} from "@api/stockApi";
import type { RootState } from "@redux/rootReducer";
import { message } from "antd";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  isFetchingList: boolean;
  shelfLifeListResult: ShelfLifeListResult;
  shelfLifeListParam: ShelfLifeListParam;
  productDailyAggregationsListResult: ProductDailyAggregationsListResult;
  productDailyAggregationsFilter: {
    sku?: string;
    nameq?: string;
    ownerId?: number;
    statsDateBefore: string | undefined;
    statsDateAfter: string | undefined;
    returnInboundOnly: number | undefined;
    productCategory: number | undefined;
  };
  staffListResult: StaffListResult;
  productCategory: ProductCategory;
}

export const initialState: IState = {
  isFetchingList: false,
  shelfLifeListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  shelfLifeListParam: {
    limit: 20,
    offset: 0,
    sku: "",
    nameQ: "",
    ownerId: -1,
    contractMode: -1,
    ordering: "",
    overCanSellExpiration: false,
    tagId: -1,
    vendorId: -1,
  },
  productDailyAggregationsListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  productDailyAggregationsFilter: {
    sku: "",
    nameq: "",
    ownerId: undefined,
    statsDateBefore: undefined,
    statsDateAfter: undefined,
    returnInboundOnly: undefined,
    productCategory: undefined,
  },
  staffListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  productCategory: {
    level1: {},
    level2: {},
    level3: {},
  },
};

export const fetchProductDailyAggregations = createAsyncThunk(
  "stock/fetchProductDailyAggregations",
  async (payload: ProductDailyAggregationsConfig, thunkApi) => {
    try {
      const response = await stockApi.fetchProductDailyAggregations(payload);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchStaffList = createAsyncThunk("stock/fetchStaffList", async (payload: FetchStaffListParam) => {
  const response = await staffApi.fetchStaffList(payload);
  return response;
});

export const fetchProductCategoryList = createAsyncThunk("stock/fetchProductCategoryList", async () => {
  const response = await productApi.fetchProductCategoryList();
  return response;
});

export const fetchShelfLifeList = createAsyncThunk<ShelfLifeListResult>(
  "stock/fetchShelfLifeList",
  async (_, thunkApi) => {
    const {
      stock: { shelfLifeListParam },
    } = thunkApi.getState() as RootState;
    const response = await stockApi.fetchShelfLifeListList(shelfLifeListParam);
    return response;
  },
);

export const exportShelfLifeListByCSV = createAsyncThunk("stock/exportShelfLifeListByCSV", async (_, thunkApi) => {
  try {
    const {
      stock: { shelfLifeListParam },
    } = thunkApi.getState() as RootState;
    const response = await stockApi.exportShelfLifeListByCSV(shelfLifeListParam);
    message.success(response);
  } catch (error: any) {
    thunkApi.rejectWithValue(error.message);
  }
});
export const exportProductDailyAggregationsByCSV = createAsyncThunk(
  "stock/exportProductDailyAggregationsByCSV",
  async (params: any, thunkApi) => {
    try {
      await stockApi.exportProductDailyAggregationsByCSV(params);
      message.success("匯出資料準備中，由於資料複雜請稍待十分鐘，成功後會寄信通知");
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    }
  },
);

const stockSlice = createSlice({
  name: "stock",
  initialState,
  reducers: {
    reset: () => initialState,
    updateShelfLifeListParam: (state, action: PayloadAction<ShelfLifeListParam>) => {
      state.shelfLifeListParam = action.payload;
    },
    clearShelfLifeListParam: (state) => {
      state.shelfLifeListParam = initialState.shelfLifeListParam;
    },
    setFilterOption: (state, action) => {
      state.productDailyAggregationsFilter = action.payload;
    },
    clearProductDailyAggregationsFilter: (state) => {
      state.productDailyAggregationsFilter = initialState.productDailyAggregationsFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShelfLifeList.pending, (state) => {
      state.isFetchingList = true;
    });
    builder.addCase(fetchShelfLifeList.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.shelfLifeListResult = action.payload;
    });
    builder.addCase(fetchProductDailyAggregations.pending, (state) => {
      state.isFetchingList = true;
    });
    builder.addCase(fetchProductDailyAggregations.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.productDailyAggregationsListResult = action.payload;
    });
    builder.addCase(fetchProductDailyAggregations.rejected, (state) => {
      state.isFetchingList = false;
    });
    builder.addCase(fetchStaffList.fulfilled, (state, action) => {
      state.staffListResult = action.payload;
    });
    builder.addCase(fetchProductCategoryList.fulfilled, (state, action) => {
      state.productCategory = action.payload;
    });
  },
});

export const {
  reset,
  updateShelfLifeListParam,
  clearShelfLifeListParam,
  setFilterOption,
  clearProductDailyAggregationsFilter,
} = stockSlice.actions;
export default stockSlice.reducer;
