import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import { fetchOptionList, fetchPlanList, SaleState } from "@redux/saleSlice";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import ChangePriceContent from "./ChangePriceContent";
import OptionContent from "./OptionContent";
import PlanContent from "./PlanContent";
import SaleContent from "./SaleContent";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;

const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

enum TabList {
  SALE_CONTENT = "SALE_CONTENT",
  OPTION_CONTENT = "OPTION_CONTENT",
  PLAN_CONTENT = "PLAN_CONTENT",
  CHANGE_PRICE_CONTENT = "CHANGE_PRICE_CONTENT",
}

export default function SaleInformation() {
  const dispatch = useDispatch();
  const { salePageDetail, salePlanListResult, optionListResult } = useSelector(SaleState);

  const { salesId } = useParams();

  const [activeKey, setActiveKey] = useState<string>(TabList.SALE_CONTENT);

  useEffect(() => {
    const parseSalesId = parseInt(salesId, 10);
    if (salesId && !Number.isNaN(parseSalesId)) {
      dispatch(fetchPlanList({ salesPage: parseSalesId, limit: 20, offset: 0 }));
    }
  }, [dispatch, salesId]);

  useEffect(() => {
    const parseSalesId = parseInt(salesId, 10);
    if (salePageDetail?.pageType === SalePageTypeValue.Any && salesId && !Number.isNaN(parseSalesId)) {
      dispatch(fetchOptionList({ salesPage: parseSalesId, limit: 20, offset: 0 }));
    }
  }, [dispatch, salesId, salePageDetail]);

  if (salePageDetail?.pageType === SalePageTypeValue.Any) {
    return (
      <Wrapper>
        <PageTitle title={`銷售頁 - ${salePageDetail?.name || "新增"}`} />
        <Scope>
          <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
            <Tabs.TabPane tab="銷售頁內容" key={TabList.SALE_CONTENT}>
              <SaleContent />
            </Tabs.TabPane>
            <Tabs.TabPane tab="選項內容" key={TabList.OPTION_CONTENT}>
              <OptionContent />
            </Tabs.TabPane>
            {optionListResult.count > 0 && (
              <Tabs.TabPane tab="方案內容" key={TabList.PLAN_CONTENT}>
                <PlanContent />
              </Tabs.TabPane>
            )}
            {salePlanListResult.results.length > 0 && optionListResult.count > 0 && (
              <Tabs.TabPane tab="變價" key={TabList.CHANGE_PRICE_CONTENT}>
                <ChangePriceContent />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Scope>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageTitle title={`銷售頁 - ${salePageDetail?.name || "新增"}`} />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="銷售頁內容" key={TabList.SALE_CONTENT}>
            <SaleContent />
          </Tabs.TabPane>
          {salePageDetail && (
            <Tabs.TabPane tab="方案內容" key={TabList.PLAN_CONTENT}>
              <PlanContent />
            </Tabs.TabPane>
          )}
          {salePlanListResult.results.length > 0 && (
            <Tabs.TabPane tab="變價" key={TabList.CHANGE_PRICE_CONTENT}>
              <ChangePriceContent />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Scope>
    </Wrapper>
  );
}
