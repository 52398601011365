import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const WarehouseCard: FC = () => {
  const isFetchingWarehouseDashboard = useSelector((state: RootState) => state.dashboard.isFetchingWarehouseDashboard);
  const warehouseDashboard = useSelector((state: RootState) => state.dashboard.warehouseDashboard);
  const { currentData, diff } = warehouseDashboard;
  const { sendErrRate = 0, damagedRate = 0 } = currentData || {};
  const { sendErrRateDiff = 0, damagedRateDiff = 0 } = diff || {};

  const sendErrRatePercentage = Math.round(sendErrRate * 1000) / 10;
  const sendErrRateTagColor = sendErrRateDiff < 0 ? "error" : "success";
  const sendErrRateTagIcon = sendErrRateDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const sendErrRateChangePercentage = Math.round(sendErrRateDiff * 1000) / 10; // 百分比取小數點第一位

  const damagedRatePercentage = Math.round(damagedRate * 1000) / 10;
  const damagedRateTagColor = damagedRateDiff < 0 ? "error" : "success";
  const damagedRateTagIcon = damagedRateDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const damagedRateChangePercentage = Math.round(damagedRateDiff * 1000) / 10; // 百分比取小數點第一位

  return (
    <Spin spinning={isFetchingWarehouseDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>倉庫</HeaderTitle>
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              寄錯率
              <Tooltip placement="bottom" title='退貨和補貨原因選"出貨錯誤"的數量 / 期間子訂單數'>
                <InfoIcon />
              </Tooltip>
              <AnchorToChart href="/dashboard/inaccurate-shipment" />
            </SectionTitle>
            <SectionContent>
              {`${sendErrRatePercentage}%`}
              <StyledTag color={sendErrRateTagColor} icon={sendErrRateTagIcon}>
                {`${sendErrRateChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <Section>
            <SectionTitle>
              破包率
              <Tooltip placement="bottom" title='退貨和補貨原因選"商品問題"的數量 / 期間子訂單數'>
                <InfoIcon />
              </Tooltip>
              <AnchorToChart href="/dashboard/shipment-damage" />
            </SectionTitle>
            <SectionContent>
              {`${damagedRatePercentage}%`}
              <StyledTag color={damagedRateTagColor} icon={damagedRateTagIcon}>
                {`${damagedRateChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default WarehouseCard;
