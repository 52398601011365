import { ChargeItem } from "@api/financeApi";
import PopupBackground from "@component/PopupBackground";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 500px;
  background-color: white;
  border-radius: 2px;
`;
const TableContainer = styled.div`
  border: 1px solid #f0f0f0;
  margin-bottom: 15px;
  width: 100%;
`;

interface Props {
  onClose: () => void;
  dataset: ChargeItem[];
}

const ChargeModal: FC<Props> = (props) => {
  const { dataset, onClose } = props;

  const tableColumns: ColumnsType<ChargeItem> = [
    { key: "title", dataIndex: "title", title: "扣費項目", width: "70%" },
    { key: "fee", dataIndex: "fee", title: "分攤金額", width: "30%", render: (value) => value.toFixed(2) },
  ];
  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <TableContainer>
          <Table
            dataSource={dataset}
            columns={tableColumns}
            scroll={{ x: "max-content", y: 250 }}
            tableLayout="auto"
            pagination={false}
            rowKey="title"
          />
        </TableContainer>
        <Button onClick={onClose}>關閉</Button>
      </Wrapper>
    </PopupBackground>
  );
};
export default ChargeModal;
