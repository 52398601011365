import React from "react";
import { getCurrencySelectItems } from "@constant/CurrencyValue";
import { Select } from "antd";

/**
 * value is passed from Ant Design Form
 * @see https://ant.design/components/form/#components-form-demo-customized-form-controls
 * @param props.value - 外層 Form 給的預設值
 */
export default function CurrencySelect(props: { value?: any; onChange?: (params: any) => void }) {
  const { value, onChange } = props;

  const triggerChange = (selectedId: number) => {
    if (onChange) {
      onChange(selectedId);
    }
  };

  return <Select value={value} onChange={triggerChange} options={getCurrencySelectItems()} />;
}
