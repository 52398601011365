/* eslint-disable camelcase */

export interface BrandListResult {
  count: number;
  next: string;
  previous: string;
  results: Brand[];
}
export interface Brand {
  id: number;
  name: string;
  icon: string | null;
  isActive: boolean;
  productQty: number;
}

interface NormalizeBrand {
  brandList: (
    list: {
      id: number;
      name: string;
      icon: string | null;
      is_active: boolean;
      product_qty: number;
    }[],
  ) => Brand[];
}

const normalizeBrand: NormalizeBrand = {
  brandList: (list) => {
    return list.map((item) => ({
      id: item.id,
      name: item.name,
      icon: item.icon,
      isActive: item.is_active,
      productQty: item.product_qty,
    }));
  },
};

export default normalizeBrand;
