import PopupBackground from "@component/PopupBackground";
import { Button } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import React, { useEffect } from "react";
import styled from "styled-components";
import { fetchInvoiceListBySubOrder, invoiceState } from "@redux/finance/invoiceSlice";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  onClose: () => void;
  subOrderId: number;
};

const Wrapper = styled.div`
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
`;
const TableWrapper = styled.div`
  margin: 20px;
  width: 1000px;
`;
const TopButton = styled(Button)`
  align-self: flex-end;
`;

export default function OrderPopup(props: Props) {
  const { onClose, subOrderId } = props;
  const dispatch = useDispatch();
  const { subOrderInvoiceList, isFetchingSubOrder } = useSelector(invoiceState);

  const columns: ColumnsType<any> = [
    {
      key: "salesPageName",
      dataIndex: "salesPageName",
      title: "銷售頁名稱",
    },
    {
      key: "salesPlanName",
      dataIndex: "salesPlanName",
      title: "方案名稱",
    },
    {
      key: "productId",
      dataIndex: "productId",
      title: "商品ID",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "國際條碼",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "contractType",
      dataIndex: "contractType",
      title: "合作方式",
    },
    {
      key: "qty",
      dataIndex: "qty",
      title: "購買數量",
    },
    {
      key: "totalAmt",
      dataIndex: "totalAmt",
      title: "總額",
    },
    {
      key: "salesRuleDiscount",
      dataIndex: "salesRuleDiscount",
      title: "優惠券折抵",
    },
    {
      key: "bkDiscount",
      dataIndex: "bkDiscount",
      title: "黑卡折抵",
    },
    {
      key: "commission",
      dataIndex: "commission",
      title: "抽成",
    },
    {
      key: "platformFee",
      dataIndex: "platformFee",
      title: "平台維護費",
    },
    {
      key: "paymentFee",
      dataIndex: "paymentFee",
      title: "金流處理費",
    },
    {
      key: "marketingFee",
      dataIndex: "marketingFee",
      title: "行銷推廣費",
    },
    {
      key: "sponsorFee",
      dataIndex: "sponsorFee",
      title: "活動贊助費",
    },
    {
      key: "shippingFee",
      dataIndex: "shippingFee",
      title: "運費攤提",
    },
    {
      key: "processFee",
      dataIndex: "processFee",
      title: "訂單處理費",
    },
  ];

  useEffect(() => {
    dispatch(fetchInvoiceListBySubOrder(subOrderId));
  }, [dispatch, subOrderId]);

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <TableWrapper>
          <Table
            scroll={{ x: "max-content" }}
            loading={isFetchingSubOrder}
            columns={columns}
            dataSource={subOrderInvoiceList}
            rowKey="sku"
            tableLayout="auto"
            pagination={false}
          />
        </TableWrapper>
        <TopButton onClick={onClose}>關閉</TopButton>
      </Wrapper>
    </PopupBackground>
  );
}
