import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cooperationApi, {
  CooperationList,
  CooperationResult,
  UpdateCooperation,
  CooperationFilter,
} from "@api/cooperationApi";
import { message } from "antd";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  cooperationList: CooperationList;
  cooperationFilter: CooperationFilter;
}

export const initialState: IState = {
  isFetching: false,
  cooperationList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  cooperationFilter: { limit: 20, offset: 0 },
};

export const fetchCooperationList = createAsyncThunk(
  "cooperation/fetchCooperationList",
  async (params?: CooperationFilter) => {
    const response = await cooperationApi.fetchCooperationList(params);
    return response;
  },
);

export const fetchCreateCooperation = createAsyncThunk(
  "cooperation/fetchCreateCooperation",
  async (params: CooperationResult, thunkApi) => {
    const response = await cooperationApi.fetchCreateCooperation(params);
    message.success("新增成功");
    thunkApi.dispatch(fetchCooperationList());
    return response;
  },
);

export const fetchUpdateCooperation = createAsyncThunk(
  "cooperation/fetchUpdateCooperation",
  async (params: UpdateCooperation, thunkApi) => {
    const response = await cooperationApi.fetchUpdateCooperation(params);
    message.success("更新成功");
    thunkApi.dispatch(fetchCooperationList());
    return response;
  },
);

export const fetchSendInvoice = createAsyncThunk("cooperation/fetchSendInvoice", async (id: number, thunkApi) => {
  try {
    await cooperationApi.fetchSendInvoice(id);
    message.success("成功開立發票");
    thunkApi.dispatch(fetchCooperationList());
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchDeleteInvoice = createAsyncThunk("cooperation/fetchDeleteInvoice", async (id: number, thunkApi) => {
  const response = await cooperationApi.fetchDeleteInvoice(id);
  message.success("已刪除");
  thunkApi.dispatch(fetchCooperationList());
  return response;
});

const cooperationSlice = createSlice({
  name: "cooperation",
  initialState,
  reducers: {
    reset: () => initialState,
    setCooperationFilter: (state, action) => {
      state.cooperationFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCooperationList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCooperationList.fulfilled, (state, action) => {
      state.cooperationList = action.payload;
      state.isFetching = false;
    });
  },
});

export const cooperationState = (state: RootState) => state.cooperationSlice;
export const { setCooperationFilter } = cooperationSlice.actions;
export default cooperationSlice.reducer;
