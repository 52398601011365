import React, { FC } from "react";
import styled from "styled-components";
import { Tooltip, Spin } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { ShipmentProgressOrderType } from "@api/dashboardApi";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 380px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const HeaderAnnotation = styled.div`
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 10px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.a`
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  text-decoration-line: underline;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const ShippingStatusCard: FC = () => {
  const isFetchingShipmentProgress = useSelector((state: RootState) => state.dashboard.isFetchingShipmentProgress);
  const shipmentProgress = useSelector((state: RootState) => state.dashboard.shipmentProgress);
  const { transferShippingDelay, wareshouseShippingDelay, warehouseShipmentDelay } = shipmentProgress;

  return (
    <Spin spinning={isFetchingShipmentProgress}>
      <Wrapper>
        <Header>
          <HeaderTitle>出貨進度</HeaderTitle>
          <HeaderAnnotation>此數值不售時間篩選影響</HeaderAnnotation>
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              倉庫配送延遲子訂單數
              <Tooltip placement="bottom" title="配送三日未配達 子訂單數">
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent
              href={`/dashboard/shipment-progress-order/${ShipmentProgressOrderType.WARESHOUSE_SHIPPING_DELAY}`}
              target="_blank"
            >
              {wareshouseShippingDelay}
            </SectionContent>
          </Section>
          <BottomSection>
            <SectionTitle>
              倉庫出貨延遲訂單數
              <Tooltip placement="bottom" title="訂單成立七天後未出貨">
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent
              href={`/dashboard/shipment-progress-order/${ShipmentProgressOrderType.WAREHOUSE_SHIPMENT_DELAY}`}
              target="_blank"
            >
              {warehouseShipmentDelay}
            </SectionContent>
          </BottomSection>
          <BottomSection>
            <SectionTitle>
              轉單出貨延遲子訂單數
              <Tooltip placement="bottom" title="下訂後三日未出貨">
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent
              href={`/dashboard/shipment-progress-order/${ShipmentProgressOrderType.TRANSFER_SHIPPING_DELAY}`}
              target="_blank"
            >
              {transferShippingDelay}
            </SectionContent>
          </BottomSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default ShippingStatusCard;
