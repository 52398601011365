import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import RevenueChart from "./revenue/Chart";
import CategorySalesChart from "./categorySales/Chart";
import OrderPriceChart from "./orderPrice/Chart";
import OrderCountChart from "./orderCount/Chart";
import OrderStatusChart from "./orderStatus/Chart";
import ReturnProductChart from "./returnProduct/Chart";
import InaccurateShipmentChart from "./warehouse/InaccurateShipmentChart";
import ShipmentDamageChart from "./warehouse/ShipmentDamageChart";
import ShipmentProgressOrderList from "./shippingStatus/OrderList";
import NewMemberPurchaseRatioChart from "./newMember/NewMemberPurchaseRatioChart";
import NewMemberCountChart from "./newMember/NewMemberCountChart";
import NewMemberFirstPurchaseChart from "./newMember/FirstPurchaseCountChart";
import MemberRevenueChart from "./newMemberRevenue/MemberRevenueChart";
import VipMemberStatusChart from "./vipStatus/Chart";
import GrossMarginChart from "./grossMargin/Chart";
import VipFinanceChart from "./vipPerformance/Chart";

const DashboardRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/revenue" element={<RevenueChart />} />
      <Route path="/order-price" element={<OrderPriceChart />} />
      <Route path="/order-count" element={<OrderCountChart />} />
      <Route path="/gross-margin" element={<GrossMarginChart />} />
      <Route path="/category-sales" element={<CategorySalesChart />} />
      <Route path="/order-status" element={<OrderStatusChart />} />
      <Route path="/return-product" element={<ReturnProductChart />} />
      <Route path="/inaccurate-shipment" element={<InaccurateShipmentChart />} />
      <Route path="/shipment-damage" element={<ShipmentDamageChart />} />
      <Route path="/shipment-progress-order/:orderType" element={<ShipmentProgressOrderList />} />
      <Route path="/new-member-purchase-ratio" element={<NewMemberPurchaseRatioChart />} />
      <Route path="/new-member-count" element={<NewMemberCountChart />} />
      <Route path="/first-purchase-count" element={<NewMemberFirstPurchaseChart />} />
      <Route path="/member-revenue" element={<MemberRevenueChart />} />
      <Route path="/vip-status" element={<VipMemberStatusChart />} />
      <Route path="/vip-performance" element={<VipFinanceChart />} />
    </Routes>
  );
};

export default DashboardRouter;
