import React, { FC, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { Button, Input, Select, Checkbox } from "antd";
import { SelectValue } from "antd/lib/select";
import PopupBackground from "@component/PopupBackground";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { FetchRuleListParams } from "@api/ruleApi";
import {
  reset,
  fetchRuleList,
  loadMoreRuleList,
  updateRuleListParams,
  updateSelectedRuleIds,
  batchAddRules,
} from "@redux/promotionAddRuleSlice";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 605px;
  height: 305px;
  padding: 20px 28px 20px 37px;
  background-color: white;
`;
const SearchBar = styled.div`
  margin-bottom: 10px;
`;
const StyledSelect = styled(Select)`
  width: 104px;
`;
const ListContainer = styled.div`
  flex: auto;
  overflow: auto;
  min-height: 0;
  margin-bottom: 10px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const RuleRow = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid #f2f2f2;
  padding: 0 10px;
`;
const RuleId = styled.div`
  flex: 0 0 10%;
  min-width: 0;
  margin-left: 10px;
`;
const RuleTitle = styled.div`
  flex: auto;
  min-width: 0;
  margin-left: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface Props {
  onClose: () => void;
}

enum SearchOptionTypeValue {
  ID,
  NAME,
}

const searchOptions = [
  {
    label: "優惠ID",
    value: SearchOptionTypeValue.ID,
  },
  {
    label: "優惠名稱",
    value: SearchOptionTypeValue.NAME,
  },
];

const AddRule: FC<Props> = (props) => {
  const { onClose } = props;

  const dispatch = useAppDispatch();
  const { ruleListResult, ruleListParams, selectedRuleIds } = useSelector((state: RootState) => state.promotionAddRule);
  const {
    promotionDetail: { id: promotionId },
  } = useSelector((state: RootState) => state.promotion);
  const { next, results: ruleList } = ruleListResult;

  const [searchType, setSearchType] = useState<SearchOptionTypeValue>(SearchOptionTypeValue.ID);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleOnSearchTypeSelect = (value: SelectValue) => {
    setSearchType(value as SearchOptionTypeValue);
  };

  const handleOnInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const handleOnCheck = (id: number) => () => {
    let newSelectedRuleIds = [...selectedRuleIds];
    const isPreviouslyChecked = selectedRuleIds.includes(id);
    if (isPreviouslyChecked) newSelectedRuleIds = newSelectedRuleIds.filter((ruleId) => ruleId !== id);
    else newSelectedRuleIds.push(id);

    dispatch(updateSelectedRuleIds(newSelectedRuleIds));
  };

  const handleOnLoadMoreRules = () => dispatch(loadMoreRuleList());

  const renderRuleRows = () => {
    const results = ruleList.map((ruleItem) => {
      const { id, title } = ruleItem;
      const isChecked = selectedRuleIds.includes(id);

      return (
        <RuleRow key={id}>
          <Checkbox checked={isChecked} onClick={handleOnCheck(id)} />
          <RuleId>{id}</RuleId>
          <RuleTitle>{title}</RuleTitle>
        </RuleRow>
      );
    });

    if (next) {
      results.push(<InfiniteScrollObserver callback={handleOnLoadMoreRules} />);
    }

    return results;
  };

  const handleOnFilterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const params: FetchRuleListParams = {
      limit: ruleListParams.limit,
      offset: 0,
      excludePromotionId: promotionId,
    };
    switch (searchType) {
      case SearchOptionTypeValue.ID:
        params.id = inputValue ? Number(inputValue) : undefined;
        params.titleQ = undefined;
        break;
      case SearchOptionTypeValue.NAME:
        params.id = undefined;
        params.titleQ = inputValue;
        break;
      default:
    }
    dispatch(updateRuleListParams(params));
    dispatch(fetchRuleList());
  };

  const handleOnSubmit = () => {
    dispatch(batchAddRules());
    onClose();
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <SearchBar>
          <Input.Group compact>
            <StyledSelect
              value={searchType}
              options={searchOptions}
              onChange={(value) => handleOnSearchTypeSelect(value as SelectValue)}
            />
            <Input
              style={{ width: "60%" }}
              value={inputValue}
              onChange={handleOnInputChange}
              onPressEnter={handleOnFilterSubmit}
            />
          </Input.Group>
        </SearchBar>
        <ListContainer>{renderRuleRows()}</ListContainer>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" onClick={handleOnSubmit} disabled={selectedRuleIds.length === 0}>
            確定
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddRule;
