import PopupBackground from "@component/PopupBackground";
import { Button, Input, Row } from "antd";
import React, { useState } from "react";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 620px;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomRow = styled(Row)<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || "30px"};
`;

const Text = styled.div`
  width: 100px;
  margin-right: 20px;
  ${fontStyle("14px", "30px")};
`;

type Props = {
  close: () => void;
  onAddOption: (spec: { label: string; unit: string }) => void;
};

export default function SpecificationModal(props: Props) {
  const { close, onAddOption } = props;

  const [label, setLabel] = useState<string>("");
  const [unit, setUnit] = useState<string>("");

  const onHandleAddOption = () => {
    onAddOption({
      label,
      unit,
    });
    close();
  };

  return (
    <PopupBackground close={() => {}} fixed>
      <Wrapper>
        <CustomRow wrap={false} marginTop="0px">
          <Text>新增選項</Text>
          <Input onChange={(e) => setLabel(e.target.value)} />
        </CustomRow>
        <CustomRow wrap={false} marginTop="12px">
          <Text>選項單位</Text>
          <Input onChange={(e) => setUnit(e.target.value)} />
        </CustomRow>
        <CustomRow justify="end">
          <CustomButton htmlType="button" onClick={close}>
            取消
          </CustomButton>
          <Button type="primary" onClick={onHandleAddOption}>
            確定
          </Button>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
