import React from "react";
import styled from "styled-components";

const Background = styled.div`
  background: ${({ theme }) => theme.colorNeutral700};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
`;

const Image = styled.img`
  width: 100%;
  max-width: 900px;
  object-fit: contain;
`;

type Props = {
  src: string;
  close: () => void;
};

export default function BigImageModal(props: Props) {
  const { src, close } = props;

  return (
    <Background onClick={close}>
      <Image src={src} />
    </Background>
  );
}
