/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect, useMemo, ChangeEvent } from "react";
import { Select, Input, DatePicker, Button } from "antd";
import styled from "styled-components";
import { SelectValue } from "antd/lib/select";
import { RangeValue } from "rc-picker/lib/interface";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { clearMarketStatisticFilter, fetchMarketStatistic, updateMarketStatisticParam } from "@redux/salesReportSlice";
import moment, { Moment } from "moment";
import { FetchMarketStatisticParams } from "@api/marketStatisticApi";

const Wrapper = styled.form`
  position: relative;
  margin-bottom: 54px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const Section = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  font-size: 14px;
`;
const Title = styled.div`
  width: 100px;
  margin-right: 15px;
`;
const StyledSelect = styled(Select)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const StyledInput = styled(Input)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 380px;
`;

const ButtonContainer = styled(Row)`
  justify-content: flex-end;
`;
const ApplyButton = styled(Button)`
  margin-left: 10px;
`;

enum LocationType {
  TOPIC = 1,
  PROMOTION = 2,
  TPIC_CATEGORY = 3,
  CATEGORY = 4,
  TAG = 5,
}

const getTagType = (lastLocationType: number | undefined) => {
  switch (lastLocationType) {
    case LocationType.PROMOTION:
      return 6;
    case LocationType.TAG:
      return 5;
    default:
      return undefined;
  }
};

interface LocalFilter {
  lastLocationIds?: number;
  _lastLocationType?: number;
  date?: [Moment | null, Moment | null];
}

const initialFilter: LocalFilter = {
  date: [null, null],
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const { marketStatisticListParams } = useSelector((state: RootState) => state.salesReport);

  const [localFilter, setLocalFilter] = useState<LocalFilter>({});
  const {
    lastLocationIds,

    _lastLocationType,

    date,
  } = localFilter;

  useEffect(() => {
    setLocalFilter({
      lastLocationIds: marketStatisticListParams.lastLocationIds,
      _lastLocationType: marketStatisticListParams.tagType === 6 ? 2 : marketStatisticListParams.lastLocationType,

      date: [
        marketStatisticListParams.startDate ? moment(marketStatisticListParams.startDate) : null,
        marketStatisticListParams.endDate ? moment(marketStatisticListParams.endDate) : null,
      ],
    });
  }, [marketStatisticListParams]);

  const handleOnChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnDateChange = (title: string) => (value: RangeValue<Moment>) => {
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnClear = () => {
    dispatch(clearMarketStatisticFilter());
    setLocalFilter(initialFilter);
  };

  const handleOnApply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const lastLocationType = _lastLocationType === LocationType.PROMOTION ? 5 : _lastLocationType;

    const filterParams: FetchMarketStatisticParams = {
      limit: 20,
      offset: 0,
      lastLocationIds,
      startDate: date && date[0] ? moment(date[0]).format("YYYY-MM-DD") : undefined,
      endDate: date && date[1] ? moment(date[1]).format("YYYY-MM-DD") : undefined,
      lastLocationType,
      tagType: getTagType(_lastLocationType),
    };
    dispatch(updateMarketStatisticParam(filterParams));
    dispatch(fetchMarketStatistic());
  };

  return (
    <Wrapper onSubmit={handleOnApply}>
      <Row>
        <Section marginRight={30}>
          <Title>銷售時間</Title>
          <RangePicker value={date} onChange={handleOnDateChange("date")} />
        </Section>
        <Section marginRight={30}>
          <Title>列表頁種類</Title>
          <StyledSelect
            width={150}
            value={_lastLocationType}
            onChange={(value) => handleOnSelect("_lastLocationType")(value as SelectValue)}
          >
            <Select.Option value={1}>主題</Select.Option>
            <Select.Option value={2}>活動</Select.Option>
            <Select.Option value={3}>主題分類</Select.Option>
            <Select.Option value={4}>分類</Select.Option>
            <Select.Option value={5}>品牌</Select.Option>
            <Select.Option value={6}>銷售頁</Select.Option>
          </StyledSelect>
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>輸入ID</Title>
          <StyledInput width={150} value={lastLocationIds} onChange={handleOnChange("lastLocationIds")} />
        </Section>
      </Row>
      <ButtonContainer>
        <Button onClick={handleOnClear}>清除篩選條件</Button>
        <ApplyButton type="primary" htmlType="submit" disabled={!_lastLocationType}>
          套用
        </ApplyButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
