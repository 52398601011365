/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { ColumnsType } from "antd/lib/table";
import { Button, Table, Modal, Form, Input, InputNumber } from "antd";
import {
  addProductManufacturer,
  deleteProductManufacturer,
  fetchDeleteSupplyChainInfo,
  fetchProductManufacturerList,
  updateProductManufacturer,
} from "@redux/brandOperation/productSlice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ProductManufacturerDetail, ProductSupplyChainListResult } from "@api/brandOperationApi";
import { FormInstance } from "antd/lib/form";
import manufacturerApi from "@api/brandOperation/manufacturerApi";
import SearchSelect from "../../../component/Common/SearchSelect";

const Wrapper = styled.div`
  padding-top: 20px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 0;
  top: -10;
`;

const TopButton = styled(Button)`
  margin-left: auto;
  margin-bottom: 10px;
  align-self: flex-end;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 40px;
`;

type Props = {
  productId: string;
};

const ProductManufacturer = (props: Props) => {
  const { productId } = props;
  const dispatch = useAppDispatch();
  const { manufacturerResult, isFetching } = useSelector((state: RootState) => state.brandOperationProductSlice);

  const [openPopup, setOpenPopup] = useState(false);
  const [supplyChainInfoId, setSupplyChainInfoId] = useState(0);
  const [mode, setMode] = useState<"edit" | "add">("add");
  const [form] = Form.useForm();

  const { results: manufacturerList } = manufacturerResult;

  useEffect(() => {
    dispatch(fetchProductManufacturerList(productId));
  }, [dispatch, productId]);

  const tableColumns: ColumnsType<ProductManufacturerDetail> = [
    {
      title: "製造商名稱",
      key: "producerName",
      dataIndex: "producerName",
    },
    {
      title: "單價",
      key: "quotedPrice",
      dataIndex: "quotedPrice",
      width: 150,
    },
    {
      title: "備貨時間",
      key: "leadTime",
      dataIndex: "leadTime",
    },
    {
      title: "箱入數",
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      width: 50,
      render: (_, values) => (
        <Row>
          <Button type="link" onClick={() => handleOnEdit(values)}>
            編輯
          </Button>
        </Row>
      ),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      fixed: "right",
      width: 50,
      render: (_, values) => (
        <Row>
          <Button type="link" onClick={() => handleOnDelete(values)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  const handleOnEdit = (values: ProductManufacturerDetail) => {
    if (!values.producerId) return;
    form.setFieldsValue(values);
    setOpenPopup(!openPopup);
    setMode("edit");
  };

  const handleOnDelete = (values: ProductManufacturerDetail) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      content: `${values.producerName} ( 單價： ${values.quotedPrice} )( 備貨時間： ${values.leadTime} )( 箱入數：${values.pcsPerCarton})`,
      okText: "是",
      cancelText: "否",
      onOk() {
        if (values.producerId) {
          dispatch(
            deleteProductManufacturer({
              productId,
              id: values.id!,
            }),
          );
        }
      },
    });
  };

  const handleOnAdd = () => {
    setOpenPopup(!openPopup);
    setMode("add");
  };

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    if (mode === "add") {
      dispatch(addProductManufacturer({ ...formValues, productId }));
    }
    if (mode === "edit") {
      dispatch(updateProductManufacturer({ ...formValues, productId }));
    }
    form.resetFields();
  };

  return (
    <Wrapper>
      <Header>
        <TopButton type="primary" onClick={handleOnAdd}>
          新增製造商資訊
        </TopButton>
      </Header>

      <TableContainer>
        <Table
          dataSource={manufacturerList}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          tableLayout="auto"
          pagination={false}
          loading={isFetching}
          rowKey="id"
        />
      </TableContainer>
      {openPopup && (
        <ProductManufacturerEdit
          form={form}
          mode={mode}
          onClose={() => setOpenPopup(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </Wrapper>
  );
};

export default ProductManufacturer;

type ModalProps = {
  mode: string;
  onClose: () => void;
  onSubmit: () => void;
  form: FormInstance;
};

const ProductManufacturerEdit = (props: ModalProps) => {
  const { onClose, onSubmit, mode, form } = props;

  const handleOnClose = () => {
    onClose();
  };
  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <Modal
      visible={!!mode}
      width="400px"
      onCancel={handleOnClose}
      okText="確定"
      cancelText="取消"
      onOk={() => {
        form.submit();
      }}
    >
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 6 }} form={form} onFinish={handleSubmit}>
          <Form.Item label="ID" name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item name="producerId" label="製造商名稱" rules={[{ required: true, message: "請填入 製造商名稱" }]}>
            <SearchSelect
              fetchListApi={manufacturerApi.fetchManufacturerList}
              optionsKey={{ label: "name", value: "id" }}
              defaultValue={form.getFieldValue("producerId")}
              onChange={(value) => {
                form.setFieldsValue({ producerId: value });
              }}
            />
          </Form.Item>
          <Form.Item name="quotedPrice" label="單價" rules={[{ required: true, message: "請填入 單價" }]}>
            <InputNumber style={{ width: "100%" }} min={0} precision={4} step={0.1} />
          </Form.Item>
          <Form.Item name="leadTime" label="備貨時間" rules={[{ required: true, message: "請填入 備貨時間" }]}>
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
          <Form.Item name="pcsPerCarton" label="箱入數" rules={[{ required: true, message: "請填入 箱入數" }]}>
            <InputNumber style={{ width: "100%" }} min={0} />
          </Form.Item>
        </Form>
      </Wrapper>
    </Modal>
  );
};
