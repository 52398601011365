import PageTitle from "@component/PageTitle";
import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import { canExecution, VendorApproveStatus, vendorApproveStatusMap } from "@constant/vendor";
import type { RootState } from "@redux/rootReducer";
import { fetchReportRegenDetail, reviewReportRegen } from "@redux/vendorSlice";
import { Button, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditModal from "./EditModal";

const ContentWrapper = styled.div`
  padding: 27px 35px;
`;

const Text = styled.p`
  width: 133px;
  margin-bottom: 33px;
  ${fontStyle("14px", "16px")};
`;

const Content = styled(Text)`
  width: max-content;
`;

export default function Detail() {
  const { reportRegenId } = useParams();
  const dispatch = useAppDispatch();
  const { reportRegenDetail } = useSelector((state: RootState) => state.vendorSlice);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchReportRegenDetail(Number(reportRegenId)));
  }, [dispatch, reportRegenId]);

  const canAction =
    [VendorApproveStatus.REJECT, VendorApproveStatus.PASS, VendorApproveStatus.DELETE].indexOf(
      reportRegenDetail.approvalStatus,
    ) > -1 || !canExecution;

  const approveStatusString = () => {
    return `${vendorApproveStatusMap[reportRegenDetail.approvalStatus]} ${
      canAction ? reportRegenDetail.updatedAt : ""
    }`;
  };

  const onDuplicateClick = () => {
    setShowModal(true);
    setIsEdit(false);
  };

  return (
    <ContentWrapper>
      <PageTitle title="重跑詳情" />
      <Row justify="end">
        <Space>
          <Button
            type="default"
            disabled={canAction}
            onClick={() => {
              dispatch(reviewReportRegen(false));
            }}
          >
            審核不通過
          </Button>
          <Button
            type="default"
            disabled={canAction}
            onClick={() => {
              dispatch(reviewReportRegen(true));
            }}
          >
            審核通過
          </Button>
          <Button type="default" onClick={onDuplicateClick} disabled={!canExecution}>
            複製
          </Button>
          <Button
            type="primary"
            disabled={reportRegenDetail.approvalStatus !== VendorApproveStatus.PENDING || !canExecution}
            onClick={() => {
              setShowModal(true);
              setIsEdit(true);
            }}
          >
            編輯
          </Button>
        </Space>
      </Row>

      <Row align="middle">
        <Text>申請狀態</Text>
        <Content>{approveStatusString()}</Content>
      </Row>
      <Row align="middle">
        <Text />

        <table className="review-table" style={{ marginBottom: 33 }}>
          {/* 職位名稱 */}
          <tbody>
            <tr>
              <th>課長</th>
              <th>財務</th>
            </tr>
            <tr>
              <td>{ReviewStateByPositionLabel[reportRegenDetail.sectionManagerApproval]}</td>
              <td>{ReviewStateByPositionLabel[reportRegenDetail.financeApproval]}</td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row align="middle">
        <Text>申請人</Text>
        <Content>{reportRegenDetail.staff?.name}</Content>
      </Row>

      <Row align="middle">
        <Text>SKUID</Text>
        <Content>{reportRegenDetail.sku}</Content>
      </Row>
      <Row align="middle">
        <Text>商品方案ID</Text>
        <Content>{reportRegenDetail.salesPlanId}</Content>
      </Row>
      <Row align="middle">
        <Text>抽成調整後比例</Text>
        <Content>{`${reportRegenDetail.adjustedCommissionRate}%`}</Content>
      </Row>
      <Row align="middle">
        <Text>金額差</Text>
        <Content>{reportRegenDetail.amountDiff}</Content>
      </Row>
      <Row align="middle">
        <Text>影響訂單時間區間</Text>
        <Content>{`${reportRegenDetail.affectedStartDate} ~ ${reportRegenDetail.affectedEndDate}`}</Content>
      </Row>
      <Row align="middle">
        <Text>申請時間</Text>
        <Content>{reportRegenDetail.createdAt}</Content>
      </Row>
      <EditModal
        isModalVisible={showModal}
        toogleModal={() => setShowModal((prev) => !prev)}
        current={reportRegenDetail}
        isEdit={isEdit}
      />
    </ContentWrapper>
  );
}
