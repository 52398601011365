import ImportFileModal from "@component/ImportFileModal";
import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { IWAppProductItem, ModifyStatus } from "@api/brandOperationApi";
import useDebounce from "@hooks/useDebounce";
import {
  IWBrandState,
  fetchIWAppProductList,
  fetchBrandVpc,
  clearBrandVpc,
  addIWapplicationProduct,
  editIWapplicationProduct,
  setImportFilePopup,
  importIWappsProductsXLS,
  deleteIWappsProducts,
  uploadWMSystem,
  clearCsvErrorReason,
  updateProductListParams,
} from "@redux/brandOperation/intoWarehouseBrandSlice";
import { Button, Table, Form, Modal, message, DatePicker, Input, Select, InputNumber, Row } from "antd";
import { FormInstance } from "antd/lib/form";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Header, FlexBox } from "src/styles/common";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import moment from "moment";
import { storageTypeOptions } from "@constant/StorageType";
import { SelectValue } from "antd/lib/select";
import { PurchaseStatus } from "@api/utils/normalizeWarehouse";

const Remarks = styled.span<{ emphasize?: boolean }>`
  position: absolute;
  left: 32px;
  bottom: 20px;
  color: ${({ emphasize, theme }) => (emphasize ? "#EC6922" : theme.colorNeutral600)};
  ${fontStyle("14px", "16px")};
`;
const PageText = styled.span`
  ${fontStyle("14px", "32px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const ProductTab = (props: { canDeploy: boolean; status: number }) => {
  const { canDeploy, status } = props;
  const dispatch = useDispatch();
  const { isFetching, productList, showImportFilePopup, csvErrorReason, productListParams } = useSelector(IWBrandState);
  const [showAddModel, setShowAddModel] = useState(false);
  const [editRecord, setEditRecord] = useState<IWAppProductItem | undefined>();
  const [pageSize, setPageSize] = useState<number>(20);
  const [page, setPage] = useState<number>(1);

  const { limit, offset } = productListParams;
  const { id: strAppId } = useParams();
  const applicationId = Number(strAppId);
  const [modal] = Modal.useModal();
  const [form] = Form.useForm();

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      updateProductListParams({
        limit: pageSize,
        offset: pageSize * (value - 1),
      }),
    );
  };

  const fetchPageSizeChange = (value: SelectValue) => {
    setPageSize(parseInt(value as string, 10));
    setPage(1);
    dispatch(
      updateProductListParams({
        limit: parseInt(value as string, 10),
        offset: 0,
      }),
    );
  };

  const columns: ColumnsType<IWAppProductItem> = [
    {
      key: "no",
      dataIndex: "no",
      title: "商品品號",
      width: 100,
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "國際條碼",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "商品名稱",
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      align: "center",
      width: 140,
      render: (value) => storageTypeOptions.find((option) => option.key === value)?.name,
    },
    {
      key: "batch",
      dataIndex: "batch",
      title: "批號",
      align: "center",
      width: 80,
    },
    {
      key: "purchasePrice",
      dataIndex: "purchasePrice",
      title: "進價",
      align: "center",
      width: 80,
    },
    {
      key: "requestQty",
      dataIndex: "requestQty",
      title: "申請進貨數量(件)",
      width: 140,
      align: "center",
    },
    {
      key: "arrivedQty",
      dataIndex: "arrivedQty",
      title: "實際進貨總量(件)",
      width: 140,
      align: "center",
    },
    {
      key: "expiredDate",
      dataIndex: "expiredDate",
      title: "有效期限",
      align: "center",
      width: 120,
    },
    {
      key: "stockinDate",
      dataIndex: "stockinDate",
      title: "上架時間",
      align: "center",
      width: 120,
    },
    {
      key: "",
      dataIndex: "",
      render: (_, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => {
              setEditRecord(record);
              setShowAddModel(true);
            }}
            disabled={
              record.modifyStatus === ModifyStatus.NONE ||
              status === PurchaseStatus.DEPLOY_SUCCESS ||
              status === PurchaseStatus.FINISH
            }
          >
            編輯
          </Button>
          <Button
            type="link"
            onClick={() => onDeleteProduct(record)}
            disabled={record.modifyStatus === ModifyStatus.LIMIT || record.modifyStatus === ModifyStatus.NONE}
          >
            刪除
          </Button>
        </FlexBox>
      ),
    },
  ];

  const onDeleteProduct = (values: IWAppProductItem) => {
    Modal.confirm({
      title: "你確定要刪除這筆資料？",
      content: `商品品號: ${values.no}`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteIWappsProducts({ applicationId, productId: values.id }));
      },
    });
  };

  const handleAddProduct = () => {
    const values = form.getFieldsValue();
    if (editRecord) {
      dispatch(
        editIWapplicationProduct({
          id: editRecord.id,
          applicationId,
          ...values,
          name: undefined,
          expiredDate: values.expiredDate?.format("YYYY-MM-DD") || "",
        }),
      );
      setEditRecord(undefined);
    } else {
      dispatch(
        addIWapplicationProduct({
          applicationId,
          ...values,
          name: undefined,
          expiredDate: values.expiredDate?.format("YYYY-MM-DD") || "",
        }),
      );
    }
    form.resetFields();
    setShowAddModel(false);
  };
  const handleAddModalClose = () => {
    setShowAddModel(false);
    setEditRecord(undefined);
  };
  const handelAddXLS = (file: File) => {
    dispatch(importIWappsProductsXLS({ applicationId, file }));
  };
  const handelUploadWMS = () => {
    if (productList.count === 0) {
      message.error("請先新增商品");
      return;
    }
    dispatch(uploadWMSystem(applicationId));
  };

  useEffect(() => {
    dispatch(fetchIWAppProductList(applicationId));
  }, [dispatch, applicationId, limit, offset]);

  useEffect(() => {
    if (csvErrorReason.statusCode === 5007) {
      const content = () => {
        const result = Object.entries(csvErrorReason?.result);
        return (
          <>
            {result.map((r) => (
              <div key={r[0]}>{`${r[1]}`}</div>
            ))}
          </>
        );
      };
      Modal.error({
        title: "匯入失敗",
        content: <>{content()}</>,
        onOk: () => {
          dispatch(clearCsvErrorReason());
          dispatch(setImportFilePopup(false));
        },
        okText: "確認",
      });
    }
  }, [csvErrorReason, dispatch, modal]);

  return (
    <>
      <Header>
        <FlexBox>
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => setShowAddModel(true)}
            disabled={!canDeploy}
          >
            新增商品
          </Button>
          <Button onClick={() => dispatch(setImportFilePopup(true))} disabled={!canDeploy}>
            匯入商品
          </Button>
          <Button onClick={handelUploadWMS} disabled={!canDeploy}>
            上傳至WMS系統
          </Button>
        </FlexBox>
        <Row>
          <PageText>{`總共${productList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => fetchPageSizeChange(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Header>
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={productList.results}
        rowKey="id"
        pagination={{
          pageSize,
          current: page,
          showSizeChanger: false,
          total: productList.count,
          onChange: fetchPageChange,
        }}
      />
      <AddAndEditModal
        form={form}
        open={showAddModel}
        onClose={handleAddModalClose}
        editRecord={editRecord}
        onSubmit={handleAddProduct}
      />
      {showImportFilePopup && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => dispatch(setImportFilePopup(false))}
          clearErrorMessage={() => {}}
          handleUploadFile={handelAddXLS}
          templatePath="admin/template/xls/intoWarehouse_products_template.xlsx"
          fileType=".xlsx"
        />
      )}
    </>
  );
};

export default ProductTab;

type AddModalProps = {
  onSubmit: () => void;
  onClose: () => void;
  open: boolean;
  form: FormInstance;
  editRecord?: IWAppProductItem;
};

const AddAndEditModal = (props: AddModalProps) => {
  const { onSubmit, onClose, open, form, editRecord } = props;
  const dispatch = useDispatch();
  const { isFetching, brandVpcIsContract, brandVpc } = useSelector(IWBrandState);
  const { id: strAppId } = useParams();
  const applicationId = Number(strAppId);

  const cantEdit = useMemo(
    () => editRecord?.modifyStatus === ModifyStatus.LIMIT || editRecord?.modifyStatus === ModifyStatus.NONE,
    [editRecord?.modifyStatus],
  );
  const handleOnSubmit = () => {
    if (!brandVpcIsContract) {
      message.error("請輸入有效商品品號");
      return;
    }
    onSubmit();
  };
  const handleCancel = () => {
    onClose();
    dispatch(clearBrandVpc());
    form.resetFields();
  };
  const remark = useMemo(() => {
    if (!brandVpcIsContract) return <Remarks emphasize>輸入之商品品號與此廠商無合作關係</Remarks>;
    return "";
  }, [brandVpcIsContract]);

  const fetchNoInfo = useDebounce((value: string) => {
    if (value) {
      dispatch(fetchBrandVpc({ applicationId, no: value }));
    } else {
      dispatch(clearBrandVpc());
      form.setFieldsValue({ name: "" });
    }
  }, 1000);

  useEffect(() => {
    if (editRecord) {
      form.setFieldsValue({ ...editRecord, expiredDate: moment(editRecord.expiredDate) });
    }
  }, [editRecord, form]);

  useEffect(() => {
    form.setFieldsValue({ name: brandVpc?.name });
    if (!brandVpcIsContract) form.setFieldsValue({ name: "" });
  }, [brandVpc, brandVpcIsContract, form]);

  return (
    <Modal
      visible={!!open}
      confirmLoading={isFetching}
      onOk={() => form.submit()}
      onCancel={handleCancel}
      okText="確認"
      cancelText="取消"
      centered
      maskClosable={false}
    >
      <Form
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 12 }}
        onFinish={handleOnSubmit}
        onFinishFailed={() => message.error("必填欄位未填寫")}
      >
        <Form.Item label="商品品號" name="no" rules={[{ required: true, message: "" }]}>
          <Input onChange={(e) => fetchNoInfo(e.target.value)} disabled={cantEdit} />
        </Form.Item>
        <Form.Item label="商品名稱" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="申請進倉數量" name="requestQty" rules={[{ required: true, message: "" }]}>
          <InputNumber min={0} disabled={cantEdit} />
        </Form.Item>
        <Form.Item label="進價" name="purchasePrice" rules={[{ required: true, message: "" }]}>
          <InputNumber min={0} disabled={editRecord?.modifyStatus === ModifyStatus.NONE} />
        </Form.Item>
        <Form.Item label="倉別" name="storageType" rules={[{ required: true, message: "" }]}>
          <Select placeholder="請選擇" disabled={cantEdit}>
            {storageTypeOptions.map((option) => (
              <Select.Option key={option.name} value={option.value}>
                {option.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="批號" name="batch">
          <Input disabled={cantEdit} />
        </Form.Item>
        <Form.Item label="有效期限" name="expiredDate" rules={[{ required: true, message: "" }]}>
          <DatePicker disabled={cantEdit} />
        </Form.Item>
        {remark}
      </Form>
    </Modal>
  );
};
