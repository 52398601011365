import vipRuleApi, {
  FetchCreateVipRulePayload,
  FetchDeleteVipRulePayload,
  FetchDownloadRedeemCodePayload,
  FetchUpdateVipRulePayload,
  FetchVipRuleListParams,
  VipRule,
} from "@api/vipRuleApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  vipRuleListResults: {
    count: number;
    next: string | null;
    previous: string | null;
    results: VipRule[];
  };
  vipRuleListParams: FetchVipRuleListParams;
  isEditDone: boolean;
  redirectId?: number;
  vipRuleInfo?: VipRule;
  vipRuleError?: string;
}

const initialState: IState = {
  isFetching: false,
  vipRuleListResults: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  vipRuleListParams: {
    titleQ: undefined,
    id: undefined,
    isActive: undefined,
    redeemCode: undefined,
    startAt: undefined,
    endAt: undefined,
    limit: 20,
    offset: 0,
  },
  isEditDone: false,
  redirectId: undefined,
  vipRuleInfo: undefined,
  vipRuleError: undefined,
};

export const fetchVipRuleList = createAsyncThunk("vipRule/fetchVipRuleList", async (params: FetchVipRuleListParams) => {
  const response = await vipRuleApi.fetchVipRuleList(params);
  return response;
});

export const fetchVipRule = createAsyncThunk("vipRule/fetchVipRule", async (vipRuleId: number) => {
  const response = await vipRuleApi.fetchVipRule(vipRuleId);
  return response;
});

export const fetchCreateVipRule = createAsyncThunk(
  "vipRule/fetchCreateVipRule",
  async (payload: FetchCreateVipRulePayload, thunkApi) => {
    try {
      const response = await vipRuleApi.fetchCreateVipRule(payload);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateVipRule = createAsyncThunk(
  "vipRule/fetchUpdateVipRule",
  async (payload: { payload: FetchUpdateVipRulePayload; vipRuleId: number }, thunkApi) => {
    try {
      const response = await vipRuleApi.fetchUpdateVipRule(payload);
      await thunkApi.dispatch(fetchVipRule(payload.vipRuleId));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateVipRuleActive = createAsyncThunk(
  "vipRule/fetchUpdateVipRuleActive",
  async (payload: { payload: FetchUpdateVipRulePayload; vipRuleId: number }, thunkApi) => {
    await vipRuleApi.fetchUpdateVipRule(payload);
    message.success("更新成功");
    return "Success";
  },
);

export const fetchDeleteVipRule = createAsyncThunk(
  "vipRule/fetchDeleteVipRule",
  async (payload: FetchDeleteVipRulePayload) => {
    await vipRuleApi.fetchDeleteVipRule(payload);
    return "Success";
  },
);

export const fetchDownloadRedeemCode = createAsyncThunk(
  "vipRule/fetchDownloadRedeemCode",
  async (params: FetchDownloadRedeemCodePayload) => {
    await vipRuleApi.fetchDownloadRedeemCode(params);
    return "Success";
  },
);

const vipRuleSlice = createSlice({
  name: "vipRule",
  initialState,
  reducers: {
    resetVipRuleSlice: () => initialState,
    setVipRuleListParams: (state, action) => {
      state.vipRuleListParams = action.payload;
    },
    setResetVipRuleListParams: (state) => {
      state.vipRuleListParams = initialState.vipRuleListParams;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVipRuleList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipRuleList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditDone = false;
      state.vipRuleListResults = action.payload;
    });
    builder.addCase(fetchVipRuleList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchVipRule.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipRule.fulfilled, (state, action) => {
      state.isFetching = false;
      state.vipRuleInfo = action.payload;
    });
    builder.addCase(fetchVipRule.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchDeleteVipRule.fulfilled, (state) => {
      state.isEditDone = true;
    });
    builder.addCase(fetchCreateVipRule.fulfilled, (state, action) => {
      state.redirectId = action.payload.id;
      state.vipRuleError = "";
    });
    builder.addCase(fetchCreateVipRule.rejected, (state, action) => {
      state.vipRuleError = action.payload as string;
    });
    builder.addCase(fetchUpdateVipRule.fulfilled, (state) => {
      state.vipRuleError = "";
    });
    builder.addCase(fetchUpdateVipRule.rejected, (state, action) => {
      state.vipRuleError = action.payload as string;
    });
    builder.addCase(fetchUpdateVipRuleActive.fulfilled, (state) => {
      state.isEditDone = true;
    });
  },
});

export const VipRuleState = (state: RootState) => state.vipRuleSlice;
export const { setVipRuleListParams, setResetVipRuleListParams, resetVipRuleSlice } = vipRuleSlice.actions;
export default vipRuleSlice.reducer;
