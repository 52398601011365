export default function downloadFile(url: string, fileName: string) {
  fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const urlBlob = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = urlBlob;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => {});
}
