/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import { RevenueChartItem, RevenueChart } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
  getTimeRangeText,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

enum SeriesNameMap {
  avgOrderPrice = "平均客單價",
  appAvgOrderPrice = "App客單價",
  webAvgOrderPrice = "Web客單價",
}

const OrderPriceChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const [avgOrderPriceChartOption, setAvgOrderPriceChartOption] = useState<Highcharts.Options>();
  const [appAvgOrderPriceChartOption, setAppAvgOrderPriceChartOption] = useState<Highcharts.Options>();
  const [webAvgOrderPriceChartOption, setWebAvgOrderPriceChartOption] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<RevenueChartItem>(
      startDate,
      endDate,
      (chartResult as RevenueChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<RevenueChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as RevenueChart).compareData || [],
      timeUnit,
    );
    const currentAvgOrderPrice: (number | null)[] = [];
    const currentAppAvgOrderPrice: (number | null)[] = [];
    const currentWebAvgOrderPrice: (number | null)[] = [];
    const compareAvgOrderPrice: (number | null)[] = [];
    const compareAppAvgOrderPrice: (number | null)[] = [];
    const compareWebAvgOrderPrice: (number | null)[] = [];
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    currentFilledTimeData.forEach((data) => {
      const { avgOrderPrice = null, appAvgOrderPrice = null, webAvgOrderPrice = null } = data;
      currentAvgOrderPrice.push(avgOrderPrice);
      currentAppAvgOrderPrice.push(appAvgOrderPrice);
      currentWebAvgOrderPrice.push(webAvgOrderPrice);
    });
    compareFilledTimeData.forEach((data) => {
      const { avgOrderPrice = null, appAvgOrderPrice = null, webAvgOrderPrice = null } = data;
      compareAvgOrderPrice.push(avgOrderPrice);
      compareAppAvgOrderPrice.push(appAvgOrderPrice);
      compareWebAvgOrderPrice.push(webAvgOrderPrice);
    });

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: SeriesNameMap.avgOrderPrice,
        data: currentAvgOrderPrice,
        type: "line",
        color: "#5C6AC4",
        pointStart,
      },
      {
        name: SeriesNameMap.appAvgOrderPrice,
        data: currentAppAvgOrderPrice,
        type: "line",
        color: "#BAE7FF",
        pointStart,
      },
      {
        name: SeriesNameMap.webAvgOrderPrice,
        data: currentWebAvgOrderPrice,
        type: "line",
        color: "#1890FF",
        pointStart,
      },
    ];

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "${text}",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointName = this.point.series.userOptions.name;
          const title = getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit);
          const value = this.point.y || 0;

          return `${title}<br/>${pointName} ${USCurrencyFormmater.format(value)}<br/>`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });

    // 相較於有選才設
    if (cStartDate && cEndDate) {
      const currentTimeRange = getTimeRangeText(startDate, endDate, timeUnit);
      const compareTimeRange = getTimeRangeText(cStartDate, cEndDate, timeUnit);

      const avgOrderPriceSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentAvgOrderPrice,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareAvgOrderPrice,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setAvgOrderPriceChartOption({
        title: {
          text: "平均客單價",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "${text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>平均客單價 ${USCurrencyFormmater.format(value)}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: avgOrderPriceSeries,
      });

      const webAvgOrderPriceSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentWebAvgOrderPrice,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareWebAvgOrderPrice,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setWebAvgOrderPriceChartOption({
        title: {
          text: "Web客單價",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "${text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>Web客單價 ${USCurrencyFormmater.format(value)}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: webAvgOrderPriceSeries,
      });

      const appAvgOrderPriceSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentAppAvgOrderPrice,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareAppAvgOrderPrice,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setAppAvgOrderPriceChartOption({
        title: {
          text: "App客單價",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "${text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>App客單價 ${USCurrencyFormmater.format(value)}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: appAvgOrderPriceSeries,
      });
    }
  }, [chartResult, chartParams]);

  const fetchRevenueChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.REVENUE));
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <ChartWrapper>
          <PageTitle title="Dashboard - 客單價" />
          <ChartFilter title="客單價" fetchAction={fetchRevenueChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
          {chartParams.cStartDate && chartParams.cEndDate && (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={avgOrderPriceChartOption}
                containerProps={{ style: { height: 500, marginBottom: 40 } }}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={webAvgOrderPriceChartOption}
                containerProps={{ style: { height: 500, marginBottom: 40 } }}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={appAvgOrderPriceChartOption}
                containerProps={{ style: { height: 500 } }}
              />
            </>
          )}
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default OrderPriceChart;
