import MemberGroupValue from "@constant/MemberGroupValue";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import SaleRuleType from "@page/Order/SaleRuleType";
import VendorLogisticsValue from "@page/Order/VendorLogisticsValue";
import type { Specification } from "@redux/saleSlice";
import CoreApi from "./CoreAPI";
import { SalesCategoryOrigin } from "./topicApi";
import { ContractMode } from "./utils/normalizeContract";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreApi();

export enum ImageType {
  GENERAL_IMAGE = 1,
  MATTING_IMAGE = 2,
  DISCOUNT_IMAGE = 3,
}

export interface SaleListResult {
  count: number;
  next: string;
  previous: string;
  results: SaleListItem[];
}

export interface SaleListItem {
  id: number;
  name: string;
  skuList: string[];
  brands: string[];
  planSpecialPriceRange: {
    maxPrice: number;
    minPrice: number;
  };
  isActive: boolean;
  pageType: SalePageTypeValue;
  plans: {
    id: number;
    price: number;
    optionCounts: number;
    salesDiscount: number;
    memberDiscounts: {
      groupId: number;
      value: number;
    }[];
  }[];
  logistic: any;
  groupCanBuy: {
    groupId: number;
    groupName: string;
    value: boolean;
  }[];
  displayVipPrice: boolean;
  reviewInfo: {
    reviewCount: number;
    avgReviewScores: number;
  };
  isEditable: boolean;
  startAt: string;
  endAt: string;
}

export interface SaleItemDetail extends SaleListItem {
  groupVisibilities: {
    isListVisibility: boolean;
    isSearchVisibility: boolean;
    memberGroup: MemberGroupValue;
    memberGroupTitle: string;
  }[];
  isAdult: boolean;
  shortDescription: string;
  description: string;
  spec: string;
  media: {
    id: number;
    name: string;
    mediaType: number;
    isActive: boolean;
    alt: string | null;
    url: string;
    image: string;
    imageType: ImageType | null;
    isShow: boolean;
    rank: number;
    endAt: string;
    startAt: string;
    isEditable: boolean;
  }[];
  salesCategories: {
    id: number;
    name: string;
    path: string;
    parent: number;
    rank: number;
    icon: string | null;
    iconAlt: string | null;
    level: number;
  }[];
  estimateShippingDate: string | null;
  metaTitle: string | null;
  metaKeywords: string | null;
  metaDescription: string | null;
}

export interface FetchSaleListParams {
  limit: number;
  offset: number;
  pageNameQ?: string;
  pageIds?: string[];
  pageId?: string;
  brandNameQ?: string;
  brandNames?: string[];
  brandNamesQ?: string[];
  productId?: string;
  productIds?: string[];
  sku?: string;
  skus?: string[];
  minPrice?: number;
  isActive?: boolean;
  categoryIds?: number[];
  tagId?: number;
  tagIds?: number[];
  excludeCategoryId?: number;
  excludeTagId?: number;
  pageType?: number;
  vendor?: string;
  slotId?: string;
  excludeAddedPage?: boolean;
  promotionId?: number;
}

export interface FetchCreateSale {
  name: string;
  isListVisibility: boolean;
  isSearchVisibility: boolean;
  isAdult: boolean;
  isOnlyVipCanBuy: boolean;
  displayVipPrice: boolean;
  isActive: boolean;
  pageType: SalePageTypeValue;
  logistic: VendorLogisticsValue;
  salesCategories: number[];
  images: {
    mediaId: number | null;
    imageDirPrefix: string;
    imageFilePath: string;
    imageAlt: string;
    imageRank?: number;
    isShow: boolean;
  }[];
  mattingImage: { imageDirPrefix: string; imageFilePath: string; imageAlt: string };
  shortDescription: string;
  description: string;
  spec: Specification[];
  estimateShippingDate: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  noModificationImageIds?: number[];
}

export interface FetchCreatePlan {
  name: string;
  salesPage: number;
  imageDirPrefix?: string;
  imageFilePath?: string;
  imageFileAlt: string;
  optionsCount?: number;
  productsInfo: {
    vpcId: number;
    qty: number;
  }[];
}

export interface FetchUpdatePlan {
  isActive?: boolean;
  name?: string;
  salesPage?: number;
  imageDirPrefix?: string;
  imageFilePath?: string;
  imageFileAlt?: string;
  optionsCount?: number;
  productsInfo?: {
    vpcId: number;
    qty: number;
  }[];
}

export interface SalePlanOption {
  id: number;
  name: string;
  vpc: {
    id: number;
    price: number;
    commissionRate: number;
    productName: string;
    contractInfo: {
      id: number;
      mode: ContractMode;
      modeName: string;
    };
    sku: string;
  };
  commissionRate: number;
  groupQty: number;
  unitPrice: number;
  unitCost: number;
  optionDiscount: number;
  canBuyCount: number;
}

export interface SalePlan {
  id: number;
  name: string;
  options: SalePlanOption[];
  price: number;
  media: {
    id: number;
    name: string;
    mediaType: number;
    isActive: boolean;
    alt: string | null;
    url: string;
    image: string;
    imageType: ImageType | null;
    isEditable: boolean;
    startAt: string;
    endAt: string;
  };
  ruleType: SaleRuleType;
  isActive: true;
  specialPrice: number;
  optionsCount: number;
  optionDiscountPeriod: {
    startAt: string | null;
    endAt: string | null;
  };
  canBuyCount: number;
  groupsSalesLimit: {
    id: number;
    memberGroup: MemberGroupValue;
    maxSaleQty: number;
    minGrossProfitMargin: number;
    startAt: string | null;
    endAt: string | null;
  }[];
  isEditable: boolean;
  endAt: string;
  startAt: string;
  description: string;
}

export interface FetchPlanOptionDiscount {
  salesPlan: number;
  startAt?: string;
  endAt?: string;
  optionsDiscountsIds?: number[];
  specialPrice?: number;
  commissionRateDiscount?: number;
  optionsDiscounts: {
    optionId: number;
    specialPrice: number;
    commissionRateDiscount?: number;
    optionsDiscountsId?: number;
  }[];
}

export interface FetchDeleteChangePrice {
  optionsDiscountsIds: number[];
  salesPlan: number;
}

export interface PlanChangePriceOption {
  optionId: number;
  optionName: string;
  optionUnitPrice: number;
  optionUnitCost: number;
  groupQty: number;
  priceDiscount: number;
  specialPriceToShow: number;
  commissionRate: number;
  commissionRateDiscount: number;
  optionsDiscountsId: number;
  contractMode: ContractMode;
}

export interface PlanChangePrice {
  id: number;
  name: string;
  price: number;
  startAt: string | null;
  endAt: string | null;
  optionsInfo: PlanChangePriceOption[];
}

export interface FetchCreateGroupLimit {
  salesPlan: number;
  groupsSalesLimit: {
    id?: number;
    groupId?: number;
    maxSaleQty: number;
    startAt?: string;
    endAt?: string;
    minGrossProfitMargin?: number;
  }[];
}

export interface FetchOptionList {
  salesPage: number;
  limit: number;
  offset: number;
}

export interface Option {
  optionName: string | null;
  productInfo: {
    id: number;
    sku: string;
    name: string;
    brand: string;
    canBuyCount: number;
  };
  vpcInfo: {
    price: number;
    cost: number;
    commissionRate: number;
    contractMode: ContractMode;
  };
  optionIds: number[];
  optionRank: number;
}

export interface FetchCreateOption {
  salePageId: number;
  payload: {
    name: string;
    vpc: number;
  };
}

export interface FetchUpdateOption {
  salePageId: number;
  payload: {
    name: string;
    vpc: number;
    optionIds: number[];
  };
}

export interface FetchDeleteOption {
  salePageId: number;
  payload: {
    optionIds: number[];
  };
}

export interface FetchUpdatePlanRanks {
  salesPlanRankData: {
    planId: number;
    rank: number;
  }[];
}

export interface FetchUpdateOptionRanks {
  salesOptionRankData: {
    optionId: number;
    rank: number;
  }[];
}

interface ISaleApi {
  fetchSaleList: (params: FetchSaleListParams) => Promise<SaleListResult>;
  fetchSalePage: (salePageId: number) => Promise<SaleItemDetail>;
  fetchSalesCategories: () => Promise<{
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  }>;
  fetchCreateSalePage: (payload: FetchCreateSale) => Promise<SaleItemDetail>;
  fetchUpdateSalePage: (salePageId: number, payload: FetchCreateSale) => Promise<void>;
  fetchChangePriceUpload: (file: File) => Promise<void>;
  refreshChangePrice: () => Promise<void>;
  fetchChangePriceDownload: (params: FetchSaleListParams) => Promise<void>;
  fetchPlanList: (config: {
    salesPage: number;
    limit: number;
    offset: number;
  }) => Promise<{
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  }>;
  fetchPlanInfo: (planId: number) => Promise<SalePlan>;
  fetchCreatePlan: (payload: FetchCreatePlan) => Promise<SalePlan>;
  fetchUpdatePlan: (planId: number, payload: FetchUpdatePlan) => Promise<void>;
  fetchToogleActivePlan: (planId: number, isActive: boolean) => Promise<void>;
  fetchChangePriceList: (config: {
    salesPage: number;
    limit: number;
    offset: number;
  }) => Promise<{
    count: number;
    next: string;
    previous: string;
    results: PlanChangePrice[];
  }>;
  fetchCreateChangePrice: (payload: FetchPlanOptionDiscount) => Promise<void>;
  fetchUpdateChangePrice: (payload: FetchPlanOptionDiscount) => Promise<void>;
  fetchDeleteChangePrice: (payload: FetchDeleteChangePrice) => Promise<void>;
  fetchOptionList: (
    config: FetchOptionList,
  ) => Promise<{
    count: number;
    next: string;
    previous: string;
    results: Option[];
  }>;
  fetchCreateOption: (payload: FetchCreateOption) => Promise<void>;
  fetchUpdateOption: (payload: FetchUpdateOption) => Promise<void>;
  fetchDeleteOption: (payload: FetchDeleteOption) => Promise<void>;
  fetchCreateGroupLimit: (payload: FetchCreateGroupLimit) => Promise<void>;
  fetchUpdateGroupLimit: (groupLimitId: number) => Promise<void>;
  fetchUpdatePlanRanks: (payload: FetchUpdatePlanRanks) => Promise<void>;
  fetchUpdateOptionRanks: (payload: FetchUpdateOptionRanks) => Promise<void>;
  batchActiveSalePages: (salePageIds: number[], isActive: boolean) => Promise<void>;
}

const saleApi: ISaleApi = {
  fetchSaleList: async (params) => {
    const {
      limit,
      offset,
      sku,
      skus,
      pageNameQ,
      tagId,
      tagIds,
      pageIds,
      categoryIds,
      brandNames,
      excludeTagId,
      brandNameQ,
      brandNamesQ,
      productId,
      productIds,
      minPrice,
      isActive,
      excludeCategoryId,
      pageType,
      vendor,
      slotId,
      excludeAddedPage,
      promotionId,
      pageId,
    } = params;
    const getParams = {
      limit,
      offset,
      page_name_q: pageNameQ,
      page_ids: pageIds?.join(),
      page_id: pageId,
      brand_name_q: brandNameQ,
      brand_names: brandNames?.join(),
      brand_names_q: brandNamesQ?.join(),
      product_id: productId,
      product_ids: productIds?.join(),
      sku,
      skus: skus?.join(),
      min_price: minPrice,
      is_active: isActive,
      category_ids: categoryIds?.join(),
      tag_id: tagId,
      tag_ids: tagIds?.join(),
      exclude_category_id: excludeCategoryId,
      exclude_tag_id: excludeTagId,
      page_type: pageType,
      vendor,
      slot_id: slotId,
      promotion_id: promotionId,
    };
    let response;
    if (excludeAddedPage) {
      response = await apiClient.getData("/manage/sales/pages-without-added/", getParams);
    } else {
      response = await apiClient.getData("/manage/sales/pages/", getParams);
    }
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSalePage: async (salePageId) => {
    const response = await apiClient.getData(`/manage/sales/pages/${salePageId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSalesCategories: async () => {
    const response = await apiClient.getData("/manage/sales/categories/", {});
    const data = response.data.result;
    return {
      level1: data.level_1,
      level2: data.level_2,
      level3: data.level_3,
    };
  },
  fetchCreateSalePage: async (payload) => {
    const response = await apiClient.postData("/manage/sales/pages/", {
      name: payload.name,
      is_list_visibility: !!payload.isListVisibility,
      is_search_visibility: !!payload.isSearchVisibility,
      is_adult: !!payload.isAdult,
      is_only_vip_can_buy: !!payload.isOnlyVipCanBuy,
      display_vip_price: !!payload.displayVipPrice,
      is_active: payload.isActive,
      page_type: payload.pageType,
      logistic: payload.logistic,
      sales_categories: payload.salesCategories,
      images: payload.images.map((item) => ({
        image_dir_prefix: item.imageDirPrefix,
        image_file_path: item.imageFilePath,
        image_alt: item.imageAlt,
        image_rank: item.imageRank,
        is_show: item.isShow,
      })),
      matting_image: {
        image_dir_prefix: payload.mattingImage.imageDirPrefix,
        image_file_path: payload.mattingImage.imageFilePath,
        image_alt: payload.mattingImage.imageAlt,
      },
      short_description: payload.shortDescription,
      description: payload.description,
      spec: payload.spec,
      estimate_shipping_date: payload.estimateShippingDate,
      meta_title: payload.metaTitle,
      meta_keywords: payload.metaKeywords,
      meta_description: payload.metaDescription,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateSalePage: async (salePageId, payload) => {
    await apiClient.putData(`/manage/sales/pages/${salePageId}/`, {
      name: payload.name,
      is_list_visibility: payload.isListVisibility,
      is_search_visibility: payload.isSearchVisibility,
      is_adult: payload.isAdult,
      is_only_vip_can_buy: payload.isOnlyVipCanBuy,
      display_vip_price: payload.displayVipPrice,
      is_active: payload.isActive,
      page_type: payload.pageType,
      logistic: payload.logistic,
      sales_categories: payload.salesCategories,
      images: payload.images.map((item) => ({
        media_id: item.mediaId,
        image_dir_prefix: item.imageDirPrefix,
        image_file_path: item.imageFilePath,
        image_alt: item.imageAlt,
        image_rank: item.imageRank,
        is_show: item.isShow,
      })),
      matting_image: payload.mattingImage
        ? {
            image_dir_prefix: payload.mattingImage.imageDirPrefix,
            image_file_path: payload.mattingImage.imageFilePath,
            image_alt: payload.mattingImage.imageAlt,
          }
        : undefined,
      short_description: payload.shortDescription,
      description: payload.description,
      spec: payload.spec,
      estimate_shipping_date: payload.estimateShippingDate,
      meta_title: payload.metaTitle,
      meta_keywords: payload.metaKeywords,
      meta_description: payload.metaDescription,
      no_modification_image_ids: payload.noModificationImageIds,
    });
  },
  fetchChangePriceUpload: async (file: File) => {
    const form = new FormData();
    form.append("xls_file", file);
    await apiClient.putData("/manage/sales/pages/xls-upload/", form);
  },
  fetchChangePriceDownload: async (params) => {
    const {
      limit,
      offset,
      sku,
      skus,
      pageNameQ,
      tagId,
      pageIds,
      categoryIds,
      brandNames,
      excludeTagId,
      brandNameQ,
      brandNamesQ,
      productId,
      productIds,
      minPrice,
      isActive,
      excludeCategoryId,
      pageType,
      vendor,
    } = params;
    const getParams = {
      limit,
      offset,
      page_name_q: pageNameQ,
      page_ids: pageIds?.join(),
      brand_name_q: brandNameQ,
      brand_names: brandNames?.join(),
      brand_names_q: brandNamesQ?.join(),
      product_id: productId,
      product_ids: productIds?.join(),
      sku,
      skus: skus?.join(),
      min_price: minPrice,
      is_active: isActive,
      category_ids: categoryIds?.join(),
      tag_id: tagId,
      exclude_category_id: excludeCategoryId,
      exclude_tag_id: excludeTagId,
      page_type: pageType,
      vendor,
    };
    await apiClient.getData("/manage/sales/pages/xls-download/", getParams, {
      responseType: "blob",
    });
  },
  fetchPlanList: async (config) => {
    const response = await apiClient.getData("/manage/sales/plans/", {
      sales_page: config.salesPage,
      limit: config.limit,
      offset: config.offset,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchPlanInfo: async (planId: number) => {
    const response = await apiClient.getData(`/manage/sales/plans/${planId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreatePlan: async (payload) => {
    const response = await apiClient.postData("/manage/sales/plans/", {
      name: payload.name,
      sales_page: payload.salesPage,
      image_dir_prefix: payload.imageDirPrefix,
      image_file_path: payload.imageFilePath,
      image_file_alt: payload.imageFileAlt,
      options_count: payload.optionsCount,
      products_info: payload.productsInfo.map((item) => ({
        vpc_id: item.vpcId,
        qty: item.qty,
      })),
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdatePlan: async (planId, payload) => {
    await apiClient.putData(`/manage/sales/plans/${planId}/`, {
      is_active: payload.isActive,
      name: payload.name,
      sales_page: payload.salesPage,
      image_dir_prefix: payload.imageDirPrefix,
      image_file_path: payload.imageFilePath,
      image_alt: payload.imageFileAlt,
      options_count: payload.optionsCount,
      products_info: payload?.productsInfo?.map((item) => ({
        vpc_id: item.vpcId,
        qty: item.qty,
      })),
    });
  },

  fetchToogleActivePlan: async (planId, isActive) => {
    await apiClient.patchData(`/manage/sales/plans/${planId}/`, {
      is_active: isActive,
    });
  },
  fetchChangePriceList: async (config) => {
    const response = await apiClient.getData("/manage/sales/plan-option-discounts/", {
      sales_page: config.salesPage,
      limit: config.limit,
      offset: config.offset,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateChangePrice: async (payload) => {
    await apiClient.postData("/manage/sales/plan-option-discounts/", {
      sales_plan: payload.salesPlan,
      start_at: payload.startAt,
      end_at: payload.endAt,
      options_discounts_ids: payload.optionsDiscountsIds,
      special_price: payload.specialPrice,
      commission_rate_discount: payload.commissionRateDiscount,
      options_discounts: payload.optionsDiscounts.map((item) => ({
        option_id: item.optionId,
        special_price: item.specialPrice,
        commission_rate_discount: item.commissionRateDiscount,
        options_discounts_id: item.optionsDiscountsId,
      })),
    });
  },
  refreshChangePrice: async () => {
    await apiClient.postData("/manage/sales/pages/purge-cache/", {});
  },
  fetchUpdateChangePrice: async (payload) => {
    await apiClient.putData("/manage/sales/plan-option-discounts/update-discounts/", {
      sales_plan: payload.salesPlan,
      start_at: payload.startAt,
      end_at: payload.endAt,
      options_discounts_ids: payload.optionsDiscountsIds,
      special_price: payload.specialPrice,
      commission_rate_discount: payload.commissionRateDiscount,
      options_discounts: payload.optionsDiscounts.map((item) => ({
        option_id: item.optionId,
        special_price: item.specialPrice,
        commission_rate_discount: item.commissionRateDiscount,
        options_discounts_id: item.optionsDiscountsId,
      })),
    });
  },
  fetchDeleteChangePrice: async (payload) => {
    await apiClient.putData("/manage/sales/plan-option-discounts/delete-discounts/", {
      options_discounts_ids: payload.optionsDiscountsIds,
      sales_plan: payload.salesPlan,
    });
  },
  fetchOptionList: async (config) => {
    const response = await apiClient.getData("/manage/sales/page-options/", {
      sales_page: config.salesPage,
      limit: config.limit,
      offset: config.offset,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateOption: async (payload) => {
    await apiClient.postData(`/manage/sales/page-options/${payload.salePageId}/batch-create/`, payload.payload);
  },
  fetchUpdateOption: async (payload) => {
    await apiClient.putData(`/manage/sales/page-options/${payload.salePageId}/batch-update/`, {
      name: payload.payload.name,
      vpc: payload.payload.vpc,
      option_ids: payload.payload.optionIds,
    });
  },
  fetchDeleteOption: async (payload) => {
    await apiClient.putData(`/manage/sales/page-options/${payload.salePageId}/batch-delete/`, {
      option_ids: payload.payload.optionIds,
    });
  },
  fetchCreateGroupLimit: async (payload) => {
    await apiClient.postData("/manage/account/groups-sales-limit/add-or-update/", {
      sales_plan: payload.salesPlan,
      groups_sales_limit: payload.groupsSalesLimit.map((item) => ({
        id: item.id,
        group_id: item.groupId,
        max_sale_qty: item.maxSaleQty,
        start_at: item.startAt,
        end_at: item.endAt,
        min_gross_profit_margin: item.minGrossProfitMargin || 0,
      })),
    });
  },
  fetchUpdateGroupLimit: async (groupLimitId) => {
    await apiClient.deleteData(`/manage/account/groups-sales-limit/${groupLimitId}/`, {});
  },
  fetchUpdatePlanRanks: async (payload) => {
    await apiClient.putData("/manage/sales/plans/ranks/", transformCamelToSnake(payload));
  },
  fetchUpdateOptionRanks: async (payload) => {
    await apiClient.putData("/manage/sales/page-options/ranks/", transformCamelToSnake(payload));
  },
  batchActiveSalePages: async (salePageIds, isActive) => {
    const payload = { sales_pages: salePageIds, is_active: isActive };
    await apiClient.putData("/manage/sales/pages/batch-actives/", payload);
  },
};

export default saleApi;
