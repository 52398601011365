import React, { useMemo } from "react";
import type { Table as TypeTable } from "antd";
import { Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import TablePageSizeInfo from "./TablePageSizeInfo";

type PropsT<T extends (...args: any) => any> = Parameters<T>[0];
type TableProps = PropsT<typeof TypeTable>;

type PaginationConfig = {
  offset: number;
  fetchList?: (params?: any) => void;
  updateFilterParams: (params?: any) => void;
  filterParams: { [key: string]: any };
  pageSizeOptions?: number[];
  totalCount: number;
  limit: number;
};

type PaginationProps =
  | {
      isPagination: false;
    }
  | {
      isPagination: true;
      paginationConfig: PaginationConfig;
    };

type ComponentProps = {
  dashboardComponent?: React.ReactNode;
};
type Props = ComponentProps & PaginationProps & TableProps & { columns: ColumnsType<any> | undefined };

export default function IntegratedTable(props: Props) {
  const { isPagination, dashboardComponent, ...tableProps } = props;

  const pagination = useMemo(() => {
    if (isPagination) {
      const { paginationConfig } = props as { paginationConfig: PaginationConfig };
      const { offset, limit: pageSize, totalCount, filterParams, updateFilterParams, fetchList } = paginationConfig;

      const onPageSizeChange = (page: number) => {
        const filter = {
          ...filterParams,
          offset: (page - 1) * pageSize,
        };
        updateFilterParams(filter);
        if (fetchList) fetchList();
        if (fetchList) fetchList(filter);
      };

      return {
        pageSize,
        current: Math.floor(offset / pageSize) + 1,
        showSizeChanger: false,
        total: totalCount,
        onChange: onPageSizeChange,
      };
    }
    return undefined;
  }, [isPagination, props]);

  const pageInfo = useMemo(() => {
    if (isPagination) {
      const { paginationConfig } = props as { paginationConfig: PaginationConfig };
      const { fetchList, updateFilterParams, filterParams } = paginationConfig;
      const onPageSizeChange = (value: SelectValue) => {
        const filter = {
          ...filterParams,
          limit: value as number,
        };
        updateFilterParams(filter);
        if (fetchList) fetchList(filter);
      };
      return {
        totalCount: paginationConfig.totalCount,
        limit: paginationConfig.limit,
        pageSizeOptions: paginationConfig.pageSizeOptions,
        onPageSizeChange,
      };
    }

    return undefined;
  }, [isPagination, props]);

  return (
    <>
      {isPagination && pageInfo && (
        <TablePageSizeInfo
          totalCount={pageInfo.totalCount}
          limit={pageInfo.limit}
          onPageSizeChange={pageInfo.onPageSizeChange}
          pageSizeOptions={pageInfo.pageSizeOptions}
        />
      )}
      {dashboardComponent}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Table {...tableProps} pagination={isPagination ? pagination : false} />
    </>
  );
}
