import { UploadOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import { fetchChangePriceUpload, SaleState, setIsUploadDone } from "@redux/saleSlice";
import { Button, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 620px;
  min-height: 223px;
  padding: 40px 20px 20px;
  background: ${({ theme }) => theme.colorNeutral100};
`;

const Text = styled.div`
  margin-top: 12px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  ${fontStyle("14px", "16px")};
`;

const DownloadLink = styled.a`
  margin-top: 12px;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  ${fontStyle("14px", "16px")};
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomRow = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 12px;

  &&& {
    & > span > div {
      display: flex;
      justify-content: center;
    }
  }
`;

type Props = {
  close: () => void;
};

export default function UploadModal(props: Props) {
  const { close } = props;

  const dispatch = useDispatch();
  const { isUploadDone, isUploadFetching } = useSelector(SaleState);

  const [uploadFile, setUploadFile] = useState<File>();

  const fetchUpload = () => {
    if (uploadFile) {
      dispatch(fetchChangePriceUpload(uploadFile));
    }
  };

  useEffect(() => {
    if (isUploadDone) {
      dispatch(setIsUploadDone(false));
      close();
    }
  }, [isUploadDone, close, dispatch]);

  return (
    <PopupBackground close={close}>
      <Wrapper>
        <CustomRow>
          <Upload onChange={(file) => setUploadFile(file.file.originFileObj as File)}>
            <UploadOutlined style={{ fontSize: "66px" }} />
            <Text>選擇檔案</Text>
          </Upload>
          <DownloadLink
            href={`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}admin/template/csv/sales_page_change_price_import_sample.xlsx`}
          >
            下載範例檔案
          </DownloadLink>
        </CustomRow>
        <Row justify="end">
          <CustomButton onClick={close}>取消</CustomButton>
          <Button type="primary" onClick={fetchUpload} loading={isUploadFetching}>
            匯入
          </Button>
        </Row>
      </Wrapper>
    </PopupBackground>
  );
}
