import CoreAPI from "./CoreAPI";
import normalizeStaff from "./utils/normalizeStaff";

const apiClient = new CoreAPI();

interface IStaffApi {
  fetchStaffList: (params: FetchStaffListParam) => Promise<StaffListResult>;
}

export interface StaffListResult {
  count: number;
  previous: string;
  next: string;
  results: StaffListItem[];
}
export interface StaffListItem {
  id: number;
  name: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  createdAt: string;
}
export interface FetchStaffListParam {
  limit: number;
  offset: number;
  nameQ?: string;
  staffGroupPermission: string;
}

const staffApi: IStaffApi = {
  fetchStaffList: async (params) => {
    const { limit, offset, nameQ, staffGroupPermission } = params;
    const getParams = {
      limit,
      offset,
      name_q: nameQ,
      staff_group_permission: staffGroupPermission,
    };
    const response = await apiClient.getData("/manage/account/staffs/", getParams);
    return normalizeStaff.list(response.data.result);
  },
};

export default staffApi;
