import { Checkbox, Form, InputNumber, Radio, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import React, { FC, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import type { LocalFormType } from ".";
import actionTypeValue, { ActionTypeValue } from "../RuleList/actionTypeValue";
import conditionTypeValue, { ConditionTypeValue } from "../RuleList/conditionTypeValue";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 224px;
  border: 1px solid #d9d9d9;
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.div`
  color: red;
`;
const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  padding: 12px 20px 20px 20px;
`;
const Row = styled.div<{ align?: string }>`
  display: flex;
  align-items: ${({ align }) => align || "flex-start"};
  margin-bottom: 10px;
`;
const RowTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 10px;
  line-height: 32px;
`;
const StyledSelect = styled(Select)`
  width: 130px;
  margin-right: 10px;
`;
const CheckboxArea = styled.div`
  margin-top: auto;
`;
const StyledInput = styled(InputNumber)<{ marginBottom?: string }>`
  width: 130px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;
const PercetTag = styled.div`
  line-height: 30px;
  margin-left: 6px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

const DiscountInput = styled(InputNumber)`
  width: 150px;
  margin-bottom: 10px;
`;
const RadioBlock = styled(Radio)`
  display: block;
  margin-bottom: 6px;
`;
const BuyMGetNWrapper = styled.div`
  margin-left: 39px;
`;
const CustomInput = styled(InputNumber)`
  margin-left: 5px;
  margin-right: 15px;
  width: 130px;
`;

export const conditionOptions = [
  {
    label: conditionTypeValue[0],
    value: 0,
  },
  {
    label: conditionTypeValue[1],
    value: 1,
  },
  {
    label: conditionTypeValue[2],
    value: 2,
  },
];
export const actionOptions = [
  {
    label: actionTypeValue[0],
    value: 0,
  },
  {
    label: actionTypeValue[1],
    value: 1,
  },
  {
    label: actionTypeValue[2],
    value: 2,
  },
  {
    label: actionTypeValue[3],
    value: 3,
  },
  {
    label: actionTypeValue[4],
    value: 4,
  },
  {
    label: actionTypeValue[5],
    value: 5,
  },
  {
    label: actionTypeValue[6],
    value: 6,
  },
  {
    label: actionTypeValue[7],
    value: 7,
  },
  {
    label: actionTypeValue[8],
    value: 8,
  },
  {
    label: actionTypeValue[9],
    value: 9,
  },
];

interface Props {
  form: FormInstance<LocalFormType>;
}

const RuleSetting: FC<Props> = (props) => {
  const { id } = useParams();
  const { form } = props;
  const isEditMode = !!id; // 有id表示已經建立案型
  const [num, setNum] = useState(1);

  const handleOnActionTypeChange = (value: SelectValue) => {
    if (value !== ActionTypeValue.DISCOUNT) {
      form.setFieldsValue({
        recalculateDiscount: false,
      });
    }
    if (value === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE) {
      form.setFieldsValue({
        bindNotify: false,
        expireNotify: false,
      });
    }
    if (value === ActionTypeValue.NON_BOUNDED_GIFT) {
      form.setFieldsValue({
        discountLimit: 1000, // 累贈
        conditionAmountUpperBound: undefined,
      });
    } else if (value === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE || value === ActionTypeValue.BOUNDED_GIFT) {
      form.setFieldsValue({
        discount: undefined,
        discountLimit: 1000, // 累贈
        conditionAmountUpperBound: undefined,
      });
    } else {
      form.setFieldsValue({
        discount: undefined,
        discountLimit: undefined,
        conditionAmountUpperBound: undefined,
      });
    }
  };

  const computeActionOptions = () => {
    const originActionOptions = actionOptions.filter(
      (item) => item.value !== ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT,
    );

    if (form.getFieldValue("conditionType") !== ConditionTypeValue.FULFIL_UNIT) {
      return originActionOptions.filter((item) => item.value !== ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE);
    }

    return originActionOptions;
  };

  const onHandleConditionService = () => {
    if (
      form.getFieldValue("conditionType") === ConditionTypeValue.FULFIL_DOLLAR &&
      form.getFieldValue("actionType") === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE
    ) {
      form.setFieldsValue({
        actionType: ActionTypeValue.NO_CONDITION,
      });
    }

    form.setFieldsValue({
      recalculateDiscount: false,
      conditionAmountUpperBound: undefined,
    });
  };

  const onHandleAfterSetUpperBound = (value: string | number | undefined) => {
    if (value) {
      form.setFieldsValue({
        discountLimit: 1,
      });
    }
  };

  return (
    <Wrapper>
      <Header>
        優惠設定
        <Red>*</Red>
      </Header>
      <Body>
        <Row>
          <RowTitle>條件</RowTitle>
          <Form.Item name="conditionType" rules={[{ required: true, message: "" }]}>
            <StyledSelect options={conditionOptions} onChange={onHandleConditionService} />
          </Form.Item>
          <Form.Item shouldUpdate>
            {({ getFieldValue }) => {
              const conditionType = getFieldValue("conditionType");
              const conditionAmount = getFieldValue("conditionAmount");
              const conditionAmountUpperBound = getFieldValue("conditionAmountUpperBound");
              const noOneError = !conditionAmount?.toString()?.trim() && !conditionAmountUpperBound?.toString()?.trim();
              switch (conditionType) {
                case ConditionTypeValue.FULFIL_DOLLAR:
                  return (
                    <div>
                      <Form.Item
                        noStyle
                        name="conditionAmount"
                        rules={[
                          {
                            validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                            message: "必填",
                          },
                        ]}
                      >
                        <StyledInput marginBottom="8px" placeholder="請輸入金額" />
                      </Form.Item>
                      <div>
                        {getFieldValue("actionType") === ActionTypeValue.NON_BOUNDED_GIFT && (
                          <>
                            <Form.Item
                              noStyle
                              name="conditionAmountUpperBound"
                              rules={[
                                {
                                  validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                                  message: "",
                                },
                              ]}
                            >
                              <StyledInput
                                placeholder="請輸入價格上限"
                                onChange={(value) => onHandleAfterSetUpperBound(value as string | number | undefined)}
                              />
                            </Form.Item>
                            {noOneError && <Red>至少擇一填寫</Red>}
                          </>
                        )}
                      </div>
                    </div>
                  );
                case ConditionTypeValue.FULFIL_UNIT:
                  return (
                    getFieldValue("actionType") !== ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE && (
                      <Form.Item name="conditionAmount" rules={[{ required: true, message: "必填" }]}>
                        <StyledInput placeholder="請輸入件數" min={0} />
                      </Form.Item>
                    )
                  );
                default:
                  return null;
              }
            }}
          </Form.Item>
        </Row>
        <Row>
          <RowTitle>案型</RowTitle>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.conditionType !== currentValues.conditionType}
            rules={[{ required: true, message: "" }]}
          >
            {() => (
              <Form.Item name="actionType" noStyle>
                <StyledSelect
                  options={computeActionOptions()}
                  onChange={(value) => handleOnActionTypeChange(value as SelectValue)}
                  disabled={isEditMode}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const actionType = getFieldValue("actionType");
              const discountNum = getFieldValue("discount");
              const discountLimitNum = getFieldValue("discountLimit");
              const noOneError = !discountNum?.toString()?.trim() && !discountLimitNum?.toString()?.trim();
              switch (actionType) {
                case ActionTypeValue.DEDUCTION:
                  return (
                    <FlexColumn>
                      <Form.Item
                        name="discount"
                        noStyle
                        rules={[
                          {
                            validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                            message: "",
                          },
                        ]}
                      >
                        <DiscountInput placeholder="折抵金額" disabled={isEditMode} />
                      </Form.Item>
                      <FlexRow>
                        <Form.Item
                          name="discountLimit"
                          noStyle
                          rules={[
                            {
                              validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                              message: "",
                            },
                          ]}
                        >
                          <DiscountInput placeholder="折抵最高百分比" disabled={isEditMode} />
                        </Form.Item>
                        <PercetTag>%</PercetTag>
                      </FlexRow>
                      {noOneError && <Red>至少擇一填寫</Red>}
                    </FlexColumn>
                  );
                case ActionTypeValue.DISCOUNT:
                  return (
                    <FlexColumn>
                      <FlexRow>
                        <Form.Item
                          noStyle
                          name="discount"
                          style={{ display: "flex", flexDirection: "row" }}
                          rules={[
                            {
                              validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                              message: "",
                            },
                          ]}
                        >
                          <DiscountInput placeholder="打折百分比" disabled={isEditMode} />
                        </Form.Item>
                        <PercetTag>% off</PercetTag>
                      </FlexRow>
                      <Form.Item
                        noStyle
                        name="discountLimit"
                        rules={[
                          {
                            validator: () => (noOneError ? Promise.reject() : Promise.resolve()),
                            message: "",
                          },
                        ]}
                      >
                        <DiscountInput placeholder="折抵最高金額" disabled={isEditMode} />
                      </Form.Item>
                      {noOneError && <Red>至少擇一填寫</Red>}
                    </FlexColumn>
                  );
                case ActionTypeValue.NON_BOUNDED_GIFT:
                case ActionTypeValue.BOUNDED_GIFT_ANY:
                  return (
                    <Form.Item name="discount" rules={[{ required: true, message: "請填贈品件數" }]}>
                      <StyledInput
                        placeholder="贈品件數"
                        disabled={isEditMode}
                        min={1}
                        value={num}
                        onChange={(e: any) => setNum(e)}
                      />
                    </Form.Item>
                  );
                case ActionTypeValue.BOUNDED_GIFT:
                  return (
                    <Form.Item name="discount" rules={[{ required: true, message: "請填贈品件數" }]}>
                      <StyledInput placeholder="贈品件數" disabled={isEditMode} />
                    </Form.Item>
                  );
                default:
                  return null;
              }
            }}
          </Form.Item>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
        >
          {({ getFieldValue }) =>
            getFieldValue("actionType") === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE && (
              <Row align="center">
                <BuyMGetNWrapper>
                  <span>買</span>
                  <Form.Item noStyle name="conditionAmount">
                    <CustomInput placeholder="請輸入件數" />
                  </Form.Item>
                  <span>送</span>
                  <Form.Item noStyle name="discount">
                    <CustomInput placeholder="請輸入件數" />
                  </Form.Item>
                </BuyMGetNWrapper>
              </Row>
            )
          }
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.actionType !== currentValues.actionType ||
            prevValues.conditionAmountUpperBound !== currentValues.conditionAmountUpperBound
          }
        >
          {({ getFieldValue }) => {
            const actionType = getFieldValue("actionType");
            switch (actionType) {
              case ActionTypeValue.NON_BOUNDED_GIFT:
              case ActionTypeValue.BOUNDED_GIFT:
                return (
                  <Form.Item name="discountLimit" noStyle>
                    <Radio.Group>
                      <RadioBlock value={1000} disabled={isEditMode || getFieldValue("conditionAmountUpperBound")}>
                        累贈
                      </RadioBlock>
                      <RadioBlock value={1} disabled={isEditMode || getFieldValue("conditionAmountUpperBound")}>
                        不累贈
                      </RadioBlock>
                    </Radio.Group>
                  </Form.Item>
                );
              default:
                return null;
            }
          }}
        </Form.Item>
        <CheckboxArea>
          <Form.Item name="isFreeShipping" noStyle valuePropName="checked">
            <Checkbox>免運</Checkbox>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.actionType !== currentValues.actionType ||
              prevValues.conditionType !== currentValues.conditionType
            }
          >
            {({ getFieldValue }) =>
              getFieldValue("conditionType") === ConditionTypeValue.FULFIL_UNIT &&
              getFieldValue("actionType") === ActionTypeValue.DISCOUNT && (
                <Form.Item name="recalculateDiscount" noStyle valuePropName="checked">
                  <Checkbox>每滿件一次即重新計算</Checkbox>
                </Form.Item>
              )
            }
          </Form.Item>
        </CheckboxArea>
      </Body>
    </Wrapper>
  );
};

export default RuleSetting;
