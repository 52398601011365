import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ListResponseType } from "src/types";
import financeOrderApi, {
  OrderListFilter,
  FinanceOrderDetail,
  ProductsListFilter,
  FinanceOrderList,
  FinanceOrderProduct,
} from "@api/financeOrderApi";
import { message } from "antd";
import throughShipmentApi, { RetailListResult, RetailPayload } from "@api/throughShipment/throughShipmentApi";
import type { RootState } from "./rootReducer";

interface FinanceOrderState {
  isFetching: boolean;
  isDeatilFetching: boolean;
  isRetailListFetching: boolean;
  isUploadSuccess: boolean;
  financeOrderListResult: ListResponseType<FinanceOrderList>;
  financeOrderDetail: FinanceOrderDetail;
  financeOrderProductResult: ListResponseType<FinanceOrderProduct>;
  orderListFilter: OrderListFilter;
  productsListFilter: ProductsListFilter;
  retailList: RetailListResult;
}

const initialState: FinanceOrderState = {
  isFetching: false,
  isDeatilFetching: false,
  isRetailListFetching: false,
  isUploadSuccess: false,
  financeOrderListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  financeOrderDetail: {
    id: 0,
    orderNumber: null,
    contractType: 0,
    retailerName: "",
    accountDate: "",
    invoiceNumber: "",
    totalAmount: 0,
    createdAt: "",
    createdBy: "",
    submittedAt: null,
    destroyedAt: null,
  },
  orderListFilter: {
    limit: 20,
    offset: 0,
  },
  retailList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  financeOrderProductResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  productsListFilter: {
    limit: 20,
    offset: 0,
  },
};

export const fetchFinanceOrderList = createAsyncThunk(
  "financeOrder/fetchFinanceOrderList",
  async (params: OrderListFilter, thunkApi) => {
    try {
      const response = await financeOrderApi.fetchFinanceOrderList(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchFinanceOrderDetail = createAsyncThunk(
  "financeOrder/fetchFinanceOrderDetail",
  async (orderId: string, thunkApi) => {
    try {
      const response = await financeOrderApi.fetchFinanceOrderDetail(orderId);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchOrderProducts = createAsyncThunk(
  "financeOrder/fetchOrderProducts",
  async (
    payload: {
      orderId: string;
      limit: number;
      offset: number;
    },
    thunkApi,
  ) => {
    try {
      const response = await financeOrderApi.fetchOrderProducts(payload);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchRetailList = createAsyncThunk(
  "externalOrder/fetchRetailList",
  async (payload: RetailPayload, thunkApi) => {
    try {
      const response = await throughShipmentApi.fetchRetailList(payload);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const submitFinanceOrder = createAsyncThunk(
  "externalOrder/submitFinanceOrder",
  async (orderId: string, thunkApi) => {
    try {
      await financeOrderApi.submitFinanceOrder(orderId);
      message.success("提交成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const deleteFinanceOrder = createAsyncThunk(
  "externalOrder/deleteFinanceOrder",
  async (orderId: number, thunkApi) => {
    try {
      const {
        financeOrderSlice: { orderListFilter },
      } = thunkApi.getState() as RootState;

      await financeOrderApi.deleteFinanceOrder(orderId);
      message.success("作廢成功");
      thunkApi.dispatch(fetchFinanceOrderList(orderListFilter));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const financeOrderSlice = createSlice({
  name: "financeOrder",
  initialState,
  reducers: {
    resetOrderFilter: () => initialState,
    setOrderListFilter: (state, action) => {
      state.orderListFilter = action.payload;
    },
    setProductsListFilter: (state, action) => {
      state.productsListFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFinanceOrderList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchFinanceOrderList.fulfilled, (state, action) => {
      state.financeOrderListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchRetailList.fulfilled, (state, action) => {
      state.retailList = action.payload;
      state.isRetailListFetching = false;
    });
    builder.addCase(fetchFinanceOrderDetail.pending, (state, action) => {
      state.isDeatilFetching = true;
    });
    builder.addCase(fetchFinanceOrderDetail.fulfilled, (state, action) => {
      state.isDeatilFetching = false;
      state.financeOrderDetail = action.payload;
    });
    builder.addCase(fetchOrderProducts.pending, (state, action) => {
      state.isDeatilFetching = true;
    });
    builder.addCase(fetchOrderProducts.fulfilled, (state, action) => {
      state.isDeatilFetching = false;
      state.financeOrderProductResult = action.payload;
    });
  },
});

export const { resetOrderFilter, setOrderListFilter, setProductsListFilter } = financeOrderSlice.actions;
export const financeOrderState = (state: RootState) => state.financeOrderSlice;
export default financeOrderSlice.reducer;
