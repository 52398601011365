import specialShipmentApi, {
  CarrierItem,
  CarrierParams,
  ProcessType,
  SpecialShipmentDetail,
  SpecialShipmentItem,
  SpecialShipmentListParams,
  SSProductItem,
  UpdateShipmentDetail,
  UpdateSSProductDetail,
} from "@api/sharing/specialShipmentApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { ListResponseType } from "src/types";

interface IState {
  isFetching: boolean;
  openShipmentFormType?: "create" | "update";
  specialShipmentList: ListResponseType<SpecialShipmentItem>;
  specialShipmentListParams: SpecialShipmentListParams;
  specialShipmentDetail?: SpecialShipmentDetail;
  carrierList?: CarrierItem[];
  specialShipmentProductList: ListResponseType<SSProductItem>;
  productParams: { limit: number; offset: number };
  createSpecialShipmentId?: number;
}
const initialState: IState = {
  isFetching: false,
  openShipmentFormType: undefined,
  specialShipmentList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  specialShipmentListParams: {
    limit: 20,
    offset: 0,
  },
  specialShipmentDetail: undefined,
  carrierList: undefined,
  specialShipmentProductList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  productParams: {
    limit: 20,
    offset: 0,
  },
};

// 出貨單：
export const fetchSpecialShipmentList = createAsyncThunk(
  "specialShipment/fetchSpecialShipmentList",
  async (params: SpecialShipmentListParams, thunkApi) => {
    try {
      const response = await specialShipmentApi.fetchSpecialShipmentList(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createSpecialShipment = createAsyncThunk(
  "specialShipment/createSpecialShipment",
  async (payload: UpdateShipmentDetail, thunkApi) => {
    try {
      const {
        specialShipmentSlice: { specialShipmentListParams },
      } = thunkApi.getState() as RootState;
      const response = await specialShipmentApi.createSpecialShipment(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchSpecialShipmentList(specialShipmentListParams));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const updateSpecialShipment = createAsyncThunk(
  "specialShipment/updateSpecialShipment",
  async (data: { shipmentId: number; payload: UpdateShipmentDetail }, thunkApi) => {
    try {
      const { shipmentId, payload } = data;
      const response = await specialShipmentApi.updateSpecialShipment(shipmentId, payload);
      message.success("更新成功");
      thunkApi.dispatch(fetchSpecialShipmentDetail(shipmentId));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const deleteSpecialShipment = createAsyncThunk(
  "specialShipment/deleteSpecialShipment",
  async (shipmentId: number, thunkApi) => {
    try {
      const {
        specialShipmentSlice: { specialShipmentListParams },
      } = thunkApi.getState() as RootState;
      const response = await specialShipmentApi.deleteSpecialShipment(shipmentId);
      thunkApi.dispatch(fetchSpecialShipmentList(specialShipmentListParams));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchCarrierList = createAsyncThunk(
  "specialShipment/fetchCarrierList",
  async (params: CarrierParams, thunkApi) => {
    try {
      const response = await specialShipmentApi.fetchCarrierList(params);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
// 出貨單詳情：
export const fetchSpecialShipmentDetail = createAsyncThunk(
  "specialShipment/fetchSpecialShipmentDetail",
  async (shipmentId: number, thunkApi) => {
    try {
      const response = await specialShipmentApi.fetchSpecialShipmentDetail(shipmentId);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const handleSpecialShipmentStatus = createAsyncThunk(
  "specialShipment/handleSpecialShipmentStatus",
  async (data: { shipmentId: number; processType: ProcessType }, thunkApi) => {
    try {
      const { shipmentId, processType } = data;
      const response = await specialShipmentApi.handleSpecialShipmentStatus(shipmentId, processType);
      thunkApi.dispatch(fetchSpecialShipmentDetail(shipmentId));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
// 出貨商品：
export const fetchSpecialShipmentProductList = createAsyncThunk(
  "specialShipment/fetchSpecialShipmentProductList",
  async (shipmentId: number, thunkApi) => {
    try {
      const {
        specialShipmentSlice: { productParams },
      } = thunkApi.getState() as RootState;
      const response = await specialShipmentApi.fetchSpecialShipmentProductList(shipmentId, productParams);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const createSpecialShipmentProduct = createAsyncThunk(
  "specialShipment/createSpecialShipmentProduct",
  async (data: { shipmentId: number; payload: UpdateSSProductDetail }, thunkApi) => {
    try {
      const { shipmentId, payload } = data;
      const response = await specialShipmentApi.createSpecialShipmentProduct(shipmentId, payload);
      thunkApi.dispatch(fetchSpecialShipmentProductList(shipmentId));
      thunkApi.dispatch(fetchSpecialShipmentDetail(shipmentId));
      message.success("新增成功");
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateSpecialShipmentProduct = createAsyncThunk(
  "specialShipment/updateSpecialShipmentProduct",
  async (data: { shipmentId: number; productId: number; payload: UpdateSSProductDetail }, thunkApi) => {
    try {
      const { shipmentId, productId, payload } = data;
      const response = await specialShipmentApi.updateSpecialShipmentProduct(shipmentId, productId, payload);
      thunkApi.dispatch(fetchSpecialShipmentProductList(shipmentId));
      thunkApi.dispatch(fetchSpecialShipmentDetail(shipmentId));
      message.success("更新成功");
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const deleteSpecialShipmentProduct = createAsyncThunk(
  "specialShipment/deleteSpecialShipmentProduct",
  async (data: { shipmentId: number; productId: number }, thunkApi) => {
    try {
      const { shipmentId, productId } = data;
      const response = await specialShipmentApi.deleteSpecialShipmentProduct(shipmentId, productId);
      thunkApi.dispatch(fetchSpecialShipmentProductList(shipmentId));
      thunkApi.dispatch(fetchSpecialShipmentDetail(shipmentId));
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const specialShipmentSlice = createSlice({
  name: "specialShipment",
  initialState,
  reducers: {
    resetSlice: () => initialState,
    updateListParams: (state, action) => {
      state.specialShipmentListParams = action.payload;
    },
    resetListParams: (state) => {
      state.specialShipmentListParams = initialState.specialShipmentListParams;
    },
    setOpenShipmentFormType: (state, action) => {
      state.openShipmentFormType = action.payload;
    },
    updateProductParams: (state, action) => {
      state.productParams = action.payload;
    },
    updateCreateSpecialShipmentId: (state, action) => {
      state.createSpecialShipmentId = action.payload;
    },
    updateCarrierList: (state, action) => {
      state.carrierList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSpecialShipmentList.pending, (state) => {
      state.isFetching = true;
    });
    // 出貨單list
    builder.addCase(fetchSpecialShipmentList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.specialShipmentList = action.payload;
    });
    builder.addCase(fetchSpecialShipmentList.rejected, (state) => {
      state.isFetching = false;
    });
    // 建立出貨單
    builder.addCase(createSpecialShipment.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createSpecialShipment.fulfilled, (state, action) => {
      state.isFetching = false;
      state.createSpecialShipmentId = action.payload;
    });
    builder.addCase(createSpecialShipment.rejected, (state) => {
      state.isFetching = false;
    });
    // 更新出貨單
    builder.addCase(updateSpecialShipment.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateSpecialShipment.fulfilled, (state) => {
      state.isFetching = false;
      state.openShipmentFormType = undefined;
    });
    builder.addCase(updateSpecialShipment.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCarrierList.fulfilled, (state, action) => {
      state.carrierList = action.payload;
    });
    builder.addCase(fetchSpecialShipmentDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSpecialShipmentDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.specialShipmentDetail = action.payload;
    });
    builder.addCase(fetchSpecialShipmentDetail.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchSpecialShipmentProductList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSpecialShipmentProductList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.specialShipmentProductList = action.payload;
    });
    builder.addCase(fetchSpecialShipmentProductList.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const {
  resetSlice,
  updateListParams,
  resetListParams,
  setOpenShipmentFormType,
  updateProductParams,
  updateCreateSpecialShipmentId,
  updateCarrierList,
} = specialShipmentSlice.actions;
export default specialShipmentSlice.reducer;

export const specialShipmentState = (state: RootState) => state.specialShipmentSlice;
