import { message } from "antd";
import { AxiosError } from "axios";
import CustomError from "./CustomeError";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

export default function normalizeAxiosError(axiosError: AxiosError): CustomError {
  if (axiosError.response) {
    // get server response, but status code is not 200
    const { data, status } = axiosError.response;
    return new CustomError(status, data.status_code, data.error_message, transformAPIKeyToCamel(data.result));
  }

  if (axiosError.request) {
    // no server response
    return new CustomError(0, 0, "伺服器沒有回應");
  }
  // something wrong with setting request
  return new CustomError(0, 0, "系統暫時無法處理您的請求");
}

export function handleCustomError(customError: CustomError): void {
  if (customError.message) {
    message.error(JSON.stringify(customError.message));
  }
}
