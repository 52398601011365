import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Rule } from "@api/promotionApi";
import { batchDeleteRules, updateSelectedRuleIds } from "@redux/promotionSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Modal, Table } from "antd";
import React, { FC, ReactText, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import AddRule from "./AddRule";

const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  padding: 15px 22px;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const DeleteButton = styled(Button)`
  position: absolute;
  left: 26px;
  bottom: 47px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const LinkText = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  margin: 0;
`;

const conditionTypeValue: {
  [type: number]: string;
} = {
  1: "滿額",
  2: "滿件",
};

const actionTypeValue: {
  [type: number]: string;
} = {
  1: "金額折抵",
  2: "折扣趴數",
  3: "免費贈品",
  4: "免費贈品(需綁定)",
  5: "加價購",
};

const ManageRule: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingDetail, promotionDetail, selectedRuleIds } = useSelector((state: RootState) => state.promotion);

  const [showAddRule, setShowAddRule] = useState<boolean>(false);

  const { id: promotionId, rules } = promotionDetail;
  const disableAddButton = rules.length >= 6;

  const handleOnCheck = (value: ReactText[]) => {
    dispatch(updateSelectedRuleIds(value as number[]));
  };

  const handleOnAdd = () => {
    if (!promotionId) {
      Modal.warning({
        title: "須先建立此筆資料，才能新增優惠",
        okText: "我知道了",
      });
    } else {
      toggleAddRule();
    }
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些優惠?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(batchDeleteRules());
      },
    });
  };

  const toggleAddRule = () => setShowAddRule((prev) => !prev);

  const tableColumns = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
      render: (value: Rule["id"]) => (
        <LinkText href={`/rule/edit/${value}`} target="_blank">
          {value}
        </LinkText>
      ),
    },
    {
      title: <TableNoWrap>名稱</TableNoWrap>,
      key: "title",
      dataIndex: "title",
    },
    {
      title: <TableNoWrap>方式</TableNoWrap>,
      key: "conditionType",
      dataIndex: "conditionType",
      render: (value: Rule["conditionType"]) => conditionTypeValue[value] || "無條件",
    },
    {
      title: <TableNoWrap>條件</TableNoWrap>,
      key: "conditionAmount",
      dataIndex: "conditionAmount",
      render: (value: Rule["conditionAmount"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>案型</TableNoWrap>,
      key: "actionType",
      dataIndex: "actionType",
      render: (value: Rule["actionType"]) => actionTypeValue[value] || "無條件",
    },
    {
      title: <TableNoWrap>折抵上限</TableNoWrap>,
      key: "discountLimit",
      dataIndex: "discountLimit",
      width: 80,
      render: (value: Rule["discountLimit"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>領取數量</TableNoWrap>,
      key: "maxBindTimes",
      dataIndex: "maxBindTimes",
      width: 80,
      render: (value: Rule["maxBindTimes"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>開放領取</TableNoWrap>,
      key: "bindOnceStartAt",
      dataIndex: "bindOnceStartAt",
      width: 150,
      render: (value: Rule["bindOnceStartAt"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>使用期限</TableNoWrap>,
      key: "useRuleDescription",
      dataIndex: "useRuleDescription",
      width: 150,
      render: (value: Rule["useRuleDescription"]) => value || "N/A",
    },
    {
      title: <TableNoWrap>頁面資訊</TableNoWrap>,
      key: "destinationLink",
      dataIndex: "destinationLink",
      render: (value: Rule["destinationLink"]) => (
        <LinkText href={value} target="_blank">
          {value}
        </LinkText>
      ),
    },
  ];

  return (
    <Wrapper>
      <TableHeader>
        <Button type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd} disabled={disableAddButton}>
          新增優惠
        </Button>
      </TableHeader>
      <TableContainer>
        <Table
          dataSource={rules}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedRuleIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingDetail}
          rowKey="id"
        />
        <DeleteButton onClick={handleOnBatchDelete} disabled={selectedRuleIds.length === 0}>
          刪除
        </DeleteButton>
      </TableContainer>
      {showAddRule && <AddRule onClose={toggleAddRule} />}
    </Wrapper>
  );
};

export default ManageRule;
