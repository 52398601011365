import { SalePlan, SalePlanOption } from "@api/saleApi";
import PopupBackground from "@component/PopupBackground";
import { fetchCreateChangePrice, fetchUpdateChangePrice, SaleState } from "@redux/saleSlice";
import MathRound from "@utils/MathRound";
import { Button, DatePicker, Input, Radio, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 80vh;
  overflow: auto;
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomRow = styled(Row)`
  margin-bottom: 12px;
`;

const CustomRadio = styled(Radio)<{ marginBottom?: string }>`
  display: block;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const CustomRadioGroup = styled(Radio.Group)`
  padding-top: 4px;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;

const CustomInput = styled(Input)<{ width?: string; gap?: boolean }>`
  width: ${({ width }) => width || "52px"};
  margin: ${({ gap }) => gap && "0px 10px"};
`;

const Text = styled.span`
  margin-right: 30px;
  min-width: 100px;
  padding: 8px 0;
  ${fontStyle("14px", "16px")};
`;

const Error = styled.span`
  color: #ec6922;
  position: absolute;
  left: 20px;
  bottom: 20px;
  ${fontStyle("14px", "16px")};
`;

enum RadioType {
  DEFAULT = 1,
  TIMING = 2,
}

type Props = {
  isLast: boolean;
  close: () => void;
  onNext: () => void;
  salePlanListResult: {
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  };
};

export default function ChangePriceModal(props: Props) {
  const { isLast, close, onNext, salePlanListResult } = props;

  const dispatch = useDispatch();
  const {
    salePageDetail,
    needReload,
    changePriceError,
    currentPlanChangePrice,
    planChangePriceListResult,
    optionListResult,
  } = useSelector(SaleState);

  const [salePlanMap, setSalePlanMap] = useState<Map<number, SalePlan>>(new Map());
  const [salePlan, setSalePlan] = useState<SalePlan>();
  const [optionDiscountPeriod, setOptionDiscountPeriod] = useState<moment.Moment[]>([]);
  const [radio, setRadio] = useState<RadioType>(RadioType.DEFAULT);
  const [error, setError] = useState<string>("");
  const [pageTypeAnyField, setPageTypeAnyField] = useState<{
    specialPrice?: number;
    commissionRate?: number;
  }>({
    specialPrice: undefined,
    commissionRate: undefined,
  });
  const [updateOptions, setUpdateOptions] = useState<SalePlanOption[]>([]);

  const computedPriceDiscount = useMemo(() => {
    if (salePlan && pageTypeAnyField?.specialPrice) {
      return Math.floor(((salePlan?.price - pageTypeAnyField?.specialPrice) / salePlan?.optionsCount) * 10000) / 10000;
    }
    return "N/A";
  }, [salePlan, pageTypeAnyField]);

  const onSubmit = () => {
    const isExist = planChangePriceListResult.results.some((item) => item.id === salePlan?.id);

    if (isExist && (pageTypeAnyField?.specialPrice ?? 0) > (salePlan?.price ?? 0)) {
      setError("優惠價必須小於售價");
      return;
    }

    if (radio === RadioType.TIMING && optionDiscountPeriod.length === 0) {
      setError("請填入時間區間");
      return;
    }

    if (currentPlanChangePrice && updateOptions.length === 0) {
      if (!pageTypeAnyField.specialPrice) {
        setError("方案優惠價必填");
        return;
      }

      dispatch(
        fetchUpdateChangePrice({
          salesPlan: salePlan!.id,
          startAt: radio === RadioType.TIMING ? optionDiscountPeriod[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
          endAt: radio === RadioType.TIMING ? optionDiscountPeriod[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
          specialPrice: pageTypeAnyField.specialPrice,
          commissionRateDiscount:
            pageTypeAnyField.commissionRate !== undefined
              ? MathRound(pageTypeAnyField.commissionRate || 0, 1)
              : undefined,
          optionsDiscountsIds: currentPlanChangePrice.optionsInfo.map((item) => item.optionsDiscountsId),
          optionsDiscounts: [],
        }),
      );
    } else {
      const checkSpecialPrice = isLast ? !pageTypeAnyField.specialPrice : pageTypeAnyField.specialPrice === 0;
      if (checkSpecialPrice) {
        setError(isLast ? "方案優惠價必填" : "方案優惠價不可為0");
        return;
      }

      dispatch(
        fetchCreateChangePrice({
          salesPlan: salePlan!.id,
          startAt: radio === RadioType.TIMING ? optionDiscountPeriod[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
          endAt: radio === RadioType.TIMING ? optionDiscountPeriod[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
          specialPrice: pageTypeAnyField.specialPrice,
          commissionRateDiscount:
            pageTypeAnyField.commissionRate !== undefined
              ? MathRound(pageTypeAnyField.commissionRate || 0, 1)
              : undefined,
          optionsDiscounts: [],
        }),
      );
    }
  };

  useEffect(() => {
    // 編輯初始化
    if (currentPlanChangePrice) {
      if (currentPlanChangePrice.startAt) {
        setRadio(RadioType.TIMING);
        setOptionDiscountPeriod([moment(currentPlanChangePrice.startAt), moment(currentPlanChangePrice.endAt)]);
      } else {
        setRadio(RadioType.DEFAULT);
      }

      const firstRate = currentPlanChangePrice.optionsInfo.find((item) => item.commissionRateDiscount > 0);
      const currentSalePlan = salePlanMap.get(currentPlanChangePrice.id)!;
      if (currentSalePlan) {
        setSalePlan(currentSalePlan);
        const optionCount = currentPlanChangePrice.price / currentPlanChangePrice.optionsInfo[0].optionUnitPrice;

        const specialPrice =
          currentPlanChangePrice.optionsInfo[0].optionUnitPrice * optionCount -
          Number((currentPlanChangePrice.optionsInfo[0].priceDiscount * optionCount).toFixed(0));

        setPageTypeAnyField({
          specialPrice: specialPrice ?? 0,
          commissionRate: firstRate ? firstRate.commissionRateDiscount : 0,
        });
      }
    }
  }, [currentPlanChangePrice, salePlanMap]);

  useEffect(() => {
    if (!currentPlanChangePrice) {
      setSalePlan(salePlanListResult.results[0]);
    }

    salePlanListResult.results.forEach((item) => {
      setSalePlanMap((prev) => new Map(prev.set(item.id, item)));
    });
  }, [salePlanListResult.results, currentPlanChangePrice]);

  useEffect(() => {
    if (salePlan) {
      setError("");

      if (changePriceError) {
        setError(changePriceError);
      }
    }
  }, [salePlan, changePriceError, salePageDetail, pageTypeAnyField]);

  useEffect(() => {
    if (needReload) {
      onNext();
    }
  }, [needReload, onNext]);

  useEffect(() => {
    // 多繞這一層的原因是
    // 如果新增變價過後又想增加 plan option，照上面的邏輯會進到 update，但是 update 沒有 optionsDiscountsId
    // 這樣會有問題，所以新增 plan option 就算在 create 那邊
    if (salePlan && currentPlanChangePrice) {
      const set = new Set();
      currentPlanChangePrice.optionsInfo.forEach((item) => {
        set.add(item.optionId);
      });
      salePlan.options.forEach((item) => {
        if (!set.has(item.id)) {
          setUpdateOptions((prev) => [...prev, item]);
        }
      });
    }
  }, [salePlan, currentPlanChangePrice]);

  return (
    <PopupBackground close={close} fixed>
      <Wrapper>
        <CustomRow align="middle" wrap={false}>
          <Text>方案名稱</Text>
          <CustomSelect
            value={salePlan?.id}
            onChange={(id) => {
              setSalePlan(salePlanMap.get(parseInt(id as string, 10))!);
              setPageTypeAnyField((prev) => ({ ...prev, specialPrice: undefined }));
            }}
          >
            {salePlanListResult.results.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </CustomSelect>
        </CustomRow>
        <CustomRow>
          <Text>方案售價</Text>
          <Text>{`$${salePlan?.price}`}</Text>
        </CustomRow>
        <CustomRow>
          <Text>方案優惠價</Text>
          <CustomInput
            width="70px"
            value={pageTypeAnyField.specialPrice}
            type="number"
            onChange={(e) => setPageTypeAnyField((prev) => ({ ...prev, specialPrice: parseInt(e.target.value, 10) }))}
          />
        </CustomRow>
        <CustomRow align="middle" wrap={false}>
          <Text>每入折扣金額</Text>
          {computedPriceDiscount}
        </CustomRow>
        <CustomRow align="middle" wrap={false}>
          <Text>方案抽成</Text>
          {/* 0831 抽成％數預設從 option 來，所有 option 會有同個 commissionRate */}
          <span>{`${optionListResult.results[0].vpcInfo.commissionRate}% -`}</span>
          <CustomInput
            width="70px"
            gap
            type="number"
            value={pageTypeAnyField.commissionRate}
            onChange={(e) => setPageTypeAnyField((prev) => ({ ...prev, commissionRate: parseFloat(e.target.value) }))}
          />
          <span>%</span>
        </CustomRow>
        <CustomRow>
          <Text>優惠時間</Text>
          <CustomRadioGroup value={radio} onChange={(e) => setRadio(e.target.value)}>
            <CustomRadio marginBottom="10px" value={RadioType.DEFAULT}>
              預設
            </CustomRadio>
            <CustomRadio value={RadioType.TIMING}>
              <DatePicker.RangePicker
                locale={locale}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                }}
                value={optionDiscountPeriod as any}
                onFocus={() => setRadio(RadioType.TIMING)}
                onChange={(val) => setOptionDiscountPeriod(val as moment.Moment[])}
              />
            </CustomRadio>
          </CustomRadioGroup>
        </CustomRow>
        <Row justify="end">
          <CustomButton onClick={close}>取消</CustomButton>
          <Button type="primary" onClick={onSubmit}>
            {isLast ? "確認" : "下一步"}
          </Button>
        </Row>
        <Error>{error}</Error>
      </Wrapper>
    </PopupBackground>
  );
}
