import { AxiosResponse } from "axios";
import moment from "moment";

/**
 * @see https://gist.github.com/dreamyguy/6b4ab77d2f118adb8a63c4a03fba349d
 * @param response
 */
export default function downloadCSV(response: AxiosResponse) {
  const csvBlob = new Blob([response.data], { type: "text/csv" });
  const csvBlobUrl = window.URL.createObjectURL(csvBlob);
  const downloadLink = document.createElement("a");
  const cvsFileName = response.headers["content-disposition"].replace("attachment; filename*=utf-8''", "");
  downloadLink.style.display = "none";
  downloadLink.href = csvBlobUrl;
  downloadLink.setAttribute(
    "download",
    decodeURIComponent(cvsFileName) || `file_export.${moment().format("YYYY-MM-DD")}.csv`,
  );
  if (typeof downloadLink.download === "undefined") {
    downloadLink.setAttribute("target", "_blank");
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
  setTimeout(() => {
    window.URL.revokeObjectURL(csvBlobUrl);
  }, 100);
}
