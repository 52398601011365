import React, { useEffect } from "react";
import { Button, Col, DatePicker, Form, Input, Select } from "antd";
import styled from "styled-components";
import PopupBackground from "@component/PopupBackground";
import cityRegionData from "@utils/CityRegionData";
import moment from "moment";
import { fetchCreateOrder, fetchUpdateOrder } from "@redux/onlineOrderSlice";
import { useDispatch } from "react-redux";
import { OrderDetail } from "@api/throughShipment/onlineOrderApi";
import getPostCodeWithCVS from "@utils/getPostCodeWithCVS";
import { useParams } from "react-router-dom";

const FormWrapper = styled(Form)`
  width: 500px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colorNeutral100};
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 5px;
  }
`;
const Reminder = styled.p`
  color: #ec6c27;
`;
const CityWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Row = styled.div`
  display: flex;
`;
const AddWrapper = styled.div`
  width: 100%;
`;
type Props = {
  onClose: () => void;
  onlineOrderDetail?: OrderDetail;
};
const EditPopup = (props: Props) => {
  const { onClose, onlineOrderDetail } = props;
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [form] = Form.useForm();
  const allCities = Object.keys(cityRegionData);

  const platform = Form.useWatch("platform", form);
  const isB2C = platform?.startsWith("B2C");

  const currentCity = Form.useWatch("city", form);

  const handleSelectCity = () => {
    form.setFieldsValue({ region: undefined, address: undefined });
  };

  const renderRegionOptions = (city: string) => {
    if (!city || !cityRegionData[city]) {
      return [];
    }
    const allRegions = Object.keys(cityRegionData[city]);
    return allRegions.map((region) => (
      <Select.Option value={region} key={region}>
        {region}
      </Select.Option>
    ));
  };

  useEffect(() => {
    form.setFieldsValue({ warehouse: "藍田" });
  }, [form]);

  const handleOnSubmit = (formValue: any) => {
    const params = {
      orderId,
      warehouse: formValue.warehouse,
      platform: formValue.platform,
      grandTotal: isB2C ? formValue.grandTotal : 0,
      estimatedShippingDate: formValue.estimatedShippingDate
        ? moment(formValue.estimatedShippingDate).format("YYYY-MM-DD")
        : "",
      estimatedArrivalDate: formValue.estimatedArrivalDate
        ? moment(formValue.estimatedArrivalDate).format("YYYY-MM-DD")
        : "",
      platformNumber: formValue.platformNumber,
      shippingMethod: formValue.shippingMethod,
      receiverName: formValue.receiverName,
      receiverPhone: formValue.receiverPhone,
      receiverZipcode: cityRegionData[formValue.city][formValue.region],
      receiverAddress: `${formValue.city}${formValue.region}${formValue.address}`,
      note: formValue.note,
    };
    if (onlineOrderDetail) {
      // 編輯
      dispatch(fetchUpdateOrder(params));
    } else {
      // 新增
      dispatch(fetchCreateOrder(params));
    }

    onClose();
  };

  const selectedPlatform: {
    [key: string]: {
      receiverName: string;
      receiverPhone: string;
      city: string;
      region: string;
      address: string;
    };
  } = {
    "B2B-PChome": {
      receiverName: "15庫-進貨組",
      receiverPhone: "02-27000898#3905",
      city: "桃園市",
      region: "龜山區",
      address: "文信路288號",
    },
    "B2B-博客來": {
      receiverName: "周姚伶",
      receiverPhone: "02-27821100 #844",
      city: "新北市",
      region: "樹林區",
      address: "佳園路二段70-1號(大智通物流中心)",
    },
    "B2B-Yahoo": {
      receiverName: "雅虎進倉區",
      receiverPhone: "03-3891316 #8121",
      city: "桃園市",
      region: "大溪區",
      address: "仁和路二段350號",
    },
    "B2B-MOMO": {
      receiverName: "MOMO進貨組",
      receiverPhone: "02-6600-7600",
      city: "台北市",
      region: "北投區",
      address: "承德路七段400號",
    },
    "B2B-蝦皮寄倉": {
      receiverName: "樂購商城-進貨組",
      receiverPhone: "03-262-6560 #3151",
      city: "桃園市",
      region: "楊梅區",
      address: "和平路576號 (物流共和國-楊梅-2B)",
    },
  };

  const handleOnPlatformChange = (value: string) => {
    if (value.startsWith("B2B")) {
      const selectedValue = selectedPlatform[value];
      form.setFieldsValue({
        receiverName: selectedValue.receiverName,
        receiverPhone: selectedValue.receiverPhone,
        city: selectedValue.city,
        region: selectedValue.region,
        address: selectedValue.address,
      });
    } else {
      form.setFieldsValue({
        receiverName: undefined,
        receiverPhone: undefined,
        city: undefined,
        region: undefined,
        address: undefined,
      });
    }
  };

  return (
    <PopupBackground close={onClose}>
      <FormWrapper
        labelAlign="left"
        colon={false}
        onFinish={handleOnSubmit}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 20 }}
        initialValues={{
          ...onlineOrderDetail,
          estimatedShippingDate: moment(onlineOrderDetail?.estimatedShippingDate),
          estimatedArrivalDate: moment(onlineOrderDetail?.estimatedArrivalDate),
          city: onlineOrderDetail && getPostCodeWithCVS(onlineOrderDetail.receiverAddress).city,
          region: onlineOrderDetail && getPostCodeWithCVS(onlineOrderDetail.receiverAddress).town,
          address: onlineOrderDetail && getPostCodeWithCVS(onlineOrderDetail.receiverAddress).address,
        }}
      >
        <Form.Item label="出庫倉庫" name="warehouse" rules={[{ required: true, message: "" }]}>
          <Select value={1}>
            <Select.Option value="藍田">藍田</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate label="通路平台" name="platform" rules={[{ required: true, message: "" }]}>
          <Select placeholder="請選擇" onChange={handleOnPlatformChange}>
            <Select.Option value="B2B-PChome">B2B-PChome</Select.Option>
            <Select.Option value="B2B-博客來">B2B-博客來</Select.Option>
            <Select.Option value="B2B-Yahoo">B2B-Yahoo</Select.Option>
            <Select.Option value="B2B-MOMO">B2B-MOMO</Select.Option>
            <Select.Option value="B2B-蝦皮寄倉">B2B-蝦皮寄倉</Select.Option>
            <Select.Option value="B2C-PChome">B2C-PChome</Select.Option>
            <Select.Option value="B2C-康是美">B2C-康是美</Select.Option>
            <Select.Option value="B2C-誠品">B2C-誠品</Select.Option>
            <Select.Option value="B2C-迪卡儂">B2C-迪卡儂</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate label="訂單總額" name="grandTotal" rules={[{ required: isB2C, message: "" }]}>
          {isB2C ? <Input /> : "-"}
        </Form.Item>
        <Form.Item
          label="預計出貨日"
          name="estimatedShippingDate"
          extra={<Reminder>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</Reminder>}
        >
          <DatePicker placeholder="請選擇日期" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          label="預計到貨日"
          name="estimatedArrivalDate"
          extra={<Reminder>*此資訊用於顯示倉庫備註中，僅供倉庫人員參考</Reminder>}
        >
          <DatePicker placeholder="請選擇日期" style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="平台訂單編號" name="platformNumber" rules={[{ required: true, message: "" }]}>
          <Input maxLength={16} placeholder="最多16字元，只能輸入中,英,數,_" />
        </Form.Item>
        <Form.Item label="運送方式" name="shippingMethod" rules={[{ required: true, message: "" }]}>
          <Select placeholder="請選擇">
            <Select.Option value="新竹物流">新竹物流</Select.Option>
            <Select.Option value="黑貓">黑貓</Select.Option>
            <Select.Option value="全盛貨運(邱哥)">全盛貨運(邱哥)</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="收件人" name="receiverName" rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="收件電話" name="receiverPhone" rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
        <Row>
          <Col span={6}>
            <span>收件地址</span>
            <RequiredIcon>*</RequiredIcon>
          </Col>
          <AddWrapper>
            <CityWrapper>
              <Form.Item
                wrapperCol={{ span: 24 }}
                name="city"
                rules={[{ required: true, message: "" }]}
                style={{ margin: "0px" }}
              >
                <Select placeholder="請選擇縣市" style={{ width: "150px" }} onChange={handleSelectCity}>
                  {allCities.map((cityName) => (
                    <Select.Option value={cityName} key={cityName}>
                      {cityName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 24 }}
                name="region"
                rules={[{ required: true, message: "" }]}
                style={{ margin: "0px" }}
              >
                <Select placeholder="請選擇區域" style={{ width: "150px" }}>
                  {renderRegionOptions(currentCity)}
                </Select>
              </Form.Item>
            </CityWrapper>
            <Form.Item wrapperCol={{ span: 24 }} name="address" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </AddWrapper>
        </Row>
        <Form.Item label="備註" name="note">
          <Input placeholder="最少5字，最多50字" minLength={5} maxLength={50} />
        </Form.Item>
        <Footer>
          <Button
            style={{
              marginRight: "14px",
            }}
            onClick={() => onClose()}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            儲存
          </Button>
        </Footer>
      </FormWrapper>
    </PopupBackground>
  );
};

export default EditPopup;
