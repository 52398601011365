import CoreAPI from "../CoreAPI";
import { transformCamelToSnake } from "../utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "../utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

export enum TradeTermValue {
  EXW = 1,
  FCA = 2,
  FAS = 3,
  FOB = 4,
}

export interface PcmList {
  count: number;
  next: string | null;
  previous: string | null;
  results: PcmItem[];
  totalAmount: number;
  totalPayment: number;
}

export interface PcmDetail {
  id: number;
  pcmNumber: string;
  producerName: string;
  producerId: number;
  staff: {
    id: number;
    name: string;
  };
  totalEstPcmAmount: number;
  status: number;
  sectionManagerApproval: number;
  managerApproval: number;
  createdDate: string;
  deployDate: string | null;
  currency: number;
  currencyRate: number;
  tradeTerm: number;
}
export interface Cashflow {
  estPaymentDate: string | null;
  estPaymentAmount: number;
  paymentDate: string | null;
  paymentAmount: number;
}

export interface PcmLines {
  id: number;
  no: string | null;
  name: string | null;
  nameEn: string | null;
  effectiveDate: string;
  price: number;
  orderPcs: number;
  estPcmAmount: number;
  //
  pcsPerCarton?: number;
  orderedQty?: number;
  rate?: number;
  pcmStatus?: number;

  //
  estArrivedDate: string | null;
  arrivedQty: string | null;
  arrivedDate: string | null;
  pcmAmount: string | null;
  cashflows: {
    estPaymentDate?: string | null;
    estPaymentAmount?: number;
    paymentDate?: string | null;
    paymentAmount?: number;
  }[];
}

export interface PcmItem {
  id: number;
  pcmNumber: string;
  producerName: string;
  createdDate: string;
  status: number;
  deployDate: string | null;
  pcmLines: {
    id: number;
    no: string | null;
    name: string | null;
    nameEn: string | null;
    effectiveDate: string;
    price: number;
    orderPcs: number;
    estPcmAmount: number;
    estArrivedDate: string | null;
    arrivedQty: string | null;
    arrivedDate: string | null;
    pcmAmount: string | null;
    cashflows: {
      estPaymentDate?: string | null;
      estPaymentAmount?: number;
      paymentDate?: string | null;
      paymentAmount?: number;
    }[];
  }[];
}

export interface PcmListParams {
  limit: number;
  offset: number;
  no?: string;
  producer?: string;
  status?: number;
  pcmNumberQ?: string;
  deployDateBefore?: string;
  deployDateAfter?: string;
  paymentDateBefore?: string;
  paymentDateAfter?: string;
}

// 商品
export interface ProductList {
  count: number;
  next: string | null;
  previous: string | null;
  results: any[];
}
export interface ProductDetail {
  id: number;
  procurementId: number;
  no: string;
  name: string;
  nameEn: string | null;
  effectiveDate: string | null;
  price: number;
  orderedQty: number;
  pcsPerCarton: number;
  orderedPcs: number;
  arrivedDate: string | null;
  arrivedQty: string | null;
  cashflows: [
    {
      id: number;
      procurementLineId: number;
      status: number;
      days: number;
      ratio: number;
    },
  ];
}

export interface AddPcmParams {
  producer?: number;
  currency?: number;
  currencyRate: number;
  tradeTerm: number;
}

export interface AddItemParams {
  id?: number;
  no?: string;
  effectiveDate?: string | null;
  price?: number;
  orderedPcs?: number;
  arrivedQty?: number;
  arrivedDate?: string | null;
  cashflows?: [
    {
      status: number;
      days: number;
      ratio: number;
    },
  ];
}

export interface PcmApi {
  // 採購單
  fetchPcmList: (params: any) => Promise<PcmList>;
  fetchPcmDetail: (pcmId: number | string) => Promise<PcmDetail>;
  fetchAddPcm: (params: AddPcmParams) => Promise<any>;
  fetchUpdatePcm: (params: { updatePcmParams: AddPcmParams; pcmId: string }) => Promise<any>;
  fetchDeletePcm: (pcmId: string) => Promise<any>;
  fetchProcess: (params: { processType: number; id: number }) => Promise<any>;
  // 採購商品
  fetchProductsList: (params: any) => Promise<ProductList>;
  fetchProductDetail: (params: { pcmId: string; productId: number }) => Promise<ProductDetail>;
  fetchAddProduct: (params: { addItem: AddItemParams; pcmId: string }) => Promise<any>;
  fetchCheckProduct: (params: { no: string; producer: number }) => Promise<any>;
  fetchDeleteProduct: (params: { pcmId: string; productId: number }) => Promise<any>;
  importProductsCSV: (params: { pcmId: string; file: File }) => Promise<any>;
}

const pcmApi: PcmApi = {
  // 採購單
  fetchPcmList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/procurement/pcms/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchPcmDetail: async (pcmId) => {
    const response = await apiClient.getData(`/tryall/manage/procurement/pcms/${pcmId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddPcm: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/tryall/manage/procurement/pcms/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdatePcm: async (params) => {
    const payload = transformCamelToSnake(params.updatePcmParams);
    const response = await apiClient.putData(`/tryall/manage/procurement/pcms/${params.pcmId}/`, payload);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDeletePcm: async (pcmId) => {
    const response = await apiClient.deleteData(`/tryall/manage/procurement/pcms/${pcmId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProcess: async (params) => {
    const { processType } = params;
    const response = await apiClient.putData(`/tryall/manage/procurement/pcms/${params.id}/process/`, {
      process_type: processType,
    });
    return transformAPIKeyToCamel(response.data.result);
  },

  // 採購商品
  fetchProductsList: async (id) => {
    const response = await apiClient.getData(`/tryall/manage/procurement/pcms/${id}/pcm-lines`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductDetail: async (params) => {
    const response = await apiClient.getData(
      `/tryall/manage/procurement/pcms/${params.pcmId}/pcm-lines/${params.productId}/`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddProduct: async (params) => {
    const requestParams = transformCamelToSnake(params.addItem);
    const response = await apiClient.postData(
      `/tryall/manage/procurement/pcms/${params.pcmId}/pcm-lines/batch/`,
      requestParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCheckProduct: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/procurement/pcms/check/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDeleteProduct: async (params) => {
    await apiClient.deleteData(`/tryall/manage/procurement/pcms/${params.pcmId}/pcm-lines/${params.productId}/`, {});
  },
  importProductsCSV: async (params) => {
    const { file } = params;
    const formData = new FormData();
    formData.append("file", file);

    const response = await apiClient.postData(
      `/tryall/manage/procurement/pcms/${params.pcmId}/pcm-lines/upload/`,
      formData,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default pcmApi;
