import React from "react";
import styled from "styled-components";
import { Button, Form, Row, DatePicker, Select, Col, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { invoiceState, setInvoiceFilter } from "@redux/finance/invoiceSlice";
import { ShippingTypeValueByNumber } from "@api/shippingFeeApi";
import { LogisticHistoriesNumber } from "@page/Order/LogisticHistories";
import { PaymentMethodIdValue } from "@page/Order/PaymentMethodValue";

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 300px;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function Filter() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { invoiceFilter } = useSelector(invoiceState);

  const onSubmit = () => {
    const formState = form.getFieldsValue();

    dispatch(
      setInvoiceFilter({
        ...invoiceFilter,
        invoiceStartDate: formState.invoiceTimePeriod && formState.invoiceTimePeriod[0]?.format("YYYY-MM-DD"),
        invoiceEndDate: formState.invoiceTimePeriod && formState.invoiceTimePeriod[1]?.format("YYYY-MM-DD"),
        pickupStartDate: formState.pickupTimePeriod && formState.pickupTimePeriod[0]?.format("YYYY-MM-DD"),
        pickupEndDate: formState.pickupTimePeriod && formState.pickupTimePeriod[1]?.format("YYYY-MM-DD"),
        capturedStartDate: formState.capturedTimePeriod && formState.capturedTimePeriod[0].format("YYYY-MM-DD"),
        capturedEndDate: formState.capturedTimePeriod && formState.capturedTimePeriod[1].format("YYYY-MM-DD"),
        shippingMethod: formState.shippingMethod,
        paymentMethod: formState.paymentMethod,
        shippingStatus: formState.shippingStatus,
        sku: formState.sku,
      }),
    );
  };

  const onReset = () => {
    form.resetFields();
    dispatch(
      setInvoiceFilter({
        ...invoiceFilter,
        invoiceStartDate: null,
        invoiceEndDate: null,
        pickupStartDate: null,
        pickupEndDate: null,
        capturedStartDate: null,
        capturedEndDate: null,
        shippingMethod: null,
        paymentMethod: null,
        shippingStatus: null,
        sku: null,
      }),
    );
  };

  return (
    <Wrapper labelCol={{ span: 5 }} colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row>
        <Col span={10}>
          <Form.Item label="開立發票時間" name="invoiceTimePeriod">
            <CustomRangePicker picker="date" />
          </Form.Item>
          <Form.Item label="請款時間" name="capturedTimePeriod">
            <CustomRangePicker picker="date" />
          </Form.Item>
          <Form.Item label="取件時間" name="pickupTimePeriod">
            <CustomRangePicker picker="date" />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="物流方式" name="shippingMethod">
            <Select>
              <Select.Option value="-1" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ShippingTypeValueByNumber.HOME_DELIVERY}>宅配</Select.Option>
              <Select.Option value={ShippingTypeValueByNumber.CVS}>全家</Select.Option>
              <Select.Option value={ShippingTypeValueByNumber.SEVEN_ELEVEN}>7-11</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="付款方式" name="paymentMethod">
            <Select>
              <Select.Option value="-1" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={PaymentMethodIdValue.CASHONDELIVERY}>貨到付款</Select.Option>
              <Select.Option value={PaymentMethodIdValue.CREDIT_CARD}>信用卡</Select.Option>
              <Select.Option value={PaymentMethodIdValue.LINEPAY}>LinePay</Select.Option>
              <Select.Option value={PaymentMethodIdValue.JKOPAY}>街口</Select.Option>
              <Select.Option value={PaymentMethodIdValue.PXPAY_PLUS}>全支付</Select.Option>
              <Select.Option value={PaymentMethodIdValue.ATOME}>Atome</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="物流狀態" name="shippingStatus">
            <Select>
              <Select.Option value="-1" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={LogisticHistoriesNumber.SHIPPING}>配送中</Select.Option>
              <Select.Option value={LogisticHistoriesNumber.ARRIVED}>到達門市</Select.Option>
              <Select.Option value={LogisticHistoriesNumber.PICKED_UP}>已取件</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="國際條碼" name="sku">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
}
