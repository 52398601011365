import { FilterTwoTone } from "@ant-design/icons";
import { OrderList } from "@api/utils/normalizeOrder";
import { fetchOrderList, orderState, resetOrderSlice, setOrderListFilter } from "@redux/orderSlice";
import { Button, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-use";
import fontStyle from "src/styles/fontStyle";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import CVSRedirect from "./CVSRedirect";
import OrderContentFilter from "./OrderContentFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

export default function IntoWarehouse() {
  const location = useLocation();

  const dispatch = useDispatch();
  const { isFetching, orderListFilter, orderListResult } = useSelector(orderState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const handleOnPageSizeChange = (value: SelectValue) => {
    dispatch(setOrderListFilter({ ...orderListFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchOrderList({ ...orderListFilter, page: value }));
  };

  const columns: ColumnsType<OrderList> = [
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "訂單編號",
    },
    {
      key: "shippingStatusText",
      dataIndex: "shippingStatusText",
      title: "訂單狀態",
    },
    {
      key: "orderTime",
      dataIndex: "orderTime",
      title: "訂購時間",
      render: (orderTime: number) => <div>{moment(orderTime * 1000).format("YYYY-MM-DD HH:mm:ss")}</div>,
    },
    {
      key: "grandTotal",
      dataIndex: "grandTotal",
      title: "主訂單總金額",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "會員名稱",
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "會員電話",
    },
    {
      key: "memberGroupText",
      dataIndex: "memberGroupText",
      title: "會員資格",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_: any, record: OrderList) => (
        <Button type="link" onClick={() => window.open(`${window.location.href}/${record.id}`)}>
          檢視
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(fetchOrderList({ ...orderListFilter, page: 1 }));
  }, [dispatch, orderListFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetOrderSlice());
    };
  }, [dispatch]);

  if (location.search) {
    /* 全家 */
    const { cvstemp } = qs.parse(location.search);
    return <CVSRedirect redirectPath={`${cvstemp}/${location.search}`} />;
  }

  return (
    <Wrapper>
      <PageTitle title="訂單內容" />
      <Row justify="space-between">
        <div>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </div>
        <Row align="middle">
          <PageText>{`總共${orderListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <OrderContentFilter isVisible={showFilter} />}
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={orderListResult.results}
          pagination={{
            pageSize: orderListFilter.limit,
            current: page,
            showSizeChanger: false,
            total: orderListResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
