import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import cohortApi, { BrandList, BrandFilter, UtmList, CohortFilter, CohortListResult } from "@api/cohortApi";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  brandList: BrandList;
  searchedBrandList: BrandList;
  utmList: UtmList;
  cohortListResult: CohortListResult[];
  cohortFilter: CohortFilter;
}

export const initialState: IState = {
  isFetching: false,
  brandList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  searchedBrandList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  utmList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  cohortListResult: [
    {
      cohortMonth: "",
      totalMemberCount: 0,
      totalLTV: 0,
      ARPU: 0,
      MList: [],
    },
  ],
  cohortFilter: {
    cohortStart: undefined,
    cohortEnd: undefined,
    groupId: undefined,
    sourceMediumIds: undefined,
    platforms: undefined,
    promotionRuleIds: undefined,
    couponCodes: undefined,
    tagIds: undefined,
  },
};

export const fetchBrandList = createAsyncThunk("cohort/fetchBrandList", async (param: BrandFilter, thunkApi) => {
  const response = await cohortApi.fetchBrandList(param);
  return response;
});

export const fetchSearchBrandList = createAsyncThunk("cohort/fetchSearchBrandList", async (name: string, thunkApi) => {
  const response = await cohortApi.fetchSearchBrandList(name);
  return response;
});

export const fetchUtmSourseList = createAsyncThunk("cohort/fetchUtmSourseList", async (_, thunkApi) => {
  const response = await cohortApi.fetchUtmSourseList();
  return response;
});

export const fetchCohortList = createAsyncThunk("cohort/fetchCohortList", async (param: CohortFilter, thunkApi) => {
  const response = await cohortApi.fetchCohortList(param);
  return response;
});

const cohortSlice = createSlice({
  name: "cohort",
  initialState,
  reducers: {
    resetCohortSlice: () => initialState,
    setCohortFilter: (state, action) => {
      state.cohortFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.brandList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSearchBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSearchBrandList.fulfilled, (state, action) => {
      state.searchedBrandList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchUtmSourseList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUtmSourseList.fulfilled, (state, action) => {
      state.utmList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchCohortList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCohortList.fulfilled, (state, action) => {
      state.cohortListResult = action.payload;
      state.isFetching = false;
    });
  },
});

export const { setCohortFilter, resetCohortSlice } = cohortSlice.actions;
export const cohortState = (state: RootState) => state.cohortSlice;
export default cohortSlice.reducer;
