enum GenderValue {
  FEMALE = 0,
  MALE = 1,
  OTHERS = 2,
}

export const genderMap = {
  [GenderValue.FEMALE]: "女",
  [GenderValue.MALE]: "男",
  [GenderValue.OTHERS]: "其它",
};

export default GenderValue;
