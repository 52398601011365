import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { exportCSVParams } from "@api/financeApi";
import { message } from "antd";
import { hideLoading, showLoading } from "@redux/notifySlice";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
}

export const initialState: IState = {
  isFetching: false,
};

export const exportCSV = createAsyncThunk(
  "statisticsDownloadSlice/exportCSV",
  async (params: exportCSVParams, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await financeApi.exportCSV(params);
      message.success("匯出資料準備中，由於資料複雜請稍待，成功後會寄信通知");
      return response;
    } catch (error: any) {
      throw thunkApi.rejectWithValue("error");
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
const StatisticsDownloadSlice = createSlice({
  name: "statisticsDownloadSlice",
  initialState,
  reducers: {
    reset: () => initialState,
  },
});

export const StatisticsDownloadState = (state: RootState) => state.statisticsDownloadSlice;
export default StatisticsDownloadSlice.reducer;
