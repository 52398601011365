import PageTitle from "@component/PageTitle";
import { resetCostStatementSlice } from "@redux/finance/costStatementSlice";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { TabList } from "./constant";
import CostBuyOut from "./CostBuyOut";
import CostWareHouseAndTransfer from "./CostWareHouseAndTransfer";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;
const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

const CostStatement = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<string>(TabList.WAREHOUSE_CONSIGN_AND_TRANSFER);

  useEffect(() => {
    return () => {
      dispatch(resetCostStatementSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="成本清單" />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="寄倉/轉單" key={TabList.WAREHOUSE_CONSIGN_AND_TRANSFER}>
            <CostWareHouseAndTransfer />
          </Tabs.TabPane>
          <Tabs.TabPane tab="買斷" key={TabList.BUY_OUT}>
            <CostBuyOut />
          </Tabs.TabPane>
        </Tabs>
      </Scope>
    </Wrapper>
  );
};
export default CostStatement;
