import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Form, Input, Button, Select, AutoComplete } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { UpdateSupplyChainParam, CreateSupplyChainParam, Currency, currencyType } from "@api/supplyChainApi";
import {
  createSupplyChain,
  updateSupplyChain,
  fetchSupplyChainDetail,
  fetchVendorList,
  fetchSearchVendor,
  fetchTryallOperationStaff,
} from "@redux/supplyChainSlice";
import { useAppDispatch } from "src/store";
import useDebounce from "@hooks/useDebounce";

interface Props {
  mode: "add" | "edit";
}
const Wrapper = styled.div`
  padding-left: 16px;
`;

const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
`;
const Row = styled.div`
  display: flex;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  text-align: left;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const RequiredIcon = styled.span`
  color: red;
`;

const Footer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const SupplyChainInfo: FC<Props> = (props) => {
  const { mode } = props;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { supplyChainId } = useParams();
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const { supplyChainDetail, vendorDetail, TryallStaffList } = supplyChainSlice;

  const [vendorName, setVendorName] = useState<string>("");

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    switch (mode) {
      case "add": {
        const createParams: CreateSupplyChainParam = {
          staff: formValues.staff,
          name: formValues.name,
          address: formValues.address,
          phone: formValues.phone,
          ubn: formValues.ubn,
          accountName: formValues.accountName,
          accountNumber: formValues.accountNumber,
          bankName: formValues.bankName,
          branchName: formValues.branchName,
          currency: formValues.currency,
        };
        dispatch(createSupplyChain(createParams));
        break;
      }
      case "edit": {
        const params: UpdateSupplyChainParam = {
          id: Number(supplyChainId),
          staff: formValues.staff,
          name: formValues.name,
          address: formValues.address,
          phone: formValues.phone,
          ubn: formValues.ubn,
          accountName: formValues.accountName,
          accountNumber: formValues.accountNumber,
          bankName: formValues.bankName,
          branchName: formValues.branchName,
          currency: formValues.currency,
          staffId: 0,
        };
        dispatch(updateSupplyChain(params));
        break;
      }
      default:
    }
  };
  useEffect(() => {
    form.setFieldsValue(supplyChainDetail);
  }, [form, supplyChainDetail]);

  useEffect(() => {
    dispatch(fetchTryallOperationStaff());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchVendorList({}));
    dispatch(fetchSearchVendor(vendorName));
  }, [dispatch, vendorName]);

  useEffect(() => {
    if (mode === "edit") {
      dispatch(fetchSupplyChainDetail(Number(supplyChainId)));
    }
  }, [supplyChainId, dispatch, mode]);

  useEffect(() => {
    if (mode === "add" && supplyChainDetail.id !== 0) {
      navigate(`/operation-of-brand/supply-chain/edit/${supplyChainDetail.id}`);
    }

    form.setFieldsValue(supplyChainDetail);
  }, [form, mode, navigate, supplyChainDetail]);

  const options = vendorDetail.results.map((item) => {
    return { value: item.name, label: item.name, text: item.id };
  });

  const staffOptions = TryallStaffList.results.map((item) => {
    return { value: item.name, label: item.name, text: item.id };
  });

  const handleSearchInput = (value: string) => {
    setVendorName(value);
  };

  const fetchSearchVendors = useDebounce((value: string) => {
    const selectedVendor = vendorDetail && vendorDetail.results.find((item) => item.name === value);
    form.setFieldsValue({
      accountName: selectedVendor && selectedVendor.bankAccount.accountName,
      accountNumber: selectedVendor && selectedVendor.bankAccount.accountNumber,
      address: selectedVendor && selectedVendor.businessAddress,
      bankName: selectedVendor && selectedVendor.bankAccount.bankName,
      branchName: selectedVendor && selectedVendor.bankAccount.branchName,
      currency: selectedVendor && selectedVendor.currency,
      id: selectedVendor && selectedVendor.id,
      phone: selectedVendor && selectedVendor.phone,
      ubn: selectedVendor && selectedVendor.ubn,
    });
  }, 600);

  return (
    <Wrapper>
      <Form form={form} initialValues={supplyChainDetail} onFinish={handleOnSubmit} style={{ maxWidth: 400 }}>
        <Row>
          <RowTitle>
            運營負責人
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="staff" rules={[{ required: true, message: "" }]}>
              <AutoComplete
                allowClear
                options={staffOptions}
                placeholder="請選擇"
                filterOption={(inputValue, option) =>
                  option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            供應商名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              <AutoComplete
                allowClear
                options={options}
                placeholder="請選擇廠商"
                filterOption={(inputValue, option) => option!.value.indexOf(inputValue) !== -1}
                notFoundContent="請至廠商列表新增供應商"
                onChange={(e) => fetchSearchVendors(e)}
                onSearch={(e) => handleSearchInput(e)}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            供應商地址
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="address" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            供應商電話
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="phone" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            供應商統編
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="ubn" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            帳戶名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="accountName" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            帳戶號碼
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="accountNumber" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            銀行名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="bankName" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>分行名稱</RowTitle>
          <RowContent width={400}>
            <Form.Item name="branchName">
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            付款幣別
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={400}>
            <Form.Item name="currency" rules={[{ required: true, message: "" }]}>
              <StyledSelect placeholder="請選擇">
                <Select.Option value={Currency.TWD}>{currencyType(Currency.TWD)}</Select.Option>
                <Select.Option value={Currency.USD}>{currencyType(Currency.USD)}</Select.Option>
                <Select.Option value={Currency.AUD}>{currencyType(Currency.AUD)}</Select.Option>
                <Select.Option value={Currency.GBP}>{currencyType(Currency.GBP)}</Select.Option>
                <Select.Option value={Currency.EUR}>{currencyType(Currency.EUR)}</Select.Option>
              </StyledSelect>
            </Form.Item>
          </RowContent>
          <Form.Item name="staffId" />
        </Row>
        <Footer>
          <Button type="primary" htmlType="submit">
            {mode === "add" ? "建立資料" : "儲存此分頁"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default SupplyChainInfo;
