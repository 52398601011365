import React, { FC, useEffect, useMemo } from "react";
import styled from "styled-components";
import { Select, Table, Button } from "antd";
import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { updateVPCSubOrderListParams, fetchVPCSubOrderList } from "@redux/salesReportSlice";
import { VPCSubOrderListItem } from "@api/salesReportApi";
import PopupBackground from "@component/PopupBackground";
import { logisticHistoryMap } from "@page/Order/LogisticHistories";

const processingTypeValue: {
  [index: number]: string;
} = {
  0: "出貨",
  3: "退貨",
};

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 620px;
  background-color: white;
  border-radius: 2px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-bottom: 16px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  border: 1px solid #f0f0f0;
  margin-bottom: 15px;
  width: 100%;
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;

interface Props {
  vpcId: number;
  onClose: () => void;
}

const pageSizeOptions = [20, 50, 100];

const SubOrderModal: FC<Props> = (props) => {
  const { vpcId, onClose } = props;

  const dispatch = useAppDispatch();
  const { isFetchingVPCSubOrderList, VPCSubOrderListParams, VPCSubOrderListResult } = useSelector(
    (state: RootState) => state.salesReport,
  );

  const { count, results: VPCSubOrderList } = VPCSubOrderListResult;
  const { offset, limit } = VPCSubOrderListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchVPCSubOrderList(vpcId));
  }, [dispatch, vpcId]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...VPCSubOrderListParams,
      limit: value as number,
    };

    dispatch(updateVPCSubOrderListParams(filter));
    dispatch(fetchVPCSubOrderList(vpcId));
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...VPCSubOrderListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateVPCSubOrderListParams(filter));
    dispatch(fetchVPCSubOrderList(vpcId));
  };

  const tableColumns = [
    {
      title: "ID",
      dataIndex: "orderShipmentId",
      width: 65,
      render: (value: VPCSubOrderListItem["orderShipmentId"], data: VPCSubOrderListItem) => (
        <Link href={`/order-content/${data.orderId}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: "子訂單類型",
      dataIndex: "processingType",
      width: 106,
      render: (value: VPCSubOrderListItem["processingType"]) => processingTypeValue[value],
    },
    {
      title: "子訂單狀態",
      dataIndex: "shippingStatus",
      width: 106,
      render: (value: VPCSubOrderListItem["shippingStatus"]) => logisticHistoryMap[value],
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
      width: 200,
      render: (value: VPCSubOrderListItem["createdAt"]) => value.substring(0, 19),
    },
    {
      title: "銷量",
      dataIndex: "salesVolume",
      width: 106,
    },
  ];

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
        <TableContainer>
          <Table
            dataSource={VPCSubOrderList}
            columns={tableColumns}
            scroll={{ x: "max-content", y: 222 }}
            tableLayout="auto"
            pagination={{
              pageSize: limit,
              current: currentPage,
              total: count,
              onChange: handleOnPageChange,
              showSizeChanger: false,
            }}
            loading={isFetchingVPCSubOrderList}
          />
        </TableContainer>
        <Button onClick={onClose}>關閉</Button>
      </Wrapper>
    </PopupBackground>
  );
};

export default SubOrderModal;
