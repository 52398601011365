import { Select, Space } from "antd";
import React from "react";
import styled from "styled-components";

const TablePageInfo = styled.div`
  font-size: 14px;
  text-align: right;
  margin: 10px 0;
`;

type Props = {
  pageSizeOptions?: number[];
  onPageSizeChange: (page: number) => void;
  totalCount: number;
  limit: number;
};

const TablePageSizeInfo = (props: Props) => {
  const { pageSizeOptions = [20, 50, 100], onPageSizeChange, totalCount, limit } = props;

  return (
    <TablePageInfo>
      <Space>
        總共
        {totalCount}
        筆, 每頁顯示
        <Select value={limit} onChange={onPageSizeChange} style={{ marginRight: "10px" }}>
          {pageSizeOptions.map((option) => (
            <Select.Option key={option} value={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Space>
      筆
    </TablePageInfo>
  );
};

export default TablePageSizeInfo;
