import React, { FC, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Button, Modal, Form, Input } from "antd";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { FormInstance } from "antd/lib/form";
import { BindTypeValue } from "@api/ruleApi";
import type { LocalFormType } from "..";
import AddExistingPromotion from "./AddExistingPromotion";
import CreateSingleSalePromotion from "./CreateSingleSalePromotion";

const Wrapper = styled.div`
  .ant-form-item-has-error & {
    border-color: #ff4d4f;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Body = styled.div`
  flex: auto;
  padding: 12px 20px 0px 20px;
`;
const Red = styled.span`
  color: red;
`;
const StyledButton = styled(Button)`
  margin-right: 10px;
`;
const ButtonRow = styled.div`
  display: flex;
  margin-bottom: 18px;
`;
const Table = styled.div`
  table-layout: fixed;
  border: 1px solid #f2f2f2;
`;
const TableHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 38px;
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const TableRow = styled.div`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 38px;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const TableColumnOne = styled.div`
  flex: 0 0 20%;
`;
const PromotionIdLink = styled.a`
  flex: 0 0 20%;
`;
const TableColumnTwo = styled.div`
  flex: 0 0 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const TableColumnThree = styled.div`
  flex: 0 0 20%;
  text-align: right;
`;
const DeleteText = styled.span`
  cursor: pointer;
  color: #1890ff;
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colorNeutral600};
  margin: 12px 0;
`;

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 224px;
  height: 274px;
  border: 1px solid #d9d9d9;
`;
interface Props {
  form: FormInstance<LocalFormType>;
}

const PromotionSetting: FC<Props> = (props) => {
  const { form } = props;
  const { id: ruleId } = useParams();
  const [showAddExistingPromotion, setShowAddExistingPromotion] = useState<boolean>(false);
  const [showCreateSingleSalePromotion, setShowCreateSingleSalePromotion] = useState<boolean>(false);

  const toggleAddExistingPromotion = () => setShowAddExistingPromotion((prev) => !prev);
  const toggleCreateSingleSalePromotion = () => setShowCreateSingleSalePromotion((prev) => !prev);

  const checkIsRuleExisting = (method: () => void) => () => {
    if (ruleId) {
      method();
    } else {
      Modal.warning({
        title: "須先建立此筆資料，才能新增優惠",
        okText: "我知道了",
      });
    }
  };

  const handleOnDeletePromotion = () => {
    form.setFieldsValue({
      promotionInfo: undefined,
      destinationLink: "",
      redirectUrl: "",
    });
  };

  return (
    <WrapperDiv>
      <Wrapper>
        <Header>
          活動品項
          <Red>*</Red>
        </Header>
        <Body>
          <Form.Item name="promotionInfo" rules={[{ required: true, message: "請指定活動品項" }]}>
            <Form.Item name="destinationLink" noStyle />
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return (
                  prevValues.promotionInfo !== currentValues.promotionInfo ||
                  currentValues.bindMethod?.bindType === BindTypeValue.ONE_TIME
                );
              }}
            >
              {({ getFieldValue }) => {
                const bindType = getFieldValue("bindMethod")?.bindType;
                const bindTimeBetween = getFieldValue("bindMethod")?.bindTimeBetween;
                const bindTimeStart =
                  bindTimeBetween && bindTimeBetween[0] ? bindTimeBetween[0].format("YYYY-MM-DD HH:mm:ss") : "";
                const bindTimeEnd =
                  bindTimeBetween && bindTimeBetween[1] ? bindTimeBetween[1].format("YYYY-MM-DD HH:mm:ss") : "";
                const promotionInfo = getFieldValue("promotionInfo");

                return (
                  <ButtonRow>
                    <StyledButton
                      type="primary"
                      size="small"
                      onClick={checkIsRuleExisting(toggleAddExistingPromotion)}
                      disabled={promotionInfo}
                    >
                      指定現有活動
                    </StyledButton>
                    <StyledButton
                      icon={<PlusCircleTwoTone />}
                      size="small"
                      onClick={checkIsRuleExisting(toggleCreateSingleSalePromotion)}
                      disabled={promotionInfo}
                    >
                      建立單品活動
                    </StyledButton>
                    <a
                      href={`/promotion/add${
                        bindType === BindTypeValue.ONE_TIME
                          ? `?bindTimeStart=${bindTimeStart}&bindTimeEnd=${bindTimeEnd}`
                          : ""
                      }`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Button icon={<PlusCircleTwoTone />} size="small" disabled={promotionInfo}>
                        建立多品活動
                      </Button>
                    </a>
                  </ButtonRow>
                );
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.promotionInfo !== currentValues.promotionInfo}
            >
              {({ getFieldValue }) => {
                const promotionInfo = getFieldValue("promotionInfo");

                if (promotionInfo) {
                  return (
                    <Table>
                      <TableHeader>
                        <TableColumnOne>活動ID</TableColumnOne>
                        <TableColumnTwo>活動名稱</TableColumnTwo>
                      </TableHeader>
                      <TableRow>
                        <PromotionIdLink href={`/promotion/edit/${promotionInfo.id}`} target="_blank">
                          {promotionInfo.id}
                        </PromotionIdLink>
                        <TableColumnTwo>{promotionInfo.title}</TableColumnTwo>
                        <TableColumnThree>
                          <DeleteText onClick={handleOnDeletePromotion}>刪除</DeleteText>
                        </TableColumnThree>
                      </TableRow>
                    </Table>
                  );
                }
                return null;
              }}
            </Form.Item>
          </Form.Item>
        </Body>
      </Wrapper>
      {showAddExistingPromotion && <AddExistingPromotion onClose={toggleAddExistingPromotion} form={form} />}
      {showCreateSingleSalePromotion && (
        <CreateSingleSalePromotion onClose={toggleCreateSingleSalePromotion} form={form} />
      )}
      <Body>
        <Title>目標頁連結設定</Title>
        <Form.Item name="redirectUrl" noStyle>
          <Input placeholder="無設定，則依據活動品項內設定，跳轉至指定頁面" />
        </Form.Item>
      </Body>
    </WrapperDiv>
  );
};

export default PromotionSetting;
