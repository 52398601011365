import { SalesCategoryOrigin } from "@api/topicApi";

export interface SalesCategory {
  key: number;
  label: string;
  value: number;
  parent: number;
  children: {
    key: number;
    label: string;
    value: number;
    parent: number;
  }[];
}

export default function generateCategoryTree(salesCategories: {
  level1: SalesCategoryOrigin[];
  level2: SalesCategoryOrigin[];
  level3: SalesCategoryOrigin[];
}) {
  const treeMap = new Map<number, SalesCategory>();
  const level1Map = new Map<number, SalesCategory>();
  const level2Map = new Map<number, SalesCategory>();
  const level3Map = new Map<number, SalesCategory>();

  const { level1, level2, level3 } = salesCategories;

  level1.forEach((category) => {
    const newCategory = {
      key: category.id,
      label: category.name,
      value: category.id,
      parent: category.parent,
      children: [],
    };

    level1Map.set(newCategory.key, newCategory);
  });

  level2.forEach((category) => {
    const newCategory = {
      key: category.id,
      label: category.name,
      value: category.id,
      parent: category.parent,
      children: [],
    };

    level2Map.set(newCategory.key, newCategory);
  });

  level3.forEach((category) => {
    const newCategory = {
      key: category.id,
      label: category.name,
      value: category.id,
      parent: category.parent,
      children: [],
    };

    const level2Parent = level2Map.get(newCategory.parent);

    if (level2Parent) {
      level2Parent.children.push({ ...newCategory, label: `${category.name}` });
      const level1Parent = level1Map.get(level2Parent.parent);

      const distinct = level1Parent?.children.some((item) => item.key === level2Parent.key);
      if (level1Parent && !distinct) {
        level1Parent.children.push({
          ...level2Parent,
          label: `${level2Parent.label}`,
        });
        treeMap.set(level1Parent.key, {
          ...level1Parent,
          label: `${level1Parent.label}`,
        });
      }
    }

    level3Map.set(newCategory.key, newCategory);
  });

  return {
    treeMap,
    level1Map,
    level2Map,
    level3Map,
  };
}
