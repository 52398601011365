import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { InvoiceFilter, InvoiceList } from "@api/financeApi";
import { message } from "antd";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  invoiceList: InvoiceList;
  invoiceFilter: InvoiceFilter;
  subOrderInvoiceList: [];
  isFetchingSubOrder: boolean;
}

export const initialState: IState = {
  isFetching: false,
  invoiceList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  invoiceFilter: {
    limit: 20,
    offset: 0,
  },
  subOrderInvoiceList: [],
  isFetchingSubOrder: false,
};

export const fetchInvoiceList = createAsyncThunk("invoice/fetchInvoiceList", async (params: InvoiceFilter, thunApi) => {
  const response = await financeApi.fetchInvoiceList(params);
  return response;
});

export const fetchInvoiceListBySubOrder = createAsyncThunk(
  "invoice/fetchInvoiceListBySubOrder",
  async (subOrderId: number, thunApi) => {
    const response = await financeApi.fetchInvoiceListBySubOrder(subOrderId);
    return response;
  },
);
export const exportVPCSalesList = createAsyncThunk("invoice/exportVPCSalesList", async (params: any, thunApi) => {
  try {
    await financeApi.exportVPCSalesList(params);
    message.success("匯出資料準備中，由於資料繁多請稍待，成功後會寄信通知");
  } catch (error: any) {
    thunApi.rejectWithValue(error.message);
  }
});

const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    resetInvoiceSlice: () => initialState,
    setInvoiceFilter: (state, action) => {
      state.invoiceFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInvoiceList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchInvoiceList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.invoiceList = action.payload;
    });
    builder.addCase(fetchInvoiceListBySubOrder.pending, (state) => {
      state.isFetchingSubOrder = true;
    });
    builder.addCase(fetchInvoiceListBySubOrder.fulfilled, (state, action) => {
      state.isFetchingSubOrder = false;
      state.subOrderInvoiceList = action.payload;
    });
  },
});

export const invoiceState = (state: RootState) => state.invoiceSlice;
export const { resetInvoiceSlice, setInvoiceFilter } = invoiceSlice.actions;
export default invoiceSlice.reducer;
