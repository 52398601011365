/* eslint-disable camelcase */
import MemberGroupValue, { memberGroupMap } from "@constant/MemberGroupValue";
import { ApprovalStatus } from "@page/Order/ApprovedState";
import CarrierTypeValue from "@page/Order/CarrierTypeValue";
import InvoiceTypeValue from "@page/Order/InvoiceTypeValue";
import { LogisticHistoriesNumber, logisticHistoryMap } from "@page/Order/LogisticHistories";
import { PaymentMethodIdValue } from "@page/Order/PaymentMethodValue";
import ProcessingType from "@page/Order/ProcessingType";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import SaleRuleType from "@page/Order/SaleRuleType";
import { ShippingTypeValueByNumber } from "@page/Order/ShippingTypeValue";

export enum SuspendType {
  VIP_ABNORMAL = 1,
  CREDIT_ABNORMAL = 2,
}

export interface OrderList {
  id: number;
  orderNumber: string;
  shippingStatus: LogisticHistoriesNumber;
  shippingStatusText: string;
  grandTotal: number;
  orderTime: string;
  name: string;
  phone: string;
  memberGroup: MemberGroupValue;
  memberGroupText: string;
  suspendType: SuspendType;
}

export interface OrderSalesPlans {
  id: number;
  price: number;
  salesDiscount: number;
  salesRuleDiscount: number;
  memberGroupDiscount: number;
  salesPlanSpecialPrice: number;
  media: string;
  qty: number;
  optionsCount: number;
  backOrderQty: number;
  salesPlanName: string;
  salesPlanRuleType: SaleRuleType;
  salesPageId: number;
  salesPageName: string;
  salesPageType: SalePageTypeValue;
  optionsText: string;
  salesPlanOptions: {
    optionName: string;
    optionQty: number;
    groupQty: number;
    productId: number;
    optionBackOrderQty: number;
    optionCanceledQty: number;
  }[];
  canceledQty: number;
}

export interface SubOrder {
  id: number;
  vendorName: string[];
  isCvsShippingFailed: boolean;
  deliveryDate: number;
  logistic: number;
  shippingMethod: number;
  shippingFee: number;
  storeName: string;
  code: string;
  address: string;
  receiverName: string;
  receiverPhone: string;
  processingType: ProcessingType;
  shippingStatus: LogisticHistoriesNumber;
  packageNumber: string;
  isDeleted: boolean;
  paymentMethod: PaymentMethodIdValue;
  notes: string;
  shipperNumber: string;
  logisticHistories: {
    status: string;
    updatedTime: string;
  }[];
  logisticShippingMethods: {
    [logistic: number]: {
      logisticId: number;
      name: string;
      shippingMethods: {
        shippingMethod: number;
        shippingMethodName: string;
        shippingThreshold: number;
        shippingFee: number;
      }[];
    };
  };
  orderSalesPlans: OrderSalesPlans[];
  salesPlansPrice: number;
  totalMemberGroupDiscount: number;
  canDelete: boolean;
  canModifyPickup: boolean;
}

export interface OrderInfo {
  orderInfo: {
    ordererEmail: string;
    canMakeInvoice: boolean;
    orderNumber: string;
    orderTime: string;
    status: LogisticHistoriesNumber;
    orderShipmentCounts: number;
    grandTotal: number;
    memberGroupDiscount: number;
    shippingFee: number;
    salesRuleDiscount: number;
    totalProductFee: number;
    paymentMethod: PaymentMethodIdValue;
    paymentExpirationTime: string;
    suspendType: SuspendType;
    isReplenishReturn: boolean;
    source: {
      ecid: string;
      platformId: string;
      platformText: string;
      applicationVersion: string;
      affiliate: string;
    };
  };
  memberInfo: {
    email: string;
    name: string;
    phone: string;
    group: MemberGroupValue;
  };
  invoiceInfo: {
    carrierType: CarrierTypeValue;
    carrierNumber: string;
    invoiceNumber: string;
    invoiceUbn: string;
    invoiceType: InvoiceTypeValue;
    invoiceTitle: string;
    donationInfo: {
      donatarius: string;
      donateCode: string;
    };
  };
  promotionRuleInfo: {
    title: string;
    description: string;
    promotionRuleId: number | null;
  };
  orderShipments: SubOrder[];
}

export interface ReplenishReturnList {
  id: number;
  orderId: number;
  orderNumber: string;
  orderTime: string;
  shippingMethod: ShippingTypeValueByNumber;
  refundAmount: number;
  qty: number;
  memberName: string;
  memberPhone: string;
  logisticId: number;
  logisticName: string;
  processingType: ProcessingType;
  approvalStatus: ApprovalStatus;
  createdAt: string;
  approvalDate: string;
  isDeleted: boolean;
}

export interface OptionInfo {
  ocQty: number;
  optionName: string;
  optionQty: number;
  paidNum: number;
  productId: number;
  reason: string;
}

export interface ReplenishReturnInfo {
  id: number;
  orderInfo: {
    orderId: number;
    orderNumber: string;
    orderTime: string;
    shippingMethod: number;
    refundAmount: number;
    qty: number;
    memberName: string;
    memberPhone: string;
  };
  logisticId: number;
  logisticName: string;
  processingType: ProcessingType;
  approvalStatus: ApprovalStatus;
  createdAt: string;
  approvalDate: string | null;
  isDeleted: boolean;
  isReturnToWarehouse: boolean;
  reverseOrderShipmentInfo: {
    receiverInfo: {
      receiverName: string;
      receiverPhone: string;
      receiverAddress: string;
    };
    applyType: number;
    approvalStatus: ApprovalStatus;
    bankInfo: {
      bankCode: string;
      bankName: string;
      bankAccount: string;
      accountName: string;
    };
  };
  orderSalesPlans: {
    id: number;
    pageId: number | null;
    pageName: string;
    salesPlan: number;
    name: string;
    price: number;
    reason: string;
    reasonMedia: string;
    salesDiscount: number;
    memberGroupDiscount: number;
    orderedQty: number;
    salesRuleDiscount: number;
    optionsInfo: OptionInfo[];
    optionText: string;
    specialPrice: number;
    discount: number; // 折價卷和黑卡擇一
    refund: number;
    singlePlanRefund: number;
  }[];
}

interface NormalizeOrder {
  orderList: (
    list: {
      id: number;
      order_number: string;
      shipping_status: LogisticHistoriesNumber;
      grand_total: number;
      order_time: string;
      name: string;
      phone: string;
      member_group: MemberGroupValue;
      suspend_type: SuspendType;
    }[],
  ) => OrderList[];
  orderInfo: (response: {
    order_info: {
      can_make_invoice: boolean;
      order_number: string;
      orderer_email: string;
      order_time: string;
      status: 0;
      order_shipment_counts: number;
      grand_total: number;
      member_group_discount: number;
      shipping_fee: number;
      sales_rule_discount: number;
      total_product_fee: number;
      payment_method: PaymentMethodIdValue;
      payment_expiration_time: string;
      suspend_type: SuspendType;
      source: {
        ecid: string;
        platform_id: string;
        application_version: string;
        affiliate: string;
      };
      is_replenish_return: boolean;
    };
    member_info: {
      email: string;
      name: string;
      phone: string;
      group: MemberGroupValue;
    };
    invoice_info: {
      carrier_type: CarrierTypeValue;
      carrier_number: string;
      invoice_number: string;
      invoice_ubn: string;
      invoice_type: InvoiceTypeValue;
      invoice_title: string;
      donation_info: {
        donatarius: string;
        donate_code: string;
      };
    };
    promotion_rule_info: {
      title: string;
      description: string;
      promotion_rule_id: number | null;
    };
    order_shipments: {
      id: number;
      vendor_name: string[];
      is_cvs_shipping_failed: boolean;
      delivery_date: number;
      logistic: number;
      shipping_method: number;
      shipping_fee: number;
      store_name: string;
      code: string;
      address: string;
      receiver_name: string;
      receiver_phone: string;
      processing_type: ProcessingType;
      shipping_status: LogisticHistoriesNumber;
      package_number: string;
      is_deleted: boolean;
      payment_method: PaymentMethodIdValue;
      notes: string;
      shipper_number: string;
      can_delete: boolean;
      can_modify_pickup: boolean;
      logistic_histories: {
        status: string;
        updated_time: string;
      }[];
      logistic_shipping_methods: {
        logistic_id: number;
        name: string;
        shipping_methods: {
          shipping_method: number;
          shipping_method_name: string;
          shipping_threshold: number;
          shipping_fee: number;
        }[];
      }[];
      order_sales_plans: {
        id: number;
        price: number;
        sales_discount: number;
        sales_rule_discount: number;
        member_group_discount: number;
        media: string;
        qty: number;
        options_count: number;
        back_order_qty: number;
        sales_plan_name: string;
        sales_plan_rule_type: SaleRuleType;
        sales_page_id: number;
        sales_page_name: string;
        sales_page_type: SalePageTypeValue;
        sales_plan_options: {
          option_name: string;
          option_qty: number;
          group_qty: number;
          product_id: number;
          option_back_order_qty: number;
          option_canceled_qty: number;
        }[];
        canceled_qty: number;
      }[];
    }[];
  }) => OrderInfo;
  replenishReturnList: (
    response: {
      id: number;
      order_info: {
        order_id: number;
        order_number: string;
        order_time: string;
        shipping_method: ShippingTypeValueByNumber;
        refund_amount: number;
        qty: number;
        member_info: {
          name: string;
          phone: string;
        };
      };
      logistic_info: {
        id: number;
        name: string;
      };
      processing_type: ProcessingType;
      approval_status: ApprovalStatus;
      created_at: string;
      approval_date: string | null;
      is_deleted: boolean;
    }[],
  ) => ReplenishReturnList[];
  replenishReturnInfo: (response: {
    id: number;
    order_info: {
      order_id: number;
      order_number: string;
      order_time: string;
      shipping_method: number;
      refund_amount: number;
      qty: number;
      member_info: {
        name: string;
        phone: string;
      };
    };
    logistic_info: {
      id: number;
      name: string;
    };
    processing_type: ProcessingType;
    approval_status: ApprovalStatus;
    created_at: string;
    approval_date: string | null;
    is_deleted: boolean;
    is_return_to_warehouse: boolean;
    reverse_order_shipment_info: {
      receiver_info: {
        receiver_name: "Max";
        receiver_phone: "0912345678";
        receiver_address: "台北市中山區龍江路";
      };
      apply_type: number;
      approval_status: ApprovalStatus;
      bank_info: {
        bank_code: string;
        bank_name: string;
        bank_account: string;
        account_name: string;
      };
    };
    order_sales_plans: {
      id: number;
      page_id: number | null;
      page_name: string;
      sales_plan: number;
      name: string;
      price: number;
      reason_media: {
        reason: string;
        media: string;
      } | null;
      sales_discount: number;
      member_group_discount: number;
      ordered_ctn_qty: number;
      sales_rule_discount: number;
      single_plan_refund: number;
      options_info: {
        option_name: string;
        option_qty: number;
        product_id: number;
        oc_qty: number;
        paid_num: number;
        reason: string;
      }[];
    }[];
  }) => ReplenishReturnInfo;
}

const normalizeOrder: NormalizeOrder = {
  orderList: (list) => {
    return list.map((item) => ({
      id: item.id,
      orderNumber: item.order_number,
      shippingStatus: item.shipping_status,
      shippingStatusText: logisticHistoryMap[item.shipping_status],
      grandTotal: item.grand_total,
      orderTime: item.order_time,
      name: item.name,
      phone: item.phone,
      memberGroup: item.member_group,
      memberGroupText: memberGroupMap[item.member_group],
      suspendType: item.suspend_type,
    }));
  },
  orderInfo: (response) => ({
    orderInfo: {
      canMakeInvoice: response.order_info.can_make_invoice,
      orderNumber: response.order_info.order_number,
      ordererEmail: response.order_info.orderer_email,
      orderTime: response.order_info.order_time,
      status: response.order_info.status,
      orderShipmentCounts: response.order_info.order_shipment_counts,
      grandTotal: response.order_info.grand_total,
      memberGroupDiscount: response.order_info.member_group_discount,
      shippingFee: response.order_info.shipping_fee,
      salesRuleDiscount: response.order_info.sales_rule_discount,
      totalProductFee: response.order_info.total_product_fee,
      paymentMethod: response.order_info.payment_method,
      paymentExpirationTime: response.order_info.payment_expiration_time,
      suspendType: response.order_info.suspend_type,
      source: {
        ecid: response.order_info.source.ecid || "",
        platformId: response.order_info.source.platform_id,
        platformText: response.order_info.source.platform_id,
        applicationVersion: response.order_info.source.application_version,
        affiliate: response.order_info.source.affiliate || "",
      },
      isReplenishReturn: response.order_info.is_replenish_return,
    },
    memberInfo: {
      email: response.member_info.email,
      name: response.member_info.name,
      phone: response.member_info.phone,
      group: response.member_info.group,
    },
    invoiceInfo: {
      carrierType: response.invoice_info.carrier_type,
      carrierNumber: response.invoice_info.carrier_number,
      invoiceNumber: response.invoice_info.invoice_number,
      invoiceUbn: response.invoice_info.invoice_ubn,
      invoiceType: response.invoice_info.invoice_type,
      invoiceTitle: response.invoice_info.invoice_title,
      donationInfo: {
        donatarius: response.invoice_info.donation_info?.donatarius,
        donateCode: response.invoice_info.donation_info?.donate_code,
      },
    },
    promotionRuleInfo: {
      title: response.promotion_rule_info.title || "",
      description: response.promotion_rule_info.description || "",
      promotionRuleId: response.promotion_rule_info.promotion_rule_id,
    },
    orderShipments: response.order_shipments.map((item) => ({
      vendorName: item.vendor_name,
      id: item.id,
      isCvsShippingFailed: item.is_cvs_shipping_failed,
      deliveryDate: item.delivery_date,
      logistic: item.logistic,
      shippingMethod: item.shipping_method,
      shippingFee: item.shipping_fee,
      storeName: item.store_name || "",
      code: item.code,
      address: item.address,
      receiverName: item.receiver_name,
      receiverPhone: item.receiver_phone,
      processingType: item.processing_type,
      shippingStatus: item.shipping_status,
      packageNumber: item.package_number,
      isDeleted: item.is_deleted,
      paymentMethod: item.payment_method,
      notes: item.notes || "",
      shipperNumber: item.shipper_number || "",
      logisticHistories: item.logistic_histories.map((history) => ({
        status: history.status,
        updatedTime: history.updated_time,
      })),
      logisticShippingMethods: item.logistic_shipping_methods.reduce((accumulate, logistic) => {
        const store: any = {};
        store[logistic.logistic_id] = {
          logisticId: logistic.logistic_id,
          name: logistic.name,
          shippingMethods: logistic.shipping_methods.map((method) => ({
            shippingMethod: method.shipping_method,
            shippingMethodName: method.shipping_method_name,
            shippingThreshold: method.shipping_threshold,
            shippingFee: method.shipping_fee,
          })),
        };

        return { ...accumulate, ...store };
      }, {}),
      orderSalesPlans: item.order_sales_plans.map((plan) => ({
        id: plan.id,
        price: plan.price,
        salesDiscount: plan.sales_discount,
        salesRuleDiscount: plan.sales_rule_discount,
        memberGroupDiscount: plan.member_group_discount,
        salesPlanSpecialPrice: plan.price - plan.sales_discount - plan.member_group_discount,
        media: plan.media,
        qty: plan.qty,
        optionsCount: plan.options_count,
        backOrderQty: plan.back_order_qty,
        salesPlanName: plan.sales_plan_name,
        salesPlanRuleType: plan.sales_plan_rule_type,
        salesPageId: plan.sales_page_id,
        salesPageName: plan.sales_page_name,
        salesPageType: plan.sales_page_type,
        optionsText: plan.sales_plan_options
          .map((option) => `${option.option_name}*${option.option_qty * option.group_qty} `)
          .toString()
          .replace(",", ""),
        salesPlanOptions: plan.sales_plan_options.map((option) => ({
          optionName: option.option_name,
          optionQty: option.option_qty,
          groupQty: option.group_qty,
          productId: option.product_id,
          optionBackOrderQty: option.option_back_order_qty,
          optionCanceledQty: option.option_canceled_qty,
        })),
        canceledQty: plan.canceled_qty,
      })),
      salesPlansPrice: item.order_sales_plans.reduce(
        (accumulate, currentValue) =>
          accumulate +
          (currentValue.price - currentValue.sales_discount - currentValue.member_group_discount) * currentValue.qty,
        0,
      ),
      totalMemberGroupDiscount: item.order_sales_plans.reduce(
        (accumulate, currentValue) => accumulate + currentValue.member_group_discount,
        0,
      ),
      canDelete: item.can_delete,
      canModifyPickup: item.can_modify_pickup,
    })),
  }),
  replenishReturnList: (response) => {
    return response.map((item) => ({
      id: item.id,
      orderId: item.order_info.order_id,
      orderNumber: item.order_info.order_number,
      orderTime: item.order_info.order_time,
      shippingMethod: item.order_info.shipping_method,
      refundAmount: item.order_info.refund_amount,
      qty: item.order_info.qty,
      memberName: item.order_info.member_info.name,
      memberPhone: item.order_info.member_info.phone,
      logisticId: item.logistic_info.id,
      logisticName: item.logistic_info.name,
      processingType: item.processing_type,
      approvalStatus: item.approval_status,
      createdAt: item.created_at,
      approvalDate: item.approval_date || "",
      isDeleted: item.is_deleted,
    }));
  },
  replenishReturnInfo: (response) => ({
    id: response.id,
    orderInfo: {
      orderId: response.order_info.order_id,
      orderNumber: response.order_info.order_number,
      orderTime: response.order_info.order_time,
      shippingMethod: response.order_info.shipping_method,
      refundAmount: response.order_info.refund_amount,
      qty: response.order_info.qty,
      memberName: response.order_info.member_info.name,
      memberPhone: response.order_info.member_info.phone,
    },
    logisticId: response.logistic_info.id,
    logisticName: response.logistic_info.name,
    processingType: response.processing_type,
    approvalStatus: response.approval_status,
    createdAt: response.created_at,
    approvalDate: response.approval_date || "",
    isDeleted: response.is_deleted,
    isReturnToWarehouse: response.is_return_to_warehouse,
    reverseOrderShipmentInfo: {
      receiverInfo: {
        receiverName: response.reverse_order_shipment_info.receiver_info.receiver_name,
        receiverPhone: response.reverse_order_shipment_info.receiver_info.receiver_phone,
        receiverAddress: response.reverse_order_shipment_info.receiver_info.receiver_address,
      },
      applyType: response.reverse_order_shipment_info.apply_type,
      approvalStatus: response.reverse_order_shipment_info.approval_status,
      bankInfo: {
        bankCode: response.reverse_order_shipment_info.bank_info?.bank_code || "",
        bankName: response.reverse_order_shipment_info.bank_info?.bank_name || "",
        bankAccount: response.reverse_order_shipment_info.bank_info?.bank_account || "",
        accountName: response.reverse_order_shipment_info.bank_info?.account_name || "",
      },
    },
    orderSalesPlans: response.order_sales_plans.map((plan) => ({
      id: plan.id,
      pageId: plan.page_id,
      pageName: plan.page_name,
      salesPlan: plan.sales_plan,
      name: plan.name,
      price: plan.price,
      reason: plan.reason_media?.reason || "",
      reasonMedia: plan.reason_media?.media || "",
      salesDiscount: plan.sales_discount,
      memberGroupDiscount: plan.member_group_discount,
      orderedQty: plan.ordered_ctn_qty,
      salesRuleDiscount: plan.sales_rule_discount,
      optionsInfo: plan.options_info.map((option) => ({
        ocQty: option.oc_qty,
        optionName: option.option_name,
        optionQty: option.option_qty,
        paidNum: option.paid_num,
        productId: option.product_id,
        reason: option.reason,
      })),
      singlePlanRefund: plan.single_plan_refund,
      optionText: plan.options_info.map((option) => `${option.option_name}*${option.option_qty}`).toString(),
      specialPrice: plan.price - plan.sales_discount - plan.member_group_discount - plan.sales_rule_discount,
      discount: plan.member_group_discount || plan.sales_rule_discount, // 折價卷和黑卡擇一
      refund:
        (plan.price - plan.sales_discount - plan.member_group_discount) * plan.ordered_ctn_qty -
        plan.sales_rule_discount,
    })),
  }),
};

export default normalizeOrder;
