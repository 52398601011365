/* eslint-disable react/jsx-props-no-spreading */
import { ExclamationCircleOutlined, MenuOutlined, PlusCircleFilled } from "@ant-design/icons";
import { AddSalePlanToRuleParam } from "@api/ruleApi";
import { SalePlan } from "@api/saleApi";
import { RuleTypeValue, SalePlanListParams } from "@api/salePlanApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import { updateSortSalePlans } from "@redux/ruleAddSalePlanSlice";
import {
  addSalePlanToRule,
  deleteSalePlanFromRule,
  fetchSalePlanList,
  loadMoreSalePlanList,
  updateSalePlanListParams,
} from "@redux/ruleSlice";
import { Button, Input, InputNumber, Modal, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { arrayMoveImmutable } from "array-move";
import React, { FC, KeyboardEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from "react-sortable-hoc";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import CopyExistingSalePlan from "./CopyExistingSalePlan";
import EditSalePlan from "./EditSalePlan";

const Wrapper = styled.div`
  grid-column: 2 span;
  border: 1px solid #d9d9d9;
  width: 870px;
  grid-row-start: 2;
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.span`
  color: red;
`;
const AddButton = styled(Button)`
  margin-left: 10px;
  margin-right: 8px;
`;
const Body = styled.div`
  padding: 12px 20px 20px 20px;
`;
const AddPlanRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const AddPlanGroup = styled(Input.Group)`
  width: 400px;
`;
const AddPlanSelect = styled(Select)`
  width: 25%;
`;
const SearchSelect = styled(Select)`
  width: 75%;
`;
const AddPlanButton = styled(Button)`
  margin-left: 8px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const Flex = styled.div`
  display: flex;
`;
const EditText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  margin-right: 10px;
  cursor: pointer;
`;
const DeleteText = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
`;

type SalesPlanTableItem = {
  id: number;
  name: string;
  specialPrice: number;
  canBuyCount: number;
  rank: number;
};

enum AddPlanOptionTypeValue {
  ID,
  NAME,
}

const addPlanOptions = [
  {
    label: "加購價ID",
    value: AddPlanOptionTypeValue.ID,
  },
  {
    label: "加購價名稱",
    value: AddPlanOptionTypeValue.NAME,
  },
];

const AdditionalPurchase: FC = () => {
  const { id: ruleId } = useParams();

  const dispatch = useAppDispatch();
  const { ruleSalePlans, searchSalePlanListResult, isFetchingRuleDetail } = useSelector(
    (state: RootState) => state.rule,
  );

  const [addPlanOption, setAddPlanOption] = useState<AddPlanOptionTypeValue>(AddPlanOptionTypeValue.ID);
  const [inputPlanId, setInputPlanId] = useState<number>();
  const [inputPlanItem, setInputPlanItem] = useState<any>();
  const [salePlanModalStatus, setSalePlanModalStatus] = useState<{
    show: boolean;
    salePlan?: SalePlan;
    salePlanId?: number;
    mode: "add" | "edit";
  }>({
    show: false,
    mode: "add",
  });
  const [showCopyExistingSalePlanModal, setShowCopyExistingSalePlanModal] = useState<boolean>(true);
  const [dataSource, setDataSource] = useState<SalesPlanTableItem[]>([]);

  const handleOnSalePlanLoadMore = useCallback(() => {
    dispatch(loadMoreSalePlanList());
  }, [dispatch]);

  const salePlanOptions = useMemo(() => {
    const { next, results } = searchSalePlanListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {`${brd.name} ($${brd.price})`}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnSalePlanLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [searchSalePlanListResult, handleOnSalePlanLoadMore]);

  const toggleCopyExistingSalePlanModal = () => setShowCopyExistingSalePlanModal((prev) => !prev);

  const handleOnPlanIdChange = (value: string | number | undefined) => {
    setInputPlanId(Number(value));
  };
  const handleOnPlanItemChange = (value: SelectValue, option: any) => {
    setInputPlanItem(option);
  };

  const handleOnOptionSelect = (value: SelectValue) => {
    switch (value) {
      case AddPlanOptionTypeValue.ID:
        setInputPlanItem(undefined);
        break;
      case AddPlanOptionTypeValue.NAME:
        setInputPlanId(undefined);
        break;
      default:
    }
    setAddPlanOption(value as number);
  };

  const checkIsRuleExisting = (action: () => void) => () => {
    if (ruleId) {
      action();
    } else {
      Modal.warning({
        title: "須先建立此筆資料，才能加入加價購方案",
        okText: "我知道了",
      });
    }
  };

  const addNewSalePlan = () => {
    setSalePlanModalStatus({
      show: true,
      mode: "add",
    });
  };

  const editSalePlan = (salePlan: SalesPlanTableItem) => () => {
    setSalePlanModalStatus({
      show: true,
      mode: "edit",
      salePlanId: salePlan.id,
    });
  };

  const closeSalePlanModal = () => {
    setSalePlanModalStatus((prev) => ({
      ...prev,
      show: false,
    }));
  };

  const handleOnDeleteSalePlan = (planId: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteSalePlanFromRule(planId));
      },
    });
  };

  const handleOnAddSalePlan = () => {
    const params: AddSalePlanToRuleParam = {};

    switch (addPlanOption) {
      case AddPlanOptionTypeValue.ID:
        params.planId = inputPlanId;
        break;
      case AddPlanOptionTypeValue.NAME:
        params.planId = Number(inputPlanItem?.key);
        break;
      default:
    }
    dispatch(addSalePlanToRule(params));
  };

  const handleOnSalePlanSearch = useDebounce((value: string) => {
    if (!value) return;

    const params: SalePlanListParams = {
      limit: 20,
      offset: 0,
      planNameQ: value,
      ruleType: RuleTypeValue.ADDITIONAL_PURCHASE,
    };
    dispatch(updateSalePlanListParams(params));
    dispatch(fetchSalePlanList());
  }, 300);

  const preventDefault = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // ---- 方案拖曳排序功能 -----
  const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(dataSource.slice(), oldIndex, newIndex)
        .filter((el) => !!el)
        .map((el, index) => ({ ...el, rank: index }));
      setDataSource(newData);
    }
  };

  const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);

  const SortableItem = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => <tr {...props} />);
  const SortableBody = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
  ));

  const DraggableContainer = (props: SortableContainerProps) => (
    <SortableBody useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow: React.FC<any> = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex((x) => x.rank === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };
  // ---- 方案拖曳排序功能 End-----

  const tableColumns = [
    {
      title: "",
      dataIndex: "",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SalesPlanTableItem, index: number) => <Flex>{`選項${index + 1}`}</Flex>,
    },
    {
      title: <TableNoWrap>加購價ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
    },
    {
      title: <TableNoWrap>方案名稱</TableNoWrap>,
      key: "name",
      dataIndex: "name",
    },
    {
      title: <TableNoWrap>加價金額</TableNoWrap>,
      key: "specialPrice",
      dataIndex: "specialPrice",
      width: 85,
    },
    {
      title: <TableNoWrap>可賣數量</TableNoWrap>,
      key: "canBuyCount",
      dataIndex: "canBuyCount",
      width: 100,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SalesPlanTableItem) => (
        <Flex>
          <EditText onClick={editSalePlan(data)}>編輯</EditText>
          <DeleteText onClick={handleOnDeleteSalePlan(data.id)}>刪除</DeleteText>
        </Flex>
      ),
    },
  ];

  useEffect(() => {
    // ---- 方案table重組格式：增加rank(index) -----
    const newData: SalesPlanTableItem[] = ruleSalePlans.map((item, index) => ({
      id: item.id,
      name: item.name,
      specialPrice: item.specialPrice,
      canBuyCount: item.canBuyCount,
      rank: index,
    }));
    setDataSource(newData);
  }, [ruleSalePlans]);

  useEffect(() => {
    // ---- 更新redux sortSalePlans 供儲存時使用 -----
    const newSalePlan = dataSource.map((item) => ({ id: item.id, rank: item.rank }));
    dispatch(updateSortSalePlans(newSalePlan));
  }, [dataSource, dispatch]);

  return (
    <Wrapper>
      <Header>
        加價購選項設定
        <Red>*</Red>
        <AddButton
          icon={<PlusCircleFilled />}
          type="primary"
          size="small"
          onClick={checkIsRuleExisting(addNewSalePlan)}
        >
          新增加價購
        </AddButton>
        <Button size="small" onClick={checkIsRuleExisting(toggleCopyExistingSalePlanModal)}>
          複製既有方案
        </Button>
      </Header>
      <Body>
        <AddPlanRow>
          <AddPlanGroup compact>
            <AddPlanSelect
              options={addPlanOptions}
              value={addPlanOption}
              onChange={(value) => handleOnOptionSelect(value as SelectValue)}
            />
            {addPlanOption === AddPlanOptionTypeValue.ID ? (
              <InputNumber
                style={{ width: "75%" }}
                value={inputPlanId}
                onChange={(value) => handleOnPlanIdChange(value as string | number | undefined)}
                onPressEnter={preventDefault}
              />
            ) : (
              <SearchSelect
                showSearch
                filterOption={false}
                value={inputPlanItem?.value}
                onChange={(value, option) => handleOnPlanItemChange(value as SelectValue, option)}
                onSearch={handleOnSalePlanSearch}
              >
                {salePlanOptions}
              </SearchSelect>
            )}
          </AddPlanGroup>
          <AddPlanButton onClick={checkIsRuleExisting(handleOnAddSalePlan)}>加入加購價</AddPlanButton>
        </AddPlanRow>
        <Table
          loading={isFetchingRuleDetail}
          dataSource={dataSource}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={false}
          rowKey="rank"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </Body>
      {showCopyExistingSalePlanModal && <CopyExistingSalePlan onClose={toggleCopyExistingSalePlanModal} />}
      {salePlanModalStatus.show && (
        <EditSalePlan
          onClose={closeSalePlanModal}
          mode={salePlanModalStatus.mode}
          salePlan={salePlanModalStatus.salePlan}
          salePlanId={salePlanModalStatus.salePlanId}
        />
      )}
    </Wrapper>
  );
};

export default AdditionalPurchase;
