import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { TopicCategoryRecommendSaleInfo } from "@api/topicApi";
import {
  fetchDeleteRecommendList,
  fetchTopicCategorySaleRecommendList,
  fetchUpdateRecommendList,
  setTopicRecommendSaleListFilter,
  topicState,
} from "@redux/topicSlice";
import { Button, Checkbox, Input, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import RecommendAddSalePage from "./RecommendAddSalePage";
import RecommendSettingFilter from "./RecommendSettingFilter";

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #d9d9d9;
  padding: 18px 22px 22px 23px;
  position: relative;
  margin-bottom: 24px;
`;

const CustomRow = styled(Row)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;

const DeleteButtonWrapper = styled.div`
  position: absolute;
  left: 23px;
  bottom: 38px;
`;

const PageLink = styled.a`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme }) => theme.colorSuccess500};
`;

type Props = {
  currentTab: string;
};

export default function RecommendSetting(props: Props) {
  const { currentTab } = props;

  const dispatch = useDispatch();
  const { topicRecommendSaleListFilter, topicRecommendSaleListResult, isFetching } = useSelector(topicState);

  const urlParameters = qs.parseUrl(window.location.href);
  const topicCategoryId = parseInt(urlParameters.query.category as string, 10);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [addSalePageModal, setAddSalePageModal] = useState<boolean>(false);
  const [recommendMap, setRecommendMap] = useState<Map<number, number | undefined>>(new Map());
  const [checkSalesItem, setCheckSalesItem] = useState<Set<number>>(new Set());
  const [page, setPage] = useState<number>(1);

  const setPageLimit = (value: SelectValue) => {
    dispatch(
      setTopicRecommendSaleListFilter({ ...topicRecommendSaleListFilter, limit: parseInt(value as string, 10) }),
    );
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      setTopicRecommendSaleListFilter({
        ...topicRecommendSaleListFilter,
        offset: (value - 1) * topicRecommendSaleListFilter.limit,
      }),
    );
  };

  const onCheckAllSalesItem = (checked: boolean) => {
    if (checked) {
      const allSalesItem = topicRecommendSaleListResult.results.map((item) => item.pageId);
      setCheckSalesItem(new Set(allSalesItem));
    } else {
      setCheckSalesItem(new Set());
    }
  };

  const onSetCheckSalesItem = (checked: boolean, pageId: number) => {
    if (checked) {
      setCheckSalesItem(new Set(checkSalesItem.add(pageId)));
    } else {
      const newCheckSalesItem = new Set(checkSalesItem);
      newCheckSalesItem.delete(pageId);
      setCheckSalesItem(newCheckSalesItem);
    }
  };

  const onResetPageRecommend = () => {
    Modal.confirm({
      title: "您確定清空站長推薦本頁的排序？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        const resetMap = new Map(recommendMap);
        resetMap.clear();
        setRecommendMap(resetMap);
      },
    });
  };

  const onDeleteSalePage = () => {
    Modal.confirm({
      title: "您確定刪除本頁資料？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        const removePageIds = Array.from(checkSalesItem);
        dispatch(
          fetchDeleteRecommendList({
            topicCategoryId,
            pageIds: removePageIds,
          }),
        );

        setCheckSalesItem(new Set());
        removePageIds.forEach((pageId) => {
          const newMap = new Map(recommendMap);
          newMap.delete(pageId);
          setRecommendMap(newMap);
        });
      },
    });
  };

  const onSubmit = () => {
    const checkHasValue = topicRecommendSaleListResult.results.every((item) => recommendMap.get(item.pageId));
    if (topicRecommendSaleListResult.results.length !== recommendMap.size || !checkHasValue) {
      Modal.warning({
        title: "主題站長推薦尚未填寫",
      });
      return;
    }

    const data = [];
    for (const [key, value] of recommendMap) {
      data.push({
        salesPage: key,
        rank: value as number,
      });
    }

    dispatch(
      fetchUpdateRecommendList({
        topicCategoryId,
        data,
      }),
    );
  };

  const columns: ColumnsType<TopicCategoryRecommendSaleInfo> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: () => <Checkbox onChange={(e) => onCheckAllSalesItem(e.target.checked)} />,
      render: (_: any, values) => (
        <Checkbox
          checked={checkSalesItem.has(values.pageId)}
          onChange={(e) => onSetCheckSalesItem(e.target.checked, values.pageId)}
        />
      ),
    },
    {
      key: "pageId",
      dataIndex: "pageId",
      title: "ID",
      render: (pageId: number) => (
        <PageLink href={`${process.env.REACT_APP_WEB_DOMAIN}/salespage/${pageId}`} target="_blank" rel="noreferrer">
          {pageId}
        </PageLink>
      ),
    },
    {
      key: "skus",
      dataIndex: "skus",
      title: "SKU",
      render: (skus: string[]) => skus.map((sku) => <div>{sku}</div>),
    },
    {
      key: "pageName",
      dataIndex: "pageName",
      title: "名稱",
    },
    {
      key: "brands",
      dataIndex: "brands",
      title: "品牌",
      render: (brands: { tagId: number; name: string }[]) => brands.map((brand) => <div>{brand.name}</div>),
    },
    {
      key: "priceRange",
      dataIndex: "priceRange",
      title: "價錢",
      render: (priceRange: { min: number; max: number }) =>
        priceRange.min === priceRange.max ? (
          <div>{`$${priceRange.min}`}</div>
        ) : (
          <div>{`$${priceRange.min}-$${priceRange.max}`}</div>
        ),
    },
    {
      key: "recommend",
      dataIndex: "recommend",
      title: "主題站長推薦",
      sorter: () => 0, // antd 需要
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              dispatch(setTopicRecommendSaleListFilter({ ordering: "-rank" }));
            } else if (column.title.props.title === "Click to sort ascending") {
              dispatch(setTopicRecommendSaleListFilter({ ordering: "rank" }));
            }
          },
        };
      },
      render: (_, values) => (
        <Input
          value={recommendMap.get(values.pageId)}
          onChange={(e) => {
            setRecommendMap(
              new Map(
                recommendMap.set(
                  values.pageId,
                  e.target.value && typeof e.target.value === "string" ? parseInt(e.target.value, 10) : undefined,
                ),
              ),
            );
          }}
        />
      ),
    },
  ];

  useEffect(() => {
    if (currentTab === "recommend") {
      dispatch(
        fetchTopicCategorySaleRecommendList({
          ...topicRecommendSaleListFilter,
          topicCategoryId,
        }),
      );
    }
  }, [dispatch, topicCategoryId, topicRecommendSaleListFilter, currentTab]);

  useEffect(() => {
    // 只記當下 page list 的 id & rank
    topicRecommendSaleListResult.results.forEach((item) => {
      setRecommendMap((prev) => new Map(prev.set(item.pageId, item.rank)));
    });
  }, [dispatch, topicRecommendSaleListResult.results]);

  return (
    <div>
      <CustomRow justify="space-between" marginBottom="22px">
        <div>
          <CustomButton type="primary" icon={<PlusCircleFilled />} onClick={() => setAddSalePageModal(true)}>
            新增
          </CustomButton>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${100}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </CustomRow>
      <RecommendSettingFilter isVisible={showFilter} />
      <TableWrapper>
        <CustomRow justify="end" marginBottom="8px">
          <CustomButton disabled={recommendMap.size === 0} onClick={onResetPageRecommend}>
            重設
          </CustomButton>
        </CustomRow>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={topicRecommendSaleListResult.results}
          pagination={{
            pageSize: topicRecommendSaleListFilter.limit,
            current: page,
            showSizeChanger: false,
            total: topicRecommendSaleListResult.count,
            onChange: fetchPageChange,
          }}
        />
        <DeleteButtonWrapper>
          <CustomButton disabled={checkSalesItem.size === 0} onClick={onDeleteSalePage}>
            刪除
          </CustomButton>
        </DeleteButtonWrapper>
      </TableWrapper>
      <Row justify="end">
        <Button type="primary" onClick={onSubmit}>
          儲存此頁面
        </Button>
      </Row>
      {addSalePageModal && <RecommendAddSalePage close={() => setAddSalePageModal(false)} />}
    </div>
  );
}
