import UrMartImg from "@assets/UrMart.png";
import MenuList from "@component/MenuList";
import Router from "@component/Router";
import { fetchWebDomainService } from "@redux/configSlice";
import { setupAWS } from "@utils/AWS";
import { initializeApp } from "firebase/app";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import AuthGate from "./component/AuthGate";
import defaultTheme from "./styles/theme";

const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0 32px;
  background-color: #001529;
`;
const Logo = styled.img`
  width: 100px;
  height: auto;
`;
const Body = styled.div`
  display: flex;
  min-height: calc(100vh - 64px);
`;
const Menu = styled.div`
  flex: 0 0 208px;
  box-shadow: inset -1px 0px 0px #f0f0f0;
  z-index: 1;
  background: white;
`;

const Content = styled.div`
  flex: auto;
  min-width: 0;
`;

/* firebase */
const firebaseConfig = {
  apiKey: "AIzaSyCDRUvKIyUcaIVmRxuUOJC4drdBzEUHyYU",
  authDomain: "urmart-bcd9c.firebaseapp.com",
  projectId: "urmart-bcd9c",
  storageBucket: "urmart-bcd9c.appspot.com",
  messagingSenderId: "235197923310",
  appId: "1:235197923310:web:3ffd61135364481f1e18ff",
  measurementId: "G-N737BVRVVC",
};

setupAWS();
initializeApp(firebaseConfig);
/* firebase end */

export default function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchWebDomainService());
  }, [dispatch]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <BrowserRouter>
        <Header>
          <Logo src={UrMartImg} />
        </Header>
        <AuthGate>
          <Body>
            <Menu>
              <MenuList />
            </Menu>
            <Content>
              <Router />
            </Content>
          </Body>
        </AuthGate>
      </BrowserRouter>
    </ThemeProvider>
  );
}
