import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import ProductHome from "./index";
import ProductEdit from "./Edit";

const ProductPage: FC = () => (
  <Routes>
    <Route path="/" element={<ProductHome />} />
    <Route path="/add" element={<ProductEdit mode="add" />} />
    <Route path="/edit/:id" element={<ProductEdit mode="edit" />} />
  </Routes>
);

export default ProductPage;
