import { MarketStatisticListItem } from "@api/marketStatisticApi";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { exportMarketStatistic, fetchMarketStatistic, updateMarketStatisticParam } from "@redux/salesReportSlice";
import { Button, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import Filter from "./Filter";

const LastLocationMap: { [key: string]: { name: string; path: (item: MarketStatisticListItem) => string } } = {
  Topic: { name: "主題", path: (item) => `topic/edit/${item.lastLocationId}` },
  Promotion: { name: "活動", path: (item) => `promotion/edit/${item.promotionId}` },
  TopicCategory: { name: "主題分類", path: (_) => "topic" },
  SalesCategory: { name: "分類", path: (_) => "salespage-category" },
  Tag: { name: "品牌", path: (item) => `brand-page/edit/${item.lastLocationId}` },
  SalesPage: { name: "銷售頁", path: (item) => `salespage/edit/${item.lastLocationId}` },
};

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div`
  font-size: 14px;
`;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding: 28px 20px;
  border: 1px solid #f0f0f0;
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;

const pageSizeOptions = [20, 50, 100];

const SalesStatistics: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingMarketStatistic, marketStatisticListParams, marketStatisticListResult } = useSelector(
    (state: RootState) => state.salesReport,
  );

  const { count, results: VPCSalesList } = marketStatisticListResult;
  const { offset, limit } = marketStatisticListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...marketStatisticListParams,
      limit: value as number,
    };

    dispatch(updateMarketStatisticParam(filter));
    dispatch(fetchMarketStatistic());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...marketStatisticListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateMarketStatisticParam(filter));
    dispatch(fetchMarketStatistic());
  };

  const handleOnExport = () => {
    dispatch(exportMarketStatistic());
  };

  const tableColumns: ColumnsType<MarketStatisticListItem> = [
    {
      title: "列表頁種類",
      width: 110,
      dataIndex: ["lastLocationType"],
      render: (data: MarketStatisticListItem["lastLocationType"]) => LastLocationMap[data].name,
    },
    {
      title: "頁面ID",
      dataIndex: ["lastLocationId"],
      width: 100,
      render: (data: MarketStatisticListItem["lastLocationId"], other: MarketStatisticListItem) => (
        <Link href={LastLocationMap[other.lastLocationType].path(other)} target="_blank">
          {data}
        </Link>
      ),
    },
    {
      title: "頁面名稱",
      dataIndex: ["lastLocationName"],
      render: (text, record) => (record.lastLocationId === 1 ? "熱銷排行" : record.lastLocationName),
    },
    {
      title: "銷售頁ID",
      width: 100,
      dataIndex: ["salesPageId"],
      render: (data: MarketStatisticListItem["salesPageId"]) => (
        <Link href={`/salespage/edit/${data}`} target="_blank">
          {data}
        </Link>
      ),
    },
    {
      title: "銷售頁名稱",
      dataIndex: ["salesPageName"],
    },
    {
      title: "營收",
      width: 200,
      dataIndex: ["revenue"],
    },
    {
      title: "銷售方案數量",
      width: 150,
      dataIndex: ["salesVolume"],
    },
  ];

  return (
    <Wrapper>
      <Header>
        <PageTitle title="活動成效表" />
        <TopButtonContainer>
          <TopButton onClick={handleOnExport}>匯出</TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <Filter />
      <TableContainer>
        <Table
          locale={{ emptyText: "請篩選列表頁種類" }}
          dataSource={VPCSalesList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: count,
            onChange: handleOnPageChange,
            showSizeChanger: false,
          }}
          loading={isFetchingMarketStatistic}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default SalesStatistics;
