import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import salePageCategoryApi, { FetchSalePageListParam, SalePageListResult } from "@api/salePageCategoryApi";
import tagApi, { TagListResult } from "@api/tagApi";
import { fetchSalePageList } from "@redux/salesCategorySlice";
import type { RootState } from "@redux/rootReducer";

interface IInitialState {
  salePageListResult: SalePageListResult;
  salePageListFilter: FetchSalePageListParam;
  selectedPageIds: number[];
  brandListResult: TagListResult;
  brandListParam: BrandListParam;
  isFetching: boolean;
}

interface BrandListParam {
  typeNameQ: "品牌";
  nameQ: string;
  limit: number;
  offset: number;
}

export const initialState: IInitialState = {
  salePageListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  salePageListFilter: {
    limit: 20,
    offset: 0,
    pageIds: "",
    sku: "",
    pageName: "",
    brandNames: [],
    minPrice: "",
    maxPrice: "",
    excludeCategoryId: 0,
  },
  selectedPageIds: [],
  brandListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  isFetching: false,
};

export const fetchSalePageListExcludeCategory = createAsyncThunk(
  "salesCategoryAddSales/fetchSalePageListExcludeCategory",
  async (_, thunkApi) => {
    const {
      salesCategory: {
        selectedCategory: { id },
      },
      salesCategoryAddSales: { salePageListFilter },
    } = thunkApi.getState() as RootState;

    const filterParams = {
      ...salePageListFilter,
      limit: initialState.salePageListFilter.limit,
      offset: initialState.salePageListFilter.offset,
      excludeCategoryId: id,
    };

    thunkApi.dispatch(updateSalePageListFilter(filterParams));
    const response = await salePageCategoryApi.fetchSalePageListExcludeCategory(filterParams);
    return response;
  },
);

export const loadMoreSalePageList = createAsyncThunk(
  "salesCategoryAddSales/loadMoreSalePageList",
  async (_, thunkApi) => {
    const {
      salesCategory: {
        selectedCategory: { id },
      },
      salesCategoryAddSales: { salePageListFilter },
    } = thunkApi.getState() as RootState;

    const filterParams = {
      ...salePageListFilter,
      offset: salePageListFilter.offset + salePageListFilter.limit,
      excludeCategoryId: id,
    };

    thunkApi.dispatch(updateSalePageListFilter(filterParams));

    const response = await salePageCategoryApi.fetchSalePageListExcludeCategory(filterParams);
    return response;
  },
);

export const fetchBrandList = createAsyncThunk(
  "salesCategoryAddSales/fetchBrandList",
  async (name: string, thunkApi) => {
    const params: BrandListParam = {
      typeNameQ: "品牌",
      nameQ: name,
      limit: 20,
      offset: 0,
    };

    thunkApi.dispatch(updateBrandListParam(params));
    const response = await tagApi.fetchTagList(params);
    return response;
  },
);

export const loadMoreBrandList = createAsyncThunk("salesCategoryAddSales/loadMoreBrandList", async (_, thunkApi) => {
  const {
    salesCategoryAddSales: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: BrandListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const batchAddSalePages = createAsyncThunk("salesCategoryAddSales/batchAddSalePages", async (_, thunkApi) => {
  const {
    salesCategory: {
      selectedCategory: { id },
    },
    salesCategoryAddSales: { selectedPageIds },
  } = thunkApi.getState() as RootState;

  const response = await salePageCategoryApi.batchAddSalePages(id, selectedPageIds);
  thunkApi.dispatch(fetchSalePageList());
  return response;
});

const salesCategorySlice = createSlice({
  name: "salesCategoryAddSales",
  initialState,
  reducers: {
    reset: () => initialState,
    updateSalePageListFilter: (state, action: PayloadAction<FetchSalePageListParam>) => {
      state.salePageListFilter = action.payload;
    },
    updateSelectedPageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPageIds = action.payload;
    },
    updateBrandListParam: (state, action: PayloadAction<BrandListParam>) => {
      state.brandListParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalePageListExcludeCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSalePageListExcludeCategory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.salePageListResult = action.payload;
    });
    builder.addCase(loadMoreSalePageList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loadMoreSalePageList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.salePageListResult = {
        ...action.payload,
        results: state.salePageListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const {
  reset,
  updateSalePageListFilter,
  updateSelectedPageIds,
  updateBrandListParam,
} = salesCategorySlice.actions;
export default salesCategorySlice.reducer;
