import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { ColumnsType } from "antd/lib/table";
import { Button, Pagination, Table, Modal } from "antd";
import {
  fetchDeleteSupplyChainInfo,
  fetchProductList,
  fetchProductSupplyChainList,
  setProductFilter,
} from "@redux/brandOperation/productSlice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ProductSupplyChainListResult } from "@api/brandOperationApi";
import { currencyType } from "@api/supplyChainApi";
import ProductOutInfoEdit from "./ProductOutInfoEdit";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  right: 0;
  top: -10;
`;

const TopButton = styled(Button)`
  margin-left: auto;
  margin-bottom: 10px;
  align-self: flex-end;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 40px;
`;

type Props = {
  productId: string;
};

const ProductOutInfo = (props: Props) => {
  const { productId } = props;
  const dispatch = useAppDispatch();
  const { productSupplyChainList, isSupplyChainFetching, pruductFilter } = useSelector(
    (state: RootState) => state.brandOperationProductSlice,
  );

  const [openPopup, setOpenPopup] = useState(false);
  const [supplyChainInfoId, setSupplyChainInfoId] = useState(0);
  const [mode, setMode] = useState<"edit" | "add">("add");

  const { limit, offset } = pruductFilter;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);
  const { count } = productSupplyChainList;

  useEffect(() => {
    dispatch(fetchProductSupplyChainList(productId));
  }, [dispatch, productId]);

  const tableColumns: ColumnsType<ProductSupplyChainListResult> = [
    {
      title: "供應商名稱",
      key: "supplyChainName",
      dataIndex: "supplyChainName",
    },
    {
      title: "供應商出廠價",
      key: "quotedPrice",
      dataIndex: "quotedPrice",
      width: 150,
    },
    {
      title: "幣別",
      key: "currency",
      dataIndex: "currency",
      render: (data) => <div>{currencyType(data)}</div>,
    },
    {
      title: "狀態",
      key: "isActive",
      dataIndex: "isActive",
      render: (data) => <div>{data ? "合作中" : "停止合作"}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      width: 50,
      render: (_, values) => (
        <Row>
          <Button type="link" onClick={() => handleOnEdit(values)}>
            編輯
          </Button>
        </Row>
      ),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      fixed: "right",
      width: 50,
      render: (_, values) => (
        <Row>
          <Button type="link" onClick={() => handleOnDelete(values)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  const handleOnEdit = (values: ProductSupplyChainListResult) => {
    if (values.id) {
      setSupplyChainInfoId(values.id);
    }
    setOpenPopup(!openPopup);
    setMode("edit");
  };

  const handleOnDelete = (values: ProductSupplyChainListResult) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        if (values.id) {
          dispatch(
            fetchDeleteSupplyChainInfo({
              productId,
              supplyChainId: values.id.toString(),
            }),
          );
        }
      },
    });
  };

  const handleOnAdd = () => {
    setOpenPopup(!openPopup);
    setMode("add");
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...pruductFilter,
      offset: (page - 1) * limit,
    };
    dispatch(setProductFilter(filter));
    dispatch(fetchProductList(pruductFilter));
  };

  return (
    <Wrapper>
      <Header>
        <TopButton type="primary" onClick={handleOnAdd}>
          新增供應商資訊
        </TopButton>
      </Header>

      <TableContainer>
        <Table
          dataSource={productSupplyChainList.results}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={false}
          loading={isSupplyChainFetching}
          rowKey="id"
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
      {openPopup && (
        <ProductOutInfoEdit
          mode={mode}
          productId={productId}
          supplyChainInfoId={supplyChainInfoId}
          close={() => setOpenPopup(false)}
        />
      )}
    </Wrapper>
  );
};

export default ProductOutInfo;
