import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FinanceOrderList, contractTypeMap } from "@api/financeOrderApi";
import {
  fetchFinanceOrderList,
  setOrderListFilter,
  financeOrderState,
  deleteFinanceOrder,
} from "@redux/financeOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import PageSizeController from "@component/PageSizeController";

const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const FinanceOrderLists = () => {
  const dispatch = useDispatch();
  const { orderListFilter, financeOrderListResult, isFetching } = useSelector(financeOrderState);
  const [page, setPage] = useState<number>(1);

  const fetchPageChange = (value: number) => {
    setPage(value);
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        offset: (value - 1) * orderListFilter.limit,
      }),
    );
  };

  const columns: ColumnsType<FinanceOrderList> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "類型",
      dataIndex: "contractType",
      render: (contractType: number) => contractTypeMap[contractType],
    },
    {
      title: "供應/經銷商",
      dataIndex: "retailerName",
    },
    {
      title: "發票號碼",
      dataIndex: "invoiceNumber",
    },
    {
      title: "發票日期",
      dataIndex: "accountDate",
    },
    {
      title: "訂單總金(含稅)",
      dataIndex: "totalAmount",
    },
    {
      title: "建立人員",
      dataIndex: "createdBy",
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
    },
    {
      title: "提交時間",
      dataIndex: "submittedAt",
    },
    {
      title: "",
      dataIndex: "delete",
      fixed: "right",
      render: (_, record: FinanceOrderList) => {
        return (
          <>
            <Button type="link" onClick={() => window.open(`${window.location.href}/${record.id}`)}>
              檢視
            </Button>
            <Button
              type="link"
              onClick={() => handleDelete(record.id)}
              disabled={!!record.destroyedAt || !!record.submittedAt}
            >
              作廢
            </Button>
          </>
        );
      },
    },
  ];

  const handleDelete = (orderId: number) => {
    Modal.confirm({
      title: "你確定要作廢嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteFinanceOrder(orderId));
      },
    });
  };

  const handlePageSizeChange = (value: number) => {
    dispatch(
      setOrderListFilter({
        limit: value,
        offset: 0,
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchFinanceOrderList(orderListFilter));
  }, [dispatch, orderListFilter]);

  const checkDisabledRow = (record: any) => ({ className: record.destroyedAt && "ur-disabled-text" });

  return (
    <div>
      <TableHeader>
        <PageSizeController
          value={20}
          totalCount={financeOrderListResult.count}
          handlePageSizeChange={handlePageSizeChange}
        />
      </TableHeader>
      <Table
        loading={isFetching}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={financeOrderListResult.results}
        onRow={checkDisabledRow}
        pagination={{
          pageSize: orderListFilter.limit,
          current: page,
          showSizeChanger: false,
          total: financeOrderListResult.count,
          onChange: fetchPageChange,
        }}
      />
    </div>
  );
};

export default FinanceOrderLists;
