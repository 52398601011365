import { RootState } from "@redux/rootReducer";
import AllStaffList from "@component/AllStaffList";
import { fetchReportRegenList, updateVendorReportRegenParams, clearVendorReportRegenParams } from "@redux/vendorSlice";
import { Button, Col, DatePicker, Row, Select, Space, Input } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import { vendorApproveStatusMap } from "@constant/vendor";

import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { ReportRegenListParams } from "@page/VendorReportRegenPage/interface";

const { RangePicker } = DatePicker;

const Wrapper = styled.form`
  padding: 0 24px;
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

interface LocalFilter {
  id: number | undefined;
  approvalStatus: number | undefined;
  salesPlanId: number | undefined;
  sku: string | undefined;
  staff: number | undefined;
  affectedDate: [Moment | null, Moment | null];
  createdDate: [Moment | null, Moment | null];
}

const VendorFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { reportRegenListParams } = useSelector((state: RootState) => state.vendorSlice);

  const [filters, setFilters] = useState<LocalFilter>({
    id: undefined,
    approvalStatus: undefined,
    salesPlanId: undefined,
    sku: undefined,
    staff: undefined,
    affectedDate: [null, null],
    createdDate: [null, null],
  });

  useEffect(() => {
    const {
      id,
      approvalStatus,
      salesPlanId,
      sku,
      affectedStartDate,
      affectedEndDate,
      createdDateAfter,
      createdDateBefore,
      staff,
    } = reportRegenListParams;
    const formatedAffectedStartDate = affectedStartDate ? moment(affectedStartDate) : null;
    const formatedAffectedEndDate = affectedEndDate ? moment(affectedEndDate) : null;
    const formatedCreatedDateAfter = createdDateAfter ? moment(createdDateAfter) : null;
    const formatedCreatedDateBefore = createdDateBefore ? moment(createdDateAfter) : null;

    setFilters({
      id,
      approvalStatus,
      salesPlanId,
      sku,
      staff,
      affectedDate: [formatedAffectedStartDate, formatedAffectedEndDate],
      createdDate: [formatedCreatedDateAfter, formatedCreatedDateBefore],
    });
  }, [reportRegenListParams]);

  function onAffectedDateChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        affectedDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        affectedDate: [null, null],
      }));
    }
  }

  function onCreatedDateChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        createdDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        createdDate: [null, null],
      }));
    }
  }

  function handleFilter(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { affectedDate, createdDate, id, approvalStatus, salesPlanId, sku, staff } = filters;
    const filterParams: ReportRegenListParams = {
      limit: 20,
      offset: 0,
      id,
      approvalStatus,
      salesPlanId,
      sku,
      staff,
      affectedStartDate: affectedDate[0] ? moment(affectedDate[0]).format("YYYY-MM-DD") : undefined,
      affectedEndDate: affectedDate[1] ? moment(affectedDate[1]).format("YYYY-MM-DD") : undefined,
      createdDateAfter: createdDate[0] ? moment(createdDate[0]).format("YYYY-MM-DD") : undefined,
      createdDateBefore: createdDate[1] ? moment(createdDate[1]).format("YYYY-MM-DD") : undefined,
    };
    dispatch(updateVendorReportRegenParams(filterParams));
    dispatch(fetchReportRegenList());
  }

  function clear() {
    dispatch(clearVendorReportRegenParams());
    dispatch(fetchReportRegenList());
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = e.target;
    setFilters((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleSelect = (key: string, value: number) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Wrapper onSubmit={handleFilter}>
      <Row>
        <Col span={5}>
          <Row>
            <Space size={20}>
              <Title>編號</Title>
              <Input value={filters.id} name="id" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>審核狀態</Title>
              <Select
                style={{ width: 200 }}
                placeholder="請選擇"
                onChange={(value: number) => handleSelect("approvalStatus", value)}
                value={filters.approvalStatus}
              >
                {Object.keys(vendorApproveStatusMap).map((el) => (
                  <Select.Option value={el}>{vendorApproveStatusMap[Number(el)]}</Select.Option>
                ))}
              </Select>
            </Space>
          </Row>
        </Col>
        <Col span={7}>
          <Row>
            <Space size={20}>
              <Title>銷售頁方案 ID</Title>
              <Input value={filters.salesPlanId} name="salesPlanId" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>SKU ID</Title>
              <Input value={filters.sku} name="sku" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "14px" }}>
        <Col span={9}>
          <Row>
            <Space size={20}>
              <Title>影響訂單時間區間</Title>
              <RangePicker
                style={{ width: 300 }}
                locale={locale}
                value={filters.affectedDate}
                onChange={onAffectedDateChange}
              />
            </Space>
          </Row>
        </Col>
        <Col span={9}>
          <Row>
            <Space size={20}>
              <Title>申請時間</Title>
              <RangePicker
                style={{ width: 300 }}
                locale={locale}
                value={filters.createdDate}
                onChange={onCreatedDateChange}
              />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>申請人</Title>
              <AllStaffList value={filters.staff} onChange={(value: number) => handleSelect("staff", value)} />
            </Space>
          </Row>
        </Col>
      </Row>

      <Row justify="end" style={{ marginTop: 17 }}>
        <Space>
          <Button type="default" size="middle" onClick={clear}>
            清除篩選條件
          </Button>
          <Button type="primary" size="middle" htmlType="submit">
            套用
          </Button>
        </Space>
      </Row>
    </Wrapper>
  );
};

export default VendorFilter;
