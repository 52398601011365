import { useEffect, FC } from "react";

interface Props {
  title: string;
}

const PageTitle: FC<Props> = (props) => {
  const { title } = props;

  useEffect(() => {
    document.title = title;
  }, [title]);

  return null;
};

export default PageTitle;
