import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import UploadImageButton from "@component/UploadImageButton";
import { fetchCreateTopic, fetchTopicFullUpdate, topicState, TopicStatus } from "@redux/topicSlice";
import { Button, Form, Input, message, Modal, Spin, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { ChromePicker, ColorChangeHandler } from "react-color";

const Title = styled.div`
  padding-bottom: 12px;
  margin-left: 22px;
  margin-bottom: 16px;
  border-bottom: solid 1px ${({ theme }) => theme.colorNeutral300};
  ${fontStyle("16px", "16px")};
`;

const CustomInput = styled(Input)<{ width?: string; mrleft?: string }>`
  width: ${({ width }) => width};
  margin-left: ${({ mrleft }) => mrleft};
`;

const CustomItem = styled(Form.Item)`
  margin-left: 22px;

  .ant-form-item-label > label.ant-form-item-no-colon::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-no-colon::after {
    margin: 0;
  }
`;

const Footer = styled.div`
  width: calc(100% - 325px - 208px);
  position: fixed;
  bottom: 0;
  right: 25px;
  padding: 12px;
  text-align: right;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const Scope = styled.div`
  position: relative;
`;

const InputAbsolute = styled(CustomInput)`
  position: absolute;
  left: 31%;
  width: 59.5%;
  top: 0;
`;

const Notice = styled.span`
  margin-right: 10px;
  ${fontStyle("14px", "22px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;
const ColorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const ColorSquare = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;
const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ColorPickerContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  transform: translate(0, -100%);
`;
const ColorPickerMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default function SetupTopic() {
  const dispatch = useDispatch();
  const { topicInfo, topicStatus, topicShortInfo, isFetching } = useSelector(topicState);
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const toggleShowColorPicker = () => setShowColorPicker((prev) => !prev);
  const navigate = useNavigate();
  const { topicId } = useParams();

  const [form] = Form.useForm();

  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const handleOnColorChange: ColorChangeHandler = (color) => {
    form.setFieldsValue({
      backgroundColor: color.hex,
    });
  };

  const onSubmit = (values: any) => {
    if (topicId) {
      dispatch(fetchTopicFullUpdate({ id: parseInt(topicId, 10), topicData: values }));
    } else {
      dispatch(fetchCreateTopic(values));
    }
  };

  useEffect(() => {
    if (topicStatus === TopicStatus.CREATE && topicShortInfo) {
      navigate(`/topic/edit/${topicShortInfo.id}`);
    }
  }, [topicStatus, topicShortInfo, navigate]);

  useEffect(() => {
    switch (topicStatus) {
      case TopicStatus.CREATE:
        message.success("新增成功");
        break;
      case TopicStatus.UPDATE:
        message.success("更新成功");
        break;
      default:
        break;
    }
  }, [topicStatus]);

  useEffect(() => {
    if (topicInfo) {
      form.setFieldsValue({
        title: topicInfo.title,
        iconUrl: topicInfo.icon || "",
        iconAlt: topicInfo.iconAlt || "",
        imageUrl: topicInfo.pageInfo.image?.url || "",
        imageAlt: topicInfo.pageInfo.image?.alt || "",
        isActive: topicInfo.isActive,
        toShow: topicInfo.toShow,
        metaTitle: topicInfo.pageInfo.metaTitle || "",
        metaKeywords: topicInfo.pageInfo.metaKeywords || "",
        metaDescription: topicInfo.pageInfo.metaDescription || "",
        rank: topicInfo?.rank,
        backgroundColor: topicInfo.pageInfo.backgroundColor,
      });
    }
  }, [form, topicInfo]);

  return (
    <Spin spinning={isFetching}>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        colon={false}
        onFinish={onSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <CustomItem label="啟用" name="isActive" valuePropName="checked">
          <Switch onChange={(value) => updateForm("isActive", value)} />
        </CustomItem>
        <CustomItem label="顯示於前台" name="toShow" valuePropName="checked">
          <Switch onChange={(value) => updateForm("toShow", value)} />
        </CustomItem>
        <CustomItem label="主題館名稱" name="title" rules={[{ required: true, message: "必填" }]}>
          <Input onChange={(e) => updateForm("title", e.target.value)} />
        </CustomItem>
        <CustomItem label="主題背景色" name="backgroundColor">
          <ColorWrapper>
            <Form.Item
              noStyle
              shouldUpdate={(prevValue, currentValues) => {
                return prevValue.pageInfo?.backgroundColor !== currentValues.pageInfo?.backgroundColor;
              }}
            >
              {({ getFieldValue }) => {
                const backgroundColor = getFieldValue("backgroundColor") || "#f4f5fa";

                return (
                  <Form.Item noStyle>
                    <ColorSquare onClick={toggleShowColorPicker} color={backgroundColor} />
                    <Text>{backgroundColor}</Text>
                    {showColorPicker && (
                      <>
                        <ColorPickerMask onClick={toggleShowColorPicker} />
                        <ColorPickerContainer>
                          <ChromePicker color={backgroundColor} disableAlpha onChange={handleOnColorChange} />
                        </ColorPickerContainer>
                      </>
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </ColorWrapper>
        </CustomItem>
        <CustomItem label="順序" name="rank">
          <CustomInput width="70px" onChange={(e) => updateForm("rank", parseInt(e.target.value, 10))} />
        </CustomItem>
        <Title>內容</Title>
        <Scope>
          <CustomItem label="圖片" name="imageUrl">
            <UploadImageButton
              hasPreviewAndDelete={!!topicInfo?.pageInfo?.image?.id}
              imageUrl={form.getFieldValue("imageUrl") || ""}
              finishUpload={(url) => updateForm("imageUrl", url)}
              onDelete={() =>
                Modal.confirm({
                  title: "確定要刪除此圖片嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeMedia({
                      module: DeleteModule.PAGE_INFO,
                      pageInfoId: topicInfo?.pageInfo.id,
                      mediaId: topicInfo?.pageInfo.image.id as number,
                    });
                    updateForm("imageUrl", undefined);
                  },
                })
              }
            />
          </CustomItem>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("imageAlt", e.target.value)}
          />
        </Scope>
        <Scope>
          <CustomItem label="Icon" name="iconUrl">
            <UploadImageButton
              hasPreviewAndDelete={!!topicInfo?.iconId}
              imageUrl={form.getFieldValue("iconUrl") || ""}
              finishUpload={(url) => updateForm("iconUrl", url)}
              onDelete={() =>
                Modal.confirm({
                  title: "確定要刪除此Icon嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeMedia({
                      module: DeleteModule.TOPIC,
                      topicId: topicInfo?.id,
                      mediaId: topicInfo?.iconId as number,
                    });
                    updateForm("iconUrl", undefined);
                  },
                })
              }
            />
          </CustomItem>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("iconAlt", e.target.value)}
          />
        </Scope>
        <Form.Item name="imageAlt" noStyle />
        <Form.Item name="iconAlt" noStyle />
        <Title>SEO</Title>
        <CustomItem label="Meta Title" name="metaTitle">
          <CustomInput onChange={(e) => updateForm("metaTitle", e.target.value)} />
        </CustomItem>
        <CustomItem label="Meta Keywords" name="metaKeywords">
          <Input.TextArea onChange={(e) => updateForm("metaKeywords", e.target.value)} />
        </CustomItem>
        <CustomItem label="Meta Description" name="metaDescription">
          <Input.TextArea onChange={(e) => updateForm("metaDescription", e.target.value)} />
        </CustomItem>
        <Footer>
          {!topicId && <Notice>*建立後顯示管理內容頁面</Notice>}
          <Button type="primary" htmlType="submit">
            {topicId ? "儲存此分頁" : "建立資料"}
          </Button>
        </Footer>
      </Form>
    </Spin>
  );
}
