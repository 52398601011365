import downloadXLS from "@utils/downloadXLS";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

export interface VipStatisticsListParams {
  startDate?: string;
  endDate?: string;
}

export interface VipStatisticsListResult {
  vipMember: number;
  canceledVipMember: number;
  data: {
    month: string;
    brandNewVipCount: number;
    newVipNetAddition: number;
    newVipCount: number;
    newVipAnnualFee: number;
    newVipRetentionCount: number;
    newVipRetentionRate: number;
    costSum: number;
    orderCount: number;
    sumRevenue: number;
    grossMargin: number;
  }[];
}

export interface VipBrandNewMemberExportParams {
  month: string;
}

interface VipStatisticsApi {
  fetchVipStatisticsList: (params: VipStatisticsListParams) => Promise<VipStatisticsListResult>;
  fetchVipInValidMemberExport: () => Promise<void>;
  fetchVipStatisticsListExport: (params: VipStatisticsListParams) => Promise<void>;
  fetchVipBrandNewMemberExport: (params: VipBrandNewMemberExportParams) => Promise<void>;
}

const vipStatisticsApi: VipStatisticsApi = {
  fetchVipStatisticsList: async (params) => {
    const response = await apiClient.getData("/manage/account/vip-statistics/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipInValidMemberExport: async () => {
    const response = await apiClient.getData(
      "/manage/account/vip-statistics/invalid-member-export/",
      {},
      {
        responseType: "blob",
      },
    );
    downloadXLS(response);
  },
  fetchVipStatisticsListExport: async (params) => {
    const response = await apiClient.getData("/manage/account/vip-statistics/export/", transformCamelToSnake(params), {
      responseType: "blob",
    });
    downloadXLS(response);
  },
  fetchVipBrandNewMemberExport: async (params) => {
    const response = await apiClient.getData(
      "/manage/account/vip-statistics/brand-new-member-export/",
      transformCamelToSnake(params),
      {
        responseType: "blob",
      },
    );
    downloadXLS(response);
  },
};

export default vipStatisticsApi;
