import productApi, {
  FetchProductListParam,
  FetchTagsListResult,
  FetchTagsParams,
  FetchVendorListParam,
  ProductCategory,
  ProductListResult,
  VendorListResult,
} from "@api/productApi";
import tagApi, { FetchTagListParam, TagListResult } from "@api/tagApi";
import { SalesChannelType } from "@api/vendorApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message, Modal } from "antd";

interface IState {
  isFetchingList: boolean;
  productListResult: ProductListResult;
  productListFilter: FetchProductListParam;
  selectedProductIds: number[];
  productCategory: ProductCategory;
  brandListResult: TagListResult;
  brandListParam: FetchTagListParam;
  vendorListResult: VendorListResult;
  vendorListParam: FetchVendorListParam;
  showImportCsvPopup: boolean;
  csvErrorMessage: string;
  tagListResult: FetchTagsListResult;
}

export const initialState: IState = {
  isFetchingList: false,
  productListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  productListFilter: {
    limit: 20,
    offset: 0,
    nameQ: undefined,
    sku: undefined,
    vendor: -1,
    owner: -1,
    brand: undefined,
    brandId: -1,
    isActive: -1,
    syncOption: -1,
    categoryLevel1: -1,
    categoryLevel2: -1,
    categoryLevel3: -1,
    lowStockRisk: false,
    attrNameQ: undefined,
    salesChannel: SalesChannelType.URMART,
  },
  selectedProductIds: [],
  productCategory: {
    level1: {},
    level2: {},
    level3: {},
  },
  brandListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  vendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorListParam: {
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  showImportCsvPopup: false,
  csvErrorMessage: "",
  tagListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
};

export const fetchProductList = createAsyncThunk("product/fetchProductList", async (_, thunkApi) => {
  const {
    product: { productListFilter },
  } = thunkApi.getState() as RootState;
  const response = await productApi.fetchProductList(productListFilter);
  return response;
});

export const fetchProductCategoryList = createAsyncThunk("product/fetchProductCategoryList", async () => {
  const response = await productApi.fetchProductCategoryList();
  return response;
});

export const fetchBrandList = createAsyncThunk("product/fetchBrandList", async (name: string | undefined, thunkApi) => {
  const params: FetchTagListParam = {
    typeNameQ: "品牌",
    nameQ: name || "",
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("product/loadMoreBrandList", async (_, thunkApi) => {
  const {
    product: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchTagListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const fetchVendorList = createAsyncThunk(
  "product/fetchVendorList",
  async (name: string | undefined, thunkApi) => {
    const params: FetchVendorListParam = {
      nameQ: name || "",
      limit: 20,
      offset: 0,
    };

    thunkApi.dispatch(updateVendorListParam(params));
    const response = await productApi.fetchVendorList(params);
    return response;
  },
);

export const loadMoreVendorList = createAsyncThunk("product/loadMoreVendorList", async (_, thunkApi) => {
  const {
    product: { vendorListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchVendorListParam = {
    ...vendorListParam,
    offset: vendorListParam.offset + vendorListParam.limit,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const batchActivesProducts = createAsyncThunk(
  "product/batchActivesProducts",
  async (isActive: boolean, thunkApi) => {
    const {
      product: { selectedProductIds },
    } = thunkApi.getState() as RootState;

    const response = await productApi.batchActivesProducts(selectedProductIds, isActive);
    thunkApi.dispatch(fetchProductList());
    thunkApi.dispatch(updateSelectedProductIds([]));
    return response;
  },
);

export const batchAddProductVendorsByCSV = createAsyncThunk(
  "product/batchAddProductVendorsByCSV",
  async (file: File) => {
    const response = await productApi.batchAddProductVendorsByCSV(file);
    Modal.info({
      title: "完成匯入時，將會寄信到您的Email",
      okText: "我知道了",
    });
    return response;
  },
);

export const exportProductVendorsByCSV = createAsyncThunk("product/exportProductVendorsByCSV", async (_, thunkApi) => {
  const {
    product: { productListFilter },
  } = thunkApi.getState() as RootState;

  const response = await productApi.exportProductVendorsByCSV(productListFilter);
  if (response !== "Success") {
    message.success(response);
  }
  return response;
});

export const fetchTags = createAsyncThunk("product/fetchTags", async (params: FetchTagsParams) => {
  const response = await productApi.fetchTags(params);
  return response;
});

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: () => initialState,
    updateProductListFilter: (state, action: PayloadAction<FetchProductListParam>) => {
      state.productListFilter = action.payload;
    },
    updateBrandListParam: (state, action: PayloadAction<FetchTagListParam>) => {
      state.brandListParam = action.payload;
    },
    updateVendorListParam: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.vendorListParam = action.payload;
    },
    updateSelectedProductIds: (state, action: PayloadAction<number[]>) => {
      state.selectedProductIds = action.payload;
    },
    clearProductListFilter: (state) => {
      state.productListFilter = initialState.productListFilter;
    },
    openImportCsvPopup: (state) => {
      state.showImportCsvPopup = true;
    },
    closeImportCsvPopup: (state) => {
      state.showImportCsvPopup = false;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductList.pending, (state) => {
      state.isFetchingList = true;
    });
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.productListResult = action.payload;
    });
    builder.addCase(fetchProductCategoryList.fulfilled, (state, action) => {
      state.productCategory = action.payload;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorListResult = action.payload;
    });
    builder.addCase(loadMoreVendorList.fulfilled, (state, action) => {
      state.vendorListResult = {
        ...action.payload,
        results: state.vendorListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(batchAddProductVendorsByCSV.fulfilled, (state) => {
      state.showImportCsvPopup = false;
      state.csvErrorMessage = "";
    });
    builder.addCase(batchAddProductVendorsByCSV.rejected, (state, action) => {
      state.csvErrorMessage = action.error.message || "";
    });
    builder.addCase(fetchTags.fulfilled, (state, action) => {
      state.tagListResult = action.payload;
    });
  },
});

export const {
  reset,
  updateBrandListParam,
  updateProductListFilter,
  updateVendorListParam,
  updateSelectedProductIds,
  clearProductListFilter,
  openImportCsvPopup,
  closeImportCsvPopup,
  clearCSVErrorMessage,
} = productSlice.actions;
export default productSlice.reducer;
