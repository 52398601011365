import { ExclamationCircleFilled, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import manufacturerApi, { ManufacturerListItem } from "@api/brandOperation/manufacturerApi";
import SearchSelect from "@component/Common/SearchSelect";
import PageTitle from "@component/PageTitle";
import {
  deleteManufacturer,
  fetchManufacturerList,
  loadMoreManufacturerList,
  manufacturerState,
  resetManufacturerListParams,
  resetManufacturerSlice,
  updateManufacturerListParams,
} from "@redux/brandOperation/manufacturerSlice";
import { Button, Col, Form, Modal, Row, Select, Space, Table } from "antd";
import { useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentWrapper,
  CustomCenterRow,
  FilterWrapper,
  FlexBox,
  Header,
  PageText,
  SelectPageSize,
} from "src/styles/common";

const ManufacturerList = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { isFetching, manufacturerListResult, manufacturerListParams } = useSelector(manufacturerState);
  const { results: manufacturerList, count: totalCount } = manufacturerListResult;
  const [page, setPage] = useState<number>(1);
  const [showFilter, setShowFilter] = useState(false);

  const handlePageOpen = (id?: number) => {
    if (id) {
      window.open(`/operation-of-brand/manufacturer/${id}`, "_blank");
    }
    window.open("/operation-of-brand/manufacturer/add", "_blank");
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    dispatch(
      updateManufacturerListParams({
        ...manufacturerListParams,
        limit: Number(value),
      }),
    );
  };

  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updateManufacturerListParams({
        ...manufacturerListParams,
        offset: manufacturerListParams.limit * (value - 1),
      }),
    );
  };
  const showDeleteConfirm = (name: string, manufacturerId: number) => {
    confirm({
      title: "確定要刪除此筆資料？",
      icon: <ExclamationCircleFilled />,
      content: name,
      okText: "確認",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        dispatch(deleteManufacturer(manufacturerId));
      },
    });
  };

  const columns: ColumnsType<ManufacturerListItem> = [
    {
      key: "name",
      dataIndex: "name",
      title: "製造商名稱",
      align: "center",
    },
    {
      key: "address",
      dataIndex: "address",
      align: "center",
      title: "製造商地址",
    },
    {
      key: "phone",
      dataIndex: "phone",
      title: "製造商電話",
      align: "center",
    },
    {
      key: "majorName",
      dataIndex: "majorName",
      title: "製造商主要聯絡人",
      width: 160,
      align: "center",
    },
    {
      key: "majorEmail",
      dataIndex: "majorEmail",
      title: "製造商電子郵件",
      width: "15%",
      align: "center",
    },
    {
      key: "staff",
      dataIndex: ["staff", "name"],
      title: "運營負責人",
      width: 120,
      align: "center",
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      render: (_, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => {
              handlePageOpen(record.id);
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => showDeleteConfirm(record.name, record.id!)}>
            刪除
          </Button>
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchManufacturerList());
  }, [dispatch, manufacturerListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetManufacturerSlice());
    };
  }, [dispatch]);

  return (
    <ContentWrapper>
      <PageTitle title="製造商" />
      <Header>
        <FlexBox>
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              handlePageOpen();
            }}
          >
            新增製造商
          </Button>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </FlexBox>
        <CustomCenterRow>
          <PageText>{`總共${totalCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CustomCenterRow>
      </Header>
      <ManufacturerListFilter show={showFilter} />
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={manufacturerList}
        rowKey="name"
        pagination={{
          pageSize: manufacturerListParams.limit,
          current: page,
          showSizeChanger: false,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />
    </ContentWrapper>
  );
};

export default ManufacturerList;

const ManufacturerListFilter = (props: { show: boolean }) => {
  const { show } = props;
  const dispatch = useDispatch();
  const { manufacturerListParams } = useSelector(manufacturerState);
  const [form] = useForm();

  const onReset = () => {
    dispatch(resetManufacturerListParams());
    form.resetFields();
  };
  const onSubmit = () => {
    const nameQ = form.getFieldValue("nameQ");
    if (nameQ) {
      dispatch(updateManufacturerListParams({ ...manufacturerListParams, nameQ }));
    }
  };

  useEffect(() => {
    dispatch(loadMoreManufacturerList({ limit: 10, offset: 0 }));
  }, [dispatch]);

  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="製造商名稱" name="nameQ">
              <SearchSelect
                fetchListApi={manufacturerApi.fetchManufacturerList}
                optionsKey={{ label: "name", value: "name" }}
                onChange={(value) => {
                  form.setFieldsValue({ nameQ: value });
                }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <Space>
          <Button onClick={onReset}>清除篩選條件</Button>
          <Button type="primary" onClick={onSubmit}>
            套用
          </Button>
        </Space>
      </FlexBox>
    </FilterWrapper>
  );
};
