import { createSlice } from "@reduxjs/toolkit";

interface IState {
  message: string;
  isLoading: boolean;
}

const initialState: IState = {
  message: "",
  isLoading: false,
};

const notifySlice = createSlice({
  name: "notify",
  initialState,
  reducers: {
    reset: () => initialState,
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
});
export const { showLoading, hideLoading } = notifySlice.actions;
export default notifySlice.reducer;
