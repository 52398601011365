import CoreAPI from "@api/CoreAPI";
import { ListResponseType } from "src/types";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

export enum ContractType {
  CONSIGNMENT = 20,
  TRANSFER = 30,
}

export const contractTypeMap: { [contractType: number]: string } = {
  [ContractType.CONSIGNMENT]: "寄倉",
  [ContractType.TRANSFER]: "轉單",
};

export interface FinanceOrderList {
  id: number;
  orderNumber: string | null;
  contractType: number;
  retailerName: string;
  accountDate: string;
  invoiceNumber: string;
  totalAmount: number;
  createdAt: string;
  createdBy: string;
  submittedAt: string | null;
  destroyedAt: string | null;
}

export interface FinanceOrderProduct {
  id: number;
  sku: string;
  barcode: string | null;
  name: string;
  qty: number;
}
export interface FinanceOrderDetail {
  id: number;
  orderNumber: string | null;
  contractType: number;
  retailerName: string;
  accountDate: string;
  invoiceNumber: string;
  totalAmount: number;
  createdAt: string;
  createdBy: string;
  submittedAt: string | null;
  destroyedAt: string | null;
}
export interface OrderListFilter {
  limit: number;
  offset: number;
  sku?: string;
  contractType?: number;
  retailerId?: number;
  accountDateAfter?: string;
  accountDateBefore?: string;
}
export interface ProductsListFilter {
  limit: number;
  offset: number;
}

export interface FinanceOrderParams {
  contractType: number;
  retailerId: number;
  accountDate: string;
  invoiceNumber: number;
  totalAmount: number;
}

export interface UploadOrderParams extends FinanceOrderParams {
  file: File;
}

interface FinanceOrderApi {
  fetchFinanceOrderList(params: OrderListFilter): Promise<ListResponseType<FinanceOrderList>>;
  uploadFinanceOrder(payload: UploadOrderParams): Promise<void>;
  fetchFinanceOrderDetail(orderId: string): Promise<FinanceOrderDetail>;
  fetchOrderProducts(payload: {
    orderId: string;
    limit: number;
    offset: number;
  }): Promise<ListResponseType<FinanceOrderProduct>>;
  submitFinanceOrder(orderId: string): Promise<void>;
  deleteFinanceOrder(orderId: number): Promise<void>;
}

const financeOrderApi: FinanceOrderApi = {
  fetchFinanceOrderList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/finance/orders/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  uploadFinanceOrder: async (payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("account_date", payload.accountDate);
    formData.append("contract_type", payload.contractType.toString());
    formData.append("invoice_number", payload.invoiceNumber.toString());
    formData.append("retailer_id", payload.retailerId.toString());
    formData.append("total_amount", payload.totalAmount.toString());

    const response = await apiClient.postData("/tryall/manage/finance/orders/upload/", formData);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchFinanceOrderDetail: async (orderId) => {
    const response = await apiClient.getData(`/tryall/manage/finance/orders/${orderId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchOrderProducts: async (payload) => {
    const { orderId, ...restPayload } = payload;
    const response = await apiClient.getData(
      `/tryall/manage/finance/orders/${orderId}/monthly-lines/`,
      transformCamelToSnake(restPayload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  submitFinanceOrder: async (orderId) => {
    const response = await apiClient.putData(`/tryall/manage/finance/orders/${orderId}/submit/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteFinanceOrder: async (orderId) => {
    const response = await apiClient.deleteData(`/tryall/manage/finance/orders/${orderId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default financeOrderApi;
