import { Button, Col, Input, Form, Row, Select, Tag, DatePicker, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FlexBox } from "src/styles/common";
import styled from "styled-components";
import { ProcessText, ProcessValue } from "@page/Cost/constans";
import locale from "antd/es/date-picker/locale/zh_TW";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { updatePcmListParams } from "@redux/brandOperation/PcmSlice";
import { RootState } from "@redux/rootReducer";

const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;
const FilterWrapper = styled.div`
  height: auto;
  overflow: hidden;
  transition: 0.3s;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const CustomCol = styled(Col)`
  margin-right: 10px;
  position: absolute;
  top: -26px;
  left: 66px;
`;

type Props = {
  handleOnKeywordSearch: () => void;
  vendorOptions: any;
  isSelectLoading: boolean;
};

const Filter = (props: Props) => {
  const { handleOnKeywordSearch, vendorOptions, isSelectLoading } = props;
  const dispatch = useDispatch();
  const { pcmListParams } = useSelector((state: RootState) => state.pcmSlice);

  const [form] = Form.useForm();
  const [tags, setTags] = useState<string[]>([]);

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    const params = {
      ...pcmListParams,
      producer: formValues.producer || undefined,
      status: formValues.status,
      pcmNumberQ: formValues.pcmNumberQ || undefined,
      deployDateBefore: formValues.deployDate ? formValues.deployDate[1].format("YYYY-MM-DD") : undefined,
      deployDateAfter: formValues.deployDate ? formValues.deployDate[0].format("YYYY-MM-DD") : undefined,
      paymentDateBefore: formValues.paymentDate ? formValues.paymentDate[1].format("YYYY-MM-DD") : undefined,
      paymentDateAfter: formValues.paymentDate ? formValues.paymentDate[0].format("YYYY-MM-DD") : undefined,
    };
    dispatch(updatePcmListParams(params));
  };

  const onReset = () => {
    form.resetFields();
    dispatch(updatePcmListParams({}));
  };

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
  };

  useEffect(() => {
    form.setFieldsValue({ skus: tags.toString() });
  }, [form, tags]);

  return (
    <FilterWrapper>
      <Form colon={false} labelCol={{ span: 6 }} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={[16, 8]}>
          <Col style={{ height: "31px" }} />
        </Row>
        <Row gutter={16}>
          <Col span={8} flex="auto">
            <Form.Item label="採購單號" name="pcmNumberQ">
              <Input allowClear />
            </Form.Item>
          </Col>
          <Col span={6} flex="auto">
            <CustomCol flex="auto">
              {tags.map((tag) => (
                <span key={tag}>
                  <Tag
                    closable
                    onClose={(e) => {
                      e.preventDefault();
                      handleClose(tag);
                    }}
                  >
                    {tag}
                  </Tag>
                </span>
              ))}
            </CustomCol>
            <Form.Item label="製造商" name="producer">
              <Select
                showSearch
                filterOption={false}
                onSearch={handleOnKeywordSearch}
                loading={isSelectLoading}
                placeholder="請選擇"
              >
                {vendorOptions}
              </Select>
            </Form.Item>
            <Form.Item name="skus" noStyle />
          </Col>
          <Col span={6}>
            <Form.Item label="狀態" name="status">
              <Select placeholder="請選擇">
                <Select.Option value={ProcessValue.CLEAN}>{ProcessText[ProcessValue.CLEAN]}</Select.Option>
                <Select.Option value={ProcessValue.PENDING}>{ProcessText[ProcessValue.PENDING]}</Select.Option>
                <Select.Option value={ProcessValue.APPROVE}>{ProcessText[ProcessValue.APPROVE]}</Select.Option>
                <Select.Option value={ProcessValue.REJECT}>{ProcessText[ProcessValue.REJECT]}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="skus" noStyle />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="下單日期" name="deployDate">
              <DatePicker.RangePicker locale={locale} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              label={
                <>
                  付款時間
                  <Tooltip
                    placement="bottom"
                    title="付款時間為實際付款時間及預期付款時間混合篩選，若有實際付款時間則篩選以實際付款時間為主，若無則篩選出符合預期付款時間項目"
                  >
                    <InfoIcon />
                  </Tooltip>
                </>
              }
              name="paymentDate"
            >
              <DatePicker.RangePicker locale={locale} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" onClick={onSubmit}>
          套用
        </Button>
      </FlexBox>
    </FilterWrapper>
  );
};

export default Filter;
