import { VipBulletinList } from "@api/vipPromotionApi";
import PopupBackground from "@component/PopupBackground";
import UploadImageButton from "@component/UploadImageButton";
import { fetchCreateVipBulletin, fetchUpdateVipBulletin } from "@redux/vipPromotionSlice";
import { Button, DatePicker, Form, Input, Row, Select, Switch } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  padding: 20px 14px 20px 27px;
  position: relative;
  width: 620px;
  background: ${({ theme }) => theme.colorNeutral100};

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-btn-block {
    width: auto;
  }
`;
const CustomRangePicker = styled(DatePicker.RangePicker)<{ showTime: any }>`
  width: 100%;
`;
const Scope = styled.div`
  position: relative;
`;
const CustomInput = styled(Input)<{ width?: string; mrleft?: string }>`
  width: ${({ width }) => width};
  margin-left: ${({ mrleft }) => mrleft};
`;
const InputAbsolute = styled(CustomInput)`
  position: absolute;
  left: 41%;
  width: 271px;
  top: 0;
`;
const LimitNotice = styled.div`
  position: absolute;
  left: 41%;
  top: 37px;
  ${fontStyle("14px", "16px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;
const CustomRow = styled(Row)`
  margin-top: 25px;
`;
const CustomButton = styled(Button)`
  margin-right: 8px;
`;

type Props = {
  vipBulletin?: VipBulletinList;
  onClose: () => void;
};

export default function EditVipGiftPopup(props: Props) {
  const { vipBulletin, onClose } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onSubmit = (values: any) => {
    const imageDirPrefix =
      values.imageUrl && !values.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "vip_gift/image"
        : undefined;
    const imageFilePath =
      values.imageUrl && !values.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? values.imageUrl
        : undefined;

    const payload = {
      isActive: values.isActive === "active",
      isDefault: values.isDefault,
      title: values.title,
      startAt:
        values?.timePeriod && values.timePeriod.length > 0
          ? values.timePeriod[0].format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      endAt:
        values?.timePeriod && values.timePeriod.length > 0
          ? values.timePeriod[1].format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      imageDirPrefix,
      imageFilePath,
      imageAlt: values.imageAlt || values.name,
    };

    if (vipBulletin) {
      dispatch(fetchUpdateVipBulletin({ ...payload, vipBulletinId: vipBulletin?.id }));
    } else {
      dispatch(fetchCreateVipBulletin(payload));
    }

    onClose();
  };

  useEffect(() => {
    if (vipBulletin) {
      form.setFieldsValue({
        isActive: vipBulletin.isActive ? "active" : "inactive",
        isDefault: vipBulletin.isDefault,
        timePeriod: vipBulletin.startAt ? [moment(vipBulletin.startAt), moment(vipBulletin.endAt)] : undefined,
        title: vipBulletin.title,
        imageUrl: vipBulletin.media?.url || "",
        imageAlt: vipBulletin.media?.alt || "",
      });
    }
  }, [vipBulletin, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper
        colon={false}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        form={form}
        initialValues={{ isActive: "inactive" }}
        onFinish={onSubmit}
      >
        <Form.Item label="狀態" name="isActive" wrapperCol={{ span: 9 }} rules={[{ required: true, message: "必填" }]}>
          <Select defaultValue="inactive" onChange={() => {}}>
            <Select.Option value="inactive">下架</Select.Option>
            <Select.Option value="active">上架</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="是否預設" name="isDefault" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <Form.Item
              label="日期"
              name="timePeriod"
              wrapperCol={{ span: 12 }}
              rules={getFieldValue("isDefault") ? [] : [{ required: true, message: "必填" }]}
            >
              <CustomRangePicker
                locale={locale}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                }}
                disabled={getFieldValue("isDefault")}
              />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <Form.Item
              label="推廣文字"
              name="title"
              wrapperCol={{ span: 16 }}
              rules={[
                { required: true, message: "必填" },
                {
                  validator: async () => {
                    if (getFieldValue("title").length > 18) {
                      throw new Error("最多輸入18個字");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="請輸入推廣文案最多18個字" />
            </Form.Item>
          )}
        </Form.Item>
        <Scope>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item label="推廣圖" name="imageUrl" rules={[{ required: true, message: "必填" }]}>
                <UploadImageButton
                  imageUrl={getFieldValue("imageUrl") || ""}
                  limitSize={2}
                  ratio={[2, 1]}
                  finishUpload={(url) => updateForm("imageUrl", url)}
                />
              </Form.Item>
            )}
          </Form.Item>
          <InputAbsolute placeholder="請輸入alt" onChange={(e) => updateForm("imageAlt", e.target.value)} />
          <LimitNotice>長:寬=2:1, 大小上限為2MB</LimitNotice>
        </Scope>
        <CustomRow justify="end">
          <CustomButton onClick={onClose}>取消</CustomButton>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
