import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { Deduction } from "@api/utils/normalizeContract";
import { fetchDeductionList, fetchDeleteDeduction } from "@redux/contractSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Modal, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditDeductionModal from "./EditDeductionModal";

const { Option } = Select;
const { confirm } = Modal;

const Wrapper = styled.div`
  padding: 0 15px;
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  margin-top: 13px;
`;

const Ellipsis = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const contractSlice = (state: RootState) => state.contract;

export default function ContractDeduction() {
  const dispatch = useDispatch();
  const { errorMessage, deductionList, deductionCount, contractDetail, isDeductEditDone } = useSelector(contractSlice);

  const [editModal, setEditModal] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [modalDeduction, setModalDeduction] = useState<Deduction | undefined>();

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchDeductionList({
        limit: pageSize,
        offset: pageSize * (value - 1),
        contractId: contractDetail!.id,
      }),
    );
  };

  const fetchDeductionDelete = (contractId: number) => {
    dispatch(fetchDeleteDeduction(contractId));
  };

  const showDeleteDeduction = (contractId: number) => {
    confirm({
      title: "確定要刪除?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        fetchDeductionDelete(contractId);
      },
      onCancel() {},
    });
  };

  const openDataEditModal = (deduction: Deduction) => {
    setEditModal(true);
    setModalDeduction(deduction);
  };

  const closeEditModal = () => {
    setEditModal(false);
    setModalDeduction(undefined);
  };

  const columns = [
    {
      key: "displayName",
      dataIndex: "displayName",
      title: "項目名稱",
      width: 103,
    },
    {
      key: "startAt",
      dataIndex: "startAt",
      title: "開始時間",
      width: 141,
    },
    {
      key: "endAt",
      dataIndex: "endAt",
      title: "結束時間",
      width: 141,
    },
    {
      key: "deductRate",
      dataIndex: "deductRate",
      title: "後扣比例",
      width: 89,
    },
    {
      key: "description",
      dataIndex: "description",
      title: "說明",
      ellipsis: true,
      render: (description: string, deduction: Deduction) => (
        <Row justify="space-between">
          <Ellipsis>{description}</Ellipsis>
          <div>
            <Button type="link" onClick={() => openDataEditModal(deduction)}>
              編輯
            </Button>
            <Button type="link" disabled={contractDetail?.isActive} onClick={() => showDeleteDeduction(deduction.id)}>
              刪除
            </Button>
          </div>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (contractDetail) {
      dispatch(
        fetchDeductionList({
          limit: pageSize,
          offset: 0,
          contractId: contractDetail.id,
        }),
      );
    }
  }, [dispatch, contractDetail, pageSize]);

  useEffect(() => {
    if (isDeductEditDone) {
      window.scrollTo(0, 0);
      setPage(1);
      dispatch(
        fetchDeductionList({
          limit: pageSize,
          offset: 0,
          contractId: contractDetail!.id,
        }),
      );
    }
  }, [dispatch, contractDetail, pageSize, isDeductEditDone]);

  useEffect(() => {
    if (errorMessage) {
      Modal.error({
        title: "錯誤",
        content: errorMessage,
      });
    }
  }, [errorMessage]);

  return (
    <Wrapper>
      <Row justify="space-between">
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={() => setEditModal(true)}>
          新增後扣項目
        </Button>
        <Row>
          <PageText>{`總共${deductionCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageSize(parseInt(value as string, 10))}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={deductionList}
          pagination={{
            pageSize,
            current: page,
            showSizeChanger: false,
            total: deductionCount,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editModal && <EditDeductionModal deduction={modalDeduction} close={closeEditModal} />}
    </Wrapper>
  );
}
