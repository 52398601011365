import { Select } from "antd";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  > Button {
    margin-right: 10px;
  }
`;
export const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  Button {
    margin-right: 10px;
  }
`;
export const ChargeAmountLink = styled.div<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.colorNeutral500 : theme.colorSuccess500)};
  cursor: ${(disabled) => (disabled ? "pointer" : "not-allowed")};
`;

export const ModalWrapper = styled.div<{ width: number }>`
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: ${({ width }) => `${width}px`};
  background-color: white;
  border-radius: 2px;
`;
export const FlexBox = styled.div`
  display: flex;
  justify-content: flex-end;
  Button {
    margin-right: 10px;
  }
`;

export const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

export const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

export const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
export const UploadFile = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  align-items: center;
  max-width: 364px;
`;
export const UploadFileName = styled.div`
  width: 95%;
  text-overflow: ellipsis;
  white-space: nowrap; /* 防止文本换行 */
  overflow: hidden;
`;
