import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { ScrapInventoryList, ScrapInventoryListParams } from "@api/financeApi";
import moment from "moment";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  scrapInventoryList: ScrapInventoryList;
  scrapInventoryListParams: ScrapInventoryListParams;
  queryMonth: string;
}

export const initialState: IState = {
  isFetching: false,
  scrapInventoryList: {
    count: 0,
    results: {
      totalAmt: 0,
      detail: [],
    },
  },
  queryMonth: moment().format("M"),
  scrapInventoryListParams: {
    dateAfter: moment().startOf("month").format("YYYY-MM-DD"),
    dateBefore: moment().endOf("month").format("YYYY-MM-DD"),
    brandId: undefined,
    limit: 20,
    offset: 0,
  },
};

export const fetchScrapInventoryList = createAsyncThunk(
  "scrapInventory/fetchScrapInventoryList",
  async (_, thunkApi) => {
    const {
      scrapInventorySlice: { scrapInventoryListParams },
    } = thunkApi.getState() as RootState;
    const response = await financeApi.fetchScrapInventoryList(scrapInventoryListParams);
    return response;
  },
);

const scrapInventorySlice = createSlice({
  name: "scrapInventory",
  initialState,
  reducers: {
    resetScrapInventorySlice: () => initialState,
    setScrapInventoryFilter: (state, action) => {
      state.scrapInventoryListParams = action.payload;
    },
    setQueryMonth: (state, action) => {
      state.queryMonth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScrapInventoryList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchScrapInventoryList.fulfilled, (state, action) => {
      state.scrapInventoryList = action.payload;
      state.isFetching = false;
    });
  },
});

export const scrapInventoryState = (state: RootState) => state.scrapInventorySlice;
export const { resetScrapInventorySlice, setScrapInventoryFilter, setQueryMonth } = scrapInventorySlice.actions;
export default scrapInventorySlice.reducer;
