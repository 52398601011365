import { RootState } from "@redux/rootReducer";
import AllStaffList from "@component/AllStaffList";
import { clearVendorSupplementParams, updateVendorSupplementParams, fetchSupplementList } from "@redux/vendorSlice";
import { Button, Col, DatePicker, Row, Select, Space, Input } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import { vendorApproveStatusMap } from "@constant/vendor";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { SupplementListParams } from "@page/VendorSupplementPage/interface";

const { RangePicker } = DatePicker;

const Wrapper = styled.form`
  padding: 0 24px;
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

interface LocalFilter {
  id: number | undefined;
  approvalStatus: number | undefined;
  salesPlanId: number | undefined;
  sku: string | undefined;
  staff: number | undefined;
  supplementDate: [Moment | null, Moment | null];
  createdDate: [Moment | null, Moment | null];
  salesChannel: number | undefined;
}

const VendorFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { supplementListParams } = useSelector((state: RootState) => state.vendorSlice);
  const salesChannelOptions = useSalesChannelOptions();

  const [filters, setFilters] = useState<LocalFilter>({
    id: undefined,
    approvalStatus: undefined,
    salesPlanId: undefined,
    sku: undefined,
    staff: undefined,
    supplementDate: [null, null],
    createdDate: [null, null],
    salesChannel: undefined,
  });

  useEffect(() => {
    const {
      id,
      approvalStatus,
      salesPlanId,
      sku,
      supplementDateAfter,
      supplementDateBefore,
      createdDateAfter,
      createdDateBefore,
      staff,
      salesChannel,
    } = supplementListParams;
    const formatedSupplementDateAfter = supplementDateAfter ? moment(supplementDateAfter) : null;
    const formatedSupplementDateBefore = supplementDateBefore ? moment(supplementDateBefore) : null;
    const formatedCreatedDateAfter = createdDateAfter ? moment(createdDateAfter) : null;
    const formatedCreatedDateBefore = createdDateBefore ? moment(createdDateAfter) : null;

    setFilters({
      id,
      approvalStatus,
      salesPlanId,
      sku,
      staff,
      salesChannel,
      supplementDate: [formatedSupplementDateAfter, formatedSupplementDateBefore],
      createdDate: [formatedCreatedDateAfter, formatedCreatedDateBefore],
    });
  }, [supplementListParams]);

  function onSupplementDataChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        supplementDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        supplementDate: [null, null],
      }));
    }
  }

  function onCreatedDateChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        createdDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        createdDate: [null, null],
      }));
    }
  }

  function handleFilter(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { supplementDate, createdDate, id, approvalStatus, salesPlanId, sku, staff, salesChannel } = filters;
    const filterParams: SupplementListParams = {
      limit: 20,
      offset: 0,
      id,
      approvalStatus,
      salesPlanId,
      sku,
      staff,
      salesChannel,
      supplementDateAfter: supplementDate[0] ? moment(supplementDate[0]).format("YYYY-MM-DD") : undefined,
      supplementDateBefore: supplementDate[1] ? moment(supplementDate[1]).format("YYYY-MM-DD") : undefined,
      createdDateAfter: createdDate[0] ? moment(createdDate[0]).format("YYYY-MM-DD") : undefined,
      createdDateBefore: createdDate[1] ? moment(createdDate[1]).format("YYYY-MM-DD") : undefined,
    };
    dispatch(updateVendorSupplementParams(filterParams));
    dispatch(fetchSupplementList());
  }

  function clear() {
    dispatch(clearVendorSupplementParams());
    dispatch(fetchSupplementList());
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = e.target;
    setFilters((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleSelect = (key: string, value: number) => {
    setFilters((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <Wrapper onSubmit={handleFilter}>
      <Row>
        <Col span={5}>
          <Row>
            <Space size={20}>
              <Title>編號</Title>
              <Input value={filters.id} name="id" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>審核狀態</Title>
              <Select
                style={{ width: 200 }}
                placeholder="請選擇"
                onChange={(value: number) => handleSelect("approvalStatus", value)}
                value={filters.approvalStatus}
              >
                {Object.keys(vendorApproveStatusMap).map((el) => (
                  <Select.Option value={el}>{vendorApproveStatusMap[Number(el)]}</Select.Option>
                ))}
              </Select>
            </Space>
          </Row>
        </Col>
        <Col span={7}>
          <Row>
            <Space size={20}>
              <Title>銷售頁方案 ID</Title>
              <Input value={filters.salesPlanId} name="salesPlanId" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>SKU ID</Title>
              <Input value={filters.sku} name="sku" onChange={handleOnChange} />
            </Space>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "14px" }}>
        <Col span={9}>
          <Row>
            <Space size={20}>
              <Title>實際補單時間</Title>
              <RangePicker
                style={{ width: 300 }}
                locale={locale}
                value={filters.supplementDate}
                onChange={onSupplementDataChange}
              />
            </Space>
          </Row>
        </Col>
        <Col span={9}>
          <Row>
            <Space size={20}>
              <Title>申請時間</Title>
              <RangePicker
                style={{ width: 300 }}
                locale={locale}
                value={filters.createdDate}
                onChange={onCreatedDateChange}
              />
            </Space>
          </Row>
        </Col>
        <Col span={6}>
          <Row>
            <Space size={20}>
              <Title>申請人</Title>
              <AllStaffList value={filters.staff} onChange={(value: number) => handleSelect("staff", value)} />
            </Space>
          </Row>
        </Col>
        <Row style={{ marginTop: "14px" }}>
          <Col span={30}>
            <Row>
              <Space size={20}>
                <Title>通路</Title>
                <Select
                  value={filters.salesChannel}
                  style={{ width: 150 }}
                  placeholder="請選擇"
                  options={salesChannelOptions}
                  onChange={(value: number) => handleSelect("salesChannel", value)}
                />
              </Space>
            </Row>
          </Col>
        </Row>
      </Row>

      <Row justify="end" style={{ marginTop: 17 }}>
        <Space>
          <Button type="default" size="middle" onClick={clear}>
            清除篩選條件
          </Button>
          <Button type="primary" size="middle" htmlType="submit">
            套用
          </Button>
        </Space>
      </Row>
    </Wrapper>
  );
};

export default VendorFilter;
