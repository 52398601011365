import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, message, Modal, Row, Select, Skeleton, Space, Tabs } from "antd";
import { onlineOrderState, fetchOrderDetail, fetchProductList, fetchSubmitOrder } from "@redux/onlineOrderSlice";
import onlineOrderApi, { Product } from "@api/throughShipment/onlineOrderApi";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { hideLoading, showLoading } from "@redux/notifySlice";
import ImportFileModal from "@component/ImportFileModal";
import PageTitle from "@component/PageTitle";
import { SelectValue } from "antd/lib/select";
import DashBoard from "@component/Common/DashBoard";
import OrderTab from "./OrderTab";
import EditProductPopup from "./EditProductPopup";
import ProductList from "./ProductList";
import EditPopup from "../EditPopup";
import translateErrors from "../utils/translateErrors";

const { TabPane } = Tabs;
const { Option } = Select;

const StyledWrapper = styled.section`
  margin: 16px 25px;
  padding: 8px 11px;
`;
const ModalContent = styled.section`
  height: 150px;
  overflow: auto;
`;
const NoticeText = styled.p`
  margin-bottom: 15px;
`;

export default function Shipment() {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { onlineOrderDetail, orderProductList } = useSelector(onlineOrderState);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState("order");
  const [showProductFormModal, setShowProductFormModal] = useState<boolean>(false);

  const [showImportModal, setShowImportModal] = useState(false);
  const [page, setPage] = useState({
    limit: 20,
    current: 1,
  });
  const [currentProduct, setCurrentProduct] = useState<Product>();
  const cannotEdit = !!onlineOrderDetail.submittedAt || !!onlineOrderDetail.destroyedAt;
  const isB2B = onlineOrderDetail.platform?.startsWith("B2B");

  function switchTab(activeKey: string) {
    setActiveTab(activeKey);
  }

  function changePageSize(value: SelectValue) {
    setPage((prevState) => ({
      ...prevState,
      limit: Number(value),
    }));
  }
  function changePage(selectedPage: any) {
    window.scrollTo(0, 0);
    setPage((prevState) => ({
      ...prevState,
      current: selectedPage,
    }));
  }

  useEffect(() => {
    dispatch(fetchOrderDetail(orderId));
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(fetchProductList(orderId));
  }, [dispatch, orderId]);

  const handleSubmit = () => {
    dispatch(fetchSubmitOrder(orderId));
  };
  const handelAddXLS = async (file: File) => {
    try {
      dispatch(showLoading());
      const response = await onlineOrderApi.fetchUploadProducts({ file, orderId });
      dispatch(fetchProductList(orderId));
      message.success("匯入成功");
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      if (!(translatedErrors.length > 0)) return null;
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {translatedErrors.map((errorItem: any) => {
              return <NoticeText>{errorItem}</NoticeText>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
      });

      return null;
    } finally {
      dispatch(hideLoading());
    }
  };

  return (
    <StyledWrapper style={{ position: "relative", border: "1px solid #F0F0F0" }}>
      <PageTitle title={`線上出貨 - ${onlineOrderDetail.orderNumber}`} />
      {onlineOrderDetail ? (
        <>
          <Tabs onChange={switchTab} type="card">
            <TabPane tab="編輯出貨單" key="order">
              <OrderTab onlineOrderDetail={onlineOrderDetail} />
            </TabPane>
            <TabPane tab="出貨商品" key="product">
              <Row gutter={[8, 8]}>
                <Col>
                  <Button
                    type="primary"
                    icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
                    onClick={() => {
                      setCurrentProduct(undefined);
                      setShowProductFormModal(true);
                    }}
                    disabled={cannotEdit}
                  >
                    新增商品
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => setShowImportModal(true)} disabled={cannotEdit}>
                    匯入商品
                  </Button>
                </Col>
                <Col>
                  <DashBoard
                    dataset={[
                      {
                        name: "出貨總數",
                        value: orderProductList.results.reduce((acc, cur) => acc + cur.estimatedQty, 0),
                      },
                    ]}
                  />
                </Col>
                {/* 分頁 */}
                <Col flex="auto">
                  <Row justify="end">
                    <Space>
                      <span>{`總共${orderProductList.results.length}筆，每頁顯示`}</span>
                      <Select defaultValue="20" style={{ width: "72px", fontSize: "13px" }} onChange={changePageSize}>
                        <Option value="20">20</Option>
                        <Option value="50">50</Option>
                        <Option value="100">100</Option>
                      </Select>
                      <span>筆</span>
                    </Space>
                  </Row>
                </Col>
              </Row>
              {/* 還貨商品列表 */}
              <ProductList
                orderId={orderId}
                list={orderProductList.results}
                openProductForm={(product) => {
                  setCurrentProduct(product);
                  setShowProductFormModal(true);
                }}
                pagination={{
                  ...page,
                }}
                cannotEdit={cannotEdit}
                onChange={changePage}
              />
            </TabPane>
          </Tabs>
          {showImportModal && (
            <ImportFileModal
              csvErrorMessage=""
              closePopup={() => setShowImportModal(false)}
              clearErrorMessage={() => {}}
              handleUploadFile={handelAddXLS}
              templatePath="admin/template/xls/online_order_products_template.xlsx"
              fileType=".xlsx"
              noticeText="註：匯入上限為 1000 筆"
            />
          )}
        </>
      ) : (
        <Skeleton />
      )}
      <div style={{ position: "absolute", top: 9, right: 20 }}>
        {activeTab === "order" ? (
          <Space>
            <Button type="primary" onClick={() => setShowPopup(true)} disabled={cannotEdit}>
              編輯
            </Button>
          </Space>
        ) : (
          <Button type="default" onClick={handleSubmit} disabled={cannotEdit || orderProductList.results.length === 0}>
            提交
          </Button>
        )}
      </div>
      {showProductFormModal && (
        <EditProductPopup
          onClose={() => setShowProductFormModal(false)}
          currentProduct={currentProduct}
          warehouseCode={onlineOrderDetail.warehouseCode}
          isB2B={isB2B}
        />
      )}
      {showPopup && <EditPopup onlineOrderDetail={onlineOrderDetail} onClose={() => setShowPopup(false)} />}
    </StyledWrapper>
  );
}
