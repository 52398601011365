import PageTitle from "@component/PageTitle";
import {
  fetchCategoriesTag,
  fetchSalesCategories,
  fetchTopicCategories,
  fetchTopicInfo,
  setIsSearching,
  topicState,
} from "@redux/topicSlice";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSearchParam } from "react-use";
import styled from "styled-components";
import CategoryTree from "./CategoryTree";
import ManageTag from "./ManageTag";
import RecommendSetting from "./RecommendSetting";
import SetupCategory from "./SetupCategory";
import SetupTopic from "./SetupTopic";

const Wrapper = styled.div<{ isManageTag: boolean }>`
  padding-top: 13px;
  padding-left: 22px;
  margin: 12px 25px 12px 0;
  border: ${({ isManageTag }) => !isManageTag && "1px solid #f0f0f0"};
  width: 100%;
  margin-bottom: ${({ isManageTag }) => !isManageTag && "162px"};
`;

const Row = styled.div`
  display: flex;
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  &&& .ant-tabs-tab {
    padding: 8px 25px;
  }
`;

const { TabPane } = Tabs;

const TopicEdit = () => (
  <CustomTabs type="card">
    <TabPane tab="設置主題館" key="topic">
      <SetupTopic />
    </TabPane>
  </CustomTabs>
);

type Props = {
  currentTab: string;
  categoryId: string;
  updateCurrentTab: (key: string) => void;
};

const CategoryEdit = (props: Props) => {
  const { categoryId, currentTab, updateCurrentTab } = props;
  return (
    <CustomTabs type="card" onChange={(key) => updateCurrentTab(key)}>
      <TabPane tab="編輯分類" key="category">
        <SetupCategory />
      </TabPane>
      {categoryId !== "new" && (
        <TabPane tab="管理內容" key="tag">
          <ManageTag />
        </TabPane>
      )}
      {categoryId !== "new" && (
        <TabPane tab="推薦設定" key="recommend">
          <RecommendSetting currentTab={currentTab} />
        </TabPane>
      )}
    </CustomTabs>
  );
};

export default function EditPage() {
  const dispatch = useDispatch();
  const { topicInfo, categoryInfo } = useSelector(topicState);

  const { topicId } = useParams();
  const categoryId = useSearchParam("category");

  const [currentTab, setCurrentTab] = useState<string>("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (topicId && categoryId !== "new") {
      dispatch(fetchTopicInfo(parseInt(topicId, 10)));
    }
  }, [topicId, dispatch, categoryId]);

  useEffect(() => {
    if (topicInfo) {
      dispatch(fetchTopicCategories({ rootCategoryId: topicInfo.rootCategory, displayInActive: true }));
    }
  }, [dispatch, topicInfo]);

  useEffect(() => {
    dispatch(fetchSalesCategories());
  }, [dispatch]);

  useEffect(() => {
    if (categoryInfo && categoryInfo.categories.length > 0) {
      dispatch(setIsSearching(false));
      dispatch(fetchCategoriesTag({ categoryIds: categoryInfo.categories, config: { limit: 10000 } }));
    }
  }, [categoryInfo, dispatch]);

  return (
    <Row>
      <PageTitle title={`主題館 - ${topicInfo?.title || "新增"}`} />
      <CategoryTree />
      <Wrapper isManageTag={currentTab === "tag" || currentTab === "recommend"}>
        {categoryId ? (
          <CategoryEdit
            categoryId={categoryId}
            currentTab={currentTab}
            updateCurrentTab={(key) => setCurrentTab(key)}
          />
        ) : (
          <TopicEdit />
        )}
      </Wrapper>
    </Row>
  );
}
