/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { VipFinanceChartItem, VipFinanceChart } from "@api/dashboardApi";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import { getPointStart, getPointInterval, getTimeFormat, getTooltipTitle, getFilledTimeData } from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

const MemberVIPFiananceChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<VipFinanceChartItem>(
      startDate,
      endDate,
      (chartResult as VipFinanceChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<VipFinanceChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as VipFinanceChart).compareData || [],
      timeUnit,
    );

    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    const currentRevenue: (number | null)[] = [];
    const currentCost: (number | null)[] = [];
    const currentGrossMargin: (number | null)[] = [];
    const compareRevenue: (number | null)[] = [];
    const compareCost: (number | null)[] = [];
    const compareGrossMargin: (number | null)[] = [];

    currentFilledTimeData.forEach((data) => {
      const { revenue = null, cost = null, grossMargin = null } = data;
      currentRevenue.push(revenue);
      currentCost.push(cost);
      currentGrossMargin.push(grossMargin);
    });
    compareFilledTimeData.forEach((data) => {
      const { revenue = null, cost = null, grossMargin = null } = data;
      compareRevenue.push(revenue);
      compareCost.push(cost);
      compareGrossMargin.push(grossMargin);
    });

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: "黑卡會員貢獻營收",
        yAxis: 0,
        data: currentRevenue,
        type: "line",
        color: "#1890FF",
        stack: "current",
        pointStart,
      },
      {
        yAxis: 0,
        name: "黑卡會員銷售成本",
        data: currentCost,
        type: "line",
        color: "#5C6AC4",
        stack: "current",
        pointStart,
      },
      {
        yAxis: 1,
        id: "current-grossMargin",
        name: "黑卡會員銷售毛利率",
        data: currentGrossMargin,
        type: "line",
        color: "#2E90B7",
        stack: "current",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.push({
        yAxis: 0,
        name: "黑卡會員貢獻營收 - 相較於",
        data: compareRevenue,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
      series.push({
        yAxis: 0,
        name: "黑卡會員銷售成本 - 相較於",
        data: compareCost,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
      series.push({
        yAxis: 1,
        id: "compare-grossMargin",
        name: "黑卡會員銷售毛利率 - 相較於",
        data: compareGrossMargin,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: [
        {
          title: {
            text: "",
          },
        },
        {
          title: {
            text: "",
          },
          labels: {
            formatter() {
              const formatValue = Math.round((this.value as number) * 100);
              return `${formatValue}%`;
            },
          },
          min: 0,
          max: 1,
          opposite: true,
        },
      ],
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointTitle = this.point.series.name;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const value = this.point.y || 0;
          const displayText = this.point.series.options.id?.includes("grossMargin")
            ? `${Math.round(value * 1000) / 10}%`
            : USCurrencyFormmater.format(value);

          return `${title}<br/>${pointTitle} ${displayText}<br/>`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });
  }, [chartResult, chartParams]);

  const fetchVipFinanceChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.VIP_FINANCE));
  }, [dispatch]);

  return (
    <Wrapper>
      <ChartWrapper>
        <Spin spinning={isFetchingChart}>
          <PageTitle title="Dashboard - 財務表現" />
          <ChartFilter title="財務表現" fetchAction={fetchVipFinanceChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
        </Spin>
      </ChartWrapper>
    </Wrapper>
  );
};

export default MemberVIPFiananceChart;
