import { fetchReplenishReturnInfo, orderState } from "@redux/orderSlice";
import { Spin, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import ProcessingType from "../ProcessingType";
import ReplenishReturnContent from "./ReplenishReturnContent";
import ReplenishReturnProduct from "./ReplenishReturnProduct";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;

const Scope = styled.div`
  padding: 13px 16px 78px 22px;
  border: solid 1px #f0f0f0;
`;

export default function OrderInformation() {
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const { isFetching, replenishReturnInfo } = useSelector(orderState);

  const [activeKey, setActiveKey] = useState<string>("content");

  const proccessingTypeString = replenishReturnInfo?.processingType === ProcessingType.RETURN ? "退貨" : "補貨";

  useEffect(() => {
    dispatch(fetchReplenishReturnInfo(parseInt(orderId, 10)));
  }, [dispatch, orderId]);

  return (
    <Spin spinning={isFetching}>
      <PageTitle title={`${proccessingTypeString} - ${replenishReturnInfo?.orderInfo.orderNumber}`} />
      <Wrapper>
        <Scope>
          <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
            <Tabs.TabPane tab={`${proccessingTypeString}內容`} key="content">
              <ReplenishReturnContent />
            </Tabs.TabPane>
            <Tabs.TabPane tab="商品內容" key="product">
              <ReplenishReturnProduct />
            </Tabs.TabPane>
          </Tabs>
        </Scope>
      </Wrapper>
    </Spin>
  );
}
