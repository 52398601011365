import React, { useState } from "react";
import styled from "styled-components";
import { Button, Form, Row, DatePicker, Select, Col, Input } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { resetReturnListParam, setReturnListParam } from "@redux/finance/returnListPageSlice";

enum StatusValue {
  Created = 0,
  CONFORMED = 1,
}

const statusValueText: { [key: number]: string } = {
  0: "已開立",
  1: "已確認",
};

const CustomDatePicker = styled(DatePicker)`
  width: 200px;
`;
const InputWidth = styled(Input)`
  width: 200px;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const approvalStatusOptions = [
  { label: "請選擇", value: -1 },
  {
    label: statusValueText[StatusValue.Created],
    value: StatusValue.Created,
  },
  {
    label: statusValueText[StatusValue.CONFORMED],
    value: StatusValue.CONFORMED,
  },
];

export default function Filter() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const listParams = useSelector((state: RootState) => state.returnListPageSlice.returnListParam);
  const [changed, setChanged] = useState(false);

  const handleSubmit = () => {
    if (!changed) return;
    const formValues = form.getFieldsValue();

    const params = {
      ...listParams,
      approvalStatus: formValues.approvalStatus === -1 ? undefined : formValues.approvalStatus,
      createdDateAfter:
        formValues.createdMonth && moment(formValues.createdMonth).startOf("month").format("YYYY-MM-DD"),
      createdDateBefore: formValues.createdMonth && moment(formValues.createdMonth).endOf("month").format("YYYY-MM-DD"),
      checkedDateAfter:
        formValues.checkedMonth && moment(formValues.checkedMonth).startOf("month").format("YYYY-MM-DD"),
      checkedDateBefore: formValues.checkedMonth && moment(formValues.checkedMonth).endOf("month").format("YYYY-MM-DD"),
      orderNumber: formValues.orderNumber,
    };
    dispatch(setReturnListParam(params));
  };

  const handelReset = () => {
    form.resetFields();
    setChanged(false);
    dispatch(resetReturnListParam());
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      form={form}
      initialValues={{ approvalStatus: -1 }}
      onFinish={handleSubmit}
      onValuesChange={() => {
        setChanged(true);
      }}
    >
      <Row gutter={[16, 0]}>
        <Col span={7}>
          <Form.Item label="開立折讓時間" name="createdMonth">
            <CustomDatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="確認狀態" name="approvalStatus">
            <Select style={{ width: 200 }} options={approvalStatusOptions} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="訂單編號" name="orderNumber">
            <InputWidth />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="確認折讓時間" name="checkedMonth">
            <CustomDatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <CustomButton onClick={handelReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
}
