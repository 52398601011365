import contractApi, {
  AddContractConfig,
  AddDeductionConfig,
  ContractListConfig,
  DeductionListConfig,
  UpdateContractConfig,
  UpdateDeductionConfig,
} from "@api/contractApi";
import { Contract, Deduction } from "@api/utils/normalizeContract";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface IState {
  isFetching: boolean;
  isEditDone: boolean;
  isDeductEditDone: boolean;
  contractList: Contract[];
  deductionList: Deduction[];
  contractCount: number;
  deductionCount: number;
  contractDetail?: Contract;
  redirectContractId: number;
  errorMessage: string;
}

const initialState: IState = {
  isFetching: false,
  isEditDone: false,
  isDeductEditDone: false,
  contractList: [],
  deductionList: [],
  contractCount: 0,
  deductionCount: 0,
  contractDetail: undefined,
  redirectContractId: 0,
  errorMessage: "",
};

export const fetchContractList = createAsyncThunk("contract/fetchContractList", async (payLoad: ContractListConfig) => {
  const { data, count } = await contractApi.fetchContractList(payLoad);
  return { data, count };
});

export const fetchDeductionList = createAsyncThunk(
  "contract/fetchDeductionList",
  async (payLoad: DeductionListConfig) => {
    const { data, count } = await contractApi.fetchDeductionList(payLoad);
    return { data, count };
  },
);

export const fetchAddContract = createAsyncThunk(
  "contract/fetchAddContract",
  async (payLoad: AddContractConfig, thunkApi) => {
    try {
      const contractId = await contractApi.fetchAddContract(payLoad);
      return contractId;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateContract = createAsyncThunk(
  "contract/fetchUpdateContract",
  async (payLoad: UpdateContractConfig) => {
    await contractApi.fetchUpdateContract(payLoad);
    return "Success";
  },
);

export const fetchAddDeduction = createAsyncThunk("contract/fetchAddDeduction", async (payLoad: AddDeductionConfig) => {
  await contractApi.fetchAddDeduction(payLoad);
  return "Success";
});

export const fetchUpdateDeduction = createAsyncThunk(
  "contract/fetchUpdateDeduction",
  async (payLoad: UpdateDeductionConfig) => {
    await contractApi.fetchUpdateDeduction(payLoad);
    return "Success";
  },
);

export const fetchContractDetail = createAsyncThunk("contract/fetchContractDetail", async (contractId: number) => {
  const response = await contractApi.fetchContractDetail(contractId);
  return response;
});

export const fetchDeleteDeduction = createAsyncThunk("contract/fetchDeleteDeduction", async (contractId: number) => {
  await contractApi.fetchDeleteDeduction(contractId);
  return "Success";
});

const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    resetEditStatus: (state) => {
      state.isEditDone = false;
      state.isDeductEditDone = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchContractList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchContractList.fulfilled, (state, action) => {
      const { data, count } = action.payload;
      state.isFetching = false;
      state.contractList = data;
      state.contractCount = count;
    });
    builder.addCase(fetchContractList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchDeductionList.pending, (state) => {
      state.isFetching = true;
      state.isDeductEditDone = false;
    });
    builder.addCase(fetchDeductionList.fulfilled, (state, action) => {
      const { data, count } = action.payload;
      state.isFetching = false;
      state.deductionList = data;
      state.deductionCount = count;
    });
    builder.addCase(fetchDeductionList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchAddContract.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAddContract.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditDone = true;
      state.redirectContractId = action.payload;
    });
    builder.addCase(fetchAddContract.rejected, (state, action) => {
      state.isFetching = false;
      state.errorMessage = action.payload as string;
    });
    builder.addCase(fetchUpdateContract.pending, (state) => {
      state.isFetching = true;
      state.isEditDone = false;
    });
    builder.addCase(fetchUpdateContract.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditDone = true;
    });
    builder.addCase(fetchUpdateContract.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchAddDeduction.pending, (state) => {
      state.isFetching = true;
      state.isEditDone = false;
    });
    builder.addCase(fetchAddDeduction.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isDeductEditDone = true;
    });
    builder.addCase(fetchAddDeduction.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateDeduction.pending, (state) => {
      state.isFetching = true;
      state.isEditDone = false;
    });
    builder.addCase(fetchUpdateDeduction.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isDeductEditDone = true;
    });
    builder.addCase(fetchUpdateDeduction.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchContractDetail.pending, (state) => {
      state.isEditDone = false;
      state.isFetching = true;
    });
    builder.addCase(fetchContractDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.contractDetail = action.payload;
    });
    builder.addCase(fetchContractDetail.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchDeleteDeduction.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchDeleteDeduction.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isDeductEditDone = true;
    });
    builder.addCase(fetchDeleteDeduction.rejected, (state, action) => {
      state.isFetching = false;
      state.errorMessage = action.payload as string;
    });
  },
});

export const { resetEditStatus } = contractSlice.actions;
export default contractSlice.reducer;
