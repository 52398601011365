import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { RootState } from "@redux/rootReducer";
import { Button, Modal, Pagination, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { FC, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import {
  batchDeleteDiscountFrame,
  fetchDiscountFrameList,
  reset,
  updateDiscountFrameListParam,
  updateSelectedFrameIds,
} from "@redux/discountFrameSlice";
import { DiscountFrameListItem } from "@api/discountFrameApi";
import PhotoModal from "@component/UploadImageButton/PhotoModal";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 0px 24px;
`;
const Header = styled.header`
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const DeleteButton = styled(Button)``;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const FrameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FrameDemoStyle = styled.img`
  width: 22px;
  height: 22px;
`;
const EditText = styled.a`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 75px;
`;

const pageSizeOptions = [20, 50, 100];

interface TableFrameItem extends DiscountFrameListItem {
  key: number;
}

const DiscountFrameList: FC = () => {
  const dispatch = useAppDispatch();
  const [localFrameList, setLocalFrameList] = useState<TableFrameItem[]>([]);
  const [photoSource, setPhotoSource] = useState<string>("");

  const isFetchingList = useSelector((state: RootState) => state.discountFrameSlice.isFetching);
  const frameListResult = useSelector((state: RootState) => state.discountFrameSlice.discountFrameListResult);
  const frameListParams = useSelector((state: RootState) => state.discountFrameSlice.discountFrameListParam);
  const selectedFrameIds = useSelector((state: RootState) => state.discountFrameSlice.selectedFrameIds);

  const { count, results: frameList } = frameListResult;
  const { offset, limit } = frameListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchDiscountFrameList());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    const tableFrameList = frameList.map((frame) => ({
      ...frame,
      key: frame.id,
    }));
    setLocalFrameList(tableFrameList);
  }, [frameList]);

  const handleOnAdd = () => {
    window.open("/discount-frame/add", "_blank");
  };

  const handleOnPhotoClick = (url: string) => {
    setPhotoSource(url);
  };

  const closePhotoModal = () => {
    setPhotoSource("");
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedFrameIds(values as number[]));
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(batchDeleteDiscountFrame()),
    });
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...frameListParams,
      limit: value as number,
    };

    dispatch(updateDiscountFrameListParam(filter));
    dispatch(fetchDiscountFrameList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...frameListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateDiscountFrameListParam(filter));
    dispatch(fetchDiscountFrameList());
  };

  const tableColumns: ColumnsType<TableFrameItem> = [
    {
      title: <TableNoWrap>宣傳名稱</TableNoWrap>,
      key: "title",
      dataIndex: "title",
    },
    {
      title: <TableNoWrap>檢視宣傳框</TableNoWrap>,
      key: "imagePath",
      dataIndex: "imagePath",
      width: 100,
      render: (value: DiscountFrameListItem["imagePath"]) => {
        return (
          <FrameWrapper>
            <FrameDemoStyle
              src={value}
              alt=""
              onClick={() => {
                handleOnPhotoClick(value);
              }}
            />
          </FrameWrapper>
        );
      },
    },
    {
      title: <TableNoWrap>宣傳開始時間</TableNoWrap>,
      key: "startAt",
      dataIndex: "startAt",
      width: 200,
    },
    {
      title: <TableNoWrap>宣傳結束時間</TableNoWrap>,
      key: "endAt",
      dataIndex: "endAt",
      width: 200,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      width: 170,
      fixed: "right",
      render: (value: any, data: DiscountFrameListItem) => {
        return (
          <EditTextContainer>
            <EditText href={`/discount-frame/edit/${data.id}`} target="_blank">
              編輯
            </EditText>
          </EditTextContainer>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="宣傳框管理" />
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
            新增宣傳框
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <Filter />
      <TableContainer>
        <Table
          dataSource={localFrameList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedFrameIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
        />
      </TableContainer>
      {photoSource && <PhotoModal source={photoSource} onClose={closePhotoModal} />}
      <Footer>
        <DeleteButton onClick={handleOnBatchDelete} disabled={selectedFrameIds.length === 0}>
          刪除
        </DeleteButton>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default DiscountFrameList;
