import React from "react";
import { Button, Col, DatePicker, Form, Row, Select } from "antd";
import { FilterWrapper, FlexBox } from "src/styles/common";
import { OrderPaymentStatus } from "@api/finance/OrderPaymentApi";

export const orderPaymentStatusOptions = [
  { label: "待請款", value: OrderPaymentStatus.PENDING },
  { label: "請款失敗", value: OrderPaymentStatus.FAILED },
];
type Props = { show: boolean; updateParams: (values: { [key: string]: any }) => void };

const OrderPaymentFilter = (props: Props) => {
  const { show, updateParams } = props;
  const [form] = Form.useForm();

  const handleOnReset = () => {
    form.resetFields();
    updateParams({
      createdDateAfter: undefined,
      createdDateBefore: undefined,
      captureDateAfter: undefined,
      captureDateBefore: undefined,
      paymentStatus: undefined,
    });
  };
  const handleOnSubmit = () => {
    const values = form.getFieldsValue();
    const { createdAt, captureAt, ...restValues } = values;
    updateParams({
      ...restValues,
      createdDateAfter: createdAt?.[0]?.format("YYYY-MM-DD"),
      createdDateBefore: createdAt?.[1]?.format("YYYY-MM-DD"),
      captureDateAfter: captureAt?.[0]?.format("YYYY-MM-DD"),
      captureDateBefore: captureAt?.[1]?.format("YYYY-MM-DD"),
    });
  };
  return (
    <FilterWrapper show={show}>
      <Form colon={false} labelAlign="left" form={form} initialValues={{}}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="訂單建立時間" name="createdAt">
              <DatePicker.RangePicker allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="請款時間" name="captureAt">
              <DatePicker.RangePicker allowClear />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="請款狀態" name="paymentStatus">
              <Select
                allowClear
                placeholder="請選擇"
                options={orderPaymentStatusOptions.filter(
                  (option) => option.value === OrderPaymentStatus.PENDING || option.value === OrderPaymentStatus.FAILED,
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <FlexBox>
        <Button onClick={handleOnReset}>清除篩選條件</Button>
        <Button type="primary" onClick={handleOnSubmit}>
          套用
        </Button>
      </FlexBox>
    </FilterWrapper>
  );
};

export default OrderPaymentFilter;
