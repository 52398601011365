import { fetchOrderInfo, orderState } from "@redux/orderSlice";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import CVSRedirect from "./CVSRedirect";
import MainOrder from "./MainOrder";
import SubOrder from "./SubOrder";
import ReplenishReturnTab from "./ReplenishReturnTab";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;

const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

export default function OrderInformation() {
  const { state } = useLocation();
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const { orderInfo } = useSelector(orderState);

  const [activeKey, setActiveKey] = useState<string>("mainOrder");

  useEffect(() => {
    dispatch(fetchOrderInfo(parseInt(orderId, 10)));
  }, [dispatch, orderId]);

  useEffect(() => {
    if (state) {
      setActiveKey("subOrder");
    }
  }, [state]);

  return (
    <Wrapper>
      <PageTitle title={`訂單內容 - ${orderInfo?.orderInfo.orderNumber}`} />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="主訂單內容" key="mainOrder">
            <MainOrder />
          </Tabs.TabPane>
          <Tabs.TabPane tab="子訂單內容" key="subOrder">
            {orderInfo?.orderShipments.map((order) => (
              <SubOrder order={order} key={order.id} />
            ))}
          </Tabs.TabPane>
          {orderInfo?.orderInfo.isReplenishReturn && orderInfo?.orderInfo ? (
            <Tabs.TabPane tab="補退貨單" key="ReplenishReturnOrder">
              <ReplenishReturnTab orderNumber={orderInfo.orderInfo.orderNumber} />
            </Tabs.TabPane>
          ) : null}
        </Tabs>
        {/* 711 */}
        <CVSRedirect />
      </Scope>
    </Wrapper>
  );
}
