import { CurrencyValue } from "@constant/CurrencyValue";

export enum ProfitsStatus {
  PENDING = 1,
  APPROVE = 2,
  REJECT = 3,
}
export enum ReceivedStatus {
  NOT_CREDITED,
  BILLED,
}
export interface VendorListResult {
  count: number;
  next: string;
  previous: string;
  results: VendorListItem[];
}

export interface VendorListParams {
  limit: number;
  offset: number;
  startDateBefore?: string;
  startDateAfter?: string;
  endDateAfter?: string;
  endDateBefore?: string;
  vendorName?: string;
  ownerId?: number;
  profitStatus?: ProfitsStatus;
  received?: ReceivedStatus;
}
export interface Context {
  showFilter: boolean;
  currentSelectRows: any[];
  excuteFilter: (params: VendorListParams) => void;
  clearFilters: () => void;
  vendors: VendorListItem[];
  updatePagination: (params: { pageSize: number; page: number; total: number }) => void;
  pagination: {
    pageSize: number;
    page: number;
    total: number;
  };
  filters?: VendorListParams;
  udpateOwner: (vendorIds: number[], ownerId: number) => void;
  modifyVendorCooperation: (vendorId: number, isActive: boolean) => void;
}

export type VendorListType = VendorListItem[];

export type VendorListItem = {
  id: number;
  name: string;
  phone: string;
  majorContact: {
    id: number;
    isMajor: boolean;
    name: string;
    phone: string;
    title: string;
    email: string;
    vendor: number;
  } | null;
  productQty: number;
  currency: CurrencyValue;
  owner: {
    id: number;
    name: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    isActive: boolean;
    createdAt: string;
    staffGroup: string;
  };
  isActive: boolean;
  createdAt: string;
  endAt: string;
  depositBalance: number;
};
