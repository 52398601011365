import PageTitle from "@component/PageTitle";
import { Tabs } from "antd";
import React from "react";
import { TabWrapper } from "src/styles/common";
import ContactInfo from "./ContactInfoTab";
import InfoTab from "./InfoTab";
import PaymentTerms from "./PaymentTermsTab";

const ManufacturerInfo = (props: { mode: string }) => {
  const { mode } = props;

  return (
    <TabWrapper>
      <PageTitle title="製造商-資訊" />
      <Tabs type="card" defaultActiveKey="info">
        <Tabs.TabPane tab="製造商資訊" key="info">
          <InfoTab mode={mode} />
        </Tabs.TabPane>
        {mode === "edit" && (
          <>
            <Tabs.TabPane tab="聯絡人資訊" key="contactInfo">
              <ContactInfo />
            </Tabs.TabPane>
            <Tabs.TabPane tab="付款條件" key="paymentTerms">
              <PaymentTerms />
            </Tabs.TabPane>
          </>
        )}
      </Tabs>
    </TabWrapper>
  );
};

export default ManufacturerInfo;
