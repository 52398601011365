import { fetchContractDetail } from "@redux/contractSlice";
import { RootState } from "@redux/rootReducer";
import { Spin, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import ContractContent from "./ContractContent";
import ContractDeduction from "./ContractDeduction";

const { TabPane } = Tabs;

const Wrapper = styled.div`
  padding: 12px 24px 12px 16px;
`;

const ContentWrapper = styled.div`
  padding: 13px 0 13px 21px;
  border: solid 1px ${({ theme }) => theme.colorNeutral300};
`;

const CustomTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  &&& .ant-tabs-tab {
    padding: 8px 25px;
  }
`;

const contractSlice = (state: RootState) => state.contract;

export default function EditContract() {
  const { contractId } = useParams();

  const dispatch = useDispatch();
  const { isFetching, contractDetail } = useSelector(contractSlice);

  useEffect(() => {
    if (contractId) {
      dispatch(fetchContractDetail(parseInt(contractId, 10)));
    }
  }, [dispatch, contractId]);

  return (
    <Wrapper>
      <PageTitle title={`合約 - ${contractDetail?.displayName || "新增"}`} />
      <ContentWrapper>
        <Spin spinning={isFetching}>
          <CustomTabs type="card">
            <TabPane tab="合約內容" key="content">
              <ContractContent />
            </TabPane>
            {contractId && (
              <TabPane tab="後扣項目" key="deduction">
                <ContractDeduction />
              </TabPane>
            )}
          </CustomTabs>
        </Spin>
      </ContentWrapper>
    </Wrapper>
  );
}
