import vipStatisticsApi, {
  VipBrandNewMemberExportParams,
  VipStatisticsListParams,
  VipStatisticsListResult,
} from "@api/vipStatisticsApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  isWaitingDownload: boolean;
  vipStatisticsListResult: VipStatisticsListResult;
  vipStatisticsListFilter: VipStatisticsListParams;
}

const initialState: IState = {
  isFetching: false,
  isWaitingDownload: false,
  vipStatisticsListResult: {
    vipMember: 0,
    canceledVipMember: 0,
    data: [],
  },
  vipStatisticsListFilter: {
    startDate: undefined,
    endDate: undefined,
  },
};

export const fetchVipStatisticsList = createAsyncThunk(
  "vipStatistics/fetchVipStatisticsList",
  async (params: VipStatisticsListParams) => {
    const response = await vipStatisticsApi.fetchVipStatisticsList(params);
    return response;
  },
);

export const fetchVipInValidMemberExport = createAsyncThunk("vipStatistics/fetchVipInValidMemberExport", async () => {
  await vipStatisticsApi.fetchVipInValidMemberExport();
  return "Success";
});

export const fetchVipStatisticsListExport = createAsyncThunk(
  "vipStatistics/fetchVipStatisticsListExport",
  async (params: VipStatisticsListParams) => {
    await vipStatisticsApi.fetchVipStatisticsListExport(params);
    return "Success";
  },
);

export const fetchVipBrandNewMemberExport = createAsyncThunk(
  "vipStatistics/fetchVipBrandNewMemberExport",
  async (params: VipBrandNewMemberExportParams) => {
    await vipStatisticsApi.fetchVipBrandNewMemberExport(params);
    return "Success";
  },
);

const vipStatisticsSlice = createSlice({
  name: "vipStatistics",
  initialState,
  reducers: {
    resetVipStatisticsSlice: () => initialState,
    setVipStatisticsListFilter: (state, action) => {
      state.vipStatisticsListFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVipStatisticsList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipStatisticsList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.vipStatisticsListResult = action.payload;
    });
    builder.addCase(fetchVipInValidMemberExport.pending, (state) => {
      state.isWaitingDownload = true;
    });
    builder.addCase(fetchVipInValidMemberExport.fulfilled, (state) => {
      state.isWaitingDownload = false;
    });
    builder.addCase(fetchVipStatisticsListExport.pending, (state) => {
      state.isWaitingDownload = true;
    });
    builder.addCase(fetchVipStatisticsListExport.fulfilled, (state) => {
      state.isWaitingDownload = false;
    });
    builder.addCase(fetchVipBrandNewMemberExport.pending, (state) => {
      state.isWaitingDownload = true;
    });
    builder.addCase(fetchVipBrandNewMemberExport.fulfilled, (state) => {
      state.isWaitingDownload = false;
    });
  },
});

export const { setVipStatisticsListFilter, resetVipStatisticsSlice } = vipStatisticsSlice.actions;
export const vipStatisticsState = (state: RootState) => state.vipStatisticsSlice;
export default vipStatisticsSlice.reducer;
