import { OrderStatus } from "@constant/OrderStatus";
import { ReturnOrderSortMethod } from "@constant/ReturnOrderSortMethod";
import { ReviewState } from "@constant/ReviewState";
import { ShipmentState } from "@constant/ShipmentState";

export interface ListResult {
  count: number;
  next: null;
  previous: null;
}

export interface ReturnOrderListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ReturnOrderDetail[];
}

export interface ReturnOrderItem {
  id: number;
  orderNumber: string;
  shipmentState: ShipmentState;
  reasonId: number;
  reasonLabel: string;
  createOrderDate: string;
  estimatedShippingDate: string;
  actualShippingDate: string;
  orderedQty: number;
  totalCost: number;
  distributionId: number;
  distributionLabel: string;
  ordererId: number;
  ordererName: string;
  totalCount: number;
  ceoApproval: number;
  managerApproval: number;
  sectionManagerApproval: number;
  financeApproval: number;
  reviewState: ReviewState;
  recipient: string;
  recipientTel: string;
  shipperNumber: string;
  packageNumber: string;
  address: string;
  zipCode: string;
  batchNumber: string;
  description: string;
  isSubmitted: boolean;
  destroyed: boolean;
  onlyRecord: boolean;
  warehouseId: string;
  chargingMethod: number;
  estimatedArrivedDate: string | null;
}

export interface ReturnErrorResult {
  sku: string;
  errReason: number;
  msg: string;
}

export interface ReviewStateByPosition {
  ceo: ReviewState;
  manager: ReviewState;
  finance: ReviewState;
  sectionManager: ReviewState;
}

export interface Filters {
  orderNumber?: string;
  sku?: string;
  startEstimateShippingDate?: string;
  endEstimateShippingDate?: string;
  startActualShippingDate?: string;
  endActualShippingDate?: string;
  marketingChannelId?: number;
  reasonId?: number;
  ordererId?: number;
  sortMethod?: ReturnOrderSortMethod;
  sortOrder?: "asc" | "desc";
  page?: number;
  pageSize?: number;
}

export interface OrderParams {
  returnOrderId: string;
  estimatedShippingDate?: string;
  reasonId?: number;
  receiver?: string;
  phone?: string;
  city?: string;
  region?: string;
  address?: string;
  batchNumber?: string;
  comment?: string;
  zipCode?: string;
  submitted?: boolean;
  distributionId?: number;
  onlyRecord?: boolean;
  warehouseId?: string;
  chargingMethod?: number;
  estimatedArrivedDate?: string;
}

export interface ReturnProductResult {
  count: number;
  next: null;
  previous: null;
  results: ReturnProduct[];
}

export interface ReturnProduct {
  id: number;
  sku: string;
  productName: string;
  cost: number;
  qty: number;
  actQty: number;
  orderId?: number;
  totalCount: number;
  canSalesQty: number;
  canReturnBadQty: number;
  effectiveDate?: string;
  errReason?: number[];
  sourceStorageName?: string;
  batch?: string;
  stock?: number;
}

export interface Distributor {
  id: number;
  name: string;
}

export interface ReturnOrderDetail {
  id: number;
  orderNumber: string;
  purchaseId: null | number;
  purchaseNumber: null | number;
  desc: string;
  status: OrderStatus;
  shipperNumber: string;
  sourceWarehouseName: string;
  sourceWarehouseCode: string;
  sourceWarehouseType: number;
  condition: string;
  destWarehouseName: string;
  destWarehouseCode: string;
  carrierId: number;
  carrierRelationCode: string;
  carrierRelationName: string;
  receiverName: string;
  receiverPhone: string;
  receiverAddress: string;
  receiverZipcode: null;
  approvalStatus: number; // 審核狀態
  sectionManagerApproval: number;
  managerApproval: number;
  financeApproval: number;
  ceoApproval: number;
  estDeployedDate: string;
  estArrivedDate: string;
  shippingAt: string;
  arrivedAt: string;
  canSubmit: true;
  canEdit: true;
  canCancel: true;
  destroyedAt: null;
  destroyedBy: null;
  staffName: null;
  createdAt: string;
}

export enum ReturnProductErrorStatus {
  DUPLICATE = 1,
  QTY_EXCEED = 2,
  OTHER = 3,
}

export interface ReturnProductErrorResult {
  sku: string;
  errReason: number;
  msg: string;
}

export enum ReturnOrderProcessType {
  APPLY = 1,
  APPROVE = 2,
  REJECT = 3,
}
// 商品
export interface ProductListResult extends ListResult {
  results: Product[];
}

export interface Product {
  id: number;
  sku: string;
  barcode: string;
  cost: number;
  productName: string;
  standardPrice: number;
  effectiveDate: string;
  storageType: string;
  batch: string;
  unitPrice: number;
  estimatedQty: number;
  actualQty: number;
  stock: number;
}

export interface CreateProduct {
  returnOrderId: string;
  sourceStorageType: string;
  sku: string;
  qty: number;
  effectiveDate: string | null;
  batch: string;
}

export interface UpdateProduct extends CreateProduct {
  productId: number;
}
export interface ProductBarcodeInfo {
  count: number;
  next: null;
  previous: null;
  results: [
    {
      sku: string;
      warehouseCode: string;
      productName: string;
      barcode: string;
      weightedCost: number;
    },
  ];
}
export interface ProductStockInfoList {
  stockInfo: ProductStockInfo[];
}

export interface ProductStockInfo {
  batch: string | null;
  canSalesQty: number;
  effectiveDate: string | null;
  storageType: string;
  warehouseCode: string;
}
export interface WarehouseList {
  address: string;
  code: string;
  contact: string;
  description: string;
  destroyedAt: null;
  id: number;
  isActive: boolean;
  name: string;
  phone: string;
  type: number;
  zipcode: string;
}

export interface CarrierList {
  id: number;
  carrierId: number;
  carrierType: number;
  name: string;
  warehouseId: number;
  warehouseCode: string;
  code: string;
}
export interface CopyWithProductsParams {
  returnOrderId: string;
  estDeployedDate: string;
  estArrivedDate?: string;
  desc: string;
}
