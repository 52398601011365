import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  min-height: 250px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;

const GrossMarginCard: FC = () => {
  const isFetchingGrossMarginDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingGrossMarginDashboard,
  );
  const grossMarginDashboard = useSelector((state: RootState) => state.dashboard.grossMarginDashboard);
  const { currentData, diff } = grossMarginDashboard;
  const { grossMargin = 0 } = currentData || {};
  const { grossMarginDiff = 0 } = diff || {};

  const grossMarginPer = Math.round(grossMargin * 1000) / 10; // 百分比取小數點第一位
  const grossMarginTagColor = grossMarginDiff < 0 ? "error" : "success";
  const grossMarginTagIcon = grossMarginDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const grossMarginChangePercentage = Math.round(grossMarginDiff * 1000) / 10; // 百分比取小數點第一位

  return (
    <Spin spinning={isFetchingGrossMarginDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>全站毛利率</HeaderTitle>
          <AnchorToChart href="/dashboard/gross-margin" />
        </Header>
        <SectionContent>
          {`${grossMarginPer}%`}
          <StyledTag color={grossMarginTagColor} icon={grossMarginTagIcon}>
            {`${grossMarginChangePercentage}%`}
          </StyledTag>
        </SectionContent>
      </Wrapper>
    </Spin>
  );
};

export default GrossMarginCard;
