import { Vendor } from "src/types";

/* eslint-disable camelcase */
export default function normalizeVendorDetails(apiRes: {
  id: number;
  name: string;
  phone: string;
  ubn: null;
  bank_account: null | {
    id: number;
    account_name: string;
    account_number: string;
    bank_name: string;
    branch_name: null | string;
    swift_code: null | string;
  };
  currency: number;
  payment_condition: null | string;
  register_address: null | string;
  business_address: string;
  owner: number;
  is_active: boolean;
  created_at: null | string; // "2020-12-23T11:11:16.627014";
  end_at: null | string;
}): Vendor.Details {
  const {
    id,
    owner,
    name,
    phone,
    ubn,
    bank_account,
    currency,
    payment_condition,
    register_address,
    business_address,
  } = apiRes;

  const bankAccount: any = bank_account || {};
  return {
    vendorId: id,
    bdOwnerId: owner,
    vendorName: name,
    vendorPhone: phone,
    vendorUbn: ubn || "",
    accountName: bankAccount.account_name || "",
    accountNumber: bankAccount.account_number || "",
    bankName: bankAccount.bank_name || "",
    branchName: bankAccount.branch_name || "",
    swiftCode: bankAccount.swift_code || "",
    currencyId: currency,
    payCondition: payment_condition || "",
    reisterAddr: register_address || "",
    buinessAddr: business_address || "",
  };
}
