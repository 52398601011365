/* eslint-disable camelcase */
import downloadCSV from "@utils/downloadCSV";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export enum ActionType {
  DISCOUNT = 1,
  EXTEND_DAY = 2,
}

export enum RedeemMethod {
  SINGLE = 1,
  RANDOM = 2,
}

export interface FetchVipRuleListParams {
  titleQ?: string;
  id?: number;
  isActive?: boolean;
  redeemCode?: string;
  startAt?: string;
  endAt?: string;
  limit?: number;
  offset?: number;
}

export interface FetchCreateVipRulePayload {
  title: string;
  redeemMethod: RedeemMethod;
  actionType: ActionType;
  discount: number;
  startAt: string;
  endAt: string;
  codePrefix?: string;
  codeLength?: number;
  maxUseTimes?: number;
  redeemCode?: string;
  isActive: boolean;
  perCodeLimit?: number;
  numOfCodes?: number;
  redeemType?: RedeemType;
}

export interface FetchUpdateVipRulePayload {
  title: string;
  startAt?: string;
  endAt?: string;
  isActive: boolean;
  perCodeLimit?: number;
  numOfCodes?: number;
  redeemType?: RedeemType;
}

export interface FetchDeleteVipRulePayload {
  ids: number[];
}

export interface FetchDownloadRedeemCodePayload {
  membershipRule: number;
}

export interface VipRule {
  id: number;
  title: string;
  isActive: boolean;
  actionType: ActionType;
  discount: number;
  useCount: number;
  redeemCode: string;
  redeemMethod: RedeemMethod;
  useStartAt: string | null;
  useEndAt: string | null;
  codePrefix: string | null;
  codeLength: number | null;
  perCodeLimit: number | null;
  numOfCodes: number | null;
  redeemType: RedeemType;
}

export enum RedeemType {
  BUY_VIP = 1, // 綁定購買VIP
  PROMOTION = 2, // 行銷條件可單獨使用
}

interface vipRuleAPI {
  fetchVipRuleList: (
    params: FetchVipRuleListParams,
  ) => Promise<{
    count: number;
    next: string | null;
    previous: string | null;
    results: VipRule[];
  }>;
  fetchVipRule: (vipRuleId: number) => Promise<VipRule>;
  fetchCreateVipRule: (payload: FetchCreateVipRulePayload) => Promise<VipRule>;
  fetchUpdateVipRule: (payload: { payload: FetchUpdateVipRulePayload; vipRuleId: number }) => Promise<void>;
  fetchDeleteVipRule: (payload: FetchDeleteVipRulePayload) => Promise<void>;
  fetchDownloadRedeemCode: (params: FetchDownloadRedeemCodePayload) => Promise<void>;
}

const apiClient = new CoreAPI();

const vipRuleApi: vipRuleAPI = {
  fetchVipRuleList: async (params) => {
    const getParams = transformCamelToSnake(params);

    const response = await apiClient.getData("/manage/account/membership/promotion-rules/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipRule: async (vipRuleId: number) => {
    const response = await apiClient.getData(`/manage/account/membership/promotion-rules/${vipRuleId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateVipRule: async (payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData("/manage/account/membership/promotion-rules/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateVipRule: async (payload) => {
    const { payload: data, vipRuleId } = payload;
    const getParams = transformCamelToSnake(data);
    await apiClient.putData(`/manage/account/membership/promotion-rules/${vipRuleId}/`, getParams);
  },
  fetchDeleteVipRule: async (payload) => {
    await apiClient.putData("/manage/account/membership/promotion-rules/batch-delete/", payload);
  },
  fetchDownloadRedeemCode: async (params) => {
    const response = await apiClient.getData("/manage/account/redeem_codes/csv-download/", {
      membership_rule: params.membershipRule,
    });
    downloadCSV(response);
  },
};

export default vipRuleApi;
