import React, { useEffect, useState, ReactText, useCallback } from "react";
import { Button, Select, Input, Table, Pagination, message, Modal, Form } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined, FormOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useForm } from "antd/lib/form/Form";
import { ColumnsType } from "antd/lib/table";
import PageTitle from "@component/PageTitle";
import {
  fetchSupplyChainList,
  updateSelectedIds,
  fetchOperationStaff,
  updateOperationStaff,
  deleteSupplyChain,
  updateSupplyChainFilter,
  fetchSearchSupplyChainList,
} from "@redux/supplyChainSlice";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { SupplyChainDetail } from "@api/supplyChainApi";
import { SelectValue } from "antd/lib/select";
import StaffEdit from "./StaffEdit";

const { Option } = Select;

const Wrapper = styled.div`
  margin: 20px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const SearchButton = styled(Button)`
  margin: 0 5px;
`;

const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;

const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;
const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;

const SupplChainList = () => {
  const dispatch = useAppDispatch();
  const [form] = useForm();
  const [inputValue, setInputValue] = useState<string>("");
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const { selectedSupplyChainIds } = supplyChainSlice;
  const supplyChainList = supplyChainSlice.supplyChainList.results;
  const { supplyChainFilter } = supplyChainSlice;
  const { limit, offset } = supplyChainFilter;
  const { count } = supplyChainSlice.supplyChainList;

  const [showStaffEditor, setShowStaffEditor] = useState(false);
  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedIds(values as number[]));
  };

  const currentPage = offset && limit && Math.floor(offset / limit) + 1;

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...supplyChainFilter,
      limit: value as number,
    };
    dispatch(updateSupplyChainFilter(filter));
    dispatch(fetchSupplyChainList());
  };
  const handleOnPageChange = (page: number) => {
    if (limit) {
      const filter = {
        ...supplyChainFilter,
        offset: (page - 1) * limit,
      };
      dispatch(updateSupplyChainFilter(filter));
    }

    dispatch(fetchSupplyChainList());
  };

  const toggleStaff = useCallback(
    (state: boolean) => {
      if (!selectedSupplyChainIds.length && !showStaffEditor) {
        message.info("請先選擇廠商");
      } else {
        setShowStaffEditor(state);
      }
    },
    [selectedSupplyChainIds, showStaffEditor],
  );
  const tableColumns: ColumnsType<any> = [
    {
      title: "供應商名稱",
      dataIndex: "name",
    },
    {
      title: "供應商地址",
      dataIndex: "address",
    },
    {
      title: "供應商電話",
      dataIndex: "phone",
    },
    {
      title: "供應商主要聯絡人",
      dataIndex: ["contact", "name"],
    },
    {
      title: "供應商電子郵件",
      dataIndex: ["contact", "email"],
    },
    {
      title: (
        <TableNoWrap>
          運營負責人
          <FormOutlined onClick={() => toggleStaff(true)} />
        </TableNoWrap>
      ),
      dataIndex: "staff",
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: SupplyChainDetail) => {
        return <Edit onClick={() => toggleEditPopup(data)}>編輯</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (value: any, data: SupplyChainDetail) => {
        return <Edit onClick={() => handleDelete(data.id)}>刪除</Edit>;
      },
    },
  ];
  const handleOnOpen = () => {
    window.open("/operation-of-brand/supply-chain/add", "_blank");
  };

  const toggleEditPopup = (data: SupplyChainDetail) => {
    window.open(`/operation-of-brand/supply-chain/edit/${data.id}`, "_blank");
  };
  const handleDelete = (id: number) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(deleteSupplyChain(id));
      },
    });
  };

  const handleSearch = () => {
    dispatch(fetchSearchSupplyChainList(inputValue));
  };
  const handleClearSearch = () => {
    dispatch(fetchSupplyChainList());
    form.setFieldsValue({ input: " " });
  };

  useEffect(() => {
    dispatch(fetchSupplyChainList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOperationStaff());
  }, [dispatch]);

  const handleOwner = useCallback(
    (ownerId: number) => {
      dispatch(updateOperationStaff(ownerId));
      toggleStaff(false);
    },
    [dispatch, toggleStaff],
  );

  return (
    <Wrapper>
      <PageTitle title="供應商管理" />
      <Header>
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={handleOnOpen}>
          新增供應商
        </Button>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect defaultValue={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            <Option value={20}>20</Option>
            <Option value={50}>50</Option>
            <Option value={100}>100</Option>
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <Form form={form} colon={false} labelAlign="left">
        <Form.Item label="供應商名稱" name="nameQ" style={{ width: "600px" }}>
          <Input style={{ width: "50%" }} onChange={(e) => setInputValue(e.target.value)} />
          <SearchButton type="primary" disabled={inputValue.length === 0} onClick={handleSearch}>
            搜尋
          </SearchButton>
          <SearchButton onClick={handleClearSearch}>清除搜尋</SearchButton>
        </Form.Item>
      </Form>
      <TableContainer>
        <Table
          scroll={{ x: "max-content" }}
          dataSource={supplyChainList}
          columns={tableColumns}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSupplyChainIds,
          }}
          rowKey="id"
          pagination={false}
        />

        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
      <StaffEdit visible={showStaffEditor} handleOwner={handleOwner} toggleManager={toggleStaff} />
    </Wrapper>
  );
};

export default SupplChainList;
