import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, DatePicker, Form, Input, Modal, Select, Table, message, Typography, InputNumber, Tooltip } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { ColumnsType } from "antd/lib/table";
import { MinusCircleTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import {
  fetchAddProduct,
  fetchCheckProduct,
  fetchProductDetail,
  resetProductDetail,
  resetProductInfo,
} from "@redux/brandOperation/PcmSlice";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "@hooks/useDebounce";
import { useParams } from "react-router-dom";
import { RootState } from "@redux/rootReducer";
import moment from "moment";

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const CustomButton = styled(Button)`
  width: 80px;
  border-radius: 2px;
`;

const PaymentWrapper = styled(Form)`
  .ant-table-tbody > tr > td {
    max-width: 40px;
    padding: 10px;
  }
  .ant-empty-normal {
    display: none;
  }
`;
const ProductToolTipCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  cursor: not-allowed;
  opacity: 1;
  z-index: 1;
`;

type Props = {
  visible: boolean;
  onClose: () => void;
  productId?: number;
};

const AddItemPopup = (props: Props) => {
  const dispatch = useDispatch();
  const { visible, onClose, productId } = props;
  const [form] = Form.useForm();
  const [paymentForm] = Form.useForm();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [count, setCount] = useState(dataSource.length);
  const { id: pcmId } = useParams();
  const { productInfo, productDetail, pcmDetail } = useSelector((state: RootState) => state.pcmSlice);

  const defaultColumns: ColumnsType<any> = [
    {
      title: "",
      dataIndex: "",
      width: "30px",
      render: (_, value) => {
        return (
          <CustomButton onClick={() => handleDelete(value.id)} icon={<MinusCircleTwoTone />}>
            刪除
          </CustomButton>
        );
      },
    },
    {
      title: "狀態",
      dataIndex: "status",
      width: "30px",
      render: (_, value) => {
        return (
          <Form.Item noStyle shouldUpdate>
            <Form.Item noStyle name={`status-${value.id}`}>
              <Select placeholder="請選擇" style={{ width: "90px" }}>
                <Select.Option value={1} key={1}>
                  下單
                </Select.Option>
                <Select.Option value={2} key={2}>
                  到貨
                </Select.Option>
              </Select>
            </Form.Item>
          </Form.Item>
        );
      },
    },
    {
      title: "天期",
      dataIndex: "days",
      width: "40px",
      render: (_, value) => {
        return (
          <Form.Item noStyle shouldUpdate>
            <Form.Item noStyle name={`days-${value.id}`}>
              <Input style={{ width: "60px", marginRight: "10px" }} />
            </Form.Item>
          </Form.Item>
        );
      },
    },
    {
      title: "比例",
      dataIndex: "ratio",
      width: "0px",
      render: (_, value) => {
        return (
          <Form.Item noStyle shouldUpdate>
            <Form.Item noStyle name={`ratio-${value.id}`}>
              <Input style={{ width: "60px", marginRight: "10px" }} />
            </Form.Item>
          </Form.Item>
        );
      },
    },
  ];

  const handleOnSubmit = () => {
    const paymentFormValues = paymentForm.getFieldsValue();
    const formValues = form.getFieldsValue();
    const paymentData = dataSource.map((item) => {
      return {
        status: parseInt(paymentFormValues[`status-${item.id}`], 10),
        days: parseInt(paymentFormValues[`days-${item.id}`], 10),
        ratio: parseInt(paymentFormValues[`ratio-${item.id}`], 10),
      };
    });

    const rateAmount = paymentData.reduce((acc, cur) => {
      return Number(acc) + Number(cur.ratio);
    }, 0);

    const payload = {
      ...formValues,
      name: undefined,
      price: Number(formValues.price),
      orderedPcs: Number(formValues.orderedPcs),
      id: productId || undefined,
      effectiveDate: formValues.effectiveDate ? formValues.effectiveDate.format("YYYY-MM-DD") : undefined,
      cashflows: paymentData,
    };

    if (rateAmount > 0 && rateAmount !== 100) {
      message.error("比例相加必須等於100");
    } else {
      dispatch(fetchAddProduct({ addItem: payload, pcmId }));
    }
  };

  const handleAdd = () => {
    const newData = {
      id: count,
    };
    setDataSource((prevDataSource) => prevDataSource.concat(newData));
    setCount(count + 1);
  };

  const handleDelete = (id: number) => {
    const newData = dataSource.filter((item: { id: number }) => item.id !== id);
    setDataSource(newData);
  };

  const handleChange = useDebounce(() => {
    const formValues = form.getFieldsValue();
    const params = {
      no: formValues.no,
      producer: pcmDetail.producerId,
    };
    dispatch(fetchCheckProduct(params));
  }, 800);
  const formValues = form.getFieldsValue();

  useEffect(() => {
    if (formValues.no) {
      form.setFieldsValue({
        name: productInfo.name,
      });
    }
  }, [form, formValues, productInfo, productInfo.name]);

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductDetail({ pcmId, productId }));
    }
  }, [dispatch, pcmId, productId]);

  useEffect(() => {
    if (productId) {
      // 編輯
      form.setFieldsValue({
        no: productDetail.no,
        effectiveDate: productDetail.effectiveDate && moment(productDetail.effectiveDate),
        price: productDetail.price,
        orderedPcs: productDetail.orderedPcs,
      });
    }
  }, [
    form,
    productDetail.effectiveDate,
    productDetail.name,
    productDetail.no,
    productDetail.orderedPcs,
    productDetail.orderedQty,
    productDetail.price,
    productId,
  ]);

  const formattedCashflows = productDetail.cashflows.map((item, index) => {
    return {
      ...item,
      index,
    };
  });

  useEffect(() => {
    // 編輯
    const params = {
      no: formValues.no,
      producer: pcmDetail.producerId,
    };
    if (formValues.no) {
      dispatch(fetchCheckProduct(params));
    }
  }, [dispatch, formValues.no, pcmDetail.producerId]);

  useEffect(() => {
    if (productId) {
      productDetail.cashflows.forEach((item) => {
        paymentForm.setFieldsValue({
          [`status-${item.id}`]: item.status,
          [`days-${item.id}`]: item.days,
          [`ratio-${item.id}`]: item.ratio,
        });
      });
      setDataSource([...formattedCashflows]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productDetail.cashflows, productId]);

  return (
    <Modal width={450} visible={visible} closable={false} footer={false}>
      <Wrapper
        labelAlign="left"
        colon={false}
        onFinish={handleOnSubmit}
        form={form}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 10 }}
      >
        <Form.Item label="條碼" name="no" rules={[{ required: true, message: "" }]}>
          <Input onChange={handleChange} />
        </Form.Item>
        <Form.Item label="商品名稱">
          <div style={{ position: "relative" }}>
            <Tooltip title={productInfo.name} placement="bottom">
              <ProductToolTipCover />
            </Tooltip>
            <Input placeholder="自動帶入" value={productInfo.name} disabled />
          </div>
        </Form.Item>
        <Form.Item label="效期" name="effectiveDate">
          <DatePicker locale={locale} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="單價" name="price" rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="下單pcs數" name="orderedPcs" rules={[{ required: true, message: "" }]}>
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="下單箱數" shouldUpdate={(prev, current) => prev.orderedPcs !== current.orderedPcs}>
          {({ getFieldValue }) => {
            if (productInfo.pcsPerCarton === 0) return null;
            const pcs = getFieldValue("orderedPcs");
            const orderedCarton = Number(pcs) / productInfo.pcsPerCarton;
            return Number.isNaN(orderedCarton) ? null : (
              <Typography.Text type={Number.isInteger(orderedCarton) ? undefined : "danger"}>
                {orderedCarton}
              </Typography.Text>
            );
          }}
        </Form.Item>
        <div style={{ marginBottom: "10px" }}> 付款方式</div>

        <PaymentWrapper form={paymentForm} labelAlign="left" colon={false}>
          <Table
            rowKey="index"
            scroll={{ x: "max-content" }}
            columns={defaultColumns}
            dataSource={dataSource}
            pagination={false}
          />
        </PaymentWrapper>
        <CustomButton style={{ margin: "10px 0 0 10px" }} onClick={() => handleAdd()} icon={<PlusCircleTwoTone />}>
          新增
        </CustomButton>
        <Footer>
          <Button
            style={{
              margin: "0 14px",
            }}
            onClick={() => {
              dispatch(resetProductInfo());
              dispatch(resetProductDetail());
              onClose();
            }}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default AddItemPopup;
