/* eslint-disable camelcase */
import type { TagListResult } from "@api/tagApi";

interface TagListRes {
  count: number;
  next: string;
  previous: string;
  results: {
    id: number;
    tag_type: string;
    name: string;
    icon: number;
    is_active: boolean;
  }[];
}

interface NormalizeTag {
  list: (res: TagListRes) => TagListResult;
}

const normalizeTag: NormalizeTag = {
  list: (res) => {
    return {
      ...res,
      results: res.results.map((tag) => ({
        id: tag.id,
        tagType: tag.tag_type,
        name: tag.name,
        icon: tag.icon,
        isActive: tag.is_active,
      })),
    };
  },
};

export default normalizeTag;
