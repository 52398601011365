import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import BrandOperationApi, {
  ProductList,
  PruductFilter,
  SupplyChainList,
  ProductSupplyChainList,
  ProductInfo,
  SupplyChainInfo,
  ProductManufacturerResult,
  ProductManufacturerDetail,
} from "@api/brandOperationApi";
import { message } from "antd";
import { hideLoading, showLoading } from "@redux/notifySlice";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  isSupplyChainFetching: boolean;
  productList: ProductList;
  pruductFilter: PruductFilter;
  productSupplyChainList: ProductSupplyChainList;
  productInfo: ProductInfo;
  supplyChainInfo: SupplyChainInfo;
  supplyChainList: SupplyChainList;
  supplyChainParam: { limit: number; offset: number };
  manufacturerResult: ProductManufacturerResult;
}

export const initialState: IState = {
  isFetching: false,
  isSupplyChainFetching: false,
  productList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  pruductFilter: { limit: 20, offset: 0 },
  productSupplyChainList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  productInfo: {
    id: 0,
    no: "",
    sku: "",
    name: "",
    nameEn: "",
    cost: 0,
    type: "一般商品",
    shelfLife: 0,
    pcsPerCarton: 0,
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
    cartonWeight: 0,
    cartonLength: 0,
    cartonWidth: 0,
    cartonHeight: 0,
    totalNetWeight: 0,
    totalGrossWeight: 0,
    totalVolume: 0,
  },
  supplyChainInfo: {
    id: 0,
    supplyChainName: "",
    supplyChainId: 0,
    quotedPrice: undefined,
    currency: undefined,
    isActive: 1,
  },
  supplyChainList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  supplyChainParam: { limit: 10, offset: 0 },
  manufacturerResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
};

export const fetchProductList = createAsyncThunk(
  "brandOperationProduct/fetchProductList",
  async (params: PruductFilter) => {
    const response = await BrandOperationApi.fetchProductList(params);
    return response;
  },
);

export const fetchProductSupplyChainList = createAsyncThunk(
  "brandOperationProduct/fetchProductSupplyChainList",
  async (productId: string) => {
    const response = await BrandOperationApi.fetchProductSupplyChainList(productId);
    return response;
  },
);
export const fetchProductInfo = createAsyncThunk(
  "brandOperationProduct/fetchProductInfo",
  async (pruductId: string) => {
    const response = await BrandOperationApi.fetchProductInfo(pruductId);
    return response;
  },
);
export const fetchAddProduct = createAsyncThunk(
  "brandOperationProduct/fetchAddProduct",
  async (param: ProductInfo, thunkAPI) => {
    try {
      const response = await BrandOperationApi.fetchAddProduct(param);
      message.success("已建立資料");
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const fetchUpdateProduct = createAsyncThunk(
  "brandOperationProduct/fetchUpdateProduct",
  async (param: { data: ProductInfo; productId: number }, thunkAPI) => {
    try {
      const response = await BrandOperationApi.fetchUpdateProduct(param);
      message.success("已儲存");
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const fetchSupplyChainInfo = createAsyncThunk(
  "brandOperationProduct/fetchSupplyChainInfo",
  async (params: { productId: string; supplyChainId: number }) => {
    const response = await BrandOperationApi.fetchProductSupplyChainInfo(params.productId, params.supplyChainId);
    return response;
  },
);
export const fetchUpdateSupplyChainInfo = createAsyncThunk(
  "brandOperationProduct/fetchUpdateSupplyChainInfo",
  async (params: { productId: string; supplyChainInfoId: number; value: any }, thunkAPI) => {
    try {
      const response = await BrandOperationApi.fetchUpdateSupplyChainInfo(
        params.productId,
        params.supplyChainInfoId,
        params.value,
      );
      message.success("更新成功");
      thunkAPI.dispatch(fetchProductSupplyChainList(params.productId));
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const fetchAddSupplyChainInfo = createAsyncThunk(
  "brandOperationProduct/fetchUpdateSupplyChainInfo",
  async (params: { productId: string; value: any }, thunkAPI) => {
    try {
      const response = await BrandOperationApi.fetchAddSupplyChainInfo(params.productId, params.value);
      message.success("新增成功");
      thunkAPI.dispatch(fetchProductSupplyChainList(params.productId));

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const fetchDeleteSupplyChainInfo = createAsyncThunk(
  "brandOperationProduct/fetchDeleteSupplyChainInfo",
  async (params: { productId: string; supplyChainId: string }, thunkAPI) => {
    try {
      await BrandOperationApi.fetchDeleteSupplyChainInfo(params.productId, params.supplyChainId);
      message.success("刪除成功");
      thunkAPI.dispatch(fetchProductSupplyChainList(params.productId));
      return "Success";
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);
export const fetchSupplyChain = createAsyncThunk("brandOperationProduct/getSupplyChain", async (params, thunkAPI) => {
  try {
    const response = await BrandOperationApi.fetchSupplyChain(params);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});
export const loadMoreSupplyChainList = createAsyncThunk(
  "brandOperationProduct/loadMoreVendorList",
  async (_, thunkApi) => {
    const {
      brandOperationProductSlice: { supplyChainParam },
    } = thunkApi.getState() as RootState;

    const params = {
      ...supplyChainParam,
      offset: supplyChainParam.offset + supplyChainParam.limit,
    };
    thunkApi.dispatch(updateSupplyChainParam(params));
    const response = await BrandOperationApi.fetchSupplyChain(params);
    return response;
  },
);

export const fetchProductManufacturerList = createAsyncThunk(
  "brandOperationProduct/fetchProductManufacturerList",
  async (productId: string, thunkAPI) => {
    try {
      const response = await BrandOperationApi.fetchProductManufacturerList(productId);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const addProductManufacturer = createAsyncThunk(
  "brandOperationProduct/addProductManufacturer",
  async (payload: ProductManufacturerDetail & { productId: string }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoading());
      const { productId, ...restPayload } = payload;
      const response = await BrandOperationApi.addProductManufacturer(productId, restPayload);
      message.success("新增成功");
      thunkAPI.dispatch(hideLoading());
      thunkAPI.dispatch(fetchProductManufacturerList(productId));
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    } finally {
      thunkAPI.dispatch(hideLoading());
    }
  },
);
export const updateProductManufacturer = createAsyncThunk(
  "brandOperationProduct/updateProductManufacturer",
  async (payload: ProductManufacturerDetail & { productId: string; id: number }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoading());
      const { productId, id, ...restPayload } = payload;
      const response = await BrandOperationApi.updateProductManufacturer(productId, id, restPayload);
      message.success("修改成功");
      thunkAPI.dispatch(hideLoading());
      thunkAPI.dispatch(fetchProductManufacturerList(productId));
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    } finally {
      thunkAPI.dispatch(hideLoading());
    }
  },
);

export const deleteProductManufacturer = createAsyncThunk(
  "brandOperationProduct/deleteProductManufacturer",
  async (params: { productId: string; id: number }, thunkAPI) => {
    try {
      thunkAPI.dispatch(showLoading());
      const { productId, id } = params;
      await BrandOperationApi.deleteProductManufacturer(productId, id);
      message.success("刪除成功");
      thunkAPI.dispatch(hideLoading());
      thunkAPI.dispatch(fetchProductManufacturerList(productId));
      return "Success";
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    } finally {
      thunkAPI.dispatch(hideLoading());
    }
  },
);
const brandOperationProductSlice = createSlice({
  name: "brandOperationProduct",
  initialState,
  reducers: {
    resetBrandOperationProductSlice: () => initialState,
    setProductFilter: (state, action) => {
      state.pruductFilter = action.payload;
    },
    updateSupplyChainParam: (state, action) => {
      state.supplyChainParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.productList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchProductSupplyChainList.pending, (state, action) => {
      state.isSupplyChainFetching = true;
    });
    builder.addCase(fetchProductSupplyChainList.fulfilled, (state, action) => {
      state.productSupplyChainList = action.payload;
      state.isSupplyChainFetching = false;
    });
    builder.addCase(fetchProductInfo.fulfilled, (state, action) => {
      state.productInfo = action.payload;
    });
    builder.addCase(fetchSupplyChainInfo.fulfilled, (state, action) => {
      state.supplyChainInfo = action.payload;
    });
    builder.addCase(fetchAddProduct.fulfilled, (state, action) => {
      state.productInfo = action.payload;
    });
    builder.addCase(fetchUpdateProduct.fulfilled, (state, action) => {
      state.productInfo = action.payload;
    });
    builder.addCase(fetchSupplyChain.fulfilled, (state, action) => {
      state.supplyChainList = action.payload;
    });
    builder.addCase(loadMoreSupplyChainList.fulfilled, (state, action) => {
      state.supplyChainList = {
        ...action.payload,
        results: state.supplyChainList.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchProductManufacturerList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductManufacturerList.rejected, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductManufacturerList.fulfilled, (state, action) => {
      state.manufacturerResult = action.payload;
      state.isFetching = false;
    });
  },
});

export const brandOperationProductState = (state: RootState) => state.brandOperationProductSlice;
export const {
  resetBrandOperationProductSlice,
  setProductFilter,
  updateSupplyChainParam,
} = brandOperationProductSlice.actions;
export default brandOperationProductSlice.reducer;
