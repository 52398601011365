import cookie from "./Cookie";

class SessionStorage {
  private canUseSessionStorage: boolean;

  constructor() {
    try {
      // 檢查 seesion storage 是否可用
      window.sessionStorage.setItem("test", "test");
      window.sessionStorage.removeItem("test");
      this.canUseSessionStorage = true;
    } catch (error: any) {
      this.canUseSessionStorage = false;
    }
  }

  public setItem = (key: string, value: any): void => {
    const convertValue = JSON.stringify(value);

    if (this.canUseSessionStorage) {
      window.sessionStorage.setItem(key, convertValue);
    } else {
      cookie.setItem(key, convertValue);
    }
  };

  /**
   * 回傳 Json parse 過的結果
   * @param key
   */
  public getItem = (key: string): any => {
    let resultValue = null;
    if (this.canUseSessionStorage) {
      const value = window.sessionStorage.getItem(key);
      if (value) {
        resultValue = JSON.parse(value);
      }
    } else {
      const value = cookie.getItem(key);
      resultValue = JSON.parse(value);
    }
    return resultValue;
  };

  public removeItem = (key: string): void => {
    if (this.canUseSessionStorage) {
      window.sessionStorage.removeItem(key);
    } else {
      cookie.removeItem(key);
    }
  };

  public clear = (): void => {
    if (this.canUseSessionStorage) {
      window.sessionStorage.clear();
    } else {
      cookie.removeAll();
    }
  };
}

const Session = new SessionStorage();

export default Session;
