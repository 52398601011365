import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { ReturnProductItem } from "@api/sharing/ReturnProductManagementApi";
import specialShipmentApi from "@api/sharing/specialShipmentApi";
import PopupBackground from "@component/PopupBackground";
import IntegratedTable from "@component/Table/IntegratedTable";
import useDebounce from "@hooks/useDebounce";
import {
  createReturnProduct,
  deleteReturnProduct,
  fetchReturnProductList,
  returnProductState,
  updateReturnProduct,
} from "@redux/sharing/ReturnProductManagementSlice";
import { Button, Col, Form, Input, InputNumber, Modal, Row, Select, Space } from "antd";
import { ValidateStatus } from "antd/es/form/FormItem";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalWrapper, StyledSection, TableWrapper } from "src/styles/common";

const ReturnProductList = (props: { returnId: number }) => {
  const { returnId } = props;
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [productItem, setProductItem] = useState<ReturnProductItem | undefined>();
  const {
    isFetching,
    returnProductAppDetail: details,
    returnProductList: { results: productListResults },
  } = useSelector(returnProductState);
  const editDisabled = !!details?.submittedAt || !!details?.destroyedAt;

  const handleModalClose = () => {
    setShowModal(false);
    setProductItem(undefined);
  };
  const handleOnSubmitProduct = (payload: ReturnProductItem, productId?: number) => {
    if (productId) {
      dispatch(updateReturnProduct({ returnId, productId, payload }));
    } else {
      dispatch(createReturnProduct({ returnId, payload }));
    }
  };

  const handleDeleteProduct = (productId: number, record: ReturnProductItem) => {
    Modal.confirm({
      title: `確定要刪除此商品 "${record.sku}"`,
      icon: <ExclamationCircleOutlined />,
      okText: "確定",
      cancelText: "取消",
      onOk: () => dispatch(deleteReturnProduct({ returnId, productId })),
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
    },
    {
      title: "商品名稱",
      dataIndex: "productName",
    },
    {
      title: "barcode",
      dataIndex: "barcode",
    },
    {
      title: "商品數量(pcs)",
      dataIndex: "qty",
    },
    {
      title: "退貨原因",
      dataIndex: "reason",
    },

    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number, record: any) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                setProductItem(record);
                setShowModal(record);
              }}
              disabled={editDisabled}
            >
              編輯
            </Button>
            <Button
              type="link"
              onClick={() => {
                handleDeleteProduct(id, record);
              }}
              disabled={editDisabled}
            >
              刪除
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchReturnProductList(returnId));
  }, [dispatch, returnId]);

  return (
    <StyledSection>
      <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => setShowModal(true)}
              disabled={editDisabled}
            >
              新增商品
            </Button>
          </Space>
        </Col>
      </Row>
      <TableWrapper>
        <IntegratedTable
          isPagination={false}
          dataSource={productListResults}
          loading={isFetching}
          columns={columns}
          scroll={{ x: "max-content" }}
          rowKey="id"
        />
      </TableWrapper>
      {showModal && (
        <ProductFormModal
          data={productItem}
          warehouseCode={details?.warehouseCode || ""}
          onClose={handleModalClose}
          onSubmit={handleOnSubmitProduct}
        />
      )}
    </StyledSection>
  );
};

export default ReturnProductList;

type ProductFormModalProps = {
  data?: ReturnProductItem;
  warehouseCode: string;
  onClose: () => void;
  onSubmit: (data: ReturnProductItem, productId?: number) => void;
};
const ProductFormModal = (props: ProductFormModalProps) => {
  const { data, warehouseCode, onClose, onSubmit } = props;
  const [form] = Form.useForm();
  const [skuStatus, setSkuStatus] = useState<ValidateStatus>("");

  const reasonOptions = [
    { label: "瑕疵品", value: "瑕疵品" },
    { label: "效期問題", value: "效期問題" },
    { label: "客人個人因素", value: "客人個人因素" },
    { label: "出貨缺件", value: "出貨缺件" },
    { label: "價格因素", value: "價格因素" },
    { label: "重複訂購", value: "重複訂購" },
    { label: "迴轉過高", value: "迴轉過高" },
    { label: "下架/停售", value: "下架/停售" },
    { label: "其他", value: "-1" },
  ];

  const handleOnSkuSearch = useDebounce(async (sku: string) => {
    if (!sku) return;
    try {
      setSkuStatus("validating");
      const response = await specialShipmentApi.fetchProductWarehouseInfo(sku, warehouseCode);
      const { productName, barcode } = response[0];
      form.setFieldsValue({ productName, barcode });
      setSkuStatus("success");
    } catch (error) {
      form.setFieldsValue({ productName: undefined, barcode: undefined });
      setSkuStatus("error");
    }
  }, 500);

  const handelOnSubmit = (values: any) => {
    const { reasonText, reason, ...payload } = values;
    // 如果reason是其他(-1),則用reasonText代替reason
    onSubmit({ ...payload, reason: reason === "-1" ? reasonText : reason }, data?.id);
    onClose();
  };

  useEffect(() => {
    if (data && !skuStatus) {
      handleOnSkuSearch(data.sku);
    }
  }, [data, handleOnSkuSearch, skuStatus]);

  return (
    <PopupBackground close={() => {}}>
      <ModalWrapper height="410px" width="450px">
        <Form
          form={form}
          labelCol={{ span: 7 }}
          labelAlign="left"
          colon={false}
          initialValues={data}
          onFinish={handelOnSubmit}
        >
          <Form.Item
            label="SKU(品號)"
            name="sku"
            validateStatus={skuStatus}
            help={skuStatus === "error" ? "查無此商品SKU" : ""}
            hasFeedback
            rules={[{ required: true, message: "" }]}
          >
            <Input onChange={(e) => handleOnSkuSearch(e.target.value)} allowClear />
          </Form.Item>
          <Form.Item label="barcode" name="barcode">
            <Input disabled />
          </Form.Item>
          <Form.Item label="商品名稱" name="productName">
            <Input disabled />
          </Form.Item>

          <Form.Item label="商品數量(pcs)" name="qty" rules={[{ required: true, message: "" }]}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item label="退貨原因" name="reason" rules={[{ required: true, message: "" }]}>
            <Select placeholder="請選擇" options={reasonOptions} allowClear />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const reason = getFieldValue("reason");
              return reason === "-1" ? (
                <Form.Item label="其他原因" name="reasonText" rules={[{ required: true, message: "" }]}>
                  <Input placeholder="請輸入詳細原因" />
                </Form.Item>
              ) : null;
            }}
          </Form.Item>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={onClose}>取消</Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
