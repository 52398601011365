import React from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import PageTitle from "@component/PageTitle";
import ShippingFeeSetting from "./ShippingFeeSetting";
import ConfigSetting from "./ConfigSetting";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const TabsContainer = styled.div`
  padding-top: 13px;
  padding-left: 22px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;
const System = () => {
  return (
    <Wrapper>
      <PageTitle title="系統" />
      <TabsContainer>
        <Tabs defaultActiveKey="basic" type="card" destroyInactiveTabPane>
          <Tabs.TabPane tab="環境設定" key="environment">
            <ConfigSetting />
          </Tabs.TabPane>
          <Tabs.TabPane tab="運費及門檻設定" key="deliveryFee">
            <ShippingFeeSetting />
          </Tabs.TabPane>
        </Tabs>
      </TabsContainer>
    </Wrapper>
  );
};

export default System;
