import React, { FC } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PopupBackground from "./PopupBackground";

interface Props {
  onConfirm: () => void;
  onClose: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 364px;
  height: 164px;
  padding: 42px 21px 24px 32px;

  background-color: white;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const WanringIcon = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: #faad14;
  margin-right: 16px;
`;
const Title = styled.p`
  font-size: 16px;
  line-height: 1.5;
  font-weight: bold;
  color: ${({ theme }) => theme.colorNeutral600};
  margin: 0;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const DeletePopup: FC<Props> = (props) => {
  const { onClose, onConfirm } = props;

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Header>
          <WanringIcon />
          <Title>你確定要刪除這筆資料 ?</Title>
        </Header>
        <Footer>
          <CancelButton onClick={onClose}>否</CancelButton>
          <Button onClick={onConfirm} type="primary">
            是
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default DeletePopup;
