import { CloseOutlined, InfoCircleFilled, UploadOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import { RootState } from "@redux/rootReducer";
import { batchCSVUploadSlotSalePages, clearCSVErrorMessage, setShowImportCsvPopup } from "@redux/slotSlice";
import { Button } from "antd";
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 305px;
  background-color: white;
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 22px;
  right: 22px;
`;
const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadIcon = styled(UploadOutlined)`
  font-size: 66px;
  color: ${({ theme }) => theme.colorNeutral500};
  margin-bottom: 35px;
`;
const WarningIcon = styled(InfoCircleFilled)`
  font-size: 42px;
  color: #f16c5d;
  margin-bottom: 39px;
`;
const SelectFile = styled.label`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const DownloadFile = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;
const GobackButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)``;
const InputFile = styled.input`
  &&& {
    display: none;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-bottom: 14px;
`;


const ImportCSV = () => {
  const [uploadFile, setUploadFile] = useState<File>();
  const { csvErrorMessage } = useSelector((state: RootState) => state.slot);
  const dispatch = useAppDispatch();

  const displayText = () => {
    if (csvErrorMessage) return <ErrorText>{csvErrorMessage}</ErrorText>;
    if (uploadFile) {
      return (
        <SelectFile>
          {uploadFile.name}
          <InputFile type="file" accept=".csv" onChange={handleOnFileUpload} />
        </SelectFile>
      );
    }

    return (
      <SelectFile>
        選擇檔案
        <InputFile type="file" accept=".csv" onChange={handleOnFileUpload} />
      </SelectFile>
    );
  };

  const handleOnFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) setUploadFile(files[0]);
  };

  const handleOnClose = () => {
    dispatch(clearCSVErrorMessage());
    dispatch(setShowImportCsvPopup(false));
    setUploadFile(undefined);
  };

  const handleOnImport = () => {
    if (uploadFile) dispatch(batchCSVUploadSlotSalePages(uploadFile));
  };

  return (
    <PopupBackground close={handleOnClose}>
      <Wrapper>
        <CloseIcon onClick={handleOnClose} />
        <CenterBlock>
          {csvErrorMessage ? <WarningIcon /> : <UploadIcon />}
          {displayText()}
          <DownloadFile
            href={`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}admin/template/csv/sales_page_id_frame_import_sample.xlsx`}
          >
            下載範例檔案
          </DownloadFile>
        </CenterBlock>
        <ButtonContainer>
          {!!csvErrorMessage && <GobackButton onClick={handleOnClose}>返回</GobackButton>}
          <ImportButton type="primary" onClick={handleOnImport} disabled={!uploadFile || !!csvErrorMessage}>
            匯入
          </ImportButton>
        </ButtonContainer>
      </Wrapper>
    </PopupBackground>
  );
};

export default ImportCSV;
