import { SaleListItem } from "@api/saleApi";
import MemberGroupValue from "@constant/MemberGroupValue";

export default function computeSalePagePrice(listItem: SaleListItem) {
  // 原價
  let minOriginalPrice = Infinity;
  let maxOriginalPrice = -Infinity;
  let minOriginalUnitPrice = Infinity;
  // 優惠價
  let minSpecialPrice = Infinity;
  let maxSpecialPrice = -Infinity;
  let minSpecialUnitPrice = Infinity;
  // 黑卡價
  let minVipPrice = Infinity;
  let maxVipPrice = -Infinity;
  let minVipUnitPrice = Infinity;

  listItem.plans.forEach((plan) => {
    const { price, salesDiscount, memberDiscounts, optionCounts } = plan;
    // original
    const originalPrice = price;
    const originalUnitPrice = originalPrice / optionCounts;
    if (originalPrice < minOriginalPrice) minOriginalPrice = originalPrice;
    if (originalPrice > maxOriginalPrice) maxOriginalPrice = originalPrice;
    if (originalUnitPrice < minOriginalUnitPrice) minOriginalUnitPrice = originalUnitPrice;

    // special
    const specialPrice = price - salesDiscount;
    const specialUnitPrice = specialPrice / optionCounts;
    if (specialPrice < minSpecialPrice) minSpecialPrice = specialPrice;
    if (specialPrice > maxSpecialPrice) maxSpecialPrice = specialPrice;
    if (specialUnitPrice < minSpecialUnitPrice) minSpecialUnitPrice = specialUnitPrice;
    // vip
    const vipDiscount = memberDiscounts.find((item) => item.groupId === MemberGroupValue.VIP)?.value || 0;
    const vipPrice = specialPrice - vipDiscount;
    const vipUnitPrice = vipPrice / optionCounts;
    if (vipPrice < minVipPrice) minVipPrice = vipPrice;
    if (vipPrice > maxVipPrice) maxVipPrice = vipPrice;
    if (vipUnitPrice < minVipUnitPrice) minVipUnitPrice = vipUnitPrice; // 任選才有用
  });

  return {
    original: {
      minOriginalPrice,
      maxOriginalPrice,
      minOriginalUnitPrice: Math.round(minOriginalUnitPrice),
    },
    special: {
      minSpecialPrice,
      maxSpecialPrice,
      minSpecialUnitPrice: Math.round(minSpecialUnitPrice),
    },
    vip: {
      minVipPrice,
      maxVipPrice,
      minVipUnitPrice: Math.round(minVipUnitPrice),
    },
  };
}
