import React, { FC, useState, useEffect, ChangeEvent } from "react";
import styled from "styled-components";
import { Button, Input, DatePicker, Row, Col } from "antd";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { useSelector } from "react-redux";
import {
  fetchDiscountFrameList,
  resetDiscountFrameListParam,
  updateDiscountFrameListParam,
} from "@redux/discountFrameSlice";
import { FetchDiscountFrameListParam } from "@api/discountFrameApi";

const Wrapper = styled.form`
  margin-bottom: 20px;
  padding: 0 0 0 25px;
`;
const RowTitle = styled.div<{ width?: number }>`
  font-size: 14px;
  line-height: 32px;
  white-space: nowrap;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  color: ${({ theme }) => theme.colorNeutral600};
`;
const RowContent = styled.div`
  flex: auto;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
`;
const ClearFilterButton = styled(Button)`
  margin-right: 8px;
`;

interface LocalListParams {
  limit: number;
  offset: number;
  titleQ?: string;
  pageId?: string;
  startAtBefore: Moment | null;
  startAtAfter: Moment | null;
  endAtBefore: Moment | null;
  endAtAfter: Moment | null;
}

const defaultFilter = {
  limit: 20,
  offset: 0,
  startAtBefore: null,
  endAtBefore: null,
  startAtAfter: null,
  endAtAfter: null,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const frameListParams = useSelector((state: RootState) => state.discountFrameSlice.discountFrameListParam);

  const [localListParams, setLocalListParams] = useState<LocalListParams>(defaultFilter);
  const { titleQ, startAtBefore, endAtBefore, startAtAfter, endAtAfter, pageId } = localListParams;

  useEffect(() => {
    const localParams: LocalListParams = {
      ...frameListParams,
      pageId: frameListParams.pageId,
      titleQ: frameListParams.titleQ,
      startAtBefore: frameListParams.startAtBefore ? moment(frameListParams.startAtBefore) : null,
      startAtAfter: frameListParams.startAtAfter ? moment(frameListParams.startAtAfter) : null,
      endAtBefore: frameListParams.endAtBefore ? moment(frameListParams.endAtBefore) : null,
      endAtAfter: frameListParams.endAtAfter ? moment(frameListParams.endAtAfter) : null,
    };
    setLocalListParams(localParams);
  }, [frameListParams]);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = e.target;
    setLocalListParams((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleOnStartRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setLocalListParams((prev) => ({
        ...prev,
        startAtBefore: endDate,
        startAtAfter: startDate,
      }));
    } else {
      setLocalListParams((prev) => ({
        ...prev,
        startAtBefore: null,
        startAtAfter: null,
      }));
    }
  };
  const handleOnEndRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setLocalListParams((prev) => ({
        ...prev,
        endAtBefore: endDate,
        endAtAfter: startDate,
      }));
    } else {
      setLocalListParams((prev) => ({
        ...prev,
        endAtBefore: null,
        endAtAfter: null,
      }));
    }
  };

  const handleOnClearFilter = () => {
    setLocalListParams(defaultFilter);
    dispatch(resetDiscountFrameListParam());
    dispatch(fetchDiscountFrameList());
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const params: FetchDiscountFrameListParam = {
      ...localListParams,
      startAtBefore:
        localListParams.startAtBefore === null ? undefined : moment(localListParams.startAtBefore).format("YYYY-MM-DD"),
      startAtAfter:
        localListParams.startAtAfter === null ? undefined : moment(localListParams.startAtAfter).format("YYYY-MM-DD"),
      endAtBefore:
        localListParams.endAtBefore === null ? undefined : moment(localListParams.endAtBefore).format("YYYY-MM-DD"),
      endAtAfter:
        localListParams.endAtAfter === null ? undefined : moment(localListParams.endAtAfter).format("YYYY-MM-DD"),
    };
    dispatch(updateDiscountFrameListParam(params));
    dispatch(fetchDiscountFrameList());
  };

  return (
    <Wrapper onSubmit={handleOnSubmit}>
      <Row gutter={[16, 8]}>
        <Col>
          <RowTitle>銷售頁ID</RowTitle>
        </Col>
        <Col span={6}>
          <RowContent>
            <Input value={pageId} name="pageId" onChange={handleOnChange} />
          </RowContent>
        </Col>
        <Col>
          <RowTitle>宣傳名稱</RowTitle>
        </Col>
        <Col span={10}>
          <RowContent>
            <Input value={titleQ} name="titleQ" onChange={handleOnChange} />
          </RowContent>
        </Col>
      </Row>
      <Row gutter={[16, 8]}>
        <Col span={3}>
          <RowTitle>宣傳開始時間</RowTitle>
        </Col>
        <Col span={7}>
          <RangePicker value={[startAtAfter, startAtBefore]} onCalendarChange={handleOnStartRangePick} />
        </Col>
        <Col span={3}>
          <RowTitle>宣傳結束時間</RowTitle>
        </Col>
        <Col span={7}>
          <RangePicker value={[endAtAfter, endAtBefore]} onCalendarChange={handleOnEndRangePick} />
        </Col>
      </Row>
      <Footer>
        <ClearFilterButton onClick={handleOnClearFilter}>清除篩選條件</ClearFilterButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default Filter;
