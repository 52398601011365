import { CloseOutlined, FilterTwoTone, UploadOutlined } from "@ant-design/icons";
import { ChargeItem, OnlineDistributorItem } from "@api/financeApi";
import PopupBackground from "@component/PopupBackground";
import {
  batchImportODListCSV,
  batchModifyODItem,
  exportODListCSV,
  fetchOnlineDistributorList,
  OnlineDistributorStatisticsState,
  updateOnlineDistributorListParams,
} from "@redux/finance/onlineDistributorStatisticsSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, DatePicker, Form, Input, Select } from "antd";
import type { InputRef } from "antd";
import { FormInstance, useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import Table, { ColumnsType } from "antd/lib/table";
import React, { ChangeEvent, FC, ReactText, useEffect, useMemo, useRef, useState } from "react";
import { usePermission, StaffPermission } from "@hooks/usePermission";
import { useDispatch, useSelector } from "react-redux";
import ChargeModal from "./ChargeModal";
import { allDistributorFormTypeOptions, BatchModifyType, distributorFormType, distributorOptions } from "./constant";
import DashBoard from "../../../component/Common/DashBoard";
import Filter from "./Filter";
import {
  ChargeAmountLink,
  FlexBox,
  Header,
  ModalWrapper,
  PageText,
  Row,
  SelectPageSize,
  TableWrapper,
  UploadFile,
  UploadFileName,
} from "./style";

const OrderList = () => {
  const dispatch = useDispatch();
  const { isFetching, onlineDistributorList, onlineDistributorListParams } = useSelector(
    OnlineDistributorStatisticsState,
  );
  const financeAccess = usePermission(StaffPermission.CAN_ACCESS_FINANCE_PAGE);
  const { results: ODlist, amount, amountWithTax, cost, charge } = onlineDistributorList;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showPaymentDateModal, setShowPaymentDateModal] = useState<boolean>(false);
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);
  const [showAllowanceModal, setShowAllowanceModal] = useState<boolean>(false);
  const [showImportCSVModal, setShowImportCSVModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const [selectedODListIds, setSelectedODListIds] = useState<number[]>([]);
  const [chargeModal, setChargeModal] = useState<ChargeItem[]>([]);

  const [dateForm] = useForm();
  const [invoiceForm] = useForm();
  const [allowanceForm] = useForm();
  const [importCSVForm] = useForm();

  const dashBoardData = useMemo(
    () => [
      { name: "對帳未稅總額", value: amount },
      { name: "對賬金額(含稅)", value: amountWithTax },
      { name: "成本", value: cost },
      { name: "扣費", value: charge },
    ],
    [amount, amountWithTax, charge, cost],
  );

  const columns: ColumnsType<OnlineDistributorItem> = [
    {
      key: "distributorName",
      dataIndex: "distributorName",
      title: "通路別",
      width: 120,
    },
    {
      key: "date",
      dataIndex: "date",
      title: "對帳月份",
      width: 120,
    },
    {
      key: "shippingType",
      dataIndex: "shippingType",
      title: "出貨 / 退貨",
      align: "center",
      width: 100,
    },
    {
      key: "contractMode",
      dataIndex: "contractMode",
      title: "寄倉 / 轉單",
      align: "center",
      width: 100,
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "國際條碼",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "訂單編號",
    },
    {
      key: "qty",
      dataIndex: "qty",
      title: "對帳數量",
      width: 100,
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "對帳未稅",
      width: 100,
    },
    {
      key: "tax",
      dataIndex: "tax",
      title: "對帳稅額",
      width: 100,
    },
    {
      key: "totalAmount",
      dataIndex: "totalAmount",
      title: "對帳金額(含稅)",
      width: 140,
    },
    {
      key: "paymentDate",
      dataIndex: "paymentDate",
      title: "金流收款日",
      width: 120,
    },
    {
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
      title: "發票號碼",
      width: 140,
    },
    {
      key: "allowance",
      dataIndex: "allowance",
      title: "折讓單",
      width: 100,
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "成本",
      width: 100,
    },
    {
      key: "chargeAmount",
      dataIndex: "chargeAmount",
      title: "扣費",
      width: 100,
      render: (value, record) => {
        return record.charges?.length !== 0 ? (
          <ChargeAmountLink onClick={() => handleShowChargeList(record.id)}>{value}</ChargeAmountLink>
        ) : (
          value
        );
      },
    },
  ];

  const handleOnCheck = (value: ReactText[]) => {
    setSelectedODListIds(value as number[]);
  };

  const handleOnExport = () => {
    dispatch(exportODListCSV());
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const params = {
      ...onlineDistributorListParams,
      limit: Number(value),
    };
    dispatch(updateOnlineDistributorListParams(params));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      updateOnlineDistributorListParams({
        ...onlineDistributorListParams,
        offset: onlineDistributorListParams.limit * (value - 1),
      }),
    );
  };

  const handleShowChargeList = (id: number) => {
    const data = ODlist.find((item) => item.id === id)?.charges;
    if (data) {
      setChargeModal(data);
    }
  };
  const handleBatchModify = (values: any) => {
    const { type, modifyItemValue } = values;

    let value;
    if (type === BatchModifyType.PAYMENT_DATE) {
      value = modifyItemValue.format("YYYY-MM-DD");
    } else {
      value = String(modifyItemValue);
    }
    dispatch(batchModifyODItem({ selectedODListIds, value, type }));
    setShowPaymentDateModal(false);
    setShowInvoiceModal(false);
    setShowInvoiceModal(false);
  };

  const handleImportCSV = (values: any) => {
    const { distributor, formType, month, file, filePassword } = values;
    dispatch(
      batchImportODListCSV({
        file,
        params: {
          distributor,
          formType,
          date: month.startOf("month").format("YYYY-MM-DD"),
          password: filePassword,
        },
      }),
    );
    setShowImportCSVModal(false);
  };

  useEffect(() => {
    dispatch(fetchOnlineDistributorList());
  }, [dispatch, onlineDistributorListParams]);

  return (
    <>
      <Header>
        <FlexBox>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
          <Button onClick={() => setShowImportCSVModal(true)} disabled={!financeAccess}>
            匯入
          </Button>
          <Button onClick={handleOnExport}>匯出</Button>
        </FlexBox>
        <Row>
          <PageText>{`總共${onlineDistributorList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Header>
      {showFilter && <Filter type="order" />}
      <DashBoard dataset={dashBoardData} isFetching={isFetching} />
      <TableWrapper>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content", y: 500 }}
          columns={columns}
          dataSource={ODlist}
          rowKey="id"
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedODListIds,
            fixed: true,
          }}
          pagination={{
            pageSize: onlineDistributorListParams.limit,
            current: page,
            showSizeChanger: false,
            total: onlineDistributorList.count,
            onChange: fetchPageChange,
          }}
          footer={() => (
            <>
              <Button
                onClick={() => setShowPaymentDateModal(true)}
                disabled={selectedODListIds.length === 0 || !financeAccess}
              >
                批次修改金流收款日
              </Button>
              <Button
                onClick={() => setShowInvoiceModal(true)}
                disabled={selectedODListIds.length === 0 || !financeAccess}
              >
                批次修改發票號碼
              </Button>
              <Button
                onClick={() => setShowAllowanceModal(true)}
                disabled={selectedODListIds.length === 0 || !financeAccess}
              >
                批次修改折讓單號
              </Button>
            </>
          )}
        />
      </TableWrapper>
      {chargeModal.length !== 0 && <ChargeModal dataset={chargeModal} onClose={() => setChargeModal([])} />}
      {showPaymentDateModal && (
        <PaymentDateModal
          formModal={dateForm}
          onSubmit={handleBatchModify}
          onClose={() => setShowPaymentDateModal(false)}
        />
      )}
      {showInvoiceModal && (
        <InvoiceModal formModal={invoiceForm} onSubmit={handleBatchModify} onClose={() => setShowInvoiceModal(false)} />
      )}
      {showAllowanceModal && (
        <AllowanceModal
          formModal={allowanceForm}
          onSubmit={handleBatchModify}
          onClose={() => setShowAllowanceModal(false)}
        />
      )}
      {showImportCSVModal && (
        <ImportCSVModal
          formModal={importCSVForm}
          onSubmit={handleImportCSV}
          onClose={() => setShowImportCSVModal(false)}
        />
      )}
    </>
  );
};
export default OrderList;

type ModifyModal = {
  formModal: FormInstance;
  onSubmit: (values: any) => void;
  onClose: () => void;
};
type ImportModal = ModifyModal;

const PaymentDateModal: FC<ModifyModal> = (props) => {
  const { formModal, onSubmit, onClose } = props;

  const handleClose = () => {
    onClose();
    formModal.resetFields();
  };
  const handleSubmit = (values: any) => {
    onSubmit({ ...values, type: BatchModifyType.PAYMENT_DATE });
  };

  return (
    <PopupBackground close={handleClose} fixed>
      <ModalWrapper width={400}>
        <Form form={formModal} onFinish={handleSubmit} onFinishFailed={showRequiredFieldsWarning}>
          <Form.Item
            label="金流收款日"
            name="modifyItemValue"
            required
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <DatePicker picker="date" placeholder="請選擇日期" style={{ width: "100%" }} />
          </Form.Item>
          <FlexBox>
            <Button onClick={handleClose}>關閉</Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
const InvoiceModal: FC<ModifyModal> = (props) => {
  const { formModal, onSubmit, onClose } = props;

  const handleClose = () => {
    onClose();
    formModal.resetFields();
  };

  const handleSubmit = (values: any) => {
    onSubmit({ ...values, type: BatchModifyType.INVOICE_NUMBER });
    formModal.resetFields();
  };
  return (
    <PopupBackground close={handleClose} fixed>
      <ModalWrapper width={400}>
        <Form form={formModal} onFinish={handleSubmit} onFinishFailed={showRequiredFieldsWarning}>
          <Form.Item
            label="發票號碼"
            name="modifyItemValue"
            required
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FlexBox>
            <Button onClick={handleClose}>關閉</Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
const AllowanceModal: FC<ModifyModal> = (props) => {
  const { formModal, onSubmit, onClose } = props;

  const handleClose = () => {
    onClose();
    formModal.resetFields();
  };
  const handleSubmit = (values: any) => {
    onSubmit({ ...values, type: BatchModifyType.ALLOWANCE });
    formModal.resetFields();
  };

  return (
    <PopupBackground close={handleClose} fixed>
      <ModalWrapper width={400}>
        <Form form={formModal} onFinish={handleSubmit} onFinishFailed={showRequiredFieldsWarning}>
          <Form.Item
            label="折讓單號"
            name="modifyItemValue"
            required
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <FlexBox>
            <Button onClick={handleClose}>關閉</Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
const ImportCSVModal: FC<ImportModal> = (props) => {
  const { formModal, onSubmit, onClose } = props;

  const [uploadFile, setUploadFile] = useState<File>();
  const inputRef = useRef<InputRef>(null);

  const handleOnImport = () => {
    if (inputRef.current?.input) inputRef.current.input.click();
  };
  const handleOnFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      formModal.setFieldsValue({ file: files[0] });
      setUploadFile(files[0]);
    }
  };

  const handleOnRemove = () => {
    setUploadFile(undefined);
    formModal.setFieldsValue({ file: undefined });
  };

  const handleClose = () => {
    onClose();
    formModal.resetFields();
  };
  const handleSubmit = (values: any) => {
    onSubmit(values);
    formModal.resetFields();
  };

  return (
    <PopupBackground close={handleClose} fixed>
      <ModalWrapper width={500}>
        <Form
          form={formModal}
          labelCol={{ span: 5 }}
          onFinish={handleSubmit}
          onFinishFailed={showRequiredFieldsWarning}
        >
          <Form.Item label="通路名稱" name="distributor" required rules={[{ required: true, message: "" }]}>
            <Select
              placeholder="請選擇"
              onChange={() => {
                const formData = formModal.getFieldsValue();
                formModal.setFieldsValue({ ...formData, formType: undefined });
              }}
            >
              {distributorOptions.map((option) => (
                <Select.Option key={option.name} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const distributor = getFieldValue("distributor");
              const formTypeOptions = distributorFormType[distributor];
              return (
                <Form.Item
                  label="表單名稱"
                  name="formType"
                  required
                  shouldUpdate
                  rules={[{ required: true, message: "" }]}
                >
                  <Select placeholder="請選擇" disabled={!formTypeOptions}>
                    {formTypeOptions &&
                      formTypeOptions.map((option) => {
                        const formType = allDistributorFormTypeOptions.find((type) => type.value === option);
                        return (
                          formType && (
                            <Select.Option key={formType.name} value={formType.value}>
                              {formType.name}
                            </Select.Option>
                          )
                        );
                      })}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item label="對帳月份" name="month" required rules={[{ required: true, message: "" }]}>
            <DatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
          <Form.Item
            label="選擇檔案"
            name="file"
            wrapperCol={{ span: 24 }}
            rules={[{ required: true, message: "" }]}
            required
          >
            <Button icon={<UploadOutlined />} onClick={handleOnImport}>
              點擊上傳
            </Button>
            {uploadFile && (
              <UploadFile>
                <UploadFileName>{uploadFile.name}</UploadFileName>
                <CloseOutlined onClick={handleOnRemove} />
              </UploadFile>
            )}
          </Form.Item>
          <Form.Item label="檔案密碼" name="filePassword">
            <Input />
          </Form.Item>
          <Input type="file" accept=".csv,.xls,.xlsx," onChange={handleOnFileUpload} ref={inputRef} hidden />

          <FlexBox>
            <Button onClick={handleClose}>關閉</Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
