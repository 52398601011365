import orderApi, {
  FetchOrderList,
  FetchReplenishReturnApproval,
  FetchReplenishReturnList,
  FetchUpdateReceive,
  OrderChangePayload,
  PlanQty,
} from "@api/orderApi";
import { OrderInfo, OrderList, ReplenishReturnInfo, ReplenishReturnList } from "@api/utils/normalizeOrder";
import { ApprovalStatusFilter } from "@page/Order/ApprovedState";
import ProcessingType from "@page/Order/ProcessingType";
import VendorLogisticsValue from "@page/Order/VendorLogisticsValue";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  orderListResult: {
    count: number;
    next: string;
    previous: string;
    results: OrderList[];
  };
  orderListFilter: {
    limit: number;
    orderNumber?: string;
    shippingStatus?: number;
    name?: string;
    phone?: string;
    shipperNumber?: string;
    packageNumber?: string;
  };
  orderInfo: OrderInfo | undefined;
  replenishReturnListResult: {
    count: number;
    next: string;
    previous: string;
    results: ReplenishReturnList[];
  };
  replenishReturnListFilter: {
    limit: number;
    orderNumber?: string;
    processingType?: ProcessingType;
    status?: ApprovalStatusFilter;
    name?: string;
    phone?: string;
    logistic?: VendorLogisticsValue;
    applyingDate?: string;
    approvalDate?: string;
  };
  replenishReturnInfo: ReplenishReturnInfo | undefined;
  isRefundDone: boolean;
  isReturnFetchingFulfilled: boolean;
}

const initialState: IState = {
  isFetching: false,
  orderListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  orderListFilter: {
    limit: 20,
    orderNumber: undefined,
    shippingStatus: undefined,
    name: undefined,
    phone: undefined,
    shipperNumber: undefined,
    packageNumber: undefined,
  },
  orderInfo: undefined,
  replenishReturnListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  replenishReturnListFilter: {
    limit: 20,
    orderNumber: undefined,
    processingType: undefined,
    status: undefined,
    name: undefined,
    phone: undefined,
    logistic: undefined,
    applyingDate: undefined,
    approvalDate: undefined,
  },
  replenishReturnInfo: undefined,
  isRefundDone: false,
  isReturnFetchingFulfilled: false,
};

export const fetchOrderList = createAsyncThunk("order/fetchOrderList", async (filterOptions: FetchOrderList) => {
  const response = await orderApi.fetchOrderList(filterOptions);
  return response;
});

export const fetchOrderInfo = createAsyncThunk("order/fetchOrderInfo", async (mainOrderId: number) => {
  const response = await orderApi.fetchOrderInfo(mainOrderId);
  return response;
});

export const fetchOrderDeploy = createAsyncThunk(
  "order/fetchOrderDeploy",
  async (payload: { subOrderId: number; mainOrderId: number; finalPlanQty?: PlanQty[] }, thunkApi) => {
    try {
      await orderApi.fetchOrderDeploy(payload.subOrderId, payload.finalPlanQty);
      await thunkApi.dispatch(fetchOrderInfo(payload.mainOrderId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderDelete = createAsyncThunk(
  "order/fetchOrderDelete",
  async (payload: { subOrderId: number; mainOrderId: number }, thunkApi) => {
    try {
      await orderApi.fetchOrderDelete(payload.subOrderId);
      await thunkApi.dispatch(fetchOrderInfo(payload.mainOrderId));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOrderRelease = createAsyncThunk("order/fetchOrderRelease", async (orderId: number, thunkApi) => {
  await orderApi.fetchOrderRelease(orderId);
  await thunkApi.dispatch(fetchOrderInfo(orderId));
  message.success("審核成功");
  return "Success";
});

export const fetchOrderCancel = createAsyncThunk("order/fetchOrderCancel", async (orderId: number, thunkApi) => {
  await orderApi.fetchOrderCancel(orderId);
  await thunkApi.dispatch(fetchOrderInfo(orderId));
  message.success("審核成功");
  return "Success";
});

export const fetchReplenishReturnList = createAsyncThunk(
  "order/fetchReplenishReturnList",
  async (filterOptions: FetchReplenishReturnList) => {
    const response = await orderApi.fetchReplenishReturnList(filterOptions);
    return response;
  },
);

export const fetchReplenishReturnInfo = createAsyncThunk("order/fetchReplenishReturnInfo", async (id: number) => {
  const response = await orderApi.fetchReplenishReturnInfo(id);
  return response;
});

export const fetchReplenishReturnRefund = createAsyncThunk(
  "order/fetchReplenishReturnRefund",
  async (subOrderIds: number[]) => {
    await orderApi.fetchReplenishReturnRefund(subOrderIds);
    return "Success";
  },
);

export const fetchReplenishReturnApproval = createAsyncThunk(
  "order/fetchReplenishReturnApproval",
  async (config: FetchReplenishReturnApproval, thunkApi) => {
    await orderApi.fetchReplenishReturnApproval(config);
    await thunkApi.dispatch(fetchReplenishReturnInfo(config.orderId));
    return "Success";
  },
);

export const fetchUpdateReceive = createAsyncThunk(
  "order/fetchUpdateReceive",
  async (payload: FetchUpdateReceive & { mainOrderId: number }, thunkApi) => {
    await orderApi.fetchUpdateReceive(payload);
    await thunkApi.dispatch(fetchOrderDeploy({ subOrderId: payload.subOrderId, mainOrderId: payload.mainOrderId }));
    return "Success";
  },
);

export const fetchRejectTransfer = createAsyncThunk(
  "order/fetchRejectTransfer",
  async (payload: { mainOrderId: number; subOrderId: number }, thunkApi) => {
    try {
      await orderApi.fetchRejectTransfer(payload.subOrderId);
      await thunkApi.dispatch(fetchOrderInfo(payload.mainOrderId));
      message.success("拒收成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchReturn = createAsyncThunk("order/fetchReturn", async (payload: OrderChangePayload, thunkApi) => {
  try {
    const response = await orderApi.fetchReturn(payload);
    await thunkApi.dispatch(fetchOrderInfo(payload.orderId));
    message.success("建立成功");

    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const changeOrderShippingStatus = createAsyncThunk(
  "order/fetchReturn",
  async (payload: { mainOrderId: number; subOrderId: number; statusCode: number }, thunkApi) => {
    const { subOrderId, mainOrderId, statusCode } = payload;
    try {
      await orderApi.changeOrderShippingStatus(subOrderId, statusCode);
      await thunkApi.dispatch(fetchOrderInfo(mainOrderId));
      message.success("狀態變更成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const makeOrderInvoice = createAsyncThunk("order/makeOrderInvoice", async (mainOrderId: number, thunkApi) => {
  try {
    await orderApi.makeOrderInvoice(mainOrderId);
    await thunkApi.dispatch(fetchOrderInfo(mainOrderId));
    message.success("開立發票成功");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    resetOrderSlice: () => initialState,
    setOrderListFilter: (state, action) => {
      state.orderListFilter = action.payload;
    },
    clearOrderListFilter: (state) => {
      state.orderListFilter = initialState.orderListFilter;
    },
    setReplenishReturnListFilter: (state, action) => {
      state.replenishReturnListFilter = action.payload;
    },
    clearReplenishReturnListFilter: (state) => {
      state.replenishReturnListFilter = initialState.replenishReturnListFilter;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.orderListResult = action.payload;
    });
    builder.addCase(fetchOrderList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchOrderInfo.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderInfo.fulfilled, (state, action) => {
      state.isFetching = false;
      state.orderInfo = action.payload;
    });
    builder.addCase(fetchOrderInfo.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReplenishReturnList.pending, (state) => {
      state.isRefundDone = false;
      state.isFetching = true;
    });
    builder.addCase(fetchReplenishReturnList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.replenishReturnListResult = action.payload;
    });
    builder.addCase(fetchReplenishReturnList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReplenishReturnInfo.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReplenishReturnInfo.fulfilled, (state, action) => {
      state.replenishReturnInfo = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchReplenishReturnInfo.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchReplenishReturnRefund.fulfilled, (state) => {
      state.isRefundDone = true;
    });
    builder.addCase(fetchReturn.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturn.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isReturnFetchingFulfilled = true;
    });
    builder.addCase(fetchReturn.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchOrderDeploy.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderDeploy.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchOrderDeploy.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const orderState = (state: RootState) => state.orderSlice;
export const {
  resetOrderSlice,
  setOrderListFilter,
  clearOrderListFilter,
  setReplenishReturnListFilter,
  clearReplenishReturnListFilter,
} = orderSlice.actions;
export default orderSlice.reducer;
