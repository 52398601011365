import throughShipmentApi, { AccessPointList, AccessPointListResult } from "@api/throughShipment/throughShipmentApi";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import { fetchCreateAccessPoint, fetchUpdateAccessPoint } from "@redux/dealerSlice";
import { Button, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const SearchList = styled.div`
  min-height: 40px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background: #ffffff;
  position: absolute;
  top: 101%;
  width: 100%;
  padding: 4px 0px;
  z-index: 10;
`;
const SearchWrapper = styled.div`
  position: relative;
`;
const SearchItem = styled.div`
  padding: 5px 12px;
  font-size: 14px;
  cursor: pointer;

  &:active {
    background: #e6f7ff;
  }

  &:hover {
    background: #f2f4f5;
  }
`;
const RowTitle = styled.div<{ bold?: boolean }>`
  align-self: flex-start;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const RequiredIcon = styled.span`
  color: red;
`;

type Props = {
  accessPoint?: AccessPointList;
  onClose: () => void;
};

interface FormState {
  name: string;
  address: string;
  code: string;
}

export default function EditAccessPointPopup(props: Props) {
  const { accessPoint, onClose } = props;

  const dispatch = useDispatch();

  const { dealerId } = useParams();

  const [form] = Form.useForm();

  const [focusRetailInput, setFocusRetailInput] = useState<boolean>(false);
  const [searchAccessPoint, setSearchAccessPoint] = useState<AccessPointListResult>({
    count: 0,
    next: null,
    previous: null,
    results: [],
  });

  const onSearchAccessPointList = useDebounce(async (searchText: string) => {
    if (searchText) {
      const response = await throughShipmentApi.fetchAccessPointList({
        nameQ: searchText,
        retailerProductline: parseInt(dealerId, 10),
        limit: 100,
        offset: 0,
      });
      setSearchAccessPoint(response);
    }
  }, 1000);

  const onSubmit = (values: FormState) => {
    if (accessPoint) {
      dispatch(fetchUpdateAccessPoint({ ...values, accessPointId: accessPoint.id }));
    } else {
      dispatch(fetchCreateAccessPoint({ ...values, retailerProductline: parseInt(dealerId, 10) }));
    }
    onClose();
  };

  useEffect(() => {
    if (accessPoint) {
      form.setFieldsValue({
        name: accessPoint.name,
        address: accessPoint.address,
        code: accessPoint.code,
      });
    }
  }, [accessPoint, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 4 }} form={form} initialValues={{}} onFinish={onSubmit}>
          <Row wrap={false}>
            <RowTitle>
              倉點名稱<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={500}>
              <SearchWrapper>
                <Form.Item name="name" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "必填" }]}>
                  <Input
                    autoComplete="off"
                    onFocus={() => setFocusRetailInput(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setFocusRetailInput(false);
                      }, 200);
                    }}
                    onChange={(e) => onSearchAccessPointList(e.target.value)}
                  />
                </Form.Item>
                {focusRetailInput && (
                  <SearchList>
                    {searchAccessPoint.results.map((item) => (
                      <SearchItem role="presentation" key={item.id} onClick={() => setFocusRetailInput(false)}>
                        {item.name}
                      </SearchItem>
                    ))}
                  </SearchList>
                )}
              </SearchWrapper>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>
              郵遞區號<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={500}>
              <Form.Item name="code" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "必填" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>
              倉點地址<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={500}>
              <Form.Item name="address" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "必填" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row justify="end">
            <CustomButton onClick={onClose}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
