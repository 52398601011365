import { ReactNode, ReactText } from "react";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import normalizeSalesCategory from "./utils/normalizeSalesCategory";

const apiClient = new CoreAPI();

export interface SalePageCategoryNode {
  key: ReactText;
  id: ReactText;
  title: string | ReactNode;
  parent: ReactText;
  salesPageCount: number;
  isActive: boolean;
  children: SalePageCategoryNode[];
  level: number;
  isLeaf?: boolean;
  isAddButton?: boolean;
  icon?: ReactNode;
}
export interface SalePageCategoryDetail {
  id: number;
  name: string;
  path: string;
  parent: number;
  pageInfo: PageInfo;
  icon?: string;
  iconId?: number;
  iconAlt: string;
  level: number;
  isActive: boolean;
  toShow: boolean;
}
export interface PageInfo {
  id: number;
  title: string;
  description: string;
  image: {
    alt: string;
    id: number;
    image: string;
    isActive: boolean;
    mediaType: number;
    name: string;
    url: string;
  };
  backgroundColor: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  sortMethods: SortMethod[];
}
export interface SortMethod {
  id: number;
  name: string;
  isDescend: boolean;
  isDefault: boolean;
  isActive: boolean;
}
export interface CreateSalesCategoryParam {
  isActive: boolean;
  name: string;
  parent: number;
  icon?: string;
  iconAlt: string;
  image?: string;
  imageAlt: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  sortMethods: SortMethod[];
}
export interface FetchSalePageListParam {
  limit: number;
  offset: number;
  pageIds: string;
  sku: string;
  pageName: string;
  brandNames: string[];
  minPrice: number | "";
  maxPrice: number | "";
  excludeCategoryId?: number;
  ordering?: "rank" | "-rank";
  startAt?: string;
  endAt?: string;
}
export interface SalePageListResult {
  count: number;
  next: string;
  previous: string;
  results: SalePageListItem[];
}
export interface SalePageListItem {
  id: number;
  name: string;
  rank: number;
  skuList: string[];
  brands: string[];
  planSpecialPriceRange: {
    maxPrice: number;
    minPrice: number;
  };
}
export interface MoveSalesPageParam {
  leftSibling?: number;
  rightSibling?: number;
  parent: number;
}

interface ISalePageCategoryApi {
  fetchSalesCategoryList: (displayInactive: boolean) => Promise<SalePageCategoryNode[]>;
  fetchSalesCategoryDetail: (id: number) => Promise<SalePageCategoryDetail>;
  createSalesCategory: (params: CreateSalesCategoryParam) => Promise<SalePageCategoryDetail>;
  updateSalesCategory: (id: number, params: CreateSalesCategoryParam) => Promise<SalePageCategoryDetail>;
  fetchSalePageList: (categoryId: number, params: FetchSalePageListParam) => Promise<SalePageListResult>;
  fetchSalePageListByLevelCollection: (
    categoryId: number,
    params: FetchSalePageListParam,
  ) => Promise<SalePageListResult>;
  fetchSalePageListExcludeCategory: (params: FetchSalePageListParam) => Promise<SalePageListResult>;
  batchUpdateSalePageRank: (categoryId: number, params: SalePageListItem[]) => Promise<StandardResponse>;
  batchAddSalePages: (categoryId: number, pageIds: number[]) => Promise<StandardResponse>;
  batchRemoveSalePages: (categoryId: number, pageIds: number[]) => Promise<StandardResponse>;
  batchAddSalePagesByCSV: (categoryId: number, file: File) => Promise<StandardResponse>;
  moveSalesPageCategory: (categoryId: number, params: MoveSalesPageParam) => Promise<void>;
}

const SalePageCategoryApi: ISalePageCategoryApi = {
  fetchSalesCategoryList: async (displayInactive) => {
    const response = await apiClient.getData("/manage/sales/categories/", {
      display_inactive: displayInactive ? 1 : 0,
    });
    return normalizeSalesCategory.list(response.data.result);
  },
  fetchSalesCategoryDetail: async (id) => {
    const response = await apiClient.getData(`/manage/sales/categories/${id}/`, {
      with_sort_method: 1,
    });
    return normalizeSalesCategory.detail(response.data.result);
  },
  createSalesCategory: async (params) => {
    const {
      isActive,
      name,
      parent,
      icon,
      iconAlt,
      image,
      imageAlt,
      metaTitle,
      metaKeywords,
      metaDescription,
      sortMethods,
    } = params;
    const defaultSortMethod = sortMethods.find((mtd) => mtd.isDefault);

    // 上傳圖片路徑
    const iconDirPrefix = icon && "sale/category/icon";
    const iconFilePath = icon;
    const imageDirPrefix = image && "sale/category/image";
    const imageFilePath = image;

    const postParams = {
      is_active: isActive,
      name,
      parent_id: parent > -1 ? parent : 1,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: iconAlt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imageAlt,
      meta_title: metaTitle,
      meta_keywords: metaKeywords,
      meta_description: metaDescription,
      default_sort_method: defaultSortMethod?.name,
      sort_methods: sortMethods.map((mtd) => ({
        name: mtd.name,
        is_active: mtd.isActive,
      })),
    };
    const response = await apiClient.postData("/manage/sales/categories/", postParams);
    return normalizeSalesCategory.detail(response.data.result);
  },
  updateSalesCategory: async (id, params) => {
    const {
      isActive,
      name,
      parent,
      icon,
      iconAlt,
      image,
      imageAlt,
      metaTitle,
      metaKeywords,
      metaDescription,
      sortMethods,
    } = params;
    const defaultSortMethod = sortMethods.find((mtd) => mtd.isDefault)!;

    // 上傳圖片路徑 icon/image 存的是s3網址或是後端回傳的暫時路徑
    // s3網址 ？ 不做更新 ： 傳路徑
    const iconDirPrefix =
      icon && !icon.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? "sale/category/icon" : undefined;
    const iconFilePath = icon && !icon.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? icon : undefined;
    const imageDirPrefix =
      image && !image.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? "sale/category/image" : undefined;
    const imageFilePath = image && !image.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? image : undefined;

    const patchParams = {
      is_active: isActive,
      name,
      parent_id: parent,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: iconAlt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imageAlt,
      meta_title: metaTitle,
      meta_keywords: metaKeywords,
      meta_description: metaDescription,
      default_sort_method: defaultSortMethod?.name,
      sort_methods: sortMethods.map((mtd) => ({
        name: mtd.name,
        is_active: mtd.isActive || mtd.name === defaultSortMethod.name, // 沒勾此順序但是排序預設有選
      })),
    };
    const response = await apiClient.patchData(`/manage/sales/categories/${id}/`, patchParams);
    return normalizeSalesCategory.detail(response.data.result);
  },
  fetchSalePageList: async (id, params) => {
    const {
      limit,
      offset,
      pageIds,
      pageName,
      brandNames,
      sku,
      minPrice,
      maxPrice,
      excludeCategoryId,
      ordering,
      startAt,
      endAt,
    } = params;
    const getParams = {
      limit,
      offset,
      page_ids: pageIds === "" ? undefined : pageIds,
      page_name_q: pageName === "" ? undefined : pageName,
      brand_names: brandNames.length > 0 ? brandNames?.join() : undefined,
      sku: sku === "" ? undefined : sku,
      min_price: minPrice === "" ? undefined : minPrice,
      max_price: maxPrice === "" ? undefined : maxPrice,
      exclude_category_id: excludeCategoryId,
      ordering,
      start_at: startAt,
      end_at: endAt,
    };

    const response = await apiClient.getData(`/manage/sales/categories/${id}/pages/`, getParams);
    const normalizedData = normalizeSalesCategory.salePageList(response.data.result);
    return normalizedData;
  },
  fetchSalePageListByLevelCollection: async (id, params) => {
    const {
      limit,
      offset,
      pageIds,
      pageName,
      brandNames,
      sku,
      minPrice,
      maxPrice,
      excludeCategoryId,
      ordering,
      startAt,
      endAt,
    } = params;
    const getParams = {
      limit,
      offset,
      page_ids: pageIds === "" ? undefined : pageIds,
      page_name_q: pageName === "" ? undefined : pageName,
      brand_names: brandNames.length > 0 ? brandNames?.join() : undefined,
      sku: sku === "" ? undefined : sku,
      min_price: minPrice === "" ? undefined : minPrice,
      max_price: maxPrice === "" ? undefined : maxPrice,
      exclude_category_id: excludeCategoryId,
      ordering,
      start_at: startAt,
      end_at: endAt,
    };

    const response = await apiClient.getData(`/manage/sales/categories/${id}/sales-page-rank/`, getParams);
    const normalizedData = normalizeSalesCategory.salePageList(response.data.result);
    return normalizedData;
  },
  fetchSalePageListExcludeCategory: async (params) => {
    const { limit, offset, pageIds, pageName, brandNames, sku, minPrice, maxPrice, excludeCategoryId } = params;
    const getParams = {
      limit,
      offset,
      page_ids: pageIds === "" ? undefined : pageIds,
      page_name_q: pageName === "" ? undefined : pageName,
      brand_names: brandNames.length > 0 ? brandNames?.join() : undefined,
      sku: sku === "" ? undefined : sku,
      min_price: minPrice === "" ? undefined : minPrice,
      max_price: maxPrice === "" ? undefined : maxPrice,
      exclude_category_id: excludeCategoryId,
    };

    const response = await apiClient.getData("/manage/sales/pages", getParams);
    return normalizeSalesCategory.salePageList(response.data.result);
  },
  batchUpdateSalePageRank: async (id, params) => {
    const putParams = {
      sales_page_rank_data: params.map((item) => ({
        sales_page: item.id,
        rank: item.rank,
      })),
    };
    const response = await apiClient.putData(`/manage/sales/categories/${id}/pages/batch/ranks/`, putParams);
    return response.data;
  },
  batchAddSalePages: async (categoryId, ids) => {
    const putParams = {
      page_ids: ids,
    };
    const response = await apiClient.putData(`/manage/sales/categories/${categoryId}/pages/batch/add/`, putParams);
    return response.data;
  },
  batchRemoveSalePages: async (categoryId, ids) => {
    const putParams = {
      page_ids: ids,
    };
    const response = await apiClient.putData(`/manage/sales/categories/${categoryId}/pages/batch/delete/`, putParams);
    return response.data;
  },
  batchAddSalePagesByCSV: async (categoryId, file) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    const response = await apiClient.putData(
      `/manage/sales/categories/${categoryId}/pages/batch/csv-upload/`,
      formData,
    );
    return response.data;
  },
  moveSalesPageCategory: async (categoryId, params) => {
    const requestParams = {
      left_sibling: params.leftSibling,
      right_sibling: params.rightSibling,
      parent: params.parent,
    };

    await apiClient.putData(`/manage/sales/categories/${categoryId}/move/`, requestParams);
  },
};

export default SalePageCategoryApi;
