/* eslint-disable @typescript-eslint/no-unused-vars */
/* 
退貨單：
  1. 列表(篩選） [GET]  /customer/manage/return-orders/
  2. 新增（匯入) [Post]  /customer/manage/return-orders/upload/
  3. 編輯 [PUT]  [PUT] /customer/manage/return-orders/<PK>/
  4. 刪除（作廢） [DELETE] /customer/manage/return-orders/<PK>/
  5. 取得通路列表 [GET] /manage/retail/retailers/?contract_types=10,30 用 throughShipmentApi
  6. 回壓發票 [POST] /customer/manage/return-orders/<pk>/invoice/
退貨單詳情：
  1. 詳情 [GET] /customer/manage/return-orders/<pk>/
  2. 提交 [PUT] /customer/manage/return-orders/<pk>/submit/
退貨商品：
  1. 列表 [GET] /customer/manage/return-orders/<order_pk>/return-orderlines/
  2. 新增 [Post] /customer/manage/return-orders/<order_pk>/return-orderlines/
  3. 編輯 [PUT]  /customer/manage/return-orders/<order_pk>/return-orderlines/<pk>/
  4. 刪除 [DELETE] /customer/manage/return-orders/<order_pk>/return-orderlines/<pk>/
  5. 取得商品資訊 [GET]  /manage/product/product-warehouse/?warehouse_code=RENTRAP&sku=maxsku3 用 SpecialShipmentApi
  */

import CoreAPI from "@api/CoreAPI";
import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "@api/utils/transformCamelToSnake";
import { InvoiceType } from "@constant/InvoiceType";
import { ListResponseType } from "src/types";

export interface ReturnProductAppListParams {
  limit: number;
  offset: number;
  sku?: string;
  warehouseCode?: string;
  returnNumber?: string;
  platform?: string;
  createDtAfter?: string;
  createDtBefore?: string;
  ordering?: string;
}

export interface ReturnProductAppItem extends ReturnInvoiceInfo {
  id: number;
  canInvoice: boolean;
  canSubmit: boolean;
  createdDt: string;
  destroyedAt: string | null;
  isReturnToWarehouse: boolean;
  originalPlatformNumber: string;
  platform: string;
  receiverName: string;
  receiverPhone: string;
  returnNumber: string;
  staff: string;
  submittedAt: string | null;
  totalQty: number;
  warehouseCode: string;
  note: string;
  invoicedBy: string;
  stockinDate: string | null;
}

export interface UpdateAppDetailPayload {
  platform: string;
  isReturnToWarehouse: boolean;
  returnNumber: boolean;
  receiverName: string;
  originalPlatformNumber: string;
}
export type ReturnInvoiceInfo = {
  invoiceNumber: string;
  invoiceAmount: number;
  invoiceType: InvoiceType;
};
export interface ReturnProductItem {
  id: number;
  sku: string;
  productName: string;
  barcode: string;
  qty: number;
  reason: string;
  warehouseStorage: number;
}
export type ProductPayload = {
  sku: string;
  qty: number;
  reason: string;
};

export enum ContractType {
  BUY_AND_NOT_RETURN = 10, // 買斷不可退'
  CONSIGNMENT = 20, // 寄倉
  TRANSFER = 30, // 轉單
}

interface ReturnProductManagementApi {
  // 退貨單：
  fetchReturnProductAppList: (params: ReturnProductAppListParams) => Promise<ListResponseType<ReturnProductAppItem>>;
  importReturnProductApp: (file: File) => Promise<void>;
  updateReturnProductApp: (returnId: number, payload: UpdateAppDetailPayload) => Promise<void>;
  deleteReturnProductApp: (returnId: number) => Promise<void>;
  backfillInvoice: (returnId: number, payload: ReturnInvoiceInfo) => Promise<void>;
  // 退貨單詳情：
  fetchReturnProductAppDetail: (returnId: number) => Promise<ReturnProductAppItem>;
  applyReturnProductApp: (returnId: number) => Promise<void>;
  // 退貨商品：
  fetchReturnProductList: (returnId: number) => Promise<ListResponseType<ReturnProductItem>>;
  createReturnProduct: (returnId: number, payload: ProductPayload) => Promise<void>;
  updateReturnProduct: (returnId: number, productId: number, payload: ProductPayload) => Promise<void>;
  deleteReturnProduct: (returnId: number, productId: number) => Promise<void>;
  fetchProductInfo: (sku: string, warehouseCode: string) => Promise<{ productName: string; barcode: string }>;
}

const apiClient = new CoreAPI();

const returnProductManagementApi: ReturnProductManagementApi = {
  // 退貨單：
  fetchReturnProductAppList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/customer/manage/return-orders/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  importReturnProductApp: async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    await apiClient.postData("/customer/manage/return-orders/upload/", formData);
  },
  updateReturnProductApp: async (returnId, payload) => {
    const putParams = transformCamelToSnake(payload);
    await apiClient.putData(`/customer/manage/return-orders/${returnId}/`, putParams);
  },
  deleteReturnProductApp: async (returnId) => {
    await apiClient.deleteData(`/customer/manage/return-orders/${returnId}/`, {});
  },
  backfillInvoice: async (returnId, payload) => {
    const postParams = transformCamelToSnake(payload);
    await apiClient.postData(`/customer/manage/return-orders/${returnId}/invoice/`, postParams);
  },
  // 退貨單詳情：
  fetchReturnProductAppDetail: async (returnId) => {
    const response = await apiClient.getData(`/customer/manage/return-orders/${returnId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  applyReturnProductApp: async (returnId) => {
    await apiClient.putData(`/customer/manage/return-orders/${returnId}/submit/`, {});
  },
  // 退貨商品：
  fetchReturnProductList: async (returnId) => {
    const response = await apiClient.getData(`/customer/manage/return-orders/${returnId}/return-orderlines/`, {
      limit: 200,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  createReturnProduct: async (returnId, payload) => {
    const postParams = transformCamelToSnake(payload);
    await apiClient.postData(`/customer/manage/return-orders/${returnId}/return-orderlines/`, postParams);
  },
  updateReturnProduct: async (returnId, productId, payload) => {
    const putParams = transformCamelToSnake(payload);
    await apiClient.putData(`/customer/manage/return-orders/${returnId}/return-orderlines/${productId}/`, putParams);
  },
  deleteReturnProduct: async (returnId, productId) => {
    await apiClient.deleteData(`/customer/manage/return-orders/${returnId}/return-orderlines/${productId}/`, {});
  },
  fetchProductInfo: async (sku, warehouseCode) => {
    const getParams = transformCamelToSnake({ warehouseCode, sku });
    const response = await apiClient.getData("/manage/product/product-warehouse/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default returnProductManagementApi;
