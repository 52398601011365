import UploadImageButton from "@component/UploadImageButton";
import { Checkbox, Form, Input, Row, Select } from "antd";
import { FormInstance } from "antd/lib/form";
import { SelectValue } from "antd/lib/select";
import React, { FC, useState } from "react";
import { ChromePicker, ColorChangeHandler } from "react-color";
import styled from "styled-components";
import type { PromotionForm } from "./index";

const Wrapper = styled.div`
  border: 1px solid #d9d9d9;
  margin-bottom: 60px;
`;
const Header = styled.div`
  padding: 10px 20px;
  background-color: #fafafa;
  color: ${({ theme }) => theme.colorNeutral600};
  font-size: 14px;
`;
const Body = styled.div`
  padding: 16px 20px 34px 20px;
`;
const Grid = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 20px;
  margin-bottom: 40px;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ColorWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const ColorSquare = styled.div<{ color?: string }>`
  width: 16px;
  height: 16px;
  border: 1px solid #d9d9d9;
  margin-right: 8px;
  background-color: ${({ color }) => color};
  cursor: pointer;
`;
const Text = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ColorPickerContainer = styled.div`
  position: absolute;
  top: -10px;
  left: 0;
  transform: translate(0, -100%);
`;
const ColorPickerMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
const SectionTitle = styled.div`
  padding-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 16px;
`;
const NoResizeTextArea = styled(Input.TextArea)`
  resize: none;
`;
const MetaContent = styled.div`
  width: 650px;
`;
const SortingRow = styled.div`
  display: flex;
`;
const DefaultSortingRow = styled.div`
  display: flex;
  align-items: center;
`;
const DefaultSortTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 26px;
`;
const StyledSelect = styled(Select)`
  width: 130px;
`;
const ImageNotice = styled.div`
  margin-left: 12px;
  color: ${({ theme }) => theme.colorNeutral500};
`;

interface Props {
  form: FormInstance<PromotionForm>;
}

const PageInfoSetting: FC<Props> = (props) => {
  const { form } = props;

  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  const toggleShowColorPicker = () => setShowColorPicker((prev) => !prev);

  const handleOnColorChange: ColorChangeHandler = (color) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      pageInfo: {
        ...values.pageInfo,
        backgroundColor: color.hex,
      },
    });
  };

  const handleImageUpload = (path: string) => {
    const { pageInfo } = form.getFieldsValue();
    form.setFieldsValue({
      pageInfo: {
        ...pageInfo,
        image: {
          ...pageInfo.image,
          url: path,
        },
      },
    });
  };

  const handleOnSortMethodSelect = (value: SelectValue) => {
    const { pageInfo } = form.getFieldsValue();
    const { sortMethods } = pageInfo;

    form.setFieldsValue({
      pageInfo: {
        ...pageInfo,
        sortMethods: sortMethods.map((mtd) => ({
          ...mtd,
          isActive: mtd.name === value ? true : mtd.isActive,
          isDefault: mtd.name === value,
        })),
      },
    });
  };

  const onSortMethodChange = () => {
    // 確保預設的選項被勾選
    const { pageInfo } = form.getFieldsValue();
    const { sortMethods } = pageInfo;

    const currentDefaultSortMethod = pageInfo.sortMethods.find((mtd) => mtd.isDefault);

    form.setFieldsValue({
      pageInfo: {
        ...pageInfo,
        sortMethods: sortMethods.map((mtd) => ({
          ...mtd,
          isActive: currentDefaultSortMethod?.name === mtd.name ? true : mtd.isActive,
        })),
      },
    });
  };

  return (
    <Wrapper>
      <Header>頁面設定</Header>
      <Body>
        <Grid>
          <Title>活動主圖</Title>
          <Row>
            <Form.Item name={["pageInfo", "image", "url"]} valuePropName="imageUrl">
              <UploadImageButton finishUpload={handleImageUpload} />
            </Form.Item>
            <ImageNotice>*圖片長寬比3:1</ImageNotice>
          </Row>
          <Title>活動主色</Title>
          <ColorWrapper>
            <Form.Item
              noStyle
              shouldUpdate={(prevValue, currentValues) => {
                return prevValue.pageInfo?.backgroundColor !== currentValues.pageInfo?.backgroundColor;
              }}
            >
              {({ getFieldValue }) => {
                const pageInfo = getFieldValue("pageInfo");
                const { backgroundColor = "#ffffff" } = pageInfo || {};
                return (
                  <Form.Item name={["pageInfo", "backgroundColor"]} noStyle>
                    <ColorSquare onClick={toggleShowColorPicker} color={backgroundColor} />
                    <Text>{backgroundColor}</Text>
                    {showColorPicker && (
                      <>
                        <ColorPickerMask onClick={toggleShowColorPicker} />
                        <ColorPickerContainer>
                          <ChromePicker color={backgroundColor} disableAlpha onChange={handleOnColorChange} />
                        </ColorPickerContainer>
                      </>
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </ColorWrapper>
        </Grid>
        <SectionTitle>SEO</SectionTitle>
        <Grid>
          <Title>Meta Title</Title>
          <MetaContent>
            <Form.Item name={["pageInfo", "metaTitle"]}>
              <Input width={650} />
            </Form.Item>
          </MetaContent>
          <Title>Meta Keywords</Title>
          <MetaContent>
            <Form.Item name={["pageInfo", "metaKeywords"]}>
              <NoResizeTextArea rows={3} />
            </Form.Item>
          </MetaContent>
          <Title>Meta Description</Title>
          <MetaContent>
            <Form.Item name={["pageInfo", "metaDescription"]}>
              <NoResizeTextArea rows={3} />
            </Form.Item>
          </MetaContent>
        </Grid>
        <SectionTitle>Available Product Listing Sort By</SectionTitle>
        <SortingRow>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.pageInfo?.sortMethods !== currentValues.pageInfo?.sortMethods;
            }}
          >
            {({ getFieldValue }) => {
              const pageInfo = getFieldValue("pageInfo");
              const { sortMethods = [] } = pageInfo || {};
              return (
                <Form.List name={["pageInfo", "sortMethods"]}>
                  {(fields) => {
                    return fields.map((field) => (
                      <Form.Item name={[field.name, "isActive"]} valuePropName="checked">
                        <Checkbox disabled={sortMethods[field.key].name === "熱銷"} onChange={onSortMethodChange}>
                          {sortMethods[field.key].name}
                        </Checkbox>
                      </Form.Item>
                    ));
                  }}
                </Form.List>
              );
            }}
          </Form.Item>
        </SortingRow>
        <DefaultSortingRow>
          <DefaultSortTitle>商品排序預設為</DefaultSortTitle>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => {
              return prevValues.pageInfo?.sortMethods !== currentValues.pageInfo?.sortMethods;
            }}
          >
            {({ getFieldValue }) => {
              const pageInfo: PromotionForm["pageInfo"] = getFieldValue("pageInfo");
              const { sortMethods = [] } = pageInfo || {};
              const isDefaultSortMethod = sortMethods.find((mtd) => mtd.isDefault);
              return (
                <StyledSelect
                  value={isDefaultSortMethod?.name}
                  onChange={(value) => handleOnSortMethodSelect(value as SelectValue)}
                >
                  {sortMethods.map((opt) => (
                    <Select.Option value={opt.name}>{opt.name}</Select.Option>
                  ))}
                </StyledSelect>
              );
            }}
          </Form.Item>
        </DefaultSortingRow>
      </Body>
    </Wrapper>
  );
};

export default PageInfoSetting;
