/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import { MenuNode } from "@api/menuMangementApi";
import { ReactNode, ReactText } from "react";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

interface MenuDetail {
  id: number;
  name: string;
  path: string;
  parent: number;

  level: number;
  isActive: boolean;
  link: string;
  openingNewTab: boolean;
}
interface MenuListResult {
  count: number;
  next: string;
  previous: string;
  results: [];
}

interface MenuNodeType {
  key: ReactText;
  id: number;
  title: string | ReactNode;
  parent: number;
  is_active: boolean;
  children: MenuNode[];
  level: number;
  isLeaf?: boolean;
  isAddButton?: boolean;
  label?: string;
  name: string;
  value?: number;
  opening_new_tab: boolean;
  link: string;
}

interface MenuListRes {
  level_1: MenuNodeType[];
  level_2: MenuNodeType[];
  level_3: MenuNodeType[];
}
interface MapCategoryNode {
  key: number;
  id: number;
  name: string;
  title: string;
  parent: number;
  isActive: boolean;
  level: number;
  children: Map<number, MapCategoryNode>;
  openingNewTab: boolean;
  link: string;
}

interface MenuDetailRes {
  id: number;
  name: string;
  path: string;
  parent: number;
  level: number;
  is_active: boolean;
  link: string;
  opening_new_tab: boolean;
}
interface SalePageListRes {
  count: number;
  next: string;
  previous: string;
  results: {
    id: number;
    name: string;
    rank: number;
    sku_list: string[];
    brands: string[];
    plan_special_price_range: {
      max_price: number;
      min_price: number;
    };
  }[];
}

interface NormalizeMenu {
  list: (res: MenuListRes) => MenuNode[];
  detail: (res: MenuDetailRes) => MenuDetail;
  salePageList: (res: SalePageListRes) => MenuListResult;
}

const normalizeMenu: NormalizeMenu = {
  list: (MenuListRes) => {
    const listMap = new Map<number, MapCategoryNode>();
    // tree level 1
    MenuListRes.level_1.forEach((level1Node) => {
      const { id, name, parent, is_active, opening_new_tab, link } = level1Node;

      const normalizedNode = {
        key: id,
        id,
        name,
        title: name,
        parent,
        level: 1,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
        openingNewTab: opening_new_tab,
        link,
      };
      listMap.set(id, normalizedNode);
    });

    // tree level 2
    MenuListRes.level_2.forEach((level2Node) => {
      const { id, parent, name, is_active, opening_new_tab, link } = level2Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: name,
        parent,
        level: 2,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
        openingNewTab: opening_new_tab,
        link,
      };

      const parentNode = listMap.get(parent)!;
      const parentNodeChildren = parentNode.children;
      parentNodeChildren.set(id, normalizedNode);
    });

    // tree level 3
    MenuListRes.level_3.forEach((level3Node) => {
      const { id, parent, name, is_active, opening_new_tab, link } = level3Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: name,
        parent,
        level: 3,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
        openingNewTab: opening_new_tab,
        link,
      };

      for (const [, level1Node] of listMap) {
        // parentNode 在level2
        const parentNode = level1Node.children.get(parent);
        if (parentNode) {
          const parentNodeChildren = parentNode.children;
          parentNodeChildren.set(id, normalizedNode);
        }
      }
    });

    // Map -> array
    const result = Array.from(listMap).map(([, lvl1]) => ({
      ...lvl1,
      children: Array.from(lvl1.children).map(([, lvl2]) => ({
        ...lvl2,
        children: Array.from(lvl2.children).map(([, lvl3]) => ({
          ...lvl3,
          children: [],
        })),
      })),
    }));

    return result;
  },
  detail: (menuDetail: MenuDetailRes) => transformAPIKeyToCamel(menuDetail),
  salePageList: (res: SalePageListRes) => transformAPIKeyToCamel(res),
};

export default normalizeMenu;
