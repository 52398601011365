import brandOperationApi, { BOStockList, BOStockListParams } from "@api/brandOperationApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface IState {
  isFetching: boolean;
  BOStockList: BOStockList;
  BOstockListParams: BOStockListParams;
}
const initialState: IState = {
  isFetching: false,
  BOStockList: {
    count: 0,
    next: "",
    previous: "",
    totalStock: 0,
    totalAmount: 0,
    results: [],
  },
  BOstockListParams: {
    no: undefined,
    skus: undefined,
    storageType: undefined,
    nameQ: undefined,
    limit: 20,
    offset: 0,
  },
};

export const fetchBOStockList = createAsyncThunk("BOStockSlice/fetchBOStockList", async (_, thunkApi) => {
  const {
    BOStockSlice: { BOstockListParams },
  } = thunkApi.getState() as RootState;
  const response = await brandOperationApi.fetchBOStockList(BOstockListParams);
  return response;
});

export const exportODListCSV = createAsyncThunk("BOStockSlice/exportODListCSV", async (_, thunkApi) => {
  const {
    BOStockSlice: { BOstockListParams },
  } = thunkApi.getState() as RootState;
  const { skus, nameQ, storageType } = BOstockListParams;
  const params = { skus, nameQ, storageType };
  const response = await brandOperationApi.exportBOStockListCSV(params);
  return response;
});

const BOStockSlice = createSlice({
  name: "BOStockSlice",
  initialState,
  reducers: {
    resetBOStockSlice: () => initialState,
    updateBOStockParams: (state, action) => {
      state.BOstockListParams = action.payload;
    },
    resetBOStockParams: (state) => {
      state.BOstockListParams = initialState.BOstockListParams;
    },
    resetBOStockList: (state) => {
      state.BOStockList = initialState.BOStockList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBOStockList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBOStockList.fulfilled, (state, action) => {
      state.BOStockList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchBOStockList.rejected, (state, action) => {
      state.isFetching = false;
    });
  },
});
export const BOStockState = (state: RootState) => state.BOStockSlice;
export const { resetBOStockSlice, updateBOStockParams, resetBOStockParams, resetBOStockList } = BOStockSlice.actions;
export default BOStockSlice.reducer;
