import { addContact, updateContact } from "@redux/vendorSlice";
import { Button, Form, Input, Modal } from "antd";
import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { VendorContact } from "./interface";

const WrapperForm = styled(Form)`
  padding: 32px 28px 20px 28px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin-top: auto;
  text-align: right;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const StyledInput = styled(Input)`
  width: 395px;
`;
const Row = styled.div`
  margin-right: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Title = styled.div`
  font-size: 14px;
  align-self: flex-start;
  line-height: 32px;
`;
const Red = styled.span`
  color: red;
`;

interface Props {
  isModalVisible: boolean;
  currentContact?: VendorContact;
  toggleContactModal: () => void;
}

const ContactModal: FC<Props> = (props) => {
  const { isModalVisible, toggleContactModal, currentContact } = props;
  const { vendorId } = useParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<VendorContact>();

  useEffect(() => {
    if (currentContact) {
      form.setFieldsValue({
        id: currentContact.id,
        name: currentContact.name,
        phone: currentContact.phone,
        email: currentContact.email,
        title: currentContact.title,
        isMajor: currentContact.isMajor,
      });
    }
  }, [currentContact, form]);

  function handleOk() {
    const contact = form.getFieldsValue();

    if (currentContact) {
      dispatch(updateContact({ ...contact, id: currentContact.id }));
    } else {
      const params = {
        contactInfo: contact!,
        vendorId,
      };
      dispatch(addContact(params));
    }
    toggleContactModal();
  }

  function handleCancel() {
    toggleContactModal();
  }

  return (
    <Modal
      visible={isModalVisible}
      width={609}
      bodyStyle={{ height: 312, padding: 0 }}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <WrapperForm<any>
        form={form}
        name="basic"
        initialValues={{
          name: currentContact?.name,
          phone: currentContact?.phone,
          email: currentContact?.email,
          position: currentContact?.title,
        }}
        onFinish={handleOk}
      >
        <Row>
          <Title>
            姓名<Red>*</Red>
          </Title>
          <Form.Item name="name" rules={[{ required: true, message: "" }]}>
            <StyledInput />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            電話<Red>*</Red>
          </Title>
          <Form.Item name="phone" rules={[{ required: true, message: "" }]}>
            <StyledInput />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            信箱<Red>*</Red>
          </Title>
          <Form.Item name="email" rules={[{ required: true, message: "" }]}>
            <StyledInput />
          </Form.Item>
        </Row>
        <Row>
          <Title>職稱</Title>
          <Form.Item name="title" noStyle>
            <StyledInput />
          </Form.Item>
        </Row>
        <ButtonContainer>
          <CancelButton type="default" onClick={handleCancel}>
            取消
          </CancelButton>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </ButtonContainer>
      </WrapperForm>
    </Modal>
  );
};

export default ContactModal;
