import { AddDeductionConfig, UpdateDeductionConfig } from "@api/contractApi";
import { Deduction, deductionTypeList } from "@api/utils/normalizeContract";
import PopupBackground from "@component/PopupBackground";
import { fetchAddDeduction, fetchUpdateDeduction, resetEditStatus } from "@redux/contractSlice";
import { RootState } from "@redux/rootReducer";
import MathRound from "@utils/MathRound";
import { Button, Form, Input, message, Select } from "antd";
import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const { TextArea } = Input;

const Wrapper = styled(Form)`
  padding: 40px 27px 28px 32px;
  width: 800px;
  height: 500px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};

  .ant-form-item-no-colon {
    width: 107px;
  }

  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${({ justify }) => justify};
`;

const CustomInput = styled(Input)<{ width: string }>`
  width: ${({ width }) => width};
`;

const CustomSelect = styled(Select)<{ width: string }>`
  &&& {
    margin-right: 41px;
    width: ${({ width }) => width};
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const Text = styled.span`
  margin: 6px 0 0 10px;
  ${fontStyle("14px", "22px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;

const monthNames: { [month: string]: number } = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

type Props = {
  deduction: Deduction | undefined;
  close: () => void;
};

const rule = [{ required: true, message: "必填" }];

const contractSlice = (state: RootState) => state.contract;

export default function EditDeductionModal(props: Props) {
  const { deduction, close } = props;

  const dispatch = useDispatch();
  const { isDeductEditDone } = useSelector(contractSlice);

  const [form] = Form.useForm();
  const { contractId } = useParams();

  const monthList = useMemo(() => {
    return Object.keys(monthNames).map((month, idx) => ({ label: idx + 1, value: month }));
  }, []);

  const dateList = useCallback((month: number) => {
    const date = new Date();
    const currentYear = date.getFullYear();
    const totalDays = new Date(currentYear, month, 0).getDate();

    return Array(totalDays)
      .fill(null)
      .map((_, idx) => ({ label: idx + 1, value: idx + 1 }));
  }, []);

  const onSubmit = (values: any) => {
    if (!deduction) {
      const payload: AddDeductionConfig = {
        description: values.description,
        deductRate: MathRound(values.deductRate, 1),
        deductType: values.deductType,
        periodStartMonth: values.periodStartMonth,
        periodStartDay: values.periodStartDay,
        periodEndMonth: values.periodEndMonth,
        periodEndDay: values.periodEndDay,
        contractId: parseInt(contractId, 10),
      };
      dispatch(fetchAddDeduction(payload));
    } else {
      const payload: UpdateDeductionConfig = {
        description: values.description,
        deductRate: MathRound(values.deductRate, 1),
        periodStartMonth: values.periodStartMonth,
        periodStartDay: values.periodStartDay,
        periodEndMonth: values.periodEndMonth,
        periodEndDay: values.periodEndDay,
        deductId: deduction.id,
      };
      dispatch(fetchUpdateDeduction(payload));
    }
  };

  useEffect(() => {
    if (isDeductEditDone) {
      if (deduction) {
        message.success("編輯成功");
      } else {
        message.success("新增成功");
      }
      dispatch(resetEditStatus());
      close();
    }
  }, [dispatch, isDeductEditDone, deduction, close]);

  return (
    <PopupBackground close={close} fixed>
      <Wrapper
        colon={false}
        form={form}
        initialValues={{
          deductType: deduction?.deductType,
          periodStartMonth: deduction?.periodStartMonth,
          periodStartDay: deduction?.periodStartDay,
          periodEndMonth: deduction?.periodEndMonth,
          periodEndDay: deduction?.periodEndDay,
          deductRate: deduction?.deductRate,
          description: deduction?.description,
        }}
        onFinish={onSubmit}
      >
        <Form.Item label="項目名稱" name="deductType" rules={rule}>
          <CustomSelect width="188px" placeholder="請選擇" disabled={!!deduction}>
            {Object.keys(deductionTypeList).map((key) => (
              <Select.Option key={deductionTypeList[parseInt(key, 10)]} value={parseInt(key, 10)}>
                {deductionTypeList[parseInt(key, 10)]}
              </Select.Option>
            ))}
          </CustomSelect>
        </Form.Item>
        <Row>
          <Form.Item label="開始時間(月)" name="periodStartMonth">
            <CustomSelect
              width="188px"
              placeholder="請選擇"
              onChange={() => form.setFieldsValue({ periodStartDay: undefined })}
            >
              {monthList.map((month) => (
                <Select.Option key={month.value} value={month.label}>
                  {month.label}
                </Select.Option>
              ))}
            </CustomSelect>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.periodStartMonth !== currentValues.periodStartMonth}
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="開始時間(日)"
                name="periodStartDay"
                rules={getFieldValue("periodStartMonth") ? rule : []}
              >
                <CustomSelect width="188px" placeholder="請選擇" disabled={!getFieldValue("periodStartMonth")}>
                  {getFieldValue("periodStartMonth") &&
                    dateList(getFieldValue("periodStartMonth")).map((date) => (
                      <Select.Option key={date.value} value={date.value}>
                        {date.label}
                      </Select.Option>
                    ))}
                </CustomSelect>
              </Form.Item>
            )}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.periodStartMonth !== currentValues.periodStartMonth}
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="結束時間(月)"
                name="periodEndMonth"
                rules={
                  getFieldValue("periodStartMonth")
                    ? [
                        ...rule,
                        () => ({
                          validator() {
                            if (getFieldValue("periodStartMonth") > getFieldValue("periodEndMonth")) {
                              return Promise.reject(new Error("結束月份不可小於開始月份"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]
                    : []
                }
              >
                <CustomSelect
                  width="188px"
                  placeholder="請選擇"
                  disabled={!getFieldValue("periodStartMonth")}
                  onChange={() => form.setFieldsValue({ periodEndDay: undefined })}
                >
                  {monthList.map((month) => (
                    <Select.Option key={month.value} value={month.label}>
                      {month.label}
                    </Select.Option>
                  ))}
                </CustomSelect>
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.periodEndMonth !== currentValues.periodEndMonth ||
              prevValues.periodStartMonth !== currentValues.periodStartMonth
            }
          >
            {({ getFieldValue }) => (
              <Form.Item
                label="結束時間(日)"
                name="periodEndDay"
                rules={
                  getFieldValue("periodStartMonth")
                    ? [
                        ...rule,
                        () => ({
                          validator() {
                            if (
                              getFieldValue("periodStartMonth") === getFieldValue("periodEndMonth") &&
                              getFieldValue("periodStartDay") > getFieldValue("periodEndDay")
                            ) {
                              return Promise.reject(new Error("相同月份不可小於開始日期"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]
                    : []
                }
              >
                <CustomSelect width="188px" placeholder="請選擇" disabled={!getFieldValue("periodStartMonth")}>
                  {getFieldValue("periodEndMonth") &&
                    dateList(getFieldValue("periodEndMonth")).map((date) => (
                      <Select.Option key={date.value} value={date.value}>
                        {date.label}
                      </Select.Option>
                    ))}
                </CustomSelect>
              </Form.Item>
            )}
          </Form.Item>
        </Row>
        <Row>
          <Form.Item label="抽成比例" name="deductRate" rules={rule}>
            <CustomInput type="number" width="188px" />
          </Form.Item>
          <Text>%</Text>
        </Row>
        <Form.Item label="說明" name="description">
          <TextArea autoSize={{ minRows: 7, maxRows: 7 }} />
        </Form.Item>
        <Form.Item>
          <Row justify="flex-end">
            <CustomButton onClick={close}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Row>
        </Form.Item>
      </Wrapper>
    </PopupBackground>
  );
}
