import BankAPI from "@api/bankApi";
import CustomError from "@api/utils/CustomeError";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Bank } from "src/types";

export const fetchBankList = createAsyncThunk<
  Bank.Item[],
  undefined,
  {
    rejectValue: CustomError;
  }
>("bank/fetchList", async (_, thunkAPI) => {
  try {
    const resp = await BankAPI.getBankCode();

    return resp;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface SliceState {
  bankList: Bank.Item[];
}

const bankSlice = createSlice({
  name: "bank",
  initialState: {
    bankList: [],
  } as SliceState,
  reducers: {
    addBank: (state, action: PayloadAction<Bank.Item>) => {
      state.bankList = [...state.bankList, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBankList.fulfilled, (state, action) => {
      state.bankList = [...action.payload];
    });
  },
});

export const { addBank } = bankSlice.actions;
export default bankSlice.reducer;
