import React, { FC, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import SocialTypeValue from "@constant/SocialTypeValue";
import { socialLogin, updateIsLogin, updateMemberAccess } from "@redux/authSlice";
import { SocialLoginParams } from "@api/adminApi";
import cookie from "@utils/Cookie";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { MEMBER_TOKEN } from "@constant/StorageKeyValue";
import { useAppDispatch } from "src/store";
import googleIconSvg from "./google-icon.svg";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 64px);
`;
const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 260px;
  height: 50px;
  box-shadow: rgb(0 0 0 / 24%) 0px 0px 2px 1px;

  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }

  &:active {
    cursor: pointer;
    background-color: #eeeeee;
    color: rgba(0, 0, 0, 0.54);
    opacity: 1;
  }
`;
const Icon = styled.img`
  margin-right: 16px;
`;
const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.7);
`;

const AuthGate: FC = ({ children }) => {
  const dispatch = useAppDispatch();
  const { isLogin } = useSelector((state: RootState) => state.auth);

  const [initialLoginChecked, setInitialLoginChecked] = useState<boolean>(false);

  useEffect(() => {
    const hasTokenInfo = cookie.getItem(MEMBER_TOKEN);
    dispatch(updateIsLogin(hasTokenInfo));
    setInitialLoginChecked(true);
    if (hasTokenInfo) {
      dispatch(updateMemberAccess(hasTokenInfo.staffPermission));
    }
  }, [dispatch]);

  const handleOnGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    const auth = getAuth();

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        const token = credential.idToken || "";
        const socialLoginParams: SocialLoginParams = {
          provider: SocialTypeValue.GOOGLE,
          socialInfo: {
            socialAccessToken: token,
          },
        };
        dispatch(socialLogin(socialLoginParams));
      }
    } catch {
      // handle error
    }
  };

  if (!initialLoginChecked) return null;
  if (isLogin) return children as ReactElement;
  return (
    <Wrapper>
      <GoogleButton onClick={handleOnGoogleLogin}>
        <Icon src={googleIconSvg} alt="google-log-in" />
        <Text>以 Google 繼續</Text>
      </GoogleButton>
    </Wrapper>
  );
};

export default AuthGate;
