import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface FetchDiscountFrameListParam {
  limit: number;
  offset: number;
  titleQ?: string;
  pageId?: string;
  startAtAfter?: string;
  startAtBefore?: string;
  endAtAfter?: string;
  endAtBefore?: string;
}

export interface DiscountFrameListResult {
  count: number;
  next: string;
  previous: string;
  results: DiscountFrameListItem[];
}
export interface DiscountFramePlanListResult {
  count: number;
  next: string;
  previous: string;
  results: DiscountFrameTabItem[];
}
export interface DiscountFrameSalePageListResult {
  count: number;
  next: string;
  previous: string;
  results: DiscountFrameTabItem[];
}

export interface DiscountFrameListItem {
  id: number;
  title: string;
  startAt: string;
  endAt: string;
  imagePath: string;
}

export interface CreateFrameInfoParams {
  title: string;
  imageDirPrefix: string | null;
  imageFilePath: string | null;
  startAt: string;
  endAt: string;
}
export interface DiscountFrameInfo {
  id: number;
  title: string;
  imagePath: string;
  mediaId: number;
  startAt: string;
  endAt: string;
}

export interface DiscountFrameTabItem {
  id: number;
  objectId: number;
  objectName: string;
  originalImagePath: string;
  compositeImagePath: string;
  salesPageId: number;
}
export interface TabListParams {
  id?: number;
  objectId?: number;
  objectName?: string;
  module: string;
  limit: number;
  offset: number;
}

export interface TabSearchListParams {
  objectId?: number;
  objectName?: string;
  module: string;
}
export interface SalePlanListResult {
  count: number;
  next: string;
  previous: string;
  results: SalePlanListItem[];
}
export interface SalePlanListItem {
  id: number;
  sku: string;
  name: string;
  pageName: string;
  price: number;
  brands: string[];
  options: { vpc: { sku: string } }[];
}
export interface SalePlanListParams {
  salesPage?: number;
  pageNameQ?: string;
  promotionId?: number;
  skus?: string;
  tagIds?: string;
  brandNamesQ?: string;
  categoryIds?: number[];
  limit: number;
  offset: number;
}

export enum DiscountModule {
  PLAN = "salesplan",
  SALEPAGE = "salespage",
}

const apiClient = new CoreAPI();

interface IDiscountFrameApi {
  fetchDiscountFrameList: (params: FetchDiscountFrameListParam) => Promise<DiscountFrameListResult>;
  createDiscountFrame: (payload: CreateFrameInfoParams) => Promise<DiscountFrameInfo>;
  updateDiscountFrameInfo: (id: number, payload: CreateFrameInfoParams) => Promise<DiscountFrameInfo>;
  fetchDiscountFrameInfo: (frameId: number) => Promise<DiscountFrameInfo>;
  batchDeleteDiscountFrame: (selectedFrameIds: number[]) => Promise<StandardResponse>;
  fetchTabList: (frameId: number, params: TabListParams) => Promise<DiscountFramePlanListResult>;
  updateDiscountTabList: (frameId: number, params: { objectId: number; module: string }) => Promise<StandardResponse>;
  batchUpdateDiscountTabList: (
    frameId: number,
    params: { objectIds: number[]; module: string },
  ) => Promise<StandardResponse>;
  batchDeleteTabList: (frameId: number, module: DiscountModule, selectedPlanIds: number[]) => Promise<StandardResponse>;
  batchAddByCSV: (payload: { module: string; file: File; frameId: number }) => Promise<StandardResponse>;
  fetchSalePlanListResult: (params: FetchDiscountFrameListParam) => Promise<SalePlanListResult>;
}

const discountFrameApi: IDiscountFrameApi = {
  fetchDiscountFrameList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/sales/ad-image-activity/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createDiscountFrame: async (payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData("/manage/sales/ad-image-activity/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  updateDiscountFrameInfo: async (id, payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(`/manage/sales/ad-image-activity/${id}/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchDiscountFrameInfo: async (frameId) => {
    const response = await apiClient.getData(`/manage/sales/ad-image-activity/${frameId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  batchDeleteDiscountFrame: async (selectedFrameIds) => {
    const params = {
      ad_image_frame_ids: selectedFrameIds,
    };
    const response = await apiClient.putData("/manage/sales/ad-image-activity/batch-delete/", params);
    return response.data;
  },

  fetchTabList: async (frameId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(`/manage/sales/ad-image-activity/${frameId}/relation/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },

  updateDiscountTabList: async (frameId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.postData(`/manage/sales/ad-image-activity/${frameId}/relation/`, getParams);
    return response.data;
  },
  batchUpdateDiscountTabList: async (frameId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.postData(
      `/manage/sales/ad-image-activity/${frameId}/relation/batch-create/`,
      getParams,
    );
    return response.data;
  },

  batchDeleteTabList: async (frameId, module, selectedTabIds) => {
    const params = {
      ad_frame_image_relation_ids: selectedTabIds,
      module,
    };
    const response = await apiClient.putData(
      `/manage/sales/ad-image-activity/${frameId}/relation/batch-delete/`,
      params,
    );
    return response.data;
  },

  batchAddByCSV: async (payload) => {
    const { module, file, frameId } = payload;
    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("module", module);
    const response = await apiClient.postData(
      `/manage/sales/ad-image-activity/${frameId}/relation/csv-upload/`,
      formData,
    );
    return transformAPIKeyToCamel(response.data);
  },
  fetchSalePlanListResult: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/sales/plans/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default discountFrameApi;
