import { FetchVendorListParam } from "@api/productApi";
import BDList from "@component/BDList";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import {
  clearVendorFilter,
  fetchFilterVendorList,
  fetchVendorList,
  loadMoreFilterVendorList,
  updateVendorListParams,
} from "@redux/vendorSlice";
import { Button, Col, DatePicker, Row, Select, Space } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { ProfitsStatus, ReceivedStatus } from "./interface";

const { RangePicker } = DatePicker;

const Wrapper = styled.form`
  padding: 0 24px;
  margin: 20px 0;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

interface LocalFilter {
  owner: number;
  nameQ?: string;
  startDate: [Moment | null, Moment | null];
  endDate: [Moment | null, Moment | null];
  profitStatus?: ProfitsStatus | -1;
  received?: ReceivedStatus | -1;
}

const VendorFilter: FC = () => {
  const dispatch = useAppDispatch();
  const { vendorListParams, filterVendorListResult } = useSelector((state: RootState) => state.vendorSlice);

  const [filters, setFilters] = useState<LocalFilter>({
    owner: -1,
    nameQ: undefined,
    startDate: [null, null],
    endDate: [null, null],
    profitStatus: -1,
    received: -1,
  });

  useEffect(() => {
    const {
      startDateAfter,
      startDateBefore,
      endDateAfter,
      endDateBefore,
      nameQ,
      owner,
      profitStatus,
      received,
    } = vendorListParams;
    const formatedStartDateAfter = startDateAfter ? moment(startDateAfter) : null;
    const formatedStartDateBefore = startDateBefore ? moment(startDateBefore) : null;
    const formatedEndDateAfter = endDateAfter ? moment(endDateAfter) : null;
    const formatedEndDateBefore = endDateBefore ? moment(endDateBefore) : null;

    setFilters({
      owner: owner || -1,
      nameQ,
      startDate: [formatedStartDateAfter, formatedStartDateBefore],
      endDate: [formatedEndDateAfter, formatedEndDateBefore],
      profitStatus,
      received,
    });
  }, [vendorListParams]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreFilterVendorList());
  }, [dispatch]);

  const vendorOptions = useMemo(() => {
    const { next, results } = filterVendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.name}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [filterVendorListResult, handleOnVendorLoadMore]);

  function onVendorChange(value: SelectValue) {
    setFilters({
      ...filters,
      nameQ: value as string,
    });
  }

  function onOwnerChange(value: any) {
    setFilters({
      ...filters,
      owner: value,
    });
  }

  function onStartDateChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        startDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        startDate: [null, null],
      }));
    }
  }

  function onEndDateChange(date: any) {
    if (date) {
      setFilters((prev) => ({
        ...prev,
        endDate: date,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        endDate: [null, null],
      }));
    }
  }

  function onProfitsStatusChange(status: number) {
    setFilters((prev) => ({
      ...prev,
      profitStatus: status === -1 ? undefined : status,
    }));
  }

  function onReceivedStatusChange(status: number) {
    setFilters((prev) => ({
      ...prev,
      received: status === -1 ? undefined : status,
    }));
  }

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchFilterVendorList(value));
  }, 300);

  useEffect(() => {
    dispatch(fetchFilterVendorList(""));
  }, [dispatch]);

  function handleFilter(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const { startDate, endDate, nameQ, owner, profitStatus, received } = filters;
    const filterParams: FetchVendorListParam = {
      limit: 20,
      offset: 0,
      nameQ,
      owner: owner < 0 ? undefined : owner,
      startDateAfter: startDate[0] ? moment(startDate[0]).format("YYYY-MM-DD") : undefined,
      startDateBefore: startDate[1] ? moment(startDate[1]).format("YYYY-MM-DD") : undefined,
      endDateAfter: endDate[0] ? moment(endDate[0]).format("YYYY-MM-DD") : undefined,
      endDateBefore: endDate[1] ? moment(endDate[1]).format("YYYY-MM-DD") : undefined,
      profitStatus: profitStatus === -1 ? undefined : profitStatus,
      received: received === -1 ? undefined : received,
    };

    dispatch(updateVendorListParams(filterParams));
    dispatch(fetchVendorList());
  }

  function clear() {
    dispatch(clearVendorFilter());
  }

  return (
    <Wrapper onSubmit={handleFilter}>
      <Row>
        <Col span={10}>
          <Row>
            <Space size={20}>
              <Title>廠商名稱</Title>
              <Select
                showSearch
                filterOption={false}
                style={{ width: 300 }}
                placeholder="請選擇"
                onChange={onVendorChange}
                onSearch={handleOnVendorSearch}
                value={filters.nameQ}
              >
                {vendorOptions}
              </Select>
            </Space>
          </Row>
          <Row style={{ marginTop: "14px" }}>
            <Space size={20}>
              <Title>建立時間</Title>
              <RangePicker
                style={{ width: 300 }}
                locale={locale}
                value={filters.startDate}
                onChange={onStartDateChange}
              />
            </Space>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <Space size={34}>
              <Title>商開負責人</Title>
              <BDList value={filters.owner} onChange={onOwnerChange} />
            </Space>
          </Row>
          <Row style={{ marginTop: "14px" }}>
            <Space size={50}>
              <Title>終止時間</Title>
              <RangePicker style={{ width: 300 }} locale={locale} value={filters.endDate} onChange={onEndDateChange} />
            </Space>
          </Row>
        </Col>
        <Col span={24} style={{ marginTop: "14px" }}>
          <Space size={20}>
            <Title>利潤轉換審核狀態</Title>
            <Select
              defaultValue={-1}
              style={{ width: 325 }}
              value={filters.profitStatus}
              onChange={onProfitsStatusChange}
            >
              <Select.Option value={-1} disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ProfitsStatus.PENDING}>待審核</Select.Option>
              <Select.Option value={ProfitsStatus.APPROVE}>審核通過</Select.Option>
              <Select.Option value={ProfitsStatus.REJECT}>審核未通過</Select.Option>
            </Select>
          </Space>
          <Space size={20} style={{ marginLeft: 20 }}>
            <Title>保證金儲值入帳</Title>
            <Select defaultValue={-1} style={{ width: 180 }} value={filters.received} onChange={onReceivedStatusChange}>
              <Select.Option value={-1} disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ReceivedStatus.NOT_CREDITED}>未入帳</Select.Option>
              <Select.Option value={ReceivedStatus.BILLED}>已入帳</Select.Option>
            </Select>
          </Space>
        </Col>
      </Row>
      <Row justify="end" style={{ marginTop: 17 }}>
        <Space>
          <Button type="default" size="middle" onClick={clear}>
            清除篩選條件
          </Button>
          <Button type="primary" size="middle" htmlType="submit">
            套用
          </Button>
        </Space>
      </Row>
    </Wrapper>
  );
};

export default VendorFilter;
