import React, { FC } from "react";
import PopupBackground from "@component/PopupBackground";
import styled from "styled-components";
import { Form, Input, Button, Select } from "antd";
import { updateSupplyChainContact, createSupplyChainContact } from "@redux/supplyChainSlice";
import { useAppDispatch } from "src/store";
import {
  SupplyChainContactDetail,
  UpdateSupplyChainContactParam,
  CreateSupplyChainContactParam,
} from "@api/supplyChainApi";

interface Props {
  onClose: () => void;
  currentData: SupplyChainContactDetail | null;
  supplyChainId: number;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
`;
const Row = styled.div`
  display: flex;
`;
const RowTitle = styled.div<{ width: number }>`
  white-space: nowrap;
  width: ${({ width }) => width}px;
  align-self: flex-start;
  text-align: left;
  margin-right: 15px;
  line-height: 32px;
  font-size: 14px;
`;
const RequiredIcon = styled.span`
  color: ${({ theme }) => theme.colorSecondary500};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const ContactPopup: FC<Props> = (props) => {
  const [form] = Form.useForm();
  const { onClose, currentData, supplyChainId } = props;
  const dispatch = useAppDispatch();

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    if (currentData) {
      const params: UpdateSupplyChainContactParam = {
        id: currentData.id,
        name: formValues.name,
        phone: formValues.phone,
        email: formValues.email,
        title: formValues.title,
        supplyChain: supplyChainId,
      };
      dispatch(updateSupplyChainContact(params));
    } else {
      const createParams: CreateSupplyChainContactParam = {
        name: formValues.name,
        phone: formValues.phone,
        email: formValues.email,
        title: formValues.title,
        supplyChain: supplyChainId,
      };
      dispatch(createSupplyChainContact(createParams));
    }
    onClose();
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form initialValues={currentData ?? undefined} form={form} onFinish={handleOnSubmit}>
          <Row>
            <RowTitle width={30}>
              姓名
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={30}>
              電話
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="phone" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={30}>
              信箱
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="email" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={30}>
              職稱
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="title" rules={[{ required: true, message: "" }]}>
                <Select>
                  <Select.Option value="業務">業務</Select.Option>
                  <Select.Option value="出貨單位">出貨單位</Select.Option>
                  <Select.Option value="財務">財務</Select.Option>
                  <Select.Option value="業務助理">業務助理</Select.Option>
                </Select>
              </Form.Item>
            </RowContent>
          </Row>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
};

export default ContactPopup;
