import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

interface NutritionApi {
  fetchLabelList: () => Promise<LabelList>;
  fetchSummaryUnitList: () => Promise<[]>;
  fetchAddLabel: (params: { label: string; unit: string; dialyIntake: string; filterEnabled: boolean }) => Promise<any>;
  fetchNutritionSummary: (productId: string) => Promise<NutritionSummary>;
  fetchAddNutritionSummary: (params: {
    productId: string;
    servingSize?: number;
    perServing?: number;
    perServingUnit?: string;
    servingPerContainer: number;
  }) => Promise<NutritionSummary>;
  fetchUpdateNutritionSummary: (params: {
    productId: string;
    servingSize?: number;
    perServing?: number;
    perServingUnit?: string;
    servingPerContainer: number;
    summaryId: number;
  }) => Promise<NutritionSummary>;
  fetchNutritionRelation: (productId: string) => Promise<NutritionRelations>;
  fetchUpdateNutritionRelation: (params: {
    productId: string;
    data: {
      id: number | null;
      labelId: number;
      amountPerServing: number;
      rank: number;
    }[];
  }) => Promise<NutritionSummary>;
}

export interface LabelList {
  count: number;
  next: string | null;
  previous: string | null;
  results: LabelResult[];
}
export interface LabelResult {
  id: number;
  label: string;
  unit: string;
  dailyIntake: number;
  tag: number;
}

export interface NutritionRelation {
  key?: string | number;
  id: number;
  nutritionLabel: {
    id: number;
    label: string;
    unit: string;
    dailyIntake: number;
    tag: number;
  };
  amountPerServing: number;
  rank: number;
}

export interface NutritionSummary {
  count: number;
  next: string | null;
  previous: string | null;
  results: SummaryResult[];
}
export interface NutritionRelations {
  count: number;
  next: string | null;
  previous: string | null;
  results: NutritionRelation[];
}

export interface SummaryResult {
  id: number;
  servingSize: number;
  perServing: number;
  perServingUnit: string;
  servingPerContainer: number;
  createdAt: string;
  updatedAt: string;
  product: number;
}

const nutritionApi: NutritionApi = {
  fetchLabelList: async () => {
    const params = {
      limit: 10000,
    };
    const response = await apiClient.getData("/manage/product/nutrition-labels/", params);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSummaryUnitList: async () => {
    const response = await apiClient.getData("/manage/product/nutrition-summary/units/", {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddLabel: async (params) => {
    const response = await apiClient.postData("/manage/product/nutrition-labels/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchNutritionSummary: async (productId) => {
    const response = await apiClient.getData(`/manage/product/products/${productId}/nutrition-summary/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddNutritionSummary: async (params) => {
    const payload = {
      servingSize: params.servingSize,
      servingPerContainer: params.servingPerContainer,
      perServing: params.perServing,
      perServingUnit: params.perServingUnit,
    };
    const response = await apiClient.postData(
      `/manage/product/products/${params.productId}/nutrition-summary/`,
      transformCamelToSnake(payload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateNutritionSummary: async (params) => {
    const payload = {
      servingSize: params.servingSize,
      servingPerContainer: params.servingPerContainer,
      perServing: params.perServing,
      perServingUnit: params.perServingUnit,
    };
    const response = await apiClient.putData(
      `/manage/product/products/${params.productId}/nutrition-summary/${params.summaryId}/`,
      transformCamelToSnake(payload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchNutritionRelation: async (productId) => {
    const response = await apiClient.getData(`/manage/product/products/${productId}/nutrition-relations/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateNutritionRelation: async (params) => {
    const response = await apiClient.postData(
      `/manage/product/products/${params.productId}/nutrition-relations/batch/`,
      transformCamelToSnake({ labels: params.data }),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default nutritionApi;
