import React, { FC } from "react";
import { Select } from "antd";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";

const { Option } = Select;

interface Props {
  value?: number;
  onChange?: (value: number) => void;
}

const StaffList: FC<Props> = (props) => {
  const { value, onChange } = props;
  const staffs = useSelector((state: RootState) => state.supplyChain.staffList.results);

  const triggerChange = (selectedId: number) => {
    if (onChange) {
      onChange(selectedId);
    }
  };
  return (
    <Select style={{ width: 120 }} onChange={triggerChange} value={value}>
      <Option value={-1} key="bd-1">
        請選擇
      </Option>
      {staffs.map((staff) => (
        <Option value={staff.id} key={staff.id}>
          {staff.name}
        </Option>
      ))}
    </Select>
  );
};

export default StaffList;
