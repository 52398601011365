import React, { FC } from "react";
import { useAppDispatch } from "src/store";
import {
  ShippingFeeListItem,
  ShippingTypeValueByNumber,
  CreateShippingFeeListParam,
  UpdateShippingFeeListParam,
} from "@api/shippingFeeApi";
import styled from "styled-components";
import { Button, Form, Input, Select, DatePicker } from "antd";
import moment from "moment";
import { createShippingFeeItem, updateShippingFeeItem } from "@redux/shippingFeeSlice";
import PopupBackground from "@component/PopupBackground";

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  currentData: ShippingFeeListItem | null;
  setCurrentData: React.Dispatch<React.SetStateAction<ShippingFeeListItem | null>>;
}

const statusList = [
  {
    label: "上架",
    value: 1,
  },
  {
    label: "下架",
    value: 0,
  },
];

export const logisticMethod = [
  { label: "宅配", value: ShippingTypeValueByNumber.HOME_DELIVERY },
  { label: "7-11", value: ShippingTypeValueByNumber.SEVEN_ELEVEN },
  { label: "全家", value: ShippingTypeValueByNumber.CVS },
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: white;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;
const Row = styled.div`
  display: flex;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  text-align: left;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const AddPopup: FC<Props> = (props) => {
  const { onClose, onConfirm, currentData, setCurrentData } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<{
    name: string;
    isActive: boolean;
    startAt: moment.Moment | null;
    endAt: moment.Moment | null;
    shippingThreshold: number;
    shippingFee: number;
    logisticMethod: number;
  }>();
  const onStartDateChange = (date: moment.Moment | null) => {
    if (date) {
      form.setFieldsValue({
        startAt: date,
      });
    }
  };
  const onEndDateChange = (date: moment.Moment | null) => {
    if (date) {
      form.setFieldsValue({
        endAt: date,
      });
    }
  };

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    if (currentData) {
      const params: UpdateShippingFeeListParam = {
        id: currentData.id,
        name: formValues.name,
        isActive: formValues.isActive,
        startAt: formValues.startAt ? formValues.startAt.format("YYYY-MM-DD") : "",
        endAt: formValues.endAt ? formValues.endAt.format("YYYY-MM-DD") : "",
        shippingThreshold: formValues.shippingThreshold,
        shippingFee: formValues.shippingFee,
        logisticMethod: formValues.logisticMethod,
      };
      dispatch(updateShippingFeeItem(params));
      setCurrentData(null);
      onConfirm();
    } else {
      const createParams: CreateShippingFeeListParam = {
        name: formValues.name,
        isActive: formValues.isActive,
        startAt: formValues.startAt ? formValues.startAt.format("YYYY-MM-DD") : "",
        endAt: formValues.endAt ? formValues.endAt.format("YYYY-MM-DD") : "",
        shippingThreshold: formValues.shippingThreshold,
        shippingFee: formValues.shippingFee,
        logisticMethod: formValues.logisticMethod,
      };
      dispatch(createShippingFeeItem(createParams));
      onConfirm();
    }
  };

  const formatedCurrentData = currentData && {
    ...currentData,
    endAt: moment(currentData.endAt),
    startAt: moment(currentData.startAt),
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form initialValues={formatedCurrentData ?? undefined} form={form} onFinish={handleOnSubmit}>
          <Row>
            <RowTitle>
              規則名稱
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <Input placeholder="請輸入" allowClear />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>
              狀態<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={188}>
              <Form.Item name="isActive" rules={[{ required: true, message: "" }]}>
                <StyledSelect options={statusList} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>
              開始時間<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <Form.Item name="startAt" rules={[{ required: true, message: "" }]}>
              <DatePicker onChange={onStartDateChange} placeholder="請選擇日期" style={{ width: "188px" }} />
            </Form.Item>
          </Row>
          <Row>
            <RowTitle>
              結束時間<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <Form.Item name="endAt" rules={[{ required: true, message: "" }]}>
              <DatePicker onChange={onEndDateChange} placeholder="請選擇日期" style={{ width: "188px" }} />
            </Form.Item>
          </Row>
          <Row>
            <RowTitle>
              運費
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={188}>
              <Form.Item name="shippingFee" rules={[{ required: true, message: "" }]}>
                <Input placeholder="請輸入金額" />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>
              免運門檻
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={188}>
              <Form.Item name="shippingThreshold" rules={[{ required: true, message: "" }]}>
                <Input placeholder="請輸入金額" />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>
              物流<RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={188}>
              <Form.Item name="logisticMethod" rules={[{ required: true, message: "" }]}>
                <StyledSelect options={logisticMethod} />
              </Form.Item>
            </RowContent>
          </Row>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddPopup;
