import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import UploadImageButton from "@component/UploadImageButton";
import {
  CategoryStatus,
  clearCategoryInfo,
  fetchCategoryInfo,
  fetchCreateCategory,
  fetchTopicCategories,
  fetchUpdateCategory,
  topicState,
} from "@redux/topicSlice";
import { Button, Checkbox, Form, Input, message, Modal, Select, Spin, Switch } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSearchParam } from "react-use";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { showRequiredFieldsWarning } from "@utils/commonMessage";

const { TextArea } = Input;
const { Item } = Form;

const Title = styled.div`
  padding-bottom: 12px;
  margin-left: 22px;
  margin-bottom: 16px;
  border-bottom: solid 1px ${({ theme }) => theme.colorNeutral300};
  ${fontStyle("16px", "16px")};
`;

const CustomInput = styled(Input)<{ width?: string; mrleft?: string }>`
  width: ${({ width }) => width};
  margin-left: ${({ mrleft }) => mrleft};
`;

const CustomItem = styled(Item)`
  margin-left: 22px;
`;

const Footer = styled.div`
  width: calc(100% - 325px - 208px);
  position: fixed;
  bottom: 0;
  right: 25px;
  padding: 12px;
  text-align: right;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const SortScope = styled.div`
  padding: 10px 0 51px 0;
`;

const Scope = styled.div`
  position: relative;
`;

const InputAbsolute = styled(CustomInput)`
  position: absolute;
  left: 31%;
  width: 59.5%;
  top: 0;
`;

const sortingMethod = ["熱銷", "推薦", "最新", "價格(低至高)", "價格(高至低)"];

export default function SetupTopic() {
  const dispatch = useDispatch();
  const { categoryInfo, categoryParentId, categoryStatus, topicInfo, isFetching } = useSelector(topicState);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const categoryId = useSearchParam("category");

  const [form] = Form.useForm();

  const [localSortMethods, setLocalSortMethods] = useState<CheckboxValueType[]>([]);

  const setSortMethodList = useCallback(() => {
    if (!categoryInfo) {
      setLocalSortMethods(sortingMethod);
    } else {
      const sortMethods =
        categoryInfo.pageInfo?.sortMethods.filter((method) => method.isActive).map((method) => method.name) || [];
      setLocalSortMethods(sortMethods);
    }
  }, [categoryInfo]);

  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onSubmit = (values: any) => {
    const resultSortMethods = new Map();

    sortingMethod.forEach((method) => {
      resultSortMethods.set(method, { name: method, isActive: false });
    });

    localSortMethods.forEach((method) => {
      resultSortMethods.set(method, { name: method, isActive: true });
    });

    if (form.getFieldValue("defaultSortMethod")) {
      resultSortMethods.set(form.getFieldValue("defaultSortMethod"), {
        name: form.getFieldValue("defaultSortMethod"),
        isActive: true,
      });
    }

    const sortMethods = Array.from(resultSortMethods.values());

    if (categoryId && categoryId !== "new") {
      dispatch(fetchUpdateCategory({ categoryId: parseInt(categoryId, 10), data: { ...values, sortMethods } }));
    } else {
      dispatch(fetchCreateCategory({ ...values, sortMethods, parentId: categoryParentId }));
    }
  };

  useEffect(() => {
    if (categoryInfo) {
      const defaultSortMethod = categoryInfo.pageInfo?.sortMethods.find((method) => method.isDefault);
      form.setFieldsValue({
        name: categoryInfo.name,
        iconUrl: categoryInfo.icon,
        iconAlt: categoryInfo.iconAlt,
        imageUrl: categoryInfo.pageInfo?.image?.url || "",
        imageAlt: categoryInfo.pageInfo?.image?.alt || "",
        isActive: categoryInfo.isActive,
        metaTitle: categoryInfo.pageInfo?.metaTitle || "",
        metaKeywords: categoryInfo.pageInfo?.metaKeywords || "",
        metaDescription: categoryInfo.pageInfo?.metaDescription || "",
        defaultSortMethod: defaultSortMethod ? defaultSortMethod.name : "",
        sortMethods: categoryInfo.pageInfo?.sortMethods || [],
      });
    }
  }, [categoryInfo, form]);

  useEffect(() => {
    if (categoryId && categoryId !== "new") {
      dispatch(fetchCategoryInfo(parseInt(categoryId, 10)));
    }

    if (categoryId === "new") {
      dispatch(clearCategoryInfo());
      form.resetFields();
      form.setFieldsValue({
        defaultSortMethod: "推薦",
      });
    }
  }, [categoryId, dispatch, form]);

  useEffect(() => {
    setSortMethodList();
  }, [setSortMethodList]);

  useEffect(() => {
    if (categoryStatus === CategoryStatus.CREATE && categoryInfo) {
      navigate(`${pathname}?category=${categoryInfo.id}`);
      dispatch(fetchTopicCategories({ rootCategoryId: topicInfo!.rootCategory, displayInActive: true }));
    }
  }, [categoryStatus, categoryInfo, pathname, topicInfo, navigate, dispatch]);

  useEffect(() => {
    if (categoryStatus === CategoryStatus.CREATE) {
      message.success("新增成功");
    } else if (categoryStatus === CategoryStatus.UPDATE) {
      message.success("更新成功");
    }
  }, [categoryStatus]);

  return (
    <Spin spinning={isFetching}>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        colon={false}
        onFinish={onSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <CustomItem name="isActive" label="啟用" valuePropName="checked">
          <Switch onChange={(value) => updateForm("isActive", value)} />
        </CustomItem>
        <CustomItem name="name" label="分類名稱" rules={[{ required: true, message: "必填" }]}>
          <Input onChange={(e) => updateForm("name", e.target.value)} />
        </CustomItem>
        <Title>內容</Title>
        <Scope>
          <CustomItem name="imageUrl" label="圖片">
            <UploadImageButton
              hasPreviewAndDelete={!!categoryInfo?.pageInfo?.image?.id}
              imageUrl={form.getFieldValue("imageUrl") || ""}
              finishUpload={(url) => updateForm("imageUrl", url)}
              onDelete={() =>
                Modal.confirm({
                  title: "確定要刪除此圖片嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeMedia({
                      module: DeleteModule.PAGE_INFO,
                      pageInfoId: categoryInfo?.pageInfo.id,
                      mediaId: categoryInfo?.pageInfo.image.id as number,
                    });
                    updateForm("imageUrl", undefined);
                  },
                })
              }
            />
          </CustomItem>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("imageAlt", e.target.value)}
          />
        </Scope>
        <Scope>
          <CustomItem name="iconUrl" label="Icon">
            <UploadImageButton
              hasPreviewAndDelete={!!categoryInfo?.iconId}
              imageUrl={form.getFieldValue("iconUrl") || ""}
              finishUpload={(url) => updateForm("iconUrl", url)}
              onDelete={() =>
                Modal.confirm({
                  title: "確定要刪除此Icon嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeMedia({
                      module: DeleteModule.TOPIC_CATEGORY,
                      topicCategoryId: categoryInfo?.id,
                      mediaId: categoryInfo?.iconId as number,
                    });
                    updateForm("iconUrl", undefined);
                  },
                })
              }
            />
          </CustomItem>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("iconAlt", e.target.value)}
          />
        </Scope>
        <Form.Item name="imageAlt" noStyle />
        <Form.Item name="iconAlt" noStyle />
        <Title>SEO</Title>
        <CustomItem name="metaTitle" label="Meta Title">
          <CustomInput onChange={(e) => updateForm("metaTitle", e.target.value)} />
        </CustomItem>
        <CustomItem name="metaKeywords" label="Meta Keywords">
          <TextArea onChange={(e) => updateForm("metaKeywords", e.target.value)} />
        </CustomItem>
        <CustomItem name="metaDescription" label="Meta Description">
          <TextArea onChange={(e) => updateForm("metaDescription", e.target.value)} />
        </CustomItem>
        <SortScope>
          <Title>Available Product Listing Sort By</Title>
          <CustomItem>
            <Checkbox.Group value={localSortMethods} onChange={(sortList) => setLocalSortMethods(sortList)}>
              {sortingMethod.map((el: any) => (
                <Checkbox value={el} disabled={el === "熱銷"}>
                  {el}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </CustomItem>
          <CustomItem name="defaultSortMethod" label="商品排序預設為">
            <Select onChange={(value) => updateForm("defaultSortMethod", value)}>
              {sortingMethod.map((method: any) => (
                <Select.Option key={method} value={method}>
                  {method}
                </Select.Option>
              ))}
            </Select>
          </CustomItem>
        </SortScope>
        <Footer>
          <Button type="primary" htmlType="submit">
            {categoryInfo ? "儲存此分頁" : "建立資料"}
          </Button>
        </Footer>
      </Form>
    </Spin>
  );
}
