/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import { ContractType } from "./salesReportApi";
import normalizeContract, { Contract, ContractMode, Deduction } from "./utils/normalizeContract";

export interface ContractListConfig {
  limit: number;
  offset: number;
}

export interface DeductionListConfig {
  contractId: number;
  limit: number;
  offset: number;
}

export interface AddContractConfig {
  mode: ContractMode;
  note?: string;
  isActive?: boolean;
  type: ContractType;
}

export interface UpdateContractConfig {
  mode?: ContractMode;
  note?: string;
  isActive?: boolean;
  contractId: number;
  type?: ContractType;
}

export interface AddDeductionConfig {
  deductType: number;
  deductRate: number;
  contractId: number;
  periodStartMonth?: number;
  periodStartDay?: number;
  periodEndMonth?: number;
  periodEndDay?: number;
  description?: string;
}

export interface UpdateDeductionConfig {
  deductRate?: number;
  deductId: number;
  periodStartMonth?: number;
  periodStartDay?: number;
  periodEndMonth?: number;
  periodEndDay?: number;
  description?: string;
}

interface ContractAPI {
  fetchContractList: (config: ContractListConfig) => Promise<{ data: Contract[]; count: number }>;
  fetchContractDetail: (contractId: number) => Promise<Contract>;
  fetchDeductionList: (config: DeductionListConfig) => Promise<{ data: Deduction[]; count: number }>;
  fetchAddContract: (payload: AddContractConfig) => Promise<number>;
  fetchUpdateContract: (payload: UpdateContractConfig) => Promise<void>;
  fetchAddDeduction: (payload: AddDeductionConfig) => Promise<void>;
  fetchUpdateDeduction: (payload: UpdateDeductionConfig) => Promise<void>;
  fetchDeleteDeduction: (deductionId: number) => Promise<void>;
}

const apiClient = new CoreAPI();

const ContractApi: ContractAPI = {
  fetchContractList: async (config) => {
    const response = await apiClient.getData("/manage/vendor/contracts/", {
      limit: config.limit,
      offset: config.offset,
    });
    return {
      data: normalizeContract.contractList(response.data.result.results),
      count: response.data.result.count,
    };
  },
  fetchContractDetail: async (contractId) => {
    const response = await apiClient.getData(`/manage/vendor/contracts/${contractId}/`, {});
    return normalizeContract.contractDetail(response.data.result);
  },
  fetchDeductionList: async (config) => {
    const response = await apiClient.getData("/manage/vendor/deductions/", {
      contract_id: config.contractId,
      limit: config.limit,
      offset: config.offset,
    });
    return {
      data: normalizeContract.deductionList(response.data.result.results),
      count: response.data.result.count,
    };
  },
  fetchAddContract: async (payload) => {
    const response = await apiClient.postData("/manage/vendor/contracts/", {
      mode: payload.mode,
      note: payload.note,
      is_active: payload.isActive,
      type: payload.type,
    });
    return response.data.result.id;
  },
  fetchUpdateContract: async (payload) => {
    await apiClient.patchData(`/manage/vendor/contracts/${payload.contractId}/`, {
      mode: payload.mode,
      note: payload.note,
      is_active: payload.isActive,
      type: payload.type,
    });
  },
  fetchAddDeduction: async (payload) => {
    await apiClient.postData("/manage/vendor/deductions/", {
      deduct_type: payload.deductType,
      deduct_rate: payload.deductRate,
      contract_id: payload.contractId,
      period_start_month: payload.periodStartMonth,
      period_start_day: payload.periodStartDay,
      period_end_month: payload.periodEndMonth,
      period_end_day: payload.periodEndDay,
      description: payload.description,
    });
  },
  fetchUpdateDeduction: async (payload) => {
    await apiClient.patchData(`/manage/vendor/deductions/${payload.deductId}/`, {
      deduct_rate: payload.deductRate,
      period_start_month: payload.periodStartMonth,
      period_start_day: payload.periodStartDay,
      period_end_month: payload.periodEndMonth,
      period_end_day: payload.periodEndDay,
      description: payload.description,
    });
  },
  fetchDeleteDeduction: async (deductionId) => {
    await apiClient.deleteData(`/manage/vendor/deductions/${deductionId}/`, {});
  },
};

export default ContractApi;
