import { brandPageState, setBrandSalePageFilter } from "@redux/brandPageSlice";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const { Option } = Select;

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 10px 0px 20px 0px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function EditSalesPageFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { brandSalePageFilter } = useSelector(brandPageState);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(setBrandSalePageFilter({ limit: 20, offset: 0 }));
  };

  const onSubmit = (values: any) => {
    dispatch(setBrandSalePageFilter({ ...brandSalePageFilter, ...values }));
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 8 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={10}>
          <Form.Item label="銷售頁ID" name="salesPageId">
            <Input />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item label="銷售頁名稱" name="salesPageName">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={10}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item label="顯示於品牌頁" name="isShow">
            <Select placeholder="請選擇">
              <Option value="true" key="1">
                是
              </Option>
              <Option value="false" key="-1">
                否
              </Option>
            </Select>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item label="" name="salesPageIsActive" valuePropName="checked">
            <Checkbox>已上架</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const values = getFieldsValue();
                const hasAnyValues = Object.values(values).some((item) => item && item !== undefined);
                return (
                  <CustomButton disabled={!hasAnyValues} onClick={onReset}>
                    清除篩選條件
                  </CustomButton>
                );
              }}
            </Form.Item>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
