import { SalesCategory } from "./generateCategoryTree";

/*
  流程是
  先確認 checkList 裡面的 key 是什麼分別丟到 level1Store、level2Store
  最後組成字串
  中間應該有可以優化的地方，但目前看起來蠻快的先放著
*/
export default function getCategoryBreadcrumb(
  level1: Map<number, SalesCategory>,
  level2: Map<number, SalesCategory>,
  level3: Map<number, SalesCategory>,
  checkList: number[],
) {
  const level1Store = new Map<
    number,
    {
      title: string;
    }
  >();

  const level2Store = new Map<
    number,
    {
      parent: number;
      isLevel2: boolean;
      title: string;
      children: string[];
    }
  >();

  checkList.forEach((checkNumber) => {
    if (level1.has(checkNumber) && !level1Store.has(checkNumber)) {
      const element = level1.get(checkNumber)!;
      level1Store.set(element.key, { title: element.title });
    }

    if (level2.has(checkNumber)) {
      const element = level2.get(checkNumber)!;

      if (!level1Store.has(element.parent)) {
        level2Store.set(element.key, { isLevel2: true, parent: element.parent, title: element.title, children: [] });
      }
    }

    if (level3.has(checkNumber)) {
      const level3Element = level3.get(checkNumber)!;

      if (level2Store.has(level3Element.parent)) {
        const level2Element = level2Store.get(level3Element.parent);

        if (!level2Element!.isLevel2) {
          // isLevel2 = false 代表沒有第二層
          level2Store.set(level3Element.parent, {
            isLevel2: false,
            parent: level2Element!.parent,
            title: level2Element!.title,
            children: [...level2Element!.children, level3Element.title],
          });
        }
      } else {
        const level2Element = level2.get(level3Element.parent);
        level2Store.set(level3Element.parent, {
          isLevel2: false,
          parent: level2Element!.parent,
          title: level2Element!.title,
          children: [level3Element.title],
        });
      }
    }
  });

  /*
    1 level1Store loop -> level1 -> 先找出 level1 (level1Store 有的話) -> 跳過這個 level2 -> 用 level1 組成字串 -> remove level1Store  e.g 服飾裝備, 專業補給
      level2Store loop -> level2 -> 先找出 level1 (level1Store 沒有的話) -> 如果沒有 children 把 level2 存 level2ResultMap -> 組成字串 e.g 服飾裝備>健身護具,女款服飾,男款服飾
                       -> level3 -> 先找出 level1 (level1Store 沒有的話) -> level1 get title -> level2 children(level3) to string -> 最後組成字串 e.g 服飾裝備>健身護具>手套,拉力帶,腰帶
  */

  const level2ResultMap = new Map<number, string[]>(); // level1Id
  let resultString = "";

  for (const value of level1Store.values()) {
    resultString = `${resultString}${value.title},`;
  }

  for (const value of level2Store.values()) {
    if (!level1Store.has(value.parent)) {
      if (value.children.length === 0) {
        if (level2ResultMap.has(value.parent)) {
          const resultElement = level2ResultMap.get(value.parent)!;
          level2ResultMap.set(value.parent, [...resultElement, value.title]);
        } else {
          level2ResultMap.set(value.parent, [value.title]);
        }
      }
    }
  }

  for (const [key, value] of level2ResultMap.entries()) {
    const level1Element = level1.get(key);
    if (level1Element) {
      resultString = `${resultString}${level1Element.title}>${value.toString()},`;
    }
  }

  for (const value of level2Store.values()) {
    if (!level1Store.has(value.parent)) {
      const level1Title = level1.get(value.parent)?.title || "";
      const level2Title = value.title;
      const level3ListString = value.children.toString();

      if (value.children.length > 0) {
        resultString = `${resultString}${level1Title}>${level2Title}>${level3ListString},`;
      }
    }
  }

  resultString = resultString.substring(0, resultString.length - 1); // 去逗號

  return resultString;
}
