import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, {
  AddChargeListItem,
  ODImportFileForm,
  OnlineDistributorChargeList,
  OnlineDistributorList,
  OnlineDistributorListParams,
} from "@api/financeApi";
import { message } from "antd";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  onlineDistributorList: OnlineDistributorList;
  onlineDistributorListParams: OnlineDistributorListParams;
  onlineDistributorChargeList: OnlineDistributorChargeList;
  ODChargeListParams: OnlineDistributorListParams;
}

export const initialState: IState = {
  isFetching: false,
  onlineDistributorList: {
    count: 0,
    next: "",
    previous: "",
    amount: 0,
    amountWithTax: 0,
    cost: 0,
    charge: 0,
    results: [],
  },
  onlineDistributorListParams: {
    distributor: undefined,
    date: undefined, // API預設為上個月資料
    limit: 20,
    offset: 0,
  },
  onlineDistributorChargeList: {
    count: 0,
    totalAmount: 0,
    results: [],
  },
  ODChargeListParams: {
    distributor: undefined,
    date: undefined, // API預設為上個月資料
    limit: 20,
    offset: 0,
  },
};

export const fetchOnlineDistributorList = createAsyncThunk(
  "ODStatistics/fetchOnlineDistributorList",
  async (_, thunkApi) => {
    const {
      ODStatisticsSlice: { onlineDistributorListParams },
    } = thunkApi.getState() as RootState;
    thunkApi.dispatch(updateFetching(true));
    const response = await financeApi.fetchOnlineDistributorList(onlineDistributorListParams);
    thunkApi.dispatch(updateFetching(false));
    return response;
  },
);

export const batchImportODListCSV = createAsyncThunk(
  "ODStatistics/batchImportODListCSV",
  async (payload: { file: File; params: ODImportFileForm }, thunkApi) => {
    const { file, params } = payload;
    try {
      const response = await financeApi.batchImportODListCSV(file, params);
      message.success("匯入資料進行中，成功會發送通知");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const exportODListCSV = createAsyncThunk("ODStatistics/exportODListCSV", async (_, thunkApi) => {
  const {
    ODStatisticsSlice: { onlineDistributorListParams },
  } = thunkApi.getState() as RootState;
  try {
    const response = await financeApi.exportODListCSV(onlineDistributorListParams);
    message.success("匯出資料準備中，成功後會寄信通知");
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const batchModifyODItem = createAsyncThunk<void, { selectedODListIds: number[]; value: string; type: string }>(
  "ODStatistics/batchModifyODItem",
  async (payload, thunkApi) => {
    const { selectedODListIds, value, type } = payload;
    try {
      const response = await financeApi.batchModifyODItem(selectedODListIds, value, type);
      thunkApi.dispatch(fetchOnlineDistributorList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchODChargeList = createAsyncThunk("ODStatistics/fetchODChargeList", async (_, thunkApi) => {
  const {
    ODStatisticsSlice: { ODChargeListParams },
  } = thunkApi.getState() as RootState;
  thunkApi.dispatch(updateFetching(true));
  const response = await financeApi.fetchOnlineDistributorChargeList(ODChargeListParams);
  thunkApi.dispatch(updateFetching(false));
  return response;
});

export const addODCharge = createAsyncThunk<void, AddChargeListItem>(
  "ODStatistics/addODCharge",
  async (payload, thunkApi) => {
    try {
      const response = await financeApi.addODCharge(payload);
      thunkApi.dispatch(fetchODChargeList());
      message.success("新增成功 ");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const deleteODCharge = createAsyncThunk<void, number>("ODStatistics/deleteODCharge", async (id, thunkApi) => {
  try {
    const response = await financeApi.deleteODCharge(id);
    thunkApi.dispatch(fetchODChargeList());
    message.success("刪除成功");
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const exportODChargeListCSV = createAsyncThunk("ODStatistics/exportODChargeListCSV", async (_, thunkApi) => {
  const {
    ODStatisticsSlice: { ODChargeListParams },
  } = thunkApi.getState() as RootState;
  try {
    const response = await financeApi.exportODChargeListCSV(ODChargeListParams);
    message.success("匯出資料準備中，成功後會寄信通知");
    return response;
  } catch (error: any) {
    throw thunkApi.rejectWithValue("error");
  }
});

const ODStatisticsSlice = createSlice({
  name: "onlineDistributorStatistics",
  initialState,
  reducers: {
    resetODStatisticsSlice: () => initialState,
    updateFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    updateODListParams: (state, action) => {
      state.onlineDistributorListParams = action.payload;
    },
    updateOnlineDistributorListParams: (state, action) => {
      state.onlineDistributorListParams = action.payload;
    },
    resetOnlineDistributorListParams: (state) => {
      state.onlineDistributorListParams = initialState.onlineDistributorListParams;
    },
    updateODChargeListParams: (state, action) => {
      state.ODChargeListParams = action.payload;
    },
    resetODChargeListParams: (state) => {
      state.ODChargeListParams = initialState.ODChargeListParams;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOnlineDistributorList.fulfilled, (state, action) => {
      state.onlineDistributorList = action.payload;
    });
    builder.addCase(fetchODChargeList.fulfilled, (state, action) => {
      state.onlineDistributorChargeList = action.payload;
    });
  },
});

export const OnlineDistributorStatisticsState = (state: RootState) => state.ODStatisticsSlice;
export const {
  resetODStatisticsSlice,
  updateFetching,
  updateOnlineDistributorListParams,
  updateODChargeListParams,
  resetOnlineDistributorListParams,
  resetODChargeListParams,
} = ODStatisticsSlice.actions;
export default ODStatisticsSlice.reducer;
