import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import { message } from "antd";
import promotionApi, { PromotionListResult, FetchPromotionListParams } from "@api/promotionApi";

interface IState {
  isFetching: boolean;
  promotionListResult: PromotionListResult;
  promotionListParams: FetchPromotionListParams;
}

const initialState: IState = {
  isFetching: false,
  promotionListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  promotionListParams: {
    limit: 20,
    offset: 0,
    isActive: -1,
  },
};

export const fetchPromotionList = createAsyncThunk("ruleAddPromotion/fetchPromotionList", async (_, thunkApi) => {
  const {
    ruleAddPromotion: { promotionListParams },
  } = thunkApi.getState() as RootState;

  try {
    const response = await promotionApi.fetchPromotionList(promotionListParams);
    if (response.count === 0) message.warning("無此活動ID");
    return response;
  } catch (error: any) {
    message.warning(error.message);
    return thunkApi.rejectWithValue(error);
  }
});

export const loadMorePromotionList = createAsyncThunk("ruleAddPromotion/loadMorePromotionList", async (_, thunkApi) => {
  const {
    ruleAddPromotion: { promotionListParams },
  } = thunkApi.getState() as RootState;

  const params: FetchPromotionListParams = {
    ...promotionListParams,
    offset: promotionListParams.offset + promotionListParams.limit,
  };

  thunkApi.dispatch(updatePromotionListParams(params));
  const response = await promotionApi.fetchPromotionList(params);
  return response;
});

const ruleAddPromotionSlice = createSlice({
  name: "ruleAddPromotion",
  initialState,
  reducers: {
    reset: () => initialState,
    updatePromotionListParams: (state, action: PayloadAction<FetchPromotionListParams>) => {
      state.promotionListParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPromotionList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPromotionList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.promotionListResult = action.payload;
    });
    builder.addCase(loadMorePromotionList.fulfilled, (state, action) => {
      state.promotionListResult = {
        ...action.payload,
        results: state.promotionListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const { reset, updatePromotionListParams } = ruleAddPromotionSlice.actions;

export default ruleAddPromotionSlice.reducer;
