import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import ruleApi, { RuleListResult, FetchRuleListParams } from "@api/ruleApi";
import promotionApi from "@api/promotionApi";
import { fetchPromotionDetail } from "./promotionSlice";

interface IState {
  ruleListResult: RuleListResult;
  ruleListParams: FetchRuleListParams;
  selectedRuleIds: number[];
}

const initialState: IState = {
  ruleListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  ruleListParams: {
    limit: 20,
    offset: 0,
  },
  selectedRuleIds: [],
};

export const fetchRuleList = createAsyncThunk("promotionAddRule/fetchRuleList", async (_, thunkApi) => {
  const {
    promotionAddRule: { ruleListParams },
  } = thunkApi.getState() as RootState;

  const response = await ruleApi.fetchRuleList(ruleListParams);
  return response;
});

export const loadMoreRuleList = createAsyncThunk("promotionAddRule/loadMoreBrandList", async (_, thunkApi) => {
  const {
    promotionAddRule: { ruleListParams },
  } = thunkApi.getState() as RootState;

  const params: FetchRuleListParams = {
    ...ruleListParams,
    offset: ruleListParams.offset + ruleListParams.limit,
  };

  thunkApi.dispatch(updateRuleListParams(params));
  const response = await ruleApi.fetchRuleList(params);
  return response;
});

export const batchAddRules = createAsyncThunk("promotionAddRule/batchAddRules", async (_, thunkApi) => {
  const {
    promotionAddRule: { selectedRuleIds },
    promotion: {
      promotionDetail: { id },
    },
  } = thunkApi.getState() as RootState;

  const response = await promotionApi.batchAddRules(id!, selectedRuleIds);
  thunkApi.dispatch(updateSelectedRuleIds([]));
  thunkApi.dispatch(fetchPromotionDetail(id!));
  return response;
});

const promotionAddRuleSlice = createSlice({
  name: "promotionAddRule",
  initialState,
  reducers: {
    reset: () => initialState,
    updateRuleListParams: (state, action: PayloadAction<FetchRuleListParams>) => {
      state.ruleListParams = action.payload;
    },
    updateSelectedRuleIds: (state, action: PayloadAction<number[]>) => {
      state.selectedRuleIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRuleList.fulfilled, (state, action) => {
      state.ruleListResult = action.payload;
    });
    builder.addCase(loadMoreRuleList.fulfilled, (state, action) => {
      state.ruleListResult = {
        ...action.payload,
        results: state.ruleListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const { reset, updateRuleListParams, updateSelectedRuleIds } = promotionAddRuleSlice.actions;

export default promotionAddRuleSlice.reducer;
