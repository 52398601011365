import { SalePlan } from "@api/saleApi";
import PopupBackground from "@component/PopupBackground";
import UploadImageButton from "@component/UploadImageButton";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import {
  fetchCreatePlan,
  fetchProductVendorList,
  fetchUpdatePlan,
  SaleState,
  setCurrentSalePlan,
  setVpcAddDone,
  setVpcList,
} from "@redux/saleSlice";
import { Button, Form, Input, InputNumber, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  position: relative;
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 80vh;
  overflow: auto;

  .ant-form-item-label {
    width: 120px;
  }

  .ant-form-item-required::before {
    order: 1;
  }

  .ant-form-item-required::after {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomHelp = styled.div`
  ${fontStyle("14px", "16px", "400")};
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colorSecondary500};
  white-space: nowrap;
  padding-left: 120px;
  position: relative;
  top: -12px;
`;

const FlexFormItem = styled(Form.Item)`
  flex: 1;
  margin-left: 30px;
`;

const AddButton = styled(Button)`
  margin-left: 30px;
`;

const Error = styled.span`
  color: #ec6922;
  position: absolute;
  left: 20px;
  bottom: 20px;
  ${fontStyle("14px", "16px")};
`;

const VpcListWrapper = styled(Row)`
  margin-bottom: 12px;
`;

const CustomRow = styled(Row)`
  position: relative;
`;

const CustomRequired = styled.div`
  position: absolute;
  left: 9.5%;
  top: 8px;
  color: #ff4d4f;
  ${fontStyle("12px", "1")};
`;

const VpcName = styled.div`
  margin-left: 50px;
  margin-right: 16px;
  width: 360px;
`;

const CustomLink = styled(Button)`
  margin-left: "auto";
`;

type Props = {
  onNext: () => void;
  close: () => void;
  salePlanListResult: {
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  };
};

export default function PlanModal(props: Props) {
  const { onNext, close, salePlanListResult } = props;

  const dispatch = useDispatch();
  const { salePageDetail, vpcError, vpcList, nextStepLoading, currentSalePlan, stepDone, vpcAddDone } = useSelector(
    SaleState,
  );
  const { results: salePlanList } = salePlanListResult;
  const imgEditable = currentSalePlan?.media.isEditable === undefined ? true : currentSalePlan?.media.isEditable;

  const [form] = Form.useForm();
  const [vpcQtyMap, setVpcQtyMap] = useState<Map<number, number>>(new Map());
  const [imageChange, setImageChange] = useState<boolean>(false);

  const onSetVpcQtyMap = (id: number, value: number) => {
    setVpcQtyMap(new Map(vpcQtyMap.set(id, value)));
  };

  const onDeleteVpc = (index: number) => {
    const cloneVpcList = [...vpcList];
    cloneVpcList.splice(index, 1);
    dispatch(setVpcList(cloneVpcList));
  };

  const onFinishUpload = (url: string) => {
    setImageChange(true);
    form.setFieldsValue({ image: url });
  };

  const setCloseModal = () => {
    dispatch(setCurrentSalePlan(undefined));
    close();
  };

  const fetchVpcProduct = (sku?: string) => {
    if (sku) {
      dispatch(fetchProductVendorList(sku));
    }
  };

  const onSubmit = (values: any) => {
    const payload = {
      name:
        salePageDetail?.pageType === SalePageTypeValue.Any
          ? `${values.name || ""} ${values.optionsCount}入組`
          : values.name,
      salesPage: salePageDetail!.id,
      imageDirPrefix: values.image && "sale/plan/image",
      imageFilePath: values.image || "",
      imageFileAlt: values.imageAlt,
      optionsCount: salePageDetail?.pageType === SalePageTypeValue.Any ? parseInt(values.optionsCount, 10) : undefined,
      productsInfo:
        salePageDetail?.pageType === SalePageTypeValue.Any
          ? []
          : vpcList.map((vpc) => ({
              ...vpc,
              qty: vpcQtyMap.get(vpc.vpcId) || 1,
            })),
    };

    if (currentSalePlan) {
      if (!imageChange) {
        delete payload.imageDirPrefix;
        delete payload.imageFilePath;
      }
      dispatch(fetchUpdatePlan({ planId: currentSalePlan.id, payload }));
    } else {
      dispatch(fetchCreatePlan(payload));
    }
  };

  useEffect(() => {
    if (stepDone) {
      onNext();
    }
  }, [stepDone, onNext]);

  useEffect(() => {
    if (currentSalePlan && salePageDetail) {
      form.setFieldsValue({
        optionsCount: currentSalePlan.optionsCount,
        name:
          salePageDetail.pageType === SalePageTypeValue.Any ? currentSalePlan.name.split(" ")[0] : currentSalePlan.name,
        image: currentSalePlan.media?.url || "",
        imageAlt: currentSalePlan.media?.alt || "",
      });

      const initVpcList = currentSalePlan?.options.map((item) => ({
        vpcId: item.vpc.id,
        qty: item.groupQty,
        name: item.name,
        sku: item.vpc.sku,
      }));
      dispatch(setVpcList(initVpcList));

      currentSalePlan.options.forEach((item) => {
        setVpcQtyMap((prev) => new Map(prev.set(item.vpc.id, item.groupQty)));
      });
    }
  }, [form, currentSalePlan, dispatch, salePageDetail]);

  useEffect(() => {
    if (vpcAddDone) {
      form.resetFields(["sku"]);
    }
  }, [vpcAddDone, form]);

  return (
    <PopupBackground close={setCloseModal} fixed>
      <Wrapper form={form} colon={false} labelAlign="left" onFinish={onSubmit}>
        {salePageDetail?.pageType === SalePageTypeValue.Any && (
          <Form.Item
            label="入數"
            name="optionsCount"
            rules={[
              { required: true, message: "必填" },
              {
                message: "已存在",
                validator: (rule, value: string) => {
                  // 編輯不用判斷
                  if (currentSalePlan) return Promise.resolve();

                  const valueInNumber = Number(value);
                  const optionsCountExists = salePlanList.some((plan) => plan.optionsCount === valueInNumber);
                  if (optionsCountExists) return Promise.reject();
                  return Promise.resolve();
                },
              },
            ]}
            wrapperCol={{ span: 6 }}
          >
            <Input type="number" />
          </Form.Item>
        )}
        <Row align="middle">
          <Form.Item
            label="方案名稱"
            name="name"
            rules={salePageDetail?.pageType !== SalePageTypeValue.Any ? [{ required: true, message: "必填" }] : []}
            wrapperCol={{ span: 16 }}
            style={{ flex: 1 }}
          >
            <Input />
          </Form.Item>
          {salePageDetail?.pageType === SalePageTypeValue.Any && (
            <Form.Item shouldUpdate>{() => <span>{`${form.getFieldValue("optionsCount") || 0} 入組`}</span>}</Form.Item>
          )}
        </Row>
        <Row wrap={false} align="top">
          <Form.Item label="方案去背圖片" name="image" rules={[{ required: true, message: "必填" }]}>
            <UploadImageButton
              imageUrl={currentSalePlan?.media?.url || ""}
              finishUpload={onFinishUpload}
              editable={imgEditable}
            />
          </Form.Item>
          <FlexFormItem label="" name="imageAlt">
            <Input placeholder="請輸入alt(非必填)" />
          </FlexFormItem>
        </Row>
        {!imgEditable && (
          <CustomHelp>
            此為活動壓框圖片不可編輯，活動期間：{currentSalePlan?.media.startAt} ~ {currentSalePlan?.media.endAt}
          </CustomHelp>
        )}
        {salePageDetail?.pageType !== SalePageTypeValue.Any && (
          <CustomRow>
            <CustomRequired>＊</CustomRequired>
            <Form.Item label="方案內容" name="sku">
              <Input placeholder="請輸入sku" onChange={() => dispatch(setVpcAddDone(false))} />
            </Form.Item>
            <AddButton onClick={() => fetchVpcProduct(form.getFieldValue("sku"))}>加入</AddButton>
          </CustomRow>
        )}
        {salePageDetail?.pageType !== SalePageTypeValue.Any &&
          vpcList.map((vpc, index) => (
            <VpcListWrapper align="middle" justify="space-around">
              <VpcName>{`${vpc.sku}, ${vpc.name}`}</VpcName>
              <InputNumber
                defaultValue={1}
                value={vpcQtyMap.get(vpc.vpcId)}
                onChange={(value) => onSetVpcQtyMap(vpc.vpcId, value as number)}
              />
              <CustomLink type="link" onClick={() => onDeleteVpc(index)}>
                刪除
              </CustomLink>
            </VpcListWrapper>
          ))}
        <Row justify="end">
          <CustomButton onClick={setCloseModal}>取消</CustomButton>
          <Button type="primary" htmlType="submit" loading={nextStepLoading}>
            下一步
          </Button>
        </Row>
        <Error>{vpcError}</Error>
      </Wrapper>
    </PopupBackground>
  );
}
