import { SalesChannelItem } from "@api/vendorApi";
import { useEffect, useState } from "react";
import * as vendorApi from "@api/vendorApi";

export default function useSalesChannelOptions() {
  const [salesChannelList, setSalesChannelList] = useState<SalesChannelItem[]>([]);

  useEffect(() => {
    const fetchSalesChannelList = async () => {
      const response = await vendorApi.fetchSalesChannelList();
      setSalesChannelList(response.results);
    };
    fetchSalesChannelList();
  }, []);

  return salesChannelList
    ? salesChannelList.map((channel) => ({
        label: channel.name,
        value: channel.id,
      }))
    : [{ label: "UrMart 官網", value: 1 }];
}
