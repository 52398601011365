import { SlotContentListItem } from "@api/slotApi";
import UploadImageButton from "@component/UploadImageButton";
import { PageType, SlotPatternValue } from "@page/Slot/constant";
import { configState } from "@redux/configSlice";
import { RootState } from "@redux/rootReducer";
import { createSlotContent, createSlotTarget, updateSlotContent } from "@redux/slotSlice";
import { Button, Checkbox, DatePicker, Form, Input, InputNumber, Modal } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import React, { FC, useEffect, useMemo, useState } from "react";
import QuillEditor from "@component/QuillEditor";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import slotEditMappiong, { EditItemType, Field } from "./SlotEditMapping";

interface Props {
  show: boolean;
  onClose: () => void;
  mode: "add" | "edit";
  contentItem: SlotContentListItem;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  line-height: 32px;
  font-size: 14px;
  width: 91px;
  margin-right: 10px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width?: number }>`
  display: flex;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const StyledInput = styled(Input)<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const StyledInputNumber = styled(InputNumber)<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const CustomDatePicker = styled(DatePicker)<{ showTime?: any }>`
  margin-right: 4px;
`;
const Footer = styled.div`
  position: absolute;
  bottom: 28px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 28px 0 32px;
`;
const OpenNewTabContainer = styled.div``;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;
const ImageRuleText = styled.div`
  margin-left: 10px;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral500};
`;
const EditWrapper = styled.div`
  margin-bottom: 20px;

  .editorClassName {
    border-radius: 2px;
    border: 1px solid #f1f1f1;
    height: 180px;
  }

  .rdw-link-modal {
    height: 225px;
  }

  .rdw-link-decorator-wrapper a {
    color: #2e90b7;
    text-decoration: underline;
  }
`;
const Annotation = styled.div<{ highlight: boolean }>`
  position: absolute;
  left: 32px;
  bottom: 22px;
  font-size: 13px;
  color: ${({ theme, highlight }) => (highlight ? "#EC6922" : theme.colorNeutral600)};
`;

interface EditForm {
  name?: string;
  url?: string;
  videoUrl?: string;
  description?: string;
  toShow?: boolean;
  position?: number;
  newPageRequired?: boolean;
  imgUrl?: string;
  imgAlt?: string;
  startAt?: Moment;
  endAt?: Moment;
}

const EditPopup: FC<Props> = (props) => {
  const { show, onClose, mode, contentItem } = props;

  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);

  const [form] = Form.useForm<EditForm>();
  const [quillHtml, setQuillHtml] = useState("");

  const [highlightAnnotation, setHighlightAnnotation] = useState<boolean>(false);

  const { slotPattern } = useSelector((state: RootState) => state.slot.slotDetail);

  const slotForm = useMemo(() => {
    return slotEditMappiong[slotPattern] || [];
  }, [slotPattern]);

  useEffect(() => {
    const startTime = contentItem.startAt === null ? undefined : contentItem.startAt;
    const endTime = contentItem.endAt === null ? undefined : contentItem.endAt;

    const formValue: EditForm = {
      toShow: contentItem.toShow,
      name: contentItem.name,
      videoUrl: contentItem.media, // videoUrl 存在版位內容裡的media
      description: contentItem.description, // text 版位用
      url: contentItem.content?.link || "",
      startAt: moment(startTime),
      endAt: moment(endTime),
      imgUrl: contentItem.media && contentItem.media.toString(), // to be modified
      position: contentItem.position,
      newPageRequired: contentItem.newPageRequired,
    };
    form.setFieldsValue(formValue);
  }, [form, contentItem, show]);

  const handleOnSubmit = async () => {
    const values = form.getFieldsValue();
    const params = {
      name: values.name,
      videoUrl: values.videoUrl,
      url: values.url,
      startAt: moment(values.startAt).format("YYYY-MM-DD HH:mm:ss"),
      endAt: moment(values.endAt).format("YYYY-MM-DD HH:mm:ss"),
      imgUrl: values.imgUrl,
      position: values.position,
      description: quillHtml,
      newPageRequired: values.newPageRequired,
    };

    switch (mode) {
      case "edit":
        dispatch(
          updateSlotContent({
            itemId: contentItem.id,
            params,
          }),
        );
        break;
      case "add":
        dispatch(createSlotContent(params));
        if (
          contentItem.slotPattern === SlotPatternValue.VIP_ONLY_PATTERN ||
          contentItem.slotPattern === SlotPatternValue.VIP_RECOMMENDATION_PATTERN
        ) {
          dispatch(
            createSlotTarget({
              pageType: PageType.VIP_PROMOTION,
              position: 1,
            }),
          );
        }
        if (contentItem.slotPattern === SlotPatternValue.SEARCH_RECOMMEND_PATTERN) {
          dispatch(
            createSlotTarget({
              pageType: PageType.SEARCH,
              position: 1,
            }),
          );
        }
        if (contentItem.slotPattern === SlotPatternValue.ANNOUNCEMENT_PATTERN) {
          dispatch(
            createSlotTarget({
              pageType: PageType.ANNOUNCEMENT_BANNER,
              position: 1,
            }),
          );
        }
        break;
      default:
    }

    onClose();
  };

  const handleOnImgUpload = (url: string) => {
    form.setFieldsValue({
      imgUrl: url,
    });
  };

  const generateFormItem = (item: EditItemType) => {
    const { type, required, requiredText, supportedUrl, ratio, ratioSize } = item;

    switch (type) {
      case Field.TITLE:
        return (
          <Row>
            <RowTitle>
              前台顯示標題
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: "必填" }]} name="name">
                <StyledInput width={400} />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.NAME:
        return (
          <Row>
            <RowTitle>
              名稱
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: requiredText }]} name="name">
                <StyledInput width={400} />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.PROMO_NAME:
        return (
          <Row>
            <RowTitle>
              推廣文案
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: requiredText }]} name="name">
                <StyledInput width={400} />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.VIDEO_URL:
        return (
          <Row>
            <RowTitle>
              影片網址
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: requiredText }]} name="videoUrl">
                <StyledInput width={400} />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.URL:
        return (
          <Row>
            <RowTitle>
              網址
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item
                rules={[
                  {
                    required,
                    message: "",
                    validator: (rule, value) => {
                      if (supportedUrl) {
                        const isValid = supportedUrl.some((urlRule) => value.startsWith(`${webDomain}${urlRule}`));
                        if (isValid) {
                          setHighlightAnnotation(false);
                          return Promise.resolve();
                        }
                        setHighlightAnnotation(true);
                        return Promise.reject();
                      }
                      setHighlightAnnotation(false);
                      return Promise.resolve();
                    },
                  },
                ]}
                name="url"
              >
                <StyledInput width={400} />
              </Form.Item>
              <Annotation highlight={highlightAnnotation}>{requiredText}</Annotation>
            </RowContent>
          </Row>
        );
      case Field.EFFECTIVE_TIME:
        return (
          <Row>
            <RowTitle>
              內容上架時間
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item rules={[{ required, message: "" }]} name="startAt">
                <CustomDatePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                  }}
                  locale={locale}
                />
              </Form.Item>
              <Form.Item rules={[{ required, message: "" }]} name="endAt">
                <CustomDatePicker
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: moment("23:59:59", "HH:mm:ss"),
                  }}
                  locale={locale}
                />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.RANK:
        return (
          <Row>
            <RowTitle>
              排序
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: requiredText }]} name="position">
                <StyledInputNumber type="number" width={104} />
              </Form.Item>
            </RowContent>
          </Row>
        );
      case Field.IMAGE:
        return (
          <Row>
            <RowTitle>
              圖片
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: "" }]} name="imgUrl" valuePropName="imageUrl">
                <UploadImageButton
                  finishUpload={handleOnImgUpload}
                  limitSize={1}
                  ratio={ratio}
                  // 最小寬度1280三月上線
                  // limitDimension={
                  //   slotPattern === SlotPatternValue.CAROUSEL_PATTERN ||
                  //   slotPattern === SlotPatternValue.FLAT_CAROUSEL_PATTERN
                  //     ? 1280
                  //     : undefined
                  // }
                  // slotPattern={slotPattern}
                />
              </Form.Item>
              <ImageRuleText>
                {ratio ? `長:寬=${ratio[0]}:${ratio[1]}, ` : "比例不限,"}大小上限為1MB
                {/* 最小寬度1280三月上線 */}
                {/* {(slotPattern === SlotPatternValue.CAROUSEL_PATTERN ||
                  slotPattern === SlotPatternValue.FLAT_CAROUSEL_PATTERN) && <>(最小寬度：1280px)</>} */}
              </ImageRuleText>
            </RowContent>
          </Row>
        );
      case Field.PROMO_IMAGE:
        return (
          <Row>
            <RowTitle>
              推廣圖
              {required && <RequiredIcon>*</RequiredIcon>}
            </RowTitle>
            <RowContent>
              <Form.Item rules={[{ required, message: "" }]} name="imgUrl" valuePropName="imageUrl">
                <UploadImageButton finishUpload={handleOnImgUpload} limitSize={1} ratio={ratio} />
              </Form.Item>
              <ImageRuleText>
                {ratioSize ? `尺寸=${ratioSize[0]}x${ratioSize[1]}(單位px), ` : "比例不限, "}
                大小上限為1MB
              </ImageRuleText>
            </RowContent>
          </Row>
        );
      case Field.TEXT:
        return (
          <EditWrapper>
            <Form.Item rules={[{ required, message: "必填" }]} name="description">
              <QuillEditor
                height={120}
                toolbarBlacklist={["video", "image", "imageLink", "indent", "blockquote"]}
                customFontList={["14px", "16px", "18px", "24px", "30px", "36px", "48px"]}
                toolbarName="short-description-toolbar"
                uploadImagePathname="sales_page_assets"
                defaultHtml={contentItem.description}
                onChangeQuill={(html) => {
                  form.setFieldsValue({
                    description: html,
                  });
                  setQuillHtml(html);
                }}
                customColorList={[
                  "#464c51",
                  "#EC6922",
                  "#82828282",
                  "#ffffff",
                  "#fef0ef",
                  "#ec6922",
                  "#f16c5d",
                  "#5c6ac4",
                  "#4bc889",
                ]}
              />
            </Form.Item>
          </EditWrapper>
        );
      case Field.TEXT_PRODUCT:
        return (
          <EditWrapper>
            <Form.Item rules={[{ required, message: "必填" }]} name="description">
              <QuillEditor
                height={120}
                toolbarBlacklist={["background", "video", "image", "imageLink", "indent", "align"]}
                toolbarName="short-description-toolbar"
                uploadImagePathname="sales_page_assets"
                defaultHtml={contentItem.description}
                customFontList={["14px"]}
                onChangeQuill={(html) => {
                  form.setFieldsValue({
                    description: html,
                  });
                  setQuillHtml(html);
                }}
                customColorList={["#464c51", "#EC6922"]}
              />
            </Form.Item>
          </EditWrapper>
        );
      default:
        return null;
    }
  };

  const OpenNewPageCheckbox = () => (
    <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.url !== currentValues.url}>
      {({ getFieldValue }) => {
        const url: string = getFieldValue("url") || "";
        const isUrmartUrl = url.startsWith(webDomain!);
        if (isUrmartUrl) {
          form.setFieldsValue({
            newPageRequired: false,
          });
        }

        return (
          <Form.Item name="newPageRequired" valuePropName="checked">
            <Checkbox disabled={isUrmartUrl || !url}>另開分頁</Checkbox>
          </Form.Item>
        );
      }}
    </Form.Item>
  );

  return (
    <Modal
      visible={show}
      width={750}
      bodyStyle={{ minHeight: 450, padding: "40px 28px 23px 32px" }}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <Form form={form} onFinish={handleOnSubmit}>
        {slotForm.map(generateFormItem)}
        <Footer>
          <OpenNewTabContainer>
            {slotForm.find((item) => item.type === Field.OPEN_NEW_PAGE) && OpenNewPageCheckbox()}
          </OpenNewTabContainer>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        </Footer>
      </Form>
    </Modal>
  );
};

export default EditPopup;
