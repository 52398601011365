/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType, fetchMemberDistributionFunnel } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import Funnel from "highcharts/modules/funnel";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { NewMemberChartItem, NewMemberChart } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
  getTimeRangeText,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;
const FunnelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

Funnel(Highcharts);

const NewMemberPurchaseRatioChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams, isFetchingMemberFunnel, memberDistributionFunnel } = useSelector(
    (state: RootState) => state.dashboard,
  );

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const [currentFunnelOptions, setCurrentFunnelOptions] = useState<Highcharts.Options>();
  const [compareFunnelOptions, setCompareFunnelOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<NewMemberChartItem>(
      startDate,
      endDate,
      (chartResult as NewMemberChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<NewMemberChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as NewMemberChart).compareData || [],
      timeUnit,
    );
    const currentMemberBuyRate: (number | null)[] = [];
    const compareMemberBuyRate: (number | null)[] = [];
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    currentFilledTimeData.forEach((data) => {
      const { memberBuyRate = null } = data;
      currentMemberBuyRate.push(memberBuyRate);
    });
    compareFilledTimeData.forEach((data) => {
      const { memberBuyRate = null } = data;
      compareMemberBuyRate.push(memberBuyRate);
    });

    const currentTimeRangeText = getTimeRangeText(startDate, endDate, timeUnit);
    const compareTimeRangeText = getTimeRangeText(cStartDate, cEndDate, timeUnit);

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: currentTimeRangeText,
        data: currentMemberBuyRate,
        type: "line",
        color: "#5C6AC4",
        stack: "current",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.push({
        name: compareTimeRangeText,
        data: compareMemberBuyRate,
        type: "line",
        color: "#1890FF",
        stack: "compare",
        pointStart,
      });
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          formatter() {
            const formatValue = Math.round((this.value as number) * 100);
            return `${formatValue}%`;
          },
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const value = Math.round((this.point.y as number) * 1000) / 10;

          return `${title}<br/>新註冊購買比例 ${value}%<br/>`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });
  }, [chartResult, chartParams]);

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const { current, compare } = memberDistributionFunnel;

    const currentTimeRangeText = getTimeRangeText(startDate, endDate, timeUnit);
    const compareTimeRangeText = getTimeRangeText(cStartDate, cEndDate, timeUnit);

    const {
      totalMember: currentTotalMember,
      memberBuyOnce: currentMemberBuyOnce,
      memberBuyTwiceGte: currentMemberBuyTwiceGTE,
    } = current;
    const {
      totalMember: compareTotalMember = 0,
      memberBuyOnce: compareMemberBuyOnce = 0,
      memberBuyTwiceGte: compareMemberBuyTwiceGTE = 0,
    } = compare || {};

    setCurrentFunnelOptions({
      chart: {
        type: "funnel",
      },
      title: {
        text: currentTimeRangeText,
      },
      plotOptions: {
        funnel: {
          dataLabels: {
            enabled: true,
            softConnector: true,
            formatter() {
              const title = this.point.name;
              const value = this.point.y as number;
              const percentage = Math.round((value / currentTotalMember) * 1000) / 10;
              return `${title} ${value}人 (${percentage}%)`;
            },
            style: {
              fontSize: "16px",
            },
          },
          center: ["40%", "50%"],
          neckWidth: "30%",
          neckHeight: "25%",
          width: "80%",
          colors: ["#1890FF", "#BAE7FF", "#BEC3E7"],
        },
      },

      legend: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          name: "member",
          type: "funnel",
          data: [
            ["註冊", currentTotalMember],
            ["轉為首次購買", currentMemberBuyOnce],
            ["二次購買以上", currentMemberBuyTwiceGTE],
          ],
        },
      ],
    });

    if (chartParams.cStartDate && chartParams.cEndDate) {
      setCompareFunnelOptions({
        chart: {
          type: "funnel",
        },
        title: {
          text: compareTimeRangeText,
        },
        plotOptions: {
          funnel: {
            dataLabels: {
              enabled: true,
              softConnector: true,
              formatter() {
                const title = this.point.name;
                const value = this.point.y as number;
                const percentage = Math.round((value / compareTotalMember) * 1000) / 10;
                return `${title} ${value}人 (${percentage}%)`;
              },
              style: {
                fontSize: "16px",
              },
            },
            center: ["40%", "50%"],
            neckWidth: "30%",
            neckHeight: "25%",
            width: "80%",
            colors: ["#1890FF", "#BAE7FF", "#BEC3E7"],
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
        series: [
          {
            name: "member",
            type: "funnel",
            data: [
              ["註冊", compareTotalMember],
              ["轉為首次購買", compareMemberBuyOnce],
              ["二次購買以上", compareMemberBuyTwiceGTE],
            ],
          },
        ],
      });
    }
  }, [chartParams, memberDistributionFunnel]);

  const fetchNewMemberChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.NEW_MEMBER));
    dispatch(fetchMemberDistributionFunnel());
  }, [dispatch]);

  return (
    <Wrapper>
      <ChartWrapper>
        <PageTitle title="Dashboard - 新註冊購買比例" />
        <Spin spinning={isFetchingChart}>
          <ChartFilter title="新註冊購買比例" fetchAction={fetchNewMemberChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
        </Spin>
        <Spin spinning={isFetchingMemberFunnel}>
          <FunnelContainer>
            {chartParams.cStartDate && chartParams.cEndDate && (
              <HighchartsReact
                highcharts={Highcharts}
                options={compareFunnelOptions}
                containerProps={{ style: { height: 400, width: "50%" } }}
              />
            )}
            <HighchartsReact
              highcharts={Highcharts}
              options={currentFunnelOptions}
              containerProps={{ style: { height: 400, width: "50%" } }}
            />
          </FunnelContainer>
        </Spin>
      </ChartWrapper>
    </Wrapper>
  );
};

export default NewMemberPurchaseRatioChart;
