import { fetchManufacturerDetail } from "@redux/brandOperation/manufacturerSlice";
import { ManufacturerInfo } from "@api/brandOperation/manufacturerApi";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { currencyValueLabel } from "@constant/CurrencyValue";
import { RootState } from "@redux/rootReducer";
import { fetchAddPcm, fetchPcmDetail, fetchUpdatePcm } from "@redux/brandOperation/PcmSlice";
import { MEMBER_TOKEN } from "@constant/StorageKeyValue";
import cookie from "@utils/Cookie";
import { useParams } from "react-router-dom";
import { isNumber } from "highcharts";
import { TradeTermValue } from "@api/brandOperation/pcmApi";

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

interface Props {
  visible: boolean;
  onClose: () => void;
  handleOnKeywordSearch: () => void;
  vendorOptions: any;
  isSelectLoading: boolean;
  manufacturerInfo: ManufacturerInfo;
}

const EditPopup = (props: Props) => {
  const { visible, onClose, handleOnKeywordSearch, vendorOptions, isSelectLoading, manufacturerInfo } = props;
  const dispatch = useDispatch();
  const tokenInfo = cookie.getItem(MEMBER_TOKEN);
  const { id: pcmId } = useParams();
  const { pcmDetail } = useSelector((state: RootState) => state.pcmSlice);

  const [form] = Form.useForm();

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();

    const params = {
      producer: isNumber(formValues.producer) ? formValues.producer : pcmDetail.producerId,
      currency: currencyLable?.value || undefined,
      currencyRate: formValues.currencyRate || undefined,
      tradeTerm: formValues.tradeTerm || undefined,
    };

    if (pcmId) {
      // 編輯
      dispatch(fetchUpdatePcm({ updatePcmParams: params, pcmId }));
    } else {
      // 新增
      dispatch(fetchAddPcm(params));
    }
  };
  const handleSelect = (manufacturerId: number) => {
    dispatch(fetchManufacturerDetail(manufacturerId));
  };

  useEffect(() => {
    if (pcmDetail.producerId) {
      dispatch(fetchManufacturerDetail(pcmDetail.producerId));
    }
  }, [dispatch, pcmDetail.producerId]);

  const currencyLable = currencyValueLabel(manufacturerInfo.currency);

  const currencyLablePcm = currencyValueLabel(pcmDetail.currency);
  const formValues = form.getFieldsValue();

  useEffect(() => {
    if (formValues.producer) {
      form.setFieldsValue({ currency: currencyLable?.label });
    }
  }, [currencyLable, currencyLable?.label, form, formValues.producer, pcmDetail.producerId]);

  useEffect(() => {
    if (pcmId) {
      dispatch(fetchPcmDetail(pcmId));
    }
  }, [dispatch, pcmId]);

  useEffect(() => {
    if (pcmId) {
      form.setFieldsValue({
        producer: pcmDetail.producerName,
        currency: currencyLablePcm?.label,
        currencyRate: pcmDetail.currencyRate,
        tradeTerm: pcmDetail.tradeTerm,
      });
    }
  }, [
    currencyLablePcm,
    currencyLablePcm?.label,
    form,
    pcmDetail.currencyRate,
    pcmDetail.producerName,
    pcmDetail.tradeTerm,
    pcmId,
  ]);
  return (
    <Modal visible={visible} closable={false} footer={false}>
      <Wrapper
        labelAlign="left"
        colon={false}
        onFinish={handleOnSubmit}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 25 }}
      >
        <Form.Item shouldUpdate label="採購人員" name="staff">
          <div>{tokenInfo.userName}</div>
        </Form.Item>
        <Form.Item label="製造商" name="producer" rules={[{ required: true, message: "" }]}>
          <Select
            showSearch
            filterOption={false}
            onSearch={handleOnKeywordSearch}
            loading={isSelectLoading}
            placeholder="請選擇"
            onChange={handleSelect}
            value={2}
          >
            {vendorOptions}
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate label="採購幣別" name="currency">
          <Input disabled placeholder="自動帶入" />
        </Form.Item>
        <Form.Item label="採購匯率" name="currencyRate" rules={[{ required: true, message: "" }]}>
          <InputNumber min={0} />
        </Form.Item>
        <Form.Item shouldUpdate label="貿易條件" name="tradeTerm">
          <Select placeholder="請選擇">
            <Select.Option value={TradeTermValue.EXW}>EXW</Select.Option>
            <Select.Option value={TradeTermValue.FOB}>FOB</Select.Option>
            <Select.Option value="">(None)</Select.Option>
          </Select>
        </Form.Item>
        <Footer>
          <Button
            style={{
              marginRight: "14px",
            }}
            onClick={() => onClose()}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            儲存
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default EditPopup;
