import React, { useEffect, useState } from "react";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import { FilterTwoTone } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Button, Table, Row, Select } from "antd";
import { ColumnsType } from "antd/lib/table";
import fontStyle from "src/styles/fontStyle";
import { SelectValue } from "antd/lib/select";
import {
  invoiceState,
  setInvoiceFilter,
  fetchInvoiceList,
  exportVPCSalesList,
  resetInvoiceSlice,
} from "@redux/finance/invoiceSlice";
import Filter from "./Filter";
import OrderPopup from "./orderPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  margin: 0 10px;
`;
const ButtonWrapper = styled.div``;

const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PointerText = styled.div`
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
`;

export default function Invoice() {
  const dispatch = useDispatch();
  const { invoiceList, invoiceFilter, isFetching } = useSelector(invoiceState);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [subOrderId, setSubOrderId] = useState<number>(0);

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setInvoiceFilter({ ...invoiceFilter, offset: (value - 1) * invoiceFilter.limit }));
  };

  const columns: ColumnsType<any> = [
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "商店自訂編號",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "子訂單編號",
    },
    {
      key: "invoiceNumber",
      dataIndex: "invoiceNumber",
      title: "發票號碼",
    },
    {
      key: "ubn",
      dataIndex: "ubn",
      title: "買受人統編",
    },
    {
      key: "buyer",
      dataIndex: "buyer",
      title: "買受人名稱",
    },
    {
      key: "tax",
      dataIndex: "tax",
      title: "稅額",
    },
    {
      key: "ntSalesAmount",
      dataIndex: "ntSalesAmount",
      title: "銷售額(不含稅)",
    },
    {
      key: "invoiceAmount",
      dataIndex: "invoiceAmount",
      title: "發票金額",
    },
    {
      key: "invoiceExecutedAt",
      dataIndex: "invoiceExecutedAt",
      title: "開立發票時間",
    },
    {
      key: "paymentMethod",
      dataIndex: "paymentMethod",
      title: "付款方式",
    },
    {
      key: "capturedAt",
      dataIndex: "capturedAt",
      title: "請款時間",
    },
    {
      key: "shippingMethod",
      dataIndex: "shippingMethod",
      title: "物流方式",
    },
    {
      key: "ntShippingFee",
      dataIndex: "ntShippingFee",
      title: "運費（未稅）",
    },
    {
      key: "buyoutSum",
      dataIndex: "buyoutSum",
      title: "買斷總額（含稅）",
    },
    {
      key: "buyoutDiscount",
      dataIndex: "buyoutDiscount",
      title: "買斷折扣（含稅）",
    },

    {
      key: "ntBuyoutDiff",
      dataIndex: "ntBuyoutDiff",
      title: "買斷總額-折扣（未稅）",
    },
    {
      key: "ctSum",
      dataIndex: "ctSum",
      title: "寄倉轉單總額（含稅）",
    },
    {
      key: "ctDiscount",
      dataIndex: "ctDiscount",
      title: "寄倉轉單折扣（含稅）",
    },
    {
      key: "ntCtDiscount",
      dataIndex: "ntCtDiscount",
      title: "寄倉轉單折扣（未稅）",
    },
    {
      key: "ntCtCommission",
      dataIndex: "ntCtCommission",
      title: "寄倉轉單抽成 (未稅)",
    },
    {
      key: "ntCtPlatformFee",
      dataIndex: "ntCtPlatformFee",
      title: "寄倉轉單平台維護費（未稅）",
    },
    {
      key: "ntCtPaymentFee",
      dataIndex: "ntCtPaymentFee",
      title: "寄倉轉單金流處理費（未稅）",
    },
    {
      key: "ntCtMarketingFee",
      dataIndex: "ntCtMarketingFee",
      title: "寄倉轉單行銷推廣費（未稅）",
    },
    {
      key: "ntCtSponsorFee",
      dataIndex: "ntCtSponsorFee",
      title: "寄倉轉單活動贊助費（未稅）",
    },
    {
      key: "ntCtShippingFee",
      dataIndex: "ntCtShippingFee",
      title: "寄倉運費攤提（未稅）",
    },
    {
      key: "ntCtProcessFee",
      dataIndex: "ntCtProcessFee",
      title: "寄倉訂單處理費（未稅）",
    },
    {
      key: "shippedAt",
      dataIndex: "shippedAt",
      title: "出貨時間",
    },
    {
      key: "pickedAt",
      dataIndex: "pickedAt",
      title: "取件時間",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "訂單內容",
      render: (_, values) => <PointerText onClick={() => onClick(values.id)}>檢視</PointerText>,
    },
  ];

  const onClick = (id: number) => {
    setOpenPopup(true);
    setSubOrderId(id);
  };

  const setPageLimit = (value: SelectValue) => {
    dispatch(setInvoiceFilter({ ...invoiceFilter, limit: parseInt(value as string, 10) }));
  };
  const handleOnExport = () => {
    dispatch(exportVPCSalesList(invoiceFilter));
  };

  useEffect(() => {
    dispatch(fetchInvoiceList(invoiceFilter));
  }, [dispatch, invoiceFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetInvoiceSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="收入(發票)清單" />
      <Row justify="space-between">
        <ButtonWrapper>
          <TopButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </TopButton>
          <TopButton onClick={handleOnExport}>匯出</TopButton>
        </ButtonWrapper>
        <Row align="middle">
          <PageText>{`總共${invoiceList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <Filter />}
      {openPopup && <OrderPopup subOrderId={subOrderId} onClose={() => setOpenPopup(false)} />}

      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={invoiceList.results}
          pagination={{
            pageSize: invoiceFilter.limit,
            current: page,
            showSizeChanger: false,
            total: invoiceList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
