import saleApi, { FetchSaleListParams, SaleListResult } from "@api/saleApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import tagApi, { TagListResult, FetchTagListParam } from "@api/tagApi";
import { StandardResponse } from "src/types";
import topicApi, { SalesCategoryOrigin } from "@api/topicApi";
import slotApi from "@api/slotApi";
import { fetchSlotTargetList } from "./slotSlice";

interface IState {
  isFetchingBrand: boolean;
  brandListResult: TagListResult;
  brandListParam: FetchTagListParam;
  isFetchingSaleList: boolean;
  saleListResult: SaleListResult;
  saleListFilter: FetchSaleListParams;
  selectedSalePageIds: number[];
  salesCategories: {
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  };
}

const initialState: IState = {
  isFetchingBrand: false,
  brandListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  isFetchingSaleList: false,
  saleListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  saleListFilter: {
    limit: 50,
    offset: 0,
  },
  selectedSalePageIds: [],
  salesCategories: {
    level1: [],
    level2: [],
    level3: [],
  },
};

export const fetchBrandList = createAsyncThunk("slotAddSalesSlice/fetchBrandList", async (name: string, thunkApi) => {
  const params: FetchTagListParam = {
    typeNameQ: "品牌",
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("slotAddSalesSlice/loadMoreBrandList", async (_, thunkApi) => {
  const {
    slotAddSalesSlice: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchTagListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const fetchSaleList = createAsyncThunk("slotAddSalesSlice/fetchSaleList", async (_, thunkApi) => {
  const {
    slotAddSalesSlice: { saleListFilter },
  } = thunkApi.getState() as RootState;

  const fetchParams = {
    ...saleListFilter,
  };
  const response = await saleApi.fetchSaleList(fetchParams);
  return response;
});

export const clearSaleListFilter = createAsyncThunk("slotAddSalesSlice/clearSaleListFilter", async (_, thunkApi) => {
  const {
    slotAddSalesSlice: { saleListFilter },
  } = thunkApi.getState() as RootState;

  const celaredFilter = {
    offset: 0,
    limit: saleListFilter.limit,
  };

  thunkApi.dispatch(updateSaleListFilter(celaredFilter));
  thunkApi.dispatch(fetchSaleList());
});

export const batchAddSalePages = createAsyncThunk<StandardResponse>(
  "slotAddSalesSlice/batchAddSalePages",
  async (_, thunkApi) => {
    const {
      slot: { slotDetail },
      slotAddSalesSlice: { selectedSalePageIds },
    } = thunkApi.getState() as RootState;
    const { id: slotId } = slotDetail;

    const response = await slotApi.batchAddSalePages(slotId, selectedSalePageIds);
    thunkApi.dispatch(fetchSlotTargetList());
    return response;
  },
);

export const fetchSalesCategoryList = createAsyncThunk("slotAddSalesSlice/fetchSalesCategoryList", async () => {
  const response = await topicApi.fetchSalesCategories();
  return response;
});

const slotAddSalesSlice = createSlice({
  name: "slotAddSalesSlice",
  initialState,
  reducers: {
    reset: () => initialState,
    updateBrandListParam: (state, action: PayloadAction<FetchTagListParam>) => {
      state.brandListParam = action.payload;
    },
    updateSaleListFilter: (state, action: PayloadAction<FetchSaleListParams>) => {
      state.saleListFilter = action.payload;
    },
    updateSelectedSalePageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSalePageIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetchingBrand = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.isFetchingBrand = false;
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchSaleList.pending, (state) => {
      state.isFetchingSaleList = true;
    });
    builder.addCase(fetchSaleList.fulfilled, (state, action) => {
      state.isFetchingSaleList = false;
      state.saleListResult = action.payload;
    });
    builder.addCase(fetchSalesCategoryList.fulfilled, (state, action) => {
      state.salesCategories = action.payload;
    });
  },
});

export const {
  reset,
  updateBrandListParam,
  updateSaleListFilter,
  updateSelectedSalePageIds,
} = slotAddSalesSlice.actions;

export default slotAddSalesSlice.reducer;
