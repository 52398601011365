import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, DatePicker, Form, Input, InputNumber, Select, Spin } from "antd";
import PopupBackground from "@component/PopupBackground";
import { useDispatch, useSelector } from "react-redux";
import { financeOrderState, fetchRetailList } from "@redux/financeOrderSlice";
import { FinanceOrderParams } from "@api/financeOrderApi";
import moment, { Moment } from "moment";

const FormWrapper = styled(Form)`
  width: 450px;
  padding: 20px;
  background-color: ${({ theme }) => theme.colorNeutral100};
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
  .ant-form-item {
    margin-bottom: 15px;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

type Props = {
  onClose: () => void;
  setShowImportPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setOrderData: React.Dispatch<React.SetStateAction<FinanceOrderParams>>;
};
const { Option } = Select;

const EditOrderForm = (props: Props) => {
  const dispatch = useDispatch();
  const { onClose, setShowImportPopup, setOrderData } = props;
  const { retailList, isRetailListFetching } = useSelector(financeOrderState);
  const retailListResult = retailList.results;

  const [form] = Form.useForm();
  const contractTypeForm = Form.useWatch("contractType", form);

  const onSubmit = (values: FinanceOrderParams) => {
    setShowImportPopup(true);
    const payload = {
      ...values,
      accountDate: values.accountDate && moment(values.accountDate).format("YYYY-MM-DD"),
    };
    setOrderData(payload);
  };

  useEffect(() => {
    if (contractTypeForm) {
      dispatch(fetchRetailList({ contractTypes: [contractTypeForm] }));
    }
  }, [dispatch, contractTypeForm]);

  const disabledDate = (currentDate: Moment) => {
    return currentDate && currentDate > moment().endOf("day");
  };

  return (
    <PopupBackground close={onClose}>
      <Spin spinning={isRetailListFetching}>
        <FormWrapper
          form={form}
          labelCol={{ span: 7 }}
          labelAlign="left"
          colon={false}
          onFinish={(values: unknown) => onSubmit(values as FinanceOrderParams)}
        >
          <Form.Item label="類型" name="contractType" rules={[{ required: true, message: "" }]}>
            <Select
              placeholder="請選擇"
              style={{ width: 250 }}
              onChange={() => form.setFieldsValue({ retailerId: undefined })}
            >
              <Option value={20}>寄倉</Option>
              <Option value={30}>轉單</Option>
            </Select>
          </Form.Item>
          <Form.Item label="供應/經銷商" name="retailerId" rules={[{ required: true, message: "" }]}>
            <Select placeholder="請選擇" style={{ width: 250 }} disabled={!contractTypeForm}>
              {retailListResult.map((retailer) => {
                return (
                  <Option key={retailer.id} value={retailer.id}>
                    {retailer.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="發票號碼" name="invoiceNumber" rules={[{ required: true, message: "" }]}>
            <Input placeholder="請輸入發票號碼" style={{ width: 250 }} />
          </Form.Item>
          <Form.Item label="發票開立日" name="accountDate" rules={[{ required: true, message: "" }]}>
            <DatePicker placeholder="請選擇日期" style={{ width: 250 }} disabledDate={disabledDate} />
          </Form.Item>
          <Form.Item label="訂單總金額(含稅)" name="totalAmount" rules={[{ required: true, message: "" }]}>
            <InputNumber placeholder="訂單總金額(含稅)" min={0} style={{ width: 250 }} />
          </Form.Item>
          <Footer>
            <Button style={{ marginRight: "14px" }} onClick={() => onClose()}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              下一步
            </Button>
          </Footer>
        </FormWrapper>
      </Spin>
    </PopupBackground>
  );
};

export default EditOrderForm;
