import downloadXLS from "@utils/downloadXLS";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface SupplyChainApi {
  fetchSupplyChainList: (params: SupplyChainFilterParam) => Promise<SupplyChainList>;
  /**
   * 供應商
   */
  fetchSupplyChainDetail: (id: number) => Promise<SupplyChainDetail>;
  fetchSearchSupplyChainList: (nameQ: string) => Promise<SupplyChainList>;
  fetchOperationStaff: () => Promise<StaffList>;
  fetchTryallOperationStaff: () => Promise<StaffList>;
  createSupplyChain: (params: CreateSupplyChainParam) => Promise<SupplyChainDetail>;
  updateSupplyChain: (params: UpdateSupplyChainParam) => Promise<SupplyChainList>;
  updateOperationStaff: (params: { supplyChainIds: number[]; staffId: number }) => Promise<StaffList>;
  deleteSupplyChain: (id: number) => Promise<void>;
  fetchVendorList: (params: VenderSearchParam) => Promise<VendorListResult>;
  fetchVendorDetail: (id: number) => Promise<VendorListItem>;
  fetchSearchVendor: (name: string) => Promise<VendorListResult>;
  /**
   * 供應商聯絡人
   */
  fetchSupplyChainContactList: (params: SupplyChainFilterParam) => Promise<SupplyChainContactList>;
  fetchSupplyChainContactDetail: (id: number) => Promise<SupplyChainContactDetail>;
  createSupplyChainContact: (params: CreateSupplyChainContactParam) => Promise<SupplyChainContactList>;
  updateSupplyChainContact: (params: UpdateSupplyChainContactParam) => Promise<SupplyChainContactList>;
  updateMajorContact: (params: UpdateMajorContactParam) => Promise<SupplyChainContactList>;
  deleteSupplyChainContact: (id: number) => Promise<void>;
  /**
   * 供應商合約
   */
  fetchSupplyChainContractList: (params: SupplyChainFilterParam) => Promise<SupplyChainContractList>;
  updateSupplyChainContract: (params: SupplyChainContractDetail) => Promise<SupplyChainContractList>;
  createSupplyChainContract: (params: CreateSupplyChainContractParam) => Promise<SupplyChainContractList>;
  deleteSupplyChainContract: (id: number) => Promise<void>;
  /**
   * 供應商通路
   */
  fetchSupplyChainRetailList: (params: SupplyChainFilterParam) => Promise<SupplyChainRetailList>;
  deleteRetailer: (id: number) => Promise<void>;
  fetchRetailRegionList: (id: number) => Promise<RetailRegionList>;
  fetchRetailList: (id: number) => Promise<RetailList>;
  fetchRetailerProductLineList: (id: number) => Promise<RetailerProductLineList>;
  fetchContractList: (params: ContractParam) => Promise<ContractList>;
  fetchRetailerProductLineRelation: (params: RelationParam) => Promise<RetailerProductLineList>;
  createSupplyChainRetailer: (params: CreateSupplyChainRetailerParam) => Promise<SupplyChainRetailerDetail>;
  /**
   * 供應商通路商品
   */
  fetchRetailerProductList: (params: SupplyChainFilterParam) => Promise<RetailerProductList>;
  fetchFilterRetailerProductList: (params: RetailerProductFilterParam) => Promise<RetailerProductList>;
  createRetailerProduct: (params: CreateRetailerProductParam) => Promise<RetailerProductList>;
  updateRetailerProduct: (params: UpdateRetailerProductParam) => Promise<RetailerProductList>;
  deleteRetailerProduct: (id: number) => Promise<void>;
  batchImportSupplyChainProductByXLS: (id: number, file: File) => Promise<StandardResponse>;
  batchExportSupplyChainProduct: (filterParams: SupplyChainProductParams) => Promise<void>;
}

/**
 * 供應商
 */

export enum Currency {
  TWD = 1, // 台幣
  USD = 2, // 美金
  RMB = 3, // 人民幣
  EUR = 4, // 歐元
  AUD = 5, // 澳幣
  YEN = 6, // 日圓
  WON = 7, // 韓元
  GBP = 8, // 英鎊
}

export const currencyType = (type: Currency) => {
  switch (type) {
    case Currency.TWD:
      return "TWD 台幣";
    case Currency.USD:
      return "USD 美金";
    case Currency.RMB:
      return "RMB 人民幣";
    case Currency.EUR:
      return "EUR 歐元";
    case Currency.AUD:
      return "AUD 澳幣";
    case Currency.YEN:
      return "YEN 日圓";
    case Currency.WON:
      return "WON 韓元";
    case Currency.GBP:
      return "GBP 英鎊";
    default:
      return "";
  }
};

export enum Region {
  Domestic = 1,
  Foreign = 2,
}

export const regionType = (type: Region) => {
  switch (type) {
    case Region.Domestic:
      return "台灣本地";
    case Region.Foreign:
      return "境外通路";
    default:
      return "";
  }
};

export interface VenderSearchParam {
  nameQ?: string;
}

export interface SupplyChainList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SupplyChainDetail[];
}

export interface SupplyChainFilterParam {
  limit?: number;
  offset?: number;
  nameQ?: string;
  supplyChain?: number;
}
export interface RetailerProductListFilterParam extends SupplyChainFilterParam {
  supplyChainRetailerProductlineRelation: number;
}

export interface SupplyChainDetail {
  id: number;
  name: string;
  address: string;
  phone: string;
  ubn: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  currency: number | string;
  staff: string;
}
export interface StaffList {
  count: number;
  next: string | null;
  previous: string | null;
  results: StaffListItem[];
}
export interface StaffListItem {
  id: number;
  name: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  createdAt: string;
}

export interface CreateSupplyChainParam {
  staff: string;
  name: string;
  address: string;
  phone: string;
  ubn: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  currency: number;
}
export interface UpdateSupplyChainParam {
  id?: number;
  staff: number | string;
  staffId: number;
  name: string;
  address: string;
  phone: string;
  ubn: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  branchName: string;
  currency: number;
}

/**
 * 供應商聯絡人
 */
export interface SupplyChainContactList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SupplyChainContactDetail[];
}

export interface SupplyChainContactDetail {
  id: number;
  name: string;
  title: string;
  phone: string;
  email: string;
  isMajor: boolean;
  supplyChain: number;
}

export interface CreateSupplyChainContactParam {
  name: string;
  phone: string;
  title: string;
  email: string;
  supplyChain: number;
  isMajor?: boolean;
}

export interface UpdateSupplyChainContactParam {
  id: number;
  name: string;
  phone: string;
  title: string;
  email: string;
  supplyChain: number;
  isMajor?: boolean;
}

export interface UpdateMajorContactParam {
  supplyChain: number;
  id: number;
  isMajor: boolean;
}

/**
 * 供應商合約
 */

export interface SupplyChainContractList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SupplyChainContractDetail[];
}

export interface SupplyChainContractDetail {
  id: number;
  pdfDirPrefix: string;
  pdfFilePath?: string;
  name: string;
  startAt: string;
  endAt: string;
  supplyChain: number;
  media?: string;
}
export interface CreateSupplyChainContractParam {
  pdfDirPrefix: string;
  pdfFilePath?: string | null;
  name: string;
  startAt: string;
  endAt: string;
  supplyChain: number;
  media?: string;
}
/**
 * 供應商通路
 */
export interface SupplyChainRetailList {
  count: number;
  next: string | null;
  previous: string | null;
  results: SupplyChainRetailerDetail[];
}
export interface SupplyChainRetailerDetail {
  id: number;
  regionType: number;
  regionName: string;
  retailer: string;
  productLine: string;
  currency: number;
  contract: {
    startAt: string;
    endAt: string;
    name: string;
    path: string;
  };
}

export interface Contract {
  startAt: string;
  endAt: string;
  name: string;
  path: string;
}

export interface RetailRegionList {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailRegion[];
}

export interface RetailRegion {
  id: number;
  name: string;
  regionType: number;
}

export interface RetailList {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailDetail[];
}
export interface RetailDetail {
  id: number;
  name: string;
  address: string;
  phone: string;
  region: {
    id: number;
    name: string;
    regionType: number;
  };
}

export interface RetailerProductLineList {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailerProductLineDetail[];
}

export interface RetailerProductLineDetail {
  id: number;
  retailer: {
    id: number;
    name: string;
    address: string;
    phone: string;
    region: {
      id: number;
      name: string;
      regionType: number;
    };
  };
  productLine: {
    id: number;
    name: string;
  };
  currency: number;
  contractType: number;
  memo: string;
  daysAfterMonthlyStatement: number;
}

export interface ContractList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContractDetail[];
}
export interface ContractDetail {
  id: number;
  productlineInfo: {
    id: number;
    name: string;
  };
  contractInfo: {
    startAt: string;
    endAt: string;
    name: string;
    path: string;
  };
}

export interface ContractParam {
  retailerId: number | undefined;
  productLineId: number | undefined;
}

export interface DeleteContractParam {
  id: number;
  supplyChain: number;
}
export interface DeleteContactParam {
  id: number;
  supplyChain: number;
}
export interface RelationParam {
  retailerId: number | undefined;
  productLineNumber: number | undefined;
}

export interface CreateSupplyChainRetailerParam {
  retailerProductline: number;
  supplyChain: number;
}
export interface DeleteSupplyChainRetailerParam {
  id: number;
  supplyChain: number;
}

export interface RetailerProductLineRelationList {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailerProductLineRelationDetail[];
}
export interface RetailerProductLineRelationDetail {
  id: number;
  retailer: {
    id: number;
    name: string;
    address: string;
    phone: string;
    region: {
      id: number;
      name: string;
      regionType: number;
    };
  };
  productLine: {
    id: number;
    name: string;
  };
  currency: number;
  contractType: number;
  memo: string;
  daysAfterMonthlyStatement: number;
}

/**
 * 供應商通路商品
 */
export interface RetailerProductList {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailerProductDetail[];
}

export interface RetailerProductDetail {
  id: number;
  currency: number;
  sku: string;
  name: string;
  nameEng: string | null;
  price: string | null;
  cost: string | null;
  quotedPrice: string | null;
  canAcceptDays: string | null;
  pcsPerCarton: string | null;
}
export interface RetailerProductFilterParam {
  supplyChainRetailerProductlineRelation?: number;
  ordering?: string;
  nameQ?: string;
  nameEngQ?: string;
  sku?: string;
  limit?: number;
  offset?: number;
}

export interface CreateRetailerProductParam {
  sku: string;
  no: string;
  name: string;
  nameEng: string;
  price: number;
  cost: number;
  quotedPrice: number;
  canAcceptDays: number;
  pcsPerCarton: number;
  supplyChainRetailerProductlineRelation: number;
}
export interface UpdateRetailerProductParam {
  id: number;
  currency: number;
  sku: string;
  no?: string;
  name: string;
  nameEng: string;
  price: number;
  cost: number;
  quotedPrice: number;
  canAcceptDays: number;
  pcsPerCarton: number;
  supplyChainRetailerProductlineRelation: number;
}

export interface DeleteRetailerProductParam {
  id: number;
  supplyChainRetailerProductlineRelation: number;
}
export interface AddSupplyChainProductByCSVParam {
  supplyChainRetailerProductlineRelation: number;
  formData?: FormData;
  file: File | undefined;
}
export interface SupplyChainProductParams {
  productIds: string;
  ordering?: string;
  price?: number;
  quotedPrice?: number;
  cost?: number;
}
export interface VendorListResult {
  count: number;
  next: string;
  previous: string;
  results: VendorListItem[];
}
export interface VendorListItem {
  bankAccount: { id: number; accountName: string; accountNumber: string; bankName: string; branchName: null };
  businessAddress: null;
  createdAt: string;
  currency: number;
  endAt: null;
  id: number;
  isActive: boolean;
  majorContact: { id: number; name: string; title: null; phone: string; email: string };
  name: string;
  owner: { id: number; name: string; username: string; firstName: string; lastName: string };
  phone: string;
  productQty: number;
  ubn: null;
}
export interface VendorListParams {
  limit: number;
  offset: number;
  startDateBefore?: string;
  startDateAfter?: string;
  endDateAfter?: string;
  endDateBefore?: string;
  vendorName?: string;
  ownerId?: number;
}

const supplyChainApi: SupplyChainApi = {
  fetchSupplyChainList: async (param) => {
    const response = await apiClient.getData("/manage/retail/supply-chain/", param);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSearchSupplyChainList: async (nameQ: string) => {
    const response = await apiClient.getData(`/manage/retail/supply-chain/?name_q=${nameQ}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchOperationStaff: async () => {
    const response = await apiClient.getData("/manage/account/staffs?department_code=5", {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchTryallOperationStaff: async () => {
    const response = await apiClient.getData(
      "/manage/account/staffs/?limit=100&offset=0&staff_group_permission=tryall_product_owner",
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSupplyChainDetail: async (id) => {
    const response = await apiClient.getData(`/manage/retail/supply-chain/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  createSupplyChain: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.postData("/manage/retail/supply-chain/", requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateSupplyChain: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.patchData(`/manage/retail/supply-chain/${param.id}/`, requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateOperationStaff: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.putData("/manage/retail/supply-chain/batch_update/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteSupplyChain: async (id) => {
    await apiClient.deleteData(`/manage/retail/supply-chain/${id}/`, {});
  },
  fetchSearchVendor: async (name: string) => {
    const response = await apiClient.getData(`/manage/vendor/vendors/?name_q=${name}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchVendorList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/vendor/vendors/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVendorDetail: async (id: number) => {
    const response = await apiClient.getData(`/manage/vendor/vendors/${id}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  /**
   * 供應商聯絡人
   */
  fetchSupplyChainContactList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/retail/supply-chain-contact/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSupplyChainContactDetail: async (id) => {
    const response = await apiClient.getData(`/manage/retail/supply-chain-contact/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteSupplyChainContact: async (id) => {
    await apiClient.deleteData(`/manage/retail/supply-chain-contact/${id}/`, {});
  },
  updateSupplyChainContact: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/retail/supply-chain-contact/${params.id}/`, requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateMajorContact: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/retail/supply-chain-contact/${params.id}/`, requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  createSupplyChainContact: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/retail/supply-chain-contact/", requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  /**
   * 供應商合約
   */
  fetchSupplyChainContractList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/retail/supply-chain-contract/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateSupplyChainContract: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/retail/supply-chain-contract/${params.id}/`, requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  createSupplyChainContract: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/retail/supply-chain-contract/", requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteSupplyChainContract: async (id: number) => {
    await apiClient.deleteData(`/manage/retail/supply-chain-contract/${id}/`, {});
  },
  /**
   * 供應商通路
   */
  fetchSupplyChainRetailList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/retail/supply-chain-retailers/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteRetailer: async (id: number) => {
    await apiClient.deleteData(`/manage/retail/supply-chain-retailers/${id}/`, {});
  },
  fetchRetailRegionList: async (regionTypeId: number) => {
    const response = await apiClient.getData(`/manage/retail/regions/?region_type=${regionTypeId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRetailList: async (regionId: number) => {
    const response = await apiClient.getData(`/manage/retail/retailers?region=${regionId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRetailerProductLineList: async (retailerId: number) => {
    const response = await apiClient.getData(`/manage/retail/retailer-relations?retailer=${retailerId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchContractList: async (params) => {
    const { retailerId, productLineId } = params;
    const response = await apiClient.getData(
      `/manage/retail/retailer-productlines-contract/?retailer=${retailerId}&product_line=${productLineId}`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRetailerProductLineRelation: async (params) => {
    const { retailerId, productLineNumber } = params;
    const response = await apiClient.getData(
      `/manage/retail/retailer-relations?retailer=${retailerId}&product_line=${productLineNumber}`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  createSupplyChainRetailer: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/retail/supply-chain-retailers/", requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  /**
   * 供應商通路商品
   */
  fetchRetailerProductList: async (params: SupplyChainFilterParam) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/retail/supply-chain-products/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchFilterRetailerProductList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/retail/supply-chain-products/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createRetailerProduct: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/retail/supply-chain-products/", requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateRetailerProduct: async (params) => {
    const requesParam = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/retail/supply-chain-products/${params.id}/`, requesParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteRetailerProduct: async (id: number) => {
    await apiClient.deleteData(`/manage/retail/supply-chain-products/${id}/`, {});
  },

  batchImportSupplyChainProductByXLS: async (id, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.putData(`/manage/retail/supply-chain-products/${id}/upload/`, formData);

    return response.data;
  },
  batchExportSupplyChainProduct: async (filterParams: SupplyChainProductParams) => {
    const params = transformCamelToSnake(filterParams);
    const response = await apiClient.getData("/manage/retail/supply-chain-products/xls-export/", params, {
      responseType: "blob",
    });
    downloadXLS(response);
  },
};

export default supplyChainApi;
