import { CloseCircleTwoTone, ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { SaleState, setAddSpecification, setUpdateSpecification, Spec, Specification } from "@redux/saleSlice";
import { Button, Col, Input, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import SpecificationModal from "./SpecificationModal";

const Wrapper = styled.div`
  padding: 15px 34px 14px 24px;
  border: solid 1px #f0f0f0;
  margin-bottom: 20px;
`;

const CustomRow = styled(Row)`
  margin-bottom: 14px;
`;

const Text = styled.span`
  margin-right: 8px;
  ${fontStyle("14px", "16px")};
`;

const CustomCol = styled(Col)`
  margin-bottom: 10px;
`;

const Field = styled.div`
  width: 108px;
  height: 54px;
  padding: 16px;
  background: "##FAFAFA";
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  ${fontStyle("14px", "22px")};
`;

const Unit = styled(Input)`
  width: 192px;
  height: 54px;
  padding: 16px;
  border: 0;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid transparent;
  margin-right: 8px;
  ${fontStyle("14px", "22px")};
`;

const CustomButton = styled(Button)`
  margin-top: 12px;
`;

const SpecificOptionWrapper = styled.div`
  & > div:last-child {
    & > div:nth-child(1) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    & > input:nth-child(2) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
`;

export enum SpecificationList {
  FOOD = "FOOD",
  PRODUCT = "PRODUCT",
}

type Props = {
  type: SpecificationList;
  index: number;
  onDeleteSpecification: () => void;
};

const foodSpecification: Specification[] = [
  {
    id: "calories",
    label: "熱量",
    value: "",
    unit: "大卡",
  },
  {
    id: "protein",
    label: "蛋白質",
    value: "",
    unit: "公克",
  },
  {
    id: "fat",
    label: "脂肪",
    value: "",
    unit: "公克",
  },
  {
    id: "saturatedFat",
    label: "飽和脂肪",
    value: "",
    unit: "公克",
  },
  {
    id: "transFat",
    label: "反式脂肪",
    value: "",
    unit: "公克",
  },
  {
    id: "carbohydrates",
    label: "碳水化合物",
    value: "",
    unit: "大卡",
  },
  {
    id: "sugar",
    label: "糖",
    value: "",
    unit: "公克",
  },
  {
    id: "sodium",
    label: "鈉",
    value: "",
    unit: "毫克",
  },
];

const productSpecification: Specification[] = [
  {
    id: "size",
    label: "尺寸",
    value: "",
    unit: "",
  },
  {
    id: "thickness",
    label: "厚度",
    value: "",
    unit: "",
  },
  {
    id: "specification",
    label: "規格",
    value: "",
    unit: "",
  },
  {
    id: "color",
    label: "顏色",
    value: "",
    unit: "",
  },
  {
    id: "material",
    label: "材質",
    value: "",
    unit: "",
  },
  {
    id: "origin",
    label: "產地",
    value: "",
    unit: "",
  },
];

const mapSpecification = (specification: Specification[]) => {
  const map = new Map<string, Specification>();

  specification.forEach((item) => {
    map.set(item.id, item);
  });

  return map;
};

export default function ProductSpecifications(props: Props) {
  const { type, index, onDeleteSpecification } = props;

  const dispatch = useDispatch();
  const { specification, salePageDetail } = useSelector(SaleState);

  const parseSpec = JSON.parse(salePageDetail?.spec || "[]") as Spec[];
  const specList = parseSpec[index]?.options || [];
  const foodSpecList = specList.filter((item) => !!item.unit);
  const productSpecList = specList.filter((item) => !item.unit);

  const [optionModal, setOptionModal] = useState<SpecificationList | undefined>();
  const [init, setInit] = useState<boolean>(false);
  const [foodMap, setFoodMap] = useState<Map<string, Specification>>(() =>
    mapSpecification(foodSpecList.length > 0 ? foodSpecList : foodSpecification),
  );
  const [productMap, setProductMap] = useState<Map<string, Specification>>(() =>
    mapSpecification(productSpecList.length > 0 ? productSpecList : productSpecification),
  );

  /** 更新 Map state */

  const addFoodMap = (spec: { label: string; unit: string }) => {
    const hash = Math.random().toString(36).substring(4);
    const newMap = new Map(foodMap.set(hash, { ...spec, id: hash, value: "" }));
    setFoodMap(newMap);
    updateSpecification(newMap);
  };

  const addProductMap = (spec: { label: string; unit: string }) => {
    const hash = Math.random().toString(36).substring(4);
    const newMap = new Map(productMap.set(hash, { ...spec, id: hash, value: "" }));
    setProductMap(newMap);
    updateSpecification(newMap);
  };

  const updateFoodMap = (item: Specification, value: string) => {
    const newMap = new Map(foodMap.set(item.id, { ...item, value }));
    setFoodMap(newMap);
    updateSpecification(newMap);
  };

  const updateProductMap = (item: Specification, value: string) => {
    const newMap = new Map(productMap.set(item.id, { ...item, value }));
    setProductMap(newMap);
    updateSpecification(newMap);
  };

  const deleteFoodMap = (id: string) => {
    const newMap = new Map(foodMap);
    newMap.delete(id);
    setFoodMap(newMap);
    updateSpecification(newMap);
  };

  const deleteProductMap = (id: string) => {
    const newMap = new Map(productMap);
    newMap.delete(id);
    setProductMap(newMap);
    updateSpecification(newMap);
  };

  const onAddOption = (spec: { label: string; unit: string }) => {
    switch (type) {
      case SpecificationList.FOOD:
        addFoodMap(spec);
        break;
      case SpecificationList.PRODUCT:
        addProductMap(spec);
        break;
      default:
        break;
    }
  };

  /** 更新規格相關  */

  const updateProductName = (value: string) => {
    const newSpecification = [...specification];
    newSpecification[index] = { ...newSpecification[index], productName: value, type };
    dispatch(setUpdateSpecification(newSpecification));
  };

  const updateProductUnit = (value: string) => {
    const newSpecification = [...specification];
    newSpecification[index] = { ...newSpecification[index], unit: value, type };
    dispatch(setUpdateSpecification(newSpecification));
  };

  const updateSpecification = (map: Map<string, Specification>) => {
    const newSpecification = [...specification];
    newSpecification[index] = { ...newSpecification[index], options: Array.from(map.values()), type };
    dispatch(setUpdateSpecification(newSpecification));
  };

  /** pop up */

  const checkDeleteOption = (fn: () => void) => {
    Modal.confirm({
      title: "你確定要刪除此欄位嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        fn();
      },
    });
  };

  const checkDeleteSpecification = (fn: () => void) => {
    const specText = `規格${(index + 1).toLocaleString("zh-u-nu-hanidec")}`;

    Modal.confirm({
      title: `你確定要刪除${specText}嗎?`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        fn();
      },
    });
  };

  const specificOptions = () => {
    switch (type) {
      case SpecificationList.FOOD:
        return Array.from(foodMap.values()).map((item) => (
          <Row align="middle">
            <Field>{item.label}</Field>
            <Unit placeholder={item.unit} value={item.value} onChange={(e) => updateFoodMap(item, e.target.value)} />
            <CloseCircleTwoTone
              twoToneColor="#1890FF"
              onClick={() => checkDeleteOption(() => deleteFoodMap(item.id))}
            />
          </Row>
        ));
      case SpecificationList.PRODUCT:
        return Array.from(productMap.values()).map((item) => (
          <Row align="middle">
            <Field>{item.label}</Field>
            <Unit placeholder={item.unit} value={item.value} onChange={(e) => updateProductMap(item, e.target.value)} />
            <CloseCircleTwoTone
              twoToneColor="#1890FF"
              onClick={() => checkDeleteOption(() => deleteProductMap(item.id))}
            />
          </Row>
        ));
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!init && !salePageDetail) {
      dispatch(
        setAddSpecification({
          type,
          productName: "",
          unit: "100",
          options: Array.from(type === SpecificationList.FOOD ? foodMap.values() : productMap.values()),
        }),
      );
      setInit(true);
    }
  }, [dispatch, init, type, productMap, foodMap, salePageDetail]);

  return (
    <Wrapper>
      <CustomRow>
        <Text>{`規格${(index + 1).toLocaleString("zh-u-nu-hanidec")}`}</Text>
        <CloseCircleTwoTone twoToneColor="#1890FF" onClick={() => checkDeleteSpecification(onDeleteSpecification)} />
      </CustomRow>
      <CustomCol span={14}>
        <Input
          placeholder="請輸入商品名稱"
          value={specification[index]?.productName || ""}
          onChange={(e) => updateProductName(e.target.value)}
        />
      </CustomCol>
      {type === SpecificationList.FOOD && (
        <CustomCol span={14}>
          <Select style={{ width: "100%" }} defaultValue="100" onChange={(value) => updateProductUnit(String(value))}>
            <Select.Option value="100">每100公克</Select.Option>
          </Select>
        </CustomCol>
      )}
      <SpecificOptionWrapper>{specificOptions()}</SpecificOptionWrapper>
      <CustomButton
        type="primary"
        icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
        onClick={() => setOptionModal(type)}
      >
        新增選項
      </CustomButton>
      {optionModal && <SpecificationModal close={() => setOptionModal(undefined)} onAddOption={onAddOption} />}
    </Wrapper>
  );
}
