import { DeductionList } from "@api/throughShipment/throughShipmentApi";
import PopupBackground from "@component/PopupBackground";
import { fetchCreateDeductionFee, fetchUpdateDeductionFee } from "@redux/dealerSlice";
import { Button, Form, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const RowTitle = styled.div<{ bold?: boolean }>`
  align-self: flex-start;
  width: 100px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const ExtendText = styled.div<{ color?: string }>`
  color: ${({ color }) => color};
  margin-bottom: 24px;
  margin-left: 4px;
`;

type Props = {
  deduction?: DeductionList;
  onClose: () => void;
};

interface FormState {
  name: string;
  rate: string;
  amount: string;
}

export default function EditDeductionFeePopup(props: Props) {
  const { deduction, onClose } = props;

  const { dealerId } = useParams();

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [validateWarning, setValidateWarning] = useState<boolean>(false);

  const onSubmit = (values: FormState) => {
    if (values.rate && values.amount) {
      return;
    }

    if (deduction) {
      dispatch(
        fetchUpdateDeductionFee({
          ...values,
          rate: values.rate ? parseInt(values.rate, 10) : undefined,
          amount: values.amount ? parseInt(values.amount, 10) : undefined,
          deductionId: deduction.id,
          retailerProductline: parseInt(dealerId, 10),
        }),
      );
    } else {
      dispatch(
        fetchCreateDeductionFee({
          ...values,
          rate: values.rate ? parseInt(values.rate, 10) : undefined,
          amount: values.amount ? parseInt(values.amount, 10) : undefined,
          retailerProductline: parseInt(dealerId, 10),
        }),
      );
    }
    onClose();
  };

  useEffect(() => {
    if (deduction) {
      form.setFieldsValue({
        name: deduction.name,
        rate: deduction.rate,
        amount: deduction.amount,
      });
    }
  }, [deduction, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 4 }} form={form} initialValues={{}} onFinish={onSubmit}>
          <Row wrap={false}>
            <RowTitle>
              項目名稱
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="name" rules={[{ required: true, message: "必填" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>比例</RowTitle>
            <RowContent width={200}>
              <Form.Item
                name="rate"
                validateStatus={validateWarning ? "warning" : "validating"}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("amount")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      if (value && getFieldValue("amount")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      setValidateWarning(false);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <ExtendText>%</ExtendText>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>金額</RowTitle>
            <RowContent width={300}>
              <Form.Item
                name="amount"
                validateStatus={validateWarning ? "warning" : "validating"}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value && !getFieldValue("rate")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      if (value && getFieldValue("rate")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      setValidateWarning(false);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <ExtendText color="#A7A7A7">*請換算台幣</ExtendText>
            </RowContent>
          </Row>
          <Row justify="space-between">
            <ExtendText color={validateWarning ? "#EC6922" : "#000000"}>* 比例與金額請擇一輸入</ExtendText>
            <Row>
              <CustomButton onClick={onClose}>取消</CustomButton>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Row>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
