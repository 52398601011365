import { SalePlan } from "@api/saleApi";
import { SalePlanListParams } from "@api/salePlanApi";
import PopupBackground from "@component/PopupBackground";
import { RootState } from "@redux/rootReducer";
import { fetchSalePlanList, reset, updateSalePlanListParams } from "@redux/ruleAddSalePlanSlice";
import { fetchCloneSalePlan } from "@redux/ruleSlice";
import { Button, Input, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { ChangeEvent, FC, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 700px;
  height: 425px;
  background-color: white;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 28px;
`;
const SearchInputWrapper = styled(Input.Group)`
  width: 400px;
`;
const SearchSelect = styled(Select)`
  width: 25%;
`;
const SearchButton = styled(Button)`
  margin-left: 8px;
`;
const Body = styled.div`
  flex: auto;
  overflow: auto;
  padding: 0 28px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 28px;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
interface Props {
  onClose: () => void;
}

enum SearchTypeValue {
  ID,
  NAME,
}

const searchOptions = [
  {
    label: "方案ID",
    value: SearchTypeValue.ID,
  },
  {
    label: "方案名稱",
    value: SearchTypeValue.NAME,
  },
];

const CopyExistingSalePlan: FC<Props> = (props) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const { id: ruleId } = useParams();
  const { isFetching, salePlanListResult, salePlanListParams } = useSelector(
    (state: RootState) => state.ruleAddSalePlan,
  );
  const { results: salePlanList } = salePlanListResult;

  const [searchType, setSearchType] = useState<SearchTypeValue>(SearchTypeValue.ID);
  const [idValue, setIdValue] = useState<string[]>([]);
  const [nameValue, setNameValue] = useState<string>("");
  const [selectedSalePlans, setSelectedSalePlan] = useState<number[]>();

  const handleOnSelect = (value: SelectValue) => {
    setIdValue([]);
    setNameValue("");
    setSearchType(value as SearchTypeValue);
  };

  const handleOnIdChange = (value: string[], option: any) => {
    setIdValue(value);
  };
  const handleOnNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setNameValue(value);
  };

  const handleOnSearch = () => {
    const params: SalePlanListParams = {
      limit: salePlanListParams.limit,
      offset: 0,
      excludeRuleId: Number(ruleId),
    };
    switch (searchType) {
      case SearchTypeValue.ID:
        params.planNameQ = undefined;
        params.planIds = idValue.toString();
        break;
      case SearchTypeValue.NAME:
        params.planIds = undefined;
        params.planNameQ = nameValue;
        break;
      default:
    }
    dispatch(updateSalePlanListParams(params));
    dispatch(fetchSalePlanList());
  };

  const handleCloseWindow = () => {
    dispatch(reset());
    onClose();
  };

  const handleOnNext = () => {
    if (selectedSalePlans) {
      dispatch(fetchCloneSalePlan({ ruleId: parseInt(ruleId, 10), planIds: selectedSalePlans }));
      onClose();
    }
  };

  const handleOnCheckboxClick = (selectedRowKeys: React.Key[], selectedRows: SalePlan[]) => {
    setSelectedSalePlan([...(selectedRowKeys as number[])]);
  };

  const columns: ColumnsType<SalePlan> = [
    {
      title: "方案ID",
      dataIndex: "id",
    },
    {
      title: "方案名稱",
      dataIndex: "name",
    },
    {
      title: "價格",
      dataIndex: "price",
      render: (value) => `$${value}`,
    },
  ];

  return (
    <PopupBackground close={handleCloseWindow} fixed>
      <Wrapper>
        <Header>
          <SearchInputWrapper compact>
            <SearchSelect
              options={searchOptions}
              value={searchType}
              onChange={(value) => handleOnSelect(value as SelectValue)}
            />
            {searchType === SearchTypeValue.ID && (
              <Select
                mode="tags"
                style={{ width: "75%" }}
                onChange={handleOnIdChange}
                open={false}
                tokenSeparators={[","]}
                value={idValue}
              />
            )}
            {searchType === SearchTypeValue.NAME && (
              <Input style={{ width: "75%" }} onChange={handleOnNameChange} value={nameValue} />
            )}
          </SearchInputWrapper>
          <SearchButton onClick={handleOnSearch}>搜尋</SearchButton>
        </Header>
        <Body>
          <Table
            loading={isFetching}
            rowKey="id"
            rowSelection={{
              type: "checkbox",
              onChange: handleOnCheckboxClick,
              selectedRowKeys: selectedSalePlans,
            }}
            columns={columns}
            dataSource={salePlanList}
            sticky
          />
        </Body>
        <Footer>
          <CancelButton onClick={handleCloseWindow}>取消</CancelButton>
          <Button type="primary" onClick={handleOnNext} disabled={selectedSalePlans?.length === 0}>
            確認
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default CopyExistingSalePlan;
