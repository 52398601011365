import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { ImageType } from "@api/saleApi";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import QuillEditor from "@component/QuillEditor";
import UploadImageButton from "@component/UploadImageButton";
import MemberGroupValue from "@constant/MemberGroupValue";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import VendorLogisticsValue from "@page/Order/VendorLogisticsValue";
import { configState } from "@redux/configSlice";
import {
  fetchCreateSalePage,
  fetchSalePage,
  fetchSalesCategories,
  fetchUpdateSalePage,
  SaleState,
  setUpdateSpecification,
  Spec,
  fetchPlanList,
} from "@redux/saleSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Cascader, Checkbox, Col, DatePicker, Form, Input, Modal, Row, Select, Switch } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ProductSpecifications, { SpecificationList } from "./ProductSpecifications";
import generateCategoryTree from "./utils/generateCategoryTree";

const Wrapper = styled(Form)`
  padding: 0px 27px;
  position: relative;
  .ant-form-item-label > label.ant-form-item-no-colon::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-no-colon::after {
    margin: 0;
  }
`;

const CustomTextArea = styled(Input.TextArea)`
  &&& {
    height: 108px;
  }
`;

const SEOTitle = styled.div`
  width: 90%;
  padding-bottom: 12px;
  margin-bottom: 17px;
  border-bottom: solid 1px ${({ theme }) => theme.colorNeutral300};
`;

const Text = styled.div`
  margin-right: 84px;
  ${fontStyle("14px", "16px")};
`;

const CustomButton = styled(Button)`
  margin-right: 16px;
`;

const CustomRow = styled(Row)`
  margin-bottom: 10px;
`;

const CustomDatePicker = styled(DatePicker)`
  max-width: 232.5px;
  width: 100%;
`;

const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const FooterNotice = styled.span`
  margin-right: 12px;
  ${fontStyle("14px", "22px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;

const UpdateFormItem = styled(Form.Item)`
  margin-bottom: 0;
`;

const IDWrapper = styled.div`
  position: relative;
`;

const IDNotice = styled.span`
  position: absolute;
  left: 35%;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ theme }) => theme.colorNeutral400};
`;

const CustomLink = styled(Button)`
  padding: 0;
`;
const InputWrapper = styled.div`
  display: flex;
`;
const CustomHelp = styled.div`
  ${fontStyle("14px", "16px", "400")};
  margin-top: 8px;
  color: ${({ theme }) => theme.colorSecondary500};
  white-space: nowrap;
`;
const ImageRuleText = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral500};
`;
interface ImageInterface {
  alt: string;
  url: string;
  id: number;
  isShow: boolean;
  rank: number;
  imageType?: ImageType;
}

export default function SaleContent() {
  const dispatch = useDispatch();
  const { webDomain } = useSelector(configState);
  const {
    isFetching,
    specification,
    salesCategories,
    salePageDetail,
    createSalePageDetail,
    salePlanListResult: { results: salePlanListResult },
  } = useSelector(SaleState);

  const navigate = useNavigate();
  const { salesId } = useParams();
  const [form] = Form.useForm();
  const [specificationList, setSpecificationList] = useState<SpecificationList[]>([]);
  const [imageList, setImageList] = useState<ImageInterface[]>([
    { alt: "", url: "", id: -1, isShow: true, rank: -1 },
    { alt: "", url: "", id: -1, isShow: true, rank: -1 },
    { alt: "", url: "", id: -1, isShow: true, rank: -1 },
    { alt: "", url: "", id: -1, isShow: true, rank: -1 },
    { alt: "", url: "", id: -1, isShow: true, rank: -1 },
  ]);
  const [categoryListSize, setCategoryListSize] = useState<number>(1);

  const [longQuillHtml, setLongQuillHtml] = useState("");
  const [shortQuillHtml, setShortQuillHtml] = useState("");

  const mediaList = useMemo(() => {
    if (salePageDetail) {
      return salePageDetail.media.filter(
        (item) => item.imageType === ImageType.GENERAL_IMAGE || item.imageType === 3 || item.imageType === null,
      );
    }
    return [];
  }, [salePageDetail]);

  const canPageIsActive = useMemo(() => {
    if (!salesId) return true;
    if (!salePageDetail || salePlanListResult.length === 0) return true;
    return salePlanListResult.filter((plan) => plan.isActive).length === 0;
  }, [salePageDetail, salePlanListResult, salesId]);

  const onAddSpecification = (type: SpecificationList) => {
    setSpecificationList([...specificationList, type]);
  };

  const onDeleteSpecification = (index: number) => {
    const newSpecification = [...specification];
    newSpecification.splice(index, 1);
    dispatch(setUpdateSpecification(newSpecification));

    const newSpecificationList = [...specificationList];
    newSpecificationList.splice(index, 1);
    setSpecificationList(newSpecificationList);
  };

  const onUpdatePicturesAlt = useCallback(
    (index, value: string) => {
      const newArray = [...imageList];
      newArray[index].alt = value;
      setImageList(newArray);
    },
    [imageList],
  );

  const onUpdateStatus = (value: string) => {
    if (value === "inactive") {
      form.setFieldsValue({ isListVisibility: false, isSearchVisibility: false });
    } else {
      form.setFieldsValue({ isListVisibility: true, isSearchVisibility: true });
    }
  };

  const onResetEstimateShippingDate = (value: VendorLogisticsValue) => {
    if (value !== VendorLogisticsValue.preorder) {
      form.resetFields(["estimateShippingDate"]);
    } else if (salePageDetail) {
      form.setFieldsValue({
        estimateShippingDate: salePageDetail.estimateShippingDate
          ? moment(salePageDetail.estimateShippingDate)
          : undefined,
      });
    }
  };

  const onFinishUpload = (index: number, value: string) => {
    const newArray = [...imageList];
    newArray[index].url = value;

    setImageList(newArray);
  };

  const toggleMediaIsShow = (index: number, e: CheckboxChangeEvent) => {
    const { checked } = e.target;
    const newArray = [...imageList];
    newArray[index].isShow = checked;

    setImageList(newArray);
  };

  const updateImageRank = (index: number, value: number) => {
    const newArray = [...imageList];

    newArray[index].rank = value;

    setImageList(newArray);
  };

  const removeSalePageMedia = (index: number) => {
    removeMedia({
      module: DeleteModule.SALES_PAGE,
      salesPageId: salePageDetail?.id,
      mediaId: imageList[index].id,
    });
    const newArray = [...imageList];
    newArray[index].url = "";
    newArray[index].id = -1;

    setImageList(newArray);
  };

  const salesCategoriesTree = useMemo(() => {
    const { level1Map, level2Map, level3Map, treeMap } = generateCategoryTree(salesCategories);
    return {
      level1Map,
      level2Map,
      level3Map,
      tree: Array.from(treeMap.values()),
    };
  }, [salesCategories]);

  const generateUpload = () => {
    const discountImage = salePageDetail?.media.find((item) => item.imageType === ImageType.DISCOUNT_IMAGE);
    return imageList.map((item, index) => {
      const isDiscountImage = item.imageType === ImageType.DISCOUNT_IMAGE;
      return (
        <Row wrap={false} align="top" style={{ width: "80%" }}>
          <Form.Item
            label={index === 0 ? "圖片" : " "}
            labelCol={{ span: 11 }}
            style={{ flex: 1 }}
            required={index === 0}
          >
            <UploadImageButton
              hasPreviewAndDelete={item.id !== -1}
              imageUrl={item.url || ""}
              finishUpload={(url) => onFinishUpload(index, url)}
              editable={!isDiscountImage}
              ratio={[1, 1]}
              limitSize={1}
              onDelete={() => {
                if (isDiscountImage) return;
                Modal.confirm({
                  title: "確定要刪除此圖片嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeSalePageMedia(index);
                  },
                });
              }}
            />
          </Form.Item>
          <Col style={{ flex: 2 }}>
            <InputWrapper>
              <Select
                disabled={isDiscountImage}
                value={item.rank}
                onChange={(value: SelectValue) => {
                  updateImageRank(index, value as number);
                }}
                style={{ width: "20%", marginRight: "10px" }}
              >
                <Select.Option value={-1}>請選擇順序</Select.Option>
                <Select.Option value={1}>1</Select.Option>
                <Select.Option value={2}>2</Select.Option>
                <Select.Option value={3}>3</Select.Option>
                <Select.Option value={4}>4</Select.Option>
                <Select.Option value={5}>5</Select.Option>
              </Select>
              <Checkbox
                checked={item.isShow}
                onChange={(e) => toggleMediaIsShow(index, e)}
                style={{ width: "20%", marginRight: "10px" }}
              >
                顯示於前台
              </Checkbox>
              <Input
                value={item.alt}
                placeholder="請輸入alt"
                onChange={(e) => onUpdatePicturesAlt(index, e.target.value)}
                style={{ width: "20%", marginRight: "10px" }}
              />
            </InputWrapper>
            {item?.imageType === ImageType.DISCOUNT_IMAGE && (
              <CustomHelp>
                此為活動壓框圖片不可編輯，活動期間：{discountImage?.startAt}~{discountImage?.endAt}
              </CustomHelp>
            )}
            <ImageRuleText>長：寬 = 1: 1, 大小上限為1MB</ImageRuleText>
          </Col>
        </Row>
      );
    });
  };

  const generateCategory = (size: number) => {
    return Array.from({ length: size }).map((item, index) => (
      <Form.Item
        label={index === 0 ? "分類" : " "}
        name={`salesCategories-${index}`}
        wrapperCol={{ span: 12 }}
        rules={index === 0 ? [{ required: true, message: "必填" }] : []}
      >
        <Cascader options={salesCategoriesTree.tree} placeholder="請選擇" />
      </Form.Item>
    ));
  };

  const onSubmit = (values: any) => {
    const images = [];

    for (let index = 0; index < 5; index += 1) {
      let imageFilePath = imageList[index].url || "";
      const imageDirPrefix = imageFilePath && "sale/image";

      if (imageFilePath.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)) {
        imageFilePath = "";
      }

      if (imageList[index].id > -1 || imageFilePath) {
        images.push({
          mediaId: imageList[index].id > -1 ? imageList[index].id : null,
          imageDirPrefix,
          imageFilePath,
          imageAlt: imageList[index].alt,
          imageRank: imageList[index].rank > -1 ? imageList[index].rank : undefined,
          isShow: imageList[index].isShow,
        });
      }
    }

    if (images.length === 0) {
      showRequiredFieldsWarning();
      return;
    }

    const categories = [];

    for (let index = 0; index < categoryListSize; index += 1) {
      const category = values[`salesCategories-${index}`];
      if (category?.length > 0) {
        categories.push(category[category.length - 1]);
      }
    }

    const payload = {
      ...values,
      shortDescription: shortQuillHtml,
      description: longQuillHtml,
      images,
      displayVipPrice: !values.displayVipPrice,
      salesCategories: categories,
      isActive: values.isActive === "active",
      estimateShippingDate: moment(values.estimateShippingDate).format("YYYY-MM-DD"),
      spec: specification,
      mattingImage:
        values.mattingImage && !values.mattingImage.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
          ? {
              imageDirPrefix: "sale/image" || values.mattingImage || "",
              imageFilePath: values.mattingImage || "",
              imageAlt: "",
            }
          : undefined,
    };

    if (salesId) {
      dispatch(fetchUpdateSalePage({ salePageId: parseInt(salesId, 10), payload }));
    } else {
      dispatch(fetchCreateSalePage(payload));
    }
  };

  useEffect(() => {
    dispatch(fetchSalesCategories());
  }, [dispatch]);

  useEffect(() => {
    if (salesId) {
      dispatch(fetchSalePage(parseInt(salesId, 10)));
      // 需要planList作為判斷上架是否可開啟的狀態，進入頁面先call，暫時先抓50筆
      dispatch(fetchPlanList({ salesPage: parseInt(salesId, 10), limit: 50, offset: 0 }));
    }
  }, [dispatch, salesId]);

  useEffect(() => {
    if (salePageDetail) {
      // 規格 init
      const specList = JSON.parse(salePageDetail.spec || "[]") as Spec[];
      // 因為type這個欄位掉了，但已經建立不少銷售頁，就先拿比較不會變動的欄位(calories)做其中一個判斷
      // 若有回來修改銷售頁就幫他回壓
      const specTypeList = specList.map((item) =>
        item.options[0]?.id === "calories" || item.type === SpecificationList.FOOD
          ? SpecificationList.FOOD
          : SpecificationList.PRODUCT,
      );
      const specListConvert = specList.map((item) =>
        item.options[0]?.id === "calories" || item.type === SpecificationList.FOOD
          ? { ...item, type: SpecificationList.FOOD }
          : { ...item, type: SpecificationList.PRODUCT },
      );
      setSpecificationList(specTypeList);
      dispatch(setUpdateSpecification(specListConvert));

      // 黑卡限購品 init
      const memberGroup = {
        normal: false,
        vip: false,
        business: false,
      };
      salePageDetail.groupCanBuy.forEach((element) => {
        if (element.groupId === MemberGroupValue.GENERAL) {
          memberGroup.normal = element.value;
        } else if (element.groupId === MemberGroupValue.VIP) {
          memberGroup.vip = element.value;
        } else if (element.groupId === MemberGroupValue.BUSINESS) {
          memberGroup.business = element.value;
        }
      });
      const newArray = [...imageList];
      mediaList.forEach((media, index) => {
        newArray[index] = {
          ...newArray[index],
          url: media.url || "",
          id: media.id || -1,
          alt: media.alt || "",
          isShow: media.isShow,
          rank: media.rank,
          imageType: media.imageType || undefined,
        };
      });

      setImageList(newArray);
      const mattingImage = salePageDetail.media.find((item) => item.imageType === ImageType.MATTING_IMAGE);

      setShortQuillHtml(salePageDetail.shortDescription);
      setLongQuillHtml(salePageDetail.description);

      form.setFieldsValue({
        mattingImage: mattingImage?.url || "",
        isListVisibility: salePageDetail.groupVisibilities[0]?.isListVisibility || false,
        isSearchVisibility: salePageDetail.groupVisibilities[0]?.isSearchVisibility || false,
        isAdult: salePageDetail.isAdult,
        isOnlyVipCanBuy: !memberGroup.normal && !memberGroup.business && memberGroup.vip,
        displayVipPrice: !salePageDetail.displayVipPrice,
        ID: salePageDetail.id,
        isActive: salePageDetail.isActive ? "active" : "inactive",
        pageType: salePageDetail.pageType,
        logistic: salePageDetail.logistic,
        estimateShippingDate: salePageDetail.estimateShippingDate
          ? moment(salePageDetail.estimateShippingDate)
          : undefined,
        name: salePageDetail.name,
        metaTitle: salePageDetail.metaTitle || "",
        metaDescription: salePageDetail.metaDescription || "",
        metaKeywords: salePageDetail.metaKeywords || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salePageDetail]);

  useEffect(() => {
    // categories init
    if (salePageDetail && salesCategoriesTree.level1Map.size > 0) {
      setCategoryListSize(salePageDetail.salesCategories.length || 1);

      try {
        salePageDetail.salesCategories.forEach((item, index) => {
          const category = salePageDetail.salesCategories[index];
          const level3 = category.id;
          const level2 = salesCategoriesTree.level2Map.get(category.parent);
          const level1 = salesCategoriesTree.level1Map.get(level2!.parent);
          form.setFieldsValue({
            [`salesCategories-${index}`]: [level1?.key, level2?.key, level3],
          });
        });
      } catch (error: any) {
        // 如果找不到分類就進這
      }
    }
  }, [form, salePageDetail, salesCategoriesTree]);

  useEffect(() => {
    if (createSalePageDetail) {
      navigate(`/salespage/edit/${createSalePageDetail.id}`);
    }
  }, [createSalePageDetail, navigate]);

  return (
    <Wrapper
      colon={false}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 5 }}
      labelAlign="left"
      form={form}
      initialValues={{ isActive: "inactive" }}
      onFinish={onSubmit}
      onFinishFailed={showRequiredFieldsWarning}
    >
      <Form.Item label="狀態" name="isActive" rules={[{ required: true, message: "必填" }]}>
        <Select defaultValue="inactive" onChange={onUpdateStatus} disabled={canPageIsActive}>
          <Select.Option value="inactive">下架</Select.Option>
          <Select.Option value="active">上架</Select.Option>
        </Select>
      </Form.Item>
      <UpdateFormItem noStyle shouldUpdate>
        {() => (
          <Form.Item label="列表可視性" name="isListVisibility" valuePropName="checked">
            <Switch disabled={form.getFieldValue("isActive") === "inactive"} />
          </Form.Item>
        )}
      </UpdateFormItem>
      <UpdateFormItem noStyle shouldUpdate>
        {() => (
          <Form.Item label="搜尋可視性" name="isSearchVisibility" valuePropName="checked">
            <Switch disabled={form.getFieldValue("isActive") === "inactive"} />
          </Form.Item>
        )}
      </UpdateFormItem>
      <Form.Item label="18禁" name="isAdult" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="黑卡限購品" name="isOnlyVipCanBuy" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Form.Item label="隱藏黑卡會員價" name="displayVipPrice" valuePropName="checked">
        <Switch />
      </Form.Item>
      {form.getFieldValue("ID") ? (
        <IDWrapper>
          <Form.Item label="ID" name="IDResult">
            <CustomLink type="link" onClick={() => window.open(`${webDomain}/salespage/${form.getFieldValue("ID")}`)}>
              {form.getFieldValue("ID")}
            </CustomLink>
          </Form.Item>
        </IDWrapper>
      ) : (
        <IDWrapper>
          <Form.Item label="ID" name="ID">
            <Input disabled />
          </Form.Item>
          <IDNotice>*儲存後將自動帶入</IDNotice>
        </IDWrapper>
      )}
      <Form.Item label="類型" name="pageType" rules={[{ required: true, message: "必填" }]}>
        <Select placeholder="請選擇" disabled={!!salePageDetail}>
          <Select.Option value={SalePageTypeValue.Simple}>單一方案</Select.Option>
          <Select.Option value={SalePageTypeValue.Group}>多方案</Select.Option>
          <Select.Option value={SalePageTypeValue.Any}>任選方案</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="出貨方式" name="logistic" rules={[{ required: true, message: "必填" }]}>
        <Select
          placeholder="請選擇"
          disabled={!!salePageDetail && form.getFieldValue("logistic") === VendorLogisticsValue.transfer}
          onChange={onResetEstimateShippingDate}
        >
          <Select.Option value={VendorLogisticsValue.warehouse}>倉庫</Select.Option>
          <Select.Option disabled={!!salePageDetail} value={VendorLogisticsValue.transfer}>
            廠商
          </Select.Option>
          <Select.Option value={VendorLogisticsValue.preorder}>倉庫預購</Select.Option>
        </Select>
      </Form.Item>
      <UpdateFormItem noStyle shouldUpdate>
        {() => (
          <Form.Item
            label="預期出貨日"
            name="estimateShippingDate"
            rules={
              form.getFieldValue("logistic") === VendorLogisticsValue.preorder
                ? [{ required: true, message: "必填" }]
                : []
            }
          >
            <CustomDatePicker
              locale={locale}
              disabled={form.getFieldValue("logistic") !== VendorLogisticsValue.preorder}
            />
          </Form.Item>
        )}
      </UpdateFormItem>
      <Form.Item label="銷售頁名稱" name="name" wrapperCol={{ span: 12 }} rules={[{ required: true, message: "必填" }]}>
        <Input placeholder="建議重點資訊20字內" />
      </Form.Item>
      {generateCategory(categoryListSize)}
      <Form.Item label=" " name="isOnlyVipCanBuy" valuePropName="checked">
        <Button
          type="primary"
          icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
          onClick={() => setCategoryListSize((prev) => prev + 1)}
        >
          新增分類
        </Button>
      </Form.Item>
      <Row wrap={false} align="top" style={{ width: "80%" }}>
        <Form.Item
          label="去背圖"
          name="mattingImage"
          labelCol={{ span: 11 }}
          style={{ flex: 1 }}
          rules={[{ required: true, message: "必填" }]}
        >
          <UploadImageButton
            ratio={[1, 1]}
            limitSize={1}
            imageUrl={form.getFieldValue("mattingImage") || ""}
            finishUpload={(url) => form.setFieldsValue({ mattingImage: url })}
          />
        </Form.Item>
        <Col style={{ flex: 2 }}>
          <ImageRuleText>長：寬 = 1: 1, 大小上限為1MB</ImageRuleText>
        </Col>
      </Row>
      {generateUpload()}
      <Form.Item
        label="銷售頁描述"
        name="shortDescription"
        wrapperCol={{ span: 16 }}
        rules={[{ required: true, message: "必填" }]}
      >
        <QuillEditor
          height={120}
          toolbarBlacklist={["background", "link", "video", "image", "imageLink"]}
          toolbarName="short-description-toolbar"
          uploadImagePathname="sales_page_assets"
          defaultHtml={shortQuillHtml}
          onChangeQuill={(html) => {
            form.setFieldsValue({
              shortDescription: html,
            });
            setShortQuillHtml(html);
          }}
        />
      </Form.Item>
      <Form.Item
        initialValue=""
        label="銷售頁介紹"
        name="description"
        wrapperCol={{ span: 16 }}
        rules={[{ required: true, message: "必填" }]}
      >
        <QuillEditor
          toolbarName="long-description-toolbar"
          uploadImagePathname="sales_page_assets"
          defaultHtml={longQuillHtml}
          onChangeQuill={(html) => {
            form.setFieldsValue({
              description: html,
            });
            setLongQuillHtml(html);
          }}
        />
      </Form.Item>
      <Row>
        <Text>商品規格</Text>
        <div>
          <CustomRow align="middle">
            <CustomButton
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => onAddSpecification(SpecificationList.PRODUCT)}
            >
              新增
            </CustomButton>
            <Text>尺寸/厚度/規格/顏色/材質/產地...</Text>
          </CustomRow>
          {specificationList.map((type, index) => (
            <ProductSpecifications
              type={type}
              index={index}
              onDeleteSpecification={() => onDeleteSpecification(index)}
            />
          ))}
        </div>
      </Row>
      <SEOTitle>SEO</SEOTitle>
      <Form.Item label="Meta Title" name="metaTitle" wrapperCol={{ span: 16 }}>
        <Input />
      </Form.Item>
      <Form.Item label="Meta Keywords" name="metaKeywords" wrapperCol={{ span: 16 }}>
        <Input />
      </Form.Item>
      <Form.Item label="Meta Description" name="metaDescription" wrapperCol={{ span: 16 }}>
        <CustomTextArea />
      </Form.Item>
      <Footer>
        <FooterNotice>*建立後顯示方案內容頁面</FooterNotice>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={isFetching}
            disabled={salesCategoriesTree.tree.length === 0}
          >
            {salesId ? "儲存此分頁" : "建立資料"}
          </Button>
        </Form.Item>
      </Footer>
    </Wrapper>
  );
}
