import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { TryallProductDailyAggregationsParams, TryallProductDailyAggregationsList } from "@api/financeApi";
import { message } from "antd";
import moment from "moment";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  tryallProductDailyAggregationsList: TryallProductDailyAggregationsList;
  tryallProductDailyAggregationsFilter: TryallProductDailyAggregationsParams;
}

export const initialState: IState = {
  isFetching: false,
  tryallProductDailyAggregationsList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  tryallProductDailyAggregationsFilter: {
    limit: 20,
    offset: 0,
    no: "",
    nameQ: "",
    statsDateBefore: undefined,
    statsDateAfter: undefined,
  },
};

export const fetchTryallProductDailyAggregations = createAsyncThunk(
  "tryallStockManagement/ fetchTryallProductDailyAggregations",
  async (params: TryallProductDailyAggregationsParams, thunkApi) => {
    try {
      const payload = {
        ...params,
        statsDateAfter: params.statsDateAfter || moment().startOf("month").format("YYYY-MM-DD"),
        statsDateBefore: params.statsDateBefore || moment().endOf("month").format("YYYY-MM-DD"),
      };
      const response = await financeApi.fetchTryallProductDailyAggregations(payload);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const exportCSVTryallProductDailyAggregations = createAsyncThunk(
  "tryallStockManagement/exportCSVTryallProductDailyAggregations",
  async (params: any, thunApi) => {
    try {
      const payload = {
        ...params,
        statsDateAfter: params.statsDateAfter || moment().startOf("month").format("YYYY-MM-DD"),
        statsDateBefore: params.statsDateBefore || moment().endOf("month").format("YYYY-MM-DD"),
      };
      await financeApi.exportCSVTryallProductDailyAggregations(payload);
      message.success("匯出資料準備中，由於資料繁多請稍待，成功後會寄信通知");
    } catch (error: any) {
      thunApi.rejectWithValue(error.message);
    }
  },
);

const TryallStockManagementSlice = createSlice({
  name: "tryallStockManagement",
  initialState,
  reducers: {
    resetTryallStockManagementSlice: () => initialState,
    setTryallProductDailyAggregationsFilter: (state, action) => {
      state.tryallProductDailyAggregationsFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTryallProductDailyAggregations.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTryallProductDailyAggregations.fulfilled, (state, action) => {
      state.isFetching = false;
      state.tryallProductDailyAggregationsList = action.payload;
    });
  },
});

export const TryallStockManagementState = (state: RootState) => state.tryallStockManagementSlice;
export const {
  resetTryallStockManagementSlice,
  setTryallProductDailyAggregationsFilter,
} = TryallStockManagementSlice.actions;
export default TryallStockManagementSlice.reducer;
