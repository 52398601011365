import { FilterOutlined } from "@ant-design/icons";
import PageSizeController from "@component/PageSizeController";
import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { fetchMemberList, reset, updateMemberListParams } from "@redux/memberSlice";
import { StyledOutlinedWrapper, StyledSection } from "src/styles/common";
import PageTitle from "@component/PageTitle";
import MemberFilterForm from "./MemberFilterForm";
import MemberList from "./MemberList";

export default function MemberListPage() {
  const { memberListResult, memberListParams } = useSelector((state: RootState) => state.member);
  const dispatch = useAppDispatch();

  const [openFilterForm, setOpenFilterForm] = useState(false);

  const { count } = memberListResult;
  const { limit } = memberListParams;

  useEffect(() => {
    dispatch(fetchMemberList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  function handlePageSizeChange(size: number) {
    dispatch(
      updateMemberListParams({
        ...memberListParams,
        limit: size,
      }),
    );
    dispatch(fetchMemberList());
  }

  function toggleFilter() {
    setOpenFilterForm((prev) => !prev);
  }

  return (
    <StyledSection>
      <PageTitle title="會員資料" />
      <Row style={{ marginBottom: 20 }} align="middle" justify="space-between">
        <Button icon={<FilterOutlined />} onClick={toggleFilter}>
          篩選
        </Button>
        <Row justify="end">
          <PageSizeController value={limit} totalCount={count} handlePageSizeChange={handlePageSizeChange} />
        </Row>
      </Row>
      {openFilterForm && <MemberFilterForm />}
      <StyledOutlinedWrapper>
        <MemberList />
      </StyledOutlinedWrapper>
    </StyledSection>
  );
}
