import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  BindTypeValue,
  CreateRuleParams,
  CustomerTypeValue,
  MemberGroupValue,
  NotifyTypeValue,
  PlatformValue,
  RuleDetail,
  UseTypeValue,
  SalePlan as RuleSalePlan,
} from "@api/ruleApi";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { createRule, deleteRule, fetchRuleDetail, updateRule } from "@redux/ruleSlice";
import { Button, Form, Input, message, Modal, Switch } from "antd";
import moment, { Moment } from "moment";
import qs from "query-string";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { ActionTypeValue } from "../RuleList/actionTypeValue";
import { ConditionTypeValue } from "../RuleList/conditionTypeValue";
import AdditionalPurchase from "./AdditonalPurchase";
import BindTime from "./BindTime";
import GiftSetting from "./GiftSetting";
import LimitSetting from "./LimitSetting";
import ManageMember from "./ManageMember";
import PromoCode from "./PromoCode";
import PromotionSetting from "./PromotionSetting";
import RuleSetting from "./RuleSetting";
import TargetSetting from "./TargetSetting";
import UseTime from "./UseTime";

const Wrapper = styled(Form)`
  padding: 25px 16px 78px 24px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
`;
const SwitchHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;
const SwitchBlock = styled.div`
  display: flex;
  align-items: center;
  margin-right: 42px;
`;
const SwitchTitle = styled.div`
  font-size: 14px;
  margin-right: 10px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const FormWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 38px;
  row-gap: 14px;
  align-items: flex-start;
`;
const FormTitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  line-height: 32px;
  white-space: nowrap;
`;
const StyledInput = styled(Input)<{ width: number }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const Remark = styled.div`
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);
  margin-left: 11px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const NoResizeTextArea = styled(Input.TextArea)`
  resize: none;
`;
const SettingSectionWrapper = styled.div`
  grid-column-start: 2;
  display: grid;
  grid-template-columns: minmax(432px, 1fr) minmax(432px, 1fr);
  grid-template-rows: auto;
  column-gap: 20px;
  row-gap: 14px;
`;
const Footer = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const MarginButton = styled(Button)`
  margin-right: 10px;
`;
interface Props {
  mode: "add" | "edit";
}

export type LocalBindMethod = Omit<
  RuleDetail["bindMethod"],
  "bindOnceStartAt" | "bindOnceEndAt" | "cycleStartDate" | "memberGroups"
> & {
  bindTimeBetween: [Moment, Moment];
  cycleStartDate: Moment;
  memberGroups: number[];
};

export type LocalUseMethod = Omit<RuleDetail["useMethod"], "useOnceStartAt" | "useOnceEndAt"> & {
  useTimeBetween: [Moment, Moment];
};

export type LocalFormType = Omit<RuleDetail, "notifyType" | "bindMethod" | "useMethod"> & {
  bindNotify: boolean;
  expireNotify: boolean;
  bindMethod: LocalBindMethod;
  useMethod: LocalUseMethod;
  recalculateDiscount: boolean;
  conditionAmountUpperBound: number;
  gift: RuleSalePlan[];
};

const RuleEdit: FC<Props> = (props) => {
  const { mode } = props;
  const { id: ruleId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm<LocalFormType>();

  const dispatch = useAppDispatch();
  const { ruleDetail } = useSelector((state: RootState) => state.rule);

  useEffect(() => {
    const query = searchParams.toString();
    // 有query -> 複製
    if (query) {
      const copiedParams = qs.parse(query, { arrayFormat: "bracket" });
      const bindStartTime = !copiedParams.bindOnceStartAt ? undefined : copiedParams.bindOnceStartAt;
      const bindEndTime = !copiedParams.bindOnceEndAt ? undefined : copiedParams.bindOnceEndAt;
      const useStartTime = !copiedParams.useOnceStartAt ? undefined : copiedParams.useOnceStartAt;
      const useEndTime = !copiedParams.useOnceEndAt ? undefined : copiedParams.useOnceEndAt;
      const bindCycleStartDate = !copiedParams.cycleStartDate ? undefined : copiedParams.cycleStartDate;
      const customerType = copiedParams.customerType ? Number(copiedParams.customerType) : CustomerTypeValue.ALL;
      const platform = copiedParams.platform ? Number(copiedParams.platform) : PlatformValue.ALL;
      const memberGroups = copiedParams.memberGroups
        ? (copiedParams.memberGroups as string[]).map((gp) => Number(gp))
        : [MemberGroupValue.GENERAL, MemberGroupValue.VIP];
      const conditionAmount = copiedParams.conditionAmount === "" ? undefined : Number(copiedParams.conditionAmount);
      const conditionAmountUpperBound =
        copiedParams.conditionAmountUpperBound === "" ? undefined : Number(copiedParams.conditionAmountUpperBound);
      const discount = copiedParams.discount === "" ? undefined : Number(copiedParams.discount);
      const discountLimit = copiedParams.discountLimit === "" ? undefined : Number(copiedParams.discountLimit);
      const bindCycles = copiedParams.bindCycles === "" ? undefined : Number(copiedParams.bindCycles);
      const maxBindTimes = copiedParams.maxBindTimes === "" ? undefined : Number(copiedParams.maxBindTimes);
      const useCycles = copiedParams.useCycles === "" ? undefined : Number(copiedParams.useCycles);
      const useBindTimes = copiedParams.useBindTimes === "" ? undefined : Number(copiedParams.useBindTimes);
      const useWithinHours = copiedParams.useWithinHours === "" ? undefined : Number(copiedParams.useWithinHours);
      const maxUseTimes = copiedParams.maxUseTimes === "" ? undefined : Number(copiedParams.maxUseTimes);

      form.setFieldsValue({
        isActive: copiedParams.isActive === "true",
        bindNotify:
          Number(copiedParams.notifyType) === NotifyTypeValue.BIND_NOTIFY ||
          Number(copiedParams.notifyType) === NotifyTypeValue.BOTH,
        expireNotify:
          Number(copiedParams.notifyType) === NotifyTypeValue.EXPIRE_NOTIFY ||
          Number(copiedParams.notifyType) === NotifyTypeValue.BOTH,
        title: copiedParams.title as string,
        description: copiedParams.description as string,
        conditionType: Number(copiedParams.conditionType),
        conditionAmount,
        conditionAmountUpperBound,
        discount,
        discountLimit,
        actionType:
          Number(copiedParams.actionType) === ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT
            ? ActionTypeValue.DISCOUNT
            : Number(copiedParams.actionType),
        isFreeShipping: copiedParams.isFreeShipping === "true",
        bindMethod: {
          memberGroups,
          bindType: Number(copiedParams.bindType),
          bindTimeBetween: [moment(bindStartTime), moment(bindEndTime)],
          bindCycles,
          cycleStartDate: moment(bindCycleStartDate),
          maxBindTimes,
          customerType,
          platform,
        },
        useMethod: {
          useType: Number(copiedParams.useType),
          useTimeBetween: [moment(useStartTime), moment(useEndTime)],
          useCycles,
          useBindTimes,
          useWithinHours,
          maxUseTimes,
        },
        recalculateDiscount: Boolean(copiedParams.recalculateDiscount),
      });
    } else {
      const onSetDefaultActionType = (actionType: ActionTypeValue | null) => {
        switch (actionType) {
          case ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT:
            return ActionTypeValue.DISCOUNT;
          case null:
            return ActionTypeValue.NO_CONDITION;
          default:
            return actionType;
        }
      };

      // default 格式
      const { bindMethod, useMethod, conditionType, actionType } = ruleDetail;
      // 改成form好接的格式
      const bindStartTime = bindMethod.bindOnceStartAt === null ? undefined : bindMethod.bindOnceStartAt;
      const bindEndTime = bindMethod.bindOnceEndAt === null ? undefined : bindMethod.bindOnceEndAt;
      const useStartTime = useMethod && (useMethod.useOnceStartAt === null ? undefined : useMethod.useOnceStartAt);
      const useEndTime = useMethod && (useMethod.useOnceEndAt === null ? undefined : useMethod.useOnceEndAt);
      const bindCycleStartDate = bindMethod.cycleStartDate === null ? undefined : bindMethod.cycleStartDate;
      const customerType = bindMethod.customerType || CustomerTypeValue.ALL;
      const platform = bindMethod.platform || PlatformValue.ALL;
      const memberGroups =
        bindMethod.memberGroups?.length === 0 || !bindMethod.memberGroups
          ? [MemberGroupValue.GENERAL, MemberGroupValue.VIP]
          : bindMethod.memberGroups.map((grp) => grp.id);

      form.setFieldsValue({
        ...ruleDetail,
        conditionType: conditionType === null ? ConditionTypeValue.NO_CONDITION : conditionType,
        actionType: onSetDefaultActionType(actionType),
        bindNotify:
          ruleDetail.notifyType === NotifyTypeValue.BIND_NOTIFY || ruleDetail.notifyType === NotifyTypeValue.BOTH,
        expireNotify:
          ruleDetail.notifyType === NotifyTypeValue.EXPIRE_NOTIFY || ruleDetail.notifyType === NotifyTypeValue.BOTH,
        bindMethod: {
          ...bindMethod,
          bindTimeBetween: [moment(bindStartTime), moment(bindEndTime)],
          cycleStartDate: moment(bindCycleStartDate),
          customerType,
          platform,
          memberGroups,
        },
        useMethod: {
          ...useMethod,
          useTimeBetween: [moment(useStartTime), moment(useEndTime)],
        },
        recalculateDiscount: actionType === ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT,
      });
    }
  }, [searchParams, form, ruleDetail]);

  useEffect(() => {
    if (mode === "edit" && ruleId) {
      dispatch(fetchRuleDetail(Number(ruleId)));
    }
  }, [dispatch, ruleId, mode]);

  useEffect(() => {
    if (mode === "add" && ruleDetail.id) {
      navigate(`/rule/edit/${ruleDetail.id}`);
    }
  }, [ruleDetail, mode, navigate]);

  const handleOnDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteRule());
        navigate("/rule");
      },
    });
  };

  const handleOnCopy = () => {
    const copiedParams = {
      isActive: ruleDetail.isActive,
      notifyType: ruleDetail.notifyType,
      title: ruleDetail.title,
      description: ruleDetail.description,
      conditionType: ruleDetail.conditionType,
      conditionAmount: ruleDetail.conditionAmount,
      conditionAmountUpperBound: ruleDetail.conditionAmountUpperBound,
      discount: ruleDetail.discount,
      discountLimit: ruleDetail.discountLimit,
      actionType: ruleDetail.actionType,
      isFreeShipping: ruleDetail.isFreeShipping,
      // bindMethod
      memberGroups: ruleDetail.bindMethod.memberGroups?.map((grp) => grp.id),
      bindType: ruleDetail.bindMethod.bindType,
      bindOnceStartAt: ruleDetail.bindMethod.bindOnceStartAt,
      bindOnceEndAt: ruleDetail.bindMethod.bindOnceEndAt,
      bindCycles: ruleDetail.bindMethod.bindCycles,
      cycleStartDate: ruleDetail.bindMethod.cycleStartDate,
      maxBindTimes: ruleDetail.bindMethod.maxBindTimes,
      customerType: ruleDetail.bindMethod.customerType,
      platform: ruleDetail.bindMethod.platform,
      // useMethod
      useType: ruleDetail.useMethod.useType,
      useOnceStartAt: ruleDetail.useMethod.useOnceStartAt,
      useOnceEndAt: ruleDetail.useMethod.useOnceEndAt,
      useCycles: ruleDetail.useMethod.useCycles,
      useBindTimes: ruleDetail.useMethod.useBindTimes,
      useWithinHours: ruleDetail.useMethod.useWithinHours,
      maxUseTimes:
        ruleDetail.bindMethod.customerType === CustomerTypeValue.NEW_CUSTOMER ? 1 : ruleDetail.useMethod.maxUseTimes,
      recalculateDiscount: ruleDetail.actionType === ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT,
    };
    const copyQuery = qs.stringify(copiedParams, { arrayFormat: "bracket" });

    window.open(`/rule/add?${copyQuery}`, "_blank");
  };

  const checkCycles = (func: Function) => () => {
    switch (mode) {
      case "edit": {
        const {
          useMethod: { useType, useCycles },
          bindMethod: { bindType, bindCycles },
        } = ruleDetail;

        const formValues = form.getFieldsValue();
        const updatedBindCycyles = formValues.bindMethod.bindCycles;
        const updatedUseCycyles = formValues.useMethod.useCycles;

        const isBindCyclesInValid =
          (bindType === BindTypeValue.CYCLE_IN_DAY ||
            bindType === BindTypeValue.CYCLE_IN_WEEK ||
            bindType === BindTypeValue.CYCLE_IN_MONTH) &&
          updatedBindCycyles! < bindCycles!;
        const isUseCyclesInValid =
          (useType === UseTypeValue.CYCLE_IN_DAY ||
            useType === UseTypeValue.CYCLE_IN_WEEK ||
            useType === UseTypeValue.CYCLE_IN_MONTH) &&
          updatedUseCycyles! < useCycles!;
        if (isUseCyclesInValid || isBindCyclesInValid) {
          Modal.warning({
            title: "領取週期/使用週期次數，僅可往上修改欄位",
            okText: "我知道了",
          });
        } else {
          func();
        }
        break;
      }
      case "add":
        func();
        break;
      default:
    }
  };

  const handleOnSubmit = () => {
    const values = form.getFieldsValue();

    if (
      values.conditionAmount &&
      values.conditionAmountUpperBound &&
      values.conditionAmount > values.conditionAmountUpperBound
    ) {
      message.warning("下限金額不能高於價格上限");
      return;
    }

    let notifyType;
    // bindNotify + expireNotify -> notifyType
    switch (true) {
      case values.bindNotify && values.expireNotify:
        notifyType = NotifyTypeValue.BOTH;
        break;
      case values.bindNotify && !values.expireNotify:
        notifyType = NotifyTypeValue.BIND_NOTIFY;
        break;
      case !values.bindNotify && values.expireNotify:
        notifyType = NotifyTypeValue.EXPIRE_NOTIFY;
        break;
      default:
        notifyType = null;
    }

    let actionType;
    switch (true) {
      case values.recalculateDiscount:
        actionType = ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT;
        break;
      default:
        actionType = values.actionType !== ActionTypeValue.NO_CONDITION ? values.actionType : null;
        break;
    }

    const params: CreateRuleParams = {
      title: values.title,
      description: values.description,
      notifyType,
      conditionType: values.conditionType === ConditionTypeValue.NO_CONDITION ? null : values.conditionType,
      conditionAmount: values.conditionAmount,
      actionType,
      conditionAmountUpperBound: values.conditionAmountUpperBound,
      discount: values.discount,
      discountLimit: actionType === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE ? 1000 : values.discountLimit,
      isFreeShipping: values.isFreeShipping,
      isActive: values.isActive,
      couponMethod: values.couponMethod,
      bindMethod: {
        memberGroupIds: values.bindMethod.memberGroups,
        bindType: values.bindMethod.bindType || UseTypeValue.NO_LIMIT,
        bindOnceStartAt:
          values.bindMethod.bindTimeBetween &&
          moment(values.bindMethod.bindTimeBetween[0]).format("YYYY-MM-DD HH:mm:ss"),
        bindOnceEndAt:
          values.bindMethod.bindTimeBetween &&
          moment(values.bindMethod.bindTimeBetween[1]).format("YYYY-MM-DD HH:mm:ss"),
        bindCycles: values.bindMethod.bindCycles,
        cycleStartDate:
          values.bindMethod.cycleStartDate && moment(values.bindMethod.cycleStartDate).format("YYYY-MM-DD"),
        maxBindTimes: values.bindMethod.maxBindTimes,
        customerType: values.bindMethod.customerType === CustomerTypeValue.ALL ? null : values.bindMethod.customerType,
        platform: values.bindMethod.platform === PlatformValue.ALL ? null : values.bindMethod.platform,
      },
      useMethod: {
        useType: values.useMethod.useType,
        useOnceStartAt:
          values.useMethod.useTimeBetween && moment(values.useMethod.useTimeBetween[0]).format("YYYY-MM-DD HH:mm:ss"),
        useOnceEndAt:
          values.useMethod.useTimeBetween && moment(values.useMethod.useTimeBetween[1]).format("YYYY-MM-DD HH:mm:ss"),
        useCycles: values.useMethod.useCycles,
        useWithinHours: values.useMethod.useWithinHours,
        maxUseTimes: values.useMethod.maxUseTimes,
      },
      promotionId: values.promotionInfo?.id,
      redirectUrl: values.redirectUrl,
    };
    switch (mode) {
      case "add":
        dispatch(createRule(params));
        break;
      case "edit":
        dispatch(updateRule(params));
        break;
      default:
    }
  };

  return (
    <Wrapper<any> colon={false} form={form} onFinish={checkCycles(handleOnSubmit)}>
      <PageTitle title={`優惠案型 - ${mode === "add" ? "新增" : ruleDetail.title}`} />
      <SwitchHeader>
        <SwitchBlock>
          <SwitchTitle>啟用</SwitchTitle>
          <Form.Item name="isActive" noStyle valuePropName="checked">
            <Switch />
          </Form.Item>
        </SwitchBlock>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
        >
          {({ getFieldValue }) => {
            if (getFieldValue("actionType") === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE) {
              return null;
            }
            return (
              <>
                <SwitchBlock>
                  <SwitchTitle>綁定通知</SwitchTitle>
                  <Form.Item name="bindNotify" noStyle valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </SwitchBlock>
                <SwitchBlock>
                  <SwitchTitle>到期通知</SwitchTitle>
                  <Form.Item name="expireNotify" noStyle valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </SwitchBlock>
              </>
            );
          }}
        </Form.Item>
      </SwitchHeader>
      <FormWrapper>
        <FormTitle>ID</FormTitle>
        <Flex>
          <Form.Item name="id" noStyle>
            <StyledInput width={86} disabled />
          </Form.Item>
          <Remark>*儲存後將自動帶入</Remark>
        </Flex>
        <FormTitle>
          優惠名稱
          <RequiredIcon>*</RequiredIcon>
        </FormTitle>
        <Form.Item name="title" rules={[{ required: true, message: "必填" }]}>
          <Input />
        </Form.Item>
        <FormTitle>優惠描述</FormTitle>
        <Form.Item name="description">
          <NoResizeTextArea rows={3} />
        </Form.Item>
        <SettingSectionWrapper>
          <RuleSetting form={form} />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.conditionType !== currentValues.conditionType}
          >
            {({ getFieldValue }) => {
              const conditionType = getFieldValue("conditionType");
              const showPromotionSetting = conditionType !== ConditionTypeValue.NO_CONDITION && mode === "edit";
              if (showPromotionSetting) return <PromotionSetting form={form} />;
              return null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
          >
            {({ getFieldValue }) => {
              const actionType = getFieldValue("actionType");
              const showAdditionalPurchase = actionType === ActionTypeValue.ADDITIONAL_PURCHASE && mode === "edit";
              const showAdditionalPurchasePromotion =
                actionType === ActionTypeValue.ADDITIONAL_PURCHASE_PROMOTION && mode === "edit";
              const showGiftSetting =
                (actionType === ActionTypeValue.NON_BOUNDED_GIFT ||
                  actionType === ActionTypeValue.BOUNDED_GIFT ||
                  actionType === ActionTypeValue.BOUNDED_GIFT_ANY) &&
                mode === "edit";

              if (showAdditionalPurchase) return <AdditionalPurchase />;
              if (showAdditionalPurchasePromotion) return <AdditionalPurchase />;
              if (showGiftSetting) return <GiftSetting form={form} />;
              return null;
            }}
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
          >
            {({ getFieldValue }) => {
              const actionType = getFieldValue("actionType");
              if (
                actionType === ActionTypeValue.ADDITIONAL_PURCHASE ||
                actionType === ActionTypeValue.ADDITIONAL_PURCHASE_PROMOTION ||
                actionType === ActionTypeValue.NON_BOUNDED_GIFT ||
                actionType === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE
              )
                return null;
              return <BindTime form={form} />;
            }}
          </Form.Item>
          <UseTime form={form} />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
          >
            {({ getFieldValue }) => {
              const actionType = getFieldValue("actionType");
              if (
                actionType === ActionTypeValue.ADDITIONAL_PURCHASE ||
                actionType === ActionTypeValue.ADDITIONAL_PURCHASE_PROMOTION ||
                actionType === ActionTypeValue.NON_BOUNDED_GIFT ||
                actionType === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE
              )
                return null;
              return <TargetSetting form={form} />;
            }}
          </Form.Item>
          <LimitSetting />
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.actionType !== currentValues.actionType}
          >
            {({ getFieldValue }) => {
              const actionType = getFieldValue("actionType");
              const displaySection =
                (actionType === ActionTypeValue.DEDUCTION ||
                  actionType === ActionTypeValue.DISCOUNT ||
                  actionType === ActionTypeValue.BOUNDED_GIFT ||
                  actionType === ActionTypeValue.BOUNDED_GIFT_ANY ||
                  actionType === ActionTypeValue.NO_CONDITION) &&
                mode === "edit";

              if (displaySection)
                return (
                  <>
                    <PromoCode form={form} />
                    <ManageMember />
                  </>
                );
              return null;
            }}
          </Form.Item>
        </SettingSectionWrapper>
      </FormWrapper>
      <Footer>
        <MarginButton disabled={mode === "add"} onClick={handleOnDelete}>
          刪除
        </MarginButton>
        <MarginButton disabled={mode === "add"} onClick={handleOnCopy}>
          複製
        </MarginButton>
        <Form.Item noStyle shouldUpdate>
          <Button type="primary" htmlType="submit">
            {mode === "add" ? "建立資料" : "儲存此分頁"}
          </Button>
        </Form.Item>
      </Footer>
    </Wrapper>
  );
};

export default RuleEdit;
