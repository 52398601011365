import { BindTypeValue, RuleDetail } from "@api/ruleApi";
import { DatePicker, Form, InputNumber, Radio, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React, { FC, MouseEvent } from "react";
import styled from "styled-components";
import type { LocalFormType } from ".";

const Wrapper = styled.div`
  position: relative;
  height: 260px;
  border: 1px solid #d9d9d9;
  grid-row-start: 3;

  & .ant-col {
    position: static;
  }
  & .ant-form-item-control-input {
    position: static;
  }
  & .ant-form-item-control-input {
    min-height: 0;
  }
  & .ant-form-item-explain {
    min-height: 0;
  }
`;
const OverrideWrapper = styled.div`
  height: 0px;
`;
const ValidateWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;

  .ant-form-item-has-error & {
    border-color: #ff4d4f;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.span`
  color: red;
`;
const Body = styled.div`
  padding: 12px 20px 20px 20px;
`;
const BlockRadio = styled(Radio)<{ radioHeight?: number }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .ant-radio.ant-radio.ant-radio {
    display: flex;
    align-items: center;
    height: ${({ radioHeight }) => radioHeight || 22}px;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;
const RangePicker = styled(DatePicker.RangePicker)<{ showTime?: any }>`
  margin-top: 10px;
  width: 350px;
`;
const StyledSelect = styled(Select)`
  width: 70px;
  margin: 0 10px;
`;
const NoBubbleBlock = styled.div``;
const CycleInput = styled(InputNumber)`
  margin: 0 6px;
  width: 58px;
`;
const CycleDatePicker = styled(DatePicker)`
  width: 200px;
  margin-left: 4px;
`;

interface Props {
  form: FormInstance<LocalFormType>;
}

const bindCyleOptions = [
  {
    label: "每日",
    value: BindTypeValue.CYCLE_IN_DAY,
  },
  {
    label: "每週",
    value: BindTypeValue.CYCLE_IN_WEEK,
  },
  {
    label: "每月",
    value: BindTypeValue.CYCLE_IN_MONTH,
  },
];

const BindTime: FC<Props> = (props) => {
  const { form } = props;

  const handleOnSelectCycles = () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      bindMethod: {
        ...values.bindMethod,
        bindType: BindTypeValue.CYCLE_IN_DAY,
      },
    });
  };

  const preventDefault = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Wrapper>
      <OverrideWrapper>
        <Form.Item name={["bindMethod", "bindType"]} rules={[{ required: true, message: "" }]}>
          <ValidateWrapper />
        </Form.Item>
      </OverrideWrapper>
      <Header>
        領取時間
        <Red>*</Red>
      </Header>
      <Body>
        <Form.Item name={["bindMethod", "bindType"]} rules={[{ required: true, message: "" }]} noStyle>
          <Radio.Group>
            <BlockRadio value={BindTypeValue.NO_LIMIT}>不限</BlockRadio>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.bindMethod !== currentValues.bindMethod}
            >
              {({ getFieldValue }) => {
                const { bindType }: RuleDetail["bindMethod"] = getFieldValue("bindMethod") || {};
                if (bindType === BindTypeValue.ONE_TIME) {
                  return (
                    <BlockRadio value={BindTypeValue.ONE_TIME}>
                      <FlexColumn>
                        <div>時間區間</div>
                        <Form.Item name={["bindMethod", "bindTimeBetween"]} noStyle>
                          <RangePicker
                            locale={locale}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{
                              hideDisabledOptions: true,
                              defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                            }}
                          />
                        </Form.Item>
                      </FlexColumn>
                    </BlockRadio>
                  );
                }
                return <BlockRadio value={BindTypeValue.ONE_TIME}>時間區間</BlockRadio>;
              }}
            </Form.Item>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) => prevValues.bindMethod !== currentValues.bindMethod}
        >
          {({ getFieldValue }) => {
            const { bindType }: RuleDetail["bindMethod"] = getFieldValue("bindMethod") || {};
            let isChecked = false;
            switch (bindType) {
              case BindTypeValue.CYCLE_IN_DAY:
              case BindTypeValue.CYCLE_IN_WEEK:
              case BindTypeValue.CYCLE_IN_MONTH:
                isChecked = true;
                break;
              default:
            }
            return (
              <BlockRadio checked={isChecked} onChange={handleOnSelectCycles} radioHeight={isChecked ? 32 : 22}>
                <FlexColumn>
                  <FlexRow>
                    週期性
                    {isChecked && (
                      <NoBubbleBlock onClick={preventDefault}>
                        <Form.Item name={["bindMethod", "bindType"]} noStyle>
                          <StyledSelect options={bindCyleOptions} />
                        </Form.Item>
                        連續
                        <Form.Item name={["bindMethod", "bindCycles"]} noStyle>
                          <CycleInput />
                        </Form.Item>
                        個週期
                      </NoBubbleBlock>
                    )}
                  </FlexRow>
                  {isChecked && (
                    <FlexRow>
                      週期開始時間
                      <Form.Item name={["bindMethod", "cycleStartDate"]} noStyle>
                        <CycleDatePicker />
                      </Form.Item>
                    </FlexRow>
                  )}
                </FlexColumn>
              </BlockRadio>
            );
          }}
        </Form.Item>
      </Body>
    </Wrapper>
  );
};

export default BindTime;
