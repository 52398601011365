import { ExclamationCircleOutlined, FilterTwoTone } from "@ant-design/icons";
import { ReplenishReturnList } from "@api/utils/normalizeOrder";
import {
  fetchReplenishReturnList,
  fetchReplenishReturnRefund,
  orderState,
  resetOrderSlice,
  setReplenishReturnListFilter,
} from "@redux/orderSlice";
import { Button, Checkbox, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { ApprovalStatus, ApprovalStatusMap } from "../ApprovedState";
import ProcessingType, { ProcessingTypeMap } from "../ProcessingType";
import { ShippingTypeValueByNumber } from "../ShippingTypeValue";
import ReplenishReturnFilter from "./ReplenishReturnFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  position: relative;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const RefundButton = styled(Button)`
  position: absolute;
  bottom: 39px;
  left: 33px;
`;

const ALink = styled.a`
  ${fontStyle("14px", "16px")};
  color: ${({ theme }) => theme.colorSuccess500};
  margin: 0;
  text-decoration: underline;
`;

export default function IntoWarehouse() {
  const dispatch = useDispatch();
  const { isFetching, replenishReturnListResult, replenishReturnListFilter, isRefundDone } = useSelector(orderState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [checkSet, setCheckSet] = useState<Set<number>>(new Set());

  const handleMemberGroupSelect = (value: SelectValue) => {
    dispatch(setReplenishReturnListFilter({ ...replenishReturnListFilter, limit: parseInt(value as string, 10) }));
  };

  const updateCheckMap = (orderId: number) => {
    if (checkSet.has(orderId)) {
      const cloneSet = new Set(checkSet);
      cloneSet.delete(orderId);
      setCheckSet(cloneSet);
    } else {
      const cloneSet = new Set(checkSet.add(orderId));
      setCheckSet(cloneSet);
    }
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchReplenishReturnList({ ...replenishReturnListFilter, page: value }));
  };

  const fetchBatchRefund = () => {
    Modal.confirm({
      title: "你確定要退款完成嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchReplenishReturnRefund(Array.from(checkSet.keys())));
      },
    });
  };

  const fetchRefund = (orderId: number) => {
    Modal.confirm({
      title: "你確定要退款完成嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchReplenishReturnRefund([orderId]));
      },
    });
  };

  const columns: ColumnsType<ReplenishReturnList> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: "",
      render: (_: any, record: ReplenishReturnList) => (
        <Checkbox
          disabled={
            !(
              record.approvalStatus === ApprovalStatus.REFUND_APPROVED &&
              record.shippingMethod === ShippingTypeValueByNumber.HOME_DELIVERY
            )
          }
          onChange={() => updateCheckMap(record.id)}
        />
      ),
    },
    {
      key: "orderNumber",
      dataIndex: "orderNumber",
      title: "訂單編號",
      render: (value: number, record: ReplenishReturnList) => (
        <ALink href={`/order-content/${record.orderId}`} target="_blank">
          {value}
        </ALink>
      ),
    },
    {
      key: "processingType",
      dataIndex: "processingType",
      title: "類別",
      render: (value: ProcessingType) => <div>{ProcessingTypeMap[value]}</div>,
    },
    {
      key: "approvalStatus",
      dataIndex: "approvalStatus",
      title: "狀態",
      render: (value: ApprovalStatus) => <div>{ApprovalStatusMap[value] || "N/A"}</div>,
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "申請時間",
      render: (value: string) => <div>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</div>,
    },
    {
      key: "orderTime",
      dataIndex: "orderTime",
      title: "訂購時間",
      render: (value: string) => <div>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</div>,
    },
    {
      key: "memberName",
      dataIndex: "memberName",
      title: "會員名稱",
    },
    {
      key: "memberPhone",
      dataIndex: "memberPhone",
      title: "會員電話",
    },
    {
      key: "logisticName",
      dataIndex: "logisticName",
      title: "出貨點",
    },
    {
      key: "qty",
      dataIndex: "qty",
      title: "補退貨數量(件)",
    },
    {
      key: "refundAmount",
      dataIndex: "refundAmount",
      title: "總退款金額",
    },
    {
      key: "approvalDate",
      dataIndex: "approvalDate",
      title: "放行時間",
      render: (value: string) => <div>{value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A"}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_: any, record: ReplenishReturnList) => (
        <>
          <Button type="link" onClick={() => window.open(`${window.location.href}/${record.id}`)}>
            檢視
          </Button>
          <Button
            type="link"
            disabled={
              !(
                record.approvalStatus === ApprovalStatus.REFUND_APPROVED &&
                record.shippingMethod === ShippingTypeValueByNumber.HOME_DELIVERY
              )
            }
            onClick={() => fetchRefund(record.id)}
          >
            退款完成
          </Button>
        </>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(fetchReplenishReturnList({ ...replenishReturnListFilter, page: 1 }));
  }, [dispatch, replenishReturnListFilter]);

  useEffect(() => {
    if (isRefundDone) {
      setPage(1);
      dispatch(fetchReplenishReturnList({ ...replenishReturnListFilter, page: 1 }));
    }
  }, [isRefundDone, dispatch, replenishReturnListFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetOrderSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="補退貨" />
      <Row justify="space-between">
        <div>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
          <CustomButton>匯出</CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${replenishReturnListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleMemberGroupSelect(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <ReplenishReturnFilter isVisible={showFilter} />
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={replenishReturnListResult.results}
          pagination={{
            pageSize: replenishReturnListFilter.limit,
            current: page,
            showSizeChanger: false,
            total: replenishReturnListResult.count,
            onChange: fetchPageChange,
          }}
        />
        <RefundButton onClick={fetchBatchRefund}>退款完成</RefundButton>
      </TableWrapper>
    </Wrapper>
  );
}
