import React, { FC } from "react";
import styled from "styled-components";
import { Button, message } from "antd";
import PopupBackground from "@component/PopupBackground";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import ruleApi, { CreateCouponParams, CouponMethodValue } from "@api/ruleApi";
import { useParams } from "react-router-dom";
import { FormInstance } from "antd/lib/form";
import type { LocalFormType } from "..";

interface Props {
  form: FormInstance<LocalFormType>;
  onClose: () => void;
  onCustomSetup: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 164px;
  width: 364px;
  padding: 40px 28px 20px 32px;
  background-color: white;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
`;
const IconContainer = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  margin-right: 16px;
  color: #faad14;
`;
const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const MarginButton = styled(Button)`
  margin-right: 10px;
`;

const RandomConfirmModal: FC<Props> = (props) => {
  const { onClose, onCustomSetup, form } = props;
  const { id: ruleId } = useParams();

  const handleOnCumstomSetup = () => {
    onCustomSetup();
    onClose();
  };

  const handleOnSystemSetup = async () => {
    const params: CreateCouponParams = {
      ruleId: Number(ruleId!),
      couponMethod: CouponMethodValue.RANDOM,
    };

    try {
      const response = await ruleApi.createCoupons(params, false);
      form.setFieldsValue({
        couponInfo: response,
      });
      onClose();
    } catch (error: any) {
      message.warning(error.message);
    }
  };

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Header>
          <IconContainer>
            <ExclamationCircleOutlined />
          </IconContainer>
          <HeaderText>是否異動兌換碼數量</HeaderText>
        </Header>
        <Footer>
          <MarginButton onClick={onClose}>否</MarginButton>
          <MarginButton onClick={handleOnSystemSetup}>是，系統產生</MarginButton>
          <Button type="primary" onClick={handleOnCumstomSetup}>
            是，自行設定
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default RandomConfirmModal;
