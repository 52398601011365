import React, { useCallback, useEffect, useState } from "react";
import { PlusCircleTwoTone } from "@ant-design/icons";
import returnOrderApi from "@api/returnOrderApi";
import ImportFileModal from "@component/ImportFileModal";
import PageTitle from "@component/PageTitle";
import { hideLoading, showLoading } from "@redux/notifySlice";
import {
  applyOrApproveReturnOrder,
  fetchOrderDetail,
  returnOrderState,
  setErrorResult,
  fetchProductList,
} from "@redux/returnOrderSlice";
import { Button, Col, message, Modal, Row, Select, Skeleton, Space, Tabs } from "antd";
import { SelectValue } from "antd/lib/select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { OrderStatus } from "@constant/OrderStatus";
import translateErrors from "@page/ThroughShipment/OnlineOrder/utils/translateErrors";
import EditOrderForm from "../EditOrderForm";
import { ReturnOrderProcessType, ReturnProduct } from "../interfaces";
import OrderDetails from "../OrderDetails";
import EditProductPopup from "./EditProductPopup";
import ReturnProductList from "./ReturnProductList";

const { TabPane } = Tabs;
const { Option } = Select;

const StyledWrapper = styled.section`
  margin: 16px 25px;
  padding: 8px 11px;
`;
const ModalContent = styled.section`
  height: 150px;
  overflow: auto;
`;

export default function Transfer() {
  const dispatch = useDispatch();
  const { returnOrderDetail, errorResult, productListResult } = useSelector(returnOrderState);
  const { results: productList } = productListResult;
  const { returnOrderId } = useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [mode, setMode] = useState<"copy" | "edit">("edit");

  const [activeTab, setActiveTab] = useState("details");
  const [showProductFormModal, setShowProductFormModal] = useState<boolean>(false);
  const [currentProduct, setCurrentProduct] = useState<ReturnProduct>();
  const [showImportModal, setShowImportModal] = useState(false);
  const [page, setPage] = useState({
    limit: 20,
    current: 1,
  });

  function sendApplication() {
    if (productListResult.count) {
      Modal.warning({
        title: "你確定要送出申請？",
        onOk: () => {
          if (returnOrderDetail) {
            dispatch(
              applyOrApproveReturnOrder({
                orderId: returnOrderDetail?.id,
                processType: ReturnOrderProcessType.APPLY,
              }),
            );
          }
        },
      });
    }
  }

  function switchTab(activeKey: string) {
    setActiveTab(activeKey);
  }

  const approveReturnOrder = async (processType: ReturnOrderProcessType) => {
    if (returnOrderDetail) {
      dispatch(
        applyOrApproveReturnOrder({
          orderId: returnOrderDetail?.id,
          processType,
        }),
      );
    }
  };
  function changePageSize(value: SelectValue) {
    setPage((prevState) => ({
      ...prevState,
      limit: Number(value),
    }));
  }
  function changePage(selectedPage: any) {
    window.scrollTo(0, 0);
    setPage((prevState) => ({
      ...prevState,
      current: selectedPage,
    }));
  }

  const onCloseEditOrderForm = useCallback(() => {
    setShowPopup(false);
  }, []);

  useEffect(() => {
    if (errorResult && errorResult?.length !== 0) {
      Modal.warning({
        title: "部分商品未複製成功，詳細如下",
        content: (
          <ModalContent>
            {errorResult.map((error, idx) => (
              <div key={error.sku}>{`${idx + 1}. ${error.msg} (sku : ${error.sku})`}</div>
            ))}
          </ModalContent>
        ),
        okText: "我知道了",
        onOk: () => {
          dispatch(setErrorResult([]));
        },
      });
    }
  }, [dispatch, errorResult]);

  useEffect(() => {
    dispatch(fetchProductList(returnOrderId));
  }, [dispatch, returnOrderId]);

  const handelAddXLS = async (file: File) => {
    try {
      dispatch(showLoading());
      const response = await returnOrderApi.importReturnProductXLS(returnOrderId, file);
      dispatch(fetchProductList(returnOrderId));
      dispatch(fetchOrderDetail(returnOrderId));
      message.success("匯入成功");
      setShowImportModal(false);
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      if (!(translatedErrors.length > 0)) return null;
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {translatedErrors.map((errorItem: any) => {
              return <p>{errorItem}</p>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
      });

      return null;
    } finally {
      dispatch(hideLoading());
    }
  };

  useEffect(() => {
    dispatch(fetchOrderDetail(returnOrderId));
  }, [dispatch, returnOrderId]);

  return (
    <StyledWrapper style={{ position: "relative", border: "1px solid #F0F0F0" }}>
      <PageTitle title={`調撥 - ${returnOrderId}`} />
      {returnOrderDetail ? (
        <Tabs onChange={switchTab} type="card">
          <TabPane tab="編輯調撥單" key="details">
            <OrderDetails orderDetail={returnOrderDetail} />
          </TabPane>
          <TabPane tab="調撥商品" key="products">
            <Row gutter={[8, 8]}>
              <Col style={{ marginBottom: 12 }}>
                <Button
                  type="primary"
                  icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
                  onClick={() => setShowProductFormModal(true)}
                  disabled={!returnOrderDetail?.canEdit}
                >
                  新增商品
                </Button>
              </Col>
              <Col style={{ marginBottom: 12 }}>
                <Button onClick={() => setShowImportModal(true)} disabled={!returnOrderDetail?.canEdit}>
                  匯入商品
                </Button>
              </Col>
              {/* 分頁 */}
              <Col flex="auto">
                <Row justify="end">
                  <Space>
                    <span>{`總共${productListResult.count}筆，每頁顯示`}</span>
                    <Select defaultValue="20" style={{ width: "72px", fontSize: "13px" }} onChange={changePageSize}>
                      <Option value="20">20</Option>
                      <Option value="50">50</Option>
                      <Option value="100">100</Option>
                    </Select>
                    <span>筆</span>
                  </Space>
                </Row>
              </Col>
            </Row>
            {/* 調撥商品列表 */}
            <ReturnProductList
              list={productList}
              onChange={changePage}
              openProductForm={(product) => {
                setCurrentProduct(product);
                setShowProductFormModal(true);
              }}
              pagination={{
                ...page,
              }}
              canEdit={returnOrderDetail?.canEdit}
              canSubmit={returnOrderDetail?.canSubmit}
              sourceWarehouseCode={returnOrderDetail.sourceWarehouseCode}
              sourceWarehouseType={returnOrderDetail.sourceWarehouseType}
            />
          </TabPane>
        </Tabs>
      ) : (
        <Skeleton />
      )}
      <div style={{ position: "absolute", top: 9, right: 20 }}>
        {activeTab === "details" ? (
          <>
            <Space>
              <Button
                type="default"
                disabled={returnOrderDetail?.status !== OrderStatus.WAITING_APPROVE}
                onClick={() => approveReturnOrder(ReturnOrderProcessType.REJECT)}
              >
                審核不通過
              </Button>
              <Button
                type="default"
                disabled={returnOrderDetail?.status !== OrderStatus.WAITING_APPROVE}
                onClick={() => approveReturnOrder(ReturnOrderProcessType.APPROVE)}
              >
                審核通過
              </Button>
              {/* 複製功能先隱藏
              <Button
                type="default"
                onClick={() => {
                  setMode("copy");
                  setShowPopup(!showPopup);
                }}
              >
                複製
              </Button> */}
              <Button
                type="primary"
                onClick={() => {
                  setMode("edit");
                  setShowPopup(!showPopup);
                }}
                disabled={!returnOrderDetail?.canEdit}
              >
                編輯
              </Button>
            </Space>
          </>
        ) : (
          <Button type="default" onClick={sendApplication} disabled={!returnOrderDetail?.canSubmit}>
            送出申請
          </Button>
        )}
      </div>
      {showPopup && <EditOrderForm mode={mode} onClose={onCloseEditOrderForm} returnOrderDetail={returnOrderDetail} />}
      {showProductFormModal && returnOrderDetail && (
        <EditProductPopup
          onClose={() => {
            setCurrentProduct(undefined);
            setShowProductFormModal(false);
          }}
          sourceWarehouseCode={returnOrderDetail.sourceWarehouseCode}
          sourceWarehouseType={returnOrderDetail.sourceWarehouseType}
          returnOrderId={returnOrderId}
          currentProduct={currentProduct}
        />
      )}
      {showImportModal && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => setShowImportModal(false)}
          clearErrorMessage={() => {}}
          handleUploadFile={handelAddXLS}
          templatePath="admin/template/xls/return_inventory_import_template_JY_v2.xlsx"
          fileType=".xlsx"
        />
      )}
    </StyledWrapper>
  );
}
