import { ExclamationCircleOutlined, FormOutlined, PlusCircleFilled } from "@ant-design/icons";
import { VipBulletinList } from "@api/vipPromotionApi";
import PageTitle from "@component/PageTitle";
import BatchActivePopup from "@page/Sale/BatchActivePopup";
import {
  fetchBatchDeleteVipBulletin,
  fetchBatchUpdateVipBulletinStatus,
  fetchVipBulletinList,
  setVipBulletinListFilter,
  VipRuleState,
} from "@redux/vipPromotionSlice";
import { Button, Checkbox, Modal, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import EditBulletinBoardPopup from "./EditBulletinBoardPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 28px 21px 31px 10px;
  border: 1px solid #f0f0f0;
`;
const DeleteButton = styled(Button)`
  &&& {
    position: absolute;
    left: 16px;
    bottom: 47px;
  }
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
const EditText = styled.div`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const EditStatusIcon = styled(FormOutlined)<{ disabled: boolean }>`
  margin-left: 5px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const pageSizeOptions = [20, 50, 100];

const VipBulletinBoard: FC = () => {
  const dispatch = useDispatch();
  const { isFetching, vipBulletinListFilter, vipBulletinListResult } = useSelector(VipRuleState);

  const [checkGiftIds, setCheckGiftIds] = useState<Set<number>>(new Set());
  const [showBatchActivePopup, setShowBatchActivePopup] = useState<boolean>(false);
  const [showBulletinBoardPopup, setShowBulletinBoardPopup] = useState<boolean>(false);
  const [editVipBulletin, setEditVipBulletin] = useState<VipBulletinList | undefined>();

  const onCheckListItem = (id: number) => {
    if (checkGiftIds.has(id)) {
      const newSet = new Set<number>();
      newSet.delete(id);
      setCheckGiftIds(newSet);
    } else {
      setCheckGiftIds(new Set(checkGiftIds.add(id)));
    }
  };

  const onDeleteSingleData = (id: number) => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(fetchBatchDeleteVipBulletin({ bulletinBoardIds: [id] }));
      },
    });
  };

  const onDeleteMultiData = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(fetchBatchDeleteVipBulletin({ bulletinBoardIds: Array.from(checkGiftIds) }));
      },
    });
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    dispatch(setVipBulletinListFilter({ ...vipBulletinListFilter, offset: (value - 1) * vipBulletinListFilter.limit }));
  };

  const onHandleCheckAllItem = () => {
    if (vipBulletinListResult.results.length === checkGiftIds.size) {
      setCheckGiftIds(new Set());
    } else {
      setCheckGiftIds(new Set(vipBulletinListResult.results.map((item) => item.id)));
    }
  };

  const fetchChangeGiftStatus = (isActive: boolean) => {
    dispatch(fetchBatchUpdateVipBulletinStatus({ bulletinBoardIds: Array.from(checkGiftIds), isActive }));
    setShowBatchActivePopup(false);
  };

  useEffect(() => {
    dispatch(fetchVipBulletinList(vipBulletinListFilter));
  }, [dispatch, vipBulletinListFilter]);

  const tableColumns: ColumnsType<VipBulletinList> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: () => (
        <Checkbox
          checked={vipBulletinListResult.results.length === checkGiftIds.size}
          onChange={onHandleCheckAllItem}
        />
      ),
      render: (_, values) => (
        <Checkbox checked={checkGiftIds.has(values.id)} onChange={() => onCheckListItem(values.id)} />
      ),
    },
    {
      title: "推廣文案",
      key: "title",
      dataIndex: "title",
    },
    {
      title: (
        <Row align="middle">
          <div>狀態</div>
          <EditStatusIcon onClick={() => setShowBatchActivePopup(true)} disabled={checkGiftIds.size === 0} />
        </Row>
      ),
      key: "isActive",
      dataIndex: "isActive",
      render: (value) => (value ? "上架" : "下架"),
    },
    {
      title: "使用時間",
      key: "timePeriod",
      dataIndex: "timePeriod",
      render: (_, values) => (
        <div>
          {values.startAt
            ? `${moment(values.startAt).format("YYYY-MM-DD HH:mm:ss")} - ${moment(values.endAt).format(
                "YYYY-MM-DD HH:mm:ss",
              )}`
            : "不限"}
        </div>
      ),
    },
    {
      title: "",
      width: 75,
      fixed: "right",
      render: (_, values) => {
        return (
          <EditTextContainer>
            <EditText
              onClick={() => {
                setEditVipBulletin(values);
                setShowBulletinBoardPopup(true);
              }}
            >
              編輯
            </EditText>
            <DeleteText onClick={() => onDeleteSingleData(values.id)}>刪除</DeleteText>
          </EditTextContainer>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="VIP 規廣" />
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={() => setShowBulletinBoardPopup(true)}>
            新增推廣
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共 {vipBulletinListResult.count} 筆, 每頁顯示
          <PageSelect
            value={vipBulletinListFilter.limit}
            onChange={(value) =>
              dispatch(setVipBulletinListFilter({ ...vipBulletinListFilter, limit: value as number }))
            }
          >
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          loading={isFetching}
          dataSource={vipBulletinListResult.results}
          columns={tableColumns}
          pagination={{
            pageSize: vipBulletinListFilter.limit,
            current: vipBulletinListFilter.offset / vipBulletinListFilter.limit + 1,
            showSizeChanger: false,
            total: vipBulletinListResult.count,
            onChange: fetchPageChange,
          }}
        />
        <DeleteButton onClick={onDeleteMultiData} disabled={checkGiftIds.size === 0}>
          刪除
        </DeleteButton>
      </TableContainer>
      <BatchActivePopup
        visible={showBatchActivePopup}
        onClose={() => setShowBatchActivePopup(false)}
        onSubmit={fetchChangeGiftStatus}
        defaultValue={-1}
      />
      {showBulletinBoardPopup && (
        <EditBulletinBoardPopup
          vipBulletin={editVipBulletin}
          onClose={() => {
            setEditVipBulletin(undefined);
            setShowBulletinBoardPopup(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default VipBulletinBoard;
