import { MEMBER_TOKEN } from "@constant/StorageKeyValue";
import cookie from "@utils/Cookie";
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { apiEndpoint } from "src/configs";
import type { TokenInfo } from "./adminApi";
import normalizeAxiosError, { handleCustomError } from "./utils/normalizeAxiosError";

const defaultTimeout = 30000;

const defaultConfig = {
  apiVersion: "v1",
  timeout: defaultTimeout,
  retry: 0,
  headers: {},
};

const { CancelToken } = axios;

export type PayloadType = {
  [key: string]: any;
};

/**
 * Base class for calling API and return axios result
 * @param timeout - specify timeout in seconds
 * @param retry - specify how many times you want to retry api
 * @param customerToken - customer's auth token, 如為用戶相關 API 需填寫
 */
class CoreAPI {
  private defaultAPI: AxiosInstance;

  private timeout: number;

  private apiVersion: string;

  private retriedTimes: number;

  private maxRetry: number;

  private cancel?: () => void;

  public constructor(requestConfig?: {
    apiVersion?: string;
    timeout?: number;
    retry?: number;
    headers?: { [headerName: string]: string };
  }) {
    const config = { ...defaultConfig, ...requestConfig };

    this.timeout = config.timeout;
    this.apiVersion = config.apiVersion;
    this.retriedTimes = 0;
    this.maxRetry = config.retry;
    this.defaultAPI = axios.create({
      baseURL: apiEndpoint,
      timeout: this.timeout,
      headers: {
        Authorization: "Bearer 2t6pjLkrr63cLmq9Vbmwkuo2vj8581vjMyjdazI3MxLMeUSFOQkF4axxR2Gxbwe7",
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        ...config?.headers,
      },
    });

    this.defaultAPI.interceptors.request.use((axiosConfig) => {
      const tokenInfo: TokenInfo = cookie.getItem(MEMBER_TOKEN);
      if (tokenInfo && tokenInfo.accessToken) {
        // eslint-disable-next-line no-param-reassign
        axiosConfig.headers.Authorization = `Bearer ${tokenInfo.accessToken}`;
      }
      return axiosConfig;
    });

    // add error handler
    this.defaultAPI.interceptors.response.use(
      (response) => {
        return response;
      },
      (error: AxiosError) => {
        if (this.retriedTimes < this.maxRetry) {
          this.retriedTimes += 1;
          return this.defaultAPI(error.config);
        }

        const customError = normalizeAxiosError(error);

        // token失效 9001
        if (customError.statusCode === 9001) {
          cookie.removeItem(MEMBER_TOKEN);
          window.location.reload();
        }
        // 畫面邏輯 : 處理message錯誤
        handleCustomError(customError);

        return Promise.reject(customError);
      },
    );
  }

  cancelRequest() {
    if (this.cancel) {
      this.cancel();
    }
  }

  getData(url: string, payload: PayloadType, config?: AxiosRequestConfig) {
    const self = this;
    return this.defaultAPI.get(`${this.apiVersion}${url}`, {
      ...config,
      params: {
        ...payload,
      },
      cancelToken: new CancelToken((c) => {
        self.cancel = c;
      }),
    });
  }

  postData(url: string, payload: PayloadType) {
    const self = this;
    return this.defaultAPI.post(`${this.apiVersion}${url}`, payload, {
      cancelToken: new CancelToken((c) => {
        self.cancel = c;
      }),
    });
  }

  putData(url: string, payload: PayloadType) {
    return this.defaultAPI.put(`${this.apiVersion}${url}`, payload);
  }

  patchData(url: string, payload: PayloadType) {
    return this.defaultAPI.patch(`${this.apiVersion}${url}`, payload);
  }

  deleteData(url: string, payload: PayloadType) {
    return this.defaultAPI.delete(`${this.apiVersion}${url}`, {
      data: payload,
    });
  }
}

export default CoreAPI;
