import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import ReturnProductAppDetails from "./ReturnProductAppDetails";
import ReturnProductAppList from "./ReturnProductAppList";

const ReturnProductAppPage: FC = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ReturnProductAppList />} />
        <Route path="/:returnId" element={<ReturnProductAppDetails />} />
      </Routes>
    </>
  );
};

export default ReturnProductAppPage;
