import MemberGroupValue, { MemberProgramType } from "@constant/MemberGroupValue";
import { Button, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { MemberFilterParams } from "@api/memberApi";
import { updateMemberListParams, clearMemberListParams, fetchMemberList } from "@redux/memberSlice";

type FilterState = Omit<MemberFilterParams, "offset" | "limit">;

const Wrapper = styled.form`
  margin-bottom: 20px;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-top: 15px;
  }
`;
const FilterRow = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 30px;
  }
`;
const FilterTitle = styled.div`
  width: 100px;
  font-size: 14px;
`;
const FilterInput = styled(Input)`
  width: 200px;
`;
const FilterSelect = styled(Select)`
  width: 200px;
`;
const ButtonContainer = styled.div`
  margin-left: auto;
`;
const ApplyButton = styled(Button)`
  margin-left: 10px;
`;

export default function MemberFilterForm() {
  const dispatch = useAppDispatch();
  const { memberListParams } = useSelector((state: RootState) => state.member);

  const [filterState, setFilterState] = useState<FilterState>({});
  const [memberGroupValue, setMemberGroupValue] = useState<number>();

  const memberGroupValueOptions = useMemo(
    () => [
      { label: "一般", value: MemberGroupValue.GENERAL },
      { label: "黑卡", value: MemberGroupValue.VIP, type: MemberProgramType.T_BLACK },
      { label: "黑卡試用", value: 999, type: MemberProgramType.T_TRIAL },
    ],
    [],
  );

  useEffect(() => {
    const { limit, offset, ...memberListFilter } = memberListParams;
    setFilterState(memberListFilter);
  }, [memberListParams]);

  function handleFilterChange(event: ChangeEvent<HTMLInputElement>, key: keyof MemberFilterParams) {
    const { value } = event.target;

    setFilterState((prev) => ({
      ...prev,
      [key]: value,
    }));
  }

  function clearFilters() {
    dispatch(clearMemberListParams());
    setMemberGroupValue(undefined);
  }

  function confirmFilters(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    // 如果要搜尋黑卡方案（ex: 黑卡試用），則要將groupId設為VIP，並將programType設為對應的方案
    const hasVIPProgram = memberGroupValue && memberGroupValue !== MemberGroupValue.GENERAL;
    const newParams = {
      ...memberListParams,
      ...filterState,
      groupId: hasVIPProgram ? MemberGroupValue.VIP : memberGroupValue,
      programType: hasVIPProgram
        ? memberGroupValueOptions.find((option) => option.value === memberGroupValue)?.type
        : undefined,
    };
    dispatch(updateMemberListParams(newParams));
    dispatch(fetchMemberList());
  }

  function handleMemberGroupSelect(value: SelectValue) {
    setMemberGroupValue(value as number);
  }

  return (
    <Wrapper onSubmit={confirmFilters}>
      <FlexRow>
        <FilterRow>
          <FilterTitle>ID</FilterTitle>

          <FilterInput
            onChange={(e) => handleFilterChange(e, "id")}
            placeholder="會員ID"
            value={filterState.id}
            allowClear
          />
        </FilterRow>
        <FilterRow>
          <FilterTitle>會員名稱</FilterTitle>
          <FilterInput
            onChange={(e) => handleFilterChange(e, "nameQ")}
            placeholder="會員名稱"
            value={filterState.nameQ}
            allowClear
          />
        </FilterRow>
        <FilterRow>
          <FilterTitle>會員電話</FilterTitle>
          <FilterInput
            onChange={(e) => handleFilterChange(e, "phone")}
            placeholder="會員電話"
            allowClear
            value={filterState.phone}
          />
        </FilterRow>
      </FlexRow>
      <FlexRow>
        <FilterRow>
          <FilterTitle>會員信箱</FilterTitle>
          <FilterInput
            onChange={(e) => handleFilterChange(e, "email")}
            placeholder="會員信箱"
            allowClear
            value={filterState.email}
          />
        </FilterRow>
        <FilterRow>
          <FilterTitle>會員資格</FilterTitle>
          <FilterSelect
            placeholder="請選擇"
            onChange={(value) => handleMemberGroupSelect(value as SelectValue)}
            value={memberGroupValue}
            options={memberGroupValueOptions}
          />
        </FilterRow>
        <ButtonContainer>
          <Button onClick={clearFilters}>清除篩選條件</Button>
          <ApplyButton type="primary" htmlType="submit">
            套用
          </ApplyButton>
        </ButtonContainer>
      </FlexRow>
    </Wrapper>
  );
}
