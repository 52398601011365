import React, { useEffect, useState } from "react";
import { Button, Col, message, Modal, Row } from "antd";
import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import { StyledSection } from "src/styles/common";
import { useDispatch, useSelector } from "react-redux";
import ImportFileModal from "@component/ImportFileModal";
import financeOrderApi, { FinanceOrderParams } from "@api/financeOrderApi";
import { fetchFinanceOrderList, financeOrderState } from "@redux/financeOrderSlice";
import EditOrderForm from "./EditOrderForm";
import Filter from "./Filter";
import FinanceOrderLists from "./FilnanceOderList";

const ModalContent = styled.section`
  height: 150px;
  overflow: auto;
`;

const FinanceOrder = () => {
  const dispatch = useDispatch();
  const { orderListFilter } = useSelector(financeOrderState);

  const [showPopup, setShowPopup] = useState(false);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [orderData, setOrderData] = useState<FinanceOrderParams>({
    contractType: 0,
    retailerId: 0,
    accountDate: "",
    invoiceNumber: 0,
    totalAmount: 0,
  });

  const handelImportCSV = async (file: File) => {
    const payload = {
      ...orderData,
      file,
    };
    try {
      await financeOrderApi.uploadFinanceOrder(payload);
      dispatch(fetchFinanceOrderList(orderListFilter));
      message.success("匯入成功");
      setIsUploadSuccess(true);
    } catch (error: any) {
      const { result } = error;
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {result.map((errorItem: any) => {
              return <p>{errorItem}</p>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
      });
    }
  };

  useEffect(() => {
    if (showImportPopup) {
      setShowPopup(false);
    }
    if (isUploadSuccess) {
      setShowImportPopup(false);
    }
  }, [showImportPopup, isUploadSuccess]);

  return (
    <div>
      <PageTitle title="月結帳單" />
      <StyledSection>
        <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
          <Col span={12}>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => setShowPopup(true)}
            >
              匯入月對帳
            </Button>
            <Button style={{ marginLeft: 10 }} icon={<FilterTwoTone />} onClick={() => setShowFilter(true)}>
              篩選
            </Button>
          </Col>
        </Row>
        {showFilter && <Filter />}
        {showPopup && (
          <EditOrderForm
            onClose={() => setShowPopup(false)}
            setOrderData={setOrderData}
            setShowImportPopup={setShowImportPopup}
          />
        )}
        {showImportPopup && (
          <ImportFileModal
            csvErrorMessage=""
            closePopup={() => setShowImportPopup(false)}
            clearErrorMessage={() => {}}
            handleUploadFile={handelImportCSV}
            templatePath="admin/template/xls/finance_order_product_template.xlsx"
            fileType=".xlsx"
            noticeText="註：匯入上限為 1000 筆"
          />
        )}
        {/* 月對帳單列表 */}
        <FinanceOrderLists />
      </StyledSection>
    </div>
  );
};

export default FinanceOrder;
