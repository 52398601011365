import onlineOrderApi, {
  CreateOrder,
  OnlineOrderListResult,
  OrderDetail,
  ProductListResult,
  UpdateOrder,
  ProductBarcodeInfo,
  ProductStockInfoList,
  OrderListFilter,
  UpdateProduct,
  CreateProduct,
} from "@api/throughShipment/onlineOrderApi";
import { message, Modal } from "antd";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import translateErrors from "@page/ThroughShipment/OnlineOrder/utils/translateErrors";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  isProductFetching: boolean;
  isProductInfoFetching: boolean;
  orderListFilter: OrderListFilter;
  onlineOrderListResult: OnlineOrderListResult;
  onlineOrderDetail: OrderDetail;
  orderProductList: ProductListResult;
  productBarcodeInfo: ProductBarcodeInfo;
  productStockInfo: ProductStockInfoList[];
}

const initialState: IState = {
  onlineOrderListResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  orderListFilter: {
    limit: 20,
    offset: 0,
  },
  isFetching: false,
  isProductFetching: false,
  isProductInfoFetching: false,
  onlineOrderDetail: {
    id: 0,
    destroyedAt: null,
    submittedAt: null,
    orderNumber: "",
    staff: "",
    createdAt: "",
    status: 0,
    warehouseCode: "",
    platform: "",
    grandTotal: 0,
    estimatedShippingDate: "",
    estimatedArrivalDate: "",
    platformNumber: "",
    shippingMethod: 0,
    receiverName: "",
    receiverPhone: "",
    shippingDate: "",
    receiverAddress: "",
    receiverZipcode: "",
    packageNumber: "",
    note: "",
  },
  orderProductList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  productBarcodeInfo: {
    count: 0,
    next: null,
    previous: null,
    results: [
      {
        sku: "",
        warehouseCode: "",
        productName: "",
        barcode: "",
        weightedCost: 0,
      },
    ],
  },
  productStockInfo: [],
};

// 主單
export const fetchOrderList = createAsyncThunk(
  "onlineOrder/fetchOrderList",
  async (payload: OrderListFilter, thunkApi) => {
    try {
      const response = onlineOrderApi.fetchOrderList(payload);
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchCreateOrder = createAsyncThunk(
  "onlineOrder/fetchCreateOrder",
  async (payload: CreateOrder, thunkApi) => {
    const {
      onlineOrderSlice: { orderListFilter },
    } = thunkApi.getState() as RootState;
    try {
      const response = await onlineOrderApi.fetchCreateOrder(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchOrderList(orderListFilter));
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: translatedErrors,
        okText: "我知道了",
      });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchOrderDetail = createAsyncThunk("onlineOrder/fetchOrderDetail", async (orderId: string) => {
  const response = await onlineOrderApi.fetchOrderDetail(orderId);
  return response;
});

export const fetchUpdateOrder = createAsyncThunk(
  "onlineOrder/fetchUpdateOrder",
  async (payload: UpdateOrder, thunkApi) => {
    try {
      const response = await onlineOrderApi.fetchUpdateOrder(payload);
      message.success("編輯成功");
      thunkApi.dispatch(fetchOrderDetail(payload.orderId));
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: translatedErrors,
        okText: "我知道了",
      });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchDeleteOrder = createAsyncThunk("onlineOrder/fetchDeleteOrder", async (orderId: number, thunkApi) => {
  try {
    const {
      onlineOrderSlice: { orderListFilter },
    } = thunkApi.getState() as RootState;
    const response = await onlineOrderApi.fetchDeleteOrder(orderId);
    message.success("已作廢");
    thunkApi.dispatch(fetchOrderList(orderListFilter));

    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchSubmitOrder = createAsyncThunk("onlineOrder/fetchSubmitOrder", async (orderId: string, thunkApi) => {
  try {
    const response = await onlineOrderApi.fetchSubmitOrder(orderId);
    thunkApi.dispatch(fetchOrderDetail(orderId));
    message.success("已提交");
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

// 商品
export const fetchProductList = createAsyncThunk("onlineOrder/fetchProductList", async (orderId: string) => {
  const response = await onlineOrderApi.fetchProductList(orderId);
  return response;
});

export const fetchCreateProduct = createAsyncThunk(
  "onlineOrder/fetchCreateProduct",
  async (payload: CreateProduct, thunkApi) => {
    try {
      const response = await onlineOrderApi.fetchCreateProduct(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchProductList(payload.orderId));
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: translatedErrors,
        okText: "我知道了",
      });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchUpdateProduct = createAsyncThunk(
  "onlineOrder/fetchUpdateProduct",
  async (payload: UpdateProduct, thunkApi) => {
    try {
      const response = await onlineOrderApi.fetchUpdateProduct(payload);
      thunkApi.dispatch(fetchProductList(payload.orderId));
      message.success("編輯成功");
      return response;
    } catch (error: any) {
      const { result } = error;
      const translatedErrors = translateErrors(result);
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: translatedErrors,
        okText: "我知道了",
      });
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchDeleteProduct = createAsyncThunk(
  "onlineOrder/fetchDeleteProduct",
  async (payload: { orderId: string; productId: number }, thunkApi) => {
    try {
      const response = await onlineOrderApi.fetchDeleteProduct(payload);
      thunkApi.dispatch(fetchProductList(payload.orderId));
      message.success("刪除成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchUploadProducts = createAsyncThunk(
  "onlineOrder/fetchUploadProducts",
  async (payload: { file: File; orderId: string }) => {
    const response = await onlineOrderApi.fetchUploadProducts(payload);
    return response;
  },
);

export const fetchProductDetailBySku = createAsyncThunk(
  "onlineOrder/fetchProductDetailBySku",
  async (payload: { sku: string; warehouse: string }) => {
    const response = await onlineOrderApi.fetchProductBarcodeBySku(payload);
    return response;
  },
);

export const fetchProductStockBySku = createAsyncThunk(
  "onlineOrder/fetchProductStockBySku",
  async (payload: { sku: string; warehouse: string }) => {
    const response = await onlineOrderApi.fetchProductStockBySku(payload);
    return response;
  },
);

const onlineOrderSlice = createSlice({
  name: "onlineOrder",
  initialState,
  reducers: {
    resetOrderFilter: () => initialState,
    setOrderListFilter: (state, action) => {
      state.orderListFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOrderList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOrderList.fulfilled, (state, action) => {
      state.onlineOrderListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchOrderDetail.fulfilled, (state, action) => {
      state.onlineOrderDetail = action.payload;
    });
    builder.addCase(fetchProductList.pending, (state, action) => {
      state.isProductFetching = true;
    });
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.orderProductList = action.payload;
      state.isProductFetching = false;
    });
    builder.addCase(fetchCreateOrder.fulfilled, (state, action) => {
      window.open(`${window.location.href}/${action.payload.id}`);
    });
    builder.addCase(fetchProductDetailBySku.pending, (state, action) => {
      state.isProductInfoFetching = true;
    });
    builder.addCase(fetchProductDetailBySku.fulfilled, (state, action) => {
      state.productBarcodeInfo = action.payload;
      state.isProductInfoFetching = false;
    });
    builder.addCase(fetchProductStockBySku.pending, (state, action) => {
      state.isProductInfoFetching = true;
    });
    builder.addCase(fetchProductStockBySku.fulfilled, (state, action) => {
      state.productStockInfo = action.payload;
      state.isProductInfoFetching = false;
    });
  },
});

export const { resetOrderFilter, setOrderListFilter } = onlineOrderSlice.actions;
export const onlineOrderState = (state: RootState) => state.onlineOrderSlice;
export default onlineOrderSlice.reducer;
