/* eslint-disable jsx-a11y/control-has-associated-label */
import { VipPromotionItemType } from "@api/vipPromotionApi";
import PageTitle from "@component/PageTitle";
import QuillEditor from "@component/QuillEditor";
import { fetchUpdateVipPromotion, fetchVipPromotion, VipRuleState } from "@redux/vipPromotionSlice";
import { Button, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  line-height: 32px;
  margin-right: 10px;
  font-size: 14px;
  width: 200px;
`;
const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export default function VipPromotion() {
  const dispatch = useDispatch();
  const { isFetching, vipPromotion } = useSelector(VipRuleState);

  const [vipPromotionIdMap, setVipPromotionIdMap] = useState<Map<VipPromotionItemType, number>>(new Map());
  const [promotionData, setPromotionData] = useState<{
    promotionBanner: string;
    promotionRights: string;
    promotionSaving: string;
    promotionUpgrade: string;
    purchasePageBanner: string;
  }>({
    promotionBanner: "",
    promotionRights: "",
    promotionSaving: "",
    promotionUpgrade: "",
    purchasePageBanner: "",
  });

  const onSubmit = () => {
    const descriptionData = [
      {
        id: vipPromotionIdMap.get(VipPromotionItemType.S_PROMOTION_BANNER) as number,
        description: promotionData.promotionBanner,
      },
      {
        id: vipPromotionIdMap.get(VipPromotionItemType.S_PROMOTION_RIGHTS) as number,
        description: promotionData.promotionRights,
      },
      {
        id: vipPromotionIdMap.get(VipPromotionItemType.S_PROMOTION_SAVING) as number,
        description: promotionData.promotionSaving,
      },
      {
        id: vipPromotionIdMap.get(VipPromotionItemType.S_PROMOTION_UPGRADE) as number,
        description: promotionData.promotionUpgrade,
      },
      {
        id: vipPromotionIdMap.get(VipPromotionItemType.S_PURCHASE_PAGE_BANNER) as number,
        description: promotionData.purchasePageBanner,
      },
    ];

    dispatch(fetchUpdateVipPromotion({ descriptionData }));
  };

  useEffect(() => {
    if (vipPromotion.length > 0) {
      const map = new Map();
      const payload = {
        promotionBanner: "",
        promotionRights: "",
        promotionSaving: "",
        promotionUpgrade: "",
        purchasePageBanner: "",
      };
      vipPromotion.forEach((promotion) => {
        map.set(promotion.itemType, promotion.id);
        switch (promotion.itemType) {
          case VipPromotionItemType.S_PROMOTION_BANNER:
            payload.promotionBanner = promotion.description;
            break;
          case VipPromotionItemType.S_PROMOTION_RIGHTS:
            payload.promotionRights = promotion.description;
            break;
          case VipPromotionItemType.S_PROMOTION_SAVING:
            payload.promotionSaving = promotion.description;
            break;
          case VipPromotionItemType.S_PROMOTION_UPGRADE:
            payload.promotionUpgrade = promotion.description;
            break;
          case VipPromotionItemType.S_PURCHASE_PAGE_BANNER:
            payload.purchasePageBanner = promotion.description;
            break;
          default:
            break;
        }
      });

      setVipPromotionIdMap(map);
      setPromotionData(payload);
    }
  }, [vipPromotion]);

  useEffect(() => {
    dispatch(fetchVipPromotion());
  }, [dispatch]);

  return (
    <Spin spinning={isFetching}>
      <Wrapper>
        <PageTitle title="黑卡推廣頁" />
        <Row>
          <RowTitle>黑卡推廣頁-Banner</RowTitle>
          <QuillEditor
            toolbarName="promotionBanner"
            uploadImagePathname="vip_promotion"
            defaultHtml={promotionData.promotionBanner}
            onChangeQuill={(html) => setPromotionData((prev) => ({ ...prev, promotionBanner: html }))}
          />
        </Row>
        <Row>
          <RowTitle>黑卡推廣頁-權益</RowTitle>
          <QuillEditor
            toolbarName="promotionRights"
            uploadImagePathname="vip_promotion"
            defaultHtml={promotionData.promotionRights}
            onChangeQuill={(html) => setPromotionData((prev) => ({ ...prev, promotionRights: html }))}
          />
        </Row>
        <Row>
          <RowTitle>黑卡推廣頁-如何省</RowTitle>
          <QuillEditor
            toolbarName="promotionSaving"
            uploadImagePathname="vip_promotion"
            defaultHtml={promotionData.promotionSaving}
            onChangeQuill={(html) => setPromotionData((prev) => ({ ...prev, promotionSaving: html }))}
          />
        </Row>
        <Row>
          <RowTitle>黑卡推廣頁-輕鬆升級</RowTitle>
          <QuillEditor
            toolbarName="promotionUpgrade"
            uploadImagePathname="vip_promotion"
            defaultHtml={promotionData.promotionUpgrade}
            onChangeQuill={(html) => setPromotionData((prev) => ({ ...prev, promotionUpgrade: html }))}
          />
        </Row>
        <Row>
          <RowTitle>黑卡購買頁-Banner</RowTitle>
          <QuillEditor
            toolbarName="purchasePageBanner"
            uploadImagePathname="vip_promotion"
            defaultHtml={promotionData.purchasePageBanner}
            onChangeQuill={(html) => setPromotionData((prev) => ({ ...prev, purchasePageBanner: html }))}
          />
        </Row>
        <Footer>
          <Button type="primary" onClick={onSubmit}>
            儲存
          </Button>
        </Footer>
      </Wrapper>
    </Spin>
  );
}
