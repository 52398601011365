import { SalesCategory } from "./generateCategoryTree";

export default function filterParentCategories(
  level1: Map<number, SalesCategory>,
  level2: Map<number, SalesCategory>,
  level3: Map<number, SalesCategory>,
  checkList: number[],
) {
  const resultMap = new Map<number, number>();

  checkList.forEach((checkNumber) => {
    if (level1.has(checkNumber)) {
      resultMap.set(checkNumber, checkNumber);
    }

    if (level2.has(checkNumber)) {
      const element = level2.get(checkNumber);

      if (!resultMap.has(element!.parent)) {
        resultMap.set(checkNumber, checkNumber);
      }
    }

    if (level3.has(checkNumber)) {
      const level3Element = level3.get(checkNumber);

      if (!resultMap.has(level3Element!.parent)) {
        const level2Element = level2.get(level3Element!.parent);

        if (!resultMap.has(level2Element!.parent)) {
          resultMap.set(checkNumber, checkNumber);
        }
      }
    }
  });

  return Array.from(resultMap.values());
}
