import axios from "axios";
import { Bank } from "src/types";

interface BankAPIType {
  getBankCode: () => Promise<Bank.Item[]>;
}

const bankCodeLink = `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/web_assets/bankCode.json`;

const BankAPI: BankAPIType = {
  getBankCode: async () => {
    const list = await axios.get(bankCodeLink);
    return list.data;
  },
};

export default BankAPI;
