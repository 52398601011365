import AWS from "aws-sdk";

const getBucketName = () => {
  const env = process.env.REACT_APP_NODE_ENV;
  if (env === "production") {
    return "lucas-production";
  }
  if (env === "staging") {
    return "lucas-staging";
  }
  return "lucas-develop";
};

const bucketRegion = "ap-northeast-1";

export const setupAWS = () => {
  AWS.config.update({
    region: bucketRegion,
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
  });
};

export interface UploadParam {
  file: string | Buffer | Uint8Array | Blob;
  name: string;
  pathname: string;
}

export const uploadPhotoToS3 = async (
  param: Partial<UploadParam>,
  progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void,
): Promise<AWS.S3.ManagedUpload.SendData> => {
  const { file, name, pathname } = param;

  const upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: getBucketName(),
      Key: `${pathname}/${name}`,
      Body: file,
      ACL: "public-read",
    },
  });

  if (progressCallback) upload.on("httpUploadProgress", progressCallback);
  return upload.promise();
};

export const uploadVIPPhotoToS3 = async (
  param: Partial<UploadParam>,
  progressCallback?: (progress: AWS.S3.ManagedUpload.Progress) => void,
): Promise<AWS.S3.ManagedUpload.SendData> => {
  const { file, name } = param;

  const upload = new AWS.S3.ManagedUpload({
    params: {
      Bucket: getBucketName(),
      Key: `media/vip/${name}`,
      Body: file,
      ContentType: "image/png",
      ACL: "public-read",
    },
  });

  if (progressCallback) upload.on("httpUploadProgress", progressCallback);
  return upload.promise();
};
