import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface IReviewApi {
  fetchReviewList: (params: FetchReviewListParams) => Promise<ReviewListResult>;
  fetchReviewDetail: (id: number) => Promise<ReviewListItem>;
  updateReviewDetail: (id: number, params: UpdateDetailParams) => Promise<ReviewListItem>;
  updateReviewApprovalStatus: (params: UpdateReviewApprovalStatus) => Promise<StandardResponse>;
}

export interface ReviewListResult {
  count: number;
  previous: string;
  next: string;
  results: ReviewListItem[];
}

export interface FetchReviewListParams {
  limit: number;
  offset: number;
  id?: number;
  orderNumber?: string;
  approvalStatus?: ReviewStatus;
  memberPhone?: string;
}

export enum ReviewStatus {
  NO_REVIEW = 0,
  PEDNING = 1,
  APPROVED = 2,
  REJECTED = 3,
}

export const ReviewStatusMap: {
  [index: number]: string;
} = {
  [ReviewStatus.NO_REVIEW]: "待評論",
  [ReviewStatus.PEDNING]: "審核中",
  [ReviewStatus.APPROVED]: "審核通過",
  [ReviewStatus.REJECTED]: "審核不通過",
};

export interface ReviewListItem {
  id: number;
  submissionAt: string;
  approvalStatus: ReviewStatus;
  comments: string;
  salesPageName: string;
  salesPageId: number;
  planName: string;
  orderNumber: string;
  orderId: number;
  memberName: string;
  memberEmail: string;
  memberPhone: string;
  photo: string;
  skus: string[];
  scores: number;
  optionsDetail: {
    totalQty: number;
    productName: string;
  }[];
}

export interface UpdateDetailParams {
  approvalStatus: ReviewStatus;
  scores: number;
  comments: string;
  imageDirPrefix?: string;
  imageFilePath?: string;
  imageAlt?: string;
}

export interface UpdateReviewApprovalStatus {
  ids: string;
  approvalStatus: 1 | -1;
}

const reviewApi: IReviewApi = {
  fetchReviewList: async (params) => {
    const formatedParams: FetchReviewListParams = {
      limit: params.limit,
      offset: params.offset,
      id: params.id || undefined,
      orderNumber: params.orderNumber || undefined,
      approvalStatus: params.approvalStatus,
      memberPhone: params.memberPhone || undefined,
    };

    const getParams = transformCamelToSnake(formatedParams);
    const response = await apiClient.getData("/manage/sales/reviews/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchReviewDetail: async (id) => {
    const response = await apiClient.getData(`/manage/sales/reviews/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  updateReviewDetail: async (id, params) => {
    const putParams = transformCamelToSnake(params);
    const response = await apiClient.putData(`/manage/sales/reviews/${id}/`, putParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateReviewApprovalStatus: async (params) => {
    const putParams = transformCamelToSnake(params);
    const response = await apiClient.putData("/manage/sales/reviews/bulk_approval/", putParams);
    return response.data;
  },
};

export default reviewApi;
