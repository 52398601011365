import React from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Select, DatePicker } from "antd";
import {
  OnlineDistributorStatisticsState,
  resetODChargeListParams,
  resetOnlineDistributorListParams,
  updateODChargeListParams,
  updateOnlineDistributorListParams,
} from "@redux/finance/onlineDistributorStatisticsSlice";
import { useDispatch, useSelector } from "react-redux";
import { distributorOptions } from "./constant";

const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 200px;
`;

type Props = {
  type: "order" | "charge";
};

export default function Filter(props: Props) {
  const { type } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { onlineDistributorListParams } = useSelector(OnlineDistributorStatisticsState);
  const { ODChargeListParams } = useSelector(OnlineDistributorStatisticsState);

  const onSubmit = (values: any) => {
    const { distributor, month } = values;
    if (!distributor && !month) return;

    switch (type) {
      case "order": {
        const filter = {
          ...onlineDistributorListParams,
          distributor,
          date: month?.startOf("month").format("YYYY-MM-DD"),
        };
        dispatch(updateOnlineDistributorListParams(filter));
        break;
      }
      case "charge": {
        const filter = {
          ...ODChargeListParams,
          distributor,
          date: month?.startOf("month").format("YYYY-MM-DD"),
        };
        dispatch(updateODChargeListParams(filter));
        break;
      }
      default:
    }
  };

  const onReset = () => {
    form.resetFields();
    switch (type) {
      case "order":
        dispatch(resetOnlineDistributorListParams());
        break;
      case "charge":
        dispatch(resetODChargeListParams());
        break;
      default:
    }
  };

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={16}>
        <Col span={7}>
          <Form.Item label="通路別" name="distributor">
            <Select placeholder="請選擇">
              {distributorOptions.map((option) => (
                <Select.Option key={option.name} value={option.value}>
                  {option.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="對帳月份" name="month">
            <CustomDatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
