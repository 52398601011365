/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-const */

import { PlusCircleOutlined } from "@ant-design/icons";
import EmptyContent from "@component/EmptyContent";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import {
  addNewMenu,
  fetchMenuList,
  fetchMoveMenu,
  toggleDisplayInactive,
  reset,
  updateSelectedMenu,
} from "@redux/menuManagementSlice";
import { MenuNode } from "@api/menuMangementApi";
import { Checkbox, Tree } from "antd";
import { DataNode, EventDataNode } from "antd/lib/tree";
import { produce } from "immer";
import React, { ReactText, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import EditTab from "./EditTab";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 12px 0 0 0;
  height: 100%;
`;
const TreeBlock = styled.div`
  flex: 3;
  min-width: 0;
  z-index: 1;
  background-color: white;
  padding-left: 20px;
`;
const ContentBlock = styled.div`
  flex: 7;
  min-width: 0;
  padding-right: 25px;
  padding-bottom: 60px;
`;
const TaleftSiblingNodelock = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 12px 0 0 20px;
  border: 1px solid ${({ theme }) => theme.colorNeutral400};
  border-radius: 2px;
`;
const AddNodeWrapper = styled.div`
  margin-left: -21px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const AddNodeText = styled.p`
  font-size: 14px;
  padding: 0 4px;
  margin: 0;
  margin-left: 4px;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;
const TreeTitle = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "#000000" : "rgba(0, 0, 0, 0.25)")};
`;

interface SelectEventInfo {
  event: "select";
  selected: boolean;
  node: EventDataNode<MenuNode>;
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
}

type CustomEvent = EventDataNode<MenuNode> & MenuNode;

const MenuMangement = () => {
  const displayInactiveCategory = useSelector((state: RootState) => state.menuMangement.displayInactive);
  const selectedMenu = useSelector((state: RootState) => state.menuMangement.selectedMenu);
  const menuList = useSelector((state: RootState) => state.menuMangement.menuList);
  const isFetchingMenuList = useSelector((state: RootState) => state.menuMangement.isFetchingMenuList);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchMenuList());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  // 加上新增按鈕到每一層
  const categoryListWithAddButton = useMemo(() => {
    const result = produce(menuList, (draftCategoryList) => {
      // 新增品類按鈕到第一層
      draftCategoryList.push({
        title: (
          <AddNodeWrapper>
            <PlusCircleOutlined />
            <AddNodeText>新增分類</AddNodeText>
          </AddNodeWrapper>
        ),
        parent: -1,
        key: "add",
        id: "add",
        isLeaf: true,
        children: [],
        isActive: true,
        isAddButton: true,
        level: 1,
        openingNewTab: false,
        link: "",
        name: "",
      });
      draftCategoryList.forEach((ctgy) => {
        // 新增品類按鈕到第二層
        const level2AddNode = {
          title: (
            <AddNodeWrapper>
              <PlusCircleOutlined />
              <AddNodeText>新增分類</AddNodeText>
            </AddNodeWrapper>
          ),
          parent: ctgy.key,
          key: `${ctgy.key}-add`,
          id: `${ctgy.key}-add`,
          isLeaf: true,
          isActive: true,
          isAddButton: true,
          children: [],
          level: 2,
          openingNewTab: false,
          link: "",
          name: "",
        };
        if (ctgy.children) {
          ctgy.children.push(level2AddNode);
        } else {
          ctgy.children = [level2AddNode];
        }
        ctgy.children.forEach((subCtgy) => {
          // 新增品類按鈕到第三層
          const level3AddNode = {
            title: (
              <AddNodeWrapper>
                <PlusCircleOutlined />
                <AddNodeText>新增分類</AddNodeText>
              </AddNodeWrapper>
            ),
            parent: subCtgy.key,
            key: `${subCtgy.key}-add`,
            id: `${subCtgy.key}-add`,
            isLeaf: true,
            isActive: true,
            isAddButton: true,
            children: [],
            salesPageCount: 0,
            level: 3,
            openingNewTab: false,
            link: "",
            name: "",
          };
          if (subCtgy.children) {
            subCtgy.children.push(level3AddNode);
          } else {
            subCtgy.children = [level3AddNode];
          }
        });
      });
    });
    return result;
  }, [menuList]);

  function toggleDisplayInactiveCategory() {
    dispatch(toggleDisplayInactive());
    dispatch(fetchMenuList());
  }

  function handleOnSelect(selectedKeys: React.ReactText[], info: SelectEventInfo) {
    const node = info.node as CustomEvent;
    const { id, parent, level, isActive, name, openingNewTab, link } = node;
    const numberId = Number(id);
    const numberParent = Number(parent);

    if (node.isAddButton) {
      // if (hasNewNode) return;

      const addedNode = {
        title: "新分類",
        parent,
        key: -1,
        id: -1,
        level,
        isActive: true,
        children: [],
        openingNewTab,
        link,
        name,
      };
      dispatch(addNewMenu(addedNode));
      dispatch(
        updateSelectedMenu({
          id: -1,
          parent: numberParent,
          level,
          isActive,
          name,
          openingNewTab,
          link,
        }),
      );
    } else {
      dispatch(
        updateSelectedMenu({
          id: numberId,
          parent: numberParent,
          level,
          isActive,
          name,
          openingNewTab,
          link,
        }),
      );
    }
  }

  function handleDragDrop(info: {
    event: React.MouseEvent<HTMLDivElement>;
    node: EventDataNode<MenuNode>;
    dragNode: EventDataNode<MenuNode>;
    dragNodesKeys: ReactText[];
    dropPosition: number;
    dropToGap: boolean;
  }) {
    const dropNode = info.node as CustomEvent;
    const dragNode = info.dragNode as CustomEvent;
    const dropPos: string[] = (dropNode as any).props.pos.split("-"); // AntDesign的EventDataNode沒有給props，用any替代

    // 移到空的父層下的第一個位置 dropNode會是父層的node 而且 dropNode.expanded = true
    // 處理同層其他node移動到該父層的第一個,drop.level===1,drag.level===2
    if (dropNode.expanded) {
      let rightSiblingNode: MenuNode | undefined;
      const dragMenuId = dragNode.key;
      let parentId = dropNode.parent;
      if (dropPos.length === 2) {
        // level2
        const dropNodeIndex = Number(dropPos[1]);
        rightSiblingNode = menuList[dropNodeIndex].children[0];
        if (menuList[dropNodeIndex].children.length === 0) {
          parentId = menuList[dropNodeIndex].id as number;
        }
      } else if (dropPos.length === 3) {
        // 1->2
        const firstDropNodeLevel = Number(dropPos[1]);
        let tempNode: MenuNode = menuList[firstDropNodeLevel];
        parentId = dropNode.id;
        const dropNodeIndex = Number(dropPos[2]);
        // 若tempNode.children[dropNodeIndex].children.length === 0？？
        rightSiblingNode = tempNode.children[dropNodeIndex].children[0];
      }
      const params = {
        categoryId: Number(dragMenuId),
        rightSibling: rightSiblingNode && Number(rightSiblingNode.id),
        // rightSibling: (rightSiblingNode && Number(rightSiblingNode.id)) || -1,
        parent: Number(parentId),
      };

      dispatch(fetchMoveMenu(params));
      return;
    }

    // 處理同層移動:
    if (dropNode.level === dragNode.level) {
      const moveLevel = dragNode.level;
      const dragMenuId = dragNode.key;
      let parentId = dropNode.parent;
      let leftSiblingNode: MenuNode | undefined;
      let rightSiblingNode: MenuNode | undefined;

      // dropPos回來的格式是 0-45-1-1 -> 第一個0沒有意義 -> 第一層的第45 index的node -> 第二層的第1 index的node -> 以此類推
      if (moveLevel === 1) {
        const dropNodeIndex = Number(dropPos[1]);
        leftSiblingNode = menuList[dropNodeIndex];
        rightSiblingNode = menuList[dropNodeIndex + 1];
      } else if (moveLevel === 2) {
        const firstDropNodeLevel = Number(dropPos[1]);
        let tempNode: MenuNode = menuList[firstDropNodeLevel];
        const dropNodeIndex = Number(dropPos[2]);
        leftSiblingNode = tempNode.children[dropNodeIndex];
        rightSiblingNode = tempNode.children[dropNodeIndex + 1];
      } else {
        const firstDropNodeLevel = Number(dropPos[1]);
        const secondDropNodeLevel = Number(dropPos[2]);
        let tempNode: MenuNode = menuList[firstDropNodeLevel].children[secondDropNodeLevel];
        const dropNodeIndex = Number(dropPos[3]);
        leftSiblingNode = tempNode.children[dropNodeIndex];
        rightSiblingNode = tempNode.children[dropNodeIndex + 1];
      }

      const params = {
        categoryId: Number(dragMenuId),
        leftSibling: leftSiblingNode && Number(leftSiblingNode.id),
        rightSibling: rightSiblingNode && Number(rightSiblingNode.id),
        parent: Number(parentId),
      };
      dispatch(fetchMoveMenu(params));
    }
    // 處理跨層移動
    else {
      let leftSiblingNode: MenuNode | undefined;
      let rightSiblingNode: MenuNode | undefined;
      const dragMenuId = dragNode.key;
      const parentId = dropNode.parent;
      if (dropPos.length === 2) {
        // 3->1
        const dropNodeIndex = Number(dropPos[1]);
        leftSiblingNode = menuList[dropNodeIndex];
        rightSiblingNode = menuList[dropNodeIndex + 1];
      } else if (dropPos.length === 3) {
        // 1->2
        const firstDropNodeLevel = Number(dropPos[1]);
        let tempNode: MenuNode = menuList[firstDropNodeLevel];
        const dropNodeIndex = Number(dropPos[2]);
        leftSiblingNode = tempNode.children[dropNodeIndex];
        rightSiblingNode = tempNode.children[dropNodeIndex + 1];
      } else if (dropPos.length === 4) {
        // 2->3
        const firstDropNodeLevel = Number(dropPos[1]);
        const secondDropNodeLevel = Number(dropPos[2]);
        let tempNode: MenuNode = menuList[firstDropNodeLevel].children[secondDropNodeLevel];
        const dropNodeIndex = Number(dropPos[3]);
        leftSiblingNode = tempNode.children[dropNodeIndex];
        rightSiblingNode = tempNode.children[dropNodeIndex + 1];
      }

      const params = {
        categoryId: Number(dragMenuId),
        leftSibling: leftSiblingNode && Number(leftSiblingNode.id),
        rightSibling: rightSiblingNode && Number(rightSiblingNode.id),
        parent: Number(parentId),
      };
      dispatch(fetchMoveMenu(params));
    }
  }

  return (
    <Wrapper>
      <PageTitle title="目錄管理頁" />
      <TreeBlock>
        {isFetchingMenuList ? (
          "Loading..."
        ) : (
          <>
            <StyledCheckbox onChange={toggleDisplayInactiveCategory} checked={displayInactiveCategory}>
              顯示未啟用的分類
            </StyledCheckbox>
            <Tree
              treeData={categoryListWithAddButton}
              onSelect={handleOnSelect}
              showIcon
              draggable
              blockNode
              onDrop={handleDragDrop}
              titleRender={(node: any) => <TreeTitle isActive={node.isActive}>{node.title}</TreeTitle>}
              defaultExpandedKeys={[selectedMenu.parent]}
              selectedKeys={[selectedMenu ? selectedMenu.id : ""]}
            />
          </>
        )}
      </TreeBlock>
      <ContentBlock>
        {selectedMenu.id !== 0 ? (
          <TaleftSiblingNodelock>
            <EditTab />
          </TaleftSiblingNodelock>
        ) : (
          <EmptyContent />
        )}
      </ContentBlock>
    </Wrapper>
  );
};

export default MenuMangement;
