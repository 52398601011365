import { ExclamationCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { uploadMedia } from "@api/uploadMediaApi";
import { Button, message, Modal, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import { UploadRequestOption as RcCustomRequestOptions } from "rc-upload/lib/interface";

import React, { useEffect, useState } from "react";

type Props = {
  onUpload: (url?: String) => void;
  path?: string;
};

const UploadFIleButton = (props: Props) => {
  const { onUpload, path } = props;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleChange = (info: UploadChangeParam) => {
    const { file } = info;
    const { response, originFileObj } = file;
    const reader = new FileReader();

    if (originFileObj) {
      reader.readAsDataURL(originFileObj);
      reader.onload = () => {
        const url = URL.createObjectURL(originFileObj);
        file.url = url;
        setFileList([file]);
      };
    }
    onUpload(response?.url);
  };

  const handleRemove = () => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        message.success("刪除成功");
        onUpload();
        setFileList([]);
      },
      onCancel() {},
    });
  };

  const updateFile = async (options: RcCustomRequestOptions) => {
    const { file, onSuccess } = options;
    try {
      const result = await uploadMedia(file as File);
      if (onSuccess) onSuccess({ url: result }, file as any);
      return "success";
    } catch (error: any) {
      return "error";
    }
  };
  useEffect(() => {
    if (path) {
      const updateFileList: UploadFile[] = [
        {
          uid: "-1",
          name: "已上傳檔案",
          status: "done",
          url: path,
          size: 0,
          type: "",
        },
      ];
      setFileList(updateFileList);
    }
  }, [path]);

  return (
    <Upload
      onChange={handleChange}
      customRequest={updateFile}
      fileList={fileList}
      onRemove={handleRemove}
      showUploadList={{ showRemoveIcon: Boolean(!path) }}
    >
      <Button icon={<UploadOutlined />}>點擊上傳</Button>
    </Upload>
  );
};
export default UploadFIleButton;
