import { RuleDetail, UseTypeValue } from "@api/ruleApi";
import { DatePicker, Form, InputNumber, Radio, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { FormInstance } from "antd/lib/form";
import moment from "moment";
import React, { FC, MouseEvent } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import type { LocalFormType } from ".";
import { ActionTypeValue } from "../RuleList/actionTypeValue";

const Wrapper = styled.div`
  position: relative;
  grid-row-start: 3;
  height: 260px;
  border: 1px solid #d9d9d9;

  & .ant-col {
    position: static;
  }
  & .ant-form-item-control-input {
    position: static;
  }
  & .ant-form-item-control-input {
    min-height: 0;
  }
  & .ant-form-item-explain {
    min-height: 0;
  }
`;
const OverrideWrapper = styled.div`
  height: 0px;
`;
const ValidateWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid transparent;

  .ant-form-item-has-error & {
    border-color: #ff4d4f;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.span`
  color: red;
`;
const Body = styled.div`
  padding: 12px 20px 20px 20px;
`;
const BlockRadio = styled(Radio)<{ radioHeight?: number }>`
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;

  .ant-radio.ant-radio.ant-radio {
    display: flex;
    align-items: center;
    height: ${({ radioHeight }) => radioHeight || 22}px;
  }
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const RangePicker = styled(DatePicker.RangePicker)<{ showTime?: any }>`
  margin-top: 10px;
  width: 350px;
`;
const StyledSelect = styled(Select)`
  width: 70px;
  margin: 0 10px;
`;
const NoBubbleBlock = styled.div``;
const CycleInput = styled(InputNumber)`
  margin: 0 6px;
  width: 58px;
`;
const HoursAfterInput = styled(InputNumber)`
  margin: 0 10px;
  width: 58px;
`;

interface Props {
  form: FormInstance<LocalFormType>;
}

const useCyleOptions = [
  {
    label: "每日",
    value: UseTypeValue.CYCLE_IN_DAY,
  },
  {
    label: "每週",
    value: UseTypeValue.CYCLE_IN_WEEK,
  },
  {
    label: "每月",
    value: UseTypeValue.CYCLE_IN_MONTH,
  },
];

const UseTime: FC<Props> = (props) => {
  const { form } = props;
  const { id } = useParams();
  const isEditMode = !!id; // 有id表示已經建立案型

  const handleOnSelectCycles = () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      useMethod: {
        ...values.useMethod,
        useType: UseTypeValue.CYCLE_IN_DAY,
      },
    });
  };

  const handleOnSelectHoursAfter = () => {
    const values = form.getFieldsValue();
    form.setFieldsValue({
      useMethod: {
        ...values.useMethod,
        useType: UseTypeValue.HOURS_AFTER_BIND,
      },
    });
  };

  const preventDefault = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Wrapper>
      <OverrideWrapper>
        <Form.Item name={["useMethod", "useType"]} rules={[{ required: true, message: "" }]}>
          <ValidateWrapper />
        </Form.Item>
      </OverrideWrapper>
      <Header>
        使用時間
        <Red>*</Red>
      </Header>
      <Body>
        <Form.Item name={["useMethod", "useType"]} rules={[{ required: true, message: "" }]} noStyle>
          <Radio.Group>
            <BlockRadio value={UseTypeValue.NO_LIMIT} disabled={isEditMode}>
              不限
            </BlockRadio>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.useMethod !== currentValues.useMethod}
            >
              {({ getFieldValue }) => {
                const { useType }: RuleDetail["useMethod"] = getFieldValue("useMethod") || {};
                if (useType === UseTypeValue.ONE_TIME) {
                  return (
                    <BlockRadio value={UseTypeValue.ONE_TIME} disabled={isEditMode}>
                      <FlexColumn>
                        <div>時間區間</div>
                        <Form.Item name={["useMethod", "useTimeBetween"]} noStyle>
                          <RangePicker
                            locale={locale}
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime={{
                              hideDisabledOptions: true,
                              defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                            }}
                          />
                        </Form.Item>
                      </FlexColumn>
                    </BlockRadio>
                  );
                }
                return (
                  <BlockRadio value={UseTypeValue.ONE_TIME} disabled={isEditMode}>
                    時間區間
                  </BlockRadio>
                );
              }}
            </Form.Item>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.useMethod !== currentValues.useMethod ||
            prevValues.actionType !== currentValues.actionType ||
            prevValues.conditionAmountUpperBound !== currentValues.conditionAmountUpperBound
          }
        >
          {({ getFieldValue }) => {
            const { useType }: RuleDetail["useMethod"] = getFieldValue("useMethod") || {};
            let isChecked = false;
            switch (useType) {
              case UseTypeValue.CYCLE_IN_DAY:
              case UseTypeValue.CYCLE_IN_WEEK:
              case UseTypeValue.CYCLE_IN_MONTH:
                isChecked = true;
                break;
              default:
            }
            if (
              getFieldValue("actionType") === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE ||
              getFieldValue("conditionAmountUpperBound")
            ) {
              return null;
            }
            return (
              <BlockRadio
                checked={isChecked}
                onChange={handleOnSelectCycles}
                radioHeight={isChecked ? 32 : 22}
                disabled={isEditMode}
              >
                <FlexRow>
                  週期性
                  {isChecked && (
                    <NoBubbleBlock onClick={preventDefault}>
                      <Form.Item name={["useMethod", "useType"]} noStyle>
                        <StyledSelect options={useCyleOptions} />
                      </Form.Item>
                      連續
                      <Form.Item name={["useMethod", "useCycles"]} noStyle>
                        <CycleInput />
                      </Form.Item>
                      個週期
                    </NoBubbleBlock>
                  )}
                </FlexRow>
              </BlockRadio>
            );
          }}
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.useMethod !== currentValues.useMethod ||
            prevValues.actionType !== currentValues.actionType ||
            prevValues.conditionAmountUpperBound !== currentValues.conditionAmountUpperBound
          }
        >
          {({ getFieldValue }) => {
            const { useType }: RuleDetail["useMethod"] = getFieldValue("useMethod") || {};
            const isChecked = useType === UseTypeValue.HOURS_AFTER_BIND;
            if (
              getFieldValue("actionType") === ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE ||
              getFieldValue("conditionAmountUpperBound")
            ) {
              return null;
            }
            return (
              <BlockRadio
                checked={isChecked}
                onChange={handleOnSelectHoursAfter}
                radioHeight={isChecked ? 32 : 22}
                disabled={isEditMode}
              >
                <FlexRow>
                  時效性
                  {isChecked && (
                    <NoBubbleBlock onClick={preventDefault}>
                      <Form.Item name={["useMethod", "useWithinHours"]} noStyle>
                        <HoursAfterInput />
                      </Form.Item>
                      個小時後到期
                    </NoBubbleBlock>
                  )}
                </FlexRow>
              </BlockRadio>
            );
          }}
        </Form.Item>
      </Body>
    </Wrapper>
  );
};

export default UseTime;
