import salePageCategoryApi, {
  CreateSalesCategoryParam,
  FetchSalePageListParam,
  SalePageCategoryDetail,
  SalePageCategoryNode,
  SalePageListItem,
  SalePageListResult,
} from "@api/salePageCategoryApi";
import tagApi, { TagListResult } from "@api/tagApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { produce } from "immer";

const loop = (data: SalePageCategoryNode[], targetId: number, callback: Function) => {
  data.forEach((item, index) => {
    if (item.id === targetId) {
      callback(item, index, data);
    }
    if (item.children) {
      loop(item.children, targetId, callback);
    }
  });
};
interface IInitialState {
  hasNewNode: boolean;
  displayInactive: boolean;
  selectedCategory: {
    id: number;
    parent: number;
    level: number;
  };
  isFetchingCategoryList: boolean;
  categoryList: SalePageCategoryNode[];
  categoryDetail: SalePageCategoryDetail;
  isFetchingSalePageList: boolean;
  salePageListResult: SalePageListResult;
  selectedPageIds: number[];
  salePageListFilter: FetchSalePageListParam;
  brandListResult: TagListResult;
  brandListParam: BrandListParam;
  csvUploadSuccess: boolean;
  csvErrorMessage: string;
  isFetching: boolean;
}

interface BrandListParam {
  typeNameQ: "品牌";
  nameQ: string;
  limit: number;
  offset: number;
}

const DefaultSortMethods = [
  {
    id: 2,
    name: "最新",
    isDescend: false,
    isDefault: true,
    isActive: true,
  },
  {
    id: 3,
    name: "熱銷",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 4,
    name: "推薦",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 5,
    name: "價格(低至高)",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
  {
    id: 1,
    name: "價格(高至低)",
    isDescend: false,
    isDefault: false,
    isActive: true,
  },
];

export const initialState: IInitialState = {
  hasNewNode: false,
  displayInactive: true,
  selectedCategory: {
    id: 0,
    parent: -1,
    level: -1,
  },
  isFetchingCategoryList: false,
  categoryList: [],
  categoryDetail: {
    id: -1,
    name: "",
    path: "",
    parent: -1,
    pageInfo: {
      id: -1,
      title: "",
      description: "",
      image: {
        alt: "",
        id: -1,
        image: "",
        isActive: false,
        mediaType: -1,
        name: "",
        url: "",
      },
      backgroundColor: "",
      metaTitle: "",
      metaKeywords: "",
      metaDescription: "",
      sortMethods: DefaultSortMethods,
    },
    icon: undefined,
    iconAlt: "",
    iconId: undefined,
    level: 0,
    isActive: false,
    toShow: true,
  },
  isFetchingSalePageList: false,
  salePageListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  selectedPageIds: [],
  salePageListFilter: {
    limit: 20,
    offset: 0,
    pageIds: "",
    sku: "",
    pageName: "",
    brandNames: [],
    minPrice: "",
    maxPrice: "",
  },
  brandListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  csvUploadSuccess: false,
  csvErrorMessage: "",
  isFetching: false,
};

export const fetchSalesCategoryList = createAsyncThunk("salesCategory/fetchSalesCategoryList", async (_, thunkApi) => {
  const {
    salesCategory: { displayInactive },
  } = thunkApi.getState() as RootState;
  const response = await salePageCategoryApi.fetchSalesCategoryList(displayInactive);
  return response;
});

export const fetchSalesCategoryDetail = createAsyncThunk(
  "salesCategory/fetchSalesCategoryDetail",
  async (id: number) => {
    const response = await salePageCategoryApi.fetchSalesCategoryDetail(id);
    // 填滿sort methods: api只會回傳isActive的sortMethod
    const filledSortMethods = DefaultSortMethods.map((defaultStmd) => {
      const sortMethod = response.pageInfo.sortMethods.find((detailStmd) => detailStmd.name === defaultStmd.name);
      if (sortMethod) return sortMethod;
      return {
        ...defaultStmd,
        isActive: false,
      };
    });
    response.pageInfo.sortMethods = filledSortMethods;
    return response;
  },
);

export const createSalesCategory = createAsyncThunk<SalePageCategoryDetail, CreateSalesCategoryParam>(
  "salesCategory/createSalesCategory",
  async (params, thunkApi) => {
    const response = await salePageCategoryApi.createSalesCategory(params);
    const newSelectedCategory = {
      id: response.id,
      parent: response.parent,
      level: response.level,
    };
    try {
      thunkApi.dispatch(updateSelectedCategory(newSelectedCategory));
      thunkApi.dispatch(fetchSalesCategoryList());
      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const updateSalesCategory = createAsyncThunk<SalePageCategoryDetail, CreateSalesCategoryParam>(
  "salesCategory/updateSalesCategory",
  async (params, thunkApi) => {
    const {
      salesCategory: {
        selectedCategory: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await salePageCategoryApi.updateSalesCategory(id, params);
      // 填滿sort methods: api只會回傳isActive的sortMethod
      const filledSortMethods = DefaultSortMethods.map((defaultStmd) => {
        const sortMethod = response.pageInfo.sortMethods.find((detailStmd) => detailStmd.name === defaultStmd.name);
        if (sortMethod) return sortMethod;
        return {
          ...defaultStmd,
          isActive: false,
        };
      });
      response.pageInfo.sortMethods = filledSortMethods;
      thunkApi.dispatch(fetchSalesCategoryList());
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchSalePageList = createAsyncThunk("salesCategory/fetchSalePageList", async (_, thunkApi) => {
  const {
    salesCategory: {
      salePageListFilter,
      selectedCategory: { id },
    },
  } = thunkApi.getState() as RootState;

  const response = await salePageCategoryApi.fetchSalePageList(id, salePageListFilter);
  return response;
});

export const fetchSalePageListByLevelCollection = createAsyncThunk(
  "salesCategory/fetchSalePageListByLevelCollection",
  async (_, thunkApi) => {
    try {
      const {
        salesCategory: {
          salePageListFilter,
          selectedCategory: { id },
        },
      } = thunkApi.getState() as RootState;

      const response = await salePageCategoryApi.fetchSalePageListByLevelCollection(id, salePageListFilter);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchBrandList = createAsyncThunk("salesCategory/fetchBrandList", async (name: string, thunkApi) => {
  const params: BrandListParam = {
    typeNameQ: "品牌",
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("salesCategoryAddSales/loadMoreBrandList", async (_, thunkApi) => {
  const {
    salesCategory: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: BrandListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const batchUpdateSalePageRank = createAsyncThunk(
  "salesCategory/batchUpdateSalePageRank",
  async (params: { salePageList: SalePageListItem[]; canAddSalespage: boolean }, thunkApi) => {
    const {
      salesCategory: {
        selectedCategory: { id },
      },
    } = thunkApi.getState() as RootState;

    const response = await salePageCategoryApi.batchUpdateSalePageRank(id, params.salePageList);

    if (params.canAddSalespage) {
      thunkApi.dispatch(fetchSalePageList());
    } else {
      thunkApi.dispatch(fetchSalePageListByLevelCollection());
    }
    return response;
  },
);

export const batchRemoveSalePages = createAsyncThunk("salesCategory/batchRemoveSalePages", async (_, thunkApi) => {
  const {
    salesCategory: {
      selectedCategory: { id },
      selectedPageIds,
    },
  } = thunkApi.getState() as RootState;

  const response = await salePageCategoryApi.batchRemoveSalePages(id, selectedPageIds);
  thunkApi.dispatch(fetchSalePageList());
  thunkApi.dispatch(updateSelectedPageIds([]));

  return response;
});

export const batchAddSalePagesByCSV = createAsyncThunk(
  "salesCategory/batchAddSalePagesByCSV",
  async (file: File, thunkApi) => {
    const {
      salesCategory: {
        selectedCategory: { id },
      },
    } = thunkApi.getState() as RootState;

    const response = await salePageCategoryApi.batchAddSalePagesByCSV(id, file);
    thunkApi.dispatch(fetchSalePageList());
    return response;
  },
);

export const moveSalesPageCategory = createAsyncThunk(
  "salesCategory/moveSalesPage",
  async (arg: { categoryId: number; leftSibling?: number; rightSibling?: number; parent: number }, thunkApi) => {
    const { categoryId, leftSibling, rightSibling, parent } = arg;
    const {
      salesCategory: { categoryList },
    } = thunkApi.getState() as RootState;

    const putParams = {
      leftSibling,
      rightSibling,
      parent,
    };

    try {
      await salePageCategoryApi.moveSalesPageCategory(categoryId, putParams);

      const newCategoryList = produce(categoryList, (draftCategoryList) => {
        // find drag node
        let dragObj: SalePageCategoryNode;
        loop(
          draftCategoryList,
          categoryId,
          (item: SalePageCategoryNode, index: number, arr: SalePageCategoryNode[]) => {
            arr.splice(index, 1);
            dragObj = item;
          },
        );

        // drop node
        const insertNodeId = leftSibling || rightSibling;
        let insertArr: SalePageCategoryNode[];
        let insertIndex: number;
        loop(
          draftCategoryList,
          insertNodeId!,
          (item: SalePageCategoryNode, index: number, arr: SalePageCategoryNode[]) => {
            insertArr = arr;
            insertIndex = index;
          },
        );

        if (insertNodeId === leftSibling) {
          insertArr!.splice(insertIndex! + 1, 0, dragObj!);
        } else {
          // insertNodeId === rightSibling
          insertArr!.unshift(dragObj!);
        }
      });

      thunkApi.dispatch(updateCategoryList(newCategoryList));
      message.success("更新成功");
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    }
  },
);

const salesCategorySlice = createSlice({
  name: "salesCategory",
  initialState,
  reducers: {
    reset: () => initialState,
    resetFilter: (state) => {
      state.salePageListFilter = initialState.salePageListFilter;
    },
    updateSelectedCategory: (state, action: PayloadAction<{ id: number; parent: number; level: number }>) => {
      state.selectedCategory = action.payload;
    },
    addNewCategory: (state, action: PayloadAction<SalePageCategoryNode>) => {
      const { parent } = action.payload;
      let parentNode: SalePageCategoryNode | undefined;
      state.hasNewNode = true;
      state.categoryList.forEach((ctgy) => {
        if (ctgy.key === parent) parentNode = ctgy;

        if (ctgy.children) {
          ctgy.children.forEach((subCtgy) => {
            if (subCtgy.key === parent) parentNode = subCtgy;

            if (subCtgy.children) {
              subCtgy.children.forEach((subsubCtgy) => {
                if (subsubCtgy.key === parent) parentNode = subsubCtgy;
              });
            }
          });
        }
      });
      if (parentNode && parentNode.children) parentNode.children.push(action.payload);
      else if (parentNode) parentNode.children = [action.payload];
      else state.categoryList.push(action.payload); // 沒有parentNode表示要新增的點在第一層
    },
    clearCategoryDetail: (state) => {
      state.categoryDetail = initialState.categoryDetail;
    },
    toggleDisplayInactive: (state) => {
      state.displayInactive = !state.displayInactive;
    },
    updateSalePageListFilter: (state, action: PayloadAction<FetchSalePageListParam>) => {
      state.salePageListFilter = action.payload;
    },
    updateSelectedPageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPageIds = action.payload;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
    updateCSVUploadSuccess: (state, action: PayloadAction<boolean>) => {
      state.csvUploadSuccess = action.payload;
    },
    updateBrandListParam: (state, action: PayloadAction<BrandListParam>) => {
      state.brandListParam = action.payload;
    },
    updateCategoryList: (state, action: PayloadAction<SalePageCategoryNode[]>) => {
      state.categoryList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalesCategoryList.pending, (state) => {
      state.isFetchingCategoryList = true;
    });
    builder.addCase(fetchSalesCategoryList.fulfilled, (state, action) => {
      state.categoryList = action.payload;
      state.isFetchingCategoryList = false;
    });
    builder.addCase(fetchSalesCategoryDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSalesCategoryDetail.fulfilled, (state, action) => {
      state.categoryDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createSalesCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createSalesCategory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryDetail = action.payload;
      state.hasNewNode = false;
    });
    builder.addCase(updateSalesCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateSalesCategory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryDetail = action.payload;
    });
    builder.addCase(fetchSalePageList.pending, (state) => {
      state.isFetchingSalePageList = true;
    });
    builder.addCase(fetchSalePageList.fulfilled, (state, action) => {
      state.isFetchingSalePageList = false;
      state.hasNewNode = false;
      state.salePageListResult = action.payload;
    });
    builder.addCase(fetchSalePageListByLevelCollection.pending, (state) => {
      state.isFetchingSalePageList = true;
    });
    builder.addCase(fetchSalePageListByLevelCollection.fulfilled, (state, action) => {
      state.isFetchingSalePageList = false;
      state.hasNewNode = false;
      state.salePageListResult = action.payload;
    });
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(batchAddSalePagesByCSV.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(batchAddSalePagesByCSV.fulfilled, (state) => {
      state.isFetching = false;
      state.csvErrorMessage = "";
      state.csvUploadSuccess = true;
    });
    builder.addCase(batchAddSalePagesByCSV.rejected, (state, action) => {
      state.isFetching = false;
      state.csvErrorMessage = action.error.message || "";
    });
  },
});

export const {
  reset,
  resetFilter,
  updateSelectedCategory,
  addNewCategory,
  clearCategoryDetail,
  toggleDisplayInactive,
  updateSalePageListFilter,
  updateSelectedPageIds,
  clearCSVErrorMessage,
  updateBrandListParam,
  updateCSVUploadSuccess,
  updateCategoryList,
} = salesCategorySlice.actions;
export default salesCategorySlice.reducer;
