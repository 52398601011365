/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { fetchReplenishReturnApproval, orderState } from "@redux/orderSlice";
import { Button, Modal, Row, Table } from "antd";
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { OptionInfo } from "@api/utils/normalizeOrder";
import { ApprovalStatus } from "../ApprovedState";
import ProcessingType from "../ProcessingType";
import VendorLogisticsValue from "../VendorLogisticsValue";
import BigImageModal from "./BigImageModal";

const Wrapper = styled.div``;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  position: relative;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const SalePageLink = styled.div`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme }) => theme.colorSuccess500};
`;
const Title = styled.div`
  font-weight: 500;
`;

export default function ReplenishReturnProduct() {
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const { replenishReturnInfo } = useSelector(orderState);

  const [bigImage, setBigImage] = useState<string>("");

  const fetchApply = (title: string, status: ApprovalStatus) => {
    Modal.confirm({
      title: `你確定要${title}嗎？`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(
          fetchReplenishReturnApproval({
            orderId: parseInt(orderId, 10),
            approvalStatus: status,
          }),
        );
      },
    });
  };
  const buttonDisable =
    (replenishReturnInfo && replenishReturnInfo?.approvalStatus >= ApprovalStatus.GOOD_RETURN_APPROVED) ||
    replenishReturnInfo?.approvalStatus === ApprovalStatus.REFUND_REJECT;
  const buttonsRender = () => {
    if (
      replenishReturnInfo?.processingType === ProcessingType.RETURN &&
      replenishReturnInfo.logisticId === VendorLogisticsValue.warehouse
    ) {
      return (
        <div>
          <CustomButton
            onClick={() => fetchApply("審核通過取回", ApprovalStatus.GOOD_RETURN_APPROVED)}
            disabled={buttonDisable}
          >
            審核通過取回
          </CustomButton>
          <CustomButton
            onClick={() => fetchApply("審核通過不取回", ApprovalStatus.NO_RETURN_APPROVED)}
            disabled={buttonDisable}
          >
            審核通過不取回
          </CustomButton>
          <CustomButton onClick={() => fetchApply("審核不通過", ApprovalStatus.RETURN_REJECT)} disabled={buttonDisable}>
            審核不通過
          </CustomButton>
          <CustomButton
            disabled={!replenishReturnInfo.isReturnToWarehouse}
            onClick={() => fetchApply("放行", ApprovalStatus.REFUND_APPROVED)}
          >
            放行
          </CustomButton>
          <CustomButton
            disabled={!replenishReturnInfo.isReturnToWarehouse}
            onClick={() => fetchApply("拒絕放行", ApprovalStatus.REFUND_REJECT)}
          >
            拒絕放行
          </CustomButton>
        </div>
      );
    }

    if (
      replenishReturnInfo?.processingType === ProcessingType.REPLENISHMENT &&
      replenishReturnInfo.logisticId !== VendorLogisticsValue.preorder
    ) {
      return (
        <div>
          <CustomButton onClick={() => fetchApply("審核通過", ApprovalStatus.REPLENISHMENT_APPROVED)}>
            審核通過不取回
          </CustomButton>
          <CustomButton onClick={() => fetchApply("審核不通過", ApprovalStatus.REPLENISHMENT_REJECT)}>
            審核不通過
          </CustomButton>
        </div>
      );
    }

    if (
      replenishReturnInfo?.processingType === ProcessingType.RETURN &&
      replenishReturnInfo.logisticId === VendorLogisticsValue.transfer
    ) {
      return (
        <div>
          <CustomButton onClick={() => fetchApply("拒絕放行", ApprovalStatus.REFUND_REJECT)}>拒絕放行</CustomButton>
        </div>
      );
    }

    return null;
  };

  const computeTotalRefund = useMemo(() => {
    return replenishReturnInfo?.orderSalesPlans.reduce(
      (accumulate, currentValue) => accumulate + currentValue.refund,
      0,
    );
  }, [replenishReturnInfo?.orderSalesPlans]);

  const columns = [
    {
      key: "pageName",
      dataIndex: "pageName",
      title: "銷售頁名稱",
      width: 345,
      render: (value: string) => <SalePageLink>{value}</SalePageLink>,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "方案名稱",
    },
    {
      key: "optionText",
      dataIndex: "optionText",
      title: "補退貨數量(件)及選項",
    },
    {
      key: "reason",
      dataIndex: "reason",
      title: "原因",
    },
    {
      key: "reason",
      dataIndex: "reason",
      title: "備註",
      render: (value: any, data: any) => (
        <div>
          {data.optionsInfo.map((item: any) => {
            return (
              <>
                <Title>{item.optionName}</Title>
                <div>實際回收數量:{item.paidNu ? item.paidNu : "資料處理中"}</div>
                <div>瑕疵數量:{item.ocQty ? item.ocQty : "資料處理中"}</div>
              </>
            );
          })}
        </div>
      ),
    },
    {
      key: "reasonMedia",
      dataIndex: "reasonMedia",
      title: "照片",
      render: (value: string) =>
        value ? (
          <div role="presentation" onClick={() => setBigImage(value)}>
            <img src={value} alt="replenish return" />
          </div>
        ) : (
          <div>N/A</div>
        ),
    },
    {
      key: "specialPrice",
      dataIndex: "specialPrice",
      title: "方案優惠價",
    },
    {
      key: "discount",
      dataIndex: "discount",
      title: "折扣金額",
    },
    {
      key: "singlePlanRefund",
      dataIndex: "singlePlanRefund",
      title: "單件退款金額",
    },
    {
      key: "refund",
      dataIndex: "refund",
      title: "項目退款總額",
      render: (value: number) => (
        <div>{replenishReturnInfo?.processingType === ProcessingType.RETURN ? value : "N/A"}</div>
      ),
    },
  ];

  return (
    <Wrapper>
      <Row align="middle">
        <Row justify="space-between">{buttonsRender()}</Row>
        {replenishReturnInfo?.processingType === ProcessingType.RETURN && (
          <span>{`總退款金額: $${computeTotalRefund}`}</span>
        )}
      </Row>
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={replenishReturnInfo?.orderSalesPlans}
          pagination={false}
        />
      </TableWrapper>
      {bigImage && <BigImageModal src={bigImage} close={() => setBigImage("")} />}
    </Wrapper>
  );
}
