import { Select, Space } from "antd";
import React, { FC } from "react";

const { Option } = Select;

interface Props {
  value: number;
  totalCount: number;
  handlePageSizeChange: (pageSize: number) => void;
}

const PageSizeController: FC<Props> = (props) => {
  const { value, totalCount, handlePageSizeChange } = props;
  return (
    <Space>
      <span>{`總共${totalCount}筆，每頁顯示`}</span>
      <Select defaultValue={value} style={{ width: "72px", fontSize: "13px" }} onChange={handlePageSizeChange}>
        <Option value={20}>20</Option>
        <Option value={50}>50</Option>
        <Option value={100}>100</Option>
      </Select>
      <span>筆</span>
    </Space>
  );
};

export default PageSizeController;
