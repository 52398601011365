import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { TagAttributeList } from "@api/attributeApi";
import PageTitle from "@component/PageTitle";
import {
  attributeState,
  fetchDeleteTagAttribute,
  fetchTagAttributeList,
  resetAttributeSlice,
  setTagAttributeFilter,
} from "@redux/attributeSlice";
import { Button, Modal, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import Filter from "./Filter";

const { Option } = Select;
const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 28px 22px 22px;
  margin-top: 20px;
`;
const Tag = styled.div`
  border-radius: 2px;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  padding: 1px 8px;
  font-size: 12px;
  display: inline-block;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function AttributeManagement() {
  const dispatch = useDispatch();
  const { tagAttributeFilter, tagAttributeListResult, isFetching } = useSelector(attributeState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setTagAttributeFilter({ limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setTagAttributeFilter({ offset: (value - 1) * tagAttributeFilter.limit }));
  };

  const onDeleteAttribute = (attributeId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteTagAttribute(attributeId));
      },
    });
  };

  const columns: ColumnsType<TagAttributeList> = [
    {
      key: "name",
      dataIndex: "name",
      title: "屬性類型",
    },
    {
      key: "tagsName",
      dataIndex: "tagsName",
      title: "屬性",
      render: (_, values) => values.tagsName.map((tag) => <Tag>{tag}</Tag>),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="flex-end">
          <Button type="link" onClick={() => window.open(`/attribute-management/edit/${values.id}`)}>
            編輯
          </Button>
          <Button type="link" onClick={() => onDeleteAttribute(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchTagAttributeList(tagAttributeFilter));
  }, [dispatch, tagAttributeFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetAttributeSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="屬性管理" />
      <Row justify="space-between">
        <Row>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => window.open("/attribute-management/edit/new")}
          >
            新增屬性類型
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </Row>
        <Row>
          <PageText>{`總共${tagAttributeListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(parseInt(value as string, 10))}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <Filter isVisible={showFilter} />
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={tagAttributeListResult.results}
          pagination={{
            pageSize: tagAttributeFilter.limit,
            current: page,
            showSizeChanger: false,
            total: tagAttributeListResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
