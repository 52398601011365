import { CustomerTypeValue } from "@api/ruleApi";
import { Form, InputNumber } from "antd";
import React, { FC } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  height: 180px;
  border: 1px solid #d9d9d9;
  grid-row-start: 4;
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Body = styled.div`
  padding: 12px 20px 20px 20px;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  column-gap: 13px;
  row-gap: 11px;
  align-items: center;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Content = styled.div`
  width: 185px;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`;

const LimitSetting: FC = () => (
  <Wrapper>
    <Header>次數</Header>
    <Body>
      <Title>可領取人數</Title>
      <Content>
        <Form.Item name={["bindMethod", "maxBindTimes"]} noStyle>
          <StyledInputNumber placeholder="無輸入等於無限次" min={0} />
        </Form.Item>
      </Content>
      <Title>每人可使用次數</Title>
      <Content>
        <Form.Item noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const bindMethod = getFieldValue("bindMethod");
            const isNewCustomer = bindMethod?.customerType === CustomerTypeValue.NEW_CUSTOMER;
            return (
              <Form.Item name={["useMethod", "maxUseTimes"]} noStyle>
                <StyledInputNumber placeholder="無輸入等於無限次" min={0} disabled={isNewCustomer} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Content>
    </Body>
  </Wrapper>
);

export default LimitSetting;
