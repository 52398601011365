import BankList from "@component/BankList";
import BankBranchList from "@component/BankBranchList";
import BDList from "@component/BDList";
import CurrencySelect from "@component/CurrencySelect";
import { Button, Form, Input, notification, message } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Vendor } from "src/types";
import { hideLoading, showLoading } from "@redux/notifySlice";
import { useDispatch } from "react-redux";
import { fetchModifiedVendor, fetchNewVendor } from "@api/vendorApi";
import fields from "./fields";
import { FixedFooter } from "./style";

export default function VendorInfo(props: { vendorDetail: Vendor.Details; vendorId: string; isNewVendor: boolean }) {
  const { vendorDetail, vendorId, isNewVendor } = props;
  const navigate = useNavigate();
  const [bankCode, setBankCode] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const handleVendorInfoSubmit = (values: any) => {
    const params = {
      vendorId: parseInt(vendorId, 10),
      bdOwnerId: values[fields.bdOwnerId],
      vendorName: values[fields.vendorName],
      vendorPhone: values[fields.vendorPhone],
      vendorUbn: values[fields.vendorUbn],
      accountName: values[fields.accountName],
      accountNumber: values[fields.accountNumber],
      bankName: values[fields.bankName],
      branchName: values[fields.branchName],
      swiftCode: values[fields.swiftCode],
      currencyId: values[fields.currency],
      payCondition: values[fields.payCondition],
      reisterAddr: values[fields.registerAddr],
      buinessAddr: values[fields.businessAddr],
    };

    if (vendorId !== "-1") {
      dispatch(showLoading());
      fetchModifiedVendor(params)
        .then(() => {
          message.success("更新成功");
        })
        .catch((error) => {
          notification.error({
            message: `${error}`,
          });
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    } else {
      dispatch(showLoading());
      fetchNewVendor(params)
        .then((resp) => {
          navigate(`/vendor/info/${resp.vendorId}`);
          message.success("建立成功");
        })
        .catch((error) => {
          notification.error({
            message: `${error}`,
          });
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    }
  };

  function rendorFormItems() {
    const columns = [
      {
        title: "商開負責人",
        isRequired: true,
        type: "BDSelect",
        fieldName: fields.bdOwnerId,
      },
      {
        title: "廠商名稱",
        isRequired: true,
        type: "Input",
        fieldName: fields.vendorName,
      },
      {
        title: "廠商電話",
        type: "Input",
        fieldName: fields.vendorPhone,
      },
      {
        title: "廠商統編",
        isRequired: true,
        placeholder: "請輸入 8 位數統編",
        type: "VendorUbn",
        fieldName: fields.vendorUbn,
      },
      {
        title: "帳戶名稱",
        isRequired: true,
        fieldName: fields.accountName,
        type: "AccountName",
      },
      {
        title: "帳戶號碼",
        isRequired: true,
        fieldName: fields.accountNumber,
        placeholder: "請輸入 10 至 16 位數帳戶號碼",
        type: "AccountNumber",
      },
      {
        title: "銀行名稱",
        isRequired: true,
        type: "BankSelect",
        fieldName: fields.bankName,
      },
      {
        title: "分行名稱",
        isRequired: true,
        type: "BranchSelect",
        fieldName: fields.branchName,
      },
      {
        title: "Swift Code",
        isRequired: false,
        type: "Input",
        fieldName: fields.swiftCode,
      },
      {
        title: "付款幣別",
        isRequired: true,
        type: "CurrencySelect",
        fieldName: fields.currency,
      },
      {
        title: "付款條件",
        isRequired: false,
        type: "Input",
        fieldName: fields.payCondition,
      },
      {
        title: "註冊地址",
        isRequired: false,
        type: "Input",
        fieldName: fields.registerAddr,
      },
      {
        title: "營業地址",
        isRequired: false,
        type: "Input",
        fieldName: fields.businessAddr,
      },
    ];

    const checkBank = (data: any, value: number) => {
      if (value === -1 || !value) {
        return Promise.reject(new Error("請選擇銀行"));
      }
      return Promise.resolve();
    };
    const checkBankBranch = (data: any, value: number) => {
      if (value === -1 || !value) {
        return Promise.reject(new Error("請選擇分行"));
      }
      return Promise.resolve();
    };

    const checkBDStaff = (_: any, value: number) => {
      if (value === -1) {
        return Promise.reject(new Error("請選擇商開負責人"));
      }
      return Promise.resolve();
    };

    const checkAccountNumber = (_: any, value: string) => {
      if (!/^\d*$/.test(value) || value.length < 10 || value.length > 16) {
        return Promise.reject(new Error("請填入正確的帳戶號碼，須為 10 至 16 位數"));
      }
      return Promise.resolve();
    };
    const checkAccountName = (_: any, value: string) => {
      if (!/^[\u4e00-\u9fa5]*$/.test(value) || !value) {
        return Promise.reject(new Error("請填入正確的帳戶名稱，須為中文"));
      }
      return Promise.resolve();
    };
    const checkVendorUbn = (_: any, value: string) => {
      if (!/^\d*$/.test(value) || value.length > 8 || !value) {
        return Promise.reject(new Error("請填入正確的廠商統編，須為 8 位數字"));
      }
      return Promise.resolve();
    };
    const checkCurrency = (_: any, value: number) => {
      if (value === -1) {
        return Promise.reject(new Error("請選擇幣別"));
      }
      return Promise.resolve();
    };

    return columns.map((col) => {
      if (col.type === "BankSelect") {
        return (
          <Form.Item
            shouldUpdate
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkBank }]}
          >
            <BankList onChange={setBankCode} form={form} />
          </Form.Item>
        );
      }
      if (col.type === "BranchSelect") {
        return (
          <Form.Item
            shouldUpdate
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkBankBranch }]}
          >
            <BankBranchList bankCode={bankCode} form={form} />
          </Form.Item>
        );
      }
      if (col.type === "BDSelect") {
        return (
          <Form.Item
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkBDStaff }]}
          >
            <BDList />
          </Form.Item>
        );
      }
      if (col.type === "CurrencySelect") {
        return (
          <Form.Item
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkCurrency }]}
          >
            <CurrencySelect />
          </Form.Item>
        );
      }
      if (col.type === "VendorUbn") {
        return (
          <Form.Item
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkVendorUbn }]}
          >
            <Input placeholder={col.placeholder} />
          </Form.Item>
        );
      }
      if (col.type === "AccountName") {
        return (
          <Form.Item
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkAccountName }]}
          >
            <Input placeholder={col.placeholder} />
          </Form.Item>
        );
      }
      if (col.type === "AccountNumber") {
        return (
          <Form.Item
            label={col.title}
            name={col.fieldName}
            key={col.fieldName}
            required={col.isRequired}
            rules={[{ validator: checkAccountNumber }]}
          >
            <Input placeholder={col.placeholder} />
          </Form.Item>
        );
      }

      return (
        <Form.Item
          label={col.title}
          name={col.fieldName}
          key={col.fieldName}
          required={col.isRequired}
          rules={[
            {
              validator: col.isRequired
                ? (_, value) => (value.trim() ? Promise.resolve() : Promise.reject())
                : undefined,
              message: `請填入${col.title}`,
            },
          ]}
        >
          <Input placeholder={col.placeholder} />
        </Form.Item>
      );
    });
  }

  return (
    <div style={{ padding: "22px 0px" }}>
      <Form
        form={form}
        name="vendorInfo"
        labelAlign="right"
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 15 }}
        layout="horizontal"
        onFinish={handleVendorInfoSubmit}
        onFinishFailed={showRequiredFieldsWarning}
        initialValues={{
          [fields.bdOwnerId]: vendorDetail.bdOwnerId,
          [fields.vendorName]: vendorDetail.vendorName,
          [fields.vendorPhone]: vendorDetail.vendorPhone,
          [fields.vendorUbn]: vendorDetail.vendorUbn,
          [fields.accountName]: vendorDetail.accountName,
          [fields.accountNumber]: vendorDetail.accountNumber,
          [fields.bankName]: vendorDetail.bankName,
          [fields.branchName]: vendorDetail.branchName,
          [fields.swiftCode]: vendorDetail.swiftCode,
          [fields.currency]: vendorDetail.currencyId,
          [fields.payCondition]: vendorDetail.payCondition,
          [fields.registerAddr]: vendorDetail.reisterAddr,
          [fields.businessAddr]: vendorDetail.buinessAddr,
        }}
      >
        {rendorFormItems()}
        <FixedFooter>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {isNewVendor ? "建立資料" : "儲存此分頁"}
            </Button>
          </Form.Item>
        </FixedFooter>
      </Form>
    </div>
  );
}
