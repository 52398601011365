// 增加幣值從currencyTypeAndValue和 CurrencyValue
export const currencyTypeAndValue = [
  { value: 1, label: "TWD 台幣" },
  { value: 2, label: "USD 美金" },
  { value: 3, label: "RMB 人民幣" },
  { value: 4, label: "EUR 歐元" },
  { value: 5, label: "AUD 澳幣" },
  { value: 6, label: "YEN 日圓" },
  { value: 7, label: "WON 韓元" },
  { value: 8, label: "GBP 英鎊" },
];

export enum CurrencyValue {
  TWD = 1, // 台幣
  USD = 2, // 美金
  RMB = 3, // 人民幣
  EUR = 4, // 歐元
  AUD = 5, // 澳幣
  YEN = 6, // 日圓
  WON = 7, // 韓元
  GBP = 8, // 英鎊
}
export default CurrencyValue;

export const currencyValueLabel = (type: CurrencyValue) => {
  return currencyTypeAndValue.find((currency) => currency.value === type);
};

export const getCurrencySelectItems = (types?: CurrencyValue[]) => {
  if (!types) return currencyTypeAndValue;
  return types.map(
    (type) => currencyTypeAndValue.find((currency) => currency.value === type) || { value: 99, label: "" },
  );
};
