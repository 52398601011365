export enum ReturnOrderReason {
  LADING = 1,
  FREE_TASTE = 2,
  PRGOODS = 3,
  PROTOTYPE = 4,
  RESERVE_SAMPLE = 5,
  INSPECTION = 6,
  GIVEAWAY = 7,
  MARKETING = 8,
  ORDER = 9,
  RETURN = 10,
  WAREHOUSE_TRANSFER = 11,
  TRANSFER = 12,
}

export const returnOrderReasonLabel: {
  [index: number]: string;
} = {
  [ReturnOrderReason.LADING]: "提品",
  [ReturnOrderReason.FREE_TASTE]: "試吃品",
  [ReturnOrderReason.PRGOODS]: "公關品",
  [ReturnOrderReason.PROTOTYPE]: "仿樣",
  [ReturnOrderReason.RESERVE_SAMPLE]: "留樣",
  [ReturnOrderReason.INSPECTION]: "檢驗",
  [ReturnOrderReason.GIVEAWAY]: "抽獎贈品",
  [ReturnOrderReason.MARKETING]: "行銷推廣",
  [ReturnOrderReason.ORDER]: "出貨",
  [ReturnOrderReason.RETURN]: "退倉",
  [ReturnOrderReason.WAREHOUSE_TRANSFER]: "移倉",
  [ReturnOrderReason.TRANSFER]: "調出",
};

export enum ReturnSubOrderReason {
  HAS_SIMILAR_PRODUCTS = 1,
  SIZE_NOT_MATCH = 2,
  HIGH_PRICE = 3,
  REORDER_FOR_DISCOUNT = 4,
  PURCHASE_ERROR = 5,
  SHIPPING_ERROR = 6,
  POOR_APPEARANCE = 7,
  QUALITY = 8,
  OTHER = 9,
}

export const returnSubOrderReasonLabel: {
  [index: number]: string;
} = {
  [ReturnSubOrderReason.HAS_SIMILAR_PRODUCTS]: "已購買類似商品",
  [ReturnSubOrderReason.SIZE_NOT_MATCH]: "尺寸版型不合/顏色不喜歡",
  [ReturnSubOrderReason.HIGH_PRICE]: "比價後價格偏高",
  [ReturnSubOrderReason.REORDER_FOR_DISCOUNT]: "欲享有優惠折扣重新訂購",
  [ReturnSubOrderReason.PURCHASE_ERROR]: "購買操作錯誤",
  [ReturnSubOrderReason.SHIPPING_ERROR]: "出貨錯誤",
  [ReturnSubOrderReason.POOR_APPEARANCE]: "商品外觀狀態不良",
  [ReturnSubOrderReason.QUALITY]: "商品品質問題",
  [ReturnSubOrderReason.OTHER]: "其他",
};
