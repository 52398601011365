enum CarrierTypeValue {
  // 手機載具
  PHONECODE = 2,
  // 自然人憑證
  NATURALCODE = 1,
  // 優馬載具
  URMART = 0,
  // 三聯式電子發票
  B2B = 0,
  // 捐贈憑證
  DONATION = 0,
}

export default CarrierTypeValue;
