import useDebounce from "@hooks/useDebounce";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import {
  fetchBrandList,
  SaleState,
  setResetSaleListParams,
  setSaleListParams,
  loadMoreBrandList,
} from "@redux/saleSlice";
import { fetchFilterVendorList, loadMoreFilterVendorList } from "@redux/vendorSlice";
import { RootState } from "@redux/rootReducer";
import { SelectValue } from "antd/lib/select";
import { Button, Col, Form, Input, Row, Select } from "antd";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";

import React, { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function SaleListFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { brandList } = useSelector(SaleState);
  const { filterVendorListResult } = useSelector((state: RootState) => state.vendorSlice);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(setResetSaleListParams());
  };

  useEffect(() => {
    dispatch(fetchFilterVendorList(""));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchBrandList({ searchName: "" }));
  }, [dispatch]);

  const onSearch = useDebounce((val: string) => {
    dispatch(fetchBrandList({ searchName: val }));
  }, 1000);

  const onVendorSearch = useDebounce((val: string) => {
    dispatch(fetchFilterVendorList(val));
  }, 1000);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreFilterVendorList());
  }, [dispatch]);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandList;
    const options = results.map((brd: { id: number; name: string }) => (
      <Select.Option value={brd.id}>{brd.name}</Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandList, handleOnBrandLoadMore]);

  const vendorOptions = useMemo(() => {
    const { next, results } = filterVendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [filterVendorListResult, handleOnVendorLoadMore]);

  const onSubmit = (values: any) => {
    const params: any = {
      pageIds: [values.id],
      sku: values.sku,
      pageNameQ: values.pageNameQ,
      isActive: typeof values.isActive === "number" ? values.isActive === 1 : undefined,
      brandNames: values.brandNames === -100 ? undefined : [values.brandNames],
      pageType: values.pageType === -100 ? undefined : values.pageType,
      vendor: values.vendor && values.vendor < 0 ? undefined : values.vendor,
    };

    dispatch(setSaleListParams(params));
  };

  const handleOnSelect = (title: string) => (value: SelectValue, option: any) => {
    form.setFieldsValue({
      [title]: option.children,
    });
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 9 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="ID" name="id">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="銷售頁名稱" name="pageNameQ">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="狀態" name="isActive">
            <Select defaultValue={-100}>
              <Select.Option value={-100}>請選擇</Select.Option>
              <Select.Option value={0}>下架</Select.Option>
              <Select.Option value={1}>上架</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="品牌" name="brandNames">
            <Select showSearch filterOption={false} onSearch={onSearch} onChange={handleOnSelect("brandNames")}>
              {brandOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="廠商" name="vendor">
            <Select showSearch onSearch={onVendorSearch} filterOption={false}>
              {vendorOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="方案類型" name="pageType">
            <Select defaultValue={-100}>
              <Select.Option value={-100}>請選擇</Select.Option>
              <Select.Option value={SalePageTypeValue.Simple}>單一方案</Select.Option>
              <Select.Option value={SalePageTypeValue.Group}>多方案</Select.Option>
              <Select.Option value={SalePageTypeValue.Any}>任選方案</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
