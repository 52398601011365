import { setVipStatisticsListFilter } from "@redux/vipStatisticsSlice";
import { Button, DatePicker, Form, Row } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding-top: 24px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const CustomRow = styled(Row)`
  margin-left: 16px;
`;

type Props = {
  isVisible: boolean;
};

export default function VipStatisticsFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(setVipStatisticsListFilter({}));
  };

  const onSubmit = (values: any) => {
    const endMonthDays = values.datePeriod[1].daysInMonth();
    const payload = {
      startDate: `${values.datePeriod[0].format("YYYY-MM")}-01`,
      endDate: `${values.datePeriod[1].format("YYYY-MM")}-${endMonthDays}`,
    };
    dispatch(setVipStatisticsListFilter(payload));
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 8 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row>
        <Form.Item label="時間區間" name="datePeriod">
          <DatePicker.RangePicker picker="month" locale={locale} />
        </Form.Item>
        <CustomRow>
          <CustomButton type="primary" htmlType="submit">
            套用
          </CustomButton>
          <Button onClick={onReset}>清除篩選條件</Button>
        </CustomRow>
      </Row>
    </Wrapper>
  );
}
