import { ApiResponse } from "src/types";
import { PaymentMethodIdValue } from "@page/Order/PaymentMethodValue";
import CoreAPI from "../CoreAPI";
import { transformCamelToSnake } from "../utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "../utils/transformAPIKeyToCamel";

// OrderPaymentStatus:待請款／請款失敗 / 請款成功 / 處理完成
export enum OrderPaymentStatus {
  PENDING = 1,
  FAILED = 5,
}

export interface OrderPaymentResp extends ApiResponse.ListResponseType {
  results: OrderPaymentItem[];
  captureFailed: number;
  waitingForCapture: number;
}
export interface OrderPaymentListFilter {
  paymentStatus?: OrderPaymentStatus;
  offset: number;
  limit: number;
  createdDateAfter?: string;
  createdDateBefore?: string;
  captureDateAfter?: string;
  captureDateBefore?: string;
}
export interface OrderPaymentItem {
  paymentId: number;
  paymentStatus: number;
  type: number;
  orderNumber: string;
  createdAt: string;
  price: number;
  transactionId: string;
  paymentMethod: PaymentMethodIdValue;
  applyCaptureAt: string;
  capturedAt: string;
  errMsg: string;
}

const apiClient = new CoreAPI();

interface OrderPayment {
  fetchOrderPaymentList: (param: OrderPaymentListFilter) => Promise<OrderPaymentResp>;
  batchFinishOrderPayment: (ids: number[]) => Promise<any>;
  exportOrderPaymentList: (params: OrderPaymentListFilter) => Promise<any>;
}
const OrderPaymentApi: OrderPayment = {
  fetchOrderPaymentList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/order-payment/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchFinishOrderPayment: async (ids: number[]) => {
    const response = await apiClient.patchData("/manage/order-payment/manually-process/", {
      payment_ids: ids,
    });
    return response;
  },
  exportOrderPaymentList: async (params: OrderPaymentListFilter) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/order-payment/csv-download/", getParams, {
      responseType: "blob",
    });
    return response;
  },
};
export default OrderPaymentApi;
