/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import normalizeTopic, {
  CategoryInfo,
  CategoryTag,
  TopicCategories,
  TopicFlatInfo,
  TopicFullInfo,
  TopicShortInfo,
} from "./utils/normalizeTopic";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface SalesCategoryOrigin {
  id: number;
  name: string;
  parent: number;
  sales_page_count: number;
}

export interface TopicSimpleUpdateConfig {
  title: string;
  iconUrl: string;
  iconAlt: string;
  toShow: boolean;
  isActive: boolean;
}

export type UpdateMultiRankConfig = { topic: number; rank: number }[];

export interface CreateTopicConfig {
  title: string;
  iconUrl: string;
  iconAlt: string;
  imageUrl: string;
  imageAlt: string;
  isActive: boolean;
  toShow: boolean;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  rank: number;
}

export interface UpdateCategoriesOrderConfig {
  topicId: number;
  leftSibling?: number;
  rightSibling?: number;
  parent?: number;
}

export interface CreateCategoryConfig {
  name: string;
  parentId: number;
  iconUrl: string;
  iconAlt: string;
  imageUrl: string;
  imageAlt: string;
  isActive: boolean;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  defaultSortMethod: string;
  sortMethods: {
    name: string;
    isActive: boolean;
  }[];
}

export interface UpdateCategoryConfig {
  name: string;
  iconUrl: string;
  iconAlt: string;
  imageUrl: string;
  imageAlt: string;
  isActive: boolean;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  defaultSortMethod: string;
  sortMethods: {
    name: string;
    isActive: boolean;
  }[];
}

export interface CategoriesPutTagsConfig {
  topicCategoryId: number;
  categories: number[];
  tags: number[];
}

export interface FetchEditRecommendList {
  topicCategoryId: number;
  data: { salesPage: number; rank: number }[];
}

export interface FetchDeleteRecommendList {
  topicCategoryId: number;
  pageIds: number[];
}

export interface FetchTopicCategorySaleList {
  topicCategoryId: number;
  limit: number;
  offset: number;
  brandNames?: string[];
  sku?: string;
  id?: number;
  nameQ?: string;
}

export interface FetchTopicCategorySaleRecommendList {
  topicCategoryId: number;
  limit: number;
  offset: number;
  ordering?: "rank" | "-rank";
  pageId?: number;
  pageNameQ?: string;
  brandNames?: string;
  sku?: string;
}

export interface TopicCategorySaleInfo {
  id: number;
  skus: string[];
  name: string;
  brands: { tagId: number; name: string }[];
  priceRange: {
    max: number;
    min: number;
  };
}

export interface TopicCategoryRecommendSaleInfo {
  id: number;
  pageId: number;
  skus: string[];
  pageName: string;
  brands: { tagId: number; name: string }[];
  priceRange: {
    max: number;
    min: number;
  };
  rank: number;
}

const apiClient = new CoreAPI();

interface TopicAPI {
  fetchTopicList: () => Promise<TopicShortInfo[]>;
  fetchTopicSimpleUpdate: (id: number, requestBody: TopicSimpleUpdateConfig) => Promise<TopicShortInfo>;
  fetchTopicFullUpdate: (id: number, requestBody: TopicFlatInfo) => Promise<TopicShortInfo>;
  fetchUpdateMultiRank: (list: UpdateMultiRankConfig) => Promise<void>;
  fetchCreateTopic: (topicInfo: CreateTopicConfig) => Promise<TopicShortInfo>;
  fetchTopicInfo: (topicId: number) => Promise<TopicFullInfo>;
  fetchTopicCategories: (rootCategoryId: number, displayInActive: boolean) => Promise<TopicCategories[]>;
  fetchUpdateCategoriesOrder: (categoryId: number, requestBody: UpdateCategoriesOrderConfig) => Promise<void>;
  fetchCategoryInfo: (categoryId: number) => Promise<CategoryInfo>;
  fetchCreateCategory: (categoryInfo: CreateCategoryConfig) => Promise<CategoryInfo>;
  fetchUpdateCategory: (categoryId: number, categoryInfo: UpdateCategoryConfig) => Promise<CategoryInfo>;
  fetchSalesCategories: () => Promise<{
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  }>;
  fetchCategoriesTag: (categoryIds: number[], config?: { nameq?: string; limit?: number }) => Promise<CategoryTag[]>;
  fetchCategoriesPutTags: (payload: CategoriesPutTagsConfig) => Promise<CategoryInfo>;
  fetchCategoryRemoveTag: (topicCategoryId: number) => Promise<void>;
  fetchTopicCategorySaleRecommendList: (
    payload: FetchTopicCategorySaleRecommendList,
  ) => Promise<{
    count: number;
    next: string | null;
    previous: string | null;
    results: TopicCategoryRecommendSaleInfo[];
  }>;
  fetchTopicCategorySaleList: (
    payload: FetchTopicCategorySaleList,
  ) => Promise<{ count: number; results: TopicCategorySaleInfo[] }>;
  fetchAddRecommendList: (payload: FetchEditRecommendList) => Promise<void>;
  fetchUpdateRecommendList: (payload: FetchEditRecommendList) => Promise<void>;
  fetchDeleteRecommendList: (payload: FetchDeleteRecommendList) => Promise<void>;
}

const TopicApi: TopicAPI = {
  fetchTopicList: async () => {
    // 未來有需要 parameters 可以帶 detail=1
    const response = await apiClient.getData("/manage/topic/topics/", {});
    return normalizeTopic.topicList(response.data.result.results);
  },
  fetchTopicSimpleUpdate: async (topicId, requestBody) => {
    const payload = {
      title: requestBody.title,
      icon_url: requestBody.iconUrl,
      icon_alt: requestBody.iconAlt,
      to_show: requestBody.toShow,
      is_active: requestBody.isActive,
    };
    const response = await apiClient.patchData(`/manage/topic/topics/${topicId}/`, payload);
    return normalizeTopic.updateTopicInfo(response.data.result);
  },
  fetchTopicFullUpdate: async (topicId, requestBody) => {
    // 上傳圖片路徑 icon/image 存的是s3網址或是後端回傳的暫時路徑
    // s3網址 ？ 不做更新 ： 傳路徑
    const iconDirPrefix =
      requestBody.iconUrl && !requestBody.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "sale/category/icon"
        : undefined;
    const iconFilePath =
      requestBody.iconUrl && !requestBody.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? requestBody.iconUrl
        : undefined;
    const imageDirPrefix =
      requestBody.imageUrl && !requestBody.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "sale/category/image"
        : undefined;
    const imageFilePath =
      requestBody.imageUrl && !requestBody.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? requestBody.imageUrl
        : undefined;

    const payload = {
      title: requestBody.title,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: requestBody.iconAlt,
      to_show: requestBody.toShow,
      is_active: requestBody.isActive,
      rank: requestBody.rank,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: requestBody.imageAlt,
      meta_title: requestBody.metaTitle,
      meta_keywords: requestBody.metaKeywords,
      meta_description: requestBody.metaDescription,
      background_color: requestBody.backgroundColor,
    };
    const response = await apiClient.patchData(`/manage/topic/topics/${topicId}/`, payload);
    return normalizeTopic.updateTopicInfo(response.data.result);
  },
  fetchUpdateMultiRank: async (list) => {
    await apiClient.putData("/manage/topic/topics/batch-ranks/", {
      topic_rank_data: list,
    });
  },
  fetchCreateTopic: async (topicInfo) => {
    // 上傳圖片路徑
    const iconDirPrefix = topicInfo.iconUrl && "topic/index/icon";
    const iconFilePath = topicInfo.iconUrl;
    const imageDirPrefix = topicInfo.imageUrl && "topic/index/image";
    const imageFilePath = topicInfo.imageUrl;

    const payload = {
      title: topicInfo.title,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: topicInfo.iconAlt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: topicInfo.imageAlt,
      is_active: topicInfo.isActive || true,
      to_show: topicInfo.toShow || true,
      meta_title: topicInfo.metaTitle,
      meta_keywords: topicInfo.metaKeywords,
      meta_description: topicInfo.metaDescription,
      rank: topicInfo.rank || 1,
    };
    const response = await apiClient.postData("/manage/topic/topics/", payload);
    return response.data.result;
  },
  fetchTopicInfo: async (topicId) => {
    const response = await apiClient.getData(`/manage/topic/topics/${topicId}/`, {});
    return normalizeTopic.topicInfo(response.data.result);
  },
  fetchTopicCategories: async (rootCategoryId, displayInActive) => {
    const inActive = displayInActive ? 1 : 0;
    const response = await apiClient.getData(
      `/manage/topic/categories/?root_category_id=${rootCategoryId}&display_inactive=${inActive}`,
      {},
    );
    return normalizeTopic.topicCategories(response.data.result);
  },
  fetchUpdateCategoriesOrder: async (categoryId, requestBody) => {
    const payload = {
      topic_id: requestBody.topicId,
      left_sibling: requestBody.leftSibling,
      right_sibling: requestBody.rightSibling,
      parent: requestBody.parent,
    };
    await apiClient.putData(`/manage/topic/categories/${categoryId}/move/`, payload);
  },
  fetchCategoryInfo: async (categoryId) => {
    const response = await apiClient.getData(`/manage/topic/categories/${categoryId}/`, {});
    return normalizeTopic.categoryInfo(response.data.result);
  },
  fetchCreateCategory: async (categoryInfo) => {
    // 上傳圖片路徑
    const iconDirPrefix = categoryInfo.iconUrl && "topic/category/icon";
    const iconFilePath = categoryInfo.iconUrl;
    const imageDirPrefix = categoryInfo.imageUrl && "topic/category/image";
    const imageFilePath = categoryInfo.imageUrl;

    const payload = {
      name: categoryInfo.name,
      parent_id: categoryInfo.parentId,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: categoryInfo.iconAlt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: categoryInfo.imageAlt,
      is_active: categoryInfo.isActive,
      meta_title: categoryInfo.metaTitle,
      meta_keywords: categoryInfo.metaKeywords,
      meta_description: categoryInfo.metaDescription,
      default_sort_method: categoryInfo.defaultSortMethod,
      sort_methods: categoryInfo.sortMethods.map((method) => ({ name: method.name, is_active: method.isActive })),
    };
    const response = await apiClient.postData("/manage/topic/categories/", payload);
    return normalizeTopic.categoryInfo(response.data.result);
  },
  fetchUpdateCategory: async (categoryId, categoryInfo) => {
    // 上傳圖片路徑 icon/image 存的是s3網址或是後端回傳的暫時路徑
    // s3網址 ？ 不做更新 ： 傳路徑
    const iconDirPrefix =
      categoryInfo.iconUrl && !categoryInfo.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "topic/category/icon"
        : undefined;
    const iconFilePath =
      categoryInfo.iconUrl && !categoryInfo.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? categoryInfo.iconUrl
        : undefined;
    const imageDirPrefix =
      categoryInfo.imageUrl && !categoryInfo.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "topic/category/image"
        : undefined;
    const imageFilePath =
      categoryInfo.imageUrl && !categoryInfo.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? categoryInfo.imageUrl
        : undefined;

    const payload = {
      name: categoryInfo.name,
      icon_dir_prefix: iconDirPrefix,
      icon_file_path: iconFilePath,
      icon_alt: categoryInfo.iconAlt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: categoryInfo.imageAlt,
      is_active: categoryInfo.isActive,
      meta_title: categoryInfo.metaTitle,
      meta_keywords: categoryInfo.metaKeywords,
      meta_description: categoryInfo.metaDescription,
      default_sort_method: categoryInfo.defaultSortMethod,
      sort_methods: categoryInfo.sortMethods.map((method) => ({ name: method.name, is_active: method.isActive })),
    };
    const response = await apiClient.patchData(`/manage/topic/categories/${categoryId}/`, payload);
    return normalizeTopic.categoryInfo(response.data.result);
  },
  fetchSalesCategories: async () => {
    /* 這邊把原始資料丟給 redux 存，原因有二
      1 需要一個資料格式但 redux-toolkit 不能存 Map @see ManageTagContext
      2 想在一進編輯新增就打
      3 這隻拉很久是正常(實測 5 ~ 12)，麻煩的地方在新增編輯是開新分頁，一定要重打
    */
    const response = await apiClient.getData("/manage/sales/categories/", {
      display_inactive: 1,
    });
    const data = response.data.result;
    return {
      level1: data.level_1,
      level2: data.level_2,
      level3: data.level_3,
    };
  },
  fetchCategoriesTag: async (categoryIds, config) => {
    const response = await apiClient.getData(`/manage/tag/tags/?category_ids=${categoryIds.toString()}`, {
      name_q: config?.nameq,
      limit: config?.limit, // api default 100
      offset: 0,
    });
    return normalizeTopic.categoriesTag(response.data.result.results);
  },
  fetchCategoriesPutTags: async (payload) => {
    const response = await apiClient.patchData(`/manage/topic/categories/${payload.topicCategoryId}/`, {
      categories: payload.categories,
      tags: payload.tags,
    });
    return normalizeTopic.categoryInfo(response.data.result);
  },
  fetchCategoryRemoveTag: async (topicCategoryId) => {
    await apiClient.deleteData(`/manage/topic/categories/${topicCategoryId}/remove-contents/`, {});
  },
  fetchTopicCategorySaleRecommendList: async (payload) => {
    const response = await apiClient.getData("/manage/topic/topic_page_relation/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchTopicCategorySaleList: async (payload) => {
    const response = await apiClient.getData("/manage/sales/pages/topic_category/", {
      ...transformCamelToSnake(payload),
      brand_names: payload.brandNames?.toString(),
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddRecommendList: async (payload) => {
    await apiClient.postData("/manage/topic/topic_page_relation/batch-create/", transformCamelToSnake(payload));
  },
  fetchUpdateRecommendList: async (payload) => {
    await apiClient.putData("/manage/topic/topic_page_relation/batch-update/", transformCamelToSnake(payload));
  },
  fetchDeleteRecommendList: async (payload) => {
    await apiClient.deleteData("/manage/topic/topic_page_relation/batch-delete/", transformCamelToSnake(payload));
  },
};

export default TopicApi;
