// 已作廢狀態由destroyedAt有回傳日期判斷
export enum ProcessingStatus {
  PENDING = 1,
  DEPLOYED = 2,
  READY = 3,
  PROCESSING = 4,
  NOSTOCK = 5,
  DONE = 6,
  CANCELED = 7,
  TERMINATED = 8,
  MERGED = 9,
}

const processingStatusOptions = [
  { key: ProcessingStatus.PENDING, name: "待拋單", value: ProcessingStatus.PENDING },
  { key: ProcessingStatus.DEPLOYED, name: "已拋單", value: ProcessingStatus.DEPLOYED },
  // Lam Tin Storage Status
  { key: ProcessingStatus.READY, name: "待處理", value: ProcessingStatus.READY },
  { key: ProcessingStatus.PROCESSING, name: "作業中", value: ProcessingStatus.PROCESSING },
  { key: ProcessingStatus.NOSTOCK, name: "庫存不足", value: ProcessingStatus.NOSTOCK },
  { key: ProcessingStatus.DONE, name: "已完成", value: ProcessingStatus.DONE },
  { key: ProcessingStatus.CANCELED, name: "已取消", value: ProcessingStatus.CANCELED },
  { key: ProcessingStatus.TERMINATED, name: "強制終止", value: ProcessingStatus.TERMINATED },
  { key: ProcessingStatus.MERGED, name: "已併單", value: ProcessingStatus.MERGED },
];
export default processingStatusOptions;
