/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { VipMemberStatusChartItem, VipMemberStatusChart } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import { getPointStart, getPointInterval, getTimeFormat, getTooltipTitle, getFilledTimeData } from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

const MemberVIPStatusChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<VipMemberStatusChartItem>(
      startDate,
      endDate,
      (chartResult as VipMemberStatusChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<VipMemberStatusChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as VipMemberStatusChart).compareData || [],
      timeUnit,
    );

    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    const currentFirstBuyCount: (number | null)[] = [];
    const currentCancelCount: (number | null)[] = [];
    const currentRenewCount: (number | null)[] = [];
    const compareFirstBuyCount: (number | null)[] = [];
    const compareCancelCount: (number | null)[] = [];
    const compareRenewCount: (number | null)[] = [];

    currentFilledTimeData.forEach((data) => {
      const { firstBuyCount = null, cancelCount = null, renewCount = null } = data;
      currentFirstBuyCount.push(firstBuyCount);
      currentCancelCount.push(cancelCount);
      currentRenewCount.push(renewCount);
    });
    compareFilledTimeData.forEach((data) => {
      const { firstBuyCount = null, cancelCount = null, renewCount = null } = data;
      compareFirstBuyCount.push(firstBuyCount);
      compareCancelCount.push(cancelCount);
      compareRenewCount.push(renewCount);
    });

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: "新增黑卡會員人數",
        data: currentFirstBuyCount,
        type: "line",
        color: "#1890FF",
        stack: "current",
        pointStart,
      },
      {
        name: "取消黑卡會員人數",
        data: currentCancelCount,
        type: "line",
        color: "#5C6AC4",
        stack: "current",
        pointStart,
      },
      {
        name: "舊客續約人數",
        data: currentRenewCount,
        type: "line",
        color: "#2E90B7",
        stack: "current",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.push({
        name: "新增黑卡會員人數 - 相較於",
        data: compareFirstBuyCount,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
      series.push({
        name: "取消黑卡會員人數 - 相較於",
        data: compareFirstBuyCount,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
      series.push({
        name: "舊客續約人數 - 相較於",
        data: compareFirstBuyCount,
        type: "line",
        stack: "compare",
        pointStart,
        visible: false,
      });
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointTitle = this.point.series.name;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const value = this.point.y;

          return `${title}<br/>${pointTitle} ${value}<br/>`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });
  }, [chartResult, chartParams]);

  const fetchVipMemberStatusChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.VIP_MEMBER_STATUS));
  }, [dispatch]);

  return (
    <Wrapper>
      <ChartWrapper>
        <Spin spinning={isFetchingChart}>
          <PageTitle title="Dashboard - 會員狀態" />
          <ChartFilter title="會員狀態" fetchAction={fetchVipMemberStatusChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
        </Spin>
      </ChartWrapper>
    </Wrapper>
  );
};

export default MemberVIPStatusChart;
