import marketStatisticApi, { FetchMarketStatisticParams, MarketStatisticListResult } from "@api/marketStatisticApi";
import productApi, { ProductCategory } from "@api/productApi";
import salesReportApi, {
  Dashboard,
  FetchVPCSalesListParams,
  FetchVPCSalesPlanListParams,
  FetchVPCSubOrderListParams,
  VPCSalesListResult,
  VPCSalesPlanListResult,
  VPCSubOrderListResult,
} from "@api/salesReportApi";
import tagApi, { FetchTagListParam, TagListResult } from "@api/tagApi";
import { SalesChannelType } from "@api/vendorApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { URMART_VIP_SKU } from "src/configs";

export interface LocalSaleListParams extends FetchVPCSalesListParams {
  categoryLevel1: number;
  categoryLevel2: number;
  categoryLevel3: number;
}
interface IState {
  isFetchingDashboard: boolean;
  dashboard: Dashboard;

  isFetchingVPCSalesList: boolean;
  VPCSalesListParams: LocalSaleListParams;
  VPCSalesListResult: VPCSalesListResult;

  isFetchingVPCSubOrderList: boolean;
  VPCSubOrderListParams: FetchVPCSubOrderListParams;
  VPCSubOrderListResult: VPCSubOrderListResult;

  isFetchingVPCSalesPlanList: boolean;
  VPCSalesPlanListParams: FetchVPCSalesPlanListParams;
  VPCSalesPlanListResult: VPCSalesPlanListResult;

  brandListResult: TagListResult;
  brandListParam: FetchTagListParam;
  productCategory: ProductCategory;

  isFetchingMarketStatistic: boolean;
  marketStatisticListParams: FetchMarketStatisticParams;
  marketStatisticListResult: MarketStatisticListResult;
}

const initialState: IState = {
  isFetchingDashboard: false,
  dashboard: {
    grossMargin: 0,
    revenueSum: 0,
    costSum: 0,
    grossProfitSum: 0,
    transferConsignmentDiscountSum: 0,
    commissionFeeSum: 0,
    postFeeSum: 0,
    rentalFeeSum: 0,
    salesVolumesSum: 0,
    orderCount: 0,
  },

  isFetchingVPCSalesList: false,
  VPCSalesListParams: {
    limit: 20,
    offset: 0,
    categoryLevel1: -1,
    categoryLevel2: -1,
    categoryLevel3: -1,
    includeAddBuy: true,
    includeGift: true,
    excludeSku: URMART_VIP_SKU,
    contractType: "1",
    salesChannel: SalesChannelType.URMART,
  },
  VPCSalesListResult: {
    grossMargin: 0,
    revenueSum: 0,
    costSum: 0,
    grossProfitSum: 0,
    transferConsignmentDiscountSum: 0,
    commissionFeeSum: 0,
    postFeeSum: 0,
    rentalFeeSum: 0,
    salesVolumesSum: 0,
    orderCount: 0,
    count: 0,
    results: [],
  },

  isFetchingVPCSubOrderList: false,
  VPCSubOrderListParams: {
    limit: 20,
    offset: 0,
  },
  VPCSubOrderListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },

  isFetchingVPCSalesPlanList: false,
  VPCSalesPlanListParams: {
    limit: 20,
    offset: 0,
  },
  VPCSalesPlanListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },

  brandListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  productCategory: {
    level1: {},
    level2: {},
    level3: {},
  },

  isFetchingMarketStatistic: false,
  marketStatisticListParams: { limit: 20, offset: 0 },
  marketStatisticListResult: {
    count: 0,
    results: [],
  },
};

export const fetchDashBoard = createAsyncThunk<Dashboard>("salesReport/fetchDashBoard", async (_, thunkApi) => {
  const {
    salesReport: { VPCSalesListParams },
  } = thunkApi.getState() as RootState;

  const { limit, offset, categoryLevel1, categoryLevel2, categoryLevel3, contractType, ...params } = VPCSalesListParams;
  // 找到最下層的category id
  const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);
  const fetchParams = {
    ...params,
    category,
    contractType: contractType?.replace("一般", "1").replace("臨時", "2"),
  };

  const response = await salesReportApi.fetchDashboard(fetchParams);
  return response;
});

export const fetchVPCSalesList = createAsyncThunk<VPCSalesListResult>(
  "salesReport/fetchVPCSalesList",
  async (_, thunkApi) => {
    const {
      salesReport: { VPCSalesListParams },
    } = thunkApi.getState() as RootState;

    const { categoryLevel1, categoryLevel2, categoryLevel3, contractType, ...params } = VPCSalesListParams;
    // 找到最下層的category id
    const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);
    const fetchParams = {
      ...params,
      category,
      contractType: contractType?.replace("一般", "1").replace("臨時", "2"),
    };

    const response = await salesReportApi.fetchVPCSalesList(fetchParams);
    return response;
  },
);

export const clearVPCSaleListFilter = createAsyncThunk<void>(
  "salesReport/clearVPCSaleListFilter",
  async (_, thunkApi) => {
    thunkApi.dispatch(updateVPCSalesListParams(initialState.VPCSalesListParams));
    thunkApi.dispatch(fetchVPCSalesList());
  },
);

export const exportVPCSalesList = createAsyncThunk<void, string>(
  "salesReport/exportVPCSalesList",
  async (groupByMode, thunkApi) => {
    const {
      salesReport: { VPCSalesListParams },
    } = thunkApi.getState() as RootState;

    const { categoryLevel1, categoryLevel2, categoryLevel3, contractType, ...params } = VPCSalesListParams;
    // 找到最下層的category id
    const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);
    const fetchParams = {
      ...params,
      category,
      contractType,
      groupByMode: groupByMode || undefined,
    };

    try {
      await salesReportApi.exportVPCSalesList(fetchParams);
      message.success("匯出資料準備中，由於資料複雜請稍待十分鐘，成功後會寄信通知 ");
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    }
  },
);

export const exportCSVPricePerOrder = createAsyncThunk<void>("salesReport/exportVPCSalesList", async (_, thunkApi) => {
  const {
    salesReport: { VPCSalesListParams },
  } = thunkApi.getState() as RootState;

  const {
    orderCreatedDateAfter,
    orderCreatedDateBefore,
    vpcStartDateAfter,
    vpcStartDateBefore,
    includeGift,
    includeAddBuy,
    productNameQ,
    owner,
    skus,
    excludeSku,
    brand,
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
  } = VPCSalesListParams;

  const fetchParams = {
    orderCreatedDateAfter,
    orderCreatedDateBefore,
    vpcStartDateAfter,
    vpcStartDateBefore,
    includeAddBuy,
    includeGift,
    productNameQ,
    owner,
    skus,
    excludeSku,
    brand,
  };

  const category = [categoryLevel3, categoryLevel2, categoryLevel1].find((ctgy) => ctgy && ctgy > 0);

  try {
    await salesReportApi.exportCSVPricePerOrder({ ...fetchParams, category });
    message.success("匯出資料準備中，由於資料複雜請稍待十分鐘，成功後會寄信通知 ");
  } catch (error: any) {
    thunkApi.rejectWithValue(error.message);
  }
});

export const fetchBrandList = createAsyncThunk("salesReport/fetchBrandList", async (name: string, thunkApi) => {
  const params: FetchTagListParam = {
    typeNameQ: "品牌",
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("salesReport/loadMoreBrandList", async (_, thunkApi) => {
  const {
    product: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchTagListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const fetchProductCategoryList = createAsyncThunk("salesReport/fetchProductCategoryList", async () => {
  const response = await productApi.fetchProductCategoryList();
  return response;
});

export const fetchVPCSubOrderList = createAsyncThunk<VPCSubOrderListResult, number>(
  "salesReport/fetchVPCSubOrderList",
  async (vpcId, thunkApi) => {
    const {
      salesReport: {
        VPCSubOrderListParams,
        VPCSalesListParams: { orderCreatedDateAfter, orderCreatedDateBefore, includeAddBuy, includeGift },
      },
    } = thunkApi.getState() as RootState;

    const fetchVPCSubOrderListParams = {
      ...VPCSubOrderListParams,
      orderCreatedDateAfter,
      orderCreatedDateBefore,
      includeAddBuy,
      includeGift,
    };
    const response = await salesReportApi.fetchVPCSubOrderList(vpcId, fetchVPCSubOrderListParams);
    return response;
  },
);

export const fetchVPCSalesPlanList = createAsyncThunk<VPCSalesPlanListResult, number>(
  "salesReport/fetchVPCSalesPlanList",
  async (vpcId, thunkApi) => {
    const {
      salesReport: {
        VPCSalesPlanListParams,
        VPCSalesListParams: { orderCreatedDateAfter, orderCreatedDateBefore, includeAddBuy, includeGift },
      },
    } = thunkApi.getState() as RootState;

    const fetchVPCSalePlanListParams = {
      ...VPCSalesPlanListParams,
      orderCreatedDateAfter,
      orderCreatedDateBefore,
      includeAddBuy,
      includeGift,
    };
    const response = await salesReportApi.fetchVPCSalePlanList(vpcId, fetchVPCSalePlanListParams);
    return response;
  },
);

export const fetchMarketStatistic = createAsyncThunk<MarketStatisticListResult>(
  "salesReport/FetchMarketStatistic",
  async (_, thunkApi) => {
    const {
      salesReport: { marketStatisticListParams },
    } = thunkApi.getState() as RootState;

    const response = await marketStatisticApi.fetchMarketStatisticList(marketStatisticListParams);
    return response;
  },
);

export const exportMarketStatistic = createAsyncThunk<void>("salesReport/export", async (_, thunkApi) => {
  const {
    salesReport: { marketStatisticListParams },
  } = thunkApi.getState() as RootState;

  const response = await marketStatisticApi.exportMarketStatisticList(marketStatisticListParams);
  return response;
});

const salesReportSlice = createSlice({
  name: "salesReport",
  initialState,
  reducers: {
    reset: () => initialState,
    updateVPCSalesListParams: (state, action: PayloadAction<LocalSaleListParams>) => {
      state.VPCSalesListParams = action.payload;
    },
    updateVPCSubOrderListParams: (state, action: PayloadAction<FetchVPCSubOrderListParams>) => {
      state.VPCSubOrderListParams = action.payload;
    },
    updateVPCSalesPlanListParams: (state, action: PayloadAction<FetchVPCSalesPlanListParams>) => {
      state.VPCSalesPlanListParams = action.payload;
    },
    updateBrandListParam: (state, action: PayloadAction<FetchTagListParam>) => {
      state.brandListParam = action.payload;
    },
    updateMarketStatisticParam: (state, action: PayloadAction<FetchMarketStatisticParams>) => {
      state.marketStatisticListParams = action.payload;
    },
    clearMarketStatisticFilter: (state) => {
      state.marketStatisticListParams = initialState.marketStatisticListParams;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashBoard.pending, (state) => {
        state.isFetchingDashboard = true;
      })
      .addCase(fetchDashBoard.fulfilled, (state, action) => {
        state.isFetchingDashboard = false;
        state.dashboard = action.payload;
      });

    builder
      .addCase(fetchVPCSalesList.pending, (state) => {
        state.isFetchingVPCSalesList = true;
      })
      .addCase(fetchVPCSalesList.fulfilled, (state, action) => {
        state.isFetchingVPCSalesList = false;
        state.VPCSalesListResult = action.payload;
      });

    builder
      .addCase(fetchVPCSubOrderList.pending, (state) => {
        state.isFetchingVPCSubOrderList = true;
      })
      .addCase(fetchVPCSubOrderList.fulfilled, (state, action) => {
        state.isFetchingVPCSubOrderList = false;
        state.VPCSubOrderListResult = action.payload;
      });

    builder
      .addCase(fetchVPCSalesPlanList.pending, (state) => {
        state.isFetchingVPCSalesPlanList = true;
      })
      .addCase(fetchVPCSalesPlanList.fulfilled, (state, action) => {
        state.isFetchingVPCSalesPlanList = false;
        state.VPCSalesPlanListResult = action.payload;
      });

    builder
      .addCase(fetchBrandList.fulfilled, (state, action) => {
        state.brandListResult = action.payload;
      })
      .addCase(loadMoreBrandList.fulfilled, (state, action) => {
        state.brandListResult = {
          ...action.payload,
          results: state.brandListResult.results.concat(action.payload.results),
        };
      });

    builder.addCase(fetchProductCategoryList.fulfilled, (state, action) => {
      state.productCategory = action.payload;
    });

    builder
      .addCase(fetchMarketStatistic.pending, (state) => {
        state.isFetchingMarketStatistic = true;
      })
      .addCase(fetchMarketStatistic.fulfilled, (state, action) => {
        state.isFetchingMarketStatistic = false;
        state.marketStatisticListResult = action.payload;
      });
  },
});

export const {
  reset,
  updateVPCSalesListParams,
  updateVPCSalesPlanListParams,
  updateVPCSubOrderListParams,
  updateBrandListParam,
  updateMarketStatisticParam,
  clearMarketStatisticFilter,
} = salesReportSlice.actions;
export default salesReportSlice.reducer;
