import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import PromotionList from "./PromotionList";
import PromotionEdit from "./Edit";

const PromotionPage: FC = () => (
  <Routes>
    <Route path="/" element={<PromotionList />} />
    <Route path="/add" element={<PromotionEdit mode="add" />} />
    <Route path="/edit/:id" element={<PromotionEdit mode="edit" />} />
  </Routes>
);

export default PromotionPage;
