import { CheckCircleFilled, PlusCircleTwoTone } from "@ant-design/icons";
import { CreateSlotParam } from "@api/slotApi";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import { createSlot } from "@redux/slotSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Form, Input, Modal } from "antd";
import React, { FC, ReactNode, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { slotPatternTextMapping, SlotPatternValue, SlotTypeValue } from "./constant";

const Wrapper = styled.div`
  padding: 24px 40px 100px 29px;
`;
const Title = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  line-height: 32px;
  margin-right: 10px;
  font-size: 14px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div`
  width: 395px;
  & .ant-form-item-control-input {
    min-height: 0;
  }
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const SlotContainer = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 50px;
  row-gap: 50px;
  min-width: 0;
`;
const SlotItem = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  align-items: flex-start;
`;
const SlotTitle = styled.div`
  font-size: 13px;
  margin-bottom: 10px;
`;
const PreviewText = styled.span`
  margin-left: 5px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const SlotImgContainer = styled.div<{ selected: boolean }>`
  position: relative;
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? ""
      : `
        &:hover > div {
          display: flex;
        }
      `}
`;
const AddBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  font-size: 14px;
`;
const AddIcon = styled(PlusCircleTwoTone)`
  margin-right: 6px;
`;
const SlotImg = styled.img`
  max-width: 180px;
  height: auto;
`;
const SelectedIcon = styled(CheckCircleFilled)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 36px;
  color: #1890ff;
`;
const PreviewImgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;
const PreviewImg = styled.img`
  margin-bottom: 20px;
  max-width: 100%;
  max-height: 100%;
`;

interface SlotItemType {
  slotPattern: number;
  slotType: number;
  value: string;
  hasPreview: boolean;
  imgUrl: string;
  previewUrl?: string;
  previewText?: string | ReactNode;
}
const slotItemList: SlotItemType[] = [
  {
    slotPattern: SlotPatternValue.FILM_PATTERN,
    slotType: SlotTypeValue.FILM_SLOT,
    value: "影片",
    hasPreview: false,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/film-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.CAROUSEL_PATTERN,
    slotType: SlotTypeValue.CAROUSEL_SLOT,
    value: "大圖輪播(長寬比=3:1)",
    hasPreview: false,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/carousel-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.FLAT_CAROUSEL_PATTERN,
    slotType: SlotTypeValue.FLAT_CAROUSEL_SLOT,
    value: "小型圖片輪播(長寬比=10:1)",
    hasPreview: false,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/small-carousel-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.SINGLE_IMAGE_PATTERN,
    slotType: SlotTypeValue.SINGLE_IMAGE_SLOT,
    value: "單一圖片(比例不限)",
    hasPreview: false,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/single-image-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.CATEGORY_RECOMMENDATION_PATTERN,
    slotType: SlotTypeValue.CATEGORY_RECOMMENDATION_SLOT,
    value: "推薦分類",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/category-recommendation-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/category-recommendation-pattern.png`,
    previewText: "*超過10個分類，自動顯示為兩排分類",
  },
  {
    slotPattern: SlotPatternValue.LIMITED_TIME_PATTERN_ONE_ROW,
    slotType: SlotTypeValue.LIMITED_TIME_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.LIMITED_TIME_PATTERN_ONE_ROW],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/limit-pattern-one-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/limit-pattern-one-row.png`,
  },
  {
    slotPattern: SlotPatternValue.LIMITED_TIME_PATTERN_TWO_ROWS,
    slotType: SlotTypeValue.LIMITED_TIME_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.LIMITED_TIME_PATTERN_TWO_ROWS],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/limit-pattern-two-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/limit-pattern-two-row.png`,
    previewText: (
      <div>
        <div>*此版型佔Desktop版面50%</div>
        <div>*若選擇此版型，Mobile/App將顯示100%</div>
      </div>
    ),
  },
  {
    slotPattern: SlotPatternValue.TOP_ACTIVITY_PATTERN_FOUR_IMG,
    slotType: SlotTypeValue.TOP_ACTIVITY_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.TOP_ACTIVITY_PATTERN_FOUR_IMG],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/top-activity-pattern-4-img.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/top-activity-pattern-4-img.png`,
    previewText: "*照片尺寸為2:1",
  },
  {
    slotPattern: SlotPatternValue.TOP_ACTIVITY_PATTERN_TWO_IMG,
    slotType: SlotTypeValue.TOP_ACTIVITY_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.TOP_ACTIVITY_PATTERN_TWO_IMG],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/top-activity-pattern-2-img.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/top-activity-pattern-2-img.png`,
    previewText: "*照片尺寸為2:1",
  },
  {
    slotPattern: SlotPatternValue.TOP_ACTIVITY_PATTERN_THREE_IMG,
    slotType: SlotTypeValue.TOP_ACTIVITY_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.TOP_ACTIVITY_PATTERN_THREE_IMG],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/top-activity-pattern-3-img.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/top-activity-pattern-3-img.png`,
    previewText: (
      <div>
        <div>*此版型佔Desktop版面50%</div>
        <div>*照片尺寸為2:1</div>
      </div>
    ),
  },

  {
    slotPattern: SlotPatternValue.BASE_PATTERN_TWO_ROWS,
    slotType: SlotTypeValue.BASE_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.BASE_PATTERN_TWO_ROWS],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/base-pattern-two-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/base-pattern-two-row.png`,
    previewText: (
      <div>
        <div>*此版型佔Desktop版面50%</div>
        <div>*照片尺寸為2:1</div>
        <div>*若選擇此版型，Mobile/App將顯示100%</div>
      </div>
    ),
  },
  {
    slotPattern: SlotPatternValue.BASE_PATTERN_ONE_ROW,
    slotType: SlotTypeValue.BASE_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.BASE_PATTERN_ONE_ROW],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/base-pattern-one-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/base-pattern-one-row.png`,
    previewText: (
      <div>
        <div>*照片尺寸為2:1</div>
      </div>
    ),
  },
  {
    slotPattern: SlotPatternValue.TOPIC_PATTERN,
    slotType: SlotTypeValue.TOPIC_SLOT,
    value: "主題版位",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/topic-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/topic-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.RANKING_PATTERN,
    slotType: SlotTypeValue.RANKING_SLOT,
    value: "排行版位",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/ranking-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/ranking-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.KEYWORD_SEARCH_PATTERN_ONE_ROW,
    slotType: SlotTypeValue.KEYWORD_SEARCH_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.KEYWORD_SEARCH_PATTERN_ONE_ROW],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/keyword-search-pattern-one-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/keyword-search-pattern-one-row.png`,
  },
  {
    slotPattern: SlotPatternValue.KEYWORD_SEARCH_PATTERN_TWO_ROWS,
    slotType: SlotTypeValue.KEYWORD_SEARCH_SLOT,
    value: slotPatternTextMapping[SlotPatternValue.KEYWORD_SEARCH_PATTERN_TWO_ROWS],
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/keyword-search-pattern-two-row.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/keyword-search-pattern-two-row.png`,
    previewText: (
      <div>
        <div>*此版型佔Desktop版面50%</div>
        <div>*若選擇此版型，Mobile/App將顯示100%</div>
      </div>
    ),
  },
  {
    slotPattern: SlotPatternValue.HTML_TEXT_PRODUCT_PATTERN,
    slotType: SlotTypeValue.HTML_TEXT_PRODUCT_SLOT,
    value: "文字帶品版位",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/html-text-product-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/html-text-product-preview.png`,
  },
  {
    slotPattern: SlotPatternValue.HTML_TEXT_PATTERN,
    slotType: SlotTypeValue.HTML_TEXT_SLOT,
    value: "文字版位",
    hasPreview: false,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/html-text-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.VIP_ONLY_PATTERN,
    slotType: SlotTypeValue.VIP_ONLY_SLOT,
    value: "黑卡-獨享購買",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/vip-limit-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/vip-limit-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.VIP_RECOMMENDATION_PATTERN,
    slotType: SlotTypeValue.VIP_RECOMMENDATION_SLOT,
    value: "黑卡-熱銷推薦",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/vip-recommendation-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/vip-recommendation-pattern.png`,
  },

  {
    slotPattern: SlotPatternValue.SEARCH_RECOMMEND_PATTERN,
    slotType: SlotTypeValue.SEARCH_RECOMMEND_SLOT,
    value: "搜尋推廣文案",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/search-recommend-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/search-recommend-pattern.png`,
  },
  {
    slotPattern: SlotPatternValue.ANNOUNCEMENT_PATTERN,
    slotType: SlotTypeValue.ANNOUNCEMENT_SLOT,
    value: "Announcement Banner",
    hasPreview: true,
    imgUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/announcement-pattern.png`,
    previewUrl: `${process.env.REACT_APP_CLOUDFLARE_DOMAIN}media/slot/preview/announcement-pattern.png`,
  },
];

const AddSlot: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm<CreateSlotParam>();

  const slotDetail = useSelector((state: RootState) => state.slot.slotDetail);

  useEffect(() => {
    // 表示創立成功，導去編輯頁
    if (slotDetail.id > 0) navigate(`/slot-management/edit/${slotDetail.id}`);
  }, [slotDetail, navigate]);

  const handleOnSelectSlot = (slotPattern: number, slotType: number) => () => {
    const selectedPattern = form.getFieldValue("slotPattern");
    const isCurrentlySelected = selectedPattern === slotPattern;

    switch (slotPattern) {
      case SlotPatternValue.VIP_ONLY_PATTERN:
        form.setFieldsValue({
          name: "黑卡會員 獨享購買",
          slotPattern: isCurrentlySelected ? -1 : slotPattern,
          slotType: isCurrentlySelected ? -1 : slotType,
        });
        break;
      case SlotPatternValue.VIP_RECOMMENDATION_PATTERN:
        form.setFieldsValue({
          name: "會員最愛 熱銷推薦",
          slotPattern: isCurrentlySelected ? -1 : slotPattern,
          slotType: isCurrentlySelected ? -1 : slotType,
        });
        break;
      default:
        form.setFieldsValue({
          slotPattern: isCurrentlySelected ? -1 : slotPattern,
          slotType: isCurrentlySelected ? -1 : slotType,
        });
    }
  };

  const handleOnPreview = (slotItem: SlotItemType) => () => {
    const { previewUrl, previewText } = slotItem;
    Modal.info({
      bodyStyle: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: 322,
      },
      width: 655,
      title: (
        <PreviewImgContainer>
          <PreviewImg src={previewUrl} alt="preview" />
        </PreviewImgContainer>
      ),
      icon: "",
      content: previewText,
      okText: "我知道了",
    });
  };

  const handleOnSubmit = () => {
    const values = form.getFieldsValue();
    dispatch(createSlot(values));
  };

  return (
    <Wrapper>
      <PageTitle title="版位 - 新增" />
      <Title>新增版位</Title>
      <Form
        form={form}
        initialValues={{
          name: "",
          slotPattern: -1,
        }}
        onFinish={handleOnSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Row>
          <RowTitle>
            版位名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) => prevValues.slotPattern !== currentValues.slotPattern}
            >
              {({ getFieldValue }) => {
                const selectedPattern = getFieldValue("slotPattern");
                return (
                  <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                    <Input
                      disabled={
                        selectedPattern === SlotPatternValue.VIP_ONLY_PATTERN ||
                        selectedPattern === SlotPatternValue.VIP_RECOMMENDATION_PATTERN
                      }
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            選擇版位
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent>
            <Form.Item
              name="slotPattern"
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value >= 0) return Promise.resolve();
                    return Promise.reject(new Error("請選擇版位"));
                  },
                }),
              ]}
            >
              <div />
            </Form.Item>
            <Form.Item name="slotType" noStyle />
          </RowContent>
        </Row>
        <Form.Item shouldUpdate={(prevValues, currentValues) => prevValues.slotPattern !== currentValues.slotPattern}>
          {({ getFieldValue }) => {
            const selectedSlotPattern = getFieldValue("slotPattern");
            return (
              <SlotContainer>
                {slotItemList.map((item) => (
                  <SlotItem key={item.slotPattern}>
                    <SlotTitle>
                      {item.value}
                      {item.hasPreview && <PreviewText onClick={handleOnPreview(item)}>預覽</PreviewText>}
                    </SlotTitle>
                    <SlotImgContainer
                      selected={selectedSlotPattern === item.slotPattern}
                      onClick={handleOnSelectSlot(item.slotPattern, item.slotType)}
                    >
                      <AddBlock>
                        <AddIcon />
                        新增版位
                      </AddBlock>
                      <SlotImg src={item.imgUrl} />
                      {selectedSlotPattern === item.slotPattern && <SelectedIcon />}
                    </SlotImgContainer>
                  </SlotItem>
                ))}
              </SlotContainer>
            );
          }}
        </Form.Item>
        <Footer>
          <Button type="primary" htmlType="submit">
            下一步
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default AddSlot;
