import brandApi, { BrandListConfig, CreateBrandConfig, UpdateBrandConfig } from "@api/brandApi";
import { Brand } from "@api/utils/normalizeBrand";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface IState {
  isFetching: boolean;
  brandList: Brand[];
  brandCount: number;
  isEditDone: boolean;
  recommendBrand: Brand[];
  recommendCount: number;
  duplicatedBrandError: boolean;
}

const initialState: IState = {
  isFetching: false,
  brandList: [],
  brandCount: 0,
  isEditDone: false,
  recommendBrand: [],
  recommendCount: 0,
  duplicatedBrandError: false,
};

export const fetchBrandList = createAsyncThunk("brand/fetchBrandList", async (payload: BrandListConfig) => {
  const { data, count } = await brandApi.fetchBrandList(payload);
  return { data, count };
});

export const fetchCreateBrand = createAsyncThunk(
  "brand/fetchCreateBrand",
  async (payload: CreateBrandConfig, thunkAPI) => {
    try {
      await brandApi.fetchCreateBrand(payload);
      return "Success";
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  },
);

export const fetchUpdateBrand = createAsyncThunk(
  "brand/fetchUpdateBrand",
  async (payload: { brandId: number; data: UpdateBrandConfig }) => {
    await brandApi.fetchUpdateBrand(payload.brandId, payload.data);
    return "Success";
  },
);

export const fetchRecommendBrand = createAsyncThunk("brand/fetchRecommendBrand", async (payload: BrandListConfig) => {
  const { data, count } = await brandApi.fetchBrandList(payload);
  return { data, count };
});

const brandSlice = createSlice({
  name: "brand",
  initialState,
  reducers: {
    clearRecommendBrand: (state) => {
      state.recommendBrand = [];
      state.recommendCount = 0;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandList.pending, (state) => {
      state.isFetching = true;
      state.isEditDone = false;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      const { data, count } = action.payload;
      state.isFetching = false;
      state.brandCount = count;
      state.brandList = data;
    });
    builder.addCase(fetchBrandList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCreateBrand.pending, (state) => {
      state.isFetching = true;
      state.duplicatedBrandError = false;
    });
    builder.addCase(fetchCreateBrand.fulfilled, (state) => {
      state.isFetching = false;
      state.isEditDone = true;
      state.duplicatedBrandError = false;
    });
    builder.addCase(fetchCreateBrand.rejected, (state) => {
      state.isFetching = false;
      state.duplicatedBrandError = true;
    });
    builder.addCase(fetchUpdateBrand.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateBrand.fulfilled, (state) => {
      state.isFetching = false;
      state.isEditDone = true;
    });
    builder.addCase(fetchUpdateBrand.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchRecommendBrand.pending, (state) => {
      state.recommendBrand = [];
      state.recommendCount = 0;
    });
    builder.addCase(fetchRecommendBrand.fulfilled, (state, action) => {
      state.recommendBrand = action.payload.data;
      state.recommendCount = action.payload.count;
    });
    builder.addCase(fetchRecommendBrand.rejected, (state) => {});
  },
});

export const { clearRecommendBrand } = brandSlice.actions;
export default brandSlice.reducer;
