/* eslint-disable camelcase */
import type { ProductCategory, ProductCategoryItem, ProductVendorListResult } from "@api/productApi";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

interface ProductCategoryNodeRes {
  id: number;
  name: string;
  parent: number;
}
interface CategoryListRes {
  level_1: ProductCategoryNodeRes[];
  level_2: ProductCategoryNodeRes[];
  level_3: ProductCategoryNodeRes[];
}

interface ProductVendorListRes {
  count: number;
  next: string;
  previous: string;
  results: {
    id: number;
    vendor_info: {
      id: number;
      name: string;
      phone: string;
      contact_name: string;
    };
    price: number;
    cost: number;
    mode: string;
    commission_rate: number;
    pcs_per_carton: number;
    delivery_days: number;
    start_date: string;
    end_date: string;
    product: {
      id: number;
      sku: string;
      owner: string;
      is_active: boolean;
      sync_stock_option: number;
      name: string;
      brand: string;
      shelf_life: number;
      can_sell_days: number;
      can_accept_days: number;
      avg_daily_sales: number;
      can_sales_qty: number;
      turnover_days: number;
    };
  }[];
}

interface INormalizeProduct {
  categoryList: (res: CategoryListRes) => ProductCategory;
  productVendorList: (res: ProductVendorListRes) => ProductVendorListResult;
}

const normalizeProduct: INormalizeProduct = {
  categoryList: (res) => {
    // 只回傳有第三層品類的node
    const level1Map = new Map<number, ProductCategoryItem>();
    const level2Map = new Map<number, ProductCategoryItem>();
    const level3Map = new Map<number, ProductCategoryItem>();

    res.level_1.forEach((lvl1) => {
      const node = {
        ...lvl1,
        children: [],
      };
      level1Map.set(lvl1.id, node);
    });
    res.level_2.forEach((lvl2) => {
      const node = {
        ...lvl2,
        children: [],
      };
      level2Map.set(lvl2.id, node);
    });
    res.level_3.forEach((lvl3) => {
      const node = {
        ...lvl3,
        children: [],
      };
      level3Map.set(lvl3.id, node);
    });

    // 塞lvl3塞到相對應的lvl2
    for (const [, lvl3] of level3Map) {
      const parentNode = level2Map.get(lvl3.parent)!;
      parentNode.children.push(lvl3);
    }
    // 塞lvl2塞到相對應的lvl1
    for (const [, lvl2] of level2Map) {
      if (lvl2.children.length > 0) {
        const parentNode = level1Map.get(lvl2.parent)!;
        parentNode.children.push(lvl2);
      }
    }

    // 過濾無chidlren的節點
    for (const [, lvl1] of level1Map) {
      if (lvl1.children.length === 0) level1Map.delete(lvl1.id);
    }
    for (const [, lvl2] of level2Map) {
      if (lvl2.children.length === 0) level1Map.delete(lvl2.id);
    }

    // Map -> obj: redux存non-serializable values(Map, Set) 會有可能出錯
    const level1: ProductCategory["level1"] = {};
    const level2: ProductCategory["level2"] = {};
    const level3: ProductCategory["level3"] = {};

    for (const [key, value] of level1Map) {
      level1[key] = value;
    }
    for (const [key, value] of level2Map) {
      level2[key] = value;
    }
    for (const [key, value] of level3Map) {
      level3[key] = value;
    }

    return {
      level1,
      level2,
      level3,
    };
  },
  productVendorList: (res) => transformAPIKeyToCamel(res),
};

export default normalizeProduct;
