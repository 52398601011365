import React from "react";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { Tabs } from "antd";
import OrderTab from "./OrderTab";
import PruductTab from "./ProductTab";

const Wrapper = styled.div`
  position: relative;
  margin: 20px 20px 20px 25px;
  padding: 12px 24px 71px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const Preview = () => {
  return (
    <Wrapper>
      <PageTitle title="採購單檢視" />
      <Tabs type="card" defaultActiveKey="attributeType">
        <Tabs.TabPane tab="採購單" key="order">
          <OrderTab />
        </Tabs.TabPane>
        <Tabs.TabPane tab="採購商品" key="product">
          <PruductTab />
        </Tabs.TabPane>
      </Tabs>
    </Wrapper>
  );
};

export default Preview;
