/* eslint-disable @typescript-eslint/no-unused-vars */
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import {
  clearVPCSaleListFilter,
  fetchBrandList,
  fetchProductCategoryList,
  fetchVPCSalesList,
  loadMoreBrandList,
  LocalSaleListParams,
  updateVPCSalesListParams,
} from "@redux/salesReportSlice";
import { Button, Checkbox, DatePicker, Input, Select } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.form`
  position: relative;
  margin-bottom: 54px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const Section = styled.div<{ marginRight?: number }>`
  display: flex;
  align-items: center;
  margin-right: ${({ marginRight }) => marginRight || 0}px;
  font-size: 14px;
`;
const Title = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}` : "80px")};
  margin-right: 10px;
`;
const StyledSelect = styled(Select)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const StyledInput = styled(Input)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 380px;
`;
const CheckboxContainer = styled(Checkbox)`
  margin-right: 20px;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;
const ApplyButton = styled(Button)`
  margin-left: 10px;
`;

interface LocalFilter {
  productNameQ?: string;
  products?: string;
  owner?: number[];
  skus?: string;
  excludeSku?: string;
  brand?: number[];
  categoryLevel1: number;
  categoryLevel2: number;
  categoryLevel3: number;
  orderCreateDate?: [Moment | null, Moment | null];
  vpcStartDate?: [Moment | null, Moment | null];
  includeGift?: boolean;
  includeAddBuy?: boolean;
  contractType?: string;
  orderType?: string;
}

const initialFilter: LocalFilter = {
  categoryLevel1: -1,
  categoryLevel2: -1,
  categoryLevel3: -1,
  orderCreateDate: [null, null],
  vpcStartDate: [null, null],
  includeAddBuy: true,
  includeGift: true,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const { bdStaffData } = useSelector((state: RootState) => state.staffs);
  const { VPCSalesListParams, brandListResult, productCategory } = useSelector((state: RootState) => state.salesReport);

  const [localFilter, setLocalFilter] = useState<LocalFilter>({
    categoryLevel1: -1,
    categoryLevel2: -1,
    categoryLevel3: -1,
  });
  const {
    productNameQ,
    owner,
    excludeSku,
    brand,
    categoryLevel1,
    categoryLevel2,
    categoryLevel3,
    orderCreateDate,
    vpcStartDate,
    includeAddBuy,
    includeGift,
    contractType,
    orderType,
  } = localFilter;

  const skus = useMemo(() => (Array.isArray(localFilter.skus) ? localFilter.skus : localFilter.skus?.split(",")), [
    localFilter.skus,
  ]);
  const products = useMemo(
    () => (Array.isArray(localFilter.products) ? localFilter.products : localFilter.products?.split(",")),
    [localFilter.products],
  );

  useEffect(() => {
    const formatedOwner = VPCSalesListParams.owner
      ? VPCSalesListParams.owner.split(",").map((n) => Number(n))
      : undefined;
    const formatedBrand = VPCSalesListParams.brand
      ? VPCSalesListParams.brand.split(",").map((n) => Number(n))
      : undefined;

    setLocalFilter({
      productNameQ: VPCSalesListParams.productNameQ,
      owner: formatedOwner,
      skus: VPCSalesListParams.skus,
      products: VPCSalesListParams.products,
      excludeSku: VPCSalesListParams.excludeSku,
      brand: formatedBrand,
      categoryLevel1: VPCSalesListParams.categoryLevel1,
      categoryLevel2: VPCSalesListParams.categoryLevel2,
      categoryLevel3: VPCSalesListParams.categoryLevel3,
      orderCreateDate: [
        VPCSalesListParams.orderCreatedDateAfter ? moment(VPCSalesListParams.orderCreatedDateAfter) : null,
        VPCSalesListParams.orderCreatedDateBefore ? moment(VPCSalesListParams.orderCreatedDateBefore) : null,
      ],
      vpcStartDate: [
        VPCSalesListParams.vpcStartDateAfter ? moment(VPCSalesListParams.vpcStartDateAfter) : null,
        VPCSalesListParams.vpcStartDateBefore ? moment(VPCSalesListParams.vpcStartDateBefore) : null,
      ],
      includeAddBuy: VPCSalesListParams.includeAddBuy,
      includeGift: VPCSalesListParams.includeGift,
      contractType: VPCSalesListParams.contractType,
      orderType: VPCSalesListParams.orderType,
    });
  }, [VPCSalesListParams]);

  const staffOptions = useMemo(() => {
    const options = bdStaffData.map((stf) => ({
      value: stf.id,
      label: stf.name,
    }));
    return options;
  }, [bdStaffData]);

  useEffect(() => {
    dispatch(fetchBrandList(""));
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const categoryLevel1Options = useMemo(() => {
    const options = Object.values(productCategory.level1).map((ctgryItem) => (
      <Select.Option key={ctgryItem.name} value={ctgryItem.id}>
        {ctgryItem.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇第一層品類
      </Select.Option>,
    );
    return options;
  }, [productCategory]);

  const categoryLevel2Options = useMemo(() => {
    if (categoryLevel1 && categoryLevel1 > 0) {
      const level2Options = productCategory.level1[categoryLevel1]!;
      const options = level2Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第一層品類，再選擇第二層品類
      </Select.Option>
    );
  }, [productCategory, categoryLevel1]);

  const categoryLevel3Options = useMemo(() => {
    if (categoryLevel2 && categoryLevel2 > 0) {
      const level3Options = productCategory.level2[categoryLevel2]!;
      const options = level3Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第二層品類，再選擇第三層品類
      </Select.Option>
    );
  }, [productCategory, categoryLevel2]);

  const handleOnChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    switch (title) {
      case "categoryLevel1":
        // 選第一品類 刷新第二三層品類
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel2: -1,
          categoryLevel3: -1,
        }));
        break;
      case "categoryLevel2":
        // 選第二品類 刷新第三層品類
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value as number,
          categoryLevel3: -1,
        }));
        break;
      default:
        setLocalFilter((prev) => ({
          ...prev,
          [title]: value,
        }));
    }
  };

  const handleOnDateChange = (title: string) => (value: RangeValue<Moment>) => {
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleOnCheck = (title: string) => (e: CheckboxChangeEvent) => {
    const { checked } = e.target;

    setLocalFilter((prev) => ({
      ...prev,
      [title]: !checked,
    }));
  };

  const handleOnClear = () => {
    dispatch(clearVPCSaleListFilter());
    setLocalFilter(initialFilter);
  };

  const handleOnApply = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { orderCreateDate: orderDate, vpcStartDate: vpcDate } = localFilter;

    const filterParams: LocalSaleListParams = {
      limit: 20,
      offset: 0,
      productNameQ: localFilter.productNameQ,
      owner: localFilter.owner?.join(),
      brand: localFilter.brand?.join(),
      skus: localFilter.skus,
      products: localFilter.products,
      excludeSku: localFilter.excludeSku,
      orderCreatedDateAfter: orderDate && orderDate[0] ? moment(orderDate[0]).format("YYYY-MM-DD") : undefined,
      orderCreatedDateBefore: orderDate && orderDate[1] ? moment(orderDate[1]).format("YYYY-MM-DD") : undefined,
      vpcStartDateAfter: vpcDate && vpcDate[0] ? moment(vpcDate[0]).format("YYYY-MM-DD") : undefined,
      vpcStartDateBefore: vpcDate && vpcDate[1] ? moment(vpcDate[1]).format("YYYY-MM-DD") : undefined,
      includeGift: localFilter.includeGift,
      includeAddBuy: localFilter.includeAddBuy,
      categoryLevel1: localFilter.categoryLevel1,
      categoryLevel2: localFilter.categoryLevel2,
      categoryLevel3: localFilter.categoryLevel3,
      contractType: localFilter.contractType,
      orderType: localFilter.orderType,
      salesChannel: VPCSalesListParams.salesChannel,
    };

    dispatch(updateVPCSalesListParams(filterParams));
    dispatch(fetchVPCSalesList());
  };
  const handleOnSelectTagModeChange = (value: SelectValue, inputName: string) => {
    setLocalFilter((prev) => ({
      ...prev,
      [inputName]: Array.isArray(value) && value.length >= 1 ? value.join() : undefined,
    }));
  };

  return (
    <Wrapper onSubmit={handleOnApply}>
      <Row>
        <Section marginRight={30}>
          <Title>負責人</Title>
          <StyledSelect
            width={280}
            options={staffOptions}
            mode="multiple"
            placeholder="請選擇"
            value={owner}
            onChange={(value) => handleOnSelect("owner")(value as SelectValue)}
          />
        </Section>
        <Section marginRight={20}>
          <Title width={120}>一般/黑卡折扣訂單</Title>
          <StyledSelect
            width={140}
            value={orderType}
            onChange={(value: any) =>
              setLocalFilter((prev) => ({
                ...prev,
                orderType: value.toString(),
              }))
            }
          >
            <Select.Option value="">全部</Select.Option>
            <Select.Option value="normal">一般</Select.Option>
            <Select.Option value="black">黑卡</Select.Option>
          </StyledSelect>
        </Section>
        <Section>
          <Title>商品名稱</Title>
          <StyledInput width={260} value={productNameQ} onChange={handleOnChange("productNameQ")} />
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>商品ID</Title>
          <Select
            mode="tags"
            style={{ width: "280px" }}
            placeholder="可一次篩選多筆, 以逗號分隔"
            value={products}
            open={false}
            tokenSeparators={[",", "，", "\n"]}
            onChange={(value) => handleOnSelectTagModeChange(value, "products")}
          />
        </Section>
        <Section>
          <Title>品牌</Title>
          <StyledSelect
            mode="multiple"
            width={280}
            showSearch
            filterOption={false}
            value={brand}
            onChange={(value) => handleOnSelect("brand")(value as SelectValue)}
            onSearch={handleOnBrandSearch}
            placeholder="請選擇"
          >
            {brandOptions}
          </StyledSelect>
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>國際條碼</Title>
          <Select
            mode="tags"
            style={{ width: "280px" }}
            placeholder="可一次篩選多筆, 以逗號分隔"
            value={skus}
            open={false}
            tokenSeparators={[",", "，", "\n"]}
            onChange={(value) => handleOnSelectTagModeChange(value, "skus")}
          />
        </Section>
        <Section>
          <Title>排除條碼</Title>
          <StyledInput
            width={280}
            placeholder="可一次篩選多筆, 以逗號分隔"
            value={excludeSku}
            onChange={handleOnChange("excludeSku")}
          />
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>第一層品類</Title>
          <StyledSelect
            width={280}
            value={categoryLevel1}
            onChange={(value) => handleOnSelect("categoryLevel1")(value as SelectValue)}
          >
            {categoryLevel1Options}
          </StyledSelect>
        </Section>
        <Section>
          <Title>開賣時間</Title>
          <RangePicker
            value={vpcStartDate}
            onChange={(value) => handleOnDateChange("vpcStartDate")(value as RangeValue<Moment>)}
          />
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>第二層品類</Title>
          <StyledSelect
            width={280}
            value={categoryLevel2}
            disabled={!categoryLevel1 || categoryLevel1 < 0}
            onChange={(value) => handleOnSelect("categoryLevel2")(value as SelectValue)}
          >
            {categoryLevel2Options}
          </StyledSelect>
        </Section>
        <Section>
          <Title>銷售期限</Title>
          <RangePicker
            value={orderCreateDate}
            onChange={(value) => handleOnDateChange("orderCreateDate")(value as RangeValue<Moment>)}
          />
        </Section>
      </Row>
      <Row>
        <Section marginRight={30}>
          <Title>第三層品類</Title>
          <StyledSelect
            width={280}
            value={categoryLevel3}
            disabled={!categoryLevel2 || categoryLevel2 < 0}
            onChange={(value) => handleOnSelect("categoryLevel3")(value as SelectValue)}
          >
            {categoryLevel3Options}
          </StyledSelect>
        </Section>
        <Section>
          <Title>合約狀態</Title>
          <StyledSelect
            width={280}
            mode="multiple"
            allowClear
            value={contractType ? contractType.split(",") : undefined}
            onChange={(value: any) =>
              setLocalFilter((prev) => ({
                ...prev,
                contractType: value.toString(),
              }))
            }
          >
            <Select.Option value="1">一般</Select.Option>
            <Select.Option value="2">臨時</Select.Option>
          </StyledSelect>
        </Section>
      </Row>
      <Row style={{ marginLeft: 480 }}>
        <Section>
          <CheckboxContainer checked={!includeGift} onChange={handleOnCheck("includeGift")}>
            不含贈品
          </CheckboxContainer>
          <CheckboxContainer checked={!includeAddBuy} onChange={handleOnCheck("includeAddBuy")}>
            不含加購品
          </CheckboxContainer>
        </Section>
      </Row>
      <ButtonContainer>
        <Button onClick={handleOnClear}>清除篩選條件</Button>
        <ApplyButton type="primary" htmlType="submit">
          套用
        </ApplyButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
