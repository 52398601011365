export enum PageType {
  HOME = 0,
  CATEGORY = 1,
  TAG = 2,
  SEARCH = 3,
  TOPIC = 4,
  SALES_PAGE = 5,
  TOPIC_CATEGORY = 6,
  VIP_PROMOTION = 7,
  ANNOUNCEMENT_BANNER = 8,
  ALL_SALES_PAGE = 9,
}

export const pageTypeTextMapping: { [pageType: number]: string } = {
  [PageType.HOME]: "首頁",
  [PageType.CATEGORY]: "分類頁",
  [PageType.TAG]: "標籤頁",
  [PageType.SEARCH]: "搜尋頁",
  [PageType.TOPIC]: "主題頁",
  [PageType.SALES_PAGE]: "銷售頁",
  [PageType.TOPIC_CATEGORY]: "主題分類頁",
  [PageType.ALL_SALES_PAGE]: "全站銷售頁",
};

export enum SlotTypeValue {
  CAROUSEL_SLOT = 0,
  HTML_TEXT_SLOT = 1,
  CATEGORY_RECOMMENDATION_SLOT = 2,
  LIMITED_TIME_SLOT = 3,
  TOP_ACTIVITY_SLOT = 4,
  BASE_SLOT = 5,
  TOPIC_SLOT = 6,
  RANKING_SLOT = 7,
  KEYWORD_SEARCH_SLOT = 8,
  FILM_SLOT = 9,
  FLAT_CAROUSEL_SLOT,
  SINGLE_IMAGE_SLOT = 11,
  VIP_ONLY_SLOT = 12,
  VIP_RECOMMENDATION_SLOT = 13,
  ANNOUNCEMENT_SLOT = 14,
  SEARCH_RECOMMEND_SLOT = 15,
  HTML_TEXT_PRODUCT_SLOT = 16,
}

export enum SlotPatternValue {
  CAROUSEL_PATTERN = 0,
  HTML_TEXT_PATTERN = 10,
  CATEGORY_RECOMMENDATION_PATTERN = 20,
  LIMITED_TIME_PATTERN_ONE_ROW = 30,
  LIMITED_TIME_PATTERN_TWO_ROWS = 31,
  TOP_ACTIVITY_PATTERN_FOUR_IMG = 40,
  TOP_ACTIVITY_PATTERN_THREE_IMG = 41,
  TOP_ACTIVITY_PATTERN_TWO_IMG = 42,
  BASE_PATTERN_TWO_ROWS = 50,
  BASE_PATTERN_ONE_ROW = 51,
  TOPIC_PATTERN = 60,
  RANKING_PATTERN = 70,
  KEYWORD_SEARCH_PATTERN_TWO_ROWS = 80,
  KEYWORD_SEARCH_PATTERN_ONE_ROW = 81,
  FILM_PATTERN = 90,
  FLAT_CAROUSEL_PATTERN = 100,
  SINGLE_IMAGE_PATTERN = 110,
  VIP_ONLY_PATTERN = 120,
  VIP_RECOMMENDATION_PATTERN = 130,
  ANNOUNCEMENT_PATTERN = 140,
  SEARCH_RECOMMEND_PATTERN = 150,
  HTML_TEXT_PRODUCT_PATTERN = 160,
}

export const slotPatternTextMapping: { [slotPattern: number]: string } = {
  [SlotPatternValue.CAROUSEL_PATTERN]: "圖片輪播",
  [SlotPatternValue.HTML_TEXT_PATTERN]: "文字版型",
  [SlotPatternValue.HTML_TEXT_PRODUCT_PATTERN]: "文字帶品版位",
  [SlotPatternValue.CATEGORY_RECOMMENDATION_PATTERN]: "推薦分類",
  [SlotPatternValue.LIMITED_TIME_PATTERN_ONE_ROW]: "限時特惠-100%",
  [SlotPatternValue.LIMITED_TIME_PATTERN_TWO_ROWS]: "限時特惠-50%",
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_FOUR_IMG]: "大檔活動(四張圖片)-100%",
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_TWO_IMG]: "大檔活動(兩張圖片)-100%",
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_THREE_IMG]: "大檔活動-50%",
  [SlotPatternValue.BASE_PATTERN_TWO_ROWS]: "基本版位-50%",
  [SlotPatternValue.BASE_PATTERN_ONE_ROW]: "基本版位-100%",
  [SlotPatternValue.TOPIC_PATTERN]: "主題版位",
  [SlotPatternValue.RANKING_PATTERN]: "排行版位",
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_TWO_ROWS]: "熱門關鍵字-50%",
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_ONE_ROW]: "熱門關鍵字-100%",
  [SlotPatternValue.FILM_PATTERN]: "影片",
  [SlotPatternValue.FLAT_CAROUSEL_PATTERN]: "小型圖片輪播",
  [SlotPatternValue.SINGLE_IMAGE_PATTERN]: "單一圖片",
  [SlotPatternValue.VIP_ONLY_PATTERN]: "黑卡-獨享購買",
  [SlotPatternValue.VIP_RECOMMENDATION_PATTERN]: "黑卡-熱銷推薦",
  [SlotPatternValue.ANNOUNCEMENT_PATTERN]: "Announcement Banner",
  [SlotPatternValue.SEARCH_RECOMMEND_PATTERN]: "搜尋推廣文案",
};

export const PageTypeList = [
  {
    id: -1,
    value: "請選擇",
  },
  {
    id: PageType.HOME,
    value: "首頁",
  },
  {
    id: PageType.CATEGORY,
    value: "分類頁",
  },
  {
    id: PageType.TAG,
    value: "標籤頁",
  },
  {
    id: PageType.SEARCH,
    value: "搜尋頁",
  },
  {
    id: PageType.TOPIC,
    value: "主題館",
  },
  {
    id: PageType.TOPIC_CATEGORY,
    value: "主題分類頁",
  },
  {
    id: PageType.ALL_SALES_PAGE,
    value: "全站銷售頁",
  },
  {
    id: PageType.SALES_PAGE,
    value: "指定銷售頁",
  },
];
export const SlotPatternList = [
  {
    id: -1,
    value: "請選擇",
  },
  ...Object.keys(slotPatternTextMapping).map((el) => ({
    id: parseInt(el, 10),
    value: slotPatternTextMapping[parseInt(el, 10)],
  })),
];
