import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { Tag, Table, Tooltip, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { CategorySalesDashboardItem, CategorySalesDashboardDiff } from "@api/dashboardApi";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 500px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Flex = styled.div`
  display: flex;
  align-items: center;
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 5px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

type TableData = CategorySalesDashboardItem & CategorySalesDashboardDiff;

const columns = [
  {
    title: "子分類名稱",
    dataIndex: "categoryName",
    width: 110,
  },
  {
    title: "營收",
    dataIndex: "revenue",
    width: 160,
    render: (value: number, data: TableData) => {
      const { revenue, revenueDiff = 0 } = data;
      const money = USCurrencyFormmater.format(revenue);
      const tagColor = revenueDiff < 0 ? "error" : "success";
      const tagIcon = revenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
      const change = Math.round(Math.abs(revenueDiff) * 1000) / 10;

      return (
        <Flex>
          {money}
          <StyledTag icon={tagIcon} color={tagColor}>
            {`${change}%`}
          </StyledTag>
        </Flex>
      );
    },
  },
  {
    title: (
      <div>
        毛利
        <Tooltip placement="bottom" title="營收-銷售成本+抽成加總">
          <InfoIcon />
        </Tooltip>
      </div>
    ),
    dataIndex: "grossProfit",
    width: 160,
    render: (value: number, data: TableData) => {
      const { grossProfit, grossProfitDiff = 0 } = data;
      const money = USCurrencyFormmater.format(grossProfit);
      const tagColor = grossProfitDiff < 0 ? "error" : "success";
      const tagIcon = grossProfitDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
      const change = Math.round(Math.abs(grossProfitDiff) * 1000) / 10;

      return (
        <Flex>
          {money}
          <StyledTag icon={tagIcon} color={tagColor}>
            {`${change}%`}
          </StyledTag>
        </Flex>
      );
    },
  },
];

const SubCategoryReportCard: FC = () => {
  const isFetchingCategorySalesDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingCategorySalesDashboard,
  );
  const categorySalesDashboard = useSelector((state: RootState) => state.dashboard.categorySalesDashboard);
  const { currentData = [], diff = [] } = categorySalesDashboard;

  const columnsData: TableData[] = useMemo(() => {
    const tempMapData: {
      [index: string]: any;
    } = {};
    currentData.forEach((item) => {
      const { categoryName } = item;
      tempMapData[categoryName] = item;
    });
    diff.forEach((item) => {
      const { categoryName } = item;
      if (tempMapData[categoryName]) {
        tempMapData[categoryName] = {
          ...tempMapData[categoryName],
          ...item,
        };
      }
    });

    return Object.values(tempMapData);
  }, [currentData, diff]);

  return (
    <Spin spinning={isFetchingCategorySalesDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>子分類營收與毛利</HeaderTitle>
          <AnchorToChart href="/dashboard/category-sales" />
        </Header>
        <Table
          rowKey="categoryName"
          columns={columns}
          dataSource={columnsData}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </Wrapper>
    </Spin>
  );
};

export default SubCategoryReportCard;
