import productApi, { FetchVendorListParam, VendorListResult } from "@api/productApi";
import staffApi, { FetchStaffListParam, StaffListResult } from "@api/staffApi";
import { ContractVendors, Purchase, PurchaseLines, PurchaseList, PurchaseVpc } from "@api/utils/normalizeWarehouse";
import warehouseApi, {
  CreatePurchasePayload,
  CreatePurchseLinePayload,
  FetchPurchaseLines,
  FetchPurchaseList,
  FetchPurchaseVpc,
  PurchaseListOrdering,
  UpdatePurchasePayload,
  UpdatePurchseLinePayload,
} from "@api/warehouseApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  purchaseListResult: {
    count: number;
    next: string;
    previous: string;
    results: PurchaseList[];
  };
  purchaseListFilter: {
    showDestroy: boolean;
    noStockin: boolean;
    stoNumber?: string;
    vendor?: number;
    owner?: number;
    sku?: string;
    limit: number;
    specDateBefore?: string;
    specDateAfter?: string;
    ordering?: PurchaseListOrdering;
    offset: number;
  };
  purchaseInfo: Purchase | undefined;
  contractVendors: ContractVendors[];
  staffListResult: StaffListResult;
  purchaseLinesResult: {
    count: number;
    next: string;
    previous: string;
    results: { abnormalCost: boolean; data: PurchaseLines[] };
  };
  purchaseVpc: PurchaseVpc | undefined;
  purchaseVpcIsContract: boolean;
  purchaseLineRefresh: boolean;
  vendorListResult: VendorListResult;
  vendorListParam: FetchVendorListParam;
}

const initialState: IState = {
  isFetching: false,
  purchaseListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  purchaseListFilter: {
    showDestroy: false,
    noStockin: false,
    limit: 20,
    offset: 0,
  },
  purchaseInfo: undefined,
  contractVendors: [],
  staffListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  purchaseLinesResult: {
    count: 0,
    next: "",
    previous: "",
    results: { abnormalCost: false, data: [] },
  },
  purchaseVpc: undefined,
  purchaseVpcIsContract: true,
  purchaseLineRefresh: true,
  vendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorListParam: {
    nameQ: "",
    limit: 20,
    offset: 0,
  },
};

export const fetchPurchaseList = createAsyncThunk(
  "warehouse/fetchPurchaseList",
  async (filterOptions: FetchPurchaseList) => {
    const response = await warehouseApi.fetchPurchaseList(filterOptions);
    return response;
  },
);

export const fetchPurchaseInfo = createAsyncThunk(
  "warehouse/fetchPurchaseInfo",
  async (params: { purchaseId: number; showDestroy?: string }) => {
    const response = await warehouseApi.fetchPurchase(params.purchaseId, params?.showDestroy);
    return response;
  },
);

export const fetchContractVendors = createAsyncThunk("warehouse/fetchContractVendors", async () => {
  const response = await warehouseApi.fetchContractVendors();
  return response;
});

export const fetchStaffList = createAsyncThunk("warehouse/fetchStaffList", async (payload: FetchStaffListParam) => {
  const response = await staffApi.fetchStaffList(payload);
  return response;
});

export const fetchCreatePurchase = createAsyncThunk(
  "warehouse/fetchCreatePurchase",
  async (payload: CreatePurchasePayload, thunkApi) => {
    const {
      warehouseSlice: { purchaseListFilter },
    } = thunkApi.getState() as RootState;
    await warehouseApi.fetchCreatePurchase(payload);
    await thunkApi.dispatch(fetchPurchaseList({ ...purchaseListFilter, limit: purchaseListFilter.limit, offset: 0 }));
    return "Success";
  },
);

export const fetchCopyPurchase = createAsyncThunk(
  "warehouse/fetchCopyPurchase",
  async (purchaseId: number, thunkApi) => {
    const {
      warehouseSlice: { purchaseListFilter },
    } = thunkApi.getState() as RootState;
    await warehouseApi.fetchCopyPurchase(purchaseId);
    await thunkApi.dispatch(fetchPurchaseList({ ...purchaseListFilter, limit: purchaseListFilter.limit, offset: 0 }));
  },
);

export const fetchDestroyPurchase = createAsyncThunk(
  "warehouse/fetchDestroyPurchase",
  async (purchaseId: number, thunkApi) => {
    const {
      warehouseSlice: { purchaseListFilter },
    } = thunkApi.getState() as RootState;
    await warehouseApi.fetchDestroyPurchase(purchaseId);
    await thunkApi.dispatch(fetchPurchaseList({ ...purchaseListFilter, limit: purchaseListFilter.limit, offset: 0 }));
  },
);

export const fetchUpdatePurchase = createAsyncThunk(
  "warehouse/fetchUpdatePurchase",
  async (payload: UpdatePurchasePayload, thunkApi) => {
    await warehouseApi.fetchUpdatePurchase(payload);
    await thunkApi.dispatch(fetchPurchaseInfo({ purchaseId: payload.purchaseId, showDestroy: "0" }));
    return "Success";
  },
);

export const fetchPurchaseLines = createAsyncThunk(
  "warehouse/fetchPurchaseLines",
  async (filterOptions: FetchPurchaseLines) => {
    const response = await warehouseApi.fetchPurchaseLines(filterOptions);
    return response;
  },
);

export const fetchPurchaseVpc = createAsyncThunk(
  "warehouse/fetchPurchaseVpc",
  async (filterOptions: FetchPurchaseVpc, thunkApi) => {
    try {
      const response = await warehouseApi.fetchPurchaseVpc(filterOptions);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data as any);
    }
  },
);

export const fetchCreatePurchseLine = createAsyncThunk(
  "warehouse/fetchCreatePurchseLine",
  async (payload: CreatePurchseLinePayload, thunkApi) => {
    try {
      await warehouseApi.fetchCreatePurchseLine(payload);
      message.success("新增成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  },
);

export const fetchUpdatePurchaseLine = createAsyncThunk(
  "warehouse/fetchUpdatePurchaseLine",
  async (payload: UpdatePurchseLinePayload, thunkApi) => {
    try {
      await warehouseApi.fetchUpdatePurchaseLine(payload);
      message.success("編輯成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response);
    }
  },
);

export const fetchDeletePurchaseLine = createAsyncThunk(
  "warehouse/fetchDeletePurchaseLine",
  async (purchaseLineId: number) => {
    await warehouseApi.fetchDeletePurchaseLine(purchaseLineId);
    message.success("刪除成功");
    return "Success";
  },
);

export const fetchDeployToLMS = createAsyncThunk("warehouse/fetchDeployToLMS", async (purchaseId: number, thunkApi) => {
  try {
    await warehouseApi.fetchDeployToLMS(purchaseId);
    await thunkApi.dispatch(fetchPurchaseInfo({ purchaseId }));
    message.success("上傳成功");
  } catch (error: any) {
    thunkApi.rejectWithValue(error.message);
  }

  return "Success";
});

export const fetchVendorList = createAsyncThunk("warehouse/fetchVendorList", async (name: string, thunkApi) => {
  const params: FetchVendorListParam = {
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const loadMoreVendorList = createAsyncThunk("warehouse/loadMoreVendorList", async (_, thunkApi) => {
  const {
    product: { vendorListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchVendorListParam = {
    ...vendorListParam,
    offset: vendorListParam.offset + vendorListParam.limit,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});
export const fetchSubmitSettlement = createAsyncThunk(
  "warehouse/fetchSubmitSettlement",
  async (
    payload: {
      purchaseId: number;
      warehouseCode: string;
    },
    thunkApi,
  ) => {
    try {
      const { purchaseId, warehouseCode } = payload;
      const response = await warehouseApi.fetchSubmitSettlement(purchaseId, warehouseCode);
      const {
        warehouseSlice: { purchaseListFilter },
      } = thunkApi.getState() as RootState;
      await thunkApi.dispatch(fetchPurchaseList({ ...purchaseListFilter, limit: purchaseListFilter.limit, offset: 0 }));
      message.success("上架成功");
      return response;
    } catch (error: any) {
      message.error(error.message);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const warehouseSlice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    setPurchaseListFilter: (state, action) => {
      state.purchaseListFilter = action.payload;
    },
    clearPurchaseListFilter: (state) => {
      state.purchaseListFilter = initialState.purchaseListFilter;
    },
    clearPurchaseVpc: (state) => {
      state.purchaseVpc = undefined;
      state.purchaseVpcIsContract = true;
    },
    updateVendorListParam: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.vendorListParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPurchaseList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPurchaseList.fulfilled, (state, action) => {
      const response = action.payload;
      state.isFetching = false;
      state.purchaseListResult = response;
    });
    builder.addCase(fetchPurchaseList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchPurchaseInfo.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPurchaseInfo.fulfilled, (state, action) => {
      const response = action.payload;
      state.isFetching = false;
      state.purchaseInfo = response;
    });
    builder.addCase(fetchPurchaseInfo.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchContractVendors.fulfilled, (state, action) => {
      const response = action.payload;
      state.contractVendors = response;
    });
    builder.addCase(fetchStaffList.fulfilled, (state, action) => {
      const response = action.payload;
      state.staffListResult = response;
    });
    builder.addCase(fetchPurchaseLines.fulfilled, (state, action) => {
      const response = action.payload;
      state.purchaseLinesResult = response;
      state.purchaseLineRefresh = false;
    });
    builder.addCase(fetchPurchaseVpc.fulfilled, (state, action) => {
      const response = action.payload;
      state.purchaseVpcIsContract = true;
      state.purchaseVpc = response;
    });
    builder.addCase(fetchPurchaseVpc.rejected, (state) => {
      state.purchaseVpc = undefined;
      state.purchaseVpcIsContract = false;
    });
    builder.addCase(fetchCreatePurchseLine.fulfilled, (state) => {
      state.purchaseLineRefresh = true;
    });
    builder.addCase(fetchUpdatePurchaseLine.fulfilled, (state) => {
      state.purchaseLineRefresh = true;
    });
    builder.addCase(fetchDeletePurchaseLine.fulfilled, (state) => {
      state.purchaseLineRefresh = true;
    });

    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorListResult = action.payload;
    });
    builder.addCase(loadMoreVendorList.fulfilled, (state, action) => {
      state.vendorListResult = {
        ...action.payload,
        results: state.vendorListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const warehouseState = (state: RootState) => state.warehouseSlice;
export const {
  setPurchaseListFilter,
  clearPurchaseListFilter,
  clearPurchaseVpc,
  updateVendorListParam,
} = warehouseSlice.actions;
export default warehouseSlice.reducer;
