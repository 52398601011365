import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import ReviewList from "./ReviewList";
import ReviewEdit from "./ReviewEdit";

const ReviewManagementPage: FC = () => (
  <Routes>
    <Route path="/" element={<ReviewList />} />
    <Route path="/edit/:id" element={<ReviewEdit />} />
  </Routes>
);

export default ReviewManagementPage;
