import React, { FC } from "react";
import styled from "styled-components";
import { RightSquareOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";

const Anchor = styled.a`
  display: flex;
  align-items: center;
`;
const RightIcon = styled(RightSquareOutlined)`
  color: #a7a7a7;
  font-size: 22px;
  margin-left: 4px;
`;

interface Props {
  href: string;
}

const AnchorToChart: FC<Props> = (props) => {
  const { href } = props;
  const dashboardActiveTab = useSelector((state: RootState) => state.dashboard.dashboardActiveTab);

  const targetUrl = `${href}?time=${dashboardActiveTab}`;

  return (
    <Anchor target="_blank" href={targetUrl} rel="noreferrer">
      <RightIcon />
    </Anchor>
  );
};

export default AnchorToChart;
