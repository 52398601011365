import { CustomsClearances } from "@api/utils/normalizeCustomsClearance";
import { CurrencyMap } from "@api/utils/normalizeWarehouse";
import { Row } from "antd";
import React from "react";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const ContentWrapper = styled.div`
  padding: 27px 35px;
`;

const Text = styled.p`
  width: 144px;
  margin-bottom: 31px;
  ${fontStyle("14px", "16px")};
`;

const Content = styled(Text)`
  width: max-content;
`;

type Props = {
  customsClearance: CustomsClearances;
};

export default function CustomsClearanceInfo(props: Props) {
  const { customsClearance } = props;
  return (
    <ContentWrapper>
      <Row align="middle">
        <Text>進口報關單號</Text>
        <Content>{customsClearance.tariffNumber}</Content>
      </Row>
      <Row align="middle">
        <Text>廠商名稱</Text>
        <Content>{customsClearance.vendorName}</Content>
      </Row>
      <Row align="middle">
        <Text>付款幣別</Text>
        <Content>{CurrencyMap[customsClearance.currency]}</Content>
      </Row>
      <Row align="middle">
        <Text>當下匯率</Text>
        <Content>{customsClearance.currencyRate}</Content>
      </Row>
      <Row align="middle">
        <Text>關稅</Text>
        <Content>{customsClearance.tariff}</Content>
      </Row>
      <Row align="middle">
        <Text>處理費</Text>
        <Content>{customsClearance.processingFee}</Content>
      </Row>
      <Row align="middle">
        <Text>運費</Text>
        <Content>{customsClearance.shippingFee}</Content>
      </Row>
      <Row align="middle">
        <Text>廠商回饋金(加總)</Text>
        <Content>{customsClearance.totalRebate === null ? "N/A" : customsClearance.totalRebate}</Content>
      </Row>
      <Row align="middle">
        <Text>加工處理費用(加總)</Text>
        <Content>{customsClearance.totalProcessingFee === null ? "N/A" : customsClearance.totalProcessingFee}</Content>
      </Row>
      <Row align="middle">
        <Text>運送方式</Text>
        <Content>{customsClearance.transportModeText}</Content>
      </Row>
    </ContentWrapper>
  );
}
