import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { fetchSupplementDetail, reviewSupplement } from "@redux/vendorSlice";
import { Button, Row, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { vendorApproveStatusMap, supplementPurposeMap, VendorApproveStatus } from "@constant/vendor";
import moment from "moment";
import PageTitle from "@component/PageTitle";
import { ReviewStateByPositionLabel } from "@constant/ReviewState";
import EditModal from "./EditModal";

const ContentWrapper = styled.div`
  padding: 27px 35px;
`;

const Text = styled.p`
  width: 133px;
  margin-bottom: 33px;
  ${fontStyle("14px", "16px")};
`;

const Content = styled(Text)`
  width: max-content;
`;

const toMonth = (timeString: string | null) => {
  return timeString ? moment(timeString).format("YYYY-MM") : "";
};

export default function Detail() {
  const { supplementId } = useParams();
  const dispatch = useAppDispatch();
  const { supplementDetail } = useSelector((state: RootState) => state.vendorSlice);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchSupplementDetail(Number(supplementId)));
  }, [dispatch, supplementId]);

  const canAction =
    [VendorApproveStatus.REJECT, VendorApproveStatus.PASS, VendorApproveStatus.DELETE].indexOf(
      supplementDetail.approvalStatus,
    ) > -1;

  const approveStatusString = () => {
    return `${vendorApproveStatusMap[supplementDetail.approvalStatus]} ${canAction ? supplementDetail.updatedAt : ""}`;
  };

  const onDuplicateClick = () => {
    setShowModal(true);
    setIsEdit(false);
  };

  return (
    <ContentWrapper>
      <PageTitle title="補單詳情" />
      <Row justify="end">
        <Space>
          <Button
            type="default"
            disabled={canAction}
            onClick={() => {
              dispatch(reviewSupplement(false));
            }}
          >
            審核不通過
          </Button>
          <Button
            type="default"
            disabled={canAction}
            onClick={() => {
              dispatch(reviewSupplement(true));
            }}
          >
            審核通過
          </Button>
          <Button type="default" onClick={onDuplicateClick}>
            複製
          </Button>
          <Button
            type="primary"
            disabled={supplementDetail.approvalStatus !== VendorApproveStatus.PENDING}
            onClick={() => {
              setShowModal(true);
              setIsEdit(true);
            }}
          >
            編輯
          </Button>
        </Space>
      </Row>

      <Row align="middle">
        <Text>申請狀態</Text>
        <Content>{approveStatusString()}</Content>
      </Row>
      <Row align="middle">
        <Text />

        <table className="review-table" style={{ marginBottom: 33 }}>
          {/* 職位名稱 */}
          <tbody>
            <tr>
              <th>課長</th>
              <th>財務</th>
            </tr>
            <tr>
              <td>{ReviewStateByPositionLabel[supplementDetail.sectionManagerApproval]}</td>
              <td>{ReviewStateByPositionLabel[supplementDetail.financeApproval]}</td>
            </tr>
          </tbody>
        </table>
      </Row>
      <Row align="middle">
        <Text>申請人</Text>
        <Content>{supplementDetail.staff.name}</Content>
      </Row>
      <Row align="middle">
        <Text>期望補單時間</Text>
        <Content>{toMonth(supplementDetail.estimatedSupplementDate)}</Content>
      </Row>
      <Row align="middle">
        <Text>補單緣由</Text>
        <Content>{`${supplementPurposeMap[supplementDetail.supplementPurpose]} ${
          supplementDetail.supplementDescription ?? ""
        }`}</Content>
      </Row>
      <Row align="middle">
        <Text>SKUID</Text>
        <Content>{supplementDetail.sku}</Content>
      </Row>
      <Row align="middle">
        <Text>商品方案ID</Text>
        <Content>{supplementDetail.salesPlanId}</Content>
      </Row>
      <Row align="middle">
        <Text>商品名稱</Text>
        <Content>{supplementDetail.productName}</Content>
      </Row>
      <Row align="middle">
        <Text>廠商名稱</Text>
        <Content>{supplementDetail?.vendor?.vendorName}</Content>
      </Row>
      <Row align="middle">
        <Text>額外增減金額</Text>
        <Content>{supplementDetail.extraAmount}</Content>
      </Row>
      <Row align="middle">
        <Text>申請時間</Text>
        <Content>{supplementDetail.createdAt}</Content>
      </Row>
      <Row align="middle">
        <Text>實際補單時間</Text>
        <Content>{toMonth(supplementDetail.supplementDate)}</Content>
      </Row>
      <EditModal
        isModalVisible={showModal}
        toggleModal={() => setShowModal((prev) => !prev)}
        current={supplementDetail}
        isEdit={isEdit}
      />
    </ContentWrapper>
  );
}
