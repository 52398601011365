import React, { FC, useState, useEffect, ChangeEvent, useMemo } from "react";
import styled from "styled-components";
import { Button, Input, Select, DatePicker } from "antd";
import { SelectValue } from "antd/lib/select";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import { useSelector } from "react-redux";
import { FetchSlotListParam } from "@api/slotApi";
import { updateSlotListParams, fetchSlotList, resetSlotListParams } from "@redux/slotSlice";
import { PageTypeList, SlotPatternList } from "./constant";

const Wrapper = styled.form`
  margin-bottom: 20px;
  padding: 0 56px 0 25px;
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 24px;
  row-gap: 14px;
`;
const Row = styled.div<{ span?: number }>`
  display: flex;
  align-items: center;
  grid-column: ${({ span }) => span || 1} span;
`;
const RowTitle = styled.div<{ width?: number }>`
  margin-right: 12px;
  font-size: 14px;
  line-height: 1.5;
  white-space: nowrap;
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  color: ${({ theme }) => theme.colorNeutral600};
`;
const RowContent = styled.div`
  flex: auto;
`;
const DateRowContent = styled.div`
  width: 380px;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const StyledSelect = styled(Select)`
  width: 200px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 26px;
`;
const ClearFilterButton = styled(Button)`
  margin-right: 8px;
`;

interface LocalListParams {
  limit: number;
  offset: number;
  nameQ?: string;
  pageType: number;
  pageInfo?: string;
  slotPattern: number;
  startAt: Moment | null;
  endAt: Moment | null;
}

const defaultFilter = {
  limit: 20,
  offset: 0,
  pageType: -1,
  slotPattern: -1,
  startAt: null,
  endAt: null,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const slotListParams = useSelector((state: RootState) => state.slot.slotListParams);

  const [localListParams, setLocalListParams] = useState<LocalListParams>(defaultFilter);
  const { nameQ, pageType, pageInfo, slotPattern, startAt, endAt } = localListParams;

  useEffect(() => {
    const localParams: LocalListParams = {
      ...slotListParams,
      startAt: slotListParams.startAt ? moment(slotListParams.startAt) : null,
      endAt: slotListParams.endAt ? moment(slotListParams.endAt) : null,
    };
    setLocalListParams(localParams);
  }, [slotListParams]);

  const pageTypeOptions = useMemo(() => {
    return PageTypeList.map((type) => (
      <Select.Option key={type.id} value={type.id}>
        {type.value}
      </Select.Option>
    ));
  }, []);

  const slotTypeOptions = useMemo(() => {
    return SlotPatternList.map((ptn) => (
      <Select.Option key={ptn.id} value={ptn.id}>
        {ptn.value}
      </Select.Option>
    ));
  }, []);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = e.target;
    setLocalListParams((prev) => ({
      ...prev,
      [inputName]: value,
    }));
  };

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    setLocalListParams((prev) => ({
      ...prev,
      [title]: value as number,
    }));
  };

  const handleOnRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [startDate, endDate] = dates;
      setLocalListParams((prev) => ({
        ...prev,
        startAt: startDate,
        endAt: endDate,
      }));
    } else {
      setLocalListParams((prev) => ({
        ...prev,
        startAt: null,
        endAt: null,
      }));
    }
  };

  const handleOnClearFilter = () => {
    setLocalListParams(defaultFilter);
    dispatch(resetSlotListParams());
    dispatch(fetchSlotList());
  };

  const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const params: FetchSlotListParam = {
      ...localListParams,
      startAt: localListParams.startAt === null ? undefined : moment(localListParams.startAt).format("YYYY-MM-DD"),
      endAt: localListParams.endAt === null ? undefined : moment(localListParams.endAt).format("YYYY-MM-DD"),
    };
    dispatch(updateSlotListParams(params));
    dispatch(fetchSlotList());
  };

  return (
    <Wrapper onSubmit={handleOnSubmit}>
      <Grid>
        <Row>
          <RowTitle>版位名稱</RowTitle>
          <RowContent>
            <Input value={nameQ} name="nameQ" onChange={handleOnChange} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle width={84}>頁面類型</RowTitle>
          <RowContent>
            <StyledSelect value={pageType} onChange={(value) => handleOnSelect("pageType")(value as SelectValue)}>
              {pageTypeOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>頁面資訊</RowTitle>
          <RowContent>
            <Input type="number" value={pageInfo} name="pageInfo" onChange={handleOnChange} />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>版位類型</RowTitle>
          <RowContent>
            <StyledSelect value={slotPattern} onChange={(value) => handleOnSelect("slotPattern")(value as SelectValue)}>
              {slotTypeOptions}
            </StyledSelect>
          </RowContent>
        </Row>
        <Row span={2}>
          <RowTitle width={84}>版位上架時間</RowTitle>
          <DateRowContent>
            <RangePicker value={[startAt, endAt]} onCalendarChange={handleOnRangePick} />
          </DateRowContent>
        </Row>
      </Grid>
      <Footer>
        <ClearFilterButton onClick={handleOnClearFilter}>清除篩選條件</ClearFilterButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default Filter;
