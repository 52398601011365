import { FilterTwoTone, FormOutlined, PlusCircleFilled } from "@ant-design/icons";
import { ProductListItem } from "@api/productApi";
import { SalesChannelType } from "@api/vendorApi";
import PageTitle from "@component/PageTitle";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";
import {
  exportProductVendorsByCSV,
  fetchProductList,
  fetchTags,
  openImportCsvPopup,
  reset,
  updateProductListFilter,
  updateSelectedProductIds,
} from "@redux/productSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Col, Pagination, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { FC, ReactText, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import BatchActivePopup from "./BatchActivePopup";
import Filter, { syncMethodList } from "./Filter";
import ImportCSV from "./ImportCSV";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  padding: 10px 0 20px 0;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const EditStatusIcon = styled(FormOutlined)<{ disabled: boolean }>`
  margin-left: 5px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

const pageSizeOptions = [20, 50, 100];

const Product: FC = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { isFetchingList, productListResult, productListFilter, selectedProductIds, showImportCsvPopup } = useSelector(
    (state: RootState) => state.product,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showBatchActivePopup, setShowBatchActivePopup] = useState<boolean>(false);

  const { count, results: productList } = productListResult;
  const { limit, offset, salesChannel } = productListFilter;
  const salesChannelOptions = useSalesChannelOptions();
  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    const brandName = searchParams.get("brand");
    // 導頁帶brand
    if (brandName) {
      dispatch(
        updateProductListFilter({
          brand: brandName,
          limit: 20,
          offset: 0,
        }),
      );
      dispatch(fetchProductList());
    } else {
      dispatch(fetchProductList());
    }

    return () => {
      dispatch(reset());
    };
  }, [dispatch, searchParams]);

  useEffect(() => {
    dispatch(fetchTags({}));
  }, [dispatch]);

  const toggleFilter = () => setShowFilter((prev) => !prev);
  const toggleBatchActivePopup = useCallback(() => setShowBatchActivePopup((prev) => !prev), []);

  const openImportPopup = () => dispatch(openImportCsvPopup());

  const handleOnAdd = () => {
    window.open("/product/add", "_blank");
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedProductIds(values as number[]));
  };
  const handelUpdateSingleFilter = (key: string, value: any) => {
    const filter = {
      ...productListFilter,
      [key]: value,
    };

    dispatch(updateProductListFilter(filter));
    dispatch(fetchProductList());
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    handelUpdateSingleFilter("limit", value as number);
  };

  const handleOnPageChange = (page: number) => {
    handelUpdateSingleFilter("offset", (page - 1) * limit);
  };

  const handleChannelChange = (value: any) => {
    handelUpdateSingleFilter("salesChannel", value === SalesChannelType.URMART ? undefined : value);
  };

  const handleOnExport = () => dispatch(exportProductVendorsByCSV());

  const tableColumns: ColumnsType<any> = useMemo(() => {
    return [
      {
        title: <TableNoWrap>ID</TableNoWrap>,
        dataIndex: "id",
        render: (value: ProductListItem["id"]) => (
          <Link href={`/product/edit/${value}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: <TableNoWrap>SKU</TableNoWrap>,
        dataIndex: "sku",
      },
      {
        title: <TableNoWrap>商開負責人</TableNoWrap>,
        dataIndex: ["owner", "name"],
        render: (value: ProductListItem["owner"]["name"]) => value || "N/A",
        width: 90,
      },
      {
        title: (
          <TableNoWrap>
            狀態
            <EditStatusIcon onClick={toggleBatchActivePopup} disabled={selectedProductIds.length === 0} />
          </TableNoWrap>
        ),
        dataIndex: "isActive",
        width: 70,
        render: (value: ProductListItem["isActive"]) => (value ? "上架" : "下架"),
      },
      {
        title: <TableNoWrap>同步至倉庫</TableNoWrap>,
        key: "isSyncWarehouse",
        dataIndex: "isSyncWarehouse",
        width: 90,
        render: (value: ProductListItem["isSyncWarehouse"]) => (value ? "是" : "否"),
      },
      {
        title: <TableNoWrap>倉庫資訊同步方式</TableNoWrap>,
        dataIndex: "syncStockOption",
        width: 140,
        render: (value: ProductListItem["syncStockOption"]) => {
          const syncOption = syncMethodList.find((mtd) => mtd.value === value);
          return syncOption?.label || "N/A";
        },
      },
      {
        title: <TableNoWrap>商品名稱</TableNoWrap>,
        dataIndex: "name",
        render: (value: ProductListItem["name"], data: ProductListItem) => (
          <Link href={`/product/edit/${data.id}`} target="_blank">
            {value}
          </Link>
        ),
      },
      {
        title: <TableNoWrap>品牌</TableNoWrap>,
        dataIndex: ["brand", "name"],
        render: (value: ProductListItem["brand"]["name"]) => value || "N/A",
        width: 100,
      },
      {
        title: <TableNoWrap>可賣量</TableNoWrap>,
        dataIndex: "canSalesQty",
        width: 70,
        render: (value: ProductListItem["canSalesQty"]) => value || "N/A",
      },
      {
        title: <TableNoWrap>總效期(天)</TableNoWrap>,
        dataIndex: "shelfLife",
        width: 80,
        render: (value: ProductListItem["shelfLife"]) => value || "N/A",
      },
      {
        title: <TableNoWrap>廠商</TableNoWrap>,
        dataIndex: ["vendorInfo", "name"],
        render: (value: ProductListItem["vendorInfo"]["name"]) => value || "N/A",
        width: 100,
      },
      {
        title: <TableNoWrap>合作模式</TableNoWrap>,
        dataIndex: ["vpcInfo", "contractInfo", "modeName"],
        render: (value: ProductListItem["vpcInfo"]["contractInfo"]["modeName"]) => value || "N/A",
        width: 90,
      },
      {
        title: <TableNoWrap>成本</TableNoWrap>,
        dataIndex: "weightedCost",
        width: 70,
      },
      {
        title: <TableNoWrap>售價</TableNoWrap>,
        dataIndex: ["vpcInfo", "price"],
        render: (value: ProductListItem["vpcInfo"]["price"]) => value || "N/A",
        width: 70,
      },
      {
        title: <TableNoWrap>抽成</TableNoWrap>,
        dataIndex: ["vpcInfo", "commissionRate"],
        width: 50,
        render: (value: ProductListItem["vpcInfo"]["commissionRate"]) => value || "N/A",
      },
      {
        title: <TableNoWrap>箱入數</TableNoWrap>,
        dataIndex: ["vpcInfo", "pcsPerCarton"],
        width: 70,
        render: (value: ProductListItem["vpcInfo"]["pcsPerCarton"]) => value || "N/A",
      },
      {
        title: <TableNoWrap>日平均銷量</TableNoWrap>,
        key: "avgDailySalesVolume",
        dataIndex: "avgDailySalesVolume",
        width: 120,
      },
      {
        title: <TableNoWrap>總迴轉天數</TableNoWrap>,
        key: "totalTurnoverDays",
        dataIndex: "totalTurnoverDays",
        width: 70,
      },
      {
        title: "",
        key: "",
        dataIndex: "",
        fixed: "right",
        render: (value: any, data: ProductListItem) => (
          <Link href={`/product/edit/${data.id}`} target="_blank">
            編輯
          </Link>
        ),
      },
    ];
  }, [selectedProductIds, toggleBatchActivePopup]);

  return (
    <Wrapper>
      <PageTitle title="商品" />
      <Header>
        <Row justify="space-between" gutter={[0, 36]}>
          <TopButtonContainer>
            <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
              新增商品
            </TopButton>
            <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
              篩選
            </TopButton>
            <TopButton onClick={openImportPopup}>匯入</TopButton>
            <TopButton onClick={handleOnExport}>匯出</TopButton>
          </TopButtonContainer>
          <TablePageInfo>
            總共
            {count}
            筆, 每頁顯示
            <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
              {pageSizeOptions.map((option) => (
                <Select.Option value={option}>{option}</Select.Option>
              ))}
            </PageSelect>
            筆
          </TablePageInfo>
        </Row>
        <Row align="middle" gutter={[16, 0]}>
          <Col>通路 :</Col>
          <Col>
            <Select
              style={{ width: 200 }}
              onChange={handleChannelChange}
              options={salesChannelOptions}
              defaultValue={salesChannel}
            />
          </Col>
        </Row>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          dataSource={productList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedProductIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
          rowKey="id"
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
      {showImportCsvPopup && <ImportCSV />}
      <BatchActivePopup visible={showBatchActivePopup} onClose={toggleBatchActivePopup} />
    </Wrapper>
  );
};

export default Product;
