import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import discountFrameApi, {
  CreateFrameInfoParams,
  DiscountFrameInfo,
  DiscountFrameListResult,
  DiscountFramePlanListResult,
  DiscountModule,
  FetchDiscountFrameListParam,
  SalePlanListParams,
  SalePlanListResult,
  TabListParams,
  TabSearchListParams,
} from "@api/discountFrameApi";
import { message } from "antd";

interface IState {
  isFetching: boolean;
  discountFrameListResult: DiscountFrameListResult;
  discountFrameListParam: FetchDiscountFrameListParam;
  selectedFrameIds: number[];
  discountFrameDetail: {
    frameId: number;
    discountFrameInfo: DiscountFrameInfo;
  };
  planList: DiscountFramePlanListResult;
  planListParam: TabListParams;
  selectedPlanIds: number[];
  salePageList: DiscountFramePlanListResult;
  salePageListParam: TabListParams;
  selectedSalePageIds: number[];
  showImportCsvPopup: boolean;
  csvErrorMessage: string;
  searchOptions: string[];
  salePlanListResult: SalePlanListResult;
  salePlanListParam: SalePlanListParams;
}

const initialState: IState = {
  isFetching: false,
  discountFrameListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  discountFrameListParam: {
    limit: 20,
    offset: 0,
  },
  selectedFrameIds: [],
  discountFrameDetail: {
    frameId: -1,
    discountFrameInfo: {
      id: -1,
      title: "",
      imagePath: "",
      startAt: "",
      endAt: "",
      mediaId: -1,
    },
  },
  planList: { count: 0, next: "", previous: "", results: [] },
  planListParam: { module: "salesplan", limit: 20, offset: 0 },
  selectedPlanIds: [],
  salePageList: { count: 0, next: "", previous: "", results: [] },
  salePageListParam: { module: "salespage", limit: 20, offset: 0 },
  selectedSalePageIds: [],
  showImportCsvPopup: false,
  csvErrorMessage: "",
  searchOptions: [],
  salePlanListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  salePlanListParam: {
    limit: 20,
    offset: 0,
  },
};

export const fetchDiscountFrameList = createAsyncThunk("discountFrame/fetchFrameList", async (_, thunkApi) => {
  const {
    discountFrameSlice: { discountFrameListParam },
  } = thunkApi.getState() as RootState;
  try {
    const response = await discountFrameApi.fetchDiscountFrameList(discountFrameListParam);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchDiscountFrameInfo = createAsyncThunk(
  "discountFrame/fetchFrameInfo",
  async (frameId: number, thunkApi) => {
    try {
      const response = await discountFrameApi.fetchDiscountFrameInfo(frameId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const batchDeleteDiscountFrame = createAsyncThunk("discountFrame/batchDeleteFrame", async (_, thunkApi) => {
  const {
    discountFrameSlice: { selectedFrameIds },
  } = thunkApi.getState() as RootState;
  try {
    const response = await discountFrameApi.batchDeleteDiscountFrame(selectedFrameIds);

    if (response.status_code === 2000) {
      message.success("批次進行中，成功會發送通知");
    }

    thunkApi.dispatch(updateSelectedFrameIds([]));
    thunkApi.dispatch(fetchDiscountFrameList());
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const createDiscountFrame = createAsyncThunk(
  "discountFrame/createDiscountFrame",
  async (payload: CreateFrameInfoParams, thunkApi) => {
    try {
      const response = await discountFrameApi.createDiscountFrame(payload);
      message.success("新增成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const updateDiscountFrameInfo = createAsyncThunk(
  "discountFrame/updateDiscountFrame",
  async (payload: CreateFrameInfoParams, thunkApi) => {
    const {
      discountFrameSlice: {
        discountFrameDetail: { frameId },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await discountFrameApi.updateDiscountFrameInfo(frameId, payload);
      message.success("儲存成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchPlanList = createAsyncThunk("discountFrame/fetchPlanList", async (frameId: number, thunkApi) => {
  const {
    discountFrameSlice: { planListParam },
  } = thunkApi.getState() as RootState;
  try {
    const response = await discountFrameApi.fetchTabList(frameId, planListParam);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const updateDiscountTabList = createAsyncThunk(
  "discountFrame/updateDiscountTabList",
  async (params: { objectId: number; module: string }, thunkApi) => {
    try {
      const {
        discountFrameSlice: {
          discountFrameDetail: { frameId },
        },
      } = thunkApi.getState() as RootState;

      const response = await discountFrameApi.updateDiscountTabList(frameId, params);
      message.success("新增成功");

      switch (params.module) {
        case DiscountModule.PLAN:
          thunkApi.dispatch(fetchPlanList(frameId));
          break;
        case DiscountModule.SALEPAGE:
          thunkApi.dispatch(fetchSalePageList());
          break;
        default:
      }
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const batchUpdateDiscountTabList = createAsyncThunk(
  "discountFrame/batchUpdateDiscountTabList",
  async (params: { objectIds: number[]; module: string }, thunkApi) => {
    try {
      const {
        discountFrameSlice: {
          discountFrameDetail: { frameId },
        },
      } = thunkApi.getState() as RootState;

      const response = await discountFrameApi.batchUpdateDiscountTabList(frameId, params);
      message.success("新增進行中，綁訂結果將寄信通知");

      switch (params.module) {
        case DiscountModule.PLAN:
          thunkApi.dispatch(fetchPlanList(frameId));
          break;
        case DiscountModule.SALEPAGE:
          thunkApi.dispatch(fetchSalePageList());
          break;
        default:
      }
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const batchAddPlanByCSV = createAsyncThunk("discountFrame/batchAddPlanByCSV", async (file: File, thunkApi) => {
  const {
    discountFrameSlice: {
      discountFrameDetail: { frameId },
    },
  } = thunkApi.getState() as RootState;
  const payload = { module: DiscountModule.PLAN, file, frameId };

  try {
    message.warn("匯入進行中，成功會發送通知");
    const response = await discountFrameApi.batchAddByCSV(payload);
    thunkApi.dispatch(toggleImportCsvPopup(false));
    return response;
  } catch (error: any) {
    throw thunkApi.rejectWithValue("error");
  }
});

export const batchDeletePlan = createAsyncThunk("discountFrame/batchDeletePlan", async (frameId: number, thunkApi) => {
  const {
    discountFrameSlice: { selectedPlanIds },
  } = thunkApi.getState() as RootState;
  try {
    const response = await discountFrameApi.batchDeleteTabList(frameId, DiscountModule.PLAN, selectedPlanIds);

    if (response.status_code === 2000) {
      message.success("批次進行中，成功會發送通知");
    }
    thunkApi.dispatch(updateSelectedPlanIds([]));
    thunkApi.dispatch(fetchPlanList(frameId));
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue("error");
  }
});

export const fetchSalePageList = createAsyncThunk("discountFrame/fetchSalePageList", async (_, thunkApi) => {
  const {
    discountFrameSlice: {
      salePageListParam,
      discountFrameDetail: { frameId },
    },
  } = thunkApi.getState() as RootState;
  try {
    const response = await discountFrameApi.fetchTabList(frameId, salePageListParam);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const batchDeleteSalePage = createAsyncThunk(
  "discountFrame/batchDeleteSalePage",
  async (activityId: number, thunkApi) => {
    const {
      discountFrameSlice: { selectedSalePageIds },
    } = thunkApi.getState() as RootState;
    const response = await discountFrameApi.batchDeleteTabList(
      activityId,
      DiscountModule.SALEPAGE,
      selectedSalePageIds,
    );

    if (response.status_code === 2000) {
      message.success("批次進行中，成功會發送通知");
    }

    thunkApi.dispatch(updateSelectedSalePageIds([]));
    thunkApi.dispatch(fetchSalePageList());
    return response;
  },
);

export const batchAddSalePageByCSV = createAsyncThunk(
  "discountFrame/batchAddPlanByCSV",
  async (file: File, thunkApi) => {
    const {
      discountFrameSlice: {
        discountFrameDetail: { frameId },
      },
    } = thunkApi.getState() as RootState;
    const payload = { module: DiscountModule.SALEPAGE, file, frameId };

    try {
      message.warn("匯入進行中，成功會發送通知");
      const response = await discountFrameApi.batchAddByCSV(payload);
      thunkApi.dispatch(toggleImportCsvPopup(false));

      return response;
    } catch (error: any) {
      throw thunkApi.rejectWithValue("error");
    }
  },
);

export const fetchSearchOptionsList = createAsyncThunk(
  "discountFrame/fetchSearchOptionsList",
  async (searchParams: TabSearchListParams, thunkApi) => {
    const {
      discountFrameSlice: {
        discountFrameDetail: { frameId },
      },
    } = thunkApi.getState() as RootState;
    try {
      const params = { ...searchParams, limit: 0, offset: 0 };
      const response = await discountFrameApi.fetchTabList(frameId, params);
      const filterOptions = response.results.map((item) => item.objectName);
      return filterOptions;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchSalePlanListResult = createAsyncThunk(
  "discountFrame/fetchSalePlanListResult",
  async (_, thunkApi) => {
    const {
      discountFrameSlice: { salePlanListParam },
    } = thunkApi.getState() as RootState;
    try {
      const response = await discountFrameApi.fetchSalePlanListResult(salePlanListParam);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const discountFrameSlice = createSlice({
  name: "discountFrame",
  initialState,
  reducers: {
    reset: () => initialState,
    resetDiscountFrameListParam: (state) => {
      state.discountFrameListParam = initialState.discountFrameListParam;
    },
    updateDiscountFrameListParam: (state, action: PayloadAction<FetchDiscountFrameListParam>) => {
      state.discountFrameListParam = action.payload;
    },
    updateSelectedFrameIds: (state, action: PayloadAction<number[]>) => {
      state.selectedFrameIds = action.payload;
    },
    updateSelectedPlanIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPlanIds = action.payload;
    },
    toggleImportCsvPopup: (state, action) => {
      state.showImportCsvPopup = action.payload;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
    updatePlanListParam: (state, action: PayloadAction<TabListParams>) => {
      state.planListParam = action.payload;
    },
    resetPlanListParam: (state) => {
      state.planListParam = {
        ...state.planListParam,
        objectId: undefined,
        objectName: undefined,
      };
    },
    updateSalePageListParam: (state, action: PayloadAction<TabListParams>) => {
      state.salePageListParam = action.payload;
    },
    resetSalePageListParam: (state) => {
      state.salePageListParam = {
        ...state.salePageListParam,
        objectId: undefined,
        objectName: undefined,
      };
    },
    updateSelectedSalePageIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSalePageIds = action.payload;
    },
    resetSearchOptions: (state) => {
      state.searchOptions = [];
    },
    updateSalePlanListParam: (state, action) => {
      state.salePlanListParam = action.payload;
    },
    resetSalePlanListParam: (state) => {
      state.salePlanListParam = initialState.salePlanListParam;
    },
    resetSalePlanList: (state) => {
      state.salePlanListResult = initialState.salePlanListResult;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDiscountFrameList.pending, (state) => {
      state.isFetching = true;
      state.discountFrameListResult = {
        count: 0,
        previous: "",
        next: "",
        results: [],
      };
    });
    builder.addCase(fetchDiscountFrameList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.discountFrameListResult = action.payload;
    });
    builder.addCase(createDiscountFrame.fulfilled, (state, action) => {
      state.isFetching = false;
      state.discountFrameDetail = { ...state.discountFrameDetail, frameId: action.payload.id };
    });
    builder.addCase(fetchDiscountFrameInfo.fulfilled, (state, action) => {
      state.isFetching = false;
      state.discountFrameDetail = { frameId: action.payload.id, discountFrameInfo: action.payload };
    });
    builder.addCase(fetchPlanList.pending, (state) => {
      state.isFetching = true;
      state.discountFrameListResult = {
        count: 0,
        previous: "",
        next: "",
        results: [],
      };
    });
    builder.addCase(fetchPlanList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.planList = action.payload;
    });
    builder.addCase(updateDiscountTabList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(updateDiscountTabList.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchSalePageList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.salePageList = action.payload;
    });
    builder.addCase(fetchSearchOptionsList.fulfilled, (state, action) => {
      state.searchOptions = action.payload;
    });
    builder.addCase(batchAddPlanByCSV.fulfilled, (state) => {
      state.csvErrorMessage = "";
    });
    builder.addCase(batchAddPlanByCSV.rejected, (state, action) => {
      state.csvErrorMessage = "匯入失敗，請重新上傳";
    });
    builder.addCase(fetchSalePlanListResult.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchSalePlanListResult.fulfilled, (state, action) => {
      state.isFetching = false;
      state.salePlanListResult = action.payload;
    });
    builder.addCase(fetchSalePlanListResult.pending, (state) => {
      state.isFetching = true;
    });
  },
});
export const DiscountFrameState = (state: RootState) => state.discountFrameSlice;
export const {
  reset,
  resetDiscountFrameListParam,
  updateDiscountFrameListParam,
  updateSelectedFrameIds,
  updateSelectedPlanIds,
  toggleImportCsvPopup,
  clearCSVErrorMessage,
  updatePlanListParam,
  resetPlanListParam,
  updateSalePageListParam,
  resetSalePageListParam,
  updateSelectedSalePageIds,
  resetSearchOptions,
  updateSalePlanListParam,
  resetSalePlanListParam,
  resetSalePlanList,
} = discountFrameSlice.actions;

export default discountFrameSlice.reducer;
