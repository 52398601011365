import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddTagAttributePayload } from "@api/attributeApi";
import {
  attributeState,
  fetchAddTagAttribute,
  fetchTagAttributeInfo,
  fetchUpdateTagAttribute,
} from "@redux/attributeSlice";
import { Button, Form, Input, message, Modal, Select, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled(Form)`
  padding: 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
`;
const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;

export default function AttributeTab() {
  const dispatch = useDispatch();
  const { isFetching, tagAttributeInfo, addAttributeId } = useSelector(attributeState);

  const navigate = useNavigate();
  const { attributeId } = useParams();
  const intAttributeId = Number(attributeId);
  const [form] = Form.useForm();

  const needResetData = useRef<boolean>(false);
  const [tagsNameSet, setTagsNameSet] = useState<Set<string>>(new Set());
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onSubmit = (values: unknown) => {
    if (attributeId) {
      if (showDeleteModal) {
        Modal.confirm({
          title: "確認要刪除此多個屬性？",
          content: "以下操作將移除相關商品 / 銷售頁上所有紀錄",
          icon: <ExclamationCircleOutlined />,
          okText: "是",
          cancelText: "否",
          onOk() {
            dispatch(fetchUpdateTagAttribute({ ...(values as AddTagAttributePayload), attributeId: intAttributeId }));
          },
        });
      } else {
        dispatch(fetchUpdateTagAttribute({ ...(values as AddTagAttributePayload), attributeId: intAttributeId }));
      }
    } else {
      dispatch(fetchAddTagAttribute(values as AddTagAttributePayload));
    }
  };

  useEffect(() => {
    if (addAttributeId) {
      navigate(`/attribute-management/edit/${addAttributeId}`);
    }
  }, [navigate, addAttributeId]);

  useEffect(() => {
    if (attributeId) {
      dispatch(fetchTagAttributeInfo(intAttributeId));
    }
  }, [dispatch, attributeId, intAttributeId]);

  useEffect(() => {
    if (tagAttributeInfo) {
      form.setFieldsValue({
        name: tagAttributeInfo.name,
        tagsName: tagAttributeInfo.tagsName,
      });

      const newSet = new Set(tagAttributeInfo.tagsName);
      setTagsNameSet(newSet);
    }
  }, [tagAttributeInfo, form]);

  return (
    <Spin spinning={isFetching}>
      <Wrapper form={form} colon={false} labelCol={{ span: 3 }} labelAlign="left" onFinish={onSubmit}>
        <Form.Item
          wrapperCol={{ span: 7 }}
          label="屬性類型名稱"
          name="name"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ setFieldsValue }) => (
            <Form.Item label="屬性" name="tagsName" rules={[{ required: true, message: "必填" }]}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                onSelect={(tag) => {
                  const newSet = new Set(tagsNameSet);
                  newSet.add(tag);
                  setTagsNameSet(newSet);
                }}
                onDeselect={(tag: string) => {
                  if (needResetData.current) {
                    setFieldsValue({
                      tagsName: Array.from(tagsNameSet),
                    });
                    message.error(`${tag}已建立`);
                    needResetData.current = false;
                  } else {
                    setShowDeleteModal(true);
                  }
                }}
                onInputKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    needResetData.current = true;
                  }
                }}
              />
            </Form.Item>
          )}
        </Form.Item>
        <Footer>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              儲存此分頁
            </Button>
          </Form.Item>
        </Footer>
      </Wrapper>
    </Spin>
  );
}
