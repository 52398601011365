import React, { FC, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Input, Button } from "antd";
import ruleApi from "@api/ruleApi";
import { useParams } from "react-router-dom";
import PopupBackground from "@component/PopupBackground";
import { useAppDispatch } from "src/store";
import { fetchMemberList } from "@redux/ruleSlice";

interface Props {
  onClose: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 600px;
  height: 190px;
  padding: 32px 28px 20px 28px;
  background-color: white;
`;
const Body = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 58px;
`;
const Red = styled.span`
  color: red;
`;
const PhoneInput = styled(Input)<{ error: boolean }>`
  width: 395px;
  ${({ error }) =>
    error &&
    `
    border: 1px solid red;
  `}
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;
const ErrorText = styled.div`
  font-size: 13px;
  color: #ec6922;
`;

const checkIsPhone = (value: string) => {
  return /^[0]{1}[9]{1}[0-9]{8}$/.test(value);
};

const AddMember: FC<Props> = (props) => {
  const { onClose } = props;
  const { id: ruleId } = useParams();
  const dispatch = useAppDispatch();

  const [phoneInput, setPhoneInput] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleOnChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setPhoneInput(value);
  };

  const handleOnCheck = () => {
    const isPhoneValid = checkIsPhone(phoneInput);

    if (isPhoneValid) {
      setErrorMessage("");
    } else {
      setErrorMessage("格式有誤");
    }
  };

  const handleOnAdd = async () => {
    if (!errorMessage) {
      try {
        await ruleApi.addMemberToRule(Number(ruleId), phoneInput);
        dispatch(fetchMemberList(Number(ruleId)));
        onClose();
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    }
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Body>
          <Title>
            電話
            <Red>*</Red>
          </Title>
          <PhoneInput value={phoneInput} onChange={handleOnChangePhone} onBlur={handleOnCheck} error={!!errorMessage} />
        </Body>
        <Footer>
          <ErrorText>{errorMessage}</ErrorText>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" onClick={handleOnAdd}>
              新增
            </Button>
          </ButtonContainer>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default AddMember;
