import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

interface CostApi {
  fetchCostList: (params: CostFilter) => Promise<CostList>;
  fetchCheckProduct: (params: {
    skuNo: string;
    type: number;
  }) => Promise<{ id?: number | undefined; name: string; originCost?: number | undefined }>;
  fetchRecostDetail: (id: number) => Promise<any>;
  fetchProcess: (payload: { processType: number; id: number }) => Promise<void>;
  fetchUploadCost: (file: File) => Promise<CostList>;
  fetchCreatCost: (params: CostResult) => Promise<any>;
  fetchUpdateCost: (payload: { params: CostResult; id: number }) => Promise<CostList>;
  fetchDeleteCost: (id: number) => Promise<CostResult[]>;
}

export interface BrandFilter {
  nameQ?: string;
}
export interface CostList {
  count: number;
  next: string | null;
  previous: string | null;
  results: CostResult[];
}
export interface CostFilter {
  status?: number;
  type?: number;
  skuNo?: string;
  limit: number;
}

export interface CostResult {
  id?: number;
  staff: {
    id: number;
    name: string;
  };
  skuNo: string;
  type: number;
  status: number;
  originCost: number;
  afterCost: number;
  startAt: string;
  endAt: string;
}

export interface CostDetail {
  id?: number;
  staff: {
    id: number;
    name: string;
  };
  type: number;
  status: number;
  skuNo: string;
  name: string;
  originCost?: number;
  afterCost?: number;
  startAt: string;
  endAt: string;
  description: string;
  affectOrders?: number;
  affectQty?: number;
  diffMarginRatio?: number;
  diffCost?: number;
  submittedAt: string;
  approvedAt: string;
  createdAt: string;
  updateAt: string;
}

const costApi: CostApi = {
  fetchCostList: async (params) => {
    const response = await apiClient.getData("/manage/recost/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCheckProduct: async (params) => {
    const response = await apiClient.postData("/manage/recost/check/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRecostDetail: async (id: number) => {
    const response = await apiClient.getData(`/manage/recost/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProcess: async (payload) => {
    const param = {
      processType: payload.processType,
    };
    await apiClient.postData(`/manage/recost/${payload.id}/process/`, transformCamelToSnake(param));
  },

  fetchUploadCost: async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.postData("/manage/recost/upload/", formData);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreatCost: async (params) => {
    const response = await apiClient.postData("/manage/recost/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateCost: async (payload) => {
    const response = await apiClient.putData(`/manage/recost/${payload.id}/`, transformCamelToSnake(payload.params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDeleteCost: async (id) => {
    const response = await apiClient.deleteData(`/manage/recost/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default costApi;
