import { Spin } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(54, 54, 54, 0.6);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export default function Loading() {
  return ReactDOM.createPortal(
    <Wrapper>
      <Spin size="large" />
    </Wrapper>,
    document.body,
  );
}
