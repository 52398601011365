/* eslint-disable camelcase */
import CoreAPI from "../CoreAPI";
import normalizeCustomsClearance, { CustomsClearances, TransportMode } from "../utils/normalizeCustomsClearance";
import { Currency } from "../utils/normalizeWarehouse";

export interface FetchCustomsClearanceList {
  tariffNumber?: string;
  limit: number;
  page: number;
}

export interface FetchCreateCustomsClearances {
  vendor: number;
  currency: Currency;
  currencyRate: number;
  tariffNumber: string;
  tariff: number;
  shippingFee: number;
  processingFee: number;
  transportMode: TransportMode;
}

export interface FetchUpdateCustomsClearances extends FetchCreateCustomsClearances {
  customsClearancesId: number;
}

export interface FetchCustomsClearancesPurchaseAdd {
  customsClearancesId: number;
  stoNumbers: string[];
  abnormalCost?: boolean;
}

export interface FetchCustomsClearancesPurchaseRemove {
  customsClearancesId: number;
  purchaseId: number;
  stoNumber: string;
}

export interface FetchBatchUpdatePurchaseLine {
  id: number;
  stoNumber: string;
  [field: string]: string | number;
}

interface CustomsClearanceAPI {
  fetchCustomsClearancesList: (
    filterOptions: FetchCustomsClearanceList,
  ) => Promise<{ count: number; next: string; previous: string; results: CustomsClearances[] }>;
  fetchDeleteCustomsClearances: (customsClearancesId: number) => Promise<void>;
  fetchCreateCustomsClearances: (payload: FetchCreateCustomsClearances) => Promise<void>;
  fetchCustomsClearances: (customsClearancesId: number) => Promise<CustomsClearances>;
  fetchUpdateCustomsClearances: (payload: FetchUpdateCustomsClearances) => Promise<void>;
  fetchCustomsClearancesPurchaseAdd: (payload: FetchCustomsClearancesPurchaseAdd) => Promise<void>;
  fetchCustomsClearancesPurchaseRemove: (payload: FetchCustomsClearancesPurchaseRemove) => Promise<void>;
  fetchCustomsClearancesRecalculate: (customsClearancesId: number) => Promise<void>;
  fetchCustomsClearancesApprove: (customsClearancesId: number) => Promise<void>;
  fetchBatchUpdatePurchaseLine: (payload: FetchBatchUpdatePurchaseLine[]) => Promise<void>;
}

const apiClient = new CoreAPI();

const CustomsClearanceApi: CustomsClearanceAPI = {
  fetchCustomsClearancesList: async (filterOptions) => {
    const response = await apiClient.getData("/manage/purchase/customs-clearances/", {
      tariff_number: filterOptions.tariffNumber,
      limit: filterOptions.limit,
      offset: (filterOptions.page - 1) * filterOptions.limit,
    });
    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: normalizeCustomsClearance.customsClearances(response.data.result.results),
    };
  },
  fetchDeleteCustomsClearances: async (customsClearancesId) => {
    await apiClient.deleteData(`/manage/purchase/customs-clearances/${customsClearancesId}/`, {});
  },
  fetchCreateCustomsClearances: async (payload) => {
    await apiClient.postData("/manage/purchase/customs-clearances/", {
      vendor: payload.vendor,
      currency: payload.currency,
      currency_rate: payload.currencyRate,
      tariff_number: payload.tariffNumber,
      tariff: payload.tariff,
      shipping_fee: payload.shippingFee,
      processing_fee: payload.processingFee,
      transport_mode: payload.transportMode,
    });
  },
  fetchCustomsClearances: async (customsClearancesId) => {
    const response = await apiClient.getData(`/manage/purchase/customs-clearances/${customsClearancesId}/`, {});
    return normalizeCustomsClearance.customsClearancesInfo(response.data.result);
  },
  fetchUpdateCustomsClearances: async (payload) => {
    await apiClient.patchData(`/manage/purchase/customs-clearances/${payload.customsClearancesId}/`, {
      vendor: payload.vendor,
      currency: payload.currency,
      currency_rate: payload.currencyRate,
      tariff_number: payload.tariffNumber,
      tariff: payload.tariff,
      shipping_fee: payload.shippingFee,
      processing_fee: payload.processingFee,
      transport_mode: payload.transportMode,
    });
  },
  fetchCustomsClearancesPurchaseAdd: async (payload) => {
    await apiClient.postData(`/manage/purchase/customs-clearances/${payload.customsClearancesId}/purchases/add/`, {
      sto_numbers: payload.stoNumbers,
    });
  },
  fetchCustomsClearancesPurchaseRemove: async (payload) => {
    await apiClient.putData(
      `/manage/purchase/customs-clearances/${payload.customsClearancesId}/purchases/${payload.purchaseId}/remove/?sto_number=${payload.stoNumber}`,
      {},
    );
  },
  fetchCustomsClearancesRecalculate: async (customsClearancesId) => {
    await apiClient.putData(`/manage/purchase/customs-clearances/${customsClearancesId}/re-calculate/`, {});
  },
  fetchCustomsClearancesApprove: async (customsClearancesId) => {
    await apiClient.putData(`/manage/purchase/customs-clearances/${customsClearancesId}/approve/`, {});
  },
  fetchBatchUpdatePurchaseLine: async (payload) => {
    const mappingPayload = payload.map((item) => ({
      id: item.id,
      rebate: item.rebate,
      sto_number: item.stoNumber,
      processing_fee: item.processingFee,
      customs_clearance_fee: item.customsClearanceFee,
      tariff_rate: item.tariffRate,
    }));
    await apiClient.putData("/manage/purchase/purchase-lines/batch-update/", {
      purchase_lines_data: mappingPayload,
    });
  },
};

export default CustomsClearanceApi;
