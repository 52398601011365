import downloadCSV from "@utils/downloadCSV";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { PromotionDetail } from "./promotionApi";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface IRuleApi {
  fetchRuleList: (params: FetchRuleListParams) => Promise<RuleListResult>;
  batchDeleteRules: (params: number[]) => Promise<StandardResponse>;
  updateRuleIsActive: (id: number, isActive: boolean) => Promise<RuleDetail>;
  fetchRuleDetail: (ruleId: number) => Promise<RuleDetail>;
  createSingleSalePromotion: (ruleId: number, salePageId: number) => Promise<CreateSingleSalePromotionResponse>;
  createRule: (params: CreateRuleParams) => Promise<RuleDetail>;
  updateRule: (ruleId: number, params: UpdateRuleParams) => Promise<RuleDetail>;
  createCoupons: (params: CreateCouponParams, customSetup: boolean) => Promise<CouponResponse>;
  updateCoupon: (params: { couponId: number; code: string }) => Promise<CouponResponse>;
  deleteCoupon: (couponId: number) => Promise<void>;
  exportCouponsByCSV: (ruleId: number) => Promise<void>;
  fetchMemberList: (ruleId: number, params: MemberListParams) => Promise<MemberListResult>;
  addMemberToRule: (ruleId: number, phone: string) => Promise<MemberListItem[]>;
  batchDeleteMember: (ruleId: number, memberIds: number[]) => Promise<MemberListItem[]>;
  batchAddMembersByCSV: (ruleId: number, file: File) => Promise<StandardResponse>;
  deleteSalePlanFromRule: (ruleId: number, planId: number) => Promise<StandardResponse>;
  addSalePlanToRule: (ruleId: number, params: AddSalePlanToRuleParam) => Promise<StandardResponse>;
  fetchCloneSalePlan: (ruleId: number, planIds: number[]) => Promise<void>;
}

export enum CouponMethodValue {
  SINGLE = 1,
  RANDOM = 2,
  NONE = 3,
}
export enum CustomerTypeValue {
  ALL = -1,
  NEW_CUSTOMER = 1,
  OLD_CUSTOMER = 2,
}
export enum PlatformValue {
  ALL = -1,
  WEB = 1,
  APP = 2,
}
export enum NotifyTypeValue {
  BIND_NOTIFY = 1,
  EXPIRE_NOTIFY = 2,
  BOTH = 3,
}
export enum BindTypeValue {
  NO_LIMIT = 1,
  ONE_TIME = 2,
  CYCLE_IN_DAY = 3,
  CYCLE_IN_WEEK = 4,
  CYCLE_IN_MONTH = 5,
}
export enum UseTypeValue {
  NO_LIMIT = 1,
  ONE_TIME = 2,
  CYCLE_IN_DAY = 3,
  CYCLE_IN_WEEK = 4,
  CYCLE_IN_MONTH = 5,
  HOURS_AFTER_BIND = 6,
}
export enum MemberGroupValue {
  GENERAL = 1,
  VIP = 2,
  BUSINESS = 3,
}

export interface FetchRuleListParams {
  offset: number;
  limit: number;
  id?: number;
  titleQ?: string;
  couponQ?: number;
  isActive?: number;
  conditionType?: number;
  actionType?: number;
  bindStartDate?: string;
  bindEndDate?: string;
  useStartDate?: string;
  useEndDate?: string;
  excludePromotionId?: number;
}

export interface RuleListResult {
  count: number;
  previous: string;
  next: string;
  results: RuleListItem[];
}

export interface RuleListItem {
  id: number;
  isActive: boolean;
  title: string;
  conditionType: number;
  conditionAmount: number;
  actionType: number;
  discount: number;
  discountLimit: number;
  maxBindTimes: number;
  bindOnceStartAt: string;
  bindOnceEndAt: string;
  useRuleDescription: string;
  destinationLink: string;
  promotion: number;
}

export interface RuleDetail {
  id?: number;
  isActive: boolean;
  notifyType: number;
  title: string;
  description: string;
  destinationLink: string;
  redirectUrl: string;
  promotionInfo?: {
    id: number;
    title: string;
    tag: number;
  };
  conditionType: number | null;
  conditionAmount?: number;
  conditionAmountUpperBound?: number;
  discount?: number;
  discountLimit?: number;
  actionType: number | null;
  couponMethod: number;
  bindMethod: {
    id?: number;
    memberGroups?: {
      id: number;
      groupId: number;
      discount: number;
      title: string;
    }[];
    bindType?: number;
    bindOnceStartAt?: string;
    bindOnceEndAt?: string;
    bindCycles?: number;
    cycleStartDate?: string;
    maxBindTimes?: number;
    customerType?: number;
    platform?: number;
    promotionRule?: number;
  };
  useMethod: {
    id?: number;
    useType?: number;
    useOnceStartAt?: string;
    useOnceEndAt?: string;
    useCycles?: number;
    useBindTimes?: number;
    useWithinHours?: number;
    maxUseTimes?: number;
    promotionRule?: number;
  };
  isFreeShipping: boolean;
  salesPlans: SalePlan[];
  couponInfo?: {
    id: number;
    code: string;
    boundCount: number;
    maxBindTimes: number;
  };
  maxBindTimesReached?: boolean;
  createdAt?: string;
}

export interface SalePlan {
  id: number;
  name: string;
  price: number;
  msrp: number;
  optionsCount: number;
  planType: number;
  ruleType: number;
  logistic: string;
  shippingMethods: {
    shippingMethod: number;
    shippingMethodName: string;
    shippingThreshold: number;
    shippingFee: number;
  }[];
  canBuy: boolean;
  canBackOrder: boolean;
  estimateShippingDate: string | null;
  salesPage: {
    id: number;
    name: string;
  };
  options: {
    id: number;
    name: string;
    salesPlan: number;
    product: {
      id: number;
      sku: string;
      name: string;
    };
    unitPrice: number;
    unitCost: number;
    groupQty: number;
    media: string;
    vpc: number;
    commissionRate: number;
    canBuyCount: number;
    mode: number;
  }[];
  media: string;
  specialPrice: number;
  cost: number;
  canBuyCount: number;
  salesDiscount: number;
  memberDiscounts: {
    groupId: number;
    groupName: string;
    value: number;
  }[];
  maxSalesQty: {
    groupId: number;
    groupName: string;
    value: number;
  }[];
}

export type CreateSingleSalePromotionResponse = Omit<PromotionDetail, "rules">;

export interface CreateRuleParams {
  title: string;
  description: string;
  notifyType: number | null;
  conditionType: number | null;
  conditionAmount?: number;
  conditionAmountUpperBound?: number;
  actionType: number | null;
  discount?: number;
  discountLimit?: number;
  isFreeShipping: boolean;
  isActive: boolean;
  salesPlans?: SalePlan[];
  couponMethod?: CouponMethodValue;
  bindMethod: {
    memberGroupIds?: number[];
    bindType?: number;
    bindOnceStartAt?: string;
    bindOnceEndAt?: string;
    bindCycles?: number;
    cycleStartDate?: string;
    maxBindTimes?: number;
    customerType?: number | null;
    platform?: number | null;
  };
  useMethod: {
    useType?: number;
    useOnceStartAt?: string;
    useOnceEndAt?: string;
    useCycles?: number;
    useWithinHours?: number;
    maxUseTimes?: number;
  };
  promotionId?: number;
  redirectUrl?: string;
}

export type UpdateRuleParams = CreateRuleParams;

export interface CreateCouponParams {
  ruleId: number;
  couponMethod: CouponMethodValue;
  code?: string;
  codeLength?: number;
  codePrefix?: string;
}

export interface CouponResponse {
  id: number;
  code: string;
  boundCount: number;
  maxBindTimes: number;
}

export interface MemberListResult {
  count: number;
  next: string;
  previous: string;
  results: MemberListItem[];
}

export interface MemberListItem {
  id: number;
  promotionRule: number;
  member: {
    id: number;
    phone: string;
    name: string;
    memberGroup: {
      id: number;
      title: string;
      groupId: number;
      discount: number;
    };
    gender: number | null;
    customerType: string;
  };
  lastBoundAt: string;
  useDeadlineDate: string;
}

export interface MemberListParams {
  limit: number;
  offset: number;
  phone?: string;
  exculdeRuleId?: number;
}

export interface AddSalePlanToRuleParam {
  planId?: number;
  planName?: string;
}

const ruleApi: IRuleApi = {
  fetchRuleList: async (params) => {
    const {
      offset,
      limit,
      id,
      titleQ,
      couponQ,
      isActive = -1,
      conditionType = -1,
      actionType = -1,
      bindStartDate,
      bindEndDate,
      useStartDate,
      useEndDate,
      excludePromotionId,
    } = params;

    const getParams = {
      offset,
      limit,
      id,
      title_q: titleQ,
      coupon_q: couponQ,
      is_active: isActive < 0 ? undefined : Boolean(isActive), // -1:undefined / 0:false / 1:true
      condition_type: conditionType < 0 ? undefined : conditionType,
      action_type: actionType < 0 ? undefined : actionType,
      bind_start_date: bindStartDate,
      bind_end_date: bindEndDate,
      use_start_date: useStartDate,
      use_end_date: useEndDate,
      exclude_promotion_id: excludePromotionId,
    };
    const response = await apiClient.getData("/manage/promotion/rules/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchDeleteRules: async (ids) => {
    const putParams = {
      rule_ids: ids,
    };
    const response = await apiClient.putData("/manage/promotion/rules/batch-delete/", putParams);
    return response.data;
  },
  updateRuleIsActive: async (ruleId, isActive) => {
    const patchParams = {
      is_active: isActive,
    };
    const response = await apiClient.patchData(`/manage/promotion/rules/${ruleId}/`, patchParams);
    return response.data;
  },
  fetchRuleDetail: async (ruleId) => {
    const response = await apiClient.getData(`/manage/promotion/rules/${ruleId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  createSingleSalePromotion: async (ruleId, salePageId) => {
    const postParams = {
      rule_id: ruleId,
      sales_page_id: salePageId,
    };
    const response = await apiClient.postData("/manage/promotion/promotions/?create_from_rule=1", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createRule: async (params) => {
    const postParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/promotion/rules/", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateRule: async (ruleId, params) => {
    const patchParams = transformCamelToSnake(params);
    const response = await apiClient.patchData(`/manage/promotion/rules/${ruleId}/`, patchParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createCoupons: async (params, customSetup) => {
    const postParams = transformCamelToSnake(params);
    const extraQuery = customSetup ? "?custom_setup=1" : "";
    const response = await apiClient.postData(`/manage/promotion/coupons/${extraQuery}`, postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateCoupon: async (params) => {
    const response = await apiClient.putData(`/manage/promotion/coupons/${params.couponId}/`, { code: params.code });
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteCoupon: async (couponId) => {
    const response = await apiClient.deleteData(`/manage/promotion/coupons/${couponId}/`, {});
    return response.data;
  },
  exportCouponsByCSV: async (ruleId) => {
    const getParams = {
      rule_id: ruleId,
    };
    const response = await apiClient.getData("/manage/promotion/coupons/csv-download/", getParams);
    downloadCSV(response);
  },
  fetchMemberList: async (ruleId, params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(`/manage/promotion/rules/${ruleId}/members/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  addMemberToRule: async (ruleId, phone) => {
    const params = {
      phone,
    };
    const response = await apiClient.postData(`/manage/promotion/rules/${ruleId}/members/`, params);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchDeleteMember: async (ruleId, memberIds) => {
    const params = {
      ids: memberIds,
    };
    const response = await apiClient.deleteData(`/manage/promotion/rules/${ruleId}/members/batch-delete/`, params);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchAddMembersByCSV: async (ruleId, file) => {
    const formData = new FormData();
    formData.append("csv_file", file);
    const response = await apiClient.postData(`/manage/promotion/rules/${ruleId}/members/csv-upload/`, formData);
    return transformAPIKeyToCamel(response.data);
  },
  deleteSalePlanFromRule: async (ruleId, planId) => {
    const deleteParams = {
      plan_id: planId,
    };
    const response = await apiClient.deleteData(`/manage/promotion/rules/${ruleId}/remove-plan/`, deleteParams);
    return response.data;
  },
  addSalePlanToRule: async (ruleId, params) => {
    const addParams = transformCamelToSnake(params);
    const response = await apiClient.postData(`/manage/promotion/rules/${ruleId}/add-plan/`, addParams);
    return response.data;
  },
  fetchCloneSalePlan: async (ruleId, planIds) => {
    await apiClient.postData("/manage/sales/plans/clone-to-rule/", {
      plan_ids: planIds,
      rule_id: ruleId,
    });
  },
};

export default ruleApi;
