import { ApprovalStatus, ApprovalStatusFilter } from "@page/Order/ApprovedState";
import ProcessingType from "@page/Order/ProcessingType";
import VendorLogisticsValue from "@page/Order/VendorLogisticsValue";
import CoreAPI from "./CoreAPI";
import normalizeOrder, { OrderInfo, OrderList, ReplenishReturnInfo, ReplenishReturnList } from "./utils/normalizeOrder";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface FetchOrderList {
  limit: number;
  page: number;
  orderNumber?: string;
  shippingStatus?: number;
  name?: string;
  phone?: string;
  shipperNumber?: string;
  packageNumber?: string;
}

export interface FetchReplenishReturnList {
  limit: number;
  page?: number;
  orderNumber?: string;
  processingType?: ProcessingType;
  status?: ApprovalStatusFilter;
  name?: string;
  phone?: string;
  logistic?: VendorLogisticsValue;
  applyingDate?: string;
  approvalDate?: string;
}

export interface FetchReplenishReturnApproval {
  orderId: number;
  approvalStatus: ApprovalStatus;
}

export interface FetchUpdateReceive {
  subOrderId: number;
  code: string;
  address: string;
  receiverName: string;
  receiverPhone: string;
  shippingMethodId: number;
  storeName: string;
}
export interface ChangeGoods {
  qty: number;
  salesPlanId: number;
  reason: string;
  options: Array<{ productId: number; qty: number }>;
}
export interface OrderChangePayload {
  orderId: number;
  orderShipmentId: number;
  applyType: number;
  bankCode?: string;
  bankName?: string;
  bankAccount?: string;
  accountName?: string;
  address: string;
  phone: string;
  name: string;
  code: string;
  changeGoods: ChangeGoods[];
}
export interface SalePlansOptions {
  statusCode: number;
  errorMessage: string;
  result: PlanOptions[];
}
export type PlanOptions = {
  id: number;
  name: string;
  unitPrice: number;
  stock: number;
  canBuyCount: number;
  groupQty: number;
  effectiveDate: string;
  productId: number;
};
export type PlanQty = {
  planId: number;
  qty: number;
  salesPlanOption: { salesPlanOptionId: number; qty: number; name?: string }[];
};
interface OrderAPI {
  fetchOrderList: (
    query: FetchOrderList,
  ) => Promise<{ count: number; next: string; previous: string; results: OrderList[] }>;
  fetchOrderInfo: (subOrderId: number) => Promise<OrderInfo>;
  fetchOrderDeploy: (subOrderId: number, PlanQty?: PlanQty[]) => Promise<void>;
  fetchOrderDelete: (subOrderId: number) => Promise<void>;
  fetchOrderRelease: (orderId: number) => Promise<void>;
  fetchOrderCancel: (orderId: number) => Promise<void>;
  fetchReplenishReturnList: (
    query: FetchReplenishReturnList,
  ) => Promise<{
    count: number;
    next: string;
    previous: string;
    results: ReplenishReturnList[];
  }>;
  fetchReplenishReturnInfo: (orderId: number) => Promise<ReplenishReturnInfo>;
  fetchReplenishReturnRefund: (subOrderIds: number[]) => Promise<void>;
  fetchReplenishReturnApproval: (config: FetchReplenishReturnApproval) => Promise<void>;
  fetchUpdateReceive: (payload: FetchUpdateReceive) => Promise<void>;
  fetchRejectTransfer: (subOrderId: number) => Promise<void>;
  fetchReturn: (payload: OrderChangePayload) => Promise<[string, string]>;
  fetchPlanOptions: (planId: number) => Promise<SalePlansOptions>;
  changeOrderShippingStatus: (subOrderId: number, statusCode: number) => Promise<void>;
  makeOrderInvoice: (mainOrderId: number) => Promise<void>;
}

const apiClient = new CoreAPI();

const orderApi: OrderAPI = {
  fetchOrderList: async (query) => {
    const response = await apiClient.getData("/manage/orders/", {
      limit: query.limit,
      offset: (query.page - 1) * query.limit,
      order_number: query.orderNumber,
      status: query.shippingStatus,
      name: query.name,
      phone: query.phone,
      shipper_number: query.shipperNumber,
      package_number: query.packageNumber,
    });

    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: normalizeOrder.orderList(response.data.result.results),
    };
  },
  fetchOrderInfo: async (subOrderId) => {
    const response = await apiClient.getData(`/manage/orders/${subOrderId}/`, {});
    return normalizeOrder.orderInfo(response.data.result);
  },
  fetchOrderDeploy: async (subOrderId, planQty?) => {
    const payload = transformCamelToSnake(planQty || {});
    await apiClient.postData(`/manage/order-shipments/${subOrderId}/deploy/`, payload);
  },
  fetchOrderDelete: async (subOrderId) => {
    await apiClient.deleteData(`/manage/order-shipments/${subOrderId}/delete/`, {});
  },
  fetchOrderRelease: async (orderId) => {
    await apiClient.putData(`/manage/orders/${orderId}/release/`, {});
  },
  fetchOrderCancel: async (orderId) => {
    await apiClient.postData(`/manage/orders/${orderId}/cancel/`, {});
  },
  fetchReplenishReturnList: async (query) => {
    const response = await apiClient.getData("/manage/reverse-order-shipments/", {
      limit: query.limit,
      offset: query.page ? (query.page - 1) * query.limit : 0,
      order_number: query.orderNumber,
      processing_type: query.processingType,
      status: query.status,
      name: query.name,
      phone: query.phone,
      logistic: query.logistic,
      applying_date_before: query.applyingDate ? query.applyingDate[1] : undefined,
      applying_date_after: query.applyingDate ? query.applyingDate[0] : undefined,
      approval_date_before: query.approvalDate ? query.approvalDate[1] : undefined,
      approval_date_after: query.approvalDate ? query.approvalDate[0] : undefined,
    });

    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: normalizeOrder.replenishReturnList(response.data.result.results),
    };
  },
  fetchReplenishReturnInfo: async (orderId) => {
    const response = await apiClient.getData(`/manage/reverse-order-shipments/${orderId}/`, {});
    return normalizeOrder.replenishReturnInfo(response.data.result);
  },
  fetchReplenishReturnRefund: async (subOrderIds) => {
    await apiClient.putData("/manage/reverse-order-shipments/batch-update/", {
      order_shipment_ids: subOrderIds,
    });
  },
  fetchReplenishReturnApproval: async (config) => {
    await apiClient.putData(`/manage/reverse-order-shipments/${config.orderId}/approval/`, {
      approval_status: config.approvalStatus,
    });
  },
  fetchUpdateReceive: async (payload) => {
    await apiClient.patchData(`/manage/order-shipments/${payload.subOrderId}/`, {
      code: payload.code,
      address: payload.address,
      receiver_name: payload.receiverName,
      receiver_phone: payload.receiverPhone,
      shipping_method_id: payload.shippingMethodId,
      store_name: payload.storeName,
    });
  },
  fetchRejectTransfer: async (subOrderId) => {
    await apiClient.putData(`/manage/order-shipments/${subOrderId}/reject/`, {});
  },
  fetchReturn: async (payload: OrderChangePayload): Promise<[string, string]> => {
    const transformChangeGoods = payload.changeGoods.map((goods) => ({
      sales_plan_id: goods.salesPlanId,
      qty: goods.qty,
      reason: goods.reason,
      options: goods.options.map((option) => ({ product_id: option.productId, qty: option.qty })),
    }));

    const requestBody = {
      order_shipment_id: payload.orderShipmentId,
      apply_type: payload.applyType,
      address: payload.address,
      phone: payload.phone,
      name: payload.name,
      code: payload.code,
      bank_code: payload.bankCode,
      bank_name: payload.bankName,
      bank_account: payload.bankAccount,
      account_name: payload.accountName,
      change_goods: transformChangeGoods,
    };
    const response = await apiClient.postData("/manage/order-change/", requestBody);
    return [response.data.result, ""];
  },

  fetchPlanOptions: async (planId: number) => {
    const resp = await apiClient.getData(`/member/sales/plans/${planId}/options/`, {});
    return transformAPIKeyToCamel(resp.data);
  },
  changeOrderShippingStatus: async (subOrderId, statusCode) => {
    await apiClient.patchData(`/manage/order-shipments/${subOrderId}/shipping-status/`, {
      shipping_status: statusCode,
    });
  },
  makeOrderInvoice: async (mainOrderId) => {
    await apiClient.postData(`/manage/orders/${mainOrderId}/invoice/`, {});
  },
};

export default orderApi;
