import {
  convertCurrencyType,
  OrderDeductionsList,
  PickupTypeByValue,
  ExternalOrderLine,
  Currency,
} from "@api/throughShipment/throughShipmentApi";
import {
  externalOrderState,
  fetchExternalOrderInfo,
  fetchSendOrderNotify,
  setIsEditOrder,
} from "@redux/externalOrderSlice";
import { Button, Table, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { storageTypeOptions } from "@constant/StorageType";
import ExpandIcon from "@assets/expand.png";
import { ColumnsType } from "antd/lib/table";

const Wrapper = styled.div`
  padding: 28px 20px 20px 20px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;
const Row = styled.div<{ marginTop?: string; marginBottom?: string }>`
  display: flex;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  min-width: 115px;
  margin-right: 23px;
`;
const NormalTitle = styled.div`
  font-size: 14px;
  min-width: 115px;
  margin-right: 23px;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const Divide = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colorNeutral400};
  margin: 28px 0;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const CustomButton = styled(Button)<{ marginRight?: string; width?: string }>`
  margin-right: ${({ marginRight }) => marginRight || ""};
  width: ${({ width }) => width || ""};
`;
const NotesWrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;
const Notes = styled.div<{ isExpanded?: boolean }>`
  text-align: left;
  margin: 15px 0;
  white-space: normal;
  overflow-y: ${({ isExpanded }) => (isExpanded ? "hidden" : "scroll")};
  max-height: 112px;
`;
const ExpandIconImg = styled.img`
  background-color: #fff;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
`;

type Props = {
  onBackStep: () => void;
};
const { Text } = Typography;
const showExpandIconLength = 60;

export default function OrderPreview(props: Props) {
  const { onBackStep } = props;

  const dispatch = useDispatch();
  const { externalOrderInfo } = useSelector(externalOrderState);

  const { externalOrderId } = useParams();
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  const [displayOrderDeductionMap, setDisplayOrderDeductionMap] = useState<Map<string, OrderDeductionsList[]>>(
    new Map(),
  );

  const calculatePieces = (): any[] => {
    let orderedCartonQty = 0;
    let totalPcs = 0;
    externalOrderInfo!.externalOrderLine
      .filter((item) => item.orderedQty > 0)
      .forEach((item) => {
        orderedCartonQty += item.orderedQty / item.pcsPerCarton;
        totalPcs += item.orderedQty;
      });
    return [
      { key: "總箱數", value: orderedCartonQty.toFixed(1) },
      { key: "總Pcs數", value: totalPcs },
    ];
  };

  useEffect(() => {
    const displayMap = new Map<string, OrderDeductionsList[]>();

    externalOrderInfo?.orderDeduction.forEach((deduction) => {
      if (displayMap.has(deduction.productlineName)) {
        const prevDisplayItem = displayMap.get(deduction.productlineName);
        displayMap.set(deduction.productlineName, [...(prevDisplayItem as OrderDeductionsList[]), deduction]);
      } else {
        displayMap.set(deduction.productlineName, [deduction]);
      }
    });

    setDisplayOrderDeductionMap(displayMap);
  }, [externalOrderInfo]);

  useEffect(() => {
    dispatch(fetchExternalOrderInfo(parseInt(externalOrderId, 10)));
  }, [dispatch, externalOrderId]);

  if (!externalOrderInfo) {
    return null;
  }

  const clickExpandIcon = (rowId: number) => {
    if (expandedRows.includes(rowId)) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
    } else {
      setExpandedRows(expandedRows.concat(rowId));
    }
  };

  const columns: ColumnsType<ExternalOrderLine> = [
    {
      key: "id",
      dataIndex: "id",
      title: "編號",
    },
    {
      key: "no",
      dataIndex: "no",
      title: "商品品號",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "品名",
    },
    {
      key: "orderedQty",
      dataIndex: "orderedQty",
      title: "總數量(pcs)",
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "商品成本(TWD)",
    },
    {
      key: "quotedPriceCurrency",
      dataIndex: "quotedPriceCurrency",
      title: `商品報價(${convertCurrencyType(externalOrderInfo!.currency)})`,
    },
    {
      key: "quotedPriceTwd",
      dataIndex: "quotedPriceTwd",
      title: "商品報價(TWD)",
    },
    {
      key: "totalDeduction",
      dataIndex: "totalDeduction",
      title: "後扣金額",
    },
    {
      key: "margin",
      dataIndex: "margin",
      title: "饌元商品毛利率",
      width: 150,
      render: (data, _) => <div>{`${Math.round(data * 10000) / 100}%`}</div>,
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      render: (value) => storageTypeOptions.find((type) => type.key === value)?.name || "-",
    },
    {
      key: "effectiveDateBatch",
      dataIndex: "effectiveDateBatch",
      title: "效期＿批號",
      render: (_, value) => {
        return <div>{`${value.effectiveDate || ""}_${value.batchNumber || ""}`}</div>;
      },
    },
    {
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
      title: "箱入數",
    },
    {
      key: "revenue",
      dataIndex: "revenue",
      title: "商品營收",
    },
    {
      key: "notes",
      dataIndex: "notes",
      title: "備註",
      width: 250,
      render: (data, value) => {
        const isExpanded = data?.length && !expandedRows.includes(value.id);

        const getCharLength = () => {
          // 若匹配中文字符則佔寬+2,非中文+1
          const regex = /[\u4e00-\u9fa5]/;
          let count = 0;
          for (let i = 0; i < data?.length; i += 1) {
            const char = data[i];
            if (regex.test(char)) {
              count += 2;
            } else {
              count += 1;
            }
          }
          return count;
        };

        const showExpandIcon = getCharLength() >= showExpandIconLength;

        const cutString = (str: string, showLength: number) => {
          // 以中文字的寬度來判斷，若小於 showLength 可以直接 return
          if (str.length * 2 <= showLength) {
            return str;
          }
          let strLength = 0;
          let showText = "";
          for (let i = 0; i < str.length; i += 1) {
            const char = str.charAt(i);
            const charCode = str.charCodeAt(i);
            // Unicode 編碼值大於 128 代表中文字符 -> 字符的寬度是 2 個字符位置。
            const charWidth = charCode > 128 ? 2 : 1;
            if (strLength + charWidth <= showLength) {
              showText += char;
              strLength += charWidth;
            }
          }
          if (strLength < str.length * 2) {
            showText += "...";
          }
          return showText;
        };

        return (
          <div>
            <NotesWrapper>
              {!isExpanded ? (
                <Notes isExpanded={isExpanded} id={`${value.id}-note`}>
                  {data}
                </Notes>
              ) : (
                cutString(data, 50)
              )}
            </NotesWrapper>
            {showExpandIcon && <ExpandIconImg src={ExpandIcon} onClick={() => clickExpandIcon(value.id)} />}
          </div>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <Row>
        <Title>合作通路類型</Title>
        <RowContent width={1000}>
          <Title>{externalOrderInfo!.retailerRegionType}</Title>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>地區</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerRegion}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>通路名稱</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerName}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>通路電話</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerContactPhone}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>通路窗口</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerContactName}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>通路電子郵件</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerContactEmail}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>通路出貨倉點</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{`${externalOrderInfo!.retailerWarehouseName}(${
            PickupTypeByValue[externalOrderInfo!.pickupType]
          })`}</NormalTitle>
        </RowContent>
      </Row>
      <Divide />
      <Title>合作條件</Title>
      <Row marginTop="20px" marginBottom="26px">
        <NormalTitle>合作模式</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.retailerContractType.toString()}</NormalTitle>
        </RowContent>
      </Row>
      <Title>付款條件</Title>
      <Row marginTop="20px">
        <NormalTitle>經銷商付款時間</NormalTitle>
        <RowContent width={100}>
          <NormalTitle>出貨後</NormalTitle>
        </RowContent>
        <RowContent width={400}>
          <NormalTitle>{externalOrderInfo!.retailerDaysAfterMonthlyStatement}</NormalTitle>
        </RowContent>
        <RowContent width={1000}>
          <NormalTitle>日</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>經銷商付款時間</NormalTitle>
        <RowContent width={100}>
          <NormalTitle>出貨後</NormalTitle>
        </RowContent>
        <RowContent width={400}>
          <NormalTitle>{externalOrderInfo!.retailerDaysAfterMonthlyStatement + 30}</NormalTitle>
        </RowContent>
        <RowContent width={1000}>
          <NormalTitle>日付款給供應商</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>經銷商支付幣別</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{convertCurrencyType(externalOrderInfo!.currency)}</NormalTitle>
        </RowContent>
      </Row>
      <Row marginTop="20px">
        <NormalTitle>匯率</NormalTitle>
        <RowContent width={1000}>
          <NormalTitle>{externalOrderInfo!.currencyRate}</NormalTitle>
        </RowContent>
      </Row>
      <Divide />
      <Title>商品後扣抽成項目</Title>
      {Array.from(displayOrderDeductionMap.keys()).map((deductionName) => (
        <Row marginTop="20px">
          <NormalTitle>{deductionName}</NormalTitle>
          <RowContent width={500}>
            <Table
              columns={[
                {
                  key: "name",
                  dataIndex: "name",
                  title: "項目名稱",
                  width: 100,
                },
                {
                  key: "rate",
                  dataIndex: "rate",
                  title: "項目抽成",
                  width: 100,
                  render: (rate) => <div>{`${rate}%`}</div>,
                },
                {
                  key: "amount",
                  dataIndex: "amount",
                  title: "項目費用",
                  width: 100,
                },
              ]}
              dataSource={displayOrderDeductionMap.get(deductionName)}
              pagination={false}
              bordered
            />
          </RowContent>
        </Row>
      ))}
      <Row marginTop="20px">
        <NormalTitle>訂單費用</NormalTitle>
        <RowContent width={500}>
          <Table
            columns={[
              {
                key: "name",
                dataIndex: "name",
                title: "項目名稱",
                width: 100,
              },
              {
                key: "rate",
                dataIndex: "rate",
                title: "項目抽成",
                width: 100,
                render: (rate) => <div>{`${rate}%`}</div>,
              },
              {
                key: "amount",
                dataIndex: "amount",
                title: "項目費用",
                width: 100,
              },
            ]}
            dataSource={externalOrderInfo!.orderFee}
            pagination={false}
            bordered
          />
        </RowContent>
      </Row>
      <Divide />
      <Title>訂單商品</Title>
      <Row marginTop="20px">
        <Table
          columns={columns.filter((item) =>
            externalOrderInfo.currency === Currency.TWD ? item.key !== "quotedPriceCurrency" : columns,
          )}
          dataSource={externalOrderInfo!.externalOrderLine.filter((item) => item.orderedQty > 0)}
          pagination={false}
          scroll={{ x: 2000, y: 325 }}
        />
      </Row>
      <Row marginTop="20px">
        <div style={{ width: "100%" }}>
          <Table
            columns={[
              {
                key: "key",
                dataIndex: "key",
                title: "合計",
                width: 100,
              },
              {
                key: "value",
                dataIndex: "value",
                render: (value) => <Text type="danger">{value}</Text>,
              },
            ]}
            dataSource={calculatePieces()}
            pagination={false}
          />
        </div>
      </Row>
      <Divide />
      <Title>訂單營收/成本/毛利總覽</Title>
      <Row marginTop="20px">
        <RowContent width={10000}>
          <Table
            columns={[
              {
                key: "totalRevenue",
                dataIndex: "totalRevenue",
                title: "訂單總營收",
              },
              {
                key: "totalCost",
                dataIndex: "totalCost",
                title: "訂單總成本",
              },
              {
                key: "totalOrderFee",
                dataIndex: "totalOrderFee",
                title: "訂單費用加總",
              },
              {
                key: "totalMargin",
                dataIndex: "totalMargin",
                title: "饌元毛利額",
              },
              {
                key: "totalMarginRatio",
                dataIndex: "totalMarginRatio",
                title: "饌元毛利率",
              },
            ]}
            dataSource={[
              {
                totalRevenue: externalOrderInfo!.totalRevenue,
                totalCost: externalOrderInfo!.totalCost,
                totalOrderFee: externalOrderInfo!.totalOrderFee,
                totalMargin: externalOrderInfo!.totalMargin,
                totalMarginRatio: `${Math.round(externalOrderInfo.totalMarginRatio * 10000) / 100}%`,
              },
            ]}
            pagination={false}
          />
        </RowContent>
      </Row>
      <Footer>
        <CustomButton marginRight="12px" onClick={onBackStep}>
          返回
        </CustomButton>
        <CustomButton
          type="primary"
          disabled={externalOrderInfo.isSend}
          onClick={() => {
            dispatch(fetchSendOrderNotify(parseInt(externalOrderId, 10)));
            dispatch(setIsEditOrder(false));
          }}
        >
          發送訂單審核通知
        </CustomButton>
      </Footer>
    </Wrapper>
  );
}
