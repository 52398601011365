import attributeApi, {
  AddTagAttributePayload,
  FetchTagAttributeFilter,
  ProductCategoryTagListResult,
  TagAttributeList,
  TagAttributeListResult,
} from "@api/attributeApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  tagAttributeFilter: FetchTagAttributeFilter;
  tagAttributeListResult: TagAttributeListResult;
  tagAttributeInfo?: TagAttributeList;
  productCategoryTagListResult: ProductCategoryTagListResult;
  addAttributeId?: number;
}

const initialState: IState = {
  isFetching: false,
  tagAttributeFilter: {
    nameQ: undefined,
    tagNameQ: undefined,
    limit: 20,
    offset: 0,
  },
  tagAttributeListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  tagAttributeInfo: undefined,
  productCategoryTagListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  addAttributeId: undefined,
};

export const fetchTagAttributeList = createAsyncThunk(
  "attribute/fetchTagAttributeList",
  async (params: FetchTagAttributeFilter) => {
    const response = await attributeApi.fetchTagAttributeList(params);
    return response;
  },
);

export const fetchTagAttributeInfo = createAsyncThunk(
  "attribute/fetchTagAttributeInfo",
  async (attributeId: number) => {
    const response = await attributeApi.fetchTagAttributeInfo(attributeId);
    return response;
  },
);

export const fetchAddTagAttribute = createAsyncThunk(
  "attribute/fetchAddTagAttribute",
  async (payload: AddTagAttributePayload, thunkApi) => {
    try {
      const attrId = await attributeApi.fetchAddTagAttribute(payload);
      message.success("新增成功");
      return attrId;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateTagAttribute = createAsyncThunk(
  "attribute/fetchUpdateTagAttribute",
  async (payload: AddTagAttributePayload & { attributeId: number }, thunkApi) => {
    try {
      const { attributeId, ...otherPayload } = payload;
      await attributeApi.fetchUpdateTagAttribute(attributeId, otherPayload);
      message.success("更新成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteTagAttribute = createAsyncThunk(
  "attribute/fetchDeleteTagAttribute",
  async (attributeId: number, thunkApi) => {
    try {
      await attributeApi.fetchDeleteTagAttribute(attributeId);
      const { attributeSlice } = thunkApi.getState() as RootState;
      thunkApi.dispatch(fetchTagAttributeList(attributeSlice.tagAttributeFilter));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchTagAttributeLoadMore = createAsyncThunk(
  "attribute/fetchTagAttributeLoadMore",
  async (_, thunkApi) => {
    const {
      attributeSlice: { tagAttributeFilter },
    } = thunkApi.getState() as RootState;
    const moreParam = {
      ...tagAttributeFilter,
      offset: tagAttributeFilter.offset + tagAttributeFilter.limit,
    };

    thunkApi.dispatch(setTagAttributeFilter(moreParam));
    const response = await attributeApi.fetchTagAttributeList(moreParam);
    return response;
  },
);

export const fetchProductCategoryTagList = createAsyncThunk(
  "attribute/fetchProductCategoryTagList",
  async (tagTypeId: number) => {
    const response = await attributeApi.fetchProductCategoryTagList(tagTypeId);
    return response;
  },
);

export const fetchAddProductCategoryTag = createAsyncThunk(
  "attribute/fetchAddProductCategoryTag",
  async (payload: { productCategoryId: number; tagTypeId: number }, thunkApi) => {
    try {
      await attributeApi.fetchAddProductCategoryTag(payload.productCategoryId, payload.tagTypeId);
      thunkApi.dispatch(fetchProductCategoryTagList(payload.tagTypeId));
      message.success("建立成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteProductCategoryTag = createAsyncThunk(
  "attribute/fetchDeleteProductCategoryTag",
  async (payload: { productCategoryId: number; tagTypeId: number }, thunkApi) => {
    try {
      await attributeApi.fetchDeleteProductCategoryTag(payload.productCategoryId, payload.tagTypeId);
      thunkApi.dispatch(fetchProductCategoryTagList(payload.tagTypeId));
      message.success("刪除成功");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const attributeSlice = createSlice({
  name: "attribute",
  initialState,
  reducers: {
    resetAttributeSlice: () => initialState,
    setTagAttributeFilter: (state, action) => {
      state.tagAttributeFilter = { ...state.tagAttributeFilter, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTagAttributeList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTagAttributeList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.tagAttributeListResult = action.payload;
    });
    builder.addCase(fetchTagAttributeInfo.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTagAttributeInfo.fulfilled, (state, action) => {
      state.isFetching = false;
      state.tagAttributeInfo = action.payload;
    });
    builder.addCase(fetchAddTagAttribute.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAddTagAttribute.fulfilled, (state, action) => {
      state.isFetching = false;
      state.addAttributeId = action.payload;
    });
    builder.addCase(fetchUpdateTagAttribute.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateTagAttribute.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchProductCategoryTagList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductCategoryTagList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.productCategoryTagListResult = action.payload;
    });
    builder.addCase(fetchTagAttributeLoadMore.fulfilled, (state, action) => {
      state.isFetching = false;
      state.tagAttributeListResult = {
        ...action.payload,
        results: state.tagAttributeListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const { setTagAttributeFilter, resetAttributeSlice } = attributeSlice.actions;
export const attributeState = (state: RootState) => state.attributeSlice;
export default attributeSlice.reducer;
