import React from "react";
import styled from "styled-components";
import { Button, Form, Row, DatePicker, Col, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { setTryallProductDailyAggregationsFilter } from "@redux/finance/tryallStockManagementSlice";

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 300px;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function Filter() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { tryallProductDailyAggregationsFilter } = useSelector((state: RootState) => state.tryallStockManagementSlice);

  const onSubmit = () => {
    const formState = form.getFieldsValue();
    dispatch(
      setTryallProductDailyAggregationsFilter({
        ...tryallProductDailyAggregationsFilter,
        statsDateAfter: formState.statsDate && formState.statsDate[0]?.format("YYYY-MM-DD"),
        statsDateBefore: formState.statsDate && formState.statsDate[1]?.format("YYYY-MM-DD"),
        nameQ: formState.nameQ,
        no: formState.no,
      }),
    );
  };

  const onReset = () => {
    form.resetFields();
    dispatch(
      setTryallProductDailyAggregationsFilter({
        ...tryallProductDailyAggregationsFilter,
        statsDateAfter: null,
        statsDateBefore: null,
        nameQ: null,
        no: null,
      }),
    );
  };

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={5}>
          <Form.Item label="商品編號" name="no">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="商品名稱" name="nameQ">
            <Input />
          </Form.Item>
        </Col>
        <Col span={9}>
          <Form.Item label="日期" name="statsDate">
            <CustomRangePicker picker="date" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
}
