import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import Slot from "./index";
import AddSlot from "./AddSlot";
import EditSlot from "./EditSlot";

const SlotPage: FC = () => (
  <Routes>
    <Route path="/" element={<Slot />} />
    <Route path="/add" element={<AddSlot />} />
    <Route path="/edit/:id" element={<EditSlot />} />
  </Routes>
);

export default SlotPage;
