import { CloseOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import { clearRecommendBrand, fetchCreateBrand, fetchRecommendBrand, fetchUpdateBrand } from "@redux/brandSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Form, Input } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

type Props = {
  brand:
    | {
        id: number;
        name: string;
      }
    | undefined;
  close: () => void;
};

const { Item } = Form;

const Wrapper = styled(Form)`
  position: relative;
  width: 650px;
  height: 223px;
  padding: 40px 22px 28px 32px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: 53px;
  }
`;

const CustomClose = styled(CloseOutlined)`
  position: absolute;
  right: 22px;
  top: 22px;
`;

const CustomInput = styled(Input)`
  width: 395px;
  margin-left: 53px;
`;

const CustomItem = styled(Item)`
  position: absolute;
  right: 28px;
  bottom: 28px;
  margin: 0;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const Recommend = styled.span`
  ${fontStyle("13px", "16px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;

const brandSlice = (state: RootState) => state.brand;

export default function EditBrand(props: Props) {
  const { brand, close } = props;

  const dispatch = useDispatch();
  const { recommendBrand, recommendCount } = useSelector(brandSlice);

  const fetchSaveBrand = (payload: { name: string }) => {
    if (brand) {
      dispatch(fetchUpdateBrand({ brandId: brand.id, data: payload }));
    } else {
      dispatch(fetchCreateBrand(payload));
    }
    close();
  };

  const fetchSearchRecommend = useDebounce((value: string) => {
    if (value) {
      dispatch(fetchRecommendBrand({ searchName: value }));
    } else {
      dispatch(clearRecommendBrand());
    }
  }, 500);

  const getRecommendBrand = () => {
    if (recommendBrand.length === 0) return "";

    const brandNames = recommendBrand.filter((item, index) => index < 10).map((item) => item.name);

    if (recommendCount > 10) {
      return `目前已有：${brandNames.toString()}...其他${recommendCount - 10}筆`;
    }
    return `目前已有：${brandNames.toString()}`;
  };

  const closeModal = () => {
    dispatch(clearRecommendBrand());
    close();
  };

  useEffect(() => {
    if (brand) {
      dispatch(fetchRecommendBrand({ searchName: brand.name }));
    }
  }, [dispatch, brand]);

  return (
    <PopupBackground close={closeModal} fixed>
      <Wrapper initialValues={{ name: brand?.name }} onFinish={(value) => fetchSaveBrand(value as { name: string })}>
        <CustomClose onClick={closeModal} />
        <Form.Item label="名稱" name="name" rules={[{ required: true, message: "必填" }]}>
          <CustomInput onChange={(e) => fetchSearchRecommend(e.target.value)} />
        </Form.Item>
        <Recommend>{getRecommendBrand()}</Recommend>
        <CustomItem>
          <CustomButton onClick={closeModal}>取消</CustomButton>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </CustomItem>
      </Wrapper>
    </PopupBackground>
  );
}
