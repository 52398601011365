import { ExclamationCircleOutlined } from "@ant-design/icons";
import { CreatePromotionParams, PromotionDetail, UpdatePromotionParams } from "@api/promotionApi";
import PageTitle from "@component/PageTitle";
import {
  createPromotion,
  defaultPageInfo,
  fetchPromotionDetail,
  reset,
  singleDeletePromotion,
  updatePromotion,
} from "@redux/promotionSlice";
import { RootState } from "@redux/rootReducer";
import { Button, DatePicker, Form, Input, Modal, Radio, Select, Switch } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment, { Moment } from "moment";
import React, { FC, MouseEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import ManageRule from "./ManageRule";
import ManageSalePage from "./ManageSalePage";
import PageInfoSetting from "./PageInfoSetting";

enum PageTypeValue {
  ALL_WEBSITE = 1,
  TARGET_SALEPAGES = 3,
}
interface Props {
  mode: "add" | "edit";
}

const Wrapper = styled.div`
  padding: 25px 16px 25px 24px;
`;
const IsActiveRow = styled.div`
  display: flex;
  margin-bottom: 30px;
`;
const IsActiveTitle = styled.div`
  margin-right: 10px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const DeleteButton = styled(Button)`
  margin-right: 10px;
`;
const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
const RowTitle = styled.div`
  font-size: 14px;
  line-height: 32px;
  width: 63px;
  margin-right: 38px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const RangePicker = styled(DatePicker.RangePicker)<{ showTime: any }>`
  width: 100%;
`;
const BlockRadios = styled(Radio)`
  display: flex;
  align-items: center;
  height: 32px;
`;
const CreatePageContainer = styled.div`
  display: flex;
  align-items: center;
`;
const CreatePageText = styled.p`
  margin: 0 8px;
`;
const PageInfoSettingWrapper = styled.div`
  padding-left: 80px;
`;
const ManageSalePageWrapper = styled.div`
  padding-left: 80px;
`;

export type PromotionForm = Omit<PromotionDetail, "startAt" | "endAt"> & {
  withTagPage: boolean;
  timeBetween: [Moment, Moment];
};

const createPageOptions: any = [
  {
    label: "是",
    value: true,
  },
  {
    label: "否",
    value: false,
  },
];

const PromotionEdit: FC<Props> = (props) => {
  const { mode } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: promotionId } = useParams();
  const { promotionDetail } = useSelector((state: RootState) => state.promotion);
  const [bindType, setBindType] = useState<number>();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const bindTimeStart = queryParams.get("bindTimeStart");
  const bindTimeEnd = queryParams.get("bindTimeEnd");

  const [form] = Form.useForm<PromotionForm>();

  useEffect(() => {
    if (mode === "add" && promotionDetail.id) {
      navigate(`/promotion/edit/${promotionDetail.id}`);
    }
  }, [mode, navigate, promotionDetail]);

  useEffect(() => {
    if (mode === "edit") dispatch(fetchPromotionDetail(Number(promotionId)));

    return () => {
      dispatch(reset());
    };
  }, [promotionId, dispatch, mode]);

  useEffect(() => {
    if (mode === "edit" && promotionDetail) {
      const {
        id,
        title,
        pageType,
        destinationLink,
        startAt,
        endAt,
        isActive,
        pageInfo,
        isShow,
        isFilterEnabled,
      } = promotionDetail;
      const clonePageInfo: typeof defaultPageInfo = JSON.parse(JSON.stringify(pageInfo));

      if (pageInfo) {
        const sortMethodNameList = clonePageInfo.sortMethods.map((item) => item.name);
        const defaultSortMethod = clonePageInfo.sortMethods.find((item) => item.isDefault);
        clonePageInfo.sortMethods = defaultPageInfo.sortMethods.map((item) => ({
          ...item,
          isActive: sortMethodNameList.includes(item.name),
          isDefault: item.name === defaultSortMethod?.name,
        }));
      }

      form.setFieldsValue({
        id,
        title,
        pageType,
        withTagPage: destinationLink.includes("promotion"),
        timeBetween: [moment(startAt), moment(endAt)],
        isActive,
        pageInfo: pageInfo === null ? defaultPageInfo : clonePageInfo,
        isShow,
        isFilterEnabled,
      });
    } else {
      const { id, title, pageType, isActive, pageInfo } = promotionDetail;
      form.setFieldsValue({
        id,
        title,
        pageType,
        withTagPage: true,
        isActive,
        pageInfo,
        isShow: true,
        isFilterEnabled: false,
      });
    }
  }, [promotionDetail, mode, form]);

  useEffect(() => {
    // 填入優惠後台建立多品活動時，領取優惠券的時間
    if (bindTimeStart && bindTimeEnd) {
      form.setFieldsValue({
        timeBetween: [moment(bindTimeStart), moment(bindTimeEnd)],
      });
    }
  }, [bindTimeEnd, bindTimeStart, form]);

  const cancelBubble = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleOnDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        dispatch(singleDeletePromotion(promotionDetail.id as number));
        navigate("/promotion");
      },
    });
  };

  const handleOnSubmit = () => {
    const values = form.getFieldsValue();
    const { title, pageType, withTagPage, isActive, timeBetween, pageInfo, isShow, isFilterEnabled } = values;

    switch (mode) {
      case "add": {
        const params: CreatePromotionParams = {
          title,
          pageType,
          withTagPage,
          isActive,
          startAt: moment(timeBetween[0]).format("YYYY-MM-DD HH:mm:ss"),
          endAt: moment(timeBetween[1]).format("YYYY-MM-DD HH:mm:ss"),
          image: pageInfo?.image.url,
          imageAlt: pageInfo?.image.alt,
          metaTitle: pageInfo?.metaTitle,
          metaKeywords: pageInfo?.metaKeywords,
          metaDescription: pageInfo?.metaDescription,
          backgroundColor: pageInfo?.backgroundColor,
          sortMethods: pageInfo?.sortMethods,
          isShow: isActive ? isShow : false,
          isFilterEnabled: isActive ? isFilterEnabled : false,
          bindType,
        };
        dispatch(createPromotion(params));
        break;
      }
      case "edit": {
        const params: UpdatePromotionParams = {
          title,
          pageType,
          withTagPage,
          isActive,
          startAt: moment(timeBetween[0]).format("YYYY-MM-DD HH:mm:ss"),
          endAt: moment(timeBetween[1]).format("YYYY-MM-DD HH:mm:ss"),
          image: pageInfo?.image.url,
          imageAlt: pageInfo?.image.alt,
          metaTitle: pageInfo?.metaTitle,
          metaKeywords: pageInfo?.metaKeywords,
          metaDescription: pageInfo?.metaDescription,
          backgroundColor: pageInfo?.backgroundColor,
          sortMethods: pageInfo?.sortMethods,
          isShow: isActive ? isShow : false,
          isFilterEnabled: isActive ? isFilterEnabled : false,
          bindType,
        };
        dispatch(updatePromotion(params));
        break;
      }
      default:
    }
  };

  const onShowDisabledOption = () => {
    const checkTimeBetween = form.getFieldValue("timeBetween")?.length > 0;
    return checkTimeBetween ? !form.getFieldValue("isActive") : true;
  };

  return (
    <Wrapper>
      <PageTitle title={`活動 - ${mode === "add" ? "新增" : promotionDetail.title}`} />
      <Form form={form} onFinish={handleOnSubmit} onFinishFailed={showRequiredFieldsWarning}>
        <IsActiveRow>
          <IsActiveTitle>啟用</IsActiveTitle>
          <Form.Item name="isActive" valuePropName="checked" noStyle>
            <Switch />
          </Form.Item>
        </IsActiveRow>
        <Row>
          <RowTitle>ID</RowTitle>
          <RowContent width={86}>
            <Form.Item name="id">
              <Input disabled />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            活動名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={584}>
            <Form.Item name="title" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            活動時間
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={350}>
            <Form.Item name="timeBetween" rules={[{ required: true, message: "" }]}>
              <RangePicker
                locale={locale}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                }}
              />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            活動對象
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <Form.Item
            name="pageType"
            rules={[
              {
                required: true,

                validator: (rule, value) => {
                  if (value < 0) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Radio.Group>
              <BlockRadios value={1}>全站</BlockRadios>
              <Form.Item
                noStyle
                shouldUpdate={(prevValue, currentValues) =>
                  prevValue.pageType !== currentValues.pageType ||
                  prevValue.isActive !== currentValues.isActive ||
                  prevValue.timeBetween !== currentValues.timeBetween
                }
              >
                {({ getFieldValue }) => {
                  const pageType = getFieldValue("pageType");
                  return (
                    <BlockRadios value={3}>
                      <CreatePageContainer>
                        指定銷售頁
                        {pageType === PageTypeValue.TARGET_SALEPAGES && (
                          <Row>
                            <CreatePageContainer onClick={cancelBubble}>
                              <CreatePageText>是否顯示活動標籤頁</CreatePageText>
                              <Form.Item name="withTagPage" noStyle>
                                <StyledSelect options={createPageOptions} />
                              </Form.Item>
                            </CreatePageContainer>
                            <CreatePageContainer onClick={cancelBubble}>
                              <CreatePageText>是否顯示於列表篩選？</CreatePageText>
                              <Form.Item name="isFilterEnabled" noStyle>
                                <StyledSelect options={createPageOptions} disabled={onShowDisabledOption()} />
                              </Form.Item>
                            </CreatePageContainer>
                            <CreatePageContainer onClick={cancelBubble}>
                              <CreatePageText>是否顯示於銷售頁？</CreatePageText>
                              <Form.Item name="isShow" noStyle>
                                <StyledSelect options={createPageOptions} disabled={onShowDisabledOption()} />
                              </Form.Item>
                            </CreatePageContainer>
                          </Row>
                        )}
                      </CreatePageContainer>
                    </BlockRadios>
                  );
                }}
              </Form.Item>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Form.Item
          noStyle
          shouldUpdate={(prevValue, currentValues) => {
            return prevValue.pageType !== currentValues.pageType || prevValue.withTagPage !== currentValues.withTagPage;
          }}
        >
          {({ getFieldValue }) => {
            const pageType = getFieldValue("pageType");
            const withTagPage = getFieldValue("withTagPage");

            return (
              pageType === PageTypeValue.TARGET_SALEPAGES &&
              withTagPage && (
                <PageInfoSettingWrapper>
                  <PageInfoSetting form={form} />
                </PageInfoSettingWrapper>
              )
            );
          }}
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prevValue, currentValues) => prevValue.pageType !== currentValues.pageType}>
          {({ getFieldValue }) => {
            const pageType = getFieldValue("pageType");

            return (
              pageType === PageTypeValue.TARGET_SALEPAGES && (
                <ManageSalePageWrapper>
                  <ManageSalePage setBindType={setBindType} />
                </ManageSalePageWrapper>
              )
            );
          }}
        </Form.Item>
        <ManageRule />
        <Form.Item>
          <Footer>
            <DeleteButton disabled={mode === "add"} onClick={handleOnDelete}>
              刪除
            </DeleteButton>
            <Button type="primary" htmlType="submit">
              {mode === "add" ? "建立資料" : "儲存此頁面"}
            </Button>
          </Footer>
        </Form.Item>
      </Form>
    </Wrapper>
  );
};

export default PromotionEdit;
