import React, { FC } from "react";
import PopupBackground from "@component/PopupBackground";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Form, Input, Button, message } from "antd";
import { useAppDispatch } from "src/store";
import {
  RetailerProductDetail,
  CreateRetailerProductParam,
  UpdateRetailerProductParam,
  Currency,
} from "@api/supplyChainApi";
import { createRetailerProduct, updateRetailerProduct } from "@redux/supplyChainSlice";

import brandOperationApi from "@api/brandOperationApi";
import useDebounce from "@hooks/useDebounce";

interface Props {
  onClose: () => void;
  setCurrentData: React.Dispatch<React.SetStateAction<RetailerProductDetail | null>>;
  currentData: RetailerProductDetail | null;
}

const PopWrapper = styled.div`
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const Row = styled.div`
  display: flex;
`;
const RowTitle = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  align-self: flex-start;
  text-align: left;
  margin-right: 15px;
  line-height: 32px;
  font-size: 14px;
  white-space: nowrap;
`;
const RequiredIcon = styled.span`
  color: ${({ theme }) => theme.colorSecondary500};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const SupplyChainProductPopup: FC<Props> = (props) => {
  const { onClose, currentData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { supplyChainRelationId, currencyNumber } = useParams();

  const handleOnNumberSearch = useDebounce(async (value: string) => {
    try {
      const response = await brandOperationApi.fetchProductList({ no: value, limit: 200, offset: 0 });
      if (response.count === 1) {
        form.setFieldsValue({ name: response.results[0].name });
      } else {
        message.warning("查無此商品品號");
        form.setFieldsValue({ name: undefined });
      }
    } catch (e) {
      message.warning("查無此商品品號");
    }
  }, 300);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    if (currentData) {
      const params: UpdateRetailerProductParam = {
        id: currentData.id,
        sku: formValues.sku,
        no: formValues.no,
        currency: currentData.currency,
        name: formValues.name,
        nameEng: formValues.nameEng,
        price: Number(formValues.price),
        cost: Number(formValues.cost),
        quotedPrice: Number(formValues.quotedPrice),
        canAcceptDays: Number(formValues.canAcceptDays),
        pcsPerCarton: Number(formValues.pcsPerCarton),
        supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
      };
      dispatch(updateRetailerProduct(params));
    } else {
      const params: CreateRetailerProductParam = {
        sku: formValues.sku,
        no: formValues.no,
        name: formValues.name,
        nameEng: formValues.nameEng,
        price: Number(formValues.price),
        cost: Number(formValues.cost),
        quotedPrice: Number(formValues.quotedPrice),
        canAcceptDays: Number(formValues.canAcceptDays),
        pcsPerCarton: Number(formValues.pcsPerCarton),
        supplyChainRetailerProductlineRelation: Number(supplyChainRelationId),
      };
      dispatch(createRetailerProduct(params));
    }
    onClose();
  };

  let currencyType;
  if (Number(currencyNumber) === Currency.TWD) {
    currencyType = "TWD";
  } else if (Number(currencyNumber) === Currency.USD) {
    currencyType = "USD";
  } else {
    currencyType = "RMB";
  }

  return (
    <PopupBackground close={onClose} fixed>
      <PopWrapper>
        <Form initialValues={currentData ?? undefined} form={form} onFinish={handleOnSubmit}>
          <Row>
            <RowTitle width={100}>
              商品品號
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="no" rules={[{ required: true, message: "" }]}>
                <Input onChange={(e) => handleOnNumberSearch(e.target.value)} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={100}>
              品名
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <Input disabled />
              </Form.Item>
            </RowContent>
          </Row>

          <Row>
            <RowTitle width={100}>
              商品售價(TWD)
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="price" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>

          <Row>
            <RowTitle width={100}>
              {`商品報價(${currencyType})`}
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="quotedPrice" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={100}>
              箱入數
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="pcsPerCarton" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle width={100}>
              允收天數
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="canAcceptDays" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        </Form>
      </PopWrapper>
    </PopupBackground>
  );
};

export default SupplyChainProductPopup;
