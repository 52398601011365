import { PlanQty } from "@api/orderApi";
import { SubOrder } from "@api/utils/normalizeOrder";
import PopupBackground from "@component/PopupBackground";
import { fetchOrderDeploy, fetchUpdateReceive } from "@redux/orderSlice";
import { Button, Radio, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { ShippingTypeValueByNumber } from "../ShippingTypeValue";

enum Step {
  CHOOSE_CVS = "CHOOSE_CVS",
  CHECK_STORE = "CHECK_STORE",
}

const Wrapper = styled.div`
  width: 450px;
  min-height: 250px;
  padding: 55px 28px 20px 24px;
  background: ${({ theme }) => theme.colorNeutral100};
`;

const Title = styled.div`
  margin-right: 18px;
  ${fontStyle("16px", "22px", "bold")};
`;

const CustomRow = styled(Row)<{ step?: Step }>`
  margin-top: ${({ step }) => (step === Step.CHOOSE_CVS ? "82px" : "99px")};
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomRadio = styled(Radio)`
  margin-bottom: 8px;
  display: block;
`;

const Text = styled.div`
  ${fontStyle("14px", "22px")};
`;

const SevenButton = (props: { redirectPathname: string }) => {
  const { redirectPathname } = props;

  const webDomain = window.location.origin;

  const eshopid = 1; // 子廠商代號
  const servicetype = 1; // 1: 付款取貨, 3: 取貨不付款
  const tempvar = `${webDomain}${redirectPathname}`; // backend redirect path
  const emapUrl = "https://emap.presco.com.tw/emapu8.ashx";
  const backendStoreRedirect = "https://api.lucas.urmart.com/api/v1/st-map-callback/";

  return (
    <form
      action={emapUrl}
      method="post"
      target="_self"
      onSubmit={(e) => {
        e.stopPropagation();
      }}
    >
      <input type="hidden" name="eshopid" value={eshopid} />
      <input type="hidden" name="servicetype" value={servicetype} />
      <input type="hidden" name="tempvar" value={tempvar} />
      <input type="hidden" name="url" value={backendStoreRedirect} />
      <Button type="primary" htmlType="submit">
        下一步
      </Button>
    </form>
  );
};

const CVSButton = () => {
  const cvstemp = window.location.pathname; // 有需要可以改成要帶回的參數
  const getCvsName = () => {
    const env = process.env.REACT_APP_NODE_ENV;
    if (env === "production") {
      return "urmart_new_admin_order_edit_pro";
    }
    if (env === "staging") {
      return "urmart_admin_order_edit_staging";
    }
    return "urmart_admin_order_edit_dev";
  };

  const cvsUrl = `https://mfme.map.com.tw/default.aspx?cvsname=${getCvsName()}&cvsid=12345&cvstemp=${cvstemp}`;

  return (
    <Button type="primary">
      <a href={cvsUrl}>下一步</a>
    </Button>
  );
};

enum LocalShippingMethod {
  Origin = -1,
}
export type Props = {
  order: SubOrder;
  close: () => void;
  onFinished?: () => void;
  finalPlanQty?: PlanQty[];
};

const { pathname } = window.location;

export default function CVSPopup(props: Props) {
  const { order, close, finalPlanQty, onFinished } = props;
  const { state } = useLocation() as any;
  const { orderId } = useParams();
  const navigation = useNavigate();

  const dispatch = useDispatch();

  const [shippingMethod, setShippingMethod] = useState<ShippingTypeValueByNumber | LocalShippingMethod>(
    ShippingTypeValueByNumber.SEVEN_ELEVEN,
  );
  const [step, setStep] = useState<Step>(Step.CHOOSE_CVS);

  const onChangeShippingMethod = (e: RadioChangeEvent) => {
    setShippingMethod(e.target.value);
  };

  const onChangeShipping = useCallback(() => {
    if (shippingMethod === LocalShippingMethod.Origin) {
      dispatch(fetchOrderDeploy({ subOrderId: order.id, mainOrderId: parseInt(orderId, 10), finalPlanQty }));
      navigation(pathname);
      close();
    } else {
      const payload = {
        mainOrderId: parseInt(orderId, 10),
        subOrderId: order.id,
        code: state.stCode,
        address: state.stAddr,
        receiverName: order.receiverName,
        receiverPhone: order.receiverPhone,
        shippingMethodId:
          state.stCate === "711" ? ShippingTypeValueByNumber.SEVEN_ELEVEN : ShippingTypeValueByNumber.CVS,
        storeName: state.stName,
      };
      dispatch(fetchUpdateReceive(payload));
      navigation(pathname);
      close();
    }
    if (onFinished) {
      onFinished();
    }
  }, [dispatch, order, state, close, navigation, shippingMethod, orderId, finalPlanQty, onFinished]);

  const handleOnClose = () => {
    navigation(pathname);
    close();
  };

  const StepComponent = useMemo(() => {
    switch (step) {
      case Step.CHOOSE_CVS:
        return (
          <Radio.Group onChange={onChangeShippingMethod}>
            <CustomRadio value={ShippingTypeValueByNumber.SEVEN_ELEVEN}>7-11</CustomRadio>
            <CustomRadio value={ShippingTypeValueByNumber.CVS}>全家/萊爾富/OK Mart</CustomRadio>
            <CustomRadio value={LocalShippingMethod.Origin}>原地址</CustomRadio>
          </Radio.Group>
        );
      case Step.CHECK_STORE:
        return <Text>{`${state?.stCode} ${state?.stName} ${state?.stAddr}`}</Text>;
      default:
        return null;
    }
  }, [step, state]);

  const ShippingMethodComponent = useMemo(() => {
    if (state || shippingMethod === LocalShippingMethod.Origin) {
      return (
        <Button type="primary" onClick={onChangeShipping}>
          確定
        </Button>
      );
    }

    switch (shippingMethod) {
      case ShippingTypeValueByNumber.SEVEN_ELEVEN:
        return <SevenButton redirectPathname={window.location.pathname} />;
      case ShippingTypeValueByNumber.CVS:
        return <CVSButton />;
      default:
        return null;
    }
  }, [shippingMethod, state, onChangeShipping]);

  useEffect(() => {
    if (state) {
      setStep(Step.CHECK_STORE);
    }
  }, [state]);

  return (
    <PopupBackground close={close}>
      <Wrapper>
        <Row>
          <Title>門市</Title>
          {StepComponent}
        </Row>
        <CustomRow justify="end" step={step}>
          <CustomButton onClick={handleOnClose}>取消</CustomButton>
          {ShippingMethodComponent}
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
