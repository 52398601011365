import { BrandPageListConfig } from "@api/brandPageApi";
import EmptyContent from "@component/EmptyContent";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { brandPageState, fetchBrandPageList } from "@redux/brandPageSlice";
import { Button, Col, Form, Input, Row, Select, Spin, Tabs } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import EditBrand from "./EditBrand";
import EditSalesPage from "./EditSalesPage";

enum SearchType {
  BRAND = 1,
  TAG_ID = 2,
}

const { Option } = Select;

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
`;
const CustomInput = styled(Input)`
  margin-right: 8px;
`;
const ListWrapper = styled.div`
  margin-top: 8px;
  height: calc(100vh - 32px);
  overflow-y: auto;
`;
const ContentWrapper = styled.div<{ isManageTag: boolean }>`
  padding: 13px 22px 0 22px;
  margin: 0px 25px 12px 12px;
  border: 1px solid #f0f0f0;
  width: 100%;
  margin-bottom: ${({ isManageTag }) => !isManageTag && "162px"};
`;
const CustomTabs = styled(Tabs)`
  width: 100%;
  height: 100%;

  &&& .ant-tabs-tab {
    padding: 8px 25px;
  }
`;
const ListItem = styled.div<{ isActive: boolean }>`
  padding: 4px 0;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  background: ${({ isActive, theme }) => (isActive ? "#bae7ff" : theme.colorNeutral100)};

  &:hover {
    background-color: #f5f5f5;
  }
`;

const { TabPane } = Tabs;

type Props = {
  updateCurrentTab: (key: string) => void;
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
};

const CategoryEdit = (props: Props) => {
  const { updateCurrentTab, containerRef } = props;
  return (
    <CustomTabs type="card" onChange={(key) => updateCurrentTab(key)}>
      <TabPane tab="編輯品牌" key="edit">
        <EditBrand containerRef={containerRef} />
      </TabPane>
      <TabPane tab="管理銷售頁" key="salespage">
        <EditSalesPage />
      </TabPane>
    </CustomTabs>
  );
};

const defaultLimit = 100;
const defaultOffset = 0;

export default function BrandPage() {
  const navigate = useNavigate();
  const { brandPageId } = useParams();

  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { isFetchingList, brandPageListResult } = useSelector(brandPageState);

  const [currentTab, setCurrentTab] = useState<string>("");
  const [searchType, setSearchType] = useState<number>(SearchType.BRAND);
  const [pageListConfig, setPageListConfig] = useState<BrandPageListConfig & { isScroll?: boolean }>({
    limit: defaultLimit,
    offset: defaultOffset,
    name: undefined,
    id: undefined,
    isScroll: false,
  });

  const pageCount = useRef(1);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const fetchBrandPageListByScroll = () => {
    if (!isFetchingList && brandPageListResult.next) {
      const page = pageCount.current;
      setPageListConfig({
        ...pageListConfig,
        isScroll: true,
        limit: defaultLimit * (page + 1),
        offset: defaultLimit * page,
      });
      pageCount.current += 1;
    }
  };

  // 後端鎖住 id 必為數字，但回來的 statuscode 為 9001 會造成登出，因此將 id 鎖住為數字才 call api
  const onSubmit = (values: any) => {
    if (!isFetchingList) {
      switch (searchType) {
        case SearchType.BRAND:
          setPageListConfig({
            ...pageListConfig,
            isScroll: false,
            limit: defaultLimit,
            offset: defaultOffset,
            name: values.searchText,
          });
          break;
        case SearchType.TAG_ID:
          setPageListConfig({
            ...pageListConfig,
            id: values.searchText ? Number(values.searchText) : undefined,
            name: undefined,
          });
          break;
        default:
      }
      pageCount.current = 1;
    }
  };

  const onSearchTypeChange = (value: number) => {
    setSearchType(value);
  };

  useEffect(() => {
    dispatch(fetchBrandPageList(pageListConfig));
  }, [dispatch, pageListConfig]);

  return (
    <Wrapper>
      <Row>
        <Col span={7}>
          <Spin spinning={isFetchingList}>
            <Form form={form} initialValues={{}} onFinish={onSubmit}>
              <Row wrap={false}>
                <Select defaultValue={SearchType.BRAND} onChange={onSearchTypeChange}>
                  <Option value={SearchType.BRAND}>品牌名稱</Option>
                  <Option value={SearchType.TAG_ID}>Tag ID</Option>
                </Select>
                <Form.Item label="" name="searchText" noStyle>
                  <CustomInput allowClear />
                </Form.Item>
                <Button type="primary" htmlType="submit">
                  搜尋
                </Button>
              </Row>
            </Form>
            <ListWrapper>
              {brandPageListResult.results.map((item) => (
                <ListItem
                  key={item.id}
                  isActive={parseInt(brandPageId, 10) === item.id}
                  onClick={() => navigate(`/brand-page/edit/${item.id}`)}
                >
                  {item.name}
                </ListItem>
              ))}
              <InfiniteScrollObserver callback={fetchBrandPageListByScroll} />
            </ListWrapper>
          </Spin>
        </Col>
        <Col span={17}>
          {brandPageId ? (
            <ContentWrapper isManageTag={currentTab === "edit" || currentTab === "salespage"} ref={containerRef}>
              <CategoryEdit updateCurrentTab={(key) => setCurrentTab(key)} containerRef={containerRef} />
            </ContentWrapper>
          ) : (
            <EmptyContent />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}
