import { ExclamationCircleOutlined, FilterTwoTone } from "@ant-design/icons";
import { ReviewListItem, ReviewStatusMap } from "@api/reviewApi";
import PhotoModal from "@component/UploadImageButton/PhotoModal";
import { configState } from "@redux/configSlice";
import {
  fetchReviewList,
  resetReviewManagementSlice,
  updateReviewApprovalStatus,
  updateReviewListParams,
  updateSelectedReviewIds,
} from "@redux/reviewManagementSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Modal, Select, Table, Tooltip } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 25px 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div`
  font-size: 14px;
`;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 25px 20px;
  border: 1px solid #f0f0f0;
`;
const ApprovalButtonContainer = styled.div`
  position: absolute;
  left: 20px;
  bottom: 25px;
`;
const ApproveButton = styled(Button)`
  margin-right: 10px;
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;
const EditText = styled.a`
  color: #1890ff;
`;
const EllipsisText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const PhotoImg = styled.img`
  max-width: 30px;
  max-height: 30px;
  cursor: pointer;
`;

const pageSizeOptions = [20, 50, 100];

const ReviewManagement: FC = () => {
  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);
  const { isFetchingList, reviewListResult, reviewListParams, selectedReviewIds } = useSelector(
    (state: RootState) => state.reviewManagement,
  );

  const [showFilter, setShowFilter] = useState<boolean>();
  const [photoSource, setPhotoSource] = useState<string>("");

  const { count, results: reviewList } = reviewListResult;
  const { offset, limit } = reviewListParams;
  const isApproveButtonDisabled = selectedReviewIds.length === 0;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchReviewList());
    return () => {
      dispatch(resetReviewManagementSlice());
    };
  }, [dispatch]);

  const toggleFilter = () => setShowFilter((prev) => !prev);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...reviewListParams,
      limit: value as number,
    };

    dispatch(updateReviewListParams(filter));
    dispatch(fetchReviewList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...reviewListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateReviewListParams(filter));
    dispatch(fetchReviewList());
  };

  const handleOnCheck = (ids: React.Key[]) => {
    dispatch(updateSelectedReviewIds(ids as number[]));
  };

  const handleOnPhotoClick = (url: string) => () => {
    setPhotoSource(url);
  };

  const closePhotoModal = () => {
    setPhotoSource("");
  };

  const approveReviews = () => {
    Modal.confirm({
      title: "你確定要審核通過嗎?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(updateReviewApprovalStatus(1));
      },
      okText: "是",
      cancelText: "否",
    });
  };

  const rejectReviews = () => {
    Modal.confirm({
      title: "你確定要審核不通過嗎?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(updateReviewApprovalStatus(-1));
      },
      okText: "是",
      cancelText: "否",
    });
  };

  const tableColumns: ColumnsType<ReviewListItem> = [
    {
      title: "ID",
      dataIndex: "id",
      width: 70,
    },
    {
      title: "評論時間",
      dataIndex: "submissionAt",
      width: 200,
      render: (value: ReviewListItem["submissionAt"]) => value?.substring(0, 19),
    },
    {
      title: "狀態",
      dataIndex: "approvalStatus",
      width: 100,
      render: (value: ReviewListItem["approvalStatus"]) => ReviewStatusMap[value],
    },
    {
      title: "評論內容",
      dataIndex: "comments",
      width: 340,
      render: (value: ReviewListItem["comments"]) => (
        <Tooltip placement="bottom" title={value}>
          <EllipsisText>{value}</EllipsisText>
        </Tooltip>
      ),
    },
    {
      title: "銷售頁名稱",
      dataIndex: "salesPageName",
      width: 340,
      render: (value: ReviewListItem["salesPageName"], data: ReviewListItem) => (
        <EllipsisText>
          <Link href={`${webDomain}/salespage/${data.salesPageId}`} target="_blank">
            {value}
          </Link>
        </EllipsisText>
      ),
    },
    {
      title: "方案名稱",
      dataIndex: "planName",
      width: 240,
    },
    {
      title: "訂單編號",
      dataIndex: "orderNumber",
      width: 170,
      render: (value: ReviewListItem["orderNumber"], data: ReviewListItem) => (
        <Link href={`/order-content/${data.orderId}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: "會員名稱",
      dataIndex: "memberName",
      width: 130,
    },
    {
      title: "會員信箱",
      dataIndex: "memberEmail",
      width: 240,
    },
    {
      title: "會員電話",
      dataIndex: "memberPhone",
      width: 120,
    },
    {
      title: "圖片",
      dataIndex: "photo",
      width: 70,
      render: (value: ReviewListItem["photo"]) => <PhotoImg src={value} onClick={handleOnPhotoClick(value)} />,
    },
    {
      title: "",
      dataIndex: "id",
      width: 70,
      fixed: "right",
      render: (value: ReviewListItem["id"]) => (
        <EditText href={`/review-management/edit/${value}`} target="_blank">
          編輯
        </EditText>
      ),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="評分評論" />
      <Header>
        <TopButtonContainer>
          <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
            篩選
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={reviewList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedReviewIds,
          }}
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: count,
            onChange: handleOnPageChange,
            showSizeChanger: false,
          }}
          loading={isFetchingList}
        />
        <ApprovalButtonContainer>
          <ApproveButton disabled={isApproveButtonDisabled} onClick={approveReviews}>
            審核通過
          </ApproveButton>
          <Button disabled={isApproveButtonDisabled} onClick={rejectReviews}>
            審核不通過
          </Button>
        </ApprovalButtonContainer>
      </TableContainer>
      {photoSource && <PhotoModal source={photoSource} onClose={closePhotoModal} />}
    </Wrapper>
  );
};

export default ReviewManagement;
