import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { DeductionList } from "@api/throughShipment/throughShipmentApi";
import PageTitle from "@component/PageTitle";
import { dealerState, fetchDeductionFeeList, fetchDeleteDeductionFee, setDeductionFilter } from "@redux/dealerSlice";
import { Button, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditDeductionFeePopup from "./EditDeductionFeePopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;

  .ant-table-tbody {
    .ant-table-placeholder > td {
      border: 0;
    }
  }
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function DeductionFee() {
  const dispatch = useDispatch();
  const { deductionFeeList, deductionFilter } = useSelector(dealerState);

  const [page, setPage] = useState<number>(1);
  const [editDeductionFeePopup, setEditDeductionFeePopup] = useState<boolean>(false);
  const [editDeductionFee, setEditDeductionFee] = useState<DeductionList | undefined>();

  const setPageLimit = (value: SelectValue) => {
    dispatch(setDeductionFilter({ ...deductionFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setDeductionFilter({ ...deductionFilter, offset: (value - 1) * deductionFilter.limit }));
  };

  const onDeleteDeductionFee = (deductionId: number) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchDeleteDeductionFee(deductionId));
      },
    });
  };

  const columns: ColumnsType<DeductionList> = [
    {
      key: "name",
      dataIndex: "name",
      title: "項目名稱",
    },
    {
      key: "rate",
      dataIndex: "rate",
      title: "比例",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "金額",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_, values) => (
        <Row justify="end">
          <Button
            type="link"
            onClick={() => {
              setEditDeductionFee(values);
              setEditDeductionFeePopup(true);
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => onDeleteDeductionFee(values.id)}>
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchDeductionFeeList(deductionFilter));
  }, [dispatch, deductionFilter]);

  return (
    <Wrapper>
      <PageTitle title="通路/經銷商 - 後扣費用" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setEditDeductionFeePopup(true)}
          >
            新增後扣項目
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${deductionFeeList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={deductionFeeList.results}
          pagination={{
            pageSize: deductionFilter.limit,
            current: page,
            showSizeChanger: false,
            total: deductionFeeList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editDeductionFeePopup && (
        <EditDeductionFeePopup
          deduction={editDeductionFee}
          onClose={() => {
            setEditDeductionFee(undefined);
            setEditDeductionFeePopup(false);
          }}
        />
      )}
    </Wrapper>
  );
}
