/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactNode, ReactText } from "react";
import { StandardResponse } from "src/types";
import normalizeMenu from "./utils/normalizeMenu";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

export interface MenuNode {
  key: ReactText;
  id: ReactText;
  title: string | ReactNode;
  parent: ReactText;
  isActive: boolean;

  children: MenuNode[];
  level: number;
  isLeaf?: boolean;
  isAddButton?: boolean;
  label?: string;
  name: string;
  value?: number;
  openingNewTab: boolean;
  link: string;
}
export interface MenuDetail {
  id: number;
  name: string;
  path: string;
  parent: number;
  level: number;
  isActive: boolean;
  link: string;
  openingNewTab: boolean;
  children?: [];
}

export interface CreateMenuParam {
  isActive: boolean;
  name: string;
  parent: number;
  openingNewTab: boolean;
  link: string;
}
export interface UpdateMenuParam {
  isActive: boolean;
  name?: string;
  parent: number;
  openingNewTab: boolean;
  link?: string;
  id: number;
}

export interface MoveMenuParam {
  leftSibling?: number;
  rightSibling?: number | string;
  parent: number;
}
export interface MenuListResult {
  count: number;
  next: string;
  previous: string;
  results: [];
}

interface IMenuMangementApi {
  fetchMenuList: (displayInactive: boolean) => Promise<MenuNode[]>;
  fetchMenuDetail: (id: number) => Promise<MenuDetail>;
  fetchCreateMenu: (params: CreateMenuParam) => Promise<MenuDetail>;
  fetchUpdateMenu: (params: UpdateMenuParam) => Promise<MenuDetail>;
  fetchMoveMenu: (menuId: number, params: MoveMenuParam) => Promise<void>;
  fetchDeleteMenu: (menuId: number) => void;
}

const MenuMangementApi: IMenuMangementApi = {
  fetchMenuList: async (displayInactive) => {
    const response = await apiClient.getData("/manage/sales/category-content/", {
      display_inactive: displayInactive ? 1 : 0,
    });

    return normalizeMenu.list(response.data.result);
  },
  fetchMenuDetail: async (id) => {
    const response = await apiClient.getData(`/manage/sales/category-content/${id}/`, {
      with_sort_method: 1,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateMenu: async (params) => {
    const { isActive, name, parent, openingNewTab, link } = params;

    const postParams = {
      is_active: isActive,
      name,
      parent: parent > -1 ? parent : 1,
      opening_new_tab: openingNewTab,
      link,
    };
    const response = await apiClient.postData("/manage/sales/category-content/", postParams);

    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateMenu: async (params) => {
    const { isActive, name, openingNewTab, link, id } = params;

    const patchParams = {
      is_active: isActive,
      name,
      opening_new_tab: openingNewTab,
      link,
    };
    const response = await apiClient.patchData(`/manage/sales/category-content/${id}/`, patchParams);

    return transformAPIKeyToCamel(response.data.result);
  },

  fetchMoveMenu: async (menuId, params) => {
    const requestParams = {
      left_sibling: params.leftSibling,
      right_sibling: params.rightSibling,
      parent: params.parent,
    };

    await apiClient.putData(`/manage/sales/category-content/${menuId}/move/`, requestParams);
  },

  fetchDeleteMenu: async (menuId: number) => {
    await apiClient.deleteData(`/manage/sales/category-content/${menuId}/`, {});
  },
};

export default MenuMangementApi;
