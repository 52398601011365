import { clearReplenishReturnListFilter, orderState, setReplenishReturnListFilter } from "@redux/orderSlice";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { ApprovalStatusFilterMap } from "../ApprovedState";
import ProcessingType, { ProcessingTypeMap } from "../ProcessingType";
import VendorLogisticsValue from "../VendorLogisticsValue";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;

type Props = {
  isVisible: boolean;
};

const timeRange = [
  {
    label: "今天",
    value: "today",
  },
  {
    label: "昨天",
    value: "yesterday",
  },
  {
    label: "上週",
    value: "lastWeek",
  },
  {
    label: "上個月",
    value: "lastMonth",
  },
  {
    label: "最近7天",
    value: "last7days",
  },
  {
    label: "最近30天",
    value: "last30days",
  },
  {
    label: "自訂",
    value: "custom",
  },
];

export default function ReplenishReturnFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { replenishReturnListFilter } = useSelector(orderState);

  const [form] = Form.useForm();

  const onChangeTimeMethod = (fieldName: "approvalDate" | "applyingDate", value: string) => {
    let timeset: ReturnType<typeof moment>[];

    switch (value) {
      case "today":
        timeset = [moment(), moment()];
        break;
      case "yesterday":
        timeset = [moment().subtract(1, "d"), moment().subtract(1, "d")];
        break;
      case "lastWeek":
        timeset = [moment().weekday(-6), moment().weekday(0)];
        break;
      case "lastMonth":
        timeset = [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")];
        break;
      case "last7days":
        timeset = [moment().subtract(6, "days"), moment()];
        break;
      case "last30days":
        timeset = [moment().subtract(29, "days"), moment()];
        break;
      default:
        timeset = [];
        break;
    }

    const values = form.getFieldsValue();
    form.setFieldsValue({
      ...values,
      [fieldName]: timeset,
    });
  };

  const onReset = () => {
    form.resetFields();
    dispatch(clearReplenishReturnListFilter());
  };

  const onSubmit = (values: any) => {
    dispatch(
      setReplenishReturnListFilter({
        ...replenishReturnListFilter,
        orderNumber: values.orderNumber,
        processingType: values.processingType > 0 ? values.processingType : undefined,
        status: values.status,
        name: values.name,
        phone: values.phone,
        logistic: values.logistic > 0 ? values.logistic : undefined,
        applyingDate: values.applyingDate
          ? [moment(values.applyingDate[0]).format("YYYY-MM-DD"), moment(values.applyingDate[1]).format("YYYY-MM-DD")]
          : ["", ""],
        approvalDate: values.approvalDate
          ? [moment(values.approvalDate[0]).format("YYYY-MM-DD"), moment(values.approvalDate[1]).format("YYYY-MM-DD")]
          : ["", ""],
      }),
    );
  };

  return (
    <Wrapper
      colon={false}
      form={form}
      isVisible={isVisible}
      initialValues={{
        orderNumber: undefined,
        processingType: undefined,
        status: undefined,
        name: undefined,
        phone: undefined,
        logistic: undefined,
        applyingDate: undefined,
        approvalDate: undefined,
        applyMethod: undefined,
        releaseMethod: undefined,
      }}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="訂單編號" name="orderNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="類別" name="processingType">
            <Select defaultValue="-100">
              <Select.Option value="-100">請選擇</Select.Option>
              {Object.keys(ProcessingTypeMap)
                .filter((key) => Number(key) === ProcessingType.REPLENISHMENT || Number(key) === ProcessingType.RETURN)
                .map((key) => (
                  <Select.Option key={key} value={key}>
                    {ProcessingTypeMap[parseInt(key, 10)]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="狀態" name="status">
            <Select defaultValue="-100">
              <Select.Option value="-100">請選擇</Select.Option>
              {Object.keys(ApprovalStatusFilterMap).map((key) => (
                <Select.Option key={key} value={key}>
                  {ApprovalStatusFilterMap[parseInt(key, 10)]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="會員名稱" name="name">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="會員電話" name="phone">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="出貨點" name="logistic">
            <Select defaultValue="-100">
              <Select.Option value="-100">請選擇</Select.Option>
              <Select.Option value={VendorLogisticsValue.warehouse}>倉庫</Select.Option>
              <Select.Option value={VendorLogisticsValue.transfer}>廠商</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={4}>
          <Form.Item label="申請時間" name="applyMethod">
            <Select defaultValue="custom" onChange={(value) => onChangeTimeMethod("applyingDate", value)}>
              {timeRange.map((time) => (
                <Select.Option key={time.value} value={time.value}>
                  {time.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="" name="applyingDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="放行時間" name="releaseMethod">
            <Select defaultValue="custom" onChange={(value) => onChangeTimeMethod("approvalDate", value)}>
              {timeRange.map((time) => (
                <Select.Option key={time.value} value={time.value}>
                  {time.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="" name="approvalDate">
            <CustomRangePicker locale={locale} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
