/* eslint-disable camelcase */
import type { StaffListResult } from "@api/staffApi";

interface INormalizeStaff {
  list: (res: StaffListRes) => StaffListResult;
}

interface StaffListRes {
  count: number;
  next: string;
  previous: string;
  results: {
    id: number;
    name: string;
    username: string;
    first_name: string;
    last_name: string;
    email: string;
    is_active: boolean;
    created_at: string;
  }[];
}

const nomalizeStaff: INormalizeStaff = {
  list: (res) => {
    return {
      ...res,
      results: res.results.map((item) => ({
        id: item.id,
        name: item.name,
        username: item.username,
        firstName: item.first_name,
        lastName: item.last_name,
        email: item.email,
        isActive: item.is_active,
        createdAt: item.created_at,
      })),
    };
  },
};

export default nomalizeStaff;
