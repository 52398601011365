import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { ImportInventorySheetInfo } from "@api/sharing/inventorySheetApi";
import { SpecialShipmentListParams } from "@api/sharing/specialShipmentApi";
import ImportFileModal from "@component/ImportFileModal";
import PageTitle from "@component/PageTitle";
import PopupBackground from "@component/PopupBackground";
import IntegratedTable from "@component/Table/IntegratedTable";
import { warehouseCodeOptions } from "@page/SpecialShipment/specialShipmentConstants";
import {
  deleteInventorySheet,
  fetchInventorySheetList,
  inventorySheetState,
  importInventorySheet,
  resetSlice,
  updateListParams,
  updateImportErrorResults,
} from "@redux/inventorySheetSlice";
import { Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Spin } from "antd";
import { RangePickerProps } from "antd/lib/date-picker";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ModalWrapper, StyledSection, TableWrapper } from "src/styles/common";
import styled from "styled-components";
import InventorySheetFilter, { inventorySheetTypeOptions } from "./InventorySheetFilter";

const ModalContent = styled.section`
  max-height: 150px;
  overflow: auto;
`;

export default function InventorySheetList() {
  const dispatch = useDispatch();
  const [openFilter, setOpenFilter] = useState(true);
  const [createModalStep, setCreateModalStep] = useState<string | undefined>();

  const { isFetching, inventorySheetList, inventorySheetListParams, importErrorResults } = useSelector(
    inventorySheetState,
  );
  const { results: inventorySheetListResults, count } = inventorySheetList;
  const handleOnDeleteItem = (shipmentId: number, orderNumber: number) => {
    Modal.confirm({
      title: `你確定要作廢這筆資料：${orderNumber}`,
      icon: <ExclamationCircleOutlined />,
      onOk() {
        dispatch(deleteInventorySheet(shipmentId));
      },
      okText: "確定",
      cancelText: "取消",
    });
  };

  const checkDisabledRow = (record: any) => ({ className: record.destroyedAt ? "ur-disabled-text" : "" });

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    const { column, field, order } = sorter;
    if (Object.keys(sorter).length === 0) return;
    let params;
    if (column) {
      const snakeField = field.replace(/([A-Z])/g, "_$1").toLowerCase();
      const ordering = order === "ascend" ? "" : "-";
      params = { ...inventorySheetListParams, ordering: `${ordering}${snakeField}` };
      dispatch(updateListParams(params));
    } else {
      params = { ...inventorySheetListParams, ordering: undefined };
      dispatch(updateListParams(params));
    }
    dispatch(fetchInventorySheetList(params));
  };

  const handleOnImportSheet = (info: ImportInventorySheetInfo, file: File) => {
    dispatch(importInventorySheet({ info, file }));
    setCreateModalStep(undefined);
  };

  const columns: ColumnsType<any> = [
    {
      title: "單號",
      dataIndex: "orderNumber",
    },
    {
      title: "倉庫",
      dataIndex: "warehouseName",
    },
    {
      title: "類型",
      dataIndex: "purposeType",
      align: "center",
      render: (type: number) => {
        return inventorySheetTypeOptions.find((option) => option.value === type)?.label;
      },
    },
    {
      title: "盤點日期",
      dataIndex: "invTakingDate",
      sorter: true,
    },
    {
      title: "建立時間",
      dataIndex: "createdAt",
      sorter: true,
    },
    {
      title: "建立人員",
      dataIndex: "staff",
    },
    {
      title: "提交時間",
      dataIndex: "submittedAt",
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (inventoryId: number, record) => {
        return (
          <>
            <Button
              type="link"
              onClick={() => {
                window.open(`/inventory/${inventoryId}/review`);
              }}
            >
              檢視
            </Button>
            <Button
              type="link"
              onClick={() => {
                handleOnDeleteItem(inventoryId, record.orderNumber);
              }}
              disabled={!!record.destroyedAt || !!record.submittedAt}
            >
              作廢
            </Button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchInventorySheetList(inventorySheetListParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (importErrorResults) {
      Modal.warning({
        title: "部分商品資料有誤，請修正",
        content: (
          <ModalContent>
            {importErrorResults.map((errorItem: any) => {
              return <p>{errorItem}</p>;
            })}
          </ModalContent>
        ),
        okText: "我知道了",
        onOk: () => {
          dispatch(updateImportErrorResults(undefined));
        },
      });
    }
  }, [dispatch, importErrorResults]);

  useEffect(() => {
    return () => {
      dispatch(resetSlice());
    };
  }, [dispatch]);

  return (
    <StyledSection>
      <PageTitle title="盤盈盤虧/報廢" />
      <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => {
                setCreateModalStep("infoForm");
              }}
            >
              匯入盤點單
            </Button>
            <Button
              icon={<FilterTwoTone />}
              onClick={() => {
                setOpenFilter(!openFilter);
              }}
            >
              篩選
            </Button>
          </Space>
        </Col>
      </Row>
      <InventorySheetFilter open={openFilter} />
      <TableWrapper>
        <IntegratedTable
          isPagination
          paginationConfig={{
            totalCount: count,
            pageSizeOptions: [50, 100, 200],
            offset: inventorySheetListParams.offset,
            limit: inventorySheetListParams.limit,
            fetchList: (filter: SpecialShipmentListParams) => {
              dispatch(fetchInventorySheetList(filter));
            },
            updateFilterParams: (filter: SpecialShipmentListParams) => {
              dispatch(updateListParams(filter));
            },
            filterParams: inventorySheetListParams,
          }}
          dataSource={inventorySheetListResults}
          loading={isFetching}
          columns={columns}
          scroll={{ x: "max-content" }}
          onRow={checkDisabledRow}
          onChange={handleTableChange}
        />
      </TableWrapper>
      {createModalStep && (
        <CreateModal step={createModalStep} setStep={setCreateModalStep} onSubmit={handleOnImportSheet} />
      )}
    </StyledSection>
  );
}

type CreateModalProps = {
  step: string;
  setStep: (step: string | undefined) => void;
  onSubmit: (values: ImportInventorySheetInfo, file: File) => void;
};

const CreateModal = (props: CreateModalProps) => {
  const { step, setStep, onSubmit } = props;
  const [form] = Form.useForm();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  const onInventoryDateChange = (date: any, dateString: string) => {
    form.setFieldsValue({ invTakingDate: dateString });
  };
  const handleFormSubmit = () => {
    setStep("upload");
  };
  const handleOnFinalUpload = (file: File) => {
    const values = form.getFieldsValue();
    const { date, ...restValues } = values;
    onSubmit(restValues, file);
  };
  const handleOnClose = () => {
    setStep(undefined);
  };
  return (
    <PopupBackground close={() => {}}>
      <ModalWrapper width="400px">
        <Form form={form} colon={false} labelCol={{ span: 8 }} labelAlign="left" onFinish={handleFormSubmit}>
          <Spin spinning={false}>
            <Form.Item label="倉庫" name="warehouseCode" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <Select options={warehouseCodeOptions} />
            </Form.Item>
            <Form.Item label="類型" name="purposeType" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <Select options={inventorySheetTypeOptions} />
            </Form.Item>
            <Form.Item label="盤點日期" name="date" labelAlign="left" rules={[{ required: true, message: "" }]}>
              <DatePicker disabledDate={disabledDate} onChange={onInventoryDateChange} />
            </Form.Item>
            <Form.Item label="備註" name="comments" labelAlign="left" rules={[{ max: 30, message: "" }]}>
              <Input placeholder="最多輸入 30 字" />
            </Form.Item>
            <Form.Item label="盤點日期value" name="invTakingDate" noStyle />
            <Row justify="end">
              <Space>
                <Button type="default" onClick={() => handleOnClose()}>
                  取消
                </Button>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </Space>
            </Row>
          </Spin>
        </Form>
      </ModalWrapper>
      {step === "upload" && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => setStep(undefined)}
          clearErrorMessage={() => {}}
          handleUploadFile={handleOnFinalUpload}
          templatePath="admin/template/xls/inventory_template.xlsx"
          fileType=".xlsx"
        />
      )}
    </PopupBackground>
  );
};
