import { getCurrencySelectItems } from "@constant/CurrencyValue";
import {
  manufacturerState,
  addManufacturer,
  updateManufacturer,
  fetchManufacturerDetail,
} from "@redux/brandOperation/manufacturerSlice";
import { RootState } from "@redux/rootReducer";
import { fetchTryallOperationStaff } from "@redux/supplyChainSlice";
import { Form, Input, Select, Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { TabContentWrapper, Footer } from "src/styles/common";

const InfoTab = (props: { mode: string }) => {
  const { mode } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: manufacturerId } = useParams();
  const [form] = useForm();

  const { manufacturerInfo } = useSelector(manufacturerState);
  const { TryallStaffList } = useSelector((state: RootState) => state.supplyChain);

  const staffOptions = useMemo(() => {
    return TryallStaffList.results.map((item) => {
      return { value: item.id, label: item.name, text: item.id };
    });
  }, [TryallStaffList.results]);

  const filterOption = (input: string, option: any) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();
    if (mode === "add") {
      dispatch(addManufacturer(formValues));
      return;
    }
    if (mode === "edit") {
      dispatch(updateManufacturer(formValues));
    }
  };

  useEffect(() => {
    if (!TryallStaffList.results.length) dispatch(fetchTryallOperationStaff());
  }, [TryallStaffList.results.length, dispatch]);

  useEffect(() => {
    // 新增完拿到新的id 要導去edit頁
    if (mode === "add" && manufacturerInfo.id) {
      navigate(`/operation-of-brand/manufacturer/${manufacturerInfo.id}`);
    }
    if (mode === "edit") {
      if (manufacturerInfo.id) {
        form.setFieldsValue({
          ...manufacturerInfo,
          staff: manufacturerInfo.staff.id || undefined,
        });
      } else {
        dispatch(fetchManufacturerDetail(Number(manufacturerId)));
      }
    }
  }, [dispatch, form, manufacturerId, manufacturerInfo, mode, navigate]);

  return (
    <TabContentWrapper>
      <Form
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 400 }}
        onFinish={handleSubmit}
      >
        <Form.Item label="ID" name="id" noStyle>
          <Input hidden />
        </Form.Item>
        <Form.Item label="營運負責人" name="staff" rules={[{ required: true, message: "請填入 營運負責人" }]}>
          <Select
            showSearch
            placeholder="請選擇"
            optionFilterProp="label"
            filterOption={filterOption}
            options={staffOptions}
          />
        </Form.Item>
        <Form.Item label="製造商名稱" name="name" rules={[{ required: true, message: "請填入 製造商名稱" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="製造商地址" name="address" rules={[{ required: true, message: "請填入 製造商地址" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="製造商電話" name="phone" rules={[{ required: true, message: "請填入 製造商電話" }]}>
          <Input />
        </Form.Item>
        <Form.Item
          label="製造商統編"
          name="ubn"
          rules={[
            { required: true, message: "請填入 製造商統編" },
            { pattern: /^\d{8}$/, message: "請確認統編正確" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="帳戶名稱" name="accountName" rules={[{ required: true, message: "請填入 帳戶名稱" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="帳戶號碼" name="accountNumber" rules={[{ required: true, message: "請填入 帳戶號碼" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="銀行名稱" name="bankName" rules={[{ required: true, message: "請填入 銀行名稱" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="分行名稱" name="bankBranch">
          <Input />
        </Form.Item>
        <Form.Item label="付款幣別" name="currency" rules={[{ required: true, message: "請填入 付款幣別" }]}>
          <Select placeholder="請選擇" options={getCurrencySelectItems()} />
        </Form.Item>
      </Form>
      <Footer>
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}
        >
          儲存此分頁
        </Button>
      </Footer>
    </TabContentWrapper>
  );
};

export default InfoTab;
