import { ContractType, RegionType } from "@api/throughShipment/throughShipmentApi";
import { dealerState, fetchRetailList, fetchRetailRegion, setDealerFilter } from "@redux/dealerSlice";
import { AutoComplete, Button, Col, Form, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function DealerFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { retailRegion, retailList, dealerFilter } = useSelector(dealerState);
  const [retailerId, setRetailerId] = useState<number>();

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(
      setDealerFilter({
        ...dealerFilter,
        regionType: undefined,
        regionName: undefined,
        contractType: undefined,
        retailer: undefined,
      }),
    );
  };

  const onSubmit = (values: any) => {
    dispatch(setDealerFilter({ ...dealerFilter, ...values, retailer: retailerId }));
  };

  useEffect(() => {
    dispatch(fetchRetailRegion({}));
    dispatch(fetchRetailList({}));
  }, [dispatch]);

  const options = retailList.results.map((item) => {
    return { value: item.name, label: item.name, id: item.id };
  });

  const handleSelectId = (value: any) => {
    const selectedRetailerId = retailList && retailList.results.find((item) => item.name === value)?.id;
    setRetailerId(selectedRetailerId);
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 10 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="合作通路類型" name="regionType" wrapperCol={{ span: 18 }}>
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={RegionType.DOMESTIC_CHANNEL}>台灣本地通路</Select.Option>
              <Select.Option value={RegionType.DOMESTIC_DISTRIBUTOR}>台灣本地經銷</Select.Option>
              <Select.Option value={RegionType.FOREIGN}>境外通路</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="通路名稱" name="retailer" wrapperCol={{ span: 18 }}>
            <AutoComplete
              allowClear
              options={options}
              placeholder="請選擇"
              filterOption={(inputValue, option) =>
                option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
              onChange={(e) => handleSelectId(e)}
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="地區" name="regionName" wrapperCol={{ span: 18 }}>
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              {retailRegion.results.map((item) => (
                <Select.Option value={item.name}>{item.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="合作模式" name="contractType" wrapperCol={{ span: 18 }}>
            <Select defaultValue="-100">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={ContractType.BUYOUT_REFUNDABLE}>買斷可退</Select.Option>
              <Select.Option value={ContractType.BUY_AND_NOT_RETURN}>買斷不可退</Select.Option>
              <Select.Option value={ContractType.CONSIGNMENT}>寄倉</Select.Option>
              <Select.Option value={ContractType.TRANSFER}>轉單</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={18}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
