import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface IDashboardApi {
  fetchRevenueDashboard: (params: DashboardParams) => Promise<RevenueDashboard>;
  fetchRevenueChart: (params: ChartParams) => Promise<RevenueChart>;
  fetchGrossMarginDashboard: (params: DashboardParams) => Promise<GrossMarginDashboard>;
  fetchGrossMarginChart: (params: ChartParams) => Promise<GrossMarginChart>;
  fetchCategorySalesDashboard: (params: DashboardParams) => Promise<CategorySalesDashboard>;
  fetchCategorySalesChart: (params: ChartParams) => Promise<CategorySalesChart>;
  fetchOrderStatusDashboard: (params: OrderStatusDashboardParams) => Promise<OrderStatusDashboard>;
  fetchOrderStatusChart: (params: OrderStatusChartParams) => Promise<OrderStatusChart>;
  fetchMemberDistribution: (params?: MemberDistributionParams) => Promise<MemberDistribution>;
  fetchWarehouseDashboard: (params: DashboardParams) => Promise<WarehouseDashboard>;
  fetchWarehouseChart: (params: ChartParams) => Promise<WarehouseChart>;
  fetchShipmentProgress: () => Promise<ShipmentProgress>;
  fetchShipmentProgressOrderList: (params: ShipmentProgressOrderListParams) => Promise<ShipmentProgressOrderListResult>;
  fetchNewMemberDashboard: (params: DashboardParams) => Promise<NewMemberDashboard>;
  fetchNewMemberChart: (params: ChartParams) => Promise<NewMemberChart>;
  fetchMemberRevenueDashboard: (params: MemberRevenueDashboardParams) => Promise<MemberRevenueDashboard>;
  fetchMemberRevenueChart: (params: MemberRevenueChartParams) => Promise<MemberRevenueChart>;
  fetchVipMemberStatusDashboard: (params: DashboardParams) => Promise<VipMemberStatusDashboard>;
  fetchVipMemberStatusChart: (params: ChartParams) => Promise<VipMemberStatusChart>;
  fetchSalesRank: (params: FetchSalesRankParams) => Promise<SalesRankItem[]>;
  fetchVipFinanceDashboard: (params: DashboardParams) => Promise<VipFinanceDashboard>;
  fetchVipFinanceChart: (params: ChartParams) => Promise<VipFinanceChart>;
}

export interface MemberDistributionParams {
  startDate: string;
  endDate: string;
}
export interface DashboardParams {
  startDate: string;
  endDate: string;
  cStartDate: string;
  cEndDate: string;
}

export interface ChartParams {
  startDate: string;
  endDate: string;
  cStartDate: string;
  cEndDate: string;
  timeUnit: GroupByValue;
}

export enum GroupByValue {
  HOUR = "hour",
  DAY = "day",
  MONTH = "month",
  YEAR = "year",
}

export interface DashboardResponse<T, U> {
  currentData?: T;
  compareData?: T;
  diff?: U;
}
export interface ChartResponse<T> {
  currentData: T[];
  compareData?: T[];
}
export interface RevenueDashboardItem {
  order: number;
  webOrder: number;
  appOrder: number;
  revenue: number;
  webRevenue: number;
  appRevenue: number;
  avgOrderPrice: number;
  webAvgOrderPrice: number;
  appAvgOrderPrice: number;
}
export interface RevenueDashboardDiff {
  orderDiff: number;
  webOrderDiff: number;
  appOrderDiff: number;
  revenueDiff: number;
  webRevenueDiff: number;
  appRevenueDiff: number;
  avgOrderPriceDiff: number;
  webAvgOrderPriceDiff: number;
  appAvgOrderPriceDiff: number;
}
export interface RevenueChartItem extends RevenueDashboardItem {
  date: string;
}
export type RevenueDashboard = DashboardResponse<RevenueDashboardItem, RevenueDashboardDiff>;
export type RevenueChart = ChartResponse<RevenueChartItem>;

export interface OrderStatusDashboardParams extends DashboardParams {
  status: "return" | "cancel";
}
export interface OrderStatusChartParams extends ChartParams {
  status: "return" | "cancel";
}
export interface OrderStatusDashboardItem {
  count: number;
  avgAmount: number;
  rate: number;
}
export interface OrderStatusDashboardDiff {
  countDiff: number;
  avgAmountDiff: number;
  rateDiff: number;
}
export interface OrderStatusChartItem extends OrderStatusDashboardItem {
  date: string;
}
export type OrderStatusDashboard = DashboardResponse<OrderStatusDashboardItem, OrderStatusDashboardDiff>;
export type OrderStatusChart = ChartResponse<OrderStatusChartItem>;

export interface CategorySalesDashboardItem {
  grossProfit: number;
  revenue: number;
  categoryName: string;
}
export interface CategorySalesDashboardDiff {
  grossProfitDiff: number;
  revenueDiff: number;
  categoryName: string;
}
export interface CategorySalesChartItem extends CategorySalesDashboardItem {
  date: string;
}
export type CategorySalesDashboard = DashboardResponse<CategorySalesDashboardItem[], CategorySalesDashboardDiff[]>;
export type CategorySalesChart = ChartResponse<CategorySalesChartItem>;

export interface WarehouseDashboardItem {
  sendErrRate: number;
  damagedRate: number;
}
export interface WarehouseDashboardDiff {
  sendErrRateDiff: number;
  damagedRateDiff: number;
}
export interface WarehouseChartItem extends WarehouseDashboardItem {
  date: string;
}
export type WarehouseDashboard = DashboardResponse<WarehouseDashboardItem, WarehouseDashboardDiff>;
export type WarehouseChart = ChartResponse<WarehouseChartItem>;

export interface NewMemberDashboardItem {
  registerMember: number;
  registerMemberBuy: number;
  memberBuyRate: number;
}
export interface NewMemberDashboardDiff {
  registerMemberDiff: number;
  registerMemberBuyDiff: number;
  memberBuyRateDiff: number;
}
export interface NewMemberChartItem extends NewMemberDashboardItem {
  date: string;
}
export type NewMemberDashboard = DashboardResponse<NewMemberDashboardItem, NewMemberDashboardDiff>;
export type NewMemberChart = ChartResponse<NewMemberChartItem>;

export interface MemberRevenueDashboardParams extends DashboardParams {
  customerType: MemberOptionValue;
}
export interface MemberRevenueChartParams extends ChartParams {
  customerType: MemberOptionValue;
}
export interface MemberRevenueDashboardItem {
  revenue: number;
  order: number;
  avgOrderPrice: number;
}
export interface MemberRevenueDashboardDiff {
  revenueDiff: number;
  orderDiff: number;
  avgOrderPriceDiff: number;
}
export interface MemberRevenueChartItem extends MemberRevenueDashboardItem {
  date: string;
}
export type MemberRevenueDashboard = DashboardResponse<MemberRevenueDashboardItem, MemberRevenueDashboardDiff>;
export type MemberRevenueChart = ChartResponse<MemberRevenueChartItem>;

export interface VipMemberStatusDashboardItem {
  firstBuyCount: number;
  renewCount: number;
  cancelCount: number;
}
export interface VipMemberStatusDashboardDiff {
  firstBuyCountDiff: number;
  renewCountDiff: number;
  cancelCountDiff: number;
}
export interface VipMemberStatusChartItem extends VipMemberStatusDashboardItem {
  date: string;
}
export type VipMemberStatusDashboard = DashboardResponse<VipMemberStatusDashboardItem, VipMemberStatusDashboardDiff> & {
  totalVipMembers: number;
  totalCancelMembers: number;
};
export type VipMemberStatusChart = ChartResponse<VipMemberStatusChartItem>;

export interface GrossMarginDashboardItem {
  grossMargin: number;
}
export interface GrossMarginDashboardDiff {
  grossMarginDiff: number;
}
export interface GrossMarginChartItem extends GrossMarginDashboardItem {
  date: string;
}
export type GrossMarginDashboard = DashboardResponse<GrossMarginDashboardItem, GrossMarginDashboardDiff>;
export type GrossMarginChart = ChartResponse<GrossMarginChartItem>;

export interface VipFinanceDashboardItem {
  grossMargin: number;
  revenue: number;
  cost: number;
}
export interface VipFinanceDashboardDiff {
  grossMarginDiff: number;
  revenueDiff: number;
  costDiff: number;
}
export interface VipFinanceChartItem extends VipFinanceDashboardItem {
  date: string;
}
export type VipFinanceDashboard = DashboardResponse<VipFinanceDashboardItem, VipFinanceDashboardDiff>;
export type VipFinanceChart = ChartResponse<VipFinanceChartItem>;

export type ChartResultType =
  | RevenueChart
  | GrossMarginChart
  | OrderStatusChart
  | CategorySalesChart
  | WarehouseChart
  | NewMemberChart
  | MemberRevenueChart
  | VipMemberStatusChart
  | VipFinanceChart;

export interface MemberDistribution {
  totalMember: number;
  memberNeverBuy: number;
  memberBuyOnce: number;
  memberBuyTwiceGte: number;
}

export interface ShipmentProgress {
  transferShippingDelay: number;
  wareshouseShippingDelay: number;
  warehouseShipmentDelay: number;
}

export interface ShipmentProgressOrderListParams {
  limit: number;
  offset: number;
  orderType: ShipmentProgressOrderType;
}

export interface ShipmentProgressOrderListResult {
  count: number;
  next: string;
  previous: string;
  results: ShipmentProgressOrderItem[];
}

export interface ShipmentProgressOrderItem {
  id: number;
  orderId: number;
  processingType: string;
  shippingStatus: string;
  createdAt: string;
}

export interface FetchSalesRankParams {
  memberType?: MemberOptionValue;
  ordering?: SalesRankOrderingValue;
  startDate: string;
  endDate: string;
}

export interface SalesRankItem {
  name: string;
  revenue: number;
  sku: string;
  grossMargin: number;
  salesVolume: number;
}

export type SalesRankOrderingValue =
  | "revenue"
  | "salesVolume"
  | "grossMargin"
  | "-revenue"
  | "-salesVolume"
  | "-grossMargin"
  | undefined;

export enum MemberOptionValue {
  ALL,
  NEW,
  OLD,
  VIP,
}

export enum ShipmentProgressOrderType {
  TRANSFER_SHIPPING_DELAY = 1,
  WARESHOUSE_SHIPPING_DELAY = 2,
  WAREHOUSE_SHIPMENT_DELAY = 3,
}

const dashboardApi: IDashboardApi = {
  fetchRevenueDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/sales/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRevenueChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/sales/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchGrossMarginDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/gross_margin/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchGrossMarginChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/gross_margin/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCategorySalesDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/category-sales/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCategorySalesChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales/category-sales/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchOrderStatusDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-service/order-status/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchOrderStatusChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-service/order-status/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchMemberDistribution: async (params) => {
    const getParams = params ? transformCamelToSnake(params) : {};
    const response = await apiClient.getData("/manage/dashboard-member/member-distribution/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchWarehouseDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-service/warehouse/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchWarehouseChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-service/warehouse/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchShipmentProgress: async () => {
    const response = await apiClient.getData("/manage/dashboard-shipment-progress/", {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchShipmentProgressOrderList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-shipment-progress/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchNewMemberDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/new-member-info/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchNewMemberChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/new-member-info/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchMemberRevenueDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/member-revenue/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchMemberRevenueChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/member-revenue/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipMemberStatusDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/member-status/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipMemberStatusChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/member-status/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSalesRank: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-sales-rank/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipFinanceDashboard: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/vip-finance/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVipFinanceChart: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/dashboard-member/vip-finance/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default dashboardApi;
