import CoreAPI from "./CoreAPI";
import normalizeTag from "./utils/normalizeTag";

const apiClient = new CoreAPI();

export interface FetchTagListParam {
  limit: number;
  offset: number;
  nameQ?: string;
  typeNameQ?: string;
  categoryId?: number;
  promotionTagId?: number;
  promotionId?: number;
}

export interface TagListResult {
  count: number;
  next: string;
  previous: string;
  results: TagListItem[];
}

export interface TagListItem {
  id: number;
  tagType: string;
  name: string;
  icon: number;
  isActive: boolean;
}
export interface TagTypeListResult {
  count: number;
  next: string;
  previous: string;
  results: TagTypeItem[];
}
export interface TagTypeItem {
  id: number;
  name: string;
}
export interface TagTypeListParam {
  limit: number;
  offset: number;
}

interface ITagApi {
  fetchTagList: (param: FetchTagListParam) => Promise<TagListResult>;
  fetchTagTypeList: (param: TagTypeListParam) => Promise<TagTypeListResult>; // 屬性
}

const tagApi: ITagApi = {
  fetchTagList: async (param) => {
    const getParam = {
      limit: param.limit,
      offset: param.offset,
      name_q: param.nameQ === "" ? undefined : param.nameQ,
      type_name_q: param.typeNameQ,
      categoryId: param.categoryId,
      promotionId: param.promotionId,
      promotionTagId: param.promotionTagId,
    };

    const response = await apiClient.getData("/manage/tag/tags", getParam);
    return normalizeTag.list(response.data.result);
  },
  fetchTagTypeList: async (param) => {
    const response = await apiClient.getData("/manage/tag/tag-types", param);
    return response.data.result;
  },
};

export default tagApi;
