import { ExclamationCircleFilled } from "@ant-design/icons";
import { CreateProductDetailParam, ProductDetail, SyncMethodValue, UpdateProductDetailParam } from "@api/productApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import {
  createProduct,
  fetchBrandList,
  fetchProductDetail,
  loadMoreBrandList,
  syncToWarehouse,
  updateProduct,
} from "@redux/productEditSlice";
import { RootState } from "@redux/rootReducer";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Form, Input, InputNumber, Modal, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-left: 16px;
`;
const Flex = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  width: 115px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
`;
const RowBoldTitle = styled(RowTitle)`
  font-weight: 700;
  margin-bottom: 12px;
`;
const RowGroup = styled.div`
  margin: 12px 0;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const SyncToWarehouseButton = styled(Button)`
  margin-right: 10px;
`;
const SyncToWarehouseText = styled.div`
  padding: 0 11px;
  font-size: 14px;
`;
const Annotation = styled.span`
  margin-left: 11px;
  font-size: 14px;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.25);
`;

interface Props {
  mode: "add" | "edit";
}

const statusList: any = [
  {
    label: "上架",
    value: true,
  },
  {
    label: "下架",
    value: false,
  },
];
const syncMethodList = [
  {
    label: "同步",
    value: SyncMethodValue.SYNC,
  },
  {
    label: "不同步",
    value: SyncMethodValue.ASYNC,
  },
  {
    label: "轉單不同步",
    value: SyncMethodValue.ASYNC_TRANSFER_ORDER,
  },
];

const BasicInfo: FC<Props> = (props) => {
  const { mode } = props;
  const [form] = Form.useForm<ProductDetail>();
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const productDetail = useSelector((state: RootState) => state.productEdit.productDetail);
  const brandListResult = useSelector((state: RootState) => state.productEdit.brandListResult);
  const staffList = useSelector((state: RootState) => state.staffs.bdStaffData);

  useEffect(() => {
    dispatch(fetchBrandList(""));
  }, [dispatch]);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  const staffOptions = useMemo(() => {
    const options = staffList.map((staff) => (
      <Select.Option key={staff.id} value={staff.id}>
        {staff.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    return options;
  }, [staffList]);

  useEffect(() => {
    if (mode === "edit") {
      dispatch(fetchProductDetail(Number(productId)));
    }
  }, [productId, dispatch, mode]);

  useEffect(() => {
    // 新增產品完拿到新的id 要導去edit頁
    if (mode === "add" && productDetail.id && productDetail.id > 0) {
      navigate(`/product/edit/${productDetail.id}`);
    }

    form.setFieldsValue(productDetail);
  }, [productDetail, mode, navigate, form]);

  const handleOnSyncToWarehouse = () => {
    dispatch(syncToWarehouse(Number(productId)));
  };

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    switch (mode) {
      case "add": {
        const createParams: CreateProductDetailParam = {
          owner: formValues.owner.id,
          name: formValues.name,
          sku: formValues.sku,
          brand: formValues.brand.id,
          shelfLife: formValues.shelfLife,
          syncOption: formValues.syncStockOption,
          isSyncWarehouse: formValues.isSyncWarehouse,
          isActive: formValues.isActive,
          stockQty: formValues.stockQty,
          processingFee: formValues.processingFee,
          weightedCost: formValues.weightedCost,
          length: formValues.length,
          width: formValues.width,
          height: formValues.height,
          weight: formValues.weight,
          cartonLength: formValues.cartonLength,
          cartonWidth: formValues.cartonWidth,
          cartonHeight: formValues.cartonHeight,
        };

        dispatch(createProduct(createParams));
        break;
      }
      case "edit": {
        const params: UpdateProductDetailParam = {
          owner: formValues.owner.id,
          name: formValues.name,
          sku: formValues.sku,
          brand: formValues.brand.id,
          shelfLife: formValues.shelfLife,
          syncOption: formValues.syncStockOption,
          isActive: formValues.isActive,
          stockQty: formValues.stockQty,
          processingFee: formValues.processingFee,
          length: formValues.length,
          width: formValues.width,
          height: formValues.height,
          weight: formValues.weight,
          cartonLength: formValues.cartonLength,
          cartonWidth: formValues.cartonWidth,
          cartonHeight: formValues.cartonHeight,
        };

        const updateParams = {
          id: Number(productId),
          params,
        };
        dispatch(updateProduct(updateParams));
        break;
      }
      default:
    }
  };

  const handleOnSelect = (title: string) => (value: SelectValue, option: any) => {
    form.setFieldsValue({
      [title]: option,
    });
  };

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleIsActiveOnChange = (value: SelectValue) => {
    if (!value) {
      Modal.confirm({
        title: "請問是否確認下架？",
        icon: <ExclamationCircleFilled />,
        content: "商品下架相關合作方式及方案(包含贈品及加購品)都將自動改為下架",
        okText: "確認",
        cancelText: "取消",
        onOk: () => form.setFieldsValue({ isActive: false }),
        onCancel: () => form.setFieldsValue({ isActive: true }),
      });
    }
  };

  useEffect(() => {
    if (productDetail?.brand) {
      dispatch(fetchBrandList(productDetail.brand.name));
    }
  }, [productDetail, dispatch]);

  return (
    <Wrapper>
      <Form
        form={form}
        initialValues={productDetail}
        onFinish={handleOnSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Row>
          <RowTitle>ID</RowTitle>
          <Flex>
            <RowContent width={86}>
              <Form.Item name="id">
                <Input disabled />
              </Form.Item>
            </RowContent>
            <Annotation>*儲存後將自動帶入</Annotation>
          </Flex>
        </Row>
        <Row>
          <RowTitle>狀態</RowTitle>
          <RowContent width={188}>
            <Form.Item name="isActive">
              <StyledSelect options={statusList} onChange={(value) => handleIsActiveOnChange(value as SelectValue)} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>同步至倉庫</RowTitle>
          <RowContent width={188}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isSyncWarehouse !== currentValues.isSyncWarehouse}
            >
              {({ getFieldValue }) => {
                const isSyncWarehouse = getFieldValue("isSyncWarehouse");
                return (
                  <Form.Item name="isSyncWarehouse">
                    <SyncToWarehouseText>{isSyncWarehouse ? "是" : "否"}</SyncToWarehouseText>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>倉庫資訊同步方式</RowTitle>
          <RowContent width={188}>
            <Form.Item name="syncStockOption">
              <StyledSelect options={syncMethodList} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            商開負責人
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item
              name={["owner", "id"]}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value >= 0) return Promise.resolve();
                    return Promise.reject();
                  },
                }),
              ]}
            >
              <StyledSelect
                showSearch
                onChange={(value, option) => handleOnSelect("owner")(value as SelectValue, option)}
                filterOption={(input, option) => {
                  const { children } = option as any;
                  return children.toLowerCase().includes(input.toLowerCase());
                }}
              >
                {staffOptions}
              </StyledSelect>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            SKU
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item
              name="sku"
              rules={[
                { required: true, message: "" },
                { max: 20, message: "最多20個字元" },
                {
                  validator: (rule, value) => {
                    const valid = /^[A-Za-z0-9-]+$/.test(value);
                    return valid ? Promise.resolve() : Promise.reject(new Error("只能輸入英文 、 - 、 數字"));
                  },
                },
              ]}
            >
              <Input disabled={mode === "edit"} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            商品名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="name" rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            品牌
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item
              name={["brand", "id"]}
              rules={[
                () => ({
                  validator(rule, value) {
                    if (value >= 0) return Promise.resolve();
                    return Promise.reject();
                  },
                }),
              ]}
            >
              <StyledSelect
                showSearch
                filterOption={false}
                onChange={(value, option) => handleOnSelect("brand")(value as SelectValue, option)}
                onSearch={handleOnBrandSearch}
              >
                {brandOptions}
              </StyledSelect>
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>
            總效期天數 <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={500}>
            <Form.Item name="shelfLife" rules={[{ required: true, message: "" }]}>
              <InputNumber />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>倉庫處理費</RowTitle>
          <RowContent width={500}>
            <Form.Item name="processingFee">
              <InputNumber />
            </Form.Item>
          </RowContent>
        </Row>
        <RowGroup>
          <Row>
            <RowBoldTitle>
              單一品項
              <RequiredIcon> *</RequiredIcon>
            </RowBoldTitle>
          </Row>
          <Row>
            <RowTitle>重量(g)</RowTitle>
            <RowContent width={500}>
              <Form.Item
                name="weight"
                rules={[
                  { required: true, message: "" },
                  () => ({
                    validator(_, value) {
                      if (value === 0) return Promise.reject();
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>長(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item
                name="length"
                rules={[
                  { required: true, message: "" },
                  () => ({
                    validator(_, value) {
                      if (value === 0) return Promise.reject();
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>寬(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item
                name="width"
                rules={[
                  { required: true, message: "" },
                  () => ({
                    validator(_, value) {
                      if (value === 0) return Promise.reject();
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>高(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item
                name="height"
                rules={[
                  { required: true, message: "" },
                  () => ({
                    validator(_, value) {
                      if (value === 0) return Promise.reject();
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
        </RowGroup>
        <RowGroup>
          <Row>
            <RowBoldTitle>外箱尺寸</RowBoldTitle>
          </Row>
          <Row>
            <RowTitle>長(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item name="cartonLength">
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>寬(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item name="cartonWidth">
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
          <Row>
            <RowTitle>高(cm)</RowTitle>
            <RowContent width={500}>
              <Form.Item name="cartonHeight">
                <InputNumber min={0} />
              </Form.Item>
            </RowContent>
          </Row>
        </RowGroup>

        <Row>
          <RowTitle>庫存加權平均成本</RowTitle>
          <RowContent width={500}>
            <Form.Item name="weightedCost">
              <InputNumber disabled={mode === "edit"} />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>庫存</RowTitle>
          <RowContent width={500}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.syncStockOption !== currentValues.syncStockOption}
            >
              {({ getFieldValue }) => {
                const syncStockOption = getFieldValue("syncStockOption");
                const isDisabled = syncStockOption === SyncMethodValue.SYNC;
                return (
                  <Form.Item name="stockQty">
                    <InputNumber disabled={isDisabled} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>預扣量</RowTitle>
          <RowContent width={500}>
            <Form.Item name="holdStockQty">
              <Input disabled />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>保存量</RowTitle>
          <RowContent width={500}>
            <Form.Item name="retentionStockQty">
              <Input disabled />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <RowTitle>可賣量</RowTitle>
          <RowContent width={500}>
            <Form.Item name="canSalesQty">
              <Input disabled />
            </Form.Item>
          </RowContent>
        </Row>
        <Footer>
          {mode === "edit" && (
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.isSyncWarehouse !== currentValues.isSyncWarehouse}
            >
              {({ getFieldValue }) => {
                const isSyncWarehouse = getFieldValue("isSyncWarehouse");
                return (
                  <SyncToWarehouseButton onClick={handleOnSyncToWarehouse} disabled={isSyncWarehouse}>
                    新增至倉庫
                  </SyncToWarehouseButton>
                );
              }}
            </Form.Item>
          )}
          <Button type="primary" htmlType="submit">
            {mode === "add" ? "建立資料" : "儲存此分頁"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default BasicInfo;
