import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import adminApi, { SocialLoginParams } from "@api/adminApi";
import cookie from "@utils/Cookie";
import { MEMBER_TOKEN } from "@constant/StorageKeyValue";

interface IState {
  isLogin: boolean;
  memberAccessToken: string[];
}

const initialState: IState = {
  isLogin: false,
  memberAccessToken: [],
};

export const socialLogin = createAsyncThunk<void, SocialLoginParams>("auth/socialLogin", async (params, thunkApi) => {
  try {
    const response = await adminApi.socialLogin(params);
    const { expiresAt } = response;
    const expiresDate = new Date(expiresAt * 1000); // python的timeStamp以秒為單位
    cookie.setExpireItem(MEMBER_TOKEN, response, expiresDate);
    thunkApi.dispatch(updateMemberAccess(response.staffPermission));
    thunkApi.dispatch(updateIsLogin(true));
  } catch (error: any) {
    thunkApi.rejectWithValue(error.message);
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    updateIsLogin: (state, action: PayloadAction<boolean>) => {
      state.isLogin = action.payload;
    },
    updateMemberAccess: (state, action: PayloadAction<string[]>) => {
      state.memberAccessToken = action.payload;
    },
  },
});

export const { updateIsLogin, updateMemberAccess } = authSlice.actions;
export default authSlice.reducer;
