import CoreAPI from "../CoreAPI";
import { transformCamelToSnake } from "../utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "../utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

export interface ManufacturerList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ManufacturerListItem[];
}

export interface ManufacturerListItem {
  id?: number;
  name: string;
  address: string;
  phone: string;
  majorName: string;
  majorEmail: string;
  staff: { name: string; id: number };
}

export interface ManufacturerListFilter {
  nameQ?: string;
  id?: number;
  limit: number;
  offset: number;
}
export interface ContactParams {
  limit: number;
  offset: number;
}
export interface PaymentTermsParams {
  limit: number;
  offset: number;
}
export interface ManufacturerInfo extends ManufacturerListItem {
  ubn: string;
  accountName: string;
  accountNumber: string;
  bankName: string;
  bankBranch: string;
  currency: number;
}
export interface ManufacturerContactList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ManufacturerContactItem[];
}
export interface ManufacturerContactItem {
  id?: number;
  isMajor?: boolean;
  name: string;
  phone: string;
  email: string;
  role: ContactPositionTitle;
}
export interface PaymentTermsResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: PaymentTermsInfo[];
}
export interface PaymentTermsInfo {
  id?: number;
  status: number;
  days: number;
  ratio: number;
}
export enum ContactPositionTitle {
  BUSINESS = 1,
  SHIPMENT = 2,
  FINANCE = 3,
}
export const contactPositionTitleLabel = [
  { value: ContactPositionTitle.BUSINESS, label: "業務" },
  { value: ContactPositionTitle.SHIPMENT, label: "出貨單位" },
  { value: ContactPositionTitle.FINANCE, label: "財務" },
];
export enum PaymentTermsStatus {
  ORDER = 1,
  ARRIVED = 2,
}
export const paymentTermsStatusLabel = [
  { value: PaymentTermsStatus.ORDER, label: "下單" },
  { value: PaymentTermsStatus.ARRIVED, label: "到貨" },
];
export interface ManufacturerApi {
  fetchManufacturerList: (params: ManufacturerListFilter) => Promise<ManufacturerList>;
  fetchManufacturerDetail: (manufacturerId: number) => Promise<ManufacturerInfo>;
  addManufacturer: (payload: ManufacturerInfo) => Promise<void>;
  updateManufacturer: (payload: ManufacturerInfo, manufacturerId: number) => Promise<void>;
  deleteManufacturer: (manufacturerId: number) => Promise<void>;
  fetchManufacturerContactList: (params: ContactParams, manufacturerId: number) => Promise<ManufacturerContactList>;
  addManufacturerContact: (payload: ManufacturerContactItem, manufacturerId: number) => Promise<void>;
  updateManufacturerContact: (
    payload: ManufacturerContactItem,
    contactId: number,
    manufacturerId: number,
  ) => Promise<void>;
  deleteManufacturerContact: (contactId: number, manufacturerId: number) => Promise<void>;
  fetchPaymentTermsList: (params: PaymentTermsParams, manufacturerId: number) => Promise<PaymentTermsResult>;
  addPaymentTerms: (payload: PaymentTermsInfo, manufacturerId: number) => Promise<void>;
  updatePaymentTerms: (payload: PaymentTermsInfo, paymentTermsId: number, manufacturerId: number) => Promise<void>;
  deletePaymentTerms: (paymentTermsId: number, manufacturerId: number) => Promise<void>;
}

const manufacturerApi: ManufacturerApi = {
  fetchManufacturerList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/producer/producers/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchManufacturerDetail: async (manufacturerId) => {
    const response = await apiClient.getData(`/tryall/manage/producer/producers/${manufacturerId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  addManufacturer: async (payload) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData("/tryall/manage/producer/producers/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateManufacturer: async (payload, manufacturerId) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(`/tryall/manage/producer/producers/${manufacturerId}/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteManufacturer: async (manufacturerId) => {
    await apiClient.deleteData(`/tryall/manage/producer/producers/${manufacturerId}/`, {});
  },
  fetchManufacturerContactList: async (params, manufacturerId) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(
      `/tryall/manage/producer/producers/${manufacturerId}/contacts/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  addManufacturerContact: async (payload, manufacturerId) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData(
      `/tryall/manage/producer/producers/${manufacturerId}/contacts/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  updateManufacturerContact: async (payload, contactId, manufacturerId) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(
      `/tryall/manage/producer/producers/${manufacturerId}/contacts/${contactId}/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteManufacturerContact: async (contactId, manufacturerId) => {
    await apiClient.deleteData(`/tryall/manage/producer/producers/${manufacturerId}/contacts/${contactId}/`, {});
  },
  fetchPaymentTermsList: async (params, manufacturerId) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData(
      `/tryall/manage/producer/producers/${manufacturerId}/cashflow/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  addPaymentTerms: async (payload, manufacturerId) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.postData(
      `/tryall/manage/producer/producers/${manufacturerId}/cashflow/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  updatePaymentTerms: async (payload, paymentTermsId, manufacturerId) => {
    const getParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(
      `/tryall/manage/producer/producers/${manufacturerId}/cashflow/${paymentTermsId}/`,
      getParams,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  deletePaymentTerms: async (paymentTermsId, manufacturerId) => {
    await apiClient.deleteData(`/tryall/manage/producer/producers/${manufacturerId}/cashflow/${paymentTermsId}/`, {});
  },
};

export default manufacturerApi;
