import { DiscountModule, TabListParams, TabSearchListParams } from "@api/discountFrameApi";
import { fetchSearchOptionsList, resetSearchOptions } from "@redux/discountFrameSlice";
import { RootState } from "@redux/rootReducer";
import { AutoComplete, Button, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const SearchBar = styled.div`
  display: flex;
  margin-bottom: 10px;
  flex-grow: 1;
  > .ant-input-group {
    display: flex;
  }
  .ant-select-selection-item {
    width: 80px;
  }
`;
const StyledSelect = styled(Select)`
  width: 104px;
`;
const SearchButton = styled(Button)`
  margin-left: 12px;
`;

const CustomButton = styled(Button)`
  margin-left: 10px;
`;

enum SearchOptionTypeValue {
  ID,
  NAME,
}

type Props = {
  module: DiscountModule;
  onReset: () => void;
  onSubmit: (params: TabListParams) => void;
};
const TabSearchBar = (props: Props) => {
  const dispatch = useDispatch();
  const { module, onReset, onSubmit } = props;

  const planListParam = useSelector((state: RootState) => state.discountFrameSlice.planListParam);
  const salePageListParam = useSelector((state: RootState) => state.discountFrameSlice.salePageListParam);
  const searchOptions = useSelector((state: RootState) => state.discountFrameSlice.searchOptions);

  const [searchType, setSearchType] = useState<SearchOptionTypeValue>(SearchOptionTypeValue.ID);
  const [searchInputValue, setSearchInputValue] = useState<string>("");
  const [searchParams, setSearchParams] = useState<TabSearchListParams>({ module });
  const [options, setOptions] = useState<{ value: string }[]>([]);
  const title = module === DiscountModule.PLAN ? "方案" : "銷售頁";

  const searchTypeOptions = [
    {
      label: `${title}ID`,
      value: SearchOptionTypeValue.ID,
    },
    {
      label: `${title}名稱`,
      value: SearchOptionTypeValue.NAME,
    },
  ];

  const handleOnSearchTypeSelect = (value: SelectValue) => {
    setSearchType(value as SearchOptionTypeValue);
    setSearchInputValue("");
    setSearchParams({ module });
  };

  const handleOnIdInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchInputValue(value);
  };

  const handleOnFilterSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    handleOnSubmit();
  };

  // AutoComplete
  const handleSearchInput = (searchText: string) => {
    const params: TabSearchListParams = {
      module,
      objectName: searchText,
    };
    setSearchParams(params);
    setSearchInputValue(searchText);
    if (!searchText) dispatch(resetSearchOptions());
  };

  const handelOnSelect = (searchText: string) => {
    setSearchInputValue(searchText);
  };

  const handleOnSubmit = () => {
    let filterParams: TabListParams = {
      module,
      limit: 20,
      offset: 0,
    };
    switch (module) {
      case DiscountModule.PLAN:
        filterParams = {
          ...planListParam,
        };
        break;
      case DiscountModule.SALEPAGE:
        filterParams = {
          ...salePageListParam,
        };
        break;
      default:
    }
    switch (searchType) {
      case SearchOptionTypeValue.ID:
        filterParams.objectId = searchInputValue ? Number(searchInputValue) : undefined;
        break;
      case SearchOptionTypeValue.NAME:
        filterParams.objectName = searchInputValue.trim();
        break;
      default:
    }
    onSubmit(filterParams);
  };

  const handelOnReset = () => {
    setSearchInputValue("");
    setSearchParams({ module });
    onReset();
  };
  useEffect(() => {
    if (searchInputValue) {
      dispatch(fetchSearchOptionsList(searchParams));
    }
  }, [searchParams, dispatch, searchInputValue]);

  useEffect(() => {
    setOptions(searchOptions.map((option) => ({ value: option })));
  }, [searchOptions]);
  return (
    <SearchBar>
      <Input.Group compact>
        <StyledSelect
          value={searchType}
          options={searchTypeOptions}
          onChange={(value) => handleOnSearchTypeSelect(value as SelectValue)}
          dropdownMatchSelectWidth={false}
        />
        {searchType === SearchOptionTypeValue.ID && (
          <Input
            value={searchInputValue}
            onChange={handleOnIdInputChange}
            onPressEnter={handleOnFilterSubmit}
            allowClear
          />
        )}
        {searchType === SearchOptionTypeValue.NAME && (
          <AutoComplete
            value={searchInputValue}
            style={{ width: "100%" }}
            allowClear
            options={options}
            onChange={handleSearchInput}
            onSelect={handelOnSelect}
            onSearch={(e) => handleSearchInput(e)}
          />
        )}
      </Input.Group>
      <SearchButton type="primary" onClick={handleOnSubmit} disabled={!searchInputValue}>
        搜尋
      </SearchButton>
      <CustomButton onClick={handelOnReset}>清除篩選條件</CustomButton>
    </SearchBar>
  );
};

export default TabSearchBar;
