import { InfoCircleFilled, UploadOutlined } from "@ant-design/icons";
import { DiscountModule } from "@api/discountFrameApi";
import PopupBackground from "@component/PopupBackground";
import {
  batchAddPlanByCSV,
  batchAddSalePageByCSV,
  clearCSVErrorMessage,
  toggleImportCsvPopup,
} from "@redux/discountFrameSlice";
import { RootState } from "@redux/rootReducer";
import { Button } from "antd";
import React, { ChangeEvent, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 305px;
  background-color: white;
`;

const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadIcon = styled(UploadOutlined)`
  font-size: 66px;
  color: ${({ theme }) => theme.colorNeutral500};
  margin-bottom: 35px;
`;
const WarningIcon = styled(InfoCircleFilled)`
  font-size: 42px;
  color: #f16c5d;
  margin-bottom: 39px;
`;
const SelectFile = styled.label`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const DownloadFile = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;
const GobackButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)``;
const InputFile = styled.input`
  &&& {
    display: none;
  }
`;
const ErrorText = styled.p`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const CustomButton = styled(Button)`
  margin-right: 8px;
`;
type Props = {
  module: string;
  templatePath: string;
};

const ImportCSV = (props: Props) => {
  const { module, templatePath } = props;
  const [uploadFile, setUploadFile] = useState<File>();
  const csvErrorMessage = useSelector((state: RootState) => state.discountFrameSlice.csvErrorMessage);
  const dispatch = useAppDispatch();

  const displayText = () => {
    if (csvErrorMessage) return <ErrorText>{csvErrorMessage}</ErrorText>;
    if (uploadFile) {
      return (
        <SelectFile>
          {uploadFile.name}
          <InputFile type="file" accept=".csv" onChange={handleOnFileUpload} />
        </SelectFile>
      );
    }
    return (
      <SelectFile>
        選擇檔案
        <InputFile type="file" accept=".csv" onChange={handleOnFileUpload} />
      </SelectFile>
    );
  };

  const closeImportPopup = () => {
    dispatch(toggleImportCsvPopup(false));
  };

  const handleOnFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) setUploadFile(files[0]);
  };

  const handleOnGoBack = () => {
    dispatch(clearCSVErrorMessage());
    setUploadFile(undefined);
    dispatch(toggleImportCsvPopup(false));
  };

  const handleOnImport = () => {
    if (!uploadFile) return;
    switch (module) {
      case DiscountModule.PLAN:
        dispatch(batchAddPlanByCSV(uploadFile));
        break;
      case DiscountModule.SALEPAGE:
        dispatch(batchAddSalePageByCSV(uploadFile));
        break;
      default:
    }
  };

  return (
    <PopupBackground close={closeImportPopup}>
      <Wrapper>
        <CenterBlock>
          {csvErrorMessage ? <WarningIcon /> : <UploadIcon />}
          {displayText()}
          <DownloadFile
            // TODO: 修改範例檔案路徑
            href={`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}${templatePath}`}
          >
            下載範例檔案
          </DownloadFile>
        </CenterBlock>
        <ButtonContainer>
          {!!csvErrorMessage && <GobackButton onClick={handleOnGoBack}>返回</GobackButton>}
          {!csvErrorMessage && <CustomButton onClick={closeImportPopup}>取消</CustomButton>}
          <ImportButton type="primary" onClick={handleOnImport} disabled={!uploadFile || !!csvErrorMessage}>
            匯入
          </ImportButton>
        </ButtonContainer>
      </Wrapper>
    </PopupBackground>
  );
};

export default ImportCSV;
