import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { MarginAddTopList, ProfitsConversionList } from "@api/vendorApi";
import {
  fetchDeleteMarginAddTop,
  fetchMarginAddTopList,
  fetchMarginSendInvoice,
  fetchMarginSendReceipt,
  fetchProfitsConversionList,
  vendorState,
} from "@redux/vendorSlice";
import { Button, Modal, Row, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MarginEditModal, { MarginEditType } from "./MarginEditModal";

const Title = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
`;
const CustomButton = styled(Button)`
  margin-bottom: 21px;
`;
const CustomLink = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
`;

export default function MarginBalanceList() {
  const dispatch = useDispatch();
  const { marginAddTopList, profitsConversionList, isMarginFetching, isProfitsFetching } = useSelector(vendorState);
  const { vendorId } = useParams();

  const [createModal, setCreateModal] = useState<boolean>();
  const [marginAddTopModal, setMarginAddTopModal] = useState<MarginAddTopList | undefined>();
  const [profitsConversionModal, setProfitsConversionModal] = useState<ProfitsConversionList | undefined>();

  const receiptDisable = (values: MarginAddTopList) => {
    switch (values.title) {
      case "保證金餘額退回":
        return true;
      case "保證金儲值":
        if (values.receipts === "尚未開立") {
          return false;
        }
        return true;

      default:
        return false;
    }
  };
  const editDisable = (values: MarginAddTopList) => {
    switch (values.title) {
      case "保證金餘額退回":
        if (!values.receiptsDate) {
          return false;
        }
        return true;

      case "保證金儲值":
        if (values.receipts === "尚未開立") {
          return false;
        }
        return true;

      default:
        return false;
    }
  };

  const marginColumns: ColumnsType<MarginAddTopList> = [
    {
      title: "建立日期",
      dataIndex: "createdAt",
    },
    {
      title: "類別",
      dataIndex: "type",
      render: (_, value) => {
        return <div>{value.title === "保證金餘額退回" ? "終止合作" : "保證金儲值"}</div>;
      },
    },
    {
      title: "項目名稱",
      dataIndex: "title",
    },
    {
      title: "金額",
      dataIndex: "amount",
    },
    {
      title: "財務確認收(退)款",
      dataIndex: "receiptsDate",
      render: (_, value) => {
        return (
          <>
            {value.title === "保證金餘額退回" ? (
              <div>{value.receiptsDate ? `${value.receiptsDate}已退回` : "未退回"}</div>
            ) : (
              <div>{value.receiptsDate ? `${value.receiptsDate}已入帳` : "否"}</div>
            )}
          </>
        );
      },
    },
    {
      title: "憑證附件",
      dataIndex: "attachment",
      render: (_, value) => {
        return (
          <>
            {value.title === "保證金餘額退回" ? (
              <div>-</div>
            ) : (
              <>
                {value.attachment ? (
                  <CustomLink type="link" href={value.attachment} target="_blank">
                    檢視檔案
                  </CustomLink>
                ) : (
                  <div>無憑證</div>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "收據開立",
      dataIndex: "receipts",
      render: (_, value) => {
        return (
          <>
            {value.title === "保證金餘額退回" ? (
              <div>-</div>
            ) : (
              <>
                {value.receipts === "尚未開立" || value.receipts === "" ? (
                  <div>尚未開立</div>
                ) : (
                  <CustomLink type="link" href={value.receipts} target="_blank">
                    檢視收據
                  </CustomLink>
                )}
              </>
            )}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "buttons",
      render: (_, values) => (
        <Row>
          <Button
            type="link"
            disabled={receiptDisable(values)}
            onClick={() => dispatch(fetchMarginSendReceipt({ vendorId: parseInt(vendorId, 10), addTopId: values.id }))}
          >
            收據開立
          </Button>
          <Button type="link" disabled={editDisable(values)} onClick={() => setMarginAddTopModal(values)}>
            編輯
          </Button>
          <Button type="link" href={`/vendor/${vendorId}/deposit/${values.id}`} target="_blank">
            檢視
          </Button>
          <Button
            type="link"
            disabled={editDisable(values)}
            onClick={() =>
              Modal.confirm({
                title: "你確定要刪除嗎?",
                icon: <ExclamationCircleOutlined />,
                okText: "是",
                cancelText: "否",
                onOk() {
                  dispatch(fetchDeleteMarginAddTop({ vendorId: parseInt(vendorId, 10), addTopId: values.id }));
                },
              })
            }
          >
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  const profitsColumns: ColumnsType<ProfitsConversionList> = [
    {
      title: "建立日期",
      dataIndex: "createdDate",
    },
    {
      title: "類別",
      dataIndex: "type",
      render: () => <div>利潤轉換</div>,
    },
    {
      title: "項目名稱",
      dataIndex: "title",
    },
    {
      title: "金額",
      dataIndex: "amount",
    },
    {
      title: "審核狀態",
      dataIndex: "reviewStatus",
      render: (reviewStatus) => <div>{reviewStatus}</div>,
    },
    {
      title: "發票開立",
      dataIndex: "invoiceNumber",
      render: (invoiceNumber) => <div>{invoiceNumber || "尚未開立"}</div>,
    },
    {
      title: "",
      dataIndex: "buttons",
      render: (_, values) => (
        <Row>
          <Button
            type="link"
            disabled={values.reviewStatus !== "審核已通過" || !!values.invoiceNumber}
            onClick={() => dispatch(fetchMarginSendInvoice({ vendorId: parseInt(vendorId, 10), profitsId: values.id }))}
          >
            開立發票
          </Button>
          <Button
            type="link"
            disabled={!!values.invoiceNumber || values.reviewStatus !== "待審核" || !!values.destroyedAt}
            onClick={() => setProfitsConversionModal(values)}
          >
            編輯
          </Button>
          <Button type="link" href={`/vendor/${vendorId}/profits/${values.id}`} target="_blank">
            檢視
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (vendorId) {
      dispatch(fetchMarginAddTopList(parseInt(vendorId, 10)));
      dispatch(fetchProfitsConversionList(parseInt(vendorId, 10)));
    }
  }, [vendorId, dispatch]);

  return (
    <div>
      <CustomButton
        type="primary"
        icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
        onClick={() => setCreateModal(true)}
      >
        建立保證金合作關係
      </CustomButton>
      <Title>保證金儲值</Title>
      <Table
        loading={isMarginFetching}
        columns={marginColumns}
        dataSource={marginAddTopList}
        pagination={false}
        scroll={{ x: "max-content" }}
        style={{ marginBottom: 40 }}
      />
      <Title>利潤轉換</Title>
      <Table
        loading={isProfitsFetching}
        columns={profitsColumns}
        dataSource={profitsConversionList}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
      {createModal && (
        <MarginEditModal marginEditType={MarginEditType.CREATE_CONTRACT} onClose={() => setCreateModal(false)} />
      )}
      {marginAddTopModal && (
        <MarginEditModal
          marginEditType={MarginEditType.EDIT_MARGIN}
          marginData={marginAddTopModal}
          onClose={() => setMarginAddTopModal(undefined)}
        />
      )}
      {profitsConversionModal && (
        <MarginEditModal
          marginEditType={MarginEditType.EDIT_PROFITS}
          profitsData={profitsConversionModal}
          onClose={() => setProfitsConversionModal(undefined)}
        />
      )}
    </div>
  );
}
