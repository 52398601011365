import React, { FC, useState, useEffect, ChangeEvent } from "react";
import { Input, Button } from "antd";
// import { SelectValue } from "antd/lib/select";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import {
  fetchTagTypeList,
  fetchCategoryDetail,
  createProductCategory,
  updateProductCategory,
  clearCategoryDetail,
  // loadingMoreTagTypeList,
} from "@redux/productCategorySlice";
import styled from "styled-components";
// import InfiniteScrollObserver from "@component/InfiniteScrollObserver";

const Wrapper = styled.div`
  padding-left: 22px;
  padding-right: 86px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;
const RowTitle = styled.div`
  flex: 2;
`;
const RowContent = styled.div`
  flex: 8;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const EditTab: FC = () => {
  const [categoryName, setCategoryName] = useState<string>("");
  const [selectedTagTypes, setSelectedTagTypes] = useState<number[]>([]);
  const dispatch = useAppDispatch();
  // const tagTypeListResult = useSelector((state: RootState) => state.productCategory.tagTypeListResult);
  const selectedCategory = useSelector((state: RootState) => state.productCategory.selectedCategory);
  const categoryDetail = useSelector((state: RootState) => state.productCategory.categoryDetail);

  const { id, level, parent } = selectedCategory;

  // const handleOnTagTypeLoadMore = useCallback(() => {
  //   dispatch(loadingMoreTagTypeList());
  // }, [dispatch]);

  useEffect(() => {
    if (level === 3) dispatch(fetchTagTypeList());
  }, [dispatch, level]);

  useEffect(() => {
    if (id < 0) {
      dispatch(clearCategoryDetail());
    } else {
      dispatch(fetchCategoryDetail(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    const { name, tagTypes } = categoryDetail;
    const tagTypesIds = tagTypes.map((tg) => tg.id);

    setCategoryName(name);
    setSelectedTagTypes(tagTypesIds);
  }, [categoryDetail]);

  function handleOnNameChange(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setCategoryName(value);
  }

  // function handleOnTagTypeSelect(e: SelectValue) {
  //   setSelectedTagTypes(e as number[]);
  // }

  function handleOnSaveClick() {
    if (id < 0) {
      // 新增
      const params = {
        parentId: parent,
        name: categoryName,
        tagTypes: selectedTagTypes,
      };
      dispatch(createProductCategory(params));
    } else {
      // 更新
      const params = {
        id,
        name: categoryName,
        tagTypes: selectedTagTypes,
      };
      dispatch(updateProductCategory(params));
    }
  }

  return (
    <Wrapper>
      <Row>
        <RowTitle>
          品類名稱
          <RequiredIcon>*</RequiredIcon>
        </RowTitle>
        <RowContent>
          <Input value={categoryName} onChange={handleOnNameChange} />
        </RowContent>
      </Row>
      {/* 屬性暫時隱藏 */}
      {/* {level === 3 && (
        <Row>
          <RowTitle>屬性</RowTitle>
          <RowContent>
            <MaxWidthSelect
              mode="multiple"
              allowClear
              value={selectedTagTypes}
              filterOption={(input, option) => {
                const label = option?.label as string;
                return label?.includes(input);
              }}
              onChange={handleOnTagTypeSelect}
            >
              {selectOptions}
            </MaxWidthSelect>
          </RowContent>
        </Row>
      )} */}
      <Footer>
        <Button type="primary" disabled={!categoryName} onClick={handleOnSaveClick}>
          儲存此分頁
        </Button>
      </Footer>
    </Wrapper>
  );
};

export default EditTab;
