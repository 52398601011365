import React, { useState } from "react";
import { Button, Form, Input, Select } from "antd";
import styled from "styled-components";
import PopupBackground from "@component/PopupBackground";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { deleteAccount } from "@redux/memberSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";

const { Option } = Select;
const { TextArea } = Input;

const CustomTextArea = styled(TextArea)`
  height: 100px;
`;

const Wrapper = styled(Form)`
  width: 400px;
  height: 420px;
  padding: 24px 32px;
  background-color: ${({ theme }) => theme.colorNeutral100};
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const WanringIcon = styled(ExclamationCircleOutlined)`
  font-size: 22px;
  color: #faad14;
  margin-right: 16px;
`;
const Header = styled.div`
  display: flex;
`;
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
`;
const Text = styled.span`
  margin-top: 10px;
  margin-bottom: 5px;
  display: inline-block;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
`;
const CustomSelect = styled(Select)``;
const Error = styled.div`
  color: ${({ theme }) => theme.colorSecondary400};
  margin-bottom: 10px;
`;
const RequiredIcon = styled.span`
  color: red;
`;

type Props = {
  onClose: () => void;
  memberInfo: {
    name: string;
    phone: string;
    id: number;
  };
};

const DeleteAccountPopup = (props: Props) => {
  const { onClose, memberInfo } = props;
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const dispatch = useDispatch();
  const { checkOrderError } = useSelector((state: RootState) => state.member);
  const [form] = Form.useForm();

  const deleteAccountValue = [
    {
      value: "無意願再次使用",
    },
    {
      value: "不想要再收到行銷訊息",
    },
    {
      value: "本人不住在台灣",
    },
    {
      value: "其他",
    },
  ];

  const onSelectChange = (value: any) => {
    setReason(value);
  };

  const purpose = reason === "其他" ? otherReason : reason;

  const onSubmit = () => {
    const payload = { id: memberInfo.id, reason: purpose, checkOrder: false };

    dispatch(deleteAccount(payload));
    onClose();
  };

  return (
    <PopupBackground close={onClose}>
      <Wrapper form={form} colon={false} labelCol={{ span: 3 }} wrapperCol={{ span: 18 }} onFinish={onSubmit}>
        <Header>
          <WanringIcon />
          <Title>請選擇刪除原因</Title>
        </Header>
        <div>
          <Text>會員名稱：</Text>
          <Text>{memberInfo.name}</Text>
        </div>
        <div>
          <Text>會員電話：</Text>
          <Text>{memberInfo.phone}</Text>
        </div>
        <Error>{checkOrderError}</Error>
        <Text>
          原因<RequiredIcon>*</RequiredIcon>
        </Text>
        <Form.Item label="" name="reason" rules={[{ required: true, message: "必填" }]}>
          <CustomSelect placeholder="請選擇" onChange={onSelectChange}>
            {deleteAccountValue.map((item) => (
              <Option value={item.value} key={`${item.value}`}>
                {item.value}
              </Option>
            ))}
          </CustomSelect>
        </Form.Item>
        {reason === "其他" && (
          <>
            <Text>
              請說明原因<RequiredIcon>*</RequiredIcon>
            </Text>
            <Form.Item label="" name="otherReason" rules={[{ required: true, message: "必填" }]}>
              <CustomTextArea placeholder="請說明其他原因" onChange={(e) => setOtherReason(e.target.value)} />
            </Form.Item>
          </>
        )}
        <ButtonWrapper>
          <Button style={{ marginRight: "5px" }} onClick={onClose}>
            取消
          </Button>
          <Button type="primary" htmlType="submit" disabled={!purpose.trim()}>
            確定
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </PopupBackground>
  );
};

export default DeleteAccountPopup;
