import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const WebSection = styled(Section)`
  grid-row-start: 2;
`;
const AppSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;

const OrderPriceCard: FC = () => {
  const isFetchingRevenueDashboard = useSelector((state: RootState) => state.dashboard.isFetchingRevenueDashboard);
  const revenueDashboard = useSelector((state: RootState) => state.dashboard.revenueDashboard);
  const { currentData, diff } = revenueDashboard;
  const { avgOrderPrice = 0, webAvgOrderPrice = 0, appAvgOrderPrice = 0 } = currentData || {};
  const { avgOrderPriceDiff = 0, webAvgOrderPriceDiff = 0, appAvgOrderPriceDiff = 0 } = diff || {};

  const avgOrderPriceCurrency = USCurrencyFormmater.format(avgOrderPrice);
  const avgOrderPriceTagColor = avgOrderPriceDiff < 0 ? "error" : "success";
  const avgOrderPriceTagIcon = avgOrderPriceDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const avgOrderPriceChangePercentage = Math.round(avgOrderPriceDiff * 1000) / 10; // 百分比取小數點第一位

  const webAvgOrderPriceCurrency = USCurrencyFormmater.format(webAvgOrderPrice);
  const webAvgOrderPriceTagColor = webAvgOrderPriceDiff < 0 ? "error" : "success";
  const webAvgOrderPriceTagIcon = webAvgOrderPriceDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const webAvgOrderPriceChangePercentage = Math.round(webAvgOrderPriceDiff * 1000) / 10; // 百分比取小數點第一位

  const appAvgOrderPriceCurrency = USCurrencyFormmater.format(appAvgOrderPrice);
  const appAvgOrderPriceTagColor = appAvgOrderPriceDiff < 0 ? "error" : "success";
  const appAvgOrderPriceTagIcon = appAvgOrderPriceDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const appAvgOrderPriceChangePercentage = Math.round(appAvgOrderPriceDiff * 1000) / 10;

  return (
    <Spin spinning={isFetchingRevenueDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>客單價</HeaderTitle>
          <AnchorToChart href="/dashboard/order-price" />
        </Header>
        <Grid>
          <Section>
            <SectionTitle>平均客單價</SectionTitle>
            <SectionContent>
              {avgOrderPriceCurrency}
              <StyledTag color={avgOrderPriceTagColor} icon={avgOrderPriceTagIcon}>
                {`${avgOrderPriceChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <WebSection>
            <SectionTitle>Web平均客單價</SectionTitle>
            <SectionContent>
              {webAvgOrderPriceCurrency}
              <StyledTag color={webAvgOrderPriceTagColor} icon={webAvgOrderPriceTagIcon}>
                {`${webAvgOrderPriceChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </WebSection>
          <AppSection>
            <SectionTitle>App平均客單價</SectionTitle>
            <SectionContent>
              {appAvgOrderPriceCurrency}
              <StyledTag color={appAvgOrderPriceTagColor} icon={appAvgOrderPriceTagIcon}>
                {`${appAvgOrderPriceChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </AppSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default OrderPriceCard;
