import cityRegionData from "@utils/CityRegionData";

interface GetPostCodeWithCVS {
  city: string;
  town: string;
  address: string;
  zipCode: string;
}

const getPostCodeWithCVS = (address: string, lastNumber?: number): GetPostCodeWithCVS => {
  if (!address || (lastNumber && lastNumber > 7) || address.length <= 6) {
    return { city: "", town: "", address: address || "", zipCode: "" };
  }
  const last = lastNumber || 5;
  const city = address.slice(0, 3);
  const town = address.slice(3, last);

  if (cityRegionData[city] && cityRegionData[city][town])
    return {
      city,
      town,
      address: address.slice(last, address.length),
      zipCode: cityRegionData[city][town],
    };

  return getPostCodeWithCVS(address, last + 1);
};

export default getPostCodeWithCVS;
