import { FormOutlined } from "@ant-design/icons";
import { VendorListItem } from "@api/productApi";
import { RootState } from "@redux/rootReducer";
import {
  fetchVendorList,
  modifyVendorCooperation,
  updateOwner,
  updateSelectedVendorIds,
  updateVendorListParams
} from "@redux/vendorSlice";
import { message, Modal, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { ReactText, useCallback, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import ManagerEditor from "./ManagerEditor";

export default function VendorList() {
  const dispatch = useAppDispatch();
  const { isFetchingVendorList, vendorListResult, vendorListParams, selectedVendorIds } = useSelector(
    (state: RootState) => state.vendorSlice,
  );

  const { limit, offset } = vendorListParams;
  const { count, results: vendorList } = vendorListResult;

  const [showManagerEditor, setShowManagerEditor] = useState(false);

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const handleSwitch = (record: VendorListItem, isActive: boolean) => {
    if (isActive) {
      const params = {
        vendorId: record.id,
        isActive: false,
      };
      Modal.confirm({
        title: "你確定要終止合作關係？",
        okText: "是",
        cancelText: "否",
        onOk: () => {
          dispatch(modifyVendorCooperation(params));
        },
      });
    } else {
      const params = {
        vendorId: record.id,
        isActive: true,
      };
      dispatch(modifyVendorCooperation(params));
    }
  };

  const toggleManager = useCallback(
    (state: boolean) => {
      if (!selectedVendorIds.length && !showManagerEditor) {
        message.info("請先選擇廠商");
      } else {
        setShowManagerEditor(state);
      }
    },
    [selectedVendorIds, showManagerEditor],
  );

  const columns: ColumnsType<VendorListItem> = [
    {
      title: "廠商名稱",
      dataIndex: "name",
    },
    {
      title: "廠商電話",
      dataIndex: "phone",
    },
    {
      title: "主要聯絡人",
      dataIndex: ["majorContact", "name"],
    },
    {
      title: "聯絡人職稱",
      dataIndex: ["majorContact", "title"],
    },
    {
      title: "聯絡人信箱",
      dataIndex: ["majorContact", "email"],
    },
    {
      title: "商品數",
      dataIndex: "productQty",
    },
    {
      title: "保證金餘額",
      dataIndex: "depositBalance",
    },
    {
      title: () => (
        <div>
          商開負責人
          <FormOutlined onClick={() => toggleManager(true)} />
        </div>
      ),
      dataIndex: ["owner", "name"],
    },
    {
      title: "合作關係",
      dataIndex: "isActive",
      render: (isActive: boolean, record: VendorListItem) => (
        <Switch checked={isActive} onChange={() => handleSwitch(record, isActive)} />
      ),
    },
    {
      title: "",
      dataIndex: "id",
      fixed: "right",
      render: (id: number) => (
        <a href={`${window.location.origin}/vendor/info/${id}`} target="_blank" rel="noreferrer">
          編輯
        </a>
      ),
    },
  ];
  const handleTableChangePage = (page: number) => {
    dispatch(
      updateVendorListParams({
        ...vendorListParams,
        offset: (page - 1) * limit,
      }),
    );
    dispatch(fetchVendorList());
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedVendorIds(values as number[]));
  };

  const handleOwner = useCallback(
    (ownerId: number) => {
      dispatch(updateOwner(ownerId));
      toggleManager(false);
    },
    [dispatch, toggleManager],
  );

  return (
    <>
      <Table
        rowSelection={{
          type: "checkbox",
          onChange: handleOnCheck,
        }}
        columns={columns}
        rowKey="id"
        dataSource={vendorList}
        loading={isFetchingVendorList}
        scroll={{ x: "max-content" }}
        pagination={{
          current: currentPage,
          total: count,
          onChange: handleTableChangePage,
          pageSize: limit,
          showSizeChanger: false,
        }}
      />

      {/* 批次修改 */}
      <ManagerEditor visible={showManagerEditor} handleOwner={handleOwner} toggleManager={toggleManager} />
    </>
  );
}
