import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

interface CooperationApi {
  fetchCooperationList: (params?: CooperationFilter) => Promise<CooperationList>;
  fetchCreateCooperation: (params: CooperationResult) => Promise<CooperationResult>;
  fetchUpdateCooperation: (params: UpdateCooperation) => Promise<CooperationResult>;
  fetchSendInvoice: (id: number) => void;
  fetchDeleteInvoice: (id: number) => void;
}

export interface CooperationList {
  count: number;
  next: string | null;
  previous: string | null;
  results: CooperationResult[];
}

export interface CooperationResult {
  id: number;
  cooperateType: string;
  name: string;
  ubn: string;
  content: string;
  startAt: string;
  endAt: string;
  amount: number;
  recognizeAt: string;
  invoiceNumber: string | null;
}

export interface UpdateCooperation {
  id: number;
  name: string;
  ubn: string;
  content: string;
  startAt: string;
  endAt: string;
  amount: number;
  recognizeAt: string;
  invoiceNumber: string | null;
}

export interface CooperationFilter {
  limit: number;
  offset: number;
}

const cooperationApi: CooperationApi = {
  fetchCooperationList: async (params) => {
    const response = await apiClient.getData("/manage/cooperation-info/", params ? transformCamelToSnake(params) : {});

    return transformAPIKeyToCamel(response.data.result);
  },

  fetchCreateCooperation: async (params) => {
    const response = await apiClient.postData("/manage/cooperation-info/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchUpdateCooperation: async (params) => {
    const response = await apiClient.patchData(`/manage/cooperation-info/${params.id}/`, transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchSendInvoice: async (id) => {
    await apiClient.postData(`/manage/cooperation-info/${id}/invoice/`, {});
  },

  fetchDeleteInvoice: async (id) => {
    await apiClient.deleteData(`/manage/cooperation-info/${id}/`, {});
  },
};

export default cooperationApi;
