import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import UploadImageButton from "@component/UploadImageButton";
import { brandPageState, fetchBrandPageDetail, fetchUpdateBrandPage } from "@redux/brandPageSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, Checkbox, Form, Input, message, Modal, Select, Spin } from "antd";
import { CheckboxValueType } from "antd/lib/checkbox/Group";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const { TextArea } = Input;
const { Item } = Form;

const Title = styled.div`
  padding-bottom: 12px;
  margin-left: 22px;
  margin-bottom: 16px;
  border-bottom: solid 1px ${({ theme }) => theme.colorNeutral300};
  ${fontStyle("16px", "16px")};
`;

const CustomInput = styled(Input)<{ width?: string; mrleft?: string }>`
  width: ${({ width }) => width};
  margin-left: ${({ mrleft }) => mrleft};
`;

const CustomItem = styled(Item)`
  margin-left: 22px;
`;

const Footer = styled.div<{ containerWidth?: number }>`
  width: ${({ containerWidth }) => `${containerWidth}px` || 0};
  position: fixed;
  bottom: 0;
  right: 4px;
  padding: 12px;
  text-align: right;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const SortScope = styled.div`
  padding: 10px 0 51px 0;
`;

const Scope = styled.div`
  position: relative;
`;

const InputAbsolute = styled(CustomInput)`
  position: absolute;
  left: 31%;
  width: 59.5%;
  top: 0;
`;

type Props = {
  containerRef: React.MutableRefObject<HTMLDivElement | null>;
};

const sortingMethod = ["熱銷", "推薦", "最新", "價格(低至高)", "價格(高至低)"];

export default function EditBrand(props: Props) {
  const { containerRef } = props;

  const { brandPageId } = useParams();

  const dispatch = useDispatch();
  const { isFetching, brandPageDetail } = useSelector(brandPageState);

  const [form] = Form.useForm();

  const [localSortMethods, setLocalSortMethods] = useState<CheckboxValueType[]>([]);

  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onSubmit = (values: any) => {
    const resultSortMethods = new Map();

    sortingMethod.forEach((method) => {
      resultSortMethods.set(method, { name: method, isActive: false });
    });

    localSortMethods.forEach((method) => {
      resultSortMethods.set(method, { name: method, isActive: true });
    });

    if (form.getFieldValue("defaultSortMethod")) {
      if (!resultSortMethods.get(form.getFieldValue("defaultSortMethod")).isActive) {
        message.info("請確定 sorting 勾選是否與預設排序相斥");
      }

      resultSortMethods.set(form.getFieldValue("defaultSortMethod"), {
        name: form.getFieldValue("defaultSortMethod"),
        isActive: true,
      });
    }

    const sortMethods = Array.from(resultSortMethods.values());
    dispatch(fetchUpdateBrandPage({ ...values, brandPageId: parseInt(brandPageId, 10), sortMethods }));
  };

  useEffect(() => {
    if (brandPageId) {
      dispatch(fetchBrandPageDetail(parseInt(brandPageId, 10)));
    }
  }, [dispatch, brandPageId]);

  useEffect(() => {
    if (brandPageDetail) {
      const defaultSortMethod = brandPageDetail.pageInfo?.sortMethods.find((method) => method.isDefault);
      form.setFieldsValue({
        name: brandPageDetail.name,
        iconUrl: brandPageDetail.icon,
        iconAlt: brandPageDetail.iconAlt,
        imageUrl: brandPageDetail.pageInfo?.image?.url || "",
        imageAlt: brandPageDetail.pageInfo?.image?.alt || "",
        metaTitle: brandPageDetail.pageInfo?.metaTitle || "",
        metaKeywords: brandPageDetail.pageInfo?.metaKeywords || "",
        metaDescription: brandPageDetail.pageInfo?.metaDescription || "",
        defaultSortMethod: defaultSortMethod ? defaultSortMethod.name : "",
        sortMethods: brandPageDetail.pageInfo?.sortMethods || [],
      });
    }
  }, [brandPageDetail, form]);

  useEffect(() => {
    if (!brandPageDetail) {
      setLocalSortMethods(sortingMethod);
    } else {
      const sortMethods =
        brandPageDetail.pageInfo?.sortMethods.filter((method) => method.isActive).map((method) => method.name) || [];
      setLocalSortMethods(sortMethods);
    }
  }, [brandPageDetail]);

  return (
    <Spin spinning={isFetching}>
      <Form
        form={form}
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 18 }}
        labelAlign="left"
        colon={false}
        onFinish={onSubmit}
        onFinishFailed={showRequiredFieldsWarning}
      >
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <CustomItem name="name" label="品牌名稱">
              {getFieldValue("name")}
            </CustomItem>
          )}
        </Form.Item>
        <Title>內容</Title>
        <Scope>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <CustomItem name="imageUrl" label="圖片">
                <UploadImageButton
                  hasPreviewAndDelete={!!brandPageDetail?.pageInfo?.image?.id}
                  imageUrl={getFieldValue("imageUrl") || ""}
                  finishUpload={(url) => updateForm("imageUrl", url)}
                  onDelete={() =>
                    Modal.confirm({
                      title: "確定要刪除此圖片嗎?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "是",
                      cancelText: "否",
                      onOk() {
                        removeMedia({
                          module: DeleteModule.PAGE_INFO,
                          pageInfoId: brandPageDetail?.pageInfo.id,
                          mediaId: brandPageDetail?.pageInfo.image?.id as number,
                        });
                        updateForm("imageUrl", undefined);
                      },
                    })
                  }
                />
              </CustomItem>
            )}
          </Form.Item>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("imageAlt", e.target.value)}
          />
        </Scope>
        <Scope>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <CustomItem name="iconUrl" label="Icon">
                <UploadImageButton
                  hasPreviewAndDelete={!!brandPageDetail?.iconId}
                  imageUrl={getFieldValue("iconUrl") || ""}
                  finishUpload={(url) => updateForm("iconUrl", url)}
                  onDelete={() =>
                    Modal.confirm({
                      title: "確定要刪除此Icon嗎?",
                      icon: <ExclamationCircleOutlined />,
                      okText: "是",
                      cancelText: "否",
                      onOk() {
                        removeMedia({
                          module: DeleteModule.TAG,
                          tagId: parseInt(brandPageId, 10),
                          mediaId: brandPageDetail?.iconId as number,
                        });
                        updateForm("iconUrl", undefined);
                      },
                    })
                  }
                />
              </CustomItem>
            )}
          </Form.Item>
          <InputAbsolute
            placeholder="請輸入alt"
            mrleft="10px"
            onChange={(e) => updateForm("iconAlt", e.target.value)}
          />
        </Scope>
        <Form.Item name="imageAlt" noStyle />
        <Form.Item name="iconAlt" noStyle />
        <Title>SEO</Title>
        <CustomItem name="metaTitle" label="Meta Title">
          <CustomInput onChange={(e) => updateForm("metaTitle", e.target.value)} />
        </CustomItem>
        <CustomItem name="metaKeywords" label="Meta Keywords">
          <TextArea onChange={(e) => updateForm("metaKeywords", e.target.value)} />
        </CustomItem>
        <CustomItem name="metaDescription" label="Meta Description">
          <TextArea onChange={(e) => updateForm("metaDescription", e.target.value)} />
        </CustomItem>
        <SortScope>
          <Title>Available Product Listing Sort By</Title>
          <CustomItem>
            <Checkbox.Group value={localSortMethods} onChange={(sortList) => setLocalSortMethods(sortList)}>
              {sortingMethod.map((el: any) => (
                <Checkbox value={el} disabled={el === "熱銷"}>
                  {el}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </CustomItem>
          <CustomItem name="defaultSortMethod" label="商品排序預設為">
            <Select onChange={(value) => updateForm("defaultSortMethod", value)}>
              {sortingMethod.map((method: any) => (
                <Select.Option key={method} value={method}>
                  {method}
                </Select.Option>
              ))}
            </Select>
          </CustomItem>
        </SortScope>
        <Footer containerWidth={containerRef.current?.offsetWidth}>
          <Button disabled={!brandPageId || !brandPageDetail} type="primary" htmlType="submit">
            儲存此分頁
          </Button>
        </Footer>
      </Form>
    </Spin>
  );
}
