import { clearOrderListFilter, orderState, setOrderListFilter } from "@redux/orderSlice";
import { Button, Col, Form, Input, Row, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { logisticHistoryMap } from "../LogisticHistories";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function CustomsDeclarationFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { orderListFilter } = useSelector(orderState);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(clearOrderListFilter());
  };

  const onSubmit = (values: any) => {
    dispatch(
      setOrderListFilter({
        ...orderListFilter,
        name: values.name,
        phone: values.phone,
        orderNumber: values.orderNumber,
        packageNumber: values.packageNumber,
        shipperNumber: values.shipperNumber,
        shippingStatus:
          !values.shippingStatus || values.shippingStatus === "-100" ? undefined : parseInt(values.shippingStatus, 10),
      }),
    );
  };

  return (
    <Wrapper
      colon={false}
      form={form}
      isVisible={isVisible}
      initialValues={{
        orderNumber: undefined,
        shippingStatus: undefined,
        name: undefined,
        phone: undefined,
        shipperNumber: undefined,
        packageNumber: undefined,
      }}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="訂單編號" name="orderNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="訂單狀態" name="shippingStatus" wrapperCol={{ span: 18 }}>
            <Select defaultValue="-100">
              <Select.Option value="-100">請選擇</Select.Option>
              {Object.keys(logisticHistoryMap).map((key) => (
                <Select.Option key={key} value={key}>
                  {logisticHistoryMap[key]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="會員名稱" name="name">
            <Input />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item label="會員電話" name="phone">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="配送編號" name="packageNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="出貨單號" name="shipperNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
