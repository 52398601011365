/* eslint-disable camelcase */
import { ReactElement } from "react";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

export interface TopicShortInfo {
  id: number;
  key: number;
  title: string;
  isActive: boolean;
  toShow: boolean;
  rank: number;
  icon: string;
  iconAlt: string;
  rootCategory: number;
}

export interface TopicFullInfo {
  id: number;
  title: string;
  isActive: boolean;
  toShow: boolean;
  rank: number;
  icon: string;
  iconAlt: string;
  iconId: number;
  rootCategory: number;
  pageInfo: {
    id: number;
    title: string;
    description: string | null;
    image: {
      alt: string;
      id: number;
      image: string;
      isActive: boolean;
      mediaType: number;
      name: string;
      url: string;
    };
    backgroundColor: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    sortMethods: any[]; // 用不到
  };
}

export interface TopicFlatInfo {
  title: string;
  isActive: boolean;
  toShow: boolean;
  rank: number;
  iconUrl: string;
  iconAlt: string;
  imageUrl: string;
  imageAlt: string;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  backgroundColor: string;
}

export interface TopicCategories {
  key: number;
  title: string | ReactElement;
  parent: number;
  salesPageCount: number;
  isDisabled: boolean;
  children: TopicCategories[];
}

export interface CategoryInfo {
  id: number;
  name: string;
  path: string;
  parent: number;
  tags: number[];
  categories: number[];
  icon: string;
  iconId: number;
  iconAlt: string;
  level: number;
  isActive: boolean;
  pageInfo: {
    id: number;
    title: string;
    description: string;
    image: {
      alt: string;
      id: number;
      image: string;
      isActive: boolean;
      mediaType: number;
      name: string;
      url: string;
    };
    backgroundColor: string;
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    sortMethods: {
      id: number;
      name: string;
      isDescend: boolean;
      isDefault: boolean;
      isActive: boolean;
    }[];
  };
}

export interface CategoryTag {
  id: number;
  tagType: string;
  name: string;
  icon: string | null;
  isActive: boolean;
  taggedItemCount: number;
}

interface NormalizeTopic {
  topicList: (
    list: {
      id: number;
      title: string;
      is_active: boolean;
      to_show: boolean;
      rank: number;
      icon: string;
      icon_alt: string;
      root_category: number;
    }[],
  ) => TopicShortInfo[];
  updateTopicInfo: (info: {
    id: number;
    title: string;
    is_active: boolean;
    to_show: boolean;
    rank: number;
    icon: string;
    icon_alt: string;
    root_category: number;
  }) => TopicShortInfo;
  topicInfo: (info: {
    id: number;
    title: string;
    is_active: boolean;
    to_show: boolean;
    rank: number;
    icon: string;
    icon_alt: string;
    icon_id: number;
    root_category: number;
    page_info: {
      id: number;
      title: string;
      description: string | null;
      image: string;
      image_alt: string;
      background_color: string;
      meta_title: string;
      meta_keywords: string;
      meta_description: string;
      sort_methods: any[];
    };
  }) => TopicFullInfo;
  topicCategories: (categories: {
    level_1: {
      id: number;
      name: string;
      parent: number;
      is_active: boolean;
      sales_page_count: number;
    }[];
    level_2: {
      id: number;
      name: string;
      parent: number;
      is_active: boolean;
      sales_page_count: number;
    }[];
    level_3: {
      id: number;
      name: string;
      parent: number;
      is_active: boolean;
      sales_page_count: number;
    }[];
  }) => TopicCategories[];
  categoryInfo: (info: {
    id: number;
    name: string;
    path: string;
    parent: number;
    tags: number[];
    categories: number[];
    page_info: {
      id: number;
      title: string;
      description: string | null;
      image: string;
      image_alt: string;
      background_color: string;
      meta_title: string;
      meta_keywords: string;
      meta_description: string | null;
      sort_methods: {
        id: number;
        name: string;
        is_descend: boolean;
        is_default: boolean;
        is_active: boolean;
      }[];
    };
    icon: string;
    icon_alt: string;
    icon_id: number;
    level: number;
    is_active: boolean;
  }) => CategoryInfo;
  topicShortInfo: (info: {
    id: number;
    title: string;
    is_active: boolean;
    to_show: boolean;
    rank: number;
    icon: string;
    icon_alt: string;
    root_category: number;
  }) => TopicShortInfo;
  categoriesTag: (
    tags: {
      id: number;
      tag_type: string;
      name: string;
      icon: string | null;
      is_active: boolean;
      tagged_item_count: number;
    }[],
  ) => CategoryTag[];
}

const normalizeTopic: NormalizeTopic = {
  topicList: (list) => {
    return list.map((item) => ({
      id: item.id,
      key: item.id,
      title: item.title,
      isActive: item.is_active,
      toShow: item.to_show,
      rank: item.rank,
      icon: item.icon,
      iconAlt: item.icon_alt,
      rootCategory: item.root_category,
    }));
  },
  updateTopicInfo: (info) => ({
    id: info.id,
    key: info.id,
    title: info.title,
    isActive: info.is_active,
    toShow: info.to_show,
    rank: info.rank,
    icon: info.icon,
    iconAlt: info.icon_alt,
    rootCategory: info.root_category,
  }),
  topicInfo: (info) => ({
    id: info.id,
    title: info.title,
    isActive: info.is_active,
    toShow: info.to_show,
    rank: info.rank,
    icon: info.icon,
    iconId: info.icon_id,
    iconAlt: info.icon_alt,
    rootCategory: info.root_category,
    pageInfo: transformAPIKeyToCamel(info.page_info),
  }),
  topicCategories: (categories) => {
    const { level_1, level_2, level_3 } = categories;
    const categoriesLvl1Map = new Map<number, TopicCategories>();
    const categoriesLvl2Map = new Map<number, TopicCategories>();

    level_1.forEach((category) => {
      const newCategory: TopicCategories = {
        key: category.id,
        title: `${category.name}(${category.sales_page_count})`,
        parent: category.parent,
        salesPageCount: category.sales_page_count,
        isDisabled: !category.is_active,
        children: [],
      };

      categoriesLvl1Map.set(newCategory.key, newCategory);
    });

    level_2.forEach((category) => {
      const newCategory: TopicCategories = {
        key: category.id,
        title: `${category.name}(${category.sales_page_count})`,
        parent: category.parent,
        salesPageCount: category.sales_page_count,
        isDisabled: !category.is_active,
        children: [],
      };

      categoriesLvl2Map.set(newCategory.key, newCategory);
    });

    level_3.forEach((category) => {
      const newCategory: TopicCategories = {
        key: category.id,
        title: `${category.name}(${category.sales_page_count})`,
        parent: category.parent,
        salesPageCount: category.sales_page_count,
        isDisabled: !category.is_active,
        children: [],
      };

      const parentCategory = categoriesLvl2Map.get(category.parent);
      parentCategory?.children.push(newCategory);
    });

    const categoriesLvl2Array = Array.from(categoriesLvl2Map.values());
    categoriesLvl2Array.forEach((category) => {
      const parentCategory = categoriesLvl1Map.get(category.parent);
      parentCategory?.children.push(category);
    });

    return Array.from(categoriesLvl1Map.values());
  },
  categoryInfo: (info) => ({
    id: info.id,
    name: info.name,
    path: info.path,
    parent: info.parent,
    tags: info.tags,
    categories: info.categories,
    icon: info.icon,
    iconId: info.icon_id,
    iconAlt: info.icon_alt,
    level: info.level,
    isActive: info.is_active,
    pageInfo: transformAPIKeyToCamel(info.page_info),
  }),
  topicShortInfo: (info) => ({
    id: info.id,
    key: info.id,
    title: info.title,
    isActive: info.is_active,
    toShow: info.to_show,
    rank: info.rank,
    icon: info.icon,
    iconAlt: info.icon_alt,
    rootCategory: info.root_category,
  }),
  categoriesTag: (tags) => {
    return tags.map((tag) => ({
      id: tag.id,
      tagType: tag.tag_type,
      name: tag.name,
      icon: tag.icon,
      isActive: tag.is_active,
      taggedItemCount: tag.tagged_item_count,
    }));
  },
};

export default normalizeTopic;
