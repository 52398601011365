import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { PromotionListItem } from "@api/promotionApi";
import {
  batchDeletePromotions,
  fetchPromotionList,
  reset,
  singleDeletePromotion,
  updatePromotionIsActive,
  updatePromotionListParams,
  updateSelectedPromotionIds,
} from "@redux/promotionSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Modal, Pagination, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { SwitchChangeEventHandler } from "antd/lib/switch";
import { ColumnsType } from "antd/lib/table";
import React, { FC, ReactText, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const DeleteButton = styled(Button)`
  position: absolute;
  left: 26px;
  bottom: 47px;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 47px;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const Img = styled.img`
  width: 30px;
  height: auto;
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
const EditText = styled.a`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const DeleteText = styled.p`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;

const pageSizeOptions = [20, 50, 100];

const PromotionList: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingList, promotionListParams, promotionListResult, selectedPromotionIds } = useSelector(
    (state: RootState) => state.promotion,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const { count, results: promotionList } = promotionListResult;
  const { limit, offset } = promotionListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchPromotionList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const toggleFilter = () => setShowFilter((prev) => !prev);

  const handleOnSingleDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(singleDeletePromotion(id)),
    });
  };

  const handleOnAdd = () => {
    window.open("/promotion/add", "_blank");
  };

  const handleOnSwitch = (id: number): SwitchChangeEventHandler => (checked) => {
    const params = {
      id,
      isActive: checked,
    };
    dispatch(updatePromotionIsActive(params));
  };

  const handleOnDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(batchDeletePromotions()),
    });
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedPromotionIds(values as number[]));
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...promotionListParams,
      limit: value as number,
    };

    dispatch(updatePromotionListParams(filter));
    dispatch(fetchPromotionList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...promotionListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updatePromotionListParams(filter));
    dispatch(fetchPromotionList());
  };

  const tableColumns: ColumnsType<PromotionListItem> = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
    },
    {
      title: <TableNoWrap>啟用</TableNoWrap>,
      key: "isActive",
      dataIndex: "isActive",
      render: (value: PromotionListItem["isActive"], data: PromotionListItem) => (
        <Switch checked={value} onChange={handleOnSwitch(data.id)} />
      ),
    },
    {
      title: <TableNoWrap>活動名稱</TableNoWrap>,
      key: "title",
      dataIndex: "title",
    },
    {
      title: <TableNoWrap>活動時間</TableNoWrap>,
      key: "startAt",
      dataIndex: "startAt",
      render: (value: PromotionListItem["startAt"], data: PromotionListItem) => {
        const { startAt, endAt } = data;
        if (startAt === null) return "";
        return `${startAt} - ${endAt}`;
      },
    },
    {
      title: <TableNoWrap>主圖</TableNoWrap>,
      key: "imageUrl",
      dataIndex: "imageUrl",
      render: (value: PromotionListItem["imageUrl"]) => <Img src={value} />,
    },
    {
      title: <TableNoWrap>網址</TableNoWrap>,
      key: "destinationLink",
      dataIndex: "destinationLink",
      render: (value: PromotionListItem["destinationLink"]) => (
        <Link href={value} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: "",
      width: 75,
      fixed: "right",
      render: (value: any, data: PromotionListItem) => {
        return (
          <EditTextContainer>
            <EditText href={`/promotion/edit/${data.id}`} target="_blank">
              編輯
            </EditText>
            <DeleteText onClick={handleOnSingleDelete(data.id)}>刪除</DeleteText>
          </EditTextContainer>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="活動" />
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
            新增活動
          </TopButton>
          <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
            篩選
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          rowKey="id"
          dataSource={promotionList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedPromotionIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
        />
        <DeleteButton onClick={handleOnDelete} disabled={selectedPromotionIds.length === 0}>
          刪除
        </DeleteButton>
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default PromotionList;
