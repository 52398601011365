import React from "react";
import styled from "styled-components";
import { Button, Form, Row, Select, Col, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { recostState, setCostFilter } from "@redux/recostSlice";
import { ProcessValue } from "./constans";

const Wrapper = styled(Form)`
  padding: 24px 0px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function Filter() {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { costFilter } = useSelector(recostState);

  const onSubmit = () => {
    const formState = form.getFieldsValue();

    dispatch(
      setCostFilter({
        ...costFilter,
        status: formState.status,
        type: formState.type,
        skuNoQ: formState.skuNo,
      }),
    );
  };

  const onReset = () => {
    form.resetFields();
    dispatch(
      setCostFilter({
        ...costFilter,
        status: null,
        type: null,
        skuNoQ: null,
      }),
    );
  };

  return (
    <Wrapper labelCol={{ span: 5 }} colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={10}>
        <Col span={7}>
          <Form.Item label="審核狀態" name="status">
            <Select placeholder="請選擇">
              <Select.Option value={ProcessValue.CLEAN}>待申請</Select.Option>
              <Select.Option value={ProcessValue.PENDING}>待審核</Select.Option>
              <Select.Option value={ProcessValue.APPROVE}>審核通過</Select.Option>
              <Select.Option value={ProcessValue.REJECT}>審核不通過</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item label="部門" name="type">
            <Select placeholder="請選擇">
              <Select.Option value={1}>UrMart</Select.Option>
              <Select.Option value={2}>品運</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="商品條碼" name="skuNo">
            <Input placeholder="請輸入商品條碼" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
}
