import MemberGroupValue, { MemberProgramType } from "@constant/MemberGroupValue";
import GenderValue from "@constant/GenderValue";
import { StandardResponse } from "src/types";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();
interface IMemberApi {
  fetchMemberList: (params: MemberFilterParams) => Promise<MemberListResult>;
  cancelVIPAutoRenewal: (id: number) => Promise<StandardResponse>;
  vipCancelPriceCheck: (id: number) => Promise<VipCancelPriceCheck>;
  cancelVIPMember: (id: number, status: CancelVIPMemberStatus) => Promise<StandardResponse>;
  deleteAccount: (params: { id: number; reason?: string; checkOrder?: boolean }) => Promise<void>;
}

export interface MemberListItem {
  customerType: string;
  memberGroup: {
    id: number;
    title: string;
    groupId: MemberGroupValue;
    discount: number;
    overDue: boolean;
  };
  id: number;
  name: string;
  createdAt: string;
  phone: string;
  email: string;
  gender: GenderValue;
  birthday: string;
  autoRenewal: boolean;
  status: VIPStatus;
  membershipProgram: null | {
    id: number;
    title: string;
    price: number;
    period: number;
    periodType: number;
    programType: number;
    isActive: boolean;
    memberGroup: {
      id: number;
      title: string;
      groupId: MemberGroupValue;
      discount: number;
    };
  };
}

export enum VIPStatus {
  PENDING = 1,
  VALID = 2,
  INVALID = 3,
  TERMINATED = 4,
  CANCELED = 5,
  DISPUTES = 6,
}

export interface MemberFilterParams {
  offset: number;
  limit: number;
  id?: string;
  nameQ?: string;
  email?: string;
  phone?: string;
  groupId?: MemberGroupValue;
  programType?: MemberProgramType;
}
export interface DeleteAccountParams {
  id: number;
  reason?: string;
  checkOrder?: boolean;
}

export interface MemberListResult {
  count: number;
  next: string;
  previous: string;
  results: MemberListItem[];
}

export interface CancelVIPRenewalParams {
  autoRenewal: boolean;
}

export enum CancelVIPMemberStatus {
  VIOLATION = 4,
  NORMAL = 5,
  DISPUTES = 6,
}
export interface VipCancelPriceCheck {
  price: number; // 購買vip 價錢
  refundAmount: number; // 需退款金額
  memberId: number; // 會員id
}

const memberApi: IMemberApi = {
  fetchMemberList: async (params) => {
    const getParams = transformCamelToSnake(params);

    const response = await apiClient.getData("/manage/account/members/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  cancelVIPAutoRenewal: async (id) => {
    const putParams = {
      auto_renewal: false,
    };
    const response = await apiClient.putData(`/manage/account/members/${id}/vip_auto_renewal/`, putParams);
    return response.data;
  },
  cancelVIPMember: async (id, status) => {
    const putParams = {
      status,
    };
    const response = await apiClient.putData(`/manage/account/members/${id}/vip_cancel/`, putParams);
    return response.data;
  },
  vipCancelPriceCheck: async (id) => {
    const response = await apiClient.getData(`/manage/account/members/${id}/vip_cancel_check/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  deleteAccount: async (payload) => {
    const params = {
      purpose: payload.reason,
      check_order: payload.checkOrder,
    };
    const response = await apiClient.deleteData(`/manage/account/members/${payload.id}`, params);
    return response.data;
  },
};

export default memberApi;
