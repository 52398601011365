import pcmApi, {
  PcmList,
  PcmListParams,
  ProductList,
  PcmDetail,
  AddPcmParams,
  AddItemParams,
  ProductDetail,
} from "@api/brandOperation/pcmApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "@redux/notifySlice";

interface IState {
  // 採購單
  pcmList: PcmList;
  pcmDetail: PcmDetail;
  pcmListParams: PcmListParams;
  isFetching: boolean;
  isDetailFetching: boolean;
  // 商品
  productList: ProductList;
  isProductListFetching: boolean;
  showImportCSV: boolean;
  productDetail: ProductDetail;
  productInfo: {
    name: string;
    no: string;
    pcsPerCarton: number;
  };
  importError: any;
  // 其他
  showPopup: boolean;
  showAddItemPopup: boolean;
}

const initialState: IState = {
  // 採購單
  pcmList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
    totalAmount: 0,
    totalPayment: 0,
  },
  pcmListParams: {
    limit: 20,
    offset: 0,
  },
  isFetching: false,
  isDetailFetching: false,
  // 商品
  isProductListFetching: false,
  showImportCSV: false,
  importError: [],

  productList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  productInfo: {
    name: "",
    no: "",
    pcsPerCarton: 0,
  },
  productDetail: {
    id: 0,
    procurementId: 0,
    no: "",
    name: "",
    nameEn: "",
    effectiveDate: null,
    arrivedDate: null,
    arrivedQty: null,
    price: 0,
    orderedQty: 0,
    orderedPcs: 0,
    pcsPerCarton: 0,
    cashflows: [
      {
        id: 0,
        procurementLineId: 0,
        status: 0,
        days: 0,
        ratio: 0,
      },
    ],
  },
  showPopup: false,
  showAddItemPopup: false,

  pcmDetail: {
    id: 0,
    pcmNumber: "",
    producerName: "",
    producerId: 0,

    staff: {
      id: 0,
      name: "",
    },
    totalEstPcmAmount: 0,
    status: 0,
    sectionManagerApproval: 0,
    managerApproval: 0,
    createdDate: "",
    deployDate: "",
    currency: 0,
    currencyRate: 0,
    tradeTerm: 0,
  },
};

export const fetchPcmList = createAsyncThunk("pcmSlice/fetchPcmList", async (params: any, thunkApi) => {
  const response = await pcmApi.fetchPcmList(params);
  thunkApi.dispatch(updatePcmList(response));
});
export const fetchPcmDetail = createAsyncThunk("pcmSlice/fetchPcmDetail", async (pcmId: number | string, thunkApi) => {
  const response = await pcmApi.fetchPcmDetail(pcmId);
  thunkApi.dispatch(updatePcmDetail(response));
});
export const fetchProductList = createAsyncThunk("pcmSlice/fetchProductList", async (id: string | number, thunkApi) => {
  const response = await pcmApi.fetchProductsList(id);
  thunkApi.dispatch(updateProductsList(response));
});
export const fetchProductDetail = createAsyncThunk(
  "pcmSlice/fetchProductDetail",
  async (params: { pcmId: string; productId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await pcmApi.fetchProductDetail(params);
      thunkApi.dispatch(updateProductDetail(response));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const fetchAddPcm = createAsyncThunk("pcmSlice/fetchAddPcm", async (params: AddPcmParams, thunkApi) => {
  try {
    thunkApi.dispatch(showLoading());

    const {
      pcmSlice: { pcmListParams },
    } = thunkApi.getState() as RootState;
    await pcmApi.fetchAddPcm(params);
    thunkApi.dispatch(fetchPcmList(pcmListParams));
    message.success("新增成功");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  } finally {
    thunkApi.dispatch(hideLoading());
    thunkApi.dispatch(setShowPopup(false));
  }
});
export const fetchUpdatePcm = createAsyncThunk(
  "pcmSlice/fetchUpdatePcm",
  async (params: { updatePcmParams: AddPcmParams; pcmId: string }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await pcmApi.fetchUpdatePcm(params);
      thunkApi.dispatch(fetchPcmDetail(params.pcmId));
      message.success("已修改");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(setShowPopup(false));
    }
  },
);

export const fetchDeletePcm = createAsyncThunk("pcmSlice/fetchDeletePcm", async (pcmId: string, thunkApi) => {
  try {
    await pcmApi.fetchDeletePcm(pcmId);
    message.success("已作廢");
    return "success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});
export const fetchProcess = createAsyncThunk(
  "pcmSlice/fetchProcess",
  async (params: { processType: number; id: number }, thunkApi) => {
    try {
      await pcmApi.fetchProcess(params);
      message.success("已送出");
      thunkApi.dispatch(fetchPcmDetail(params.id));
      thunkApi.dispatch(fetchProductList(params.id));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchAddProduct = createAsyncThunk(
  "pcmSlice/fetchAddProduct",
  async (params: { addItem: AddItemParams; pcmId: string }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await pcmApi.fetchAddProduct(params);
      thunkApi.dispatch(fetchProductList(params.pcmId));
      // 更新採購單狀態
      thunkApi.dispatch(fetchPcmDetail(params.pcmId));
      if (params.addItem.id) {
        message.success("編輯成功");
      } else {
        message.success("新增成功");
      }
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());

      thunkApi.dispatch(setShowPopup(false));
    }
  },
);
export const fetchCheckProduct = createAsyncThunk(
  "pcmSlice/fetchCheckProduct",
  async (params: { no: string; producer: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await pcmApi.fetchCheckProduct(params);
      thunkApi.dispatch(updateProductInfo(response));
      return "success";
    } catch (error: any) {
      thunkApi.dispatch(updateProductInfo(""));

      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const fetchDeleteProduct = createAsyncThunk(
  "pcmSlice/fetchDeleteProduct",
  async (params: { pcmId: string; productId: number }, thunkApi) => {
    try {
      await pcmApi.fetchDeleteProduct(params);
      thunkApi.dispatch(fetchProductList(params.pcmId));
      // 更新採購單狀態
      thunkApi.dispatch(fetchPcmDetail(params.pcmId));
      message.success("已刪除");
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const importProductsCSV = createAsyncThunk(
  "pcmSlice/importIWappsProductsXLS",
  async (params: { pcmId: string; file: File }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await pcmApi.importProductsCSV({ pcmId: params.pcmId, file: params.file });
      message.success("匯入成功");
      thunkApi.dispatch(fetchProductList(params.pcmId));
      // 更新採購單狀態
      thunkApi.dispatch(fetchPcmDetail(params.pcmId));
      return response;
    } catch (error: any) {
      const errorResults = Object.values(error.result);
      thunkApi.dispatch(setImportError(errorResults));
      thunkApi.dispatch(setShowImportCSV(false));

      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(setImportError([]));
    }
  },
);

const pcmSlice = createSlice({
  name: "pcmSlice",
  initialState,
  reducers: {
    resetPcmSlice: () => initialState,
    updatePcmList: (state, action) => {
      state.pcmList = action.payload;
    },
    updatePcmDetail: (state, action) => {
      state.pcmDetail = action.payload;
    },
    updateProductsList: (state, action) => {
      state.productList = action.payload;
    },
    updateProductInfo: (state, action) => {
      state.productInfo = action.payload;
    },
    resetProductInfo: (state) => {
      state.productInfo = initialState.productInfo;
    },
    updateProductDetail: (state, action) => {
      state.productDetail = action.payload;
    },
    resetProductDetail: (state) => {
      state.productDetail = initialState.productDetail;
    },
    updatePcmListParams: (state, action) => {
      state.pcmListParams = action.payload;
    },
    setShowPopup: (state, action) => {
      state.showPopup = action.payload;
    },
    setShowAddItemPopup: (state, action) => {
      state.showAddItemPopup = action.payload;
    },
    setShowImportCSV: (state, action) => {
      state.showImportCSV = action.payload;
    },
    setImportError: (state, action) => {
      state.importError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPcmList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPcmList.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchPcmDetail.pending, (state, action) => {
      state.isDetailFetching = true;
    });
    builder.addCase(fetchPcmDetail.fulfilled, (state, action) => {
      state.isDetailFetching = false;
    });
    builder.addCase(fetchAddProduct.fulfilled, (state, action) => {
      state.showAddItemPopup = false;
    });
    builder.addCase(fetchProductList.pending, (state, action) => {
      state.isProductListFetching = true;
    });
    builder.addCase(fetchProductList.fulfilled, (state, action) => {
      state.isProductListFetching = false;
    });
    builder.addCase(importProductsCSV.pending, (state, action) => {
      state.showImportCSV = true;
    });
    builder.addCase(importProductsCSV.fulfilled, (state, action) => {
      state.showImportCSV = false;
    });
  },
});

export const pcmState = (state: RootState) => state.pcmSlice;
export const {
  resetPcmSlice,
  updatePcmList,
  updatePcmListParams,
  setShowPopup,
  setShowAddItemPopup,
  updateProductsList,
  updatePcmDetail,
  updateProductInfo,
  updateProductDetail,
  resetProductDetail,
  setShowImportCSV,
  setImportError,
  resetProductInfo,
} = pcmSlice.actions;
export default pcmSlice.reducer;
