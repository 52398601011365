import PopupBackground from "@component/PopupBackground";
import { orderState } from "@redux/orderSlice";
import { Button, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 450px;
  height: 250px;
  padding: 16px 16px 16px 24px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
`;

const Title = styled.div`
  margin-bottom: 17px;
  ${fontStyle("16px", "22px", "bold")};
`;

const Text = styled.div`
  padding-right: 16px;
  ${fontStyle("14px", "20px")};
`;

const CustomRow = styled(Row)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || "6px"};
`;

type Props = {
  close: () => void;
};

export default function MainOrderDetailPopup(props: Props) {
  const { close } = props;

  const { orderInfo: order } = useSelector(orderState);
  const { orderInfo } = order || {};

  if (!orderInfo) return null;

  return (
    <PopupBackground close={close}>
      <Wrapper>
        <Title>訂單明細</Title>
        <CustomRow justify="space-between">
          <Text>商品總金額</Text>
          <Text>
            {`$${
              orderInfo.grandTotal + orderInfo.salesRuleDiscount + orderInfo.memberGroupDiscount - orderInfo.shippingFee
            }`}
          </Text>
        </CustomRow>
        <CustomRow justify="space-between">
          <Text>運費總金額</Text>
          <Text>{`$${orderInfo.shippingFee}`}</Text>
        </CustomRow>
        {orderInfo.memberGroupDiscount > 0 && (
          <CustomRow justify="space-between" marginBottom="29px">
            <Text>黑卡折扣</Text>
            <Text>{`-$${orderInfo.memberGroupDiscount}`}</Text>
          </CustomRow>
        )}
        {orderInfo.salesRuleDiscount > 0 && (
          <CustomRow justify="space-between" marginBottom="29px">
            <Text>折價券折扣</Text>
            <Text>{`-$${orderInfo.salesRuleDiscount}`}</Text>
          </CustomRow>
        )}
        <CustomRow justify="space-between" marginBottom="29px">
          <Text>總計</Text>
          <Text>{`$${orderInfo.grandTotal}`}</Text>
        </CustomRow>
        <CustomRow justify="end">
          <Button type="primary" onClick={close}>
            我知道了
          </Button>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
