import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import SupplyChainInfo from "@page/SupplyChain/SupplyChainInfo";
import ContactInfo from "@page/SupplyChain/ContactInfo";
import EditContract from "@page/SupplyChain/EditContract";
import CoroperationPath from "@page/SupplyChain/Retailer";

interface Props {
  mode: "add" | "edit";
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
`;

const TabsContainer = styled.div`
  padding: 13px 22px 13px 22px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const SupplyChainTabs: FC<Props> = (props) => {
  const { mode } = props;
  const { supplyChainId } = useParams();

  return (
    <Wrapper>
      {mode === "add" ? <PageTitle title="供應商-新增" /> : <PageTitle title="供應商-編輯" />}
      <TabsContainer>
        <Tabs defaultActiveKey="basic" type="card" destroyInactiveTabPane>
          <Tabs.TabPane tab="供應商資訊" key="supplierInfo">
            <SupplyChainInfo mode={mode} />
          </Tabs.TabPane>

          {mode === "edit" && (
            <>
              <Tabs.TabPane tab="聯絡人資訊" key="contactInfo">
                <ContactInfo supplyChainId={Number(supplyChainId)} />
              </Tabs.TabPane>
              <Tabs.TabPane tab="合約管理" key="EditContract">
                <EditContract />
              </Tabs.TabPane>
              <Tabs.TabPane tab="當前合作通路" key="coroperationPath">
                <CoroperationPath />
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </TabsContainer>
    </Wrapper>
  );
};

export default SupplyChainTabs;
