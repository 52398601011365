/* eslint-disable camelcase */
export interface ProductDailyAggregations {
  id: number;
  statsDate: string;
  productId: number;
  productName: string;
  sku: string;
  owner: string;
  categoryPath: string;
  goodInitialInventory: number;
  badInitialInventory: number;
  goodInbound: number;
  badInbound: number;
  goodReturnInbound: number;
  badReturnInbound: number;
  goodOutbound: number;
  badOutbound: number;
  goodSales: number;
  badSales: number;
  goodDeleteSales: number;
  goodEntry: number;
  badEntry: number;
  goodReturn: number;
  badReturn: number;
  goodDeleteOutbound: number;
  badDeleteOutbound: number;
  goodAttributeChangeOutbound: number;
  badAttributeChangeInbound: number;
  badAttributeChangeOutbound: number;
  goodAttributeChangeInbound: number;
  goodProductTransferOutbound: number;
  goodInventoryProfit: number;
  badInventoryProfit: number;
  goodInventoryLosses: number;
  badInventoryLosses: number;
  goodFinalInventory: number;
  badFinalInventory: number;
}

interface NormalizeStock {
  productDailyAggregations: (
    response: {
      id: number;
      stats_date: string;
      product_info: {
        id: number;
        sku: string;
        name: string;
        owner: string;
        category_path: string;
      };
      good_initial_inventory: number;
      bad_initial_inventory: number;
      good_inbound: number;
      bad_inbound: number;
      good_outbound: number;
      bad_outbound: number;
      good_return_inbound: number;
      bad_return_inbound: number;
      good_sales: number;
      bad_sales: number;
      good_inventory_profit: number;
      bad_inventory_profit: number;
      good_inventory_losses: number;
      bad_inventory_losses: number;
      good_final_inventory: number;
      bad_final_inventory: number;
      good_delete_sales: number;
      good_entry: number;
      bad_entry: number;
      good_return: number;
      bad_return: number;
      good_delete_outbound: number;
      bad_delete_outbound: number;
      good_attribute_change_outbound: number;
      bad_attribute_change_inbound: number;
      bad_attribute_change_outbound: number;
      good_attribute_change_inbound: number;
      good_product_transfer_outbound: number;
    }[],
  ) => ProductDailyAggregations[];
}

const normalizeStock: NormalizeStock = {
  productDailyAggregations: (response) => {
    return response.map((item) => ({
      id: item.id,
      statsDate: item.stats_date,
      productId: item.product_info.id,
      productName: item.product_info.name,
      sku: item.product_info.sku,
      owner: item.product_info.owner,
      categoryPath: item.product_info.category_path,
      goodInitialInventory: item.good_initial_inventory,
      badInitialInventory: item.bad_initial_inventory,
      goodInbound: item.good_inbound,
      badInbound: item.bad_inbound,
      goodReturnInbound: item.good_return_inbound,
      badReturnInbound: item.bad_return_inbound,
      goodOutbound: item.good_outbound,
      badOutbound: item.bad_outbound,
      goodSales: item.good_sales,
      badSales: item.bad_sales,
      goodInventoryProfit: item.good_inventory_profit,
      badInventoryProfit: item.bad_inventory_profit,
      goodInventoryLosses: item.good_inventory_losses,
      badInventoryLosses: item.bad_inventory_losses,
      goodFinalInventory: item.good_final_inventory,
      badFinalInventory: item.bad_final_inventory,
      goodDeleteSales: item.good_delete_sales,
      goodEntry: item.good_entry,
      badEntry: item.bad_entry,
      goodReturn: item.good_return,
      badReturn: item.bad_return,
      goodDeleteOutbound: item.good_delete_outbound,
      badDeleteOutbound: item.bad_delete_outbound,
      goodAttributeChangeOutbound: item.good_attribute_change_outbound,
      badAttributeChangeInbound: item.bad_attribute_change_inbound,
      badAttributeChangeOutbound: item.bad_attribute_change_outbound,
      goodAttributeChangeInbound: item.good_attribute_change_inbound,
      goodProductTransferOutbound: item.good_product_transfer_outbound,
    }));
  },
};

export default normalizeStock;
