/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import normalizeBrand, { Brand } from "./utils/normalizeBrand";

export interface BrandListConfig {
  limit?: number;
  offset?: number;
  searchName?: string;
  searchId?: number;
}

export interface CreateBrandConfig {
  name: string;
}

export interface UpdateBrandConfig {
  name: string;
}

interface BrandAPI {
  fetchBrandList: (parameters: BrandListConfig) => Promise<{ data: Brand[]; count: number }>;
  fetchCreateBrand: (payload: CreateBrandConfig) => Promise<void>;
  fetchUpdateBrand: (brandId: number, payload: UpdateBrandConfig) => Promise<void>;
}

const apiClient = new CoreAPI();

const BrandApi: BrandAPI = {
  fetchBrandList: async (param) => {
    const getParam = {
      limit: param.limit,
      offset: param.offset,
      name_q: param.searchName === "" ? undefined : param.searchName,
      id: param.searchId,
    };

    const response = await apiClient.getData("/manage/tag/brands", getParam);

    return {
      data: normalizeBrand.brandList(response.data.result.results),
      count: response.data.result.count,
    };
  },
  fetchCreateBrand: async (payload) => {
    await apiClient.postData("/manage/tag/brands/", payload);
  },
  fetchUpdateBrand: async (brandId, payload) => {
    await apiClient.patchData(`/manage/tag/brands/${brandId}/`, payload);
  },
};

export default BrandApi;
