import React, { useEffect, useState } from "react";
import PageTitle from "@component/PageTitle";
import { FilterTwoTone } from "@ant-design/icons";
import styled from "styled-components";
import { Button, Table, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { cohortState, fetchCohortList, resetCohortSlice } from "@redux/cohortSlice";
import { ColumnsType } from "antd/lib/table";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

export default function Cohort() {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { cohortListResult, cohortFilter, isFetching } = useSelector(cohortState);

  const columns: ColumnsType<any> = [
    {
      key: "totalMemberCount",
      dataIndex: "totalMemberCount",
      title: "總人數",
    },
    {
      key: "totalLTV",
      dataIndex: "totalLTV",
      title: <Tooltip title="單一月份全部週期營收加總">總LTV</Tooltip>,
    },
    {
      key: "ARPU",
      dataIndex: "ARPU",
      title: <Tooltip title="人均貢獻">ARPU</Tooltip>,
    },
    {
      key: "cohortMonth",
      dataIndex: "cohortMonth",
      title: "月份",
    },
  ];

  const round = (num: number) => {
    const expandedNum = Number((Math.abs(num) * 100).toPrecision(15));
    return (Math.round(expandedNum) / 100) * Math.sign(num);
  };

  if (cohortListResult) {
    for (let i: number = 0; i < cohortListResult.length; i += 1) {
      columns.push({
        title: `M${i}`,
        key: `${i}`,
        dataIndex: "MList",
        render: (data) => (
          <>
            {data[i] && (
              <>
                <div>
                  訂單 {data[i]?.orderCount}
                  {` (${round((data[i].orderCount / data[0].orderCount) * 100)}%)`}
                </div>
                <div>
                  營收 {data[i]?.amount}
                  {` (${round((data[i].amount / data[0].amount) * 100)}%)`}
                </div>
                <div>
                  會員 {data[i]?.memberCount}
                  {` (${round((data[i].memberCount / data[0].memberCount) * 100)}%)`}
                </div>
              </>
            )}
          </>
        ),
      });
    }
  }

  useEffect(() => {
    dispatch(fetchCohortList(cohortFilter));
  }, [cohortFilter, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetCohortSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="會員Cohort分析表" />
      <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
        篩選
      </Button>
      {showFilter && <Filter />}
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          pagination={false}
          loading={isFetching}
          columns={columns}
          dataSource={cohortListResult}
        />
      </TableWrapper>
    </Wrapper>
  );
}
