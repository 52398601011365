import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { ProductVendorListItem } from "./productApi";
import { SalePlan } from "./saleApi";

const apiClient = new CoreAPI();

interface ISalePlanApi {
  fetchSalePlanList: (params: SalePlanListParams) => Promise<SalePlanListResult>;
  getSalePlan: (id: number) => Promise<SalePlan>;
  createSalePlanToRule: (params: CreateSalePlanToRuleParams) => Promise<SalePlan>;
  updateSalePlanToRule: (planId: number, params: UpdateSalePlanToRuleParams) => Promise<SalePlan>;
  fetchVendorProductContract: (params: FetchVendorProductContractParams) => Promise<VendorProductContractResult>;
  batchSortRulesSalesPlan: (ruleId: number, salePlans: SortItem[]) => Promise<void>;
}

export enum RuleTypeValue {
  NORMAL = 0,
  GIFT = 1,
  ADDITIONAL_PURCHASE = 2,
}

export interface SalePlanListResult {
  count: number;
  next: string;
  previous: string;
  results: SalePlan[];
}

export interface SalePlanListParams {
  limit: number;
  offset: number;
  planIds?: string;
  planNameQ?: string;
  excludeRuleId?: number;
  ruleType?: RuleTypeValue;
}

export interface CreateSalePlanToRuleParams {
  name: string;
  imageDirPrefix?: string;
  imageFilePath?: string;
  ruleId: number;
  ruleProductsInfo: RuleProductInfo[];
  description?: string;
}

type UpdateSalePlanToRuleParams = CreateSalePlanToRuleParams;

export interface RuleProductInfo {
  vpcId: number;
  qty: number;
  price: number;
  commissionRate: number;
}

export interface FetchVendorProductContractParams {
  excludeRuleId: number;
  sku: string;
  isActive?: boolean;
  salesChannel: number;
}

export interface VendorProductContractResult {
  count: number;
  next: string;
  previous: string;
  results: VendorProductContractItem[];
}

type VendorProductContractItem = Omit<ProductVendorListItem, "deductionInfo"> & { vpcId: number };

export type SortItem = {
  id: number;
  rank: number;
};

const salePlanApi: ISalePlanApi = {
  fetchSalePlanList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/sales/plans/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  getSalePlan: async (id) => {
    const response = await apiClient.getData(`/manage/sales/plans/${id}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  createSalePlanToRule: async (params) => {
    const postParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/manage/sales/plans/", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateSalePlanToRule: async (planId, params) => {
    const putParams = transformCamelToSnake(params);
    const response = await apiClient.putData(`/manage/sales/plans/${planId}/`, putParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchVendorProductContract: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/product/product-vendors/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchSortRulesSalesPlan: async (ruleId, salePlans) => {
    const payload = { relations: transformCamelToSnake(salePlans) };
    const response = await apiClient.postData(`/manage/promotion/rules/${ruleId}/batch-rank/`, payload);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default salePlanApi;
