import React, { FC } from "react";
import { Routes, Route } from "react-router-dom";
import RuleList from "./RuleList";
import RuleEdit from "./Edit";

const RulePage: FC = () => (
  <Routes>
    <Route path="/" element={<RuleList />} />
    <Route path="/add" element={<RuleEdit mode="add" />} />
    <Route path="/edit/:id" element={<RuleEdit mode="edit" />} />
  </Routes>
);

export default RulePage;
