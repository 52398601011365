import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const NewMemberCard: FC = () => {
  const isFetchingNewMemberDashboard = useSelector((state: RootState) => state.dashboard.isFetchingNewMemberDashboard);
  const newMemberDashboard = useSelector((state: RootState) => state.dashboard.newMemberDashboard);
  const { currentData, diff } = newMemberDashboard;
  const { registerMember, registerMemberBuy, memberBuyRate = 0 } = currentData || {};
  const { registerMemberDiff = 0, registerMemberBuyDiff = 0, memberBuyRateDiff = 0 } = diff || {};

  const memberBuyRatePercentage = Math.round(memberBuyRate * 1000) / 10; // 百分比取小數點第一位
  const memberBuyRateTagColor = memberBuyRateDiff < 0 ? "error" : "success";
  const memberBuyRateTagIcon = memberBuyRateDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const memberBuyRateChangePercentage = Math.round(memberBuyRateDiff * 1000) / 10; // 百分比取小數點第一位

  const registerMemberTagColor = registerMemberDiff < 0 ? "error" : "success";
  const registerMemberTagIcon = registerMemberDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const registerMemberChangePercentage = Math.round(registerMemberDiff * 1000) / 10; // 百分比取小數點第一位

  const registerMemberBuyTagColor = registerMemberBuyDiff < 0 ? "error" : "success";
  const registerMemberBuyTagIcon = registerMemberBuyDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const registerMemberBuyChangePercentage = Math.round(registerMemberBuyDiff * 1000) / 10; // 百分比取小數點第一位

  return (
    <Spin spinning={isFetchingNewMemberDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>新客</HeaderTitle>
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              新註冊購買比例
              <Tooltip placement="bottom" title="新註冊會員在時間區段變為首次購買以上人數/新註冊會員數">
                <InfoIcon />
              </Tooltip>
              <AnchorToChart href="/dashboard/new-member-purchase-ratio" />
            </SectionTitle>
            <SectionContent>
              {`${memberBuyRatePercentage}%`}
              <StyledTag color={memberBuyRateTagColor} icon={memberBuyRateTagIcon}>
                {`${memberBuyRateChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <BottomSection>
            <SectionTitle>
              新註冊會員人數
              <AnchorToChart href="/dashboard/new-member-count" />
            </SectionTitle>
            <SectionContent>
              {registerMember}
              <StyledTag color={registerMemberTagColor} icon={registerMemberTagIcon}>
                {`${registerMemberChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
          <BottomSection>
            <SectionTitle>
              首次購買人數
              <AnchorToChart href="/dashboard/first-purchase-count" />
            </SectionTitle>
            <SectionContent>
              {registerMemberBuy}
              <StyledTag color={registerMemberBuyTagColor} icon={registerMemberBuyTagIcon}>
                {`${registerMemberBuyChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default NewMemberCard;
