import { FilterTwoTone } from "@ant-design/icons";
import { ShelfLifeListItem, ShelfLifeListParam } from "@api/stockApi";
import { RootState } from "@redux/rootReducer";
import { exportShelfLifeListByCSV, fetchShelfLifeList, reset, updateShelfLifeListParam } from "@redux/stockSlice";
import { Button, Pagination, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import React, { FC, Key, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import moment from "moment";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 30px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 100px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 30px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;

const Text = styled.div``;
const TextWarning = styled.div`
  color: red;
`;

const pageSizeOptions = [20, 50, 100];

const ShelfLife: FC = () => {
  const dispatch = useAppDispatch();
  const shelfLifeListResult = useSelector((state: RootState) => state.stock.shelfLifeListResult);
  const shelfLifeListParam = useSelector((state: RootState) => state.stock.shelfLifeListParam);
  const isFetchingList = useSelector((state: RootState) => state.stock.isFetchingList);

  const [showFilter, setShowFilter] = useState<boolean>(true);

  const { count, results: productList } = shelfLifeListResult;
  const { limit, offset } = shelfLifeListParam;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchShelfLifeList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const toggleShowFilter = () => setShowFilter((prev) => !prev);

  const handleOnExport = () => dispatch(exportShelfLifeListByCSV());

  const handleOnPageSizeChange = (value: SelectValue) => {
    const params = {
      ...shelfLifeListParam,
      limit: value as number,
    };

    dispatch(updateShelfLifeListParam(params));
    dispatch(fetchShelfLifeList());
  };

  const handleOnPageChange = (page: number) => {
    const params = {
      ...shelfLifeListParam,
      offset: (page - 1) * limit,
    };

    dispatch(updateShelfLifeListParam(params));
    dispatch(fetchShelfLifeList());
  };

  // sorting
  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: any,
  ) => {
    const { field, order } = sorter;
    let ordering = "";

    switch (order) {
      case "descend":
        ordering = `-${field}`;
        break;
      case "ascend":
        ordering = field;
        break;
      default:
    }

    const params: ShelfLifeListParam = {
      ...shelfLifeListParam,
      ordering,
    };

    dispatch(updateShelfLifeListParam(params));
    dispatch(fetchShelfLifeList());
  };

  const tableColumns: ColumnsType<ShelfLifeListItem> = [
    {
      title: <TableNoWrap>ID</TableNoWrap>,
      key: "id",
      dataIndex: "id",
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? <Text>{data.id}</Text> : <TextWarning>{data.id}</TextWarning>,
    },
    {
      title: <TableNoWrap>SKU</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? <Text>{value.sku}</Text> : <TextWarning>{value.sku}</TextWarning>,
    },
    {
      title: <TableNoWrap>商品名稱</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.name}</Text>
        ) : (
          <TextWarning>{value.name}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>庫存數</TableNoWrap>,
      key: "stockQty",
      dataIndex: "stockQty",
      width: 90,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.stockQty}</Text>
        ) : (
          <TextWarning>{data.stockQty}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>庫存價值</TableNoWrap>,
      key: "stockValue",
      dataIndex: "stockValue",
      width: 100,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.stockValue}</Text>
        ) : (
          <TextWarning>{data.stockValue}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>有效日期</TableNoWrap>,
      key: "effectiveDate",
      dataIndex: "effectiveDate",
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.effectiveDate}</Text>
        ) : (
          <TextWarning>{data.effectiveDate}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>允售期限</TableNoWrap>,
      key: "canSellExpiration",
      dataIndex: "",
      sorter: true,
      render: (data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.canSellExpiration}</Text>
        ) : (
          <TextWarning>{data.canSellExpiration}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>日平均銷量</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      width: 110,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.avgDailySalesVolume}</Text>
        ) : (
          <TextWarning>{value.avgDailySalesVolume}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>迴轉天數</TableNoWrap>,
      key: "turnoverDays",
      dataIndex: "turnoverDays",
      width: 80,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.turnoverDays}</Text>
        ) : (
          <TextWarning>{data.turnoverDays}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>剩餘可售天數</TableNoWrap>,
      key: "remainingCanSellDays",
      dataIndex: "remainingCanSellDays",
      width: 130,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{data.remainingCanSellDays}</Text>
        ) : (
          <TextWarning>{data.remainingCanSellDays}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>良品</TableNoWrap>,
      key: "qualified",
      dataIndex: "qualified",
      width: 130,
      render: (value: ShelfLifeListItem["qualified"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value ? "是" : "否"}</Text>
        ) : (
          <TextWarning>{value ? "是" : "否"}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>成本</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      width: 70,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.weightedCost}</Text>
        ) : (
          <TextWarning>{value.weightedCost}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>倉租費用</TableNoWrap>,
      key: "warehouseRentalFee",
      dataIndex: "warehouseRentalFee",
      width: 80,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.weightedCost}</Text>
        ) : (
          <TextWarning>{value.weightedCost}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>商開負責人</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.owner}</Text>
        ) : (
          <TextWarning>{value.owner}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>合作模式</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.contractMode}</Text>
        ) : (
          <TextWarning>{value.contractMode}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>最近實際進倉日</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      width: 140,
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.latestStockedDate}</Text>
        ) : (
          <TextWarning>{value.latestStockedDate}</TextWarning>
        ),
    },
    {
      title: <TableNoWrap>上架時間</TableNoWrap>,
      key: "productInfo",
      dataIndex: "productInfo",
      sorter: true,
      render: (value: ShelfLifeListItem["productInfo"], data: ShelfLifeListItem) =>
        moment() <= moment(data.canSellExpiration) ? (
          <Text>{value.createdAt}</Text>
        ) : (
          <TextWarning>{value.createdAt}</TextWarning>
        ),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="效期管理" />
      <Header>
        <TopButtonContainer>
          <TopButton icon={<FilterTwoTone />} onClick={toggleShowFilter}>
            篩選
          </TopButton>
          <TopButton onClick={handleOnExport}>匯出</TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option value={option}>{option}</Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          dataSource={productList}
          columns={tableColumns}
          scroll={{ x: "max-content" }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
          onChange={handleOnChange}
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default ShelfLife;
