import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { useDispatch } from "react-redux";
import { resetODStatisticsSlice } from "@redux/finance/onlineDistributorStatisticsSlice";
import OrderList from "./OrderList";
import ChargeList from "./ChargeList";
// import { useDispatch } from "react-redux";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;
const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

enum TabList {
  ORDER_LIST = "ORDER_LIST",
  CHARGE_LIST = "CHARGE_LIST",
}

const OnlineDistributorStatistics = () => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState<string>(TabList.ORDER_LIST);

  useEffect(() => {
    return () => {
      dispatch(resetODStatisticsSlice());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="線上通路銷售報表" />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="訂單資料" key={TabList.ORDER_LIST}>
            <OrderList />
          </Tabs.TabPane>
          <Tabs.TabPane tab="扣費" key={TabList.CHARGE_LIST}>
            <ChargeList />
          </Tabs.TabPane>
        </Tabs>
      </Scope>
    </Wrapper>
  );
};

export default OnlineDistributorStatistics;
