import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchAndActivate, getRemoteConfig, getValue } from "firebase/remote-config";
import type { RootState } from "./rootReducer";

interface IState {
  webDomain: string;
}

const initialState: IState = {
  webDomain: "",
};

export const fetchWebDomainService = createAsyncThunk("config/fetchWebDomainService", async () => {
  const remoteConfig = getRemoteConfig();
  remoteConfig.settings.minimumFetchIntervalMillis = 60000;
  await fetchAndActivate(remoteConfig);
  const remoteProductionDomain = getValue(remoteConfig, "production_domain");
  const localSetDomain = process.env.REACT_APP_WEB_DOMAIN as string;

  if (window.location.href.includes("ada-test") || window.location.href.includes("localhost")) {
    return localSetDomain;
  }
  return remoteProductionDomain.asString() || localSetDomain;
});

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWebDomainService.fulfilled, (state, action) => {
      state.webDomain = action.payload;
    });
  },
});

export const configState = (state: RootState) => state.config;
export default configSlice.reducer;
