import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { ColumnsType } from "antd/lib/table";
import { Button, Table, Modal, Select, Pagination } from "antd";
import { PlusCircleTwoTone, ExclamationCircleOutlined } from "@ant-design/icons";
import EditContractPopup from "@page/SupplyChain/EditContractPopup";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { SelectValue } from "antd/lib/select";
import { fetchSupplyChainContractList, deleteSupplyChainContract } from "@redux/supplyChainSlice";
import { SupplyChainContractDetail, DeleteContractParam } from "@api/supplyChainApi";

const Wrapper = styled.div``;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TablePageInfo = styled.div`
  font-size: 14px;
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  position: relative;
  padding: 20px 10px 50px 10px;
  border: 1px solid #f0f0f0;
`;
const TablePagination = styled(Pagination)`
  position: absolute;
  right: 21px;
  bottom: 10px;
`;
const Edit = styled.div`
  white-space: nowrap;
  color: #1790ff;
  cursor: pointer;
`;

const PdfDiv = styled.div`
  position: relative;
  width: 60px;
  height: 70px;
`;
const ClickContract = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const EditContract = () => {
  const dispatch = useAppDispatch();
  const [currentContract, setcurrentContract] = useState<SupplyChainContractDetail | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const supplyChainSlice = useSelector((state: RootState) => state.supplyChain);
  const supplyChainContractList = supplyChainSlice.supplyChainContractList.results;
  const { count } = supplyChainSlice.supplyChainContractList;
  const { isFetching } = supplyChainSlice;
  const { supplyChainFilter } = supplyChainSlice;
  const { limit, offset } = supplyChainFilter;
  const { supplyChainId } = useParams();

  const currentPage = offset && limit && Math.floor(offset / limit) + 1;

  const handleSelectChange = (value: SelectValue) => {
    const filter = {
      ...supplyChainFilter,
      limit: value as number,
      supplyChain: Number(supplyChainId),
    };
    dispatch(fetchSupplyChainContractList(filter));
  };

  const handleOnPageChange = (page: number) => {
    if (limit) {
      const filter = {
        ...supplyChainFilter,
        offset: (page - 1) * limit,
        supplyChain: Number(supplyChainId),
      };
      dispatch(fetchSupplyChainContractList(filter));
    }
  };

  const tableColumns: ColumnsType<SupplyChainContractDetail> = [
    {
      title: "編號",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "合約名稱",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "合約生效時間",
      dataIndex: "startAt",
      key: "startAt",
    },
    {
      title: "合約結束時間",
      dataIndex: "endAt",
      key: "endAt",
    },
    {
      title: "檢視檔案",
      dataIndex: "media",
      key: "media",
      render: (media) => (
        <PdfDiv>
          <embed src={media} width="100%" height="100%" />
          <ClickContract onClick={() => window.open(media, "_blank")} />
        </PdfDiv>
      ),
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data) => {
        return <Edit onClick={() => toggleEditPopup(data)}>檢視/編輯</Edit>;
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      fixed: "right",
      render: (data) => {
        return <Edit onClick={() => handleDelete(data.id)}>刪除</Edit>;
      },
    },
  ];
  const toggleEditPopup = (data: SupplyChainContractDetail) => {
    setOpenPopup(true);
    setcurrentContract(data);
  };

  const handlePopup = () => {
    setOpenPopup((prev) => !prev);
    setcurrentContract(null);
  };

  const handleDelete = (deleteId: number) => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        const params: DeleteContractParam = {
          id: deleteId,
          supplyChain: Number(supplyChainId),
        };
        dispatch(deleteSupplyChainContract(params));
      },
    });
  };

  useEffect(() => {
    const filter = {
      supplyChain: Number(supplyChainId),
    };
    dispatch(fetchSupplyChainContractList(filter));
  }, [dispatch, supplyChainId]);

  return (
    <Wrapper>
      <Header>
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={handlePopup}>
          新增合約
        </Button>
        {openPopup && <EditContractPopup onClose={handlePopup} currentContract={currentContract} />}
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect defaultValue="20" onChange={(value) => handleSelectChange(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          dataSource={supplyChainContractList}
          columns={tableColumns}
          pagination={false}
          rowKey="id"
        />
        <TablePagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </TableContainer>
    </Wrapper>
  );
};

export default EditContract;
