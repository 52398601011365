import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const WebSection = styled(Section)`
  grid-row-start: 2;
`;
const AppSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;

const OrderCountCard: FC = () => {
  const isFetchingRevenueDashboard = useSelector((state: RootState) => state.dashboard.isFetchingRevenueDashboard);
  const revenueDashboard = useSelector((state: RootState) => state.dashboard.revenueDashboard);
  const { currentData, diff } = revenueDashboard;
  const { order = 0, webOrder = 0, appOrder = 0 } = currentData || {};
  const { orderDiff = 0, webOrderDiff = 0, appOrderDiff = 0 } = diff || {};

  const orderTagColor = orderDiff < 0 ? "error" : "success";
  const orderTagIcon = orderDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const orderChangePercentage = Math.round(orderDiff * 1000) / 10; // 百分比取小數點第一位

  const webOrderTagColor = webOrderDiff < 0 ? "error" : "success";
  const webOrderTagIcon = webOrderDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const webOrderChangePercentage = Math.round(webOrderDiff * 1000) / 10; // 百分比取小數點第一位

  const appOrderTagColor = appOrderDiff < 0 ? "error" : "success";
  const appOrderTagIcon = appOrderDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const appOrderChangePercentage = Math.round(appOrderDiff * 1000) / 10;

  return (
    <Spin spinning={isFetchingRevenueDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>訂單數</HeaderTitle>
          <AnchorToChart href="/dashboard/order-count" />
        </Header>
        <Grid>
          <Section>
            <SectionTitle>總訂單數</SectionTitle>
            <SectionContent>
              {order}
              <StyledTag color={orderTagColor} icon={orderTagIcon}>
                {`${orderChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <WebSection>
            <SectionTitle>Web訂單數</SectionTitle>
            <SectionContent>
              {webOrder}
              <StyledTag color={webOrderTagColor} icon={webOrderTagIcon}>
                {`${webOrderChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </WebSection>
          <AppSection>
            <SectionTitle>App訂單數</SectionTitle>
            <SectionContent>
              {appOrder}
              <StyledTag color={appOrderTagColor} icon={appOrderTagIcon}>
                {`${appOrderChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </AppSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default OrderCountCard;
