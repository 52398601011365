export enum ActionTypeValue {
  NO_CONDITION,
  DEDUCTION,
  DISCOUNT,
  NON_BOUNDED_GIFT,
  BOUNDED_GIFT,
  ADDITIONAL_PURCHASE,
  ACTION_TYPE_RECALCULATE_DISCOUNT,
  ACTION_TYPE_BUY_M_GET_N_FREE,
  ADDITIONAL_PURCHASE_PROMOTION,
  BOUNDED_GIFT_ANY,
}

const actionTypeValue: {
  [type: number]: string;
} = {
  [ActionTypeValue.NO_CONDITION]: "無條件",
  [ActionTypeValue.DEDUCTION]: "折抵",
  [ActionTypeValue.DISCOUNT]: "打折",
  [ActionTypeValue.NON_BOUNDED_GIFT]: "贈品不綁人",
  [ActionTypeValue.BOUNDED_GIFT]: "贈品綁人全選",
  [ActionTypeValue.ADDITIONAL_PURCHASE]: "加價購",
  [ActionTypeValue.ACTION_TYPE_RECALCULATE_DISCOUNT]: "滿件重新計算",
  [ActionTypeValue.ACTION_TYPE_BUY_M_GET_N_FREE]: "滿M送N",
  [ActionTypeValue.ADDITIONAL_PURCHASE_PROMOTION]: "加價購推廣區",
  [ActionTypeValue.BOUNDED_GIFT_ANY]: "贈品綁人任選",
};
export default actionTypeValue;
