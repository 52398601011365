import { CreateSlotTargetParam, SlotTargetListItem } from "@api/slotApi";
import { RootState } from "@redux/rootReducer";
import { createSlotTarget, updateSlotDetail } from "@redux/slotSlice";
import { Button, Form, InputNumber, Modal, Select } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import { PageType, PageTypeList, SlotTypeValue } from "../../constant";
import AddSalePage from "./AddSalePage/AddSalePage";

interface Props {
  show: boolean;
  mode: string | undefined;
  slotDetail?: SlotTargetListItem;
  onClose: () => void;
}

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  align-self: flex-start;
  line-height: 32px;
  font-size: 14px;
  width: 63px;
  margin-right: 25px;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const RowContent = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "auto")};
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const ButtonContainer = styled.div`
  position: absolute;
  bottom: 23px;
  right: 28px;
  text-align: right;
`;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;

const AddNewPopup: FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { show, onClose, mode, slotDetail } = props;
  const [form] = Form.useForm<CreateSlotTargetParam>();
  const [isSalePage, setIsSalePage] = useState(false);
  const { slotType } = useSelector((state: RootState) => state.slot.slotDetail);

  const pageTypeOptions = useMemo(() => {
    return PageTypeList.map((pageType) => {
      const optionEle = (
        <Select.Option key={pageType.id} value={pageType.id}>
          {pageType.value}
        </Select.Option>
      );
      if (slotType === SlotTypeValue.FLAT_CAROUSEL_SLOT || slotType === SlotTypeValue.CAROUSEL_SLOT) {
        return optionEle;
      }

      switch (pageType.id) {
        case PageType.ALL_SALES_PAGE:
        case PageType.SALES_PAGE:
          return null;
        default:
          return optionEle;
      }
    });
  }, [slotType]);

  useEffect(() => {
    form.setFieldsValue({
      pageType: -1,
      pageInfo: undefined,
      position: 1,
    });
  }, [form, show]);

  const handleOnSubmit = async () => {
    const values = form.getFieldsValue();
    if (mode === "new") {
      dispatch(createSlotTarget(values));
    } else if (slotDetail?.id) {
      const payload = {
        relationId: slotDetail.id,
        params: values,
      };
      dispatch(updateSlotDetail(payload));
    }

    onClose();
  };

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue({
        pageType: slotDetail?.pageType,
        pageInfo: slotDetail?.pageInfo,
        position: slotDetail?.position,
      });
    }
  }, [form, mode, slotDetail?.pageInfo, slotDetail?.pageType, slotDetail?.position]);

  const onPageTypeChange = () => {
    form.setFieldsValue({
      pageInfo: undefined,
      position: undefined,
    });
  };

  return (
    <Modal
      centered
      visible={show}
      width={isSalePage ? 930 : 650}
      bodyStyle={{ height: isSalePage ? 620 : 288, padding: "40px 28px 23px 32px" }}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <Form
        form={form}
        initialValues={{
          pageType: -1,
          position: 1,
        }}
        onFinish={handleOnSubmit}
      >
        <Row>
          <RowTitle>
            頁面類型
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent width={200}>
            <Form.Item
              name="pageType"
              rules={[
                {
                  validator: (rule, value: number) => {
                    if (value > -1) return Promise.resolve();
                    return Promise.reject();
                  },
                },
              ]}
            >
              <StyledSelect onChange={onPageTypeChange}>{pageTypeOptions}</StyledSelect>
            </Form.Item>
          </RowContent>
        </Row>
        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.pageType !== currentValues.pageType}>
          {({ getFieldValue }) => {
            const pageType = getFieldValue("pageType");
            const isHome = pageType === PageType.HOME;
            if (isHome) {
              form.setFieldsValue({
                pageInfo: undefined,
              });
            }
            switch (pageType) {
              case PageType.ALL_SALES_PAGE:
                setIsSalePage(false);
                return null;
              case PageType.SALES_PAGE:
                setIsSalePage(true);
                return null;
              default:
                setIsSalePage(false);
                return (
                  <>
                    <Row>
                      <RowTitle>
                        頁面資訊
                        {!isHome && <RequiredIcon>*</RequiredIcon>}
                      </RowTitle>
                      <RowContent width={200}>
                        <Form.Item name="pageInfo" rules={isHome ? [] : [{ required: true, message: "" }]}>
                          <InputNumber disabled={isHome || pageType < 0} />
                        </Form.Item>
                      </RowContent>
                    </Row>
                    <Row>
                      <RowTitle>位置</RowTitle>
                      <RowContent width={70}>
                        <Form.Item name="position">
                          <InputNumber />
                        </Form.Item>
                      </RowContent>
                    </Row>
                  </>
                );
            }
          }}
        </Form.Item>
        {isSalePage ? (
          <AddSalePage onClose={onClose} />
        ) : (
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        )}
      </Form>
    </Modal>
  );
};

export default AddNewPopup;
