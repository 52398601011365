import { ProfitsConversionList, ProftisReviewStatus } from "@api/vendorApi";
import {
  fetchApproveTransferProfits,
  fetchDeleteTransferProfits,
  fetchMarginAddTopInfo,
  fetchProfitsConversionInfo,
  vendorState,
} from "@redux/vendorSlice";
import { Button, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import MarginEditModal, { MarginEditType } from "./MarginEditModal";

const Wrapper = styled.div`
  border: 1px solid #f0f0f0;
  padding: 28px 20px 8px 20px;
  margin: 20px 24px;
  border-radius: 2px;
`;
const Text = styled.div`
  font-size: 14px;
  min-width: 110px;
  margin-right: 23px;
`;
const CustomRow = styled(Row)`
  margin-bottom: 20px;
`;
const ButtonRow = styled(Row)`
  margin-top: 20px;
  margin-right: 20px;
`;
const CustomButton = styled(Button)`
  margin-left: 10px;
`;
const CustomLink = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
`;

export default function MarginManagementView() {
  const dispatch = useDispatch();
  const { marginAddTopInfo, profitsConversionInfo } = useSelector(vendorState);

  const { vendorId, depositId, profitsId } = useParams();

  const [profitsConversionModal, setProfitsConversionModal] = useState<ProfitsConversionList | undefined>();

  useEffect(() => {
    if (depositId) {
      dispatch(fetchMarginAddTopInfo({ vendorId: parseInt(vendorId, 10), addTopId: parseInt(depositId, 10) }));
    }
    if (profitsId) {
      dispatch(fetchProfitsConversionInfo({ vendorId: parseInt(vendorId, 10), profitsId: parseInt(profitsId, 10) }));
    }
  }, [dispatch, depositId, vendorId, profitsId]);

  if (depositId) {
    return (
      <Wrapper>
        <CustomRow align="middle">
          <Text>建立日期</Text>
          <Text>{moment(marginAddTopInfo?.createdAt).format("YYYY-MM-DD")}</Text>
        </CustomRow>
        <CustomRow align="middle">
          <Text>申請人</Text>
          <Text>{marginAddTopInfo?.staff}</Text>
        </CustomRow>
        <CustomRow align="middle">
          <Text>類別</Text>
          {marginAddTopInfo?.title === "保證金餘額退回" ? <Text>終止合作</Text> : <Text>保證金收取</Text>}
        </CustomRow>
        <CustomRow align="middle">
          <Text>項目名稱</Text>
          <Text>{marginAddTopInfo?.title}</Text>
        </CustomRow>
        <CustomRow align="middle">
          <Text>金額</Text>
          <Text>{marginAddTopInfo?.amount}</Text>
        </CustomRow>
        <CustomRow align="middle">
          <Text>財務確認收款</Text>
          {marginAddTopInfo?.title !== "保證金餘額退回" ? (
            <Text>{marginAddTopInfo?.receiptsDate ? `${marginAddTopInfo?.receiptsDate} 已入帳` : "否"}</Text>
          ) : (
            <Text>{marginAddTopInfo?.receiptsDate ? `${marginAddTopInfo?.receiptsDate} 已入帳` : "未退回"}</Text>
          )}
        </CustomRow>
        {marginAddTopInfo?.title !== "保證金餘額退回" && (
          <>
            <CustomRow align="middle">
              <Text>憑證附件</Text>
              <CustomLink type="link" href={marginAddTopInfo?.attachment} target="_blank">
                檢視檔案
              </CustomLink>
            </CustomRow>
            <CustomRow align="middle">
              <Text>收據開立</Text>
              {marginAddTopInfo?.receipts ? (
                <CustomLink type="link" href={marginAddTopInfo?.receipts} target="_blank">
                  收據連結
                </CustomLink>
              ) : (
                <Text>尚未開立</Text>
              )}
            </CustomRow>
          </>
        )}
      </Wrapper>
    );
  }

  if (profitsId) {
    return (
      <>
        <ButtonRow justify="end" align="middle">
          <CustomButton
            disabled={!!profitsConversionInfo?.destroyedAt}
            onClick={() =>
              dispatch(
                fetchApproveTransferProfits({
                  vendorId: parseInt(vendorId, 10),
                  profitsId: parseInt(profitsId, 10),
                  payload: { reviewStatus: ProftisReviewStatus.REJECT },
                }),
              )
            }
          >
            審核不通過
          </CustomButton>
          <CustomButton
            disabled={!!profitsConversionInfo?.destroyedAt}
            onClick={() =>
              dispatch(
                fetchApproveTransferProfits({
                  vendorId: parseInt(vendorId, 10),
                  profitsId: parseInt(profitsId, 10),
                  payload: { reviewStatus: ProftisReviewStatus.Approve },
                }),
              )
            }
          >
            審核通過
          </CustomButton>
          <CustomButton
            type="primary"
            disabled={!!profitsConversionInfo?.destroyedAt}
            onClick={() => setProfitsConversionModal(profitsConversionInfo)}
          >
            編輯
          </CustomButton>
          <CustomButton
            disabled={!!profitsConversionInfo?.destroyedAt}
            onClick={() =>
              dispatch(
                fetchDeleteTransferProfits({ vendorId: parseInt(vendorId, 10), profitsId: parseInt(profitsId, 10) }),
              )
            }
          >
            作廢
          </CustomButton>
        </ButtonRow>
        <Wrapper>
          <CustomRow align="middle">
            <Text>建立日期</Text>
            <Text>{profitsConversionInfo?.createdDate}</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>申請人</Text>
            <Text>{profitsConversionInfo?.staff}</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>主管審核狀態</Text>
            <Text>{`${profitsConversionInfo?.reviewStatus}`}</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>類別</Text>
            <Text>利潤轉換</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>項目名稱</Text>
            <Text>{profitsConversionInfo?.title}</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>金額</Text>
            <Text>{profitsConversionInfo?.amount}</Text>
          </CustomRow>
          <CustomRow align="middle">
            <Text>發票開立</Text>
            <Text>{profitsConversionInfo?.invoiceNumber || "尚未開立"}</Text>
          </CustomRow>
          {profitsConversionModal && (
            <MarginEditModal
              marginEditType={MarginEditType.EDIT_PROFITS}
              profitsData={profitsConversionModal}
              onClose={() => setProfitsConversionModal(undefined)}
            />
          )}
        </Wrapper>
      </>
    );
  }

  return null;
}
