class CustomError extends Error {
  httpStatus: number;

  message: string;

  statusCode: number;

  result: any;

  constructor(httpStatus: number, serverStatus: number, serverMessage: string, result?: any) {
    super();
    this.httpStatus = httpStatus;
    this.statusCode = serverStatus;
    this.message = serverMessage;
    this.result = result;
  }
}
export default CustomError;
