import React, { useEffect, useState } from "react";
import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import PageTitle from "@component/PageTitle";
import { fetchReturnOrderList, returnOrderState, setReturnOrderListFilter } from "@redux/returnOrderSlice";
import { Button, Col, Row, Select, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { StyledOutlinedWrapper, StyledSection } from "src/styles/common";
import styled from "styled-components";
import EditOrderForm from "./EditOrderForm";
import Filter from "./Filter";
import ReturnOrderList from "./ReturnOrderList";

const { Option } = Select;

const PageInfoText = styled(Space)`
  font-size: 14px;
`;

export default function ReturnOrderListPage() {
  const dispatch = useDispatch();
  const { returnOrderList, returnOrderListFilter } = useSelector(returnOrderState);
  const [showPopup, setShowPopup] = useState(false);
  const [openFilterForm, setOpenFilterForm] = useState(false);

  const handlePageSize = (pageSize: string) => {
    dispatch(
      setReturnOrderListFilter({
        limit: parseInt(pageSize, 10),
      }),
    );
  };

  const toggleFilterForm = () => {
    setOpenFilterForm(!openFilterForm);
  };

  const toggleNewOrderForm = () => {
    setShowPopup(!showPopup);
  };

  useEffect(() => {
    dispatch(fetchReturnOrderList(returnOrderListFilter));
  }, [dispatch, returnOrderListFilter]);

  return (
    <div>
      <PageTitle title="調撥出貨" />
      <StyledSection>
        <Row justify="space-between" style={{ marginBottom: 21 }} align="middle">
          <Col span={12}>
            <Button type="primary" icon={<PlusCircleTwoTone twoToneColor="#1890FF" />} onClick={toggleNewOrderForm}>
              新增調撥單
            </Button>
            <Button style={{ marginLeft: 10 }} icon={<FilterTwoTone />} onClick={toggleFilterForm}>
              篩選
            </Button>
          </Col>
          <Col span={12}>
            <Row justify="end">
              <PageInfoText>
                <span>{`總共${returnOrderList.count}筆，每頁顯示`}</span>
                <Select defaultValue="20" style={{ width: "72px", fontSize: "13px" }} onChange={handlePageSize}>
                  <Option value="20">20</Option>
                  <Option value="50">50</Option>
                  <Option value="100">100</Option>
                </Select>
                <span>筆</span>
              </PageInfoText>
            </Row>
          </Col>
        </Row>
        {openFilterForm && <Filter />}
        {showPopup && <EditOrderForm onClose={() => setShowPopup(false)} />}
        {/* 調撥單列表 */}
        <StyledOutlinedWrapper>
          <ReturnOrderList />
        </StyledOutlinedWrapper>
      </StyledSection>
    </div>
  );
}
