import { PlusCircleOutlined } from "@ant-design/icons";
import { SalePageCategoryNode } from "@api/salePageCategoryApi";
import EmptyContent from "@component/EmptyContent";
import PageTitle from "@component/PageTitle";
import { RootState } from "@redux/rootReducer";
import {
  addNewCategory,
  fetchSalesCategoryList,
  moveSalesPageCategory,
  reset,
  toggleDisplayInactive,
  updateSelectedCategory,
} from "@redux/salesCategorySlice";
import { Checkbox, Tabs, Tree } from "antd";
import { DataNode, EventDataNode } from "antd/lib/tree";
import { produce } from "immer";
import React, { FC, ReactText, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import EditTab from "./EditTab";
import ManageSaleList from "./ManageSaleList";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  padding: 12px 0 0 0;
  height: 100%;
`;
const TreeBlock = styled.div`
  flex: 2;
  min-width: 0;
  z-index: 1;
  background-color: white;
  padding-left: 20px;
`;
const ContentBlock = styled.div`
  flex: 7;
  min-width: 0;
  padding-right: 25px;
  padding-bottom: 60px;
`;
const TabBlock = styled.div`
  width: 100%;
  min-height: 300px;
  padding: 12px 0 0 20px;
  border: 1px solid ${({ theme }) => theme.colorNeutral400};
  border-radius: 2px;
`;
const AddNodeWrapper = styled.div`
  margin-left: -21px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
`;
const AddNodeText = styled.p`
  font-size: 14px;
  padding: 0 4px;
  margin: 0;
  margin-left: 4px;
`;
const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 10px;
`;
const TreeTitle = styled.div<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? "#000000" : "rgba(0, 0, 0, 0.25)")};
`;

interface SelectEventInfo {
  event: "select";
  selected: boolean;
  node: EventDataNode<SalePageCategoryNode>;
  selectedNodes: DataNode[];
  nativeEvent: MouseEvent;
}
type CustomEvent = EventDataNode<SalePageCategoryNode> & SalePageCategoryNode;

const SalesCategory: FC = () => {
  const categoryList = useSelector((state: RootState) => state.salesCategory.categoryList);
  const isFetchingCategoryList = useSelector((state: RootState) => state.salesCategory.isFetchingCategoryList);
  const displayInactiveCategory = useSelector((state: RootState) => state.salesCategory.displayInactive);
  const hasNewNode = useSelector((state: RootState) => state.salesCategory.hasNewNode);
  const selectedCategory = useSelector((state: RootState) => state.salesCategory.selectedCategory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchSalesCategoryList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  // 加上新增按鈕到每一層
  const categoryListWithAddButton = useMemo(() => {
    const result = produce(categoryList, (draftCategoryList) => {
      // 新增品類按鈕到第一層
      draftCategoryList.push({
        title: (
          <AddNodeWrapper>
            <PlusCircleOutlined />
            <AddNodeText>新增分類</AddNodeText>
          </AddNodeWrapper>
        ),
        parent: -1,
        key: "add",
        id: "add",
        children: [],
        isLeaf: true,
        isAddButton: true,
        isActive: true,
        salesPageCount: 0,
        level: 1,
      });
      draftCategoryList.forEach((ctgy) => {
        // 新增品類按鈕到第二層
        const level2AddNode = {
          title: (
            <AddNodeWrapper>
              <PlusCircleOutlined />
              <AddNodeText>新增分類</AddNodeText>
            </AddNodeWrapper>
          ),
          parent: ctgy.key,
          key: `${ctgy.key}-add`,
          id: `${ctgy.key}-add`,
          isLeaf: true,
          isActive: true,
          isAddButton: true,
          children: [],
          salesPageCount: 0,
          level: 2,
        };
        if (ctgy.children) {
          ctgy.children.push(level2AddNode);
        } else {
          ctgy.children = [level2AddNode];
        }
        ctgy.children.forEach((subCtgy) => {
          // 新增品類按鈕到第三層
          const level3AddNode = {
            title: (
              <AddNodeWrapper>
                <PlusCircleOutlined />
                <AddNodeText>新增分類</AddNodeText>
              </AddNodeWrapper>
            ),
            parent: subCtgy.key,
            key: `${subCtgy.key}-add`,
            id: `${subCtgy.key}-add`,
            isLeaf: true,
            isActive: true,
            isAddButton: true,
            children: [],
            salesPageCount: 0,
            level: 3,
          };
          if (subCtgy.children) {
            subCtgy.children.push(level3AddNode);
          } else {
            subCtgy.children = [level3AddNode];
          }
        });
      });
    });
    return result;
  }, [categoryList]);

  function toggleDisplayInactiveCategory() {
    dispatch(toggleDisplayInactive());
    dispatch(fetchSalesCategoryList());
  }

  function handleOnSelect(selectedKeys: React.ReactText[], info: SelectEventInfo) {
    const node = info.node as CustomEvent;
    const { id, parent, level } = node;
    const numberId = Number(id);
    const numberParent = Number(parent);

    if (node.isAddButton) {
      if (hasNewNode) return;

      const countNumber = level === 3 ? "" : "(0)";
      const addedNode = {
        title: `新分類${countNumber}`,
        parent,
        key: -1,
        id: -1,
        level,
        isActive: true,
        children: [],
        salesPageCount: 0,
      };
      dispatch(addNewCategory(addedNode));
      dispatch(
        updateSelectedCategory({
          id: -1,
          parent: numberParent,
          level,
        }),
      );
    } else {
      dispatch(
        updateSelectedCategory({
          id: numberId,
          parent: numberParent,
          level,
        }),
      );
    }
  }

  function handleDragDrop(info: {
    event: React.MouseEvent<HTMLDivElement>;
    node: EventDataNode<SalePageCategoryNode>;
    dragNode: EventDataNode<SalePageCategoryNode>;
    dragNodesKeys: ReactText[];
    dropPosition: number;
    dropToGap: boolean;
  }) {
    const dropNode = info.node as CustomEvent;
    const dragNode = info.dragNode as CustomEvent;
    const dropPos: string[] = (dropNode as any).props.pos.split("-"); // AntDesign的EventDataNode沒有給props，用any替代

    // 同一level才可以移動
    if (dropNode.level === dragNode.level) {
      const moveLevel = dragNode.level;
      const dragCategoryId = dragNode.key;
      const parentId = dropNode.parent;
      let leftSiblingNode: SalePageCategoryNode | undefined;
      let rightSiblingNode: SalePageCategoryNode | undefined;

      // dropPos回來的格式是 0-45-1-1 -> 第一個0沒有意義 -> 第一層的第45 index的node -> 第二層的第1 index的node -> 以此類推
      if (moveLevel === 1) {
        const dropNodeIndex = Number(dropPos[1]);
        leftSiblingNode = categoryList[dropNodeIndex];
        rightSiblingNode = categoryList[dropNodeIndex + 1];
      } else {
        const firstDropNodeLevel = Number(dropPos[1]);
        let tempNode: SalePageCategoryNode = categoryList[firstDropNodeLevel];

        for (let i = 2; i <= moveLevel; i += 1) {
          if (i === moveLevel) {
            const dropNodeIndex = Number(dropPos[i]);
            leftSiblingNode = tempNode.children[dropNodeIndex];
            rightSiblingNode = tempNode.children[dropNodeIndex + 1];
          } else {
            const currentLevelDropNodeIndex = Number(dropPos[i]);
            tempNode = tempNode.children[currentLevelDropNodeIndex];
          }
        }
      }

      const params = {
        categoryId: Number(dragCategoryId),
        leftSibling: leftSiblingNode && Number(leftSiblingNode.id),
        rightSibling: rightSiblingNode && Number(rightSiblingNode.id),
        parent: Number(parentId),
      };
      dispatch(moveSalesPageCategory(params));
    }
    // 移到空的父層下的第一個位置 dropNode會是父層的node 而且 dropNode.expanded = true
    else if (dropNode.expanded && dropNode.level + 1 === dragNode.level) {
      const moveLevel = dragNode.level;
      const dragCategoryId = dragNode.key;
      const parentId = dropNode.parent;
      let rightSiblingNode: SalePageCategoryNode | undefined;

      const firstDropNodeLevel = Number(dropPos[1]);
      let tempNode: SalePageCategoryNode = categoryList[firstDropNodeLevel];

      for (let i = 2; i <= moveLevel; i += 1) {
        if (i === moveLevel) {
          [rightSiblingNode] = tempNode.children;
        } else {
          const currentLevelDropNodeIndex = Number(dropPos[i]);
          tempNode = tempNode.children[currentLevelDropNodeIndex];
        }
      }

      const params = {
        categoryId: Number(dragCategoryId),
        rightSibling: rightSiblingNode && Number(rightSiblingNode.id),
        parent: Number(parentId),
      };
      dispatch(moveSalesPageCategory(params));
    }
  }

  return (
    <Wrapper>
      <PageTitle title="分類頁" />
      <TreeBlock>
        {isFetchingCategoryList ? (
          "Loading..."
        ) : (
          <>
            <StyledCheckbox onChange={toggleDisplayInactiveCategory} checked={displayInactiveCategory}>
              顯示未啟用的分類
            </StyledCheckbox>
            <Tree
              treeData={categoryListWithAddButton}
              onSelect={handleOnSelect}
              showIcon
              draggable
              blockNode
              onDrop={handleDragDrop}
              titleRender={(node: any) => <TreeTitle isActive={node.isActive}>{node.title}</TreeTitle>}
              defaultExpandedKeys={[selectedCategory.parent]}
              selectedKeys={[selectedCategory ? selectedCategory.id : ""]}
            />
          </>
        )}
      </TreeBlock>
      <ContentBlock>
        {selectedCategory.id !== 0 ? (
          <TabBlock>
            <Tabs defaultActiveKey="edit" type="card">
              <Tabs.TabPane tab="編輯分類" key="edit">
                <EditTab />
              </Tabs.TabPane>
              {selectedCategory.id > 0 && (
                <Tabs.TabPane tab="管理銷售頁" key="products">
                  <ManageSaleList canAddSalespage={selectedCategory.level === 3} />
                </Tabs.TabPane>
              )}
            </Tabs>
          </TabBlock>
        ) : (
          <EmptyContent />
        )}
      </ContentBlock>
    </Wrapper>
  );
};

export default SalesCategory;
