import { FilterTwoTone } from "@ant-design/icons";
import { VPCSalesListItem } from "@api/salesReportApi";
import PageTitle from "@component/PageTitle";
import PopupBackground from "@component/PopupBackground";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";
import { RootState } from "@redux/rootReducer";
import {
  exportCSVPricePerOrder,
  exportVPCSalesList,
  fetchVPCSalesList,
  reset,
  updateVPCSalesListParams,
} from "@redux/salesReportSlice";
import { Button, Col, Radio, Row, Select, Space, Table } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import Title from "antd/lib/typography/Title";
import React, { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import { ModalWrapper } from "src/styles/common";
import styled from "styled-components";
import { SalesChannelType } from "@api/vendorApi";
import Dashboard from "./Dashboard";
import Filter from "./Filter";
import SalePlanModal from "./SalePlanModal";
import SubOrderModal from "./SubOrderModal";

const Wrapper = styled.div`
  padding: 20px 20px 0 25px;
`;

const Header = styled.header`
  padding: 10px 0 20px 0;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div`
  font-size: 14px;
`;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding: 28px 20px;
  border: 1px solid #f0f0f0;
`;
const Link = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
`;
const PointerText = styled.div`
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
`;
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const pageSizeOptions = [20, 50, 100];

const SalesStatistics: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingVPCSalesList, VPCSalesListParams, VPCSalesListResult } = useSelector(
    (state: RootState) => state.salesReport,
  );
  const salesChannelOptions = useSalesChannelOptions();
  const [showFilter, setShowFilter] = useState<boolean>();
  const [showSubOrderModal, setShowSubOrderModal] = useState<number>(); // vpcId控制開關
  const [showSalesPlanModal, setShowSalesPlanModal] = useState<number>(); // vpcId控制開關
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);

  const { count, results: VPCSalesList } = VPCSalesListResult;
  const { offset, limit, salesChannel } = VPCSalesListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchVPCSalesList());

    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const toggleFilter = () => setShowFilter((prev) => !prev);

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...VPCSalesListParams,
      limit: value as number,
    };

    dispatch(updateVPCSalesListParams(filter));
    dispatch(fetchVPCSalesList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...VPCSalesListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateVPCSalesListParams(filter));
    dispatch(fetchVPCSalesList());
  };

  const handleOnExport = (groupBy: string) => {
    dispatch(exportVPCSalesList(groupBy));
  };

  const handleOnExportCSVPricePerOrder = () => {
    dispatch(exportCSVPricePerOrder());
  };

  const openSubOrderModal = (id: number) => () => {
    setShowSubOrderModal(id);
  };

  const closeSubOrderModal = () => {
    setShowSubOrderModal(undefined);
  };

  const openSalesPlanModal = (id: number) => () => {
    setShowSalesPlanModal(id);
  };

  const closeSalesPlanModal = () => {
    setShowSalesPlanModal(undefined);
  };

  const handleSalesChannelChange = (value: any) => {
    const filter = {
      ...VPCSalesListParams,
      salesChannel: value,
    };
    dispatch(updateVPCSalesListParams(filter));
    dispatch(fetchVPCSalesList());
  };

  const tableColumns: ColumnsType<VPCSalesListItem> = [
    {
      title: "ID",
      dataIndex: ["vpcInfo", "vpcId"],
      width: 65,
      render: (value: VPCSalesListItem["vpcInfo"]["vpcId"], data: VPCSalesListItem) => (
        <Link href={`/product/edit/${data.vpcInfo.productId}`} target="_blank">
          {data.vpcInfo.productId}
        </Link>
      ),
    },
    {
      title: "國際條碼",
      dataIndex: ["vpcInfo", "productSku"],
      width: 180,
    },
    {
      title: "商品負責人",
      dataIndex: ["vpcInfo", "owner", "name"],
      width: 180,
    },
    {
      title: "商品名稱",
      dataIndex: ["vpcInfo", "productName"],
      width: 340,
      render: (value: VPCSalesListItem["vpcInfo"]["productName"], data: VPCSalesListItem) => (
        <Link href={`/product/edit/${data.vpcInfo.productId}`} target="_blank">
          {data.vpcInfo.productName}
        </Link>
      ),
    },
    {
      title: "銷量",
      dataIndex: ["vpcInfo", "salesVolume"],
      width: 65,
      render: (value: VPCSalesListItem["vpcInfo"]["salesVolume"], data: VPCSalesListItem) => (
        <PointerText onClick={openSubOrderModal(data.vpcInfo.vpcId)}>{value}</PointerText>
      ),
    },
    {
      title: "訂單數",
      dataIndex: ["vpcInfo", "orderCount"],
      width: 100,
    },
    {
      title: "營收",
      dataIndex: ["vpcInfo", "revenue"],
      width: 100,
    },
    {
      title: "可賣量",
      dataIndex: ["vpcInfo", "canSalesQty"],
      width: 100,
    },
    {
      title: "黑卡折抵",
      dataIndex: ["vpcInfo", "memberGroupDiscount"],
      width: 100,
    },
    {
      title: "活動折抵",
      dataIndex: ["vpcInfo", "salesRuleDiscount"],
      width: 100,
    },
    {
      title: "運費攤提",
      dataIndex: ["vpcInfo", "vendorLogisticsFee"],
      width: 100,
    },
    {
      title: "廠商負擔",
      dataIndex: ["vpcInfo", "vendorFee"],
      width: 100,
    },
    {
      title: "商品抽成",
      dataIndex: ["vpcInfo", "commissionFee"],
      width: 100,
    },
    {
      title: "商品成本",
      dataIndex: ["vpcInfo", "cost"],
      width: 100,
    },
    {
      title: "商品毛利",
      dataIndex: ["vpcInfo", "grossProfit"],
      width: 100,
    },
    {
      title: "倉租攤提",
      dataIndex: ["vpcInfo", "rentalFee"],
      width: 100,
    },
    {
      title: "毛利率",
      dataIndex: ["vpcInfo", "grossMargin"],
      width: 100,
      render: (value: VPCSalesListItem["vpcInfo"]["grossMargin"]) => {
        const displayValue = (value * 100).toFixed(2);
        return `${displayValue}%`;
      },
    },
    {
      title: "合作方式",
      dataIndex: ["vpcInfo", "contractModeStr"],
      width: 100,
    },
    {
      title: "",
      dataIndex: ["vpcInfo", "vpcId"],
      width: 140,
      fixed: "right",
      render: (value: VPCSalesListItem["vpcInfo"]["vpcId"]) =>
        VPCSalesListParams.salesChannel === SalesChannelType.URMART && (
          <PointerText onClick={openSalesPlanModal(value)}>查看銷售方案</PointerText>
        ),
    },
  ];

  return (
    <Wrapper>
      {openExportModal && <ExportModal onClose={() => setOpenExportModal(false)} onSubmit={handleOnExport} />}
      <Header>
        <Row justify="space-between" gutter={[0, 36]}>
          <PageTitle title="銷售統計表" />
          <TopButtonContainer>
            <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
              篩選
            </TopButton>
            <TopButton
              onClick={() => {
                setOpenExportModal(true);
              }}
            >
              匯出
            </TopButton>
            <TopButton onClick={handleOnExportCSVPricePerOrder}>客單價分析</TopButton>
          </TopButtonContainer>
          <TablePageInfo>
            總共
            {count}
            筆, 每頁顯示
            <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
              {pageSizeOptions.map((option) => (
                <Select.Option key={option} value={option}>
                  {option}
                </Select.Option>
              ))}
            </PageSelect>
            筆
          </TablePageInfo>
        </Row>
        <Row align="middle" gutter={[16, 0]}>
          <Col>通路 :</Col>
          <Col>
            <Select
              style={{ width: 200 }}
              onChange={handleSalesChannelChange}
              options={salesChannelOptions}
              defaultValue={salesChannel}
            />
          </Col>
        </Row>
      </Header>
      {showFilter && <Filter />}
      <Dashboard />
      <TableContainer>
        <Table
          dataSource={VPCSalesList}
          columns={tableColumns}
          rowKey={(record) => record.vpcInfo.vpcId}
          scroll={{ x: "max-content", y: 400 }}
          tableLayout="auto"
          pagination={{
            pageSize: limit,
            current: currentPage,
            total: count,
            onChange: handleOnPageChange,
            showSizeChanger: false,
          }}
          loading={isFetchingVPCSalesList}
        />
      </TableContainer>
      {showSubOrderModal && <SubOrderModal vpcId={showSubOrderModal} onClose={closeSubOrderModal} />}
      {showSalesPlanModal && <SalePlanModal vpcId={showSalesPlanModal} onClose={closeSalesPlanModal} />}
    </Wrapper>
  );
};

export default SalesStatistics;

const ExportModal = (props: { onClose: () => void; onSubmit: (groupByMode: string) => void }) => {
  const { onClose, onSubmit } = props;
  const [groupByMode, setGroupByMode] = useState<string | undefined>();
  const handleClosePopup = () => {
    onClose();
  };
  const onChange = (e: RadioChangeEvent) => {
    setGroupByMode(e.target.value);
  };
  const onHandleSubmit = () => {
    if (groupByMode !== undefined) {
      onSubmit(groupByMode);
    }
    onClose();
  };

  return (
    <PopupBackground close={handleClosePopup} fixed>
      <ModalWrapper width="300px">
        <Title level={5}>彙整區間</Title>
        <Radio.Group onChange={onChange} value={groupByMode}>
          <Space direction="vertical">
            <Radio value="day">日</Radio>
            <Radio value="month">月</Radio>
            <Radio value="">無</Radio>
          </Space>
        </Radio.Group>
        <ModalFooter>
          <Space>
            <Button onClick={handleClosePopup}>取消</Button>
            <Button type="primary" disabled={groupByMode === undefined} onClick={onHandleSubmit}>
              確定
            </Button>
          </Space>
        </ModalFooter>
      </ModalWrapper>
    </PopupBackground>
  );
};
