// import { fetchOptionList, fetchPlanList, SaleState } from "@redux/saleSlice";
import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { fetchDiscountFrameInfo } from "@redux/discountFrameSlice";
import DiscountFrameContent from "./DiscountFrameContent";
import DiscountFramePlanContent from "./DiscountFramePlanContent";
import DiscountFrameSalePageContent from "./DiscountFrameSalePageContent";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;

const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

enum TabList {
  FRAME_CONTENT = "FRAME_CONTENT",
  PLAN_CONTENT = "PLAN_CONTENT",
  SALE_CONTENT = "SALE_CONTENT",
}

export default function DiscountFrameInformation() {
  const dispatch = useDispatch();
  // const { salePageDetail, optionListResult } = useSelector(SaleState);
  const { frameId } = useParams();

  const [activeKey, setActiveKey] = useState<string>(TabList.FRAME_CONTENT);

  useEffect(() => {
    const parseFrameId = parseInt(frameId, 10);
    if (frameId && !Number.isNaN(parseFrameId)) {
      dispatch(fetchDiscountFrameInfo(parseFrameId));
    }
  }, [dispatch, frameId]);

  return (
    <Wrapper>
      <PageTitle title={`宣傳框 - ${frameId ? "編輯" : "新增"}`} />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="宣傳框資訊" key={TabList.FRAME_CONTENT}>
            <DiscountFrameContent />
          </Tabs.TabPane>
          {frameId && (
            <Tabs.TabPane tab="新增方案" key={TabList.PLAN_CONTENT}>
              <DiscountFramePlanContent />
            </Tabs.TabPane>
          )}
          {frameId && (
            <Tabs.TabPane tab="新增銷售頁" key={TabList.SALE_CONTENT}>
              <DiscountFrameSalePageContent />
            </Tabs.TabPane>
          )}
        </Tabs>
      </Scope>
    </Wrapper>
  );
}
