import { ExclamationCircleOutlined, FilterTwoTone } from "@ant-design/icons";
import { BrandSalesPageList } from "@api/brandPageApi";
import {
  brandPageState,
  fetchBrandSalesPage,
  fetchUpdateBrandSalesPageRank,
  setBrandSalePageFilter,
  fetchSalePageShowInBrandPage,
} from "@redux/brandPageSlice";
import { Button, Input, Modal, Row, Select, Spin, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, ReactText } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import EditSalesPageFilter from "./EditSalesPageFilter";

const Wrapper = styled.div`
  padding-bottom: 16px;
`;
const CustomButton = styled(Button)`
  margin: 0 5px;
`;
const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;
const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;
const TableWrapper = styled.div`
  border: solid 1px #d9d9d9;
  padding: 18px 22px 22px 23px;
  position: relative;
  margin-bottom: 24px;
`;
const CustomRow = styled(Row)<{ marginBottom?: string }>`
  margin-bottom: ${({ marginBottom }) => marginBottom || 0};
`;

export default function EditSalesPage() {
  const { brandPageId } = useParams();

  const dispatch = useDispatch();
  const { isFetching, brandSalePageFilter, brandSalePageResult, brandPageDetail } = useSelector(brandPageState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [salePageRankMap, setSalePageRankMap] = useState<Map<number, number | undefined>>(new Map());
  const [enableMultipleBrand, setEnableMultipleBrand] = useState<boolean>(false);
  const [selectedSaleIds, setSelectedSaleIds] = useState<number[]>([]);

  const setPageLimit = (value: SelectValue) => {
    dispatch(
      setBrandSalePageFilter({
        ...brandSalePageFilter,
        limit: parseInt(value as string, 10),
      }),
    );
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      setBrandSalePageFilter({
        ...brandSalePageFilter,
        offset: (value - 1) * brandSalePageFilter.limit,
      }),
    );
  };

  const onResetPageRecommend = () => {
    Modal.confirm({
      title: "您確定清空站長推薦本頁的排序？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        setSalePageRankMap(new Map());
      },
    });
  };

  const handleShowInBrandPage = (isShow: boolean) => {
    const params = {
      salesPageIds: selectedSaleIds,
      isShow,
      brandId: parseInt(brandPageId, 10),
    };
    Modal.confirm({
      title: `你確定要${isShow ? "「顯示」" : "「不顯示」"}勾選的銷售頁嗎?`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchSalePageShowInBrandPage(params));
        setSelectedSaleIds([]);
      },
    });
  };

  const onSubmit = () => {
    const checkHasValue = brandSalePageResult.results.every((item) => salePageRankMap.get(item.salesPageId));
    if (brandSalePageResult.results.length !== salePageRankMap.size || !checkHasValue) {
      Modal.warning({
        title: "站長推薦尚未填寫",
      });
      return;
    }

    const data = [];
    for (const [key, value] of salePageRankMap) {
      data.push({
        salesPage: key,
        rank: value as number,
      });
    }

    dispatch(
      fetchUpdateBrandSalesPageRank({
        brandPageId: parseInt(brandPageId, 10),
        enableMultipleBrand,
        salesPageRankData: data,
      }),
    );
  };

  const handleOnCheck = (value: ReactText[]) => {
    setSelectedSaleIds(value as number[]);
  };

  const columns: ColumnsType<BrandSalesPageList> = [
    {
      key: "salesPageId",
      dataIndex: "salesPageId",
      title: "ID",
    },
    {
      key: "skus",
      dataIndex: "skus",
      title: "SKU",
      render: (skus: string[]) => skus.map((sku) => <div>{sku}</div>),
    },
    {
      key: "salesPageName",
      dataIndex: "salesPageName",
      title: "名稱",
    },
    {
      key: "brand",
      dataIndex: "brand",
      title: "品牌",
    },
    {
      key: "salesPagePrice",
      dataIndex: "salesPagePrice",
      title: "價錢",
      render: (salesPagePrice: number) => <div>{`$${salesPagePrice}`}</div>,
    },
    {
      key: "salesPageIsActive",
      dataIndex: "salesPageIsActive",
      title: "上架",
      render: (salesPageIsActive: boolean) => <div>{salesPageIsActive ? "是" : "否"}</div>,
    },
    {
      key: "rank",
      dataIndex: "rank",
      title: "站長推薦",
      sorter: () => 0, // antd 需要
      onHeaderCell: (column: any) => {
        return {
          onClick: () => {
            if (column.title.props.title === "Click to sort descending") {
              dispatch(setBrandSalePageFilter({ ...brandSalePageFilter, ordering: "-rank" }));
            } else if (column.title.props.title === "Click to sort ascending") {
              dispatch(setBrandSalePageFilter({ ...brandSalePageFilter, ordering: "rank" }));
            }
          },
        };
      },
      render: (_, values) => (
        <Input
          type="number"
          value={salePageRankMap.get(values.salesPageId)}
          onChange={(e) => {
            if (typeof e.target.value === "string" && !Number.isNaN(parseInt(e.target.value, 10))) {
              setSalePageRankMap(new Map(salePageRankMap.set(values.salesPageId, parseInt(e.target.value, 10))));
            } else {
              setSalePageRankMap(new Map(salePageRankMap.set(values.salesPageId, undefined)));
            }
          }}
        />
      ),
    },
    {
      key: "isShow",
      dataIndex: "isShow",
      title: "顯示於品牌頁",
      render: (salesPageIsActive: boolean) => <div>{salesPageIsActive ? "是" : "否"}</div>,
    },
  ];

  useEffect(() => {
    dispatch(fetchBrandSalesPage({ ...brandSalePageFilter, tagId: parseInt(brandPageId, 10) }));
  }, [dispatch, brandSalePageFilter, brandPageId]);

  useEffect(() => {
    setSalePageRankMap(new Map());
    brandSalePageResult.results.forEach((item) => {
      setSalePageRankMap((prev) => new Map(prev.set(item.salesPageId, item.rank)));
    });
  }, [brandSalePageResult]);

  useEffect(() => {
    if (brandPageDetail) {
      setEnableMultipleBrand(brandPageDetail.enableMultipleBrand);
    }
  }, [brandPageDetail]);

  return (
    <Spin spinning={isFetching}>
      <Wrapper>
        <CustomRow justify="space-between" marginBottom="22px">
          <div>
            <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
              篩選
            </CustomButton>
          </div>
          <Row align="middle">
            <PageText>{`總共${brandSalePageResult.count}筆, 每頁顯示`}</PageText>
            <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as string)}>
              <Select.Option value="20">20</Select.Option>
              <Select.Option value="50">50</Select.Option>
              <Select.Option value="100">100</Select.Option>
            </SelectPageSize>
            <PageText>筆</PageText>
          </Row>
        </CustomRow>
        <EditSalesPageFilter isVisible={showFilter} />
        <TableWrapper>
          <CustomRow justify="space-between" align="middle" marginBottom="8px">
            <div>
              顯示於品牌頁
              <CustomButton disabled={selectedSaleIds.length === 0} onClick={() => handleShowInBrandPage(true)}>
                是
              </CustomButton>
              <CustomButton disabled={selectedSaleIds.length === 0} onClick={() => handleShowInBrandPage(false)}>
                否
              </CustomButton>
            </div>
            <CustomButton disabled={salePageRankMap.size === 0} onClick={onResetPageRecommend}>
              排序重設
            </CustomButton>
          </CustomRow>
          <Table
            scroll={{ x: "max-content" }}
            columns={columns}
            tableLayout="auto"
            dataSource={brandSalePageResult.results}
            pagination={{
              pageSize: brandSalePageFilter.limit,
              current: page,
              showSizeChanger: false,
              total: brandSalePageResult.count,
              onChange: fetchPageChange,
            }}
            rowSelection={{
              onChange: handleOnCheck,
              selectedRowKeys: selectedSaleIds,
            }}
            rowKey="salesPageId"
          />
        </TableWrapper>
        <Row justify="end">
          <Button type="primary" onClick={onSubmit}>
            儲存此頁面
          </Button>
        </Row>
      </Wrapper>
    </Spin>
  );
}
