import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Col, Form, Row, Select, DatePicker, Tag } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  cohortState,
  fetchBrandList,
  fetchSearchBrandList,
  fetchUtmSourseList,
  setCohortFilter,
} from "@redux/cohortSlice";
import useDebounce from "@hooks/useDebounce";

const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function Filter() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { searchedBrandList, utmList, cohortFilter } = useSelector(cohortState);
  const [brandName, setBrandName] = useState<string>("");

  const onSubmit = (values: any) => {
    const formValues = form.getFieldsValue();

    dispatch(
      setCohortFilter({
        ...cohortFilter,
        ...values,
        cohortStart: values.timePeriod ? moment(values.timePeriod[0]).format("YYYY-MM") : undefined,
        cohortEnd: values.timePeriod ? moment(values.timePeriod[1]).format("YYYY-MM") : undefined,
        timePeriod: undefined,
        platforms: formValues.platforms?.toString(),
        sourceMediumIds: formValues.sourceMediumIds?.toString(),
        tagIds: formValues.tagIds?.toString(),
        promotionRuleIds: formValues.promotionRuleIds?.toString(),
        couponCodes: formValues.couponCodes?.toString(),
      }),
    );
  };

  const onReset = () => {
    form.resetFields();
    setBrandName("");
    dispatch(
      setCohortFilter({
        ...cohortFilter,
        cohortStart: undefined,
        cohortEnd: undefined,
        groupId: undefined,
        sourceMediumIds: undefined,
        platforms: undefined,
        promotionRuleIds: undefined,
        couponCodes: undefined,
        tagIds: undefined,
      }),
    );
  };

  const handleSearchInput = useDebounce((value: string) => {
    setBrandName(value);
  }, 400);

  const onFocus = () => {
    setBrandName("");
  };

  useEffect(() => {
    dispatch(fetchBrandList({}));
    dispatch(fetchSearchBrandList(brandName));
  }, [brandName, dispatch]);

  useEffect(() => {
    dispatch(fetchUtmSourseList());
  }, [dispatch]);

  useEffect(() => {
    form.setFieldsValue({
      platforms: ["web", "tablet", "mobile", "ios", "android"],
    });
  }, [form]);

  const options = searchedBrandList.results.map((item) => {
    return { value: item.id, label: item.name, text: item.id };
  });

  function tagRender(props: {
    label: any;
    closable: boolean | undefined;
    onClose: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
  }) {
    const { label, closable, onClose } = props;
    const onPreventMouseDown = (event: { preventDefault: () => void; stopPropagation: () => void }) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  }

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="時間區間" name="timePeriod" rules={[{ required: true, message: "必填" }]}>
            <CustomRangePicker picker="month" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="會籍" name="groupId">
            <Select defaultValue="不限">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value={0}>不限</Select.Option>
              <Select.Option value={2}>黑卡</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="來源" name="sourceMediumIds">
            <Select mode="multiple" optionFilterProp="children">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              {utmList &&
                utmList.results.map((item) => (
                  <Select.Option value={item.id}>
                    {item.source}/{item.medium}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="平台" name="platforms">
            <Select mode="multiple">
              <Select.Option value="-100" disabled>
                請選擇
              </Select.Option>
              <Select.Option value="web">桌機版網頁(web)</Select.Option>
              <Select.Option value="tablet">平板網頁(tablet)</Select.Option>
              <Select.Option value="mobile">手機版網頁(mobile)</Select.Option>
              <Select.Option value="ios">iOS</Select.Option>
              <Select.Option value="android">Android</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="優惠券代碼" name="couponCodes">
            <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="活動優惠ID" name="promotionRuleIds">
            <Select mode="tags" style={{ width: "100%" }} tokenSeparators={[","]} />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="品牌" name="tagIds">
            <Select
              mode="multiple"
              options={options}
              tagRender={tagRender}
              onFocus={onFocus}
              filterOption={(inputValue: string, option: any) => option!.label.includes(inputValue)}
              onSearch={(e) => handleSearchInput(e)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
