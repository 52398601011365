/* eslint-disable react/no-this-in-sfc */
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-bottom: 20px;
  width: 100%;
  min-height: 300px;
  border-radius: 2px;

  & .ant-spin-container {
    display: flex;
  }
`;
const MemberStatistics = styled.div`
  width: 330px;
  height: 100%;
  margin-right: 90px;
`;
const HeaderTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 4px;
`;
const HeaderContent = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 46px;
`;
const StatisticRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const ColorSquare = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;
const RowTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
`;
const RowContent = styled.div`
  font-size: 20px;
  color: rgba(0, 0, 0, 0.85);
`;
const MemberChart = styled.div``;

const MemberCard: FC = () => {
  const isFetchingMemberDistribution = useSelector((state: RootState) => state.dashboard.isFetchingMemberDistribution);
  const memberDistribution = useSelector((state: RootState) => state.dashboard.memberDistribution);
  const { totalMember, memberNeverBuy, memberBuyOnce, memberBuyTwiceGte } = memberDistribution;

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "pie",
        width: 250,
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: false,
          },
        },
      },
      tooltip: {
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        formatter() {
          const percentage = Math.round((this.y / totalMember) * 1000) / 10;
          return (
            `<span style="color: ${this.point.color}">■ </span>${this.point.name}<br>` +
            `<span style="color: transparent">■ </span>${this.y}人 (${percentage}%)`
          );
        },
      },
      series: [
        {
          type: "pie",
          data: [
            {
              name: "首次購物人數(累計)",
              y: memberBuyOnce,
              color: "#1890FF",
            },
            {
              name: "二次以上購物人數(累計)",
              y: memberBuyTwiceGte,
              color: "#BAE7FF",
            },
            {
              name: "從未購物人數(累計)",
              y: memberNeverBuy,
              color: "#D3D3D3",
            },
          ],
        },
      ],
    });
  }, [memberNeverBuy, memberBuyOnce, memberBuyTwiceGte, totalMember]);

  return (
    <Wrapper>
      <Spin spinning={isFetchingMemberDistribution}>
        <MemberStatistics>
          <HeaderTitle>總會員人數(累計，此數值不售時間篩選影響)</HeaderTitle>
          <HeaderContent>{`${totalMember.toLocaleString("en-US")}人`}</HeaderContent>
          <StatisticRow>
            <RowTitle>
              <ColorSquare color="#1890FF" />
              首次購物人數(累計)
            </RowTitle>
            <RowContent>{`${memberBuyOnce.toLocaleString("en-US")}人`}</RowContent>
          </StatisticRow>
          <StatisticRow>
            <RowTitle>
              <ColorSquare color="#BAE7FF" />
              二次以上購物人數(累計)
            </RowTitle>
            <RowContent>{`${memberBuyTwiceGte.toLocaleString("en-US")}人`}</RowContent>
          </StatisticRow>
          <StatisticRow>
            <RowTitle>
              <ColorSquare color="#D3D3D3" />
              從未購物人數(累計)
            </RowTitle>
            <RowContent>{`${memberNeverBuy.toLocaleString("en-US")}人`}</RowContent>
          </StatisticRow>
        </MemberStatistics>
        <MemberChart>
          <HeaderTitle>總會員人數佔購物次數比例</HeaderTitle>
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: "100%" } }}
          />
        </MemberChart>
      </Spin>
    </Wrapper>
  );
};

export default MemberCard;
