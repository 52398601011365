import { TokenInfo } from "@api/adminApi";
import { ProcessingAppListItem } from "@api/tryall/ProcessingApplicationApi";
import PopupAddAndUpdateModal from "@component/Common/PopupAddAndUpdateModal";
import cookie from "@utils/Cookie";
import { MEMBER_TOKEN } from "@constant/StorageKeyValue";
import { createProcessingApp, updateProcessingApp } from "@redux/ProcessingApplicationSlice";
import { Col, Form, Input, Row } from "antd";
import moment from "moment";
import React, { FC, useEffect } from "react";
import { useAppDispatch } from "src/store";
import DatePicker, { RangePickerProps } from "antd/lib/date-picker";
import TextArea from "antd/lib/input/TextArea";

type Props = {
  onClose: () => void;
  editData?: ProcessingAppListItem;
};
const ProcessAppAddModal: FC<Props> = (props) => {
  const { onClose, editData } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const tokenInfo: TokenInfo = cookie.getItem(MEMBER_TOKEN);
  const { userId, userName } = tokenInfo;

  const handleOnAdd = () => {
    const values = form.getFieldsValue();
    const { estDeliveryDate } = values;
    dispatch(
      createProcessingApp({
        ...values,
        estDeliveryDate: estDeliveryDate ? moment(estDeliveryDate).format("YYYY-MM-DD") : undefined,
      }),
    );
  };
  const handleOnUpdate = () => {
    if (editData) {
      const values = form.getFieldsValue();
      const { estDeliveryDate } = values;
      dispatch(
        updateProcessingApp({
          id: editData.id,
          ...values,
          estDeliveryDate: estDeliveryDate ? moment(estDeliveryDate).format("YYYY-MM-DD") : undefined,
        }),
      );
    }
  };
  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    if (editData) {
      return current && current < moment(editData.createdAt).startOf("day");
    }
    return current && current < moment().startOf("day");
  };

  useEffect(() => {
    form.setFieldsValue({ staff: userId });
  }, [form, userId]);

  return (
    <PopupAddAndUpdateModal
      form={form}
      onClose={onClose}
      onAdd={handleOnAdd}
      onUpdate={handleOnUpdate}
      initialValues={
        editData
          ? {
              ...editData,
              estDeliveryDate: editData?.estDeliveryDate ? moment(editData?.estDeliveryDate) : undefined,
            }
          : undefined
      }
      width={420}
      padding={15}
      AddText="建立"
      updateText="確定"
    >
      <>
        <Form.Item name="staff" labelCol={{ span: 8 }} noStyle>
          <Input hidden />
        </Form.Item>
        <Row gutter={[0, 16]}>
          <Col span={7} style={{ left: "11%" }}>
            建立人員 :
          </Col>
          <Col style={{ left: "1%" }}> {userName}</Col>
        </Row>
        <Form.Item
          label="加工單編號"
          name="procNumber"
          labelCol={{ span: 7 }}
          rules={[{ required: true, message: "請輸入加工單編號" }]}
        >
          <Input max={40} placeholder="最多輸入40字元" allowClear />
        </Form.Item>
        <Form.Item
          label="預計完工日期"
          name="estDeliveryDate"
          labelCol={{ span: 7 }}
          rules={[{ required: true, message: "請輸入預計完工日期" }]}
        >
          <DatePicker style={{ width: "100%" }} placeholder="請選擇日期" disabledDate={disabledDate} />
        </Form.Item>
        <Form.Item label="備註" name="note" labelCol={{ span: 7 }}>
          <TextArea
            maxLength={200}
            placeholder="最多輸入200字元"
            style={{ height: "142px" }}
            showCount
            autoSize={{ minRows: 6, maxRows: 6 }}
          />
        </Form.Item>
      </>
    </PopupAddAndUpdateModal>
  );
};
export default ProcessAppAddModal;
