enum MemberGroupValue {
  GENERAL = 1,
  VIP = 2,
  BUSINESS = 3,
}

export default MemberGroupValue;

export const memberGroupMap = {
  1: "一般",
  2: "黑卡",
  3: "商務",
};

export enum MemberProgramType {
  T_TRIAL = 1, // 試用
  T_BLACK = 2, // 一般黑卡
  T_FAMILY = 3, // 家庭卡
  T_BUSINESS = 4, // 商業卡
  T_GIFT = 5, // 此由系統贈送(補償 or 加碼 or 使用邀請連結...)
}

export const MemberProgramTypeOptions = [
  { label: "黑卡試用", value: MemberProgramType.T_TRIAL },
  { label: "黑卡", value: MemberProgramType.T_BLACK },
  { label: "家庭卡", value: MemberProgramType.T_FAMILY },
  { label: "商業卡", value: MemberProgramType.T_BUSINESS },
  { label: "此由系統贈送", value: MemberProgramType.T_GIFT },
];
