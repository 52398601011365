import {
  clearPurchaseLinesFilter,
  setPurchaseLinesFilter,
  customsClearanceState,
} from "@redux/sharing/customsClearanceSlice";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)`
  margin-top: 16px;

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const ButtonWrapper = styled.div`
  margin-left: auto;
`;

export default function CustomsClearanceProductFilter() {
  const dispatch = useDispatch();
  const { purchaseLinesFilter } = useSelector(customsClearanceState);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(clearPurchaseLinesFilter());
  };

  const onSubmit = (values: any) => {
    const params = {
      ...purchaseLinesFilter,
      ...values,
    };
    dispatch(setPurchaseLinesFilter(params));
  };

  return (
    <Wrapper form={form} onFinish={onSubmit}>
      <Row align="middle" gutter={30}>
        <Col span={6}>
          <Form.Item label="LMS編號" name="stoNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <ButtonWrapper>
          <Form.Item>
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Form.Item>
        </ButtonWrapper>
      </Row>
    </Wrapper>
  );
}
