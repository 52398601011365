import React, { FC, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Tabs } from "antd";
import { changeDashboardTab, DashboardTime } from "@redux/dashboardSlice";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import PageTitle from "@component/PageTitle";
import Highcharts from "highcharts";
import RevenueCard from "./revenue/Card";
import OrderPriceCard from "./orderPrice/Card";
import OrderCountCard from "./orderCount/Card";
import GrossMarginCard from "./grossMargin/Card";
import CategorySalesCard from "./categorySales/Card";
import OrderStatusCard from "./orderStatus/Card";
import ReturnProductCard from "./returnProduct/Card";
import WarehouseCard from "./warehouse/Card";
import ShippingStatusCard from "./shippingStatus/Card";
import MemberCard from "./member/Card";
import NewMemberCard from "./newMember/Card";
import NewMemberRevenueCard from "./newMemberRevenue/Card";
import OldMemberRevenueCard from "./oldMemberRevenue/Card";
import VipCountCard from "./vipCount/Card";
import VipStatusCard from "./vipStatus/Card";
import VipPerformanceCard from "./vipPerformance/Card";
import SalesRanking from "./SalesRanking";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const TabsContainer = styled.div`
  padding: 15px 40px 90px 20px;
  padding-bottom: 125px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;
const TabContent = styled.div`
  padding-top: 16px;
`;
const SectionTitle = styled.div<{ first?: boolean }>`
  font-size: 20px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-top: ${({ first }) => (first ? 0 : 60)}px;
  margin-bottom: 16px;
`;
const SectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`;

/* --- highchart調時區 --- */
const timezone = new Date().getTimezoneOffset();
Highcharts.setOptions({
  time: {
    timezoneOffset: timezone,
  },
});
/* --- end --- */

const Dashboard: FC = () => {
  const dispatch = useAppDispatch();
  const dashboardActiveTab = useSelector((state: RootState) => state.dashboard.dashboardActiveTab);

  useEffect(() => {
    dispatch(changeDashboardTab(DashboardTime.TODAY));
  }, [dispatch]);

  const handleOnTimeChange = useCallback(
    (tabKey: string) => {
      if (tabKey === dashboardActiveTab) return;

      dispatch(changeDashboardTab(tabKey as DashboardTime));
    },
    [dispatch, dashboardActiveTab],
  );

  return (
    <Wrapper>
      <PageTitle title="全站Dashboard" />
      <TabsContainer>
        <Tabs type="card" onTabClick={handleOnTimeChange} defaultActiveKey={DashboardTime.TODAY}>
          <Tabs.TabPane tab="今天" key={DashboardTime.TODAY} />
          <Tabs.TabPane tab="本月" key={DashboardTime.THIS_MONTH} />
          <Tabs.TabPane tab="昨天" key={DashboardTime.YESTERDAY} />
          <Tabs.TabPane tab="近7日" key={DashboardTime.LAST_7_DAYS} />
          <Tabs.TabPane tab="近30日" key={DashboardTime.LAST_30_DAYS} />
        </Tabs>
        <TabContent>
          <SectionTitle first>營銷</SectionTitle>
          <SectionContent>
            <RevenueCard />
            <OrderPriceCard />
            <OrderCountCard />
            <GrossMarginCard />
            <CategorySalesCard />
          </SectionContent>
          <SectionTitle>服務</SectionTitle>
          <SectionContent>
            <OrderStatusCard />
            <ReturnProductCard />
            <WarehouseCard />
            <ShippingStatusCard />
          </SectionContent>
          <SectionTitle>會員</SectionTitle>
          <SectionContent>
            <MemberCard />
            <NewMemberCard />
            <NewMemberRevenueCard />
            <OldMemberRevenueCard />
          </SectionContent>
          <SectionTitle>黑卡會員</SectionTitle>
          <SectionContent>
            <VipCountCard />
            <VipStatusCard />
            <VipPerformanceCard />
          </SectionContent>
          <SalesRanking />
        </TabContent>
      </TabsContainer>
    </Wrapper>
  );
};

export default Dashboard;
