import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

interface CohortApi {
  fetchBrandList: (param: BrandFilter) => Promise<BrandList>;
  fetchSearchBrandList: (name: string) => Promise<BrandList>;
  fetchUtmSourseList: () => Promise<UtmList>;
  fetchCohortList: (param: CohortFilter) => Promise<CohortListResult[]>;
}

export interface BrandFilter {
  nameQ?: string;
}
export interface BrandList {
  count: number;
  next: string | null;
  previous: string | null;
  results: BrandListResult[];
}
export interface BrandListResult {
  id: number;
  name: string;
  icon: string | null;
  isActive: boolean;
  productQty: number;
}

export interface CohortFilter {
  cohortStart?: string;
  cohortEnd?: string;
  groupId?: number;
  sourceMediumIds?: number[];
  platforms?: string[];
  promotionRuleIds?: number[];
  couponCodes?: string[];
  tagIds?: number[];
}
export interface CohortListResult {
  cohortMonth: string;
  totalMemberCount: number;
  totalLTV: number;
  ARPU: number;
  MList: [];
}

export interface MList {
  cohortM: string;
  orderCount: number;
  amount: number;
  memberCount: number;
}

export interface UtmList {
  count: number;
  next: string | null;
  previous: string | null;
  results: UtmListResult[];
}
export interface UtmListResult {
  id: number;
  source: string;
  medium: string;
}

const cohortApi: CohortApi = {
  fetchBrandList: async (param) => {
    const getParams = transformCamelToSnake(param);
    const response = await apiClient.getData("/manage/tag/brands/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchSearchBrandList: async (name: string) => {
    const response = await apiClient.getData(`/manage/tag/brands/?name_q=${name}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUtmSourseList: async () => {
    const response = await apiClient.getData("/manage/utm-source-medium", {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCohortList: async (param) => {
    const response = await apiClient.getData("/manage/order-statistics/cohort", transformCamelToSnake(param));
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default cohortApi;
