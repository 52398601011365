import { Spin, Tooltip } from "antd";
import React from "react";
import styled from "styled-components";
import { InfoCircleTwoTone } from "@ant-design/icons";

const DashboardWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
  min-width: 140px;
`;
const Card = styled.div`
  flex: 0 0 128px;
  height: 82px;
  background-color: #fafafa;
  margin-right: 10px;
  padding: 10px 14px;
`;
const CardTitle = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 2px;
  width: max-content;
`;
const CardContent = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

/**
 * Dashboard component
 * @param {Array<{ name: string; value: number; note?: string }>} props.dataset - dataset 傳入的資料 name: 標題 value: 值 note: 提示
 * @param {boolean} [props.isFetching=false] - is fetching data
 */

type Props = {
  dataset: { name: string; value: number; note?: string }[];
  isFetching?: boolean;
};
const DashBoard = (props: Props) => {
  const { dataset, isFetching = false } = props;

  return (
    <Spin spinning={isFetching}>
      <DashboardWrapper>
        {dataset.map((data) => (
          <Card key={data.name}>
            {data.note ? (
              <CardTitle>
                {data.name}
                <Tooltip title={data.note}>
                  <InfoIcon />
                </Tooltip>
              </CardTitle>
            ) : (
              <CardTitle>{data.name}</CardTitle>
            )}
            <CardContent>{data.value ?? "-"}</CardContent>
          </Card>
        ))}
      </DashboardWrapper>
    </Spin>
  );
};
export default DashBoard;
