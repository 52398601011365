import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import type { RootState } from "@redux/rootReducer";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import { Spin, Tag, Tooltip } from "antd";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 380px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;

const VipPerformanceCard: FC = () => {
  const isFetchingVipFinanceDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingVipFinanceDashboard,
  );
  const vipFinanceDashboard = useSelector((state: RootState) => state.dashboard.vipFinanceDashboard);
  const { currentData, diff } = vipFinanceDashboard;
  const { revenue = 0, grossMargin = 0, cost = 0 } = currentData || {};
  const { revenueDiff = 0, grossMarginDiff = 0, costDiff = 0 } = diff || {};

  const revenueCurrency = USCurrencyFormmater.format(revenue);
  const revenueTagColor = revenueDiff < 0 ? "error" : "success";
  const revenueTagIcon = revenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const revenueChangePercentage = Math.round(revenueDiff * 1000) / 10; // 百分比取小數點第一位

  const grossMarginPer = Math.round(grossMargin * 1000) / 10;
  const grossMarginTagColor = grossMarginDiff < 0 ? "error" : "success";
  const grossMarginTagIcon = grossMarginDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const grossMarginChangePercentage = Math.round(grossMarginDiff * 1000) / 10; // 百分比取小數點第一位

  const costCurrency = USCurrencyFormmater.format(cost);
  const costTagColor = costDiff < 0 ? "error" : "success";
  const costTagIcon = costDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const costChangePercentage = Math.round(costDiff * 1000) / 10;

  return (
    <Spin spinning={isFetchingVipFinanceDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>財務表現</HeaderTitle>
          <AnchorToChart href="/dashboard/vip-performance" />
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              黑卡會員貢獻營收
              <Tooltip
                placement="bottom"
                title="黑卡會員所貢獻之銷貨收入（進項）減去取消單費用（減項）、黑卡會費及退款費用（減項）"
              >
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              {revenueCurrency}
              <StyledTag color={revenueTagColor} icon={revenueTagIcon}>
                {`${revenueChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <BottomSection>
            <SectionTitle>
              黑卡會員銷售成本
              <Tooltip
                placement="bottom"
                title="若是寄倉、轉單商品則是商品售價＊（1-抽成）＊銷售數量；若是買斷則是商品成本*銷售數量"
              >
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              {costCurrency}
              <StyledTag color={costTagColor} icon={costTagIcon}>
                {`${costChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
          <BottomSection>
            <SectionTitle>
              黑卡會員銷售毛利率
              <Tooltip placement="bottom" title="(貢獻營收-銷售成本 + 抽成加總 + 後扣加總 + 倉租加總) / 營收加總">
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              {`${grossMarginPer}%`}
              <StyledTag color={grossMarginTagColor} icon={grossMarginTagIcon}>
                {`${grossMarginChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default VipPerformanceCard;
