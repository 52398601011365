/* eslint-disable react/jsx-props-no-spreading */
import { MenuOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { SalePlan } from "@api/saleApi";
import MemberGroupValue from "@constant/MemberGroupValue";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import {
  fetchPlanList,
  fetchToogleActivePlan,
  fetchUpdatePlanRanks,
  SaleState,
  setCurrentSalePlan,
  setResetVpcList,
} from "@redux/saleSlice";
import { Button, Row, Select, Switch, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { arrayMoveImmutable } from "array-move";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import ModalComplex, { ModalType } from "./ModalComplex";

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: "grab", color: "#999" }} />);

const Wrapper = styled.div``;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;

const Image = styled.img`
  width: 30px;
  height: 30px;
  object-fit: cover;
`;

export default function PlanContent() {
  const dispatch = useDispatch();
  const { isFetching, salePlanListResult, salePageDetail, isEditPlanDone, saleListParams } = useSelector(SaleState);

  const { salesId } = useParams();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [sequence, setSequence] = useState<ModalType[]>([]);
  const [dataSource, setDataSource] = useState<any>([]);

  const computeGroupLimit = (type: MemberGroupValue, plan: SalePlan) => {
    const customGroupLimit = plan.groupsSalesLimit.find(
      (item) => type === item.memberGroup && moment().isBetween(item.startAt, item.endAt),
    );
    const defaultGroupLimit = plan.groupsSalesLimit.find(
      (item) => type === item.memberGroup && !item.startAt && !item.endAt,
    );
    if (customGroupLimit) {
      return {
        memberLimit: customGroupLimit.maxSaleQty,
        memberLimitTime: `${moment(customGroupLimit.startAt).format("YYYY-MM-DD HH:mm:ss")} - ${moment(
          customGroupLimit.endAt,
        ).format("YYYY-MM-DD HH:mm:ss")}`,
      };
    }
    return {
      memberLimit: defaultGroupLimit?.maxSaleQty || 0,
      memberLimitTime: "預設",
    };
  };

  const setPageLimit = (value: SelectValue) => {
    setPageSize(parseInt(value as string, 10));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);

    dispatch(fetchPlanList({ salesPage: parseInt(salesId, 10), limit: pageSize, offset: (value - 1) * pageSize }));
  };

  const setCreateModalOpen = () => {
    setSequence([
      ModalType.PLAN,
      salePageDetail?.pageType === SalePageTypeValue.Any ? ModalType.ANY_CHANGE_PRICE : ModalType.NORMAL_CHANGE_PRICE,
      ModalType.PURCHASE_LIMIT,
    ]);
    dispatch(setResetVpcList());
    dispatch(setCurrentSalePlan(undefined));
    setOpenModal(true);
  };

  const setUpdateModalOpen = (plan: SalePlan) => {
    setSequence([ModalType.PLAN, ModalType.PURCHASE_LIMIT]);
    dispatch(setResetVpcList());
    dispatch(setCurrentSalePlan(plan));
    setOpenModal(true);
  };

  const updateActive = (isActive: boolean, currentSalePlan: SalePlan) => {
    const activePlanLength = salePlanListResult.results.filter((item) => item.isActive).length;
    dispatch(fetchToogleActivePlan({ planId: currentSalePlan.id, isActive, isLastActive: activePlanLength === 1 }));
  };

  const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).map((item: SalePlan, index) => ({
        planId: item.id,
        rank: index,
      }));
      dispatch(
        fetchUpdatePlanRanks({
          salesPlanRankData: newData,
        }),
      );
    }
  };

  const SortableItem = SortableElement((props: any) => <tr {...props} />);
  const SortableContainers = SortableContainer((props: any) => <tbody {...props} />);

  const DraggableContainer = (props: any) => (
    <SortableContainers useDragHandle disableAutoscroll helperClass="row-dragging" onSortEnd={onSortEnd} {...props} />
  );

  const DraggableBodyRow = (props: any) => {
    const index = dataSource.findIndex((x: any) => x.index === props["data-row-key"]);
    return <SortableItem index={index} {...props} />;
  };

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 30,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      key: "isActive",
      dataIndex: "isActive",
      title: "上架",
      render: (isActive: boolean, others: SalePlan) => (
        <Switch
          checked={isActive}
          onChange={() => {
            updateActive(!isActive, others);
          }}
        />
      ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "name",
      dataIndex: "name",
      title: "方案名稱",
    },
    {
      key: "media",
      dataIndex: "media",
      title: "方案圖片",
      render: (_: any, others: SalePlan) => <Image src={others?.media?.url || ""} />,
    },
    {
      key: "specialPrice",
      dataIndex: "specialPrice",
      title: "方案優惠價",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "方案售價",
    },
    {
      key: "discountTime",
      dataIndex: "discountTime",
      title: "優惠時間",
      render: (_: any, others: SalePlan) => (
        <div>
          {others.optionDiscountPeriod.startAt
            ? `${moment(others.optionDiscountPeriod.startAt).format("YYYY-MM-DD")}-${moment(
                others.optionDiscountPeriod.endAt,
              ).format("YYYY-MM-DD")}`
            : "預設"}
        </div>
      ),
    },
    {
      key: "canBuyCount",
      dataIndex: "canBuyCount",
      title: "方案可賣量",
    },
    {
      key: "memberLimit",
      dataIndex: "memberLimit",
      title: "一般會員限購數",
      render: (_: any, plan: SalePlan) => <div>{computeGroupLimit(MemberGroupValue.GENERAL, plan).memberLimit}</div>,
    },
    {
      key: "vipMemberLimit",
      dataIndex: "vipMemberLimit",
      title: "黑卡會員限購數",
      render: (_: any, plan: SalePlan) => <div>{computeGroupLimit(MemberGroupValue.VIP, plan).memberLimit}</div>,
    },
    {
      key: "memberLimitTime",
      dataIndex: "memberLimitTime",
      title: "一般會員限購時間",
      render: (_: any, plan: SalePlan) => {
        return <div>{computeGroupLimit(MemberGroupValue.GENERAL, plan).memberLimitTime}</div>;
      },
    },
    {
      key: "vipMemberLimitTime",
      dataIndex: "vipMemberLimitTime",
      title: "黑卡會員限購時間",
      render: (_: any, plan: SalePlan) => <div>{computeGroupLimit(MemberGroupValue.VIP, plan).memberLimitTime}</div>,
    },
    {
      key: "minGrossProfitMargin",
      dataIndex: "minGrossProfitMargin",
      title: "最低限額",
      render: (_: any, plan: SalePlan) => {
        const customGroupLimit = plan.groupsSalesLimit.find(
          (item) => MemberGroupValue.VIP === item.memberGroup && moment().isBetween(item.startAt, item.endAt),
        );
        if (customGroupLimit) {
          return <div>{customGroupLimit.minGrossProfitMargin}</div>;
        }
        const defaultGroupLimit = plan.groupsSalesLimit.find(
          (item) => MemberGroupValue.VIP === item.memberGroup && !item.startAt && !item.endAt,
        );
        return defaultGroupLimit && <div>{defaultGroupLimit.minGrossProfitMargin}</div>;
      },
    },
    {
      key: "planContent",
      dataIndex: "planContent",
      title: "方案內容",
      render: (_: any, others: SalePlan) => <div>{others.options.map((item) => `${item.name}*${item.groupQty}`)}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_: any, others: SalePlan) => {
        return (
          <Button type="link" onClick={() => setUpdateModalOpen(others)}>
            編輯
          </Button>
        );
      },
    },
  ];

  if (salePageDetail?.pageType === SalePageTypeValue.Any) {
    columns.splice(8, 1);
    columns.splice(4, 0, {
      key: "optionsCount",
      dataIndex: "optionsCount",
      title: "入數",
    });
  }

  useEffect(() => {
    setDataSource(salePlanListResult.results.map((item, index) => ({ ...item, index })));
  }, [salePlanListResult.results]);

  useEffect(() => {
    const parseSalesId = parseInt(salesId, 10);
    if (salesId && !Number.isNaN(parseSalesId)) {
      setPage(1);
      dispatch(fetchPlanList({ salesPage: parseSalesId, limit: pageSize, offset: 0 }));
    }
  }, [dispatch, pageSize, salesId]);

  useEffect(() => {
    const parseSalesId = parseInt(salesId, 10);
    if (isEditPlanDone && salesId && !Number.isNaN(parseSalesId)) {
      setPage(1);
      dispatch(fetchPlanList({ salesPage: parseSalesId, limit: pageSize, offset: 0 }));
    }
  }, [dispatch, pageSize, salesId, isEditPlanDone]);

  return (
    <Wrapper>
      <Row justify="space-between">
        <Button
          type="primary"
          icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
          disabled={salePageDetail?.pageType === SalePageTypeValue.Simple && salePlanListResult.results.length === 1}
          onClick={setCreateModalOpen}
        >
          新增方案
        </Button>
        <Row align="middle">
          <PageText>{`總共${salePlanListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={salePageDetail?.pageType === SalePageTypeValue.Group ? columns : columns.slice(1)}
          dataSource={dataSource}
          rowKey="index"
          scroll={{ x: "max-content" }}
          pagination={{
            pageSize: saleListParams.limit,
            current: page,
            showSizeChanger: false,
            total: salePlanListResult.count,
            onChange: fetchPageChange,
          }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />
      </TableWrapper>
      {openModal && <ModalComplex sequence={sequence} close={() => setOpenModal(false)} />}
    </Wrapper>
  );
}
