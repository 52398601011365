import cityRegionData from "@utils/CityRegionData";

export default function useCityRegionCode() {
  const cityNameList = Object.keys(cityRegionData);

  return {
    allCities: cityRegionData,
    cityNameList,
    cityNameOptions: cityNameList.map((city) => ({ label: city, value: city })),
    getCityRegion: (city: string) => cityRegionData[city],
    getCityRegionOptions: (city: string) =>
      Object.entries(cityRegionData[city]).map((key) => ({ label: key[0], value: key[1] })),
  };
}
