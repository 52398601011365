import CoreAPI from "../CoreAPI";
import { transformAPIKeyToCamel } from "../utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "../utils/transformCamelToSnake";

const apiClient = new CoreAPI();

// defined constant type
export enum RegionType {
  DOMESTIC_CHANNEL = 1,
  FOREIGN = 2,
  DOMESTIC_DISTRIBUTOR = 3,
}
export const convertRegionType = (type?: RegionType) => {
  switch (type) {
    case RegionType.DOMESTIC_CHANNEL:
      return "台灣本地通路";
    case RegionType.FOREIGN:
      return "境外通路";
    case RegionType.DOMESTIC_DISTRIBUTOR:
      return "台灣本地經銷";
    default:
      return "";
  }
};
export enum Currency {
  TWD = 1,
  USD = 2,
  RMB = 3,
}
export const convertCurrencyType = (type: Currency) => {
  switch (type) {
    case Currency.TWD:
      return "TWD";
    case Currency.USD:
      return "USD";
    case Currency.RMB:
      return "RMB";
    default:
      return "";
  }
};
export enum ContractType {
  BUYOUT_REFUNDABLE = 1,
  BUY_AND_NOT_RETURN = 2,
  CONSIGNMENT = 3,
  TRANSFER = 4,
}
export const convertContractType = (type: ContractType) => {
  switch (type) {
    case ContractType.BUYOUT_REFUNDABLE:
      return "買斷可退";
    case ContractType.BUY_AND_NOT_RETURN:
      return "買斷不可退";
    case ContractType.CONSIGNMENT:
      return "寄倉";
    case ContractType.TRANSFER:
      return "轉單";
    default:
      return "";
  }
};
export enum Incoterms {
  FOB = "FOB",
  CIF = "CIF",
  EXW = "EXW",
  DDU = "DDU",
}
export enum ApprovalStatus {
  NOT_REQUIRED = 0,
  PENDING = 1,
  AUDIT = 2,
  APPROVED = 3,
  REJECTED = 4,
  INVALID = 5,
}
export const convertApprovalStatus = (type: ApprovalStatus) => {
  switch (type) {
    case ApprovalStatus.PENDING:
      return "待審核";
    case ApprovalStatus.APPROVED:
      return "審核已通過";
    case ApprovalStatus.REJECTED:
      return "審核未通過";
    case ApprovalStatus.AUDIT:
      return "審核中";
    case ApprovalStatus.INVALID:
      return "作廢";
    case ApprovalStatus.NOT_REQUIRED:
      return "不需審核";
    default:
      return "";
  }
};
export enum ShippingStatus {
  DEPLOY_PENDING = 0,
  DEPLOY_SUCCESS = 1,
}
export enum AmountRateType {
  RATE = 1,
  AMOUNT = 2,
}

// defined api type
export interface ListFilter {
  limit: number;
  offset: number;
}
export interface RetailPayload {
  region?: number;
  contractTypes?: number[];
}
export interface RetailList {
  id: number;
  name: string;
  address: string;
  phone: string;
  region: {
    id: number;
    name: string;
    regionType: RegionType;
  };
}
export interface RetailListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: RetailList[];
}
export interface AddRetailProductLinePayload {
  name: string;
}
export interface RetailRegionPayload {
  name?: string;
  regionType?: RegionType;
}
export interface RegionListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: number;
    name: string;
    regionType: RegionType;
  }[];
}
export interface CreateRegionPayload {
  name: string;
  regionType: RegionType;
}
export interface CreateRegion {
  id: number;
  name: string;
  regionType: RegionType;
}
export interface RetailProductLinePayload {
  name?: string;
  nameQ?: string;
}
export interface RetailProductLineListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: {
    id: number;
    name: string;
  }[];
}
export interface DealerFilter extends ListFilter {
  regionType?: RegionType;
  retailer?: number;
  regionName?: string;
  contractType?: ContractType;
  currency?: Currency;
  productLine?: number;
}
export interface DealerList {
  id: number;
  retailer: {
    id: number;
    name: string;
    address: string;
    phone: string;
    region: {
      id: number;
      name: string;
      regionType: RegionType;
    };
    erpCustomerName: string;
  };
  productLine: {
    id: number;
    name: string;
  };
  currency: Currency;
  contractType: ContractType;
  memo: string;
  daysAfterMonthlyStatement: number;
}
export interface DealerListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: DealerList[];
}
export interface UpdateDealerPayload {
  address: string;
  phone: string;
  currency: Currency;
  contractType: ContractType;
  memo: string;
  daysAfterMonthlyStatement: number;
}
export interface CreateDealerPayload extends UpdateDealerPayload {
  name: string;
  region: RegionType;
  productLine: number;
  erpCustomerName: string;
}
export interface ContactInfoListFilter extends ListFilter {
  retailerProductline?: number;
  retailer?: number;
  productlines?: string;
}
export interface ContactInfoList {
  id: number;
  name: string;
  title: string;
  phone: string;
  email: string;
  isMajor: boolean;
  retailerProductline: number;
}
export interface ContactInfoListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContactInfoList[];
}
export interface CreateContactInfoPayload {
  name: string;
  title: string;
  phone: string;
  email: string;
  isMajor?: boolean;
  retailerProductline: number;
}
export interface UpdateContactInfoPayload {
  name?: string;
  title?: string;
  phone?: string;
  email?: string;
  isMajor?: boolean;
}
export interface DeductionList {
  id: number;
  name: string;
  rate: number;
  amount: number;
  retailerProductline: number;
}
export interface DeductionListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: DeductionList[];
}
export interface CreateDeductionPayload {
  name: string;
  rate?: number;
  amount?: number;
  retailerProductline: number;
}
export interface UpdateDeductionPayload {
  name?: string;
  rate?: number;
  amount?: number;
  retailerProductline?: number;
}
export interface AccessPointListPayload extends ListFilter {
  name?: string;
  nameQ?: string;
  retailer?: number;
  productlines?: string;
  retailerProductline?: number;
}
export interface AccessPointList {
  id: number;
  name: string;
  address: string;
  retailerProductline: number;
  code: string;
}
export interface AccessPointListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: AccessPointList[];
}
export interface CreateAccessPointPayload {
  name: string;
  address: string;
  code: string;
  retailerProductline: number;
}
export interface UpdateAccessPointPayload {
  name: string;
  code: string;
  address: string;
}
export interface ContractList {
  id: number;
  name: string;
  startAt: string;
  endAt: string;
  media: string;
  retailerProductline: number;
}
export interface ContractListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ContractList[];
}
export interface CreateContractPayload {
  name: string;
  startAt: string;
  endAt: string;
  pdfDirPrefix?: string;
  pdfFilePath?: string;
  retailerProductline: number;
}
export interface UpdateContractPayload extends CreateContractPayload {}

export enum PickupType {
  SELF_PICKUP = 0,
  CHARTER = 1,
  HCT_CHARTER = 2,
  T_CAT = 3,
}

export enum PickupTypeByValue {
  "訂單自取" = 0,
  "訂單非自取 (專車)" = 1,
  "訂單非自取 (新竹)" = 2,
  "訂單非自取 (黑貓)" = 3,
}

export interface ExternalOrderLine {
  id: number;
  no: string;
  externalOrder: number;
  batchNumber: null;
  sku: string;
  name: string;
  nameEng: string;
  effectiveDate: null;
  cost: number;
  pcsPerCarton: number;
  orderedQty: number;
  quotedPriceTwd: number;
  quotedPriceCurrency: number;
  totalDeduction: number;
  totalQty: number;
  margin: number;
  revenue: number;
  canAcceptDays: null;
  shippedQty: number;
}
export interface ExternalOrderInfo {
  id: number;
  staff: {
    id: number;
    name: string;
  };
  media: string | null;
  orderNumber: string;
  shippingStatus: string;
  retailerRegion: string;
  retailerRegionType: string;
  retailerName: string;
  retailerContactName: string;
  retailerContactPhone: string;
  retailerContactEmail: string;
  retailerProductlineName: number[];
  retailerWarehouseName: string;
  retailerContractType: string[];
  retailerDaysAfterMonthlyStatement: number;
  pickupType: PickupType;
  approvalStatus: string;
  sectionManagerApproval: ApprovalStatus;
  managerApproval: ApprovalStatus;
  financeApproval: ApprovalStatus;
  ceoApproval: ApprovalStatus;
  estimatedShippingDate: string;
  specifiedDeliveryDate: string;
  shippingAt: string;
  currencyRate: number;
  currency: Currency;
  incoterms: Incoterms;
  mato?: string;
  createdAt: string;
  updatedAt: string;
  totalRevenue: number;
  totalCost: number;
  totalOrderFee: number;
  totalMargin: number;
  totalMarginRatio: number;
  externalOrderLine: ExternalOrderLine[];
  orderDeduction: {
    id: number;
    productlineName: string;
    name: string;
    rate: number;
    amount: number;
    externalOrder: number;
    type: AmountRateType;
  }[];
  orderFee: {
    id: number;
    name: string;
    rate: number;
    amount: number;
    externalOrder: number;
  }[];
  isSend: boolean;
  deliveryAt: string;
  totalShippedQty: number;
  totalOrderedQty: number;
  invoiceInfo: InvoiceInfo;
  canInvoice: boolean;
}
export type InvoiceInfo = {
  invoiceNumber: string;
  invoiceAt: string;
  grandTotal: number;
};
export interface CreateExternalOrderPayload {
  estimatedShippingDate: string;
  retailerRegion: string;
  retailerRegionType: string;
  retailerName: string;
  retailerContactName: string;
  retailerContactPhone: string;
  retailerContactEmail: string;
  retailerProductlineName: string[];
  retailerWarehouseName: string;
  retailerWarehouseAddress: string;
  retailerWarehouseCode: string;
  pickupType: PickupType;
  currencyRate: number;
  currency: Currency;
  incoterms: string;
}
export interface CreateExternalOrder {
  id: number;
  staff: {
    id: number;
    name: string;
  };
  media: string | null;
  orderNumber: string;
  shippingStatus: string;
  retailerRegion: string;
  retailerRegionType: string;
  retailerName: string;
  retailerContactName: string;
  retailerContactPhone: string;
  retailerContactEmail: string;
  retailerProductlineName: string[];
  retailerWarehouseName: string;
  retailerContractType: string[];
  retailerDaysAfterMonthlyStatement: number;
  pickupType: PickupType;
  approvalStatus: string;
  sectionManagerApproval: ApprovalStatus;
  managerApproval: ApprovalStatus;
  financeApproval: ApprovalStatus;
  ceoApproval: ApprovalStatus;
  estimatedShippingDate: string;
  shippingAt: string;
  currencyRate: number;
  currency: Currency;
  incoterms: Incoterms;
  createdAt: string;
  updatedAt: string;
}
export interface UpdateExternalOrderPayload extends CreateExternalOrderPayload {}
export interface ApprovalExternalOrder {
  isApproved: boolean;
}
export interface BatchUpdateOrderLines {
  id: number;
  batchNumber: string;
  effectiveDate: string | null;
  canAcceptDays?: number;
  cost: number;
  quotedPriceCurrency: number;
  quotedPriceTwd: number;
  pcsPerCarton: number;
  orderedQty: number;
}
export interface BatchUpdateOrderDeductions {
  id: number;
  rate?: number;
  amount?: number;
}
export interface OrderLinesParams {
  externalOrder: number | string;
  sku?: string;
  nameQ?: string;
  nameEngQ?: string;
  effectiveDateBefore?: string;
  effectiveDateAfter?: string;
  limit?: number;
}
export interface CreateOrderFeePayload {
  externalOrder: number;
  name: string;
  rate?: number;
  amount?: number;
}
export interface UpdateOrderFeePayload {
  id: number;
  rate?: number;
  amount?: number;
}
export interface DeleteOrderFeePayload {
  orderFeeIds: number[];
}
export interface OrderLinesList {
  id: number;
  no: string;
  externalOrder: number;
  batchNumber: string | null;
  sku: string;
  name: string;
  nameEng: string;
  effectiveDate: string | null;
  effectiveDateBatch: string | null;
  cost: number;
  pcsPerCarton: number;
  orderedQty: number;
  quotedPriceTwd: number;
  quotedPriceCurrency: number;
  totalDeduction: number;
  totalQty: number;
  margin: number;
  revenue: number;
  canAcceptDays: number | null;
  canSalesQty: number;
  storageType: string;
  notes: string;
  showData: boolean;
  totalStock: number;
  validErrorMessage: string;
}
export interface OrderLinesListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: OrderLinesList[];
}
export interface OrderDeductionsList {
  id: number;
  productlineName: string;
  name: string;
  rate?: number;
  amount?: number;
  externalOrder: number;
  type: AmountRateType;
}
export interface OrderDeductionsListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: OrderDeductionsList[];
}
export interface OrderFeeList {
  id: number;
  name: string;
  rate?: number;
  amount?: number;
  externalOrder: number;
  type: AmountRateType;
}
export interface OrderFeeListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: OrderFeeList[];
}
export interface ExternalOrdersListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ExternalOrdersList[];
}
export interface onlineOrdersListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ExternalOrdersList[];
}

export interface ExternalOrdersList {
  id: number;
  staff: {
    id: number;
    name: string;
  };
  orderNumber: string;
  shippingStatus: string;
  retailerRegion: string;
  retailerRegionType: string;
  retailerName: string;
  retailerContactName: string;
  retailerContactPhone: string;
  retailerContactEmail: string;
  retailerProductlineName: string[];
  retailerWarehouseName: string;
  retailerContractType: string[];
  retailerDaysAfterMonthlyStatement: number;
  pickupType: PickupType;
  approvalStatus: string;
  sectionManagerApproval: number;
  managerApproval: number;
  financeApproval: number;
  ceoApproval: number;
  estimatedShippingDate: string;
  shippingAt: string;
  currencyRate: number;
  currency: number;
  incoterms: string;
  createdAt: string;
  updatedAt: string;
  media: null;
  canInvoice: boolean;
  deliveryAt: string;
}

export interface ExternalOrdersExportParam {
  orderNumber?: string;
  retailerNameQ?: string;
  shippingStatus?: number;
  staff?: number;
  approvalStatus?: number;
  createdAtAfter?: string;
  createdAtBefore?: string;
  shippingAtAfter?: string;
  shippingAtBefore?: string;
}
export interface OnlineOrdersFilter extends ListFilter {
  orderNumber?: string;
  platForm?: string;
  platFormrNumber?: string;
  id?: string;
  createdAtAfter?: string;
  createdAtBefore?: string;
  shippingAtAfter?: string;
  shippingAtBefore?: string;
}

export interface ExternalOrdersFilterParam extends ListFilter, ExternalOrdersExportParam {}

interface ThroughShipmentApi {
  // 通路
  fetchRetailRegion: (payload: RetailRegionPayload) => Promise<RegionListResult>;
  fetchCreateRegion: (payload: CreateRegionPayload) => Promise<CreateRegion>;
  fetchRetailList: (payload: RetailPayload) => Promise<RetailListResult>;
  fetchRetailProductLine: (payload?: RetailProductLinePayload) => Promise<RetailProductLineListResult>;
  fetchAddRetailProductLine: (payload: AddRetailProductLinePayload) => Promise<void>;
  fetchDealerList: (payload: DealerFilter) => Promise<DealerListResult>;
  fetchDealerInfo: (delearId: number) => Promise<DealerList>;
  fetchCreateDealer: (payload: CreateDealerPayload) => Promise<DealerList>;
  fetchUpdateDealer: (delearId: number, payload: UpdateDealerPayload) => Promise<void>;
  fetchDeleteDealer: (delearId: number) => Promise<void>;
  fetchContactInfoList: (payload: ContactInfoListFilter) => Promise<ContactInfoListResult>;
  fetchCreateContact: (payload: CreateContactInfoPayload) => Promise<void>;
  fetchUpdateContact: (contactId: number, payload: UpdateContactInfoPayload) => Promise<void>;
  fetchDeleteContact: (contactId: number) => Promise<void>;
  fetchDeductionFeeList: (retailerId: number, payload: ListFilter) => Promise<DeductionListResult>;
  fetchCreateDeductionFee: (payload: CreateDeductionPayload) => Promise<void>;
  fetchUpdateDeductionFee: (deductionId: number, payload: UpdateDeductionPayload) => Promise<void>;
  fetchDeleteDeductionFee: (deductionId: number) => Promise<void>;
  fetchAccessPointList: (payload: AccessPointListPayload) => Promise<AccessPointListResult>;
  fetchCreateAccessPoint: (payload: CreateAccessPointPayload) => Promise<void>;
  fetchUpdateAccessPoint: (accessPointId: number, payload: UpdateAccessPointPayload) => Promise<void>;
  fetchDeleteAccessPoint: (accessPointId: number) => Promise<void>;
  fetchContractList: (retailerId: number, payload: ListFilter) => Promise<ContractListResult>;
  fetchCreateContract: (payload: CreateContractPayload) => Promise<void>;
  fetchUpdateContract: (contractId: number, payload: UpdateContractPayload) => Promise<void>;
  fetchDeleteContract: (contractId: number) => Promise<void>;
  fetchErpCustomerList: () => Promise<string[]>;
  // 出貨訂單
  fetchExternalOrdersList: (payload: ExternalOrdersFilterParam) => Promise<ExternalOrdersListResult>;
  fetchExternalOrderInfo: (externalOrderId: number) => Promise<ExternalOrderInfo>;
  fetchCreateExternalOrder: (payload: CreateExternalOrderPayload) => Promise<CreateExternalOrder>;
  fetchUpdateExternalOrder: (externalOrderId: number, payload: UpdateExternalOrderPayload) => Promise<void>;
  fetchDeleteExternalOrder: (externalOrderId: number) => Promise<void>;
  fetchApprovalExternalOrder: (externalOrderId: number, payload: ApprovalExternalOrder) => Promise<void>;
  fetchOrderLines: (params: OrderLinesParams) => Promise<OrderLinesListResult>;
  fetchCreateProduct: (params: { externalOrder: string; no: string }) => Promise<OrderLinesListResult>;
  fetchDeleteProduct: (params: { externalOrder: string; id: number }) => Promise<void>;
  fetchBatchUpdateOrderLines: (payload: BatchUpdateOrderLines[]) => Promise<void>;
  fetchOrderDeductions: (externalOrderId: number) => Promise<OrderDeductionsListResult>;
  fetchBatchUpdateOrderDeductions: (payload: BatchUpdateOrderDeductions[]) => Promise<void>;
  fetchOrderFeeList: (externalOrderId: number) => Promise<OrderFeeListResult>;
  fetchBatchCreateOrderFee: (payload: CreateOrderFeePayload[]) => Promise<void>;
  fetchBatchUpdateOrderFee: (payload: UpdateOrderFeePayload[]) => Promise<void>;
  fetchBatchDeleteOrderFee: (payload: DeleteOrderFeePayload) => Promise<void>;
  fetchSendOrderNotify: (externalOrderId: number) => Promise<void>;
  importIWappsProductsXLS: (params: { externalOrder: string; file: File }) => Promise<void>;
  exportCSV: (filter: ExternalOrdersExportParam) => Promise<void>;
  fetchCreateInvoice: (externalOrderId: number, payload: InvoiceInfo) => Promise<void>;
}

const throughShipmentApi: ThroughShipmentApi = {
  fetchRetailRegion: async (payload) => {
    const response = await apiClient.getData("/manage/retail/regions/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateRegion: async (payload) => {
    const response = await apiClient.postData("/manage/retail/regions/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRetailList: async (payload) => {
    const getParams = {
      ...payload,
      contractTypes: payload?.contractTypes?.join(","),
    };
    const response = await apiClient.getData("/manage/retail/retailers/", transformCamelToSnake(getParams));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchRetailProductLine: async (payload) => {
    const response = await apiClient.getData(
      "/manage/retail/product-lines/",
      payload ? transformCamelToSnake(payload) : undefined,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddRetailProductLine: async (payload) => {
    await apiClient.postData("/manage/retail/product-lines/", payload);
  },
  fetchDealerList: async (payload) => {
    const response = await apiClient.getData("/manage/retail/retailer-relations/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDealerInfo: async (delearId) => {
    const response = await apiClient.getData(`/manage/retail/retailer-relations/${delearId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateDealer: async (payload) => {
    const response = await apiClient.postData("/manage/retail/retailer-relations/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateDealer: async (delearId, payload) => {
    await apiClient.patchData(`/manage/retail/retailer-relations/${delearId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteDealer: async (delearId) => {
    await apiClient.deleteData(`/manage/retail/retailer-relations/${delearId}/`, {});
  },
  fetchContactInfoList: async (payload) => {
    const response = await apiClient.getData("/manage/retail/retailer-contacts/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateContact: async (payload) => {
    await apiClient.postData("/manage/retail/retailer-contacts/", transformCamelToSnake(payload));
  },
  fetchUpdateContact: async (contactId, payload) => {
    await apiClient.patchData(`/manage/retail/retailer-contacts/${contactId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteContact: async (contactId) => {
    await apiClient.deleteData(`/manage/retail/retailer-contacts/${contactId}/`, {});
  },
  fetchDeductionFeeList: async (retailerId, payload) => {
    const response = await apiClient.getData(
      `/manage/retail/retailer-deductions/?retailer_productline=${retailerId}`,
      payload,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateDeductionFee: async (payload) => {
    await apiClient.postData("/manage/retail/retailer-deductions/", transformCamelToSnake(payload));
  },
  fetchUpdateDeductionFee: async (deductionId, payload) => {
    await apiClient.patchData(`/manage/retail/retailer-deductions/${deductionId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteDeductionFee: async (deductionId) => {
    await apiClient.deleteData(`/manage/retail/retailer-deductions/${deductionId}/`, {});
  },
  fetchAccessPointList: async (payload) => {
    const response = await apiClient.getData("/manage/retail/retailer-warehouses/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateAccessPoint: async (payload) => {
    await apiClient.postData("/manage/retail/retailer-warehouses/", transformCamelToSnake(payload));
  },
  fetchUpdateAccessPoint: async (accessPointId, payload) => {
    await apiClient.patchData(`/manage/retail/retailer-warehouses/${accessPointId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteAccessPoint: async (accessPointId) => {
    await apiClient.deleteData(`/manage/retail/retailer-warehouses/${accessPointId}/`, {});
  },
  fetchContractList: async (retailerId, payload) => {
    const response = await apiClient.getData(
      `/manage/retail/retailer-contracts/?retailer_productline=${retailerId}`,
      payload,
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateContract: async (payload) => {
    await apiClient.postData("/manage/retail/retailer-contracts/", transformCamelToSnake(payload));
  },
  fetchUpdateContract: async (contractId, payload) => {
    await apiClient.patchData(`/manage/retail/retailer-contracts/${contractId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteContract: async (contractId) => {
    await apiClient.deleteData(`/manage/retail/retailer-contracts/${contractId}/`, {});
  },
  fetchErpCustomerList: async () => {
    const response = await apiClient.getData("/manage/erp/customers", {});
    return transformAPIKeyToCamel(response.data.result?.name);
  },
  // 出貨訂單
  fetchExternalOrdersList: async (payload) => {
    const response = await apiClient.getData("/manage/retail/external-orders/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchExternalOrderInfo: async (externalOrderId) => {
    const response = await apiClient.getData(`/manage/retail/external-orders/${externalOrderId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateExternalOrder: async (payload) => {
    const response = await apiClient.postData("/manage/retail/external-orders/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateExternalOrder: async (externalOrderId, payload) => {
    await apiClient.patchData(`/manage/retail/external-orders/${externalOrderId}/`, transformCamelToSnake(payload));
  },
  fetchDeleteExternalOrder: async (externalOrderId) => {
    await apiClient.deleteData(`/manage/retail/external-orders/${externalOrderId}/`, {});
  },
  fetchApprovalExternalOrder: async (externalOrderId, payload) => {
    await apiClient.putData(
      `/manage/retail/external-orders/${externalOrderId}/approve/`,
      transformCamelToSnake(payload),
    );
  },
  fetchOrderLines: async (params) => {
    const response = await apiClient.getData("/manage/retail/order-lines/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  importIWappsProductsXLS: async (params) => {
    const formData = new FormData();
    formData.append("file", params.file);
    formData.append("external_order", params.externalOrder);
    const response = await apiClient.postData("/manage/retail/order-lines/upload/", formData);
    return response.data;
  },
  fetchCreateProduct: async (params) => {
    const response = await apiClient.postData("/manage/retail/order-lines/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchDeleteProduct: async (params) => {
    const payload = {
      externalOrder: params.externalOrder,
    };
    await apiClient.deleteData(`/manage/retail/order-lines/${params.id}/`, transformCamelToSnake(payload));
  },
  fetchBatchUpdateOrderLines: async (payload) => {
    await apiClient.putData("/manage/retail/order-lines/batch-update/", transformCamelToSnake(payload));
  },
  fetchOrderDeductions: async (externalOrderId) => {
    const response = await apiClient.getData(`/manage/retail/order-deductions/?external_order=${externalOrderId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchBatchUpdateOrderDeductions: async (payload) => {
    await apiClient.putData("/manage/retail/order-deductions/batch-update/", payload);
  },
  fetchOrderFeeList: async (externalOrderId) => {
    const response = await apiClient.getData(`/manage/retail/order-fee/?external_order=${externalOrderId}`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchBatchCreateOrderFee: async (payload) => {
    await apiClient.postData("/manage/retail/order-fee/batch-create/", transformCamelToSnake(payload));
  },
  fetchBatchUpdateOrderFee: async (payload) => {
    await apiClient.putData("/manage/retail/order-fee/batch-update/", transformCamelToSnake(payload));
  },
  fetchBatchDeleteOrderFee: async (payload) => {
    await apiClient.putData("/manage/retail/order-fee/batch-delete/", transformCamelToSnake(payload));
  },
  fetchSendOrderNotify: async (externalOrderId) => {
    await apiClient.postData(`/manage/retail/external-orders/${externalOrderId}/send-notify/`, {});
  },
  exportCSV: async (filter) => {
    const getParams = transformCamelToSnake(filter);
    await apiClient.getData("/manage/retail/external-orders/export/", getParams);
  },
  fetchCreateInvoice: async (externalOrderId, payload) => {
    await apiClient.postData(
      `/manage/retail/external-orders/${externalOrderId}/invoice/`,
      transformCamelToSnake(payload),
    );
  },
};

export default throughShipmentApi;
