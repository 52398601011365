import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import { StandardResponse } from "src/types";
import CoreApi from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreApi();

interface IPromotionApi {
  fetchPromotionList: (params: FetchPromotionListParams) => Promise<PromotionListResult>;
  batchDeletePromotions: (params: number[]) => Promise<StandardResponse>;
  fetchPromotionDetail: (id: number) => Promise<PromotionDetail>;
  createPromotion: (params: CreatePromotionParams) => Promise<PromotionDetail>;
  updatePromotion: (id: number, params: UpdatePromotionParams) => Promise<PromotionDetail>;
  updatePromotionIsActive: (id: number, isActive: boolean) => Promise<PromotionDetail>;
  updatePromotionIsDeleted: (id: number, isDeleted: boolean) => Promise<PromotionDetail>;
  batchCSVUploadSalePages: (promotionId: number, file: File) => Promise<StandardResponse>;
  batchDeleteSalePages: (promotionId: number, pageIds: number[], type: number) => Promise<StandardResponse>;
  batchAddSalePages: (promotionId: number, ids: number[], type: number) => Promise<StandardResponse>;
  batchDeleteRules: (promotionId: number, ids: number[]) => Promise<StandardResponse>;
  batchAddRules: (promotionId: number, ids: number[]) => Promise<StandardResponse>;
  bindRelation: (params: SalePageListParams) => Promise<TagAndCategoryListResponse>;
}

interface TagAndCategoryListResponse {
  statusCode: number;
  errorMessage: string;
  result: { count: number; results: [] };
}

export interface PromotionListResult {
  count: number;
  previous: string;
  next: string;
  results: PromotionListItem[];
}

export interface PromotionListItem {
  id: number;
  title: string;
  pageType: number;
  destinationLink: string;
  startAt: string;
  endAt: string;
  tag: number;
  isActive: boolean;
  imageUrl: string;
}

export interface FetchPromotionListParams {
  limit: number;
  offset: number;
  id?: number;
  salesPageId?: number;
  isActive: number;
  titleQ?: string;
  destinationLinkQ?: string;
  startDate?: string;
  endDate?: string;
  brandId?: number;
}

export interface CreatePromotionParams {
  title: string;
  pageType: number;
  withTagPage: boolean;
  isActive: boolean;
  startAt: string;
  endAt: string;
  image?: string;
  imageAlt?: string;
  metaTitle?: string;
  metaKeywords?: string;
  metaDescription?: string;
  backgroundColor?: string;
  sortMethods?: SortMethod[];
  isShow?: boolean;
  isFilterEnabled?: boolean;
  bindType?: number;
}

export interface SortMethod {
  id?: number;
  name: string;
  isActive: boolean;
  isDescend?: boolean;
  isDefault?: boolean;
}

export interface PromotionDetail {
  id?: number;
  title: string;
  pageType: number;
  destinationLink: string;
  startAt: string;
  endAt: string;
  tag: number;
  isActive: boolean;
  isDeleted: boolean;
  pageInfo: {
    id: number;
    title: string;
    description: string;
    backgroundColor: string;
    image: {
      id: number;
      name: string;
      mediaType: number;
      isActive: boolean;
      alt: string;
      url: string;
      image: string;
    };
    metaTitle: string;
    metaKeywords: string;
    metaDescription: string;
    sortMethods: SortMethod[];
  };
  rules: Rule[];
  isShow: boolean;
  isFilterEnabled: boolean;
}

export interface Rule {
  id: number;
  isActive: boolean;
  title: string;
  conditionType: number;
  conditionAmount: number;
  actionType: number;
  discount: number;
  discountLimit: number;
  maxBindTimes: number;
  bindOnceStartAt: string;
  bindOnceEndAt: string;
  useRuleDescription: string;
  destinationLink: string;
  promotion: number;
}

export type UpdatePromotionParams = CreatePromotionParams;

export interface TagList {
  statusCode: number;
  errorMessage: string;
  result: {
    count: number;
    results: TagListResult[];
  };
}

export interface TagListResult {
  id: number;
  tagType: string;
  name: string;
  icon: null;
  isActive: true;
  taggedItemCount: number;
}

export interface CategoryListResult {
  id: number;
  name: string;
  path: string;
  parent: number;
  pageInfo: {
    id: number;
    title: string;
    description: null;
    backgroundColor: "#FFFFFF";
    image: {
      id: number;
      name: string;
      mediaType: string;
      isActive: string;
      alt: string;
      url: string;
      image: string;
      imageType: string;
      isShow: string;
      rank: string;
    };
    metaTitle: null;
    metaKeywords: null;
    metaDescription: null;
    sortMethods: [
      {
        id: number;
        name: string;
        isDescend: true;
        isDefault: false;
        isActive: true;
      },
      {
        id: number;
        name: string;
        isDescend: false;
        isDefault: false;
        isActive: true;
      },
      {
        id: number;
        name: string;
        isDescend: true;
        isDefault: true;
        isActive: true;
      },
      {
        id: number;
        name: string;
        isDescend: false;
        isDefault: false;
        isActive: true;
      },
      {
        id: number;
        name: string;
        isDescend: false;
        isDefault: false;
        isActive: true;
      },
    ];
  };
  iconId: number;
  icon: string;
  iconAlt: null;
  level: number;
  isActive: true;
  toShow: true;
}
export interface CategoryList {
  statusCode: number;
  errorMessage: string;
  result: {
    count: number;
    results: CategoryListResult[];
  };
}

export interface SalePageList {
  statusCode: number;
  errorMessage: string;
  result: {
    count: number;
    results: SaleListItem[];
  };
}

export interface SaleListItem {
  id: number;
  name: string;
  skuList: string[];
  brands: string[];
  planSpecialPriceRange: {
    maxPrice: number;
    minPrice: number;
  };
  isActive: boolean;
  pageType: SalePageTypeValue;
  plans: {
    id: number;
    price: number;
    optionCounts: number;
    salesDiscount: number;
    memberDiscounts: {
      groupId: number;
      value: number;
    }[];
  }[];
  logistic: any;
  groupCanBuy: {
    groupId: number;
    groupName: string;
    value: boolean;
  }[];
  displayVipPrice: boolean;
  reviewInfo: {
    reviewCount: number;
    avgReviewScores: number;
  };
  isEditable: boolean;
  startAt: string;
  endAt: string;
}

export interface SalePageListParams {
  id: number;
  type: number;
  sku?: string;
  pageNameQ?: string;
  limit: number;
  offset: number;
}

export interface FetchListParam {
  limit: number;
  offset: number;
  objectIds?: number[];
}

const promotionApi: IPromotionApi = {
  fetchPromotionList: async (params) => {
    const { id, titleQ, isActive, destinationLinkQ, startDate, endDate, limit, offset, brandId, salesPageId } = params;
    const getParams = {
      id,
      limit,
      offset,
      title_q: titleQ,
      is_active: isActive === -1 ? undefined : Boolean(isActive),
      destination_link_q: destinationLinkQ,
      start_date: startDate === null ? undefined : startDate,
      end_date: endDate === null ? undefined : endDate,
      tag_id: brandId,
      sales_page_id: salesPageId,
    };
    const response = await apiClient.getData("/manage/promotion/promotions/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchDeletePromotions: async (params) => {
    const putParams = {
      promotion_ids: params,
    };
    const response = await apiClient.putData("/manage/promotion/promotions/batch-delete/", putParams);
    return response.data;
  },
  fetchPromotionDetail: async (id) => {
    const getParams = {
      with_rules: 1,
    };
    const response = await apiClient.getData(`/manage/promotion/promotions/${id}/`, getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createPromotion: async (params) => {
    const {
      title,
      pageType,
      withTagPage,
      isActive,
      startAt,
      endAt,
      sortMethods,
      image,
      imageAlt,
      metaDescription,
      metaKeywords,
      metaTitle,
      backgroundColor,
      isShow,
      isFilterEnabled,
    } = params;
    const defaultSortMethod = sortMethods?.find((mtd) => mtd.isDefault);

    // 上傳圖片路徑
    const imageDirPrefix = image && "promotion/image";
    const imageFilePath = image;

    const postParams = {
      title,
      page_type: pageType,
      with_tag_page: withTagPage,
      is_active: isActive,
      start_at: startAt,
      end_at: endAt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imageAlt,
      meta_title: metaTitle,
      meta_keywords: metaKeywords,
      meta_description: metaDescription,
      background_color: backgroundColor,
      default_sort_method: defaultSortMethod?.name,
      sort_methods: sortMethods?.map((mtd) => ({
        name: mtd.name,
        is_active: mtd.isActive,
      })),
      is_show: isShow,
      is_filter_enabled: isFilterEnabled,
    };
    const response = await apiClient.postData("/manage/promotion/promotions/", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updatePromotion: async (id, params) => {
    const {
      title,
      pageType,
      withTagPage,
      isActive,
      startAt,
      endAt,
      sortMethods,
      image,
      imageAlt,
      metaDescription,
      metaKeywords,
      metaTitle,
      backgroundColor,
      isShow,
      isFilterEnabled,
      bindType,
    } = params;
    const defaultSortMethod = sortMethods?.find((mtd) => mtd.isDefault);

    // 上傳圖片路徑 icon/image 存的是s3網址或是後端回傳的暫時路徑
    // s3網址 ？ 不做更新 ： 傳路徑
    const imageDirPrefix =
      image && !image.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? "promotion/image" : undefined;
    const imageFilePath = image && !image.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? image : undefined;

    const postParams = {
      title,
      page_type: pageType,
      with_tag_page: withTagPage,
      is_active: isActive,
      start_at: startAt,
      end_at: endAt,
      image_dir_prefix: imageDirPrefix,
      image_file_path: imageFilePath,
      image_alt: imageAlt,
      meta_title: metaTitle,
      meta_keywords: metaKeywords,
      meta_description: metaDescription,
      background_color: backgroundColor,
      default_sort_method: defaultSortMethod?.name,
      sort_methods: sortMethods?.map((mtd) => ({
        name: mtd.name,
        is_active: mtd.isActive,
      })),
      is_show: isShow,
      is_filter_enabled: isFilterEnabled,
      bind_type: bindType,
    };
    const response = await apiClient.patchData(`/manage/promotion/promotions/${id}/`, postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updatePromotionIsActive: async (id, isActive) => {
    const postParams = {
      is_active: isActive,
      is_show: false,
      is_filter_enabled: false,
    };
    const response = await apiClient.patchData(`/manage/promotion/promotions/${id}/`, postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updatePromotionIsDeleted: async (id, isDeleted) => {
    const postParams = {
      is_deleted: isDeleted,
    };
    const response = await apiClient.patchData(`/manage/promotion/promotions/${id}/`, postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  batchCSVUploadSalePages: async (promotionId: number, file: File) => {
    const formData = new FormData();
    formData.append("csv_file", file);

    const response = await apiClient.putData(
      `/manage/promotion/promotions/${promotionId}/pages/batch/csv-upload/`,
      formData,
    );
    return response.data;
  },
  batchDeleteSalePages: async (promotionId, pageIds, type) => {
    const putParams = {
      object_ids: pageIds,
      type,
    };

    const response = await apiClient.putData(
      `/manage/promotion/promotions/${promotionId}/pages/batch/delete/`,
      putParams,
    );
    return response.data;
  },
  batchAddSalePages: async (promotionId, pageIds, type) => {
    const putParams = {
      object_ids: pageIds,
      type,
    };

    const response = await apiClient.putData(`/manage/promotion/promotions/${promotionId}/pages/batch/add/`, putParams);
    return response.data;
  },
  batchDeleteRules: async (promotionId, ids) => {
    const putParams = {
      rule_ids: ids,
    };

    const response = await apiClient.putData(
      `/manage/promotion/promotions/${promotionId}/rules/batch/delete/`,
      putParams,
    );
    return response.data;
  },
  batchAddRules: async (promotionId, ids) => {
    const putParams = {
      rule_ids: ids,
    };

    const response = await apiClient.putData(`/manage/promotion/promotions/${promotionId}/rules/batch/add/`, putParams);
    return response.data;
  },
  bindRelation: async (params) => {
    const getParams = {
      tag_id: params.id,
      type: params.type,
      limit: params.limit,
      offset: params.offset,
      page_name_q: params.pageNameQ,
      sku: params.sku,
    };

    const response = await apiClient.getData("/manage/promotion/bind-relations/", getParams);
    return transformAPIKeyToCamel(response.data);
  },
};

export default promotionApi;
