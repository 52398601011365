import { ExclamationCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { ManufacturerContactItem, contactPositionTitleLabel } from "@api/brandOperation/manufacturerApi";
import PopupBackground from "@component/PopupBackground";
import {
  manufacturerState,
  updateContactParams,
  updateManufacturerContact,
  deleteManufacturerContact,
  addManufacturerContact,
  fetchManufacturerContactList,
} from "@redux/brandOperation/manufacturerSlice";
import { Modal, Tag, Button, Select, Table, Form, message, Input, Checkbox } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  FlexBox,
  ContentWrapper,
  Header,
  CustomCenterRow,
  PageText,
  SelectPageSize,
  ModalWrapper,
} from "src/styles/common";

const ContactInfo = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const { id } = useParams();
  const manufacturerId = Number(id);
  const { isFetching, manufacturerContactListResult, contactParams } = useSelector(manufacturerState);
  const { results: contactList, count: totalCount } = manufacturerContactListResult;
  const [page, setPage] = useState<number>(1);
  const [showAddModel, setShowAddModel] = useState<string | undefined>();
  const [AddModalForm] = useForm();

  const handleOnPageSizeChange = (value: SelectValue) => {
    dispatch(
      updateContactParams({
        ...contactParams,
        limit: Number(value),
      }),
    );
  };
  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updateContactParams({
        ...contactParams,
        offset: contactParams.limit * (value - 1),
      }),
    );
  };

  const handleMajorContact = (record: ManufacturerContactItem & { id: number }) => {
    dispatch(updateManufacturerContact({ ...record, isMajor: true, manufacturerId }));
  };

  const showDeleteConfirm = (manufacturerName: string, contactId: number) => {
    confirm({
      title: "確定要刪除此筆資料？",
      icon: <ExclamationCircleFilled />,
      content: manufacturerName,
      okText: "確認",
      okType: "danger",
      cancelText: "取消",
      onOk() {
        dispatch(deleteManufacturerContact({ manufacturerId, contactId }));
      },
    });
  };
  const handleOnSubmit = () => {
    const formValues = AddModalForm.getFieldsValue();
    if (showAddModel === "add") {
      dispatch(addManufacturerContact({ ...formValues, manufacturerId }));
    }
    if (showAddModel === "edit") {
      dispatch(updateManufacturerContact({ ...formValues, manufacturerId }));
    }
    AddModalForm.resetFields();
    setShowAddModel(undefined);
  };

  const columns: ColumnsType<ManufacturerContactItem> = [
    {
      key: "name",
      dataIndex: "name",
      title: "聯絡人姓名",
      align: "center",
      width: 120,
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      width: 100,
      render: (_, record) => record.isMajor && <Tag color="processing">主要聯絡人</Tag>,
    },
    {
      key: "phone",
      dataIndex: "phone",
      align: "center",
      title: "聯絡人電話",
    },
    {
      key: "email",
      dataIndex: "email",
      title: "聯絡人信箱",
      align: "center",
    },
    {
      key: "role",
      dataIndex: "role",
      title: "聯絡人職稱",
      width: 120,
      align: "center",
      render: (value) => contactPositionTitleLabel.find((item) => item.value === value)?.label,
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      align: "right",
      render: (_, record) => {
        return (
          <Button
            disabled={record.isMajor}
            onClick={() => handleMajorContact(record as ManufacturerContactItem & { id: number })}
          >
            設為主要聯絡人
          </Button>
        );
      },
    },

    {
      key: "",
      dataIndex: "",
      title: "",
      render: (_, record) => (
        <FlexBox>
          <Button
            type="link"
            onClick={() => {
              AddModalForm.setFieldsValue(record);
              setShowAddModel("edit");
            }}
          >
            編輯
          </Button>
          <Button type="link" onClick={() => showDeleteConfirm(record.name, record.id!)}>
            刪除
          </Button>
        </FlexBox>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchManufacturerContactList(manufacturerId));
  }, [dispatch, contactParams, manufacturerId]);

  return (
    <ContentWrapper>
      <Header>
        <FlexBox>
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              setShowAddModel("add");
            }}
          >
            新增聯絡人
          </Button>
        </FlexBox>
        <CustomCenterRow>
          <PageText>{`總共${totalCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CustomCenterRow>
      </Header>
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: 500 }}
        columns={columns}
        dataSource={contactList}
        rowKey="phone"
        pagination={{
          pageSize: contactParams.limit,
          current: page,
          showSizeChanger: false,
          total: totalCount,
          onChange: handlePageChange,
        }}
      />
      {showAddModel && (
        <ContactInfoAddModal
          form={AddModalForm}
          onClose={() => {
            setShowAddModel(undefined);
            AddModalForm.resetFields();
          }}
          onSubmit={handleOnSubmit}
        />
      )}
    </ContentWrapper>
  );
};

export default ContactInfo;

const ContactInfoAddModal = (props: { form: FormInstance; onClose: () => void; onSubmit: () => void }) => {
  const { form, onClose, onSubmit } = props;
  const handleClosePopup = () => {
    onClose();
  };
  const handleOnSubmit = () => {
    onSubmit();
  };

  return (
    <PopupBackground close={handleClosePopup} fixed>
      <ModalWrapper width="350px">
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 18 }}
          onFinish={handleOnSubmit}
          onFinishFailed={() => message.error("必填欄位未填寫")}
        >
          <Form.Item label="contactId" name="id" noStyle>
            <Input hidden />
          </Form.Item>
          <Form.Item label="isMajor" name="isMajor" noStyle>
            <Checkbox style={{ visibility: "hidden" }} />
          </Form.Item>
          <Form.Item label="姓名" name="name" rules={[{ required: true, message: "請填入 姓名" }]}>
            <Input />
          </Form.Item>
          <Form.Item label="電話" name="phone" rules={[{ required: true, message: "請填入 電話" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="信箱"
            name="email"
            rules={[
              { type: "email", message: "請檢查 信箱 格式" },
              { required: true, message: "請填入 信箱" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="職稱" name="role" rules={[{ required: true, message: "請填入 職稱" }]}>
            <Select placeholder="請選擇" options={contactPositionTitleLabel} />
          </Form.Item>
          <FlexBox marginTop="12px">
            <Button onClick={handleClosePopup}>取消</Button>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
