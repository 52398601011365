import React, { useEffect, useState } from "react";
import styled from "styled-components";
import fontStyle from "src/styles/fontStyle";
import { Button, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import PageTitle from "@component/PageTitle";
import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import Filter from "@page/Cost/Filter";
import {
  recostState,
  fetchCostList,
  setImportFilePopup,
  fetchUploadCost,
  setCostFilter,
  fetchDeleteCost,
  resetRecostSlice,
} from "@redux/recostSlice";
import Loading from "@component/Loading/Loading";
import { CostResult } from "@api/recostApi";
import { useDispatch, useSelector } from "react-redux";
import ImportFileModal from "@component/ImportFileModal";
import { SelectValue } from "antd/lib/select";
import { useNavigate } from "react-router-dom";
import { ProcessValue, DepartmentValue, ProcessText } from "./constans";
import EditPopup from "./EditPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 28px 22px 22px;
  margin-top: 20px;
`;

const Recost = () => {
  const dispatch = useDispatch();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [recostId, setRecostId] = useState<number>();
  const [mode, setMode] = useState<string>();
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();

  const { costList, showImportFilePopup, costFilter, isFetching, isPending } = useSelector(recostState);
  const setPageLimit = (value: SelectValue) => {
    dispatch(setCostFilter({ ...costFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchCostList());
  };

  const handleOperPopup = (id?: number, modeType?: string) => {
    setMode(modeType);
    setOpenForm(!openForm);
    setRecostId(id);
  };

  const handleDelete = (id: number) => {
    dispatch(fetchDeleteCost(id));
  };
  const columns: ColumnsType<CostResult> = [
    {
      key: "staff",
      dataIndex: ["staff", "name"],
      title: "申請人",
    },
    {
      key: "type",
      dataIndex: "type",
      title: "部門",
      render: (data) => {
        return <div>{DepartmentValue[data]}</div>;
      },
    },
    {
      key: "skuNo",
      dataIndex: "skuNo",
      title: "商品條碼",
    },
    {
      key: "status",
      dataIndex: "status",
      title: "狀態",
      render: (data) => {
        return <div>{ProcessText[data]}</div>;
      },
    },
    {
      key: "originCost",
      dataIndex: "originCost",
      title: "更改前成本",
    },
    {
      key: "afterCost",
      dataIndex: "afterCost",
      title: "更改後成本",
    },
    {
      key: "timePeriod",
      dataIndex: "timePeriod",
      title: "回壓時間區間",
      render: (_, value) => {
        return (
          <div>
            {value.startAt} - {value.endAt}
          </div>
        );
      },
    },
    {
      key: "buttons",
      dataIndex: "",
      title: "",
      render: (_, values) => {
        return (
          <div>
            <Button type="link" onClick={() => navigate(`/recost/${values.id}/preview`)}>
              檢視
            </Button>
            <Button
              type="link"
              disabled={values.status === ProcessValue.PENDING || values.status === ProcessValue.APPROVE}
              onClick={() => handleOperPopup(values.id, "edit")}
            >
              編輯
            </Button>
            <Button
              type="link"
              disabled={values.status === ProcessValue.PENDING || values.status === ProcessValue.APPROVE}
              onClick={() => handleDelete(values.id!)}
            >
              刪除
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchCostList());
  }, [dispatch, costFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetRecostSlice());
    };
  }, [dispatch]);

  const handelAddXLS = (file: File) => {
    dispatch(fetchUploadCost(file));
  };

  return (
    <Wrapper>
      <PageTitle title="成本回壓" />
      {openForm && (
        <EditPopup visible={openForm} onClose={() => setOpenForm(!openForm)} recostId={recostId} mode={mode} />
      )}
      <Row justify="space-between">
        <div>
          <CustomButton type="primary" icon={<PlusCircleTwoTone />} onClick={() => handleOperPopup(undefined, "new")}>
            新增回壓資料
          </CustomButton>
          <CustomButton icon={<FilterTwoTone />} onClick={() => dispatch(setImportFilePopup(!showImportFilePopup))}>
            匯入回壓資料
          </CustomButton>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${costList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {showFilter && <Filter />}
      {isPending && <Loading />}
      <TableWrapper>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={costList.results}
          pagination={{
            pageSize: costFilter.limit,
            current: page,
            showSizeChanger: false,
            total: costList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {showImportFilePopup && (
        <ImportFileModal
          csvErrorMessage=""
          closePopup={() => dispatch(setImportFilePopup(false))}
          clearErrorMessage={() => {}}
          handleUploadFile={handelAddXLS}
          templatePath="admin/template/xls/cost_recalculate.xlsx"
          fileType=".xlsx,.csv,.xls"
          isFetching={isPending}
        />
      )}
    </Wrapper>
  );
};

export default Recost;
