/* eslint-disable camelcase */
import type { ProductCategoryDetail, ProductCategoryNode } from "@api/productCategoryApi";

interface ProductCategoryNodeRes {
  id: number;
  name: string;
  parent: number;
  product_count: number;
}
interface CategoryListRes {
  level_1: ProductCategoryNodeRes[];
  level_2: ProductCategoryNodeRes[];
  level_3: ProductCategoryNodeRes[];
}
interface MapCategoryNode {
  key: number;
  id: number;
  name: string;
  title: string;
  parent: number;
  productCount: number;
  level: number;
  children: Map<number, MapCategoryNode>;
}

interface CategoryDetailRes {
  id: number;
  name: string;
  tag_types: {
    id: number;
    code: number;
    name: string;
  }[];
  tag_types_attributes: {
    [attributeName: string]: { id: number; name: string }[];
  };
}

interface INormalizeProductCategory {
  list: (res: CategoryListRes) => ProductCategoryNode[];
  detail: (res: CategoryDetailRes) => ProductCategoryDetail;
}

const normalizeProductCategory: INormalizeProductCategory = {
  list: (res) => {
    const listMap = new Map<number, MapCategoryNode>();
    // tree level 1
    res.level_1.forEach((lvl1Node) => {
      const { id, name, parent, product_count } = lvl1Node;
      const normalizedNode = {
        id,
        key: id,
        name,
        title: `${name}(${product_count})`,
        parent,
        productCount: product_count,
        level: 1,
        children: new Map(),
        value: id,
        label: name,
      };
      listMap.set(id, normalizedNode);
    });
    // tree level 2
    res.level_2.forEach((lvl2Node) => {
      const { id, name, parent, product_count } = lvl2Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: `${name}(${product_count})`,
        parent,
        productCount: product_count,
        level: 2,
        children: new Map(),
        value: id,
        label: name,
      };

      const parentNode = listMap.get(parent);
      const parentNodeChildren = parentNode?.children;
      parentNodeChildren?.set(id, normalizedNode);
    });
    // tree level 3
    res.level_3.forEach((lvl3Node) => {
      const { id, name, parent, product_count } = lvl3Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: `${name}(${product_count})`,
        parent,
        productCount: product_count,
        level: 3,
        children: new Map(),
        value: id,
        label: name,
      };

      for (const [, level1Node] of listMap) {
        // parentNode 在level2
        const parentNode = level1Node.children.get(parent);
        if (parentNode) {
          const parentNodeChildren = parentNode.children;
          parentNodeChildren.set(id, normalizedNode);
        }
      }
    });

    // Map -> array
    const result = Array.from(listMap).map(([, lvl1]) => ({
      ...lvl1,
      children: Array.from(lvl1.children).map(([, lvl2]) => ({
        ...lvl2,
        children: Array.from(lvl2.children).map(([, lvl3]) => ({
          ...lvl3,
          children: [],
        })),
      })),
    }));

    return result;
  },
  detail: (res: CategoryDetailRes) => {
    const { id, name, tag_types, tag_types_attributes } = res;
    const result = {
      id,
      name,
      tagTypes: tag_types,
      tagTypesAttributes: tag_types_attributes,
    };

    return result;
  },
};

export default normalizeProductCategory;
