enum InvoiceTypeValue {
  // 手機條碼 二聯式發票
  // 自然人憑證 二聯式發票
  // B2C 二聯式發票
  B2C = 0,
  // B2B 三聯式發票
  B2B = 1,
  // 捐贈發票
  DONATION = 2,
}

export default InvoiceTypeValue;
