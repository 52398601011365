import BDList from "@component/BDList";
import { Modal, Space } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .ant-modal-header {
    padding-bottom: 0;
    border: 0;
  }

  .ant-modal-body {
    padding-bottom: 59px;
  }

  .ant-modal-footer {
    border: 0;
    padding-bottom: 20px;
  }
`;

export default function ManagerEditor(props: {
  visible: boolean;
  handleOwner: (currentOwnerId: number) => void;
  toggleManager: (state: boolean) => void;
}) {
  const { visible, handleOwner, toggleManager } = props;
  const [currentOwner, setCurrentOwner] = useState(-1);
  const [okDisable, setOkDisable] = useState(true);

  useEffect(() => {
    if (currentOwner !== -1) {
      setOkDisable(false);
    }
  }, [currentOwner]);

  const handleOK = () => {
    handleOwner(currentOwner);
  };

  const handleCancel = () => {
    toggleManager(false);
  };

  const handleOnChange = (value: number) => {
    setCurrentOwner(value);
  };

  return (
    <CustomModal
      title="批次修改商開負責人"
      visible={visible}
      onOk={handleOK}
      onCancel={handleCancel}
      okButtonProps={{ disabled: okDisable }}
      cancelButtonProps={{ disabled: false }}
      okText="確定"
      cancelText="取消"
    >
      <Space size={34}>
        <span>負責人</span>
        <BDList onChange={handleOnChange} value={currentOwner} />
      </Space>
    </CustomModal>
  );
}
