import { orderState } from "@redux/orderSlice";
import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { ApprovalStatus, ApprovalStatusMap } from "../ApprovedState";
import ProcessingType, { ProcessingTypeMap } from "../ProcessingType";

const ContentWrapper = styled.div`
  margin-top: 39px;
`;

const Text = styled.p`
  width: max-content;
  margin-bottom: 20px;
  ${fontStyle("14px", "16px")};
`;

const ALink = styled.a`
  ${fontStyle("14px", "16px")};
  color: ${({ theme }) => theme.colorSuccess500};
  margin: 0;
  text-decoration: underline;
`;

export default function ReplenishReturnContent() {
  const { replenishReturnInfo } = useSelector(orderState);
  const {
    orderInfo,
    processingType = ProcessingType.NORMAL,
    approvalStatus = ApprovalStatus.PENDING,
    createdAt,
    logisticName,
    reverseOrderShipmentInfo,
  } = replenishReturnInfo || {};

  return (
    <>
      <Text>訂單資訊</Text>
      <Row>
        <Col span={8}>
          <Text>
            {"訂單編號: "}
            <ALink href={`/order-content/${orderInfo?.orderId}`} target="_blank">
              {orderInfo?.orderNumber}
            </ALink>
          </Text>
        </Col>
        <Col span={8}>
          <Text>{`類別: ${ProcessingTypeMap[processingType]}`}</Text>
        </Col>
        <Col span={8}>
          <Text>{`狀態: ${ApprovalStatusMap[approvalStatus] || "N/A"}`}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Text>{`申請時間: ${moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}`}</Text>
        </Col>
        <Col span={8}>
          <Text>{`訂購時間: ${moment(orderInfo?.orderTime).format("YYYY-MM-DD HH:mm:ss")}`}</Text>
        </Col>
        <Col span={8}>
          <Text>{`出貨點: ${logisticName}`}</Text>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Text>{`會員名稱: ${orderInfo?.memberName}`}</Text>
        </Col>
        <Col span={8}>
          <Text>{`會員電話: ${orderInfo?.memberPhone}`}</Text>
        </Col>
      </Row>
      <ContentWrapper>
        <Text>補退貨資訊</Text>
        <Row>
          <Col span={8}>
            <Text>{`取件人: ${reverseOrderShipmentInfo?.receiverInfo.receiverName}`}</Text>
          </Col>
          <Col span={8}>
            <Text>{`取件電話: ${reverseOrderShipmentInfo?.receiverInfo.receiverPhone}`}</Text>
          </Col>
          <Col span={8}>
            <Text>{`取件地址: ${reverseOrderShipmentInfo?.receiverInfo.receiverAddress}`}</Text>
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Text>{`退款戶名: ${reverseOrderShipmentInfo?.bankInfo.accountName || "N/A"}`}</Text>
          </Col>
          <Col span={8}>
            <Text>{`銀行行名及分行名: ${reverseOrderShipmentInfo?.bankInfo.bankName || "N/A"}`}</Text>
          </Col>
          <Col span={8}>
            <Text>{`帳號: ${reverseOrderShipmentInfo?.bankInfo.bankAccount || "N/A"}`}</Text>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
}
