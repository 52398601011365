import PopupBackground from "@component/PopupBackground";
import { Button, Form, InputNumber, Row, Tooltip } from "antd";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import React, { useEffect, useMemo, useState } from "react";
import orderApi, { PlanOptions, PlanQty } from "@api/orderApi";
import { OrderSalesPlans } from "@api/utils/normalizeOrder";
import Table, { ColumnsType } from "antd/lib/table";
import { InfoCircleOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  width: 820px;
  height: 520px;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16px 1fr;
`;
const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 32px;
`;
const Title = styled.div`
  ${fontStyle("16px", "16px", "700")};
  color: ${({ theme }) => theme.colorNeutral600};
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;
const TipText = styled.div`
  ${fontStyle("13px", "20px", "700")};
  padding-bottom: 4px;
`;
const OptionRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px;
`;
const OptionInfo = styled.div``;
const OptionName = styled.div`
  ${fontStyle("14px", "18px")};
  color: ${({ theme }) => theme.colorNeutral600};
  margin-bottom: 4px;
`;
const OptionCanBuyCount = styled.div`
  ${fontStyle("14px", "16px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;
const OutOfStock = styled.span`
  margin-right: 2px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ErrorHelpText = styled.span`
  ${fontStyle("14px", "16px", "400")};
  color: ${({ theme }) => theme.colorSecondary500};
`;
const ButtonWrapper = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
interface ChangedOrderSalesPlans extends OrderSalesPlans {
  changedQty: number;
}

type Props = {
  plan: ChangedOrderSalesPlans;
  close: () => void;
  onSubmit: (planQty: PlanQty) => void;
};

type FormInputValue = { [key: string]: number };

export default function OrderPlanAnyOptionsModal(props: Props) {
  const { close, plan, onSubmit } = props;
  const [form] = Form.useForm();

  const { id: planId, optionsCount, changedQty } = plan!;
  const totalCount = optionsCount * changedQty;

  const [optionList, setOptionList] = useState<PlanOptions[]>([]);
  const [errorStatus, setErrorStatus] = useState<string>("");

  const planOriginalOptionsId = useMemo(() => plan.salesPlanOptions.map((options) => options.productId), [plan]);

  const columns: ColumnsType<ChangedOrderSalesPlans> = [
    {
      key: "salesPageName",
      dataIndex: "salesPageName",
      title: "銷售頁名稱",
      width: 210,
    },
    {
      key: "salesPlanName",
      dataIndex: "salesPlanName",
      title: "方案名稱",
      width: 95,
    },
    {
      key: "changedQty",
      dataIndex: "changedQty",
      title: "訂購數量",
      width: 100,
    },
    {
      key: "optionsText",
      dataIndex: "optionsText",
      title: "選項內容",
      render: (_: string, record: OrderSalesPlans) => {
        return (
          <>
            <TipText>選項：請填寫加總={totalCount}的數量</TipText>
            {optionList.map((option) => {
              const inputMax = totalCount >= option.canBuyCount ? option.canBuyCount : totalCount;
              return (
                <OptionRow>
                  <OptionInfo>
                    <OptionName>{option.name}</OptionName>
                    <OptionCanBuyCount>
                      目前剩餘可賣量：{option.canBuyCount}
                      {option.canBuyCount === 0 && (
                        <>
                          <OutOfStock> (可能為缺件)</OutOfStock>
                          <Tooltip title="需自行聯繫倉庫確認是否為缺件">
                            <InfoCircleOutlined />
                          </Tooltip>
                        </>
                      )}
                    </OptionCanBuyCount>
                  </OptionInfo>
                  <Form.Item shouldUpdate={(prevValues, currentValues) => currentValues === 0} noStyle>
                    {({ getFieldValue }) => (
                      <Form.Item
                        name={`optionQty-${option.productId}`}
                        validateStatus={getFieldValue(`optionQty-${option.productId}`) && errorStatus ? "error" : ""}
                      >
                        <InputNumber
                          max={inputMax}
                          min={0}
                          type="number"
                          style={{ width: "70px" }}
                          // onChange={onNumberChange}
                        />
                      </Form.Item>
                    )}
                  </Form.Item>
                </OptionRow>
              );
            })}
          </>
        );
      },
    },
  ];

  const handlePlanQtyChange = (changedValues: FormInputValue, allValues: FormInputValue) => {
    setErrorStatus("");
  };

  const handleOnSubmit = () => {
    const values = Object.values(form.getFieldsValue()) as number[];
    const totalOptionsCount = values.reduce((acc, cur) => (cur === undefined ? acc : acc + cur), 0);
    if (totalOptionsCount > totalCount) {
      setErrorStatus(`選項總數量不得大於${totalCount}`);
      return;
    }
    if (totalOptionsCount < totalCount) {
      setErrorStatus(`選項總數量不足${totalCount}`);
      return;
    }
    const allValues: FormInputValue = form.getFieldsValue();
    const selectedOptions = Object.entries(allValues).filter((option) => !!option[1]);
    const salesPlanOption = selectedOptions.map((selectedOption) => {
      const productId = parseInt(selectedOption[0].replace("optionQty-", ""), 10);
      const salesPlanOptionId = optionList.find((option) => option.productId === productId)!.id;
      return {
        salesPlanOptionId,
        qty: selectedOption[1],
        name: optionList.find((option) => option.id === salesPlanOptionId)?.name,
      };
    });
    const payload = { planId, qty: changedQty, salesPlanOption };
    onSubmit(payload);
    close();
  };

  useEffect(() => {
    const fetchOptions = async () => {
      if (planId && planId !== -1) {
        const resp = await orderApi.fetchPlanOptions(planId);
        resp.result.forEach((option, index) => {
          const matchProduct = planOriginalOptionsId.indexOf(option.productId);
          if (matchProduct !== -1) {
            const selectedOption = resp.result.splice(index, 1);
            resp.result.unshift(selectedOption[0]);
          }
        });
        setOptionList(resp.result);
      }
    };
    fetchOptions();
  }, [planId, setOptionList, planOriginalOptionsId]);

  useEffect(() => {
    if (optionList.length > 0) {
      const optionQty: FormInputValue = {};
      plan.salesPlanOptions.forEach((option) => {
        const canBuyCount = optionList.find((listOption) => listOption.productId === option.productId)?.canBuyCount;
        if (option.productId && canBuyCount) {
          optionQty[`optionQty-${option.productId}`] = option.optionQty >= canBuyCount ? canBuyCount : option.optionQty;
        }
      });
      form.setFieldsValue(optionQty);
    }
  }, [form, optionList, plan.salesPlanOptions]);

  return (
    <PopupBackground close={close}>
      <Wrapper>
        <Row>
          <Title>確認選項內容數量</Title>
        </Row>
        <Form form={form} colon={false} onValuesChange={handlePlanQtyChange} onFinish={handleOnSubmit}>
          <Grid>
            <TableWrapper>
              <Table columns={columns} dataSource={[plan]} pagination={false} scroll={{ x: 600, y: 330 }} />
            </TableWrapper>
            <Footer>
              <ErrorHelpText>{errorStatus}</ErrorHelpText>
              <ButtonWrapper>
                <CancelButton onClick={close}>取消</CancelButton>
                <Button type="primary" htmlType="submit">
                  確認
                </Button>
              </ButtonWrapper>
            </Footer>
          </Grid>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
