import { Select } from "antd";
import React from "react";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";

const { Option } = Select;

/**
 * value is passed from Ant Design Form
 * @see https://ant.design/components/form/#components-form-demo-customized-form-controls
 * @param props.value - 外層 Form 給的預設值
 */
export default function BDList(props: { value?: any; onChange?: (params: any) => void }) {
  const { value, onChange } = props;
  const staffs = useSelector((state: RootState) => state.staffs.allStaffData);
  // const ownerObservablePosition = ownerList.length > 5 ? ownerList.length - 5 : -1;

  const triggerChange = (selectedId: string) => {
    if (onChange) {
      onChange(selectedId);
    }
  };

  return (
    <Select
      style={{ width: "15vw", maxWidth: 188 }}
      onChange={triggerChange}
      value={value}
      showSearch
      filterOption={(input, option: any) => option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      <Option value={-1} key="bd-1">
        請選擇
      </Option>
      {staffs.map((bd) => (
        <Option value={bd.id} key={`bd${bd.id}`}>
          {bd.name}
        </Option>
      ))}
    </Select>
  );
}
