import productApi from "@api/productApi";
import { EditReportRegenDetail, ReportRegenDetail } from "@page/VendorReportRegenPage/interface";
import { addReportRegen, editReportRegen } from "@redux/vendorSlice";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const { RangePicker } = DatePicker;

const WrapperForm = styled(Form)`
  padding: 32px 28px 20px 28px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  margin-top: auto;
  text-align: right;
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const StyledInput = styled(Input)`
  width: 395px;
`;

const StyledRateInput = styled(Input)`
  width: 255px;
`;

const RateText = styled.span`
  align-self: flex-start;
  line-height: 32px;
  width: 50px;
  margin: 0px 10px;
`;

const Row = styled.div`
  margin-right: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const RateRow = styled(Row)`
  margin-right: 0px;
`;
const Title = styled.div`
  font-size: 14px;
  align-self: flex-start;
  line-height: 32px;
`;
const Red = styled.span`
  color: red;
`;

interface Props {
  isModalVisible: boolean;
  current?: Partial<ReportRegenDetail>;
  toogleModal: () => void;
  isEdit: boolean;
}

const EditModal: FC<Props> = (props) => {
  const { isModalVisible, toogleModal, current, isEdit } = props;
  const [rate, setRate] = useState(0);

  const dispatch = useAppDispatch();

  const [form] = Form.useForm<Partial<EditReportRegenDetail>>();

  const onSearchProduct = useCallback(async () => {
    const sku = form.getFieldValue("sku");
    if (sku) {
      const res = await productApi.fetchProductDetailListByFilter({ sku });
      const product = res.results[0];
      if (product) {
        setRate(product.vpcInfo?.commissionRate ?? 0);
        if (current) {
          form.setFieldsValue({
            adjustedCommissionRate: (current?.adjustedCommissionRate ?? 0) - (product.vpcInfo?.commissionRate ?? 0),
          });
        }
      }
    }
  }, [form, current]);

  useEffect(() => {
    if (current && isModalVisible) {
      form.setFieldsValue({
        salesPlanId: current.salesPlanId,
        sku: current.sku,
        adjustedCommissionRate: 0,
        affectedDate: [moment(current.affectedStartDate), moment(current.affectedEndDate)],
      });
      onSearchProduct();
    }
  }, [current, form, isModalVisible, onSearchProduct]);

  function handleOk() {
    const payload = form.getFieldsValue();
    if (current && isEdit) {
      dispatch(editReportRegen(payload));
    } else {
      dispatch(addReportRegen(payload));
    }

    form.resetFields();
    toogleModal();
  }

  function handleCancel() {
    form.resetFields();

    toogleModal();
  }

  return (
    <Modal
      visible={isModalVisible}
      width={659}
      bodyStyle={{ height: 400, padding: 0 }}
      closable={false}
      footer={false}
      maskClosable={false}
    >
      <WrapperForm<any> form={form} name="basic" onFinish={handleOk}>
        <Row>
          <Title>銷售頁方案ID(選填)</Title>
          <Form.Item name="salesplanId">
            <StyledInput />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            SKUID<Red>*</Red>
          </Title>
          <Form.Item name="sku" rules={[{ required: true, message: "" }]}>
            <StyledInput onBlur={onSearchProduct} />
          </Form.Item>
        </Row>
        <Row>
          <Title>
            抽成調整後比例<Red>*</Red>
          </Title>
          <RateRow>
            <RateText>{`${rate || "N/A"}% + `}</RateText>

            <Form.Item name="adjustedCommissionRate" rules={[{ required: true, message: "" }]}>
              <StyledRateInput type="number" />
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <RateText>{` = ${rate + Number(getFieldValue("adjustedCommissionRate")) || "N/A"}%`}</RateText>
              )}
            </Form.Item>
          </RateRow>
        </Row>
        <Row>
          <Title>
            影響訂單時間區間<Red>*</Red>
          </Title>
          <Form.Item name="affectedDate" rules={[{ required: true, message: "" }]}>
            <RangePicker style={{ width: 395 }} locale={locale} />
          </Form.Item>
        </Row>

        <ButtonContainer>
          <CancelButton type="default" onClick={handleCancel}>
            取消
          </CancelButton>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </ButtonContainer>
      </WrapperForm>
    </Modal>
  );
};

export default EditModal;
