/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactText, useEffect, useMemo, useState } from "react";
import { Button, Modal, Pagination, Select, Table } from "antd";
// import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { ExclamationCircleOutlined, PlusCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { SelectValue } from "antd/lib/select";
import { DiscountFrameTabItem, DiscountModule, TabListParams } from "@api/discountFrameApi";
import { ColumnsType } from "antd/lib/table";
import PhotoModal from "@component/UploadImageButton/PhotoModal";
import {
  toggleImportCsvPopup,
  updateSalePageListParam,
  batchDeleteSalePage,
  fetchSalePageList,
  updateSelectedSalePageIds,
  resetSalePageListParam,
  batchUpdateDiscountTabList,
} from "@redux/discountFrameSlice";
import { configState } from "@redux/configSlice";
import { useParams } from "react-router-dom";
import AddSaleModal from "./AddSaleModal";
import ImportCSV from "./ImportCSV";
import TabSearchBar from "./TabSearchBar";

const Wrapper = styled.div`
  padding-top: 20px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div`
  width: 70%;
  display: flex;
`;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const TablePageInfo = styled.div``;

const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  margin-bottom: 16px;
`;
const FrameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FrameDemoStyle = styled.img`
  width: 22px;
  height: 22px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;
const DeleteButton = styled(Button)``;

const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
`;

const HeaderButton = styled(Button)`
  margin-right: 10px;
`;

interface TableFrameItem extends DiscountFrameTabItem {
  key: number;
}

const pageSizeOptions = [20, 50, 100];

export default function SalePageContent() {
  const dispatch = useDispatch();
  const { frameId } = useParams();
  const parseFrameId = Number(frameId);

  const isFetchingList = useSelector((state: RootState) => state.discountFrameSlice.isFetching);
  const salePageListParam = useSelector((state: RootState) => state.discountFrameSlice.salePageListParam);
  const selectedSalePageIds = useSelector((state: RootState) => state.discountFrameSlice.selectedSalePageIds);
  const salePageListResult = useSelector((state: RootState) => state.discountFrameSlice.salePageList);
  const showImportCsvPopup = useSelector((state: RootState) => state.discountFrameSlice.showImportCsvPopup);
  const { webDomain } = useSelector(configState);

  const { count, results: salePageList } = salePageListResult;
  const { offset, limit } = salePageListParam;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  const [addModalState, setAddModalState] = useState(false);
  const [localFrameList, setLocalFrameList] = useState<TableFrameItem[]>([]);

  const [photoSource, setPhotoSource] = useState<string>("");

  const openImportCVS = () => {
    dispatch(toggleImportCsvPopup(true));
  };

  const fetchListWithFilter = (filter: TabListParams) => {
    dispatch(updateSalePageListParam(filter));
    dispatch(fetchSalePageList());
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...salePageListParam,
      limit: value as number,
    };
    fetchListWithFilter(filter);
  };
  const handleOnPageChange = (page: number) => {
    const filter = {
      ...salePageListParam,
      offset: (page - 1) * limit,
    };
    fetchListWithFilter(filter);
  };

  const handleOnPhotoClick = (url: string) => {
    setPhotoSource(url);
  };
  const closePhotoModal = () => {
    setPhotoSource("");
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedSalePageIds(values as number[]));
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(batchDeleteSalePage(parseFrameId)),
    });
  };
  const handleAddId = (selectedIds: number[]) => {
    const params = {
      objectIds: selectedIds,
      module: DiscountModule.SALEPAGE,
    };
    dispatch(batchUpdateDiscountTabList(params));
    setAddModalState(false);
  };
  const tableColumns: ColumnsType<TableFrameItem> = [
    {
      title: <TableNoWrap>銷售頁ID</TableNoWrap>,
      key: "objectId",
      dataIndex: "objectId",
      align: "center",
      width: 100,
      render: (value: DiscountFrameTabItem["objectId"]) => (
        <Link href={`${webDomain}/salespage/${value}`} target="_blank">
          {value}
        </Link>
      ),
    },
    {
      title: <TableNoWrap>銷售頁名稱</TableNoWrap>,
      align: "left",
      key: "pageName",
      dataIndex: "pageName",
    },
    {
      title: <TableNoWrap>原始圖片</TableNoWrap>,
      key: "originalImagePath",
      dataIndex: "originalImagePath",
      width: 150,
      align: "center",
      render: (value: DiscountFrameTabItem["originalImagePath"]) => {
        return (
          <FrameWrapper>
            <FrameDemoStyle
              src={value}
              alt=""
              onClick={() => {
                handleOnPhotoClick(value);
              }}
            />
          </FrameWrapper>
        );
      },
    },
    {
      title: <TableNoWrap>新圖片</TableNoWrap>,
      key: "compositeImagePath",
      dataIndex: "compositeImagePath",
      width: 150,
      align: "center",
      render: (value: DiscountFrameTabItem["compositeImagePath"]) => {
        return (
          <FrameWrapper>
            <FrameDemoStyle
              src={value}
              alt=""
              onClick={() => {
                handleOnPhotoClick(value);
              }}
            />
          </FrameWrapper>
        );
      },
    },
  ];
  useEffect(() => {
    dispatch(fetchSalePageList());
    return () => {
      dispatch(resetSalePageListParam());
    };
  }, [dispatch]);

  useEffect(() => {
    const tableFrameList = salePageList.map((salePage) => ({
      ...salePage,
      key: salePage.id,
    }));
    setLocalFrameList(tableFrameList);
  }, [salePageList]);

  return (
    <Wrapper>
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={() => setAddModalState(true)}>
            新增銷售頁
          </TopButton>
          <HeaderButton onClick={openImportCVS}>匯入</HeaderButton>
          <TabSearchBar
            module={DiscountModule.SALEPAGE}
            onSubmit={(filter) => fetchListWithFilter(filter)}
            onReset={() => {
              dispatch(resetSalePageListParam());
              dispatch(fetchSalePageList());
            }}
          />
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      <TableContainer>
        <Table
          dataSource={localFrameList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSalePageIds,
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
        />
      </TableContainer>
      {photoSource && <PhotoModal source={photoSource} onClose={closePhotoModal} />}
      <Footer>
        <DeleteButton onClick={handleOnBatchDelete} disabled={selectedSalePageIds.length === 0}>
          刪除
        </DeleteButton>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
      {showImportCsvPopup && (
        <ImportCSV
          module={DiscountModule.SALEPAGE}
          templatePath="admin/template/csv/sales_page_id_frame_import_sample.xlsx"
        />
      )}
      {addModalState && (
        <AddSaleModal submit={handleAddId} close={() => setAddModalState(false)} module={DiscountModule.SALEPAGE} />
      )}
    </Wrapper>
  );
}
