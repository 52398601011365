import React, { FC } from "react";
import styled from "styled-components";
import EmptyDataImg from "@assets/EmptyData.png";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
const Icon = styled.img`
  width: 120px;
  height: auto;
`;

const EmptyContent: FC = () => (
  <Wrapper>
    <Icon src={EmptyDataImg} />
  </Wrapper>
);

export default EmptyContent;
