import { ContractMode, PurchaseLines } from "@api/utils/normalizeWarehouse";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import {
  clearPurchaseVpc,
  fetchCreatePurchseLine,
  fetchPurchaseVpc,
  fetchUpdatePurchaseLine,
} from "@redux/warehouseSlice";
import MathRound from "@utils/MathRound";
import { Button, Form, Input, Row, DatePicker } from "antd";
import "moment/locale/zh-tw";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import moment from "moment";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 650px;
  padding: 30px 28px 60px 32px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
`;

const Notice = styled.span`
  margin-bottom: 24px;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.25);
  ${fontStyle("14px", "22px")};
`;

const CustomRow = styled(Row)`
  .ant-form-item-label {
    min-width: 123px;
    text-align: left;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;

const CustomInput = styled(Input)<{ hide?: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "block")};
  width: 246px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  right: 28px;
  bottom: 0;
`;

const Remarks = styled.span<{ emphasize?: boolean }>`
  position: absolute;
  left: 32px;
  bottom: 28px;
  color: ${({ emphasize, theme }) => (emphasize ? "#EC6922" : theme.colorNeutral600)};
  ${fontStyle("14px", "16px")};
`;

const ConsignmentPrice = styled.div`
  margin-bottom: 24px;
  ${fontStyle("14px", "22px")};
`;

type Props = {
  purchaseLine?: PurchaseLines;
  close: () => void;
};

const warehouseSlice = (state: RootState) => state.warehouseSlice;

export default function IntoWarehouseEditProduct(props: Props) {
  const { purchaseLine, close } = props;

  const { warehouseId } = useParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { purchaseInfo, purchaseVpc, purchaseVpcIsContract, purchaseLineRefresh } = useSelector(warehouseSlice);
  const [initialize, setInitialize] = useState(false);
  // antd form 不能即時取得值和 render 所以新增一個 state 去更新畫面的 remark
  const [backComputeData, setBackComputeData] = useState({
    sku: purchaseLine?.sku || "",
    requestQty: purchaseLine?.requestQty || 0,
    purchasePrice: purchaseLine?.purchasePrice || 0,
    commissionRate: purchaseLine?.commissionRate || 0,
  });

  const remark = useMemo(() => {
    switch (purchaseVpc?.contractMode) {
      case ContractMode.M_DOMESTIC_BUYOUT:
        return (
          <Remarks>{`註: 進倉金額  $${backComputeData.purchasePrice}*${
            Number.isNaN(backComputeData.requestQty) ? " " : backComputeData.requestQty
          }=${
            Number.isNaN(backComputeData.requestQty) ? "" : backComputeData.purchasePrice * backComputeData.requestQty
          }`}</Remarks>
        );
      case ContractMode.M_FOREIGN_BUYOUT:
        return (
          <Remarks>{`註: 進倉金額  $${backComputeData.purchasePrice}*${
            Number.isNaN(backComputeData.requestQty) ? " " : backComputeData.requestQty
          }=${
            Number.isNaN(backComputeData.requestQty) ? "" : backComputeData.purchasePrice * backComputeData.requestQty
          }`}</Remarks>
        );
      case ContractMode.M_CONSIGNMENT:
        return (
          <Remarks>
            {`註: 進倉金額  $${purchaseVpc?.price}*(100-${purchaseVpc?.commissionRate})/100*${
              Number.isNaN(backComputeData.requestQty) ? " " : backComputeData.requestQty
            }=$${
              Number.isNaN(backComputeData.requestQty)
                ? ""
                : ((purchaseVpc?.price * (100 - purchaseVpc?.commissionRate)) / 100) * backComputeData.requestQty
            }`}
          </Remarks>
        );
      case ContractMode.M_TRANSFER:
        return <Remarks emphasize>此為轉單商品，無法加入進倉單</Remarks>;
      default:
        if (!purchaseVpcIsContract) return <Remarks emphasize>輸入之SKU與此廠商無合作關係</Remarks>;
        if (!backComputeData.sku) return null;
        return null;
    }
  }, [purchaseVpc, backComputeData, purchaseVpcIsContract]);

  const fetchSKUInfo = useDebounce((value: string) => {
    setBackComputeData({
      ...backComputeData,
      sku: value,
    });
    if (value) {
      dispatch(fetchPurchaseVpc({ vendor: purchaseInfo!.vendor.id, sku: value }));
    } else {
      dispatch(clearPurchaseVpc());
      form.resetFields();
    }
  }, 1000);

  const closePopup = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  const onSubmit = async (values: any) => {
    if (!purchaseVpc) return;
    const { expiredDate, canAcceptExpirationDate } = form.getFieldsValue();
    const payload = {
      purchase: parseInt(warehouseId, 10),
      requestQty: parseInt(values.requestQty, 10),
      purchasePrice: MathRound(values.purchasePrice, 2),
      vpc: purchaseVpc!.id,
      expiredDate: expiredDate && expiredDate.format("YYYY-MM-DD"),
      canAcceptExpirationDate: canAcceptExpirationDate && canAcceptExpirationDate.format("YYYY-MM-DD"),
    };

    if (purchaseLine) {
      dispatch(fetchUpdatePurchaseLine({ ...payload, purchaseLineId: purchaseLine.id }));
    } else {
      dispatch(fetchCreatePurchseLine(payload));
    }
  };
  const computeCanAcceptExpirationDate = useCallback(
    (expiredDate: moment.Moment | null) => {
      if (!purchaseVpc || !expiredDate) return null;
      let acceptRatio;
      switch (purchaseVpc.contractMode) {
        case ContractMode.M_DOMESTIC_BUYOUT: // 國內
          acceptRatio = 2 / 3;
          break;
        case ContractMode.M_FOREIGN_BUYOUT: // 國外
          acceptRatio = 1 / 2;
          break;
        default:
          acceptRatio = 1 / 2;
      }
      return moment(expiredDate).subtract(purchaseVpc.shelfLife * acceptRatio, "days");
    },
    [purchaseVpc],
  );

  const onExpiredDateChange = (expiredDate: moment.Moment | null) => {
    const canAcceptExpirationDate = computeCanAcceptExpirationDate(expiredDate);
    if (canAcceptExpirationDate) form.setFieldsValue({ canAcceptExpirationDate });
  };

  useEffect(() => {
    const values = form.getFieldsValue();
    // 新增
    if (!purchaseLine && purchaseVpc) {
      const { expiredDate } = values;
      const formData = {
        ...values,
        productName: purchaseVpc.productName,
        purchasePrice: purchaseVpc.cost,
      };
      const computeData = {
        purchasePrice: purchaseVpc.cost,
        commissionRate: purchaseVpc.commissionRate || 0,
        contractMode: purchaseVpc.contractMode,
        requestQty: Number(values.requestQty),
      };
      switch (purchaseVpc.contractMode) {
        case ContractMode.M_DOMESTIC_BUYOUT:
          break;
        case ContractMode.M_FOREIGN_BUYOUT:
          break;
        default: {
          const purchasePrice = (purchaseVpc.price * (100 - purchaseVpc?.commissionRate)) / 100;
          formData.purchasePrice = purchasePrice;
          computeData.purchasePrice = purchasePrice;
        }
      }
      const canAcceptExpirationDate = expiredDate && computeCanAcceptExpirationDate(expiredDate);
      form.setFieldsValue({ ...formData, canAcceptExpirationDate });

      setBackComputeData((prev) => ({
        ...prev,
        ...computeData,
      }));
    }

    // 編輯
    if (purchaseLine && !purchaseVpc && !initialize) {
      dispatch(fetchPurchaseVpc({ vendor: purchaseInfo!.vendor.id, sku: purchaseLine!.sku }));
      setInitialize(true);
      return;
    }

    if (purchaseLine && purchaseVpc) {
      const { expiredDate } = values;
      const expiredDateNow = expiredDate || moment(purchaseLine.expiredDate);
      const canAcceptExpirationDate = computeCanAcceptExpirationDate(expiredDate);
      const formData = {
        ...values,
        sku: values.sku || purchaseLine.sku,
        productName: purchaseLine.productName,
        purchasePrice: purchaseVpc.cost,
        requestQty: purchaseLine.requestQty,
        expiredDate: expiredDateNow,
        canAcceptExpirationDate,
      };
      switch (purchaseVpc.contractMode) {
        case ContractMode.M_DOMESTIC_BUYOUT:
          form.setFieldsValue({
            ...values,
            productName: purchaseVpc?.productName,
            purchasePrice: purchaseVpc?.cost,
          });
          break;
        case ContractMode.M_FOREIGN_BUYOUT:
          break;
        default: {
          const purchasePrice = (purchaseVpc?.price * (100 - purchaseVpc?.commissionRate)) / 100;
          formData.purchasePrice = purchasePrice;
          setBackComputeData((prev) => ({
            ...prev,
            purchasePrice: (purchaseVpc?.price * (100 - purchaseVpc?.commissionRate)) / 100,
          }));
        }
      }
      form.setFieldsValue(formData);
    }
  }, [computeCanAcceptExpirationDate, dispatch, form, initialize, purchaseInfo, purchaseLine, purchaseVpc]);

  useEffect(() => {
    if (purchaseLineRefresh) {
      closePopup();
    }
  }, [closePopup, purchaseLineRefresh]);

  useEffect(() => {
    // 編輯再 setFieldsValue 一次因為點開 popup 先去拿 purchaseLine 裡的 canAcceptExpirationDate
    if (purchaseLine && purchaseVpc) {
      if (purchaseLine?.canAcceptExpirationDate) {
        form.setFieldsValue({
          canAcceptExpirationDate: moment(purchaseLine?.canAcceptExpirationDate),
        });
      } else {
        form.setFieldsValue(null);
      }
    }
  }, [form, purchaseLine, purchaseLine?.canAcceptExpirationDate, purchaseVpc]);

  return (
    <PopupBackground close={closePopup} fixed>
      <Wrapper colon={false} form={form} labelAlign="left" onFinish={onSubmit}>
        <Form.Item
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 10 }}
          label="sku"
          name="sku"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input onChange={(e) => fetchSKUInfo(e.target.value)} />
        </Form.Item>
        <CustomRow align="middle">
          <Form.Item label="商品名稱" name="productName">
            <CustomInput disabled />
          </Form.Item>
          <Notice>*輸入SKU後自動帶入</Notice>
        </CustomRow>
        <Form.Item
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 10 }}
          label="申請進倉數量"
          name="requestQty"
          rules={[{ required: true, message: "必填" }]}
        >
          <Input
            disabled={!!purchaseInfo?.matoLink}
            onChange={(e) =>
              setBackComputeData((prev) => ({
                ...prev,
                requestQty: parseInt(e.target.value, 10),
              }))
            }
          />
        </Form.Item>
        <CustomRow align="middle">
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 10 }}
            label="進價"
            name="purchasePrice"
            rules={[{ required: true, message: "必填" }]}
          >
            <CustomInput
              hide={purchaseVpc?.contractMode === ContractMode.M_CONSIGNMENT}
              onChange={(e) =>
                setBackComputeData({
                  ...backComputeData,
                  purchasePrice: parseFloat(e.target.value),
                })
              }
            />
          </Form.Item>
          {purchaseVpc?.contractMode === ContractMode.M_CONSIGNMENT && (
            <ConsignmentPrice>{backComputeData.purchasePrice}</ConsignmentPrice>
          )}
        </CustomRow>
        <CustomRow>
          <Form.Item
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 10 }}
            label="有效期限"
            name="expiredDate"
            rules={[{ required: true, message: "必填" }]}
          >
            <DatePicker onChange={onExpiredDateChange} placeholder="請選擇日期" style={{ width: "188px" }} />
          </Form.Item>
          <Form.Item labelCol={{ span: 5 }} wrapperCol={{ span: 10 }} label="允收日" name="canAcceptExpirationDate">
            <DatePicker placeholder="請選擇日期" style={{ width: "188px" }} />
          </Form.Item>
        </CustomRow>
        {remark}
        <ButtonWrapper>
          <Form.Item>
            <CustomButton onClick={closePopup}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Form.Item>
        </ButtonWrapper>
      </Wrapper>
    </PopupBackground>
  );
}
