import processingApplicationApi, {
  ProcessingAppList,
  ProcessingAppListItem,
  ProcessingAppListParams,
  ProcessingLineCreateItem,
  ProcessingLineItemInfo,
  ProcessingLineType,
  ProductStockDetail,
} from "@api/tryall/ProcessingApplicationApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "./notifySlice";

interface IState {
  isFetching: boolean;
  listResult: ProcessingAppList;
  details: ProcessingAppListItem | undefined;
  listParam: ProcessingAppListParams;
  processingLineList: {
    [ProcessingLineType.TARGET]: ProcessingLineItemInfo[];
    [ProcessingLineType.SOURCE]: ProcessingLineItemInfo[];
  };
  processingLineItemInfo: ProcessingLineItemInfo | undefined;
  isLineListFetching: boolean;
  productStockDetail: ProductStockDetail | undefined;
  isNoStockChecked: string;
}

const initialState: IState = {
  isFetching: false,
  isLineListFetching: false,
  listResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  details: undefined,
  listParam: {
    limit: 20,
    offset: 0,
  },
  processingLineList: {
    [ProcessingLineType.TARGET]: [],
    [ProcessingLineType.SOURCE]: [],
  },
  processingLineItemInfo: undefined,
  productStockDetail: undefined,
  isNoStockChecked: "",
};

export const fetchProcessingAppList = createAsyncThunk(
  "ProcessingApplicationSlice/fetchProcessingAppList",
  async (_, thunkApi) => {
    const {
      ProcessingApplicationSlice: { listParam },
    } = thunkApi.getState() as RootState;
    try {
      const response = await processingApplicationApi.fetchProcessingAppList(listParam);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchProcessingAppDetail = createAsyncThunk(
  "ProcessingApplicationSlice/fetchProcessingAppDetail",
  async (processingAppId: number, thunkApi) => {
    try {
      const response = await processingApplicationApi.fetchProcessingAppDetail(processingAppId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const createProcessingApp = createAsyncThunk(
  "ProcessingApplicationSlice/createProcessingApp",
  async (payload: ProcessingAppListItem, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await processingApplicationApi.createProcessingApp(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchProcessingAppList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const updateProcessingApp = createAsyncThunk(
  "ProcessingApplicationSlice/updateProcessingApp",
  async (payload: ProcessingAppListItem, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { id, ...restPayload } = payload;
      const response = await processingApplicationApi.updateProcessingApp(id, restPayload);
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const cancelProcessingApp = createAsyncThunk(
  "ProcessingApplicationSlice/cancelProcessingApp",
  async (processingAppId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await processingApplicationApi.cancelProcessingApp(processingAppId);
      message.success("作廢成功");
      thunkApi.dispatch(fetchProcessingAppList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const fetchProcessingLineList = createAsyncThunk(
  "ProcessingApplicationSlice/fetchProcessingLineList",
  async (processingAppId: number, thunkApi) => {
    try {
      const response = await processingApplicationApi.fetchProcessingLineList(processingAppId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const addProcessingLineItem = createAsyncThunk(
  "ProcessingApplicationSlice/addProcessingLineItem",
  async (payload: { processingAppId: number; type: ProcessingLineType; info: ProcessingLineCreateItem }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { processingAppId, type, info } = payload;
      const response = await processingApplicationApi.addProcessingLineItem(processingAppId, type, info);
      message.success("新增成功");
      thunkApi.dispatch(fetchProcessingLineList(processingAppId));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const updateProcessingLineItem = createAsyncThunk(
  "ProcessingApplicationSlice/updateProcessingLineItem",
  async (
    payload: { processingAppId: number; id: number; type: ProcessingLineType; info: ProcessingLineCreateItem },
    thunkApi,
  ) => {
    try {
      thunkApi.dispatch(showLoading());
      const { processingAppId, id, type, info } = payload;
      const response = await processingApplicationApi.updateProcessingLineItem(processingAppId, id, type, info);
      message.success("更新成功");
      thunkApi.dispatch(fetchProcessingLineList(processingAppId));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const deleteProcessingLineItem = createAsyncThunk(
  "ProcessingApplicationSlice/deleteProcessingLineItem",
  async (params: { processingAppId: number; itemId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { processingAppId, itemId } = params;
      await processingApplicationApi.deleteProcessingLineItem(processingAppId, itemId);
      message.success("刪除成功");
      thunkApi.dispatch(fetchProcessingLineList(processingAppId));
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const uploadToWMSSystem = createAsyncThunk(
  "ProcessingApplicationSlice/uploadToWMSSystem",
  async (processingAppId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await processingApplicationApi.uploadToWMSSystem(processingAppId);
      thunkApi.dispatch(fetchProcessingAppDetail(processingAppId));
      message.success("上傳成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const fetchProductStockDetail = createAsyncThunk(
  "ProcessingApplicationSlice/fetchProductStockDetail",
  async (no: string, thunkApi) => {
    try {
      const response = await processingApplicationApi.fetchProductStockDetail(no);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const ProcessingApplicationSlice = createSlice({
  name: "ProcessingApplication",
  initialState,
  reducers: {
    resetProcessingSlice: (state) => initialState,
    updateProcessingListParams: (state, action) => {
      state.listParam = action.payload;
    },
    resetProcessingListParams: (state) => {
      state.listParam = initialState.listParam;
    },
    resetProductStockDetail: (state) => {
      state.productStockDetail = initialState.productStockDetail;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProcessingAppList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProcessingAppList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchProcessingAppList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.listResult = action.payload;
    });
    builder.addCase(fetchProcessingAppDetail.fulfilled, (state, action) => {
      state.details = action.payload;
    });
    builder.addCase(updateProcessingApp.fulfilled, (state, action) => {
      state.details = action.payload;
    });
    builder.addCase(fetchProcessingLineList.pending, (state, action) => {
      state.isLineListFetching = true;
    });
    builder.addCase(fetchProcessingLineList.rejected, (state, action) => {
      state.isLineListFetching = false;
    });
    builder.addCase(fetchProcessingLineList.fulfilled, (state, action) => {
      state.isLineListFetching = false;
      state.processingLineList = action.payload;
    });
    builder.addCase(fetchProductStockDetail.pending, (state, action) => {
      state.isFetching = true;
      state.isNoStockChecked = "";
    });
    builder.addCase(fetchProductStockDetail.rejected, (state, action) => {
      state.isFetching = false;
      state.isNoStockChecked = "查無對應商品，請確認商品品號碼是否正確";
    });
    builder.addCase(fetchProductStockDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isNoStockChecked = "";
      state.productStockDetail = action.payload;
    });
  },
});

export const {
  resetProcessingSlice,
  updateProcessingListParams,
  resetProcessingListParams,
  resetProductStockDetail,
} = ProcessingApplicationSlice.actions;
export const ProcessingApplicationState = (state: RootState) => state.ProcessingApplicationSlice;
export default ProcessingApplicationSlice.reducer;
