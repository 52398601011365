import React, { ChangeEvent, useRef, useState } from "react";
import { Button, Form, Row, Select, Input, message } from "antd";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";

import { setProductFilter } from "@redux/brandOperation/productSlice";

import styled from "styled-components";

const InputWidth = styled(Input)`
  width: 320px;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
  margin-bottom: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const Filter = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const [skuSet, setSkuSet] = useState<Set<string>>(new Set());
  const needResetData = useRef<boolean>(false);
  const { pruductFilter } = useSelector((state: RootState) => state.brandOperationProductSlice);

  const handleSubmit = (value: any) => {
    dispatch(setProductFilter({ ...pruductFilter, skus: value.skus.toLocaleString(), nameQ: value.name }));
  };

  const handelReset = () => {
    form.resetFields();
    dispatch(setProductFilter({ limit: 20, offset: 0 }));
  };

  const onDeselect = (e: any) => {
    const newSet = new Set(skuSet);
    if (needResetData.current) {
      message.error(`${e}已建立`);
      needResetData.current = false;
      newSet.add(e);
      setSkuSet(newSet);
    }
  };
  const handleOnSkuChange = (e: any) => {
    setSkuSet(e);
  };

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    dispatch(setProductFilter({ ...pruductFilter, nameQ: value }));
  };

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{ approvalStatus: -1 }} onFinish={handleSubmit}>
      <Row>
        <Form.Item label="商品條碼" name="skus">
          <Select
            placeholder="可輸入多個條碼"
            allowClear
            value={Array.from(skuSet)}
            mode="tags"
            style={{ width: "320px" }}
            onDeselect={onDeselect}
            onChange={handleOnSkuChange}
            onInputKeyDown={(e) => {
              if (e.key === "Enter") {
                needResetData.current = true;
              }
            }}
          />
        </Form.Item>
      </Row>
      <Row>
        <Form.Item label="商品名稱" name="name">
          <InputWidth onChange={handleOnChange} />
        </Form.Item>
      </Row>
      <Row justify="end">
        <CustomButton onClick={handelReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
};

export default Filter;
