import { Tabs } from "antd";
import React from "react";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import VendorInfo from "./VendorInfo";

const { TabPane } = Tabs;

const StyledSection = styled.section`
  margin: 12px 16px 162px 12px;
  padding: 13px 22px;
  border: 1px solid #e5e5e5;
`;

export default function NewVendorPage() {
  return (
    <StyledSection>
      <PageTitle title="廠商資訊 - 新增" />
      <Tabs defaultActiveKey="1" type="card" size="small">
        <TabPane tab="廠商資訊" key="1">
          <VendorInfo
            vendorDetail={{
              vendorId: -1,
              bdOwnerId: -1,
              vendorName: "",
              vendorPhone: "",
              vendorUbn: "",
              accountName: "",
              accountNumber: "",
              bankName: "",
              branchName: "",
              swiftCode: "",
              currencyId: 1,
              payCondition: "",
              reisterAddr: "",
              buinessAddr: "",
            }}
            vendorId="-1"
            isNewVendor
          />
        </TabPane>
      </Tabs>
    </StyledSection>
  );
}
