import { fetchMarginBalanceList, vendorState } from "@redux/vendorSlice";
import { Table } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 56px 36px 0 37px;
`;
const Footer = styled.div`
  width: 100%;
  background: #fafafa;
  display: flex;
  align-items: center;
  padding: 16px;
`;
const TotalText = styled.div`
  font-size: 16px;
  font-weight: bold;
  width: 51%;
`;
const TotalBalance = styled(TotalText)`
  font-size: 16px;
  font-weight: bold;
  color: #f5222d;
  width: 49%;
`;

export default function MarginBalanceList() {
  const dispatch = useDispatch();
  const { marinBalanceListResult } = useSelector(vendorState);

  const columns = [
    {
      title: "廠商名稱",
      dataIndex: "name",
    },
    {
      title: "保證金額餘額",
      dataIndex: "depositBalance",
    },
  ];

  useEffect(() => {
    dispatch(fetchMarginBalanceList());
  }, [dispatch]);

  return (
    <Wrapper>
      <Table
        columns={columns}
        dataSource={marinBalanceListResult?.summaryList}
        pagination={false}
        scroll={{ y: 450 }}
      />
      <Footer>
        <TotalText>合計</TotalText>
        <TotalBalance>{marinBalanceListResult?.balanceSum}</TotalBalance>
      </Footer>
    </Wrapper>
  );
}
