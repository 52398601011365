/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  background: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

const Inner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

interface PropsState {
  children: React.ReactNode;
  close: () => void;
  fixed?: boolean;
}

export default function PopupBackground(props: PropsState) {
  const { children, close, fixed } = props;

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const stopPropagation = (e: React.FormEvent<EventTarget>) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  return (
    <Wrapper
      onClick={() => {
        if (!fixed) close();
      }}
    >
      <Inner onClick={(e) => stopPropagation(e)}>{children}</Inner>
    </Wrapper>
  );
}
