import systemApi, {
  ShippingFeeListResult,
  CreateShippingFeeListParam,
  DeleteShippingFeeListParam,
  UpdateShippingFeeListParam,
  ShippingFeeListParam,
} from "@api/shippingFeeApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "@redux/rootReducer";

interface IState {
  isFetching: boolean;
  shippingFeeListResult: ShippingFeeListResult;
  selectedProductIds: number[];
  shippingFeeListFilter: ShippingFeeListParam;
}
export const initialState: IState = {
  isFetching: false,
  shippingFeeListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  selectedProductIds: [],
  shippingFeeListFilter: {
    limit: 20,
    offset: 0,
  },
};
export const fetchShippingFeeList = createAsyncThunk("shippingFee/fetchShippingFeeList", async (_, thunkApi) => {
  const {
    shippingFee: { shippingFeeListFilter },
  } = thunkApi.getState() as RootState;
  const response = await systemApi.fetchShippingFeeList(shippingFeeListFilter);
  thunkApi.dispatch(updateSelectedProductIds([]));
  return response;
});
export const createShippingFeeItem = createAsyncThunk(
  "shippingFee/createShippingFeeItem",
  async (params: CreateShippingFeeListParam, thunkApi) => {
    const response = await systemApi.createShippingFeeItem(params);
    message.success("已儲存");
    thunkApi.dispatch(fetchShippingFeeList());
    return response;
  },
);
export const updateShippingFeeItem = createAsyncThunk(
  "shippingFee/updateShippingFeeItem",
  async (params: UpdateShippingFeeListParam, thunkApi) => {
    const response = await systemApi.updateShippingFeeItem(params);
    message.success("已儲存");
    thunkApi.dispatch(fetchShippingFeeList());
    return response;
  },
);
export const deleteShippingFeeItem = createAsyncThunk(
  "shippingFee/deleteShippingFeeItem",
  async (params: DeleteShippingFeeListParam, thunkApi) => {
    const response = await systemApi.deleteShippingFeeItem(params);
    message.success("已刪除");
    thunkApi.dispatch(fetchShippingFeeList());
    return response;
  },
);

const shippingFeeSlice = createSlice({
  name: "shippingFee",
  initialState,
  reducers: {
    reset: () => initialState,

    updateSelectedProductIds: (state, action: PayloadAction<number[]>) => {
      state.selectedProductIds = action.payload;
    },
    updateShippingFeeListFilter: (state, action: PayloadAction<ShippingFeeListParam>) => {
      state.shippingFeeListFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchShippingFeeList.fulfilled, (state, action) => {
      state.shippingFeeListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createShippingFeeItem.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(createShippingFeeItem.fulfilled, (state, action) => {
      state.shippingFeeListResult = action.payload;
      state.isFetching = false;
    });
  },
});
export const { updateSelectedProductIds, updateShippingFeeListFilter } = shippingFeeSlice.actions;
export default shippingFeeSlice.reducer;
