/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface BrandPageListConfig {
  limit: number;
  offset: number;
  name?: string;
  id?: number;
}
export interface UpdateBrandPagePayload {
  brandPageId: number;
  metaTitle: string;
  metaKeywords: string;
  metaDescription: string;
  defaultSortMethod: string;
  sortMethods: { name: string; isActive: boolean }[];
  iconUrl: string;
  iconAlt: string;
  imageUrl: string;
  imageAlt: string;
}
export interface BrandPageList {
  count: number;
  next: string | null;
  previous: string | null;
  results: { id: number; name: string; isActive: boolean }[];
}
export interface BrandPageDetail {
  name: string;
  pageInfo: {
    id: number;
    title: string;
    description: string | null;
    backgroundColor: string;
    image?: {
      alt: string;
      id: number;
      image: string;
      image_type: string | null;
      is_active: boolean;
      is_show: boolean;
      media_type: number;
      name: string;
      rank: number;
      url: string;
    };
    metaTitle: string | null;
    metaKeywords: string | null;
    metaDescription: string | null;
    sortMethods: {
      id: number;
      name: string;
      isDescend: boolean;
      isDefault: boolean;
      isActive: boolean;
    }[];
  };
  iconId: number | null;
  icon: string | null;
  iconAlt: string | null;
  enableMultipleBrand: boolean;
}
export interface BrandSalesPageConfig {
  offset: number;
  limit: number;
  salesPageId?: number;
  sku?: string;
  salesPageName?: string;
  tagId?: number;
  salesPageIsActive?: boolean;
  ordering?: "rank" | "-rank";
}
export interface BrandSalesPageResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: BrandSalesPageList[];
}
export interface BrandSalesPageList {
  salesPageId: number;
  salesPageName: string;
  salesPageIsActive: boolean;
  rank: number;
  salesPagePrice: number;
  skus: string[];
  brand: string;
}
export interface BrandSalesPageRankPayload {
  brandPageId: number;
  enableMultipleBrand: boolean;
  salesPageRankData: { salesPage: number; rank: number }[];
}

export interface SalePageShowInBrandPagePayload {
  salesPageIds: number[];
  isShow: boolean | undefined;
  brandId: number;
}

interface BrandPageAPI {
  fetchBrandPageList: (config: BrandPageListConfig) => Promise<BrandPageList>;
  fetchBrandPageDetail: (brandPageId: number) => Promise<BrandPageDetail>;
  fetchUpdateBrandPage: (payload: UpdateBrandPagePayload) => Promise<BrandPageDetail>;
  fetchBrandSalesPage: (config: BrandSalesPageConfig) => Promise<BrandSalesPageResult>;
  fetchUpdateBrandSalesPageRank: (payload: BrandSalesPageRankPayload) => Promise<void>;
  fetchSalePageShowInBrandPage: (payload: SalePageShowInBrandPagePayload) => void;
}

export enum BrandTypeCode {
  T_OTHER = 0,
  T_BRAND = 1,
  T_PROMOTION = 2,
  T_SEO = 3,
  T_ATTR = 4,
}

const apiClient = new CoreAPI();

const BrandPageApi: BrandPageAPI = {
  fetchBrandPageList: async (config) => {
    const response = await apiClient.getData("/manage/tag/tag-pages/", {
      limit: config.limit,
      offset: config.offset,
      name_q: config.name,
      id: config.id,
      type_code: BrandTypeCode.T_BRAND,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchBrandPageDetail: async (brandPageId) => {
    const response = await apiClient.getData(`/manage/tag/tag-pages/${brandPageId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateBrandPage: async (payload) => {
    const { brandPageId, ...otherPayload } = payload;

    const iconDirPrefix =
      payload.iconUrl && !payload.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "topic/category/icon"
        : undefined;
    const iconFilePath =
      payload.iconUrl && !payload.iconUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? payload.iconUrl
        : undefined;
    const imageDirPrefix =
      payload.imageUrl && !payload.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "topic/category/image"
        : undefined;
    const imageFilePath =
      payload.imageUrl && !payload.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? payload.imageUrl
        : undefined;

    const response = await apiClient.patchData(
      `/manage/tag/tag-pages/${brandPageId}/`,
      transformCamelToSnake({
        ...otherPayload,
        icon_dir_prefix: iconDirPrefix,
        icon_file_path: iconFilePath,
        image_dir_prefix: imageDirPrefix,
        image_file_path: imageFilePath,
      }),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchBrandSalesPage: async (config) => {
    const response = await apiClient.getData(
      "/manage/tag/tag-page-relation/",
      transformCamelToSnake({ ...config, salesPageIsActive: config.salesPageIsActive ? "True" : undefined }),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchUpdateBrandSalesPageRank: async (payload) => {
    const { brandPageId, ...otherPayload } = payload;
    await apiClient.putData(`/manage/tag/tag-page-relation/${brandPageId}/ranks/`, transformCamelToSnake(otherPayload));
  },

  fetchSalePageShowInBrandPage: async (payload) => {
    const params = {
      sales_page_ids: payload.salesPageIds,
      is_show: payload.isShow,
    };
    await apiClient.patchData(`/manage/tag/tag-page-relation/${payload.brandId}/show/`, transformCamelToSnake(params));
  },
};

export default BrandPageApi;
