export enum LogisticHistories {
  REFUND_SUCCESS = "退款成功",
  REFUND_PENDING = "等待退款",
  RETURN_TO_WAREHOUSE_COMPLETE = "退件驗收完成",
  RETURN_TO_WAREHOUSE = "退件至倉庫，驗收中",
  EXCHANGE_REJECT = "換貨申請失敗，請洽客服",
  EXCHANGE_APPROVED = "換貨申請成功",
  EXCHANGE_APPLY = "收到換貨申請",
  RETURN_REJECT = "退貨申請失敗，請洽客服",
  RETURN_APPROVED = "退貨申請成功",
  RETURN_APPLY = "收到退貨申請",
  MISSING_REJECT = "補貨申請失敗，請洽客服",
  MISSING_APPROVED = "補貨申請成功",
  MISSING_APPLY = "收到補貨申請",
  SUSPEND = "訂單異常",
  CANCELED = "取消訂單",
  WAITING_TO_DEPLOY = "收到訂單",
  DEPLOY_SUCCESS = "已拋單",
  PICKING = "揀貨處理中",
  OUT_OF_STOCK = "商品缺貨，請等候信件通知",
  SHIPPING = "配送中",
  SHIPPING_FAILED = "配送異常",
  SHIPPING_REJECT = "包裹逾期未取退回中",
  ARRIVED = "已到達門市",
  PICKED_UP = "已取件",
  FINISH = "訂單完成",
}

export enum LogisticHistoriesNumber {
  REFUND_SUCCESS = -70,
  REFUND_PENDING = -60,
  RETURN_TO_WAREHOUSE_COMPLETE = -50,
  RETURN_TO_WAREHOUSE = -40,
  EXCHANGE_REJECT = -30,
  EXCHANGE_APPROVED = -20,
  EXCHANGE_APPLY = -10,
  RETURN_REJECT = -31,
  RETURN_APPROVED = -21,
  RETURN_APPLY = -11,
  MISSING_REJECT = -32,
  MISSING_APPROVED = -22,
  MISSING_APPLY = -12,
  SUSPEND = -1,
  CANCELED = 0,
  WAITING_TO_DEPLOY = 10,
  DEPLOY_SUCCESS = 11,
  PICKING = 20,
  OUT_OF_STOCK = 30,
  SHIPPING = 40,
  SHIPPING_FAILED = 50,
  SHIPPING_REJECT = 60,
  ARRIVED = 70,
  PICKED_UP = 80,
  FINISH = 90,
}

export const logisticHistoryMap: { [key: string]: string } = {
  "-70": "退款成功",
  "-60": "等待退款",
  "-50": "退件驗收完成",
  "-40": "退件至倉庫，驗收中",
  "-30": "換貨申請失敗，請洽客服",
  "-20": "換貨申請成功",
  "-10": "收到換貨申請",
  "-31": "退貨申請失敗，請洽客服",
  "-21": "退貨申請成功",
  "-11": "收到退貨申請",
  "-32": "補貨申請失敗，請洽客服",
  "-22": "補貨申請成功",
  "-12": "收到補貨申請",
  "-1": "訂單異常",
  "0": "取消訂單",
  "10": "收到訂單",
  "11": "已拋單",
  "15": "揀貨處理中",
  "20": "揀貨處理中",
  "30": "商品缺貨，請等候信件通知",
  "40": "配送中",
  "50": "配送異常",
  "60": "包裹逾期未取退回中",
  "70": "已到達門市",
  "80": "已取件",
  "90": "訂單完成",
};

export const getStatusWithoutUnusual = (status: LogisticHistoriesNumber) => {
  // 補退貨狀態不會顯示
  if (status < -1) {
    return LogisticHistories.FINISH;
  }
  return logisticHistoryMap[status];
};
