import dashboardApi, {
  CategorySalesDashboard,
  ChartParams,
  ChartResultType,
  DashboardParams,
  GrossMarginDashboard,
  GroupByValue,
  MemberDistribution,
  MemberOptionValue,
  MemberRevenueChart,
  MemberRevenueChartParams,
  MemberRevenueDashboard,
  MemberRevenueDashboardParams,
  NewMemberDashboard,
  OrderStatusChartParams,
  OrderStatusDashboard,
  OrderStatusDashboardParams,
  RevenueDashboard,
  SalesRankItem,
  SalesRankOrderingValue,
  ShipmentProgress,
  ShipmentProgressOrderListParams,
  ShipmentProgressOrderListResult,
  VipFinanceDashboard,
  VipMemberStatusDashboard,
  WarehouseDashboard,
} from "@api/dashboardApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

interface IState {
  dashboardActiveTab: DashboardTime;
  dashboardParams: DashboardParams;

  isFetchingRevenueDashboard: boolean;
  revenueDashboard: RevenueDashboard;
  isFetchingGrossMarginDashboard: boolean;
  grossMarginDashboard: GrossMarginDashboard;
  isFetchingCategorySalesDashboard: boolean;
  categorySalesDashboard: CategorySalesDashboard;
  isFetchingOrderCancelDashboard: boolean;
  orderCancelDashboard: OrderStatusDashboard;
  isFetchingOrderReturnDashboard: boolean;
  orderReturnDashboard: OrderStatusDashboard;
  isFetchingMemberDistribution: boolean;
  memberDistribution: MemberDistribution;
  isFetchingWarehouseDashboard: boolean;
  warehouseDashboard: WarehouseDashboard;
  isFetchingShipmentProgress: boolean;
  shipmentProgress: ShipmentProgress;
  isFetchingNewMemberDashboard: boolean;
  newMemberDashboard: NewMemberDashboard;
  isFetchingNewMemberRevenueDashboard: boolean;
  newMemberRevenueDashboard: MemberRevenueDashboard;
  isFetchingOldMemberRevenueDashboard: boolean;
  oldMemberRevenueDashboard: MemberRevenueDashboard;
  isFetchingVipMemberStatusDashboard: boolean;
  vipMemberStatusDashboard: VipMemberStatusDashboard;
  isFetchingVipFinanceDashboard: boolean;
  vipFinanceDashboard: VipFinanceDashboard;
  isFetchingSalesRank: boolean;
  salesRank: SalesRankItem[];
  salesRankParams: {
    memberType?: MemberOptionValue;
    ordering?: SalesRankOrderingValue;
  };

  chartParams: ChartParams;
  isFetchingChart: boolean;
  chartResult: ChartResultType;
  memberRevenueChart: MixedMemberRevenueChart; // 新舊客營收把兩個圖表api合在一起顯示，格式特別所以額外放
  isFetchingShipmentProgressOrderList: boolean;
  shipmentProgressOrderListResult: ShipmentProgressOrderListResult;
  shipmentProgressOrderListParams: ShipmentProgressOrderListParams;
  isFetchingMemberFunnel: boolean;
  memberDistributionFunnel: MemberDistributionFunnel;
}

export enum DashboardTime {
  TODAY = "TODAY",
  THIS_MONTH = "THIS_MONTH",
  YESTERDAY = "YESTERDAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_30_DAYS = "LAST_30_DAYS",
}
interface MemberDistributionFunnel {
  current: MemberDistribution;
  compare?: MemberDistribution;
}

interface MixedMemberRevenueChart {
  old: MemberRevenueChart;
  new: MemberRevenueChart;
}

export enum DashboardChartType {
  REVENUE,
  GROSS_MARGIN,
  CATEGORY_SALES,
  ORDER_CANCEL,
  ORDER_RETURN,
  WAREHOUSE,
  NEW_MEMBER,
  VIP_MEMBER_STATUS,
  VIP_FINANCE,
}

const initialState: IState = {
  dashboardActiveTab: DashboardTime.TODAY,
  dashboardParams: {
    startDate: "",
    endDate: "",
    cStartDate: "",
    cEndDate: "",
  },
  isFetchingRevenueDashboard: false,
  revenueDashboard: {},
  isFetchingGrossMarginDashboard: false,
  grossMarginDashboard: {},
  isFetchingCategorySalesDashboard: false,
  categorySalesDashboard: {},
  isFetchingOrderCancelDashboard: false,
  orderCancelDashboard: {},
  isFetchingOrderReturnDashboard: false,
  orderReturnDashboard: {},
  isFetchingMemberDistribution: false,
  memberDistribution: {
    totalMember: 0,
    memberNeverBuy: 0,
    memberBuyOnce: 0,
    memberBuyTwiceGte: 0,
  },
  isFetchingWarehouseDashboard: false,
  warehouseDashboard: {},
  isFetchingShipmentProgress: false,
  shipmentProgress: {
    transferShippingDelay: 0,
    wareshouseShippingDelay: 0,
    warehouseShipmentDelay: 0,
  },
  isFetchingNewMemberDashboard: false,
  newMemberDashboard: {},
  isFetchingNewMemberRevenueDashboard: false,
  newMemberRevenueDashboard: {},
  isFetchingOldMemberRevenueDashboard: false,
  oldMemberRevenueDashboard: {},
  isFetchingVipMemberStatusDashboard: false,
  vipMemberStatusDashboard: {
    totalVipMembers: 0,
    totalCancelMembers: 0,
  },
  isFetchingVipFinanceDashboard: false,
  vipFinanceDashboard: {},
  isFetchingSalesRank: false,
  salesRank: [],
  salesRankParams: {
    memberType: MemberOptionValue.ALL,
  },

  chartParams: {
    startDate: "2020-01-01",
    endDate: "2020-01-01",
    cStartDate: "",
    cEndDate: "",
    timeUnit: GroupByValue.HOUR,
  },
  isFetchingChart: false,
  chartResult: {
    currentData: [],
    compareData: [],
  },
  memberRevenueChart: {
    old: {
      currentData: [],
      compareData: [],
    },
    new: {
      currentData: [],
      compareData: [],
    },
  },
  isFetchingShipmentProgressOrderList: false,
  shipmentProgressOrderListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  shipmentProgressOrderListParams: {
    limit: 20,
    offset: 0,
    orderType: 1,
  },
  isFetchingMemberFunnel: false,
  memberDistributionFunnel: {
    current: {
      totalMember: 0,
      memberNeverBuy: 0,
      memberBuyOnce: 0,
      memberBuyTwiceGte: 0,
    },
  },
};

export const fetchRevenueDashboard = createAsyncThunk<RevenueDashboard>(
  "dashboard/fetchRevenueDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchRevenueDashboard(dashboardParams);
    return response;
  },
);

export const fetchGrossMarginDashboard = createAsyncThunk<GrossMarginDashboard>(
  "dashboard/fetchGrossMarginDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchGrossMarginDashboard(dashboardParams);
    return response;
  },
);

export const fetchDashboardChart = createAsyncThunk<ChartResultType, DashboardChartType>(
  "dashboard/fetchRevenueChart",
  async (chartType, thunkApi) => {
    const {
      dashboard: { chartParams },
    } = thunkApi.getState() as RootState;

    let response = initialState.chartResult;

    switch (chartType) {
      case DashboardChartType.REVENUE:
        response = await dashboardApi.fetchRevenueChart(chartParams);
        break;
      case DashboardChartType.GROSS_MARGIN:
        response = await dashboardApi.fetchGrossMarginChart(chartParams);
        break;
      case DashboardChartType.CATEGORY_SALES:
        response = await dashboardApi.fetchCategorySalesChart(chartParams);
        break;
      case DashboardChartType.ORDER_CANCEL: {
        const cancelParams: OrderStatusChartParams = {
          ...chartParams,
          status: "cancel",
        };
        response = await dashboardApi.fetchOrderStatusChart(cancelParams);
        break;
      }
      case DashboardChartType.ORDER_RETURN: {
        const returnParams: OrderStatusChartParams = {
          ...chartParams,
          status: "return",
        };
        response = await dashboardApi.fetchOrderStatusChart(returnParams);
        break;
      }
      case DashboardChartType.WAREHOUSE:
        response = await dashboardApi.fetchWarehouseChart(chartParams);
        break;
      case DashboardChartType.NEW_MEMBER:
        response = await dashboardApi.fetchNewMemberChart(chartParams);
        break;
      case DashboardChartType.VIP_MEMBER_STATUS:
        response = await dashboardApi.fetchVipMemberStatusChart(chartParams);
        break;
      case DashboardChartType.VIP_FINANCE:
        response = await dashboardApi.fetchVipFinanceChart(chartParams);
        break;
      default:
    }

    return response;
  },
);

export const fetchCategorySalesDashboard = createAsyncThunk<CategorySalesDashboard>(
  "dashboard/fetchCategorySalesDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchCategorySalesDashboard(dashboardParams);
    return response;
  },
);

export const fetchOrderCancelDashboard = createAsyncThunk<OrderStatusDashboard>(
  "dashboard/fetchOrderCancelDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const cancelParams: OrderStatusDashboardParams = {
      ...dashboardParams,
      status: "cancel",
    };
    const response = await dashboardApi.fetchOrderStatusDashboard(cancelParams);
    return response;
  },
);

export const fetchOrderReturnDashboard = createAsyncThunk<OrderStatusDashboard>(
  "dashboard/fetchOrderReturnDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const returnParams: OrderStatusDashboardParams = {
      ...dashboardParams,
      status: "return",
    };
    const response = await dashboardApi.fetchOrderStatusDashboard(returnParams);
    return response;
  },
);

export const fetchMemberDistribution = createAsyncThunk<MemberDistribution>(
  "dashboard/fetchMemberDistribution",
  async () => {
    const response = await dashboardApi.fetchMemberDistribution();
    return response;
  },
);

export const fetchWarehouseDashboard = createAsyncThunk<WarehouseDashboard>(
  "dashboard/fetchWarehouseDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchWarehouseDashboard(dashboardParams);
    return response;
  },
);

export const fetchShipmentProgress = createAsyncThunk<ShipmentProgress>("dashboard/fetchShipmentProgress", async () => {
  const response = await dashboardApi.fetchShipmentProgress();
  return response;
});

export const fetchShipmentProgressOrderList = createAsyncThunk<ShipmentProgressOrderListResult>(
  "dashboard/fetchShipmentProgressOrderList",
  async (_, thunkApi) => {
    const {
      dashboard: { shipmentProgressOrderListParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchShipmentProgressOrderList(shipmentProgressOrderListParams);
    return response;
  },
);

export const fetchNewMemberDashboard = createAsyncThunk<NewMemberDashboard>(
  "dashboard/fetchNewMemberDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchNewMemberDashboard(dashboardParams);
    return response;
  },
);

export const fetchMemberDistributionFunnel = createAsyncThunk<MemberDistributionFunnel>(
  "dashboard/fetchMemberDistributionFunnel",
  async (_, thunkApi) => {
    const {
      dashboard: { chartParams },
    } = thunkApi.getState() as RootState;
    const { startDate, endDate, cStartDate, cEndDate } = chartParams;

    if (cStartDate && cEndDate) {
      const currentParams = {
        startDate,
        endDate,
      };
      const compareParams = {
        startDate: cStartDate,
        endDate: cEndDate,
      };
      const [current, compare] = await Promise.all([
        dashboardApi.fetchMemberDistribution(currentParams),
        dashboardApi.fetchMemberDistribution(compareParams),
      ]);
      return {
        current,
        compare,
      };
    }

    const currentParams = {
      startDate,
      endDate,
    };
    const current = await dashboardApi.fetchMemberDistribution(currentParams);
    return {
      current,
    };
  },
);

export const fetchNewMemberRevenueDashboard = createAsyncThunk<MemberRevenueDashboard>(
  "dashboard/fetchNewMemberRevenueDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const newMemberRevenueParams: MemberRevenueDashboardParams = {
      ...dashboardParams,
      customerType: MemberOptionValue.NEW,
    };
    const response = await dashboardApi.fetchMemberRevenueDashboard(newMemberRevenueParams);
    return response;
  },
);

export const fetchOldMemberRevenueDashboard = createAsyncThunk<MemberRevenueDashboard>(
  "dashboard/fetchOldMemberRevenueDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const oldMemberRevenueParams: MemberRevenueDashboardParams = {
      ...dashboardParams,
      customerType: MemberOptionValue.OLD,
    };
    const response = await dashboardApi.fetchMemberRevenueDashboard(oldMemberRevenueParams);
    return response;
  },
);

export const fetchMemberRevenueChart = createAsyncThunk<MixedMemberRevenueChart>(
  "dashboard/fetchMemberRevenueChart",
  async (_, thunkApi) => {
    const {
      dashboard: { chartParams },
    } = thunkApi.getState() as RootState;

    const newMemberRevenueParams: MemberRevenueChartParams = {
      ...chartParams,
      customerType: MemberOptionValue.NEW,
    };
    const oldMemberRevenueParams: MemberRevenueChartParams = {
      ...chartParams,
      customerType: MemberOptionValue.OLD,
    };

    const memberRevenueRequests = [
      dashboardApi.fetchMemberRevenueChart(newMemberRevenueParams),
      dashboardApi.fetchMemberRevenueChart(oldMemberRevenueParams),
    ];

    const [newMemberRevenue, oldMemberRevenue] = await Promise.all(memberRevenueRequests);
    return {
      old: oldMemberRevenue,
      new: newMemberRevenue,
    };
  },
);

export const fetchVipMemberStatusDashboard = createAsyncThunk<VipMemberStatusDashboard>(
  "dashboard/fetchVipMemberStatusDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchVipMemberStatusDashboard(dashboardParams);
    return response;
  },
);

export const fetchVipFinanceDashboard = createAsyncThunk<VipFinanceDashboard>(
  "dashboard/fetchVipFinanceDashboard",
  async (_, thunkApi) => {
    const {
      dashboard: { dashboardParams },
    } = thunkApi.getState() as RootState;

    const response = await dashboardApi.fetchVipFinanceDashboard(dashboardParams);
    return response;
  },
);

export const fetchSalesRank = createAsyncThunk<SalesRankItem[]>("dashboard/fetchSalesRank", async (_, thunkApi) => {
  const {
    dashboard: { salesRankParams, dashboardParams },
  } = thunkApi.getState() as RootState;
  const { memberType, ordering } = salesRankParams;

  const fetchSalesRankParams = {
    startDate: dashboardParams.startDate,
    endDate: dashboardParams.endDate,
    memberType: memberType === MemberOptionValue.ALL ? undefined : memberType,
    ordering,
  };

  const response = await dashboardApi.fetchSalesRank(fetchSalesRankParams);
  return response;
});

export const changeDashboardTab = createAsyncThunk<void, DashboardTime>(
  "dashboard/changeDashboardTab",
  async (tabKey, thunkApi) => {
    const { dispatch } = thunkApi;
    let params: DashboardParams = {
      startDate: "",
      endDate: "",
      cStartDate: "",
      cEndDate: "",
    };

    switch (tabKey) {
      case DashboardTime.TODAY: {
        const todayStart = moment().startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        const yesterdayStart = moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const yesterdayNow = moment().subtract(1, "days").format("YYYY-MM-DD HH:mm:ss");
        params = {
          startDate: todayStart,
          endDate: now,
          cStartDate: yesterdayStart,
          cEndDate: yesterdayNow,
        };
        break;
      }
      case DashboardTime.THIS_MONTH: {
        const monthStart = moment().startOf("month").format("YYYY-MM-DD HH:mm:ss");
        const now = moment().format("YYYY-MM-DD HH:mm:ss");
        const lastMonthStart = moment().subtract(1, "month").startOf("month").format("YYYY-MM-DD HH:mm:ss");
        const lastMonthNow = moment().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss");
        params = {
          startDate: monthStart,
          endDate: now,
          cStartDate: lastMonthStart,
          cEndDate: lastMonthNow,
        };
        break;
      }
      case DashboardTime.YESTERDAY: {
        const yesterdayStart = moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days2AgoStart = moment().subtract(2, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days2AgoEnd = moment().subtract(2, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        params = {
          startDate: yesterdayStart,
          endDate: yesterdayEnd,
          cStartDate: days2AgoStart,
          cEndDate: days2AgoEnd,
        };
        break;
      }
      case DashboardTime.LAST_7_DAYS: {
        const days7AgoStart = moment().subtract(7, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days14AgoStart = moment().subtract(14, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days8AgoEnd = moment().subtract(8, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        params = {
          startDate: days7AgoStart,
          endDate: yesterdayEnd,
          cStartDate: days14AgoStart,
          cEndDate: days8AgoEnd,
        };
        break;
      }
      case DashboardTime.LAST_30_DAYS: {
        const days30AgoStart = moment().subtract(30, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const yesterdayEnd = moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days60AgoStart = moment().subtract(60, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        const days31AgoEnd = moment().subtract(31, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss");
        params = {
          startDate: days30AgoStart,
          endDate: yesterdayEnd,
          cStartDate: days60AgoStart,
          cEndDate: days31AgoEnd,
        };
        break;
      }
      default:
    }

    dispatch(updateDashboardActiveTab(tabKey));
    dispatch(updateDashboardParams(params));
    dispatch(fetchDashboardApis());
  },
);

export const fetchDashboardApis = createAsyncThunk("dashboard/fetchDashboardApis", async (_, thunkApi) => {
  const { dispatch } = thunkApi;

  dispatch(fetchRevenueDashboard());
  dispatch(fetchGrossMarginDashboard());
  dispatch(fetchCategorySalesDashboard());
  dispatch(fetchOrderCancelDashboard());
  dispatch(fetchOrderReturnDashboard());
  dispatch(fetchMemberDistribution());
  dispatch(fetchWarehouseDashboard());
  dispatch(fetchShipmentProgress());
  dispatch(fetchNewMemberDashboard());
  dispatch(fetchNewMemberRevenueDashboard());
  dispatch(fetchOldMemberRevenueDashboard());
  dispatch(fetchVipMemberStatusDashboard());
  dispatch(fetchVipFinanceDashboard());
  dispatch(fetchSalesRank());
});

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    updateDashboardActiveTab: (state, action: PayloadAction<DashboardTime>) => {
      state.dashboardActiveTab = action.payload;
    },
    updateDashboardParams: (state, action: PayloadAction<DashboardParams>) => {
      state.dashboardParams = action.payload;
    },
    updateChartParams: (state, action: PayloadAction<ChartParams>) => {
      state.chartParams = action.payload;
    },
    updateShipmentProgressOrderListParams: (state, action: PayloadAction<ShipmentProgressOrderListParams>) => {
      state.shipmentProgressOrderListParams = action.payload;
    },
    updateSalesRankParams: (
      state,
      action: PayloadAction<{
        memberType?: MemberOptionValue;
        ordering?: SalesRankOrderingValue;
      }>,
    ) => {
      state.salesRankParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRevenueDashboard.pending, (state) => {
        state.isFetchingRevenueDashboard = true;
      })
      .addCase(fetchRevenueDashboard.fulfilled, (state, action) => {
        state.isFetchingRevenueDashboard = false;
        state.revenueDashboard = action.payload;
      });

    builder
      .addCase(fetchGrossMarginDashboard.pending, (state) => {
        state.isFetchingGrossMarginDashboard = true;
      })
      .addCase(fetchGrossMarginDashboard.fulfilled, (state, action) => {
        state.isFetchingGrossMarginDashboard = false;
        state.grossMarginDashboard = action.payload;
      });

    builder
      .addCase(fetchDashboardChart.pending, (state) => {
        state.isFetchingChart = true;
      })
      .addCase(fetchDashboardChart.fulfilled, (state, action) => {
        state.isFetchingChart = false;
        state.chartResult = action.payload;
      });

    builder
      .addCase(fetchCategorySalesDashboard.pending, (state) => {
        state.isFetchingCategorySalesDashboard = true;
      })
      .addCase(fetchCategorySalesDashboard.fulfilled, (state, action) => {
        state.isFetchingCategorySalesDashboard = false;
        state.categorySalesDashboard = action.payload;
      });

    builder
      .addCase(fetchOrderCancelDashboard.pending, (state) => {
        state.isFetchingOrderCancelDashboard = true;
      })
      .addCase(fetchOrderCancelDashboard.fulfilled, (state, action) => {
        state.isFetchingOrderCancelDashboard = false;
        state.orderCancelDashboard = action.payload;
      });

    builder
      .addCase(fetchOrderReturnDashboard.pending, (state) => {
        state.isFetchingOrderReturnDashboard = true;
      })
      .addCase(fetchOrderReturnDashboard.fulfilled, (state, action) => {
        state.isFetchingOrderReturnDashboard = false;
        state.orderReturnDashboard = action.payload;
      });

    builder
      .addCase(fetchMemberDistribution.pending, (state) => {
        state.isFetchingMemberDistribution = true;
      })
      .addCase(fetchMemberDistribution.fulfilled, (state, action) => {
        state.isFetchingMemberDistribution = false;
        state.memberDistribution = action.payload;
      });

    builder
      .addCase(fetchWarehouseDashboard.pending, (state) => {
        state.isFetchingWarehouseDashboard = true;
      })
      .addCase(fetchWarehouseDashboard.fulfilled, (state, action) => {
        state.isFetchingWarehouseDashboard = false;
        state.warehouseDashboard = action.payload;
      });

    builder
      .addCase(fetchShipmentProgress.pending, (state) => {
        state.isFetchingShipmentProgress = true;
      })
      .addCase(fetchShipmentProgress.fulfilled, (state, action) => {
        state.isFetchingShipmentProgress = false;
        state.shipmentProgress = action.payload;
      });

    builder
      .addCase(fetchShipmentProgressOrderList.pending, (state) => {
        state.isFetchingShipmentProgressOrderList = true;
      })
      .addCase(fetchShipmentProgressOrderList.fulfilled, (state, action) => {
        state.isFetchingShipmentProgressOrderList = false;
        state.shipmentProgressOrderListResult = action.payload;
      });

    builder
      .addCase(fetchNewMemberDashboard.pending, (state) => {
        state.isFetchingNewMemberDashboard = true;
      })
      .addCase(fetchNewMemberDashboard.fulfilled, (state, action) => {
        state.isFetchingNewMemberDashboard = false;
        state.newMemberDashboard = action.payload;
      });

    builder
      .addCase(fetchMemberDistributionFunnel.pending, (state) => {
        state.isFetchingMemberFunnel = true;
      })
      .addCase(fetchMemberDistributionFunnel.fulfilled, (state, action) => {
        state.isFetchingMemberFunnel = false;
        state.memberDistributionFunnel = action.payload;
      });

    builder
      .addCase(fetchNewMemberRevenueDashboard.pending, (state) => {
        state.isFetchingNewMemberRevenueDashboard = true;
      })
      .addCase(fetchNewMemberRevenueDashboard.fulfilled, (state, action) => {
        state.isFetchingNewMemberRevenueDashboard = false;
        state.newMemberRevenueDashboard = action.payload;
      });

    builder
      .addCase(fetchOldMemberRevenueDashboard.pending, (state) => {
        state.isFetchingOldMemberRevenueDashboard = true;
      })
      .addCase(fetchOldMemberRevenueDashboard.fulfilled, (state, action) => {
        state.isFetchingOldMemberRevenueDashboard = false;
        state.oldMemberRevenueDashboard = action.payload;
      });

    builder
      .addCase(fetchMemberRevenueChart.pending, (state) => {
        state.isFetchingChart = true;
      })
      .addCase(fetchMemberRevenueChart.fulfilled, (state, action) => {
        state.isFetchingChart = false;
        state.memberRevenueChart = action.payload;
      });

    builder
      .addCase(fetchVipMemberStatusDashboard.pending, (state) => {
        state.isFetchingVipMemberStatusDashboard = true;
      })
      .addCase(fetchVipMemberStatusDashboard.fulfilled, (state, action) => {
        state.isFetchingVipMemberStatusDashboard = false;
        state.vipMemberStatusDashboard = action.payload;
      });

    builder
      .addCase(fetchVipFinanceDashboard.pending, (state) => {
        state.isFetchingVipFinanceDashboard = true;
      })
      .addCase(fetchVipFinanceDashboard.fulfilled, (state, action) => {
        state.isFetchingVipFinanceDashboard = false;
        state.vipFinanceDashboard = action.payload;
      });

    builder
      .addCase(fetchSalesRank.pending, (state) => {
        state.isFetchingSalesRank = true;
      })
      .addCase(fetchSalesRank.fulfilled, (state, action) => {
        state.isFetchingSalesRank = false;
        state.salesRank = action.payload;
      });
  },
});

export const {
  updateDashboardActiveTab,
  updateDashboardParams,
  updateChartParams,
  updateShipmentProgressOrderListParams,
  updateSalesRankParams,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
