import BrandPageApi, {
  BrandPageDetail,
  BrandPageList,
  BrandPageListConfig,
  BrandSalesPageConfig,
  BrandSalesPageRankPayload,
  BrandSalesPageResult,
  UpdateBrandPagePayload,
  SalePageShowInBrandPagePayload,
} from "@api/brandPageApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  isFetchingList: boolean;
  brandPageListResult: BrandPageList;
  brandPageDetail?: BrandPageDetail;
  brandSalePageResult: BrandSalesPageResult;
  brandSalePageFilter: BrandSalesPageConfig;
}

const initialState: IState = {
  isFetching: false,
  isFetchingList: false,
  brandPageListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  brandPageDetail: undefined,
  brandSalePageResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  brandSalePageFilter: {
    offset: 0,
    limit: 20,
    salesPageId: undefined,
    sku: undefined,
    salesPageName: undefined,
    salesPageIsActive: undefined,
    tagId: undefined,
    ordering: undefined,
  },
};

export const fetchBrandPageList = createAsyncThunk(
  "brandPage/fetchBrandPageList",
  async (config: BrandPageListConfig & { isScroll?: boolean }, thunkApi) => {
    const { isScroll, ...otherConfig } = config;
    const response = await BrandPageApi.fetchBrandPageList(otherConfig);
    if (isScroll) {
      const { brandPageSlice } = thunkApi.getState() as RootState;
      const previousList = brandPageSlice.brandPageListResult.results;
      response.results = [...previousList, ...response.results];
    }
    return response;
  },
);

export const fetchBrandPageDetail = createAsyncThunk("brandPage/fetchBrandPageDetail", async (brandPageId: number) => {
  const response = await BrandPageApi.fetchBrandPageDetail(brandPageId);
  return response;
});

export const fetchUpdateBrandPage = createAsyncThunk(
  "brandPage/fetchUpdateBrandPage",
  async (payload: UpdateBrandPagePayload, thunkApi) => {
    try {
      const response = await BrandPageApi.fetchUpdateBrandPage(payload);
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue("Failure");
    }
  },
);

export const fetchBrandSalesPage = createAsyncThunk(
  "brandPage/fetchBrandSalesPage",
  async (config: BrandSalesPageConfig) => {
    const response = await BrandPageApi.fetchBrandSalesPage(config);
    return response;
  },
);

export const fetchUpdateBrandSalesPageRank = createAsyncThunk(
  "brandPage/fetchUpdateBrandSalesPageRank",
  async (payload: BrandSalesPageRankPayload) => {
    await BrandPageApi.fetchUpdateBrandSalesPageRank(payload);
    message.success("儲存成功");
    return "Success";
  },
);

export const fetchSalePageShowInBrandPage = createAsyncThunk(
  "brandPage/fetchSalePageShowInBrandPage",
  async (payload: SalePageShowInBrandPagePayload, thunkApi) => {
    const {
      brandPageSlice: { brandSalePageFilter },
    } = thunkApi.getState() as RootState;

    await BrandPageApi.fetchSalePageShowInBrandPage(payload);
    message.success(payload.isShow ? "加入成功" : "刪除成功");
    thunkApi.dispatch(fetchBrandSalesPage({ ...brandSalePageFilter, tagId: payload.brandId }));
    return "Success";
  },
);

const brandPageSlice = createSlice({
  name: "brandPage",
  initialState,
  reducers: {
    setBrandSalePageFilter: (state, action) => {
      state.brandSalePageFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBrandPageList.pending, (state) => {
      state.isFetchingList = true;
    });
    builder.addCase(fetchBrandPageList.fulfilled, (state, action) => {
      state.isFetchingList = false;
      state.brandPageListResult = action.payload;
    });
    builder.addCase(fetchBrandPageDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBrandPageDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandPageDetail = action.payload;
    });
    builder.addCase(fetchUpdateBrandPage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateBrandPage.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandPageDetail = action.payload;
    });
    builder.addCase(fetchUpdateBrandPage.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchBrandSalesPage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchBrandSalesPage.fulfilled, (state, action) => {
      state.isFetching = false;
      state.brandSalePageResult = action.payload;
    });
  },
});

export const brandPageState = (state: RootState) => state.brandPageSlice;
export const { setBrandSalePageFilter } = brandPageSlice.actions;
export default brandPageSlice.reducer;
