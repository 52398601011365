import React, { FC, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Radio, Form, Button, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import ruleApi, { CouponMethodValue, RuleDetail, CreateCouponParams } from "@api/ruleApi";
import { FormInstance } from "antd/lib/form";
import { useSelector, useDispatch } from "react-redux";
import { exportCouponsByCSV } from "@redux/ruleSlice";
import { RootState } from "@redux/rootReducer";
import type { LocalFormType } from "..";
import SingleCouponSetup from "./SingleCouponSetup";
import RandomCouponSetup from "./RandomCouponSetup";
import RandomConfirmModal from "./RandomConfirmModal";

const Wrapper = styled.div`
  position: relative;
  grid-column: 2 span;
  grid-row-start: 5;

  & .ant-col {
    position: static;
  }
  & .ant-form-item-control-input {
    position: static;
  }
  & .ant-form-item-control-input {
    min-height: 0;
  }
  & .ant-form-item-explain {
    min-height: 0;
  }
`;
const ValidateWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border: 1px solid #d9d9d9;
  z-index: -1;

  .ant-form-item-has-error & {
    border-color: #ff4d4f;
  }
`;
const Header = styled.div`
  display: flex;
  align-items: center;

  height: 36px;
  padding: 0 20px;
  margin: 0 1px;

  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  background-color: #fafafa;
`;
const Red = styled.span`
  color: red;
`;
const Body = styled.div`
  padding: 14px 20px 20px 20px;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
`;
const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
const LeftButton = styled(Button)`
  margin-right: 10px;
`;
const CouponInfoWrapper = styled.div`
  width: 630px;
  margin-top: 18px;
  border: 1px solid #f2f2f2;
`;
const CouponInfoTitle = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 18px;
  background-color: #fafafa;
`;
const CouponInfoRow = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 18px;
`;
const CouponInfoCol = styled.div<{ widthPercentage: number }>`
  width: ${({ widthPercentage }) => widthPercentage}%;
  font-size: 12px;
`;
const ExportButton = styled(Button)`
  margin-left: auto;
`;
const OverrideWrapper = styled.div`
  height: 0px;
`;
interface Props {
  form: FormInstance<LocalFormType>;
}

const PromoCode: FC<Props> = (props) => {
  const { form } = props;
  const { id: ruleId } = useParams();
  const dispatch = useDispatch();

  const maxBindTimesFromDB = useSelector((state: RootState) => state.rule.ruleDetail.bindMethod.maxBindTimes);
  const couponMethodFromDB = useSelector((state: RootState) => state.rule.ruleDetail.couponMethod);
  const currentMaxBindTimes = useRef<number>(-1);
  const isEdit = useRef<boolean>(false);
  const [showSingleCouponSetup, setShowSingleCouponSetup] = useState<boolean>(false);
  const [showRandomCouponSetup, setShowRandomCouponSetup] = useState<boolean>(false);
  const [showConfirmRandomModal, setShowConfirmRandomModal] = useState<boolean>(false);
  // 偵測可領取人數是否上調
  useEffect(() => {
    if (!maxBindTimesFromDB) return;

    // -1 表示第一次拿到值
    if (currentMaxBindTimes.current !== -1 && maxBindTimesFromDB > currentMaxBindTimes.current) {
      switch (couponMethodFromDB) {
        case CouponMethodValue.SINGLE:
          Modal.confirm({
            title: "是否異動兌換碼數量?",
            icon: <ExclamationCircleOutlined />,
            content: "",
            okText: "是",
            cancelText: "否",
            onOk: async () => {
              const params: CreateCouponParams = {
                ruleId: Number(ruleId!),
                couponMethod: CouponMethodValue.SINGLE,
              };
              try {
                const response = await ruleApi.createCoupons(params, false);
                form.setFieldsValue({
                  couponInfo: response,
                });
              } catch (error: any) {
                message.warning(error.message);
              }
            },
          });
          break;
        case CouponMethodValue.RANDOM:
          toggleConfirmRandomModal();
          break;
        default:
      }
    }
    currentMaxBindTimes.current = maxBindTimesFromDB;
  }, [maxBindTimesFromDB, couponMethodFromDB, form, ruleId]);

  const toggleSingleCouponSetup = () => setShowSingleCouponSetup((prev) => !prev);
  const toggleRandomCouponSetup = () => setShowRandomCouponSetup((prev) => !prev);
  const toggleConfirmRandomModal = () => setShowConfirmRandomModal((prev) => !prev);

  const createSingleCouponInfo = (couponInfo: RuleDetail["couponInfo"]) => {
    const { code, boundCount, maxBindTimes } = couponInfo || {};
    return (
      <CouponInfoWrapper>
        <CouponInfoTitle>
          <CouponInfoCol widthPercentage={30}>序號</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>數量</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>已被領取次數</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>操作</CouponInfoCol>
        </CouponInfoTitle>
        <CouponInfoRow>
          <CouponInfoCol widthPercentage={30}>{code}</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>{maxBindTimes}</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>{boundCount}</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>
            <Button
              onClick={() => {
                isEdit.current = true;
                toggleSingleCouponSetup();
              }}
            >
              編輯
            </Button>
            <Button onClick={handleDeleteCoupon}>刪除</Button>
          </CouponInfoCol>
        </CouponInfoRow>
      </CouponInfoWrapper>
    );
  };

  const createRandomCouponInfo = (couponInfo: RuleDetail["couponInfo"]) => {
    const { boundCount, maxBindTimes } = couponInfo || {};
    return (
      <CouponInfoWrapper>
        <CouponInfoTitle>
          <CouponInfoCol widthPercentage={30}>數量</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>已被領取次數</CouponInfoCol>
        </CouponInfoTitle>
        <CouponInfoRow>
          <CouponInfoCol widthPercentage={30}>{maxBindTimes}</CouponInfoCol>
          <CouponInfoCol widthPercentage={30}>{boundCount}</CouponInfoCol>
        </CouponInfoRow>
      </CouponInfoWrapper>
    );
  };

  const handleCustomSetting = (couponMethod: CouponMethodValue) => () => {
    if (ruleId) {
      switch (couponMethod) {
        case CouponMethodValue.SINGLE:
          isEdit.current = false;
          toggleSingleCouponSetup();
          break;
        case CouponMethodValue.RANDOM:
          toggleRandomCouponSetup();
          break;
        default:
      }
    } else {
      Modal.warning({
        title: "須先建立此筆資料",
        okText: "我知道了",
      });
    }
  };

  const handleSystemGenerating = (couponMethod: CouponMethodValue) => async () => {
    if (ruleId) {
      // 打api不走redux: 為了要只更新兌換碼部分資訊而不是重拉整個ruleDetail (會蓋掉還沒儲存而被更動的欄位)
      try {
        const params: CreateCouponParams = {
          ruleId: Number(ruleId!),
          couponMethod,
        };
        const response = await ruleApi.createCoupons(params, false);
        form.setFieldsValue({
          couponInfo: response,
        });
      } catch (error: any) {
        message.error("產生失敗");
      }
    } else {
      Modal.warning({
        title: "須先建立此筆資料",
        okText: "我知道了",
      });
    }
  };

  const handleDeleteCoupon = async () => {
    const values = form.getFieldsValue();
    await ruleApi.deleteCoupon(Number(values.couponInfo?.id!));
    form.setFieldsValue({
      couponInfo: undefined,
    });
    message.info("刪除成功");
  };

  const handleOnExport = () => {
    dispatch(exportCouponsByCSV());
  };

  const handleSingleCouponSubmit = async (
    code: string,
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    try {
      if (isEdit.current) {
        const values = form.getFieldsValue();
        const params = {
          couponId: Number(values.couponInfo?.id!),
          code,
        };
        const response = await ruleApi.updateCoupon(params);
        form.setFieldsValue({
          couponInfo: response,
        });
        message.success("編輯成功");
      } else {
        const params: CreateCouponParams = {
          ruleId: Number(ruleId!),
          couponMethod: CouponMethodValue.SINGLE,
          code,
        };
        const response = await ruleApi.createCoupons(params, true);
        form.setFieldsValue({
          couponInfo: response,
        });
      }

      toggleSingleCouponSetup();
    } catch (error: any) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Wrapper>
      <OverrideWrapper>
        <Form.Item name="couponMethod" rules={[{ required: true, message: "" }]}>
          <ValidateWrapper />
        </Form.Item>
      </OverrideWrapper>
      <Header>
        兌換碼設定
        <Red>*</Red>
      </Header>
      <Body>
        <Form.Item
          noStyle
          shouldUpdate={(prevValues, currentValues) =>
            prevValues.couponMethod !== currentValues.couponMethod || prevValues.couponInfo !== currentValues.couponInfo
          }
        >
          {({ getFieldValue }) => {
            const couponMethod = getFieldValue("couponMethod");
            const couponInfo = getFieldValue("couponInfo");
            const isDisabled = couponInfo || couponMethodFromDB === CouponMethodValue.NONE;

            return (
              <FlexColumn>
                <FlexRow>
                  <Form.Item name="couponMethod" rules={[{ required: true, message: "" }]}>
                    <Radio.Group disabled={isDisabled}>
                      <Radio value={CouponMethodValue.SINGLE}>單一</Radio>
                      <Radio value={CouponMethodValue.RANDOM}>隨機</Radio>
                      <Radio value={CouponMethodValue.NONE}>無</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {(couponMethod === CouponMethodValue.SINGLE || couponMethod === CouponMethodValue.RANDOM) && (
                    <>
                      <LeftButton type="primary" disabled={isDisabled} onClick={handleCustomSetting(couponMethod)}>
                        自行設定序號
                      </LeftButton>
                      <Button disabled={isDisabled} onClick={handleSystemGenerating(couponMethod)}>
                        系統產生序號
                      </Button>
                      {couponMethod === CouponMethodValue.RANDOM && (
                        <ExportButton onClick={handleOnExport} disabled={!couponInfo}>
                          匯出
                        </ExportButton>
                      )}
                    </>
                  )}
                </FlexRow>
                <Form.Item name="couponInfo" noStyle>
                  {couponMethod === CouponMethodValue.SINGLE && couponInfo && createSingleCouponInfo(couponInfo)}
                  {couponMethod === CouponMethodValue.RANDOM && couponInfo && createRandomCouponInfo(couponInfo)}
                </Form.Item>
              </FlexColumn>
            );
          }}
        </Form.Item>
      </Body>
      {showSingleCouponSetup && (
        <SingleCouponSetup
          onSubmit={handleSingleCouponSubmit}
          onClose={toggleSingleCouponSetup}
          defaultValue={form.getFieldsValue().couponInfo?.code || ""}
        />
      )}
      {showRandomCouponSetup && <RandomCouponSetup onClose={toggleRandomCouponSetup} form={form} />}
      {showConfirmRandomModal && (
        <RandomConfirmModal onClose={toggleConfirmRandomModal} onCustomSetup={toggleRandomCouponSetup} form={form} />
      )}
    </Wrapper>
  );
};

export default PromoCode;
