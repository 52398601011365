import Highcharts from "highcharts";
import { GroupByValue } from "@api/dashboardApi";
import moment from "moment";

export const hoverAction = (thisPoint: Highcharts.Point, state: "" | Highcharts.PointStateValue | undefined) => {
  const allSeries = thisPoint.series.chart.series;
  const stackName = thisPoint.series.userOptions.stack;
  const thisIndex = thisPoint.index;

  allSeries.forEach((ser) => {
    if (ser.options.stack === stackName && thisPoint.series !== ser) {
      ser.points[thisIndex].setState(state);
    }
  });
};

export const getTooltipTitle = (startAt: string, endAt: string, currentTime: string, groupBy: GroupByValue): string => {
  switch (groupBy) {
    case GroupByValue.MONTH: {
      const filterStartTime = moment(startAt);
      const filterEndTime = moment(endAt);
      const startOfTheMonth = moment(currentTime).startOf("month");
      const endOfTheMonth = moment(currentTime).endOf("month");

      const rangeStart = filterStartTime > startOfTheMonth ? filterStartTime : startOfTheMonth;
      const rangeEnd = filterEndTime < endOfTheMonth ? filterEndTime : endOfTheMonth;

      return `${moment(rangeStart).format("YYYY-MM-DD")} - ${moment(rangeEnd).format("YYYY-MM-DD")}`;
    }
    case GroupByValue.YEAR: {
      const filterStartTime = moment(startAt);
      const filterEndTime = moment(endAt);
      const startOfTheYear = moment(currentTime).startOf("year");
      const endOfTheYear = moment(currentTime).endOf("year");

      const rangeStart = filterStartTime > startOfTheYear ? filterStartTime : startOfTheYear;
      const rangeEnd = filterEndTime < endOfTheYear ? filterEndTime : endOfTheYear;

      return `${moment(rangeStart).format("YYYY-MM-DD")} - ${moment(rangeEnd).format("YYYY-MM-DD")}`;
    }
    case GroupByValue.HOUR:
    case GroupByValue.DAY:
    default:
      return currentTime;
  }
};

export const getPointStart = (startTime: string, groupBy: GroupByValue): number => {
  switch (groupBy) {
    case GroupByValue.HOUR:
      return moment(startTime).startOf("hour").valueOf();
    case GroupByValue.DAY:
      return moment(startTime).startOf("day").valueOf();
    case GroupByValue.MONTH:
      return moment(startTime).startOf("month").valueOf();
    case GroupByValue.YEAR:
      return moment(startTime).startOf("year").valueOf();
    default:
      return 0;
  }
};

export const getPointInterval = (value: GroupByValue): number => {
  switch (value) {
    case GroupByValue.HOUR:
      return 3600 * 1000;
    case GroupByValue.DAY:
      return 3600 * 1000 * 24;
    case GroupByValue.MONTH:
      return 3600 * 1000 * 24 * 30;
    case GroupByValue.YEAR:
      return 3600 * 1000 * 24 * 365;
    default:
      return 0;
  }
};

export const getTimeFormat = (value: GroupByValue): string => {
  switch (value) {
    case GroupByValue.HOUR:
      return "%H";
    case GroupByValue.DAY:
      return "%Y - %m - %e";
    case GroupByValue.MONTH:
      return "%Y - %m";
    case GroupByValue.YEAR:
      return "%Y";
    default:
      return "%Y - %m - %e";
  }
};

export const getFilledTimeData: <T extends { date: string }>(
  startTime: string,
  endTime: string,
  data: T[],
  groupBy: GroupByValue,
) => Map<string, T> = (startTime, endTime, data, groupBy) => {
  const allDataMap = new Map();

  switch (groupBy) {
    case GroupByValue.HOUR: {
      const momentStart = moment(startTime).startOf("hour");
      const momentEnd = moment(endTime).startOf("hour");

      do {
        allDataMap.set(momentStart.format("YYYY-MM-DD HH"), {});
      } while (momentStart.add(1, "hours").diff(momentEnd) <= 0);

      break;
    }
    case GroupByValue.DAY: {
      const momentStart = moment(startTime).startOf("day");
      const momentEnd = moment(endTime).startOf("day");

      do {
        allDataMap.set(momentStart.format("YYYY-MM-DD"), {});
      } while (momentStart.add(1, "days").diff(momentEnd) <= 0);

      break;
    }
    case GroupByValue.MONTH: {
      const momentStart = moment(startTime).startOf("month");
      const momentEnd = moment(endTime).startOf("month");

      do {
        allDataMap.set(momentStart.format("YYYY-MM"), {});
      } while (momentStart.add(1, "months").diff(momentEnd) <= 0);

      break;
    }
    case GroupByValue.YEAR: {
      const momentStart = moment(startTime).startOf("year");
      const momentEnd = moment(endTime).startOf("year");

      do {
        allDataMap.set(momentStart.format("YYYY"), {});
      } while (momentStart.add(1, "years").diff(momentEnd) <= 0);

      break;
    }
    default:
  }

  data.forEach((item) => {
    const { date } = item;
    allDataMap.set(date, item);
  });

  return allDataMap;
};

export const getTimeRangeText = (startTime: string, endTime: string, groupBy: GroupByValue) => {
  let timeOne = startTime;
  let timeTwo = endTime;

  switch (groupBy) {
    case GroupByValue.HOUR:
      timeOne = moment(startTime).startOf("day").format("YYYY-MM-DD HH:mm:ss");
      timeTwo = moment(endTime).endOf("day").format("YYYY-MM-DD HH:mm:ss");
      break;
    case GroupByValue.DAY:
    case GroupByValue.MONTH:
    case GroupByValue.YEAR:
      timeOne = moment(startTime).format("YYYY-MM-DD");
      timeTwo = moment(endTime).format("YYYY-MM-DD");
      break;
    default:
  }

  if (timeOne === timeTwo) return timeOne;
  return `${timeOne} ~ ${timeTwo}`;
};
