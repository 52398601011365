import { ReactNode, ReactText } from "react";
import CoreAPI from "./CoreAPI";
import { TagTypeItem } from "./tagApi";
import normalizeProductCategory from "./utils/normalizeProductCategory";

const apiClient = new CoreAPI();

interface IProductCategoryApi {
  getProductCategoryList: (params: GetProductCategoryListParam) => Promise<ProductCategoryNode[]>;
  getCategoryDetail: (id: number) => Promise<ProductCategoryDetail>;
  createProductCategory: (params: CreateProductCategoryParam) => Promise<ProductCategoryDetail>;
  updateProductCategory: (params: UpdateProductCategoryParam) => Promise<ProductCategoryDetail>;
}

export interface ProductCategoryNode {
  title: string | ReactNode;
  id: ReactText;
  key: ReactText;
  level: number;
  parent: ReactText;
  children?: ProductCategoryNode[];
  isLeaf?: boolean;
  productCount: number;
  isAddButton?: boolean;
  icon?: ReactNode;
}
export interface ProductCategoryDetail {
  id: number;
  name: string;
  tagTypes: TagTypeItem[];
  tagTypesAttributes: {
    [attributeName: string]: { id: number; name: string }[];
  };
}

export interface GetProductCategoryListParam {
  withProductCount: boolean;
}
export interface CreateProductCategoryParam {
  name: string;
  parentId: number;
  tagTypes: number[];
}
export interface UpdateProductCategoryParam {
  id: number;
  name?: string;
  tagTypes?: number[];
}

const productCategoryApi: IProductCategoryApi = {
  getProductCategoryList: async (params) => {
    const { withProductCount } = params;

    const getParams = {
      with_product_count: withProductCount ? 1 : 0,
    };
    const response = await apiClient.getData("/manage/product/product-categories/", getParams);
    return normalizeProductCategory.list(response.data.result);
  },
  getCategoryDetail: async (id) => {
    const response = await apiClient.getData(`/manage/product/product-categories/${id}/`, {});
    return normalizeProductCategory.detail(response.data.result);
  },
  createProductCategory: async (params) => {
    const { parentId, name, tagTypes } = params;
    const postParams = {
      parent_id: parentId,
      name,
      tag_types: tagTypes,
    };
    const response = await apiClient.postData("/manage/product/product-categories/", postParams);
    return normalizeProductCategory.detail(response.data.result);
  },
  updateProductCategory: async (params) => {
    const { id, name, tagTypes } = params;
    const patchParams = {
      name,
      tag_types: tagTypes,
    };
    const response = await apiClient.patchData(`/manage/product/product-categories/${id}/`, patchParams);
    return normalizeProductCategory.detail(response.data.result);
  },
};

export default productCategoryApi;
