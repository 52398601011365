import BrandApi, { BrandListConfig } from "@api/brandApi";
import topicApi, {
  CategoriesPutTagsConfig,
  CreateCategoryConfig,
  CreateTopicConfig,
  FetchDeleteRecommendList,
  FetchEditRecommendList,
  FetchTopicCategorySaleList,
  FetchTopicCategorySaleRecommendList,
  SalesCategoryOrigin,
  TopicCategoryRecommendSaleInfo,
  TopicCategorySaleInfo,
  UpdateCategoriesOrderConfig,
  UpdateCategoryConfig,
  UpdateMultiRankConfig,
} from "@api/topicApi";
import { Brand } from "@api/utils/normalizeBrand";
import {
  CategoryInfo,
  CategoryTag,
  TopicCategories,
  TopicFlatInfo,
  TopicFullInfo,
  TopicShortInfo,
} from "@api/utils/normalizeTopic";
import type { Categories } from "@page/TopicPage/CategoryTree";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

export enum TopicStatus {
  CREATE = "create",
  UPDATE = "update",
}

export enum CategoryStatus {
  CREATE = "create",
  UPDATE = "update",
  DEFAULT = "default",
}

interface IState {
  isFetching: boolean;
  isCategoryFetching: boolean;
  isTagEdit: boolean;
  topicList: TopicShortInfo[];
  topicInfo: TopicFullInfo | null;
  topicCategories: TopicCategories[];
  categoryLevel1Keys: number[];
  categoryInfo: CategoryInfo | null;
  categoryParentId: number;
  topicStatus: TopicStatus | null;
  categoryStatus: CategoryStatus | null;
  topicShortInfo: TopicShortInfo | null;
  salesCategories: {
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  } | null;
  categoriesTag: CategoryTag[];
  isSearching: boolean;
  brandList: Brand[];
  topicSaleListResult: {
    count: number;
    results: TopicCategorySaleInfo[];
  };
  topicRecommendSaleListResult: {
    count: number;
    next: string | null;
    previous: string | null;
    results: TopicCategoryRecommendSaleInfo[];
  };
  topicRecommendSaleListFilter: {
    pageId?: number;
    pageNameQ?: string;
    sku?: string;
    brandNames?: string;
    ordering?: "rank" | "-rank";
    limit: number;
    offset: number;
  };
}

const initialState: IState = {
  isFetching: false,
  isCategoryFetching: false,
  isTagEdit: false,
  topicList: [],
  topicInfo: null, // for edit single topic
  topicCategories: [],
  categoryLevel1Keys: [],
  categoryInfo: null,
  categoryParentId: -1,
  topicStatus: null,
  categoryStatus: null,
  topicShortInfo: null,
  salesCategories: null,
  categoriesTag: [],
  isSearching: false,
  brandList: [],
  topicSaleListResult: {
    count: 0,
    results: [],
  },
  topicRecommendSaleListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  topicRecommendSaleListFilter: {
    pageId: undefined,
    pageNameQ: undefined,
    sku: undefined,
    brandNames: undefined,
    ordering: undefined,
    limit: 20,
    offset: 0,
  },
};

export const fetchTopicList = createAsyncThunk("topic/fetchTopicList", async () => {
  const response = await topicApi.fetchTopicList();
  return response;
});

export const fetchTopicSimpleUpdate = createAsyncThunk(
  "topic/fetchTopicSimpleUpdate",
  async ({ id, topicData }: { id: number; topicData: TopicShortInfo }) => {
    const updateData = {
      title: topicData.title,
      iconUrl: topicData.icon,
      iconAlt: topicData.iconAlt,
      toShow: topicData.toShow,
      isActive: topicData.isActive,
    };

    const response = await topicApi.fetchTopicSimpleUpdate(id, updateData);
    return response;
  },
);

export const fetchTopicFullUpdate = createAsyncThunk(
  "topic/fetchTopicFullUpdate",
  async ({ id, topicData }: { id: number; topicData: TopicFlatInfo }) => {
    await topicApi.fetchTopicFullUpdate(id, topicData);
    return "Success";
  },
);

export const fetchUpdateMultiRank = createAsyncThunk(
  "topic/fetchUpdateMultiRank",
  async (payload: UpdateMultiRankConfig, { dispatch }) => {
    await topicApi.fetchUpdateMultiRank(payload);
    dispatch(fetchTopicList());
    return "Success";
  },
);

export const fetchCreateTopic = createAsyncThunk("topic/fetchCreateTopic", async (payload: CreateTopicConfig) => {
  const response = await topicApi.fetchCreateTopic(payload);
  return response;
});

export const fetchTopicInfo = createAsyncThunk("topic/fetchTopicInfo", async (topicId: number) => {
  const response = await topicApi.fetchTopicInfo(topicId);
  return response;
});

export const fetchTopicCategories = createAsyncThunk(
  "topic/fetchTopicCategories",
  async (payload: { rootCategoryId: number; displayInActive: boolean }) => {
    const response = await topicApi.fetchTopicCategories(payload.rootCategoryId, payload.displayInActive);
    return response;
  },
);

export const fetchUpdateCategoriesOrder = createAsyncThunk(
  "topic/fetchUpdateCategoriesOrder",
  async (
    payload: {
      categoryId: number;
      rootCategoryId: number;
      displayInActive: boolean;
      data: UpdateCategoriesOrderConfig;
    },
    { dispatch },
  ) => {
    await topicApi.fetchUpdateCategoriesOrder(payload.categoryId, payload.data);
    dispatch(
      fetchTopicCategories({ rootCategoryId: payload.rootCategoryId, displayInActive: payload.displayInActive }),
    );
    return "Success";
  },
);

export const fetchCategoryInfo = createAsyncThunk("topic/fetchCategoryInfo", async (categoryId: number) => {
  const response = await topicApi.fetchCategoryInfo(categoryId);
  return response;
});

export const fetchCreateCategory = createAsyncThunk(
  "topic/fetchCreateCategory",
  async (payload: CreateCategoryConfig) => {
    const response = await topicApi.fetchCreateCategory(payload);
    return response;
  },
);

export const fetchUpdateCategory = createAsyncThunk(
  "topic/fetchUpdateCategory",
  async (payload: { categoryId: number; data: UpdateCategoryConfig }) => {
    const response = await topicApi.fetchUpdateCategory(payload.categoryId, payload.data);
    return response;
  },
);

export const fetchSalesCategories = createAsyncThunk("topic/fetchSalesCategories", async () => {
  const response = await topicApi.fetchSalesCategories();
  return response;
});

export const fetchCategoriesTag = createAsyncThunk(
  "topic/fetchCategoriesTag",
  async (payload: { categoryIds: number[]; config?: { nameq?: string; limit?: number } }) => {
    const response = await topicApi.fetchCategoriesTag(payload.categoryIds, payload.config);
    return response;
  },
);

export const fetchCategoriesPutTags = createAsyncThunk(
  "topic/fetchCategoriesPutTags",
  async (payload: CategoriesPutTagsConfig, thunkApi) => {
    const response = await topicApi.fetchCategoriesPutTags(payload);
    return response;
  },
);

export const fetchCategoryRemoveTag = createAsyncThunk(
  "topic/fetchCategoryRemoveTag",
  async (topicCategoryId: number, thunkApi) => {
    await topicApi.fetchCategoryRemoveTag(topicCategoryId);
    await thunkApi.dispatch(fetchCategoryInfo(topicCategoryId));
    return "Success";
  },
);

export const fetchTopicCategorySaleRecommendList = createAsyncThunk(
  "topic/fetchTopicCategorySaleRecommendList",
  async (payload: FetchTopicCategorySaleRecommendList) => {
    const response = await topicApi.fetchTopicCategorySaleRecommendList(payload);
    return response;
  },
);

export const fetchTopicCategorySaleList = createAsyncThunk(
  "topic/fetchTopicCategorySaleList",
  async (payload: FetchTopicCategorySaleList) => {
    const response = await topicApi.fetchTopicCategorySaleList(payload);
    return response;
  },
);

export const fetchAddRecommendList = createAsyncThunk(
  "topic/fetchAddRecommendList",
  async (payload: FetchEditRecommendList, thunkApi) => {
    await topicApi.fetchAddRecommendList(payload);
    const { topic } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(
      fetchTopicCategorySaleRecommendList({
        ...topic.topicRecommendSaleListFilter,
        topicCategoryId: payload.topicCategoryId,
      }),
    );
    message.success("建立成功");
    return "Success";
  },
);

export const fetchUpdateRecommendList = createAsyncThunk(
  "topic/fetchUpdateRecommendList",
  async (payload: FetchEditRecommendList, thunkApi) => {
    await topicApi.fetchUpdateRecommendList(payload);
    const { topic } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(
      fetchTopicCategorySaleRecommendList({
        ...topic.topicRecommendSaleListFilter,
        topicCategoryId: payload.topicCategoryId,
      }),
    );
    message.success("更新成功");
    return "Success";
  },
);

export const fetchDeleteRecommendList = createAsyncThunk(
  "topic/fetchDeleteRecommendList",
  async (payload: FetchDeleteRecommendList, thunkApi) => {
    await topicApi.fetchDeleteRecommendList(payload);
    const { topic } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(
      fetchTopicCategorySaleRecommendList({
        ...topic.topicRecommendSaleListFilter,
        topicCategoryId: payload.topicCategoryId,
      }),
    );
    message.success("刪除成功");
    return "Success";
  },
);

export const fetchBrandList = createAsyncThunk("sale/fetchBrandList", async (payload: BrandListConfig) => {
  const { data } = await BrandApi.fetchBrandList(payload);
  return data;
});

const topicSlice = createSlice({
  name: "topic",
  initialState,
  reducers: {
    addNewCategory: (state, action: PayloadAction<{ newNode: Categories; parentId: number }>) => {
      const { newNode, parentId } = action.payload;
      // add level1
      if (parentId === state.topicInfo!.id) {
        state.topicCategories.splice(state.topicCategories.length, 0, newNode);
      } else {
        // add level2
        const lvl1Item = state.topicCategories.find((item) => item.key === parentId);
        if (lvl1Item) {
          const nodeChild = lvl1Item.children;
          nodeChild.splice(nodeChild.length, 0, newNode);
        } else {
          // add level 3
          state.topicCategories.forEach((lvl1Node) => {
            const lvl2Parent = lvl1Node.children.find((lvl2Item) => lvl2Item.key === parentId);
            if (lvl2Parent) {
              const nodeChild = lvl2Parent.children;
              nodeChild.splice(nodeChild.length, 0, newNode);
            }
          });
        }
      }

      if (parentId === state.topicInfo!.id) {
        state.categoryParentId = state.topicInfo!.rootCategory;
      } else {
        state.categoryParentId = parentId;
      }
    },
    setNewCategoryParent: (state, action) => {
      state.categoryParentId = action.payload;
    },
    clearCategoryInfo: (state) => {
      state.categoryInfo = null;
    },
    clearTopicInfo: (state) => {
      state.categoryParentId = -1;
      state.topicInfo = null;
      state.topicCategories = [];
    },
    setIsSearching: (state, action) => {
      state.isSearching = action.payload;
    },
    setTopicRecommendSaleListFilter: (state, action) => {
      state.topicRecommendSaleListFilter = { ...state.topicRecommendSaleListFilter, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTopicList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTopicList.fulfilled, (state, action) => {
      state.topicList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchTopicList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchTopicSimpleUpdate.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTopicSimpleUpdate.fulfilled, (state, action) => {
      const findIndex = state.topicList.findIndex((item) => item.id === action.payload.id);

      if (findIndex !== -1) {
        state.topicList[findIndex] = action.payload;
      }

      state.isFetching = false;
      state.topicStatus = TopicStatus.UPDATE;
    });
    builder.addCase(fetchTopicSimpleUpdate.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchTopicFullUpdate.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTopicFullUpdate.fulfilled, (state) => {
      state.isFetching = false;
      state.topicStatus = TopicStatus.UPDATE;
    });
    builder.addCase(fetchTopicFullUpdate.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateMultiRank.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateMultiRank.fulfilled, (state) => {
      state.isFetching = false;
      state.topicStatus = TopicStatus.UPDATE;
    });
    builder.addCase(fetchUpdateMultiRank.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCreateTopic.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCreateTopic.fulfilled, (state, action) => {
      state.isFetching = false;
      state.topicStatus = TopicStatus.CREATE;
      state.topicShortInfo = action.payload;
    });
    builder.addCase(fetchCreateTopic.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchTopicInfo.pending, (state) => {
      state.isFetching = true;
      state.topicStatus = null;
      state.categoryStatus = null;
    });
    builder.addCase(fetchTopicInfo.fulfilled, (state, action) => {
      state.isFetching = false;
      state.topicInfo = action.payload;
    });
    builder.addCase(fetchTopicInfo.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchTopicCategories.pending, (state) => {
      state.isFetching = true;
      state.topicStatus = null;
    });
    builder.addCase(fetchTopicCategories.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isTagEdit = false;
      state.topicCategories = action.payload;
      state.categoryStatus = CategoryStatus.DEFAULT;
    });
    builder.addCase(fetchTopicCategories.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateCategoriesOrder.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateCategoriesOrder.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateCategoriesOrder.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCategoryInfo.pending, (state) => {
      state.isFetching = true;
      state.topicStatus = null;
      state.categoryStatus = null;
    });
    builder.addCase(fetchCategoryInfo.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryInfo = action.payload;
    });
    builder.addCase(fetchCategoryInfo.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCreateCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCreateCategory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryStatus = CategoryStatus.CREATE;
      state.categoryInfo = action.payload;
    });
    builder.addCase(fetchCreateCategory.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateCategory.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryStatus = CategoryStatus.UPDATE;
      state.categoryInfo = action.payload;
    });
    builder.addCase(fetchUpdateCategory.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchSalesCategories.pending, (state) => {
      state.isCategoryFetching = true;
    });
    builder.addCase(fetchSalesCategories.fulfilled, (state, action) => {
      state.isCategoryFetching = false;
      state.salesCategories = action.payload;
    });
    builder.addCase(fetchSalesCategories.rejected, (state) => {
      state.isCategoryFetching = false;
    });
    builder.addCase(fetchCategoriesTag.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCategoriesTag.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoriesTag = action.payload;
    });
    builder.addCase(fetchCategoriesTag.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCategoriesPutTags.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCategoriesPutTags.fulfilled, (state, action) => {
      state.isFetching = false;
      state.categoryInfo = action.payload;
      state.isTagEdit = true;
    });
    builder.addCase(fetchCategoriesPutTags.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCategoryRemoveTag.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCategoryRemoveTag.fulfilled, (state) => {
      state.isFetching = false;
      state.isTagEdit = true;
    });
    builder.addCase(fetchCategoryRemoveTag.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.brandList = action.payload;
    });
    builder.addCase(fetchTopicCategorySaleList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTopicCategorySaleList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.topicSaleListResult = action.payload;
    });
    builder.addCase(fetchTopicCategorySaleList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchTopicCategorySaleRecommendList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTopicCategorySaleRecommendList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.topicRecommendSaleListResult = action.payload;
    });
    builder.addCase(fetchTopicCategorySaleRecommendList.rejected, (state) => {
      state.isFetching = false;
    }); //
    builder.addCase(fetchUpdateRecommendList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateRecommendList.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateRecommendList.rejected, (state) => {
      state.isFetching = false;
    });
  },
});

export const topicState = (state: RootState) => state.topic;
export const {
  addNewCategory,
  clearTopicInfo,
  setNewCategoryParent,
  clearCategoryInfo,
  setIsSearching,
  setTopicRecommendSaleListFilter,
} = topicSlice.actions;
export default topicSlice.reducer;
