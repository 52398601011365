export enum ConditionTypeValue {
  NO_CONDITION,
  FULFIL_DOLLAR,
  FULFIL_UNIT,
}

const conditionTypeValue: {
  [type: number]: string;
} = {
  [ConditionTypeValue.NO_CONDITION]: "無條件",
  [ConditionTypeValue.FULFIL_DOLLAR]: "滿額",
  [ConditionTypeValue.FULFIL_UNIT]: "滿件",
};

export default conditionTypeValue;
