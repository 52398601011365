import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Button, Select, Table } from "antd";
import PageTitle from "@component/PageTitle";
import { FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import { ContentWrapper, CustomCenterRow, FlexBox, Header, PageText, SelectPageSize } from "src/styles/common";
import { RootState } from "@redux/rootReducer";
import DashBoard from "@component/Common/DashBoard";
import { useDispatch, useSelector } from "react-redux";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import { PcmItem, PcmLines } from "@api/brandOperation/pcmApi";
import { ProcessText } from "@page/Cost/constans";
import { fetchPcmList, updatePcmListParams, setShowPopup, resetPcmSlice } from "@redux/brandOperation/PcmSlice";
import useDebounce from "@hooks/useDebounce";
import { loadMoreManufacturerList, manufacturerState } from "@redux/brandOperation/manufacturerSlice";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { useNavigate } from "react-router-dom";
import Filter from "./Filter";
import EditPopup from "./EditPopup";

const ChildrenWrapper = styled.div`
  line-height: 30px;
`;

const PurchaseOrder = () => {
  const [page, setPage] = useState<number>(1);
  const [showFilter, setShowFilter] = useState(false);
  const navigate = useNavigate();
  const { manufacturerSelectList, manufacturerInfo, isSelectLoading } = useSelector(manufacturerState);
  const [limit] = useState(10);

  const [offset, setOffset] = useState(-limit);

  const dispatch = useDispatch();
  const { pcmList, pcmListParams, isFetching, showPopup } = useSelector((state: RootState) => state.pcmSlice);

  const dashBoardData = useMemo(
    () => [
      {
        name: "總採購量",
        value: pcmList.totalAmount,
        note: "總採購量為實際到貨及預期到貨混合加總，若有實際到貨數則採用，若無則以預期到貨數做計算",
      },
      {
        name: "付款金額",
        value: pcmList.totalPayment,
        note: "付款金額為實際付款金額及預期付款金額混合加總，若有實際付款金額則採用，若無則以預期付款金額做計算",
      },
    ],
    [pcmList.totalAmount, pcmList.totalPayment],
  );

  const handleOnPageSizeChange = (value: SelectValue) => {
    dispatch(
      updatePcmListParams({
        ...pcmListParams,
        limit: Number(value),
      }),
    );
  };

  const handlePageChange = (value: number) => {
    setPage(value);
    dispatch(
      updatePcmListParams({
        ...pcmListParams,
        offset: pcmListParams.limit * (value - 1),
      }),
    );
  };

  const columns: ColumnsType<PcmItem> = [
    {
      key: "pcmNumber",
      dataIndex: "pcmNumber",
      title: "採購單號",
      fixed: "left",
      width: 120,
    },
    {
      key: "producerName",
      dataIndex: "producerName",
      title: "製造商",
      fixed: "left",
    },
    {
      key: "createdDate",
      dataIndex: "createdDate",
      title: "建立日期",
      fixed: "left",
      width: 120,
    },
    {
      key: "status",
      dataIndex: "status",
      title: "狀態",
      fixed: "left",
      width: 120,
      render: (data) => {
        return <div>{ProcessText[data]}</div>;
      },
    },
    {
      key: "deployDate",
      dataIndex: "deployDate",
      title: "下單日期",
      fixed: "left",
      width: 120,
    },
    {
      key: "no",
      dataIndex: "no",
      title: "國際條碼",
      width: 120,
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.no}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "中文品名",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.name}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "nameEn",
      dataIndex: "nameEn",
      width: 120,
      title: "英文品名",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.nameEn}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "effectiveDate",
      dataIndex: "effectiveDate",
      width: 120,
      title: "效期",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.effectiveDate}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "orderCcs",
      dataIndex: "orderCcs",
      width: 120,
      title: "下單 pcs 數",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.orderPcs}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "price",
      dataIndex: "price",
      width: 120,
      title: "單價（含稅）",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.price}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "estPcmAmount",
      dataIndex: "estPcmAmount",
      width: 120,
      title: "預期採購金額",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.estPcmAmount}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "estArrivedDate",
      dataIndex: "estArrivedDate",
      width: 120,
      title: "預期到貨時間",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.estArrivedDate}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "estPaymentDate",
      dataIndex: "estPaymentDate",
      width: 120,
      title: "預期付款時間",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) =>
              pcmLine.cashflows.map((cashflow) => {
                return <ChildrenWrapper>{cashflow.estPaymentDate}</ChildrenWrapper>;
              }),
            )}
          </div>
        );
      },
    },
    {
      key: "estPaymentAmount",
      dataIndex: "estPaymentAmount",
      width: 120,
      title: "預期付款金額",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) =>
              pcmLine.cashflows.map((cashflow) => {
                return <ChildrenWrapper>{cashflow.estPaymentAmount}</ChildrenWrapper>;
              }),
            )}
          </div>
        );
      },
    },
    {
      key: "arrivedQty",
      dataIndex: "arrivedQty",
      width: 120,
      title: "實際到貨數量",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.arrivedQty}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "pcmAmount",
      dataIndex: "pcmAmount",
      width: 120,
      title: "實際採購金額",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.pcmAmount}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "arrivedDate",
      dataIndex: "arrivedDate",
      width: 120,
      title: "實際到貨時間",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) => {
              return <ChildrenWrapper>{pcmLine.arrivedDate}</ChildrenWrapper>;
            })}
          </div>
        );
      },
    },
    {
      key: "paymentDate",
      dataIndex: "paymentDate",
      width: 120,
      title: "實際付款時間",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) =>
              pcmLine.cashflows.map((cashflow) => {
                return <ChildrenWrapper>{cashflow.paymentDate}</ChildrenWrapper>;
              }),
            )}
          </div>
        );
      },
    },
    {
      key: "paymentAmount",
      dataIndex: "paymentAmount",
      width: 120,
      title: "實際付款金額",
      render: (_, value) => {
        return (
          <div>
            {value.pcmLines.map((pcmLine: PcmLines) =>
              pcmLine.cashflows.map((cashflow) => {
                return <ChildrenWrapper>{cashflow.paymentAmount}</ChildrenWrapper>;
              }),
            )}
          </div>
        );
      },
    },
    {
      key: "buttons",
      dataIndex: "",
      title: "",
      render: (_, values) => {
        return (
          <Button type="link" onClick={() => navigate(`/operation-of-brand/procurement/${values.id}/preview`)}>
            檢視
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchPcmList(pcmListParams));
  }, [dispatch, pcmListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetPcmSlice());
    };
  }, [dispatch]);

  const handleOnLoadMore = useCallback(() => {
    setOffset(offset + limit);
  }, [limit, offset]);

  const handleOnKeywordSearch = useDebounce((value: string) => {
    dispatch(loadMoreManufacturerList({ nameQ: value, offset: 0, limit: 300 }));
  }, 300);

  const vendorOptions = useMemo(() => {
    const { next, results } = manufacturerSelectList;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id || ""}>
        {vdr.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [handleOnLoadMore, manufacturerSelectList]);

  useEffect(() => {
    dispatch(loadMoreManufacturerList({ limit, offset: offset + limit }));
  }, [dispatch, limit, offset]);

  return (
    <ContentWrapper>
      <PageTitle title="採購單" />
      <Header>
        <FlexBox>
          <Button type="primary" icon={<PlusCircleFilled />} onClick={() => dispatch(setShowPopup(!showPopup))}>
            新增採購單
          </Button>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
        </FlexBox>
        <CustomCenterRow>
          <PageText>{`總共${pcmList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as string)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </CustomCenterRow>
      </Header>
      {showFilter && (
        <Filter
          handleOnKeywordSearch={handleOnKeywordSearch}
          vendorOptions={vendorOptions}
          isSelectLoading={isSelectLoading}
        />
      )}
      {showPopup && (
        <EditPopup
          visible={showPopup}
          onClose={() => dispatch(setShowPopup(!showPopup))}
          handleOnKeywordSearch={handleOnKeywordSearch}
          vendorOptions={vendorOptions}
          isSelectLoading={isSelectLoading}
          manufacturerInfo={manufacturerInfo}
        />
      )}
      <DashBoard dataset={dashBoardData} isFetching={isFetching} />
      <Table
        loading={isFetching}
        scroll={{ x: "max-content", y: "calc(100vh - 250px)" }}
        columns={columns}
        dataSource={pcmList.results}
        rowKey="id"
        size="small"
        pagination={{
          pageSize: pcmListParams.limit,
          current: page,
          showSizeChanger: false,
          total: pcmList.count,
          onChange: handlePageChange,
        }}
      />
    </ContentWrapper>
  );
};

export default PurchaseOrder;
