import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";

import { SelectValue } from "antd/lib/select";

import { Modal, Button, Select } from "antd";

interface Props {
  visible: boolean;
  onClose: () => void;
  onSubmit: (isActive: boolean) => void;
  defaultValue: number;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  padding: 32px 28px 20px 32px;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  font-weight: bold;
  margin-bottom: 32px;
`;
const SelectRow = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 20px;
`;
const StyledSelect = styled(Select)`
  width: 180px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;

const statusOptions = [
  {
    value: -1,
    label: "請選擇",
  },
  {
    value: 0,
    label: "下架",
  },
  {
    value: 1,
    label: "上架",
  },
];

const BatchActivePopup: FC<Props> = (props) => {
  const { visible, onClose, onSubmit, defaultValue } = props;

  const [status, setStatus] = useState<number>(-1);

  useEffect(() => {
    setStatus(defaultValue);
  }, [defaultValue]);

  const handleOnSelect = (value: SelectValue) => {
    setStatus(value as number);
  };

  const handleConfirm = () => {
    if (defaultValue !== status) {
      onSubmit(status === 1);
    } else {
      onClose();
    }
  };

  return (
    <Modal visible={visible} closable={false} footer={false} bodyStyle={{ padding: 0, width: 520, height: 223 }}>
      <Wrapper>
        <Title>批次修改上下架狀態</Title>
        <SelectRow>
          <RowTitle>狀態</RowTitle>
          <StyledSelect
            options={statusOptions}
            value={status}
            onChange={(value) => handleOnSelect(value as SelectValue)}
          />
        </SelectRow>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" onClick={handleConfirm} disabled={status === -1}>
            確定
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default BatchActivePopup;
