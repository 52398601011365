import PopupBackground from "@component/PopupBackground";
import { Button, Form, InputNumber, message, Modal, Row, Table } from "antd";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { OrderSalesPlans, SubOrder } from "@api/utils/normalizeOrder";
import { ColumnsType } from "antd/lib/table";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { PlanQty } from "@api/orderApi";
import OrderPlanAnyOptionsModal from "./OrderPlanAnyOptionsModal";
import CVSPopup from "./CVSPopup";
import SalePageTypeValue from "../SalePageTypeValue";

const Wrapper = styled.div`
  width: 820px;
  height: 520px;
  padding: 24px 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 16px 1fr;
`;

const Title = styled.div`
  ${fontStyle("16px", "16px", "700")};
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 32px;
`;
const TableWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
`;
const ErrorHelpText = styled.span`
  ${fontStyle("14px", "16px", "400")};
  color: ${({ theme }) => theme.colorSecondary500};
`;
const ButtonWrapper = styled.div``;
const ReselectButton = styled(Button)`
  padding: 0;
`;
const AnyOptionsText = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
interface ChangedOrderSalesPlans extends OrderSalesPlans {
  changedQty: number;
}

type Props = {
  order: SubOrder;
  close: () => void;
};

type FormInputValue = { [key: string]: number };

const initialPlan = {
  id: -1,
  price: -1,
  salesDiscount: -1,
  salesRuleDiscount: -1,
  memberGroupDiscount: -1,
  salesPlanSpecialPrice: -1,
  media: "",
  qty: -1,
  optionsCount: -1,
  backOrderQty: -1,
  salesPlanName: "",
  salesPlanRuleType: 4,
  salesPageId: -1,
  salesPageName: "",
  salesPageType: 1,
  optionsText: "",
  salesPlanOptions: [
    {
      optionName: "",
      optionQty: -1,
      groupQty: -1,
      productId: -1,
      optionBackOrderQty: -1,
      optionCanceledQty: -1,
    },
  ],
  changedQty: -1,
  canceledQty: -1,
};

enum ErrorStatus {
  MinQtyError = "訂購數量錯誤，數量最小值=1",
  optionNotSelected = "選項數量尚未選擇",
}

export default function OrderPlanQty(props: Props) {
  const { close, order } = props;
  const { orderSalesPlans } = order;

  const changedSalesPlanList = orderSalesPlans.map((plan) => ({ ...plan, changedQty: plan.qty }));
  const [form] = Form.useForm();
  const [orderSalesPlansList, setOrderSalesPlansList] = useState(changedSalesPlanList);
  const [errorStatus, setErrorStatus] = useState<ErrorStatus | null>(null);
  const [openOptionsModal, setOpenOptionsModal] = useState(false);
  const [openCVS, setOpenCVS] = useState(false);
  const [finalPlanQty, setFinalPlanQty] = useState<PlanQty[]>([]);
  const [planOptions, setPlanOptions] = useState<ChangedOrderSalesPlans>(initialPlan);
  const [reselectedAnyPlans, setReselectedAnyPlans] = useState<PlanQty[]>([]);

  const columns: ColumnsType<ChangedOrderSalesPlans> = [
    {
      key: "salesPageName",
      dataIndex: "salesPageName",
      title: "銷售頁名稱",
      width: 280,
    },
    {
      key: "salesPlanName",
      dataIndex: "salesPlanName",
      title: "方案名稱",
      width: 160,
    },
    {
      key: "qty",
      dataIndex: "qty",
      title: "訂購數量",
      width: 100,
      render: (value: number, data: ChangedOrderSalesPlans) => {
        return (
          <Form.Item
            name={`planQty-${data.id}`}
            rules={[
              { required: true },
              () => ({
                validator(rule, values) {
                  return values !== 0 ? Promise.resolve() : Promise.reject();
                },
              }),
              () => ({
                validator(rule, values) {
                  if (data.salesPageType === SalePageTypeValue.Any && data.qty !== values) {
                    return Promise.reject();
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber max={value} min={0} type="number" style={{ width: "70px" }} onChange={onNumberChange} />
          </Form.Item>
        );
      },
    },
    {
      key: "optionsText",
      dataIndex: "optionsText",
      width: 140,
      title: "選項內容",
      render: (_: string, record: ChangedOrderSalesPlans) => {
        if (record.salesPageType === SalePageTypeValue.Any) {
          const renewOption = reselectedAnyPlans.find((reselected) => reselected.planId === record.id);
          const { salesPlanOptions } = record;
          let str = "";
          if (renewOption) {
            renewOption.salesPlanOption?.forEach((option) => {
              str += `${option.name}*${option.qty},`;
            });
          } else {
            salesPlanOptions.forEach((option) => {
              str += `${option.optionName}*${option.optionQty},`;
            });
          }
          return (
            <>
              <AnyOptionsText>{str.slice(0, -1)}</AnyOptionsText>
              <ReselectButton
                type="link"
                onClick={() => {
                  setPlanOptions(record);
                  setOpenOptionsModal(true);
                  setErrorStatus(null);
                }}
              >
                重新選擇數量
              </ReselectButton>
            </>
          );
        }
        return "-";
      },
    },
    {
      key: "id",
      dataIndex: "id",
      title: "",
      render: (value: number, record: OrderSalesPlans) => (
        <Button type="link" onClick={() => handlePlanDelete(value)}>
          刪除
        </Button>
      ),
    },
  ];

  const onNumberChange = (value: any) => {
    if (value === 0) {
      setErrorStatus(ErrorStatus.MinQtyError);
    }
  };

  const handlePlanQtyChange = (changedValues: FormInputValue, allValues: FormInputValue) => {
    setErrorStatus(null);
    const changedId = parseInt(Object.keys(changedValues)[0].replace("planQty-", ""), 10);
    const changedPlan = orderSalesPlansList.find((plan) => plan.id === changedId);
    if (changedPlan?.salesPageType === SalePageTypeValue.Any) {
      const changedPlanList = orderSalesPlansList.map((plan) => {
        return plan.id !== changedId ? plan : { ...plan, changedQty: Object.values(changedValues)[0] };
      });
      setOrderSalesPlansList(changedPlanList);
    }
  };

  const handlePlanDelete = (id: number) => {
    const salePlan = orderSalesPlansList.find((plan) => plan.id === id);
    Modal.confirm({
      title: `你確定要刪除${salePlan?.salesPlanName}?`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        const deletedList = orderSalesPlansList.filter((plan) => plan.id !== id);
        setOrderSalesPlansList(deletedList);
        message.success("刪除成功");
      },
    });
  };
  const handleOnNext = () => {
    try {
      const payload = orderSalesPlansList.map((plan) => {
        const qty = form.getFieldValue(`planQty-${plan.id}`);

        let salesPlanOption;
        if (qty === 0) throw ErrorStatus.MinQtyError;
        switch (plan.salesPageType) {
          case SalePageTypeValue.Any:
            salesPlanOption = reselectedAnyPlans.find((reselected) => reselected.planId === plan.id);
            if (plan.qty !== plan.changedQty) throw new Error();
            if (salesPlanOption) {
              const options = salesPlanOption.salesPlanOption;
              return {
                planId: plan.id,
                qty,
                salesPlanOption: options.map((option) => ({
                  salesPlanOptionId: option.salesPlanOptionId,
                  qty: option.qty,
                })),
              };
            }
            return {
              planId: plan.id,
              qty,
            };
          case SalePageTypeValue.Simple:
          case SalePageTypeValue.Group:
          default:
            return {
              planId: plan.id,
              qty,
            };
        }
      });
      setFinalPlanQty((payload as unknown) as PlanQty[]);
      setOpenCVS(true);
    } catch (error: any) {
      setErrorStatus(ErrorStatus.optionNotSelected);
    }
  };

  const handleOnOptionReselected = (planQty: PlanQty) => {
    const newList = orderSalesPlansList.map((plan) =>
      plan.id === planQty.planId ? { ...plan, qty: planQty.qty } : plan,
    );
    setOrderSalesPlansList(newList);
    setReselectedAnyPlans([...finalPlanQty, planQty]);
  };

  useEffect(() => {
    const planQty: FormInputValue = {};
    orderSalesPlansList.forEach((plan) => {
      planQty[`planQty-${plan.id}`] = plan.qty;
    });
    form.setFieldsValue(planQty);
    // 只有初次render 須同步form和orderSalesPlansList的數量
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  useEffect(() => {
    form.validateFields();
  }, [orderSalesPlansList, form]);

  return (
    <PopupBackground close={close}>
      <Wrapper>
        <Row>
          <Title>確認訂單方案數量</Title>
        </Row>
        <Form form={form} colon={false} onValuesChange={handlePlanQtyChange}>
          <Grid>
            <TableWrapper>
              <Table
                columns={columns}
                dataSource={orderSalesPlansList}
                pagination={false}
                scroll={{ x: 600, y: 350 }}
              />
            </TableWrapper>
            <Footer>
              <ErrorHelpText>{errorStatus}</ErrorHelpText>
              <ButtonWrapper>
                <CancelButton onClick={close}>取消</CancelButton>
                <Button type="primary" onClick={handleOnNext}>
                  下一步
                </Button>
              </ButtonWrapper>
            </Footer>
          </Grid>
        </Form>
        {openOptionsModal && (
          <OrderPlanAnyOptionsModal
            plan={planOptions}
            close={() => setOpenOptionsModal(false)}
            onSubmit={handleOnOptionReselected}
          />
        )}
        {openCVS && (
          <CVSPopup order={order} close={() => setOpenCVS(false)} finalPlanQty={finalPlanQty} onFinished={close} />
        )}
      </Wrapper>
    </PopupBackground>
  );
}
