import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import recostApi, { CostResult, CostList, CostDetail, CostFilter } from "@api/recostApi";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  costList: CostList;
  checkedProduct: {
    id?: number;
    name: string;
    originCost?: number;
  };
  costDetail: CostDetail;
  costFilter: CostFilter;
  showImportFilePopup: boolean;
  isFetching: boolean;
  checkedError: {
    message?: string;
    name?: string;
    stack?: string;
  };
  isCheckPass: boolean;
  isPending: boolean;
}

export const initialState: IState = {
  costList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  checkedProduct: {
    id: undefined,
    name: "",
    originCost: undefined,
  },
  costFilter: {
    status: undefined,
    type: undefined,
    skuNo: undefined,
    limit: 20,
  },
  costDetail: {
    id: undefined,
    staff: {
      id: 0,
      name: "",
    },
    type: -1,
    status: 0,
    skuNo: "",
    name: "",
    originCost: undefined,
    afterCost: undefined,
    startAt: "",
    endAt: "",
    description: "",
    affectOrders: undefined,
    affectQty: undefined,
    diffMarginRatio: undefined,
    submittedAt: "",
    approvedAt: "",
    createdAt: "",
    updateAt: "",
  },
  showImportFilePopup: false,
  isFetching: false,
  checkedError: {
    message: undefined,
    name: undefined,
    stack: undefined,
  },
  isCheckPass: true,
  isPending: false,
};

export const fetchCostList = createAsyncThunk("recost/fetchCostList", async (_, thunkApi) => {
  const {
    recostSlice: { costFilter },
  } = thunkApi.getState() as RootState;
  const response = await recostApi.fetchCostList(costFilter);
  return response;
});
export const fetchCheckProduct = createAsyncThunk(
  "recost/fetchCheckProduct",
  async (params: { skuNo: string; type: number }, thunkApi) => {
    const response = await recostApi.fetchCheckProduct(params);
    return response;
  },
);
export const fetchRecostDetail = createAsyncThunk("recost/fetchRecostDetail", async (id: number, thunkApi) => {
  const response = await recostApi.fetchRecostDetail(id);
  return response;
});
export const fetchProcess = createAsyncThunk(
  "recost/fetchProcess",
  async (params: { processType: number; id: number }, thunkApi) => {
    try {
      await recostApi.fetchProcess(params);
      message.success("已送出");
      thunkApi.dispatch(fetchRecostDetail(params.id));
      thunkApi.dispatch(fetchCostList());
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchCreatCost = createAsyncThunk("recost/fetchCreatCost", async (params: CostResult, thunkApi) => {
  try {
    const response = await recostApi.fetchCreatCost(params);
    thunkApi.dispatch(fetchCostList());
    message.success("新增成功");
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});
export const fetchUpdateCost = createAsyncThunk(
  "recost/fetchUpdateCost",
  async (payload: { params: CostResult; id: number }, thunkApi) => {
    try {
      const response = await recostApi.fetchUpdateCost(payload);
      thunkApi.dispatch(fetchCostList());
      thunkApi.dispatch(fetchRecostDetail(payload.id));
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchDeleteCost = createAsyncThunk("recost/fetchCreatCost", async (id: number, thunkApi) => {
  try {
    await recostApi.fetchDeleteCost(id);
    thunkApi.dispatch(fetchCostList());
    message.success("刪除成功");

    return "success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});
export const fetchUploadCost = createAsyncThunk("recost/fetchUploadCost", async (file: File, thunkApi) => {
  try {
    const response = await recostApi.fetchUploadCost(file);
    thunkApi.dispatch(fetchCostList());
    message.success("建檔完成，請檢視建檔結果後送出申請");
    return response;
  } catch (error: any) {
    const errorResults = Object.values(error.result);
    errorResults.forEach((errorResult: any) => {
      return message.error(errorResult);
    });
    return thunkApi.rejectWithValue(error);
  }
});

const recostSlice = createSlice({
  name: "recost",
  initialState,
  reducers: {
    resetRecostSlice: () => initialState,
    setCostFilter: (state, action) => {
      state.costFilter = action.payload;
    },
    setImportFilePopup: (state, action) => {
      state.showImportFilePopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCostList.fulfilled, (state, action) => {
      state.costList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchCostList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCheckProduct.fulfilled, (state, action) => {
      state.checkedProduct = action.payload;
      state.isCheckPass = true;
    });
    builder.addCase(fetchCheckProduct.rejected, (state, action) => {
      state.checkedError = action.error;
      state.isCheckPass = false;
    });
    builder.addCase(fetchRecostDetail.fulfilled, (state, action) => {
      state.costDetail = action.payload;
    });
    builder.addCase(fetchUploadCost.fulfilled, (state) => {
      state.showImportFilePopup = false;
      state.isPending = false;
    });
    builder.addCase(fetchUploadCost.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(fetchUploadCost.rejected, (state) => {
      state.isPending = false;
    });
    builder.addCase(fetchCreatCost.fulfilled, (state) => {
      state.isPending = false;
    });
    builder.addCase(fetchCreatCost.pending, (state) => {
      state.isPending = true;
    });
    builder.addCase(fetchUpdateCost.fulfilled, (state) => {
      state.isPending = false;
    });
    builder.addCase(fetchUpdateCost.pending, (state) => {
      state.isPending = true;
    });
  },
});

export const { resetRecostSlice, setCostFilter, setImportFilePopup } = recostSlice.actions;
export const recostState = (state: RootState) => state.recostSlice;
export default recostSlice.reducer;
