import React, { FC, useState, ChangeEvent } from "react";
import styled from "styled-components";
import { Input, Button } from "antd";
import PopupBackground from "@component/PopupBackground";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 200px;
  padding: 32px 28px 20px 28px;
  background-color: white;
`;
const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 16px;
`;
const Red = styled.span`
  color: red;
`;
const StyledInput = styled(Input)<{ error: boolean }>`
  width: 395px;
  ${({ error }) =>
    error &&
    `
    border: 1px solid red;
  `}
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Annotation = styled.div`
  font-size: 13px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const ErrorText = styled.div`
  margin-top: 18px;
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
`;

interface Props {
  onClose: () => void;
  onSubmit: (code: string, setErrorMessage: React.Dispatch<React.SetStateAction<string>>) => void;
  defaultValue: string;
}

const validateCode = (value: string) => {
  if (value.length < 3) return false;
  if (value.length > 16) return false;
  return /^[a-zA-Z0-9]*$/.test(value);
};

const SingleCouponSetup: FC<Props> = (props) => {
  const { onClose, onSubmit, defaultValue } = props;

  const [code, setCode] = useState<string>(defaultValue);
  const [isCodeValid, setIsCodeValid] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const validValue = validateCode(value);

    setCode(value);
    setIsCodeValid(validValue);
  };

  const handleOnSubmit = async () => {
    if (isCodeValid) {
      onSubmit(code ?? "", setErrorMessage);
    }
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <FlexRow>
          <Title>
            兌換碼序號
            <Red>*</Red>
          </Title>
          <StyledInput value={code} onChange={handleOnChange} error={!isCodeValid} />
        </FlexRow>
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        <Footer>
          <Annotation>註: 僅限輸入大小寫英文、數字，且限制3~16字</Annotation>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" onClick={handleOnSubmit}>
              確定
            </Button>
          </ButtonContainer>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default SingleCouponSetup;
