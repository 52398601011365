import SocialTypeValue from "@constant/SocialTypeValue";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI({
  apiVersion: "",
});

interface IAdminApi {
  socialLogin: (params: SocialLoginParams) => Promise<TokenInfo>;
}

export interface SocialLoginParams {
  provider: SocialTypeValue;
  socialInfo: {
    socialAccessToken: string;
  };
}

export type TokenInfo = {
  userId: number;
  userName: string;
  tokenType: string;
  accessToken: string;
  expiresAt: number;
  staffPermission: string[];
};

const adminApi: IAdminApi = {
  socialLogin: async (params) => {
    const postParams = transformCamelToSnake(params);
    const response = await apiClient.postData("/admin/social-login/", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default adminApi;
