enum ProcessingType {
  NORMAL = 0,
  REPLENISHMENT = 1,
  EXCHANGE = 2,
  RETURN = 3,
}

export const ProcessingTypeMap: { [key: number]: string } = {
  0: "一般出貨",
  1: "補貨",
  2: "換貨",
  3: "退貨",
};

export default ProcessingType;
