/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { CategorySalesChartItem, CategorySalesChart } from "@api/dashboardApi";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  hoverAction,
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

const CategoryRevenueAndGrossProfitChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [revenueChartOptions, setRevenueChartOptions] = useState<Highcharts.Options>();
  const [grossProfitChartOption, setGrossProfitChartOption] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const { currentData, compareData = [] } = chartResult as CategorySalesChart;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);

    /* 產出時間區間所有單位時間 */
    const filledCurrentTimeMap = getFilledTimeData<CategorySalesChartItem>(startDate, endDate, [], timeUnit);
    const filledCompareTimeMap = getFilledTimeData<CategorySalesChartItem>(cStartDate, cEndDate, [], timeUnit);
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    /* --- end ---- */

    const formatCurrentDataByCategory = new Map<string, CategorySalesChartItem[]>();
    currentData.forEach((item) => {
      const { categoryName } = item;
      const categoryDataArray = formatCurrentDataByCategory.get(categoryName);

      if (categoryDataArray) {
        categoryDataArray.push(item);
      } else {
        formatCurrentDataByCategory.set(categoryName, [item]);
      }
    });

    const formatCompareDataByCategory = new Map<string, CategorySalesChartItem[]>();
    compareData.forEach((item) => {
      const { categoryName } = item;
      const categoryDataArray = formatCompareDataByCategory.get(categoryName);

      if (categoryDataArray) {
        categoryDataArray.push(item);
      } else {
        formatCompareDataByCategory.set(categoryName, [item]);
      }
    });

    /* 分Category塞資料 */
    const revenueSeries: Highcharts.SeriesOptionsType[] = [];
    const grossProfitSeries: Highcharts.SeriesOptionsType[] = [];

    for (const [categoryName, categorySalesItems] of formatCurrentDataByCategory) {
      const filledTimeCategoryMap = getFilledTimeData<CategorySalesChartItem>(
        startDate,
        endDate,
        categorySalesItems,
        timeUnit,
      );

      const currentRevenue: (number | null)[] = [];
      const currentGrossProfit: (number | null)[] = [];

      filledTimeCategoryMap.forEach((item) => {
        const { revenue = null, grossProfit = null } = item;
        currentRevenue.push(revenue);
        currentGrossProfit.push(grossProfit);
      });

      const categoryRevenueSeries: Highcharts.SeriesOptionsType = {
        name: categoryName,
        type: "line",
        data: currentRevenue,
        stack: "current",
        pointStart,
      };
      const categoryGrossProfitSeries: Highcharts.SeriesOptionsType = {
        name: categoryName,
        type: "line",
        data: currentGrossProfit,
        stack: "current",
      };

      revenueSeries.push(categoryRevenueSeries);
      grossProfitSeries.push(categoryGrossProfitSeries);
    }

    for (const [categoryName, categorySalesItems] of formatCompareDataByCategory) {
      const filledTimeCategoryMap = getFilledTimeData<CategorySalesChartItem>(
        cStartDate,
        cEndDate,
        categorySalesItems,
        timeUnit,
      );

      const compareRevenue: (number | null)[] = [];
      const compareGrossProfit: (number | null)[] = [];

      filledTimeCategoryMap.forEach((item) => {
        const { revenue = null, grossProfit = null } = item;
        compareRevenue.push(revenue);
        compareGrossProfit.push(grossProfit);
      });

      const categoryRevenueSeries: Highcharts.SeriesOptionsType = {
        name: `${categoryName} - 相較於`,
        type: "line",
        data: compareRevenue,
        stack: "compare",
        pointStart,
        visible: false,
      };
      const categoryGrossProfitSeries: Highcharts.SeriesOptionsType = {
        name: `${categoryName} - 相較於`,
        type: "line",
        data: compareGrossProfit,
        stack: "compare",
        pointStart,
        visible: false,
      };

      revenueSeries.push(categoryRevenueSeries);
      grossProfitSeries.push(categoryGrossProfitSeries);
    }

    setRevenueChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "{text}",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointName = this.point.series.userOptions.name;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const value = USCurrencyFormmater.format(this.point.y || 0);

          return `${title}<br/>${pointName} ${value}`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
        column: {
          stacking: "normal",
          states: {
            inactive: {
              enabled: false,
            },
          },
          point: {
            events: {
              mouseOver() {
                hoverAction(this, "hover");
              },
              mouseOut() {
                hoverAction(this, "");
              },
            },
          },
        },
      },
      series: revenueSeries,
    });

    setGrossProfitChartOption({
      title: {
        text: "子分類毛利",
        align: "left",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "{text}",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointName = this.point.series.userOptions.name;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const value = USCurrencyFormmater.format(this.point.y || 0);

          return `${title}<br/>${pointName} ${value}`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
        column: {
          stacking: "normal",
          states: {
            inactive: {
              enabled: false,
            },
          },
          point: {
            events: {
              mouseOver() {
                hoverAction(this, "hover");
              },
              mouseOut() {
                hoverAction(this, "");
              },
            },
          },
        },
      },
      series: grossProfitSeries,
    });
  }, [chartResult, chartParams]);

  const fetchCategorySalesChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.CATEGORY_SALES));
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <ChartWrapper>
          <PageTitle title="Dashboard - 子分類營收" />
          <ChartFilter title="子分類營收" fetchAction={fetchCategorySalesChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={revenueChartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
          <HighchartsReact
            highcharts={Highcharts}
            options={grossProfitChartOption}
            containerProps={{ style: { height: 500 } }}
          />
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default CategoryRevenueAndGrossProfitChart;
