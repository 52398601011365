import Cookies from "js-cookie";

export class Cookie<S extends string, T = any> {
  public setItem = (key: S, value: T): void => {
    const convertValue = JSON.stringify(value);
    Cookies.set(key, convertValue);
  };

  public setExpireItem = (key: S, value: T, expireTime: number | Date): void => {
    const convertValue = JSON.stringify(value);
    Cookies.set(key, convertValue, { expires: expireTime });
  };

  public getItem = (key: S): any => {
    const value = Cookies.get(key);
    if (value && JSON.stringify(value) !== "{}") {
      return JSON.parse(value);
    }
    return null;
  };

  public getAllItem = (): { [key: string]: string } => {
    const value = Cookies.get();
    if (value && JSON.stringify(value) !== "{}") {
      const object: { [key: string]: string } = {};
      Object.keys(value).forEach((key: string) => {
        object[key] = JSON.parse(value[key]);
      });
      return object;
    }
    return value;
  };

  public removeItem = (key: S): void => {
    Cookies.remove(key);
  };

  public removeAll = (): void => {
    Object.keys(Cookies.get()).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
  };
}

const cookieInstance = new Cookie();
export default cookieInstance;
