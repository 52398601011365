import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { ExcessInventoryList, ExcessInventoryFilter } from "@api/financeApi";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  excessInventoryList: ExcessInventoryList;
  excessInventoryFilter: ExcessInventoryFilter;
}

export const initialState: IState = {
  isFetching: false,
  excessInventoryList: {
    totalAmount: 0,
    count: 0,
    details: [
      {
        sku: "",
        owner: "",
        productName: "",
        brand: "",
        vendor: "",
        canSaleDate: "",
        effectiveStock: 0,
        amountOfTheMonth: 0,
      },
    ],
  },
  excessInventoryFilter: {
    dateAfter: undefined,
    dateBefore: undefined,
    sku: undefined,
    brand: undefined,
    limit: 20,
    offset: 0,
    month: undefined,
  },
};

export const fetchExcessInventoryList = createAsyncThunk(
  "excessInventory/fetchExcessInventoryList",
  async (params: ExcessInventoryFilter) => {
    const response = await financeApi.fetchExcessInventoryList(params);
    return response;
  },
);

const excessInventorySlice = createSlice({
  name: "excessInventory",
  initialState,
  reducers: {
    resetExcessInventorySlice: () => initialState,
    setExcessInventoryFilter: (state, action) => {
      state.excessInventoryFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchExcessInventoryList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchExcessInventoryList.fulfilled, (state, action) => {
      state.excessInventoryList = action.payload;
      state.isFetching = false;
    });
  },
});

export const excessInventoryState = (state: RootState) => state.excessInventorySlice;
export const { resetExcessInventorySlice, setExcessInventoryFilter } = excessInventorySlice.actions;
export default excessInventorySlice.reducer;
