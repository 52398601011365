import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Spin } from "antd";
import { InfoCircleTwoTone, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;

const RevenueCard: FC = () => {
  const isFetchingRevenueDashboard = useSelector((state: RootState) => state.dashboard.isFetchingRevenueDashboard);
  const revenueDashboard = useSelector((state: RootState) => state.dashboard.revenueDashboard);
  const { currentData, diff } = revenueDashboard;
  const { revenue = 0, webRevenue = 0, appRevenue = 0 } = currentData || {};
  const { revenueDiff = 0, webRevenueDiff = 0, appRevenueDiff = 0 } = diff || {};

  const revenueCurrency = USCurrencyFormmater.format(revenue);
  const revenueTagColor = revenueDiff < 0 ? "error" : "success";
  const revenueTagIcon = revenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const revenueChangePercentage = Math.round(revenueDiff * 1000) / 10; // 百分比取小數點第一位

  const webRevenueCurrency = USCurrencyFormmater.format(webRevenue);
  const webRevenueTagColor = webRevenueDiff < 0 ? "error" : "success";
  const webRevenueTagIcon = webRevenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const webRevenueChangePercentage = Math.round(webRevenueDiff * 1000) / 10; // 百分比取小數點第一位

  const appRevenueCurrency = USCurrencyFormmater.format(appRevenue);
  const appRevenueTagColor = appRevenueDiff < 0 ? "error" : "success";
  const appRevenueTagIcon = appRevenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const appRevenueChangePercentage = Math.round(appRevenueDiff * 1000) / 10;

  return (
    <Spin spinning={isFetchingRevenueDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>營收</HeaderTitle>
          <AnchorToChart href="/dashboard/revenue" />
        </Header>
        <Section>
          <SectionTitle>
            累計營收
            <Tooltip
              placement="bottom"
              title="所選日期間發生之銷貨收入(進項)減去取消單費用(減項)、黑卡會費及退款費用(減項)"
            >
              <InfoIcon />
            </Tooltip>
          </SectionTitle>
          <SectionContent>
            {revenueCurrency}
            <StyledTag color={revenueTagColor} icon={revenueTagIcon}>
              {`${revenueChangePercentage}%`}
            </StyledTag>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>Web營收</SectionTitle>
          <SectionContent>
            {webRevenueCurrency}
            <StyledTag color={webRevenueTagColor} icon={webRevenueTagIcon}>
              {`${webRevenueChangePercentage}%`}
            </StyledTag>
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>App營收</SectionTitle>
          <SectionContent>
            {appRevenueCurrency}
            <StyledTag color={appRevenueTagColor} icon={appRevenueTagIcon}>
              {`${appRevenueChangePercentage}%`}
            </StyledTag>
          </SectionContent>
        </Section>
      </Wrapper>
    </Spin>
  );
};

export default RevenueCard;
