/* eslint-disable @typescript-eslint/no-unused-vars */
import { FilterTwoTone, PlusCircleTwoTone, FormOutlined } from "@ant-design/icons";
import { SaleListItem } from "@api/saleApi";
import MemberGroupValue from "@constant/MemberGroupValue";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import VendorLogisticsValue, { MapLogisticValue } from "@page/Order/VendorLogisticsValue";
import { configState } from "@redux/configSlice";
import {
  fetchChangePriceDownload,
  fetchSaleList,
  SaleState,
  setSaleListParams,
  batchActiveSalePages,
  refreshChangePrice,
  resetSaleSlice,
} from "@redux/saleSlice";
import { Button, Checkbox, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { RootState } from "@redux/rootReducer";
import PageTitle from "@component/PageTitle";
import { hideLoading, showLoading } from "@redux/notifySlice";
import modal from "antd/lib/modal";
import { usePermission, StaffPermission } from "@hooks/usePermission";
import SaleListFilter from "./SaleListFilter";
import UploadModal from "./UploadModal";
import BatchActivePopup from "./BatchActivePopup";
import computeSalePagePrice from "./utils/computeSalePageListPrice";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
  position: relative;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const CustomLink = styled(Button)`
  padding: 0;
`;

const EditStatusIcon = styled(FormOutlined)<{ disabled: boolean }>`
  margin-left: 5px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;

export default function Sale() {
  const dispatch = useDispatch();
  const { webDomain } = useSelector(configState);
  const { isFetching, saleList, saleListParams, isDownloading } = useSelector(SaleState);
  const purgeSalesRelatedCache = usePermission(StaffPermission.CAN_PURGE_SALES_RELATED_CACHE);

  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showBatchActivePopup, setShowBatchActivePopup] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [checkSaleIds, setCheckSaleIds] = useState<Set<number>>(new Set());

  const activeDefaultValue = useMemo(() => {
    const matchedArray: SaleListItem[] = [];
    checkSaleIds.forEach((id: number) => {
      const matchItem = saleList.results.find((el) => el.id === id);
      if (matchItem) {
        matchedArray.push(matchItem);
      }
    });

    if (matchedArray.every((el) => el.isActive)) {
      return 1;
    }
    if (matchedArray.every((el) => !el.isActive)) {
      return 0;
    }
    return -1;
  }, [saleList, checkSaleIds]);

  const setPageLimit = (value: SelectValue) => {
    dispatch(setSaleListParams({ ...saleListParams, limit: parseInt(value as string, 10) }));
  };

  const toggleBatchActivePopup = useCallback(() => setShowBatchActivePopup((prev) => !prev), []);

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchSaleList({
        ...saleListParams,
        offset: (value - 1) * saleListParams.limit,
      }),
    );
  };

  const fetchDownload = () => {
    dispatch(
      fetchChangePriceDownload({
        ...saleListParams,
        pageIds: checkSaleIds.size !== 0 ? Array.from(checkSaleIds).map((id) => String(id)) : saleListParams.pageIds,
      }),
    );
  };

  const openSalePage = (id?: number) => {
    window.open(`${window.location.href}/edit/${id || "new"}`);
  };

  const onSetAllSalePageIds = (checked: boolean) => {
    const ids = saleList.results.map((item) => item.id);
    setCheckSaleIds(checked ? new Set(ids) : new Set());
  };

  const onSetSalePageId = (checked: boolean, id: number) => {
    if (checked) {
      setCheckSaleIds(new Set(checkSaleIds.add(id)));
    } else {
      const newSet = new Set(checkSaleIds);
      newSet.delete(id);
      setCheckSaleIds(newSet);
    }
  };

  const handleOnSubmit = (isActive: boolean) => {
    dispatch(batchActiveSalePages({ salePagesIds: [...checkSaleIds], isActive }));

    toggleBatchActivePopup();
  };

  const refreshChangePriceConfig = {
    title: "重整變價",
    content: (
      <>
        此功能將清理現有變價的快取，使用後將導致短期資料庫使用量提升，網站效能下降，若已知明確銷售頁變價異常請直接至該銷售頁做調整，勿使用此功能。
      </>
    ),
    okText: "確認",
    cancelText: "取消",
    onOk: () => {
      dispatch(refreshChangePrice());
    },
  };

  const columns: ColumnsType<SaleListItem> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: () => <Checkbox onChange={(e) => onSetAllSalePageIds(e.target.checked)} />,
      render: (_: any, others: SaleListItem) => (
        <Checkbox
          checked={checkSaleIds.has(others.id)}
          onChange={(e) => onSetSalePageId(e.target.checked, others.id)}
        />
      ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
      render: (id: number) => (
        <CustomLink type="link" onClick={() => window.open(`${webDomain}/salespage/${id}`)}>
          {id}
        </CustomLink>
      ),
    },
    {
      key: "name",
      dataIndex: "name",
      width: 300,
      title: "銷售頁名稱",
    },
    {
      title: (
        <Row align="middle">
          <div>狀態</div>
          <EditStatusIcon onClick={toggleBatchActivePopup} disabled={checkSaleIds.size === 0} />
        </Row>
      ),
      dataIndex: "isActive",
      render: (isActive: boolean) => <div>{isActive ? "上架" : "下架"}</div>,
    },
    {
      key: "pageType",
      dataIndex: "pageType",
      title: "類型",
      render: (pageType: SalePageTypeValue) => {
        switch (pageType) {
          case SalePageTypeValue.Simple:
            return <div>單一方案</div>;
          case SalePageTypeValue.Group:
            return <div>多方案</div>;
          case SalePageTypeValue.Any:
            return <div>任選方案</div>;
          default:
            return "";
        }
      },
    },
    {
      key: "brands",
      dataIndex: "brands",
      title: "品牌",
      render: (brands: string[]) => {
        const unique = [...new Set(brands)];
        return <div>{unique.toString()}</div>;
      },
    },
    {
      key: "logistic",
      dataIndex: "logistic",
      title: "出貨方式",
      render: (logistic: VendorLogisticsValue) => <div>{MapLogisticValue[logistic]}</div>,
    },
    {
      key: "memberGroupDiscount",
      dataIndex: "memberGroupDiscount",
      title: "黑卡會員價",
      render: (_: any, others: SaleListItem) => {
        const {
          vip: { minVipPrice, maxVipPrice },
        } = computeSalePagePrice(others);
        const rangeVipPrice = minVipPrice === maxVipPrice ? minVipPrice : `${minVipPrice}-${maxVipPrice}`;
        return <div>{rangeVipPrice}</div>;
      },
    },
    {
      key: "specialPrice",
      dataIndex: "specialPrice",
      title: "優惠價",
      render: (_: any, others: SaleListItem) => {
        const {
          special: { minSpecialPrice, maxSpecialPrice },
        } = computeSalePagePrice(others);
        const rangeSpecialPrice =
          minSpecialPrice === maxSpecialPrice ? minSpecialPrice : `${minSpecialPrice}-${maxSpecialPrice}`;
        return <div>{rangeSpecialPrice}</div>;
      },
    },
    {
      key: "price",
      dataIndex: "price",
      title: "售價",
      render: (_: any, others: SaleListItem) => {
        const {
          original: { minOriginalPrice, maxOriginalPrice },
        } = computeSalePagePrice(others);
        const rangeOriginalPrice =
          minOriginalPrice === maxOriginalPrice ? minOriginalPrice : `${minOriginalPrice}-${maxOriginalPrice}`;
        return <div>{rangeOriginalPrice}</div>;
      },
    },
    {
      key: "vipOnly",
      dataIndex: "vipOnly",
      title: "黑卡限購品",
      render: (_: any, others: SaleListItem) => {
        const memberGroup = {
          normal: false,
          vip: false,
          business: false,
        };

        others.groupCanBuy.forEach((element) => {
          if (element.groupId === MemberGroupValue.GENERAL) {
            memberGroup.normal = element.value;
          } else if (element.groupId === MemberGroupValue.VIP) {
            memberGroup.vip = element.value;
          } else if (element.groupId === MemberGroupValue.BUSINESS) {
            memberGroup.business = element.value;
          }
        });

        return <div>{memberGroup.vip && !memberGroup.normal && !memberGroup.business ? "是" : "否"}</div>;
      },
    },
    {
      key: "displayVipPrice",
      dataIndex: "displayVipPrice",
      title: "隱藏黑卡會員價",
      render: (displayVipPrice: boolean) => <div>{displayVipPrice ? "否" : "是"}</div>,
    },
    {
      key: "avgReviewScores",
      dataIndex: "avgReviewScores",
      title: "評價分數",
      render: (_: any, others: SaleListItem) => <div>{others.reviewInfo.avgReviewScores || "N/A"}</div>,
    },
    {
      key: "reviewCount",
      dataIndex: "reviewCount",
      title: "評價次數",
      render: (_: any, others: SaleListItem) => <div>{others.reviewInfo.reviewCount || "N/A"}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_: any, others: SaleListItem) => (
        <Button type="link" onClick={() => openSalePage(others.id)}>
          編輯
        </Button>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(
      fetchSaleList({
        ...saleListParams,
        offset: 0,
      }),
    );
  }, [dispatch, saleListParams]);

  useEffect(() => {
    return () => {
      dispatch(resetSaleSlice());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isDownloading) {
      dispatch(showLoading());
    } else {
      setTimeout(() => {
        dispatch(hideLoading());
      }, 300);
    }
  }, [isDownloading, dispatch]);

  return (
    <Wrapper>
      <PageTitle title="銷售頁" />
      <Row justify="space-between">
        <div>
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => openSalePage()}
          >
            新增銷售頁
          </CustomButton>
          <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </CustomButton>
          <CustomButton onClick={() => setUploadModal(true)}>匯入變價</CustomButton>
          <CustomButton onClick={() => modal.confirm(refreshChangePriceConfig)} disabled={!purgeSalesRelatedCache}>
            重整變價
          </CustomButton>
          <CustomButton onClick={fetchDownload}>匯出</CustomButton>
        </div>
        <Row align="middle">
          <PageText>{`總共${saleList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <SaleListFilter isVisible={showFilter} />
      <TableWrapper>
        <Table
          loading={isFetching}
          scroll={{ x: "max-content" }}
          columns={columns}
          dataSource={saleList.results}
          pagination={{
            pageSize: saleListParams.limit,
            current: page,
            showSizeChanger: false,
            total: saleList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {uploadModal && <UploadModal close={() => setUploadModal(false)} />}
      <BatchActivePopup
        visible={showBatchActivePopup}
        onClose={toggleBatchActivePopup}
        onSubmit={handleOnSubmit}
        defaultValue={activeDefaultValue}
      />
    </Wrapper>
  );
}
