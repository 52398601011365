import { ApprovalStatus } from "@api/throughShipment/throughShipmentApi";
import { externalOrderState } from "@redux/externalOrderSlice";
import { Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ChooseOrderList from "./ChooseOrderList";
import EditExternalOrder from "./EditExternalOrder";
import OrderFee from "./OrderFee";
import OrderPreview from "./OrderPreview";

const Wrapper = styled.div`
  padding: 20px 25px;

  [data-theme="compact"] .site-navigation-steps,
  .site-navigation-steps {
    box-shadow: 0px -1px 0 0 #e8e8e8 inset;
  }
`;
const Content = styled.div`
  margin-top: 20px;
  padding-bottom: 100px;
`;

export default function ExternalOrderInformation() {
  const { externalOrderInfo, isEditOrder } = useSelector(externalOrderState);

  const [currentStep, setCurrentStep] = useState<number>(0); // antd step 預設 number

  const approvalStatus = [
    externalOrderInfo?.financeApproval,
    externalOrderInfo?.managerApproval,
    externalOrderInfo?.sectionManagerApproval,
    externalOrderInfo?.ceoApproval,
  ];
  const atLeastOneApproved = approvalStatus.some((status) => status === ApprovalStatus.APPROVED);
  const cantEditPreviousStep = atLeastOneApproved || externalOrderInfo?.isSend;

  const onHandleChangeStep = (step: number) => {
    if (atLeastOneApproved && (step === 0 || step === 1)) {
      return;
    }

    if (step === 0 && currentStep === 1) {
      setCurrentStep(step);
    } else if (step <= 3 && currentStep >= 2) {
      setCurrentStep(step);
    }
  };

  const StepComponent = () => {
    switch (currentStep) {
      case 0:
        return <EditExternalOrder onNextStep={() => setCurrentStep(1)} />;
      case 1:
        return <ChooseOrderList onNextStep={() => setCurrentStep(2)} onBackStep={() => setCurrentStep(0)} />;
      case 2:
        return <OrderFee onNextStep={() => setCurrentStep(3)} onBackStep={() => setCurrentStep(1)} />;
      case 3:
        return <OrderPreview onBackStep={() => setCurrentStep(2)} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (isEditOrder && (atLeastOneApproved || (!atLeastOneApproved && externalOrderInfo?.isSend))) {
      setCurrentStep(2);
    }
  }, [atLeastOneApproved, externalOrderInfo?.isSend, isEditOrder]);

  return (
    <Wrapper>
      <Steps type="navigation" current={currentStep} className="site-navigation-steps" onChange={onHandleChangeStep}>
        <Steps.Step
          disabled={cantEditPreviousStep}
          status={currentStep >= 0 ? "process" : "wait"}
          title="建立訂單基本資料"
        />
        <Steps.Step
          disabled={cantEditPreviousStep}
          status={currentStep >= 1 ? "process" : "wait"}
          title="選擇訂單商品"
        />
        <Steps.Step status={currentStep >= 2 ? "process" : "wait"} title="費用填寫" />
        <Steps.Step status={currentStep >= 2 ? "process" : "wait"} title="訂單預覽" />
      </Steps>
      <Content>{StepComponent()}</Content>
    </Wrapper>
  );
}
