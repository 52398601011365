import PageTitle from "@component/PageTitle";
import { Button, Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchProcess, fetchRecostDetail, recostState } from "@redux/recostSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loading from "@component/Loading/Loading";
import EditPopup from "./EditPopup";
import { ProcessValue, DepartmentValue, ProcessText } from "./constans";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CustomButton = styled(Button)`
  margin-left: 10px;
`;
const ButtonWrapper = styled.div``;
const Text = styled.p`
  width: 133px;
  margin-bottom: 33px;
`;

const Content = styled(Text)`
  width: max-content;
`;
const ContentWrapper = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #f2f2f2;
`;
const Preview = () => {
  const [openForm, setOpenForm] = useState<boolean>(false);
  const { costDetail, isPending } = useSelector(recostState);
  const { id } = useParams();
  const [mode, setMode] = useState<string>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchRecostDetail(parseInt(id, 10)));
  }, [dispatch, id]);

  const handleOperPopup = (modeType: string) => {
    setMode(modeType);
    setOpenForm(!openForm);
  };

  return (
    <Wrapper>
      <PageTitle title="成本回壓檢視" />
      <EditPopup visible={openForm} onClose={() => setOpenForm(false)} recostId={parseInt(id, 10)} mode={mode} />
      <Header>
        成本回壓檢視
        <ButtonWrapper>
          <CustomButton
            type="primary"
            onClick={() => dispatch(fetchProcess({ processType: ProcessValue.PENDING, id: parseInt(id, 10) }))}
            disabled={
              costDetail.status === ProcessValue.PENDING ||
              costDetail.status === ProcessValue.APPROVE ||
              costDetail.status === ProcessValue.REJECT
            }
          >
            送出
          </CustomButton>
          <CustomButton
            onClick={() => dispatch(fetchProcess({ processType: ProcessValue.APPROVE, id: parseInt(id, 10) }))}
            disabled={
              costDetail.status === ProcessValue.CLEAN ||
              costDetail.status === ProcessValue.APPROVE ||
              costDetail.status === ProcessValue.REJECT
            }
          >
            審核通過
          </CustomButton>
          <CustomButton
            onClick={() => dispatch(fetchProcess({ processType: ProcessValue.REJECT, id: parseInt(id, 10) }))}
            disabled={
              costDetail.status === ProcessValue.CLEAN ||
              costDetail.status === ProcessValue.APPROVE ||
              costDetail.status === ProcessValue.REJECT
            }
          >
            審核不通過
          </CustomButton>
          <CustomButton
            onClick={() => handleOperPopup("edit")}
            disabled={costDetail.status === ProcessValue.PENDING || costDetail.status === ProcessValue.APPROVE}
          >
            編輯
          </CustomButton>
        </ButtonWrapper>
      </Header>
      {isPending && <Loading />}
      <ContentWrapper>
        <Row>
          <Text>申請人</Text>
          <Content>{costDetail.staff.name}</Content>
        </Row>
        <Row>
          <Text>部門</Text>
          <Content>{DepartmentValue[costDetail.type]}</Content>
        </Row>
        <Row>
          <Text>商品條碼/品號</Text>
          <Content>{costDetail.skuNo}</Content>
        </Row>
        <Row>
          <Text>商品名稱</Text>
          <Content>{costDetail.name}</Content>
        </Row>
        <Row>
          <Text>審核狀態</Text>
          <Content>{ProcessText[costDetail.status]}</Content>
        </Row>
        <Row>
          <Text>更改前成本</Text>
          <Content>{costDetail.originCost}</Content>
        </Row>
        <Row>
          <Text>更改後成本</Text>
          <Content>{costDetail.afterCost}</Content>
        </Row>
        <Row>
          <Text>回壓時間區間</Text>
          <Content>
            {costDetail.startAt} - {costDetail.endAt}
          </Content>
        </Row>
        <Row>
          <Text>影響訂單數</Text>
          <Content>{costDetail.affectOrders}</Content>
        </Row>
        <Row>
          <Text>影響銷量</Text>
          <Content>{costDetail.affectQty}</Content>
        </Row>
        <Row>
          <Text>成本差</Text>
          <Content>{costDetail.diffCost}</Content>
        </Row>
        <Row>
          <Text>毛利率差</Text>
          <Content>{costDetail.diffMarginRatio}</Content>
        </Row>
        <Row>
          <Text>送出申請時間</Text>
          <Content>{costDetail.submittedAt}</Content>
        </Row>
        <Row>
          <Text>審核通過時間</Text>
          <Content>{costDetail.approvedAt}</Content>
        </Row>
        <Row>
          <Text>回壓原因</Text>
          <Content>{costDetail.description}</Content>
        </Row>
      </ContentWrapper>
    </Wrapper>
  );
};

export default Preview;
