import { ExclamationCircleOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import MemberGroupValue, { memberGroupMap } from "@constant/MemberGroupValue";
import { fetchCreateGroupLimit, fetchUpdateGroupLimit, SaleState } from "@redux/saleSlice";
import { Button, DatePicker, InputNumber, message, Modal, Row, Select, Table } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 80vh;
  overflow: auto;

  .ant-table-tbody > tr > td {
    border: 0;
  }

  .ant-table-cell {
    padding: 10px;
  }
`;

const CustomButton = styled(Button)`
  margin-left: 8px;
`;

const CustomRow = styled(Row)`
  margin-top: 12px;
`;

const CustomSelect = styled(Select)`
  width: 90px;
`;

const Div = styled.div`
  display: flex;
  align-items: center;
`;

const NoticeText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
`;

interface GroupsSalesLimit {
  id: number;
  memberGroup: number;
  maxSaleQty: number;
  startAt: string;
  endAt: string;
  isAddColumn: boolean;
  isExist: boolean;
  minGrossProfitMargin?: number;
}

interface UpdateGroupsSalesLimit {
  memberGroup?: number;
  minGrossProfitMargin?: number;
  maxSaleQty?: number;
  startAt?: string;
  endAt?: string;
}

type Props = {
  close: () => void;
  onNext: () => void;
};

export default function GroupLimitModal(props: Props) {
  const { close, onNext } = props;

  const dispatch = useDispatch();
  const { currentSalePlan, groupLimitError } = useSelector(SaleState);

  const [defaultQtyIsChange, setDefaultQtyIsChange] = useState<boolean>(false);
  const [existSalesLimit, setExistSalesLimit] = useState<Map<number, GroupsSalesLimit>>(new Map());

  const setUpdateSalesLimit = (values: UpdateGroupsSalesLimit, record: GroupsSalesLimit) => {
    const currentGroupLimit = currentSalePlan?.groupsSalesLimit.find((item) => item.id === record.id);
    if (currentGroupLimit?.maxSaleQty === 20) {
      setDefaultQtyIsChange(!record.startAt && !record.endAt && values.maxSaleQty !== 20);
    }

    setExistSalesLimit(
      (prev) =>
        new Map(
          existSalesLimit.set(record.id, {
            ...(prev.get(record.id) as GroupsSalesLimit),
            ...values,
          }),
        ),
    );
  };

  const setAddNewUnUploadData = (id: number) => {
    if (
      existSalesLimit.get(id)?.memberGroup === -1 ||
      !existSalesLimit.get(id)?.startAt ||
      !existSalesLimit.get(id)?.endAt
    ) {
      message.warning("您尚未完成限購數之設定");
      return;
    }

    if (moment(existSalesLimit.get(id)?.endAt).isBefore(moment(existSalesLimit.get(id)?.startAt))) {
      message.warning("結束時間需要在開始時間之後");
      return;
    }

    const generalList = Array.from(existSalesLimit.values()).filter(
      (item) => item.memberGroup === MemberGroupValue.GENERAL,
    );
    if (generalList.length > 2) {
      message.warning("只能設定最多兩組一般會員限購");
      return;
    }

    const vipList = Array.from(existSalesLimit.values()).filter((item) => item.memberGroup === MemberGroupValue.VIP);
    if (vipList.length > 2) {
      message.warning("只能設定最多兩組黑卡會員限購");
      return;
    }

    setExistSalesLimit(
      (prev) =>
        new Map(
          prev.set(Date.now(), {
            ...(prev.get(id) as GroupsSalesLimit),
            id: Date.now(),
            isAddColumn: false,
          }),
        ),
    );

    setExistSalesLimit(
      (prev) =>
        new Map(
          prev.set(-1, {
            id: -1,
            isAddColumn: true,
            memberGroup: -1,
            maxSaleQty: 1,
            startAt: "",
            endAt: "",
            isExist: false,
            minGrossProfitMargin: 0,
          }),
        ),
    );

    if (existSalesLimit.size > 4) {
      const clone = new Map(existSalesLimit);
      clone.delete(-1);
      setExistSalesLimit(clone);
    }
  };

  const onCreateGroupsSalesLimit = () => {
    if (currentSalePlan) {
      dispatch(
        fetchCreateGroupLimit({
          salesPlan: currentSalePlan.id,
          groupsSalesLimit: Array.from(existSalesLimit.values())
            .filter((item) => item.id !== -1)
            .map((item) => ({
              id: item.isExist ? item.id : undefined,
              groupId: item.memberGroup,
              maxSaleQty: item.maxSaleQty,
              startAt: item.startAt,
              endAt: item.endAt,
              minGrossProfitMargin: item.minGrossProfitMargin,
            })),
        }),
      );
    }
  };

  const onSubmit = () => {
    const defaultEditColumn = existSalesLimit.get(-1);
    if (defaultEditColumn?.memberGroup !== -1 || defaultEditColumn?.startAt || defaultEditColumn?.endAt) {
      Modal.confirm({
        title: "您尚未完成限購數之設定，確定要繼續嗎?",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          onCreateGroupsSalesLimit();
          onNext();
        },
      });
    } else if (defaultQtyIsChange) {
      Modal.confirm({
        title: "你確定要變更預設的限購數或黑卡最低限額？",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          onCreateGroupsSalesLimit();
          onNext();
        },
      });
    } else {
      onCreateGroupsSalesLimit();
      onNext();
    }
  };

  const onDeleteGroupSalesLimit = (id: number) => {
    if (existSalesLimit.get(id)?.isExist) {
      dispatch(fetchUpdateGroupLimit(id));
      const clone = new Map(existSalesLimit);
      clone.delete(id);
      setExistSalesLimit(clone);
    } else {
      const clone = new Map(existSalesLimit);
      clone.delete(id);

      if (existSalesLimit.size === 4) {
        setExistSalesLimit(
          new Map([
            [
              -1,
              {
                id: -1,
                isAddColumn: true,
                memberGroup: -1,
                maxSaleQty: 1,
                startAt: "",
                endAt: "",
                isExist: false,
                minGrossProfitMargin: 0,
              },
            ],
            ...Array.from(clone.entries()),
          ]),
        );
      } else {
        setExistSalesLimit(clone);
      }
    }
  };

  const columns = [
    {
      key: "memberGroup",
      dataIndex: "memberGroup",
      title: "會員資格",
      width: 90,
      render: (value: string, record: GroupsSalesLimit) =>
        record.isAddColumn ? (
          <CustomSelect
            defaultValue={-1}
            value={existSalesLimit.get(record.id)?.memberGroup}
            onChange={(val) => setUpdateSalesLimit({ memberGroup: val as MemberGroupValue }, record)}
          >
            <Select.Option value={-1}>請選擇</Select.Option>
            <Select.Option value={MemberGroupValue.GENERAL}>一般</Select.Option>
            <Select.Option value={MemberGroupValue.VIP}>黑卡</Select.Option>
          </CustomSelect>
        ) : (
          <div>{memberGroupMap[parseInt(value, 10) as MemberGroupValue]}</div>
        ),
    },
    {
      key: "maxSaleQty",
      dataIndex: "maxSaleQty",
      title: "限購數",
      render: (value: number, record: GroupsSalesLimit) => (
        <InputNumber
          value={record.isAddColumn ? existSalesLimit.get(record.id)?.maxSaleQty : value}
          onChange={(val) => {
            return setUpdateSalesLimit({ maxSaleQty: val as number }, record);
          }}
        />
      ),
    },
    {
      key: "minGrossProfitMargin",
      dataIndex: "minGrossProfitMargin",
      title: "最低限額",
      width: 120,
      render: (value: number, record: GroupsSalesLimit) => {
        return (
          <>
            {record.memberGroup === MemberGroupValue.VIP && (
              <Div>
                <InputNumber
                  style={{ marginRight: "10px", border: value === 0 ? "#ff4d4f solid" : "" }}
                  value={record.isAddColumn ? existSalesLimit.get(record.id)?.minGrossProfitMargin : value}
                  onChange={(val) => {
                    return setUpdateSalesLimit({ minGrossProfitMargin: (val as unknown) as number }, record);
                  }}
                  min={0}
                />
                %
              </Div>
            )}
          </>
        );
      },
    },
    {
      key: "purchaseLimitTime",
      dataIndex: "purchaseLimitTime",
      title: "限購時間",
      width: 400,
      render: (_: any, record: GroupsSalesLimit) => {
        if (record.isAddColumn) {
          return (
            <Row wrap={false} justify="space-between">
              <DatePicker
                locale={locale}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: moment("00:00:00", "HH:mm:ss"),
                }}
                value={
                  existSalesLimit.get(record.id)?.startAt ? moment(existSalesLimit.get(record.id)?.startAt) : undefined
                }
                onChange={(val) =>
                  setUpdateSalesLimit(
                    {
                      startAt: val!.format("YYYY-MM-DD HH:mm:ss") || "",
                    },
                    record,
                  )
                }
              />
              <DatePicker
                locale={locale}
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: moment("23:59:59", "HH:mm:ss"),
                }}
                value={
                  existSalesLimit.get(record.id)?.endAt ? moment(existSalesLimit.get(record.id)?.endAt) : undefined
                }
                onChange={(val) =>
                  setUpdateSalesLimit(
                    {
                      endAt: val!.format("YYYY-MM-DD HH:mm:ss") || "",
                    },
                    record,
                  )
                }
              />
              <CustomButton onClick={() => setAddNewUnUploadData(record.id)}>新增</CustomButton>
            </Row>
          );
        }
        if (!record.isAddColumn && !record.startAt) {
          return <div>預設</div>;
        }
        return (
          <Row justify="space-between" align="middle">
            <div>{`${record.startAt.replace("T", " ")} - ${record.endAt.replace("T", " ")}`}</div>
            <Button type="link" onClick={() => onDeleteGroupSalesLimit(record.id)}>
              刪除
            </Button>
          </Row>
        );
      },
    },
  ];

  useEffect(() => {
    if (groupLimitError) {
      message.error(groupLimitError);
    }
  }, [groupLimitError]);

  useEffect(() => {
    if (currentSalePlan) {
      const empty = new Map();
      setExistSalesLimit(empty);

      // 0825 filter 這段因為後端不更動資料，畫面又不想顯示，以後如果真的有 BUSINESS 再拿掉
      const clone = currentSalePlan.groupsSalesLimit
        .filter((item) => item.memberGroup !== MemberGroupValue.BUSINESS)
        .map((item) => ({
          ...item,
          startAt: item.startAt || "",
          endAt: item.endAt || "",
          isAddColumn: false,
          isExist: true,
        }));

      if (clone.length < 4) {
        clone.unshift({
          id: -1,
          isAddColumn: true,
          memberGroup: -1,
          maxSaleQty: 1,
          startAt: "",
          endAt: "",
          isExist: false,
          minGrossProfitMargin: 0,
        });
      }

      clone.forEach((item) => {
        setExistSalesLimit((prev) => new Map(prev.set(item.id, item)));
      });
    }
  }, [currentSalePlan]);

  return (
    <PopupBackground close={close} fixed>
      <Wrapper>
        <Table columns={columns} dataSource={Array.from(existSalesLimit.values())} pagination={false} />
        <CustomRow justify="end">
          <Button onClick={close}>取消</Button>
          <CustomButton type="primary" onClick={onSubmit}>
            確定
          </CustomButton>
        </CustomRow>
        <NoticeText>*若輸入值為 0 ，代表毛利額不設限</NoticeText>
      </Wrapper>
    </PopupBackground>
  );
}
