import { FilterTwoTone } from "@ant-design/icons";
import { VipStatisticsListResult } from "@api/vipStatisticsApi";
import PageTitle from "@component/PageTitle";
import {
  fetchVipBrandNewMemberExport,
  fetchVipInValidMemberExport,
  fetchVipStatisticsList,
  fetchVipStatisticsListExport,
  resetVipStatisticsSlice,
  vipStatisticsState,
} from "@redux/vipStatisticsSlice";
import { Button, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import VipStatisticsFilter from "./VipStatisticsFilter";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 10px 22px 17px;
  margin-top: 20px;
`;
const CustomButton = styled(Button)`
  margin-right: 8px;
`;
const CountWrapper = styled.div`
  padding: 10px 14px;
  background: #fafafa;
  border-radius: 2px;
  min-width: 114px;
  margin-right: 8px;
  margin-bottom: 20px;
`;
const CountTitle = styled.div`
  ${fontStyle("13px", "22px")};
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.45);
`;
const CountText = styled.div`
  ${fontStyle("20px", "28px")};
`;
const CountLink = styled.div`
  ${fontStyle("20px", "28px")};
  color: #1890ff;
  text-decoration: underline;
  text-decoration-color: #1890ff;
  cursor: pointer;
`;

export default function VipStatistics() {
  const dispatch = useDispatch();
  const { isWaitingDownload, isFetching, vipStatisticsListResult, vipStatisticsListFilter } = useSelector(
    vipStatisticsState,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const numberWithCommas = (num: number) => {
    return num?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const columns: ColumnsType<VipStatisticsListResult["data"][0]> = [
    {
      key: "month",
      dataIndex: "month",
      title: "月份",
      render: (month) => <div>{moment(month).format("YYYY-MM")}</div>,
    },
    {
      key: "brandNewVipCount",
      dataIndex: "brandNewVipCount",
      title: "當月全新黑卡會員數",
    },
    {
      key: "newVipCount",
      dataIndex: "newVipCount",
      title: "當月新增黑卡會員數",
    },
    {
      key: "newVipAnnualFee",
      dataIndex: "newVipAnnualFee",
      title: "當月黑卡新增會費",
      render: (newVipAnnualFee) => <div>{`$${numberWithCommas(newVipAnnualFee)}`}</div>,
    },
    {
      key: "newVipNetAddition",
      dataIndex: "newVipNetAddition",
      title: "當月淨新增黑卡會員數",
    },
    {
      key: "newVipRevenue",
      dataIndex: "newVipRevenue",
      title: "黑卡會員貢獻營收",
      render: (newVipRevenue) => <div>{`$${numberWithCommas(parseFloat(newVipRevenue.toFixed(2)))}`}</div>,
    },
    {
      key: "newVipOrderCount",
      dataIndex: "newVipOrderCount",
      title: "黑卡會員貢獻訂單數",
    },
    {
      key: "newVipOrderCost",
      dataIndex: "newVipOrderCost",
      title: "黑卡會員銷售成本",
      render: (newVipOrderCost) => <div>{`$${numberWithCommas(parseFloat(newVipOrderCost.toFixed(2)))}`}</div>,
    },
    {
      key: "newVipOrderGrossMargin",
      dataIndex: "newVipOrderGrossMargin",
      title: "黑卡會員銷售毛利率",
      render: (newVipOrderGrossMargin) => <div>{`${parseFloat((newVipOrderGrossMargin * 100).toFixed(2))}%`}</div>,
    },
    {
      key: "newVipRetentionCount",
      dataIndex: "newVipRetentionCount",
      title: "留存數",
    },
    {
      key: "newVipRetentionRate",
      dataIndex: "newVipRetentionRate",
      title: "留存率",
      render: (newVipRetentionRate) => <div>{`${parseFloat((newVipRetentionRate * 100).toFixed(2))}%`}</div>,
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      fixed: "right",
      render: (_, values) => (
        <Button
          type="link"
          onClick={() => dispatch(fetchVipBrandNewMemberExport({ month: moment(values.month).format("YYYY-MM") }))}
        >
          下載全新黑卡客資料
        </Button>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchVipStatisticsList(vipStatisticsListFilter));
  }, [dispatch, vipStatisticsListFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetVipStatisticsSlice());
    };
  }, [dispatch]);

  return (
    <Spin spinning={isWaitingDownload}>
      <Wrapper>
        <PageTitle title="黑卡會員分析表" />
        <Row>
          <CountWrapper>
            <CountTitle>累計會員數</CountTitle>
            <CountText>{vipStatisticsListResult.vipMember}</CountText>
          </CountWrapper>
          <CountWrapper>
            <CountTitle>累計取消會員數</CountTitle>
            <CountLink onClick={() => dispatch(fetchVipInValidMemberExport())}>
              {vipStatisticsListResult.canceledVipMember}
            </CountLink>
          </CountWrapper>
        </Row>
        <Row justify="space-between">
          <div>
            <CustomButton icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
              篩選
            </CustomButton>
            <Button onClick={() => dispatch(fetchVipStatisticsListExport(vipStatisticsListFilter))}>匯出</Button>
          </div>
        </Row>
        <VipStatisticsFilter isVisible={showFilter} />
        <TableWrapper>
          <Table
            loading={isFetching}
            scroll={{ x: "max-content" }}
            columns={columns}
            dataSource={vipStatisticsListResult.data}
            pagination={false}
          />
        </TableWrapper>
      </Wrapper>
    </Spin>
  );
}
