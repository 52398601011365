import { PlanChangePrice, SalePlan, SalePlanOption } from "@api/saleApi";
import { ContractMode } from "@api/utils/normalizeContract";
import PopupBackground from "@component/PopupBackground";
import { StaffPermission, usePermission } from "@hooks/usePermission";
import { fetchCreateChangePrice, fetchUpdateChangePrice, SaleState } from "@redux/saleSlice";
import MathRound from "@utils/MathRound";
import { Button, DatePicker, Input, Radio, Row, Select, Table } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  min-width: 800px;
  max-width: 80vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 90vh;
  overflow: auto;
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const CustomRow = styled(Row)`
  margin-bottom: 12px;
`;

const CustomRadio = styled(Radio)<{ marginBottom?: string }>`
  display: block;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const CustomRadioGroup = styled(Radio.Group)`
  padding-top: 4px;
`;

const CustomSelect = styled(Select)`
  width: 100%;
`;

const CustomInput = styled(Input)<{ width?: string; gap?: boolean; error?: boolean }>`
  width: ${({ width }) => width || "52px"};
  margin: ${({ gap }) => gap && "0px 10px"};
  border: ${({ error }) => error && "2px solid #ec6922"};
`;

const Text = styled.span`
  margin-right: 30px;
  min-width: 100px;
  padding: 8px 0;
  ${fontStyle("14px", "16px")};
`;

const TableWrapper = styled.div`
  margin-bottom: 12px;
`;

const Required = styled.span`
  color: red;
`;

const Error = styled.span`
  color: #ec6922;
  position: absolute;
  left: 20px;
  bottom: 20px;
  ${fontStyle("14px", "16px")};
`;

const QtyText = styled.span`
  font-weight: bold;
`;

interface Option {
  id: number;
  name: string;
  vpc: {
    id: number;
    price: number;
    commissionRate: number;
    productName: string;
    contractInfo: {
      id: number;
      mode: ContractMode;
      modeName: string;
    };
  };
  groupQty: number;
  unitPrice: number;
  unitCost: number;
  canBuyCount: number;
}

enum RadioType {
  DEFAULT = 1,
  TIMING = 2,
}

type Props = {
  isLast: boolean;
  close: () => void;
  onNext: () => void;
  salePlanListResult: {
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  };
};

export default function ChangePriceModal(props: Props) {
  const { isLast, close, onNext, salePlanListResult } = props;

  const dispatch = useDispatch();
  const {
    salePageDetail,
    needReload,
    changePriceError,
    currentPlanChangePrice,
    planChangePriceListResult,
  } = useSelector(SaleState);
  const modifyCommissionPermission = usePermission(StaffPermission.CAN_COMMISSION_RATE_DISCOUNT);
  const [specialPriceMap, setSpecialPriceMap] = useState<Map<number, number>>(new Map());
  const [rateMap, setRateMap] = useState<Map<number, number>>(new Map());
  const [salePlanMap, setSalePlanMap] = useState<Map<number, SalePlan>>(new Map());
  const [salePlan, setSalePlan] = useState<SalePlan>();
  const [optionDiscountPeriod, setOptionDiscountPeriod] = useState<moment.Moment[]>([]);
  const [radio, setRadio] = useState<RadioType>(RadioType.DEFAULT);
  const [error, setError] = useState<string>("");

  const [updateOptions, setUpdateOptions] = useState<SalePlanOption[]>([]);

  const computeSpecialPrice = (plan?: PlanChangePrice) => {
    const specialPrice = salePlan?.options.reduce(
      (acc, item) => acc + (specialPriceMap.get(item.id)! * 10 || item.groupQty * item.unitPrice * 10),
      0,
    );
    return specialPrice! > 0 ? `$${specialPrice! / 10}` : "N/A";
  };

  const updateSpecialPrice = (e: React.ChangeEvent<HTMLInputElement>, others: Option) => {
    const { value } = e.target;

    const reg = /^\d*(\.\d{0,1})?$/;

    if (value && reg.test(value)) {
      setSpecialPriceMap(new Map(specialPriceMap.set(others.id, parseFloat(value))));
    } else if (value === "") {
      const map = new Map(specialPriceMap);
      map.delete(others.id);
      setSpecialPriceMap(map);
    }
  };
  const onCancel = () => {
    close();
    setError("");
  };

  const onSubmit = () => {
    if (radio === RadioType.TIMING && optionDiscountPeriod.length === 0) {
      setError("請填入時間區間");
      return;
    }
    let overLoad = "";
    salePlan?.options.forEach((item) => {
      const spec = specialPriceMap.get(item.id) as number | null;
      if (typeof spec === "number") {
        if (spec > item.unitPrice * item.groupQty) {
          overLoad = `${item.name}: 優惠價超過售價，請重新填寫`;
        }
        if (Number.isNaN(spec)) {
          overLoad = "須填正整數，請重新填寫";
        }
        if (spec < 1) {
          overLoad = "優惠價不能小於 1";
        }
      }
    });

    if (computeSpecialPrice(currentPlanChangePrice).includes(".")) {
      setError("方案優惠價格須為整數");
      return;
    }

    if (overLoad) {
      setError(overLoad);
      return;
    }

    if (currentPlanChangePrice && updateOptions.length === 0) {
      if (currentPlanChangePrice.optionsInfo.some((item) => !specialPriceMap.get(item.optionId))) {
        setError("方案優惠價必填");
        return;
      }

      dispatch(
        fetchUpdateChangePrice({
          salesPlan: salePlan!.id,
          startAt: radio === RadioType.TIMING ? optionDiscountPeriod[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
          endAt: radio === RadioType.TIMING ? optionDiscountPeriod[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
          specialPrice: 0,
          commissionRateDiscount: undefined,
          optionsDiscountsIds: undefined,
          optionsDiscounts: currentPlanChangePrice.optionsInfo.map((item) => ({
            optionId: item.optionId,
            specialPrice: specialPriceMap.get(item.optionId)!,
            commissionRateDiscount: MathRound(rateMap.get(item.optionId) || 0, 1),
            optionsDiscountsId: item.optionsDiscountsId,
          })),
        }),
      );
    } else {
      const optionList = updateOptions.length > 0 ? updateOptions : salePlan!.options;
      const checkOptionList = isLast
        ? optionList.some((item) => !specialPriceMap.get(item.id))
        : optionList.some((item) => specialPriceMap.get(item.id) === 0);

      if (checkOptionList) {
        setError(isLast ? "方案優惠價必填" : "方案優惠價不可為0");
        return;
      }

      dispatch(
        fetchCreateChangePrice({
          salesPlan: salePlan!.id,
          startAt: radio === RadioType.TIMING ? optionDiscountPeriod[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
          endAt: radio === RadioType.TIMING ? optionDiscountPeriod[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
          specialPrice: 0,
          commissionRateDiscount: undefined,
          optionsDiscounts: optionList.map((item) => ({
            optionId: item.id,
            specialPrice: specialPriceMap.get(item.id)!,
            commissionRateDiscount: MathRound(rateMap.get(item.id) || 0, 1),
          })),
        }),
      );
    }
  };

  useEffect(() => {
    // 編輯初始化
    if (currentPlanChangePrice) {
      if (currentPlanChangePrice.startAt) {
        setRadio(RadioType.TIMING);
        setOptionDiscountPeriod([moment(currentPlanChangePrice.startAt), moment(currentPlanChangePrice.endAt)]);
      } else {
        setRadio(RadioType.DEFAULT);
      }

      setSalePlan(salePlanMap.get(currentPlanChangePrice.id)!);

      currentPlanChangePrice.optionsInfo.forEach((item) => {
        setSpecialPriceMap((prev) => new Map(prev.set(item.optionId, item.specialPriceToShow)));
        setRateMap((prev) => new Map(prev.set(item.optionId, item.commissionRateDiscount)));
      });
    }
  }, [currentPlanChangePrice, salePlanMap]);

  useEffect(() => {
    if (!currentPlanChangePrice) {
      setSalePlan(salePlanListResult.results[0]);
    }

    salePlanListResult.results.forEach((item) => {
      setSalePlanMap((prev) => new Map(prev.set(item.id, item)));
    });
  }, [salePlanListResult.results, currentPlanChangePrice]);

  useEffect(() => {
    // 新建初始化
    if (salePlan) {
      salePlan.options.forEach((item) => {
        setRateMap((prev) => (prev.get(item.id) ? prev : new Map(prev.set(item.id, 0))));
      });
    }
  }, [salePlan, currentPlanChangePrice]);

  useEffect(() => {
    if (salePlan) {
      setError("");

      if (changePriceError) {
        setError(changePriceError);
      }

      salePlan.options.forEach((item) => {
        const spec = specialPriceMap.get(item.id);
        if (spec) {
          if (item.unitCost * item.groupQty > spec) {
            setError("此優惠價低於包含之商品成本");
          }
        }
      });
    }
  }, [salePlan, specialPriceMap, changePriceError, salePageDetail]);

  useEffect(() => {
    if (needReload) {
      onNext();
    }
  }, [needReload, onNext]);

  useEffect(() => {
    // 多繞這一層的原因是
    // 如果新增變價過後又想增加 plan option，照上面的邏輯會進到 update，但是 update 沒有 optionsDiscountsId
    // 這樣會有問題，所以新增 plan option 就算在 create 那邊
    if (salePlan && currentPlanChangePrice) {
      const set = new Set();
      currentPlanChangePrice.optionsInfo.forEach((item) => {
        set.add(item.optionId);
      });
      salePlan.options.forEach((item) => {
        if (!set.has(item.id)) {
          setUpdateOptions((prev) => [...prev, item]);
        }
      });
    }
  }, [salePlan, currentPlanChangePrice]);

  const columns = [
    {
      key: "name",
      dataIndex: "name",
      title: "名稱",
      render: (name: string, others: Option) => (
        <div>
          <span>{`${name}`}</span>
          <QtyText>{` x ${others.groupQty}`}</QtyText>
        </div>
      ),
    },
    {
      key: "unitPrice",
      dataIndex: "unitPrice",
      title: "售價",
      render: (unitPrice: number, others: Option) => unitPrice * others.groupQty,
    },
    {
      key: "specialPrice",
      dataIndex: "specialPrice",
      width: 100,
      title: () => {
        const isExist = planChangePriceListResult.results.some((item) => item.id === salePlan?.id);
        return (
          <Row justify="center">
            <span>優惠價</span>
            {isExist && <Required>*</Required>}
          </Row>
        );
      },
      render: (_: any, others: Option) => (
        <CustomInput
          width="100px"
          type="number"
          placeholder={`${others.unitPrice * others.groupQty}`}
          value={specialPriceMap.get(others.id)}
          onChange={(e) => updateSpecialPrice(e, others)}
        />
      ),
    },
    {
      key: "unitCost",
      dataIndex: "unitCost",
      title: "成本",
      render: (unitCost: number, others: Option) => {
        const { mode } = others.vpc.contractInfo;
        return mode === ContractMode.Vendor || mode === ContractMode.Warehouse ? (
          <div>N/A</div>
        ) : (
          <div>{unitCost * others.groupQty}</div>
        );
      },
    },
    {
      key: "commissionRate",
      dataIndex: "commissionRate",
      title: "抽成",
      width: 160,
      render: (_: any, others: Option) => {
        const { mode } = others.vpc.contractInfo;
        return mode === ContractMode.Vendor || mode === ContractMode.Warehouse ? (
          <Row align="middle">
            <div>{`${others.vpc.commissionRate}% -  `}</div>
            <CustomInput
              error={rateMap.get(others.id) ? others.vpc.commissionRate < rateMap.get(others.id)! : false}
              disabled={!modifyCommissionPermission}
              type="number"
              min={0}
              value={rateMap.get(others.id)}
              onChange={(e) => setRateMap(new Map(rateMap.set(others.id, parseFloat(e.target.value))))}
              style={{ marginLeft: "10px" }}
            />
            <div>%</div>
          </Row>
        ) : (
          <div>N/A</div>
        );
      },
    },
  ];

  return (
    <PopupBackground close={onCancel} fixed>
      <Wrapper>
        <CustomRow align="middle" wrap={false}>
          <Text>方案名稱</Text>
          <CustomSelect
            value={salePlan?.id}
            onChange={(id) => setSalePlan(salePlanMap.get(parseInt(id as string, 10))!)}
          >
            {salePlanListResult.results.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </CustomSelect>
        </CustomRow>
        <CustomRow>
          <Text>變價內容</Text>
        </CustomRow>
        <TableWrapper>
          <Table columns={columns} dataSource={salePlan?.options} pagination={false} />
        </TableWrapper>
        <CustomRow>
          <Text>方案售價</Text>
          <Text>{`$${salePlan?.price}`}</Text>
        </CustomRow>
        <CustomRow>
          <Text>方案優惠價</Text>
          <Text>{computeSpecialPrice(currentPlanChangePrice)}</Text>
        </CustomRow>
        <CustomRow>
          <Text>優惠時間</Text>
          <CustomRadioGroup value={radio} onChange={(e) => setRadio(e.target.value)}>
            <CustomRadio marginBottom="10px" value={RadioType.DEFAULT}>
              預設
            </CustomRadio>
            <CustomRadio value={RadioType.TIMING}>
              <DatePicker.RangePicker
                locale={locale}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{
                  hideDisabledOptions: true,
                  defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
                }}
                value={optionDiscountPeriod as any}
                onFocus={() => setRadio(RadioType.TIMING)}
                onChange={(val) => setOptionDiscountPeriod(val as moment.Moment[])}
              />
            </CustomRadio>
          </CustomRadioGroup>
        </CustomRow>
        <Row justify="end">
          <CustomButton onClick={onCancel}>取消</CustomButton>
          <Button type="primary" onClick={onSubmit}>
            {isLast ? "確認" : "下一步"}
          </Button>
        </Row>
        <Error>{error}</Error>
      </Wrapper>
    </PopupBackground>
  );
}
