import brandApi, { BrandListConfig } from "@api/brandApi";
import productApi from "@api/productApi";
import saleApi, {
  FetchCreateGroupLimit,
  FetchCreateOption,
  FetchCreatePlan,
  FetchCreateSale,
  FetchDeleteChangePrice,
  FetchDeleteOption,
  FetchOptionList,
  FetchPlanOptionDiscount,
  FetchSaleListParams,
  FetchUpdateOption,
  FetchUpdateOptionRanks,
  FetchUpdatePlan,
  FetchUpdatePlanRanks,
  Option,
  PlanChangePrice,
  SaleItemDetail,
  SaleListResult,
  SalePlan,
} from "@api/saleApi";
import { SalesCategoryOrigin } from "@api/topicApi";
import { SalesChannelType } from "@api/vendorApi";
import { BrandListResult } from "@api/utils/normalizeBrand";
import { ContractMode } from "@api/utils/normalizeContract";
import VendorLogisticsValue from "@page/Order/VendorLogisticsValue";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "./notifySlice";
import type { RootState } from "./rootReducer";

export interface Spec {
  type: string;
  productName: string;
  unit: string;
  options: Specification[];
}

export interface Specification {
  id: string;
  label: string;
  value: string;
  unit: string;
}

interface IState {
  specification: Spec[];
  isFetching: boolean;
  isDownloading: boolean;
  saleList: SaleListResult;
  brandList: BrandListResult;
  brandListParam: BrandListConfig;
  saleListParams: FetchSaleListParams;
  salePageDetail?: SaleItemDetail;
  createSalePageDetail?: SaleItemDetail;
  salesCategories: {
    level1: SalesCategoryOrigin[];
    level2: SalesCategoryOrigin[];
    level3: SalesCategoryOrigin[];
  };
  vpcList: {
    vpcId: number;
    qty: number;
    name: string;
    sku: string;
  }[];
  vpcError: string;
  nextStepLoading: boolean;
  stepDone: boolean;
  salePlanListResult: {
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  };
  currentSalePlan?: SalePlan;
  planChangePriceListResult: {
    count: number;
    next: string;
    previous: string;
    results: PlanChangePrice[];
  };
  currentPlanChangePrice?: PlanChangePrice;
  needReload: boolean;
  changePriceError: string;
  groupLimitError: string;
  isEditPlanDone: boolean;
  vpcTemp?: {
    vpcId: number;
    name: string;
  };
  optionListResult: {
    count: number;
    next: string;
    previous: string;
    results: Option[];
  };
  optionError: string;
  isEditOptionDone: boolean;
  currentOption?: Option;
  vpcAddDone: boolean;
  isUploadDone: boolean;
  isUploadFetching: boolean;
}

const initialState: IState = {
  specification: [],
  isFetching: false,
  isDownloading: false,
  saleList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  brandList: { count: 0, previous: "", next: "", results: [] },
  saleListParams: {
    limit: 20,
    offset: 0,
    tagId: undefined,
    skus: undefined,
    pageIds: undefined,
    pageNameQ: undefined,
    brandNames: undefined,
    categoryIds: undefined,
    excludeTagId: undefined,
  },
  salePageDetail: undefined,
  createSalePageDetail: undefined,
  salesCategories: {
    level1: [],
    level2: [],
    level3: [],
  },
  vpcList: [],
  vpcError: "",
  nextStepLoading: false,
  stepDone: false,
  salePlanListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  currentSalePlan: undefined,
  planChangePriceListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  currentPlanChangePrice: undefined,
  needReload: false,
  changePriceError: "",
  groupLimitError: "",
  isEditPlanDone: false,
  vpcTemp: undefined,
  optionListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  optionError: "",
  isEditOptionDone: false,
  currentOption: undefined,
  vpcAddDone: false,
  isUploadDone: false,
  isUploadFetching: false,
  brandListParam: {
    limit: 20,
    offset: 0,
    searchName: "",
    searchId: undefined,
  },
};

export const fetchSaleList = createAsyncThunk("sale/fetchSaleList", async (payload: FetchSaleListParams) => {
  const data = await saleApi.fetchSaleList(payload);
  return data;
});

export const fetchBrandList = createAsyncThunk("sale/fetchBrandList", async (payload: BrandListConfig, thunkApi) => {
  thunkApi.dispatch(updateBrandListParam(payload));
  const { data } = await brandApi.fetchBrandList(payload);
  return data;
});

export const loadMoreBrandList = createAsyncThunk("product/loadMoreBrandList", async (_, thunkApi) => {
  const {
    product: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: BrandListConfig = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await brandApi.fetchBrandList(params);
  return response;
});

export const fetchSalePage = createAsyncThunk("sale/fetchSalePage", async (salePageId: number) => {
  const data = await saleApi.fetchSalePage(salePageId);
  return data;
});

export const fetchSalesCategories = createAsyncThunk("sale/fetchSalesCategories", async () => {
  const response = await saleApi.fetchSalesCategories();
  return response;
});

export const fetchCreateSalePage = createAsyncThunk("sale/fetchCreateSalePage", async (payload: FetchCreateSale) => {
  const response = await saleApi.fetchCreateSalePage(payload);
  message.success("新增成功");
  return response;
});

export const fetchUpdateSalePage = createAsyncThunk(
  "sale/fetchUpdateSalePage",
  async (payload: { salePageId: number; payload: FetchCreateSale }) => {
    await saleApi.fetchUpdateSalePage(payload.salePageId, payload.payload);
    message.success("更新成功");
    return "Success";
  },
);

export const fetchChangePriceUpload = createAsyncThunk("sale/fetchChangePriceUpload", async (file: File, thunkApi) => {
  try {
    await saleApi.fetchChangePriceUpload(file);
    message.success("匯入成功");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});
export const refreshChangePrice = createAsyncThunk("sale/refreshChangePrice", async (_, thunkApi) => {
  try {
    thunkApi.dispatch(showLoading());
    await saleApi.refreshChangePrice();
    message.success("處理中，由於資料繁多請稍待，清除完畢後會寄信通知");
    return "Success";
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  } finally {
    thunkApi.dispatch(hideLoading());
  }
});

export const fetchChangePriceDownload = createAsyncThunk(
  "sale/fetchSaleListDownload",
  async (params: FetchSaleListParams, thunkApi) => {
    try {
      await saleApi.fetchChangePriceDownload(params);
      message.success("下載成功，請稍候至信箱收取檔案");
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchPlanList = createAsyncThunk(
  "sale/fetchPlanList",
  async (config: { salesPage: number; limit: number; offset: number }) => {
    const response = await saleApi.fetchPlanList(config);
    return response;
  },
);

const fetchFunction = async (sku: string, thunkApi: any) => {
  const data = await productApi.fetchProductVendorList({
    limit: 1,
    offset: 0,
    sku,
    isActive: true,
    isHidden: false,
    salesChannel: SalesChannelType.URMART,
  });
  const { saleSlice } = thunkApi.getState() as RootState;

  if (data.results.length === 0) {
    return thunkApi.rejectWithValue("查無此商品");
  }

  if (
    saleSlice.salePageDetail?.logistic === VendorLogisticsValue.transfer &&
    data.results[0].mode !== ContractMode.Vendor
  ) {
    return thunkApi.rejectWithValue("此銷售頁包含之商品，出貨點需為轉單出貨，請重新選擇");
  }

  if (
    saleSlice.salePageDetail?.logistic !== VendorLogisticsValue.transfer &&
    data.results[0].mode === ContractMode.Vendor
  ) {
    return thunkApi.rejectWithValue("此銷售頁包含之商品，出貨點需為倉庫出貨，請重新選擇");
  }
  if (!data.results[0].product.isActive) {
    return thunkApi.rejectWithValue("只能新增上架之商品，請重新選擇");
  }

  return {
    vpcId: data.results[0].vpcId,
    qty: data.results[0].product.canSalesQty || 0,
    name: data.results[0].product.name,
    sku: data.results[0].product.sku,
  };
};

export const fetchProductVendor = createAsyncThunk("sale/fetchProductVendor", fetchFunction);

export const fetchProductVendorList = createAsyncThunk("sale/fetchProductVendorList", fetchFunction);

export const fetchCreatePlan = createAsyncThunk("sale/fetchCreatePlan", async (payload: FetchCreatePlan, thunkApi) => {
  try {
    const response = await saleApi.fetchCreatePlan(payload);
    return response;
  } catch (error: any) {
    if (error.message === "err_msg: products_info is empty") {
      return thunkApi.rejectWithValue("方案內容不能為空");
    }
    return thunkApi.rejectWithValue(error.message);
  }
});

export const fetchUpdatePlan = createAsyncThunk(
  "sale/fetchUpdatePlan",
  async (payload: { planId: number; payload: FetchUpdatePlan }, thunkApi) => {
    try {
      await saleApi.fetchUpdatePlan(payload.planId, payload.payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchToogleActivePlan = createAsyncThunk(
  "sale/fetchToogleActivePlan",
  async (payload: { planId: number; isActive: boolean; isLastActive: boolean }, thunkApi) => {
    try {
      await saleApi.fetchToogleActivePlan(payload.planId, payload.isActive);

      if (payload.isLastActive && !payload.isActive) {
        const { saleSlice } = thunkApi.getState() as RootState;
        thunkApi.dispatch(fetchSalePage(saleSlice.salePageDetail!.id));
      }

      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchChangePriceList = createAsyncThunk(
  "sale/fetchChangePriceList",
  async (config: { salesPage: number; limit: number; offset: number }) => {
    const data = await saleApi.fetchChangePriceList(config);
    return data;
  },
);

export const fetchCreateChangePrice = createAsyncThunk(
  "sale/fetchCreateChangePrice",
  async (payload: FetchPlanOptionDiscount, thunkApi) => {
    try {
      await saleApi.fetchCreateChangePrice(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateChangePrice = createAsyncThunk(
  "sale/fetchUpdateChangePrice",
  async (payload: FetchPlanOptionDiscount, thunkApi) => {
    try {
      await saleApi.fetchUpdateChangePrice(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteChangePrice = createAsyncThunk(
  "sale/fetchDeleteChangePrice",
  async (payload: FetchDeleteChangePrice, thunkApi) => {
    try {
      await saleApi.fetchDeleteChangePrice(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchOptionList = createAsyncThunk("sale/fetchOptionList", async (config: FetchOptionList) => {
  const data = await saleApi.fetchOptionList(config);
  return data;
});

export const fetchCreateOption = createAsyncThunk(
  "sale/fetchCreateOption",
  async (payload: FetchCreateOption, thunkApi) => {
    try {
      await saleApi.fetchCreateOption(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateOption = createAsyncThunk(
  "sale/fetchUpdateOption",
  async (payload: FetchUpdateOption, thunkApi) => {
    try {
      await saleApi.fetchUpdateOption(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteOption = createAsyncThunk("sale/fetchDeleteOption", async (payload: FetchDeleteOption) => {
  await saleApi.fetchDeleteOption(payload);
  return "Success";
});

export const fetchCreateGroupLimit = createAsyncThunk(
  "sale/fetchCreateGroupLimit",
  async (payload: FetchCreateGroupLimit, thunkApi) => {
    try {
      await saleApi.fetchCreateGroupLimit(payload);
      const { saleSlice } = thunkApi.getState() as RootState;
      const response = await saleApi.fetchPlanInfo(saleSlice.currentSalePlan!.id);
      thunkApi.dispatch(setCurrentSalePlan(response));
      message.success("成功");
      return "Success";
    } catch (error: any) {
      if (!error.message) {
        message.error("");
      }
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateGroupLimit = createAsyncThunk(
  "sale/fetchUpdateGroupLimit",
  async (groupLimitId: number, thunkApi) => {
    try {
      await saleApi.fetchUpdateGroupLimit(groupLimitId);
      const { saleSlice } = thunkApi.getState() as RootState;
      const response = await saleApi.fetchPlanInfo(saleSlice.currentSalePlan!.id);
      thunkApi.dispatch(setCurrentSalePlan(response));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdatePlanRanks = createAsyncThunk(
  "sale/fetchUpdatePlanRanks",
  async (payload: FetchUpdatePlanRanks, thunkApi) => {
    try {
      await saleApi.fetchUpdatePlanRanks(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateOptionRanks = createAsyncThunk(
  "sale/fetchUpdateOptionRanks",
  async (payload: FetchUpdateOptionRanks, thunkApi) => {
    try {
      await saleApi.fetchUpdateOptionRanks(payload);
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const batchActiveSalePages = createAsyncThunk(
  "sale/batchActiveSalePages",
  async ({ salePagesIds, isActive }: { salePagesIds: number[]; isActive: boolean }, thunkApi) => {
    try {
      const { saleSlice } = thunkApi.getState() as RootState;
      await saleApi.batchActiveSalePages(salePagesIds, isActive);
      thunkApi.dispatch(fetchSaleList(saleSlice.saleListParams));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    resetSaleSlice: () => initialState,
    updateBrandListParam: (state, action: PayloadAction<BrandListConfig>) => {
      state.brandListParam = action.payload;
    },
    setAddSpecification: (state, action: PayloadAction<Spec>) => {
      state.specification = [...state.specification, action.payload];
    },
    setUpdateSpecification: (state, action: PayloadAction<Spec[]>) => {
      state.specification = action.payload;
    },
    setSaleListParams: (state, action: PayloadAction<FetchSaleListParams>) => {
      state.saleListParams = {
        ...state.saleListParams,
        ...action.payload,
      };
    },
    setResetSaleListParams: (state) => {
      state.saleListParams = initialState.saleListParams;
    },
    setVpcList: (
      state,
      action: PayloadAction<
        {
          vpcId: number;
          qty: number;
          name: string;
          sku: string;
        }[]
      >,
    ) => {
      state.vpcList = action.payload;
    },
    setCurrentSalePlan: (state, action: PayloadAction<SalePlan | undefined>) => {
      state.currentSalePlan = action.payload;
    },
    setCurrentPlanChangePrice: (state, action: PayloadAction<PlanChangePrice | undefined>) => {
      state.currentPlanChangePrice = action.payload;
    },
    setCurrentOption: (state, action: PayloadAction<Option | undefined>) => {
      state.currentOption = action.payload;
    },
    setResetVpcList: (state) => {
      state.vpcList = [];
    },
    setResetVpcTemp: (state) => {
      state.vpcTemp = initialState.vpcTemp;
    },
    setOptionError: (state, action) => {
      state.optionError = action.payload;
    },
    setVpcAddDone: (state, action) => {
      state.vpcAddDone = action.payload;
    },
    setIsUploadDone: (state, action) => {
      state.isUploadDone = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSaleList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSaleList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.saleList = action.payload;
    });
    builder.addCase(fetchSaleList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.brandList.results = action.payload;
    });
    builder.addCase(fetchSalePage.fulfilled, (state, action) => {
      state.salePageDetail = action.payload;
    });
    builder.addCase(fetchCreateSalePage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCreateSalePage.fulfilled, (state, action) => {
      state.isFetching = false;
      state.createSalePageDetail = action.payload;
    });
    builder.addCase(fetchCreateSalePage.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateSalePage.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateSalePage.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateSalePage.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchSalesCategories.fulfilled, (state, action) => {
      state.salesCategories = action.payload;
    });
    builder.addCase(fetchProductVendor.fulfilled, (state, action) => {
      state.vpcTemp = action.payload;
    });
    builder.addCase(fetchProductVendor.rejected, (state, action) => {
      state.optionError = action.payload as string;
    });
    builder.addCase(fetchProductVendor.pending, (state, action) => {
      state.optionError = "";
    });
    builder.addCase(fetchProductVendorList.pending, (state, action) => {
      state.vpcError = "";
    });
    builder.addCase(fetchProductVendorList.fulfilled, (state, action) => {
      state.vpcList = [...state.vpcList, action.payload];
      state.vpcAddDone = true;
    });
    builder.addCase(fetchProductVendorList.rejected, (state, action) => {
      state.vpcError = action.payload as string;
    });
    builder.addCase(fetchCreatePlan.pending, (state) => {
      state.nextStepLoading = true;
    });
    builder.addCase(fetchCreatePlan.fulfilled, (state, action) => {
      state.nextStepLoading = false;
      state.stepDone = true;
      state.isEditPlanDone = true;
      state.vpcError = "";
      state.groupLimitError = "";
      state.currentSalePlan = action.payload;
    });
    builder.addCase(fetchCreatePlan.rejected, (state, action) => {
      state.nextStepLoading = false;
      state.vpcError = action.payload as string;
    });

    builder.addCase(fetchUpdatePlan.pending, (state) => {
      state.nextStepLoading = true;
    });
    builder.addCase(fetchUpdatePlan.fulfilled, (state) => {
      state.isEditPlanDone = true;
      state.stepDone = true;
      state.nextStepLoading = false;
      state.groupLimitError = "";
    });
    builder.addCase(fetchUpdatePlan.rejected, (state) => {
      state.nextStepLoading = false;
    });
    builder.addCase(fetchToogleActivePlan.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchToogleActivePlan.fulfilled, (state) => {
      state.isFetching = false;
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchToogleActivePlan.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchPlanList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPlanList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditPlanDone = false;
      state.stepDone = false;
      state.needReload = false;
      state.salePlanListResult = action.payload;
    });
    builder.addCase(fetchPlanList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchChangePriceList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchChangePriceList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.needReload = false;
      state.changePriceError = "";
      state.planChangePriceListResult = action.payload;
    });
    builder.addCase(fetchChangePriceList.rejected, (state) => {
      state.isFetching = false;
      state.needReload = false;
    });
    builder.addCase(fetchCreateChangePrice.fulfilled, (state) => {
      state.needReload = true;
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchUpdateChangePrice.fulfilled, (state) => {
      state.needReload = true;
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchDeleteChangePrice.fulfilled, (state) => {
      state.needReload = true;
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchCreateChangePrice.rejected, (state, action) => {
      state.changePriceError = action.payload as string;
    });
    builder.addCase(fetchUpdateChangePrice.rejected, (state, action) => {
      state.changePriceError = action.payload as string;
    });
    builder.addCase(fetchDeleteChangePrice.rejected, (state, action) => {
      state.changePriceError = action.payload as string;
    });
    builder.addCase(fetchCreateGroupLimit.pending, (state, action) => {
      state.isEditPlanDone = false;
      state.isFetching = true;
    });
    builder.addCase(fetchCreateGroupLimit.fulfilled, (state, action) => {
      state.isEditPlanDone = true;
      state.isFetching = false;
    });
    builder.addCase(fetchCreateGroupLimit.rejected, (state, action) => {
      state.groupLimitError = action.payload as string;
      state.isFetching = false;
    });
    builder.addCase(fetchUpdateGroupLimit.fulfilled, (state, action) => {
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchUpdateGroupLimit.rejected, (state, action) => {
      state.groupLimitError = action.payload as string;
    });
    builder.addCase(fetchDeleteOption.fulfilled, (state) => {
      state.isEditOptionDone = true;
    });
    builder.addCase(fetchUpdateOption.fulfilled, (state) => {
      state.isEditOptionDone = true;
    });
    builder.addCase(fetchCreateOption.fulfilled, (state) => {
      state.isEditOptionDone = true;
    });
    builder.addCase(fetchCreateOption.rejected, (state, action) => {
      state.optionError = action.payload as string;
    });
    builder.addCase(fetchUpdateOption.rejected, (state, action) => {
      state.optionError = action.payload as string;
    });
    builder.addCase(fetchOptionList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchOptionList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.isEditOptionDone = false;
      state.optionListResult = action.payload;
    });
    builder.addCase(fetchOptionList.rejected, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchChangePriceUpload.pending, (state) => {
      state.isUploadFetching = true;
    });
    builder.addCase(fetchChangePriceUpload.fulfilled, (state) => {
      state.isUploadDone = true;
      state.isUploadFetching = false;
    });
    builder.addCase(fetchChangePriceUpload.rejected, (state) => {
      state.isUploadDone = false;
      state.isUploadFetching = false;
    });
    builder.addCase(fetchUpdatePlanRanks.fulfilled, (state) => {
      state.isEditPlanDone = true;
    });
    builder.addCase(fetchUpdateOptionRanks.fulfilled, (state) => {
      state.isEditOptionDone = true;
    });
    builder.addCase(fetchChangePriceDownload.pending, (state) => {
      state.isDownloading = true;
    });
    builder.addCase(fetchChangePriceDownload.fulfilled, (state, action) => {
      state.isDownloading = false;
    });
  },
});
export const { resetSaleSlice } = saleSlice.actions;
export const SaleState = (state: RootState) => state.saleSlice;
export const {
  updateBrandListParam,
  setAddSpecification,
  setUpdateSpecification,
  setSaleListParams,
  setResetSaleListParams,
  setCurrentSalePlan,
  setVpcList,
  setCurrentPlanChangePrice,
  setCurrentOption,
  setResetVpcList,
  setResetVpcTemp,
  setOptionError,
  setVpcAddDone,
  setIsUploadDone,
} = saleSlice.actions;
export default saleSlice.reducer;
