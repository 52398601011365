/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { WarehouseChart, WarehouseChartItem } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
  getTimeRangeText,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

const InaccurateShipmentChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<WarehouseChartItem>(
      startDate,
      endDate,
      (chartResult as WarehouseChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<WarehouseChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as WarehouseChart).compareData || [],
      timeUnit,
    );
    const currentSendErrRate: (number | null)[] = [];
    const compareSendErrRate: (number | null)[] = [];
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    currentFilledTimeData.forEach((data) => {
      const { sendErrRate = null } = data;
      currentSendErrRate.push(sendErrRate);
    });
    compareFilledTimeData.forEach((data) => {
      const { sendErrRate = null } = data;
      compareSendErrRate.push(sendErrRate);
    });

    const currentTimeRangeText = getTimeRangeText(startDate, endDate, timeUnit);
    const compareTimeRangeText = getTimeRangeText(cStartDate, cEndDate, timeUnit);

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: currentTimeRangeText,
        data: currentSendErrRate,
        type: "line",
        color: "#5C6AC4",
        stack: "current",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.push({
        name: compareTimeRangeText,
        data: compareSendErrRate,
        type: "line",
        color: "#1890FF",
        stack: "compare",
        pointStart,
      });
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "${text}",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const stackName = this.point.series.userOptions.stack;
          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
          const sendErrRatePercentage = this.point.y ? Math.round(this.point.y * 1000) / 10 : 0;

          return `${title}<br/>寄錯率 ${sendErrRatePercentage}%`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });
  }, [chartResult, chartParams]);

  const fetchInaccurateShipmentChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.WAREHOUSE));
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <ChartWrapper>
          <PageTitle title="Dashboard - 寄錯率" />
          <ChartFilter title="寄錯率" fetchAction={fetchInaccurateShipmentChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default InaccurateShipmentChart;
