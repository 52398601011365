/* eslint-disable react/require-default-props */
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { memberGroupMap } from "@constant/MemberGroupValue";
import { fetchOrderCancel, fetchOrderRelease, orderState } from "@redux/orderSlice";
import { Button, Modal, Row } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import CarrierTypeValue from "../CarrierTypeValue";
import InvoiceTypeValue from "../InvoiceTypeValue";
import { PaymentMethodList } from "../PaymentMethodValue";
import MainOrderDetailPopup from "./MainOrderDetailPopup";

const background = "#fafafa";
const borderColor = "#f0f0f0";

const Wrapper = styled.div``;

const Title = styled.div<{ noMarginTop?: boolean }>`
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "28px")};
  margin-bottom: 18px;
  padding-left: 8px;
  ${fontStyle("14px", "25px")};
`;

const Field = styled.div`
  width: 100%;
  max-width: 126px;
  padding: 16px 0;
  text-align: center;
  background: ${background};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px ${borderColor};
  border-bottom: solid 1px ${borderColor};
`;

const FieldValue = styled(Row)<{ customMaxWidth?: string; isLink?: boolean }>`
  width: 100%;
  max-width: ${({ customMaxWidth }) => customMaxWidth || "194px"};
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: solid 1px ${borderColor};
  border-bottom: solid 1px ${borderColor};
  text-decoration: ${({ isLink }) => isLink && "underline"};
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme, isLink }) => (isLink ? theme.colorSuccess500 : theme.colorNeutral600)};
`;

const CustomRow = styled(Row)<{ twoPairs?: boolean }>`
  max-width: 1009px;
  width: 100%;

  ${({ twoPairs }) => {
    if (twoPairs) {
      return `
      & > div:nth-child(1) {
        border-top: solid 1px ${borderColor};
      }
      & > div:nth-child(2) {
        border-top: solid 1px ${borderColor};
      }
      & > div:nth-child(2n + 1) {
        border-left: solid 1px ${borderColor};
      }
      `;
    }
    return `
    & > div:nth-child(1) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(2) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(3) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(4) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(5) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(6) {
      border-top: solid 1px ${borderColor};
    }
    & > div:nth-child(6n + 1) {
      border-left: solid 1px ${borderColor};
    }
    `;
  }}
`;

const ButtonWrapper = styled.div`
  margin-left: 8px;

  & > button:nth-child(odd) {
    margin-right: 6px;
  }
`;

const CustomButton = styled(Button)`
  padding: 4px 11px;
`;

type IGenerateFields = {
  title: string;
  value: any;
  button?: React.ReactNode;
  customMaxWidth?: string;
  isLink?: boolean;
};

const GenerateFields = (props: IGenerateFields) => {
  const { title, value, button, customMaxWidth, isLink } = props;
  return (
    <>
      <Field>{title}</Field>
      <FieldValue align="middle" customMaxWidth={customMaxWidth} isLink={isLink}>
        {value}
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </FieldValue>
    </>
  );
};

export default function MainOrderTab() {
  const { orderId } = useParams();

  const dispatch = useDispatch();
  const { orderInfo: order } = useSelector(orderState);
  const { orderInfo, memberInfo, invoiceInfo, promotionRuleInfo } = order || {};
  const [openDetail, setOpenDetail] = useState<boolean>(false);

  const onVerifyPass = (isPass: boolean) => {
    Modal.confirm({
      title: isPass ? "你確定要審核通過嗎?" : "你確定要審核不通過嗎?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        if (isPass) {
          dispatch(fetchOrderRelease(parseInt(orderId, 10)));
        } else {
          dispatch(fetchOrderCancel(parseInt(orderId, 10)));
        }
      },
      okText: "是",
      cancelText: "否",
    });
  };

  return (
    <Wrapper>
      <Title noMarginTop>主訂單資訊</Title>
      <CustomRow wrap>
        <GenerateFields
          title="訂單狀態"
          value={orderInfo?.suspendType ? "待審核" : "已通過"}
          customMaxWidth="243px"
          button={
            <>
              <CustomButton disabled={orderInfo?.suspendType === null} onClick={() => onVerifyPass(true)}>
                審核通過
              </CustomButton>
              <CustomButton disabled={orderInfo?.suspendType === null} onClick={() => onVerifyPass(false)}>
                審核不通過
              </CustomButton>
            </>
          }
        />
        <GenerateFields title="訂單編號" value={orderInfo?.orderNumber} />
        <GenerateFields title="訂購時間" value={moment(orderInfo?.orderTime).format("YYYY-MM-DD HH:mm:ss")} />
        <GenerateFields
          title="分批到貨數"
          customMaxWidth="243px"
          value={`分${orderInfo?.orderShipmentCounts.toLocaleString("zh-u-nu-hanidec")}批到貨`}
        />
        <GenerateFields
          title="付款方式"
          value={orderInfo && orderInfo.paymentMethod > -1 ? PaymentMethodList[orderInfo?.paymentMethod] : "N/A"}
        />
        <GenerateFields
          title="主訂單總金額"
          value={orderInfo?.grandTotal}
          button={<Button onClick={() => setOpenDetail(true)}>查看明細</Button>}
        />
        <GenerateFields
          title="付款截止日期"
          customMaxWidth="243px"
          value={
            orderInfo?.paymentExpirationTime
              ? moment(orderInfo?.paymentExpirationTime).format("YYYY-MM-DD HH:mm:ss")
              : "N/A"
          }
        />
        <GenerateFields title="訂購信箱" value={orderInfo?.ordererEmail} />
      </CustomRow>
      <Title>會員資訊</Title>
      <CustomRow wrap>
        <GenerateFields title="會員名稱" value={memberInfo?.name} />
        <GenerateFields title="會員信箱" value={memberInfo?.email} />
        <GenerateFields title="會員電話" value={memberInfo?.phone} />
        <GenerateFields title="會員資格" value={memberInfo ? memberGroupMap[memberInfo.group] : "N/A"} />
      </CustomRow>
      <Title>發票內容</Title>
      <CustomRow wrap>
        <GenerateFields title="發票選項" value={invoiceInfo ? InvoiceTypeValue[invoiceInfo.invoiceType] : "N/A"} />
        <GenerateFields title="發票抬頭" value={invoiceInfo?.invoiceTitle || "N/A"} />
        <GenerateFields title="統一編號" value={invoiceInfo?.invoiceUbn || "N/A"} />
        <GenerateFields title="載具條碼" value={invoiceInfo?.carrierNumber || "N/A"} />
        <GenerateFields
          title="自然人憑證"
          value={invoiceInfo?.carrierType === CarrierTypeValue.NATURALCODE ? invoiceInfo?.carrierNumber : "N/A"}
        />
        <GenerateFields title="捐贈對象" value={invoiceInfo?.donationInfo.donatarius || "N/A"} />
      </CustomRow>
      <Title>活動資訊</Title>
      <CustomRow wrap twoPairs>
        <GenerateFields
          title="優惠名稱"
          value={promotionRuleInfo?.title || "N/A"}
          customMaxWidth="832px"
          isLink={!!promotionRuleInfo?.title}
        />
        <GenerateFields title="優惠描述" value={promotionRuleInfo?.description || "N/A"} customMaxWidth="832px" />
      </CustomRow>
      <Title>訂單來源資訊</Title>
      <CustomRow wrap>
        <GenerateFields title="裝置" value={orderInfo?.source.platformText} />
        <GenerateFields title="App Version" value={orderInfo?.source.applicationVersion || "N/A"} />
        <GenerateFields title="分銷渠道" value={orderInfo?.source.affiliate || "N/A"} />
        <GenerateFields title="ECID" value={orderInfo?.source.ecid || "N/A"} customMaxWidth="832px" />
      </CustomRow>
      {openDetail && <MainOrderDetailPopup close={() => setOpenDetail(false)} />}
    </Wrapper>
  );
}
