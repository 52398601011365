import React, { useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import PopupBackground from "@component/PopupBackground";
import { Button, Form, Input, Select } from "antd";
import { Currency } from "@api/supplyChainApi";
import { useAppDispatch } from "src/store";
import {
  fetchSupplyChainInfo,
  fetchAddSupplyChainInfo,
  fetchUpdateSupplyChainInfo,
  fetchSupplyChain,
  loadMoreSupplyChainList,
} from "@redux/brandOperation/productSlice";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";

type Props = {
  close: () => void;
  supplyChainInfoId: number;
  productId: string;
  mode: "edit" | "add";
};

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
const ProductOutInfoEdit = (props: Props) => {
  const { close, supplyChainInfoId, productId, mode } = props;
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const { supplyChainInfo, supplyChainList } = useSelector((state: RootState) => state.brandOperationProductSlice);

  const onSubmit = () => {
    const formValue = form.getFieldsValue();

    if (mode === "edit") {
      dispatch(
        fetchUpdateSupplyChainInfo({
          productId,
          value: {
            currency: formValue.currency,
            quotedPrice: Number(formValue.quotedPrice),
            isActive: formValue.isActive,
            supplyChainId: supplyChainInfo.supplyChainId,
          },
          supplyChainInfoId: supplyChainInfo.id,
        }),
      );
    } else {
      dispatch(
        fetchAddSupplyChainInfo({
          productId,
          value: {
            currency: formValue.currency,
            supplyChainId: formValue.supplyChainName,
            quotedPrice: Number(formValue.quotedPrice),
            isActive: formValue.isActive,
          },
        }),
      );
    }

    close();
  };

  useEffect(() => {
    if (mode === "edit") {
      dispatch(
        fetchSupplyChainInfo({
          productId,
          supplyChainId: supplyChainInfoId,
        }),
      );
    }
  }, [dispatch, mode, productId, supplyChainInfoId]);

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue(supplyChainInfo);
    }
  }, [form, mode, supplyChainInfo]);

  useEffect(() => {
    dispatch(fetchSupplyChain());
  }, [dispatch]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreSupplyChainList());
  }, [dispatch]);

  const supplyChainOptions = useMemo(() => {
    const { next, results } = supplyChainList;

    const options = results.map((item: any) => (
      <Select.Option key={item.id} value={item.id}>
        {item.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [handleOnVendorLoadMore, supplyChainList]);

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue({ isActive: supplyChainInfo.isActive ? 1 : 0 });
    } else {
      form.setFieldsValue({ isActive: 1 });
    }
  }, [form, mode, supplyChainInfo]);

  return (
    <PopupBackground close={close} fixed>
      <Wrapper>
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 5 }}
          form={form}
          initialValues={{ isActive: 1 }}
          onFinish={onSubmit}
        >
          <Form.Item
            wrapperCol={{ span: 7 }}
            label="供應商名稱"
            name="supplyChainName"
            rules={[{ required: true, message: "必填" }]}
          >
            <StyledSelect showSearch filterOption={false}>
              {supplyChainOptions}
            </StyledSelect>
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 7 }}
            label="供應商出廠價"
            name="quotedPrice"
            rules={[{ required: true, message: "必填" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            wrapperCol={{ span: 7 }}
            label="幣別"
            name="currency"
            rules={[{ required: true, message: "必填" }]}
          >
            <StyledSelect placeholder="請選擇">
              <Select.Option value={Currency.TWD}>TWD 台幣</Select.Option>
              <Select.Option value={Currency.USD}>USD 美金</Select.Option>
              <Select.Option value={Currency.RMB}>RMB 人民幣</Select.Option>
              <Select.Option value={Currency.EUR}>EUR 歐元</Select.Option>
              <Select.Option value={Currency.AUD}>AUD 澳幣</Select.Option>
              <Select.Option value={Currency.YEN}>YEN 日圓</Select.Option>
              <Select.Option value={Currency.WON}>WON 韓元</Select.Option>
              <Select.Option value={Currency.GBP}>GBP 英鎊</Select.Option>
            </StyledSelect>
          </Form.Item>
          <Form.Item
            shouldUpdate
            wrapperCol={{ span: 7 }}
            label="狀態"
            name="isActive"
            rules={[{ required: true, message: "必填" }]}
          >
            <StyledSelect defaultValue={1}>
              <Select.Option value={1}>合作中</Select.Option>
              <Select.Option value={0}>停止合作</Select.Option>
            </StyledSelect>
          </Form.Item>
          <Footer>
            <Form.Item>
              <CancelButton onClick={() => close()}>取消</CancelButton>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Form.Item>
          </Footer>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
};

export default ProductOutInfoEdit;
