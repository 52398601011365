import { TopicShortInfo } from "@api/utils/normalizeTopic";
import plusCircle from "@assets/blue-circle@2x.png";
import edit from "@assets/edit@2x.png";
import { configState } from "@redux/configSlice";
import {
  clearTopicInfo,
  fetchTopicList,
  fetchTopicSimpleUpdate,
  fetchUpdateMultiRank,
  topicState,
} from "@redux/topicSlice";
import { Button, Input, Select, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";

const Wrapper = styled.div`
  padding: 20px 41px 20px 25px;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const JustifyBetween = styled(FlexRow)<{ marginBottom?: string }>`
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  padding: 20px 28px 22px 22px;
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  position: relative;
`;

const Icon = styled.img`
  width: 14px;
  margin-right: 5px;
  cursor: pointer;
`;

const PageInfo = styled.a`
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme }) => theme.colorSuccess500};
`;

const OrderMargin = styled.span`
  margin-right: 2px;
`;

const OrderButtons = styled.div`
  position: absolute;
  top: 32px;
  right: 48px;

  & > button:first-child {
    margin-right: 10px;
  }
`;

const CustomInput = styled(Input)`
  width: 70px;
`;

const { Option } = Select;

export default function TopicPage() {
  const dispatch = useDispatch();
  const { topicList, isFetching } = useSelector(topicState);
  const { webDomain } = useSelector(configState);

  const [pageSize, setPageSize] = useState<number>(20);
  const [isOrdering, setIsOrdering] = useState<boolean>(false);
  const [topicMap, setTopicMap] = useState<Map<number, { topic: number; rank: number }>>(new Map());

  const fetchUpdateIsActive = (record: TopicShortInfo) => {
    dispatch(fetchTopicSimpleUpdate({ id: record.id, topicData: { ...record, isActive: !record.isActive } }));
  };

  const fetchUpdateToShow = (record: TopicShortInfo) => {
    dispatch(fetchTopicSimpleUpdate({ id: record.id, topicData: { ...record, toShow: !record.toShow } }));
  };

  const fetchMultiRankUpdate = () => {
    const list = Array.from(topicMap.keys()).map((key) => topicMap.get(key));
    dispatch(
      fetchUpdateMultiRank(
        list as {
          topic: number;
          rank: number;
        }[],
      ),
    );

    setIsOrdering(false);
  };

  const updateTopicMap = (id: number, value: string) => {
    const newTopicMap = new Map(topicMap.set(id, { topic: id, rank: parseInt(value, 10) }));
    setTopicMap(newTopicMap);
  };

  const columns = [
    {
      key: "isActive",
      dataIndex: "isActive",
      title: "起用",
      render: (isActive: boolean, record: TopicShortInfo) => (
        <Switch checked={isActive} disabled={isOrdering} onChange={() => fetchUpdateIsActive(record)} />
      ),
    },
    {
      key: "title",
      dataIndex: "title",
      title: "主題館名稱",
    },
    {
      key: "id",
      dataIndex: "id",
      title: "頁面資訊",
      render: (id: number) => (
        <PageInfo href={`${webDomain}/topic/${id}`} target="_blank">
          {id}
        </PageInfo>
      ),
    },
    {
      key: "toShow",
      dataIndex: "toShow",
      title: "顯示於前台",
      render: (toShow: boolean, record: TopicShortInfo) => (
        <Switch checked={toShow} disabled={isOrdering} onChange={() => fetchUpdateToShow(record)} />
      ),
    },
    {
      key: "rank",
      dataIndex: "rank",
      title: () => (
        <FlexRow>
          <OrderMargin>順序</OrderMargin>
          {!isOrdering && <Icon src={edit} alt="edit" onClick={() => setIsOrdering(true)} />}
        </FlexRow>
      ),
      render: (order: number, record: TopicShortInfo) => (
        <JustifyBetween>
          {isOrdering ? (
            <CustomInput defaultValue={order} onChange={(e) => updateTopicMap(record.id, e.target.value)} />
          ) : (
            <span>{order}</span>
          )}
          <Button type="link" onClick={() => window.open(`${window.location.href}/edit/${record.id}`)}>
            編輯
          </Button>
        </JustifyBetween>
      ),
      width: "40%",
    },
  ];

  useEffect(() => {
    dispatch(fetchTopicList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearTopicInfo());
  }, [dispatch]);

  return (
    <Wrapper>
      <PageTitle title="主題館" />
      <JustifyBetween marginBottom="21px">
        <Button
          type="primary"
          icon={<Icon src={plusCircle} />}
          onClick={() => window.open(`${window.location.href}/edit`)}
        >
          新增主題館
        </Button>
        <FlexRow>
          <PageText>{`總共${topicList.length}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageSize(parseInt(value as string, 10))}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </FlexRow>
      </JustifyBetween>
      <TableWrapper>
        <Table loading={isFetching} columns={columns} dataSource={topicList} pagination={{ pageSize }} />
        {isOrdering && (
          <OrderButtons>
            <Button type="default" onClick={() => setIsOrdering(false)}>
              取消
            </Button>
            <Button type="primary" onClick={fetchMultiRankUpdate}>
              儲存
            </Button>
          </OrderButtons>
        )}
      </TableWrapper>
    </Wrapper>
  );
}
