import {
  ManufacturerList,
  ManufacturerListFilter,
  ContactParams,
  PaymentTermsParams,
  ManufacturerInfo,
  ManufacturerContactList,
  ManufacturerContactItem,
  PaymentTermsInfo,
  PaymentTermsResult,
} from "@api/brandOperation/manufacturerApi";
import brandOperationApi from "@api/brandOperationApi";
import { hideLoading, showLoading } from "@redux/notifySlice";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface IState {
  isFetching: boolean;
  manufacturerListResult: ManufacturerList;
  manufacturerListParams: ManufacturerListFilter;
  manufacturerSelectList: ManufacturerList;
  isSelectLoading: boolean;
  manufacturerSelectListParams: ManufacturerListFilter;
  manufacturerInfo: ManufacturerInfo;
  manufacturerContactListResult: ManufacturerContactList;
  contactParams: ContactParams;
  paymentTermsList: PaymentTermsResult;
  paymentTermsParams: PaymentTermsParams;
}
const initialState: IState = {
  isFetching: false,
  manufacturerListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  manufacturerListParams: {
    nameQ: undefined,
    id: undefined,
    limit: 20,
    offset: 0,
  },
  manufacturerSelectList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  isSelectLoading: false,
  manufacturerSelectListParams: {
    limit: 10,
    offset: 0,
  },
  manufacturerInfo: {
    id: 0,
    name: "",
    address: "",
    phone: "",
    majorName: "",
    majorEmail: "",
    staff: { name: "", id: 0 },
    ubn: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    bankBranch: "",
    currency: 0,
  },
  manufacturerContactListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  contactParams: {
    limit: 20,
    offset: 0,
  },
  paymentTermsList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  paymentTermsParams: {
    limit: 20,
    offset: 0,
  },
};

export const fetchManufacturerList = createAsyncThunk(
  "manufacturerSlice/fetchManufacturerList",
  async (_, thunkApi) => {
    try {
      const {
        manufacturerSlice: { manufacturerListParams },
      } = thunkApi.getState() as RootState;
      const response = await brandOperationApi.fetchManufacturerList(manufacturerListParams);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const loadMoreManufacturerList = createAsyncThunk(
  "manufacturerSlice/loadMoreManufacturerList",
  async (params: { nameQ?: string; limit: number; offset: number }, thunkApi) => {
    const response = await brandOperationApi.fetchManufacturerList(params);
    return response;
  },
);
export const fetchManufacturerDetail = createAsyncThunk(
  "manufacturerSlice/fetchProductDetail",
  async (manufacturerId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await brandOperationApi.fetchManufacturerDetail(manufacturerId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const addManufacturer = createAsyncThunk(
  "manufacturerSlice/addManufacturer",
  async (payload: ManufacturerInfo, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await brandOperationApi.addManufacturer(payload);
      message.success("已新增");
      thunkApi.dispatch(updateManufacturerInfo(response));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const updateManufacturer = createAsyncThunk(
  "manufacturerSlice/updateManufacturer",
  async (payload: ManufacturerInfo & { id: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { id, ...resetPayload } = payload;
      const response = await brandOperationApi.updateManufacturer(resetPayload, id);
      message.success("已儲存");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const deleteManufacturer = createAsyncThunk(
  "manufacturerSlice/deleteManufacturer",
  async (manufacturerId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await brandOperationApi.deleteManufacturer(manufacturerId);
      message.success("已刪除");
      thunkApi.dispatch(fetchManufacturerList());
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const fetchManufacturerContactList = createAsyncThunk(
  "manufacturerSlice/fetchManufacturerContactList",
  async (manufacturerId: number, thunkApi) => {
    try {
      const {
        manufacturerSlice: { contactParams },
      } = thunkApi.getState() as RootState;
      const response = await brandOperationApi.fetchManufacturerContactList(contactParams, manufacturerId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const addManufacturerContact = createAsyncThunk(
  "manufacturerSlice/addManufacturerContact",
  async (payload: ManufacturerContactItem & { manufacturerId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { manufacturerId, ...restPayload } = payload;
      await brandOperationApi.addManufacturerContact(restPayload, manufacturerId);
      message.success("已新增");
      thunkApi.dispatch(fetchManufacturerContactList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const updateManufacturerContact = createAsyncThunk(
  "manufacturerSlice/updateManufacturerContact",
  async (payload: ManufacturerContactItem & { manufacturerId: number; id: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { id, manufacturerId, ...restPayload } = payload;
      await brandOperationApi.updateManufacturerContact(restPayload, id, manufacturerId);
      message.success("已儲存");
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(fetchManufacturerContactList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const deleteManufacturerContact = createAsyncThunk(
  "manufacturerSlice/deleteManufacturerContact",
  async (params: { manufacturerId: number; contactId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { manufacturerId, contactId } = params;
      await brandOperationApi.deleteManufacturerContact(contactId, manufacturerId);
      message.success("已刪除");
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(fetchManufacturerContactList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const fetchPaymentTermsList = createAsyncThunk(
  "manufacturerSlice/fetchPaymentTermsList",
  async (manufacturerId: number, thunkApi) => {
    try {
      const {
        manufacturerSlice: { paymentTermsParams },
      } = thunkApi.getState() as RootState;
      const response = await brandOperationApi.fetchPaymentTermsList(paymentTermsParams, manufacturerId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const addPaymentTerms = createAsyncThunk(
  "manufacturerSlice/addPaymentTerms",
  async (payload: PaymentTermsInfo & { manufacturerId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { manufacturerId, ...restPayload } = payload;
      await brandOperationApi.addPaymentTerms(restPayload, manufacturerId);
      message.success("已新增");
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(fetchPaymentTermsList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const updatePaymentTerms = createAsyncThunk(
  "manufacturerSlice/updatePaymentTerms",
  async (payload: PaymentTermsInfo & { manufacturerId: number; id: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { id, manufacturerId, ...restPayload } = payload;
      await brandOperationApi.updatePaymentTerms(restPayload, id, manufacturerId);
      message.success("已儲存");
      thunkApi.dispatch(hideLoading());
      thunkApi.dispatch(fetchPaymentTermsList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const deletePaymentTerms = createAsyncThunk(
  "manufacturerSlice/deletePaymentTerms",
  async (params: { manufacturerId: number; paymentTermsId: number }, thunkApi) => {
    try {
      const { manufacturerId, paymentTermsId } = params;
      await brandOperationApi.deletePaymentTerms(paymentTermsId, manufacturerId);
      message.success("已刪除");
      thunkApi.dispatch(fetchPaymentTermsList(manufacturerId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
const manufacturerSlice = createSlice({
  name: "manufacturerSlice",
  initialState,
  reducers: {
    resetManufacturerSlice: () => initialState,
    updateManufacturerListParams: (state, action) => {
      state.manufacturerListParams = action.payload;
    },
    updateManufacturerInfo: (state, action) => {
      state.manufacturerInfo = action.payload;
    },
    resetManufacturerListParams: (state) => {
      state.manufacturerListParams = initialState.manufacturerListParams;
    },
    updateContactParams: (state, action) => {
      state.contactParams = action.payload;
    },
    updatePaymentTermsParams: (state, action) => {
      state.paymentTermsParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManufacturerList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchManufacturerList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchManufacturerList.fulfilled, (state, action) => {
      state.manufacturerListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(loadMoreManufacturerList.pending, (state, action) => {
      state.isSelectLoading = true;
    });
    builder.addCase(loadMoreManufacturerList.fulfilled, (state, action) => {
      state.manufacturerSelectList = action.payload;
      state.isSelectLoading = false;
    });
    builder.addCase(fetchManufacturerDetail.fulfilled, (state, action) => {
      state.manufacturerInfo = action.payload;
    });
    builder.addCase(fetchManufacturerContactList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchManufacturerContactList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchManufacturerContactList.fulfilled, (state, action) => {
      state.manufacturerContactListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchPaymentTermsList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchPaymentTermsList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchPaymentTermsList.fulfilled, (state, action) => {
      state.paymentTermsList = action.payload;
      state.isFetching = false;
    });
  },
});
export const manufacturerState = (state: RootState) => state.manufacturerSlice;
export const {
  resetManufacturerSlice,
  updateManufacturerListParams,
  updateManufacturerInfo,
  resetManufacturerListParams,
  updateContactParams,
  updatePaymentTermsParams,
} = manufacturerSlice.actions;
export default manufacturerSlice.reducer;
