import React, { FC, useEffect, useRef } from "react";

interface Props {
  callback: () => void;
  options?: IntersectionObserverInit;
}

const InfiniteScrollObserver: FC<Props> = (props) => {
  const { callback, options } = props;
  const observer = useRef<IntersectionObserver>();
  const element = useRef<HTMLDivElement>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      if (entry && entry.isIntersecting) {
        callback();
      }
    }, options);

    if (element.current) observer.current.observe(element.current);

    return () => observer.current?.disconnect();
  }, [callback, options]);

  return <div ref={element} />;
};

export default InfiniteScrollObserver;
