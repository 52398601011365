import { CloseOutlined, InfoCircleFilled, UploadOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import { Button } from "antd";
import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import Loading from "@component/Loading/Loading";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 305px;
  background-color: white;
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 22px;
  right: 22px;
`;
const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadIcon = styled(UploadOutlined)`
  font-size: 66px;
  color: ${({ theme }) => theme.colorNeutral500};
  margin-bottom: 35px;
`;
const WarningIcon = styled(InfoCircleFilled)`
  font-size: 42px;
  color: #f16c5d;
  margin-bottom: 39px;
`;
const SelectFile = styled.label`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const DownloadFile = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;
const GobackButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)``;
const InputFile = styled.input`
  display: none;
`;
const ErrorText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
  margin: 4px 0 8px 0;
`;
const NoticeText = styled.p`
  position: absolute;
  left: 27px;
  bottom: 28px;
  font-size: 13px;
  margin: 0;
`;

type Props = {
  csvErrorMessage: string;
  closePopup: () => void;
  clearErrorMessage: () => void;
  handleUploadFile: (file: File) => void;
  openTemplateLink?: string;
  templatePath?: string;
  noticeText?: string;
  csvErrorReason?: any;
  fileType?: string;
  isFetching?: boolean;
};

const ImportFileModal = (props: Props) => {
  const {
    csvErrorMessage,
    closePopup,
    clearErrorMessage,
    handleUploadFile,
    openTemplateLink,
    templatePath,
    noticeText,
    csvErrorReason,
    fileType,
    isFetching,
  } = props;
  const [uploadFile, setUploadFile] = useState<File>();

  const displayText = () => {
    if (csvErrorMessage) {
      return (
        <>
          <ErrorText>{csvErrorMessage}</ErrorText>
          {csvErrorReason && <ErrorText>{`${csvErrorReason.message} : ${csvErrorReason.result}`}</ErrorText>}
        </>
      );
    }
    return (
      <SelectFile>
        {uploadFile ? uploadFile.name : " 選擇檔案"}
        <InputFile type="file" accept={fileType || ".csv"} onChange={handleOnFileUpload} />
      </SelectFile>
    );
  };
  const handleOnFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) setUploadFile(files[0]);
  };

  const closeImportPopup = () => {
    handleOnGoBack();
  };

  const handleOnGoBack = () => {
    clearErrorMessage();
    setUploadFile(undefined);
    closePopup();
  };

  const handleOnImport = () => {
    if (uploadFile) handleUploadFile(uploadFile);
  };

  return (
    <PopupBackground close={closeImportPopup}>
      <Wrapper>
        {isFetching && <Loading />}
        <CloseIcon onClick={closeImportPopup} />
        <CenterBlock>
          {csvErrorMessage ? <WarningIcon /> : <UploadIcon />}
          {displayText()}
          {openTemplateLink && (
            <DownloadFile href={openTemplateLink} target="_blank">
              範例檔案
            </DownloadFile>
          )}
          {templatePath && (
            <DownloadFile href={`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}${templatePath}`}>下載範例檔案</DownloadFile>
          )}
        </CenterBlock>
        <ButtonContainer>
          {!!csvErrorMessage && <GobackButton onClick={handleOnGoBack}>返回</GobackButton>}
          <ImportButton type="primary" onClick={handleOnImport} disabled={!uploadFile || !!csvErrorMessage}>
            匯入
          </ImportButton>
        </ButtonContainer>
        {noticeText && <NoticeText>{noticeText || "註: 匯入上限為100筆"}</NoticeText>}
      </Wrapper>
    </PopupBackground>
  );
};

export default ImportFileModal;
