import React, { FC, useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { SelectValue } from "antd/lib/select";
import { batchActivesProducts } from "@redux/productSlice";
import { useAppDispatch } from "src/store";
import { Modal, Button, Select } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";

interface Props {
  visible: boolean;
  onClose: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  padding: 32px 28px 20px 32px;
`;
const Title = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  font-weight: bold;
  margin-bottom: 32px;
`;
const SelectRow = styled.div`
  display: flex;
  align-items: center;
`;
const RowTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
  margin-right: 20px;
`;
const StyledSelect = styled(Select)`
  width: 180px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;

const statusOptions = [
  {
    value: -1,
    label: "請選擇",
  },
  {
    value: 0,
    label: "下架",
  },
  {
    value: 1,
    label: "上架",
  },
];

const BatchActivePopup: FC<Props> = (props) => {
  const { visible, onClose } = props;
  const dispatch = useAppDispatch();
  const productListResult = useSelector((state: RootState) => state.product.productListResult);
  const selectedProductIds = useSelector((state: RootState) => state.product.selectedProductIds);

  const [status, setStatus] = useState<number>(-1);

  const { results: productList } = productListResult;

  const handleIsActiveOnChange = (value: SelectValue) => {
    if (!value) {
      Modal.confirm({
        title: "請問是否確認下架？",
        icon: <ExclamationCircleFilled />,
        content: "商品下架相關合作方式及方案(包含贈品及加購品)都將自動改為下架",
        okText: "確認",
        cancelText: "取消",
        onOk: () => setStatus(value as number),
        onCancel: () => setStatus((prevState) => prevState),
      });
    } else {
      setStatus(value as number);
    }
  };
  useEffect(() => {
    const selectedProducts = productList.filter((product) => selectedProductIds.includes(product.id));

    // 所有選得商品都active => 自動選上架; 反之下架; 混合則不選
    if (selectedProducts.every((product) => product.isActive)) setStatus(1);
    else if (selectedProducts.every((product) => !product.isActive)) setStatus(0);
    else setStatus(-1);
  }, [selectedProductIds, productList]);

  const handleOnSubmit = () => {
    switch (status) {
      case -1:
        return;
      case 0:
        dispatch(batchActivesProducts(false));
        onClose();
        break;
      case 1:
        dispatch(batchActivesProducts(true));
        onClose();
        break;
      default:
    }
  };

  return (
    <Modal visible={visible} closable={false} footer={false} bodyStyle={{ padding: 0, width: 520, height: 223 }}>
      <Wrapper>
        <Title>批次修改上下架狀態</Title>
        <SelectRow>
          <RowTitle>狀態</RowTitle>
          <StyledSelect
            options={statusOptions}
            value={status}
            onChange={(value) => handleIsActiveOnChange(value as SelectValue)}
          />
        </SelectRow>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" onClick={handleOnSubmit}>
            確定
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default BatchActivePopup;
