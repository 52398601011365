import { FetchRuleListParams } from "@api/ruleApi";
import { RootState } from "@redux/rootReducer";
import { cleaRuleListParams, fetchRuleList, updateRuleListParams } from "@redux/ruleSlice";
import { Button, DatePicker, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import moment, { Moment } from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import actionTypeValue from "./actionTypeValue";
import conditionTypeValue from "./conditionTypeValue";

const Wrapper = styled.form`
  padding: 0 24px;
  margin-bottom: 20px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;
const Section = styled.div`
  display: flex;
  align-items: center;
  margin-right: 50px;
`;
const SectionTitle = styled.div<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}px` : "")};
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const SectionContent = styled.div<{ width?: number }>`
  min-width: 0;
  width: ${({ width }) => (width ? `${width}px` : "")};
  margin-left: 20px;
`;
const StyledSelect = styled(Select)`
  width: 100%;
`;
const RangePicker = styled(DatePicker.RangePicker)`
  width: 380px;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const ClearButton = styled(Button)`
  margin-right: 8px;
`;

const activeOptions = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: "是",
    value: 1,
  },
  {
    label: "否",
    value: 0,
  },
];
export const conditionOptions = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: conditionTypeValue[0],
    value: 0,
  },
  {
    label: conditionTypeValue[1],
    value: 1,
  },
  {
    label: conditionTypeValue[2],
    value: 2,
  },
];
export const actionOptions = [
  {
    label: "請選擇",
    value: -1,
  },
  {
    label: actionTypeValue[0],
    value: 0,
  },
  {
    label: actionTypeValue[1],
    value: 1,
  },
  {
    label: actionTypeValue[2],
    value: 2,
  },
  {
    label: actionTypeValue[3],
    value: 3,
  },
  {
    label: actionTypeValue[4],
    value: 4,
  },
  {
    label: actionTypeValue[5],
    value: 5,
  },
  {
    label: actionTypeValue[6],
    value: 6,
  },
  {
    label: actionTypeValue[7],
    value: 7,
  },
  {
    label: actionTypeValue[8],
    value: 8,
  },
  {
    label: actionTypeValue[9],
    value: 9,
  },
];

type LocalFilter = Omit<FetchRuleListParams, "bindStartDate" | "bindEndDate" | "useStartDate" | "useEndDate"> & {
  bindStartDate: Moment | null;
  bindEndDate: Moment | null;
  useStartDate: Moment | null;
  useEndDate: Moment | null;
};

const defaultFilter: LocalFilter = {
  limit: 20,
  offset: 0,
  isActive: -1,
  conditionType: -1,
  actionType: -1,
  bindStartDate: null,
  bindEndDate: null,
  useStartDate: null,
  useEndDate: null,
};

const Filter: FC = () => {
  const dispatch = useAppDispatch();
  const { ruleListParams } = useSelector((state: RootState) => state.rule);

  const [localFilter, setLocalFilter] = useState<LocalFilter>(defaultFilter);
  const {
    id,
    isActive,
    conditionType,
    actionType,
    titleQ,
    couponQ,
    bindStartDate,
    bindEndDate,
    useStartDate,
    useEndDate,
  } = localFilter;

  useEffect(() => {
    setLocalFilter({
      ...ruleListParams,
      bindStartDate: ruleListParams.bindStartDate ? moment(ruleListParams.bindStartDate) : null,
      bindEndDate: ruleListParams.bindEndDate ? moment(ruleListParams.bindEndDate) : null,
      useStartDate: ruleListParams.useStartDate ? moment(ruleListParams.useStartDate) : null,
      useEndDate: ruleListParams.useEndDate ? moment(ruleListParams.useEndDate) : null,
    });
  }, [ruleListParams]);

  const handleOnChange = (title: string) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value,
    }));
  };

  const handleBindRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [rangeStartDate, rangeEndDate] = dates;
      setLocalFilter((prev) => ({
        ...prev,
        bindStartDate: rangeStartDate,
        bindEndDate: rangeEndDate,
      }));
    } else {
      setLocalFilter((prev) => ({
        ...prev,
        bindStartDate: null,
        bindEndDate: null,
      }));
    }
  };

  const handleUseRangePick = (dates: RangeValue<Moment>) => {
    if (dates) {
      const [rangeStartDate, rangeEndDate] = dates;
      setLocalFilter((prev) => ({
        ...prev,
        useStartDate: rangeStartDate,
        useEndDate: rangeEndDate,
      }));
    } else {
      setLocalFilter((prev) => ({
        ...prev,
        useStartDate: null,
        useEndDate: null,
      }));
    }
  };

  const handleOnSelect = (title: string) => (value: SelectValue) => {
    setLocalFilter((prev) => ({
      ...prev,
      [title]: value as number,
    }));
  };

  const handleOnClearFilter = () => {
    setLocalFilter(defaultFilter);
    dispatch(cleaRuleListParams());
  };

  const handleOnSaveFilter = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const filter: FetchRuleListParams = {
      ...localFilter,
      bindStartDate: bindStartDate === null ? undefined : moment(bindStartDate).format("YYYY-MM-DD"),
      bindEndDate: bindEndDate === null ? undefined : moment(bindEndDate).format("YYYY-MM-DD"),
      useStartDate: useStartDate === null ? undefined : moment(useStartDate).format("YYYY-MM-DD"),
      useEndDate: useEndDate === null ? undefined : moment(useEndDate).format("YYYY-MM-DD"),
    };

    dispatch(updateRuleListParams(filter));
    dispatch(fetchRuleList());
  };

  return (
    <Wrapper onSubmit={handleOnSaveFilter}>
      <Row>
        <Section>
          <SectionTitle width={60}>ID</SectionTitle>
          <SectionContent width={108}>
            <Input value={id} onChange={handleOnChange("id")} />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>名稱</SectionTitle>
          <SectionContent width={322}>
            <Input value={titleQ} onChange={handleOnChange("titleQ")} />
          </SectionContent>
        </Section>
      </Row>
      <Row>
        <Section>
          <SectionTitle width={60}>使用期限</SectionTitle>
          <SectionContent>
            <RangePicker value={[useStartDate, useEndDate]} onChange={handleUseRangePick} />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle width={60}>開放領取</SectionTitle>
          <SectionContent>
            <RangePicker value={[bindStartDate, bindEndDate]} onChange={handleBindRangePick} />
          </SectionContent>
        </Section>
      </Row>
      <Row>
        <Section>
          <SectionTitle width={60}>啟用</SectionTitle>
          <SectionContent width={108}>
            <StyledSelect
              options={activeOptions}
              value={isActive}
              onChange={(value) => handleOnSelect("isActive")(value as SelectValue)}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>條件</SectionTitle>
          <SectionContent width={108}>
            <StyledSelect
              options={conditionOptions}
              value={conditionType}
              onChange={(value) => handleOnSelect("conditionType")(value as SelectValue)}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>案型</SectionTitle>
          <SectionContent width={130}>
            <StyledSelect
              options={actionOptions}
              value={actionType}
              onChange={(value) => handleOnSelect("actionType")(value as SelectValue)}
            />
          </SectionContent>
        </Section>
        <Section>
          <SectionTitle>兌換碼</SectionTitle>
          <SectionContent width={130}>
            <Input value={couponQ} onChange={handleOnChange("couponQ")} />
          </SectionContent>
        </Section>
      </Row>
      <ButtonContainer>
        <ClearButton onClick={handleOnClearFilter}>清除篩選條件</ClearButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
