/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteModule, removeMedia } from "@api/uploadMediaApi";
import UploadImageButton from "@component/UploadImageButton";
import { createDiscountFrame, updateDiscountFrameInfo } from "@redux/discountFrameSlice";
import { RootState } from "@redux/rootReducer";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  padding: 0px 27px;
  position: relative;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const ImgInfo = styled.span`
  padding-left: 10px;
  color: #a7a7a7;
  align-self: center;
`;
const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;
const FooterNotice = styled.span`
  margin-right: 12px;
  ${fontStyle("14px", "22px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;

export default function DiscountFrameContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const frameDetail = useSelector((state: RootState) => state.discountFrameSlice.discountFrameDetail);
  const { mediaId } = frameDetail.discountFrameInfo;
  const { frameId } = useParams();
  const [form] = Form.useForm();

  const [uploadImage, setUploadImage] = useState(false);

  const removeImageMedia = () => {
    removeMedia({
      module: DeleteModule.DISCOUNT_FRAME,
      discountFrameId: Number(frameId),
      mediaId,
    });
    form.setFieldsValue({ imageFilePath: null });
  };

  const onSubmit = (fieldsValue: any) => {
    const { imageFilePath, title, date } = fieldsValue;
    const [startDate, endDate] = date;
    const params = {
      title,
      imageFilePath: uploadImage ? imageFilePath : null,
      imageDirPrefix: uploadImage ? "sales/image_frame_activity/image" : null,
      startAt: moment(startDate).startOf("minute").format("YYYY-MM-DD HH:mm:ss"),
      endAt: moment(endDate).endOf("minute").format("YYYY-MM-DD HH:mm:ss"),
    };

    if (frameDetail.frameId !== -1) {
      dispatch(updateDiscountFrameInfo(params));
      setUploadImage(false);
    } else {
      dispatch(createDiscountFrame(params));
      setUploadImage(false);
    }
  };

  useEffect(() => {
    // 表示創立成功，導去編輯頁
    if (frameDetail.frameId !== -1) {
      navigate(`/discount-frame/edit/${frameDetail.frameId}`);
    }

    if (frameDetail.discountFrameInfo.id !== -1) {
      const info = frameDetail.discountFrameInfo;
      form.setFieldsValue({
        endAt: info.endAt,
        imageFilePath: info.imagePath,
        startAt: info.startAt,
        title: info.title,
        date: [moment(info.startAt), moment(info.endAt)],
      });
    }
  }, [frameDetail?.frameId, frameDetail.discountFrameInfo.id, navigate, frameDetail.discountFrameInfo, form]);

  return (
    <Wrapper
      colon={false}
      labelCol={{ span: 3 }}
      wrapperCol={{ span: 8 }}
      labelAlign="left"
      form={form}
      onFinish={onSubmit}
      onFinishFailed={showRequiredFieldsWarning}
    >
      <Form.Item label="宣傳名稱" name="title" rules={[{ required: true, message: "必填" }]}>
        <Input />
      </Form.Item>
      <Form.Item shouldUpdate wrapperCol={{ span: 24 }}>
        {({ getFieldValue }) => (
          <Form.Item
            labelCol={{ span: 3 }}
            label="宣傳框"
            name="imageFilePath"
            rules={[{ required: true, message: "必填" }]}
            shouldUpdate
          >
            <UploadImageButton
              hasPreviewAndDelete
              imageUrl={getFieldValue("imageFilePath") || ""}
              finishUpload={(url) => {
                setUploadImage(true);
                form.setFieldsValue({ imageFilePath: url });
              }}
              limitSize={1}
              dimension={[800, 800]}
              onDelete={() =>
                Modal.confirm({
                  title: "確定要刪除此圖片嗎?",
                  icon: <ExclamationCircleOutlined />,
                  okText: "是",
                  cancelText: "否",
                  onOk() {
                    removeImageMedia();
                  },
                })
              }
            />
            <ImgInfo>尺寸=800x800(單位px), 大小上限為1MB</ImgInfo>
          </Form.Item>
        )}
      </Form.Item>
      <Form.Item label="宣傳時間區間" name="date" rules={[{ type: "array", required: true, message: "必填" }]}>
        <DatePicker.RangePicker
          style={{ width: "100%" }}
          format="YYYY-MM-DD HH:mm"
          showTime={{
            hideDisabledOptions: true,
            format: "HH:mm",
          }}
        />
      </Form.Item>
      <Footer>
        <FooterNotice>*建立後顯示新增方案 / 銷售頁</FooterNotice>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {frameId ? "儲存此分頁" : "建立資料"}
          </Button>
        </Form.Item>
      </Footer>
    </Wrapper>
  );
}
