import CustomError from "@api/utils/CustomeError";
import UserPermission from "@constant/UserPermission";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { User } from "src/types";

export const fetchUserState = createAsyncThunk<
  User.State,
  undefined,
  {
    rejectValue: CustomError;
  }
>("user/fetchUserState", async (_, thunkAPI) => {
  try {
    const state = {
      permission: UserPermission.CUSTOMER_SERVICE,
    };
    return state;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface SliceState {
  isPending: boolean;
  isFulfilled: boolean;
  user: User.State;
}

const userSlice = createSlice({
  name: "user",
  initialState: {
    isPending: false,
    isFulfilled: false,
    user: { permission: -1 },
  } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserState.pending, (state) => {
        state.isPending = true;
      })
      .addCase(fetchUserState.fulfilled, (state, action) => {
        state.isFulfilled = true;
        state.isPending = false;
        state.user = { ...action.payload };
      })
      .addCase(fetchUserState.rejected, (state) => {
        state.isFulfilled = false;
        state.isPending = false;
      });
  },
});

export default userSlice.reducer;
