const fields = {
  bdOwnerId: "bdOwnerId",
  vendorName: "vendorName",
  vendorPhone: "vendorPhone",
  vendorUbn: "vendorUbn",
  accountName: "accountName",
  accountNumber: "accountNumber",
  bankName: "bankName",
  bankId: "bankId",
  branchName: "branchName",
  swiftCode: "swiftCode",
  currency: "currency",
  payCondition: "payCondition",
  registerAddr: "registerAddress",
  businessAddr: "activeAddress",
};

export default fields;
