import CoreAPI from "@api/CoreAPI";
import { transformAPIKeyToCamel } from "@api/utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "@api/utils/transformCamelToSnake";
import { ProcessingStatus } from "@page/ProcessingApplication/ProcessingStatus";
import { ApiResponse } from "src/types";

const apiClient = new CoreAPI();

interface ProcessingApplicationApi {
  fetchProcessingAppList: (params: ProcessingAppListParams) => Promise<ProcessingAppList>;
  fetchProcessingAppDetail: (processingAppId: number) => Promise<ProcessingAppListItem>;
  createProcessingApp: (payload: ProcessingAppListCreateItem) => Promise<ProcessingAppListItem>;
  updateProcessingApp: (
    processingAppId: number,
    payload: ProcessingAppListCreateItem,
  ) => Promise<ProcessingAppListItem>;
  cancelProcessingApp: (processingAppId: number) => Promise<ProcessingAppList>;
  fetchProcessingLineList: (processingAppId: number) => Promise<ProcessingLineList["results"]>;
  addProcessingLineItem: (
    processingAppId: number,
    type: ProcessingLineType,
    payload: ProcessingLineCreateItem,
  ) => Promise<void>;
  updateProcessingLineItem: (
    processingAppId: number,
    id: number,
    type: ProcessingLineType,
    payload: ProcessingLineCreateItem & { id?: number },
  ) => Promise<void>;
  deleteProcessingLineItem: (processingAppId: number, itemId: number) => Promise<void>;
  uploadToWMSSystem: (processingAppId: number) => Promise<void>;
  fetchProductStockDetail: (no: string) => Promise<ProductStockDetail>;
}

export enum ProcessingLineType {
  SOURCE = 1,
  TARGET = 2,
}

export interface ProcessingAppList extends ApiResponse.ListResponseType {
  results: ProcessingAppListItem[];
}
export interface ProcessingAppListCreateItem {
  id?: number;
  procNumber: string;
  estDeliveryDate: string;
  note: string;
}
export interface ProcessingAppListItem extends ProcessingAppListCreateItem {
  id: number;
  procNumber: string;
  estDeliveryDate: string;
  status: ProcessingStatus;
  createdAt: string;
  destroyedAt: string;
  note: string;
  staff: { id: number; name: string };
}
export interface ProcessingLineCreateItem {
  id?: number;
  no: string;
  type: number;
  storageType: string;
  effectiveDate: string;
  qty: number;
  batch: string;
}
export interface ProcessingLineList {
  count: number;
  next: string;
  previous: string;
  results: {
    [ProcessingLineType.TARGET]: ProcessingLineItemInfo[];
    [ProcessingLineType.SOURCE]: ProcessingLineItemInfo[];
  };
}

export interface ProcessingLineItemInfo {
  id: number;
  processingId: number;
  type: number;
  no: string;
  name: string;
  storageType: string;
  effectiveDate: string;
  batch: string;
  cost: number;
  qty: number;
}

export interface ProcessingAppListParams {
  procNumbersQ?: string;
  status?: boolean;
  offset: number;
  limit: number;
}
export interface ProductStockDetail {
  name: string;
  cost: number;
  stocks: { [key: string]: StockDetail[] };
}
export interface StockDetail {
  batch: string;
  effectiveDate: string;
  canSalesQty: number;
}

const processingApplicationApi: ProcessingApplicationApi = {
  fetchProcessingAppList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/tryall/manage/process/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProcessingAppDetail: async (processingAppId) => {
    const response = await apiClient.getData(`/tryall/manage/process/${processingAppId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  createProcessingApp: async (payload) => {
    const postParams = transformCamelToSnake(payload);
    const response = await apiClient.postData("/tryall/manage/process/", postParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateProcessingApp: async (processingAppId, payload) => {
    const putParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(`/tryall/manage/process/${processingAppId}/`, putParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  cancelProcessingApp: async (processingAppId) => {
    const response = await apiClient.deleteData(`/tryall/manage/process/${processingAppId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProcessingLineList: async (processingAppId) => {
    const response = await apiClient.getData(`/tryall/manage/process/${processingAppId}/process-lines/`, {});
    return transformAPIKeyToCamel(response.data.result.results);
  },
  addProcessingLineItem: async (processingAppId, type, payload) => {
    const postParams = transformCamelToSnake(payload);
    const response = await apiClient.postData(`/tryall/manage/process/${processingAppId}/process-lines/`, {
      ...postParams,
      type,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  updateProcessingLineItem: async (processingAppId, id, type, payload) => {
    const putParams = transformCamelToSnake(payload);
    const response = await apiClient.putData(`/tryall/manage/process/${processingAppId}/process-lines/${id}/`, {
      ...putParams,
      type,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteProcessingLineItem: async (processingAppId, itemId) => {
    const response = await apiClient.deleteData(
      `/tryall/manage/process/${processingAppId}/process-lines/${itemId}/`,
      {},
    );
    return transformAPIKeyToCamel(response.data.result);
  },
  uploadToWMSSystem: async (processingAppId) => {
    const response = await apiClient.putData(`/tryall/manage/process/${processingAppId}/deploy-to-lms/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchProductStockDetail: async (no) => {
    const response = await apiClient.getData("/tryall/manage/process/check-line/", { no });
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default processingApplicationApi;
