import { DefaultTheme } from "styled-components";

const defaultTheme: DefaultTheme = {
  colorPrimary500: "#4A559D",
  colorPrimary200: "#C9CCDE",
  colorSecondary500: "#EC6922",
  colorSecondary400: "#F16C5D",
  colorSecondary300: "#F5A623",
  colorSecondary100: "#FEF0EF",
  colorNeutral700: "#000000",
  colorNeutral600: "#464C51",
  colorNeutral500: "#A7A7A7",
  colorNeutral400: "#D3D3D3",
  colorNeutral300: "#F2F2F2",
  colorNeutral200: "#F5F5F5",
  colorNeutral150: "#FAFAFA",
  colorNeutral100: "#FFFFFF",
  colorSuccess500: "#2E90B7",
  colorSuccess400: "#4BC889",
  colorSuccess300: "#C8AE8C",
};

export default defaultTheme;
