import PageTitle from "@component/PageTitle";
import { Tabs } from "antd";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AccessPoint from "./AccessPoint";
import ContactInformation from "./ContactInformation";
import ContractManagement from "./ContractManagement";
import DealerContent from "./DealerContent";
import DeductionFee from "./DeductionFee";

const Wrapper = styled.div`
  padding: 18px 14px 0px 14px;

  .ant-tabs {
    overflow: visible;
  }
`;
const Scope = styled.div`
  padding: 12px 16px 78px 16px;
  border: solid 1px #f0f0f0;
`;

enum TabList {
  DEALER_CONTENT = "DEALER_CONTENT",
  CONTACT_INFORMATION = "CONTACT_INFORMATION",
  DEDUCTION_FEE = "DEDUCTION_FEE",
  ACCESS_POINT = "ACCESS_POINT",
  CONTRACT_MANAGEMENT = "CONTRACT_MANAGEMENT",
}

export default function DealerInformation() {
  const { dealerId } = useParams();

  const [activeKey, setActiveKey] = useState<string>(TabList.DEALER_CONTENT);

  return (
    <Wrapper>
      <PageTitle title="" />
      <Scope>
        <Tabs type="card" activeKey={activeKey} onChange={(key) => setActiveKey(key)}>
          <Tabs.TabPane tab="通路資訊" key={TabList.DEALER_CONTENT}>
            <DealerContent />
          </Tabs.TabPane>
          {dealerId && (
            <>
              <Tabs.TabPane tab="聯絡資訊" key={TabList.CONTACT_INFORMATION}>
                <ContactInformation />
              </Tabs.TabPane>
              <Tabs.TabPane tab="後扣費用" key={TabList.DEDUCTION_FEE}>
                <DeductionFee />
              </Tabs.TabPane>
              <Tabs.TabPane tab="通路倉點" key={TabList.ACCESS_POINT}>
                <AccessPoint />
              </Tabs.TabPane>
              <Tabs.TabPane tab="合約管理" key={TabList.CONTRACT_MANAGEMENT}>
                <ContractManagement />
              </Tabs.TabPane>
            </>
          )}
        </Tabs>
      </Scope>
    </Wrapper>
  );
}
