import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import nutritionApi, { SummaryResult, NutritionSummary, LabelList, NutritionRelations } from "@api/nutritionApi";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  nutritionSummary: NutritionSummary;
  summaryResult: SummaryResult;
  isFetching: boolean;
  isSummaryFetching: boolean;
  showAddPopup: boolean;
  labelList: LabelList;
  summaryUnitList: string[];
  nutritionRelations: NutritionRelations;
}

export const initialState: IState = {
  nutritionSummary: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  nutritionRelations: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  labelList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  summaryUnitList: [],
  summaryResult: {
    id: 0,
    servingSize: 0,
    perServing: 0,
    perServingUnit: "",
    servingPerContainer: 0,
    createdAt: "",
    updatedAt: "",
    product: 0,
  },
  isFetching: false,
  isSummaryFetching: true,
  showAddPopup: false,
};

export const fetchLabelList = createAsyncThunk("nutrition/fetchLabelList", async (_, thunkApi) => {
  const response = await nutritionApi.fetchLabelList();
  return response;
});
export const fetchSummaryUnitList = createAsyncThunk("nutrition/fetchSummaryUnitList", async (_, thunkApi) => {
  const response = await nutritionApi.fetchSummaryUnitList();
  return response;
});
export const fetchAddLabel = createAsyncThunk(
  "nutrition/fetchAddLabel",
  async (params: { label: string; unit: string; dialyIntake: string; filterEnabled: boolean }, thunkApi) => {
    try {
      const response = await nutritionApi.fetchAddLabel(params);
      thunkApi.dispatch(fetchLabelList());
      message.success("已新增");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchNutritionSummary = createAsyncThunk(
  "nutrition/fetchNutritionSummary",
  async (productId: string, thunkApi) => {
    const response = await nutritionApi.fetchNutritionSummary(productId);
    thunkApi.dispatch(fetchSummaryUnitList());
    return response;
  },
);
export const fetchAddNutritionSummary = createAsyncThunk(
  "nutrition/fetchAddNutritionSummary",
  async (
    params: {
      productId: string;
      servingSize?: number;
      servingPerContainer: number;
      perServing?: number;
      perServingUnit?: string;
    },
    thunkApi,
  ) => {
    try {
      await nutritionApi.fetchAddNutritionSummary(params);
      message.success("已新增");
      thunkApi.dispatch(fetchNutritionSummary(params.productId));
      thunkApi.dispatch(fetchSummaryUnitList());
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchUpdateNutritionSummary = createAsyncThunk(
  "nutrition/fetchUpdateNutritionSummary",
  async (
    params: {
      productId: string;
      servingSize?: number;
      servingPerContainer: number;
      summaryId: number;
      perServing?: number;
      perServingUnit?: string;
    },
    thunkApi,
  ) => {
    try {
      await nutritionApi.fetchUpdateNutritionSummary(params);
      thunkApi.dispatch(fetchNutritionSummary(params.productId));
      thunkApi.dispatch(fetchSummaryUnitList());
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
export const fetchNutritionRelation = createAsyncThunk(
  "nutrition/fetchNutritionRelation",
  async (productId: string, thunkApi) => {
    const response = await nutritionApi.fetchNutritionRelation(productId);
    return response;
  },
);
export const fetchUpdateNutritionRelation = createAsyncThunk(
  "nutrition/fetchUpdateNutritionRelation",
  async (
    params: {
      productId: string;
      data: {
        id: number | null;
        labelId: number;
        amountPerServing: number;
        rank: number;
      }[];
    },
    thunkApi,
  ) => {
    try {
      await nutritionApi.fetchUpdateNutritionRelation(params);
      message.success("已更新");
      thunkApi.dispatch(fetchNutritionRelation(params.productId));
      return "success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const nutritionSlice = createSlice({
  name: "nutrition",
  initialState,
  reducers: {
    reset: () => initialState,
    setShowAddPopup: (state, action) => {
      state.showAddPopup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLabelList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchLabelList.fulfilled, (state, action) => {
      state.labelList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSummaryUnitList.fulfilled, (state, action) => {
      state.summaryUnitList = action.payload;
    });
    builder.addCase(fetchAddLabel.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchAddLabel.fulfilled, (state, action) => {
      state.isFetching = false;
      state.showAddPopup = false;
    });
    builder.addCase(fetchNutritionSummary.pending, (state, action) => {
      state.isSummaryFetching = true;
    });
    builder.addCase(fetchNutritionSummary.fulfilled, (state, action) => {
      state.isSummaryFetching = false;
      state.nutritionSummary = action.payload;
    });
    builder.addCase(fetchNutritionRelation.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchNutritionRelation.fulfilled, (state, action) => {
      state.isFetching = false;
      state.nutritionRelations = action.payload;
    });
  },
});

export const { reset, setShowAddPopup } = nutritionSlice.actions;
export const nutritionState = (state: RootState) => state.nutritionSlice;
export default nutritionSlice.reducer;
