import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import salePlanApi, {
  SalePlanListResult,
  SalePlanListParams,
  CreateSalePlanToRuleParams,
  SortItem,
} from "@api/salePlanApi";
import { SalePlan } from "@api/saleApi";
import { message } from "antd";
import { refreshRuleSalePlans } from "./ruleSlice";

interface IState {
  isFetching: boolean;
  salePlanListResult: SalePlanListResult;
  salePlanListParams: SalePlanListParams;
  sortSalePlans: SortItem[];
}

const initialState: IState = {
  isFetching: false,
  salePlanListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  salePlanListParams: {
    limit: 20,
    offset: 0,
  },
  sortSalePlans: [],
};

export const fetchSalePlanList = createAsyncThunk("ruleAddSalePlan/fetchSalePlanList", async (_, thunkApi) => {
  const {
    ruleAddSalePlan: { salePlanListParams },
  } = thunkApi.getState() as RootState;

  try {
    const response = await salePlanApi.fetchSalePlanList(salePlanListParams);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const loadMoreSalePlanList = createAsyncThunk("ruleAddSalePlan/loadMoreSalePlanList", async (_, thunkApi) => {
  const {
    ruleAddSalePlan: { salePlanListParams },
  } = thunkApi.getState() as RootState;

  const params: SalePlanListParams = {
    ...salePlanListParams,
    offset: salePlanListParams.offset + salePlanListParams.limit,
  };

  try {
    thunkApi.dispatch(updateSalePlanListParams(params));
    const response = await salePlanApi.fetchSalePlanList(params);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const createSalePlanToRule = createAsyncThunk<SalePlan, CreateSalePlanToRuleParams>(
  "ruleAddSalePlan/createSalePlanToRule",
  async (params, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await salePlanApi.createSalePlanToRule(params);
      message.success("建立成功");
      thunkApi.dispatch(refreshRuleSalePlans(id!));
      return response;
    } catch (error: any) {
      message.warning(error.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateSalePlanToRule = createAsyncThunk<
  SalePlan,
  {
    planId: number;
    params: CreateSalePlanToRuleParams;
  }
>("ruleAddSalePlan/updateSalePlanToRule", async (args, thunkApi) => {
  const { planId, params } = args;
  const {
    rule: {
      ruleDetail: { id },
    },
  } = thunkApi.getState() as RootState;

  try {
    const response = await salePlanApi.updateSalePlanToRule(planId, params);
    message.success("更新成功");
    thunkApi.dispatch(refreshRuleSalePlans(id!));
    return response;
  } catch (error: any) {
    message.warning(error.message);
    return thunkApi.rejectWithValue(error);
  }
});
export const sortSalePlanToAdditionalPurchase = createAsyncThunk<void, { ruleId: number; salePlans: SortItem[] }>(
  "ruleAddSalePlan/sortSalePlanToAdditionalPurchase",
  async (payload, thunkApi) => {
    const { ruleId, salePlans } = payload;
    try {
      const response = await salePlanApi.batchSortRulesSalesPlan(ruleId, salePlans);
      return response;
    } catch (error: any) {
      message.warning(error.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

const ruleAddSalePlanSlice = createSlice({
  name: "ruleAddSalePlan",
  initialState,
  reducers: {
    reset: () => initialState,
    updateSalePlanListParams: (state, action: PayloadAction<SalePlanListParams>) => {
      state.salePlanListParams = action.payload;
    },
    updateSortSalePlans: (state, action: PayloadAction<SortItem[]>) => {
      state.sortSalePlans = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSalePlanList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSalePlanList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.salePlanListResult = action.payload;
    });
    builder.addCase(loadMoreSalePlanList.fulfilled, (state, action) => {
      state.salePlanListResult = {
        ...action.payload,
        results: state.salePlanListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const { reset, updateSalePlanListParams, updateSortSalePlans } = ruleAddSalePlanSlice.actions;

export default ruleAddSalePlanSlice.reducer;
