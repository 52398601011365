type PaymentMethodListType = {
  [key: string]: string;
};

export enum PaymentMethodValue {
  CREDIT_CARD = "creditcard",
  CASH_ON_DELIVERY = "cashondelivery",
  JKOPAY = "jkopay",
  LINEPAY = "linepay",
  APPLEPAY = "applepay",
  ATOME = "atome",
  PXPAYPLUS = "pxpayplus",
}

export enum PaymentMethodIdValue {
  CASHONDELIVERY = 0,
  CREDIT_CARD = 1,
  JKOPAY = 2,
  LINEPAY = 3,
  APPLEPAY = 4,
  ATOME = 5,
  PXPAY_PLUS = 6,
}

export const PaymentMethodList: PaymentMethodListType = {
  [PaymentMethodIdValue.CASHONDELIVERY]: "貨到付款",
  [PaymentMethodIdValue.CREDIT_CARD]: "信用卡付款",
  [PaymentMethodIdValue.LINEPAY]: "LINEPay",
  [PaymentMethodIdValue.JKOPAY]: "街口支付",
  [PaymentMethodIdValue.ATOME]: "Atome",
  [PaymentMethodIdValue.PXPAY_PLUS]: "全支付",
};

export const transformPaymemtMethodToCode = (method: string) => {
  if (method === PaymentMethodValue.CASH_ON_DELIVERY) return 0;
  if (method === PaymentMethodValue.CREDIT_CARD) return 1;
  if (method === PaymentMethodValue.JKOPAY) return 2;
  if (method === PaymentMethodValue.LINEPAY) return 3;
  if (method === PaymentMethodValue.APPLEPAY) return 4;
  if (method === PaymentMethodValue.ATOME) return 5;
  if (method === PaymentMethodValue.PXPAYPLUS) return 6;
  return -1;
};
