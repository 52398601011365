import memberApi, {
  MemberListResult,
  MemberFilterParams,
  CancelVIPMemberStatus,
  DeleteAccountParams,
} from "@api/memberApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "./notifySlice";
import type { RootState } from "./rootReducer";

interface IState {
  isFetchingMemberList: boolean;
  memberListResult: MemberListResult;
  memberListParams: MemberFilterParams;
  checkOrderError: string;
  isDeleteAccountFetching: boolean | null;
}

const initialState: IState = {
  isFetchingMemberList: false,
  memberListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  memberListParams: {
    offset: 0,
    limit: 20,
  },
  checkOrderError: "",
  isDeleteAccountFetching: null,
};

export const fetchMemberList = createAsyncThunk<MemberListResult>("member/fetchMemberList", async (_, thunkApi) => {
  const {
    member: { memberListParams },
  } = thunkApi.getState() as RootState;

  const response = await memberApi.fetchMemberList(memberListParams);
  return response;
});

export const cancelVIPAutoRenewal = createAsyncThunk<void, number>(
  "member/cancelVIPAutoRenewal",
  async (id, thunkApi) => {
    try {
      await memberApi.cancelVIPAutoRenewal(id);
      thunkApi.dispatch(fetchMemberList());
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    }
  },
);

export const cancelVIPMember = createAsyncThunk<void, { id: number; status: CancelVIPMemberStatus }>(
  "member/cancelVIPMember",
  async (params, thunkApi) => {
    try {
      const { id, status } = params;
      thunkApi.dispatch(showLoading());
      await memberApi.cancelVIPMember(id, status);
      message.success("取消成功");
      thunkApi.dispatch(fetchMemberList());
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const deleteAccount = createAsyncThunk("member/deleteAccount", async (params: DeleteAccountParams, thunkApi) => {
  try {
    thunkApi.dispatch(showLoading());

    const response = await memberApi.deleteAccount(params);
    if (!params.checkOrder) {
      message.success("成功刪除帳戶");
    }
    thunkApi.dispatch(fetchMemberList());
    return response;
  } catch (error) {
    message.error(error.message);
    return thunkApi.rejectWithValue(error.message);
  } finally {
    thunkApi.dispatch(hideLoading());
  }
});

export const clearMemberListParams = createAsyncThunk<void>("member/clearMemberListParams", async (_, thunkApi) => {
  thunkApi.dispatch(updateMemberListParams(initialState.memberListParams));
  thunkApi.dispatch(fetchMemberList());
});

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    reset: () => initialState,
    updateMemberListParams: (state, action: PayloadAction<MemberFilterParams>) => {
      state.memberListParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMemberList.pending, (state) => {
      state.isFetchingMemberList = true;
    });
    builder.addCase(fetchMemberList.fulfilled, (state, action) => {
      state.isFetchingMemberList = false;
      state.memberListResult = action.payload;
    });
    builder.addCase(deleteAccount.rejected, (state, action) => {
      state.checkOrderError = action.payload as string;
    });
    builder.addCase(deleteAccount.pending, (state, action) => {
      state.isDeleteAccountFetching = true;
      state.checkOrderError = "";
    });
    builder.addCase(deleteAccount.fulfilled, (state, action) => {
      state.isDeleteAccountFetching = false;
      state.checkOrderError = "";
    });
  },
});

export const { reset, updateMemberListParams } = memberSlice.actions;
export default memberSlice.reducer;
