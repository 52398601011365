import React, { FC, useEffect, ReactText, useMemo } from "react";
import styled from "styled-components";
import PopupBackground from "@component/PopupBackground";
import { Button, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useAppDispatch } from "src/store";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import {
  updateSelectedPageIds,
  reset,
  batchAddSalePages,
  loadMoreSalePageList,
} from "@redux/salesCategoryAddSalesSlice";
import { SalePageListItem } from "@api/salePageCategoryApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import Filter from "./Filter";

interface Props {
  onClose: () => void;
}

const Wrapper = styled.div`
  position: relaive;
  width: 870px;
  height: 640px;
  border-radius: 2px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background-color: white;
  padding: 28px 52px 0 32px;
`;
const SaveButton = styled(Button)`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 22px;
  right: 22px;
`;

const NewSalePagePopup: FC<Props> = (props) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const { isFetching, salePageListResult, selectedPageIds } = useSelector(
    (state: RootState) => state.salesCategoryAddSales,
  );

  const salePageList = useMemo(() => {
    const { next, results } = salePageListResult;
    if (next) {
      const observerData: SalePageListItem = {
        id: -1,
        name: "",
        rank: 0,
        skuList: [],
        brands: [],
        planSpecialPriceRange: {
          maxPrice: 0,
          minPrice: 0,
        },
      };
      return [...results, observerData];
    }
    return results;
  }, [salePageListResult]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  const handleOnCheck = (values: ReactText[]) => {
    const filterInfiniteObserverId = values.filter((value) => value >= 0);
    dispatch(updateSelectedPageIds(filterInfiniteObserverId as number[]));
  };

  const handleOnSave = () => {
    dispatch(batchAddSalePages());
    onClose();
  };

  const handleOnLoadMore = () => {
    dispatch(loadMoreSalePageList());
  };

  const tableColumns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      render: (value: SalePageListItem["id"]) => {
        if (value < 0) {
          return <InfiniteScrollObserver callback={handleOnLoadMore} />;
        }
        return value;
      },
    },
    {
      title: "SKU",
      key: "skuList",
      dataIndex: "skuList",
      render: (value: SalePageListItem["skuList"]) => (
        <div>
          {value.map((sku) => (
            <p>{sku}</p>
          ))}
        </div>
      ),
    },
    {
      title: "名稱",
      key: "name",
      dataIndex: "name",
    },
    {
      title: "品牌",
      key: "brands",
      dataIndex: "brands",
      render: (value: SalePageListItem["brands"]) => (
        <div>
          {value.map((brand) => (
            <p>{brand}</p>
          ))}
        </div>
      ),
    },
    {
      title: "價錢",
      key: "planSpecialPriceRange",
      dataIndex: "planSpecialPriceRange",
      render: (value: SalePageListItem["planSpecialPriceRange"]) => `$${value.minPrice}-$${value.maxPrice}`,
    },
  ];

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <Filter />
        <Table
          rowKey="id"
          dataSource={salePageList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 280 }}
          tableLayout="auto"
          pagination={false}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedPageIds,
            getCheckboxProps: (record: SalePageListItem) => ({
              disabled: record.id < 0,
              name: record.name,
            }),
          }}
          loading={isFetching}
        />
        <SaveButton type="primary" onClick={handleOnSave} disabled={selectedPageIds.length === 0}>
          儲存
        </SaveButton>
      </Wrapper>
    </PopupBackground>
  );
};

export default NewSalePagePopup;
