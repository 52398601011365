import { topicState } from "@redux/topicSlice";
import { Checkbox, Tree } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useManageTagContext } from "./ManageTagContext";
import { SalesCategory } from "./utils/generateCategoryTree";

const SearchElement = styled.span`
  color: #1890ff;
`;

const CustomCheckbox = styled(Checkbox)`
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
  padding: 0 8px 4px 20px;

  .ant-checkbox {
    order: 1;
  }
`;

const getParentKey = (key: number, tree: SalesCategory[]): number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children as SalesCategory[])) {
        parentKey = getParentKey(key, node.children as SalesCategory[]);
      }
    }
  }
  return parentKey;
};

const smallList: { key: number; title: string }[] = [];

export default function StepChooseCategory() {
  const { categoryInfo } = useSelector(topicState);

  const {
    tree,
    level1,
    level2,
    level3,
    searchText,
    checkCategories,
    setCheckCategories,
    setTagList,
  } = useManageTagContext();

  const [checkAll, setCheckAll] = useState<boolean>(false);
  const [isSearchDone, setIsSearchDone] = useState<boolean>(false);
  const [treeSearchState, setTreeSearchState] = useState<{
    expandedKeys: number[];
    autoExpandParent: boolean;
  }>({
    expandedKeys: [],
    autoExpandParent: true,
  });

  const treeArray = useMemo(() => Array.from(tree.values()), [tree]);

  const generateSmallList = useCallback((data: SalesCategory[]) => {
    for (let i = 0; i < data.length; i += 1) {
      const node = data[i];
      const { key, title } = node;
      smallList.push({ key, title });
      if (node.children) {
        generateSmallList(node.children as SalesCategory[]);
      }
    }
  }, []);

  const onExpand = (expandedKeys: React.ReactText[]) => {
    setTreeSearchState({
      ...treeSearchState,
      expandedKeys: expandedKeys as number[],
      autoExpandParent: false,
    });
  };

  const searchCategory = useCallback(
    (text: string) => {
      const expandedKeys = smallList
        .map((item) => {
          if (item.title.indexOf(text) > -1) {
            return getParentKey(item.key, treeArray);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      setTreeSearchState({
        expandedKeys: expandedKeys as number[],
        autoExpandParent: true,
      });
    },
    [treeArray],
  );

  const checkAllCategories = () => {
    if (checkCategories.length !== level1.size) {
      setCheckCategories([...level1.keys()]);
      setCheckAll(true);
    } else {
      setCheckCategories([]);
      setCheckAll(false);
    }
  };

  const loopData = (data: any) => {
    return data.map((item: any) => {
      const index = item.title.indexOf(searchText);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchText.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <SearchElement>{searchText}</SearchElement>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { title, key: item.key, children: loopTree(item.children) };
      }

      return {
        title,
        key: item.key,
      };
    });
  };

  const loopTree = (data: any) => {
    if (searchText) {
      const cloneData = data.slice().sort((item: any) => {
        const index = item.title.indexOf(searchText);
        return index > -1 ? -1 : 1;
      });
      return loopData(cloneData);
    }
    return loopData(data);
  };

  const onCheckCategory = (
    list:
      | React.Key[]
      | {
          checked: React.Key[];
          halfChecked: React.Key[];
        },
  ) => {
    const keyList = list as number[];
    const allSize = level1.size + level2.size + level3.size;

    if (keyList.length === allSize) {
      setCheckCategories(Array.from(level1.keys()));
    } else {
      setCheckCategories(keyList);
    }

    // 切換絕對分類貼標時的重置
    setTagList(new Map());
  };

  useEffect(() => {
    generateSmallList(treeArray);
  }, [generateSmallList, treeArray]);

  useEffect(() => {
    if (searchText) {
      searchCategory(searchText);
      setIsSearchDone(false);
    }
    if (!isSearchDone && !searchText) {
      setIsSearchDone(true);
      setTreeSearchState((prev) => ({ ...prev, autoExpandParent: true, expandedKeys: checkCategories }));
    }
  }, [searchText, checkCategories, isSearchDone, searchCategory]);

  useEffect(() => {
    if (categoryInfo) {
      setCheckAll(checkCategories.length === level1.size);
    }
  }, [checkCategories.length, level1.size, categoryInfo]);

  return (
    <>
      <CustomCheckbox checked={checkAll} onChange={checkAllCategories}>
        全站
      </CustomCheckbox>
      <Tree
        checkable
        height={500}
        treeData={loopTree(treeArray)}
        autoExpandParent={treeSearchState.autoExpandParent}
        expandedKeys={treeSearchState.expandedKeys}
        checkedKeys={checkCategories}
        onCheck={onCheckCategory}
        onExpand={onExpand}
      />
    </>
  );
}
