import { RootState } from "@redux/rootReducer";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import Loading from "./Loading";

const NotifyLoading: FC = () => {
  const isLoading = useSelector((state: RootState) => state.notifySlice.isLoading);

  return isLoading ? <Loading /> : null;
};

export default NotifyLoading;
