/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import { RevenueChartItem, RevenueChart } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
  getTimeRangeText,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;
`;

enum SeriesNameMap {
  orderCount = "訂單數",
  appOrderCount = "App訂單數",
  webOrderCount = "Web訂單數",
}

const OrderCountChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();
  const [orderCountChartOption, setOrderCountChartOption] = useState<Highcharts.Options>();
  const [appOrderCountChartOption, setAppOrderCountChartOption] = useState<Highcharts.Options>();
  const [webOrderCountChartOption, setWebOrderCountChartOption] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<RevenueChartItem>(
      startDate,
      endDate,
      (chartResult as RevenueChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<RevenueChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as RevenueChart).compareData || [],
      timeUnit,
    );
    const currentOrderCount: (number | null)[] = [];
    const currentAppOrderCount: (number | null)[] = [];
    const currentWebOrderCount: (number | null)[] = [];
    const compareOrderCount: (number | null)[] = [];
    const compareAppOrderCount: (number | null)[] = [];
    const compareWebOrderCount: (number | null)[] = [];
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    currentFilledTimeData.forEach((data) => {
      const { order = null, appOrder = null, webOrder = null } = data;
      currentOrderCount.push(order);
      currentAppOrderCount.push(appOrder);
      currentWebOrderCount.push(webOrder);
    });
    compareFilledTimeData.forEach((data) => {
      const { order = null, appOrder = null, webOrder = null } = data;
      compareOrderCount.push(order);
      compareAppOrderCount.push(appOrder);
      compareWebOrderCount.push(webOrder);
    });

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: SeriesNameMap.orderCount,
        data: currentOrderCount,
        type: "line",
        color: "#5C6AC4",
        pointStart,
      },
      {
        name: SeriesNameMap.appOrderCount,
        data: currentAppOrderCount,
        type: "line",
        color: "#BAE7FF",
        pointStart,
      },
      {
        name: SeriesNameMap.webOrderCount,
        data: currentWebOrderCount,
        type: "line",
        color: "#1890FF",
        pointStart,
      },
    ];

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "line",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "{text}",
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const pointName = this.point.series.userOptions.name;
          const title = getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit);
          const value = this.point.y || 0;

          return `${title}<br/>${pointName} ${value}<br/>`;
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        // useHTML: true,
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
      },
      series,
    });

    // 相較於有選才設
    if (cStartDate && cEndDate) {
      const currentTimeRange = getTimeRangeText(startDate, endDate, timeUnit);
      const compareTimeRange = getTimeRangeText(cStartDate, cEndDate, timeUnit);

      const orderCountSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentOrderCount,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareOrderCount,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setOrderCountChartOption({
        title: {
          text: "訂單數",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "{text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>訂單數 ${value}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: orderCountSeries,
      });

      const webOrderCountSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentWebOrderCount,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareWebOrderCount,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setWebOrderCountChartOption({
        title: {
          text: "Web訂單數",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "{text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>Web訂單數 ${value}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: webOrderCountSeries,
      });

      const appOrderCountSeries: Highcharts.SeriesOptionsType[] = [
        {
          name: currentTimeRange,
          data: currentAppOrderCount,
          type: "line",
          color: "#5C6AC4",
          stack: "current",
          pointStart,
        },
        {
          name: compareTimeRange,
          data: compareAppOrderCount,
          type: "line",
          color: "#1890FF",
          stack: "compare",
          pointStart,
        },
      ];

      setAppOrderCountChartOption({
        title: {
          text: "App訂單數",
          align: "left",
        },
        chart: {
          type: "line",
        },
        xAxis: {
          type: "datetime",
          labels: {
            formatter() {
              const timeFormat = getTimeFormat(timeUnit);
              const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
              return formattedText;
            },
            rotation: -45,
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            format: "{text}",
          },
        },
        tooltip: {
          formatter() {
            const pointIndex = this.point.index;
            const stackName = this.point.series.userOptions.stack;

            const title =
              stackName === "current"
                ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
                : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);
            const value = this.point.y || 0;

            return `${title}<br/>App訂單數 ${value}<br/>`;
          },
          backgroundColor: "#5D5954",
          borderColor: "#5D5954",
          style: {
            color: "#FFFFFF",
            fontSize: "12px",
            lineHeight: "22px",
          },
          // useHTML: true,
        },
        legend: {
          align: "right",
          verticalAlign: "middle",
          layout: "vertical",
        },
        plotOptions: {
          series: {
            pointStart,
            pointInterval,
          },
        },
        series: appOrderCountSeries,
      });
    }
  }, [chartResult, chartParams]);

  const fetchRevenueChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.REVENUE));
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <ChartWrapper>
          <PageTitle title="Dashboard - 訂單數" />
          <ChartFilter title="訂單數" fetchAction={fetchRevenueChart} />
          <HighchartsReact
            highcharts={Highcharts}
            options={chartOptions}
            containerProps={{ style: { height: 500, marginBottom: 40 } }}
          />
          {chartParams.cStartDate && chartParams.cEndDate && (
            <>
              <HighchartsReact
                highcharts={Highcharts}
                options={orderCountChartOption}
                containerProps={{ style: { height: 500, marginBottom: 40 } }}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={webOrderCountChartOption}
                containerProps={{ style: { height: 500, marginBottom: 40 } }}
              />
              <HighchartsReact
                highcharts={Highcharts}
                options={appOrderCountChartOption}
                containerProps={{ style: { height: 500 } }}
              />
            </>
          )}
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default OrderCountChart;
