import supplyChainApi, {
  SupplyChainList,
  SupplyChainDetail,
  StaffList,
  CreateSupplyChainParam,
  UpdateSupplyChainParam,
  SupplyChainContactList,
  SupplyChainContactDetail,
  UpdateSupplyChainContactParam,
  CreateSupplyChainContactParam,
  UpdateMajorContactParam,
  SupplyChainContractList,
  CreateSupplyChainContractParam,
  SupplyChainContractDetail,
  SupplyChainRetailList,
  RetailRegionList,
  RetailList,
  RetailerProductLineList,
  ContractList,
  ContractParam,
  RetailerProductLineRelationList,
  SupplyChainFilterParam,
  CreateSupplyChainRetailerParam,
  RelationParam,
  SupplyChainRetailerDetail,
  RetailerProductList,
  RetailerProductFilterParam,
  CreateRetailerProductParam,
  UpdateRetailerProductParam,
  DeleteRetailerProductParam,
  SupplyChainProductParams,
  AddSupplyChainProductByCSVParam,
  VendorListResult,
  DeleteContractParam,
  DeleteContactParam,
  DeleteSupplyChainRetailerParam,
  RetailerProductListFilterParam,
  VenderSearchParam,
} from "@api/supplyChainApi";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "@redux/rootReducer";
import { message } from "antd";
import { hideLoading, showLoading } from "./notifySlice";

interface IState {
  isFetching: boolean;
  selectedSupplyChainIds: number[];
  supplyChainList: SupplyChainList;
  supplyChainFilter: SupplyChainFilterParam;
  supplyChainContactFilter: SupplyChainFilterParam;
  supplyChainContractFilter: SupplyChainFilterParam;
  supplyChainRetailerFilter: SupplyChainFilterParam;
  supplyChainDetail: SupplyChainDetail;
  staffList: StaffList;
  TryallStaffList: StaffList;
  vendorList: VendorListResult;
  vendorDetail: VendorListResult;
  supplyChainContactList: SupplyChainContactList;
  supplyChainContactDetail: SupplyChainContactDetail;
  supplyChainContractList: SupplyChainContractList;
  supplyChainRetailList: SupplyChainRetailList;
  supplyChainRetailerDetail: SupplyChainRetailerDetail;
  retailRegionList: RetailRegionList;
  retailList: RetailList;
  retailerProductLineList: RetailerProductLineList;
  contractList: ContractList;
  retailerProductLineRelationList: RetailerProductLineRelationList;
  retailerProductList: RetailerProductList;
  retailerProductFilter: RetailerProductFilterParam;
  supplyChainProductFilter: SupplyChainFilterParam;
  selectedSupplyChainProductSelectedIds: number[];
  xlsUploadSuccess: boolean;
  xlsErrorMessage: string;
  supplyChainProductParams: SupplyChainProductParams;
}

export const initialState: IState = {
  supplyChainProductParams: {
    productIds: "",
    ordering: "",
    price: 0,
    quotedPrice: 0,
    cost: 0,
  },
  isFetching: false,
  selectedSupplyChainIds: [],
  supplyChainList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  supplyChainFilter: {
    limit: 20,
    offset: 0,
  },
  supplyChainContactFilter: {
    limit: 20,
    offset: 0,
  },
  supplyChainContractFilter: {
    limit: 20,
    offset: 0,
  },
  supplyChainRetailerFilter: {
    limit: 20,
    offset: 0,
  },

  supplyChainProductFilter: {
    limit: 20,
    offset: 0,
  },
  supplyChainDetail: {
    id: 0,
    name: "",
    address: "",
    phone: "",
    ubn: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    branchName: "",
    currency: "",
    staff: "",
  },
  staffList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  TryallStaffList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  vendorList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  vendorDetail: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  supplyChainContactList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  supplyChainContactDetail: {
    id: 0,
    name: "",
    title: "",
    phone: "",
    email: "",
    isMajor: true,
    supplyChain: 0,
  },
  /**
   * 供應商合約
   */
  supplyChainContractList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  /**
   * 供應商通路
   */
  supplyChainRetailList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  supplyChainRetailerDetail: {
    id: 0,
    regionType: 0,
    regionName: "",
    retailer: "",
    productLine: "",
    currency: 1,
    contract: {
      startAt: "",
      endAt: "",
      name: "",
      path: "",
    },
  },
  retailRegionList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailerProductLineList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  contractList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailerProductLineRelationList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailerProductList: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  retailerProductFilter: {
    ordering: "",
    nameQ: "",
    nameEngQ: "",
  },
  selectedSupplyChainProductSelectedIds: [],
  xlsUploadSuccess: false,
  xlsErrorMessage: "",
};

export const fetchSupplyChainList = createAsyncThunk("supplyChain/fetchSupplyChainList", async (_, thunkApi) => {
  const {
    supplyChain: { supplyChainFilter },
  } = thunkApi.getState() as RootState;
  const response = await supplyChainApi.fetchSupplyChainList(supplyChainFilter);
  thunkApi.dispatch(updateSelectedIds([]));
  return response;
});

export const fetchSearchSupplyChainList = createAsyncThunk(
  "supplyChain/fetchSearchSupplyChainList",
  async (nameQ: string, thunkApi) => {
    const response = await supplyChainApi.fetchSearchSupplyChainList(nameQ);
    return response;
  },
);

export const fetchSupplyChainDetail = createAsyncThunk(
  "supplyChain/fetchSupplyChainDetail",
  async (id: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await supplyChainApi.fetchSupplyChainDetail(id);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const fetchOperationStaff = createAsyncThunk("supplyChain/fetchOperationStaff", async (_, thunkApi) => {
  const response = await supplyChainApi.fetchOperationStaff();
  return response;
});
export const fetchTryallOperationStaff = createAsyncThunk(
  "supplyChain/fetchTryallOperationStaff",
  async (_, thunkApi) => {
    const response = await supplyChainApi.fetchTryallOperationStaff();
    return response;
  },
);

export const fetchVendorList = createAsyncThunk(
  "supplyChain/fetchVendorList",
  async (param: VenderSearchParam, thunkApi) => {
    const response = await supplyChainApi.fetchVendorList(param);
    return response;
  },
);

export const fetchVendorDetail = createAsyncThunk("supplyChain/fetchVendorDetail", async (id: number, thunkApi) => {
  const response = await supplyChainApi.fetchVendorDetail(id);
  return response;
});
export const fetchSearchVendor = createAsyncThunk("supplyChain/fetchSearchVendor", async (name: string, thunkApi) => {
  const response = await supplyChainApi.fetchSearchVendor(name);
  return response;
});

export const createSupplyChain = createAsyncThunk(
  "supplyChain/createSuppluChain",
  async (params: CreateSupplyChainParam, thunkApi) => {
    try {
      const response = await supplyChainApi.createSupplyChain(params);
      message.success("已儲存");
      thunkApi.dispatch(fetchSupplyChainList());
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);

export const updateSupplyChain = createAsyncThunk(
  "supplyChain/updateSupplyChain",
  async (params: UpdateSupplyChainParam, thunkApi) => {
    try {
      const response = await supplyChainApi.updateSupplyChain(params);
      message.success("已儲存");
      thunkApi.dispatch(fetchSupplyChainList());
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);
export const updateOperationStaff = createAsyncThunk<void, number>(
  "supplyChain/updateOperationStaff",
  async (staffId, thunkApi) => {
    const {
      supplyChain: { selectedSupplyChainIds },
    } = thunkApi.getState() as RootState;
    try {
      await supplyChainApi.updateOperationStaff({ supplyChainIds: selectedSupplyChainIds, staffId });
      thunkApi.dispatch(fetchSupplyChainList());
    } catch (error: any) {
      message.error(`${error.message}`);
    }
  },
);
export const deleteSupplyChain = createAsyncThunk("supplyChain/deleteSupplyChain", async (id: number, thunkApi) => {
  try {
    const response = await supplyChainApi.deleteSupplyChain(id);
    message.success("已刪除");
    thunkApi.dispatch(fetchSupplyChainList());
    return response;
  } catch (error: any) {
    message.error(`${error.message}`);
    throw error;
  }
});

/**
 * 供應商聯絡人
 */

export const fetchSupplyChainContactList = createAsyncThunk(
  "supplyChain/fetchSupplyChainContactList",
  async (params: SupplyChainFilterParam, thunkApi) => {
    const response = await supplyChainApi.fetchSupplyChainContactList(params);
    thunkApi.dispatch(updateSelectedIds([]));
    return response;
  },
);

export const fetchSupplyChainContactDetail = createAsyncThunk(
  "supplyChain/fetchSupplyChainContactDetail",
  async (id: number, thunkApi) => {
    const response = await supplyChainApi.fetchSupplyChainContactDetail(id);
    return response;
  },
);
export const updateSupplyChainContact = createAsyncThunk(
  "supplyChain/updateSupplyChainContactDetail",
  async (params: UpdateSupplyChainContactParam, thunkApi) => {
    const response = await supplyChainApi.updateSupplyChainContact(params);
    message.success("已儲存");
    const filter = {
      supplyChain: params.supplyChain,
    };
    thunkApi.dispatch(fetchSupplyChainContactList(filter));
    return response;
  },
);
export const updateMajorContact = createAsyncThunk(
  "supplyChain/updateMajorContact",
  async (params: UpdateMajorContactParam, thunkApi) => {
    const response = await supplyChainApi.updateMajorContact(params);
    const filter = {
      supplyChain: params.supplyChain,
    };
    thunkApi.dispatch(fetchSupplyChainContactList(filter));
    return response;
  },
);
export const createSupplyChainContact = createAsyncThunk(
  "supplyChain/createSupplyChainContactDetail",
  async (params: CreateSupplyChainContactParam, thunkApi) => {
    const response = await supplyChainApi.createSupplyChainContact(params);
    const filter = {
      supplyChain: params.supplyChain,
    };
    thunkApi.dispatch(fetchSupplyChainContactList(filter));
    return response;
  },
);
export const deleteSupplyChainContact = createAsyncThunk(
  "supplyChain/deleteSupplyChainContact",
  async (params: DeleteContactParam, thunkApi) => {
    try {
      const response = await supplyChainApi.deleteSupplyChainContact(params.id);
      message.success("已刪除");
      const filter = {
        supplyChain: params.supplyChain,
      };
      thunkApi.dispatch(fetchSupplyChainContactList(filter));
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);
/**
 * 供應商合約
 */

export const fetchSupplyChainContractList = createAsyncThunk(
  "supplyChain/fetchSupplyChainContractList",
  async (params: SupplyChainFilterParam, thunkApi) => {
    const response = await supplyChainApi.fetchSupplyChainContractList(params);
    return response;
  },
);
export const createSupplyChainContract = createAsyncThunk(
  "supplyChain/createSupplyChainContract",
  async (params: CreateSupplyChainContractParam, thunkApi) => {
    const response = await supplyChainApi.createSupplyChainContract(params);
    message.success("已新增");
    const filter = {
      supplyChain: params.supplyChain,
    };
    thunkApi.dispatch(fetchSupplyChainContractList(filter));
    return response;
  },
);
export const updateSupplyChainContract = createAsyncThunk(
  "supplyChain/updateSupplyChainContract",
  async (params: SupplyChainContractDetail, thunkApi) => {
    const response = await supplyChainApi.updateSupplyChainContract(params);
    message.success("已儲存");
    const filter = {
      supplyChain: params.supplyChain,
    };
    thunkApi.dispatch(fetchSupplyChainContractList(filter));
    return response;
  },
);
export const deleteSupplyChainContract = createAsyncThunk(
  "supplyChain/deleteSupplyChainContract",
  async (params: DeleteContractParam, thunkApi) => {
    try {
      const response = await supplyChainApi.deleteSupplyChainContract(params.id);
      message.success("已刪除");
      const filter = {
        supplyChain: params.supplyChain,
      };
      thunkApi.dispatch(fetchSupplyChainContractList(filter));
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);
/**
 * 供應商通路
 */

export const fetchSupplyChainRetailList = createAsyncThunk(
  "supplyChain/fetchSupplyChainRetailList",
  async (params: SupplyChainFilterParam, thunkApi) => {
    const response = await supplyChainApi.fetchSupplyChainRetailList(params);
    return response;
  },
);
export const deleteRetailer = createAsyncThunk(
  "supplyChain/deleteRetailer",
  async (params: DeleteSupplyChainRetailerParam, thunkApi) => {
    try {
      const response = await supplyChainApi.deleteRetailer(params.id);
      message.success("已刪除");
      const filter = {
        supplyChain: params.supplyChain,
      };
      thunkApi.dispatch(fetchSupplyChainRetailList(filter));
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);
export const fetchRetailRegionList = createAsyncThunk(
  "supplyChain/fetchRetailRegionList",
  async (regionTypeId: number, thunkApi) => {
    const response = await supplyChainApi.fetchRetailRegionList(regionTypeId);
    return response;
  },
);
export const fetchRetailList = createAsyncThunk("supplyChain/fetchRetailList", async (regonId: number, thunkApi) => {
  const response = await supplyChainApi.fetchRetailList(regonId);
  return response;
});
export const fetchRetailerProductLineList = createAsyncThunk(
  "supplyChain/fetchRetailerProductLineList",
  async (retailerId: number, thunkApi) => {
    const response = await supplyChainApi.fetchRetailerProductLineList(retailerId);
    return response;
  },
);
export const fetchContractList = createAsyncThunk(
  "supplyChain/fetchContractList",
  async (params: ContractParam, thunkApi) => {
    const response = await supplyChainApi.fetchContractList(params);
    return response;
  },
);
export const fetchRetailerProductLineRelation = createAsyncThunk(
  "supplyChain/fetchRetailerProductLineRelation",
  async (params: RelationParam, thunkApi) => {
    const response = await supplyChainApi.fetchRetailerProductLineRelation(params);
    return response;
  },
);
export const createSupplyChainRetailer = createAsyncThunk(
  "supplyChain/createSupplyChainRetailer",
  async (params: CreateSupplyChainRetailerParam, thunkApi) => {
    try {
      const response = await supplyChainApi.createSupplyChainRetailer(params);
      message.success("已新增");
      const filter = {
        supplyChain: params.supplyChain,
      };
      thunkApi.dispatch(fetchSupplyChainRetailList(filter));
      return response;
    } catch (error: any) {
      message.error(`${error.message}`);
      throw error;
    }
  },
);

/**
 * 供應商通路商品
 */
export const fetchRetailerProductList = createAsyncThunk(
  "supplyChain/fetchRetailerProductList",
  async (params: RetailerProductListFilterParam, thunkApi) => {
    const response = await supplyChainApi.fetchRetailerProductList(params);
    thunkApi.dispatch(updateSupplyChainProductSelectedIds([]));
    return response;
  },
);
export const fetchFilterRetailerProductList = createAsyncThunk(
  "supplyChain/fetchFilterRetailerProductList",
  async (params: RetailerProductFilterParam, thunkApi) => {
    const response = await supplyChainApi.fetchFilterRetailerProductList(params);
    return response;
  },
);
export const createRetailerProduct = createAsyncThunk(
  "supplyChain/createRetailerProduct",
  async (params: CreateRetailerProductParam, thunkApi) => {
    await supplyChainApi.createRetailerProduct(params);
    message.success("已新增");
    const filter = {
      supplyChainRetailerProductlineRelation: params.supplyChainRetailerProductlineRelation,
    };
    thunkApi.dispatch(fetchRetailerProductList(filter));
  },
);
export const updateRetailerProduct = createAsyncThunk(
  "supplyChain/updateRetailerProduct",
  async (params: UpdateRetailerProductParam, thunkApi) => {
    await supplyChainApi.updateRetailerProduct(params);
    message.success("已儲存");
    const filter = {
      supplyChainRetailerProductlineRelation: params.supplyChainRetailerProductlineRelation,
    };
    thunkApi.dispatch(fetchRetailerProductList(filter));
  },
);
export const deleteRetailerProduct = createAsyncThunk(
  "supplyChain/deleteRetailerProduct",
  async (params: DeleteRetailerProductParam, thunkApi) => {
    const response = await supplyChainApi.deleteRetailerProduct(params.id);
    message.success("已刪除");
    const filter = {
      supplyChainRetailerProductlineRelation: params.supplyChainRetailerProductlineRelation,
    };
    thunkApi.dispatch(fetchRetailerProductList(filter));
    return response;
  },
);

export const batchImportSupplyChainProductByXLS = createAsyncThunk(
  "salesCategory/batchImportSupplyChainProductByXLS",
  // eslint-disable-next-line consistent-return
  async (params: AddSupplyChainProductByCSVParam, thunkApi) => {
    try {
      if (params.file) {
        const response = await supplyChainApi.batchImportSupplyChainProductByXLS(
          params.supplyChainRetailerProductlineRelation,
          params.file,
        );
        const filter = {
          supplyChainRetailerProductlineRelation: params.supplyChainRetailerProductlineRelation,
        };
        thunkApi.dispatch(fetchRetailerProductList(filter));
        message.success("匯入成功");
        return response;
      }
    } catch (error: any) {
      message.error("匯入失敗");
      throw error;
    }
  },
);

export const batchExportSupplyChainProduct = createAsyncThunk(
  "vendor/batchExportSupplyChainProduct",
  async (params: SupplyChainProductParams, thunkApi) => {
    const response = await supplyChainApi.batchExportSupplyChainProduct(params);
    return response;
  },
);

const supplyChainSlice = createSlice({
  name: "supplyChain",
  initialState,
  reducers: {
    resetSupplyChainSlice: () => initialState,
    updateSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSupplyChainIds = action.payload;
    },
    deleteSupplyChain: (state, action: PayloadAction<SupplyChainList>) => {
      state.supplyChainList = action.payload;
    },
    updateSupplyChainFilter: (state, action: PayloadAction<SupplyChainFilterParam>) => {
      state.supplyChainFilter = action.payload;
    },
    updateContactFilter: (state, action: PayloadAction<SupplyChainFilterParam>) => {
      state.supplyChainContactFilter = action.payload;
    },
    updateContractFilter: (state, action: PayloadAction<SupplyChainFilterParam>) => {
      state.supplyChainContractFilter = action.payload;
    },
    updateRetailerFilter: (state, action: PayloadAction<SupplyChainFilterParam>) => {
      state.supplyChainRetailerFilter = action.payload;
    },
    updateSupplyChainProductFilter: (state, action: PayloadAction<SupplyChainFilterParam>) => {
      state.supplyChainProductFilter = action.payload;
    },
    updateSupplyChainProductSelectedIds: (state, action: PayloadAction<number[]>) => {
      state.selectedSupplyChainProductSelectedIds = action.payload;
    },

    /**
     * 供應商聯絡人
     */
    updateSupplyChainContact: (state, action: PayloadAction<SupplyChainList>) => {
      state.supplyChainList = action.payload;
    },
    createSupplyChainContact: (state, action: PayloadAction<SupplyChainList>) => {
      state.supplyChainList = action.payload;
    },
    /**
     * 供應商合約
     */
    createSupplyChainContract: (state, action: PayloadAction<SupplyChainContractList>) => {
      state.supplyChainContractList = action.payload;
    },

    deleteSupplyChainContract: (state, action: PayloadAction<SupplyChainContractList>) => {
      state.supplyChainContractList = action.payload;
    },
    /**
     * 供應商通路
     */
    deleteRetailer: (state, action: PayloadAction<SupplyChainRetailList>) => {
      state.supplyChainRetailList = action.payload;
    },
    clearXLSErrorMessage: (state) => {
      state.xlsErrorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSupplyChainList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSupplyChainList.fulfilled, (state, action) => {
      state.supplyChainList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSearchSupplyChainList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSearchSupplyChainList.fulfilled, (state, action) => {
      state.supplyChainList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSupplyChainDetail.fulfilled, (state, action) => {
      state.supplyChainDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchOperationStaff.fulfilled, (state, action) => {
      state.staffList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchTryallOperationStaff.fulfilled, (state, action) => {
      state.TryallStaffList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSearchVendor.fulfilled, (state, action) => {
      state.vendorDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createSupplyChain.fulfilled, (state, action) => {
      state.supplyChainDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(updateSupplyChain.fulfilled, (state, action) => {
      state.supplyChainList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(updateOperationStaff.fulfilled, (state, action) => {
      state.isFetching = false;
    });
    /**
     * 供應商聯絡人
     */
    builder.addCase(fetchSupplyChainContactList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSupplyChainContactList.fulfilled, (state, action) => {
      state.supplyChainContactList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchSupplyChainContactDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSupplyChainContactDetail.fulfilled, (state, action) => {
      state.supplyChainContactDetail = action.payload;
      state.isFetching = false;
    });

    /**
     * 供應商合約
     */
    builder.addCase(fetchSupplyChainContractList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSupplyChainContractList.fulfilled, (state, action) => {
      state.supplyChainContractList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(updateSupplyChainContract.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateSupplyChainContract.fulfilled, (state, action) => {
      state.supplyChainContractList = action.payload;
      state.isFetching = false;
    });
    /**
     * 供應商通路
     */
    builder.addCase(fetchSupplyChainRetailList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchSupplyChainRetailList.fulfilled, (state, action) => {
      state.supplyChainRetailList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchRetailRegionList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRetailRegionList.fulfilled, (state, action) => {
      state.retailRegionList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchRetailList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRetailList.fulfilled, (state, action) => {
      state.retailList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchRetailerProductLineList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRetailerProductLineList.fulfilled, (state, action) => {
      state.retailerProductLineList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchContractList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchContractList.fulfilled, (state, action) => {
      state.contractList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchRetailerProductLineRelation.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRetailerProductLineRelation.fulfilled, (state, action) => {
      state.retailerProductLineRelationList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createSupplyChainRetailer.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createSupplyChainRetailer.fulfilled, (state, action) => {
      state.supplyChainRetailerDetail = action.payload;
      state.isFetching = false;
    });
    /**
     * 供應商通路商品
     */
    builder.addCase(fetchRetailerProductList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchRetailerProductList.fulfilled, (state, action) => {
      state.retailerProductList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchFilterRetailerProductList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchFilterRetailerProductList.fulfilled, (state, action) => {
      state.retailerProductList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createRetailerProduct.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createRetailerProduct.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(updateRetailerProduct.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateRetailerProduct.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(batchImportSupplyChainProductByXLS.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(batchImportSupplyChainProductByXLS.fulfilled, (state) => {
      state.isFetching = false;
      state.xlsErrorMessage = "";
      state.xlsUploadSuccess = true;
    });
    builder.addCase(batchImportSupplyChainProductByXLS.rejected, (state, action) => {
      state.xlsErrorMessage = action.error.message || "";
    });
  },
});

export const {
  resetSupplyChainSlice,
  updateSelectedIds,
  updateSupplyChainFilter,
  updateContactFilter,
  updateContractFilter,
  updateRetailerFilter,
  updateSupplyChainProductFilter,
  updateSupplyChainProductSelectedIds,
  clearXLSErrorMessage,
} = supplyChainSlice.actions;
export default supplyChainSlice.reducer;
