/* eslint-disable camelcase */
import SaleRuleType from "@page/Order/SaleRuleType";
import CoreAPI from "./CoreAPI";
import { ContractMode } from "./utils/normalizeContract";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface VipGiftList {
  id: number;
  description: string;
  useStartAt: string;
  useEndAt: string;
  salesPlan: {
    id: number;
    name: string;
    options: {
      id: number;
      name: string;
      vpc: {
        id: number;
        price: number;
        commissionRate: number;
        sku: string;
        productName: string;
        contractInfo: {
          id: number;
          mode: ContractMode;
          modeName: string;
        };
      };
      groupQty: number;
      unitPrice: number;
      unitCost: number;
      optionDiscount?: number;
      commissionRate: number;
      canBuyCount: number;
    }[];
    price: number;
    media: {
      id: number;
      name: string;
      mediaType: number;
      isActive: true;
      alt?: string;
      url: string;
      image: string;
      imageType: null;
      isShow: true;
      rank: number;
    };
    ruleType: SaleRuleType;
    isActive: boolean;
    specialPrice: number;
    optionsCount: number;
    optionDiscountPeriod: {
      startAt?: string;
      endAt?: string;
    };
    canBuyCount: number;
    groupsSalesLimit: [];
    rank: number;
  };
}

export interface VipGiftListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: VipGiftList[];
}

export interface CreateVipGiftPayload {
  description: string;
  useStartAt: string;
  useEndAt: string;
  salesPlanData: {
    name: string;
    productsInfo: {
      vpcId: number;
      qty: number;
      price: number;
      commissionRate: number;
    }[];
    isActive: boolean;
    imageDirPrefix?: string;
    imageFilePath?: string;
    imageAlt?: string;
  };
}

export interface BatchUpdateVipGiftStatusPayload {
  giftRuleData: { id: number; isActive: boolean }[];
}

export interface BatchDeleteVipGiftPayload {
  ids: number[];
}

export interface VipBulletinList {
  id: number;
  startAt: string;
  endAt: string;
  media: {
    id: number;
    name: string;
    mediaType: number;
    isActive: true;
    alt?: string;
    url: string;
    image: string;
    imageType: null;
    isShow: true;
    rank: number;
  };
  title: string;
  isDefault: boolean;
  isActive: boolean;
}

export interface VipBulletinListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: VipBulletinList[];
}

export interface CreateVipBulletinPayload {
  isActive: boolean;
  isDefault: boolean;
  startAt: string;
  endAt: string;
  imageFilePath?: string;
  imageDirPrefix?: string;
  imageAlt: string;
  title: string;
}

export interface BatchUpdateVipBulletinStatusPayload {
  bulletinBoardIds: number[];
  isActive: boolean;
}

export interface BatchDeleteVipBulletinPayload {
  bulletinBoardIds: number[];
}

export interface VipGiftListFilter {
  limit: number;
  offset: number;
}

export enum VipPromotionItemType {
  S_PROMOTION_BANNER = 1,
  S_PROMOTION_RIGHTS = 2,
  S_PROMOTION_SAVING = 3,
  S_PROMOTION_UPGRADE = 4,
  S_PURCHASE_PAGE_BANNER = 5,
}

export interface VipPromotion {
  id: number;
  itemType: VipPromotionItemType;
  title: string | null;
  subTitle: string | null;
  description: string;
  startAt: string | null;
  endAt: string | null;
  createdAt: string;
  media: string | null;
}

export interface UpdateVipPromotionPayload {
  descriptionData: { id: number; description: string }[];
}

interface VipPromotionAPI {
  fetchVipGiftList: (payload: VipGiftListFilter) => Promise<VipGiftListResult>;
  fetchCreateVipGift: (payload: CreateVipGiftPayload) => Promise<void>;
  fetchUpdateVipGift: (payload: CreateVipGiftPayload & { vipGiftId: number }) => Promise<void>;
  fetchBatchUpdateVipGiftStatus: (payload: BatchUpdateVipGiftStatusPayload) => Promise<void>;
  fetchBatchDeleteVipGift: (payload: BatchDeleteVipGiftPayload) => Promise<void>;
  fetchVipBulletinList: (payload: VipGiftListFilter) => Promise<VipBulletinListResult>;
  fetchCreateVipBulletin: (payload: CreateVipBulletinPayload) => Promise<void>;
  fetchUpdateVipBulletin: (payload: CreateVipBulletinPayload & { vipBulletinId: number }) => Promise<void>;
  fetchBatchUpdateVipBulletinStatus: (payload: BatchUpdateVipBulletinStatusPayload) => Promise<void>;
  fetchBatchDeleteVipBulletin: (payload: BatchDeleteVipBulletinPayload) => Promise<void>;
  fetchVipPromotion: () => Promise<VipPromotion[]>;
  fetchUpdateVipPromotion: (payload: UpdateVipPromotionPayload) => Promise<void>;
}

const apiClient = new CoreAPI();

const vipPromotionAPI: VipPromotionAPI = {
  fetchVipGiftList: async (payload) => {
    const response = await apiClient.getData("/manage/account/membership/gift-promotion-rules/", payload);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateVipGift: async (payload) => {
    await apiClient.postData("/manage/account/membership/gift-promotion-rules/", transformCamelToSnake(payload));
  },
  fetchUpdateVipGift: async (payload) => {
    const { vipGiftId, ...otherPayload } = payload;
    await apiClient.putData(
      `/manage/account/membership/gift-promotion-rules/${vipGiftId}/`,
      transformCamelToSnake(otherPayload),
    );
  },
  fetchBatchUpdateVipGiftStatus: async (payload) => {
    await apiClient.putData(
      "/manage/account/membership/gift-promotion-rules/batch-update/",
      transformCamelToSnake(payload),
    );
  },
  fetchBatchDeleteVipGift: async (payload) => {
    await apiClient.putData(
      "/manage/account/membership/gift-promotion-rules/batch-delete/",
      transformCamelToSnake(payload),
    );
  },
  fetchVipBulletinList: async (payload) => {
    const response = await apiClient.getData("/manage/account/bulletin_board/", payload);
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchCreateVipBulletin: async (payload) => {
    await apiClient.postData("/manage/account/bulletin_board/", transformCamelToSnake(payload));
  },
  fetchUpdateVipBulletin: async (payload) => {
    const { vipBulletinId, ...otherPayload } = payload;
    await apiClient.putData(`/manage/account/bulletin_board/${vipBulletinId}/`, transformCamelToSnake(otherPayload));
  },
  fetchBatchUpdateVipBulletinStatus: async (payload) => {
    await apiClient.putData("/manage/account/bulletin_board/batch-update/", transformCamelToSnake(payload));
  },
  fetchBatchDeleteVipBulletin: async (payload) => {
    await apiClient.putData("/manage/account/bulletin_board/batch-delete/", transformCamelToSnake(payload));
  },
  fetchVipPromotion: async () => {
    const response = await apiClient.getData("/manage/account/membership/promotion-page/", {});
    return transformAPIKeyToCamel(response.data.result.results);
  },
  fetchUpdateVipPromotion: async (payload) => {
    await apiClient.putData("/manage/account/membership/promotion-page/batch-update/", transformCamelToSnake(payload));
  },
};

export default vipPromotionAPI;
