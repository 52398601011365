import React, { FC, useState, useEffect } from "react";
import PopupBackground from "@component/PopupBackground";
import { uploadMedia } from "@api/uploadMediaApi";
import { RcFile } from "antd/lib/upload";
import { Form, Input, Button, DatePicker, Upload, message, Row, Modal } from "antd";
import moment from "moment";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { UploadOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { SupplyChainContractDetail, CreateSupplyChainContractParam } from "@api/supplyChainApi";
import { updateSupplyChainContract, createSupplyChainContract } from "@redux/supplyChainSlice";
import locale from "antd/es/date-picker/locale/zh_TW";
import { useParams } from "react-router-dom";

interface Props {
  onClose: () => void;
  currentContract: SupplyChainContractDetail | null;
}

const PopWrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }

  .ant-upload-span {
    margin-top: 20px;
  }

  .ant-upload-list-item-name {
    overflow: visible;
    white-space: normal;
    text-overflow: initial;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;
const UploadText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
  margin: 0 10px;
`;
const ErrorText = styled.div`
  color: ${({ theme }) => theme.colorSecondary500};
`;

const EditContractPopup: FC<Props> = (props) => {
  const { onClose, currentContract } = props;
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { supplyChainId } = useParams();
  const [contractPath, setContractPath] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const [uploadFile, setUploadFile] = useState<any[]>([]);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    if (currentContract) {
      const params: SupplyChainContractDetail = {
        id: currentContract.id,
        pdfDirPrefix: "supply_chain_contract/pdf/",
        pdfFilePath: contractPath || undefined,
        name: formValues.name,
        startAt: formValues.startAt ? formValues.startAt.format("YYYY-MM-DD") : "",
        endAt: formValues.endAt ? formValues.endAt.format("YYYY-MM-DD") : "",
        supplyChain: Number(supplyChainId),
      };
      dispatch(updateSupplyChainContract(params));
    } else {
      const createParams: CreateSupplyChainContractParam = {
        pdfDirPrefix: "supply_chain_contract/pdf/",
        pdfFilePath: contractPath || undefined,
        name: formValues.name,
        startAt: formValues.startAt ? formValues.startAt.format("YYYY-MM-DD") : "",
        endAt: formValues.endAt ? formValues.endAt.format("YYYY-MM-DD") : "",
        supplyChain: Number(supplyChainId),
      };
      dispatch(createSupplyChainContract(createParams));
    }
    onClose();
  };

  const handleDeletePDF = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => {
        setUploadFile([]);
        form.setFieldsValue({ uploadPDF: "" });
        message.success("刪除成功");
      },
    });
  };
  const formatedCurrentContract = currentContract && {
    ...currentContract,
    endAt: moment(currentContract.endAt),
    startAt: moment(currentContract.startAt),
  };

  async function handleUploadPDF(file: RcFile) {
    try {
      const result = await uploadMedia(file);
      setContractPath(result);
      setUploadFile([file]);
    } catch (error: any) {
      message.error(`上傳失敗：${error.message}`);
    }
    return "";
  }

  useEffect(() => {
    if (currentContract) {
      form.setFieldsValue({
        uploadPDF: currentContract.media,
      });
      setUploadFile([
        {
          name: currentContract.name,
          url: currentContract.media,
        },
      ]);
    }
  }, [currentContract, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <PopWrapper>
        <Form colon={false} initialValues={formatedCurrentContract ?? undefined} form={form} onFinish={handleOnSubmit}>
          <Form.Item label="合約名稱" name="name" wrapperCol={{ span: 18 }} rules={[{ required: true, message: "" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="合約生效時間"
            name="startAt"
            wrapperCol={{ span: 10 }}
            rules={[
              { required: true, message: "" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("endAt") && moment(getFieldValue("endAt")).isSame(value, "day")) {
                    setErrorMessage(true);
                    return Promise.reject(new Error(" "));
                  }
                  setErrorMessage(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker locale={locale} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label=" 合約結束時間"
            name="endAt"
            wrapperCol={{ span: 10 }}
            rules={[
              { required: true, message: "" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (value && getFieldValue("startAt") && moment(getFieldValue("startAt")).isSame(value, "day")) {
                    setErrorMessage(true);
                    return Promise.reject(new Error(" "));
                  }
                  setErrorMessage(false);
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker locale={locale} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item name="uploadPDF" wrapperCol={{ span: 24 }} rules={[{ required: true, message: "必填" }]}>
            <Upload fileList={uploadFile} accept="pdf/*" action={handleUploadPDF} onRemove={handleDeletePDF}>
              <Row align="middle">
                <Button icon={<UploadOutlined />}>點擊上傳</Button>
                <UploadText>*僅能上傳PDF</UploadText>
              </Row>
            </Upload>
          </Form.Item>
          <Row justify="space-between">{errorMessage && <ErrorText>合約生效時間與合約結束時間不能重疊</ErrorText>}</Row>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </ButtonContainer>
        </Form>
      </PopWrapper>
    </PopupBackground>
  );
};

export default EditContractPopup;
