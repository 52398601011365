import { ReturnSubOrderReason, returnSubOrderReasonLabel } from "@constant/ReturnOrderReason";

const reasons = [
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.HAS_SIMILAR_PRODUCTS],
    value: ReturnSubOrderReason.HAS_SIMILAR_PRODUCTS,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.SIZE_NOT_MATCH],
    value: ReturnSubOrderReason.SIZE_NOT_MATCH,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.HIGH_PRICE],
    value: ReturnSubOrderReason.HIGH_PRICE,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.REORDER_FOR_DISCOUNT],
    value: ReturnSubOrderReason.REORDER_FOR_DISCOUNT,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.PURCHASE_ERROR],
    value: ReturnSubOrderReason.PURCHASE_ERROR,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.SHIPPING_ERROR],
    value: ReturnSubOrderReason.SHIPPING_ERROR,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.POOR_APPEARANCE],
    value: ReturnSubOrderReason.POOR_APPEARANCE,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.QUALITY],
    value: ReturnSubOrderReason.QUALITY,
  },
  {
    label: returnSubOrderReasonLabel[ReturnSubOrderReason.OTHER],
    value: ReturnSubOrderReason.OTHER,
  },
];

export default reasons;
