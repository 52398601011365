import { FilterTwoTone } from "@ant-design/icons";
import { RootState } from "@redux/rootReducer";
import moment from "moment";
import {
  fetchProductCategoryList,
  fetchProductDailyAggregations,
  fetchStaffList,
  exportProductDailyAggregationsByCSV,
  setFilterOption,
} from "@redux/stockSlice";
import { Button, Select, Table, Radio, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import PopupBackground from "@component/PopupBackground";
import { ModalWrapper } from "src/styles/common";
import { RadioChangeEvent } from "antd/lib/radio";
import Title from "antd/lib/typography/Title";
import Filter from "./Filter";

const { Option } = Select;

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 28px 22px 22px;
  margin-top: 20px;
`;

const Ellipsis = styled.div`
  max-width: 400px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const stockSlice = (state: RootState) => state.stock;

export default function StockManagement() {
  const dispatch = useDispatch();
  const { productDailyAggregationsListResult, productDailyAggregationsFilter, isFetchingList } = useSelector(
    stockSlice,
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [openExportModal, setOpenExportModal] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchProductDailyAggregations({ ...productDailyAggregationsFilter, limit: pageSize, page: value }));
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "statsDate",
      dataIndex: "statsDate",
      title: "日期",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
      render: (productName: string) => <Ellipsis>{productName}</Ellipsis>,
    },
    {
      key: "owner",
      dataIndex: "owner",
      title: "商開負責人",
      width: 150,
    },
    {
      key: "goodInitialInventory",
      dataIndex: "goodInitialInventory",
      title: "期初良品",
      width: 100,
    },
    {
      key: "badInitialInventory",
      dataIndex: "badInitialInventory",
      title: "期初壞品",
      width: 100,
    },
    {
      key: "goodInbound",
      dataIndex: "goodInbound",
      title: "進倉良品",
      width: 100,
    },
    {
      key: "badInbound",
      dataIndex: "badInbound",
      title: "進倉壞品",
      width: 100,
    },
    {
      key: "goodEntry",
      dataIndex: "goodEntry",
      title: "良品移倉入庫",
      width: 130,
    },
    {
      key: "badEntry",
      dataIndex: "badEntry",
      title: "不良品移倉入庫",
      width: 130,
    },
    {
      key: "goodReturn",
      dataIndex: "goodReturn",
      title: "良品移倉出庫",
      width: 130,
    },
    {
      key: "badReturn",
      dataIndex: "badReturn",
      title: "不良品移倉出庫",
      width: 130,
    },
    {
      key: "goodReturnInbound",
      dataIndex: "goodReturnInbound",
      title: "回倉良品",
      width: 100,
    },
    {
      key: "badReturnInbound",
      dataIndex: "badReturnInbound",
      title: "回倉不良品",
      width: 130,
    },
    {
      key: "goodSales",
      dataIndex: "goodSales",
      title: "銷貨良品",
      width: 100,
    },
    {
      key: "goodDeleteSales",
      dataIndex: "goodDeleteSales",
      title: "良品刪單",
      width: 100,
    },
    {
      key: "goodOutbound",
      dataIndex: "goodOutbound",
      title: "退倉良品",
      width: 100,
    },
    {
      key: "badOutbound",
      dataIndex: "badOutbound",
      title: "退倉不良品",
      width: 130,
    },
    {
      key: "goodDeleteOutbound",
      dataIndex: "goodDeleteOutbound",
      title: "良品廠退刪單",
      width: 130,
    },
    {
      key: "badDeleteOutbound",
      dataIndex: "badDeleteOutbound",
      title: "不良品廠退刪單",
      width: 130,
    },
    {
      key: "goodAttributeChangeOutbound",
      dataIndex: "goodAttributeChangeOutbound",
      title: "良品異動出庫",
      width: 130,
    },
    {
      key: "badAttributeChangeInbound",
      dataIndex: "badAttributeChangeInbound",
      title: "不良品異動入庫",
      width: 130,
    },
    {
      key: "badAttributeChangeOutbound",
      dataIndex: "badAttributeChangeOutbound",
      title: "不良品異動出庫",
      width: 130,
    },
    {
      key: "goodAttributeChangeInbound",
      dataIndex: "goodAttributeChangeInbound",
      title: "良品異動入庫",
      width: 130,
    },
    {
      key: "goodProductTransferOutbound",
      dataIndex: "goodProductTransferOutbound",
      title: "良品轉倉出庫",
      width: 130,
    },
    {
      key: "goodInventoryProfit",
      dataIndex: "goodInventoryProfit",
      title: "良品盤盈",
      width: 100,
    },
    {
      key: "goodInventoryLosses",
      dataIndex: "goodInventoryLosses",
      title: "良品盤損",
      width: 100,
    },
    {
      key: "badInventoryProfit",
      dataIndex: "badInventoryProfit",
      title: "不良品盤盈",
      width: 130,
    },
    {
      key: "badInventoryLosses",
      dataIndex: "badInventoryLosses",
      title: "不良品盤損",
      width: 130,
    },
    {
      key: "goodFinalInventory",
      dataIndex: "goodFinalInventory",
      title: "期末良品",
      width: 100,
    },
    {
      key: "badFinalInventory",
      dataIndex: "badFinalInventory",
      title: "期末壞品",
      width: 100,
    },
  ];

  useEffect(() => {
    // 預設前30天
    dispatch(
      setFilterOption({
        statsDateBefore: moment().format("YYYY-MM-DD"),
        statsDateAfter: moment().subtract(30, "days").format("YYYY-MM-DD"),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    setPage(1);
    if (productDailyAggregationsFilter.statsDateBefore) {
      dispatch(fetchProductDailyAggregations({ ...productDailyAggregationsFilter, limit: pageSize, page: 1 }));
    }
  }, [dispatch, pageSize, productDailyAggregationsFilter]);

  useEffect(() => {
    dispatch(fetchStaffList({ staffGroupPermission: "product_owner", limit: 100, offset: 0 }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchProductCategoryList());
  }, [dispatch]);

  const handleOnExport = (groupByMode: string) => {
    // 沒選時間預設前30天
    const filter = {
      ...productDailyAggregationsFilter,
      statsDateBefore: productDailyAggregationsFilter.statsDateBefore
        ? productDailyAggregationsFilter.statsDateBefore
        : moment().format("YYYY-MM-DD"),
      statsDateAfter: productDailyAggregationsFilter.statsDateAfter
        ? productDailyAggregationsFilter.statsDateAfter
        : moment().subtract(30, "days").format("YYYY-MM-DD"),
    };
    dispatch(exportProductDailyAggregationsByCSV({ ...filter, groupByMode }));
  };

  return (
    <Wrapper>
      <PageTitle title="庫存變動數" />
      {openExportModal && <ExportModal onClose={() => setOpenExportModal(false)} onSubmit={handleOnExport} />}

      <Row justify="space-between">
        <Row justify="flex-start">
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
          <Button
            style={{ marginLeft: "10px" }}
            icon={<FilterTwoTone />}
            onClick={() => setOpenExportModal(!openExportModal)}
          >
            匯出
          </Button>
        </Row>
        <Row>
          <PageText>{`總共${productDailyAggregationsListResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageSize(parseInt(value as string, 10))}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <Filter isVisible={showFilter} />
      <TableWrapper>
        <Table
          loading={isFetchingList}
          columns={columns}
          dataSource={productDailyAggregationsListResult.results}
          tableLayout="auto"
          scroll={{ x: "max-content" }}
          pagination={{
            pageSize,
            current: page,
            showSizeChanger: false,
            total: productDailyAggregationsListResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}

const ExportModal = (props: { onClose: () => void; onSubmit: (groupByMode: string) => void }) => {
  const { onClose, onSubmit } = props;
  const [groupByMode, setGroupByMode] = useState<string | undefined>();
  const handleClosePopup = () => {
    onClose();
  };
  const onChange = (e: RadioChangeEvent) => {
    setGroupByMode(e.target.value);
  };
  const onHandleSubmit = () => {
    if (groupByMode !== undefined) {
      onSubmit(groupByMode);
    }
    onClose();
  };

  return (
    <PopupBackground close={handleClosePopup}>
      <ModalWrapper width="300px">
        <Title level={5}>匯出資料</Title>
        <Radio.Group onChange={onChange} value={groupByMode}>
          <Space direction="vertical">
            <Radio value="origin">原始</Radio>
            <Radio value="sort_out">彙整</Radio>
          </Space>
        </Radio.Group>
        <ModalFooter>
          <Space>
            <Button onClick={handleClosePopup}>取消</Button>
            <Button type="primary" disabled={groupByMode === undefined} onClick={onHandleSubmit}>
              確定
            </Button>
          </Space>
        </ModalFooter>
      </ModalWrapper>
    </PopupBackground>
  );
};
