/* eslint-disable react/no-this-in-sfc */
import type { RootState } from "@redux/rootReducer";
import { Spin } from "antd";
import React, { FC } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  min-height: 250px;
  border-radius: 2px;
`;
const HeaderTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 4px;
`;
const HeaderContent = styled.div`
  font-size: 24px;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 46px;
`;

const VipCountCard: FC = () => {
  const isFetchingVipMemberStatusDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingVipMemberStatusDashboard,
  );
  const vipMemberStatusDashboard = useSelector((state: RootState) => state.dashboard.vipMemberStatusDashboard);
  const { totalVipMembers, totalCancelMembers } = vipMemberStatusDashboard;

  return (
    <Spin spinning={isFetchingVipMemberStatusDashboard}>
      <Wrapper>
        <HeaderTitle>總黑卡會員人數(累計，此數值不受時間篩選影響)</HeaderTitle>
        <HeaderContent>{`${totalVipMembers.toLocaleString("en-US")}人`}</HeaderContent>
        <HeaderTitle>累計取消會員數(累計，此數值不受時間篩選影響)</HeaderTitle>
        <HeaderContent>{`${totalCancelMembers && totalCancelMembers.toLocaleString("en-US")}人`}</HeaderContent>
      </Wrapper>
    </Spin>
  );
};

export default VipCountCard;
