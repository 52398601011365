import queryString from "query-string";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const getStoreInfoFromURL = () => {
  const { location } = window;
  const currentParams = queryString.parse(location.search);

  const { stCate = "", stCode = "", stName = "", stAddr = "", cvsspot = "", name = "", addr = "" } = currentParams;
  if (stCate) {
    return { stCate, stCode, stName, stAddr };
  }
  if (cvsspot) {
    return { stCate: "cvs", stCode: cvsspot, stName: name, stAddr: addr };
  }
  return {};
};

type Props = {
  redirectPath?: string;
};

export default function CVSRedirect(props: Props) {
  const { redirectPath } = props;

  const navigate = useNavigate();

  const storeInfoFromURL = getStoreInfoFromURL();

  useEffect(() => {
    if (storeInfoFromURL.stCate) {
      if (redirectPath) {
        navigate(redirectPath, {
          state: {
            stCate: storeInfoFromURL.stCate,
            stCode: storeInfoFromURL.stCode,
            stName: storeInfoFromURL.stName,
            stAddr: storeInfoFromURL.stAddr,
          },
        });
      } else {
        navigate(window.location.pathname, {
          state: {
            stCate: storeInfoFromURL.stCate,
            stCode: storeInfoFromURL.stCode,
            stName: storeInfoFromURL.stName,
            stAddr: storeInfoFromURL.stAddr,
          },
        });
      }
    }
  }, [storeInfoFromURL, navigate, redirectPath]);

  return null;
}
