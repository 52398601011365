import { ExclamationCircleOutlined, FilterTwoTone, FormOutlined, PlusCircleFilled } from "@ant-design/icons";
import { SlotListItem } from "@api/slotApi";
import { configState } from "@redux/configSlice";
import { RootState } from "@redux/rootReducer";
import {
  batchDeleteSlot,
  batchUpdateRank,
  fetchSlotList,
  reset,
  singleDeleteSlot,
  updateSelectedSlotIds,
  updateSlotListParams,
} from "@redux/slotSlice";
import { Button, InputNumber, Modal, Pagination, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import { ColumnsType, TablePaginationConfig } from "antd/lib/table";
import React, { FC, Key, ReactText, SyntheticEvent, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import PageTitle from "@component/PageTitle";
import styled from "styled-components";
import { PageType, pageTypeTextMapping, slotPatternTextMapping } from "./constant";
import Filter from "./Filter";

const Wrapper = styled.div`
  padding: 20px 20px 56px 24px;
`;
const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 21px;
`;
const TopButtonContainer = styled.div``;
const TablePageInfo = styled.div``;
const TopButton = styled(Button)`
  margin-right: 10px;
`;
const PageSelect = styled(Select)`
  margin: 0 10px;
`;
const TableContainer = styled.div`
  padding-left: 16px;
  margin-bottom: 16px;
`;
const DeleteButton = styled(Button)``;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const Link = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colorSuccess500};
  cursor: pointer;
  margin: 0;
  text-decoration: underline;
`;
const EditText = styled.a`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
`;
const DeleteText = styled.p<{ disabled: boolean }>`
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? "#BFBFBF" : "#1890ff")};
  cursor: pointer;
  margin: 0;
  margin-right: 22px;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
`;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const EditIcon = styled(FormOutlined)`
  margin-left: 5px;
`;
const EditTextContainer = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: 75px;
`;

const pageSizeOptions = [20, 50, 100];

interface TableSlotItem extends SlotListItem {
  key: number;
}

const Slot: FC = () => {
  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [localSlotList, setLocalSlotList] = useState<TableSlotItem[]>([]);

  const isFetchingList = useSelector((state: RootState) => state.slot.isFetchingList);
  const slotListResult = useSelector((state: RootState) => state.slot.slotListResult);
  const slotListParams = useSelector((state: RootState) => state.slot.slotListParams);
  const selectedSlotIds = useSelector((state: RootState) => state.slot.selectedSlotIds);

  const { count, results: slotList } = slotListResult;
  const { offset, limit } = slotListParams;

  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchSlotList());
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    const tableSlotList = slotList.map((slot) => ({
      ...slot,
      key: slot.pageTypeRelationId || slot.id,
    }));
    setLocalSlotList(tableSlotList);
  }, [slotList]);

  const toggleFilter = () => setShowFilter((prev) => !prev);
  const toggleEditMode = (event?: SyntheticEvent) => {
    if (event) event.stopPropagation();
    setEditMode((prev) => !prev);
  };

  const handleOnRankChange = (index: number) => (value: string | number | undefined) => {
    setLocalSlotList((prev) => {
      const newList = [...prev];
      newList[index] = {
        ...newList[index],
        position: Number(value),
      };
      return newList;
    });
  };

  const handleOnEditCancel = () => {
    const tableSlotList = slotList.map((slot) => ({
      ...slot,
      key: slot.pageTypeRelationId || slot.id,
    }));
    setLocalSlotList(tableSlotList);
    toggleEditMode();
  };
  const handleOnSave = () => {
    dispatch(batchUpdateRank(localSlotList));
    toggleEditMode();
  };

  const handleOnAdd = () => {
    window.open("/slot-management/add", "_blank");
  };

  const handleOnCheck = (values: ReactText[]) => {
    dispatch(updateSelectedSlotIds(values as number[]));
  };

  const handleOnSingleDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(singleDeleteSlot(id)),
    });
  };

  const handleOnBatchDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這些資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(batchDeleteSlot()),
    });
  };

  const handleOnPageSizeChange = (value: SelectValue) => {
    const filter = {
      ...slotListParams,
      limit: value as number,
    };

    dispatch(updateSlotListParams(filter));
    dispatch(fetchSlotList());
  };

  const handleOnPageChange = (page: number) => {
    const filter = {
      ...slotListParams,
      offset: (page - 1) * limit,
    };

    dispatch(updateSlotListParams(filter));
    dispatch(fetchSlotList());
  };

  // sorting
  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: any,
  ) => {
    const { field, order } = sorter;
    let ordering = "";
    switch (order) {
      case "descend":
        ordering = `-${field}`;
        break;
      case "ascend":
        ordering = field;
        break;
      default:
    }

    const params = {
      ...slotListParams,
      ordering,
    };

    dispatch(updateSlotListParams(params));
    dispatch(fetchSlotList());
  };

  const tableColumns: ColumnsType<TableSlotItem> = [
    {
      title: <TableNoWrap>版位名稱</TableNoWrap>,
      key: "name",
      dataIndex: "name",
    },
    {
      title: <TableNoWrap>頁面類型</TableNoWrap>,
      key: "pageType",
      dataIndex: "pageType",
      render: (value: SlotListItem["pageType"]) => pageTypeTextMapping[value] || "N/A",
      width: 120,
    },
    {
      title: <TableNoWrap>頁面資訊</TableNoWrap>,
      key: "pageInfo",
      dataIndex: "pageInfo",
      width: 100,
      render: (value: SlotListItem["pageInfo"], data: SlotListItem) => {
        switch (data.pageType) {
          case PageType.CATEGORY:
            return (
              <Link href={`${webDomain}/category/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.SALES_PAGE:
            return (
              <Link href={`${webDomain}/salespage/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.SEARCH:
            return (
              <Link href={`${webDomain}/search`} target="_blank">
                N/A
              </Link>
            );
          case PageType.TAG:
            return (
              <Link href={`${webDomain}/tag/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.TOPIC:
            return (
              <Link href={`${webDomain}/topic/${value}`} target="_blank">
                {value}
              </Link>
            );
          case PageType.TOPIC_CATEGORY:
            return (
              <Link href={`${webDomain}/topic-category/${value}`} target="_blank">
                {value}
              </Link>
            );
          default:
            return "N/A";
        }
      },
    },
    {
      title: <TableNoWrap>版位類型</TableNoWrap>,
      key: "slotPattern",
      dataIndex: "slotPattern",
      render: (value: SlotListItem["slotPattern"]) => slotPatternTextMapping[value],
    },
    {
      title: <TableNoWrap>版位上架時間</TableNoWrap>,
      key: "startAt",
      dataIndex: "startAt",
      render: (value: SlotListItem["startAt"], data: SlotListItem) => {
        const { startAt, endAt } = data;
        if (startAt === null && endAt === null) return "";
        return `${startAt} - ${endAt}`;
      },
    },
    {
      title: (
        <TableNoWrap>
          位置
          {editMode ? "" : <EditIcon onClick={toggleEditMode} />}
        </TableNoWrap>
      ),
      width: 100,
      key: "position",
      dataIndex: "position",
      sorter: true,
      render: (value: SlotListItem["position"], data: SlotListItem, index: number) => {
        const disabledEditPosition = data.pageInfo === null && data.pageType === null;

        return editMode ? (
          <InputNumber
            type="number"
            value={value}
            onChange={(valueN) => handleOnRankChange(index)(valueN as string | number | undefined)}
            disabled={disabledEditPosition}
          />
        ) : (
          value
        );
      },
    },
    {
      title: editMode ? (
        <TableNoWrap>
          <CancelButton onClick={handleOnEditCancel}>取消</CancelButton>
          <Button type="primary" onClick={handleOnSave}>
            儲存
          </Button>
        </TableNoWrap>
      ) : (
        ""
      ),
      key: "",
      dataIndex: "",
      width: 170,
      fixed: "right",
      render: (value: any, data: SlotListItem) => {
        const disabledDelete = data.pageInfo === null && data.pageType === null;
        return (
          <EditTextContainer>
            {editMode ? (
              ""
            ) : (
              <>
                <EditText href={`/slot-management/edit/${data.id}`} target="_blank">
                  編輯
                </EditText>
                <DeleteText onClick={handleOnSingleDelete(data.pageTypeRelationId)} disabled={disabledDelete}>
                  刪除
                </DeleteText>
              </>
            )}
          </EditTextContainer>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="版位管理" />
      <Header>
        <TopButtonContainer>
          <TopButton type="primary" icon={<PlusCircleFilled />} onClick={handleOnAdd}>
            新增版位
          </TopButton>
          <TopButton icon={<FilterTwoTone />} onClick={toggleFilter}>
            篩選
          </TopButton>
        </TopButtonContainer>
        <TablePageInfo>
          總共
          {count}
          筆, 每頁顯示
          <PageSelect value={limit} onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            {pageSizeOptions.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </PageSelect>
          筆
        </TablePageInfo>
      </Header>
      {showFilter && <Filter />}
      <TableContainer>
        <Table
          dataSource={localSlotList}
          columns={tableColumns}
          scroll={{ x: "max-content", y: 400 }}
          rowSelection={{
            onChange: handleOnCheck,
            selectedRowKeys: selectedSlotIds,
            getCheckboxProps: (record) => {
              return {
                disabled: record.pageTypeRelationId === null,
              };
            },
          }}
          tableLayout="auto"
          pagination={false}
          loading={isFetchingList}
          onChange={handleOnChange}
        />
      </TableContainer>
      <Footer>
        <DeleteButton onClick={handleOnBatchDelete} disabled={selectedSlotIds.length === 0}>
          刪除
        </DeleteButton>
        <Pagination
          current={currentPage}
          total={count}
          onChange={handleOnPageChange}
          showSizeChanger={false}
          pageSize={limit}
        />
      </Footer>
    </Wrapper>
  );
};

export default Slot;
