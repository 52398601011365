// import moment from "moment";

export enum VendorApproveStatus {
  PENDING = 1,
  PORCESS = 2,
  PASS = 3,
  REJECT = 4,
  DELETE = 5,
}

export const vendorApproveStatusMap: { [key: number]: string } = {
  [VendorApproveStatus.PENDING]: "待審核",
  [VendorApproveStatus.PORCESS]: "審核中",
  [VendorApproveStatus.PASS]: "審核已通過",
  [VendorApproveStatus.REJECT]: "審核不通過",
  [VendorApproveStatus.DELETE]: "作廢",
};

export enum SupplementPurpose {
  ADJUST_UNSOLD_AMOUNT = 1,
  LAST_MONTH_BALANCE = 2,
  OUTBOUND_DELAY = 3,
  STORAGE_PROCESS_FEE = 4,
  OTHERS = 5,
}

export const supplementPurposeMap: { [key: number]: string } = {
  [SupplementPurpose.ADJUST_UNSOLD_AMOUNT]: "末售金額調整",
  [SupplementPurpose.LAST_MONTH_BALANCE]: "上月結算後差額",
  [SupplementPurpose.OUTBOUND_DELAY]: "晚退倉",
  [SupplementPurpose.STORAGE_PROCESS_FEE]: "倉租處理費",
  [SupplementPurpose.OTHERS]: "其他",
};

export const canExecution = true;

export default {};
