import React, { FC, MouseEvent } from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background-color: black;
  align-items: center;
  justify-content: center;
  z-index: 10;
`;
const Photo = styled.img`
  max-height: 100%;
  max-width: 80%;
`;

interface Props {
  source: string;
  onClose: () => void;
}

const PhotoModal: FC<Props> = (props) => {
  const { onClose, source } = props;

  const stopPropagation = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
  };

  return (
    <ModalBackground onClick={onClose}>
      <Photo src={source} onClick={stopPropagation} />
    </ModalBackground>
  );
};

export default PhotoModal;
