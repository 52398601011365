import { StarFilled, StarOutlined } from "@ant-design/icons";
import { UpdateDetailParams } from "@api/reviewApi";
import UploadImageButton from "@component/UploadImageButton";
import { configState } from "@redux/configSlice";
import { fetchReviewDetail, updateReviewDetail } from "@redux/reviewManagementSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Form, Input, Select, Tabs } from "antd";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { StatusOptions } from "./ReviewList/Filter";

const Wrapper = styled.div`
  padding: 10px 25px 100px 25px;
`;
const TabContainer = styled.div`
  padding: 15px;
  padding-bottom: 155px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;
const ContentWrapper = styled.div`
  padding-left: 25px;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;
const Row = styled.div`
  display: flex;
  margin-bottom: 20px;
`;
const RowTitle = styled.div`
  font-size: 14px;
  flex: 0 0 100px;
  margin-right: 24px;
  line-height: 22px;
`;
const RowContent = styled.div`
  font-size: 14px;
  line-height: 22px;
  min-width: 0;
`;
const Red = styled.span`
  color: red;
`;
const StyledSelect = styled(Select)`
  && {
    width: 200px;
  }
`;
const StyledTextarea = styled(Input.TextArea)`
  width: 584px;
  &&&& {
    height: 108px;
  }
`;
const Link = styled.a`
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
`;
const StarContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;
const StarIcon = styled.div`
  margin-right: 4px;
`;
const FilledStar = styled(StarFilled)`
  color: #ffbb00;
  cursor: pointer;
`;
const EmptyStar = styled(StarOutlined)`
  color: #d3d3d3;
  cursor: pointer;
`;

const ReviewEdit: FC = () => {
  const { id: reviewId } = useParams();

  const dispatch = useAppDispatch();
  const { webDomain } = useSelector(configState);
  const { reviewDetail } = useSelector((state: RootState) => state.reviewManagement);

  const [form] = Form.useForm<UpdateDetailParams>();

  const {
    id,
    submissionAt,
    approvalStatus,
    comments,
    salesPageId,
    salesPageName,
    planName,
    orderId,
    orderNumber,
    memberName,
    memberEmail,
    memberPhone,
    photo,
    skus,
    scores,
    optionsDetail,
  } = reviewDetail;

  useEffect(() => {
    dispatch(fetchReviewDetail(Number(reviewId)));
  }, [dispatch, reviewId]);

  useEffect(() => {
    form.setFieldsValue({
      comments,
      approvalStatus,
      scores,
      imageFilePath: photo,
    });
  }, [form, comments, approvalStatus, scores, photo]);

  const changeScores = (value: number) => () => {
    form.setFieldsValue({
      scores: value,
    });
  };

  const handlePhotoUpload = (url: string) => {
    form.setFieldsValue({
      imageFilePath: url,
    });
  };

  const handleOnSubmit = () => {
    const values = form.getFieldsValue();

    dispatch(updateReviewDetail(values));
  };

  return (
    <Wrapper>
      <PageTitle title={`評分評論 - ${reviewDetail.planName}`} />
      <Form form={form} onFinish={handleOnSubmit} onFinishFailed={showRequiredFieldsWarning}>
        <TabContainer>
          <Tabs type="card">
            <Tabs.TabPane tab="評分評論內容">
              <ContentWrapper>
                <Row>
                  <RowTitle>ID</RowTitle>
                  <RowContent>{id}</RowContent>
                </Row>
                <Row>
                  <RowTitle>評論時間</RowTitle>
                  <RowContent>{submissionAt.substring(0, 19)}</RowContent>
                </Row>
                <Row>
                  <RowTitle>狀態</RowTitle>
                  <Form.Item name="approvalStatus" noStyle>
                    <StyledSelect options={StatusOptions} />
                  </Form.Item>
                </Row>
                <Row>
                  <RowTitle>訂單編號</RowTitle>
                  <Link href={`/order-content/${orderId}`} target="_blank">
                    {orderNumber}
                  </Link>
                </Row>
                <Row>
                  <RowTitle>會員名稱</RowTitle>
                  <RowContent>{memberName}</RowContent>
                </Row>
                <Row>
                  <RowTitle>會員信箱</RowTitle>
                  <RowContent>{memberEmail}</RowContent>
                </Row>
                <Row>
                  <RowTitle>會員電話</RowTitle>
                  <RowContent>{memberPhone}</RowContent>
                </Row>
                <Row>
                  <RowTitle>評分</RowTitle>
                  <Form.Item name="scores" noStyle />
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValue, currentValues) => {
                      return prevValue.scores !== currentValues.scores;
                    }}
                  >
                    {({ getFieldValue }) => {
                      const localScores = getFieldValue("scores");
                      return (
                        <StarContainer>
                          <StarIcon onClick={changeScores(1)}>
                            {localScores >= 1 ? <FilledStar /> : <EmptyStar />}
                          </StarIcon>
                          <StarIcon onClick={changeScores(2)}>
                            {localScores >= 2 ? <FilledStar /> : <EmptyStar />}
                          </StarIcon>
                          <StarIcon onClick={changeScores(3)}>
                            {localScores >= 3 ? <FilledStar /> : <EmptyStar />}
                          </StarIcon>
                          <StarIcon onClick={changeScores(4)}>
                            {localScores >= 4 ? <FilledStar /> : <EmptyStar />}
                          </StarIcon>
                          <StarIcon onClick={changeScores(5)}>
                            {localScores >= 5 ? <FilledStar /> : <EmptyStar />}
                          </StarIcon>
                        </StarContainer>
                      );
                    }}
                  </Form.Item>
                </Row>
                <Row>
                  <RowTitle>
                    評論內容
                    <Red>*</Red>
                  </RowTitle>
                  <Form.Item name="comments" rules={[{ required: true, message: "" }]}>
                    <StyledTextarea />
                  </Form.Item>
                </Row>
                <Row>
                  <RowTitle>銷售頁名稱</RowTitle>
                  <Link href={`${webDomain}/salespage/${salesPageId}`} target="_blank">
                    {salesPageName}
                  </Link>
                </Row>
                <Row>
                  <RowTitle>方案名稱</RowTitle>
                  <RowContent>{planName}</RowContent>
                </Row>
                <Row>
                  <RowTitle>數量(件)及選項</RowTitle>
                  <RowContent>
                    {optionsDetail.reduce((acc, curValue, index) => {
                      const text = `${curValue.productName}*${curValue.totalQty}`;
                      return index === 0 ? text : `${acc}, ${text}`;
                    }, "")}
                  </RowContent>
                </Row>
                <Row>
                  <RowTitle>SKU</RowTitle>
                  <RowContent>{skus.join(", ")}</RowContent>
                </Row>
                <Row>
                  <RowTitle>圖片</RowTitle>
                  <Form.Item name="imageFilePath" valuePropName="imageUrl" noStyle>
                    <UploadImageButton finishUpload={handlePhotoUpload} />
                  </Form.Item>
                </Row>
              </ContentWrapper>
            </Tabs.TabPane>
          </Tabs>
        </TabContainer>
        <Footer>
          <Button type="primary" htmlType="submit">
            儲存此分頁
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default ReviewEdit;
