import React, { useCallback, useEffect, useState } from "react";
import saleApi, { SalePlan } from "@api/saleApi";
import { useParams } from "react-router-dom";
import { hideLoading, showLoading } from "@redux/notifySlice";
import { useDispatch } from "react-redux";
import AnyChangePriceModal from "./AnyChangePriceModal";
import NormalChangePriceModal from "./NormalChangePriceModal";
import OptionModal from "./OptionModal";
import PlanModal from "./PlanModal";
import GroupLimitModal from "./GroupLimitModal";

export enum ModalType {
  PLAN = "PLAN",
  OPTION = "OPTION",
  NORMAL_CHANGE_PRICE = "NORMAL_CHANGE_PRICE",
  ANY_CHANGE_PRICE = "ANY_CHANGE_PRICE",
  PURCHASE_LIMIT = "PURCHASE_LIMIT",
}

type Props = {
  sequence: ModalType[];
  close: () => void;
};

export default function ModalComplex(props: Props) {
  const { sequence, close } = props;
  const { salesId } = useParams();
  const dispatch = useDispatch();

  const [step, setStep] = useState<number>(0);
  const [modalType, setModalType] = useState<ModalType>(sequence[0]);
  const [salePlanListResult, setSalePlanListResult] = useState<{
    count: number;
    next: string;
    previous: string;
    results: SalePlan[];
  }>({
    count: 0,
    next: "",
    previous: "",
    results: [],
  });

  const onNextAction = useCallback(() => {
    if (step === sequence.length - 1) {
      close();
    } else {
      setModalType(sequence[step + 1]);
      setStep((prev) => prev + 1);
    }
  }, [close, sequence, step]);

  useEffect(() => {
    const getSalePlanList = async () => {
      dispatch(showLoading());
      const response = await saleApi.fetchPlanList({ salesPage: parseInt(salesId, 10), limit: 100, offset: 0 });
      setSalePlanListResult(response);
      dispatch(hideLoading());
    };
    getSalePlanList();
  }, [dispatch, salesId]);
  switch (modalType) {
    case ModalType.PLAN:
      return <PlanModal onNext={onNextAction} close={close} salePlanListResult={salePlanListResult} />;
    case ModalType.OPTION:
      return <OptionModal onNext={onNextAction} close={close} />;
    case ModalType.ANY_CHANGE_PRICE:
      return (
        <AnyChangePriceModal
          onNext={onNextAction}
          close={close}
          isLast={step === sequence.length - 1}
          salePlanListResult={salePlanListResult}
        />
      );
    case ModalType.NORMAL_CHANGE_PRICE:
      return (
        <NormalChangePriceModal
          onNext={onNextAction}
          close={close}
          isLast={step === sequence.length - 1}
          salePlanListResult={salePlanListResult}
        />
      );
    case ModalType.PURCHASE_LIMIT:
      return <GroupLimitModal onNext={onNextAction} close={close} />;
    default:
      return null;
  }
}
