import { ProcessingAppListItem } from "@api/tryall/ProcessingApplicationApi";
import { Col, Row } from "antd";
import React, { useMemo } from "react";
import { TabContentWrapper } from "src/styles/common";
import styled from "styled-components";
import ProcessAppAddModal from "./ProcessAppAddModal";
import processingStatusOptions from "./ProcessingStatus";

const NoWrapCol = styled(Col)`
  min-width: 120px;
`;

type Props = {
  details: ProcessingAppListItem;
  showEditModal: boolean;
  onModalClose: () => void;
};
const ProcessingApplicationInfo = (props: Props) => {
  const { details, showEditModal, onModalClose } = props;
  const { procNumber, staff, estDeliveryDate, note, status, createdAt } = details;
  const processingStatus = useMemo(() => {
    return processingStatusOptions.find((item) => item.value === status)?.name || "no match status";
  }, [status]);

  return (
    <TabContentWrapper>
      {showEditModal && <ProcessAppAddModal onClose={onModalClose} editData={details} />}
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>加工單編號</NoWrapCol>
        <Col>{procNumber}</Col>
      </Row>
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>建立人員</NoWrapCol>
        <Col>{staff?.name}</Col>
      </Row>
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>預計完工日期</NoWrapCol>
        <Col>{estDeliveryDate}</Col>
      </Row>
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>備註</NoWrapCol>
        <Col>{note}</Col>
      </Row>
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>狀態</NoWrapCol>
        <Col>{processingStatus}</Col>
      </Row>
      <Row gutter={[0, 16]} wrap={false}>
        <NoWrapCol span={4}>建立日期</NoWrapCol>
        <Col>{createdAt}</Col>
      </Row>
    </TabContentWrapper>
  );
};
export default ProcessingApplicationInfo;
