import { SpecialShipmentDetail } from "@api/sharing/specialShipmentApi";
import ReviewStateTable from "@page/ReturnOrder/RevieStateTabel";
import { specialShipmentState } from "@redux/sharing/specialShipmentSlice";
import { Col, Modal, Row, Spin, Typography } from "antd";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { StyledSection } from "src/styles/common";
import styled from "styled-components";
import {
  approvalStatusOptions,
  OrderStatus,
  orderStatusOptions,
  SpecialShipmentPurpose,
  specialShipmentPurposeOptions,
} from "./specialShipmentConstants";

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoticeText = styled.a`
  display: block;
  width: 100px;
  margin-top: 5px;
`;

type ItemKeys = keyof SpecialShipmentDetail;

type RenderFunction = {
  label: string;
  dataKey: ItemKeys | "";
  render?: (value: any, record: SpecialShipmentDetail) => React.ReactNode;
};

type Props = {
  itemDetails: SpecialShipmentDetail;
};

export default function SpecialShipmentDetails(props: Props) {
  const { itemDetails } = props;
  const { isFetching } = useSelector(specialShipmentState);

  const info = () => {
    Modal.info({
      icon: null,
      okText: "我知道了",
      content: (
        <div>
          <FlexColumn>
            <Typography.Title level={5}>審核規則說明</Typography.Title>
            <Typography.Text strong>主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：不須任何人審核</Typography.Text>
            <Typography.Paragraph>2. 3000 元以上：送出申請後，需 CEO / 財務進行審核</Typography.Paragraph>
            <Typography.Text strong>非主管級送出申請的審核規則</Typography.Text>
            <Typography.Text>1. 3000 元內：送出申請後，一／二階主管審核</Typography.Text>
            <Typography.Text>
              1. 3000 元以上：送出申請後，除需一／二階主管審核之外， CEO / 財務也需要審核
            </Typography.Text>
          </FlexColumn>
        </div>
      ),
      onOk() {},
    });
  };

  const fieldsData: RenderFunction[] = useMemo(
    () => [
      {
        label: "訂單編號",
        dataKey: "orderNumber",
      },
      {
        label: "審核狀態",
        dataKey: "approvalStatus",
        render: (approvalStatus, record) => {
          const statusString = record.canSubmit
            ? "未送出申請"
            : approvalStatusOptions.find((option) => option.value === approvalStatus)?.label;
          return (
            <>
              {statusString}
              <NoticeText onClick={info}>審核規則說明</NoticeText>
            </>
          );
        },
      },
      {
        label: "",
        dataKey: "",
        render: (_, record) => {
          return (
            <ReviewStateTable
              data={{
                ceo: record.ceoApproval,
                finance: record.financeApproval,
                manager: record.managerApproval,
                sectionManager: record.sectionManagerApproval,
              }}
            />
          );
        },
      },
      {
        label: "建立人員",
        dataKey: "staffName",
      },
      {
        label: "建立時間",
        dataKey: "createdAt",
      },
      {
        label: "訂單狀態",
        dataKey: "status",
        render: (state: OrderStatus) => <p>{orderStatusOptions.find((option) => option.value === state)?.label}</p>,
      },
      {
        label: "出倉倉庫",
        dataKey: "warehouseName",
      },
      {
        label: "預計拋單日期",
        dataKey: "estDeployedDate",
      },
      {
        label: "預計到貨日期",
        dataKey: "estArrivedDate",
      },
      {
        label: "出貨緣由",
        dataKey: "purpose",
        render: (state: SpecialShipmentPurpose) => (
          <p>{specialShipmentPurposeOptions.find((option) => option.value === state)?.label}</p>
        ),
      },
      {
        label: "緣由說明",
        dataKey: "desc",
      },
      {
        label: "運送方式",
        dataKey: "carrierRelationName",
      },
      {
        label: "收件人",
        dataKey: "receiverName",
      },
      {
        label: "收件電話",
        dataKey: "receiverPhone",
      },
      {
        label: "收件地址",
        dataKey: "receiverAddress",
        render: (value, record) => {
          return `${record.receiverZipcode}  ${value}`;
        },
      },
      {
        label: "實際出貨時間",
        dataKey: "shippingAt",
      },
      {
        label: "運送編號",
        dataKey: "shippingNumber",
      },
    ],
    [],
  );

  return (
    <Spin spinning={isFetching}>
      <StyledSection>{fieldsData.map((field) => GenerateRow(field, itemDetails))}</StyledSection>
    </Spin>
  );
}

const GenerateRow = (field: RenderFunction, details: SpecialShipmentDetail) => {
  const { dataKey, render, label } = field;
  const data = dataKey !== "" ? details[dataKey] : "N/A";
  return (
    <Row gutter={24} style={{ marginBottom: "24px" }} key={field.dataKey}>
      <Col span={4}>{label}</Col>
      <Col flex="auto">{render ? render(data, details) : data ?? "N/A"}</Col>
    </Row>
  );
};
