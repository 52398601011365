import { PlusCircleTwoTone } from "@ant-design/icons";
import { Contract } from "@api/utils/normalizeContract";
import PageTitle from "@component/PageTitle";
import { fetchContractList, fetchUpdateContract } from "@redux/contractSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Select, Switch, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const { Option } = Select;

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  margin-top: 21px;
  padding: 21px 28px 22px 22px;
`;

const contractSlice = (state: RootState) => state.contract;

export default function ContractPage() {
  const dispatch = useDispatch();
  const { isFetching, contractList, contractCount, isEditDone } = useSelector(contractSlice);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchContractList({
        limit: pageSize,
        offset: pageSize * (value - 1),
      }),
    );
  };

  const openEditPage = (contractId?: number) => {
    if (contractId) {
      window.open(`/contract/edit/${contractId}`);
    } else {
      window.open("/contract/edit/new");
    }
  };

  const columns = [
    {
      key: "isActive",
      dataIndex: "isActive",
      title: "啟用",
      render: (isActive: boolean, contract: Contract) => (
        <Switch
          checked={isActive}
          onChange={(checked) =>
            dispatch(
              fetchUpdateContract({
                contractId: contract.id,
                isActive: checked,
              }),
            )
          }
        />
      ),
    },
    {
      key: "displayName",
      dataIndex: "displayName",
      title: "合約名稱",
    },
    {
      key: "modeName",
      dataIndex: "modeName",
      title: "合作方式",
    },
    {
      key: "totalDeductionRate",
      dataIndex: "totalDeductionRate",
      title: "總後扣比例",
      render: (totalDeductionRate: number, contract: Contract) => (
        <Row justify="space-between">
          <div>{totalDeductionRate}</div>
          <Button type="link" onClick={() => openEditPage(contract.id)}>
            編輯
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    dispatch(fetchContractList({ limit: pageSize, offset: 0 }));
  }, [dispatch, pageSize]);

  useEffect(() => {
    if (isEditDone) {
      dispatch(fetchContractList({ limit: pageSize, offset: pageSize * (page - 1) }));
    }
  }, [dispatch, pageSize, page, isEditDone]);

  return (
    <Wrapper>
      <PageTitle title="合約" />
      <Row justify="space-between">
        <Button type="primary" icon={<PlusCircleTwoTone />} onClick={() => openEditPage()}>
          新增合約
        </Button>
        <Row>
          <PageText>{`總共${contractCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageSize(parseInt(value as string, 10))}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={contractList}
          pagination={{
            pageSize,
            current: page,
            showSizeChanger: false,
            total: contractCount,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
    </Wrapper>
  );
}
