import brandOperationApi, {
  AddIWApplicationParams,
  FetchVendorListParam,
  IntoWarehouseDetails,
  IntoWarehouseList,
  IntoWarehouseParams,
  IWAppProductList,
  IWDetailsProductItem,
  UploadErrorReason,
} from "@api/brandOperationApi";
import { SupplyChainList } from "@api/supplyChainApi";
import { hideLoading, showLoading } from "@redux/notifySlice";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";

interface IState {
  isFetching: boolean;
  intoWarehouseList: IntoWarehouseList;
  intoWarehouseParams: IntoWarehouseParams;
  intoWarehouseDetails: IntoWarehouseDetails | undefined;
  vendorListParam: FetchVendorListParam;
  vendorListResult: SupplyChainList;
  editModal: boolean;
  productList: IWAppProductList;
  productListParams: { offset: number; limit: number };
  brandVpc: { name: string; no: string } | undefined;
  brandVpcIsContract: boolean;
  csvErrorMessage: string;
  showImportFilePopup: boolean;
  csvErrorReason: UploadErrorReason;
  errorStatusCode?: number;
}
const initialState: IState = {
  isFetching: false,
  intoWarehouseList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  intoWarehouseParams: {
    stoNumber: undefined,
    supplyChain: undefined,
    skus: undefined,
    specDateAfter: undefined,
    specDateBefore: undefined,
    limit: 20,
    offset: 0,
  },
  vendorListParam: {
    nameQ: undefined,
    limit: 10,
    offset: 0,
  },
  vendorListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  intoWarehouseDetails: undefined,
  editModal: false,
  productList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  productListParams: { limit: 20, offset: 0 },
  brandVpc: undefined,
  brandVpcIsContract: true,
  csvErrorMessage: "",
  showImportFilePopup: false,
  csvErrorReason: {
    httpStatus: -1,
    message: "",
    result: "",
    statusCode: -1,
  },
  errorStatusCode: undefined,
};

export const fetchIntoWarehouseList = createAsyncThunk(
  "intoWarehouseBrandSlice/fetchIntoWarehouseList",
  async (_, thunkApi) => {
    const {
      intoWarehouseBrandSlice: { intoWarehouseParams },
    } = thunkApi.getState() as RootState;
    const response = await brandOperationApi.fetchIntoWarehouseList(intoWarehouseParams);
    return response;
  },
);

export const fetchVendorList = createAsyncThunk(
  "intoWarehouseBrandSlice/fetchVendorList",
  async (nameQ: string, thunkApi) => {
    const {
      intoWarehouseBrandSlice: { vendorListParam },
    } = thunkApi.getState() as RootState;
    const response = await brandOperationApi.fetchVendorList({ ...vendorListParam, nameQ });
    return response;
  },
);

export const loadMoreVendorList = createAsyncThunk(
  "intoWarehouseBrandSlice/loadMoreVendorList",
  async (_, thunkApi) => {
    const {
      intoWarehouseBrandSlice: { vendorListParam },
    } = thunkApi.getState() as RootState;

    const params: FetchVendorListParam = {
      ...vendorListParam,
      offset: vendorListParam.offset + vendorListParam.limit,
    };

    thunkApi.dispatch(updateVendorListParam(params));
    const response = await brandOperationApi.fetchVendorList(vendorListParam);
    return response;
  },
);
export const addIWApplication = createAsyncThunk<string, AddIWApplicationParams>(
  "intoWarehouseBrandSlice/addIWApplication",
  async (payload, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await brandOperationApi.addIWApplication(payload);
      message.success("新增成功");
      thunkApi.dispatch(fetchIntoWarehouseList());
      return "Success";
    } catch (error: any) {
      message.error(error.message || "新增失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const copyIWApplication = createAsyncThunk(
  "intoWarehouseBrandSlice/copyIWApplication",
  async (applicationId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await brandOperationApi.copyIWApplication(applicationId);
      message.success("複製成功");
      thunkApi.dispatch(fetchIntoWarehouseList());
      return "Success";
    } catch (error: any) {
      message.error(error.message || "複製失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const invalidIWApplication = createAsyncThunk(
  "intoWarehouseBrandSlice/invalidIWApplication",
  async (applicationId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await brandOperationApi.invalidIWApplication(applicationId);
      message.success("作廢成功");
      thunkApi.dispatch(fetchIntoWarehouseList());
      return "Success";
    } catch (error: any) {
      message.error(error.message || "作廢失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const fetchIWApplicationDetails = createAsyncThunk(
  "intoWarehouseBrandSlice/fetchIWApplicationDetails",
  async (applicationId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const response = await brandOperationApi.fetchIWApplicationDetails(applicationId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const EditIWApplication = createAsyncThunk(
  "intoWarehouseBrandSlice/EditIWApplication",
  async (payload: { applicationId: number; supplyChain: number; specDate: string }, thunkApi) => {
    try {
      const { applicationId, supplyChain, specDate } = payload;
      await brandOperationApi.editIWApplication(applicationId, { supplyChain, specDate });
      message.success("編輯完成");
      thunkApi.dispatch(fetchIWApplicationDetails(applicationId));
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      thunkApi.dispatch(clearBrandVpc());
      return "Success";
    } catch (error: any) {
      message.error(error.message || "編輯失敗");
      return thunkApi.rejectWithValue(error.message);
    }
  },
);
export const fetchIWAppProductList = createAsyncThunk(
  "intoWarehouseBrandSlice/fetchIWAppProductList",
  async (applicationId: number, thunkApi) => {
    try {
      const {
        intoWarehouseBrandSlice: { productListParams },
      } = thunkApi.getState() as RootState;
      const { limit, offset } = productListParams;
      const response = await brandOperationApi.fetchIWAppProductList(applicationId, limit, offset);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchBrandVpc = createAsyncThunk(
  "intoWarehouseBrandSlice/fetchBrandVpc",
  async (payload: { applicationId: number; no: string }, thunkApi) => {
    try {
      const { applicationId, no } = payload;
      const response = await brandOperationApi.fetchBrandVpc(applicationId, no);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.response.data as any);
    }
  },
);

export const addIWapplicationProduct = createAsyncThunk<string, IWDetailsProductItem>(
  "intoWarehouseBrandSlice/addIWapplicationProduct",
  async (payload: IWDetailsProductItem, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { applicationId, ...items } = payload;
      if (!applicationId) throw Error("no applicationId");

      await brandOperationApi.addIWapplicationProduct(applicationId, items);
      message.success("新增成功");
      thunkApi.dispatch(clearBrandVpc());
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      return "Success";
    } catch (error: any) {
      message.error(error.message || "新增失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const editIWapplicationProduct = createAsyncThunk<string, IWDetailsProductItem>(
  "intoWarehouseBrandSlice/editIWapplicationProduct",
  async (payload: IWDetailsProductItem, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { applicationId, ...items } = payload;
      if (!applicationId) throw Error("no applicationId");

      await brandOperationApi.editIWapplicationProduct(applicationId, items);
      message.success("編輯成功");
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      return "Success";
    } catch (error: any) {
      message.error(error.message || "編輯失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const importIWappsProductsXLS = createAsyncThunk(
  "intoWarehouseBrandSlice/importIWappsProductsXLS",
  async (payload: { applicationId: number; file: File }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { applicationId, file } = payload;
      const response = await brandOperationApi.importIWappsProductsXLS(applicationId!, file);
      message.success("匯入成功");
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const deleteIWappsProducts = createAsyncThunk(
  "intoWarehouseBrandSlice/deleteIWappsProducts",
  async (payload: { applicationId: number; productId: number }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { applicationId, productId } = payload;
      await brandOperationApi.deleteIWappsProducts(applicationId, productId);
      message.success("刪除成功");
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      return "Success";
    } catch (error: any) {
      message.error(error.message || "刪除失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);
export const uploadWMSystem = createAsyncThunk(
  "intoWarehouseBrandSlice/uploadWMSystem",
  async (applicationId: number, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      await brandOperationApi.uploadWMSystem(applicationId);
      message.success("上傳成功");
      thunkApi.dispatch(fetchIWApplicationDetails(applicationId));
      thunkApi.dispatch(fetchIWAppProductList(applicationId));
      return "Success";
    } catch (error: any) {
      message.error(error.message || "上傳失敗");
      return thunkApi.rejectWithValue(error.message);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

const intoWarehouseBrandSlice = createSlice({
  name: "intoWarehouseBrandSlice",
  initialState,
  reducers: {
    resetIntoWarehouseBrandSlice: () => initialState,
    updateIntoWarehouseParams: (state, action) => {
      state.intoWarehouseParams = action.payload;
    },
    resetIntoWarehouseParams: (state) => {
      state.intoWarehouseParams = initialState.intoWarehouseParams;
    },
    updateVendorListParam: (state, action) => {
      state.vendorListParam = action.payload;
    },
    setEditModal: (state, action) => {
      state.editModal = action.payload;
    },
    clearBrandVpc: (state) => {
      state.brandVpc = undefined;
      state.brandVpcIsContract = true;
    },
    setImportFilePopup: (state, action) => {
      state.showImportFilePopup = action.payload;
    },
    clearCsvErrorReason: (state) => {
      state.csvErrorReason = initialState.csvErrorReason;
    },
    updateProductListParams: (state, action) => {
      state.productListParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchIntoWarehouseList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchIntoWarehouseList.fulfilled, (state, action) => {
      state.intoWarehouseList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchIntoWarehouseList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorListResult = action.payload;
    });
    builder.addCase(loadMoreVendorList.fulfilled, (state, action) => {
      state.vendorListResult = {
        ...action.payload,
        results: state.vendorListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchIWApplicationDetails.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchIWApplicationDetails.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchIWApplicationDetails.fulfilled, (state, action) => {
      state.intoWarehouseDetails = action.payload;
      state.isFetching = false;
    });
    builder.addCase(EditIWApplication.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(EditIWApplication.fulfilled, (state, action) => {
      state.isFetching = false;
      state.editModal = false;
    });
    builder.addCase(EditIWApplication.rejected, (state, action) => {
      state.isFetching = false;
      state.editModal = false;
    });
    builder.addCase(fetchIWAppProductList.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(fetchIWAppProductList.rejected, (state, action) => {
      state.isFetching = false;
    });
    builder.addCase(fetchIWAppProductList.fulfilled, (state, action) => {
      state.productList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchBrandVpc.fulfilled, (state, action) => {
      state.brandVpcIsContract = true;
      state.brandVpc = action.payload;
    });
    builder.addCase(fetchBrandVpc.rejected, (state) => {
      state.brandVpc = undefined;
      state.brandVpcIsContract = false;
    });
    builder.addCase(importIWappsProductsXLS.fulfilled, (state, action) => {
      state.showImportFilePopup = false;
    });
    builder.addCase(importIWappsProductsXLS.rejected, (state, action) => {
      state.csvErrorMessage = "匯入失敗，請重新上傳" || action.error.message;
      state.csvErrorReason = action.payload as UploadErrorReason;
    });
  },
});
export const IWBrandState = (state: RootState) => state.intoWarehouseBrandSlice;

export const {
  resetIntoWarehouseBrandSlice,
  updateIntoWarehouseParams,
  resetIntoWarehouseParams,
  updateVendorListParam,
  setEditModal,
  clearBrandVpc,
  setImportFilePopup,
  clearCsvErrorReason,
  updateProductListParams,
} = intoWarehouseBrandSlice.actions;
export default intoWarehouseBrandSlice.reducer;
