import { RootState } from "@redux/rootReducer";
import {
  clearPurchaseListFilter,
  setPurchaseListFilter,
  fetchVendorList,
  loadMoreVendorList,
} from "@redux/warehouseSlice";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import React, { useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function CustomsClearanceFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { staffListResult, purchaseListFilter, vendorListResult } = useSelector(
    (state: RootState) => state.warehouseSlice,
  );

  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchVendorList(""));
  }, [dispatch]);

  const handleOnVendorLoadMore = useCallback(() => {
    dispatch(loadMoreVendorList());
  }, [dispatch]);

  const vendorOptions = useMemo(() => {
    const { next, results } = vendorListResult;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id}>
        {vdr.name}
      </Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnVendorLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [vendorListResult, handleOnVendorLoadMore]);

  const handleOnVendorSearch = useDebounce((value: string) => {
    dispatch(fetchVendorList(value));
  }, 300);

  const onReset = () => {
    form.resetFields();
    dispatch(clearPurchaseListFilter());
  };

  const onSubmit = (values: any) => {
    const { specDate, ...restValues } = values;
    dispatch(
      setPurchaseListFilter({
        ...purchaseListFilter,
        ...restValues,
        specDateBefore: specDate && specDate[1]?.format("YYYY-MM-DD"),
        specDateAfter: specDate && specDate[0]?.format("YYYY-MM-DD"),
        offset: 0,
      }),
    );
  };

  return (
    <Wrapper
      colon={false}
      form={form}
      isVisible={isVisible}
      initialValues={{
        showDestroy: false,
        noStockin: false,
        stoNumber: undefined,
        vendor: undefined,
        owner: undefined,
        sku: undefined,
      }}
      onFinish={onSubmit}
    >
      <Row gutter={[8, 0]}>
        <Col>
          <Form.Item name="showDestroy" valuePropName="checked">
            <Checkbox>顯示作廢進倉單</Checkbox>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="noStockin" valuePropName="checked">
            <Checkbox>顯示未有實際進倉日的進倉單</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={6}>
          <Form.Item label="LMS編號" name="stoNumber">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="廠商名稱" name="vendor">
            <Select showSearch filterOption={false} onSearch={handleOnVendorSearch} placeholder="請選擇" allowClear>
              {vendorOptions}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="商開負責人" name="owner">
            <Select placeholder="請選擇" allowClear>
              {staffListResult.results.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col span={12}>
          <Form.Item label="預期進倉日期" name="specDate">
            <DatePicker.RangePicker locale={locale} />
          </Form.Item>
        </Col>
        <div>
          <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
          <Button type="primary" htmlType="submit">
            套用
          </Button>
        </div>
      </Row>
    </Wrapper>
  );
}
