/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useCallback, useMemo, ChangeEvent, useEffect } from "react";
import styled from "styled-components";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { attributeState, fetchTagAttributeList, fetchTagAttributeLoadMore } from "@redux/attributeSlice";
import getCategoryBreadcrumb from "@page/TopicPage/utils/getCategoryBreadcrumb";
import generateCategoryTree, { SalesCategory } from "@page/TopicPage/utils/generateCategoryTree";
import { fetchSalesCategoryList } from "@redux/promotionSlice";
import Form, { FormInstance } from "antd/lib/form";
import useDebounce from "@hooks/useDebounce";
import { Button, Col, Input, Row, Select, Space } from "antd";
import { useAppDispatch } from "src/store";
import { fetchBrandList, loadMoreBrandList } from "@redux/promotionAddSalesSlice";
import { RootState } from "@redux/rootReducer";
import { useSelector } from "react-redux";
import { resetSalePlanList } from "@redux/discountFrameSlice";
import CategoriesModal from "./CategoriesModal";

const CategoriesInputContainer = styled.div`
  position: relative;
`;
const ModalMask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
`;
type FilterProps = {
  form: FormInstance;
  onSubmit: (values: any) => void;
  onReset: () => void;
};

const AddSaleFilter = (props: FilterProps) => {
  const { form, onSubmit, onReset } = props;
  const dispatch = useAppDispatch();
  const { isFetchingBrand, brandListResult } = useSelector((state: RootState) => state.promotionAddSales);
  const { tagAttributeListResult, isFetching: isAttributeFetching } = useSelector(attributeState);
  // ---- 分類 ----
  const { salesCategories } = useSelector((state: RootState) => state.promotion);
  const [openCategoriesModal, setOpenCategoriesModal] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [level1, setLevel1] = useState<Map<number, SalesCategory>>(new Map());
  const [level2, setLevel2] = useState<Map<number, SalesCategory>>(new Map());
  const [level3, setLevel3] = useState<Map<number, SalesCategory>>(new Map());
  const [tree, setTree] = useState<Map<number, SalesCategory>>(new Map());
  const [currentCategoryTag, setCurrentCategoryTag] = useState<string>();

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const params = {
      ...formValues,
      pageId: formValues.pageId ? formValues.pageId : undefined,
      pageNameQ: formValues.pageNameQ ? formValues.pageNameQ.trim() : undefined,
      brandNamesQ: formValues.brandNamesQ?.length > 0 ? formValues.brandNamesQ : undefined,
      skus: formValues.skus?.length > 0 ? formValues.skus : undefined,
      promotionId: formValues.promotionId ? Number(formValues.promotionId) : undefined,
    };
    onSubmit(params);
  };
  const handleOnResetParams = () => {
    form.resetFields();
    onReset();
  };
  // ---- 品牌 ----
  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brand) => (
      <Select.Option key={brand.name} value={brand.name}>
        {brand.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  // ---- 屬性標籤 ----
  const handleTagAttributeOnSearch = useDebounce((value: string) => {
    dispatch(fetchTagAttributeList({ tagNameQ: value, limit: 20, offset: 0 }));
  }, 300);

  const handleOnTagAttributeLoadMore = useCallback(() => {
    dispatch(fetchTagAttributeLoadMore());
  }, [dispatch]);

  const tagAttributeOptions = useMemo(() => {
    const { next, results } = tagAttributeListResult;
    const options = results.map((attribute) => (
      <Select.Option key={attribute.name} value={attribute.id}>
        {attribute.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnTagAttributeLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [handleOnTagAttributeLoadMore, tagAttributeListResult]);

  // ---- 分類 ----
  const handelCategoryModalSubmit = (categoryIds: number[]) => {
    form.setFieldsValue({ categoryIds });
    const tags = getCategoryBreadcrumb(level1, level2, level3, categoryIds);
    setCurrentCategoryTag(tags);
  };
  const handleOnCategoriesSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchText(value);
  };
  const handleOnCategoriesFocus = () => {
    setOpenCategoriesModal(true);
  };
  const closeCategoriesModal = () => setOpenCategoriesModal(false);

  useEffect(() => {
    dispatch(fetchBrandList(""));
    dispatch(fetchTagAttributeList({ limit: 20, offset: 0 }));
    dispatch(fetchSalesCategoryList());
    return () => {
      dispatch(resetSalePlanList());
    };
  }, [dispatch]);

  useEffect(() => {
    if (salesCategories) {
      const { treeMap, level1Map, level2Map, level3Map } = generateCategoryTree(salesCategories);
      setTree(treeMap);
      setLevel1(level1Map);
      setLevel2(level2Map);
      setLevel3(level3Map);
    }
  }, [salesCategories]);

  return (
    <Form
      form={form}
      colon={false}
      labelAlign="left"
      labelCol={{ span: 5 }}
      onFinish={handleOnSubmit}
      style={{ marginTop: "10px" }}
    >
      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item label="銷售頁ID" name="pageId">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="銷售頁名稱" name="pageNameQ">
            <Input allowClear />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item label="活動ID" name="promotionId">
            <Input allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="品牌" name="brandNamesQ">
            <Select
              mode="multiple"
              allowClear
              filterOption={false}
              onSearch={handleOnBrandSearch}
              loading={isFetchingBrand}
            >
              {brandOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]}>
        <Col span={12}>
          <Form.Item label="SKU" name="skus">
            <Select mode="tags" placeholder="可用Enter加入多筆" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="屬性標籤" name="tagIds">
            <Select
              mode="multiple"
              allowClear
              filterOption={false}
              onSearch={handleTagAttributeOnSearch}
              loading={isAttributeFetching}
            >
              {tagAttributeOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[12, 0]} justify="space-between">
        <Col span={12}>
          <Form.Item name="categoryIds" label="分類">
            <CategoriesInputContainer>
              {openCategoriesModal ? (
                <Input value={searchText} onChange={handleOnCategoriesSearch} allowClear />
              ) : (
                <Input value={currentCategoryTag} onFocus={handleOnCategoriesFocus} allowClear />
              )}
              {openCategoriesModal && (
                <>
                  <ModalMask onClick={closeCategoriesModal} />
                  <CategoriesModal
                    tree={tree}
                    level1={level1}
                    level2={level2}
                    level3={level3}
                    searchText={searchText}
                    categoryIds={form.getFieldValue("categoryIds")}
                    setLocalFormFilter={handelCategoryModalSubmit}
                    onClose={closeCategoriesModal}
                  />
                </>
              )}
            </CategoriesInputContainer>
          </Form.Item>
        </Col>
        <Col>
          <Space>
            <Button onClick={handleOnResetParams}>清除篩選條件</Button>
            <Button type="primary" htmlType="submit">
              搜 尋
            </Button>
          </Space>
        </Col>
      </Row>
    </Form>
  );
};

export default AddSaleFilter;
