/* eslint-disable camelcase */
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

export interface FetchTagAttributeFilter {
  nameQ?: string;
  tagNameQ?: string;
  limit: number;
  offset: number;
}
export interface TagAttributeList {
  id: number;
  name: string;
  tagsName: string[];
}
export interface TagAttributeListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: TagAttributeList[];
}
export interface AddTagAttributePayload {
  name: string;
  tagsName: string[];
}
export interface ProductCategoryTagList {
  id: number;
  path: string;
}
export interface ProductCategoryTagListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ProductCategoryTagList[];
}

interface AttributeApi {
  fetchTagAttributeList: (params: FetchTagAttributeFilter) => Promise<TagAttributeListResult>;
  fetchTagAttributeInfo: (attributeId: number) => Promise<TagAttributeList>;
  fetchAddTagAttribute: (payload: AddTagAttributePayload) => Promise<number>;
  fetchUpdateTagAttribute: (attributeId: number, payload: AddTagAttributePayload) => Promise<void>;
  fetchDeleteTagAttribute: (attributeId: number) => Promise<void>;
  fetchProductCategoryTagList: (tagTypeId: number) => Promise<ProductCategoryTagListResult>;
  fetchAddProductCategoryTag: (productCategoryId: number, tagTypeId: number) => Promise<void>;
  fetchDeleteProductCategoryTag: (productCategoryId: number, tagTypeId: number) => Promise<void>;
}

const apiClient = new CoreAPI();

const attributeApi: AttributeApi = {
  fetchTagAttributeList: async (params) => {
    const response = await apiClient.getData("/manage/tag/tag-attribute/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchTagAttributeInfo: async (attributeId) => {
    const response = await apiClient.getData(`/manage/tag/tag-attribute/${attributeId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddTagAttribute: async (payload) => {
    const response = await apiClient.postData(
      "/manage/tag/tag-attribute/",
      transformCamelToSnake({ ...payload, tagsName: payload.tagsName.toString() }),
    );
    return response.data.result.id;
  },
  fetchUpdateTagAttribute: async (attributeId, payload) => {
    await apiClient.patchData(
      `/manage/tag/tag-attribute/${attributeId}/`,
      transformCamelToSnake({ ...payload, tagsName: payload.tagsName.toString() }),
    );
  },
  fetchDeleteTagAttribute: async (attributeId) => {
    await apiClient.deleteData(`/manage/tag/tag-attribute/${attributeId}/`, {});
  },
  fetchProductCategoryTagList: async (tagTypeId) => {
    const response = await apiClient.getData("/manage/product/product-category-tag-types/", {
      tag_type_id: tagTypeId,
    });
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchAddProductCategoryTag: async (productCategoryId, tagTypeId) => {
    await apiClient.postData(`/manage/product/product-category-tag-types/${productCategoryId}/add-tag-type/`, {
      tag_type_id: tagTypeId,
    });
  },
  fetchDeleteProductCategoryTag: async (productCategoryId, tagTypeId) => {
    await apiClient.putData(`/manage/product/product-category-tag-types/${productCategoryId}/remove-tag-type/`, {
      tag_type_id: tagTypeId,
    });
  },
};

export default attributeApi;
