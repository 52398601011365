import productApi, {
  ContractListParam,
  ContractListResult,
  CreateProductDetailParam,
  CreateProductVendorParam,
  FetchProductStockListParam,
  FetchProductVendorListParam,
  FetchSaleMethodListParam,
  FetchVendorListParam,
  ProductCategory,
  ProductDetail,
  ProductStockListResult,
  ProductVendorDetail,
  ProductVendorListItem,
  ProductVendorListResult,
  SaleMethodListResult,
  UpdateProductDetailParam,
  UpdateProductVendorParam,
  VendorListResult,
} from "@api/productApi";
import productCategoryApi, { ProductCategoryDetail } from "@api/productCategoryApi";
import staffApi, { FetchStaffListParam, StaffListResult } from "@api/staffApi";
import tagApi, { FetchTagListParam, TagListResult } from "@api/tagApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { hideLoading, showLoading } from "./notifySlice";

interface IState {
  isFetching: boolean;
  /* 基本資訊 */
  productDetail: ProductDetail;
  brandListResult: TagListResult;
  brandListParam: FetchTagListParam;
  staffListResult: StaffListResult;
  staffListParam: FetchStaffListParam;
  /* 品類屬性 */
  productCategoryList: ProductCategory;
  productCategoryDetail: ProductCategoryDetail;
  /* 合作方式 */
  vendorListResult: VendorListResult;
  vendorListParam: FetchVendorListParam;
  isFetchingProductVendorList: boolean;
  productVendorListResult: ProductVendorListResult;
  productVendorListParam: FetchProductVendorListParam;
  selectedProductVendorIds: number[];
  contractListResult: ContractListResult;
  contractListParam: ContractListParam;
  productVendorEditPopup: ProductVendorEditPopupStatus;
  collabAffectedItems: any[];
  /* 銷售方式 */
  productVendorDetail: ProductVendorDetail;
  saleMethodListResult: SaleMethodListResult;
  saleMethodListParam: FetchSaleMethodListParam;
  isFetchingSaleMethodList: boolean;
  /* 效期庫存 */
  productStockListResult: ProductStockListResult;
  productStockListParam: FetchProductStockListParam;
  isFetchingProductStockList: boolean;
}

interface ProductVendorEditPopupStatus {
  show: boolean;
  mode: "add" | "edit";
  productVendorListItem?: ProductVendorListItem;
}

export const initialState: IState = {
  isFetching: false,
  productDetail: {
    sku: "",
    name: "",
    owner: {
      id: -1,
      name: "",
    },
    brand: {
      id: -1,
      name: "",
    },
    isActive: true,
    syncStockOption: 1,
    isSyncWarehouse: false,
    categories: [],
    tags: [],
    length: 0,
    weight: 0,
    width: 0,
    height: 0,
  },
  brandListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  brandListParam: {
    typeNameQ: "品牌",
    nameQ: "",
    limit: 20,
    offset: 0,
  },
  staffListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  staffListParam: {
    nameQ: "",
    limit: 50,
    offset: 0,
    staffGroupPermission: "",
  },
  productCategoryList: {
    level1: {},
    level2: {},
    level3: {},
  },
  productCategoryDetail: {
    id: -1,
    name: "",
    tagTypes: [],
    tagTypesAttributes: {},
  },
  vendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  vendorListParam: {
    nameQ: "",
    limit: 50,
    offset: 0,
  },
  isFetchingProductVendorList: false,
  productVendorListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  productVendorListParam: {
    limit: 20,
    offset: 0,
  },
  selectedProductVendorIds: [],
  contractListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  contractListParam: {
    limit: 50,
    offset: 0,
  },
  productVendorEditPopup: {
    show: false,
    mode: "add",
  },
  collabAffectedItems: [],
  productVendorDetail: {
    commissionRate: 0,
    contract: 0,
    cost: 0,
    deliveryDays: 0,
    endDate: "",
    id: 0,
    mode: 0,
    modeName: "",
    pcsPerCarton: 0,
    price: 0,
    product: {
      brand: {
        id: 0,
        name: "",
      },
      canSellDays: 0,
      canSalesQty: 0,
      canAcceptDays: 0,
      categories: [],
      height: 0,
      holdStockQty: 0,
      id: 0,
      isActive: false,
      isSyncWarehouse: false,
      length: 0,
      name: "",
      owner: {
        id: 0,
        name: "",
      },
      retentionStockQty: 0,
      shelfLife: 0,
      sku: "",
      stockQty: 0,
      syncStockOption: 0,
      tags: [],
      weight: 0,
      weightedCost: 0,
      width: 0,
    },
    startDate: "",
    vendorInfo: {
      id: 0,
      name: "",
      phone: "",
      contactName: "",
    },
    vpcId: 0,
  },
  saleMethodListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  saleMethodListParam: {
    limit: 100,
    offset: 0,
  },
  isFetchingSaleMethodList: false,
  productStockListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  productStockListParam: {
    limit: 20,
    offset: 0,
  },
  isFetchingProductStockList: false,
};

export const fetchProductDetail = createAsyncThunk("productEdit/fetchProductDetail", async (id: number) => {
  const response = await productApi.fetchProductDetail(id);
  return response;
});

export const fetchProductCategoryList = createAsyncThunk("productEdit/fetchProductCategoryList", async () => {
  const response = await productApi.fetchProductCategoryList();
  return response;
});

export const fetchBrandList = createAsyncThunk("productEdit/fetchBrandList", async (name: string, thunkApi) => {
  const params: FetchTagListParam = {
    typeNameQ: "品牌",
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const loadMoreBrandList = createAsyncThunk("productEdit/loadMoreBrandList", async (_, thunkApi) => {
  const {
    productEdit: { brandListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchTagListParam = {
    ...brandListParam,
    offset: brandListParam.offset + brandListParam.limit,
  };

  thunkApi.dispatch(updateBrandListParam(params));
  const response = await tagApi.fetchTagList(params);
  return response;
});

export const fetchStaffList = createAsyncThunk("productEdit/fetchStaffList", async (name: string, thunkApi) => {
  const params: FetchStaffListParam = {
    nameQ: name,
    limit: 20,
    offset: 0,
    staffGroupPermission: "product_owner",
  };

  thunkApi.dispatch(updateStaffListParam(params));
  const response = await staffApi.fetchStaffList(params);
  return response;
});

export const loadMoreStaffList = createAsyncThunk("productEdit/loadMoreStaffList", async (_, thunkApi) => {
  const {
    productEdit: { staffListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchStaffListParam = {
    ...staffListParam,
    offset: staffListParam.offset + staffListParam.limit,
  };

  thunkApi.dispatch(updateStaffListParam(params));
  const response = await staffApi.fetchStaffList(params);
  return response;
});

export const createProduct = createAsyncThunk("productEdit/createProduct", async (params: CreateProductDetailParam) => {
  const response = await productApi.createProduct(params);
  message.success("已儲存");
  return response;
});

export const updateProduct = createAsyncThunk(
  "productEdit/updateProduct",
  async (arg: { id: number; params: UpdateProductDetailParam }, thunkApi) => {
    const { id, params } = arg;
    await productApi.updateProduct(id, params);
    thunkApi.dispatch(fetchProductDetail(id));
    message.success("已儲存");
    return "Success";
  },
);

export const syncToWarehouse = createAsyncThunk("productEdit/syncToWarehouse", async (id: number, thunkdApi) => {
  const response = await productApi.syncToWarehouse(id);
  thunkdApi.dispatch(fetchProductDetail(id));
  message.success("更新成功");
  return response;
});

export const fetchCategoryDetail = createAsyncThunk("productEdit/fetchCategoryDetail", async (categoryId: number) => {
  const response = await productCategoryApi.getCategoryDetail(categoryId);
  return response;
});

export const fetchVendorList = createAsyncThunk("productEdit/fetchVendorList", async (name: string, thunkApi) => {
  const params: FetchVendorListParam = {
    nameQ: name,
    limit: 20,
    offset: 0,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const loadMoreVendorList = createAsyncThunk("productEdit/loadMoreVendorList", async (_, thunkApi) => {
  const {
    product: { vendorListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchVendorListParam = {
    ...vendorListParam,
    offset: vendorListParam.offset + vendorListParam.limit,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchVendorList(params);
  return response;
});

export const fetchProductVendorList = createAsyncThunk("productEdit/fetchProductVendorList", async (_, thunkApi) => {
  const {
    productEdit: { productVendorListParam, productDetail },
  } = thunkApi.getState() as RootState;

  const params = {
    ...productVendorListParam,
    productId: productDetail.id,
    withDeduction: true,
  };
  const response = await productApi.fetchProductVendorList(params);
  return response;
});

export const fetchContractList = createAsyncThunk("productEdit/fetchContractList", async (_, thunkApi) => {
  const {
    productEdit: { contractListParam },
  } = thunkApi.getState() as RootState;

  const response = await productApi.fetchContractList(contractListParam);
  return response;
});

export const loadMoreContractList = createAsyncThunk("productEdit/loadMoreContractList", async (_, thunkApi) => {
  const {
    productEdit: { contractListParam },
  } = thunkApi.getState() as RootState;

  const params: FetchVendorListParam = {
    ...contractListParam,
    offset: contractListParam.offset + contractListParam.limit,
  };

  thunkApi.dispatch(updateVendorListParam(params));
  const response = await productApi.fetchContractList(params);
  return response;
});

export const createProductVendor = createAsyncThunk(
  "productEdit/createProductVendor",
  async (params: CreateProductVendorParam, thunkApi) => {
    try {
      const response = await productApi.createProductVendor(params);

      thunkApi.dispatch(
        updateProductVendorEditPopup({
          show: false,
          mode: "add",
        }),
      );
      thunkApi.dispatch(fetchProductVendorList());

      return response;
    } catch (error: any) {
      return error;
    }
  },
);

export const updateProductVendor = createAsyncThunk(
  "productEdit/updateProductVendor",
  async (args: { vendorListItemId: number; params: UpdateProductVendorParam }, thunkApi) => {
    try {
      thunkApi.dispatch(showLoading());
      const { vendorListItemId, params } = args;
      const response = await productApi.updateProductVendor(vendorListItemId, params);

      thunkApi.dispatch(
        updateProductVendorEditPopup({
          show: false,
          mode: "add",
        }),
      );
      thunkApi.dispatch(fetchProductVendorList());

      return response;
    } catch (error: any) {
      return error;
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const batchRemoveProductVendors = createAsyncThunk(
  "productEdit/batchRemoveProductVendors",
  async (_, thunkApi) => {
    const {
      productEdit: { selectedProductVendorIds },
    } = thunkApi.getState() as RootState;

    const response = await productApi.batchRemoveProductVendors(selectedProductVendorIds);
    thunkApi.dispatch(fetchProductVendorList());
    return response;
  },
);

export const fetchProductVendorDetail = createAsyncThunk<ProductVendorDetail, number>(
  "productEdit/fetchProductVendorDetail",
  async (productId) => {
    const response = await productApi.fetchProductVendorDetail(productId);
    return response;
  },
);

export const fetchSaleMethodList = createAsyncThunk(
  "productEdit/fetchSaleMethodList",
  async (productId: number, thunkApi) => {
    const {
      productEdit: { saleMethodListParam },
    } = thunkApi.getState() as RootState;

    const response = await productApi.fetchSaleMethodList(productId, saleMethodListParam);
    return response;
  },
);

export const fetchProductStockList = createAsyncThunk(
  "productEdit/fetchProductStockList",
  async (productId: number, thunkApi) => {
    const {
      productEdit: { productStockListParam },
    } = thunkApi.getState() as RootState;

    const response = await productApi.fetchProductStockList(productId, productStockListParam);
    return response;
  },
);

const productEditSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    reset: () => initialState,
    updateBrandListParam: (state, action: PayloadAction<FetchTagListParam>) => {
      state.brandListParam = action.payload;
    },
    updateStaffListParam: (state, action: PayloadAction<FetchStaffListParam>) => {
      state.staffListParam = action.payload;
    },
    updateVendorListParam: (state, action: PayloadAction<FetchVendorListParam>) => {
      state.vendorListParam = action.payload;
    },
    updateProductVendorListParam: (state, action: PayloadAction<FetchProductVendorListParam>) => {
      state.productVendorListParam = action.payload;
    },
    updateProductVendorEditPopup: (state, action: PayloadAction<ProductVendorEditPopupStatus>) => {
      state.productVendorEditPopup = action.payload;
    },
    updateSaleMethodListParam: (state, action: PayloadAction<FetchSaleMethodListParam>) => {
      state.saleMethodListParam = action.payload;
    },
    updateProductStockListParam: (state, action: PayloadAction<FetchProductStockListParam>) => {
      state.productStockListParam = action.payload;
    },
    updateContractListParam: (state, action: PayloadAction<ContractListParam>) => {
      state.contractListParam = action.payload;
    },
    updateSelectedProductVendorIds: (state, action: PayloadAction<number[]>) => {
      state.selectedProductVendorIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductDetail.fulfilled, (state, action) => {
      state.isFetching = false;
      state.productDetail = action.payload;
    });
    builder.addCase(fetchProductCategoryList.fulfilled, (state, action) => {
      state.productCategoryList = action.payload;
    });
    builder.addCase(fetchBrandList.fulfilled, (state, action) => {
      state.brandListResult = action.payload;
    });
    builder.addCase(loadMoreBrandList.fulfilled, (state, action) => {
      state.brandListResult = {
        ...action.payload,
        results: state.brandListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchVendorList.fulfilled, (state, action) => {
      state.vendorListResult = action.payload;
    });
    builder.addCase(loadMoreVendorList.fulfilled, (state, action) => {
      state.vendorListResult = {
        ...action.payload,
        results: state.vendorListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(fetchStaffList.fulfilled, (state, action) => {
      state.staffListResult = action.payload;
    });
    builder.addCase(loadMoreStaffList.fulfilled, (state, action) => {
      state.staffListResult = {
        ...action.payload,
        results: state.staffListResult.results.concat(action.payload.results),
      };
    });
    builder.addCase(createProduct.pending, (state, action) => {
      state.isFetching = true;
    });
    builder.addCase(createProduct.fulfilled, (state, action) => {
      state.productDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(updateProduct.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateProduct.fulfilled, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCategoryDetail.fulfilled, (state, action) => {
      state.productCategoryDetail = action.payload;
    });
    builder.addCase(fetchProductVendorList.pending, (state) => {
      state.isFetchingProductVendorList = true;
    });
    builder.addCase(fetchProductVendorList.fulfilled, (state, action) => {
      state.productVendorListResult = action.payload;
      state.isFetchingProductVendorList = false;
    });
    builder.addCase(fetchContractList.fulfilled, (state, action) => {
      state.contractListResult = action.payload;
    });
    builder.addCase(fetchSaleMethodList.pending, (state, action) => {
      state.isFetchingSaleMethodList = true;
    });
    builder.addCase(fetchSaleMethodList.fulfilled, (state, action) => {
      state.isFetchingSaleMethodList = false;
      state.saleMethodListResult = action.payload;
    });
    builder.addCase(fetchProductVendorDetail.fulfilled, (state, action) => {
      state.productVendorDetail = action.payload;
    });
    builder.addCase(fetchProductStockList.pending, (state, action) => {
      state.isFetchingProductStockList = true;
    });
    builder.addCase(fetchProductStockList.fulfilled, (state, action) => {
      state.isFetchingProductStockList = false;
      state.productStockListResult = action.payload;
    });
    builder.addCase(loadMoreContractList.fulfilled, (state, action) => {
      state.contractListResult = {
        ...action.payload,
        results: state.contractListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const {
  reset,
  updateBrandListParam,
  updateStaffListParam,
  updateVendorListParam,
  updateProductVendorListParam,
  updateProductVendorEditPopup,
  updateSaleMethodListParam,
  updateProductStockListParam,
  updateContractListParam,
  updateSelectedProductVendorIds,
} = productEditSlice.actions;
export default productEditSlice.reducer;
