import styled from "styled-components";

export const ColText = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  height: 32px;
`;

export const Required = styled.span`
  font-size: 14px;
  color: #fc4e03;
`;

/**
 * Because left menu z-index is set to 1,
 * so we can directly set fixed footer as width 100%
 */
export const FixedFooter = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding-right: 20px;

  // overwrite ant design style
  .ant-form-item {
    margin-bottom: 0;
  }
`;
