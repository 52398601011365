import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import { useAppDispatch } from "src/store";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { fetchProductInfo, fetchAddProduct, fetchUpdateProduct } from "@redux/brandOperation/productSlice";
import { useNavigate, useParams } from "react-router-dom";

const Wrapper = styled(Form)`
  padding: 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: none;
  }
`;
const Footer = styled.div`
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 40px;
  position: fixed;
  bottom: 0;
  left: -16px;
  width: calc(100% + 31px);

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const Text = styled.div`
  margin-bottom: 30px;
`;

const GrayArea = styled.div`
  padding: 5px;
  border-radius: 2px;
  width: 175px;
  height: 32px;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
`;
const StyledSelect = styled(Select)`
  text-align: left;
  width: 100%;
`;

type Props = {
  mode: "add" | "edit";
};
export enum ProductType {
  NORMAL = 1,
  COMBINATION = 2,
  PACKAGE = 3,
}

const InfoTab = (props: Props) => {
  const { mode } = props;
  const dispatch = useAppDispatch();
  const { productId } = useParams();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { productInfo } = useSelector((state: RootState) => state.brandOperationProductSlice);

  const onSubmit = () => {
    const formValue = form.getFieldsValue();
    const updateValue = {
      ...formValue,
    };
    delete updateValue.no;
    if (mode === "edit") {
      dispatch(fetchUpdateProduct({ data: updateValue, productId: Number(productId) }));
    } else {
      dispatch(fetchAddProduct(formValue));
    }
  };

  useEffect(() => {
    if (mode === "edit") {
      dispatch(fetchProductInfo(productId));
    }
  }, [dispatch, mode, productId]);

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue(productInfo);
    }
  }, [form, mode, productInfo]);

  useEffect(() => {
    if (mode === "add" && productInfo.id > 0) {
      navigate(`/operation-of-brand/product/edit/${productInfo.id}`);
    }
  }, [dispatch, mode, navigate, productInfo.id]);

  return (
    <Wrapper form={form} initialValues={{}} colon={false} labelCol={{ span: 5 }} labelAlign="left" onFinish={onSubmit}>
      <Form.Item wrapperCol={{ span: 8 }} label="商品品號" name="no" rules={[{ required: true, message: "" }]}>
        <Input disabled={mode === "edit"} />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="商品條碼" name="sku">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="商品名稱" name="name" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="商品英文名稱" name="nameEn">
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="商品成本" name="cost" rules={[{ required: true, message: "" }]}>
        <Input disabled={mode === "edit"} />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="商品類型" name="type" rules={[{ required: true, message: "" }]}>
        <StyledSelect
          onChange={(value) => {
            form.setFieldsValue({ shelfLife: value === ProductType.PACKAGE ? 9999 : undefined });
          }}
        >
          <Select.Option value={ProductType.NORMAL}>一般商品</Select.Option>
          <Select.Option value={ProductType.COMBINATION}>組合商品</Select.Option>
          <Select.Option value={ProductType.PACKAGE}>包材/耗材</Select.Option>
        </StyledSelect>
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const type = getFieldValue("type");
          return (
            <Form.Item
              name="shelfLife"
              rules={[
                { required: true, message: "" },
                {
                  validator: (rule, value) => (value !== 0 ? Promise.resolve() : Promise.reject()),
                  message: "總效期不能為 0，請修正",
                },
              ]}
              wrapperCol={{ span: 8 }}
              label="總效期"
            >
              <InputNumber disabled={type === ProductType.PACKAGE} style={{ width: "100%" }} min={0} type="number" />
            </Form.Item>
          );
        }}
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="箱入數" name="pcsPerCarton" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Text>單一品項</Text>
      <Form.Item
        shouldUpdate
        wrapperCol={{ span: 8 }}
        label="重量(g)"
        name="weight"
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="長(cm)" name="length" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="寬(cm)" name="width" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="高(cm)" name="height" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Text>箱規</Text>
      <Form.Item wrapperCol={{ span: 8 }} label="重量(g)" name="cartonWeight" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="長(cm)" name="cartonLength" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="寬(cm)" name="cartonWidth" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <Form.Item wrapperCol={{ span: 8 }} label="高(cm)" name="cartonHeight" rules={[{ required: true, message: "" }]}>
        <Input />
      </Form.Item>
      <br />
      {/* 商品重量*箱入數 */}
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const totalNetWeight = Number(getFieldValue("weight")) * Number(getFieldValue("pcsPerCarton"));
          return (
            <Form.Item wrapperCol={{ span: 8 }} label="總淨重(g)" name="totalNetWeight">
              <GrayArea> {totalNetWeight || 0}</GrayArea>
            </Form.Item>
          );
        }}
      </Form.Item>
      {/* 商品重量*箱入數+箱重 */}
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const totalGrossWeight =
            Number(getFieldValue("weight")) * Number(getFieldValue("pcsPerCarton")) +
            Number(getFieldValue("cartonWeight"));

          return (
            <Form.Item wrapperCol={{ span: 8 }} label="總毛重(g)" name="totalGrossWeight">
              <GrayArea> {totalGrossWeight || 0}</GrayArea>
            </Form.Item>
          );
        }}
      </Form.Item>
      {/* 依照箱規長、寬、高計算材積 */}
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const totalVolume =
            Number(getFieldValue("cartonLength")) *
            Number(getFieldValue("cartonWidth")) *
            Number(getFieldValue("cartonHeight"));

          return (
            <Form.Item wrapperCol={{ span: 8 }} label="總材積" name="totalVolume">
              <GrayArea> {totalVolume || 0}</GrayArea>
            </Form.Item>
          );
        }}
      </Form.Item>
      <Footer>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {mode === "add" ? "建立資料" : "儲存此分頁"}
          </Button>
        </Form.Item>
      </Footer>
    </Wrapper>
  );
};

export default InfoTab;
