import PopupBackground from "@component/PopupBackground";
import { Button, Space } from "antd";
import Form, { FormInstance } from "antd/lib/form";
import React, { FC, useMemo } from "react";
import styled from "styled-components";

const PopWrapper = styled.div<{
  width?: number | string;
  height?: number | string;
  padding?: number | string;
}>`
  width: ${({ width }) => {
    if (typeof width === "string") return width;
    return width ? `${width}px` : "auto";
  }};
  height: ${({ height }) => {
    if (typeof height === "string") return height;
    return height ? `${height}px` : "auto";
  }};
  padding: ${({ padding }) => {
    if (typeof padding === "string") return padding;
    return padding ? `${padding}px` : "auto";
  }};
  background-color: white;
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 15px;
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type Props = {
  form: FormInstance;
  onClose: () => void;
  onAdd: () => void;
  onUpdate: () => void;
  initialValues?: any;
  width?: number | string;
  height?: number | string;
  padding?: number | string;
  style?: any;
  children: React.ReactNode;
  AddText?: string;
  updateText?: string;
  cancelText?: string;
  title?: string;
};
const PopupAddAndUpdateModal: FC<Props> = (props) => {
  const {
    form,
    onClose,
    onAdd,
    onUpdate,
    initialValues,
    width,
    height,
    padding,
    style,
    children,
    AddText,
    updateText,
    cancelText,
    title,
  } = props;

  const handleOnSubmit = () => {
    if (initialValues) {
      onUpdate();
    } else {
      onAdd();
    }
    onClose();
  };

  const OKButtonText = useMemo(() => {
    if (initialValues) {
      return updateText || "確定";
    }
    return AddText || "確定";
  }, [AddText, initialValues, updateText]);

  const cancelButtonText = useMemo(() => {
    return cancelText || "取消";
  }, [cancelText]);

  return (
    <PopupBackground close={onClose} fixed>
      <PopWrapper width={width} height={height} style={style} padding={padding}>
        {title && <Title>{title}</Title>}
        <Form initialValues={initialValues} form={form} onFinish={handleOnSubmit}>
          {children}
          <Footer>
            <Space>
              <Button onClick={onClose}>{cancelButtonText}</Button>
              <Button type="primary" htmlType="submit">
                {OKButtonText}
              </Button>
            </Space>
          </Footer>
        </Form>
      </PopWrapper>
    </PopupBackground>
  );
};

export default PopupAddAndUpdateModal;
