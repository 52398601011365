import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const OrderReturnCard: FC = () => {
  const isFetchingOrderReturnDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingOrderReturnDashboard,
  );
  const orderReturnDashboard = useSelector((state: RootState) => state.dashboard.orderReturnDashboard);
  const { currentData, diff } = orderReturnDashboard;
  const { count = 0, avgAmount = 0, rate = 0 } = currentData || {};
  const { countDiff = 0, avgAmountDiff = 0, rateDiff = 0 } = diff || {};

  const ratePercentage = Math.round(rate * 1000) / 10; // 百分比取小數點第一位
  const rateTagColor = rateDiff < 0 ? "error" : "success";
  const rateTagIcon = rateDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const rateChangePercentage = Math.round(rateDiff * 1000) / 10; // 百分比取小數點第一位

  const countTagColor = countDiff < 0 ? "error" : "success";
  const countTagIcon = countDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const countChangePercentage = Math.round(countDiff * 1000) / 10; // 百分比取小數點第一位

  const avgAmountCurrency = USCurrencyFormmater.format(avgAmount);
  const avgAmountTagColor = avgAmountDiff < 0 ? "error" : "success";
  const avgAmountTagIcon = avgAmountDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const avgAmountChangePercentage = Math.round(avgAmountDiff * 1000) / 10;

  return (
    <Spin spinning={isFetchingOrderReturnDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>退貨</HeaderTitle>
          <AnchorToChart href="/dashboard/return-product" />
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              退貨率
              <Tooltip placement="bottom" title="退貨申請子訂單數/總子訂單數">
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              {`${ratePercentage}%`}
              <StyledTag color={rateTagColor} icon={rateTagIcon}>
                {`${rateChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <BottomSection>
            <SectionTitle>退貨子訂單數</SectionTitle>
            <SectionContent>
              {count}
              <StyledTag color={countTagColor} icon={countTagIcon}>
                {`${countChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
          <BottomSection>
            <SectionTitle>平均退貨金額</SectionTitle>
            <SectionContent>
              {avgAmountCurrency}
              <StyledTag color={avgAmountTagColor} icon={avgAmountTagIcon}>
                {`${avgAmountChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default OrderReturnCard;
