import React from "react";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { Tabs } from "antd";
import { useParams } from "react-router-dom";
import InfoTab from "./InfoTab";
import ProductOutInfo from "./ProductOutInfo";
import ProductManufacturer from "./ProductManufacturer";

const Wrapper = styled.div`
  position: relative;
  margin: 20px 20px 20px 25px;
  padding: 12px 24px 71px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

type Props = {
  mode: "add" | "edit";
};

const AddProduct = (props: Props) => {
  const { mode } = props;
  const { productId } = useParams();

  return (
    <Wrapper>
      <PageTitle title="品運商品" />
      <Tabs type="card" defaultActiveKey="attributeType">
        <Tabs.TabPane tab="基本資訊" key="attributeType">
          <InfoTab mode={mode} />
        </Tabs.TabPane>
        {productId && (
          <Tabs.TabPane tab="商品x供應商" key="tagType">
            <ProductOutInfo productId={productId} />
          </Tabs.TabPane>
        )}
        {productId && (
          <Tabs.TabPane tab="商品x製造商" key="manufacturer">
            <ProductManufacturer productId={productId} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Wrapper>
  );
};

export default AddProduct;
