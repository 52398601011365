import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  financeOrderState,
  fetchFinanceOrderDetail,
  submitFinanceOrder,
  fetchOrderProducts,
  setProductsListFilter,
} from "@redux/financeOrderSlice";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { FinanceOrderProduct, contractTypeMap } from "@api/financeOrderApi";
import { StyledSection } from "src/styles/common";
import PageSizeController from "@component/PageSizeController";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const TableHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Text = styled.p``;
const Title = styled(Text)`
  font-weight: bold;
  padding-right: 20px;
`;

const Block = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const FinanceOrderDetail = () => {
  const dispatch = useDispatch();
  const { isDeatilFetching, financeOrderDetail, financeOrderProductResult, productsListFilter } = useSelector(
    financeOrderState,
  );
  const { orderId } = useParams();
  const [page, setPage] = useState<number>(1);

  const { limit = 20 } = productsListFilter;

  const columns: ColumnsType<FinanceOrderProduct> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "SKU(品號)",
      dataIndex: "sku",
    },
    {
      title: "barcode",
      dataIndex: "barcode",
    },
    {
      title: "商品名稱",
      dataIndex: "name",
    },
    {
      title: "數量",
      dataIndex: "qty",
    },
  ];

  useEffect(() => {
    const payload = {
      orderId,
      ...productsListFilter,
    };
    dispatch(fetchOrderProducts(payload));
  }, [dispatch, orderId, productsListFilter]);

  useEffect(() => {
    dispatch(fetchFinanceOrderDetail(orderId));
  }, [dispatch, orderId]);

  const handleSubmit = () => {
    dispatch(submitFinanceOrder(orderId));
  };

  const handlePageSizeChange = (value: number) => {
    dispatch(
      setProductsListFilter({
        limit: value,
        offset: 0,
      }),
    );
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(setProductsListFilter({ offset: (value - 1) * limit }));
  };

  return (
    <StyledSection>
      <Header>
        <div>
          <Block>
            <Title>類型</Title>
            <Text>{contractTypeMap[financeOrderDetail.contractType]}</Text>
          </Block>
          <Block>
            <Title>供應／經銷商</Title>
            <Text>{financeOrderDetail.retailerName}</Text>
          </Block>
        </div>
        <Button onClick={handleSubmit} disabled={!!financeOrderDetail.submittedAt || !!financeOrderDetail.destroyedAt}>
          提交
        </Button>
      </Header>
      <TableHeader>
        <PageSizeController
          value={20}
          totalCount={financeOrderProductResult.count}
          handlePageSizeChange={handlePageSizeChange}
        />
      </TableHeader>
      <Table
        loading={isDeatilFetching}
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={financeOrderProductResult.results}
        pagination={{
          pageSize: limit,
          current: page,
          showSizeChanger: false,
          total: financeOrderProductResult.count,
          onChange: fetchPageChange,
        }}
      />
    </StyledSection>
  );
};

export default FinanceOrderDetail;
