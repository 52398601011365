import React, { useEffect, useMemo, useState } from "react";
import { FlexBox, Header } from "src/styles/common";
import { Button, Modal, Table } from "antd";
import { RootState } from "@redux/rootReducer";
import { ExclamationCircleOutlined, FilterTwoTone, PlusCircleFilled } from "@ant-design/icons";
import ImportCSV from "@component/ImportFileModal";
import { useDispatch, useSelector } from "react-redux";
import {
  importProductsCSV,
  setShowAddItemPopup,
  fetchProductList,
  fetchDeleteProduct,
  updatePcmListParams,
  setShowImportCSV,
} from "@redux/brandOperation/PcmSlice";
import { ColumnsType } from "antd/lib/table";
import { useParams } from "react-router-dom";
import { PcmLines } from "@api/brandOperation/pcmApi";
import styled from "styled-components";
import { ProcessValue } from "@page/Cost/constans";
import AddItemPopup from "./AddItemPopup";
import EditPopup from "./EditPopup";

const ErrorDiv = styled.div``;

const ProductTab = () => {
  const dispatch = useDispatch();
  const { showAddItemPopup, productList, isProductListFetching, showImportCSV, importError, pcmDetail } = useSelector(
    (state: RootState) => state.pcmSlice,
  );
  const { id: pcmId } = useParams();
  const [page, setPage] = useState<number>(1);
  const [productId, setProductId] = useState<number>();
  const pageSize = 20;
  const handelImportCSV = (file: File) => {
    dispatch(importProductsCSV({ pcmId, file }));
  };

  const columns: ColumnsType<PcmLines> = [
    {
      key: "no",
      dataIndex: "no",
      title: "條碼",
      width: 100,
      fixed: "left",
      render: (_, value) => {
        return <div>{value.no}</div>;
      },
    },
    {
      key: "name",
      dataIndex: "name",
      title: "中文品名",
      width: 150,
      fixed: "left",
    },
    {
      key: "nameEn",
      dataIndex: "nameEn",
      width: 100,
      title: "英文品名",
    },
    {
      key: "effectiveDate",
      dataIndex: "effectiveDate",
      width: 150,
      title: "效期",
    },
    {
      key: "currencyRate",
      dataIndex: "currencyRate",
      width: 100,
      title: "匯率",
    },
    {
      key: "price",
      dataIndex: "price",
      width: 100,
      title: "單價(含稅)",
    },
    {
      key: "orderedPcs",
      dataIndex: "orderedPcs",
      width: 100,
      title: "下單pcs數",
    },
    {
      key: "estPcmAmount",
      dataIndex: "estPcmAmount",
      width: 130,
      title: "預期採購金額",
    },
    {
      key: "pcsPerCarton",
      dataIndex: "pcsPerCarton",
      width: 100,
      title: "箱入數",
    },

    {
      key: "orderedCtnQty",
      dataIndex: "orderedCtnQty",
      width: 100,
      title: "下單箱數",
    },
    {
      key: "estArrivedDate",
      dataIndex: "estArrivedDate",
      width: 130,
      title: "預期到貨時間",
    },
    {
      key: "estPaymentDate",
      dataIndex: " estPaymentDate",
      width: 130,
      title: "預期付款時間",
      render: (_, value) => {
        return (
          <div>
            {value?.cashflows.map((cashflow) => {
              return <div>{cashflow?.estPaymentDate}</div>;
            })}
          </div>
        );
      },
    },
    {
      key: "estPaymentAmount",
      dataIndex: "estPaymentAmount",
      width: 130,
      title: "預期付款金額",
      render: (_, value) => {
        return (
          <div>
            {value?.cashflows.map((cashflow) => {
              return <div>{cashflow?.estPaymentAmount}</div>;
            })}
          </div>
        );
      },
    },
    {
      key: "arrivedDate",
      dataIndex: "arrivedDate",
      width: 130,
      title: "實際到貨時間",
    },
    {
      key: "arrivedQty",
      dataIndex: "arrivedQty",
      width: 130,
      title: "實際到貨數量",
    },
    {
      key: "paymentAmount",
      dataIndex: "paymentAmount",
      width: 130,
      title: "實際採購金額",
    },
    {
      key: "paymentDate",
      dataIndex: "paymentDate",
      width: 130,
      title: "實際付款時間",
      render: (_, value) => {
        return (
          <div>
            {value?.cashflows.map((cashflow) => {
              return <div>{cashflow?.paymentDate}</div>;
            })}
          </div>
        );
      },
    },
    {
      key: "paymentAmount",
      dataIndex: "paymentAmount",
      width: 130,
      title: "實際付款金額",
      render: (_, value) => {
        return (
          <div>
            {value?.cashflows.map((cashflow) => {
              return <div>{cashflow?.paymentAmount}</div>;
            })}
          </div>
        );
      },
    },
    {
      key: "",
      dataIndex: "",
      render: (_, value) => {
        return (
          <>
            {value.no && (
              <FlexBox>
                <Button
                  type="link"
                  onClick={() => {
                    dispatch(setShowAddItemPopup(!showAddItemPopup));
                    setProductId(value.id);
                  }}
                  disabled={pcmDetail.status === ProcessValue.PENDING}
                >
                  編輯
                </Button>
                <Button
                  type="link"
                  onClick={() => handleOnBatchDelete(value.id)}
                  disabled={pcmDetail.status === ProcessValue.APPROVE || pcmDetail.status === ProcessValue.PENDING}
                >
                  刪除
                </Button>
              </FlexBox>
            )}
          </>
        );
      },
    },
  ];

  function createChildren(datas: any) {
    const formattedData: any[] = [];

    datas.forEach((data: any) => {
      const { cashflows, ...rest } = data;
      const children =
        cashflows.length > 1 ? cashflows.slice(1).map((cashflow: any) => ({ cashflows: [cashflow] })) : null;

      const newData = { ...rest, cashflows: [cashflows[0]], children };
      formattedData.push(newData);
      return {};
    });

    return formattedData;
  }

  useEffect(() => {
    dispatch(fetchProductList(pcmId));
  }, [dispatch, pcmId]);

  const handleOnBatchDelete = (id: number) => {
    Modal.confirm({
      title: "你確定要刪除這個商品?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk: () => dispatch(fetchDeleteProduct({ pcmId, productId: id })),
    });
  };
  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      updatePcmListParams({
        limit: pageSize,
        offset: pageSize * (value - 1),
      }),
    );
  };

  useEffect(() => {
    if (importError.length > 0) {
      Modal.error({
        title: "匯入失敗, 請重新上傳",
        content: importError.map((error: string) => <ErrorDiv>{error}</ErrorDiv>),
      });
    }
  }, [dispatch, importError]);

  const renderItemPopUp = useMemo(() => {
    /* pcmStatus 為核准僅能部分編輯，待審核無法編輯 */
    if (pcmDetail.status === ProcessValue.CLEAN || pcmDetail.status === ProcessValue.REJECT) {
      return (
        <AddItemPopup visible onClose={() => dispatch(setShowAddItemPopup(!showAddItemPopup))} productId={productId} />
      );
    }

    if (pcmDetail.status === ProcessValue.APPROVE) {
      return (
        <EditPopup productId={productId} visible onClose={() => dispatch(setShowAddItemPopup(!showAddItemPopup))} />
      );
    }
    return null;
  }, [dispatch, pcmDetail.status, productId, showAddItemPopup]);

  return (
    <div>
      {showAddItemPopup && renderItemPopUp}
      <Header>
        <FlexBox>
          <Button
            type="primary"
            icon={<PlusCircleFilled />}
            onClick={() => {
              dispatch(setShowAddItemPopup(!showAddItemPopup));
              setProductId(undefined);
            }}
            disabled={pcmDetail.status === ProcessValue.APPROVE || pcmDetail.status === ProcessValue.PENDING}
          >
            建立商品
          </Button>
          <Button
            icon={<FilterTwoTone />}
            onClick={() => dispatch(setShowImportCSV(!showImportCSV))}
            disabled={pcmDetail.status === ProcessValue.APPROVE || pcmDetail.status === ProcessValue.PENDING}
          >
            批次匯入
          </Button>
        </FlexBox>
      </Header>
      {showImportCSV && (
        <ImportCSV
          csvErrorMessage=""
          clearErrorMessage={() => {}}
          closePopup={() => dispatch(setShowImportCSV(!showImportCSV))}
          handleUploadFile={handelImportCSV}
          templatePath="admin/template/xls/pcm_template_v1.xlsx"
          fileType=".xlsx,.csv,.xls"
        />
      )}

      {/* <Test> */}
      <Table
        loading={isProductListFetching}
        scroll={{ x: "max-content", y: 400 }}
        columns={columns}
        dataSource={productList.results && createChildren(productList.results)}
        rowKey="id"
        pagination={{
          pageSize,
          current: page,
          showSizeChanger: false,
          total: productList.count,
          onChange: fetchPageChange,
        }}
      />
      {/* </Test> */}
    </div>
  );
};

export default ProductTab;
