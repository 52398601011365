/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { OrderSalesPlans, SubOrder } from "@api/utils/normalizeOrder";
import cityRegionData from "@utils/CityRegionData";
import SalePageTypeValue from "@page/Order/SalePageTypeValue";
import { fetchBankList } from "@redux/bankSlice";
import { fetchReturn, orderState } from "@redux/orderSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Col, Form, Input, Modal, Row, Select, Space, Table } from "antd";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { PaymentMethodIdValue } from "../PaymentMethodValue";
import { ShippingTypeValueByNumber } from "../ShippingTypeValue";
import reasons from "./reasonData";

type Props = {
  onClose: () => void;
  order: SubOrder;
  showReturn: boolean;
};

function getObjectKeys(obj: any) {
  return Object.keys(obj).map((key: string) => parseInt(key, 10));
}

const ModalWrapper = styled(Modal)`
  &.ant-modal {
    top: 50px;
  }
  && .ant-form-item-required.ant-form-item-no-colon::before {
    display: none;
  }
  && .ant-form-item-required.ant-form-item-no-colon::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }
`;

const Header = styled.header`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;
const Title = styled.div`
  background-color: #fafafa;
  padding: 20px;
  margin-bottom: 10px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const TitleBox = styled.div`
  width: 45vw;
  margin: 10px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Option = styled.div`
  display: flex;
  justify-content: space-between;
`;
const CustomInput = styled(Input)`
  position: relative;
`;

const Div = styled.div`
  width: 100px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TextWrapper = styled.div<{ disabled?: boolean }>`
  color: ${({ theme, disabled }) => (disabled ? theme.colorNeutral500 : theme.colorNeutral600)};
`;
const Notice = styled.div`
  color: red;
`;

const ReturnPopup = (props: Props) => {
  const { isFetching, isReturnFetchingFulfilled } = useSelector(orderState);
  const { onClose, order, showReturn } = props;
  const { orderId } = useParams();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState({});

  const allCities = Object.keys(cityRegionData);
  const city = form.getFieldValue("city");
  const dispatch = useDispatch();
  const bank = useSelector((state: RootState) => state.bank);
  const [bankName, setBankName] = useState<string>("");
  const [bankCode, setBankCode] = useState<string>("");

  useEffect(() => {
    dispatch(fetchBankList());
  }, [dispatch]);

  const updateInput = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
    form.setFieldsValue({ optionQty: value });
  };

  // 若為任選方案且購買數量為1，預設填入方案可退量
  const handleDefaultInput = (field: string, value: any, data: OrderSalesPlans) => {
    if (data.salesPageType === SalePageTypeValue.Any && data.qty === 1 && Number(value) === 1) {
      data.salesPlanOptions.map((item: any) => {
        form.setFieldsValue({ [`optionQty-${item.productId}`]: item.optionQty - (item.optionBackOrderQty || 0) });
      });
    } else {
      data.salesPlanOptions.map((item: any) => {
        form.setFieldsValue({ [`optionQty-${item.productId}`]: undefined });
      });
    }
    form.setFieldsValue({ [field]: value });
    form.setFieldsValue({ returnQty: value });
  };

  const onReasonChange = (value: number) => {
    form.setFieldsValue({
      reason: value,
    });
  };
  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    switch (id) {
      case "ur-address":
        form.setFieldsValue({
          address: value,
        });
        break;
      case "ur-phone":
        form.setFieldsValue({
          phone: value,
        });
        break;
      case "ur-batchNumber":
        form.setFieldsValue({
          batchNumber: value,
        });
        break;
      case "ur-comment":
        form.setFieldsValue({
          comment: value,
        });
        break;
      case "ur-receiverName":
        form.setFieldsValue({
          receiverName: value,
        });
        break;
      default:
        break;
    }
  };
  const onAreaChange = (type: "city" | "region", value: any) => {
    if (type === "city") {
      form.setFieldsValue({
        city: value,
      });
    } else if (type === "region") {
      form.setFieldsValue({
        region: value,
      });
    }
  };

  const renderRegionOptions = (citys: string) => {
    if (!citys || !cityRegionData[citys]) {
      return [];
    }
    const allRegions = Object.keys(cityRegionData[city]);
    return allRegions.map((region) => <Select.Option value={region}>{region}</Select.Option>);
  };

  const handleCancel = () => {
    onClose();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goods: {
    salesPlanId: number;
    qty: number;
    options: any;
    reason: string;
  }[] = [];

  useEffect(() => {
    order.orderSalesPlans.forEach((item) => {
      const options = item.salesPlanOptions.reduce((accumulate, option) => {
        const storage: {
          [optionId: number]: {
            productId: number;
            qty: number;
          };
        } = {};
        storage[option.productId] = {
          productId: option.productId,
          qty:
            Number(form.getFieldValue(`returnQty-${item.id}`)) === 0
              ? 0
              : Number(form.getFieldValue(`optionQty-${option.productId}`)),
        };
        return { ...accumulate, ...storage };
      }, {});

      // 若 qty 是 0 就不傳給後端
      if (form.getFieldValue(`returnQty-${item.id}`) && Number(form.getFieldValue(`returnQty-${item.id}`)) !== 0) {
        goods.push({
          salesPlanId: item.id,
          qty: Number(form.getFieldValue(`returnQty-${item.id}`)),
          options,
          reason: form.getFieldValue(`reason-${item.id}`),
        });
      }
    });
  }, [form, goods, order.orderSalesPlans]);

  const normalizeGoods = () => {
    const result = getObjectKeys(goods).map((planId) => {
      const { salesPlanId, qty, options, reason } = goods[planId];

      return {
        qty: qty || 0,
        salesPlanId,
        reason,
        options: getObjectKeys(options).map((optionId) => ({
          productId: options[optionId].productId,
          qty: options[optionId].qty || 0,
        })),
      };
    });

    return result;
  };

  const onSubmit = () => {
    const formState = form.getFieldsValue();

    dispatch(
      fetchReturn({
        orderId: Number(orderId),
        address: `${formState.city ? formState.city : ""}${formState.region ? formState.region : ""}${
          formState.address
        }`,
        orderShipmentId: order.id,
        applyType: 1,
        phone: formState.phone,
        name: formState.receiverName,
        code:
          formState.region === undefined || formState.city === undefined
            ? order.code
            : cityRegionData[formState.city][formState.region],
        bankCode,
        bankName: bankName + formState.branchName,
        bankAccount: formState.bankAccount,
        accountName: formState.accountName,
        changeGoods: normalizeGoods(),
      }),
    );
  };

  useEffect(() => {
    if (isReturnFetchingFulfilled === true) {
      onClose();
    }
  }, [isReturnFetchingFulfilled, onClose]);

  const updateBankInfo = (value: { value: string; label: string }) => {
    const selectedBankCode = value.value;
    const selectedBankName = value.label;

    setBankName(selectedBankName);
    setBankCode(selectedBankCode);
  };

  const columns: any = [
    {
      key: "salesPlanName",
      dataIndex: "salesPlanName",
      title: "方案名稱ss",
      render: (value: any, data: OrderSalesPlans) => (
        <>
          <TextWrapper disabled={data.qty === data.backOrderQty}>{data.salesPageName}</TextWrapper>
          <TextWrapper disabled={data.qty === data.backOrderQty}>{data.salesPlanName}</TextWrapper>
        </>
      ),
    },
    {
      key: "price",
      dataIndex: "price",
      title: "單件可退金額",
      width: 100,
      render: (value: any, data: OrderSalesPlans) => (
        <TextWrapper disabled={data.qty === data.backOrderQty}>
          <Div>{data.price}</Div>
        </TextWrapper>
      ),
    },

    {
      key: "qty",
      dataIndex: "qty",
      title: "可退數量",
      render: (value: any, data: OrderSalesPlans) => (
        <TextWrapper disabled={data.qty === data.backOrderQty}>
          <Div>{data.qty - data.backOrderQty}</Div>
        </TextWrapper>
      ),
    },
    {
      key: "optionsText",
      dataIndex: "optionsText",
      title: "退貨數量",
      render: (value: any, data: OrderSalesPlans) => (
        <>
          <Col span={6}>
            <Form.Item
              name="returnQty"
              rules={[
                ({ getFieldsValue }) => ({
                  validator(rule, values) {
                    const formValue = getFieldsValue();
                    if (!formValue.returnQty) {
                      return Promise.reject(new Error("請填寫退貨數量"));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Form.Item
                shouldUpdate
                name={`returnQty-${data.id}`}
                rules={[
                  () => ({
                    validator(rule, values) {
                      if (values > data.qty) {
                        return Promise.reject(new Error("退貨數量錯誤"));
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <CustomInput
                  disabled={data.qty === data.backOrderQty}
                  bordered
                  onChange={(e) => handleDefaultInput(`returnQty-${data.id}`, e.target.value, data)}
                  defaultValue={0}
                />
              </Form.Item>
            </Form.Item>
          </Col>
          <TextWrapper disabled={data.qty === data.backOrderQty}>
            請填寫總數＝
            {data.optionsCount *
              (form.getFieldValue(`returnQty-${data.id}`) ? Number(form.getFieldValue(`returnQty-${data.id}`)) : 0)}
            的數量
          </TextWrapper>
          <TextWrapper disabled={data.qty === data.backOrderQty}>
            {data.salesPageName}
            {data.salesPlanName}
          </TextWrapper>
          <OptionWrapper>
            {data.salesPlanOptions.map((item) => (
              <Option>
                <TextWrapper disabled={data.qty === data.backOrderQty}>
                  {item.optionName}
                  <div>
                    *可退總數
                    {(item.optionQty ? item.optionQty : 0) - (item.optionBackOrderQty ? item.optionBackOrderQty : 0)}
                  </div>
                </TextWrapper>
                <Col span={7}>
                  <Form.Item
                    shouldUpdate
                    name={`optionQty-${item.productId}`}
                    rules={[
                      ({ getFieldsValue }) => ({
                        validator(rule, values) {
                          const formValue = getFieldsValue();
                          const hasOneValueInReturnQty = Object.keys(formValue)
                            .filter((fieldKey) => fieldKey.startsWith("returnQty-"))
                            .some(
                              (fieldKey) =>
                                Number(fieldKey.split("-")[1]) === data.id && Number(formValue[fieldKey]) > 0,
                            );

                          const hasOneValueInOption = Object.keys(formValue)
                            .filter((fieldKey) => fieldKey.startsWith("optionQty-"))
                            .some(
                              (fieldKey) => Number(fieldKey.split("-")[1]) === item.productId && !!formValue[fieldKey],
                            );

                          if (
                            Number(values) >
                            (item.optionQty ? item.optionQty : 0) -
                              (item.optionBackOrderQty ? item.optionBackOrderQty : 0)
                          ) {
                            return Promise.reject(new Error("退貨數量錯誤"));
                          }
                          if (!hasOneValueInOption && hasOneValueInReturnQty) {
                            return Promise.reject(new Error("請填選項"));
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      onChange={(e) => updateInput(`optionQty-${item.productId}`, e.target.value)}
                      disabled={
                        (data.salesPageType === SalePageTypeValue.Any &&
                          data.qty === 1 &&
                          Number(form.getFieldValue(`returnQty-${data.id}`)) === 1) ||
                        data.qty === data.backOrderQty
                      }
                    />
                  </Form.Item>
                </Col>
              </Option>
            ))}
          </OptionWrapper>
        </>
      ),
    },
    {
      key: "reason",
      dataIndex: "reason",
      title: "原因",
      render: (value: any, data: any) => (
        <Form.Item
          name={`reason-${data.id}`}
          rules={[
            ({ getFieldsValue }) => ({
              validator(rule, values) {
                const formValue = getFieldsValue();
                const hasOneValueInReturnQty = Object.keys(formValue)
                  .filter((fieldKey) => fieldKey.startsWith("returnQty-"))
                  .some((fieldKey) => Number(fieldKey.split("-")[1]) === data.id && Number(formValue[fieldKey]) > 0);

                const hasResonInReturnItem = Object.keys(formValue)
                  .filter((fieldKey) => fieldKey.startsWith("reason-"))
                  .some((fieldKey) => Number(fieldKey.split("-")[1]) === data.id && !!formValue[fieldKey]);

                if (values) {
                  return Promise.resolve();
                }
                if (hasOneValueInReturnQty && !hasResonInReturnItem) {
                  return Promise.reject(new Error("請選擇原因"));
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Select
            placeholder="請選擇"
            onChange={onReasonChange}
            style={{ width: "20vw" }}
            disabled={data.qty === data.backOrderQty}
          >
            {reasons.map((reason) => (
              <Select.Option value={reason.label}>{reason.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      ),
    },
  ];

  useEffect(() => {
    form.setFieldsValue({ receiverName: order.receiverName });
    form.setFieldsValue({ phone: order.receiverPhone });
    if (order.shippingMethod === 1) {
      form.setFieldsValue({ address: order.address });
    }
  }, [form, order]);
  return (
    <ModalWrapper visible={showReturn} width="90%" onCancel={onClose} footer={null} maskClosable={false}>
      <Header>新增退貨單</Header>
      <Form form={form} colon={false} onValuesChange={(values: any) => setFormValues(values)} onFinish={onSubmit}>
        <TableWrapper>
          <Table
            loading={isFetching}
            columns={columns}
            dataSource={order.orderSalesPlans}
            pagination={false}
            scroll={{ x: "max-content", y: 400 }}
          />
        </TableWrapper>
        <TitleWrapper>
          <TitleBox>
            <Title>取件地址</Title>

            <Form.Item label="姓名" name="receiverName">
              <Input onChange={onInputChange} id="ur-receiverName" defaultValue={order.receiverName} />
            </Form.Item>
            <Form.Item label="電話" name="phone">
              <Input onChange={onInputChange} id="ur-phone" defaultValue={order.receiverPhone} />
            </Form.Item>
            <Row>
              <Col span={2}>
                <span>地址</span>
              </Col>

              <Col span={11}>
                <Form.Item name="city">
                  <Select
                    onChange={(value) => onAreaChange("city", value)}
                    style={{ width: "100%" }}
                    placeholder="請選擇"
                  >
                    {allCities.map((cityName) => (
                      <Select.Option value={cityName}>{cityName}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={11}>
                <Form.Item wrapperCol={{ span: 24 }} name="region" label="" style={{ marginRight: 8 }}>
                  <Select
                    onChange={(value) => onAreaChange("region", value)}
                    style={{ width: "100%" }}
                    placeholder="請選擇"
                  >
                    {renderRegionOptions(city)}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              label=""
              name="address"
              wrapperCol={{ span: 30, offset: 2 }}
              rules={[
                {
                  required: true,
                  message: "請填入地址",
                },
              ]}
            >
              <Input
                onChange={onInputChange}
                id="ur-address"
                placeholder="請填入地址"
                defaultValue={
                  order.shippingMethod === ShippingTypeValueByNumber.HOME_DELIVERY ? order.address : undefined
                }
              />
              *請填寫可讓物流當面取件的地址
              {order.shippingMethod === ShippingTypeValueByNumber.HOME_DELIVERY && (
                <Notice>*若欲更改地址之縣市及地區，需於選單中選擇縣市區域，並且注意地址輸入框不可有縣市區域資訊</Notice>
              )}
            </Form.Item>
          </TitleBox>
          {order.paymentMethod === PaymentMethodIdValue.CASHONDELIVERY && (
            <TitleBox>
              <Title>退款帳戶</Title>
              <Form.Item name="accountName" rules={[{ required: true, message: "必填" }]}>
                <InputWrapper>
                  <div>戶名 </div>
                  <Col span={18}>
                    <Input onChange={onInputChange} id="ur-accountName" />
                  </Col>
                </InputWrapper>
              </Form.Item>

              <Form.Item name="bankName">
                <InputWrapper>
                  <div>銀行行名 </div>
                  <Col span={18}>
                    <Select labelInValue placeholder="請選擇" onChange={updateBankInfo}>
                      {bank.bankList.map((info: { code: string; name: string }) => (
                        <Select.Option value={info.code} key={`bank${info.code}`}>
                          {info.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </InputWrapper>
              </Form.Item>
              <Form.Item name="branchName" rules={[{ required: true, message: "必填" }]}>
                <InputWrapper>
                  <div>分行行名 </div>
                  <Col span={18}>
                    <Input onChange={onInputChange} id="ur-branchName" />
                  </Col>
                </InputWrapper>
              </Form.Item>
              <Form.Item name="bankAccount" rules={[{ required: true, message: "必填" }]}>
                <InputWrapper>
                  <div>帳號</div>
                  <Col span={18}>
                    <Input onChange={onInputChange} id="ur-bankAccount" />
                  </Col>
                </InputWrapper>
              </Form.Item>
            </TitleBox>
          )}
        </TitleWrapper>
        <Row justify="end">
          <Space>
            <Button type="default" onClick={handleCancel}>
              取消
            </Button>
            <Button type="primary" htmlType="submit">
              建立退貨單
            </Button>
          </Space>
        </Row>
      </Form>
    </ModalWrapper>
  );
};

export default ReturnPopup;
