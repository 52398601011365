import { ExclamationCircleOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import productCategoryApi, { ProductCategoryNode } from "@api/productCategoryApi";
import {
  attributeState,
  fetchAddProductCategoryTag,
  fetchDeleteProductCategoryTag,
  fetchProductCategoryTagList,
} from "@redux/attributeSlice";
import { Button, Cascader, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div<{ alignCenter: boolean }>`
  padding: 20px;
  display: flex;
  align-items: ${({ alignCenter }) => (alignCenter ? "center" : "flex-start")};
`;
const Title = styled.div`
  font-size: 14px;
  margin-right: 23px;
`;
const CustomButton = styled(Button)`
  margin-left: 12px;
`;
const ProductCategoryText = styled.div`
  min-width: 390px;
  margin-right: 13px;
`;
const CascaderWrapper = styled.div`
  position: relative;
`;
const NoticeText = styled.div`
  color: #f5222d;
  position: absolute;
  top: 100%;
  left: 10px;
`;

export default function ProductCategoryTab() {
  const dispatch = useDispatch();
  const { productCategoryTagListResult, isFetching } = useSelector(attributeState);

  const { attributeId } = useParams();
  const intAttributeId = Number(attributeId);

  const [productCategoryList, setProductCategoryList] = useState<ProductCategoryNode[]>([]);
  const [addProductCategoryId, setAddProductCategoryId] = useState<number | undefined>();
  const [checkEmptyData, setCheckEmptyData] = useState<boolean>(false);

  const handleCreateProductCategoryConnection = (productCategoryId?: number) => {
    if (productCategoryId) {
      dispatch(fetchAddProductCategoryTag({ productCategoryId, tagTypeId: intAttributeId }));
      setCheckEmptyData(false);
    } else {
      setCheckEmptyData(true);
    }
  };

  const handleDeleteProductCategoryConnection = (productCategoryId?: number) => {
    if (productCategoryId) {
      Modal.confirm({
        title: "確認要刪除此品類？",
        content: "以下操作將移除相關商品 / 銷售頁上所有紀錄",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(fetchDeleteProductCategoryTag({ productCategoryId, tagTypeId: intAttributeId }));
        },
      });
    }
  };

  useEffect(() => {
    dispatch(fetchProductCategoryTagList(intAttributeId));
  }, [dispatch, intAttributeId]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await productCategoryApi.getProductCategoryList({ withProductCount: true });
      setProductCategoryList(response);
    };
    fetchData();
  }, []);

  return (
    <Spin spinning={isFetching}>
      <Wrapper alignCenter={productCategoryTagListResult.results.length === 0}>
        <Title>編輯品類</Title>
        <div>
          {productCategoryTagListResult.results.map((item) => (
            <Row align="middle" style={{ marginBottom: 12 }}>
              <ProductCategoryText>{item.path}</ProductCategoryText>
              <Button type="default" onClick={() => handleDeleteProductCategoryConnection(item.id)}>
                刪除
              </Button>
            </Row>
          ))}
          <Row>
            <CascaderWrapper>
              <Cascader
                style={{ width: 390, border: `solid 1px ${checkEmptyData ? "#ff4d4f" : "transparent"}` }}
                options={productCategoryList}
                changeOnSelect
                placeholder="請選擇"
                onChange={(idList) => {
                  if (idList.length > 0) {
                    setAddProductCategoryId(Number(idList[idList.length - 1]));
                  }
                }}
              />
              {checkEmptyData && <NoticeText>請選擇品類</NoticeText>}
            </CascaderWrapper>
            <CustomButton
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => handleCreateProductCategoryConnection(addProductCategoryId)}
            >
              新增
            </CustomButton>
          </Row>
        </div>
      </Wrapper>
    </Spin>
  );
}
