import React from "react";
import { Button, DatePicker, Form, Input, Row, Select } from "antd";
import styled from "styled-components";
import moment from "moment";
import { useDispatch } from "react-redux";
import PopupBackground from "@component/PopupBackground";
import { CooperationResult } from "@api/cooperationApi";
import { fetchCreateCooperation, fetchUpdateCooperation } from "@redux/finance/cooperationSlice";

type Props = {
  onClose: () => void;
  action: string;
  currentData: CooperationResult | undefined;
};

const { Option } = Select;

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const CustomRangePicker = styled(DatePicker.RangePicker)`
  width: 100%;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 100%;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

export default function EditInvoicePopup(props: Props) {
  const dispatch = useDispatch();
  const { onClose, action, currentData } = props;
  const [form] = Form.useForm();

  const formatedcurrentData = {
    ...currentData,
    timePeriod: [moment(currentData?.startAt), moment(currentData?.endAt)],
    recognizeAt: moment(currentData?.recognizeAt),
  };

  const onSubmit = () => {
    const formState = form.getFieldsValue();
    if (action === "new") {
      dispatch(
        fetchCreateCooperation({
          ...formState,
          startAt: formState.timePeriod[0].format("YYYY-MM"),
          endAt: formState.timePeriod[1].format("YYYY-MM"),
          recognizeAt: formState.recognizeAt.format("YYYY-MM"),
          timePeriod: null,
        }),
      );
      onClose();
    } else {
      dispatch(
        fetchUpdateCooperation({
          ...formState,
          id: currentData?.id,
          startAt: formState.timePeriod[0].format("YYYY-MM"),
          endAt: formState.timePeriod[1].format("YYYY-MM"),
          recognizeAt: formState.recognizeAt.format("YYYY-MM"),
          timePeriod: null,
        }),
      );
      onClose();
    }
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form
          colon={false}
          labelAlign="left"
          labelCol={{ span: 5 }}
          form={form}
          initialValues={action === "edit" || action === "view" ? formatedcurrentData : undefined}
          onFinish={onSubmit}
        >
          <Form.Item label="類型" name="cooperateType" wrapperCol={{ span: 12 }}>
            <Select defaultValue="cooperation" disabled={action === "view"}>
              <Option value="cooperation">異業合作</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="合作對象"
            name="name"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <Input disabled={action === "view"} />
          </Form.Item>
          <Form.Item
            label="對象統編"
            name="ubn"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <Input disabled={action === "view"} />
          </Form.Item>
          <Form.Item
            label="合作內容"
            name="content"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <Input disabled={action === "view"} />
          </Form.Item>
          <Form.Item
            label="合作期間"
            name="timePeriod"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <CustomRangePicker picker="month" disabled={action === "view"} />
          </Form.Item>
          <Form.Item
            label="收入金額"
            name="amount"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <Input disabled={action === "view"} />
          </Form.Item>
          <Form.Item
            label="收入所屬年月"
            name="recognizeAt"
            wrapperCol={{ span: 12 }}
            rules={[{ required: true, message: "必填" }]}
          >
            <CustomDatePicker picker="month" disabled={action === "view"} />
          </Form.Item>
          {action === "edit" ||
            (action === "view" && (
              <Form.Item label="發票號碼" name="invoiceNumber" wrapperCol={{ span: 12 }}>
                <div>{formatedcurrentData?.invoiceNumber || "尚未開立"}</div>
              </Form.Item>
            ))}
          <Row justify="end">
            {action === "view" ? (
              <Button type="primary" onClick={onClose}>
                確認
              </Button>
            ) : (
              <>
                <CustomButton onClick={onClose}>取消</CustomButton>
                <Button type="primary" htmlType="submit">
                  送出
                </Button>
              </>
            )}
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
