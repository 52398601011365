/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useEffect, useMemo } from "react";
import styled from "styled-components";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { Button, Col, Form, Row, Select, DatePicker, Input } from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { fetchBrandList, loadMoreBrandList } from "@redux/productSlice";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import { scrapInventoryState, setQueryMonth, setScrapInventoryFilter } from "@redux/finance/scrapInventorySlice";

const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const CustomDatePicker = styled(DatePicker)`
  width: 200px;
`;

export default function Filter() {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const brandListResult = useSelector((state: RootState) => state.product.brandListResult);
  const { scrapInventoryListParams } = useSelector(scrapInventoryState);

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const onSubmit = (values: any) => {
    dispatch(
      setScrapInventoryFilter({
        ...scrapInventoryListParams,
        dateAfter: values.month && values.month.startOf("month").format("YYYY-MM-DD"),
        dateBefore: values.month && values.month.endOf("month").format("YYYY-MM-DD"),
        brandId: values.brand,
      }),
    );
    dispatch(setQueryMonth(values.month && values.month.format("M")));
  };

  const onReset = () => {
    form.resetFields();
    dispatch(
      setScrapInventoryFilter({
        ...scrapInventoryListParams,
        dateAfter: moment().startOf("month").format("YYYY-MM-DD"),
        dateBefore: moment().endOf("month").format("YYYY-MM-DD"),
        brandId: undefined,
      }),
    );
    dispatch(setQueryMonth(moment().format("M")));
  };

  const onBlur = () => {
    dispatch(fetchBrandList());
  };

  useEffect(() => {
    dispatch(fetchBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brd) => (
      <Select.Option key={brd.id} value={brd.id}>
        {brd.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{}} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="報廢月份" name="month">
            <CustomDatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="品牌" name="brand">
            <Select showSearch placeholder="請選擇" filterOption={false} onBlur={onBlur} onSearch={handleOnBrandSearch}>
              {brandOptions}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
