/* eslint-disable camelcase */
import type { SalePageCategoryDetail, SalePageCategoryNode, SalePageListResult } from "@api/salePageCategoryApi";
import { transformAPIKeyToCamel } from "./transformAPIKeyToCamel";

interface CategoryNode {
  id: number;
  name: string;
  parent: number;
  sales_page_count: number;
  is_active: boolean;
}

interface SalesCategoryListRes {
  level_1: CategoryNode[];
  level_2: CategoryNode[];
  level_3: CategoryNode[];
}
interface MapCategoryNode {
  key: number;
  id: number;
  name: string;
  title: string;
  parent: number;
  salesPageCount: number;
  isActive: boolean;
  level: number;
  children: Map<number, MapCategoryNode>;
}
interface SalesCategoryDetailRes {
  id: number;
  name: string;
  path: string;
  parent: number;
  page_info: {
    id: number;
    title: string;
    description: string;
    image: string;
    image_alt: string;
    background_color: string;
    meta_title: string;
    meta_keywords: string;
    meta_description: string;
    sort_methods: {
      id: number;
      name: string;
      is_descend: boolean;
      is_default: boolean;
      is_active: boolean;
    }[];
  };
  icon: string;
  icon_alt: string;
  icon_id: number;
  level: number;
  is_active: boolean;
  to_show: boolean;
}
interface SalePageListRes {
  count: number;
  next: string;
  previous: string;
  results: {
    id: number;
    name: string;
    rank: number;
    sku_list: string[];
    brands: string[];
    plan_special_price_range: {
      max_price: number;
      min_price: number;
    };
  }[];
}

interface NormalizeSalesCategory {
  list: (res: SalesCategoryListRes) => SalePageCategoryNode[];
  detail: (res: SalesCategoryDetailRes) => SalePageCategoryDetail;
  salePageList: (res: SalePageListRes) => SalePageListResult;
}

const normalizeSalesCategory: NormalizeSalesCategory = {
  list: (salesCategoryListRes) => {
    const listMap = new Map<number, MapCategoryNode>();
    // tree level 1
    salesCategoryListRes.level_1.forEach((level1Node) => {
      const { id, name, parent, sales_page_count, is_active } = level1Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: `${name}(${sales_page_count})`,
        parent,
        salesPageCount: sales_page_count,
        level: 1,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
      };
      listMap.set(id, normalizedNode);
    });

    // tree level 2
    salesCategoryListRes.level_2.forEach((level2Node) => {
      const { id, parent, name, sales_page_count, is_active } = level2Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: `${name}(${sales_page_count})`,
        parent,
        salesPageCount: sales_page_count,
        level: 2,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
      };

      const parentNode = listMap.get(parent)!;
      const parentNodeChildren = parentNode.children;
      parentNodeChildren.set(id, normalizedNode);
    });

    // tree level 3
    salesCategoryListRes.level_3.forEach((level3Node) => {
      const { id, parent, name, sales_page_count, is_active } = level3Node;
      const normalizedNode = {
        key: id,
        id,
        name,
        title: `${name}(${sales_page_count})`,
        parent,
        salesPageCount: sales_page_count,
        level: 3,
        isActive: is_active,
        children: new Map(),
        label: name,
        value: id,
      };

      for (const [, level1Node] of listMap) {
        // parentNode 在level2
        const parentNode = level1Node.children.get(parent);
        if (parentNode) {
          const parentNodeChildren = parentNode.children;
          parentNodeChildren.set(id, normalizedNode);
        }
      }
    });

    // Map -> array
    const result = Array.from(listMap).map(([, lvl1]) => ({
      ...lvl1,
      children: Array.from(lvl1.children).map(([, lvl2]) => ({
        ...lvl2,
        children: Array.from(lvl2.children).map(([, lvl3]) => ({
          ...lvl3,
          children: [],
        })),
      })),
    }));

    return result;
  },
  detail: (salesCategoryDetail: SalesCategoryDetailRes) => transformAPIKeyToCamel(salesCategoryDetail),
  salePageList: (res: SalePageListRes) => transformAPIKeyToCamel(res),
};

export default normalizeSalesCategory;
