import { SalesCategoryOrigin } from "@api/topicApi";

export interface SalesCategory {
  key: number;
  title: string;
  parent: number;
  salesPageCount: number;
  children: {
    key: number;
    title: string;
    parent: number;
    salesPageCount: number;
  }[];
}

export default function generateCategoryTree(salesCategories: {
  level1: SalesCategoryOrigin[];
  level2: SalesCategoryOrigin[];
  level3: SalesCategoryOrigin[];
}) {
  const treeMap = new Map<number, SalesCategory>();
  const level1Map = new Map<number, SalesCategory>();
  const level2Map = new Map<number, SalesCategory>();
  const level3Map = new Map<number, SalesCategory>();

  const { level1, level2, level3 } = salesCategories;

  level1.forEach((category) => {
    const newCategory = {
      key: category.id,
      title: category.name,
      parent: category.parent,
      salesPageCount: category.sales_page_count,
      children: [],
    };

    treeMap.set(newCategory.key, { ...newCategory, title: `${category.name}(${category.sales_page_count})` });
    level1Map.set(newCategory.key, newCategory);
  });

  level2.forEach((category) => {
    const newCategory = {
      key: category.id,
      title: category.name,
      parent: category.parent,
      salesPageCount: category.sales_page_count,
      children: [],
    };

    const parent = level1Map.get(newCategory.parent);

    if (parent) {
      parent.children.push({ ...newCategory, title: `${category.name}(${category.sales_page_count})` });
      treeMap.set(parent.key, { ...parent, title: `${parent.title}(${category.sales_page_count})` });
    }

    level2Map.set(newCategory.key, newCategory);
  });

  level3.forEach((category) => {
    const newCategory = {
      key: category.id,
      title: category.name,
      parent: category.parent,
      salesPageCount: category.sales_page_count,
      children: [],
    };

    const level2Parent = level2Map.get(newCategory.parent);

    if (level2Parent) {
      level2Parent.children.push({ ...newCategory, title: `${category.name}(${category.sales_page_count})` });
      const level1Parent = level1Map.get(level2Parent.parent);

      if (level1Parent) {
        const index = level1Parent.children.findIndex((item) => item.key === level2Parent.key);
        level1Parent.children[index] = {
          ...level2Parent,
          title: `${level2Parent.title}(${level2Parent.salesPageCount})`,
        };
        treeMap.set(level1Parent.key, {
          ...level1Parent,
          title: `${level1Parent.title}(${level1Parent.salesPageCount})`,
        });
      }
    }

    level3Map.set(newCategory.key, newCategory);
  });

  return {
    treeMap, // 含 salespagecount
    level1Map,
    level2Map,
    level3Map,
  };
}
