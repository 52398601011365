import PopupBackground from "@component/PopupBackground";
import { fetchBatchCreateOrderFee } from "@redux/externalOrderSlice";
import { Button, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  background: ${({ theme }) => theme.colorNeutral100};
  border-radius: 2px;
  width: 620px;
  padding: 23px 14px 20px 20px;

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;
const RowTitle = styled.div<{ bold?: boolean }>`
  align-self: flex-start;
  width: 100px;
  line-height: 32px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
`;
const RowContent = styled.div<{ width: number }>`
  width: ${({ width }) => width}px;
  display: flex;
  align-items: center;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Percent = styled.div`
  margin-left: 24px;
  margin-bottom: 24px;
`;

type Props = {
  onClose: () => void;
};

interface FormState {
  name: string;
  rate: string;
  amount: string;
}

export default function CreateOrderFeePopup(props: Props) {
  const { onClose } = props;

  const dispatch = useDispatch();

  const { externalOrderId } = useParams();

  const [form] = Form.useForm();

  const [validateWarning, setValidateWarning] = useState<boolean>(false);

  const onSubmit = (values: FormState) => {
    const payload = {
      name: values.name,
      rate: values.rate ? Number(values.rate) : undefined,
      amount: values.amount ? Number(values.amount) : undefined,
    };
    dispatch(fetchBatchCreateOrderFee([{ ...payload, externalOrder: parseInt(externalOrderId, 10) }]));
    onClose();
  };

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <Form colon={false} labelAlign="left" labelCol={{ span: 4 }} form={form} initialValues={{}} onFinish={onSubmit}>
          <Row wrap={false}>
            <RowTitle>
              項目名稱
              <RequiredIcon>*</RequiredIcon>
            </RowTitle>
            <RowContent width={400}>
              <Form.Item name="name" rules={[{ required: true, message: "" }]}>
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>項目比例</RowTitle>
            <RowContent width={400}>
              <Form.Item
                name="rate"
                validateStatus={validateWarning ? "warning" : "validating"}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue("amount")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      setValidateWarning(false);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
              <Percent>%</Percent>
            </RowContent>
          </Row>
          <Row wrap={false}>
            <RowTitle>項目金額</RowTitle>
            <RowContent width={400}>
              <Form.Item
                name="amount"
                validateStatus={validateWarning ? "warning" : "validating"}
                rules={[
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value && getFieldValue("rate")) {
                        setValidateWarning(true);
                        return Promise.reject(new Error(" "));
                      }
                      setValidateWarning(false);
                      return Promise.resolve();
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </RowContent>
          </Row>
          <Row justify="end">
            <CustomButton onClick={onClose}>取消</CustomButton>
            <Button type="primary" htmlType="submit">
              確定
            </Button>
          </Row>
        </Form>
      </Wrapper>
    </PopupBackground>
  );
}
