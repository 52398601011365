import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import {
  fetchCreateOption,
  fetchProductVendor,
  fetchUpdateOption,
  SaleState,
  setOptionError,
  setResetVpcTemp,
} from "@redux/saleSlice";
import { Button, Form, Input, Row } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  position: relative;
  max-width: 70vw;
  padding: 20px;
  background: ${({ theme }) => theme.colorNeutral100};
  max-height: 80vh;
  overflow: auto;

  .ant-form-item-label {
    min-width: 100px;
    margin-right: 15px;
  }

  .ant-form-item-required::before {
    order: 1;
  }

  .ant-form-item-required::after {
    display: none;
  }
`;

const CustomButton = styled(Button)`
  margin-right: 8px;
`;

const Error = styled.span`
  color: #ec6922;
  position: absolute;
  left: 20px;
  bottom: 20px;
  ${fontStyle("14px", "16px")};
`;

type Props = {
  close: () => void;
  onNext: () => void;
};

export default function OptionModal(props: Props) {
  const { close, onNext } = props;

  const dispatch = useDispatch();
  const { vpcTemp, optionError, isEditOptionDone, currentOption } = useSelector(SaleState);

  const { salesId } = useParams();

  const [form] = Form.useForm();

  const setCloseModal = () => {
    dispatch(setResetVpcTemp());
    close();
  };

  const fetchVpcInfo = useDebounce((sku: string) => {
    dispatch(fetchProductVendor(sku));
  }, 500);

  const onSubmit = (values: any) => {
    if (vpcTemp) {
      if (currentOption) {
        dispatch(
          fetchUpdateOption({
            salePageId: parseInt(salesId, 10),
            payload: {
              name: values.optionName,
              vpc: vpcTemp.vpcId,
              optionIds: currentOption.optionIds,
            },
          }),
        );
      } else {
        dispatch(
          fetchCreateOption({
            salePageId: parseInt(salesId, 10),
            payload: {
              name: values.optionName,
              vpc: vpcTemp.vpcId,
            },
          }),
        );
      }
    } else {
      dispatch(setOptionError("無效的 sku"));
    }
  };

  useEffect(() => {
    if (vpcTemp && !form.getFieldValue("optionName")) {
      form.setFieldsValue({
        optionName: vpcTemp.name,
      });
    }
  }, [vpcTemp, form]);

  useEffect(() => {
    if (currentOption) {
      dispatch(fetchProductVendor(currentOption.productInfo.sku));
      form.setFieldsValue({
        sku: currentOption.productInfo.sku,
        optionName: currentOption.optionName,
      });
    }
  }, [form, currentOption, dispatch]);

  useEffect(() => {
    if (isEditOptionDone && !optionError) {
      dispatch(setResetVpcTemp());
      onNext();
    }
  }, [isEditOptionDone, optionError, onNext, dispatch]);

  return (
    <PopupBackground close={setCloseModal} fixed>
      <Wrapper form={form} colon={false} labelAlign="left" onFinish={onSubmit}>
        <Form.Item label="SKU" name="sku" rules={[{ required: true, message: "必填" }]}>
          <Input onChange={(e) => fetchVpcInfo(e.target.value)} />
        </Form.Item>
        <Form.Item label="選項名稱" name="optionName" rules={[{ required: true, message: "必填" }]}>
          <Input />
        </Form.Item>
        <Row justify="end">
          <CustomButton onClick={setCloseModal}>取消</CustomButton>
          <Button type="primary" htmlType="submit" disabled={!!optionError}>
            確定
          </Button>
        </Row>
        <Error>{optionError}</Error>
      </Wrapper>
    </PopupBackground>
  );
}
