/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchMemberRevenueChart } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import { MemberRevenueChartItem } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  hoverAction,
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;

  & .highcharts-tooltip span {
  }
`;

enum SeriesNameMap {
  currentNewMemberRevenue = "新客營收",
  currentOldMemberRevenue = "舊客營收",
  compareNewMemberRevenue = "新客營收 - 相較於",
  compareOldMemberRevenue = "舊客營收 - 相較於",
}

const SalesRevenueChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, memberRevenueChart, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);

    const currentOldMemberRevenue: (number | null)[] = [];
    const currentNewMemberRevenue: (number | null)[] = [];
    const compareOldMemberRevenue: (number | null)[] = [];
    const compareNewMemberRevenue: (number | null)[] = [];

    const currentOldMemberRevenueObj: MemberRevenueChartItem[] = [];
    const currentNewMemberRevenueObj: MemberRevenueChartItem[] = [];
    const compareOldMemberRevenueObj: MemberRevenueChartItem[] = [];
    const compareNewMemberRevenueObj: MemberRevenueChartItem[] = [];

    const { new: newMemberRevenueChartResult, old: oldMemberRevenueChartResult } = memberRevenueChart;

    /* ---- 新客 ---- */
    const newMemberFilledCurrentTimeMap = getFilledTimeData<MemberRevenueChartItem>(
      startDate,
      endDate,
      newMemberRevenueChartResult.currentData,
      timeUnit,
    );
    const newMemberFilledCompareTimeMap = getFilledTimeData<MemberRevenueChartItem>(
      cStartDate,
      cEndDate,
      newMemberRevenueChartResult.compareData || [],
      timeUnit,
    );

    const currentTimes = Array.from(newMemberFilledCurrentTimeMap.keys());
    const compareTimes = Array.from(newMemberFilledCompareTimeMap.keys());
    const newMemberCurrentFilledTimeData = Array.from(newMemberFilledCurrentTimeMap.values());
    const newMemberCompareFilledTimeData = Array.from(newMemberFilledCompareTimeMap.values());

    newMemberCurrentFilledTimeData.forEach((data) => {
      const { revenue = null } = data;
      currentNewMemberRevenue.push(revenue);
      currentNewMemberRevenueObj.push(data);
    });
    newMemberCompareFilledTimeData.forEach((data) => {
      const { revenue = null } = data;
      compareNewMemberRevenue.push(revenue);
      compareNewMemberRevenueObj.push(data);
    });
    /* ---- end ---- */

    /* ---- 舊客 ---- */
    const oldMemberFilledCurrentTimeMap = getFilledTimeData<MemberRevenueChartItem>(
      startDate,
      endDate,
      oldMemberRevenueChartResult.currentData,
      timeUnit,
    );
    const oldMemberFilledCompareTimeMap = getFilledTimeData<MemberRevenueChartItem>(
      cStartDate,
      cEndDate,
      oldMemberRevenueChartResult.compareData || [],
      timeUnit,
    );

    const oldMemberCurrentFilledTimeData = Array.from(oldMemberFilledCurrentTimeMap.values());
    const oldMemberCompareFilledTimeData = Array.from(oldMemberFilledCompareTimeMap.values());

    oldMemberCurrentFilledTimeData.forEach((data) => {
      const { revenue = null } = data;
      currentOldMemberRevenue.push(revenue);
      currentOldMemberRevenueObj.push(data);
    });
    oldMemberCompareFilledTimeData.forEach((data) => {
      const { revenue = null } = data;
      compareOldMemberRevenue.push(revenue);
      compareOldMemberRevenueObj.push(data);
    });
    /* ---- end ---- */

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: SeriesNameMap.currentNewMemberRevenue,
        data: currentNewMemberRevenue,
        type: "column",
        stack: "current",
        color: "#BAE7FF",
        pointStart,
      },
      {
        name: SeriesNameMap.currentOldMemberRevenue,
        data: currentOldMemberRevenue,
        type: "column",
        stack: "current",
        color: "#1890FF",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.unshift({
        name: SeriesNameMap.compareOldMemberRevenue,
        data: compareOldMemberRevenue,
        type: "column",
        stack: "compare",
        color: "#C9CCDE",
        pointStart,
      });
      series.unshift({
        name: SeriesNameMap.compareNewMemberRevenue,
        data: compareNewMemberRevenue,
        type: "column",
        stack: "compare",
        color: "#5C6AC4",
        pointStart,
      });
      
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "column",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "${text}",
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
        column: {
          stacking: "normal",
          states: {
            inactive: {
              enabled: false,
            },
          },
          point: {
            events: {
              mouseOver() {
                hoverAction(this, "hover");
              },
              mouseOut() {
                hoverAction(this, "");
              },
            },
          },
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const stackName = this.point.series.userOptions.stack;

          switch (stackName) {
            case "current": {
              const title = getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit);

              const oldMemberRevenue = currentOldMemberRevenue[pointIndex] || 0;
              const newMemberRevenue = currentNewMemberRevenue[pointIndex] || 0;
              const totalRevenue = oldMemberRevenue + newMemberRevenue;
              const oldMemberRevenuePer = Math.round((oldMemberRevenue / totalRevenue) * 1000) / 10; // 百分比取小數點第一位
              const newMemberRevenuePer = Math.round((newMemberRevenue / totalRevenue) * 1000) / 10;

              const oldMemberAvgOrderPrice = currentOldMemberRevenueObj[pointIndex].avgOrderPrice || 0;
              const newMemberAvgOrderPrice = currentNewMemberRevenueObj[pointIndex].avgOrderPrice || 0;
              const oldMemberOrder = currentOldMemberRevenueObj[pointIndex].order || 0;
              const newMemberOrder = currentNewMemberRevenueObj[pointIndex].order || 0;

              return (
                `${title}<br/>` +
                `新客營收 ${USCurrencyFormmater.format(newMemberRevenue)}(${newMemberRevenuePer}%)<br/>` +
                `舊客營收 ${USCurrencyFormmater.format(oldMemberRevenue)}(${oldMemberRevenuePer}%)<br/>` +
                `新客客單價 ${USCurrencyFormmater.format(newMemberAvgOrderPrice)}<br/>` +
                `舊客客單價 ${USCurrencyFormmater.format(oldMemberAvgOrderPrice)}<br/>` +
                `新客訂單數 ${newMemberOrder}<br/>` +
                `舊客訂單數 ${oldMemberOrder}`
              );
            }
            case "compare": {
              const title = getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);

              const oldMemberRevenue = compareOldMemberRevenue[pointIndex] || 0;
              const newMemberRevenue = compareNewMemberRevenue[pointIndex] || 0;
              const totalRevenue = oldMemberRevenue + newMemberRevenue;
              const oldMemberRevenuePer = Math.round((oldMemberRevenue / totalRevenue) * 1000) / 10; // 百分比取小數點第一位
              const newMemberRevenuePer = Math.round((newMemberRevenue / totalRevenue) * 1000) / 10;

              const oldMemberAvgOrderPrice = compareOldMemberRevenueObj[pointIndex].avgOrderPrice || 0;
              const newMemberAvgOrderPrice = compareNewMemberRevenueObj[pointIndex].avgOrderPrice || 0;
              const oldMemberOrder = compareOldMemberRevenueObj[pointIndex].order || 0;
              const newMemberOrder = compareNewMemberRevenueObj[pointIndex].order || 0;

              return (
                `${title}<br/>` +
                `新客營收 ${USCurrencyFormmater.format(newMemberRevenue)}(${newMemberRevenuePer}%)<br/>` +
                `舊客營收 ${USCurrencyFormmater.format(oldMemberRevenue)}(${oldMemberRevenuePer}%)<br/>` +
                `新客客單價 ${USCurrencyFormmater.format(newMemberAvgOrderPrice)}<br/>` +
                `舊客客單價 ${USCurrencyFormmater.format(oldMemberAvgOrderPrice)}<br/>` +
                `新客訂單數 ${newMemberOrder}<br/>` +
                `舊客訂單數 ${oldMemberOrder}`
              );
            }
            default:
              return "";
          }
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        useHTML: true,
      },
      series,
    });
  }, [memberRevenueChart, chartParams]);

  const fetchChart = useCallback(() => {
    dispatch(fetchMemberRevenueChart());
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <PageTitle title="Dashboard - 新舊客營收" />
        <ChartWrapper>
          <ChartFilter title="新舊客營收" fetchAction={fetchChart} />
          <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ style: { height: 500 } }} />
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default SalesRevenueChart;
