export enum ProcessValue {
  CLEAN = 0, // 待申請
  PENDING = 1, // 待審核
  APPROVE = 2, // 核准
  REJECT = 3, // 拒絕
}
export enum ProcessText {
  "待申請" = 0, // 待申請
  "待審核" = 1, // 待審核
  "審核通過" = 2, // 核准
  "審核不通過" = 3, // 拒絕
}

export enum DepartmentValue {
  "Urmart" = 1,
  "品運" = 2,
}
