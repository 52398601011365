export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export function transformCamelToSnake(input: object): any {
  if (typeof input === "string" || typeof input === "number" || typeof input === "boolean" || input === null) {
    return input;
  }
  if (Array.isArray(input)) {
    return input.map(transformCamelToSnake);
  }
  return Object.entries(input).reduce((accumulator, currentValue) => {
    const [key, value] = currentValue;
    const snakeKey = camelToSnakeCase(key);
    // eslint-disable-next-line operator-linebreak

    let snakeValue: any = value;
    if (Array.isArray(value)) snakeValue = value.map(transformCamelToSnake);
    else if (typeof value === "object" && value !== null) {
      snakeValue = transformCamelToSnake(value);
    }

    return {
      ...accumulator,
      [snakeKey]: snakeValue,
    };
  }, {});
}
