/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { RootState } from "@redux/rootReducer";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  clearMenuDetail,
  fetchMenuDetail,
  fetchCreateMenu,
  fetchUpdateMenu,
  initialState,
  fetchDeleteMenu,
  reset,
} from "@redux/menuManagementSlice";
import { Button, Checkbox, Form, Input, Modal, Switch, Col } from "antd";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 12px 12px 86px 22px;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
`;
const RowTitle = styled.div`
  flex: 2;
  line-height: 32px;
`;

const RowContent = styled.div`
  flex: 8;
  display: flex;
`;
const RequiredIcon = styled.span`
  color: red;
`;
const Footer = styled.div`
  position: fixed;
  right: 25px;
  bottom: 0;
  width: 100%;
  display: flex;
  padding: 13px 12px;
  align-items: center;
  justify-content: flex-end;
  background-color: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const CustomButton = styled(Button)`
  margin-right: 14px;
`;
const GreyText = styled.div`
  color: ${({ theme }) => theme.colorNeutral500};
`;

const EditTab = () => {
  const [form] = Form.useForm();
  const menuDetail = useSelector((state: RootState) => state.menuMangement.menuDetail);
  const selectedMenu = useSelector((state: RootState) => state.menuMangement.selectedMenu);
  const dispatch = useAppDispatch();

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const { isActive, name, link, openingNewTab } = formValues;

    const params = {
      isActive,
      name,
      parent,
      openingNewTab,
      link,
    };
    const updateParams = {
      isActive,
      name,
      parent,
      openingNewTab,
      link,
      id,
    };
    if (id < 0) {
      // id = -1 新增的分類
      dispatch(fetchCreateMenu(params));
    } else {
      dispatch(fetchUpdateMenu(updateParams));
    }
  };

  const { id, parent } = selectedMenu;

  useEffect(() => {
    if (id < 0) {
      // 新增分類 id = -1
      dispatch(clearMenuDetail());
      form.resetFields();
    } else {
      dispatch(fetchMenuDetail(id));
    }
  }, [dispatch, form, id]);

  useEffect(() => {
    form.setFieldsValue(menuDetail);
  }, [form, menuDetail]);

  const hendleDelete = (e: any) => {
    if (menuDetail.children?.length === 0) {
      // 如果node沒有chridren
      Modal.confirm({
        title: "你確定要刪除嗎？",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(fetchDeleteMenu(id));
          form.resetFields();
        },
      });
    } else {
      // 如果node有chridren
      Modal.confirm({
        title: "你確定要刪除嗎？",
        content: "目錄下有其他子目錄，移除此目錄將連帶移除涵蓋的子目錄，請問是否確定移除？",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(fetchDeleteMenu(id));
          form.resetFields();
        },
      });
    }
  };

  return (
    <Wrapper>
      <Form form={form} initialValues={initialState.menuDetail} onFinish={handleOnSubmit}>
        <Row>
          <RowTitle>啟用</RowTitle>
          <RowContent>
            <Form.Item name="isActive" valuePropName="checked">
              <Switch />
            </Form.Item>
          </RowContent>
        </Row>

        <Row>
          <RowTitle>
            目錄名稱
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent>
            <Form.Item name="name" style={{ width: "90%" }} rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>

        <Row>
          <RowTitle>
            連結
            <RequiredIcon>*</RequiredIcon>
          </RowTitle>
          <RowContent>
            <Form.Item name="link" style={{ width: "90%" }} rules={[{ required: true, message: "" }]}>
              <Input />
            </Form.Item>
          </RowContent>
        </Row>
        <Row>
          <Form.Item name="openingNewTab" valuePropName="checked">
            <Checkbox>另開分頁</Checkbox>
          </Form.Item>
        </Row>
        <Row>
          <GreyText>*按下「建立新資料」按鈕後新目錄才會顯示前台</GreyText>
        </Row>

        <Footer>
          <CustomButton onClick={hendleDelete}>{id < 0 ? null : "刪除"}</CustomButton>

          <Button type="primary" htmlType="submit">
            {id < 0 ? "建立資料" : "儲存此分頁"}
          </Button>
        </Footer>
      </Form>
    </Wrapper>
  );
};

export default EditTab;
