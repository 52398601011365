import { fetchVendorSourceFile } from "@api/vendorApi";
import downloadCSV from "@utils/downloadCSV";
import { Button, Table } from "antd";
import React from "react";

export default function VendorSource(props: { vendorId: string; source: any }) {
  const { vendorId, source } = props;

  function handleClick(modeId: number) {
    fetchVendorSourceFile(parseInt(vendorId, 10), modeId)
      .then((res) => {
        downloadCSV(res);
      })
      .catch(() => {
        // handle error
      });
  }
  const columns = [
    {
      title: "合作模式",
      dataIndex: "modeName",
      width: 200,
      key: "modeName",
    },
    {
      title: "商品數",
      dataIndex: "productQty",
      key: "productQty",
      render: (qty: number, record: any) => (
        <div>
          <span>{qty}</span>
          <Button type="link" onClick={() => handleClick(record.mode)}>
            下載檔案
          </Button>
        </div>
      ),
    },
  ];

  return <Table columns={columns} dataSource={source} pagination={false} />;
}
