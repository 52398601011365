/* eslint-disable camelcase */

import { Currency } from "./normalizeWarehouse";

export enum TransportMode {
  AIR = 1,
  WATER = 2,
}

export const TransportModeMap = {
  [TransportMode.AIR]: "空運",
  [TransportMode.WATER]: "海運",
};

export const convertTransportMode = (mode: TransportMode) => {
  switch (mode) {
    case TransportMode.AIR:
      return "空運";
    case TransportMode.WATER:
      return "海運";
    default:
      return "";
  }
};

export enum ApprovalStatus {
  PENDING = 1,
  FINISH = 2,
}

export interface CustomsClearances {
  id: number;
  vendor: number;
  vendorName: string;
  currency: Currency;
  currencyRate: number;
  tariffNumber: string;
  tariff: number;
  shippingFee: number;
  processingFee: number;
  transportMode: TransportMode;
  transportModeText: string;
  approvalStatus: ApprovalStatus;
  isCostRecalculated: boolean;
  totalProcessingFee: number;
  totalRebate: number;
}

interface NormalizeCustomsClearance {
  customsClearances: (
    response: {
      id: number;
      vendor: number;
      vendor_name: string;
      currency: Currency;
      currency_rate: number;
      tariff_number: string;
      tariff: number;
      shipping_fee: number;
      processing_fee: number;
      transport_mode: TransportMode;
      approval_status: ApprovalStatus;
      is_cost_recalculated: boolean;
      total_processing_fee: number;
      total_rebate: number;
    }[],
  ) => CustomsClearances[];
  customsClearancesInfo: (response: {
    id: number;
    vendor: number;
    vendor_name: string;
    currency: Currency;
    currency_rate: number;
    tariff_number: string;
    tariff: number;
    shipping_fee: number;
    processing_fee: number;
    transport_mode: TransportMode;
    approval_status: ApprovalStatus;
    is_cost_recalculated: boolean;
    total_processing_fee: number;
    total_rebate: number;
  }) => CustomsClearances;
}

const normalizeCustomsClearance: NormalizeCustomsClearance = {
  customsClearances: (response) => {
    return response.map((item) => ({
      id: item.id,
      vendor: item.vendor,
      vendorName: item.vendor_name,
      currency: item.currency,
      currencyRate: item.currency_rate,
      tariffNumber: item.tariff_number,
      tariff: item.tariff,
      shippingFee: item.shipping_fee,
      processingFee: item.processing_fee,
      transportMode: item.transport_mode,
      transportModeText: convertTransportMode(item.transport_mode),
      approvalStatus: item.approval_status,
      isCostRecalculated: item.is_cost_recalculated,
      totalProcessingFee: item.total_processing_fee,
      totalRebate: item.total_rebate,
    }));
  },
  customsClearancesInfo: (response) => ({
    id: response.id,
    vendor: response.vendor,
    vendorName: response.vendor_name,
    currency: response.currency,
    currencyRate: response.currency_rate,
    tariffNumber: response.tariff_number,
    tariff: response.tariff,
    shippingFee: response.shipping_fee,
    processingFee: response.processing_fee,
    transportMode: response.transport_mode,
    transportModeText: convertTransportMode(response.transport_mode),
    approvalStatus: response.approval_status,
    isCostRecalculated: response.is_cost_recalculated,
    totalProcessingFee: response.total_processing_fee,
    totalRebate: response.total_rebate,
  }),
};

export default normalizeCustomsClearance;
