import CoreAPI from "./CoreAPI";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";

const apiClient = new CoreAPI();

export enum ShippingTypeValueByNumber {
  HOME_DELIVERY = 1,
  SEVEN_ELEVEN = 2,
  CVS = 3,
  RETURN = 4,
}
interface ShippingFeeApi {
  fetchShippingFeeList: (prarms: ShippingFeeListParam) => Promise<ShippingFeeListResult>;
  createShippingFeeItem: (params: CreateShippingFeeListParam) => Promise<ShippingFeeListResult>;
  updateShippingFeeItem: (params: UpdateShippingFeeListParam) => Promise<ShippingFeeListResult>;
  deleteShippingFeeItem: (params: DeleteShippingFeeListParam) => Promise<ShippingFeeListResult>;
}

export interface ShippingFeeListParam {
  limit: number;
  offset: number;
}

export interface ShippingFeeListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: ShippingFeeListItem[];
}
export interface ShippingFeeListItem {
  id: number;
  name: string;
  isActive: boolean;
  startAt: string;
  endAt: string;
  shippingFee: number;
  shippingThreshold: number;
  logisticMethod: number;
}

export interface UpdateShippingFeeListParam {
  id: number;
  name: string;
  isActive: boolean;
  startAt: string;
  endAt: string;
  shippingThreshold: number;
  shippingFee: number;
  logisticMethod: number;
}
export interface DeleteShippingFeeListParam {
  shippingFeeRuleIds: number[];
}
export interface CreateShippingFeeListParam {
  name: string;
  isActive: boolean;
  startAt: string;
  endAt: string;
  shippingThreshold: number;
  shippingFee: number;
  logisticMethod: number;
}

const systemApi: ShippingFeeApi = {
  fetchShippingFeeList: async (params) => {
    const { limit, offset } = params;
    const getParams = {
      limit,
      offset,
    };
    const response = await apiClient.getData("/manage/shipping-fee-rules/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  createShippingFeeItem: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.postData("/manage/shipping-fee-rules/", requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  updateShippingFeeItem: async (param) => {
    const requestParam = transformCamelToSnake(param);
    const response = await apiClient.patchData(`/manage/shipping-fee-rules/${param.id}/`, requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
  deleteShippingFeeItem: async (param) => {
    const requestParam = { shipping_fee_rule_ids: param };
    const response = await apiClient.putData("/manage/shipping-fee-rules/batch-delete/", requestParam);
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default systemApi;
