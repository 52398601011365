import React, { useEffect } from "react";
import styled from "styled-components";
import { Button, DatePicker, Form, Input, Modal } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import { RootState } from "@redux/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { fetchAddProduct, fetchProductDetail } from "@redux/brandOperation/PcmSlice";
import moment from "moment";

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;
const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;

type Props = {
  visible: boolean;
  onClose: () => void;
  productId?: number;
};

const EditPopup = (props: Props) => {
  const { visible, onClose, productId } = props;
  const dispatch = useDispatch();
  const { id: pcmId } = useParams();

  const [form] = Form.useForm();
  const { productDetail } = useSelector((state: RootState) => state.pcmSlice);

  useEffect(() => {
    if (productId) {
      dispatch(fetchProductDetail({ pcmId, productId }));
    }
  }, [dispatch, pcmId, productId]);

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const payload = {
      arrivedDate: formValues.arrivedDate && formValues.arrivedDate.format("YYYY-MM-DD"),
      arrivedQty: formValues.arrivedQty,
      id: productId,
    };
    dispatch(fetchAddProduct({ addItem: payload, pcmId }));
  };

  useEffect(() => {
    form.setFieldsValue({
      arrivedQty: productDetail.arrivedQty,
      arrivedDate: productDetail.arrivedDate && moment(productDetail.arrivedDate),
    });
  }, [form, productDetail.arrivedDate, productDetail.arrivedQty]);

  return (
    <Modal width={450} visible={visible} closable={false} footer={false}>
      <Wrapper
        labelAlign="left"
        colon={false}
        onFinish={handleOnSubmit}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 25 }}
      >
        <Form.Item label="實際到貨數量" name="arrivedQty">
          <Input />
        </Form.Item>
        <Form.Item label="實際到貨時間" name="arrivedDate">
          <DatePicker locale={locale} style={{ width: "100%" }} />
        </Form.Item>
        <Footer>
          <Button
            style={{
              marginRight: "14px",
            }}
            onClick={() => onClose()}
          >
            取消
          </Button>
          <Button type="primary" htmlType="submit">
            儲存
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default EditPopup;
