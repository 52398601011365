import { Currency, Purchase, PurchaseStatus } from "@api/utils/normalizeWarehouse";
import { Row } from "antd";
import React from "react";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const ContentWrapper = styled.div`
  padding: 27px 35px;
`;

const Text = styled.p`
  width: 133px;
  margin-bottom: 33px;
  ${fontStyle("14px", "16px")};
`;

const Content = styled(Text)`
  width: max-content;
`;

interface Props {
  purchase?: Purchase;
}

const convertPurchaseStatus = (status?: PurchaseStatus) => {
  switch (status) {
    case PurchaseStatus.NORMAL:
      return "正常";
    case PurchaseStatus.INVALID:
      return "作廢";
    case PurchaseStatus.DESTROYED:
      return "人工作廢";
    case PurchaseStatus.DEPLOY_SUCCESS:
      return "已拋單";
    case PurchaseStatus.FINISH:
      return "已送達";
    default:
      return "";
  }
};

const convertCurrency = (currency?: Currency) => {
  switch (currency) {
    case Currency.NTD:
      return "台幣";
    case Currency.USD:
      return "美金";
    case Currency.EUR:
      return "歐元";
    case Currency.GBP:
      return "英鎊";
    case Currency.AUD:
      return "澳幣";
    case Currency.JPY:
      return "日圓";
    case Currency.KRW:
      return "韓圓";
    default:
      return "";
  }
};

const convertSpecPeriod = (specPeriod: number | undefined) => {
  switch (specPeriod) {
    case 9:
      return "9-10點";
    case 10:
      return "10-11點";
    case 13:
      return "13-14點";
    case 14:
      return "14-15點";
    case 15:
      return "15-16點";
    default:
      return "";
  }
};

export default function WarehouseInfo(props: Props) {
  const { purchase } = props;

  return (
    <ContentWrapper>
      <Row align="middle">
        <Text>LMS編號</Text>
        <Content>{purchase?.stoNumber || "N/A"}</Content>
      </Row>
      <Row align="middle">
        <Text>開單人員</Text>
        <Content>{purchase?.staff?.name || "N/A"}</Content>
      </Row>
      <Row align="middle">
        <Text>申請狀態</Text>
        <Content>{convertPurchaseStatus(purchase?.status)}</Content>
      </Row>
      <Row align="middle">
        <Text>廠商名稱</Text>
        <Content>{purchase?.vendor?.name}</Content>
      </Row>
      <Row align="middle">
        <Text>商開負責人</Text>
        <Content>{purchase?.vendor?.owner?.name}</Content>
      </Row>
      <Row align="middle">
        <Text>採購商品幣別</Text>
        <Content>{convertCurrency(purchase?.currency)}</Content>
      </Row>
      <Row align="middle">
        <Text>預計進倉日期</Text>
        <Content>{purchase?.specDate}</Content>
      </Row>
      <Row align="middle">
        <Text>預計進倉時段</Text>
        <Content>{convertSpecPeriod(purchase?.specPeriod)}</Content>
      </Row>
      <Row align="middle">
        <Text>倉庫進倉日期</Text>
        <Content>{purchase?.stockinDate || "N/A"}</Content>
      </Row>
      <Row align="middle">
        <Text>實際入庫時間</Text>
        <Content>{purchase?.actStockinDate || "N/A"}</Content>
      </Row>
      <Row align="middle">
        <Text>申請日期</Text>
        <Content>{purchase?.applicationDate || "N/A"}</Content>
      </Row>
    </ContentWrapper>
  );
}
