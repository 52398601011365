import { setResetVipRuleListParams, setVipRuleListParams, VipRuleState } from "@redux/vipRuleSlice";
import { Button, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 24px 0px 0px 16px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function VipRuleListFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();
  const { vipRuleListParams } = useSelector(VipRuleState);

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(setResetVipRuleListParams());
  };

  const onSubmit = (values: any) => {
    dispatch(
      setVipRuleListParams({
        ...vipRuleListParams,
        titleQ: values.titleQ,
        id: values.id,
        isActive: values.isActive === undefined || values.isActive === -100 ? undefined : !!values.isActive,
        redeemCode: values.redeemCode,
        startAt: values.usePeriod ? values.usePeriod[0].format("YYYY-MM-DD HH:mm:ss") : undefined,
        endAt: values.usePeriod ? values.usePeriod[1].format("YYYY-MM-DD HH:mm:ss") : undefined,
      }),
    );
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 9 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="ID" name="id">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="名稱" name="titleQ">
            <Input />
          </Form.Item>
        </Col>
        <Col span={7}>
          <Form.Item label="Redeem Code" name="redeemCode">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={7}>
          <Form.Item label="啟用" name="isActive">
            <Select defaultValue={-100}>
              <Select.Option value={-100}>請選擇</Select.Option>
              <Select.Option value={0}>下架</Select.Option>
              <Select.Option value={1}>上架</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="使用時間" name="usePeriod" labelCol={{ span: 4 }}>
            <DatePicker.RangePicker
              locale={locale}
              format="YYYY-MM-DD HH:mm:ss"
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
              }}
              style={{ width: "380px", marginLeft: "24px" }}
            />
          </Form.Item>
        </Col>
        <Col span={5}>
          <Row justify="end">
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
