import vipPromotionAPI, {
  BatchDeleteVipBulletinPayload,
  BatchDeleteVipGiftPayload,
  BatchUpdateVipBulletinStatusPayload,
  BatchUpdateVipGiftStatusPayload,
  CreateVipBulletinPayload,
  CreateVipGiftPayload,
  UpdateVipPromotionPayload,
  VipBulletinListResult,
  VipGiftListFilter,
  VipGiftListResult,
  VipPromotion,
} from "@api/vipPromotionApi";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import type { RootState } from "./rootReducer";

interface IState {
  isFetching: boolean;
  vipGiftListResult: VipGiftListResult;
  vipGiftListFilter: VipGiftListFilter;
  vipBulletinListFilter: VipGiftListFilter;
  vipBulletinListResult: VipBulletinListResult;
  vipPromotion: VipPromotion[];
}

const initialState: IState = {
  isFetching: false,
  vipBulletinListFilter: {
    limit: 20,
    offset: 0,
  },
  vipGiftListFilter: {
    limit: 20,
    offset: 0,
  },
  vipGiftListResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  vipBulletinListResult: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  vipPromotion: [],
};

export const fetchVipGiftList = createAsyncThunk(
  "vipPromotionSlice/fetchVipGiftList",
  async (payload: { limit: number; offset: number }) => {
    const response = await vipPromotionAPI.fetchVipGiftList(payload);
    return response;
  },
);

export const fetchCreateVipGift = createAsyncThunk(
  "vipPromotionSlice/fetchCreateVipGift",
  async (payload: CreateVipGiftPayload, thunkApi) => {
    await vipPromotionAPI.fetchCreateVipGift(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipGiftList(vipPromotionSlice.vipGiftListFilter));
    return "Success";
  },
);

export const fetchUpdateVipGift = createAsyncThunk(
  "vipPromotionSlice/fetchUpdateVipGift",
  async (payload: CreateVipGiftPayload & { vipGiftId: number }, thunkApi) => {
    await vipPromotionAPI.fetchUpdateVipGift(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipGiftList(vipPromotionSlice.vipGiftListFilter));
    return "Success";
  },
);

export const fetchBatchUpdateVipGiftStatus = createAsyncThunk(
  "vipPromotionSlice/fetchBatchUpdateVipGiftStatus",
  async (payload: BatchUpdateVipGiftStatusPayload, thunkApi) => {
    await vipPromotionAPI.fetchBatchUpdateVipGiftStatus(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipGiftList(vipPromotionSlice.vipGiftListFilter));
    return "Success";
  },
);

export const fetchBatchDeleteVipGift = createAsyncThunk(
  "vipPromotionSlice/fetchBatchDeleteVipGift",
  async (payload: BatchDeleteVipGiftPayload, thunkApi) => {
    await vipPromotionAPI.fetchBatchDeleteVipGift(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipGiftList(vipPromotionSlice.vipGiftListFilter));
    return "Success";
  },
);

export const fetchVipBulletinList = createAsyncThunk(
  "vipPromotionSlice/fetchVipBulletinList",
  async (payload: VipGiftListFilter) => {
    const response = await vipPromotionAPI.fetchVipBulletinList(payload);
    return response;
  },
);

export const fetchCreateVipBulletin = createAsyncThunk(
  "vipPromotionSlice/fetchCreateVipBulletin",
  async (payload: CreateVipBulletinPayload, thunkApi) => {
    await vipPromotionAPI.fetchCreateVipBulletin(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipBulletinList(vipPromotionSlice.vipBulletinListFilter));
    return "Success";
  },
);

export const fetchUpdateVipBulletin = createAsyncThunk(
  "vipPromotionSlice/fetchUpdateVipBulletin",
  async (payload: CreateVipBulletinPayload & { vipBulletinId: number }, thunkApi) => {
    await vipPromotionAPI.fetchUpdateVipBulletin(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipBulletinList(vipPromotionSlice.vipBulletinListFilter));
    return "Success";
  },
);

export const fetchBatchUpdateVipBulletinStatus = createAsyncThunk(
  "vipPromotionSlice/fetchBatchUpdateVipBulletinStatus",
  async (payload: BatchUpdateVipBulletinStatusPayload, thunkApi) => {
    await vipPromotionAPI.fetchBatchUpdateVipBulletinStatus(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipBulletinList(vipPromotionSlice.vipBulletinListFilter));
    return "Success";
  },
);

export const fetchBatchDeleteVipBulletin = createAsyncThunk(
  "vipPromotionSlice/fetchBatchDeleteVipBulletin",
  async (payload: BatchDeleteVipBulletinPayload, thunkApi) => {
    await vipPromotionAPI.fetchBatchDeleteVipBulletin(payload);
    const { vipPromotionSlice } = thunkApi.getState() as RootState;
    await thunkApi.dispatch(fetchVipBulletinList(vipPromotionSlice.vipBulletinListFilter));
    return "Success";
  },
);

export const fetchVipPromotion = createAsyncThunk("vipPromotionSlice/fetchVipPromotion", async () => {
  const response = await vipPromotionAPI.fetchVipPromotion();
  return response;
});

export const fetchUpdateVipPromotion = createAsyncThunk(
  "vipPromotionSlice/fetchUpdateVipPromotion",
  async (payload: UpdateVipPromotionPayload, thunkApi) => {
    try {
      const response = await vipPromotionAPI.fetchUpdateVipPromotion(payload);
      message.success("更新成功");
      return response;
    } catch (error: any) {
      message.error(`更新失敗: ${error.message}`);
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

const vipPromotionSlice = createSlice({
  name: "vipPromotion",
  initialState,
  reducers: {
    setVipGiftListFilter: (state, action) => {
      state.vipGiftListFilter = action.payload;
    },
    setVipBulletinListFilter: (state, action) => {
      state.vipBulletinListFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVipGiftList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipGiftList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.vipGiftListResult = action.payload;
    });
    builder.addCase(fetchVipBulletinList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipBulletinList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.vipBulletinListResult = action.payload;
    });
    builder.addCase(fetchVipPromotion.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchVipPromotion.fulfilled, (state, action) => {
      state.isFetching = false;
      state.vipPromotion = action.payload;
    });
    builder.addCase(fetchUpdateVipPromotion.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchUpdateVipPromotion.fulfilled, (state) => {
      state.isFetching = false;
    });
  },
});

export const VipRuleState = (state: RootState) => state.vipPromotionSlice;
export const { setVipGiftListFilter, setVipBulletinListFilter } = vipPromotionSlice.actions;
export default vipPromotionSlice.reducer;
