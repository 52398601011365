import productCategoryApi, {
  CreateProductCategoryParam,
  ProductCategoryDetail,
  ProductCategoryNode,
  UpdateProductCategoryParam,
} from "@api/productCategoryApi";
import tagApi, { TagTypeListParam, TagTypeListResult } from "@api/tagApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IState {
  hasNewNode: boolean;
  categoryList: ProductCategoryNode[];
  selectedCategory: {
    id: number;
    parent: number;
    level: number;
  };
  categoryDetail: ProductCategoryDetail;
  tagTypeListResult: TagTypeListResult;
  tagTypeListParam: TagTypeListParam;
  isFetching: boolean;
}

const initialState: IState = {
  hasNewNode: false,
  categoryList: [],
  selectedCategory: {
    id: 0,
    parent: -1,
    level: 0,
  },
  categoryDetail: {
    id: -1,
    name: "",
    tagTypes: [],
    tagTypesAttributes: {},
  },
  tagTypeListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  tagTypeListParam: {
    limit: 20,
    offset: 0,
  },
  isFetching: false,
};

export const fetchProductCategoryList = createAsyncThunk("productCategory/fetchProductCategoryList", async () => {
  const params = {
    withProductCount: true,
  };
  const response = await productCategoryApi.getProductCategoryList(params);
  return response;
});

export const fetchTagTypeList = createAsyncThunk("productCategory/fetchTagTypeList", async (_, thunkApi) => {
  const pagingParam = {
    limit: initialState.tagTypeListParam.limit,
    offset: initialState.tagTypeListParam.offset,
  };

  thunkApi.dispatch(updateTagTypeListParam(pagingParam));
  const response = await tagApi.fetchTagTypeList(pagingParam);
  return response;
});

export const loadingMoreTagTypeList = createAsyncThunk(
  "productCategory/loadingMoreTagTypeList",
  async (_, thunkApi) => {
    const {
      productCategory: { tagTypeListParam },
    } = thunkApi.getState() as RootState;

    const pagingParam = {
      ...tagTypeListParam,
      offset: tagTypeListParam.offset + tagTypeListParam.limit,
    };

    thunkApi.dispatch(updateTagTypeListParam(pagingParam));
    const response = await tagApi.fetchTagTypeList(pagingParam);
    return response;
  },
);

export const fetchCategoryDetail = createAsyncThunk("productCategory/fetchCategoryDetail", async (id: number) => {
  const response = await productCategoryApi.getCategoryDetail(id);
  return response;
});

export const createProductCategory = createAsyncThunk(
  "productCategory/createProductCategory",
  async (params: CreateProductCategoryParam, thunkApi) => {
    try {
      const response = await productCategoryApi.createProductCategory(params);
      const {
        productCategory: {
          selectedCategory: { parent, level },
        },
      } = thunkApi.getState() as RootState;

      const newSelectedCategory = {
        id: response.id,
        parent,
        level,
      };

      thunkApi.dispatch(updateSelectedCategory(newSelectedCategory));
      thunkApi.dispatch(fetchProductCategoryList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateProductCategory = createAsyncThunk(
  "productCategory/updateProductCategory",
  async (params: UpdateProductCategoryParam, thunkApi) => {
    try {
      const response = await productCategoryApi.updateProductCategory(params);
      thunkApi.dispatch(fetchProductCategoryList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const productCategorySlice = createSlice({
  name: "productCategory",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    addNewCategory: (state, action: PayloadAction<ProductCategoryNode>) => {
      const { parent } = action.payload;
      let parentNode: ProductCategoryNode | undefined;
      state.categoryList.forEach((ctgy) => {
        if (ctgy.key === parent) parentNode = ctgy;

        if (ctgy.children) {
          ctgy.children.forEach((subCtgy) => {
            if (subCtgy.key === parent) parentNode = subCtgy;

            if (subCtgy.children) {
              subCtgy.children.forEach((subsubCtgy) => {
                if (subsubCtgy.key === parent) parentNode = subsubCtgy;
              });
            }
          });
        }
      });
      if (parentNode && parentNode.children) parentNode.children.push(action.payload);
      else if (parentNode) parentNode.children = [action.payload];
      else state.categoryList.push(action.payload); // 沒有parentNode表示要新增的點在第一層

      state.hasNewNode = true;
    },
    clearCategoryDetail: (state) => {
      state.categoryDetail = initialState.categoryDetail;
    },
    updateSelectedCategory: (state, action: PayloadAction<{ id: number; parent: number; level: number }>) => {
      state.selectedCategory = action.payload;
    },
    updateTagTypeListParam: (state, action: PayloadAction<TagTypeListParam>) => {
      state.tagTypeListParam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProductCategoryList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchProductCategoryList.fulfilled, (state, action) => {
      state.categoryList = action.payload;
      state.isFetching = false;
    });
    builder.addCase(fetchTagTypeList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchTagTypeList.fulfilled, (state, action) => {
      state.tagTypeListResult = action.payload;
      state.isFetching = false;
    });
    builder.addCase(loadingMoreTagTypeList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loadingMoreTagTypeList.fulfilled, (state, action) => {
      state.tagTypeListResult = {
        ...action.payload,
        results: state.tagTypeListResult.results.concat(action.payload.results),
      };
      state.isFetching = false;
    });
    builder.addCase(fetchCategoryDetail.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCategoryDetail.fulfilled, (state, action) => {
      state.categoryDetail = action.payload;
      state.isFetching = false;
    });
    builder.addCase(createProductCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(createProductCategory.fulfilled, (state, action) => {
      state.categoryDetail = action.payload;
      state.isFetching = false;
      state.hasNewNode = false;
    });
    builder.addCase(updateProductCategory.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(updateProductCategory.fulfilled, (state, action) => {
      state.categoryDetail = action.payload;
      state.isFetching = false;
    });
  },
});

export const {
  reset,
  addNewCategory,
  clearCategoryDetail,
  updateSelectedCategory,
  updateTagTypeListParam,
} = productCategorySlice.actions;
export default productCategorySlice.reducer;
