/* eslint-disable camelcase */

import { ContractType } from "@api/salesReportApi";

export enum ContractMode {
  Domestic = 1,
  Foreign = 2,
  Warehouse = 3,
  Vendor = 4,
}

export const modeList: { [key: number]: string } = {
  1: "國內買斷",
  2: "國外買斷",
  3: "寄倉",
  4: "轉單",
};

export enum DeductionType {
  Maintain = 1,
  Sales = 2,
  Activity = 3,
  Payment = 4,
}

export const deductionTypeList: { [key: number]: string } = {
  1: "平台維護費",
  2: "金流處理費",
  3: "行銷推廣費",
  4: "活動贊助費",
};

export interface Contract {
  id: number;
  displayName: string;
  mode: ContractMode;
  modeName: string;
  version: string;
  note: string;
  isActive: boolean;
  deductions: {
    deductType: number;
    displayName: string;
    rate: number;
  }[];
  totalDeductionRate: number;
  type: ContractType;
}

export interface Deduction {
  id: number;
  displayName: string;
  deductType: DeductionType;
  deductRate: number;
  description: string;
  contractId: number;
  contractDisplayName: string;
  periodStartMonth: number;
  periodStartDay: number;
  periodEndMonth: number;
  periodEndDay: number;
  startAt: string;
  endAt: string;
}

interface NormalizeContract {
  contractList: (
    list: {
      id: number;
      display_name: string;
      mode: number;
      version: string;
      note: string;
      is_active: boolean;
      deduction_info: {
        deductions: {
          deduct_type: number;
          display_name: string;
          rate: number;
        }[];
        total_deduction_rate: number;
      };
      type: ContractType;
    }[],
  ) => Contract[];
  deductionList: (
    list: {
      id: number;
      display_name: string;
      deduct_type: number;
      deduct_rate: number;
      description: string;
      contract_info: {
        id: number;
        display_name: string;
      };
      period_start_month: number;
      period_start_day: number;
      period_end_month: number;
      period_end_day: number;
    }[],
  ) => Deduction[];
  contractDetail: (contract: {
    id: number;
    display_name: string;
    mode: number;
    version: string;
    note: string;
    is_active: boolean;
    deduction_info: {
      deductions: {
        deduct_type: number;
        display_name: string;
        rate: number;
      }[];
      total_deduction_rate: number;
    };
    type: ContractType;
  }) => Contract;
}

const formatTime = (type: "start" | "end", month: number, day: number): string => {
  if (!month && !day) return "";
  const date = `${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`;
  const time = type === "start" ? " 00:00:00" : "23:59:59";
  return `${date} ${time}`;
};

const normalizeContract: NormalizeContract = {
  contractList: (list) => {
    return list.map((item) => ({
      id: item.id,
      displayName: item.display_name,
      mode: item.mode,
      modeName: modeList[item.mode],
      version: item.version,
      note: item.note,
      isActive: item.is_active,
      deductions: item.deduction_info.deductions.map((deduction) => ({
        deductType: deduction.deduct_type,
        displayName: deduction.display_name,
        rate: deduction.rate,
      })),
      totalDeductionRate: item.deduction_info.total_deduction_rate,
      type: item.type,
    }));
  },
  deductionList: (list) => {
    return list.map((item) => ({
      id: item.id,
      displayName: item.display_name,
      deductType: item.deduct_type,
      deductRate: item.deduct_rate,
      description: item.description,
      contractId: item.contract_info.id,
      contractDisplayName: item.contract_info.display_name,
      periodStartMonth: item.period_start_month, // 以下四個時間值為全有或全空
      periodEndMonth: item.period_end_month,
      periodStartDay: item.period_start_day,
      periodEndDay: item.period_end_day,
      startAt: formatTime("start", item.period_start_month, item.period_start_day),
      endAt: formatTime("end", item.period_end_month, item.period_end_day),
    }));
  },
  contractDetail: (contract) => ({
    id: contract.id,
    displayName: contract.display_name,
    mode: contract.mode,
    modeName: modeList[contract.mode],
    version: contract.version,
    note: contract.note,
    isActive: contract.is_active,
    deductions: contract.deduction_info.deductions.map((deduction) => ({
      deductType: deduction.deduct_type,
      displayName: deduction.display_name,
      rate: deduction.rate,
    })),
    totalDeductionRate: contract.deduction_info.total_deduction_rate,
    type: contract.type,
  }),
};

export default normalizeContract;
