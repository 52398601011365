/* eslint-disable @typescript-eslint/no-unused-vars */
import { CaretDownOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { OrderSalesPlans, SubOrder } from "@api/utils/normalizeOrder";
import { CURRENT_SUB_ORDER } from "@constant/StorageKeyValue";
import SaleRuleType from "@page/Order/SaleRuleType";
import {
  changeOrderShippingStatus,
  fetchOrderDelete,
  fetchOrderDeploy,
  fetchRejectTransfer,
  orderState,
} from "@redux/orderSlice";
import { hideLoading, showLoading } from "@redux/notifySlice";

import Session from "@utils/SessionStorage";
import { Button, Modal, Row, Table, Timeline } from "antd";
import moment from "moment";
import { LogisticHistoriesNumber } from "@page/Order/LogisticHistories";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { ColumnsType } from "antd/lib/table";
import { ShippingTypeValueByNumber, transformShippingCode } from "../ShippingTypeValue";
import VendorLogisticsValue from "../VendorLogisticsValue";
import CVSPopup from "./CVSPopup";
import ReturnPopup from "./ReturnPopup";
import OrderPlanQty from "./OrderPlanQty";

const Wrapper = styled.div`
  margin-bottom: 61px;
`;

const CustomRow = styled(Row)`
  margin-bottom: 16px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const Vendor = styled.div`
  margin-right: 14px;
  ${fontStyle("16px", "22px", "bold")};
`;

const HistoriesWrapper = styled.div`
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  width: 300px;
  min-height: 200px;
  padding: 18px 14px;
  margin-top: 6px;
  display: none;
  position: absolute;
  top: 100%;
  z-index: 10;

  &::before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 3.5px 5px 3.5px;
    border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
  }

  .ant-timeline-item-content {
    display: flex;
  }
`;

const Text = styled.div<{ hoverLight?: boolean }>`
  margin-bottom: 16px;
  position: relative;
  ${fontStyle("14px", "22px")};

  &:hover {
    cursor: ${({ hoverLight }) => hoverLight && "pointer"};
    color: ${({ hoverLight, theme }) => (hoverLight ? "#EC6922" : theme.colorNeutral600)};

    ${HistoriesWrapper} {
      display: block;
    }
  }
`;

const SalePageLink = styled.a`
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
`;

const OrderInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const TableWrapper = styled.div`
  margin-bottom: 20px;
`;

const TimelineItem = styled(Timeline.Item)`
  color: ${({ theme }) => theme.colorNeutral100};
  ${fontStyle("13px", "22px")};
`;

const InlineItem = styled.div`
  width: 100px;
`;

enum ShippingStatus {
  PICKED_UP = 80,
}

type Props = {
  order: SubOrder;
};

export default function SubOrderTab(props: Props) {
  const { order } = props;
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { orderId } = useParams();
  const [openPlanQty, setOpenPlanQty] = useState<boolean>(false);
  const [openCVS, setOpenCVS] = useState<boolean>(false);
  const [showReturn, setShowReturn] = useState<boolean>(false);

  const { isFetching } = useSelector(orderState);

  const ifCanReturn =
    (order.logistic === VendorLogisticsValue.warehouse && order.shippingStatus === LogisticHistoriesNumber.PICKED_UP) ||
    (VendorLogisticsValue.warehouse && order.shippingStatus === LogisticHistoriesNumber.FINISH);

  const columns: ColumnsType<OrderSalesPlans> = [
    {
      key: "salesPageName",
      dataIndex: "salesPageName",
      title: "銷售頁名稱",
      render: (
        value: string,
        record, // TODO 銷售頁後台連結
      ) => (
        <SalePageLink href={`/salespage/edit/${record.salesPageId}`} target="_blank">
          {value}
        </SalePageLink>
      ),
    },
    {
      key: "salesPlanName",
      dataIndex: "salesPlanName",
      title: "方案名稱",
    },
    {
      key: "qty",
      dataIndex: "qty",
      align: "center",
      title: "訂購數量",
      width: 100,
    },
    {
      key: "canceledQty",
      dataIndex: "canceledQty",
      align: "center",
      title: "已取消數量",
      width: 110,
    },
    {
      key: "backOrderQty",
      dataIndex: "backOrderQty",
      align: "center",
      title: "已退貨數量",
      width: 110,
    },
    {
      key: "optionsText",
      dataIndex: "optionsText",
      title: "選項內容",
    },
    {
      key: "price",
      dataIndex: "price",
      title: "方案售價",
      width: 100,
      render: (value: OrderSalesPlans["price"], record) => {
        return record.salesPlanRuleType === SaleRuleType.GIFT ? "N/A" : value;
      },
    },
    {
      key: "specialPrice",
      dataIndex: "price",
      title: "方案優惠價",
      width: 120,
      render: (value: OrderSalesPlans["price"], record) => {
        return record.salesPlanRuleType === SaleRuleType.GIFT ? "N/A" : value - record.salesDiscount;
      },
    },
    {
      key: "vipPrice",
      dataIndex: "price",
      title: "方案黑卡價",
      width: 120,
      render: (value: OrderSalesPlans["price"], record: OrderSalesPlans) => {
        return record.salesPlanRuleType === SaleRuleType.GIFT
          ? "N/A"
          : value - record.salesDiscount - record.memberGroupDiscount;
      },
    },
  ];

  const shippingText = useMemo(() => {
    switch (order.shippingMethod) {
      case ShippingTypeValueByNumber.HOME_DELIVERY:
        return `${order.code} ${order.address}`;
      case ShippingTypeValueByNumber.SEVEN_ELEVEN:
        return `${transformShippingCode(order.shippingMethod)}, ${order.code} ${order.storeName} ${order.address}`;
      case ShippingTypeValueByNumber.CVS:
        return `${order.code} ${order.storeName} ${order.address}`;
      default:
        return "";
    }
  }, [order]);

  const reverseLogisticHistories = useMemo(() => {
    const reversed = [...order.logisticHistories].reverse();
    return reversed.map((item) => (
      <TimelineItem key={item.updatedTime}>
        <InlineItem>{`${item.status}`}</InlineItem>
        <div>{`${moment(item.updatedTime).format("YYYY-MM-DD HH:mm:ss")}`}</div>
      </TimelineItem>
    ));
  }, [order.logisticHistories]);

  const lastLogisticHistory = useMemo(() => {
    if (order.logisticHistories.length === 0) return "";

    const lastHistory = order.logisticHistories[order.logisticHistories.length - 1];
    return `歷史紀錄: ${lastHistory.status} ${moment(lastHistory.updatedTime).format("YYYY-MM-DD HH:mm:ss")}`;
  }, [order.logisticHistories]);

  const orderShippingNumberRender = () => {
    const orderIdThreshold = 1499768;

    switch (order.shippingMethod) {
      case ShippingTypeValueByNumber.SEVEN_ELEVEN:
        return order.id >= orderIdThreshold ? ` (UTC${order.id})` : ` (UMS${order.id})`;
      case ShippingTypeValueByNumber.CVS:
        return order.id >= orderIdThreshold ? ` (UTC${order.id})` : "";
      default:
        return "";
    }
  };

  const onThrow = () => {
    if (order.shippingStatus === LogisticHistoriesNumber.OUT_OF_STOCK) {
      setOpenPlanQty(true);
      return;
    }

    if (order.isCvsShippingFailed) {
      Session.setItem(CURRENT_SUB_ORDER, order.id);
      setOpenCVS(true);
    } else {
      Modal.confirm({
        title: "你確定要重拋這筆訂單?",
        icon: <ExclamationCircleOutlined />,
        okText: "是",
        cancelText: "否",
        onOk() {
          dispatch(fetchOrderDeploy({ subOrderId: order.id, mainOrderId: parseInt(orderId, 10) }));
        },
      });
    }
  };

  const onClickReturn = () => {
    setShowReturn(true);
  };

  const onDelete = () => {
    Modal.confirm({
      title: "你確定要刪除這筆訂單?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchOrderDelete({ subOrderId: order.id, mainOrderId: parseInt(orderId, 10) }));
      },
    });
  };

  const onRejectTransfer = () => {
    Modal.confirm({
      title: "你確定要拒收這筆訂單?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(fetchRejectTransfer({ subOrderId: order.id, mainOrderId: parseInt(orderId, 10) }));
      },
    });
  };

  const onChangeShippingStatus = () => {
    Modal.confirm({
      title: "你確定將狀態更改為[已取件]嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(
          changeOrderShippingStatus({
            subOrderId: order.id,
            mainOrderId: parseInt(orderId, 10),
            statusCode: ShippingStatus.PICKED_UP,
          }),
        );
      },
    });
  };

  useEffect(() => {
    if (state && order.id === Session.getItem(CURRENT_SUB_ORDER)) {
      setOpenCVS(true);
    }
  }, [state, order.id]);

  useEffect(() => {
    if (isFetching) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [isFetching, dispatch]);
  return (
    <Wrapper>
      <CustomRow align="middle">
        <Vendor>
          {order.logisticShippingMethods[order.logistic].name}
          {order.vendorName.length === 1 && order.logistic === VendorLogisticsValue.transfer && <span> - </span>}
          {order.vendorName.length === 1 && order.logistic === VendorLogisticsValue.transfer ? order.vendorName[0] : ""}
        </Vendor>
        <CustomButton disabled={order.logistic !== VendorLogisticsValue.warehouse} onClick={onThrow}>
          重拋單
        </CustomButton>
        <CustomButton
          disabled={order.logistic !== VendorLogisticsValue.warehouse || !order.canDelete}
          onClick={onDelete}
        >
          刪單
        </CustomButton>
        <CustomButton disabled={order.logistic !== VendorLogisticsValue.transfer} onClick={onRejectTransfer}>
          拒收
        </CustomButton>
        <CustomButton disabled={!ifCanReturn} onClick={onClickReturn}>
          退貨
        </CustomButton>
        <CustomButton disabled={!order.canModifyPickup} onClick={onChangeShippingStatus}>
          已取件
        </CustomButton>
      </CustomRow>
      <Text>訂單資訊</Text>
      <OrderInfo>
        <Text>{`商品金額: $${order.salesPlansPrice}`}</Text>
        <Text>{`運費: $${order.shippingFee}`}</Text>
        <Text>{`總金額: $${order.salesPlansPrice + order.shippingFee}`}</Text>
        <Text>{`備註: ${order.notes}`}</Text>
      </OrderInfo>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={order.orderSalesPlans}
          pagination={false}
          scroll={{ x: "max-content", y: 400 }}
          rowKey="id"
        />
      </TableWrapper>
      <Text>寄送資訊</Text>
      <OrderInfo>
        <Text>{`收件人: ${order.receiverName}`}</Text>
        <Text>{`收件電話: ${order.receiverPhone}`}</Text>
        <Text>{`運送方式: ${shippingText}`}</Text>
        <Text>{`配送編號: ${order.packageNumber || "N/A"}`}</Text>
        <Text>
          {`出貨單號: ${order.shipperNumber || "N/A"}`}
          {orderShippingNumberRender()}
        </Text>
        <Text hoverLight>
          {lastLogisticHistory}
          <CaretDownOutlined />
          <HistoriesWrapper>
            <Timeline>{reverseLogisticHistories}</Timeline>
          </HistoriesWrapper>
        </Text>
        {order.logistic === VendorLogisticsValue.preorder && (
          <Text>{`預期到貨日: ${moment(order.deliveryDate * 1000).format("YYYY-MM-DD HH:mm:ss")}`}</Text>
        )}
      </OrderInfo>
      {openPlanQty && <OrderPlanQty order={order} close={() => setOpenPlanQty(false)} />}
      {openCVS && <CVSPopup order={order} close={() => setOpenCVS(false)} />}
      {showReturn && <ReturnPopup order={order} onClose={() => setShowReturn(false)} showReturn={showReturn} />}
    </Wrapper>
  );
}
