import { Button, DatePicker, Form, Input, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import useDebounce from "@hooks/useDebounce";
import { useDispatch, useSelector } from "react-redux";
import { fetchCheckProduct, fetchCreatCost, fetchRecostDetail, fetchUpdateCost, recostState } from "@redux/recostSlice";

interface Props {
  visible: boolean;
  onClose: () => void;
  recostId?: number;
  mode?: string;
}

const Wrapper = styled(Form)`
  display: flex;
  flex-direction: column;
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: auto;
`;
const CancelButton = styled(Button)`
  margin-right: 14px;
`;

const Row = styled.div`
  position: relative;
`;
const Remark = styled.div`
  position: absolute;
  color: red;
  top: 110px;
  left: 140px;
`;

const EditPopup = (props: Props) => {
  const { visible, onClose, recostId, mode } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { costDetail, checkedProduct, checkedError, isCheckPass, isPending } = useSelector(recostState);
  const [showError, setShowError] = useState(false);
  const skuNo = form.getFieldValue("skuNo");

  const handleOnSubmit = () => {
    const formValues = form.getFieldsValue();
    const params = {
      ...formValues,
      startAt: formValues.timePeriod ? moment(formValues.timePeriod[0]).format("YYYY-MM-DD HH:mm:ss") : "",
      endAt: formValues.timePeriod ? moment(formValues.timePeriod[1]).format("YYYY-MM-DD HH:mm:ss") : "",
      timePeriod: undefined,
    };

    if (mode === "edit" && recostId) {
      dispatch(fetchUpdateCost({ params, id: recostId }));
    } else {
      dispatch(fetchCreatCost(params));
    }

    onClose();
  };

  const handleProductCheck = useDebounce((e: any) => {
    const type = form.getFieldValue("type");
    if (e.target.value && type > 0) {
      const params = {
        skuNo: e.target.value,
        type,
      };

      dispatch(fetchCheckProduct(params));
      setShowError(true);
    }
  }, 300);

  const handleSelectType = (e: number) => {
    const typedSkuNo = form.getFieldValue("skuNo");
    const params = {
      skuNo: typedSkuNo,
      type: e,
    };
    dispatch(fetchCheckProduct(params));
    if (typedSkuNo) {
      setShowError(true);
    }
  };

  const handleSelectTime = (e: any) => {
    const typedSkuNo = form.getFieldValue("skuNo");
    const selectType = form.getFieldValue("type");
    const params = {
      skuNo: typedSkuNo,
      type: selectType,
      startAt: e ? moment(e[0]).format("YYYY-MM-DD HH:mm:ss") : "",
      endAt: e ? moment(e[1]).format("YYYY-MM-DD HH:mm:ss") : "",
    };
    dispatch(fetchCheckProduct(params));
    setShowError(true);
  };

  useEffect(() => {
    if (recostId) {
      dispatch(fetchRecostDetail(recostId));
    }
  }, [dispatch, recostId]);

  useEffect(() => {
    if (skuNo && isCheckPass) {
      form.setFieldsValue({
        id: checkedProduct.id,
        name: checkedProduct.name,
        originCost: checkedProduct.originCost,
      });
    } else {
      form.resetFields(["id", "name", "originCost"]);
    }
  }, [
    checkedError,
    checkedError.name,
    checkedProduct.id,
    checkedProduct.name,
    checkedProduct.originCost,
    form,
    isCheckPass,
    skuNo,
  ]);

  useEffect(() => {
    if (mode === "edit") {
      form.setFieldsValue({
        timePeriod: [moment(costDetail?.startAt), moment(costDetail?.endAt)],
        type: costDetail?.type,
        skuNo: costDetail?.skuNo,
        id: costDetail?.id,
        name: costDetail?.name,
        afterCost: costDetail?.afterCost,
        originCost: costDetail?.originCost,
        description: costDetail?.description,
      });
    }
  }, [
    costDetail?.afterCost,
    costDetail?.description,
    costDetail?.endAt,
    costDetail?.id,
    costDetail?.name,
    costDetail?.originCost,
    costDetail?.skuNo,
    costDetail?.startAt,
    costDetail?.type,
    form,
    mode,
  ]);

  return (
    <Modal visible={visible} closable={false} footer={false}>
      <Wrapper
        labelAlign="left"
        colon={false}
        onFinish={handleOnSubmit}
        form={form}
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 25 }}
      >
        <Form.Item shouldUpdate label="部門選擇" name="type" rules={[{ required: true, message: "" }]}>
          <Select placeholder="請選擇" onChange={handleSelectType}>
            <Select.Option value={1}>UrMart</Select.Option>
            <Select.Option value={2}>品運</Select.Option>
          </Select>
        </Form.Item>
        <Row>
          <Form.Item
            shouldUpdate
            label="商品條碼/品號"
            name="skuNo"
            rules={[
              () => ({
                validator(rule, values) {
                  if (!values) {
                    setShowError(false);
                  }
                  return Promise.resolve();
                },
              }),
              { required: true, message: "" },
            ]}
          >
            <Input onChange={handleProductCheck} />
          </Form.Item>
        </Row>
        {!isCheckPass && showError && <Remark>{checkedError.message}</Remark>}
        <Form.Item label="商品ID" name="id">
          <Input placeholder="自動帶入" disabled />
        </Form.Item>
        <Form.Item label="商品名稱" name="name">
          <Input placeholder="自動帶入" disabled />
        </Form.Item>
        <Form.Item label="時間" name="timePeriod" rules={[{ required: true, message: "" }]}>
          <DatePicker.RangePicker
            style={{ width: "100%" }}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              hideDisabledOptions: true,
              format: "HH:mm:ss",
            }}
            onChange={handleSelectTime}
          />
        </Form.Item>
        <Form.Item label="商品成本" name="originCost">
          <Input placeholder="自動帶入" disabled />
        </Form.Item>
        <Form.Item label="回壓成本" name="afterCost" rules={[{ required: true, message: "" }]}>
          <Input />
        </Form.Item>
        <Form.Item label="回壓原因" name="description">
          <Input />
        </Form.Item>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button type="primary" htmlType="submit" disabled={isPending}>
            儲存
          </Button>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

export default EditPopup;
