import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { ReturnList, ReturnListParams } from "@api/financeApi";
import moment from "moment";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  returnList: ReturnList;
  returnListParam: ReturnListParams;
}

const lastMonth = moment().subtract(30, "days");

export const initialState: IState = {
  isFetching: false,
  returnList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  returnListParam: {
    limit: 20,
    offset: 0,
    createdDateAfter: lastMonth.startOf("month").format("YYYY-MM-DD"),
    createdDateBefore: lastMonth.endOf("month").format("YYYY-MM-DD"),
    checkedDateAfter: undefined,
    checkedDateBefore: undefined,
    orderNumber: undefined,
    approvalStatus: -1,
  },
};

export const fetchReturnList = createAsyncThunk("finance/fetchReturnList", async (_, thunkApi) => {
  const {
    returnListPageSlice: { returnListParam },
  } = thunkApi.getState() as RootState;
  try {
    const params = {
      ...returnListParam,
      approvalStatus: returnListParam.approvalStatus === -1 ? undefined : returnListParam.approvalStatus,
    };
    const response = await financeApi.fetchReturnList(params);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error.message);
  }
});

export const exportReturnList = createAsyncThunk<void>("finance/exportReturnList", async (_, thunkApi) => {
  const {
    returnListPageSlice: { returnListParam },
  } = thunkApi.getState() as RootState;
  const params = {
    ...returnListParam,
    approvalStatus: returnListParam.approvalStatus === -1 ? undefined : returnListParam.approvalStatus,
  };
  const response = await financeApi.exportReturnList(params);
  return response;
});

const returnListPageSlice = createSlice({
  name: "returnListPage",
  initialState,
  reducers: {
    resetReturnListPageSlice: () => initialState,
    setReturnListParam: (state, action) => {
      state.returnListParam = action.payload;
    },
    resetReturnListParam: (state) => {
      state.returnListParam = {
        ...initialState.returnListParam,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReturnList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchReturnList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.returnList = action.payload;
    });
  },
});

export const returnListPageState = (state: RootState) => state.returnListPageSlice;
export const { resetReturnListPageSlice, setReturnListParam, resetReturnListParam } = returnListPageSlice.actions;
export default returnListPageSlice.reducer;
