import staffApi from "@api/staffApi";
import CustomError from "@api/utils/CustomeError";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Staff } from "src/types";

export const fecthBdStaffs = createAsyncThunk<
  Staff.BD[],
  undefined,
  {
    rejectValue: CustomError;
  }
>("staffs/fetchAllBdStaffs", async (_, thunkAPI) => {
  try {
    const resp = await staffApi.fetchStaffList({
      limit: 100,
      offset: 0,
      staffGroupPermission: "product_owner",
    });
    return resp.results;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const fetchAllStaffs = createAsyncThunk<
  Staff.BD[],
  undefined,
  {
    rejectValue: CustomError;
  }
>("staffs/fetchAllStaffs", async (_, thunkAPI) => {
  try {
    const resp = await staffApi.fetchStaffList({
      limit: 100,
      offset: 0,
      staffGroupPermission: "",
    });
    return resp.results;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

interface SliceState {
  isPending: boolean;
  isFulfilled: boolean;
  bdStaffData: Staff.BD[];
  allStaffData: Staff.BD[];
}

const staffsSlice = createSlice({
  name: "staffs",
  initialState: {
    isPending: false,
    isFulfilled: false,
    bdStaffData: [],
    allStaffData: [],
  } as SliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fecthBdStaffs.pending, (state) => {
        state.isPending = true;
      })
      .addCase(fecthBdStaffs.fulfilled, (state, action) => {
        state.isFulfilled = true;
        state.isPending = false;
        if (Array.isArray(action.payload)) {
          state.bdStaffData = [...action.payload];
        } else {
          state.bdStaffData = [];
        }
      })
      .addCase(fecthBdStaffs.rejected, (state) => {
        state.isFulfilled = false;
        state.isPending = false;
      })
      .addCase(fetchAllStaffs.pending, (state) => {
        state.isPending = true;
      })
      .addCase(fetchAllStaffs.fulfilled, (state, action) => {
        state.isFulfilled = true;
        state.isPending = false;
        if (Array.isArray(action.payload)) {
          state.allStaffData = [...action.payload];
        } else {
          state.allStaffData = [];
        }
      })
      .addCase(fetchAllStaffs.rejected, (state) => {
        state.isFulfilled = false;
        state.isPending = false;
      });
  },
});

export default staffsSlice.reducer;
