import productApi from "@api/productApi";
import { VipGiftList } from "@api/vipPromotionApi";
import { SalesChannelType } from "@api/vendorApi";
import PopupBackground from "@component/PopupBackground";
import UploadImageButton from "@component/UploadImageButton";
import { fetchCreateVipGift, fetchUpdateVipGift } from "@redux/vipPromotionSlice";
import { Button, DatePicker, Form, Input, InputNumber, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)`
  padding: 20px 14px 20px 27px;
  position: relative;
  width: 620px;
  background: ${({ theme }) => theme.colorNeutral100};

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    order: 1;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    margin: 0;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-btn-block {
    width: auto;
  }
`;
const CustomRangePicker = styled(DatePicker.RangePicker)<{ showTime: any }>`
  width: 100%;
`;
const Scope = styled.div`
  position: relative;
`;
const CustomInput = styled(Input)<{ width?: string; mrleft?: string }>`
  width: ${({ width }) => width};
  margin-left: ${({ mrleft }) => mrleft};
`;
const InputAbsolute = styled(CustomInput)`
  position: absolute;
  left: 41%;
  width: 271px;
  top: 0;
`;
const CustomTextArea = styled(Input.TextArea)`
  &&& {
    height: 123px;
  }
`;
const AddButton = styled(Button)`
  margin-left: 4px;
  position: absolute;
  right: 71px;
  top: -42px;
`;
const SkuWrapper = styled.div`
  margin-left: 122px;
  position: relative;
`;
const SkuItem = styled.div`
  margin-right: 22px;
`;
const CustomRow = styled(Row)`
  margin-top: 25px;
`;
const CustomButton = styled(Button)`
  margin-right: 8px;
`;
const NoticeText = styled.div`
  color: #ff4d4f;
`;

interface Option {
  vpcId: number;
  qty: number;
  price: number;
  commissionRate: number;
  sku: string;
  name: string;
}

type Props = {
  vipGift?: VipGiftList;
  onClose: () => void;
};

export default function EditVipGiftPopup(props: Props) {
  const { vipGift, onClose } = props;

  const dispatch = useDispatch();

  const [optionList, setOptionList] = useState<Option[]>([]);
  const [searchSku, setSearchSku] = useState<string>("");
  const [skuNotFound, setSkuNotFound] = useState<boolean>(false);

  const [form] = Form.useForm();

  const updateForm = (field: string, value: any) => {
    form.setFieldsValue({ [field]: value });
  };

  const onDeleteVpc = (vpcId: number) => {
    setOptionList(optionList.filter((item) => item.vpcId !== vpcId));
  };

  const onChangeVpcQty = (vpcId: number, qty: number) => {
    const list = optionList.map((option) => {
      if (option.vpcId === vpcId) {
        option.qty = qty;
      }
      return option;
    });
    setOptionList(list);
  };

  const onSubmit = (values: any) => {
    const imageDirPrefix =
      values.imageUrl && !values.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? "vip_gift/image"
        : undefined;
    const imageFilePath =
      values.imageUrl && !values.imageUrl.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
        ? values.imageUrl
        : undefined;

    const payload = {
      description: values.description,
      useStartAt:
        values?.timePeriod && values.timePeriod.length > 0
          ? values.timePeriod[0].format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      useEndAt:
        values?.timePeriod && values.timePeriod.length > 0
          ? values.timePeriod[1].format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      salesPlanData: {
        name: values.name,
        productsInfo: optionList.map((option) => ({
          vpcId: option.vpcId,
          qty: option.qty,
          price: option.price,
          commissionRate: option.commissionRate,
        })),
        isActive: values.isActive === "active",
        imageDirPrefix,
        imageFilePath,
        imageAlt: values.imageAlt || values.name,
      },
    };

    if (vipGift) {
      dispatch(fetchUpdateVipGift({ ...payload, vipGiftId: vipGift.id }));
    } else {
      dispatch(fetchCreateVipGift(payload));
    }

    onClose();
  };

  const fetchProductVendorList = async () => {
    setSkuNotFound(false);
    const response = await productApi.fetchProductVendorList({
      limit: 1,
      offset: 0,
      sku: searchSku,
      isActive: true,
      salesChannel: SalesChannelType.URMART,
    });
    if (response.results.length > 0) {
      const data = response.results[0];
      const option = {
        vpcId: data.vpcId,
        qty: 1,
        price: data.price,
        commissionRate: data.commissionRate,
        sku: data.product.sku,
        name: data.product.name,
      };
      setOptionList([...optionList, option]);
    } else {
      setSkuNotFound(true);
    }
  };

  useEffect(() => {
    if (vipGift) {
      form.setFieldsValue({
        isActive: vipGift.salesPlan.isActive ? "active" : "inactive",
        timePeriod: [moment(vipGift.useStartAt), moment(vipGift.useEndAt)],
        name: vipGift.salesPlan.name,
        imageUrl: vipGift.salesPlan.media?.url || "",
        imageAlt: vipGift.salesPlan.media?.alt || "",
        price: vipGift.salesPlan.price,
        description: vipGift.description,
      });

      const list = vipGift.salesPlan.options.map((option) => ({
        vpcId: option.vpc.id,
        qty: option.groupQty,
        price: option.vpc.price,
        commissionRate: option.vpc.commissionRate,
        id: option.id,
        sku: option.vpc.sku,
        name: option.name,
      }));
      setOptionList(list);
    }
  }, [vipGift, form]);

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper
        colon={false}
        labelCol={{ span: 5 }}
        wrapperCol={{ span: 24 }}
        labelAlign="left"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item label="狀態" name="isActive" wrapperCol={{ span: 9 }} rules={[{ required: true, message: "必填" }]}>
          <Select>
            <Select.Option value="inactive">下架</Select.Option>
            <Select.Option value="active">上架</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="日期"
          name="timePeriod"
          wrapperCol={{ span: 12 }}
          rules={[{ required: true, message: "必填" }]}
        >
          <CustomRangePicker
            locale={locale}
            format="YYYY-MM-DD HH:mm:ss"
            showTime={{
              hideDisabledOptions: true,
              defaultValue: [moment("00:00:00", "HH:mm:ss"), moment("23:59:59", "HH:mm:ss")],
            }}
          />
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {({ getFieldValue }) => (
            <Form.Item
              label="贈品名稱"
              name="name"
              wrapperCol={{ span: 16 }}
              rules={[
                { required: true, message: "必填" },
                {
                  validator: async () => {
                    if (getFieldValue("name")?.length > 20) {
                      throw new Error("最多輸入20個字");
                    }
                  },
                },
              ]}
            >
              <Input placeholder="建議重點資訊20字內" />
            </Form.Item>
          )}
        </Form.Item>
        <Scope>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <Form.Item label="贈品去背圖" name="imageUrl" rules={[{ required: true, message: "必填" }]}>
                <UploadImageButton
                  imageUrl={getFieldValue("imageUrl") || ""}
                  finishUpload={(url) => updateForm("imageUrl", url)}
                />
              </Form.Item>
            )}
          </Form.Item>
          <InputAbsolute
            placeholder="請輸入alt"
            value={form.getFieldValue("imageAlt")}
            onChange={(e) => updateForm("imageAlt", e.target.value)}
          />
        </Scope>
        <Form.Item label="" name="imageAlt" noStyle />
        <Form.Item label="建議售價" name="price">
          <div>{`$${optionList.reduce((acc, curr) => acc + curr.price * curr.qty, 0)}`}</div>
        </Form.Item>
        <Form.Item
          label="方案描述"
          name="description"
          wrapperCol={{ span: 16 }}
          rules={[{ required: true, message: "必填" }]}
        >
          <CustomTextArea />
        </Form.Item>
        <Form.Item
          label="方案內容"
          name="sku"
          wrapperCol={{ span: 20 }}
          rules={[
            {
              validator: async (_, value) => {
                if (!value && optionList.length === 0) {
                  throw new Error("贈品內容不能為空");
                }
              },
            },
          ]}
        >
          <CustomInput
            placeholder="請輸入sku"
            width="319px"
            value={searchSku}
            onChange={(e) => {
              setSkuNotFound(false);
              setSearchSku(e.target.value);
            }}
          />
        </Form.Item>
        <SkuWrapper>
          <AddButton onClick={fetchProductVendorList}>加入</AddButton>
          {skuNotFound && <NoticeText>sku碼無效找不到商品</NoticeText>}
          {optionList.map((option) => (
            <Row align="middle">
              <SkuItem>{`${option.sku},${option.name}`}</SkuItem>
              <InputNumber value={option.qty} onChange={(value) => onChangeVpcQty(option.vpcId, value as number)} />
              <Button type="link" block onClick={() => onDeleteVpc(option.vpcId)}>
                刪除
              </Button>
            </Row>
          ))}
        </SkuWrapper>
        <CustomRow justify="end">
          <CustomButton onClick={onClose}>取消</CustomButton>
          <Button type="primary" htmlType="submit">
            確定
          </Button>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
