import { setTopicRecommendSaleListFilter } from "@redux/topicSlice";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  padding: 10px 0px 20px 0px;
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function RecommendSettingFilter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
  };

  const onSubmit = (values: any) => {
    dispatch(setTopicRecommendSaleListFilter(values));
  };

  return (
    <Wrapper
      colon={false}
      labelAlign="left"
      labelCol={{ span: 6 }}
      form={form}
      isVisible={isVisible}
      initialValues={{}}
      onFinish={onSubmit}
    >
      <Row gutter={30}>
        <Col span={11}>
          <Form.Item label="銷售頁ID" name="pageId">
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="銷售頁名稱" name="pageNameQ">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={11}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item label="品牌" name="brandNames">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={24}>
          <Row justify="end">
            <Form.Item noStyle shouldUpdate>
              {({ getFieldsValue }) => {
                const values = getFieldsValue();
                const hasAnyValues = Object.values(values).some((item) => item && item !== undefined);
                return (
                  <CustomButton disabled={!hasAnyValues} onClick={onReset}>
                    清除篩選條件
                  </CustomButton>
                );
              }}
            </Form.Item>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Row>
        </Col>
      </Row>
    </Wrapper>
  );
}
