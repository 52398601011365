import ruleApi, {
  AddSalePlanToRuleParam,
  CouponMethodValue,
  CreateRuleParams,
  FetchRuleListParams,
  MemberListItem,
  MemberListParams,
  MemberListResult,
  NotifyTypeValue,
  RuleDetail,
  RuleListResult,
  SalePlan,
} from "@api/ruleApi";
import salePlanApi, { SalePlanListParams, SalePlanListResult } from "@api/salePlanApi";
import CustomError from "@api/utils/CustomeError";
import { StandardResponse } from "src/types";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message, Modal } from "antd";
import { hideLoading, showLoading } from "./notifySlice";

interface IState {
  isFetchingRuleList: boolean;
  ruleListResult: RuleListResult;
  ruleListParams: FetchRuleListParams;
  selectedRuleIds: number[];
  // edit/add頁
  isFetchingRuleDetail: boolean;
  ruleDetail: RuleDetail;
  ruleSalePlans: SalePlan[];
  // 會員
  isFetchingMemberList: boolean;
  memberListResult: MemberListResult;
  memberListParams: MemberListParams;
  selectedMemberIds: number[];
  showImportCsvPopup: boolean;
  csvErrorMessage: string;
  // 贈品/加價購搜尋方案
  searchSalePlanListResult: SalePlanListResult;
  searchSalePlanListParams: SalePlanListParams;
}

export const initialState: IState = {
  isFetchingRuleList: false,
  ruleListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  ruleListParams: {
    limit: 20,
    offset: 0,
    isActive: -1,
    conditionType: -1,
    actionType: -1,
  },
  selectedRuleIds: [],
  isFetchingRuleDetail: false,
  ruleDetail: {
    isActive: true,
    title: "",
    description: "",
    destinationLink: "",
    redirectUrl: "",
    conditionType: 0,
    actionType: 0,
    notifyType: NotifyTypeValue.BOTH,
    couponMethod: CouponMethodValue.SINGLE,
    bindMethod: {},
    useMethod: {},
    isFreeShipping: false,
    salesPlans: [],
  },
  ruleSalePlans: [],
  isFetchingMemberList: false,
  memberListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  memberListParams: {
    limit: 20,
    offset: 0,
  },
  selectedMemberIds: [],
  showImportCsvPopup: false,
  csvErrorMessage: "",
  searchSalePlanListResult: {
    count: 0,
    previous: "",
    next: "",
    results: [],
  },
  searchSalePlanListParams: {
    limit: 20,
    offset: 0,
  },
};

export const fetchRuleList = createAsyncThunk("rule/fetchRuleList", async (_, thunkApi) => {
  const {
    rule: { ruleListParams },
  } = thunkApi.getState() as RootState;

  try {
    const response = await ruleApi.fetchRuleList(ruleListParams);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const batchDeleteRules = createAsyncThunk<StandardResponse>("rule/batchDeleteRules", async (_, thunkApi) => {
  const {
    rule: { selectedRuleIds },
  } = thunkApi.getState() as RootState;

  try {
    const response = await ruleApi.batchDeleteRules(selectedRuleIds);
    thunkApi.dispatch(fetchRuleList());
    thunkApi.dispatch(updateSelectedRuleIds([]));
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const singleDeleteRule = createAsyncThunk<StandardResponse, number>(
  "rule/singleDeleteRule",
  async (id, thunkApi) => {
    try {
      const response = await ruleApi.batchDeleteRules([id]);
      thunkApi.dispatch(fetchRuleList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateRuleIsActive = createAsyncThunk<any, { id: number; isActive: boolean }>(
  "rule/updateRuleIsActive",
  async (params, thunkApi) => {
    try {
      const { id, isActive } = params;
      const response = await ruleApi.updateRuleIsActive(id, isActive);
      thunkApi.dispatch(fetchRuleList());
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const cleaRuleListParams = createAsyncThunk("rule/cleaRuleListParams", (_, thunkApi) => {
  thunkApi.dispatch(updateRuleListParams(initialState.ruleListParams));
  thunkApi.dispatch(fetchRuleList());
});

export const fetchRuleDetail = createAsyncThunk<RuleDetail, number>(
  "rule/fetchRuleDetail",
  async (ruleId, thunkApi) => {
    try {
      const response = await ruleApi.fetchRuleDetail(ruleId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const refreshRuleSalePlans = createAsyncThunk<RuleDetail, number>(
  "rule/refreshRuleSalePlans",
  async (ruleId, thunkApi) => {
    try {
      const response = await ruleApi.fetchRuleDetail(ruleId);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const createRule = createAsyncThunk<RuleDetail, CreateRuleParams>(
  "rule/createRule",
  async (params, thunkApi) => {
    thunkApi.dispatch(showLoading());
    try {
      const {
        ruleAddSalePlan: { sortSalePlans },
      } = thunkApi.getState() as RootState;
      const response = await ruleApi.createRule(params);
      await salePlanApi.batchSortRulesSalesPlan(response.id!, sortSalePlans);
      message.success("新增成功");
      return response;
    } catch (error: any) {
      message.error(JSON.stringify(error.message));
      return thunkApi.rejectWithValue(error);
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const updateRule = createAsyncThunk<RuleDetail, CreateRuleParams>(
  "rule/updateRule",
  async (params, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
      ruleAddSalePlan: { sortSalePlans },
    } = thunkApi.getState() as RootState;
    thunkApi.dispatch(showLoading());
    try {
      const response = await ruleApi.updateRule(id!, params);
      await salePlanApi.batchSortRulesSalesPlan(id!, sortSalePlans);
      message.success("更新成功");
      return response;
    } catch (error: any) {
      Modal.warning({
        title: JSON.stringify(error.message),
        okText: "我知道了",
      });
      throw error;
    } finally {
      thunkApi.dispatch(hideLoading());
    }
  },
);

export const deleteRule = createAsyncThunk<StandardResponse>("rule/deleteRule", async (_, thunkApi) => {
  const {
    rule: {
      ruleDetail: { id },
    },
  } = thunkApi.getState() as RootState;

  try {
    const response = await ruleApi.batchDeleteRules([id!]);
    message.success("刪除成功");
    return response;
  } catch (error: any) {
    message.error("刪除失敗");
    return thunkApi.rejectWithValue(error);
  }
});

export const exportCouponsByCSV = createAsyncThunk("rule/exportCouponsByCSV", async (_, thunkApi) => {
  const {
    rule: {
      ruleDetail: { id },
    },
  } = thunkApi.getState() as RootState;

  try {
    const response = await ruleApi.exportCouponsByCSV(id!);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchMemberList = createAsyncThunk<MemberListResult, number>(
  "rule/fetchMemberList",
  async (ruleId, thunkApi) => {
    const {
      rule: { memberListParams },
    } = thunkApi.getState() as RootState;

    try {
      const response = await ruleApi.fetchMemberList(ruleId, memberListParams);
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const batchDeleteMember = createAsyncThunk<MemberListItem[]>("rule/batchDeleteMember", async (_, thunkApi) => {
  const {
    rule: {
      ruleDetail: { id },
      selectedMemberIds,
    },
  } = thunkApi.getState() as RootState;

  try {
    const response = await ruleApi.batchDeleteMember(id!, selectedMemberIds);
    thunkApi.dispatch(fetchMemberList(id!));
    thunkApi.dispatch(updateSelectedMemberIds([]));
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const singleDeleteMember = createAsyncThunk<MemberListItem[], number>(
  "rule/singleDeleteMember",
  async (memberId, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await ruleApi.batchDeleteMember(id!, [memberId]);
      thunkApi.dispatch(fetchMemberList(id!));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const batchAddMembersByCSV = createAsyncThunk<StandardResponse, File>(
  "rule/batchAddMembersByCSV",
  async (file, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await ruleApi.batchAddMembersByCSV(id!, file);
      thunkApi.dispatch(closeImportCsvPopup());
      message.warn("匯入進行中，成功會發送通知");
      return response;
    } catch (error: any) {
      throw thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteSalePlanFromRule = createAsyncThunk<StandardResponse, number>(
  "rule/deleteSalePlanFromRule",
  async (planId, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await ruleApi.deleteSalePlanFromRule(id!, planId);
      thunkApi.dispatch(fetchRuleDetail(id!));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const addSalePlanToRule = createAsyncThunk<StandardResponse, AddSalePlanToRuleParam>(
  "rule/addSalePlanToRule",
  async (params, thunkApi) => {
    const {
      rule: {
        ruleDetail: { id },
      },
    } = thunkApi.getState() as RootState;

    try {
      const response = await ruleApi.addSalePlanToRule(id!, params);
      thunkApi.dispatch(fetchRuleDetail(id!));
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchSalePlanList = createAsyncThunk("rule/fetchSalePlanList", async (_, thunkApi) => {
  const {
    rule: { searchSalePlanListParams },
  } = thunkApi.getState() as RootState;

  try {
    const response = await salePlanApi.fetchSalePlanList(searchSalePlanListParams);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const loadMoreSalePlanList = createAsyncThunk("rule/loadMoreSalePlanList", async (_, thunkApi) => {
  const {
    rule: { searchSalePlanListParams },
  } = thunkApi.getState() as RootState;

  const params: SalePlanListParams = {
    ...searchSalePlanListParams,
    offset: searchSalePlanListParams.offset + searchSalePlanListParams.limit,
  };

  try {
    thunkApi.dispatch(updateSalePlanListParams(params));
    const response = await salePlanApi.fetchSalePlanList(params);
    return response;
  } catch (error: any) {
    return thunkApi.rejectWithValue(error);
  }
});

export const fetchCloneSalePlan = createAsyncThunk(
  "rule/fetchCloneSalePlan",
  async (config: { ruleId: number; planIds: number[] }, thunkApi) => {
    try {
      await ruleApi.fetchCloneSalePlan(config.ruleId, config.planIds);
      await thunkApi.dispatch(fetchRuleDetail(config.ruleId));
      return "Success";
    } catch (error: any) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const ruleSlice = createSlice({
  name: "rule",
  initialState,
  reducers: {
    reset: () => initialState,
    updateRuleListParams: (state, action: PayloadAction<FetchRuleListParams>) => {
      state.ruleListParams = action.payload;
    },
    updateSelectedRuleIds: (state, action: PayloadAction<number[]>) => {
      state.selectedRuleIds = action.payload;
    },
    updateMemberListParams: (state, action: PayloadAction<MemberListParams>) => {
      state.memberListParams = action.payload;
    },
    updateSelectedMemberIds: (state, action: PayloadAction<number[]>) => {
      state.selectedMemberIds = action.payload;
    },
    openImportCsvPopup: (state) => {
      state.showImportCsvPopup = true;
    },
    closeImportCsvPopup: (state) => {
      state.showImportCsvPopup = false;
    },
    clearCSVErrorMessage: (state) => {
      state.csvErrorMessage = "";
    },
    updateSalePlanListParams: (state, action: PayloadAction<SalePlanListParams>) => {
      state.searchSalePlanListParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRuleList.pending, (state) => {
      state.isFetchingRuleList = true;
    });
    builder.addCase(fetchRuleList.fulfilled, (state, action) => {
      state.isFetchingRuleList = false;
      state.ruleListResult = action.payload;
    });
    builder.addCase(fetchRuleDetail.pending, (state) => {
      state.isFetchingRuleDetail = true;
    });
    builder.addCase(fetchRuleDetail.fulfilled, (state, action) => {
      state.isFetchingRuleDetail = false;
      state.ruleDetail = action.payload;
      state.ruleSalePlans = action.payload.salesPlans;
    });
    builder.addCase(refreshRuleSalePlans.fulfilled, (state, action) => {
      state.ruleSalePlans = action.payload.salesPlans;
    });
    builder.addCase(createRule.pending, (state) => {
      state.isFetchingRuleDetail = true;
    });
    builder.addCase(createRule.fulfilled, (state, action) => {
      state.isFetchingRuleDetail = false;
      state.ruleDetail = action.payload;
    });
    builder.addCase(updateRule.pending, (state) => {
      state.isFetchingRuleDetail = true;
    });
    builder.addCase(updateRule.fulfilled, (state, action) => {
      state.isFetchingRuleDetail = false;
      state.ruleDetail = action.payload;
    });
    builder.addCase(fetchMemberList.pending, (state) => {
      state.isFetchingMemberList = true;
    });
    builder.addCase(fetchMemberList.fulfilled, (state, action) => {
      state.isFetchingMemberList = false;
      state.memberListResult = action.payload;
    });
    builder.addCase(batchAddMembersByCSV.fulfilled, (state) => {
      state.csvErrorMessage = "";
    });
    builder.addCase(batchAddMembersByCSV.rejected, (state, action) => {
      const { payload } = action;
      const { result, message: errorMessage } = payload as CustomError;

      if (result && result[0]) state.csvErrorMessage = `${result[0]} 有錯誤: 此電話非UrMart會員`;
      else state.csvErrorMessage = errorMessage;
    });
    builder.addCase(fetchSalePlanList.fulfilled, (state, action) => {
      state.searchSalePlanListResult = action.payload;
    });
    builder.addCase(loadMoreSalePlanList.fulfilled, (state, action) => {
      state.searchSalePlanListResult = {
        ...state.searchSalePlanListResult,
        results: state.searchSalePlanListResult.results.concat(action.payload.results),
      };
    });
  },
});

export const {
  reset,
  updateRuleListParams,
  updateSelectedRuleIds,
  updateMemberListParams,
  updateSelectedMemberIds,
  openImportCsvPopup,
  closeImportCsvPopup,
  clearCSVErrorMessage,
  updateSalePlanListParams,
} = ruleSlice.actions;
export default ruleSlice.reducer;
