import { FilterTwoTone, PlusCircleTwoTone } from "@ant-design/icons";
import { RootState } from "@redux/rootReducer";
import {
  fetchSupplementList,
  updateVendorSupplementParams,
  deleteSupplement,
  exportSupplement,
} from "@redux/vendorSlice";
import { Button, Col, Row, Select, Space, Table } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import moment from "moment";
import { SupplementItem } from "@page/VendorSupplementPage/interface";
import { ColumnsType } from "antd/lib/table";
import { vendorApproveStatusMap, supplementPurposeMap, VendorApproveStatus } from "@constant/vendor";
import PageTitle from "@component/PageTitle";
import useSalesChannelOptions from "@hooks/useSalesChannelOptions";
import VendorFilter from "./Filter";
import EditModal from "./EditModal";

const { Option } = Select;

const Wrapper = styled.div`
  padding: 25px 20px;
`;
const Text = styled.div`
  white-space: nowrap;
`;

export default function SupplementListPage() {
  const dispatch = useAppDispatch();
  const { supplementListResult, supplementListParams, isFetchingSupplementList } = useSelector(
    (state: RootState) => state.vendorSlice,
  );
  const { limit, offset } = supplementListParams;
  const { count, results: list } = supplementListResult;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const salesChannelOptions = useSalesChannelOptions();
  const currentPage = useMemo(() => {
    return Math.floor(offset / limit) + 1;
  }, [limit, offset]);

  useEffect(() => {
    dispatch(fetchSupplementList());
  }, [dispatch]);

  const handleSelectChange = (value: number) => {
    dispatch(
      updateVendorSupplementParams({
        ...supplementListParams,
        limit: value,
      }),
    );
    dispatch(fetchSupplementList());
  };

  const handleTableChangePage = (page: number) => {
    dispatch(
      updateVendorSupplementParams({
        ...supplementListParams,
        offset: (page - 1) * limit,
      }),
    );
    dispatch(fetchSupplementList());
  };

  const columns: ColumnsType<SupplementItem> = [
    {
      title: "補單編號",
      dataIndex: "id",
    },
    {
      title: "審核狀態",
      dataIndex: "approvalStatus",
      render: (data: number) => vendorApproveStatusMap[data],
    },
    {
      title: "通路",
      dataIndex: "salesChannel",
      render: (data: number) => {
        const matchedItem = salesChannelOptions.find((item) => {
          return item.value === data;
        });
        return matchedItem ? matchedItem.label : null;
      },
    },
    {
      title: "補單緣由",
      dataIndex: "supplementPurpose",
      render: (data: number) => supplementPurposeMap[data],
    },
    {
      title: "銷售頁方案ID",
      dataIndex: "salesPlanId",
    },
    {
      title: "SKUID",
      dataIndex: "sku",
    },
    {
      title: "額外增減金額",
      dataIndex: "extraAmount",
    },
    {
      title: "實際補單時間",
      dataIndex: "supplementDate",
      render: (data: string) => {
        return data ? moment(data).format("YYYY-MM") : null;
      },
    },
    {
      title: "申請人",
      dataIndex: ["staff", "name"],
    },
    {
      title: "申請時間",
      dataIndex: "createdAt",
    },

    {
      title: "",
      dataIndex: "action",
      fixed: "right",
      render: (_: any, otherData: SupplementItem) => (
        <Row justify="space-between">
          <Button type="link" href={`/supplement/${otherData.id}`} target="_blank">
            檢視
          </Button>

          <Button
            type="link"
            disabled={
              [VendorApproveStatus.REJECT, VendorApproveStatus.PASS, VendorApproveStatus.DELETE].indexOf(
                otherData.approvalStatus,
              ) > -1
            }
            onClick={() => {
              dispatch(deleteSupplement(otherData.id));
            }}
          >
            作廢
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Wrapper>
      <PageTitle title="月結補單" />
      <Row justify="space-between">
        <Col span={12}>
          <Space>
            <Button
              type="primary"
              icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
              onClick={() => setShowModal(true)}
            >
              新增月結補單
            </Button>
            <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
              篩選
            </Button>
            <Button onClick={() => dispatch(exportSupplement())}>匯出</Button>
          </Space>
        </Col>
        <Col span={6}>
          <Space>
            <Text>{`總共${count}筆，每頁顯示`}</Text>
            <Select
              value={limit}
              style={{ width: "72px", fontSize: "13px" }}
              onChange={(value) => handleSelectChange(value as number)}
            >
              <Option value={20}>20</Option>
              <Option value={50}>50</Option>
              <Option value={100}>100</Option>
            </Select>
            <span>筆</span>
          </Space>
        </Col>
      </Row>
      {showFilter && <VendorFilter />}
      <div style={{ border: "1px solid #fffff", marginTop: "15px" }}>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={list}
          loading={isFetchingSupplementList}
          scroll={{ x: "max-content" }}
          pagination={{
            current: currentPage,
            total: count,
            onChange: handleTableChangePage,
            pageSize: limit,
            showSizeChanger: false,
          }}
        />
      </div>
      <EditModal isModalVisible={showModal} toggleModal={() => setShowModal((prev) => !prev)} isEdit={false} />
    </Wrapper>
  );
}
