import { DeleteOutlined, EyeOutlined, LoadingOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, message, Upload } from "antd";
import { RcFile } from "antd/lib/upload";
import React, { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { uploadVIPPhotoToS3 } from "@utils/AWS";
import PhotoModal from "./PhotoModal";

interface Props {
  imageUrl?: string;
  finishUpload: (url: string) => void;
  limitSize?: number; // in MB
  ratio?: [number, number]; // 長:寬
  mode?: "small" | "normal";
  hasPreviewAndDelete?: boolean;
  onDelete?: () => void;
}

const UploadWrapper = styled(Upload)`
  position: relative;
  width: auto;

  &:hover {
    #upload-hover-mask {
      display: flex;
    }
  }

  #upload-hover-mask {
    display: none;
  }

  .ant-upload.ant-upload-select-picture-card {
    margin-right: 0;
    margin-bottom: 0;
  }
`;
const UploadButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadButtonText = styled.p`
  margin-top: 8px;
`;
const ImageWrapper = styled.img`
  max-width: 100px;
  max-height: 100%;
`;
const SmallImageWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 170px;
  height: 32px;
  padding: 6px 10px;
  border: 1px solid #d9d9d9;
`;
const SmallImage = styled.img`
  max-width: 20px;
  max-height: 100%;
  margin-right: 6px;
`;

const DeleteIcon = styled(DeleteOutlined)`
  margin-left: 6px;
  font-size: 12px;
`;
const HoverMask = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
`;
const MaskIconContainer = styled.div`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
`;
const PreviewIcon = styled(EyeOutlined)`
  margin-right: 14px;
`;

const checkSize = (file: File, size: number) => {
  if (file.size <= size * 1024 * 1024) return true;
  message.error(`大小上限為${size}MB`);
  return false;
};

const checkAspectRatio = (file: File, ratio: [number, number]) => {
  return new Promise<void>((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);
    image.onload = () => {
      const { width, height } = image;
      if (Math.abs(width / height - ratio[0] / ratio[1]) < 0.1) {
        resolve();
      } else {
        message.error(`長:寬=${ratio[0]}:${ratio[1]}`);
        reject();
      }
    };
    image.onerror = reject;
  });
};

const UploadImageButton: FC<Props> = (props) => {
  const { imageUrl, limitSize, ratio, mode, hasPreviewAndDelete, onDelete, finishUpload } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [localImageUrl, setLocalImageUrl] = useState<string | undefined>(imageUrl);
  const [photoSource, setPhotoSource] = useState<string>("");

  useEffect(() => {
    if (!imageUrl?.startsWith("/efs")) {
      // efs暫時路徑不做顯示
      setLocalImageUrl(imageUrl);
    }
  }, [imageUrl]);

  const handleUploadImage = async (file: RcFile) => {
    setIsLoading(true);
    try {
      const url = await uploadVIPPhotoToS3({
        file,
        name: file.name,
      });

      const hostDomain = url.Location.split("media/vip")[0];
      const newUrl = url.Location.replace(hostDomain, process.env.REACT_APP_CLOUDFLARE_DOMAIN as string);
      finishUpload(newUrl);
      setIsLoading(false);
      return "success";
    } catch {
      message.error("上傳失敗");
      return "fail";
    }
  };

  function handleBeforeUpload(file: RcFile) {
    const validatedSize = limitSize ? checkSize(file, limitSize) : true; // 有傳limitSize才做驗證
    const validatedRatio = ratio ? checkAspectRatio(file, ratio) : true; // 有傳ratio才做驗證
    return validatedSize && validatedRatio;
  }

  const handleOnDelete = useCallback(
    (e: MouseEvent<HTMLImageElement>) => {
      e.preventDefault();
      e.stopPropagation();

      if (onDelete) {
        onDelete();
      }
    },
    [onDelete],
  );

  function StopPropagation(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
  }

  function handleOnPreview() {
    if (localImageUrl) setPhotoSource(localImageUrl);
  }

  function closePhotoModal() {
    setPhotoSource("");
  }

  const uploadButton = useMemo(() => {
    switch (mode) {
      case "small":
        return <Button icon={<UploadOutlined />}>上傳</Button>;
      case "normal":
      default:
        return (
          <UploadButtonWrapper>
            {isLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <UploadButtonText>Upload</UploadButtonText>
          </UploadButtonWrapper>
        );
    }
  }, [mode, isLoading]);

  const imageWrapper = useMemo(() => {
    switch (mode) {
      case "small":
        return (
          <SmallImageWrapper>
            <SmallImage src={localImageUrl} />
            <DeleteIcon onClick={handleOnDelete} />
          </SmallImageWrapper>
        );
      case "normal":
      default:
        return <ImageWrapper src={localImageUrl} />;
    }
  }, [mode, localImageUrl, handleOnDelete]);

  return (
    <>
      <UploadWrapper
        accept="image/*"
        name="avatar"
        listType={mode === "small" ? "text" : "picture-card"}
        showUploadList={false}
        action={handleUploadImage}
        beforeUpload={handleBeforeUpload}
      >
        {localImageUrl ? imageWrapper : uploadButton}
        {hasPreviewAndDelete && localImageUrl && (
          <HoverMask id="upload-hover-mask" onClick={StopPropagation}>
            <MaskIconContainer>
              <PreviewIcon onClick={handleOnPreview} />
              <DeleteOutlined onClick={handleOnDelete} />
            </MaskIconContainer>
          </HoverMask>
        )}
      </UploadWrapper>
      {photoSource && <PhotoModal source={photoSource} onClose={closePhotoModal} />}
    </>
  );
};

export default UploadImageButton;
