import downloadCSV from "@utils/downloadCSV";
import CoreAPI from "./CoreAPI";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface IMarketStatisticApi {
  fetchMarketStatisticList: (params: FetchMarketStatisticParams) => Promise<MarketStatisticListResult>;
  exportMarketStatisticList: (params: FetchMarketStatisticParams) => Promise<void>;
}

export interface MarketStatisticListResult {
  count: number;
  results: MarketStatisticListItem[];
}

export interface MarketStatisticListItem {
  lastLocationType: string;
  lastLocationId: number;
  lastLocationName: string;
  salesPageId: number;
  salesPageName: string;
  skus: string[];
  revenue: number;
  salesVolume: number;
  promotionId?: number;
}

export interface FetchMarketStatisticParams {
  limit: number;
  offset: number;
  startDate?: string;
  endDate?: string;
  lastLocationType?: number;
  tagType?: number;
  lastLocationIds?: number;
}

const marketStatisticApi: IMarketStatisticApi = {
  fetchMarketStatisticList: async (params) => {
    const getParams = transformCamelToSnake(params);

    const response = await apiClient.getData("/manage/order-statistics/market/", getParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  exportMarketStatisticList: async (params) => {
    const getParams = transformCamelToSnake(params);
    const response = await apiClient.getData("/manage/order-statistics/market/csv-download/", getParams);
    downloadCSV(response);
  },
};

export default marketStatisticApi;
