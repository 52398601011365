import { RootState } from "@redux/rootReducer";
import { clearProductDailyAggregationsFilter, setFilterOption } from "@redux/stockSlice";
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select } from "antd";
import locale from "antd/es/date-picker/locale/zh_TW";
import "moment/locale/zh-tw";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import type { Moment } from "moment";
import moment from "moment";

const { RangePicker } = DatePicker;

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  padding: 22px 13px 26px 39px;

  .ant-form-item-no-colon {
    width: 84px;
  }
`;

const CustomRangePicker = styled(RangePicker)`
  width: 100%;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

const stockSlice = (state: RootState) => state.stock;

type RangeValue = [Moment | null, Moment | null] | null;

export default function Filter(props: Props) {
  const { isVisible } = props;
  const [dates, setDates] = useState<RangeValue>(null);
  const dispatch = useDispatch();
  const { staffListResult, productCategory } = useSelector(stockSlice);

  const [form] = Form.useForm();

  const [categoryId, setCategoryId] = useState<{
    categoryLevel1: number;
    categoryLevel2: number;
    categoryLevel3: number;
  }>({
    categoryLevel1: -1,
    categoryLevel2: -1,
    categoryLevel3: -1,
  });

  const onSelectCategory = (field: string, value: number) => {
    switch (field) {
      case "categoryLevel1":
        setCategoryId({ categoryLevel1: value, categoryLevel2: -1, categoryLevel3: -1 });
        break;
      case "categoryLevel2":
        setCategoryId((prev) => ({ ...prev, categoryLevel2: value, categoryLevel3: -1 }));
        break;
      case "categoryLevel3":
        setCategoryId((prev) => ({ ...prev, categoryLevel3: value }));
        break;
      default:
        break;
    }
  };

  const onReset = () => {
    setCategoryId({
      categoryLevel1: -1,
      categoryLevel2: -1,
      categoryLevel3: -1,
    });
    dispatch(clearProductDailyAggregationsFilter());
    form.resetFields();
  };

  const onSubmit = (value: any) => {
    let category;

    if (categoryId.categoryLevel3 > 0) {
      category = categoryId.categoryLevel3;
    } else if (categoryId.categoryLevel2 > 0) {
      category = categoryId.categoryLevel2;
    } else if (categoryId.categoryLevel1 > 0) {
      category = categoryId.categoryLevel1;
    }

    const payload = {
      sku: value.sku,
      nameq: value.nameq,
      ownerId: value.ownerId > 0 ? value.ownerId : undefined,
      statsDateBefore: value.statsDate && value.statsDate[1].format("YYYY-MM-DD"),
      statsDateAfter: value.statsDate && value.statsDate[0].format("YYYY-MM-DD"),
      returnInboundOnly: value.returnInboundOnly ? 1 : undefined,
      productCategory: category,
    };
    dispatch(setFilterOption(payload));
  };

  const staffOptions = useMemo(() => {
    const options = staffListResult.results.map((staff) => (
      <Select.Option value={staff.id}>{staff.name}</Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇
      </Select.Option>,
    );
    return options;
  }, [staffListResult]);

  const categoryLevel1Options = useMemo(() => {
    const options = Object.values(productCategory.level1).map((ctgryItem) => (
      <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
    ));

    options.unshift(
      <Select.Option key={-1} value={-1}>
        請選擇第一層品類
      </Select.Option>,
    );
    return options;
  }, [productCategory]);

  const categoryLevel2Options = useMemo(() => {
    if (categoryId.categoryLevel1 > 0) {
      const level2Options = productCategory.level1[categoryId.categoryLevel1]!;
      const options = level2Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第一層品類，再選擇第二層品類
      </Select.Option>
    );
  }, [productCategory, categoryId.categoryLevel1]);

  const categoryLevel3Options = useMemo(() => {
    if (categoryId.categoryLevel2 > 0) {
      const level3Options = productCategory.level2[categoryId.categoryLevel2]!;
      const options = level3Options.children.map((ctgryItem) => (
        <Select.Option value={ctgryItem.id}>{ctgryItem.name}</Select.Option>
      ));
      options.unshift(
        <Select.Option key={-1} value={-1}>
          請選擇
        </Select.Option>,
      );
      return options;
    }
    return (
      <Select.Option key={-1} value={-1}>
        請先選擇第二層品類，再選擇第三層品類
      </Select.Option>
    );
  }, [productCategory, categoryId.categoryLevel2]);

  const disabledDate = (current: Moment) => {
    if (!dates) {
      return false;
    }

    const overDays = dates[0] && current.diff(dates[0], "days") > 180;

    return !!overDays;
  };

  useEffect(() => {
    const now = moment();
    const thirtyDaysAgo = moment().subtract(30, "days");

    form.setFieldsValue({
      statsDate: [now, thirtyDaysAgo],
    });
  }, [form]);

  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    }
  };

  const resetDate = () => {
    form.setFieldsValue({
      statsDate: [null, null],
    });
  };

  return (
    <Wrapper
      colon={false}
      form={form}
      isVisible={isVisible}
      initialValues={{
        sku: "",
        nameq: "",
        ownerId: -1,
        statsDate: "",
        returnInboundOnly: false,
      }}
      onFinish={onSubmit}
    >
      <Row>
        <Col span={24}>
          <Form.Item name="returnInboundOnly" valuePropName="checked">
            <Checkbox>退貨紀錄查詢</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item label="SKU" name="sku">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="商品名稱" name="nameq">
            <Input />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="商開負責人" name="ownerId">
            <Select>{staffOptions}</Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item label="日期" name="statsDate">
            <CustomRangePicker
              onOpenChange={onOpenChange}
              locale={locale}
              disabledDate={disabledDate}
              onCalendarChange={(val) => setDates(val)}
              onFocus={resetDate}
              value={dates}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item label="第一層品類">
            <Select value={categoryId.categoryLevel1} onChange={(value) => onSelectCategory("categoryLevel1", value)}>
              {categoryLevel1Options}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item label="第二層品類">
            <Select
              value={categoryId.categoryLevel2}
              disabled={categoryId.categoryLevel1 < 0}
              onChange={(value) => onSelectCategory("categoryLevel2", value)}
            >
              {categoryLevel2Options}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30}>
        <Col span={9}>
          <Form.Item label="第三層品類">
            <Select
              value={categoryId.categoryLevel3}
              disabled={categoryId.categoryLevel2 < 0}
              onChange={(value) => onSelectCategory("categoryLevel3", value)}
            >
              {categoryLevel3Options}
            </Select>
          </Form.Item>
        </Col>
        <Col span={16} push={9}>
          <Form.Item>
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}
