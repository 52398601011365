import { Button, Select, Row, Table, Spin, message } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import { ProcessValue, ProcessText } from "@page/Cost/constans";
import { fetchProcess, fetchPcmDetail, setShowPopup } from "@redux/brandOperation/PcmSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@redux/rootReducer";
import useDebounce from "@hooks/useDebounce";
import { loadMoreManufacturerList, manufacturerState } from "@redux/brandOperation/manufacturerSlice";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import { useParams, useNavigate } from "react-router-dom";
import pcmApi, { TradeTermValue } from "@api/brandOperation/pcmApi";
import { hideLoading, showLoading } from "@redux/notifySlice";
import { currencyValueLabel } from "@constant/CurrencyValue";
import EditPopup from "../EditPopup";

const Wrapper = styled.div`
  padding: 20px 20px 20px 25px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const CustomButton = styled(Button)`
  margin-left: 10px;
`;
const ButtonWrapper = styled.div``;
const Text = styled.p`
  width: 133px;
  margin-bottom: 33px;
`;

const Content = styled(Text)`
  width: max-content;
  margin-right: 30px;
`;

const OrderTab = () => {
  const dispatch = useDispatch();
  const [limit] = useState(10);
  const { id: pcmId } = useParams();
  const navigate = useNavigate();
  const [offset, setOffset] = useState(-limit);
  const handleOperPopup = (type: string) => {
    dispatch(setShowPopup(!showPopup));
  };
  const { manufacturerSelectList, manufacturerInfo, isSelectLoading } = useSelector(manufacturerState);
  const { pcmDetail, showPopup, isDetailFetching } = useSelector((state: RootState) => state.pcmSlice);

  const handleOnLoadMore = useCallback(() => {
    setOffset(offset + limit);
  }, [limit, offset]);

  const handleOnKeywordSearch = useDebounce((value: string) => {
    dispatch(loadMoreManufacturerList({ nameQ: value, offset: 0, limit: 300 }));
  }, 300);

  const vendorOptions = useMemo(() => {
    const { next, results } = manufacturerSelectList;
    const options = results.map((vdr) => (
      <Select.Option key={vdr.id} value={vdr.id || ""}>
        {vdr.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [handleOnLoadMore, manufacturerSelectList]);

  useEffect(() => {
    dispatch(loadMoreManufacturerList({ limit, offset: offset + limit }));
  }, [dispatch, limit, offset]);

  useEffect(() => {
    dispatch(fetchPcmDetail(pcmId));
  }, [dispatch, pcmId]);

  const deletePcm = async () => {
    showLoading();
    try {
      await pcmApi.fetchDeletePcm(pcmId);
      message.success("已作廢");
      return "success";
    } catch (error: any) {
      return {};
    } finally {
      hideLoading();
      navigate("/operation-of-brand/procurement");
    }
  };

  return (
    <Wrapper>
      <Spin spinning={isDetailFetching}>
        <PageTitle title="採購單" />
        <Header>
          <ButtonWrapper>
            <CustomButton
              type="primary"
              onClick={() => dispatch(fetchProcess({ processType: ProcessValue.PENDING, id: parseInt(pcmId, 10) }))}
              disabled={
                pcmDetail.status === ProcessValue.PENDING ||
                pcmDetail.status === ProcessValue.APPROVE ||
                pcmDetail.status === ProcessValue.REJECT
              }
            >
              送出
            </CustomButton>
            <CustomButton
              onClick={() => dispatch(fetchProcess({ processType: ProcessValue.APPROVE, id: parseInt(pcmId, 10) }))}
              disabled={
                pcmDetail.status === ProcessValue.CLEAN ||
                pcmDetail.status === ProcessValue.APPROVE ||
                pcmDetail.status === ProcessValue.REJECT
              }
            >
              審核通過
            </CustomButton>
            <CustomButton
              onClick={() => dispatch(fetchProcess({ processType: ProcessValue.REJECT, id: parseInt(pcmId, 10) }))}
              disabled={
                pcmDetail.status === ProcessValue.CLEAN ||
                pcmDetail.status === ProcessValue.APPROVE ||
                pcmDetail.status === ProcessValue.REJECT
              }
            >
              審核不通過
            </CustomButton>
            <CustomButton
              onClick={() => handleOperPopup("edit")}
              disabled={pcmDetail.status === ProcessValue.PENDING || pcmDetail.status === ProcessValue.APPROVE}
            >
              編輯
            </CustomButton>
            <CustomButton
              onClick={deletePcm}
              disabled={pcmDetail.status === ProcessValue.PENDING || pcmDetail.status === ProcessValue.APPROVE}
            >
              作廢
            </CustomButton>
          </ButtonWrapper>
        </Header>
        {showPopup && (
          <EditPopup
            visible={showPopup}
            onClose={() => dispatch(setShowPopup(!showPopup))}
            handleOnKeywordSearch={handleOnKeywordSearch}
            vendorOptions={vendorOptions}
            isSelectLoading={isSelectLoading}
            manufacturerInfo={manufacturerInfo}
          />
        )}
        <Row>
          <Text>採購編號</Text>
          <Content>{pcmDetail.pcmNumber}</Content>
        </Row>
        <Row>
          <Text>製造商</Text>
          <Content>{pcmDetail.producerName}</Content>
        </Row>
        <Row>
          <Text>採購人員</Text>
          <Content>{pcmDetail.staff.name}</Content>
        </Row>
        <Row>
          <Text>採購金額</Text>
          <Content>{pcmDetail.totalEstPcmAmount}</Content>
        </Row>
        <Row>
          <Text>狀態</Text>
          <Content>{ProcessText[pcmDetail.status]}</Content>
          <Table
            columns={[
              {
                key: "sectionManagerApproval",
                dataIndex: "sectionManagerApproval",
                title: "一階主管",
                width: 150,
                render: (data, value) => {
                  return <div>{ProcessText[data]}</div>;
                },
              },
              {
                key: "managerApproval",
                dataIndex: "managerApproval",
                title: "二階主管",
                width: 150,
                render: (data, value) => {
                  return <div>{ProcessText[data]}</div>;
                },
              },
            ]}
            dataSource={[
              {
                managerApproval: pcmDetail.managerApproval,
                sectionManagerApproval: pcmDetail.sectionManagerApproval,
              },
            ]}
            pagination={false}
            bordered
          />
        </Row>
        <Row>
          <Text>建單日期</Text>
          <Content>{pcmDetail.createdDate}</Content>
        </Row>
        <Row>
          <Text>下單日期</Text>
          <Content>{pcmDetail.deployDate}</Content>
        </Row>
        <Row>
          <Text>採購幣別</Text>
          <Content>{currencyValueLabel(pcmDetail.currency)?.label}</Content>
        </Row>
        <Row>
          <Text>採購匯率</Text>
          <Content>{pcmDetail.currencyRate}</Content>
        </Row>
        <Row>
          <Text>貿易條件</Text>
          <Content>{TradeTermValue[pcmDetail.tradeTerm]}</Content>
        </Row>
      </Spin>
    </Wrapper>
  );
};

export default OrderTab;
