import PageTitle from "@component/PageTitle";
import { Tabs } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AttributeTab from "./AttributeTab";
import ProductCategoryTab from "./ProductCategoryTab";

const Wrapper = styled.div`
  margin: 20px 20px 20px 25px;
  padding: 12px 24px 71px 16px;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
`;

const AttributeInfoEdit = () => {
  const { attributeId } = useParams();

  return (
    <Wrapper>
      <PageTitle title="屬性管理" />
      <Tabs type="card">
        <Tabs.TabPane tab="屬性管理" key="attributeType">
          <AttributeTab />
        </Tabs.TabPane>
        {attributeId && (
          <Tabs.TabPane tab="品類管理" key="tagType">
            <ProductCategoryTab />
          </Tabs.TabPane>
        )}
      </Tabs>
    </Wrapper>
  );
};

export default AttributeInfoEdit;
