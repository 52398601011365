export enum OnlineDistributor {
  MOMO = 1,
  PCHOME = 2,
  SHOPEE = 3,
  SHOPEE24H = 4,
  YAHOO = 5,
  BOOK = 6,
}

export enum OnlineDistributorName {
  MOMO = "MOMO",
  PCHOME = "PCHOME",
  SHOPEE = "蝦皮",
  SHOPEE24H = "蝦皮24H",
  YAHOO = "YAHOO",
  BOOK = "博客來",
}
// 通路
export const distributorOptions = [
  { key: "MOMO", name: "Momo", value: 1 },
  { key: "PCHOME", name: "PChome", value: 2 },
  { key: "SHOPEE", name: "蝦皮", value: 3 },
  { key: "SHOPEE24H", name: "蝦皮24H", value: 4 },
  { key: "YAHOO", name: "Yahoo", value: 5 },
  { key: "BOOK", name: "博客來", value: 6 },
];

export enum BatchModifyType {
  PAYMENT_DATE = "payment_date",
  INVOICE_NUMBER = "invoice_number",
  ALLOWANCE = "allowance",
}

export enum OnlineDistributorForm {
  ORDER = 1, // 訂單
  RETURN = 2, // 退貨
  PAYMENT = 3, // 活動贊助金
  SALE_BONUS = 4, // 銷售獎勵金
  PAYMENT_DETAIL = 5, // 贊助金扣款明細
  PACKAGE = 6, // 包裝費
  SHIPPING = 7, // 運費
  FIND = 8, //  罰款
  RETURN_FEE = 9, // 逆物流處理費
  PROCESSING_FEE = 10, // 加工費
  ANNIVERSARY = 11, // 週年慶贊助費費
  FESTIVAL = 12, // 民俗節慶贊助費
}

export const allDistributorFormTypeOptions = [
  { key: "ORDER", name: "訂單", value: OnlineDistributorForm.ORDER },
  { key: "RETURN", name: "退貨", value: OnlineDistributorForm.RETURN },
  { key: "PAYMENT", name: "活動贊助金", value: OnlineDistributorForm.PAYMENT },
  { key: "SALE_BONUS", name: "銷售獎勵金", value: OnlineDistributorForm.SALE_BONUS },
  { key: "PAYMENT_DETAIL", name: "行銷贊助金", value: OnlineDistributorForm.PAYMENT_DETAIL },
  { key: "PACKAGE", name: "包裝費", value: OnlineDistributorForm.PACKAGE },
  { key: "SHIPPING", name: "運費", value: OnlineDistributorForm.SHIPPING },
  { key: "FIND", name: "罰款", value: OnlineDistributorForm.FIND },
  { key: "RETURN_FEE", name: "逆物流處理費", value: OnlineDistributorForm.RETURN_FEE },
  { key: "PROCESSING_FEE", name: "加工費", value: OnlineDistributorForm.PROCESSING_FEE },
  { key: "ANNIVERSARY", name: "週年慶贊助費費", value: OnlineDistributorForm.ANNIVERSARY },
  { key: "FESTIVAL", name: "民俗節慶贊助費", value: OnlineDistributorForm.FESTIVAL },
];

export const distributorFormType: { [key: number]: OnlineDistributorForm[] } = {
  [OnlineDistributor.MOMO]: [
    OnlineDistributorForm.ORDER,
    OnlineDistributorForm.RETURN,
    OnlineDistributorForm.PAYMENT,
    OnlineDistributorForm.SALE_BONUS,
    OnlineDistributorForm.PAYMENT_DETAIL,
    OnlineDistributorForm.PACKAGE,
    OnlineDistributorForm.SHIPPING,
    OnlineDistributorForm.FIND,
  ],
  [OnlineDistributor.PCHOME]: [OnlineDistributorForm.ORDER],
  [OnlineDistributor.SHOPEE]: [OnlineDistributorForm.ORDER],
  [OnlineDistributor.SHOPEE24H]: [OnlineDistributorForm.ORDER],
  [OnlineDistributor.YAHOO]: [
    OnlineDistributorForm.ORDER,
    OnlineDistributorForm.PAYMENT,
    OnlineDistributorForm.SALE_BONUS,
    OnlineDistributorForm.PACKAGE,
    OnlineDistributorForm.SHIPPING,
    OnlineDistributorForm.RETURN_FEE,
    OnlineDistributorForm.PROCESSING_FEE,
    OnlineDistributorForm.ANNIVERSARY,
    OnlineDistributorForm.FESTIVAL,
  ],
  [OnlineDistributor.BOOK]: [OnlineDistributorForm.ORDER],
};

export const ChargeDistributorOptions = ["MOMO", "PCHOME", "SHOPEE", "SHOPEE24H", "YAHOO", "BOOK"];

export enum ChargeItemTitleType {
  WAREHOUSE_RENT = 1, // 倉租
  CONSUMABLES_SHIPPING = 2, // 耗材/派工/運費
  PAYMENT = 3, // 活動贊助金
  CHARGED_REPORT = 4, // 付費報表訂閱
  SERVICE_FEE = 5, // 平台服務費
  SMS_FEE = 6, // 簡訊費
  WAREHOUSE_FEE = 7, // 倉庫作業處理費寄倉商品
  WAREHOUSE_SETTING = 8, // 安裝費寄倉商品
  PRODUCT_LIABILITY_INSURANCE = 9, // 產品責任險費用
  IT_SERVICE_FEE = 10, // 資訊平台使用費
  INFO_SECURITY_FEE = 11, // 資訊安全維護費
  SYSTEM_SERVICE_FEE = 12, // 系統服務費
  RETURN_SHIPPING = 13, // 退貨運費
}
export const allChargeTitleTypeOptions = [
  { key: ChargeItemTitleType.WAREHOUSE_RENT, name: "倉租", value: "倉租" },
  { key: ChargeItemTitleType.CONSUMABLES_SHIPPING, name: "耗材/派工/運費", value: "耗材/派工/運費" },
  { key: ChargeItemTitleType.PAYMENT, name: "活動贊助金", value: "活動贊助金" },
  { key: ChargeItemTitleType.CHARGED_REPORT, name: "付費報表訂閱", value: "付費報表訂閱" },
  { key: ChargeItemTitleType.SERVICE_FEE, name: "平台服務費", value: "平台服務費" },
  { key: ChargeItemTitleType.SMS_FEE, name: "簡訊費", value: "簡訊費" },
  { key: ChargeItemTitleType.WAREHOUSE_FEE, name: "倉庫作業處理費寄倉商品", value: "倉庫作業處理費寄倉商品" },
  { key: ChargeItemTitleType.WAREHOUSE_SETTING, name: "安裝費寄倉商品", value: "安裝費寄倉商品" },
  { key: ChargeItemTitleType.PRODUCT_LIABILITY_INSURANCE, name: "產品責任險費用", value: "產品責任險費用" },
  { key: ChargeItemTitleType.IT_SERVICE_FEE, name: "資訊平台使用費", value: "資訊平台使用費" },
  { key: ChargeItemTitleType.INFO_SECURITY_FEE, name: "資訊安全維護費", value: "資訊安全維護費" },
  { key: ChargeItemTitleType.SYSTEM_SERVICE_FEE, name: "系統服務費", value: "系統服務費" },
  { key: ChargeItemTitleType.RETURN_SHIPPING, name: "退貨運費", value: "退貨運費" },
];

export const distributorChargeTitle: { [key: number]: ChargeItemTitleType[] } = {
  [OnlineDistributor.MOMO]: [
    ChargeItemTitleType.WAREHOUSE_RENT,
    ChargeItemTitleType.CONSUMABLES_SHIPPING,
    ChargeItemTitleType.PAYMENT,
    ChargeItemTitleType.CHARGED_REPORT,
    ChargeItemTitleType.SERVICE_FEE,
  ],
  [OnlineDistributor.PCHOME]: [
    ChargeItemTitleType.SMS_FEE,
    ChargeItemTitleType.WAREHOUSE_FEE,
    ChargeItemTitleType.WAREHOUSE_SETTING,
    ChargeItemTitleType.PRODUCT_LIABILITY_INSURANCE,
  ],
  [OnlineDistributor.YAHOO]: [
    ChargeItemTitleType.WAREHOUSE_RENT,
    ChargeItemTitleType.IT_SERVICE_FEE,
    ChargeItemTitleType.INFO_SECURITY_FEE,
  ],
  [OnlineDistributor.SHOPEE24H]: [ChargeItemTitleType.SYSTEM_SERVICE_FEE],
  [OnlineDistributor.SHOPEE]: [],
  [OnlineDistributor.BOOK]: [ChargeItemTitleType.PAYMENT, ChargeItemTitleType.RETURN_SHIPPING],
};
export default OnlineDistributorName;
