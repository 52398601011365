import { ExclamationCircleOutlined, FilterTwoTone, PlusOutlined } from "@ant-design/icons";
import { ChargeListItem } from "@api/financeApi";
import PopupBackground from "@component/PopupBackground";
import {
  addODCharge,
  deleteODCharge,
  exportODChargeListCSV,
  fetchODChargeList,
  OnlineDistributorStatisticsState,
  updateODChargeListParams,
} from "@redux/finance/onlineDistributorStatisticsSlice";
import { showRequiredFieldsWarning } from "@utils/commonMessage";
import { Button, DatePicker, Divider, Form, Input, InputNumber, Modal, Select, Table } from "antd";
import { FormInstance } from "antd/lib/form";
import { useForm } from "antd/lib/form/Form";
import { SelectValue } from "antd/lib/select";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { usePermission, StaffPermission } from "@hooks/usePermission";
import {
  allChargeTitleTypeOptions,
  ChargeDistributorOptions,
  distributorChargeTitle,
  distributorOptions,
} from "./constant";
import DashBoard from "../../../component/Common/DashBoard";
import Filter from "./Filter";
import { ChargeAmountLink, FlexBox, Header, ModalWrapper, PageText, Row, SelectPageSize, TableWrapper } from "./style";

const ChargeList = () => {
  const dispatch = useDispatch();
  const financeAccess = usePermission(StaffPermission.CAN_ACCESS_FINANCE_PAGE);
  const { isFetching, onlineDistributorChargeList, ODChargeListParams } = useSelector(OnlineDistributorStatisticsState);
  const { results: chargeList, totalAmount } = onlineDistributorChargeList;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);

  const [addChargeForm] = useForm();
  const dashBoardData = useMemo(() => [{ name: "總金額", value: totalAmount }], [totalAmount]);

  const columns: ColumnsType<ChargeListItem> = [
    {
      key: "distributorName",
      dataIndex: "distributorName",
      title: "通路別",
      width: 200,
    },
    {
      key: "date",
      dataIndex: "date",
      title: "對帳月份",
    },
    {
      key: "title",
      dataIndex: "title",
      title: "名目",
    },
    {
      key: "amount",
      dataIndex: "amount",
      title: "金額",
      width: 160,
    },
    {
      key: "",
      dataIndex: "",
      title: "",
      width: 100,
      render: (value, record) => {
        return (
          <ChargeAmountLink disabled={!financeAccess} onClick={() => handleDeleteItem(record)}>
            刪除
          </ChargeAmountLink>
        );
      },
    },
  ];

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      updateODChargeListParams({
        ...ODChargeListParams,
        offset: ODChargeListParams.limit * (value - 1),
      }),
    );
  };
  const handleOnPageSizeChange = (value: SelectValue) => {
    const params = {
      ...ODChargeListParams,
      limit: Number(value),
    };
    dispatch(updateODChargeListParams(params));
  };

  const handelAddChargeItem = (values: any) => {
    const payload = { ...values, date: values.date.startOf("month").format("YYYY-MM-DD") };
    dispatch(addODCharge(payload));
    setShowAddModal(false);
  };
  const handleDeleteItem = (record: ChargeListItem) => {
    if (!financeAccess) return;
    Modal.confirm({
      title: `你確定要刪除 ${record.title} 嗎?`,
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteODCharge(record.id));
      },
    });
  };
  const handleOnExport = () => {
    dispatch(exportODChargeListCSV());
  };

  useEffect(() => {
    dispatch(fetchODChargeList());
  }, [dispatch, ODChargeListParams]);

  return (
    <>
      <Header>
        <FlexBox>
          <Button icon={<FilterTwoTone />} onClick={() => setShowFilter(!showFilter)}>
            篩選
          </Button>
          <Button onClick={() => setShowAddModal(true)} disabled={!financeAccess}>
            新增
          </Button>
          <Button onClick={handleOnExport}>匯出</Button>
        </FlexBox>
        <Row>
          <PageText>{`總共${onlineDistributorChargeList.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => handleOnPageSizeChange(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Header>
      {showFilter && <Filter type="charge" />}
      <DashBoard dataset={dashBoardData} isFetching={isFetching} />
      <TableWrapper>
        <Table
          rowKey="id"
          loading={isFetching}
          scroll={{ x: "max-content", y: 500 }}
          columns={columns}
          dataSource={chargeList}
          pagination={{
            pageSize: ODChargeListParams.limit,
            current: page,
            showSizeChanger: false,
            total: onlineDistributorChargeList.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {showAddModal && (
        <AddChargeModal
          formModal={addChargeForm}
          onSubmit={handelAddChargeItem}
          onClose={() => setShowAddModal(false)}
        />
      )}
    </>
  );
};
export default ChargeList;

type AddModal = {
  formModal: FormInstance;
  onSubmit: (values: any) => void;
  onClose: () => void;
};

const AddChargeModal: FC<AddModal> = (props) => {
  const { formModal, onSubmit, onClose } = props;

  const [items, setItems] = useState<{ name: string; value: string }[]>([]);
  const [name, setName] = useState("");
  const inputRef = useRef<any>(null);

  const handleSubmit = (values: any) => {
    onSubmit(values);
    formModal.resetFields();
  };
  const handleClose = () => {
    onClose();
    formModal.resetFields();
  };
  const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const addItem = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    e.preventDefault();
    setItems([...items, { name, value: name }]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  return (
    <PopupBackground close={handleClose} fixed>
      <ModalWrapper width={500}>
        <Form
          form={formModal}
          labelCol={{ span: 5 }}
          onFinish={handleSubmit}
          onFinishFailed={showRequiredFieldsWarning}
        >
          <Form.Item label="通路名稱" name="distributor" required rules={[{ required: true, message: "" }]}>
            <Select
              placeholder="請選擇"
              onChange={() => {
                const formData = formModal.getFieldsValue();
                formModal.setFieldsValue({ ...formData, title: undefined });
              }}
            >
              {ChargeDistributorOptions.map((option) => {
                const optionData = distributorOptions.find((opt) => opt.key === option);
                return (
                  optionData && (
                    <Select.Option key={optionData.name} value={optionData.value}>
                      {optionData.name}
                    </Select.Option>
                  )
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item label="對帳月份" name="date" required rules={[{ required: true, message: "" }]}>
            <DatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {({ getFieldValue }) => {
              const distributor = getFieldValue("distributor");
              const titleTypeOptions = distributorChargeTitle[distributor];
              return (
                <Form.Item label="名目" name="title" required shouldUpdate rules={[{ required: true, message: "" }]}>
                  <Select
                    placeholder="請選擇"
                    disabled={!titleTypeOptions}
                    dropdownRender={(menu) => {
                      return (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Row>
                            <Input
                              placeholder="請輸入名目"
                              ref={inputRef}
                              value={name}
                              onChange={onNameChange}
                              style={{ margin: "0 8px" }}
                            />
                            <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
                              新增項目
                            </Button>
                          </Row>
                        </>
                      );
                    }}
                  >
                    {titleTypeOptions &&
                      titleTypeOptions.map((option) => {
                        const titleType = allChargeTitleTypeOptions.find((type) => type.key === option);
                        return (
                          titleType && (
                            <Select.Option key={titleType.name} value={titleType.value}>
                              {titleType.name}
                            </Select.Option>
                          )
                        );
                      })}
                    {items.map((item) => (
                      <Select.Option key={item.name} value={item.value}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );
            }}
          </Form.Item>
          <Form.Item label="金額" name="amount" required>
            <InputNumber />
          </Form.Item>
          <FlexBox>
            <Button onClick={handleClose}>關閉</Button>
            <Button type="primary" htmlType="submit">
              確認
            </Button>
          </FlexBox>
        </Form>
      </ModalWrapper>
    </PopupBackground>
  );
};
