import React, { FC } from "react";
import styled from "styled-components";
import { Tag, Tooltip, Spin } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import AnchorToChart from "../AnchorToChart";

const Wrapper = styled.div`
  padding: 25px;
  border: 1px solid #f0f0f0;
  margin-right: 20px;
  margin-bottom: 20px;
  min-width: 362px;
  border-radius: 2px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
`;
const HeaderTitle = styled.div`
  padding: 7px 16px;
  border: 1px solid #f0f0f0;
  background: #f2f4f5;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 25px;
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
`;
const BottomSection = styled(Section)`
  grid-row-start: 2;
`;
const SectionTitle = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 10px;
`;
const SectionContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const StyledTag = styled(Tag)`
  padding: 1px 6px;
  margin-left: 8px;
`;
const InfoIcon = styled(InfoCircleTwoTone)`
  margin-left: 4px;
`;

const OldMemberRevenueCard: FC = () => {
  const isFetchingOldMemberRevenueDashboard = useSelector(
    (state: RootState) => state.dashboard.isFetchingOldMemberRevenueDashboard,
  );
  const oldMemberRevenueDashboard = useSelector((state: RootState) => state.dashboard.oldMemberRevenueDashboard);
  const { currentData, diff } = oldMemberRevenueDashboard;
  const { revenue = 0, order = 0, avgOrderPrice = 0 } = currentData || {};
  const { revenueDiff = 0, orderDiff = 0, avgOrderPriceDiff = 0 } = diff || {};

  const revenueCurrency = USCurrencyFormmater.format(revenue);
  const revenueTagColor = revenueDiff < 0 ? "error" : "success";
  const revenueTagIcon = revenueDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const revenueChangePercentage = Math.round(revenueDiff * 1000) / 10; // 百分比取小數點第一位

  const orderTagColor = orderDiff < 0 ? "error" : "success";
  const orderTagIcon = orderDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const orderChangePercentage = Math.round(orderDiff * 1000) / 10; // 百分比取小數點第一位

  const avgOrderPriceCurrency = USCurrencyFormmater.format(avgOrderPrice);
  const avgOrderPriceTagColor = avgOrderPriceDiff < 0 ? "error" : "success";
  const avgOrderPriceTagIcon = avgOrderPriceDiff < 0 ? <CaretDownOutlined /> : <CaretUpOutlined />;
  const avgOrderPriceChangePercentage = Math.round(avgOrderPriceDiff * 1000) / 10; // 百分比取小數點第一位

  return (
    <Spin spinning={isFetchingOldMemberRevenueDashboard}>
      <Wrapper>
        <Header>
          <HeaderTitle>舊客營收</HeaderTitle>
          <AnchorToChart href="/dashboard/member-revenue" />
        </Header>
        <Grid>
          <Section>
            <SectionTitle>
              舊客貢獻營收
              <Tooltip
                placement="bottom"
                title="二次購買以上會員所貢獻之銷貨收入（進項）減去取消單費用（減項）、黑卡會費及退款費用（減項）"
              >
                <InfoIcon />
              </Tooltip>
            </SectionTitle>
            <SectionContent>
              {revenueCurrency}
              <StyledTag color={revenueTagColor} icon={revenueTagIcon}>
                {`${revenueChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </Section>
          <BottomSection>
            <SectionTitle>舊客訂單數</SectionTitle>
            <SectionContent>
              {order}
              <StyledTag color={orderTagColor} icon={orderTagIcon}>
                {`${orderChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
          <BottomSection>
            <SectionTitle>舊客客單價</SectionTitle>
            <SectionContent>
              {avgOrderPriceCurrency}
              <StyledTag color={avgOrderPriceTagColor} icon={avgOrderPriceTagIcon}>
                {`${avgOrderPriceChangePercentage}%`}
              </StyledTag>
            </SectionContent>
          </BottomSection>
        </Grid>
      </Wrapper>
    </Spin>
  );
};

export default OldMemberRevenueCard;
