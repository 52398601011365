import { setTagAttributeFilter } from "@redux/attributeSlice";
import { Button, Col, Form, Input, Row } from "antd";
import "moment/locale/zh-tw";
import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)<{ isVisible: boolean }>`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  padding-top: 20px;

  .ant-form-item-no-colon {
    width: 84px;
  }
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

type Props = {
  isVisible: boolean;
};

export default function Filter(props: Props) {
  const { isVisible } = props;

  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const onReset = () => {
    form.resetFields();
    dispatch(
      setTagAttributeFilter({
        nameQ: undefined,
        tagNameQ: undefined,
      }),
    );
  };

  const onSubmit = (values: unknown) => {
    dispatch(setTagAttributeFilter(values));
  };

  return (
    <Wrapper colon={false} form={form} isVisible={isVisible} onFinish={onSubmit}>
      <Row gutter={30}>
        <Col span={8}>
          <Form.Item label="屬性類型" name="nameQ">
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="屬性名稱" name="tagNameQ">
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={30} justify="end">
        <Col span={28}>
          <Form.Item>
            <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
            <Button type="primary" htmlType="submit">
              套用
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Wrapper>
  );
}
