import { ExclamationCircleOutlined } from "@ant-design/icons";
import { SalePlan, SalePlanOption } from "@api/saleApi";
import { SalesChannelType } from "@api/vendorApi";
import salePlanApi, { CreateSalePlanToRuleParams, FetchVendorProductContractParams } from "@api/salePlanApi";
import { ContractMode } from "@api/utils/normalizeContract";
import PopupBackground from "@component/PopupBackground";
import QuillEditor from "@component/QuillEditor";
import UploadImageButton from "@component/UploadImageButton";
import { createSalePlanToRule, reset, updateSalePlanToRule } from "@redux/ruleAddSalePlanSlice";
import { Button, Input, InputNumber, Modal, Table } from "antd";
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

interface Props {
  mode: "add" | "edit";
  salePlan?: SalePlan;
  salePlanId?: number;
  onClose: () => void;
}

const Wrapper = styled.div`
  height: 80vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 52px;
  width: 900px;
  background-color: white;
  padding: 30px 28px 20px 30px;
  position: relative;
`;
const FormBody = styled.div`
  overflow: auto;
  overflow: overlay;
`;
const FormGrid = styled.div`
  width: 100%;
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  column-gap: 25px;
  row-gap: 18px;
  align-items: center;
  margin-bottom: 13px;
  margin-right: auto;
`;
const Title = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Red = styled.span`
  color: red;
`;
const StyledInput = styled(Input)<{ width: number }>`
  width: ${({ width }) => width}px;
`;
const AddSKUButton = styled(Button)`
  margin: 0 8px;
`;
const TableNoWrap = styled.div`
  white-space: nowrap;
`;
const DeleteText = styled.div`
  font-size: 13px;
  color: #1890ff;
  cursor: pointer;
`;
const Footer = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
`;
const PlanPriceText = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const ButtonContainer = styled.div``;
const CancelButton = styled(Button)`
  margin-right: 10px;
`;
const ErrorText = styled.span`
  color: #ec6922;
`;
const StyledInputNumber = styled(InputNumber)`
  width: 70px;
`;
const TableWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.colorNeutral300};
  padding: 20px;
  margin-bottom: 15px;
`;
const TableTitle = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
`;

const EditSalePlan: FC<Props> = (props) => {
  const { salePlan, mode, salePlanId, onClose } = props;
  const dispatch = useAppDispatch();
  const { id: ruleId } = useParams();

  const [name, setName] = useState<string>("");
  const [img, setImg] = useState<string>("");
  const [sku, setSku] = useState<string>("");
  const [options, setOptions] = useState<SalePlanOption[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [shortQuillHtml, setShortQuillHtml] = useState("");

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  useEffect(() => {
    switch (mode) {
      case "add":
        if (salePlan) {
          setImg(salePlan.media?.url || "");
          setName(salePlan.name);
          setOptions(salePlan.options);
          setShortQuillHtml(salePlan.description);
        }
        break;
      case "edit":
        if (salePlanId) fetchSalePlan(salePlanId);
        break;
      default:
    }
  }, [salePlan, mode, salePlanId]);

  const fetchSalePlan = async (id: number) => {
    try {
      const response = await salePlanApi.getSalePlan(id);
      setImg(response.media?.url || "");
      setName(response.name);
      setOptions(response.options);
      setShortQuillHtml(response.description);
      return "success";
    } catch (error: any) {
      return "error";
    }
  };

  const planPrice = useMemo(() => {
    return options.reduce((acc, curValue) => {
      if (curValue.groupQty) return acc + curValue.groupQty * curValue.unitPrice;
      return acc;
    }, 0);
  }, [options]);

  const handleOnNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setName(value);
  };

  const handleOnSkuChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSku(value);
  };

  const handleOnDelete = (id: number) => () => {
    Modal.confirm({
      title: "你確定要刪除這筆資料?",
      icon: <ExclamationCircleOutlined />,
      content: "",
      okText: "是",
      cancelText: "否",
      onOk() {
        setOptions((prev) => prev.filter((opt) => opt.id !== id));
      },
    });
  };

  const handleOnAddProduct = async () => {
    if (!sku) return; // 空值
    if (options.some((opt) => opt.vpc.sku === sku)) {
      setErrorMessage(`商品不得重複，SKU=${sku}`);
      return;
    } // 已存在

    const params: FetchVendorProductContractParams = {
      excludeRuleId: Number(ruleId),
      sku,
      isActive: true,
      salesChannel: SalesChannelType.URMART,
    };
    try {
      const response = await salePlanApi.fetchVendorProductContract(params);
      const { results } = response;
      if (results.length === 0) setErrorMessage("SKU有誤/搜尋不到商品");
      else {
        const searchedProduct = results[0];
        const {
          id,
          product,
          price,
          cost,
          commissionRate,
          mode: contractMode,
          contract,
          modeName,
          vpcId,
        } = searchedProduct;

        // 轉單商品不可以加
        if (contractMode === ContractMode.Vendor) {
          setErrorMessage("非為倉庫出貨之商品，請重新輸入");
          return;
        }

        const isAlreadyExist = options.find((opt) => opt.id === id);
        if (!isAlreadyExist) {
          setErrorMessage("");
          setOptions((prev) => [
            ...prev,
            {
              id,
              name: product.name,
              commissionRate,
              vpc: {
                price,
                id: vpcId,
                sku: product.sku,
                commissionRate,
                productName: product.name,
                contractInfo: {
                  id: contract,
                  mode: contractMode,
                  modeName,
                },
              },
              groupQty: 0,
              unitPrice: price,
              unitCost: cost,
              optionDiscount: 0,
              canBuyCount: 0,
            },
          ]);
        }
      }
    } catch {
      // error
    }
  };

  const handleOnChangeQty = (id: number) => (value: string | number | undefined) => {
    const newOptiosn = options.map((opt) => {
      if (opt.id === id) {
        return {
          ...opt,
          groupQty: value === "" ? 0 : Number(value),
        };
      }
      return opt;
    });
    setOptions(newOptiosn);
  };
  const handleOnChangePrice = (id: number) => (value: string | number | undefined) => {
    const newOptiosn = options.map((opt) => {
      if (opt.id === id) {
        return {
          ...opt,
          unitPrice: value === "" ? 0 : Number(value),
          optionDiscount: 0,
        };
      }
      return opt;
    });

    setOptions(newOptiosn);
  };
  const handleOnChangeCommissionRate = (id: number) => (value: string | number | undefined) => {
    const newOptiosn = options.map((opt) => {
      if (opt.id === id) {
        return {
          ...opt,
          commissionRate: value === "" ? 0 : Number(value),
        };
      }
      return opt;
    });
    setOptions(newOptiosn);
  };

  const preventDefault = (e: KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleSubmit = () => {
    if (options.length === 0) {
      Modal.warning({
        title: "此方案無商品內容，請新增後再按下確定",
        okText: "我知道了",
      });
      return;
    }
    if (!img) {
      Modal.warning({
        title: "請上傳圖片",
        okText: "我知道了",
      });
      return;
    }

    const imageFilePath = img && !img.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? img : undefined;
    const params: CreateSalePlanToRuleParams = {
      name,
      imageDirPrefix: "saleplan/image",
      imageFilePath,
      ruleId: Number(ruleId),
      ruleProductsInfo: options.map((opt) => ({
        vpcId: opt.vpc.id,
        qty: opt.groupQty || 0,
        price: opt.unitPrice - opt.optionDiscount || 0,
        commissionRate: opt.commissionRate || 0,
      })),
      description: shortQuillHtml,
    };
    switch (mode) {
      case "add":
        dispatch(createSalePlanToRule(params));
        onClose();
        break;
      case "edit": {
        if (salePlanId) {
          const updateParams = {
            planId: salePlanId,
            params,
          };
          dispatch(updateSalePlanToRule(updateParams));
          onClose();
        }
        break;
      }
      default:
    }
  };

  const tableColumns = [
    {
      title: <TableNoWrap>SKU</TableNoWrap>,
      dataIndex: ["vpc", "sku"],
      width: 150,
    },
    {
      title: <TableNoWrap>商品名稱</TableNoWrap>,
      dataIndex: ["vpc", "productName"],
      width: 310,
    },
    {
      title: <TableNoWrap>數量</TableNoWrap>,
      key: "groupQty",
      dataIndex: "groupQty",
      render: (value: SalePlanOption["groupQty"], data: SalePlanOption) => (
        <StyledInputNumber
          value={value}
          onChange={(valueN) => handleOnChangeQty(data.id)(valueN as string | number | undefined)}
          min={0}
          onPressEnter={preventDefault}
        />
      ),
      width: 80,
    },
    {
      title: <TableNoWrap>單價</TableNoWrap>,
      key: "unitPrice",
      dataIndex: "unitPrice",
      render: (value: SalePlanOption["unitPrice"], data: SalePlanOption) => (
        <StyledInputNumber
          value={data.unitPrice - data.optionDiscount}
          onChange={(valueN) => handleOnChangePrice(data.id)(valueN as string | number | undefined)}
          min={0}
          onPressEnter={preventDefault}
        />
      ),
      width: 80,
    },
    {
      title: <TableNoWrap>抽成(%)</TableNoWrap>,
      key: "commissionRate",
      dataIndex: ["commissionRate"],
      render: (value: SalePlanOption["commissionRate"], data: SalePlanOption) => {
        switch (data.vpc.contractInfo.mode) {
          case ContractMode.Domestic:
          case ContractMode.Foreign:
            return null;
          default:
            return (
              <StyledInputNumber
                value={value}
                onChange={(valueN) => handleOnChangeCommissionRate(data.id)(valueN as string | number | undefined)}
                onPressEnter={preventDefault}
              />
            );
        }
      },
      width: 80,
    },
    {
      title: "",
      key: "",
      dataIndex: "",
      render: (value: any, data: SalePlanOption) => <DeleteText onClick={handleOnDelete(data.id)}>刪除</DeleteText>,
    },
  ];

  return (
    <PopupBackground close={onClose} fixed>
      <Wrapper>
        <FormBody>
          <FormGrid>
            <Title>
              加價購名稱
              <Red>*</Red>
            </Title>
            <StyledInput width={395} value={name} onChange={handleOnNameChange} />
            <Title>
              圖片
              <Red>*</Red>
            </Title>
            <UploadImageButton finishUpload={setImg} mode="small" imageUrl={img} />

            <Title>方案描述</Title>
            <QuillEditor
              height={120}
              toolbarBlacklist={["background", "link", "video", "image", "imageLink"]}
              toolbarName="short-description-toolbar"
              uploadImagePathname="sales_page_assets"
              defaultHtml={shortQuillHtml}
              onChangeQuill={(html) => {
                setShortQuillHtml(html);
              }}
              customFontList={["14px", "16px"]}
              customColorList={["#464c51", "#ec6922"]}
            />
            <Title>SKU</Title>
            <div>
              <StyledInput width={214} value={sku} onChange={handleOnSkuChange} onPressEnter={preventDefault} />
              <AddSKUButton onClick={handleOnAddProduct}>加入</AddSKUButton>
              <ErrorText>{errorMessage}</ErrorText>
            </div>
          </FormGrid>
          <TableWrapper>
            <TableTitle>加購價組成</TableTitle>
            <Table
              dataSource={options}
              columns={tableColumns}
              scroll={{ x: "max-content", y: 185 }}
              tableLayout="auto"
              pagination={false}
              loading={false}
              rowKey="id"
            />
          </TableWrapper>
        </FormBody>
        <Footer>
          <PlanPriceText>{`此加價購方案價錢: $${planPrice}`}</PlanPriceText>
          <ButtonContainer>
            <CancelButton onClick={onClose}>取消</CancelButton>
            <Button type="primary" onClick={handleSubmit}>
              確定
            </Button>
          </ButtonContainer>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default EditSalePlan;
