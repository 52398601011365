enum VendorLogisticsValue {
  warehouse = 1,
  transfer = 2,
  preorder = 3,
}

export const MapLogisticValue = {
  [VendorLogisticsValue.warehouse]: "倉庫",
  [VendorLogisticsValue.transfer]: "轉單",
  [VendorLogisticsValue.preorder]: "預購",
};

export default VendorLogisticsValue;
