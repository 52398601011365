import { SlotPatternValue } from "../../constant";

export enum Field {
  VIDEO_URL = "videoUrl",
  EFFECTIVE_TIME = "effectiveTime",
  URL = "url",
  IMAGE = "imageUrl",
  NAME = "name",
  RANK = "rank",
  OPEN_NEW_PAGE = "openNewPage",
  TEXT = "text",
  TEXT_PRODUCT = "textProduct",
  PROMO_NAME = "promoName",
  PROMO_IMAGE = "promoImage",
  TITLE = "title",
}

export interface SlotEditMapping {
  [pattern: number]: EditItemType[];
}
export interface EditItemType {
  type: string;
  required: boolean;
  ratioSize?: [number, number];
  ratio?: [number, number];
  requiredText?: string;
  supportedUrl?: string[];
}

const slotEditMapping: SlotEditMapping = {
  [SlotPatternValue.FILM_PATTERN]: [
    {
      type: Field.VIDEO_URL,
      required: true,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.BASE_PATTERN_ONE_ROW]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.IMAGE,
      ratio: [2, 1],
      required: true,
    },
  ],
  [SlotPatternValue.BASE_PATTERN_TWO_ROWS]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.IMAGE,
      ratio: [2, 1],
      required: true,
    },
  ],
  [SlotPatternValue.CAROUSEL_PATTERN]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [3, 1],
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.FLAT_CAROUSEL_PATTERN]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [10, 1],
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.SINGLE_IMAGE_PATTERN]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.CATEGORY_RECOMMENDATION_PATTERN]: [
    {
      type: Field.NAME,
      required: true,
    },
    {
      type: Field.URL,
      required: true,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [1, 1],
      required: true,
    },
  ],
  [SlotPatternValue.LIMITED_TIME_PATTERN_ONE_ROW]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.LIMITED_TIME_PATTERN_TWO_ROWS]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_TWO_IMG]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [2, 1],
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_THREE_IMG]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [2, 1],
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.TOP_ACTIVITY_PATTERN_FOUR_IMG]: [
    {
      type: Field.NAME,
      required: false,
    },
    {
      type: Field.URL,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [2, 1],
      required: true,
    },
    {
      type: Field.OPEN_NEW_PAGE,
      required: false,
    },
  ],
  [SlotPatternValue.TOPIC_PATTERN]: [
    {
      type: Field.NAME,
      required: true,
    },
    {
      type: Field.URL,
      required: true,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
  ],
  [SlotPatternValue.RANKING_PATTERN]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_ONE_ROW]: [
    {
      type: Field.NAME,
      required: true,
    },
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [1, 1],
      required: true,
    },
  ],
  [SlotPatternValue.KEYWORD_SEARCH_PATTERN_TWO_ROWS]: [
    {
      type: Field.NAME,
      required: true,
    },
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.RANK,
      required: false,
    },
    {
      type: Field.IMAGE,
      ratio: [1, 1],
      required: true,
    },
  ],
  [SlotPatternValue.HTML_TEXT_PATTERN]: [
    {
      type: Field.TEXT,
      required: false,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.HTML_TEXT_PRODUCT_PATTERN]: [
    {
      type: Field.TITLE,
      required: true,
    },
    {
      type: Field.TEXT_PRODUCT,
      required: false,
    },

    {
      type: Field.URL,
      requiredText: "*網址僅限分類頁/標籤頁/主題分類頁/搜尋結果頁/活動頁，且開頭為https://",
      supportedUrl: ["/category", "/tag", "/topic-category", "/search", "/promotion"],
      required: true,
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.VIP_RECOMMENDATION_PATTERN]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限銷售頁，且開頭為https://",
      supportedUrl: ["/salespage"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
  [SlotPatternValue.VIP_ONLY_PATTERN]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "*網址僅限銷售頁，且開頭為https://",
      supportedUrl: ["/salespage"],
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],

  [SlotPatternValue.ANNOUNCEMENT_PATTERN]: [
    {
      type: Field.URL,
      required: true,
      requiredText: "",
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
    {
      type: Field.PROMO_IMAGE,
      ratio: [3, 4], // 有ratioSize就會有ratio
      ratioSize: [900, 1200], // 純顯示文字用
      required: true,
    },
  ],
  [SlotPatternValue.SEARCH_RECOMMEND_PATTERN]: [
    {
      type: Field.PROMO_NAME,
      required: true,
    },
    {
      type: Field.URL,
      required: true,
      requiredText: "",
    },
    {
      type: Field.EFFECTIVE_TIME,
      required: true,
    },
  ],
};

export default slotEditMapping;
