import { ExclamationCircleOutlined, PlusCircleOutlined, UploadOutlined } from "@ant-design/icons";
import { AddSalesRecordItem, VenderSalesRecordItem } from "@api/vendorApi";
import PopupBackground from "@component/PopupBackground";
import {
  addVenderSalesRecord,
  createVendorSalesInvoice,
  deleteVenderSalesRecord,
  fetchVenderSalesRecordList,
  vendorState,
} from "@redux/vendorSlice";
import { Button, Col, DatePicker, Form, InputNumber, Modal, Row, Select, Table, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/zh_TW";
import { uploadMedia } from "@api/uploadMediaApi";
import { RcFile } from "antd/lib/upload";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import PhotoModal from "@component/UploadImageButton/PhotoModal";

const Wrapper = styled.div``;
const WrapperGrid = styled.div`
  display: Grid;
  grid-template-rows: 1fr auto;
  height: 100%;
`;
const WrapperModal = styled.div`
  width: 620px;
  height: 382px;
  background: ${({ theme }) => theme.colorNeutral100};
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  padding: 20px;
`;
const RequireMark = styled.span`
  color: #ff4d4f;
  margin-right: 4px;
  font-size: 16px;
`;
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
`;
const ImageWrapper = styled.div`
  cursor: pointer;
`;
const Img = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;
const PdfDiv = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  margin: auto;
`;
const ClickContract = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;
const titleOptions = [
  { label: "短約", value: "短約" },
  { label: "行銷方案", value: "行銷方案" },
];

const AddModal = (props: { onSubmit: (data: AddSalesRecordItem) => void; onClose: () => void }) => {
  const { onSubmit, onClose } = props;
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploadFile, setUploadFile] = useState<string>("");

  const handleUploadImage = async (file: RcFile) => {
    try {
      const result = await uploadMedia(file);
      setUploadFile(result);
      return "";
    } catch (error: any) {
      return "error";
    }
  };
  const handleChange: UploadProps["onChange"] = (info) => {
    const newFileList = info.fileList.slice(-1);
    setFileList(newFileList);
    form.setFieldsValue({ attachment: info.file });
  };
  const handleSubmit = () => {
    const formData = form.getFieldsValue();
    const data: AddSalesRecordItem = {
      title: formData.title,
      amount: formData.amount,
      startDt: moment(formData.cooperationPeriod[0]).format("YYYY-MM-DD"),
      endDt: moment(formData.cooperationPeriod[1]).format("YYYY-MM-DD"),
      remitDt: moment(formData.remittanceDate).format("YYYY-MM-DD"),
      filePath: uploadFile,
      dirPrefix: "",
    };
    onSubmit(data);
    onClose();
  };
  return (
    <PopupBackground close={onClose} fixed>
      <WrapperModal>
        <Form
          form={form}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          labelAlign="left"
          onFinish={handleSubmit}
          style={{ height: "100%" }}
        >
          <WrapperGrid>
            <Wrapper>
              <Form.Item label="項目" name="title" rules={[{ required: true, message: "" }]}>
                <Select options={titleOptions} placeholder="請選擇" />
              </Form.Item>
              <Form.Item label="金額" name="amount" rules={[{ required: true, message: "" }]}>
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item
                label="合作時間"
                name="cooperationPeriod"
                wrapperCol={{ span: 16 }}
                rules={[{ required: true, message: "" }]}
              >
                <DatePicker.RangePicker locale={locale} style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="匯款日期" name="remittanceDate" rules={[{ required: true, message: "" }]}>
                <DatePicker locale={locale} style={{ width: "100%" }} />
              </Form.Item>
              <Row>
                <Col span={4} style={{ lineHeight: "32px", alignItems: "center" }}>
                  <RequireMark>*</RequireMark>憑證附件:
                </Col>
                <Col flex="auto">
                  <Form.Item
                    name="attachment"
                    rules={[{ required: true, message: "請上傳附件" }]}
                    wrapperCol={{ span: 16 }}
                  >
                    <Upload
                      action={handleUploadImage}
                      fileList={fileList}
                      onChange={handleChange}
                      style={{ width: "100%" }}
                    >
                      <Button icon={<UploadOutlined />}>點擊上傳</Button>
                    </Upload>
                  </Form.Item>
                </Col>
              </Row>
            </Wrapper>
            <Footer>
              <Button onClick={onClose} style={{ marginRight: 8 }}>
                取消
              </Button>
              <Button type="primary" htmlType="submit">
                確定
              </Button>
            </Footer>
          </WrapperGrid>
        </Form>
      </WrapperModal>
    </PopupBackground>
  );
};

const SalesRecord = () => {
  const dispatch = useDispatch();
  const { vendorId: vendorIdStr } = useParams();
  const vendorId = parseInt(vendorIdStr, 10);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [photoSource, setPhotoSource] = useState<string>("");

  const { isFetchingVendorList, salesRecordList } = useSelector(vendorState);

  const handleDeleteRecord = (values: VenderSalesRecordItem) => {
    Modal.confirm({
      title: "你確定要刪除嗎?",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(deleteVenderSalesRecord({ vendorId, recordId: values.id }));
      },
    });
  };
  const handleCreateInvoice = (values: VenderSalesRecordItem) => {
    Modal.confirm({
      title: "你確定要開立發票嗎？",
      content: "開立發票後，系統將會寄送通知信給建立人員",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(createVendorSalesInvoice({ vendorId, recordId: values.id }));
      },
    });
  };
  const handleAddSubmit = (addItem: AddSalesRecordItem) => {
    dispatch(addVenderSalesRecord({ vendorId, addItem }));
  };
  const handleOnPhotoClick = (url: string) => {
    setPhotoSource(url);
  };
  useEffect(() => {
    dispatch(fetchVenderSalesRecordList(vendorId));
  }, [dispatch, vendorId]);

  const tableColumns: ColumnsType<VenderSalesRecordItem> = [
    {
      title: "建立日期",
      key: "createdDt",
      dataIndex: "createdDt",
      width: 120,
    },
    {
      title: "項目名稱",
      key: "title",
      dataIndex: "title",
      width: "15%",
    },
    {
      title: "金額",
      key: "amount",
      dataIndex: "amount",
      width: 100,
    },
    {
      title: "合作開始時間",
      key: "startDt",
      dataIndex: "startDt",
      render: (value, _) => moment(value).format("YYYY-MM-DD"),
      width: 120,
    },
    {
      title: "合作結束時間",
      key: "endDt",
      dataIndex: "endDt",
      width: 120,
      render: (value, _) => moment(value).format("YYYY-MM-DD"),
    },
    {
      title: "憑證附件",
      key: "attachment",
      dataIndex: "attachment",
      width: 100,
      align: "center",
      render: (value) => {
        const extension = value.split(".").pop();
        if (extension === "pdf") {
          return (
            <PdfDiv>
              <embed src={value} width="100%" height="100%" style={{ overflow: "hidden" }} />
              <ClickContract onClick={() => window.open(value, "_blank")} />
            </PdfDiv>
          );
        }
        return (
          <ImageWrapper
            role="presentation"
            onClick={() => {
              handleOnPhotoClick(value);
            }}
          >
            <Img src={value} />
          </ImageWrapper>
        );
      },
    },
    {
      title: "建立人員",
      key: "staff",
      dataIndex: "staff",
    },
    {
      title: "發票",
      key: "invoiceDt",
      dataIndex: "invoiceDt",
      width: 120,
      render: (value) => value || "尚未開立",
    },
    {
      title: "",
      dataIndex: "",
      width: 180,
      render: (_, values) => (
        <Row>
          <Button type="link" onClick={() => handleCreateInvoice(values)} disabled={!!values.invoiceDt}>
            開立發票
          </Button>
          <Button
            type="link"
            onClick={() => {
              handleDeleteRecord(values);
            }}
            disabled={!!values.invoiceDt}
          >
            刪除
          </Button>
        </Row>
      ),
    },
  ];

  return (
    <Wrapper>
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          setOpenAddModal(true);
        }}
        style={{ marginBottom: 24 }}
      >
        建立帳單
      </Button>
      <Table
        loading={isFetchingVendorList}
        columns={tableColumns}
        dataSource={salesRecordList}
        pagination={false}
        scroll={{ x: "max-content" }}
        style={{ marginBottom: 40 }}
        rowKey="id"
      />
      {photoSource && <PhotoModal source={photoSource} onClose={() => setPhotoSource("")} />}
      {openAddModal && (
        <AddModal
          onSubmit={handleAddSubmit}
          onClose={() => {
            setOpenAddModal(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default SalesRecord;
