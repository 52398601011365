import { ContractType } from "@api/salesReportApi";
import { ContractMode, modeList } from "@api/utils/normalizeContract";
import { fetchAddContract, fetchUpdateContract, resetEditStatus } from "@redux/contractSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Form, Input, message, Select, Switch } from "antd";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";

const Wrapper = styled(Form)`
  padding: 15px 13px;

  .ant-form-item-no-colon {
    width: 124px;
  }
`;

const CustomInput = styled(Input)<{ width: string }>`
  width: 100%;
  max-width: ${({ width }) => width};
`;

const CustomSelect = styled(Select)<{ width: string }>`
  width: 100%;
  max-width: ${({ width }) => width};
`;

const VersionNotice = styled.span`
  margin: 5px 10px 0 10px;
  ${fontStyle("14px", "22px")};
  color: ${({ theme }) => theme.colorNeutral500};
`;

const ButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: calc(100% - 208px);
  padding: 12px 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #fafafa;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
`;

const Row = styled.div`
  display: flex;
`;

const contractSlice = (state: RootState) => state.contract;

export default function ContractContent() {
  const navigate = useNavigate();
  const { contractId } = useParams();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { contractDetail, isEditDone, redirectContractId, errorMessage } = useSelector(contractSlice);

  const [contractState, setContractState] = useState<{ modeName: string; note: string }>({ modeName: "", note: "" });

  const formDisplayName = useMemo(() => {
    let str = "";

    if (contractState.modeName) {
      str += `${contractState.modeName}_`;
    }

    if (contractState.note) {
      str += `${contractState.note}_`;
    }

    return `${str}${moment().format("YYYYMMDD")}`;
  }, [contractState]);

  const updateContractState = (currentValue: any, allValue: any) => {
    setContractState({ modeName: modeList[allValue.mode] || "", note: allValue.note || "" });
  };

  const onSubmit = (value: any) => {
    if (contractDetail) {
      dispatch(
        fetchUpdateContract({
          contractId: parseInt(contractId, 10),
          mode: value.mode,
          note: value.note,
          isActive: value.isActive,
          type: value.type,
        }),
      );
    } else {
      dispatch(
        fetchAddContract({
          mode: value.mode,
          note: value.note,
          isActive: value.isActive,
          type: value.type,
        }),
      );
    }
  };

  useEffect(() => {
    if (contractDetail) {
      form.resetFields(); // 非同步更新用
      setContractState({ modeName: contractDetail.modeName, note: contractDetail.note });
    }
  }, [contractDetail, form]);

  useEffect(() => {
    if (contractState) {
      form.resetFields(["displayName"]); // 更新合約名稱用
    }
  }, [contractState, form]);

  useEffect(() => {
    if (isEditDone) {
      if (contractId) {
        message.success("編輯成功");
      } else {
        message.success("新增成功");
      }
    }
  }, [isEditDone, contractId]);

  useEffect(() => {
    if (redirectContractId > 0) {
      dispatch(resetEditStatus()); // 避免新增和編輯提示同時存在
      navigate(`/contract/edit/${redirectContractId}`);
    }
  }, [dispatch, navigate, redirectContractId]);

  useEffect(() => {
    if (errorMessage) {
      message.error(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Wrapper
      colon={false}
      form={form}
      initialValues={{
        isActive: contractDetail ? contractDetail.isActive : true,
        version: contractDetail?.version || moment().format("YYYYMMDD"),
        displayName: formDisplayName,
        mode: contractDetail?.mode || ContractMode.Warehouse,
        note: contractDetail?.note || "",
        type: contractDetail?.type || undefined,
      }}
      onValuesChange={updateContractState}
      onFinish={onSubmit}
    >
      <Form.Item label="啟用" name="isActive" valuePropName="checked">
        <Switch />
      </Form.Item>
      <Row>
        <Form.Item label="合約版本" name="version">
          <CustomInput width="188px" disabled />
        </Form.Item>
        <VersionNotice>*系統自動帶入當下年月日</VersionNotice>
      </Row>
      <Form.Item label="合約名稱" name="displayName">
        <CustomInput width="500px" disabled />
      </Form.Item>
      <Form.Item label="合作方式" name="mode">
        <CustomSelect width="188px">
          {Object.keys(modeList).map((key) => (
            <Select.Option key={key} value={parseInt(key, 10)}>
              {modeList[parseInt(key, 10)]}
            </Select.Option>
          ))}
        </CustomSelect>
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) =>
          (getFieldValue("mode") === ContractMode.Warehouse || getFieldValue("mode") === ContractMode.Vendor) && (
            <Form.Item label="合約狀態" name="type">
              <CustomSelect width="188px">
                <Select.Option value={ContractType.NORMAL}>一般</Select.Option>
                <Select.Option value={ContractType.TEMPORARY}>臨時</Select.Option>
              </CustomSelect>
            </Form.Item>
          )
        }
      </Form.Item>
      <Form.Item label="名稱註記" name="note">
        <CustomInput width="500px" placeholder="最多32個字元" maxLength={32} />
      </Form.Item>
      <Form.Item>
        <ButtonWrapper>
          <VersionNotice>*建立後顯示後扣項目頁面</VersionNotice>
          <Button type="primary" htmlType="submit">
            {contractDetail ? "儲存此分頁" : "建立資料"}
          </Button>
        </ButtonWrapper>
      </Form.Item>
    </Wrapper>
  );
}
