/*
example error response(706,750是error code):
{
  "status_code": 9009,
  "error_message": "訂單新增/修改有誤",
  "result": {
      "706": [],
      "750": [
          "大潤發_123456781"
      ]
  }
}

/**
 * Translate error object to array of error messages
 * @param errorObject error object
 * @returns array of error messages  ["大潤發_123456781"]
 *
 */
const translateErrors = (errorObject: any) => {
  if (!errorObject) return [];
  const translatedErrors: string[] = [];
  Object.keys(errorObject).forEach((key) => {
    if (errorObject[key].length > 0) {
      errorObject[key].forEach((item: string) => {
        translatedErrors.push(` ${item}`);
      });
    }
  });

  return translatedErrors;
};

export default translateErrors;
