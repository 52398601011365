import CoreAPI from "./CoreAPI";

const apiClient = new CoreAPI({
  apiVersion: "",
});

export enum DeleteModule {
  PAGE_INFO = "page_info",
  SALES_PAGE = "sales_page",
  TOPIC = "topic",
  TOPIC_CATEGORY = "topic_category",
  SALES_CATEGORY = "sales_category",
  TAG = "tag",
  DISCOUNT_FRAME = "image_frame",
}

export const uploadMedia = async (file: File): Promise<string> => {
  const formData = new FormData();
  formData.append("force_update", "1");
  formData.append("media_file", file);

  const response = await apiClient.postData("upload-media/", formData);
  return response.data.result;
};

export const removeMedia = async (params: {
  module: DeleteModule;
  // 擇一
  pageInfoId?: number;
  salesPageId?: number;
  topicId?: number;
  topicCategoryId?: number;
  salesCategoryId?: number;
  tagId?: number;
  discountFrameId?: number;
  mediaId: number;
}): Promise<void> => {
  await apiClient.deleteData(
    `remove-media/?module=${params.module}&module_id=${
      params.pageInfoId ||
      params.salesPageId ||
      params.topicId ||
      params.topicCategoryId ||
      params.salesCategoryId ||
      params.tagId ||
      params.discountFrameId
    }&media_id=${params.mediaId}`,
    {},
  );
};
