enum ShippingTypeValue {
  HOME_DELIVERY = "宅配",
  SEVEN_ELEVEN = "7-11",
  CVS = "全家",
  RETURN = "退貨便",
}

export enum ShippingTypeValueByNumber {
  HOME_DELIVERY = 1,
  SEVEN_ELEVEN = 2,
  CVS = 3,
  RETURN = 4,
}

/**
 * 做這層處理的目的在，送 api 時並不是直接送中文是送數字，以下為暫時有的項
 */
export function transformShippingMethod(method: string): number {
  if (method === ShippingTypeValue.HOME_DELIVERY) return 1;
  if (method === ShippingTypeValue.SEVEN_ELEVEN) return 2;
  if (method === ShippingTypeValue.CVS) return 3;
  if (method === ShippingTypeValue.RETURN) return 4;
  return -1;
}

export function transformShippingCode(code: number): string {
  if (code === 1) return ShippingTypeValue.HOME_DELIVERY;
  if (code === 2) return ShippingTypeValue.SEVEN_ELEVEN;
  if (code === 3) return ShippingTypeValue.CVS;
  if (code === 4) return ShippingTypeValue.RETURN;
  return "";
}

export default ShippingTypeValue;
