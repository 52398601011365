import { PlusCircleTwoTone } from "@ant-design/icons";
import { Brand } from "@api/utils/normalizeBrand";
import { fetchBrandList } from "@redux/brandSlice";
import { RootState } from "@redux/rootReducer";
import { Button, Input, notification, Select, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import PageTitle from "@component/PageTitle";
import EditBrand from "./EditBrand";

const { Option } = Select;

enum SearchType {
  ID = "id",
  NAME = "name",
}

const Wrapper = styled.div`
  padding: 20px 41px 20px 25px;
`;

const Row = styled.div<{ justify?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
`;

const FullRow = styled(Row)`
  flex: 1;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const CustomInput = styled(Input)`
  &&& {
    width: 31%;
    margin-right: 10px;
    padding: 4px 11px;
  }
`;

const CustomButton = styled(Button)`
  &&& {
    margin-right: 10px;
  }
`;

const CustomSelect = styled(Select)`
  &&& .ant-select-selector {
    width: 104px;
  }
`;

const TableWrapper = styled.div`
  border: solid 1px #f0f0f0;
  border-radius: 2px;
  padding: 20px 28px 22px 22px;
  margin-top: 20px;
`;

const ProductLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme }) => theme.colorSuccess500};
`;

const brandSlice = (state: RootState) => state.brand;

export default function BrandPage() {
  const dispatch = useDispatch();
  const { isFetching, brandList, brandCount, isEditDone, duplicatedBrandError } = useSelector(brandSlice);
  useEffect(() => {
    const notificationKey = "duplicatedBrand";

    if (duplicatedBrandError) {
      notification.error({
        key: notificationKey,
        message: "品牌名稱重複",
      });
    } else {
      notification.close(notificationKey);
    }
  }, [duplicatedBrandError]);
  const [editBrand, setEditBrand] = useState<{ id: number; name: string } | undefined>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  const [searchInfo, setSearchInfo] = useState<{ type: SearchType; value: string | number | undefined }>({
    type: SearchType.NAME,
    value: undefined,
  });

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(
      fetchBrandList({
        limit: pageSize,
        offset: pageSize * (value - 1),
        searchId: searchInfo.type === SearchType.ID ? (searchInfo.value as number) : undefined,
        searchName: searchInfo.type === SearchType.NAME ? (searchInfo.value as string) : undefined,
      }),
    );
  };

  const fetchSearchBrand = () => {
    dispatch(
      fetchBrandList({
        limit: pageSize,
        offset: 0,
        searchId: searchInfo.type === SearchType.ID ? (searchInfo.value as number) : undefined,
        searchName: searchInfo.type === SearchType.NAME ? (searchInfo.value as string) : undefined,
      }),
    );
  };

  const fetchPageSizeChange = (value: string) => {
    setPageSize(parseInt(value as string, 10));
    dispatch(
      fetchBrandList({
        limit: parseInt(value as string, 10),
        offset: 0,
        searchId: searchInfo.type === SearchType.ID ? (searchInfo.value as number) : undefined,
        searchName: searchInfo.type === SearchType.NAME ? (searchInfo.value as string) : undefined,
      }),
    );
  };

  const searchInputHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;
    setSearchInfo({ ...searchInfo, value: e.target.value });

    if (!searchValue) {
      setPage(1);
      dispatch(
        fetchBrandList({
          limit: pageSize,
          offset: 0,
        }),
      );
    }
  };

  const openBrandModal = () => {
    setEditModal(true);
    setEditBrand(undefined);
  };

  const openExistBrandModal = (brand: Brand) => {
    setEditModal(true);
    setEditBrand({
      id: brand.id,
      name: brand.name,
    });
  };

  const columns = [
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
      width: 120,
    },
    {
      key: "name",
      dataIndex: "name",
      title: "名稱",
      width: 350,
    },
    {
      key: "productQty",
      dataIndex: "productQty",
      title: "商品數量",
      render: (productQty: number, brand: Brand) => (
        <Row justify="space-between">
          <ProductLink href={`/product?brand=${brand.name}`} target="_blank">
            {productQty}
          </ProductLink>
          <Button type="link" onClick={() => openExistBrandModal(brand)}>
            編輯
          </Button>
        </Row>
      ),
    },
  ];

  useEffect(() => {
    setPage(1);
    dispatch(
      fetchBrandList({
        limit: 20,
        offset: 0,
      }),
    );
  }, [dispatch]);

  // 新增編輯重新取資料，不在 redux 做的原因在要保持 pageSize,page,search
  useEffect(() => {
    if (isEditDone) {
      setPage(1);
      dispatch(
        fetchBrandList({
          limit: pageSize,
          offset: 0,
          searchId: searchInfo.type === SearchType.ID ? (searchInfo.value as number) : undefined,
          searchName: searchInfo.type === SearchType.NAME ? (searchInfo.value as string) : undefined,
        }),
      );
    }
  }, [dispatch, isEditDone, pageSize, searchInfo]);

  return (
    <Wrapper>
      <PageTitle title="品牌" />
      <Row justify="space-between">
        <FullRow>
          <Input.Group compact>
            <CustomButton type="primary" icon={<PlusCircleTwoTone twoToneColor="#1890FF" />} onClick={openBrandModal}>
              新增品牌
            </CustomButton>
            <CustomSelect
              defaultValue={SearchType.NAME}
              onChange={(value) => setSearchInfo({ ...searchInfo, type: value as SearchType })}
            >
              <Option value={SearchType.ID}>品牌ID</Option>
              <Option value={SearchType.NAME}>品牌名稱</Option>
            </CustomSelect>
            <CustomInput onChange={searchInputHandler} onPressEnter={fetchSearchBrand} />
            <Button type="primary" onClick={fetchSearchBrand}>
              搜尋
            </Button>
          </Input.Group>
        </FullRow>
        <Row>
          <PageText>{`總共${brandCount}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => fetchPageSizeChange(value as string)}>
            <Option value="20">20</Option>
            <Option value="50">50</Option>
            <Option value="100">100</Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      <TableWrapper>
        <Table
          loading={isFetching}
          columns={columns}
          dataSource={brandList}
          pagination={{
            pageSize,
            current: page,
            showSizeChanger: false,
            total: brandCount,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {editModal && <EditBrand brand={editBrand} close={() => setEditModal(false)} />}
    </Wrapper>
  );
}
