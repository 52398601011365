import * as vendorApi from "@api/vendorApi";
import PageTitle from "@component/PageTitle";
import { message, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Vendor } from "src/types";
import styled from "styled-components";
import MarginManagement from "./MarginManagement";
import SalesRecord from "./SalesRecord";
import VendorContacts from "./VendorContacts";
import VendorInfo from "./VendorInfo";
import VendorSource from "./VendorSource";

const { TabPane } = Tabs;

const StyledSection = styled.section`
  margin: 12px 16px 162px 12px;
  padding: 13px 22px;
  border: 1px solid #e5e5e5;
`;

export default function VendorInfoPage() {
  const { vendorId } = useParams();
  const [vendorDetails, setVendorDetails] = useState<Vendor.Details>({
    vendorId: -1,
    bdOwnerId: -1,
    vendorName: "",
    vendorPhone: "",
    vendorUbn: "",
    accountName: "",
    accountNumber: "",
    bankName: "",
    branchName: "",
    swiftCode: "",
    currencyId: -1,
    payCondition: "",
    reisterAddr: "",
    buinessAddr: "",
  });
  const [source, setSource] = useState<any[]>([]);

  useEffect(() => {
    async function getVendorDetails(id: string) {
      if (!id) return;
      try {
        const details = await vendorApi.fetchVendorDetail(parseInt(vendorId, 10));
        setVendorDetails(details);
      } catch (error: any) {
        message.error(`無法取得廠商詳情 ${error}`);
      }
    }
    getVendorDetails(vendorId);
  }, [vendorId]);

  useEffect(() => {
    async function fetchData() {
      const vendorSource = await vendorApi.fetchVendorSource(parseInt(vendorId, 10));
      setSource(vendorSource);
    }
    if (vendorId) {
      fetchData();
    }
  }, [vendorId]);

  return (
    <StyledSection>
      <PageTitle title={`廠商資訊 - ${vendorDetails.vendorName}`} />
      <Tabs defaultActiveKey="1" type="card" size="small">
        <TabPane tab="廠商資訊" key="1">
          {vendorDetails.vendorId !== -1 && (
            <VendorInfo vendorDetail={vendorDetails} vendorId={vendorId} isNewVendor={false} />
          )}
        </TabPane>
        <TabPane tab="聯絡資訊" key="2">
          <VendorContacts />
        </TabPane>
        <TabPane tab="廠商貨源" key="3">
          <VendorSource vendorId={vendorId} source={source} />
        </TabPane>
        <TabPane tab="保證金管理" key="4">
          <MarginManagement />
        </TabPane>
        <TabPane tab="銷售紀錄" key="5">
          <SalesRecord />
        </TabPane>
      </Tabs>
    </StyledSection>
  );
}
