import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { fetchCategoriesPutTags, fetchCategoriesTag, setIsSearching, topicState } from "@redux/topicSlice";
import { Button, Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { useManageTagContext } from "./ManageTagContext";
import StepChooseCategory from "./StepChooseCategory";
import StepChooseTag from "./StepChooseTag";
import filterParentCategories from "./utils/filterParentCategories";
import getCategoryBreadcrumb from "./utils/getCategoryBreadcrumb";

const Wrapper = styled.div<{ openModal: boolean }>`
  width: 853px;
  min-height: 600px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.04), 0px 6px 16px rgba(0, 0, 0, 0.04),
    0px 9px 28px 8px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
  padding: 24px 28px 20px 22px;
  position: relative;
  left: 17px;
  top: -10px;
  flex-direction: column;
  background: ${({ theme }) => theme.colorNeutral100};
  display: ${({ openModal }) => (openModal ? "flex" : "none")};

  &&& .ant-tree-treenode {
    width: 400px;
    position: relative;

    .ant-tree-checkbox {
      position: absolute;
      right: 0;
    }
  }
`;

const CustomButton = styled(Button)<{ marginleft?: string }>`
  margin-left: ${({ marginleft }) => marginleft || "auto"};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const ChoosetTagList = styled.div`
  margin-right: 16px;
  width: 100%;
  ${fontStyle("13px", "24px")};
`;

const CustomClose = styled(CloseOutlined)`
  position: absolute;
  top: 22px;
  right: 22px;
  cursor: pointer;
`;

enum Step {
  CATEGORY = "category",
  TAG = "tag",
}

export default function CategoryTags() {
  const dispatch = useDispatch();
  const { isCategoryFetching, categoryInfo, categoriesTag, isSearching } = useSelector(topicState);

  const {
    level1,
    level2,
    level3,
    checkCategories,
    tagList,
    openModal,
    setOpenModal,
    setTagList,
    setCheckCategories,
  } = useManageTagContext();

  const [step, setStep] = useState<Step>(Step.CATEGORY);

  useEffect(() => {
    if (categoryInfo) setCheckCategories(categoryInfo.categories);
  }, [setCheckCategories, categoryInfo]);

  useEffect(() => {
    if (categoryInfo && categoriesTag.length > 0 && !isSearching) {
      const newMap = new Map<number, { name: string; id: number }>();

      categoriesTag.forEach((tag) => {
        if (categoryInfo.tags.includes(tag.id)) {
          newMap.set(tag.id, { name: tag.name, id: tag.id });
        }
      });

      setTagList(newMap);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryInfo, setTagList, categoriesTag, isSearching]);

  const nextStep = () => {
    if (checkCategories.length === 0) return;
    dispatch(setIsSearching(false));
    dispatch(fetchCategoriesTag({ categoryIds: checkCategories, config: { limit: 10000 } }));
    setStep(Step.TAG);
  };

  const categoriesPutTags = () => {
    Modal.confirm({
      title: "若異動分類內容，原推薦設定將被移除你確定要異動分類內容？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(
          fetchCategoriesPutTags({
            topicCategoryId: categoryInfo!.id,
            categories: filterParentCategories(level1, level2, level3, checkCategories),
            tags: Array.from(tagList.keys()),
          }),
        );
        setOpenModal(false);
      },
    });
  };

  const renderContent = () => {
    if (step === Step.CATEGORY) return <StepChooseCategory />;
    if (step === Step.TAG) return <StepChooseTag />;
    return null;
  };

  const renderButtons = () => {
    if (step === Step.CATEGORY) {
      return (
        <CustomButton type="primary" disabled={checkCategories.length === 0} onClick={nextStep}>
          下一步
        </CustomButton>
      );
    }
    if (step === Step.TAG) {
      return (
        <Row>
          <CustomButton onClick={() => setStep(Step.CATEGORY)}>上一步</CustomButton>
          <CustomButton marginleft="14px" type="primary" onClick={categoriesPutTags}>
            確定
          </CustomButton>
        </Row>
      );
    }
    return null;
  };

  const generateCategoryBreadcrumb = () => {
    const breadcrumb = getCategoryBreadcrumb(level1, level2, level3, checkCategories);
    const level1Length = level1.size;

    if (level1Length === checkCategories.length) {
      return "已選：全選";
    }

    if (breadcrumb.length > 0) {
      return `已選：${breadcrumb}`;
    }
    return "";
  };

  useEffect(() => {
    if (!openModal) {
      setStep(Step.CATEGORY);
    }
  }, [openModal]);

  return (
    <Wrapper openModal={openModal}>
      <Spin spinning={isCategoryFetching} />
      <CustomClose onClick={() => setOpenModal(false)} />
      {renderContent()}
      <Row>
        <ChoosetTagList>{generateCategoryBreadcrumb()}</ChoosetTagList>
        {renderButtons()}
      </Row>
    </Wrapper>
  );
}
