import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import financeApi, { CostWareHouseAndTransferList, costStatementListParams, CostBuyOutList } from "@api/financeApi";
import moment from "moment";
import type { RootState } from "../rootReducer";

interface IState {
  isFetching: boolean;
  costWareHouseAndTransferList: CostWareHouseAndTransferList;
  costWareHouseAndTransferListParam: costStatementListParams;
  costBuyOutList: CostBuyOutList;
  costBuyOutListParam: costStatementListParams;
}

const lastMonth = moment().subtract(30, "days");

export const initialState: IState = {
  isFetching: false,
  costWareHouseAndTransferList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  costWareHouseAndTransferListParam: {
    limit: 20,
    offset: 0,
    dateAfter: lastMonth.startOf("month").format("YYYY-MM-DD"),
    dateBefore: lastMonth.endOf("month").format("YYYY-MM-DD"),
  },
  costBuyOutList: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  costBuyOutListParam: {
    limit: 20,
    offset: 0,
    dateAfter: lastMonth.startOf("month").format("YYYY-MM-DD"),
    dateBefore: lastMonth.endOf("month").format("YYYY-MM-DD"),
  },
};

export const fetchCostWareHouseAndTransferList = createAsyncThunk(
  "finance/CostWareHouseAndTransferList",
  async (_, thunkApi) => {
    const {
      costStatementSlice: { costWareHouseAndTransferListParam },
    } = thunkApi.getState() as RootState;
    const response = await financeApi.fetchCostWareHouseAndTransferList(costWareHouseAndTransferListParam);
    return response;
  },
);
export const fetchCostBuyOutList = createAsyncThunk("finance/fetchCostBuyOutList", async (_, thunkApi) => {
  const {
    costStatementSlice: { costBuyOutListParam },
  } = thunkApi.getState() as RootState;
  const response = await financeApi.fetchCostBuyOutList(costBuyOutListParam);
  return response;
});

const costStatementSlice = createSlice({
  name: "costStatement",
  initialState,
  reducers: {
    resetCostStatementSlice: () => initialState,
    setCostWareHouseAndTransferListParam: (state, action) => {
      state.costWareHouseAndTransferListParam = action.payload;
    },
    resetCostWareHouseAndTransferListParam: (state) => {
      state.costWareHouseAndTransferListParam = {
        ...state.costWareHouseAndTransferListParam,
        contract: undefined,
        dateAfter: lastMonth.startOf("month").format("YYYY-MM-DD"),
        dateBefore: lastMonth.endOf("month").format("YYYY-MM-DD"),
      };
    },
    setCostBuyOutListParam: (state, action) => {
      state.costBuyOutListParam = action.payload;
    },
    resetCostBuyOutListParam: (state) => {
      state.costBuyOutListParam = {
        ...state.costBuyOutListParam,
        contract: undefined,
        dateAfter: lastMonth.startOf("month").format("YYYY-MM-DD"),
        dateBefore: lastMonth.endOf("month").format("YYYY-MM-DD"),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCostWareHouseAndTransferList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCostWareHouseAndTransferList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.costWareHouseAndTransferList = action.payload;
    });
    builder.addCase(fetchCostBuyOutList.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(fetchCostBuyOutList.fulfilled, (state, action) => {
      state.isFetching = false;
      state.costBuyOutList = action.payload;
    });
  },
});

export const costStatementState = (state: RootState) => state.costStatementSlice;
export const {
  resetCostStatementSlice,
  setCostWareHouseAndTransferListParam,
  resetCostWareHouseAndTransferListParam,
  setCostBuyOutListParam,
  resetCostBuyOutListParam,
} = costStatementSlice.actions;
export default costStatementSlice.reducer;
