export enum ApprovalStatus {
  PENDING = 0,
  REPLENISHMENT_REJECT = -1,
  RETURN_REJECT = -2,
  REPLENISHMENT_APPROVED = 10,
  GOOD_RETURN_APPROVED = 11,
  NO_RETURN_APPROVED = 12,
  REFUND_REJECT = -10,
  REFUND_APPROVED = 20,
  REFUND_SUCCESS = 30,
  REFUND_FAILED = -20,
}

export const ApprovalStatusMap: { [key: string]: string } = {
  "0": "審核中",
  "-1": "補貨審核遭拒",
  "-2": "退貨審核遭拒",
  "10": "補貨審核通過",
  "11": "退貨審核通過取回",
  "12": "退貨審核通過不取回",
  "-10": "不予以退款",
  "20": "退款放行",
  "30": "退款成功",
  "-20": "退款失敗",
  "15": "等待放行",
};

export enum ApprovalStatusFilter {
  REJECTED = -1,
  APPLY = 0,
  APPROVED = 1,
  RELEASE = 2,
  NOT_RELEASE = 3,
  REFUND_OK = 4,
}

export const ApprovalStatusFilterMap: { [key: string]: string } = {
  "-1": "審核不通過",
  "0": "已申請",
  "1": "審核通過",
  "2": "放行",
  "3": "不放行",
  "4": "退款完成",
  "5": "等待放行",
};
