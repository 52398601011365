import { ReviewStateByPosition, ReviewStateByPositionLabel } from "@constant/ReviewState";
import React, { useMemo } from "react";
import styled from "styled-components";

const GrayText = styled.p`
  color: ${({ theme }) => theme.colorNeutral500};
`;
const StyledTd = styled.td`
  width: 200px;
`;

export interface ReviewState {
  ceo: number;
  manager: number;
  finance: number;
  sectionManager: number;
}

/**
 * 一階主管 sectionManager
 * 二階主管 manager
 * 財務 finance
 * CEO ceo
 */

export default function ReviewStateTable(props: { data: ReviewState }) {
  const { data } = props;

  const getState = (allState: number[]) => {
    if (allState.includes(ReviewStateByPosition.PASSED)) {
      return ReviewStateByPosition.PASSED;
    }
    if (allState.includes(ReviewStateByPosition.NOT_PASSED)) {
      return ReviewStateByPosition.NOT_PASSED;
    }
    if (allState.includes(ReviewStateByPosition.NO_REVIEW)) {
      return ReviewStateByPosition.NO_REVIEW;
    }
    return ReviewStateByPosition.WAITING;
  };

  const reviewStateList = useMemo(() => {
    const { ceo, manager, sectionManager, finance } = data;
    // 一二階主管為一組，財務/CEO為一組，只需其中一人審核即可
    // 顯示優先序為：（"審核通過" or "審核不通過") > ("不需審核" or "尚未審核")

    return [
      {
        title: "一／二階主管",
        key: "manager",
        state: getState([manager, sectionManager]),
      },
      {
        title: "財務 / CEO",
        key: "finance/ceo",
        state: getState([finance, ceo]),
      },
    ];
  }, [data]);

  return (
    <table className="review-table">
      {/* 職位名稱 */}
      <tbody>
        <tr>
          {reviewStateList.map((column) => (
            <th key={column.key}>{column.title}</th>
          ))}
        </tr>
        {/* Review State */}
        <tr>
          {reviewStateList.map((column) => {
            const stateLabel = ReviewStateByPositionLabel[column.state];
            return (
              <StyledTd key={column.key}>
                {stateLabel === "不需審核" ? <GrayText>{stateLabel}</GrayText> : stateLabel}
              </StyledTd>
            );
          })}
        </tr>
      </tbody>
    </table>
  );
}
