export enum CooperationValue {
  TRANSFER = 0,
  NEW_WAREHOUSE = 1,
  OLD_WAREHOUSE = 2,
  SHORT_TRANSFER = 3,
  SHORT_WAREHOUSE = 4,
}
export enum BuyOutCooperationValue {
  DOMESTIC_BUY_OUT = 0,
  FOREIGN_BUY_OUT = 1,
}
export const CooperationList: { [key: number]: string } = {
  0: "轉單",
  1: "新約寄倉",
  2: "舊約寄倉",
  3: "轉單短約",
  4: "寄倉短約",
};
export const BuyOutCooperationList: { [key: number]: string } = {
  0: "國內買斷",
  1: "國外買斷",
};

export enum TabList {
  WAREHOUSE_CONSIGN_AND_TRANSFER = "WAREHOUSE_CONSIGN_AND_TRANSFER",
  BUY_OUT = "BUY_OUT",
}
