import { ExclamationCircleOutlined, FilterTwoTone, FormOutlined, PlusCircleTwoTone } from "@ant-design/icons";
import { PurchaseLines, PurchaseList } from "@api/utils/normalizeWarehouse";
import {
  customsClearanceState,
  fetchBatchUpdatePurchaseLine,
  fetchCustomsClearancesPurchaseRemove,
  fetchPurchaseLines,
  fetchPurchaseList,
  setPurchaseLinesFilter,
} from "@redux/sharing/customsClearanceSlice";
import MathRound from "@utils/MathRound";
import { Button, Input, Modal, Row, Select, Table } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import CustomsClearanceAddForm from "./CustomsClearanceAddForm";
import CustomsClearanceProductFilter from "./CustomsClearanceProductFilter";

const storageTypeOptions = [
  { key: "JY1", name: "即期倉", value: "JY1" },
  { key: "JY2", name: "良品倉", value: "JY2" },
  { key: "JY3", name: "經銷倉", value: "JY3" },
  { key: "JY4", name: "線上倉", value: "JY4" },
  { key: "JY5", name: "家樂福倉", value: "JY5" },
  { key: "JY6", name: "大潤發倉", value: "JY6" },
  { key: "JY7", name: "迪卡儂倉", value: "JY7" },
  { key: "JY8", name: "寶雅倉", value: "JY8" },
  { key: "JY9", name: "全聯倉", value: "JY9" },
  { key: "JY10", name: "跨境倉", value: "JY10" },
  { key: "JY11", name: "UrMart 倉", value: "JY11" },
  { key: "JY12", name: "壞品倉", value: "JY12" },
];

const ProductWrapper = styled.div`
  padding: 15px 22px;
`;

const PageText = styled.span`
  ${fontStyle("14px", "20px")};
`;

const SelectPageSize = styled(Select)`
  &&& {
    margin: 0 10px;
  }
`;

const TableWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width};
  margin-top: 19px;
`;

const TableTitle = styled.span`
  margin-right: 4px;
`;

const EditInput = styled(Input)`
  width: 70px;
  height: 32px;
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const Warning = styled.div`
  color: red;
`;

interface EditFields {
  tariffRate: boolean;
  customsClearanceFee: boolean;
  rebate: boolean;
  processingFee: boolean;
}

interface FieldValues {
  field: "tariffRate" | "customsClearanceFee" | "rebate" | "processingFee";
  value: number;
}

const oneFieldIsTrue = (editFields: EditFields) => Object.values(editFields).some((item) => item);

const generateTableTitle = (config: {
  field: "tariffRate" | "customsClearanceFee" | "rebate" | "processingFee";
  title: string;
  editFields: EditFields;
  setEditFields: React.Dispatch<React.SetStateAction<EditFields>>;
}) => {
  // 自己被選到及沒有任何一個被選到
  if ((oneFieldIsTrue(config.editFields) && config.editFields[config.field]) || !oneFieldIsTrue(config.editFields)) {
    return (
      <div role="presentation" onClick={() => config.setEditFields((prev) => ({ ...prev, [config.field]: true }))}>
        <TableTitle>{config.title}</TableTitle>
        <FormOutlined />
      </div>
    );
  }

  // 其中一個欄位被選到，其他欄位顯示
  return <TableTitle>{config.title}</TableTitle>;
};

const generateTableColumn = (
  config: FieldValues & {
    others?: PurchaseLines;
    editFields: EditFields;
    onChange: (config: FieldValues) => void;
  },
) => {
  if (config.editFields[config.field]) {
    return (
      <Row align="middle">
        <EditInput
          type="number"
          defaultValue={config.value}
          onChange={(e) => config.onChange({ field: config.field, value: parseFloat(e.target.value || "0") })}
        />
        {config.field === "tariffRate" && <div>%</div>}
      </Row>
    );
  }

  return <div>{config.value === null ? "N/A" : config.value}</div>;
};

const initEditFields = {
  tariffRate: false,
  customsClearanceFee: false,
  rebate: false,
  processingFee: false,
};

export default function CustomsClearanceProduct() {
  const { customsClearanceId } = useParams();
  const integerCustomsClearanceId = parseInt(customsClearanceId, 10);

  const dispatch = useDispatch();
  const {
    purchaseListResult,
    purchaseLinesResult,
    purchaseLinesFilter,
    isEditDone,
    isPurchaseLinesFetching,
  } = useSelector(customsClearanceState);

  const [addWarehouse, setAddWarehouse] = useState<boolean>(false);
  const [filterProduct, setFilterProduct] = useState<boolean>(false);
  const [editFields, setEditFields] = useState<EditFields>(initEditFields);
  const [page, setPage] = useState<number>(1);
  const [batchMap, setBatchMap] = useState(new Map());

  const setPageLimit = (value: SelectValue) => {
    dispatch(setPurchaseLinesFilter({ ...purchaseLinesFilter, limit: parseInt(value as string, 10) }));
  };

  const fetchPageChange = (value: number) => {
    window.scrollTo(0, 0);
    setPage(value);
    dispatch(fetchPurchaseLines({ ...purchaseLinesFilter, customsClearance: integerCustomsClearanceId, page: value }));
  };

  const deleteWarehouse = (purchaseId: number, stoNumber: string) => {
    Modal.confirm({
      title: "你確定要刪除這筆進倉單？",
      icon: <ExclamationCircleOutlined />,
      okText: "是",
      cancelText: "否",
      onOk() {
        dispatch(
          fetchCustomsClearancesPurchaseRemove({
            customsClearancesId: integerCustomsClearanceId,
            purchaseId,
            stoNumber,
          }),
        );
      },
      onCancel() {},
    });
  };

  const updateBatchMap = (config: FieldValues & { id: number; stoNumber: string | null }) => {
    const cloneMap = new Map(
      batchMap.set(config.id, {
        stoNumber: config.stoNumber,
        id: config.id,
        [config.field]: MathRound(config.value, 2),
      }),
    );
    setBatchMap(cloneMap);
  };

  const fetchBatchUpdate = () => {
    setEditFields(initEditFields);
    dispatch(fetchBatchUpdatePurchaseLine(Array.from(batchMap.values())));
  };

  const intoWarehouseColumns = [
    {
      key: "stoNumber",
      dataIndex: "stoNumber",
      title: "LMS編號",
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: "",
      render: (_: any, others: PurchaseList) => {
        return (
          <Row justify="end" align="middle" wrap={false}>
            <Button type="link" onClick={() => deleteWarehouse(others.id, others.stoNumber)}>
              刪除
            </Button>
          </Row>
        );
      },
    },
  ];

  const customClearanceProductColumns = [
    {
      key: "stoNumber",
      dataIndex: "stoNumber",
      title: "LMS編號",
    },
    {
      key: "no",
      dataIndex: "no",
      title: "商品品號",
    },
    {
      key: "sku",
      dataIndex: "sku",
      title: "SKU",
    },
    {
      key: "productName",
      dataIndex: "productName",
      title: "商品名稱",
    },
    {
      key: "goodQty",
      dataIndex: "goodQty",
      title: "良品數",
    },
    {
      key: "expiredDate",
      dataIndex: "expiredDate",
      title: "效期",
    },
    {
      key: "purchasePrice",
      dataIndex: "purchasePrice",
      title: "進價",
      render: (value: number, others: PurchaseLines) => {
        const purchasePrice = Number((others.purchasePrice * others.currencyRate).toFixed(2));
        const cost = Number(others.weightedCost) === 0 ? 0.1 : Number(others.weightedCost);
        return (
          <>
            {purchasePrice / cost < 0.5 || purchasePrice / cost > 2 ? (
              <Warning>{purchasePrice}</Warning>
            ) : (
              <div>{purchasePrice}</div>
            )}
          </>
        );
      },
    },
    {
      key: "cost",
      dataIndex: "cost",
      title: "目前成本",
      render: (value: number, others: PurchaseLines) => {
        return <div>{Number(others.weightedCost)}</div>;
      },
    },
    {
      key: "storageType",
      dataIndex: "storageType",
      title: "倉別",
      render: (value: string) => storageTypeOptions.find((type) => type.key === value)?.name,
    },
    {
      key: "batch",
      dataIndex: "batch",
      title: "批號",
    },
    {
      key: "tariffRate",
      dataIndex: "tariffRate",
      title: () =>
        generateTableTitle({
          field: "tariffRate",
          title: "關稅比",
          editFields,
          setEditFields,
        }),
      render: (value: number, others: PurchaseLines) =>
        generateTableColumn({
          field: "tariffRate",
          value,
          editFields,
          others,
          onChange: (config) =>
            updateBatchMap({ stoNumber: others.stoNumber, id: others.id, field: config.field, value: config.value }),
        }),
    },
    {
      key: "customsClearanceFee",
      dataIndex: "customsClearanceFee",
      title: () =>
        generateTableTitle({
          field: "customsClearanceFee",
          title: "報關價格",
          editFields,
          setEditFields,
        }),
      render: (value: number, others: PurchaseLines) =>
        generateTableColumn({
          field: "customsClearanceFee",
          value,
          editFields,
          others,
          onChange: (config) =>
            updateBatchMap({ stoNumber: others.stoNumber, id: others.id, field: config.field, value: config.value }),
        }),
    },
    {
      key: "rebate",
      dataIndex: "rebate",
      title: () =>
        generateTableTitle({
          field: "rebate",
          title: "廠商回饋金",
          editFields,
          setEditFields,
        }),
      render: (value: number, others: PurchaseLines) =>
        generateTableColumn({
          field: "rebate",
          value,
          editFields,
          onChange: (config) =>
            updateBatchMap({
              stoNumber: others.stoNumber,
              id: others.id,
              field: config.field,
              value: config.value,
            }),
        }),
    },
    {
      key: "processingFee",
      dataIndex: "processingFee",
      title: () =>
        generateTableTitle({
          field: "processingFee",
          title: "加工處理費用",
          editFields,
          setEditFields,
        }),
      render: (value: number, others: PurchaseLines) =>
        generateTableColumn({
          field: "processingFee",
          value,
          editFields,
          onChange: (config) =>
            updateBatchMap({ stoNumber: others.stoNumber, id: others.id, field: config.field, value: config.value }),
        }),
    },
    {
      key: "buttons",
      dataIndex: "buttons",
      title: () =>
        oneFieldIsTrue(editFields) && (
          <div>
            <CustomButton onClick={() => setEditFields(initEditFields)}>取消</CustomButton>
            <Button type="primary" onClick={fetchBatchUpdate}>
              儲存
            </Button>
          </div>
        ),
    },
  ];

  useEffect(() => {
    if (isEditDone) {
      setPage(1);
      dispatch(fetchPurchaseList({ customsClearance: integerCustomsClearanceId, limit: 100, offset: 0 }));
      dispatch(fetchPurchaseLines({ ...purchaseLinesFilter, customsClearance: integerCustomsClearanceId, page: 1 }));
    }
  }, [isEditDone, dispatch, integerCustomsClearanceId, purchaseLinesFilter]);

  return (
    <ProductWrapper>
      <Row align="middle" justify="space-between">
        <Row align="middle">
          <CustomButton
            type="primary"
            icon={<PlusCircleTwoTone twoToneColor="#1890FF" />}
            onClick={() => setAddWarehouse(true)}
          >
            新增進倉單
          </CustomButton>
          <Button icon={<FilterTwoTone />} onClick={() => setFilterProduct(!filterProduct)}>
            篩選
          </Button>
        </Row>
        <Row align="middle">
          <PageText>{`總共${purchaseLinesResult.count}筆, 每頁顯示`}</PageText>
          <SelectPageSize defaultValue="20" onChange={(value) => setPageLimit(value as SelectValue)}>
            <Select.Option value="20">20</Select.Option>
            <Select.Option value="50">50</Select.Option>
            <Select.Option value="100">100</Select.Option>
          </SelectPageSize>
          <PageText>筆</PageText>
        </Row>
      </Row>
      {filterProduct && <CustomsClearanceProductFilter />}
      <TableWrapper width="282px">
        <Table columns={intoWarehouseColumns} dataSource={purchaseListResult.results} pagination={false} />
      </TableWrapper>
      <TableWrapper>
        <Table
          loading={isPurchaseLinesFetching}
          scroll={{ x: "max-content" }}
          columns={customClearanceProductColumns}
          dataSource={purchaseLinesResult.results.data}
          pagination={{
            showSizeChanger: false,
            pageSize: purchaseLinesFilter.limit,
            current: page,
            total: purchaseLinesResult.count,
            onChange: fetchPageChange,
          }}
        />
      </TableWrapper>
      {addWarehouse && <CustomsClearanceAddForm close={() => setAddWarehouse(false)} />}
    </ProductWrapper>
  );
}
