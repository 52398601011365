import { fetchReplenishReturnList, orderState } from "@redux/orderSlice";
import { Table } from "antd";
import React, { useEffect } from "react";
import fontStyle from "src/styles/fontStyle";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ApprovalStatus } from "@api/throughShipment/throughShipmentApi";
import moment from "moment";
import { ColumnsType } from "antd/lib/table";
import { ReplenishReturnList } from "@api/utils/normalizeOrder";
import { ApprovalStatusMap } from "../ApprovedState";

const Wrapper = styled.div`
  margin-bottom: 61px;
`;
const Title = styled.div`
  ${fontStyle("14px", "22px", "700")};
`;
const TableWrapper = styled.div`
  margin-top: 20px;
`;
const ALink = styled.a`
  ${fontStyle("14px", "16px")};
  color: ${({ theme }) => theme.colorSuccess500};
  margin: 0;
  text-decoration: underline;
`;
export default function ReplenishReturnTab(props: { orderNumber: string }) {
  const { orderNumber } = props;
  const dispatch = useDispatch();
  const { isFetching, replenishReturnListResult, replenishReturnListFilter } = useSelector(orderState);

  const columns: ColumnsType<ReplenishReturnList> = [
    {
      key: "id",
      dataIndex: "id",
      title: "子訂單編號",
      render: (value: number) => (
        <ALink href={`/replenish-return/${value}`} target="_blank">
          {value}
        </ALink>
      ),
    },
    {
      key: "approvalStatus",
      dataIndex: "approvalStatus",
      title: "狀態",
      render: (value: ApprovalStatus) => <div>{ApprovalStatusMap[value] || "N/A"}</div>,
    },
    {
      key: "createdAt",
      dataIndex: "createdAt",
      title: "申請時間",
      render: (value: string) => <div>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</div>,
    },
    {
      key: "orderTime",
      dataIndex: "orderTime",
      title: "訂購時間",
      render: (value: string) => <div>{moment(value).format("YYYY-MM-DD HH:mm:ss")}</div>,
    },
    {
      key: "logisticName",
      dataIndex: "logisticName",
      title: "出貨點",
    },
    {
      key: "qty",
      dataIndex: "qty",
      align: "center",
      title: "補退貨數量(件)",
    },
    {
      key: "refundAmount",
      dataIndex: "refundAmount",
      title: "總退款金額",
    },
    {
      key: "approvalDate",
      dataIndex: "approvalDate",
      title: "放行時間",
      render: (value: string) => <div>{value ? moment(value).format("YYYY-MM-DD HH:mm:ss") : "N/A"}</div>,
    },
  ];
  useEffect(() => {
    dispatch(fetchReplenishReturnList({ ...replenishReturnListFilter, orderNumber }));
  }, [dispatch, orderNumber, replenishReturnListFilter]);
  return (
    <Wrapper>
      <Title>退補貨單</Title>
      <TableWrapper>
        <Table
          scroll={{ x: "max-content" }}
          loading={isFetching}
          columns={columns}
          dataSource={replenishReturnListResult.results}
          pagination={false}
        />
      </TableWrapper>
    </Wrapper>
  );
}
