import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import { VPCAlertItem } from "@api/productApi";
import { useSelector } from "react-redux";
import { configState } from "@redux/configSlice";

interface Props {
  mode: "edit" | "delete";
  subTitle?: string;
  salePageList?: VPCAlertItem[];
  onClose: () => void;
  onConfirm: () => void;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  height: 300px;
  padding: 32px 32px 20px 32px;
  background-color: white;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;
const HeaderIcon = styled.div`
  color: #faad14;
  margin-right: 16px;
`;
const HeaderTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
`;
const SubTitle = styled.p`
  color: ${({ theme }) => theme.colorSecondary500};
  margin-bottom: 8px;
`;
const PopupBody = styled.div`
  height: 70%;
  overflow-y: auto;
`;
const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;
const CancelButton = styled(Button)`
  margin-right: 8px;
`;

const ReminderPopup: FC<Props> = (props) => {
  const { mode, onClose, onConfirm, subTitle, salePageList } = props;
  const title = `提醒: ${mode === "edit" ? "更改" : "刪除"}合作模式，將影響以下銷售頁方案的抽成與售價`;
  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <Header>
          <HeaderIcon>
            <ExclamationCircleOutlined />
          </HeaderIcon>
          <HeaderTitle>{title}</HeaderTitle>
        </Header>
        {subTitle && <SubTitle>{subTitle}</SubTitle>}
        <PopupBody>{salePageList?.map((pageInfo) => SalePageItemCard(pageInfo))}</PopupBody>
        <Footer>
          <CancelButton onClick={onClose}>取消</CancelButton>
          <Button onClick={onConfirm} type="primary" disabled={!!subTitle}>
            確定更改
          </Button>
        </Footer>
      </Wrapper>
    </PopupBackground>
  );
};

export default ReminderPopup;

const CardWrapper = styled.div`
  display: flex;
  margin-bottom: 4px;
`;
const CardPageIdLink = styled.a`
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colorSuccess500};
  color: ${({ theme }) => theme.colorSuccess500};
  padding-right: 12px;
`;
const CardPageName = styled.div``;
const CardPlanOptionsText = styled.div`
  color: ${({ theme }) => theme.colorNeutral500};
`;

const SalePageItemCard = (pageInfo: VPCAlertItem) => {
  const { pageId, pageName, plans } = pageInfo;
  const { webDomain } = useSelector(configState);

  const salePageOptions = useMemo(() => {
    const uniqueOptionsMap = new Map<number, typeof plans[0]["options"][0]>();

    plans.forEach((plan) => {
      plan.options.forEach((option) => {
        if (!uniqueOptionsMap.has(option.productId)) {
          uniqueOptionsMap.set(option.productId, option);
        }
      });
    });
    return Array.from(uniqueOptionsMap.values());
  }, [plans]);

  return (
    <CardWrapper>
      <CardPageIdLink href={`${webDomain}/salespage/${pageId}`} target="_blank">
        {pageId}
      </CardPageIdLink>
      <div>
        <CardPageName>{pageName}</CardPageName>
        <CardPlanOptionsText>方案選項</CardPlanOptionsText>

        {salePageOptions.map((option) => (
          <CardPlanOptionsText>{`${option.name}原售價${option.price}變為${option.newPrice}`}</CardPlanOptionsText>
        ))}
      </div>
    </CardWrapper>
  );
};
