/* eslint-disable @typescript-eslint/no-unused-vars */
import menuMangementApi, { CreateMenuParam, MenuDetail, MenuNode, UpdateMenuParam } from "@api/menuMangementApi";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { produce } from "immer";

const loop = (data: MenuNode[], targetId: number | string | undefined, callback: Function) => {
  data.forEach((item, index) => {
    if (item.id === targetId) {
      callback(item, index, data);
    }
    if (item.children) {
      loop(item.children, targetId, callback);
    }
  });
};

interface IInitialState {
  hasNewNode: boolean;
  displayInactive: boolean;
  selectedMenu: SelectedMenu;
  isFetching: boolean;
  isFetchingMenuList: boolean;
  menuList: MenuNode[];
  menuDetail: MenuDetail;
}

interface SelectedMenu {
  id: number;
  isActive: boolean;
  level: number;
  link: string;
  name: string;
  openingNewTab: boolean;
  parent: number;
  treeId?: number;
  children?: [];
}

export const initialState: IInitialState = {
  hasNewNode: false,
  displayInactive: true,
  selectedMenu: {
    id: 0,
    isActive: true,
    parent: -1,
    level: -1,
    link: "",
    name: "",
    openingNewTab: false,
    treeId: -1,
    children: [],
  },
  isFetching: false,
  isFetchingMenuList: false,
  menuList: [],
  menuDetail: {
    id: -1,
    name: "",
    path: "",
    parent: -1,
    level: 0,
    isActive: false,
    link: "",
    openingNewTab: false,
  },
};

export const fetchMenuList = createAsyncThunk("menuMangement/fetchMenuList", async (_, thunkApi) => {
  const {
    menuMangement: { displayInactive },
  } = thunkApi.getState() as RootState;
  const response = await menuMangementApi.fetchMenuList(displayInactive);

  return response;
});

export const fetchMenuDetail = createAsyncThunk("salesCategory/fetchMenuDetail", async (id: number) => {
  const response = await menuMangementApi.fetchMenuDetail(id);

  return response;
});

export const fetchCreateMenu = createAsyncThunk(
  "menuMangement/fetchCreateMenu",
  async (params: CreateMenuParam, thunkApi) => {
    try {
      const response = await menuMangementApi.fetchCreateMenu(params);
      const newSelectedCategory = {
        id: response.id,
        parent: response.parent,
        level: response.level,
        isActive: response.isActive,
        name: response.name,
        openingNewTab: response.openingNewTab,
        link: response.link,
      };

      thunkApi.dispatch(updateSelectedMenu(newSelectedCategory));
      thunkApi.dispatch(fetchMenuList());

      message.success("建立成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchUpdateMenu = createAsyncThunk(
  "menuMangement/fetchUpdateMenu",
  async (params: UpdateMenuParam, thunkApi) => {
    try {
      const response = await menuMangementApi.fetchUpdateMenu(params);
      thunkApi.dispatch(fetchMenuList());
      message.success("更新成功");
      return response;
    } catch (error: any) {
      return thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchMoveMenu = createAsyncThunk(
  "menuMangement/fetchMoveMenu",
  async (
    arg: { categoryId: number; leftSibling?: number; rightSibling?: number | string; parent: number },
    thunkApi,
  ) => {
    const { categoryId, leftSibling, rightSibling, parent } = arg;
    const {
      menuMangement: { menuList },
    } = thunkApi.getState() as RootState;
    const insertNodeId = leftSibling || rightSibling || parent;
    const putParams = {
      leftSibling,
      rightSibling,
      parent,
    };

    try {
      await menuMangementApi.fetchMoveMenu(categoryId, putParams);

      const newCategoryList = produce(menuList, (draftMenuList) => {
        // find drag node
        let dragObj: MenuNode;
        loop(draftMenuList, categoryId, (item: MenuNode, index: number, arr: MenuNode[]) => {
          arr.splice(index, 1);
          dragObj = item;
        });
        let insertArr: MenuNode[];
        let insertIndex: number;

        if (insertNodeId === leftSibling && insertNodeId !== undefined) {
          loop(draftMenuList, insertNodeId!, (item: MenuNode, index: number, arr: MenuNode[]) => {
            insertArr = arr;
            insertIndex = index;
          });

          insertArr!.splice(insertIndex! + 1, 0, dragObj!);
        } else if (insertNodeId === rightSibling && insertNodeId !== undefined) {
          loop(draftMenuList, insertNodeId!, (item: MenuNode, index: number, arr: MenuNode[]) => {
            insertArr = arr;
            insertIndex = index;
          });
          insertArr!.unshift(dragObj!);
        } else {
          // 1層到2層的a,a沒有children
          loop(draftMenuList, insertNodeId, (item: MenuNode, index: number, arr: MenuNode[]) => {
            insertArr = item.children || [];
            insertArr.unshift(dragObj);
          });
        }
      });

      thunkApi.dispatch(updateMenuList(newCategoryList));

      message.success("更新成功");
    } catch (error: any) {
      thunkApi.rejectWithValue(error.message);
    }
  },
);

export const fetchDeleteMenu = createAsyncThunk("menuMangement/fetchDeleteMenu", async (id: number, thunkApi) => {
  const response = await menuMangementApi.fetchDeleteMenu(id);
  message.success("已刪除");
  thunkApi.dispatch(fetchMenuList());
  return response;
});

const menuMangementSlice = createSlice({
  name: "menuMangement",
  initialState,
  reducers: {
    reset: () => initialState,
    updateSelectedMenu: (
      state,
      action: PayloadAction<{
        id: number;
        isActive: boolean;
        level: number;
        link: string;
        name: string;
        openingNewTab: boolean;
        parent: number;
        treeId?: number;
        children?: [];
      }>,
    ) => {
      state.selectedMenu = action.payload;
    },
    addNewMenu: (state, action: PayloadAction<MenuNode>) => {
      const { parent } = action.payload;
      let parentNode: MenuNode | undefined;
      state.hasNewNode = true;
      state.menuList.forEach((menu) => {
        if (menu.key === parent) parentNode = menu;

        if (menu.children) {
          menu.children.forEach((subMenu) => {
            if (subMenu.key === parent) parentNode = subMenu;

            if (subMenu.children) {
              subMenu.children.forEach((subsubMenu) => {
                if (subsubMenu.key === parent) parentNode = subsubMenu;
              });
            }
          });
        }
      });
      if (parentNode && parentNode.children) parentNode.children.push(action.payload);
      else if (parentNode) parentNode.children = [action.payload];
      else state.menuList.push(action.payload); // 沒有parentNode表示要新增的點在第一層
    },
    updateMenuList: (state, action: PayloadAction<MenuNode[]>) => {
      state.menuList = action.payload;
    },
    clearMenuDetail: (state) => {
      state.menuDetail = initialState.menuDetail;
    },
    toggleDisplayInactive: (state) => {
      state.displayInactive = !state.displayInactive;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenuList.pending, (state) => {
      state.isFetchingMenuList = true;
    });
    builder.addCase(fetchMenuList.fulfilled, (state, action) => {
      state.isFetchingMenuList = false;
      state.menuList = action.payload;
    });
    builder.addCase(fetchMenuDetail.pending, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchMenuDetail.fulfilled, (state, action) => {
      state.isFetching = true;
      state.menuDetail = action.payload;
    });

    builder.addCase(fetchCreateMenu.pending, (state) => {
      state.isFetching = false;
    });
    builder.addCase(fetchCreateMenu.fulfilled, (state, action) => {
      state.isFetching = true;
      state.menuDetail = action.payload;
    });
  },
});

export const {
  updateSelectedMenu,
  addNewMenu,
  updateMenuList,
  clearMenuDetail,
  toggleDisplayInactive,
  reset,
} = menuMangementSlice.actions;
export default menuMangementSlice.reducer;
