import { InfoCircleOutlined } from "@ant-design/icons";
import { Dashboard } from "@api/salesReportApi";
import { RootState } from "@redux/rootReducer";
import { Spin, Tooltip } from "antd";
import React, { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
  overflow-x: auto;
`;
const Card = styled.div`
  flex: 0 0 128px;
  height: 82px;
  background-color: #fafafa;
  margin-right: 10px;
  padding: 10px 14px;
`;
const CardTitle = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 2px;
  width: max-content;
`;
const CardContent = styled.div`
  font-size: 20px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.85);
`;

interface DashboardCard {
  key: keyof Dashboard;
  title: string;
}

const dashboardCardList: DashboardCard[] = [
  {
    key: "grossMargin",
    title: "毛利率",
  },
  {
    key: "revenueSum",
    title: "營收加總",
  },
  {
    key: "salesVolumesSum",
    title: "銷量加總",
  },
  {
    key: "costSum",
    title: "成本加總",
  },
  {
    key: "grossProfitSum",
    title: "毛利加總",
  },
  {
    key: "transferConsignmentDiscountSum",
    title: "轉單/寄倉折抵加總",
  },
  {
    key: "commissionFeeSum",
    title: "抽成加總",
  },
  {
    key: "postFeeSum",
    title: "後扣加總",
  },
  {
    key: "rentalFeeSum",
    title: "倉租加總",
  },
  {
    key: "orderCount",
    title: "訂單總計",
  },
];

const DashboardReport: FC = () => {
  const { VPCSalesListResult, isFetchingVPCSalesList } = useSelector((state: RootState) => state.salesReport);

  const dashboard = useMemo(
    () => ({
      commissionFeeSum: VPCSalesListResult.commissionFeeSum || 0,
      costSum: VPCSalesListResult.costSum || 0,
      grossMargin: VPCSalesListResult.grossMargin || 0,
      grossProfitSum: VPCSalesListResult.grossProfitSum || 0,
      orderCount: VPCSalesListResult.orderCount || 0,
      postFeeSum: VPCSalesListResult.postFeeSum || 0,
      rentalFeeSum: VPCSalesListResult.rentalFeeSum || 0,
      revenueSum: VPCSalesListResult.revenueSum || 0,
      salesVolumesSum: VPCSalesListResult.salesVolumesSum || 0,
      transferConsignmentDiscountSum: VPCSalesListResult.transferConsignmentDiscountSum || 0,
    }),
    [VPCSalesListResult],
  );
  const generateCard = (item: DashboardCard) => {
    const { key, title } = item;
    const contentValue = key === "grossMargin" ? dashboard[key] * 100 : dashboard[key];

    if (key === "transferConsignmentDiscountSum") {
      return (
        <Tooltip placement="top" title="黑卡及活動折抵加總" key={title}>
          <Card>
            <CardTitle>
              {title} <InfoCircleOutlined />
            </CardTitle>
            <CardContent>{contentValue}</CardContent>
          </Card>
        </Tooltip>
      );
    }

    return (
      <Card key={title}>
        <CardTitle>{title}</CardTitle>
        <CardContent>{contentValue}</CardContent>
      </Card>
    );
  };

  return (
    <Spin spinning={isFetchingVPCSalesList}>
      <Wrapper>{dashboardCardList.map(generateCard)}</Wrapper>
    </Spin>
  );
};

export default DashboardReport;
