import React from "react";
import styled from "styled-components";
import { Button, Form, Row, DatePicker, Select, Col } from "antd";
import moment from "moment";
import { BuyOutCooperationList, BuyOutCooperationValue, CooperationList, CooperationValue, TabList } from "./constant";

const CustomDatePicker = styled(DatePicker)`
  width: 200px;
`;
const Wrapper = styled(Form)`
  padding: 24px 0px 0px 16px;
`;
const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const wareHouseSearchTypeOptions = [
  { label: "請選擇", value: -1 },
  {
    label: CooperationList[CooperationValue.TRANSFER],
    value: CooperationValue.TRANSFER,
  },
  {
    label: CooperationList[CooperationValue.NEW_WAREHOUSE],
    value: CooperationValue.NEW_WAREHOUSE,
  },
  {
    label: CooperationList[CooperationValue.OLD_WAREHOUSE],
    value: CooperationValue.OLD_WAREHOUSE,
  },
  {
    label: CooperationList[CooperationValue.SHORT_TRANSFER],
    value: CooperationValue.SHORT_TRANSFER,
  },
  {
    label: CooperationList[CooperationValue.SHORT_WAREHOUSE],
    value: CooperationValue.SHORT_WAREHOUSE,
  },
];
const buyOutSearchTypeOptions = [
  { label: "請選擇", value: -1 },
  {
    label: BuyOutCooperationList[BuyOutCooperationValue.DOMESTIC_BUY_OUT],
    value: BuyOutCooperationValue.DOMESTIC_BUY_OUT,
  },
  {
    label: BuyOutCooperationList[BuyOutCooperationValue.FOREIGN_BUY_OUT],
    value: BuyOutCooperationValue.FOREIGN_BUY_OUT,
  },
];

type Props = {
  type: TabList;
  onSubmit: (params: { contract: number; dateAfter: string; dateBefore: string }) => void;
  onReset: () => void;
};

export default function Filter(props: Props) {
  const { type, onSubmit, onReset } = props;
  const [form] = Form.useForm();

  const selectOptions = () => {
    let options;
    switch (type) {
      case TabList.BUY_OUT:
        options = buyOutSearchTypeOptions;
        break;
      case TabList.WAREHOUSE_CONSIGN_AND_TRANSFER:
        options = wareHouseSearchTypeOptions;
        break;
      default:
    }
    return options;
  };

  const handleSubmit = () => {
    const formValues = form.getFieldsValue();
    if (formValues.contract === -1 && !formValues.month) return;

    const params = {
      contract: formValues.contract === -1 ? undefined : formValues.contract,
      dateAfter: moment(formValues.month).startOf("month").format("YYYY-MM-DD"),
      dateBefore: moment(formValues.month).endOf("month").format("YYYY-MM-DD"),
    };
    onSubmit(params);
  };

  const handelReset = () => {
    form.resetFields();
    onReset();
  };
  return (
    <Wrapper colon={false} labelAlign="left" form={form} initialValues={{ contract: -1 }} onFinish={handleSubmit}>
      <Row gutter={[16, 0]}>
        <Col>
          <Form.Item label="合作模式" name="contract">
            <Select style={{ width: "15vw", maxWidth: 188 }} options={selectOptions()} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item label="月份" name="month">
            <CustomDatePicker picker="month" placeholder="請選擇月份" />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end">
        <CustomButton onClick={handelReset}>清除篩選條件</CustomButton>
        <Button type="primary" htmlType="submit">
          套用
        </Button>
      </Row>
    </Wrapper>
  );
}
