/* eslint-disable @typescript-eslint/no-unused-vars */
import CoreAPI from "./CoreAPI";
import normalizeStock, { ProductDailyAggregations } from "./utils/normalizeStock";
import { transformAPIKeyToCamel } from "./utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "./utils/transformCamelToSnake";

const apiClient = new CoreAPI();

interface IStockApi {
  fetchShelfLifeListList: (params: ShelfLifeListParam) => Promise<ShelfLifeListResult>;
  exportShelfLifeListByCSV: (params: ShelfLifeListParam) => Promise<ShelfLifeListParam>;
  exportProductDailyAggregationsByCSV: (params: any) => Promise<any>;
  fetchProductDailyAggregations: (
    config: ProductDailyAggregationsConfig,
  ) => Promise<ProductDailyAggregationsListResult>;
}

export interface ProductDailyAggregationsConfig {
  limit: number;
  page: number;
  sku?: string;
  nameq?: string;
  ownerId?: number;
  statsDateBefore?: string;
  statsDateAfter?: string;
  returnInboundOnly?: number;
  productCategory?: number;
}

export interface ProductDailyAggregationsListResult {
  count: number;
  previous: string;
  next: string;
  results: ProductDailyAggregations[];
}

export interface ShelfLifeListResult {
  count: number;
  previous: string;
  next: string;
  results: ShelfLifeListItem[];
}

export interface ShelfLifeListItem {
  id: number;
  stockQty: number;
  effectiveDate: string;
  canSellExpiration: string;
  qualified: boolean;
  stockValue: number;
  turnoverDays: number;
  remainingCanSellDays: number;
  warehouseRentalFee: number;
  productInfo: {
    id: number;
    sku: string;
    name: string;
    avgDailySalesVolume: number;
    weightedCost: number;
    owner: string;
    contractMode: string;
    latestStockedDate: string;
    createdAt: string;
  };
}

export interface ShelfLifeListParam {
  contractMode: ContractMode;
  sku: string;
  nameQ: string;
  ownerId: number;
  ordering: string;
  limit: number;
  offset: number;
  overCanSellExpiration: boolean;
  tagId: number;
  vendorId: number;
}

export enum ContractMode {
  BUYOUT = 1,
  CONSIGN_WAREHOUSE = 2,
}

const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

const shelfLifeApi: IStockApi = {
  fetchShelfLifeListList: async (params) => {
    const {
      contractMode,
      sku,
      nameQ,
      ownerId,
      ordering,
      limit,
      offset,
      overCanSellExpiration,
      tagId,
      vendorId,
    } = params;

    const requestParams = {
      opt: 1, // 詳情列表
      contract_mode: contractMode < 0 ? undefined : contractMode,
      sku: sku === "" ? undefined : sku,
      name_q: nameQ === "" ? undefined : nameQ,
      owner_id: ownerId < 0 ? undefined : ownerId,
      ordering: ordering === "" ? undefined : camelToSnakeCase(ordering),
      over_can_sell_expiration: overCanSellExpiration,
      limit,
      offset,
      brand: tagId === -1 ? undefined : tagId,
      vendor: vendorId === -1 ? undefined : vendorId,
    };

    const response = await apiClient.getData("/manage/stock/effectivenesses/", requestParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  exportShelfLifeListByCSV: async (params) => {
    const { contractMode, sku, nameQ, overCanSellExpiration, ownerId, ordering, tagId, vendorId } = params;

    const requestParams = {
      opt: 1, // 詳情列表
      contract_mode: contractMode < 0 ? undefined : contractMode,
      sku: sku === "" ? undefined : sku,
      name_q: nameQ === "" ? undefined : nameQ,
      over_can_sell_expiration: overCanSellExpiration,
      owner_id: ownerId < 0 ? undefined : ownerId,
      ordering: ordering === "" ? undefined : camelToSnakeCase(ordering),
      brand: tagId < 0 ? undefined : tagId,
      vendor_id: vendorId < 0 ? undefined : vendorId,
    };

    const response = await apiClient.getData("/manage/stock/effectivenesses/csv-download/", requestParams);
    return transformAPIKeyToCamel(response.data.result);
  },
  exportProductDailyAggregationsByCSV: async (params) => {
    const requestParams = {
      sku: params.sku || undefined,
      name_q: params.nameq || undefined,
      owner_id: params.ownerId || undefined,
      stats_date_before: params.statsDateBefore || undefined,
      stats_date_after: params.statsDateAfter || undefined,
      return_inbound_only: params.returnInboundOnly || undefined,
      product_category: params.productCategory || undefined,
      action: params.groupByMode,
    };
    const response = await apiClient.getData("/manage/product/product-daily-aggregations/export/", requestParams);

    return response.data.result;
  },
  fetchProductDailyAggregations: async (config) => {
    const requestParams = {
      limit: config.limit,
      offset: (config.page - 1) * config.limit,
      sku: config.sku || undefined,
      name_q: config.nameq || undefined,
      owner_id: config.ownerId || undefined,
      stats_date_before: config.statsDateBefore || undefined,
      stats_date_after: config.statsDateAfter || undefined,
      return_inbound_only: config.returnInboundOnly || undefined,
      product_category: config.productCategory || undefined,
    };
    const response = await apiClient.getData("/manage/product/product-daily-aggregations/", requestParams);
    return {
      count: response.data.result.count,
      next: response.data.result.next,
      previous: response.data.result.previous,
      results: normalizeStock.productDailyAggregations(response.data.result.results),
    };
  },
};

export default shelfLifeApi;
