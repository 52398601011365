import { CloseOutlined, InfoCircleFilled, UploadOutlined } from "@ant-design/icons";
import PopupBackground from "@component/PopupBackground";
import { RootState } from "@redux/rootReducer";
import { batchAddSalePagesByCSV, clearCSVErrorMessage, updateCSVUploadSuccess } from "@redux/salesCategorySlice";
import { Button } from "antd";
import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  height: 305px;
  background-color: white;
`;
const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 22px;
  right: 22px;
`;
const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const UploadIcon = styled(UploadOutlined)`
  font-size: 66px;
  color: ${({ theme }) => theme.colorNeutral500};
  margin-bottom: 35px;
`;
const WarningIcon = styled(InfoCircleFilled)`
  font-size: 42px;
  color: #f16c5d;
  margin-bottom: 39px;
`;
const SelectFile = styled.label`
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const DownloadFile = styled.a`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorSuccess500};
  text-decoration: underline;
  cursor: pointer;
`;
const ButtonContainer = styled.div`
  position: absolute;
  right: 28px;
  bottom: 20px;
`;
const GobackButton = styled(Button)`
  margin-right: 10px;
`;
const ImportButton = styled(Button)``;
const InputFile = styled.input`
  display: none;
`;
const ErrorText = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.colorNeutral600};
`;

interface Props {
  onClose: () => void;
}

const ImportCSV: FC<Props> = (props) => {
  const { onClose } = props;
  const [uploadFile, setUploadFile] = useState<File>();
  const csvUploadSuccess = useSelector((state: RootState) => state.salesCategory.csvUploadSuccess);
  const csvErrorMessage = useSelector((state: RootState) => state.salesCategory.csvErrorMessage);
  const dispatch = useAppDispatch();

  // 監聽csv上傳 成功狀態
  useEffect(() => {
    if (csvUploadSuccess) {
      onClose();
      dispatch(updateCSVUploadSuccess(false));
    }

    return () => {
      dispatch(updateCSVUploadSuccess(false));
    };
  }, [csvUploadSuccess, onClose, dispatch]);

  const displayText = () => {
    if (csvErrorMessage) return <ErrorText>{csvErrorMessage}</ErrorText>;
    if (uploadFile) {
      return (
        <SelectFile>
          {uploadFile.name}
          <InputFile type="file" accept=".csv,.xlsx,.xls" onChange={handleOnFileUpload} />
        </SelectFile>
      );
    }

    return (
      <SelectFile>
        選擇檔案
        <InputFile type="file" accept=".csv,.xlsx,.xls" onChange={handleOnFileUpload} />
      </SelectFile>
    );
  };

  const handleOnFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) setUploadFile(files[0]);
  };

  const handleOnGoBack = () => {
    dispatch(clearCSVErrorMessage());
    setUploadFile(undefined);
  };

  const handleOnImport = () => {
    if (uploadFile) dispatch(batchAddSalePagesByCSV(uploadFile));
  };

  return (
    <PopupBackground close={onClose}>
      <Wrapper>
        <CloseIcon onClick={onClose} />
        <CenterBlock>
          {csvErrorMessage ? <WarningIcon /> : <UploadIcon />}
          {displayText()}
          <DownloadFile
            href={`${process.env.REACT_APP_CLOUDFLARE_DOMAIN}admin/template/csv/category_batch_add_sales_pages.xlsx`}
          >
            下載範例檔案
          </DownloadFile>
        </CenterBlock>
        <ButtonContainer>
          {!!csvErrorMessage && <GobackButton onClick={handleOnGoBack}>返回</GobackButton>}
          <ImportButton type="primary" onClick={handleOnImport} disabled={!uploadFile || !!csvErrorMessage}>
            匯入
          </ImportButton>
        </ButtonContainer>
      </Wrapper>
    </PopupBackground>
  );
};

export default ImportCSV;
