import { FetchSalePageListParam } from "@api/salePageCategoryApi";
import InfiniteScrollObserver from "@component/InfiniteScrollObserver";
import useDebounce from "@hooks/useDebounce";
import { RootState } from "@redux/rootReducer";
import moment from "moment";

import {
  fetchBrandList,
  fetchSalePageList,
  fetchSalePageListByLevelCollection,
  initialState,
  loadMoreBrandList,
  updateSalePageListFilter,
} from "@redux/salesCategorySlice";
import { Button, DatePicker, Input, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/store";
import styled from "styled-components";
import locale from "antd/es/date-picker/locale/zh_TW";

const Wrapper = styled.div`
  display: grid;
  padding-bottom: 26px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  column-gap: 10px;
  row-gap: 12px;
  border-bottom: 1px solid #f2f2f2;
  margin-bottom: 14px;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Title = styled.div`
  flex: 0.7;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colorNeutral600};
`;
const Content = styled.div`
  flex: 3;
`;
const PriceInput = styled(Input)`
  width: calc(50% - 10px);
  &:first-child {
    margin-right: 20px;
  }
`;
const MaxWidthSelect = styled(Select)`
  width: 100%;
`;

const ButtonContainer = styled.div`
  grid-column: 2;
  justify-self: end;
`;
const ClearButton = styled(Button)`
  margin-right: 10px;
`;
const SearchButton = styled(Button)``;

type Props = {
  canAddSalespage: boolean;
};

const Filter: FC<Props> = (props) => {
  const { canAddSalespage } = props;

  const filter = useSelector((state: RootState) => state.salesCategory.salePageListFilter);
  const brandListResult = useSelector((state: RootState) => state.salesCategory.brandListResult);
  const dispatch = useAppDispatch();
  const [localFilter, setLocalFilter] = useState<FetchSalePageListParam>(initialState.salePageListFilter);

  const { pageIds, pageName, sku, brandNames, minPrice, maxPrice, endAt, startAt } = localFilter;

  const handleOnBrandLoadMore = useCallback(() => {
    dispatch(loadMoreBrandList());
  }, [dispatch]);

  const brandOptions = useMemo(() => {
    const { next, results } = brandListResult;
    const options = results.map((brand) => (
      <Select.Option key={brand.id} value={brand.id}>
        {brand.name}
      </Select.Option>
    ));

    if (next) {
      options.push(
        <Select.Option value="loading..." disabled>
          loading...
          <InfiniteScrollObserver callback={handleOnBrandLoadMore} />
        </Select.Option>,
      );
    }

    return options;
  }, [brandListResult, handleOnBrandLoadMore]);

  useEffect(() => {
    dispatch(fetchBrandList(""));
  }, [dispatch]);

  useEffect(() => {
    setLocalFilter(filter);
  }, [filter]);

  const handleOnBrandSearch = useDebounce((value: string) => {
    dispatch(fetchBrandList(value));
  }, 300);

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, title } = e.target;

    const updatedFilter = {
      ...localFilter,
      [title]: value,
    };

    setLocalFilter(updatedFilter);
  };

  const handleSelectTime = (e: any) => {
    const params = {
      ...localFilter,
      startAt: e ? moment(e[0]).format("YYYY-MM-DD HH:mm:ss") : "",
      endAt: e ? moment(e[1]).format("YYYY-MM-DD HH:mm:ss") : "",
    };

    setLocalFilter(params);
  };

  const handleOnPriceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, title } = e.target;
    const targetValue = value === "" ? "" : Number(value);

    const updatedFilter = {
      ...localFilter,
      [title]: targetValue,
    };

    setLocalFilter(updatedFilter);
  };

  const handleOnBrandChange = (values: SelectValue) => {
    const updatedFilter = {
      ...localFilter,
      brandNames: values as string[],
    };

    setLocalFilter(updatedFilter);
  };
  const handleOnClear = () => {
    setLocalFilter({ ...initialState.salePageListFilter, startAt: undefined, endAt: undefined });
    dispatch(updateSalePageListFilter({ ...initialState.salePageListFilter, startAt: undefined, endAt: undefined }));
    if (canAddSalespage) {
      dispatch(fetchSalePageList());
    } else {
      dispatch(fetchSalePageListByLevelCollection());
    }
  };

  const handleOnSearch = () => {
    dispatch(
      updateSalePageListFilter({
        ...localFilter,
        offset: 0,
      }),
    );
    if (canAddSalespage) {
      dispatch(fetchSalePageList());
    } else {
      dispatch(fetchSalePageListByLevelCollection());
    }
  };

  return (
    <Wrapper>
      <Row>
        <Title>銷售頁ID</Title>
        <Content>
          <Input value={pageIds} title="pageIds" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>銷售頁名稱</Title>
        <Content>
          <Input value={pageName} title="pageName" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>SKU</Title>
        <Content>
          <Input value={sku} title="sku" onChange={handleOnChange} />
        </Content>
      </Row>
      <Row>
        <Title>品牌</Title>
        <Content>
          <MaxWidthSelect
            mode="multiple"
            allowClear
            value={brandNames}
            filterOption={false}
            onSearch={handleOnBrandSearch}
            onChange={(value) => handleOnBrandChange(value as SelectValue)}
          >
            {brandOptions}
          </MaxWidthSelect>
        </Content>
      </Row>
      <Row>
        <Title>價錢</Title>
        <Content>
          <PriceInput
            value={minPrice}
            title="minPrice"
            type="number"
            onChange={handleOnPriceChange}
            placeholder="From"
          />
          <PriceInput value={maxPrice} title="maxPrice" type="number" onChange={handleOnPriceChange} placeholder="To" />
        </Content>
      </Row>
      <Row>
        <Title>展示期間</Title>
        <Content>
          <DatePicker.RangePicker
            value={startAt && endAt ? [moment(startAt), moment(endAt)] : undefined}
            onChange={handleSelectTime}
            format="YYYY-MM-DD HH:mm"
            showTime={{
              hideDisabledOptions: true,
              format: "HH:mm",
            }}
            locale={locale}
            style={{ width: "100%" }}
          />
        </Content>
      </Row>
      <ButtonContainer>
        <ClearButton onClick={handleOnClear}>清除篩選條件</ClearButton>
        <SearchButton type="primary" onClick={handleOnSearch}>
          套用
        </SearchButton>
      </ButtonContainer>
    </Wrapper>
  );
};

export default Filter;
