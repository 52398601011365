import { OrderStatus } from "@constant/OrderStatus";
import CoreAPI from "../CoreAPI";
import { transformAPIKeyToCamel } from "../utils/transformAPIKeyToCamel";
import { transformCamelToSnake } from "../utils/transformCamelToSnake";

const apiClient = new CoreAPI();

export interface ListFilter {
  limit: number;
  offset: number;
}

export interface ListResult {
  count: number;
  next: null;
  previous: null;
}

export interface OrderListFilter {
  limit: number;
  offset: number;
  status?: number;
  orderNumber?: string;
  platformNumber?: string;
  sku?: string;
  estimatedShippingAfter?: string;
  estimatedShippingBefore?: string;
  shippingAfter?: string;
  shippingBefore?: string;
}

export interface OnlineOrderListResult {
  count: number;
  next: string | null;
  previous: string | null;
  results: OnlineOrderList[];
}

export interface OrderDetail {
  id: number;
  destroyedAt: string | null;
  submittedAt: string | null;
  orderNumber: string;
  staff: string;
  createdAt: string;
  status: OrderStatus;
  warehouseCode: string;
  platform: string;
  grandTotal: number;
  estimatedShippingDate: string | null;
  estimatedArrivalDate: string | null;
  platformNumber: string;
  shippingMethod: number;
  receiverName: string;
  receiverPhone: string;
  receiverAddress: string;
  receiverZipcode: string;
  shippingDate: string | null;
  packageNumber: string;
  note: string;
}

export interface OnlineOrderList {
  id: number;
  orderNumber: string;
  platform: string;
  platformNumber: string;
  status: number;
  orderStatus: number;
  grandTotal: number;
  receiverName: string;
  totalEstimatedQty: number;
  estimatedShippingDate: string | null;
  totalActualQty: number;
  shippingDate: string | null;
  destroyedAt: string | null;
  submittedAt: string | null;
  createdAt: string;
}
export interface OnlineOrdersFilter extends ListFilter {
  orderNumber?: string;
  platForm?: string;
  platFormrNumber?: string;
  id?: string;
  createdAtAfter?: string;
  createdAtBefore?: string;
  shippingAtAfter?: string;
  shippingAtBefore?: string;
}
export interface UpdateOrder {
  orderId: string;
  warehouse: number;
  platform: string;
  platformNumber: number;
  grandTotal?: number;
  estimatedShippingDate: string;
  estimatedArrivalDate: string;
  shippingMethod: number;
  receiverName: string;
  receiverPhone: string;
  receiverAddress?: string;
  receiverZipcode: string;
}

export type CreateOrder = Omit<UpdateOrder, "orderId">;

// 商品
export interface ProductListResult extends ListResult {
  results: Product[];
}

export interface Product {
  id: number;
  sku: string;
  barcode: string;
  cost: number;
  productName: string;
  standardPrice: number;
  effectiveDate: string;
  storageType: string;
  batch: string;
  unitPrice: number;
  estimatedQty: number;
  actualQty: number;
  stock: number;
}

export interface CreateProduct {
  orderId: string;
  sku: string;
  storageType: string;
  estimatedQty: number;
  unitPrice: number | null;
  effectiveDate: string;
}

export interface UpdateProduct extends CreateProduct {
  productId: number;
}
export interface ProductBarcodeInfo {
  count: number;
  next: null;
  previous: null;
  results: [
    {
      sku: string;
      warehouseCode: string;
      productName: string;
      barcode: string;
      weightedCost: number;
    },
  ];
}
export interface ProductStockInfoList {
  stockInfo: ProductStockInfo[];
}

export interface ProductStockInfo {
  batch: string | null;
  canSalesQty: number;
  effectiveDate: string | null;
  storageType: string;
  warehouseCode: string;
}

interface OnlineOrderApi {
  // 主單
  fetchOrderList: (payload: OnlineOrdersFilter) => Promise<OnlineOrderListResult>;
  fetchCreateOrder: (payload: CreateOrder) => Promise<OrderDetail>;
  fetchUpdateOrder: (payload: UpdateOrder) => Promise<void>;
  fetchDeleteOrder: (orderId: number) => Promise<void>;
  fetchOrderDetail: (orderId: string) => Promise<OrderDetail>;
  fetchBatchUploadOrderLists: (file: File) => Promise<void>;
  fetchSubmitOrder: (id: string) => Promise<void>;
  // 出貨商品
  fetchProductList: (orderId: string) => Promise<ProductListResult>;
  fetchCreateProduct: (payload: CreateProduct) => Promise<void>;
  fetchUpdateProduct: (payload: UpdateProduct) => Promise<void>;
  fetchDeleteProduct: (payload: { orderId: string; productId: number }) => Promise<void>;
  fetchUploadProducts: (payload: { file: File; orderId: string }) => Promise<void>;
  // 編輯出貨商品
  fetchProductBarcodeBySku: (payload: { sku: string; warehouse: string }) => Promise<ProductBarcodeInfo>;
  fetchProductStockBySku: (payload: { sku: string; warehouse: string }) => Promise<ProductStockInfoList[]>;
}

const onlineOrderApi: OnlineOrderApi = {
  // 主單
  fetchOrderList: async (payload) => {
    const response = await apiClient.getData("/tryall/manage/order/orders/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchCreateOrder: async (payload) => {
    const response = await apiClient.postData("/tryall/manage/order/orders/", transformCamelToSnake(payload));
    return transformAPIKeyToCamel(response.data.result);
  },
  fetchOrderDetail: async (orderId) => {
    const response = await apiClient.getData(`/tryall/manage/order/orders/${orderId}/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchUpdateOrder: async (payload) => {
    await apiClient.putData(`/tryall/manage/order/orders/${payload.orderId}/`, transformCamelToSnake(payload));
  },

  fetchDeleteOrder: async (id) => {
    await apiClient.deleteData(`/tryall/manage/order/orders/${id}`, {});
  },

  fetchSubmitOrder: async (orderId) => {
    await apiClient.putData(`/tryall/manage/order/orders/${orderId}/submit/`, {});
  },

  fetchBatchUploadOrderLists: async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    const response = await apiClient.postData("/tryall/manage/order/orders/upload/", formData);
    return response.data;
  },

  // 商品
  fetchProductList: async (orderId) => {
    const response = await apiClient.getData(`/tryall/manage/order/orders/${orderId}/orderlines/`, {});
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchCreateProduct: async (payload) => {
    const { orderId, ...restPayload } = payload;
    const response = await apiClient.postData(
      `/tryall/manage/order/orders/${orderId}/orderlines/`,
      transformCamelToSnake(restPayload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchUpdateProduct: async (payload) => {
    const { orderId, productId, ...restPayload } = payload;
    const response = await apiClient.putData(
      `/tryall/manage/order/orders/${orderId}/orderlines/${productId}/`,
      transformCamelToSnake(restPayload),
    );
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchDeleteProduct: async (params: { orderId: string; productId: number }) => {
    await apiClient.deleteData(`/tryall/manage/order/orders/${params.orderId}/orderlines/${params.productId}/`, {});
  },

  fetchUploadProducts: async (payload) => {
    const formData = new FormData();
    formData.append("file", payload.file);
    const response = await apiClient.postData(
      `/tryall/manage/order/orders/${payload.orderId}/orderlines/upload/`,
      formData,
    );
    return response.data;
  },

  fetchProductBarcodeBySku: async (payload) => {
    const params = {
      warehouseCode: payload.warehouse,
      sku: payload.sku,
    };
    const response = await apiClient.getData("/manage/product/product-warehouse/", transformCamelToSnake(params));
    return transformAPIKeyToCamel(response.data.result);
  },

  fetchProductStockBySku: async (payload) => {
    const params = {
      warehouseCode: payload.warehouse,
      sku: payload.sku,
    };
    const response = await apiClient.getData(
      "/manage/product/product-warehouse/stocks/",
      transformCamelToSnake(params),
    );
    return transformAPIKeyToCamel(response.data.result);
  },
};

export default onlineOrderApi;
