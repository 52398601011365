import useDebounce from "@hooks/useDebounce";
import { Select, SelectProps } from "antd";
import React, { useEffect } from "react";

/**
 * 無限滾動下拉選單
 * @param config offset: 起始位置, limit: 每次請求數量
 * @param fetchOptions 請求選項 API 至少需要傳入 offset, limit 兩個參數, nameQ 為搜尋參數
 * @param reset SelectProps 其他參數與 Ant design Select 相同
 */

type Props = {
  config?: {
    offset?: number;
    limit?: number;
  };
  fetchOptions: (searchParams: { offset: number; limit: number; nameQ?: string }) => Promise<any>;
} & SelectProps;

const InfiniteScrollSelect = (props: Props) => {
  const { config, fetchOptions, ...reset } = props;
  const limit = config?.limit || 10;
  const [nowOffset, setOffset] = React.useState<number>(config?.offset || 0);
  const [list, setList] = React.useState<any[]>([]);
  const [options, setOptions] = React.useState<{ label: string; value: number }[]>([]);
  const [listEnd, setListEnd] = React.useState<boolean>(false);

  const fetchList = async (offset: number, init?: boolean) => {
    const response = await fetchOptions({ offset, limit });
    const { next, results } = response;
    const itemsOptions = results.map((result: any) => ({ label: result.name, value: result.id }));

    if (next) {
      itemsOptions.push({ label: " loading...", value: -1, disabled: true });
    } else {
      setListEnd(true);
    }
    // 回填值:除了init都要保留原有值
    setOffset(offset);
    setList(init ? results : list.concat(results));
    setOptions(init ? itemsOptions : options.filter((item) => item.value !== -1)?.concat(itemsOptions));
  };

  const fetchSearchList = async (nameQ: string) => {
    const response = await fetchOptions({ offset: 0, limit: 200, nameQ });
    const { results } = response;
    const itemsOptions = results.map((result: any) => ({ label: result.name, value: result.id }));
    setOptions(itemsOptions);
  };

  const handleOnSearch = useDebounce((nameQ: string) => {
    if (nameQ) {
      fetchSearchList(nameQ);
    } else {
      const itemsOptions: any = list.map((result: any) => ({ label: result.name, value: result.id }));
      if (!listEnd) {
        itemsOptions.push({ label: " loading...", value: -1, disabled: true });
      }
      setOptions(itemsOptions);
    }
  }, 300);

  const handleOnPopupScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLDivElement;
    if (listEnd) return;
    if (scrollTop + clientHeight >= scrollHeight) {
      fetchList(nowOffset + limit);
    }
  };

  useEffect(() => {
    // init fetch
    fetchList(nowOffset, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Select
      placeholder="請選擇"
      options={options}
      onPopupScroll={handleOnPopupScroll}
      onSearch={handleOnSearch}
      filterOption={false}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...reset}
    />
  );
};
export default InfiniteScrollSelect;
