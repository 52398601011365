/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-template-curly-in-string */
import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useAppDispatch } from "src/store";
import { useSelector } from "react-redux";
import type { RootState } from "@redux/rootReducer";
import { fetchDashboardChart, DashboardChartType } from "@redux/dashboardSlice";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";
import USCurrencyFormmater from "@utils/USCurrencyFormmater";
import { RevenueChartItem, RevenueChart } from "@api/dashboardApi";
import PageTitle from "@component/PageTitle";
import ChartFilter from "../ChartFilter";
import {
  hoverAction,
  getPointStart,
  getPointInterval,
  getTimeFormat,
  getTooltipTitle,
  getFilledTimeData,
} from "../utils";

const Wrapper = styled.div`
  padding: 10px 25px;
`;
const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  border-radius: 2px;
  padding: 30px;
  min-height: 700px;

  & .highcharts-tooltip span {
    height: 90px;
  }
`;

enum SeriesNameMap {
  currentWebRevenue = "Web 營收",
  currentAppRevenue = "App 營收",
  compareWebRevenue = "Web 營收 - 相較於",
  compareAppRevenue = "App 營收 - 相較於",
}

const SalesRevenueChart: FC = () => {
  const dispatch = useAppDispatch();
  const { isFetchingChart, chartResult, chartParams } = useSelector((state: RootState) => state.dashboard);

  const [chartOptions, setChartOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const { startDate, endDate, cStartDate, cEndDate, timeUnit } = chartParams;
    const pointStart = getPointStart(startDate, timeUnit);
    const pointInterval = getPointInterval(timeUnit);
    const filledCurrentTimeMap = getFilledTimeData<RevenueChartItem>(
      startDate,
      endDate,
      (chartResult as RevenueChart).currentData,
      timeUnit,
    );
    const filledCompareTimeMap = getFilledTimeData<RevenueChartItem>(
      cStartDate,
      cEndDate,
      (chartResult as RevenueChart).compareData || [],
      timeUnit,
    );
    const currentWebRevenue: (number | null)[] = [];
    const currentAppRevenue: (number | null)[] = [];
    const compareWebRevenue: (number | null)[] = [];
    const compareAppRevenue: (number | null)[] = [];
    const currentTimes = Array.from(filledCurrentTimeMap.keys());
    const compareTimes = Array.from(filledCompareTimeMap.keys());
    const currentFilledTimeData = Array.from(filledCurrentTimeMap.values());
    const compareFilledTimeData = Array.from(filledCompareTimeMap.values());

    currentFilledTimeData.forEach((data) => {
      const { webRevenue = null, appRevenue = null } = data;
      currentWebRevenue.push(webRevenue);
      currentAppRevenue.push(appRevenue);
    });
    compareFilledTimeData.forEach((data) => {
      const { webRevenue = null, appRevenue = null } = data;
      compareWebRevenue.push(webRevenue);
      compareAppRevenue.push(appRevenue);
    });

    const series: Highcharts.SeriesOptionsType[] = [
      {
        name: SeriesNameMap.currentWebRevenue,
        data: currentWebRevenue,
        type: "column",
        stack: "current",
        color: "#BAE7FF",
        pointStart,
      },
      {
        name: SeriesNameMap.currentAppRevenue,
        data: currentAppRevenue,
        type: "column",
        stack: "current",
        color: "#1890FF",
        pointStart,
      },
    ];

    if (cStartDate && cEndDate) {
      series.unshift({
        name: SeriesNameMap.compareAppRevenue,
        data: compareAppRevenue,
        type: "column",
        stack: "compare",
        color: "#5C6AC4",
        pointStart,
      });
      series.unshift({
        name: SeriesNameMap.compareWebRevenue,
        data: compareWebRevenue,
        type: "column",
        stack: "compare",
        color: "#C9CCDE",
        pointStart,
      });
    }

    setChartOptions({
      title: {
        text: "",
      },
      chart: {
        type: "column",
      },
      xAxis: {
        type: "datetime",
        labels: {
          formatter() {
            const timeFormat = getTimeFormat(timeUnit);
            const formattedText = Highcharts.dateFormat(timeFormat, this.value as number);
            return formattedText;
          },
          rotation: -45,
        },
      },
      yAxis: {
        title: {
          text: "",
        },
        labels: {
          format: "${text}",
        },
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
      },
      plotOptions: {
        series: {
          pointStart,
          pointInterval,
        },
        column: {
          stacking: "normal",
          states: {
            inactive: {
              enabled: false,
            },
          },
          point: {
            events: {
              mouseOver() {
                hoverAction(this, "hover");
              },
              mouseOut() {
                hoverAction(this, "");
              },
            },
          },
        },
      },
      tooltip: {
        formatter() {
          const pointIndex = this.point.index;
          const stackName = this.point.series.userOptions.stack;

          const title =
            stackName === "current"
              ? getTooltipTitle(startDate, endDate, currentTimes[pointIndex], timeUnit)
              : getTooltipTitle(cStartDate, cEndDate, compareTimes[pointIndex], timeUnit);

          const webRevenueName =
            stackName === "current" ? SeriesNameMap.currentWebRevenue : SeriesNameMap.compareWebRevenue;
          const appRevenueName =
            stackName === "current" ? SeriesNameMap.currentAppRevenue : SeriesNameMap.compareAppRevenue;
          let webRevenue = 0;
          let appRevenue = 0;

          this.series.chart.series.forEach((srs) => {
            if (srs.options.stack === stackName && srs.options.name === webRevenueName)
              webRevenue = srs.points[pointIndex].y || 0;
            if (srs.options.stack === stackName && srs.options.name === appRevenueName)
              appRevenue = srs.points[pointIndex].y || 0;
          });

          const totalRevenue = webRevenue + appRevenue;
          const webRevenuePer = Math.round((webRevenue / totalRevenue) * 1000) / 10; // 百分比取小數點第一位
          const appRevenuePer = Math.round((appRevenue / totalRevenue) * 1000) / 10;

          return (
            `${title}<br/>` +
            `累計營收 ${USCurrencyFormmater.format(totalRevenue)}<br/>` +
            `Web 營收 ${USCurrencyFormmater.format(webRevenue)} (${webRevenuePer}%)<br/>` +
            `App 營收 ${USCurrencyFormmater.format(appRevenue)} (${appRevenuePer}%)`
          );
        },
        backgroundColor: "#5D5954",
        borderColor: "#5D5954",
        style: {
          color: "#FFFFFF",
          fontSize: "12px",
          lineHeight: "22px",
        },
        useHTML: true,
      },
      series,
    });
  }, [chartResult, chartParams]);

  const fetchRevenueChart = useCallback(() => {
    dispatch(fetchDashboardChart(DashboardChartType.REVENUE));
  }, [dispatch]);

  return (
    <Spin spinning={isFetchingChart}>
      <Wrapper>
        <PageTitle title="Dashboard - 營收" />
        <ChartWrapper>
          <ChartFilter title="營收" fetchAction={fetchRevenueChart} />
          <HighchartsReact highcharts={Highcharts} options={chartOptions} containerProps={{ style: { height: 500 } }} />
        </ChartWrapper>
      </Wrapper>
    </Spin>
  );
};

export default SalesRevenueChart;
