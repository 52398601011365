import { TopicCategorySaleInfo } from "@api/topicApi";
import PopupBackground from "@component/PopupBackground";
import useDebounce from "@hooks/useDebounce";
import { fetchAddRecommendList, fetchBrandList, fetchTopicCategorySaleList, topicState } from "@redux/topicSlice";
import { Button, Checkbox, Col, Form, Input, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/lib/table/interface";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Wrapper = styled(Form)`
  width: 870px;
  max-height: 640px;
  padding: 28px 28px 20px 32px;
  background: ${({ theme }) => theme.colorNeutral100};
`;

const CustomButton = styled(Button)`
  margin-right: 10px;
`;

const TableWrapper = styled.div`
  padding-top: 14px;
  margin-top: 14px;
  border-top: solid 1px ${({ theme }) => theme.colorNeutral300};
`;

const CustomRow = styled(Row)<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => marginTop || 0};
`;

type Props = {
  close: () => void;
};

export default function RecommendAddSalePage(props: Props) {
  const { close } = props;

  const dispatch = useDispatch();
  const { brandList, topicSaleListResult, topicRecommendSaleListResult, isFetching } = useSelector(topicState);

  const [form] = Form.useForm<{
    brand: string[];
    id: string;
    name: string;
    sku: string;
  }>();

  const [checkSalesItem, setCheckSalesItem] = useState<Set<number>>(new Set());

  const urlParameters = qs.parseUrl(window.location.href);
  const topicCategoryId = parseInt(urlParameters.query.category as string, 10);

  const onReset = () => {
    form.resetFields();
  };

  const onCheckAllSalesItem = (checked: boolean) => {
    if (checked) {
      const allSalesItem = topicSaleListResult.results.map((item) => item.id);
      setCheckSalesItem(new Set(allSalesItem));
    } else {
      setCheckSalesItem(new Set());
    }
  };

  const onSetCheckSalesItem = (checked: boolean, id: number) => {
    if (checked) {
      setCheckSalesItem(new Set(checkSalesItem.add(id)));
    } else {
      const newCheckSalesItem = new Set(checkSalesItem);
      newCheckSalesItem.delete(id);
      setCheckSalesItem(newCheckSalesItem);
    }
  };

  const onSearchBrands = useDebounce((val: string) => {
    if (val) {
      dispatch(fetchBrandList({ searchName: val, limit: 100, offset: 0 }));
    }
  }, 1000);

  const onSearch = () => {
    const values = form.getFieldsValue();
    dispatch(
      fetchTopicCategorySaleList({
        topicCategoryId,
        limit: 100,
        offset: 0,
        brandNames: values.brand,
        sku: values.sku,
        id: values.id ? parseInt(values.id, 10) : undefined,
        nameQ: values.name,
      }),
    );
  };

  const onSubmit = () => {
    dispatch(
      fetchAddRecommendList({
        topicCategoryId,
        data: Array.from(checkSalesItem).map((salePageId) => ({
          salesPage: salePageId as number,
          rank: 50,
        })),
      }),
    );
    close();
  };

  const columns: ColumnsType<TopicCategorySaleInfo> = [
    {
      key: "checkbox",
      dataIndex: "checkbox",
      title: () => <Checkbox onChange={(e) => onCheckAllSalesItem(e.target.checked)} />,
      render: (_: any, values) => (
        <Checkbox
          checked={checkSalesItem.has(values.id)}
          onChange={(e) => onSetCheckSalesItem(e.target.checked, values.id)}
        />
      ),
    },
    {
      key: "id",
      dataIndex: "id",
      title: "ID",
    },
    {
      key: "skus",
      dataIndex: "skus",
      title: "SKU",
      render: (skus: string[]) => skus.map((sku) => <div>{sku}</div>),
    },
    {
      key: "name",
      dataIndex: "name",
      title: "名稱",
    },
    {
      key: "brands",
      dataIndex: "brands",
      title: "品牌",
      render: (brands: { tagId: number; name: string }[]) => brands.map((brand) => <div>{brand.name}</div>),
    },
    {
      key: "priceRange",
      dataIndex: "priceRange",
      title: "價錢",
      render: (priceRange: { min: number; max: number }) =>
        priceRange.min === priceRange.max ? (
          <div>{`$${priceRange.min}`}</div>
        ) : (
          <div>{`$${priceRange.min}-$${priceRange.max}`}</div>
        ),
    },
  ];

  useEffect(() => {
    dispatch(
      fetchTopicCategorySaleList({
        topicCategoryId,
        limit: 100,
        offset: 0,
      }),
    );
  }, [dispatch, topicCategoryId]);

  useEffect(() => {
    topicRecommendSaleListResult.results.forEach((item) => {
      setCheckSalesItem((prev) => new Set(prev.add(item.pageId)));
    });
  }, [topicRecommendSaleListResult.results]);

  return (
    <PopupBackground close={close} fixed>
      <Wrapper<any>
        colon={false}
        labelAlign="left"
        labelCol={{ span: 6 }}
        form={form}
        initialValues={{}}
        onFinish={onSearch}
      >
        <Row gutter={36}>
          <Col span={11}>
            <Form.Item label="銷售頁ID" name="id">
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="銷售頁名稱" name="name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={36}>
          <Col span={11}>
            <Form.Item label="SKU" name="sku">
              <Input />
            </Form.Item>
          </Col>
          <Col span={11}>
            <Form.Item label="品牌" name="brand">
              <Select
                mode="multiple"
                onSearch={onSearchBrands}
                onFocus={() => dispatch(fetchBrandList({ limit: 100, offset: 0 }))}
              >
                {brandList.map((brand) => (
                  <Select.Option key={brand.id} value={brand.name}>
                    {brand.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="end">
          <CustomButton onClick={onReset}>清除篩選條件</CustomButton>
          <Button type="primary" htmlType="submit">
            搜尋
          </Button>
        </Row>
        <TableWrapper>
          <Table
            loading={isFetching}
            scroll={{ y: 300 }}
            columns={columns}
            dataSource={topicSaleListResult.results}
            pagination={false}
          />
        </TableWrapper>
        <CustomRow justify="end" marginTop="20px">
          <Button type="primary" onClick={onSubmit}>
            確認
          </Button>
        </CustomRow>
      </Wrapper>
    </PopupBackground>
  );
}
