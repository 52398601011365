import React from "react";
import CategoryTags from "./CategoryTags";
import ManageTagProvider from "./ManageTagContext";
import SearchCategory from "./SearchCategory";

export default function ManageTag() {
  return (
    <ManageTagProvider>
      <SearchCategory />
      <CategoryTags />
    </ManageTagProvider>
  );
}
