import { Tree, Button } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { FetchSaleListParams } from "@api/saleApi";
import { SalesCategory } from "@page/TopicPage/utils/generateCategoryTree";
import getCategoryBreadcrumb from "@page/TopicPage/utils/getCategoryBreadcrumb";

const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;

  width: 400px;
  height: 300px;
  padding: 14px 12px 12px 17px;
  z-index: 1;

  background-color: white;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.04), 0px 6px 16px rgba(0, 0, 0, 0.04),
    0px 9px 28px 8px rgba(0, 0, 0, 0.04);
  border-radius: 2px;
`;
const SearchElement = styled.span`
  color: #1890ff;
`;
const TreeContainer = styled.div`
  flex: auto;
  overflow: auto;
`;
const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SelectedText = styled.div`
  font-size: 12px;
`;

const getParentKey = (key: number, tree: SalesCategory[]): number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i += 1) {
    const node = tree[i];
    if (node.children) {
      if (node.children.some((item: any) => item.key === key)) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children as SalesCategory[])) {
        parentKey = getParentKey(key, node.children as SalesCategory[]);
      }
    }
  }
  return parentKey;
};

const smallList: { key: number; title: string }[] = [];

interface Props {
  tree: Map<number, SalesCategory>;
  level1: Map<number, SalesCategory>;
  level2: Map<number, SalesCategory>;
  level3: Map<number, SalesCategory>;
  searchText: string;
  localFilter: FetchSaleListParams;
  setLocalFilter: React.Dispatch<React.SetStateAction<FetchSaleListParams>>;
  onClose: () => void;
}

export default function CategoriesModal(props: Props) {
  const { tree, localFilter, setLocalFilter, searchText, level1, level2, level3, onClose } = props;
  const { categoryIds } = localFilter;

  const [treeSearchState, setTreeSearchState] = useState<{
    expandedKeys: number[];
    autoExpandParent: boolean;
  }>({
    expandedKeys: [],
    autoExpandParent: true,
  });
  const [currentSelectedCategoryIds, setCurrentSelectedCategoryIds] = useState<number[]>([]);

  useEffect(() => {
    if (categoryIds) setCurrentSelectedCategoryIds(categoryIds);
  }, [categoryIds]);

  const treeArray = useMemo(() => Array.from(tree.values()), [tree]);

  const currentCategoryTag = useMemo(() => {
    return getCategoryBreadcrumb(level1, level2, level3, currentSelectedCategoryIds);
  }, [currentSelectedCategoryIds, level1, level2, level3]);

  const handleOnCheck = (list: any) => {
    setCurrentSelectedCategoryIds(list as number[]);
  };

  const handleOnSubmit = () => {
    setLocalFilter((prev) => ({
      ...prev,
      categoryIds: currentSelectedCategoryIds,
    }));
    onClose();
  };

  const generateSmallList = useCallback((data: SalesCategory[]) => {
    for (let i = 0; i < data.length; i += 1) {
      const node = data[i];
      const { key, title } = node;
      smallList.push({ key, title });
      if (node.children) {
        generateSmallList(node.children as SalesCategory[]);
      }
    }
  }, []);

  const onExpand = (expandedKeys: React.ReactText[]) => {
    setTreeSearchState({
      ...treeSearchState,
      expandedKeys: expandedKeys as number[],
      autoExpandParent: false,
    });
  };

  const searchCategory = useCallback(
    (text: string) => {
      const expandedKeys = smallList
        .map((item) => {
          if (item.title.indexOf(text) > -1) {
            return getParentKey(item.key, treeArray);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);

      setTreeSearchState({
        expandedKeys: expandedKeys as number[],
        autoExpandParent: true,
      });
    },
    [treeArray],
  );

  const loopData = (data: any) => {
    return data.map((item: any) => {
      const index = item.title.indexOf(searchText);
      const beforeStr = item.title.substr(0, index);
      const afterStr = item.title.substr(index + searchText.length);
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <SearchElement>{searchText}</SearchElement>
            {afterStr}
          </span>
        ) : (
          <span>{item.title}</span>
        );
      if (item.children) {
        return { title, key: item.key, children: loopTree(item.children) };
      }

      return {
        title,
        key: item.key,
      };
    });
  };

  const loopTree = (data: any) => {
    if (searchText) {
      const cloneData = data.slice().sort((item: any) => {
        const index = item.title.indexOf(searchText);
        return index > -1 ? -1 : 1;
      });
      return loopData(cloneData);
    }
    return loopData(data);
  };

  useEffect(() => {
    generateSmallList(treeArray);
  }, [generateSmallList, treeArray]);

  useEffect(() => {
    if (searchText) {
      searchCategory(searchText);
    }
    if (!searchText) {
      setTreeSearchState((prev) => ({ ...prev, autoExpandParent: true, expandedKeys: categoryIds || [] }));
    }
  }, [searchText, searchCategory, categoryIds]);

  return (
    <Wrapper>
      <TreeContainer>
        <Tree
          checkable
          height={230}
          treeData={loopTree(treeArray)}
          autoExpandParent={treeSearchState.autoExpandParent}
          expandedKeys={treeSearchState.expandedKeys}
          checkedKeys={currentSelectedCategoryIds}
          onCheck={handleOnCheck}
          onExpand={onExpand}
        />
      </TreeContainer>
      <Footer>
        <SelectedText>{currentCategoryTag}</SelectedText>
        <Button type="primary" onClick={handleOnSubmit}>
          搜尋
        </Button>
      </Footer>
    </Wrapper>
  );
}
