import reviewApi, {
  FetchReviewListParams,
  ReviewListItem,
  ReviewListResult,
  ReviewStatus,
  UpdateDetailParams,
  UpdateReviewApprovalStatus,
} from "@api/reviewApi";
import { StandardResponse } from "src/types";
import type { RootState } from "@redux/rootReducer";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message } from "antd";

interface IState {
  isFetchingList: boolean;
  reviewListResult: ReviewListResult;
  reviewListParams: FetchReviewListParams;
  reviewDetail: ReviewListItem;
  selectedReviewIds: number[];
}

export const initialState: IState = {
  isFetchingList: false,
  reviewListResult: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  reviewListParams: {
    limit: 20,
    offset: 0,
    approvalStatus: ReviewStatus.PEDNING,
  },
  reviewDetail: {
    id: -1,
    submissionAt: "",
    approvalStatus: 0,
    comments: "",
    salesPageName: "",
    salesPageId: -1,
    planName: "",
    orderNumber: "",
    orderId: -1,
    memberName: "",
    memberEmail: "",
    memberPhone: "",
    photo: "",
    skus: [],
    scores: 0,
    optionsDetail: [],
  },
  selectedReviewIds: [],
};

export const fetchReviewList = createAsyncThunk("reviewManagement/fetchReviewList", async (_, thunkApi) => {
  const {
    reviewManagement: { reviewListParams },
  } = thunkApi.getState() as RootState;

  const response = await reviewApi.fetchReviewList(reviewListParams);
  return response;
});

export const fetchReviewDetail = createAsyncThunk<ReviewListItem, number>(
  "reviewManagement/fetchReviewDetail",
  async (id) => {
    const response = await reviewApi.fetchReviewDetail(id);
    return response;
  },
);

export const updateReviewDetail = createAsyncThunk<ReviewListItem, UpdateDetailParams>(
  "reviewManaement/updateReviewDetail",
  async (params, thunkApi) => {
    const {
      reviewManagement: { reviewDetail },
    } = thunkApi.getState() as RootState;
    const { id } = reviewDetail;

    try {
      const { imageFilePath } = params;
      const imageDirPrefix =
        imageFilePath && !imageFilePath.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!) ? "review" : undefined;

      const validImageFilePath =
        imageFilePath && !imageFilePath.startsWith(process.env.REACT_APP_CLOUDFLARE_DOMAIN!)
          ? imageFilePath
          : undefined;

      const updateParams = {
        ...params,
        imageDirPrefix,
        imageFilePath: validImageFilePath,
      };
      const response = await reviewApi.updateReviewDetail(id, updateParams);
      message.success("更新成功");
      return response;
    } catch (error: any) {
      message.warning(error.message);
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const updateReviewApprovalStatus = createAsyncThunk<StandardResponse, 1 | -1>(
  "reviewManaement/updateReviewApprovalStatus",
  async (status, thunkApi) => {
    const {
      reviewManagement: { selectedReviewIds },
    } = thunkApi.getState() as RootState;

    const params: UpdateReviewApprovalStatus = {
      ids: selectedReviewIds.join(),
      approvalStatus: status,
    };

    const response = await reviewApi.updateReviewApprovalStatus(params);
    thunkApi.dispatch(updateSelectedReviewIds([]));
    thunkApi.dispatch(fetchReviewList());
    return response;
  },
);

export const clearFilter = createAsyncThunk("reviewManagement/clearFilter", async (_, thunkApi) => {
  thunkApi.dispatch(updateReviewListParams(initialState.reviewListParams));
  thunkApi.dispatch(fetchReviewList());
});

const reviewManagementSlice = createSlice({
  name: "reviewManagement",
  initialState,
  reducers: {
    resetReviewManagementSlice: () => initialState,
    updateReviewListParams: (state, action: PayloadAction<FetchReviewListParams>) => {
      state.reviewListParams = action.payload;
    },
    updateSelectedReviewIds: (state, action: PayloadAction<number[]>) => {
      state.selectedReviewIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchReviewList.pending, (state) => {
        state.isFetchingList = true;
      })
      .addCase(fetchReviewList.fulfilled, (state, action) => {
        state.isFetchingList = false;
        state.reviewListResult = action.payload;
      });

    builder.addCase(fetchReviewDetail.fulfilled, (state, action) => {
      state.reviewDetail = action.payload;
    });

    builder.addCase(updateReviewDetail.fulfilled, (state, action) => {
      state.reviewDetail = action.payload;
    });
  },
});

export const {
  resetReviewManagementSlice,
  updateReviewListParams,
  updateSelectedReviewIds,
} = reviewManagementSlice.actions;
export default reviewManagementSlice.reducer;
